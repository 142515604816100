import React, { Component } from "react";
import {
  Tag,
  Row,
  Col,
  Icon,
  Popover,
  Tabs,
  Popconfirm,
  Checkbox,
  Collapse,
  Radio,
} from "antd";
import TableLayout from "components/layout/TableLayout";
import { observer, inject } from "mobx-react";
import { getCurrentlocaleText } from "Core/Utils";
import SizeUnit from "Core/SizeUnit";
import WanFirewallFunc from "components/Containers/WanFirewallFunc";
import StatusContent from "components/Containers/StatusContent";
import NetworkController from "controller/NetworkController";
import ResetCounterIcon from "../../assets/img/reset_counter.svg";
import Icons from "../UI-Components/Icons";

const TabPane = Tabs.TabPane;
const Panel = Collapse.Panel;

const radioStyle = {
  display: "block",
  height: "30px",
  lineHeight: "30px",
};
const RadioGroup = Radio.Group;
@inject(
  "AuthStore",
  "InodeViewModel",
  "NetworkViewModel",
  "FirewallGroupViewModel",
)
@observer
class WanFirewall extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = this.props.InodeViewModel;
    this.networkModel = this.props.NetworkViewModel;
    this.firewallGroupModel = this.props.FirewallGroupViewModel;
    this.state = {
      showUnResolvedRules: false,
      FirewallStatus: this.props.FirewallStatus,
      wanFirewallTableData: [],
      showResetHitcounter: false,
      isDontShowChecked: false,
      resetShowedNetworks: [],
      visible: false,
      PopConfirmState: false,
      resetShowedNodes: [],
      NetworkIds: [],
      expandedRow: [],
      defaultActiveKey: "2",
    };
  }

  prepareFormData = values => {
    let firewall = values.firewallgroup;
    let policy = {
      policy: {
        firewall: firewall.map(val => {
          return val;
        }),
      },
    };
    return policy;
  };

  componentDidMount() {
    let NetworkIds = this.state.NetworkIds || [],
      Networks = this.props.FirewallStatus;
    if (Networks.length > 0) {
      Networks.forEach(Network => {
        if (WanFirewallFunc.checkForDebugEnabled(Network))
          NetworkIds.push(Network.id);
      });
    }
    this.refreshRulesHitCounters();
    let firewallTableData = !this.props.ShowAll
      ? WanFirewallFunc.FirewallTableData(
          this.props.FirewallStatus,
          this.firewallGroupModel.firewallGroups,
          this.state.showUnResolvedRules,
        )
      : WanFirewallFunc.FirewallTableAllData(
          this.props.FirewallStatus,
          this.firewallGroupModel.firewallGroups,
          this.state.showUnResolvedRules,
        );
    this.setState({
      wanFirewallTableData: firewallTableData,
      NetworkIds: NetworkIds,
    });
    let directionkeys = [];
    if (firewallTableData) {
      firewallTableData.forEach(data => {
        if (data.direction) {
          directionkeys.push(data.key);
        }
      });
      //Update keys to expand row
      this.setState({ expandedRow: directionkeys });
    }
    let showedNetworks = [];
    let currentUserId = this.props.AuthStore.loggedInUser.id,
      showediNodes = [];
    if (localStorage[currentUserId]) {
      if (JSON.parse(localStorage[currentUserId]).CounterResetSkippedNetworks)
        showedNetworks = JSON.parse(localStorage[currentUserId])
          .CounterResetSkippedNetworks;
      if (JSON.parse(localStorage[currentUserId]).CounterResetSkippediNodes)
        showediNodes = JSON.parse(localStorage[currentUserId])
          .CounterResetSkippediNodes;
    }
    this.setState({
      resetShowedNetworks: showedNetworks,
      resetShowedNodes: showediNodes,
    });
  }

  refreshRulesHitCounters = () => {
    if (!this.props.ShowAll) {
      if (WanFirewallFunc.checkForDebugEnabled(this.props.FirewallStatus)) {
        this.updateHitcounter = setInterval(() => {
          this.getNetworkDetails([this.props.netWorkId]);
        }, 30000);
      }
    } else {
      this.updateAllNetworks(this.props.FirewallStatus);
    }
  };
  componentWillUnmount() {
    /*Required to clear interval on component unmount*/
    clearInterval(this.updateHitcounter);
  }

  updateAllNetworks = Networks => {
    let NetworkIds = [];
    if (Networks.length > 0) {
      Networks.forEach(Network => {
        if (WanFirewallFunc.checkForDebugEnabled(Network))
          NetworkIds.push(Network.id);
      });
    }
    if (NetworkIds.length > 0) {
      this.updateHitcounter = setInterval(() => {
        this.getNetworkDetails(NetworkIds);
      }, 30000);
    }
  };

  checkForDebug = () => {
    let Networks = this.props.FirewallStatus,
      status = false;
    if (Networks.length > 0) {
      Networks.forEach(Network => {
        if (WanFirewallFunc.checkForDebugEnabled(Network)) status = true;
      });
    }
    return status;
  };

  AddNetworkDontShowList = (NetworkId = []) => {
    let currentUserId = this.props.AuthStore.loggedInUser.id;
    let showedNetworks = [],
      showediNodes = [];
    if (localStorage[currentUserId]) {
      if (JSON.parse(localStorage[currentUserId]).CounterResetSkippedNetworks)
        showedNetworks = JSON.parse(localStorage[currentUserId])
          .CounterResetSkippedNetworks;
      if (JSON.parse(localStorage[currentUserId]).CounterResetSkippediNodes)
        showediNodes = JSON.parse(localStorage[currentUserId])
          .CounterResetSkippediNodes;
    }
    if (NetworkId.length > 0) {
      NetworkId.forEach(n => {
        if (
          localStorage[currentUserId] &&
          !JSON.parse(
            localStorage[currentUserId],
          ).CounterResetSkippedNetworks.includes(n)
        )
          showedNetworks.push(n);
      });
      localStorage.setItem(
        currentUserId,
        JSON.stringify({
          CounterResetSkippedNetworks: showedNetworks,
          CounterResetSkippediNodes: showediNodes,
        }),
      );
      this.setState({
        resetShowedNetworks: showedNetworks,
        isDontShowChecked: false,
        resetShowedNodes: showediNodes,
      });
    }
  };
  AddiNodeDontShowList = () => {
    let currentUserId = this.props.AuthStore.loggedInUser.id;
    let showedNetworks = [],
      showediNodes = [];
    if (localStorage[currentUserId]) {
      if (JSON.parse(localStorage[currentUserId]).CounterResetSkippedNetworks)
        showedNetworks = JSON.parse(localStorage[currentUserId])
          .CounterResetSkippedNetworks;
      if (JSON.parse(localStorage[currentUserId]).CounterResetSkippediNodes)
        showediNodes = JSON.parse(localStorage[currentUserId])
          .CounterResetSkippediNodes;
    }
    showediNodes.push(this.props.nodeId);
    localStorage.setItem(
      currentUserId,
      JSON.stringify({
        CounterResetSkippedNetworks: showedNetworks,
        CounterResetSkippediNodes: showediNodes,
      }),
    );
    this.setState({
      resetShowedNetworks: showedNetworks,
      isDontShowChecked: false,
      resetShowedNodes: showediNodes,
    });
  };
  handleVisibleChange = visible => {
    if (!visible) {
      this.setState({ visible });
      return;
    }
    // Determining condition before show the popconfirm.
    if (
      !this.props.ShowAll &&
      this.state.resetShowedNetworks.includes(this.props.netWorkId)
    ) {
      this.resetHitCounter(); // next step
    } else if (
      this.props.ShowAll &&
      this.state.resetShowedNodes.includes(this.props.nodeId)
    ) {
      this.resetHitCounter(); // next step
    } else {
      this.setState({ visible }); // show the popconfirm
    }
  };
  resetHitCounter = () => {
    let Networks = this.props.FirewallStatus;
    if (!this.props.ShowAll) {
      if (this.state.isDontShowChecked)
        this.AddNetworkDontShowList([this.props.netWorkId]);
      if (this.props.debugEnabled) {
        NetworkController.resetCounterByNetworkId([this.props.netWorkId], true)
          .then(response => {
            this.triggerResetHitCounter(false);
          })
          .catch(error => {
            this.triggerResetHitCounter(false);
          });
      }
    } else {
      let NetworkIds = [];
      if (Networks.length > 0) {
        Networks.forEach(Network => {
          if (WanFirewallFunc.checkForDebugEnabled(Network))
            NetworkIds.push(Network.id);
        });
        if (Networks.length > 0) {
          if (this.state.isDontShowChecked)
            this.AddiNodeDontShowList(NetworkIds);
          NetworkController.resetCounterByNetworkId(NetworkIds, true)
            .then(response => {
              this.triggerResetHitCounter(false);
            })
            .catch(error => {
              this.triggerResetHitCounter(false);
            });
        }
      }
    }
  };

  UNSAFE_componentWillReceiveProps(newprops) {
    if (
      JSON.stringify(newprops.FirewallStatus) !==
      JSON.stringify(this.props.FirewallStatus)
    ) {
      let NetworkIds = this.state.NetworkIds || [],
        Networks = newprops.FirewallStatus;
      if (Networks.length > 0) {
        Networks.forEach(Network => {
          if (WanFirewallFunc.checkForDebugEnabled(Network))
            NetworkIds.push(Network.id);
        });
      }
      this.setState({
        FirewallStatus: newprops.FirewallStatus,
        NetworkIds: NetworkIds,
      });
      let firewallTableData = !this.props.ShowAll
        ? WanFirewallFunc.FirewallTableData(
            newprops.FirewallStatus,
            this.firewallGroupModel.firewallGroups,
            this.state.showUnResolvedRules,
          )
        : WanFirewallFunc.FirewallTableAllData(
            newprops.FirewallStatus,
            this.firewallGroupModel.firewallGroups,
            this.state.showUnResolvedRules,
          );
      this.setState({
        wanFirewallTableData: firewallTableData,
      });
      let directionkeys = [];
      if (firewallTableData) {
        firewallTableData.forEach(data => {
          if (data.direction) {
            directionkeys.push(data.key);
          }
        });
        //Update keys to expand row
        this.setState({ expandedRow: directionkeys });
      }
      this.updateAllNetworks(newprops.FirewallStatus);
    }
  }
  getNetworkDetails = (NetworkIds = []) => {
    if (NetworkIds.length > 0) {
      NetworkController.getNetworkById(NetworkIds).then(res => {
        this.setState({
          wanFirewallTableData: !this.props.ShowAll
            ? WanFirewallFunc.FirewallTableData(
                res[0],
                this.firewallGroupModel.firewallGroups,
                this.state.showUnResolvedRules,
              )
            : WanFirewallFunc.FirewallTableAllData(
                NetworkIds.length === this.state.FirewallStatus.length
                  ? res
                  : this.updateEnabledNetwork(NetworkIds, res),
                this.firewallGroupModel.firewallGroups,
                this.state.showUnResolvedRules,
              ),
        });
      });
    }
  };

  updateEnabledNetwork(NetworkIds, result) {
    let Networks = this.state.FirewallStatus;
    let CurrentNetwork = "";
    NetworkIds.forEach(NetworkId => {
      Networks.forEach((Network, index) => {
        if (Network.id === NetworkId) CurrentNetwork = Network;
      });
    });
    let index = Networks.indexOf(CurrentNetwork);
    // updated status of a firewall rules will be replaced in existing network
    Networks[index] = result[0];
    this.setState({ FirewallStatus: Networks });
    return Networks;
  }

  changeListview = e => {
    this.setState({ showUnResolvedRules: e.target.checked });
  };
  triggerResetHitCounter = status => {
    this.setState({ showResetHitcounter: status });
  };
  prepareNetworkData = (record, interface_type) => {
    if (record.source_network.length > 0 && interface_type === "source") {
      if (
        record.source_network.toString() != "AnyPeer" &&
        !record.source_network.toString().includes("_iotium") &&
        !record.source_network.toString().includes(":")
      ) {
        return WanFirewallFunc.getNetworkGroupInfo(
          record.source_network.toString(),
          this.props.orgsNetwork,
        );
      } else {
        if (record.source_network.toString().includes("_iotium.network.id")) {
          return WanFirewallFunc.getNetworkGroupInfo(
            record.source_network
              .toString()
              .split(":")[1]
              .trim(),
            this.props.orgsNetwork,
          );
        } else {
          return WanFirewallFunc.checkforAnyString(
            record.source_network.toString(),
          ).length > 15 ? (
            <Popover
              placement="top"
              content={WanFirewallFunc.checkforAnyString(
                record.source_network.toString(),
              )}
            >
              <Tag className={"firewall-rules-tag-content iot-tag-label-color"}>
                {" "}
                <Icons
                  type="ai"
                  name="AiOutlineTag"
                  style={{ marginRight: 5 }}
                />
                {WanFirewallFunc.checkforAnyString(
                  record.source_network.toString(),
                )}
              </Tag>
            </Popover>
          ) : (
            <Tag className="iot-tag-label-color">
              {" "}
              <Icons type="ai" name="AiOutlineTag" style={{ marginRight: 5 }} />
              {WanFirewallFunc.checkforAnyString(
                record.source_network.toString(),
              )}
            </Tag>
          );
        }
      }
    } else if (
      record.destination_network.length > 0 &&
      interface_type === "destination"
    ) {
      if (
        record.destination_network.toString() != "AnyPeer" &&
        !record.destination_network.toString().includes("_iotium") &&
        !record.destination_network.toString().includes(":")
      ) {
        return WanFirewallFunc.getNetworkGroupInfo(
          record.destination_network.toString(),
          this.props.orgsNetwork,
        );
      } else {
        if (
          record.destination_network.toString().includes("_iotium.network.id")
        ) {
          return WanFirewallFunc.getNetworkGroupInfo(
            record.destination_network
              .toString()
              .split(":")[1]
              .trim(),
            this.props.orgsNetwork,
          );
        } else {
          return WanFirewallFunc.checkforAnyString(
            record.destination_network.toString(),
          ).length > 15 ? (
            <Popover
              placement="top"
              content={WanFirewallFunc.checkforAnyString(
                record.destination_network.toString(),
              )}
            >
              <Tag className={"firewall-rules-tag-content iot-tag-label-color"}>
                {" "}
                <Icons
                  type="ai"
                  name="AiOutlineTag"
                  style={{ marginRight: 5 }}
                />
                {WanFirewallFunc.checkforAnyString(
                  record.destination_network.toString(),
                )}
              </Tag>
            </Popover>
          ) : (
            <Tag className="iot-tag-label-color">
              {" "}
              <Icons type="ai" name="AiOutlineTag" style={{ marginRight: 5 }} />
              {WanFirewallFunc.checkforAnyString(
                record.destination_network.toString(),
              )}
            </Tag>
          );
        }
      }
    } else {
      return WanFirewallFunc.checkforAnyString("*");
    }
  };
  ChangePopupVisibility = () => {
    this.setState({ isDontShowChecked: !this.state.isDontShowChecked });
  };
  /**
   *  Get more Info...
   */
  getMoreInfo = record => {
    return (
      <span
        onClick={() => {
          this.setState({ currentRowKey: record.key });
        }}
      >
        <Tabs
          tabPosition={"top"}
          defaultActiveKey={this.state.defaultActiveKey}
        >
          <TabPane
            tab={
              <span>
                {getCurrentlocaleText("networks.diode.tab.header.text")}
              </span>
            }
            key="1"
          >
            <Row type="flex" gutter={2}>
              <Col style={{ marginBottom: 25, marginTop: 20 }} span={24}>
                <RadioGroup disabled={true} value={record.direction}>
                  <Radio style={radioStyle} value={"FromServiceToDevice"}>
                    {getCurrentlocaleText(
                      "networks.southside.direction1.label.text",
                    )}
                  </Radio>
                  <Radio style={radioStyle} value={"FromDeviceToService"}>
                    {getCurrentlocaleText(
                      "networks.southside.direction2.label.text",
                    )}
                  </Radio>
                </RadioGroup>
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </span>
    );
  };
  onExpand = (expanded, record) => {
    if (record.key != null) {
      let keys = this.state.expandedRow;
      let index = keys.findIndex(val => {
        return val === record.key;
      });
      if (expanded) {
        if (index === -1) {
          keys.push(record.key);
        }
        this.setExpandedRowKeys(keys);
      } else {
        if (index !== -1) {
          keys.splice(index, 1);
        }
        this.setExpandedRowKeys(keys);
      }
    }
  };
  setExpandedRowKeys = keys => {
    this.setState({ expandedRow: keys, defaultActiveKey: "1" });
  };
  render() {
    this.columns = [
      {
        title: "",
        dataIndex: "firewall_status",
        render: (text, record, index) => {
          return (
            <span key={index}>
              {record.is_resolved ? null : (
                <Popover
                  placement="topRight"
                  title={getCurrentlocaleText(
                    "firewall.rule_not_resolved.label.text",
                  )}
                  content={getCurrentlocaleText(
                    "firewall.rule_not_resolved.label.message",
                  )}
                >
                  {" "}
                  <Icon
                    type="exclamation-circle"
                    className={"firewall-rules-unresolved"}
                  />
                </Popover>
              )}
              {record.show_warning && (
                <Popover
                  placement="topRight"
                  title={getCurrentlocaleText(
                    "firewall.rule_priority_repeated.label.text",
                  )}
                  content={getCurrentlocaleText(
                    "firewall.rule_priority_repeated.label.message",
                  )}
                >
                  <Icon
                    className={"firewall-rules-repeated"}
                    type="exception"
                  />
                </Popover>
              )}
            </span>
          );
        },
      },
      {
        title: (
          <span className={"pheonix-table-content-wrap"}>
            {getCurrentlocaleText("firewall.all_rules.rules.table.header.text")}
          </span>
        ),
        dataIndex: "network_name",
        render: (text, record, index) => {
          return (
            <span style={{ wordBreak: "break-word" }} key={index}>
              {record.network_name}
            </span>
          );
        },
      },
      {
        title: (
          <span className={"pheonix-table-content-wrap"}>
            {getCurrentlocaleText("general.security.policy.text")}
          </span>
        ),
        dataIndex: "firewallgroup",
        render: (text, record, index) => {
          return (
            <span style={{ wordBreak: "break-word" }}>
              {WanFirewallFunc.replaceDefaultGroupName(record.group_name)}
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText("firewall.list.table.header.priority.text"),
        dataIndex: "priority",
        sorter: (a, b) => a.priority - b.priority,
        render: (text, record, index) => {
          return <span>{record.priority}</span>;
        },
      },
      {
        title: (
          <span className={"pheonix-table-content-wrap"}>
            {getCurrentlocaleText("general.firewall.from_network.label.text")}
          </span>
        ),
        dataIndex: "source_network",
        render: (text, record, index) => {
          return <span>{this.prepareNetworkData(record, "source")}</span>;
        },
      },
      {
        title: (
          <span className={"pheonix-table-content-wrap"}>
            {getCurrentlocaleText("general.firewall.to_network.label.text")}
          </span>
        ),
        dataIndex: "destination_network",
        render: (text, record, index) => {
          return <span>{this.prepareNetworkData(record, "destination")}</span>;
        },
      },
      {
        title: getCurrentlocaleText("firewall.list.table.header.protocol.text"),
        dataIndex: "protocol",
        render: (text, record, index) => {
          return <span>{record.protocol}</span>;
        },
      },
      {
        title: (
          <span className={"pheonix-table-content-wrap"}>
            {getCurrentlocaleText(
              "firewall.list.table.header.source_port.text",
            )}
          </span>
        ),
        dataIndex: "sourceport",
        render: (text, record, index) => {
          return (
            <span>{WanFirewallFunc.checkforAnyString(record.source_port)}</span>
          );
        },
      },
      {
        title: (
          <span className={"pheonix-table-content-wrap"}>
            {getCurrentlocaleText(
              "firewall.list.table.header.destination_port.text",
            )}
          </span>
        ),
        dataIndex: "port",
        render: (text, record, index) => {
          return <span>{WanFirewallFunc.checkforAnyString(record.port)}</span>;
        },
      },
      {
        title: (
          <span className={"pheonix-table-content-wrap"}>
            {getCurrentlocaleText("firewall.list.table.header.source.text")}
          </span>
        ),
        dataIndex: "source_ipaddress",
        render: (text, record, index) => {
          return (
            <span key={index}>
              {record.source_tag && record.source_tag.includes("@tag:") ? (
                <Popover
                  content={WanFirewallFunc.checkforAnyString(
                    record.source_ipaddress,
                  )}
                  title={getCurrentlocaleText(
                    "firewall.list.table.source.hover.title.text",
                  )}
                >
                  <Tag>{record.source_tag.replace("@tag:", " ")}</Tag>
                </Popover>
              ) : record.source_ipaddress ? (
                WanFirewallFunc.checkforAnyString(record.source_ipaddress)
              ) : (
                WanFirewallFunc.checkforAnyString("*")
              )}
            </span>
          );
        },
      },
      {
        title: (
          <span className={"pheonix-table-content-wrap"}>
            {getCurrentlocaleText(
              "firewall.list.table.header.destination.text",
            )}
          </span>
        ),
        dataIndex: "destination_ipaddress",
        render: (text, record, index) => {
          return (
            <span key={index}>
              {record.destination_tag &&
              record.destination_tag.includes("@tag:") ? (
                <Popover
                  content={WanFirewallFunc.checkforAnyString(
                    record.destination_ipaddress,
                  )}
                  title={getCurrentlocaleText(
                    "firewall.list.table.destination.hover.title.text",
                  )}
                >
                  <Tag>{record.destination_tag.replace("@tag:", " ")}</Tag>
                </Popover>
              ) : record.destination_ipaddress ? (
                WanFirewallFunc.checkforAnyString(record.destination_ipaddress)
              ) : (
                WanFirewallFunc.checkforAnyString("*")
              )}
            </span>
          );
        },
      },
      {
        title: (
          <span className={"pheonix-table-content-wrap"}>
            {getCurrentlocaleText("general.table.actions.header.text")}
          </span>
        ),
        dataIndex: "action",
        render: (text, record, index) => {
          return (
            <span key={index}>
              <Tag
                style={{ minWidth: 85, textAlign: "center" }}
                className={WanFirewallFunc.getTagColor(record.action)}
                key={index}
              >
                {record.action}
              </Tag>
            </span>
          );
        },
      },
      {
        title: (
          <span className={"pheonix-table-content-wrap"}>
            {getCurrentlocaleText("firewall.rule.byte_header.text")}
            {((!this.props.ShowAll &&
              !this.props.viewOnly &&
              this.props.debugEnabled &&
              !this.props.cluster_id) ||
              (this.props.ShowAll && this.state.NetworkIds.length > 0)) && (
              <Popconfirm
                overlayClassName={"custom-inline-popup"}
                placement="rightTop"
                title={
                  <div className="inline-popup" style={{ marginLeft: -20 }}>
                    <div className="inline-popup-header">
                      {" "}
                      <p className="inline-popup-title">
                        {getCurrentlocaleText(
                          "firewall.reset.counter.inline.popup.title",
                        )}
                      </p>
                    </div>
                    <p className="inline-popup-body">
                      {
                        <span>
                          <p>
                            {getCurrentlocaleText(
                              "firewall.reset.counter.all_rules.message",
                              { 0: this.props.ShowAll ? "iNode" : "network" },
                            )}
                          </p>
                        </span>
                      }
                    </p>
                    <Checkbox
                      checked={this.state.isDontShowChecked}
                      onChange={this.ChangePopupVisibility}
                    >
                      {getCurrentlocaleText("firewall.reset.skip.text", {
                        0: this.props.ShowAll ? "iNode" : "network",
                      })}
                    </Checkbox>
                  </div>
                }
                visible={this.state.visible}
                onVisibleChange={this.handleVisibleChange}
                overlayStyle={{ width: "25%" }}
                onConfirm={this.resetHitCounter}
                okText={getCurrentlocaleText(
                  "firewall.reset.counter.confirm.button.text",
                )}
                cancelText="No"
              >
                <span
                  title={getCurrentlocaleText(
                    "firewall.reset.counter.button.label.text",
                  )}
                >
                  <ResetCounterIcon className="custom-icon-reset-counter" />
                </span>
              </Popconfirm>
            )}
          </span>
        ),
        dataIndex: "packet_count",
        key: "packet_count",
        width: "8%",
        render: (text, record, index) => {
          return (
            <span className={"pheonix-table-content-wrap"} key={index}>
              {record.packet_count && record.byte_count ? (
                <Popover
                  key={index}
                  content={
                    <StatusContent
                      packets={WanFirewallFunc.getStatusPacketsData(record)}
                      bytes={SizeUnit.formatBytesSize(record.byte_count)}
                      key={index}
                    />
                  }
                  title={getCurrentlocaleText("firewall.rule.byte_header.text")}
                >
                  <span>
                    {WanFirewallFunc.getStatusPacketsData(record) +
                      "/" +
                      SizeUnit.formatBytesSize(record.byte_count)}
                  </span>
                </Popover>
              ) : (
                <span>{"-"}</span>
              )}
            </span>
          );
        },
      },
    ];
    if (!this.props.ShowAll) {
      this.columns.splice(1, 1);
    }
    let content = (
      <>
        <div style={{ textAlign: "right" }}>
          {this.props.nodeState === "ALIVE" &&
            (this.props.debugEnabled
              ? this.props.debugEnabled
                ? true
                : false
              : this.checkForDebug()) && (
              <span style={{ fontWeight: "normal", marginRight: 20 }} />
            )}{" "}
          <span style={{ fontWeight: "normal", marginRight: 20 }}>
            <Checkbox onChange={this.changeListview}>
              {getCurrentlocaleText("firewall.list.table_view.checkbox.label")}
            </Checkbox>
          </span>
        </div>
        <TableLayout
          columns={this.columns}
          dataSource={this.state.wanFirewallTableData}
          rowClassName={(record, index) => {
            return record.is_generated ? "iot-generated-table-row-data" : "";
          }}
          tableSize="small"
          hideRowSelection={true}
          pagination={false}
          style={{ marginTop: 20 }}
          expandedRowKeys={this.state.expandedRow}
          onExpand={this.onExpand}
          expandedRowRender={record => {
            return record.direction ? this.getMoreInfo(record) : null;
          }}
        />
      </>
    );

    return <div>{content}</div>;
  }
}

export default WanFirewall;
