import React, { Component } from "react";
import { getCurrentlocaleText } from "Core/Utils";
import { observer, inject } from "mobx-react";
import FileSaver from "file-saver";
import Icons from "../UI-Components/Icons";

const copyIconStyle = {
  fontSize: 17,
  position: "absolute",
  marginTop: 2,
  marginLeft: 5,
  cursor: "pointer",
};

@inject("UiStore")
@observer
class ApiKeySuccessModal extends Component {
  constructor(props) {
    super(props);
    this.apiKeyElement = null;
    this.state = {
      visibility: this.props.visibility,
      api_key: this.props.api_key,
      showCopied: false,
      key_name: this.props.key_name,
    };
  }
  UNSAFE_componentWillReceiveProps(newprops) {
    if (this.prop != newprops) {
      this.setState({
        visibility: newprops.visibility,
        api_key: newprops.api_key,
        key_name: newprops.key_name,
      });
    }
  }
  ignoreAlert = () => {
    this.props.hidePopup();
  };

  updateHelp = data => {
    this.props.UiStore.SetStoreData("helpData", data);
    setTimeout(() => {
      let elm = document.querySelector(".help-container");
      if (elm) {
        elm.lastChild.querySelector(".help-content").innerHTML =
          data && data.data && data.data[2] && data.data[2].content
            ? data.data[2].content
            : "";
      }
    }, 10);
  };

  downloadKey = () => {
    let fileData = this.state.api_key;
    //fileData = fileData.join(" \n"); // including line-break
    let blob = new Blob([fileData], { type: "text/plain;charset=utf-8" }),
      fileName = this.state.key_name;
    FileSaver.saveAs(blob, fileName);
  };

  copyKey = () => {
    if (this.apiKeyElement) {
      this.selectElementText(this.apiKeyElement);
      /* Copy the text inside the text field */
      document.execCommand("copy");
      this.setState({ showCopied: true });
      setTimeout(() => {
        this.setState({ showCopied: false });
      }, 2000);
    }
  };

  selectElementText = el => {
    var range = document.createRange(); // create new range object
    range.selectNodeContents(el); // set range to encompass desired element text
    var selection = window.getSelection(); // get Selection object from currently user selected text
    selection.removeAllRanges(); // unselect any user selected text (if any)
    selection.addRange(range); // add range to Selection object to select it
  };

  render() {
    return (
      <div
        onClick={this.updateHelp({
          title: getCurrentlocaleText("user.api_keys.label.text"),
          data: [
            {
              subtitle: "",
              content: getCurrentlocaleText("user.api_keys.success.message"),
            },
            {
              subtitle: "",
              content: getCurrentlocaleText("user.api_keys.success.message2"),
            },
            {
              subtitle: "",
              content: getCurrentlocaleText("user.api_keys.success.message3"),
            },
          ],
        })}
      >
        <span>{}</span>
        <br />
        <br />
        <span>
          <strong style={{ marginBottom: 5, display: "inline-flex" }}>
            {getCurrentlocaleText("user.api_keys.label.text")}
          </strong>
        </span>
        <br />
        <code
          ref={c => {
            this.apiKeyElement = c;
          }}
          style={{
            border: "0.5px solid #8080804d",
            padding: 5,
            borderRadius: 3,
            fontSize: 14,
          }}
        >
          {this.state.api_key}
        </code>
        <span onClick={this.copyKey}>
          <Icons
            type="ai"
            className={"baseline iot-copy-icon-style"}
            name="AiOutlineCopy"
          />
        </span>

        {this.state.showCopied && (
          <span
            style={{
              position: "relative",
              float: "right",
              marginRight: "15%",
              fontSize: 10,
              color: "green",
            }}
          >
            {getCurrentlocaleText("user.api_keys.selection.copied.text")}
          </span>
        )}
      </div>
    );
  }
}

export default ApiKeySuccessModal;
