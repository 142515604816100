import React, { Component } from "react";
import { Link } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { Row, Col, Steps, Button, Icon } from "antd";
import { Form } from "@ant-design/compatible";
import { getCurrentlocaleText } from "Core/Utils";
import HelpCard from "components/UI-Components/HelpCard";
import AlertConditionItems from "components/Alerts/components/AlertConditionItems";
import AlertTargetItems from "components/Alerts/components/AlertTargetItems";
import AlertNotificationItems from "components/Alerts/components/AlertNotificationItems";
import AlertGeneralInfoDisplay from "components/Alerts/components/AlertGeneralInfoDisplay";
import "../../assets/styles/Alert.css";
import Icons from "../UI-Components/Icons";

const Step = Steps.Step;
const steps = [
  {
    title: getCurrentlocaleText("inode.interface.name.label.text"),
  },
  {
    title: getCurrentlocaleText("managealerts.condition.text"),
  },
  {
    title: getCurrentlocaleText("managealerts.target.text"),
  },
  {
    title: getCurrentlocaleText("managealerts.notification.text"),
  },
];

@inject("UiStore")
@observer
class AlertStepForm extends Component {
  constructor(props) {
    super(props);
    this.UiStore = this.props.UiStore;
    this.state = {
      current: 0,
      formType: props.formType,
      isSubmitting: false,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ isSubmitting: props.isSubmitting });
  }

  validateForm = () => {
    // Here Values is final step form values
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let alertFormData = this.UiStore.alertFormData,
          formData = Object.assign(
            {},
            alertFormData.genInfo,
            alertFormData.condition,
            alertFormData.target,
            values,
          );
        this.props.onSubmit(formData);
      }
    });
  };

  moveToNext = () => {
    let current = this.state.current;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        //First step (General Info)
        if (current === 0) {
          let alertFormData = this.UiStore.alertFormData;
          alertFormData.genInfo = values;
          this.UiStore.alertFormData = alertFormData;
          if (this.state.formType === "add") {
            this.UiStore.alertFormData.condition = {};
          }
        }
        // Second step (Condition)
        if (current === 1) {
          this.UiStore.alertFormData.condition = values;
          if (this.state.formType === "add") {
            this.UiStore.alertFormData.target = {};
          }
        }
        // Third step (Target)
        if (current === 2) {
          this.UiStore.alertFormData.target = values;
          if (this.state.formType === "add") {
            this.UiStore.alertFormData.notification = {};
          }
        }
        current += 1;
        this.setState({ current });
      }
    });
  };

  moveToPrevious = () => {
    let current = this.state.current;
    current -= 1;
    this.setState({ current });
  };

  componentWillUnmount() {
    this.UiStore.resetAlertFormData();
  }

  render() {
    const { current } = this.state;
    return (
      <div>
        <Form noValidate onSubmit={this.props.onSubmit} className="alert-form">
          {/*Steps*/}
          <Row gutter={2} type="flex" align="top">
            <Col span={24}>
              <Steps current={current}>
                {steps.map(item => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
            </Col>
          </Row>
          <Row
            gutter={2}
            type="flex"
            align="top"
            className="alert-steps-contianer"
          >
            <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 13 }}
              lg={{ span: 13 }}
              xl={{ span: 12 }}
              className="card-grid"
            >
              <div className="alert-steps-contianer-col">
                {current === 0 && (
                  <AlertGeneralInfoDisplay
                    formType={this.state.formType}
                    formReference={this.props.form}
                    isDefault={this.props.isDefault}
                  />
                )}
                {current === 1 && (
                  <AlertConditionItems
                    formType={this.state.formType}
                    formReference={this.props.form}
                    isDefault={this.props.isDefault}
                  />
                )}
                {current === 2 && (
                  <AlertTargetItems
                    formType={this.state.formType}
                    formReference={this.props.form}
                    isDefault={this.props.isDefault}
                  />
                )}
                {current === 3 && (
                  <AlertNotificationItems
                    formType={this.state.formType}
                    formReference={this.props.form}
                    isDefault={this.props.isDefault}
                  />
                )}
              </div>
            </Col>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 11 }}
              lg={{ span: 11 }}
              xl={{ span: 12 }}
            >
              <HelpCard
                customStyle={{
                  borderLeft: "none",
                }}
                span={24}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24} className="col-center">
              <div className="alert-steps-contianer">
                <Link to="/managealerts">
                  <Button
                    className="alert-steps-contianer-btn"
                    disabled={this.state.isSubmitting}
                    title={getCurrentlocaleText("cancel.button.text")}
                  >
                    {getCurrentlocaleText("cancel.button.text")}
                  </Button>
                </Link>
                {current !== 0 && current <= steps.length - 1 && (
                  <Button
                    className="alert-steps-contianer-btn"
                    onClick={this.moveToPrevious}
                    disabled={this.state.isSubmitting}
                    title={getCurrentlocaleText("previous.button.text")}
                  >
                    {getCurrentlocaleText("previous.button.text")}
                  </Button>
                )}
                {current < steps.length - 1 && (
                  <Button
                    type="primary"
                    onClick={this.moveToNext}
                    title={getCurrentlocaleText("next.button.text")}
                  >
                    {getCurrentlocaleText("next.button.text")}
                  </Button>
                )}
                {this.state.formType !== "view" &&
                  !this.props.isDefault &&
                  current === steps.length - 1 && (
                    <Button
                      type="primary"
                      onSubmit={this.validateForm}
                      onClick={this.validateForm}
                      loading={this.state.isSubmitting}
                      title={getCurrentlocaleText("save.button.text")}
                    >
                      <Icons
                        type="ai"
                        name="AiOutlineSave"
                        className="no-margin"
                      />{" "}
                      {getCurrentlocaleText("save.button.text")}
                    </Button>
                  )}
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

const AlertStepsForm = Form.create()(AlertStepForm);
export default AlertStepsForm;
