import React, { Component } from "react";
import { Alert } from "antd";
import { Form } from "@ant-design/compatible";
import { observer, inject } from "mobx-react";
import Input from "components/UI-Components/InputBox";
import RolesSelect from "components/UI-Components/UserRolesSelect";
import {
  focusFirstInput,
  passwordValidator,
  getCurrentlocaleText,
} from "Core/Utils";
import Icons from "components/UI-Components/Icons";
import FormErrorMessage from "components/UI-Components/FormErrorMessage";
import PasswordInput from "components/UI-Components/PasswordInput";
import TimezoneComponent from "components/Logs/Components/TimezoneComponent";
import OrgController from "controller/OrgController";

const FormItem = Form.Item;

@inject("ModelStore", "UiStore")
@observer
class UserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orgTimezone: "",
      orgLoading: true,
      isRoleChanged: false,
      isApiAccessEnabled: false,
      isCentralAuth: false,
    };
  }

  UNSAFE_componentWillMount() {
    // Getting org timezone & apikey policy
    if (this.props.orgId) {
      OrgController.getOrgById(this.props.orgId)
        .then(response => {
          if (response) {
            if (response.timezone) {
              this.setState({ orgTimezone: response.timezone });
            }
            if (response.policy && response.policy.apikey) {
              this.setState({
                isApiAccessEnabled: response.policy.apikey.enable,
              });
            } else {
              this.setState({
                isApiAccessEnabled: false,
              });
            }
            if (response.is_central_auth) {
              this.setState({ isCentralAuth: true });
            }
          }
          this.setState({ orgLoading: false });
        })
        .catch(error => {
          this.setState({ orgLoading: false });
        });
    }
  }

  componentDidMount() {
    /* Focus First Input */
    focusFirstInput();
  }

  setSelectVal = values => {
    let formVals = {};
    formVals[values.type] = {
      value: values.value,
    };
    this.props.form.setFields(formVals);
    // Check if role has changed or not to show warning.
    let initialRole = this.getInitialRoles();
    if (
      initialRole &&
      initialRole[0] &&
      values.value &&
      values.value[0] &&
      initialRole[0] !== values.value[0]
    ) {
      this.setState({ isRoleChanged: true });
    } else {
      this.setState({ isRoleChanged: false });
    }
  };

  validatePassword = (rule, value, callback) => {
    let validation = passwordValidator(value);
    if (validation) {
      return callback(validation);
    }
    // Check confirm password
    let new_password = this.props.form.getFieldValue("confirm_password");
    if (new_password) {
      this.checkConfirmNewPassword(new_password);
    }
    return callback();
  };

  checkConfirmNewPassword = new_password => {
    let obj = {};
    let confirm_password = this.props.form.getFieldValue("password");
    obj["confirm_password"] = { value: `${new_password}` };
    if (new_password !== confirm_password) {
      obj["confirm_password"].errors = [
        new Error("Confirm Password does not match the password"),
      ];
    }
    this.props.form.setFields(obj);
  };

  checkPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue("password")) {
      callback("Confirm Password does not match the password");
    } else {
      callback();
    }
  };

  getInitialRoles = () => {
    if (this.props.initialVal && this.props.initialVal.roles) {
      return this.props.initialVal.roles.map(val => {
        return val.id;
      });
    }
  };

  selectedTimezoneValue = value => {
    this.props.form.setFields({
      timezone: {
        value: value,
      },
    });
  };

  render() {
    const { getFieldDecorator, getFieldError } = this.props.form;
    return (
      <Form noValidate onSubmit={this.props.onSubmit}>
        <FormItem label="Full Name" hasFeedback>
          {getFieldDecorator("name", {
            rules: [
              {
                required: true,
                message: "Please enter your full name.",
                whitespace: true,
              },
              {
                max: 255,
                message: "Full name cannot be longer than 255 characters.",
              },
              {
                pattern: /^[A-Za-z0-9 ]*$/,
                message: "Full name must not contain any special characters.",
              },
            ],
            initialValue: this.props.initialVal
              ? this.props.initialVal.name
              : "",
          })(
            <Input
              className="firstInput"
              autoComplete="off"
              prefix={<Icons type="ai" name="AiOutlineUser" />}
              placeholder="Full Name"
            />,
          )}
        </FormItem>
        <FormItem label="Email Address" hasFeedback>
          {getFieldDecorator("email", {
            rules: [
              {
                required: true,
                message: "Please enter your email address.",
                type: "email",
              },
            ],
            initialValue: this.props.initialVal
              ? this.props.initialVal.email
              : "",
          })(
            <Input
              type="email"
              autoComplete="off"
              prefix={<Icons type="ai" name="AiOutlineMail" />}
              placeholder="Email Address"
              disabled={this.props.disabledEmail}
            />,
          )}
          {!getFieldError("email") && !this.props.disabledEmail && (
            <div>
              <span>
                <span style={{ marginRight: 7 }}>
                  <Icons
                    type="fa"
                    name="FaInfoCircle"
                    style={{ fontSize: 16 }}
                  />
                </span>
                <span
                  style={{
                    position: "absolute",
                    marginTop: 5,
                    lineHeight: "1.8",
                  }}
                >
                  {getCurrentlocaleText("user.verify_email.info.message")}
                </span>
              </span>
            </div>
          )}
        </FormItem>
        {!this.state.isCentralAuth && !this.props.disabledPassword && (
          <FormItem label="Password">
            {getFieldDecorator("password", {
              rules: [
                { required: true, message: "Please enter your password!" },
                { validator: this.validatePassword },
              ],
            })(
              <PasswordInput
                prefix={
                  <Icons
                    type="ai"
                    name="AiOutlineLock"
                    style={{ fontSize: 13 }}
                  />
                }
                type="password"
                placeholder="Password"
                onChange={e => {}}
              />,
            )}
          </FormItem>
        )}
        {!this.state.isCentralAuth && !this.props.disabledPassword && (
          <FormItem label="Confirm Password">
            {getFieldDecorator("confirm_password", {
              rules: [
                { required: true, message: "Please confirm your password!" },
                { validator: this.checkPassword },
              ],
            })(
              <PasswordInput
                prefix={
                  <Icons
                    type="ai"
                    name="AiOutlineLock"
                    style={{ fontSize: 13 }}
                  />
                }
                type="password"
                placeholder="Confirm Password"
                onChange={e => {}}
              />,
            )}
          </FormItem>
        )}
        <FormItem label="Role" hasFeedback>
          {getFieldDecorator("roles", {
            rules: [{ required: true, message: "Please select the role" }],
            initialValue: this.props.initialVal ? this.getInitialRoles() : "",
          })(
            <span>
              <RolesSelect
                setSelectVal={this.setSelectVal}
                initialValue={
                  this.props.initialVal ? this.getInitialRoles() : []
                }
                disabled={this.props.disabledRole}
                params={{
                  org_id: this.props.orgId,
                  own: true,
                }}
              />
              {this.props.action === "edit" &&
                !this.props.disabledRole &&
                this.state.isRoleChanged &&
                this.state.isApiAccessEnabled && (
                  <Alert
                    style={{ marginTop: 10 }}
                    message={getCurrentlocaleText(
                      "user.rolechange.warning.message",
                    )}
                    type="warning"
                    showIcon
                  />
                )}
            </span>,
          )}
        </FormItem>
        {!this.state.orgLoading && (
          <FormItem label="Time Zone" hasFeedback>
            {getFieldDecorator("timezone", {
              initialValue:
                this.props.initialVal && this.props.initialVal.timezone
                  ? this.props.initialVal.timezone
                  : "",
            })(
              <TimezoneComponent
                placeholder="Time Zone"
                initialValue={
                  this.props.initialVal && this.props.initialVal.timezone
                    ? this.props.initialVal.timezone
                    : ""
                }
                selectedValue={this.selectedTimezoneValue}
                style={{}}
                defaultTimezone={"org"}
                defaultTimezoneValue={this.state.orgTimezone}
              />,
            )}
          </FormItem>
        )}
        <FormErrorMessage />
      </Form>
    );
  }
}

const UsersForm = Form.create()(UserForm);
export default UsersForm;
