import React, { Component } from "react";
import { getCurrentlocaleText, isChrome } from "../../Core/Utils";
import { Form } from "@ant-design/compatible";
import Input from "components/UI-Components/InputBox";
import { AutoComplete, Row, Col } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";
import InodeController from "controller/InodeController";
const FormItem = Form.Item;

class LocationInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      mapFeatureResults: [],
      filteredSearchResult: [],
      streetSource: [],
      isNotChrome: false,
    };
  }

  componentDidMount() {
    var chrome = isChrome();
    if (!chrome) {
      this.setState({ isNotChrome: true });
    }
  }

  /**
   * On Search functuin for city input. Based on the search keyword.
   * @param {*} value
   */
  onCitySearch = value => {
    InodeController.fetchGeoLocationInfo(encodeURI(value)).then(response => {
      if (response.features && response.features.length > 0) {
        let mapFeatureResults = response.features;
        let dataSource = [];
        let filteredSearchResult = [];
        mapFeatureResults.forEach(element => {
          filteredSearchResult.push({
            id: element.id,
            name: element.place_name,
          });
          dataSource.push({
            value: element.id,
            label: element.place_name,
            key: element.id,
          });
        });
        this.setState({
          mapFeatureResults: mapFeatureResults,
          dataSource: dataSource,
          filteredSearchResult: filteredSearchResult,
        });
        this.setState({ isCityChosen: true });
      } else {
        this.setState({
          mapFeatureResults: [],
          dataSource: [],
          filteredSearchResult: [],
        });
        if (this.props.initiatedFrom === "new") {
          this.props.form.setFieldsValue({
            state: null,
            country: null,
          });
        }
        this.setState({
          isCityChosen: true,
          disableCountryInput: true,
          disableStateInput: true,
        });
      }
    });
  };

  /**
   * On Select event for city name selection on the form
   * @param {*} value
   */
  onStreetSelect = value => {
    let selectedObject = this.state.mapFeatureResults.filter(list => {
      if (list.id == value) return list;
    });
    if (selectedObject && selectedObject.length > 0) {
      let contextInfo = selectedObject[0].context;
      let filtered_context_value = [];
      let region_value = null;
      let postcode_value = null;
      if (contextInfo.length > 0) {
        contextInfo.forEach(cInfo => {
          filtered_context_value.push(cInfo.text);
          if (cInfo.id.includes("region")) {
            region_value = cInfo.text;
          } else if (cInfo.id.includes("postcode")) {
            postcode_value = cInfo.text;
          }
        });
        // Add region postcode as another value to filter
        if (postcode_value != null && region_value != null) {
          filtered_context_value.push(region_value + " " + postcode_value);
        }
      }
      let place_name = selectedObject[0].place_name
        ? selectedObject[0].place_name
        : null;
      let formated_place_name = "";
      let filtered_place_name = [];
      if (place_name) {
        let place_names = place_name.split(",");
        if (place_names.length > 0) {
          place_names.forEach(place => {
            let places = place.trim();
            if (!filtered_context_value.includes(places)) {
              filtered_place_name.push(places);
            }
          });
        }
      }

      let cityValue = null,
        stateValue = null,
        countryValue = null,
        neigbourHood = null,
        locality = null,
        streetValue = null,
        postcode;

      if (contextInfo && contextInfo.length > 0) {
        contextInfo.forEach((ctxt, index) => {
          if (ctxt.id.includes("region")) {
            stateValue = ctxt.text;
          } else if (ctxt.id.includes("country")) {
            countryValue = ctxt.text;
          } else if (ctxt.id.includes("place")) {
            cityValue = ctxt.text;
          } else if (ctxt.id.includes("neighborhood")) {
            neigbourHood = ctxt.text;
          } else if (ctxt.id.includes("locality")) {
            locality = ctxt.text;
          } else if (ctxt.id.includes("postcode")) {
            postcode = ctxt.text;
          }
        });
        if (cityValue == null) cityValue = selectedObject[0].text;
        if (countryValue == null) countryValue = selectedObject[0].text;
        if (stateValue == null) stateValue = selectedObject[0].text;
        if (neigbourHood != null && locality != null) {
          streetValue = neigbourHood + "," + locality;
        } else {
          if (filtered_place_name.length > 0) {
            if (filtered_place_name.length > 1) {
              formated_place_name = filtered_place_name.join(",");
            } else {
              if (locality) {
                formated_place_name = filtered_place_name[0] + ", " + locality;
              } else {
                formated_place_name = filtered_place_name[0];
              }
            }
          } else {
            formated_place_name = selectedObject[0].text;
          }
          streetValue = formated_place_name;
        }
        this.props.form.setFieldsValue({
          city: cityValue,
          state: stateValue,
          country: countryValue,
          street: streetValue,
          zipcode: postcode ? postcode : null,
        });
      }
      // if there is no context (only country) then it will b filled for three values city/state/country
      else {
        streetValue = selectedObject[0].text;
        this.props.form.setFieldsValue({
          city: cityValue,
          state: stateValue,
          country: countryValue,
          street: streetValue,
        });
        this.setState({ isCityChosen: true });
      }
    }
    // no matches found
    else {
      let contextInfo =
        this.state.mapFeatureResults && this.state.mapFeatureResults.length > 0
          ? this.state.mapFeatureResults[0].context
          : null;
      let mapFeatureResults =
        this.state.mapFeatureResults && this.state.mapFeatureResults.length > 0
          ? this.state.mapFeatureResults[0]
          : null;
      let cityValue = null,
        stateValue = null,
        countryValue = null,
        neigbourHood = null,
        locality = null,
        postcode;
      if (contextInfo && contextInfo.length > 0 && mapFeatureResults) {
        contextInfo.forEach((ctxt, index) => {
          if (ctxt.id.includes("region")) {
            stateValue = ctxt.text;
          } else if (ctxt.id.includes("country")) {
            countryValue = ctxt.text;
          } else if (ctxt.id.includes("place")) {
            cityValue = ctxt.text;
          } else if (ctxt.id.includes("neighborhood")) {
            neigbourHood = ctxt.text;
          } else if (ctxt.id.includes("locality")) {
            locality = ctxt.text;
          } else if (ctxt.id.includes("postcode")) {
            postcode = ctxt.text;
          }
        });
        if (cityValue == null) cityValue = mapFeatureResults.text;
        if (countryValue == null) countryValue = mapFeatureResults.text;
        if (stateValue == null) stateValue = mapFeatureResults.text;

        if (neigbourHood != null && locality != null) {
          streetValue = neigbourHood + "," + locality;
        } else if (neigbourHood != null) {
          streetValue = neigbourHood;
        } else if (locality != null) {
          streetValue = locality;
        }

        this.props.form.setFieldsValue({
          city: cityValue,
          state: stateValue,
          country: countryValue,
          street: streetValue,
          zipcode: postcode ? postcode : null,
        });
        this.setState({ isCityChosen: true });
      } else {
        this.props.form.setFieldsValue({
          state: null,
          country: null,
        });
        this.setState({
          isCityChosen: true,
          disableCountryInput: true,
          disableStateInput: true,
        });
      }
    }
  };

  /**
   * On Search functuin for city input. Based on the search keyword.
   * @param {*} value
   */
  onStreetSearch = value => {
    InodeController.fetchGeoLocationInfo(encodeURI(value)).then(response => {
      if (response.features && response.features.length > 0) {
        let mapFeatureResults = response.features;
        let streetSource = [];
        let filteredSearchResult = [];
        mapFeatureResults.forEach(element => {
          filteredSearchResult.push({
            id: element.id,
            name: element.place_name,
          });
          streetSource.push({
            value: element.id,
            label: element.place_name,
            key: element.id,
          });
        });
        this.setState({
          mapFeatureResults: mapFeatureResults,
          streetSource: streetSource,
          filteredSearchResult: filteredSearchResult,
        });
        this.setState({ isCityChosen: true });
      } else {
        this.setState({
          mapFeatureResults: [],
          streetSource: [],
          filteredSearchResult: [],
        });
        if (this.props.initiatedFrom === "new") {
          this.props.form.setFieldsValue({
            state: null,
            country: null,
          });
        }
        this.setState({
          isCityChosen: true,
          disableCountryInput: true,
          disableStateInput: true,
        });
      }
    });
  };

  /**
   * On Select event for city name selection on the form
   * @param {*} value
   */
  onCitySelect = value => {
    let selectedObject = this.state.mapFeatureResults.filter(list => {
      if (list.id == value) return list;
    });
    if (selectedObject && selectedObject.length > 0) {
      let contextInfo = selectedObject[0].context;

      let cityValue = null,
        stateValue = null,
        countryValue = null;

      if (contextInfo && contextInfo.length > 0) {
        contextInfo.forEach((ctxt, index) => {
          if (ctxt.id.includes("region")) {
            stateValue = ctxt.text;
          } else if (ctxt.id.includes("country")) {
            countryValue = ctxt.text;
          } else if (ctxt.id.includes("place")) {
            cityValue = ctxt.text;
          }
        });
        if (cityValue == null) cityValue = selectedObject[0].text;
        if (countryValue == null) countryValue = selectedObject[0].text;
        if (stateValue == null) stateValue = selectedObject[0].text;
      }
      // if there is no context (only country) then it will b filled for three values city/state/country
      else {
        cityValue = selectedObject[0].text;
        stateValue = selectedObject[0].text;
        countryValue = selectedObject[0].text;
      }
      this.props.form.setFieldsValue({
        city: cityValue,
        state: stateValue,
        country: countryValue,
      });
      this.setState({ isCityChosen: true });
    }
    // no matches found
    else {
      let contextInfo =
        this.state.mapFeatureResults && this.state.mapFeatureResults.length > 0
          ? this.state.mapFeatureResults[0].context
          : null;
      let mapFeatureResults =
        this.state.mapFeatureResults && this.state.mapFeatureResults.length > 0
          ? this.state.mapFeatureResults[0]
          : null;
      let cityValue = null,
        stateValue = null,
        countryValue = null;
      if (contextInfo && contextInfo.length > 0 && mapFeatureResults) {
        contextInfo.forEach((ctxt, index) => {
          if (ctxt.id.includes("region")) {
            stateValue = ctxt.text;
          } else if (ctxt.id.includes("country")) {
            countryValue = ctxt.text;
          } else if (ctxt.id.includes("place")) {
            cityValue = ctxt.text;
          }
        });
        if (cityValue == null) cityValue = mapFeatureResults.text;
        if (countryValue == null) countryValue = mapFeatureResults.text;
        if (stateValue == null) stateValue = mapFeatureResults.text;

        this.props.form.setFieldsValue({
          city: cityValue,
          state: stateValue,
          country: countryValue,
        });
        this.setState({ isCityChosen: true });
      } else {
        this.props.form.setFieldsValue({
          state: null,
          country: null,
        });
        this.setState({
          isCityChosen: true,
          disableCountryInput: true,
          disableStateInput: true,
        });
      }
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <FormItem
          label={getCurrentlocaleText("location.street.label.text")}
          hasFeedback
        >
          {getFieldDecorator("street", {
            rules: [
              {
                required: true,
                message: getCurrentlocaleText(
                  "location.street.mandatory.error.message",
                ),
                whitespace: true,
              },
              {
                max: 100,
                message: getCurrentlocaleText(
                  "location.street.maxlength.error.message",
                ),
              },
            ],
          })(
            <AutoComplete
              options={this.state.streetSource}
              onSelect={this.onStreetSelect}
              onSearch={this.onStreetSearch}
              prefix={<EnvironmentOutlined />}
              autoComplete={"off"}
              defaultActiveFirstOption={true}
              placeholder={getCurrentlocaleText("location.street.label.text")}
            ></AutoComplete>,
          )}
        </FormItem>
        <Row gutter={0} type="flex" justify="start">
          <Col span={11}>
            <FormItem
              label={getCurrentlocaleText("location.city.label.text")}
              hasFeedback
            >
              {getFieldDecorator("city", {
                rules: [
                  {
                    required: true,
                    message: getCurrentlocaleText(
                      "location.city.mandatory.error.message",
                    ),
                    whitespace: true,
                  },
                  {
                    pattern: /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/,
                    message: "City is not valid",
                  },
                  {
                    max: 100,
                    message: getCurrentlocaleText(
                      "location.city.maxlength.error.message",
                    ),
                  },
                ],
              })(
                <AutoComplete
                  options={this.state.dataSource}
                  onSelect={this.onCitySelect}
                  onSearch={this.onCitySearch}
                  autoComplete={"off"}
                  defaultActiveFirstOption={true}
                  placeholder="Search your City here"
                ></AutoComplete>,
              )}
            </FormItem>
          </Col>
          <Col span={11} push={2}>
            <FormItem
              label={getCurrentlocaleText("location.state.label.text")}
              hasFeedback
            >
              {getFieldDecorator("state", {
                rules: [
                  {
                    required: true,
                    message: getCurrentlocaleText(
                      "location.state.mandatory.error.message",
                    ),
                    whitespace: true,
                  },
                  {
                    pattern: /^[a-zA-Z]([a-zA-Z']+)+(\s[a-zA-Z']+)*$/,
                    message: getCurrentlocaleText(
                      "location.state.invalid.error.message",
                    ),
                  },
                  {
                    max: 100,
                    message: getCurrentlocaleText(
                      "location.state.maxlength.error.message",
                    ),
                  },
                ],
              })(
                <Input
                  autoComplete="off"
                  placeholder={getCurrentlocaleText(
                    "location.state.label.text",
                  )}
                />,
              )}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={11}>
            <FormItem
              label={getCurrentlocaleText("location.country.label.text")}
              hasFeedback
            >
              {getFieldDecorator("country", {
                rules: [
                  {
                    required: true,
                    message: getCurrentlocaleText(
                      "location.country.mandatory.error.message",
                    ),
                    whitespace: true,
                  },
                  {
                    pattern: /^[a-zA-Z]([a-zA-Z']+)+(\s[a-zA-Z']+)*$/,
                    message: "Country is not valid",
                  },
                  {
                    max: 100,
                    message: getCurrentlocaleText(
                      "location.country.maxlength.error.message",
                    ),
                  },
                ],
              })(
                <Input
                  autoComplete="off"
                  placeholder={getCurrentlocaleText(
                    "location.country.label.text",
                  )}
                />,
              )}
            </FormItem>
          </Col>
          <Col span={11} push={2}>
            <FormItem
              label={getCurrentlocaleText("location.zipcode.label.text")}
              hasFeedback
            >
              {getFieldDecorator("zipcode", {
                rules: [
                  {
                    required: true,
                    message: getCurrentlocaleText(
                      "location.zipcode.mandatory.error.message",
                    ),
                    whitespace: true,
                  },
                  {
                    pattern: /^[A-Za-z0-9-]*$/,
                    message: "Zipcode is not valid",
                  },
                  {
                    max: 20,
                    message: getCurrentlocaleText(
                      "location.zipcode.maxlength.error.message",
                    ),
                  },
                ],
              })(
                <Input
                  autoComplete="off"
                  placeholder={getCurrentlocaleText(
                    "location.zipcode.label.text",
                  )}
                />,
              )}
            </FormItem>
          </Col>
        </Row>
      </div>
    );
  }
}

export default LocationInput;
