import React, { Component } from "react";
import Icons from "components/UI-Components/Icons";
import { Icon } from "antd";
class DiodeIcon extends Component {
  render() {
    return (
      <span className={"diode-span"}>
        <Icons
          type={this.props.isVirtualNode ? "md" : "fa"}
          name={this.props.isVirtualNode ? "MdCloudQueue" : "FaRegHdd"}
          className={"baseline iot-diode-icon-style"}
        />{" "}
        {this.props.direction === "comparearrows" ? (
          <span style={{ color: "green" }}>
            <Icons
              type="ai"
              name="AiOutlineArrowLeft"
              style={{ marginLeft: 6, marginRight: -4, fontSize: 13 }}
            />
            <Icons
              type="ai"
              name="AiOutlineArrowRight"
              style={{ fontSize: 13 }}
            />
          </span>
        ) : this.props.direction === "keyboardbackspace" ? (
          <Icons
            type="ai"
            name="AiOutlineArrowRight"
            className="iot-diode-arrow-icon-style"
          />
        ) : (
          <Icons
            type="ai"
            name="AiOutlineArrowLeft"
            className="iot-diode-arrow-icon-style"
          />
        )}
        <Icons
          type={this.props.isVirtualNode ? "fa" : "md"}
          name={this.props.isVirtualNode ? "FaRegHdd" : "MdCloudQueue"}
          className={"baseline iot-diode-cloud-icon-style"}
        />
      </span>
    );
  }
}
export default DiodeIcon;
