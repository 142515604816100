import React, { Component } from "react";
import { Row } from "antd";
import DarkLogo from "components/UI-Components/DarkLogo";
import DarkAppName from "components/UI-Components/DarkAppName";
class DarkAppInfo extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Row
        type="flex"
        align="middle"
        justify="center"
        style={{ display: "grid" }}
      >
        <DarkLogo {...this.props} />
        <DarkAppName {...this.props} />
      </Row>
    );
  }
}

export default DarkAppInfo;
