import React, { Component } from "react";
import { Row, Col, Icon, Select, Button } from "antd";
import { Form } from "@ant-design/compatible";
import { observer, inject } from "mobx-react";

import ServicePortForm from "components/Forms/ServicePortForm";
import AllowAccessFromForm from "components/Forms/AllowAccessFromForm";
import Input from "components/UI-Components/InputBox";
import { customSort, focusFirstInput, getCurrentlocaleText } from "Core/Utils";
import LabelProvider from "components/UI-Components/LabelProvider";

import OrgController from "controller/OrgController";
import ServiceController from "controller/ServiceController";
import HelpCard from "../UI-Components/HelpCard";
import InodeController from "../../controller/InodeController";
import Icons from "../UI-Components/Icons";

const FormItem = Form.Item;
const Option = Select.Option;
const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
@inject(
  "UiStore",
  "AuthStore",
  "ServiceViewModel",
  "ServiceListenerViewModel",
  "InodeViewModel",
)
@observer
class ListenerFormPage extends Component {
  constructor(props) {
    super(props);
    this.serviceViewModel = this.props.ServiceViewModel;
    this.listenerModel = this.props.ServiceListenerViewModel;
    this.currentTime = new Date().getTime();
    this.state = {
      selectedVnode: null,
      tags: [],
      availableServices: [],
      availableServiceSelectors: [],
      service_selector_validate_state: "",
      service_selector_options: [],
      selectedSelectors: [],
      type: "NodePort",
      isDisabled: false,
      availableListeners: [],
      service_selector: [],
      allow_access_from: [],
      service: null,
      listenerId: null,
      service_ports: [
        {
          key: this.currentTime.toString(),
          protocol: null,
          port: null,
          node_port: null,
          isdeleted: false,
        },
      ],
      DirectionSpecifiedPorts: [],
      nodeId: this.props.match.params.nodeId || this.props.match.params.id,
    };

    this.type = [{ value: "NodePort", name: "NodePort" }];
  }
  componentWillUnmount() {}

  UNSAFE_componentWillReceiveProps(nextprops) {
    if (JSON.stringify(nextprops) != JSON.stringify(this.props)) {
      this.setState(
        {
          availableListeners: nextprops.availableListener,
          listenerId: nextprops.listenerId,
        },
        () => {
          this.updateForm(nextprops);
        },
      );
    }
  }
  UNSAFE_componentWillMount() {
    this.props.UiStore.SetStoreData("helpData", "");
    if (this.props.InodeViewModel && !this.props.InodeViewModel.inode) {
      InodeController.getInode(this.state.nodeId, true, true);
    }
  }

  getSelectorValues = selectors => {
    let Selectedselectors = [];
    if (selectors.name) {
      delete selectors.name;
    }
    for (let key in selectors) {
      if (key && key.charAt(0) != "_")
        Selectedselectors.push(key + " : " + selectors[key]);
    }
    return Selectedselectors;
  };
  componentDidMount() {
    focusFirstInput();
    this.setState({
      availableListeners: this.props.availableListener,
      listenerId: this.props.listenerId,
    });
    this.getServices();
  }
  getServices = () => {
    let services = this.serviceViewModel.services;
    if (!services || services.length === 0) {
      setTimeout(() => {
        this.serviceList();
      }, 100);
      services = this.serviceViewModel.services;
      let servicesIdName = [];
      services.forEach(val => {
        let service = {};
        if (val.spec && val.spec.system_service != true) {
          service.id = val.id;
          service.name = val.name;
          servicesIdName.push(service);
        }
      });
      let service_selectors = this.listServiceSerlectorData(servicesIdName);
      this.setState({
        availableServices: services,
        availableServiceSelectors: servicesIdName,
        service_selector_options: service_selectors,
      });
    }
  };
  serviceList = () => {
    ServiceController.getServicesForiNodes([this.props.nodeId]).then(resp => {
      let services = this.serviceViewModel.services;
      let servicesIdName = [];
      let exist = false;
      services.forEach(val => {
        if (
          this.state.service_selector &&
          this.state.service_selector.length > 0 &&
          val.name === this.state.service_selector[0]
        ) {
          exist = true;
        }
        let service = {};
        if (val.spec && val.spec.system_service != true) {
          service.id = val.id;
          service.name = val.name;
          servicesIdName.push(service);
        }
      });
      if (
        !exist &&
        this.state.service_selector &&
        this.state.service_selector.length > 0
      ) {
        let service = {};
        service.id = this.state.service_selector[0];
        service.name = this.state.service_selector[0];
        servicesIdName.push(service);
      }
      let service_selectors = this.listServiceSerlectorData(servicesIdName);
      this.setState({
        availableServices: services,
        availableServiceSelectors: servicesIdName,
        service_selector_options: service_selectors,
      });
    });
  };
  getorgInfo = () => {
    OrgController.getOrgById(this.props.orgId, true).then(response => {
      if (response) {
        this.setState({ orgInitialVal: response });
      }
    });
  };

  updateForm = props => {
    let formData = props.formData;
    let service_ports = [];
    if (
      formData &&
      formData.service_ports &&
      formData.service_ports.length > 0
    ) {
      formData.service_ports.forEach((port, i) => {
        port.key = new Date().getTime().toString() + i;
        port.node_port = port.node_port ? port.node_port.toString() : "";
        port.port = port.port ? port.port.toString() : "";
        service_ports.push(port);
      });
    }
    this.props.form.setFields({
      name: {
        value: formData.name,
      },
      type: {
        value: formData.type,
      },
      service_selector: {
        value: formData.service_selector
          ? formData.service_selector.name
          : undefined,
      },
      allow_access_from: {
        value: formData.allow_access_from
          ? formData.allow_access_from
          : this.props.action === "view" && props.listenerId
          ? ["0.0.0.0/0"]
          : undefined,
      },
      service_ports: {
        value: service_ports,
      },
    });

    // View form
    if (this.props.action === "view" && this.props.formData) {
      this.props.form.setFields({
        name: {
          value: formData.name,
        },
        type: {
          value: formData.type,
        },
        service_ports: {
          value: service_ports,
        },
        allow_access_from: {
          value: formData.allow_access_from
            ? formData.allow_access_from
            : this.props.action === "view" && props.listenerId
            ? ["0.0.0.0/0"]
            : undefined,
        },
        service_selector: {
          value: formData.service_selector
            ? formData.service_selector.name
            : undefined,
        },
      });
    }
    this.props.form.setFields({
      type: {
        value: this.state.type,
      },
    });
    this.setState({
      allow_access_from: formData.allow_access_from
        ? formData.allow_access_from
        : this.props.action === "view" && props.listenerId
        ? ["0.0.0.0/0"]
        : undefined,
      service_selector: formData.service_selector
        ? [formData.service_selector.name]
        : null,
      service: formData.service,
      service_ports: service_ports,
    });
    this.isDisabled();
  };
  getTagKeyValues = labels => {
    let tags = [];
    if (labels.name) {
      delete labels.name;
    }
    for (let key in labels) {
      if (key && key.charAt(0) != "_") tags.push(key + " : " + labels[key]);
    }
    return tags;
  };
  /**
   *
   */
  updateSelectedKeys = values => {
    let selectedLabels = [];
    if (values.length > 0) {
      values.forEach(val => {
        selectedLabels.push(val.split(":")[0].trim());
      });
    }
    return selectedLabels;
  };
  submitForm = () => {
    this.validateForm();
  };
  validateForm = () => {
    let service_selector = {};
    if (this.state.service_selector) {
      service_selector.name =
        this.state.service_selector && this.state.service_selector[0]
          ? this.state.service_selector[0]
          : null;
    }

    if (this.props.formData) {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          if (
            !this.state.service_ports ||
            this.state.service_ports.length === 0
          ) {
            this.props.UiStore.setErrorMessage(
              getCurrentlocaleText(
                "service_listener.service_ports.empty.error",
              ),
            );
            return;
          }
          values = {
            name: values.name,
            service_ports: this.state.service_ports,
            service_selector: service_selector,
            type: this.state.type,
            allow_access_from: this.state.allow_access_from,
          };
          this.props.onSubmit(values);
          this.setState({ formValues: values });
        }
        return values;
      });
    } else {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          if (
            !this.state.service_ports ||
            this.state.service_ports.length === 0
          ) {
            this.props.UiStore.setErrorMessage(
              getCurrentlocaleText(
                "service_listener.service_ports.empty.error",
              ),
            );
            return;
          }
          this.serviceViewModel.loading = true;
          values = {
            name: values.name,
            service_ports: this.state.service_ports,
            service_selector: service_selector,
            allow_access_from: this.state.allow_access_from,
            type: this.state.type,
          };
          this.props.onSubmit(values);
          this.setState({ formValues: values });
          return values;
        }
      });
    }
  };

  /**
   * Handle On Deselect
   */
  handleOnDeselect = value => {
    this.clearUnselectedLabels(
      value,
      this.state.service_selector,
      this.state.availableServiceSelectors,
    );
    let index1;
    this.state.service_selector_options.forEach((available, i) => {
      if (available.name === value) {
        index1 = i;
      }
    });
    let availableTag = this.state.service_selector_options;

    if (index1 != -1) {
      availableTag.splice(index1);
      this.setState({ service_selector_options: availableTag });
    }
  };
  /**
   * Check for Unselected Labels
   */
  clearUnselectedLabels = (
    value,
    selectedSelectors,
    availableServiceSelectors,
  ) => {
    let key = value.trim(),
      index;
    let found = false;
    this.state.availableServices.forEach(service => {
      if (service.name === value) {
        found = true;
      }
    });
    availableServiceSelectors.forEach((available, i) => {
      if (available.name === value && !found) {
        index = i;
      }
    });
    if (index >= 0) {
      availableServiceSelectors.splice(index, 1);
    }
    if (selectedSelectors) {
      selectedSelectors.splice(selectedSelectors.indexOf(key), 1);
    }
    return {
      service_selector: selectedSelectors,
      available_selectors: availableServiceSelectors,
    };
  };
  /**
   * Method to handel change
   */
  handleChange = values => {};

  isDisabled = () => {
    if (this.props.action === "view") this.setState({ isDisabled: true });
    else if (this.props.isVirtualNode) this.setState({ isDisabled: true });
    else this.setState({ isDisabled: false });
  };

  resetHelp = () => {
    this.props.UiStore.SetStoreData("helpData", {});
  };

  updateHelp = data => {
    this.props.UiStore.SetStoreData("helpData", data);
  };

  listServiceSerlectorData = datas => {
    return (
      datas &&
      datas
        .sort((x, y) => {
          return customSort(x.name, y.name);
        })
        .map((val, index) => {
          return (
            <Option key={val.name} title={val.name} value={val.name}>
              {val.name}
            </Option>
          );
        })
    );
  };
  selectedServiceSelector = value => {
    this.props.form.setFields({
      service_selector: {
        value: value,
      },
    });
    this.props.form.setFields({
      name: {
        value: value.length > 0 ? value[0] : undefined,
      },
    });
    this.setState({ service_selector: value });
    let services = this.state.availableServiceSelectors;
    value.forEach((valueFinal, i) => {
      let existingServiceSelector = false;

      this.state.availableServiceSelectors.forEach(service => {
        if (
          valueFinal !== null &&
          (valueFinal === service.name || valueFinal === service.id)
        ) {
          existingServiceSelector = true;
        }
      });
      if (existingServiceSelector === false) {
        let service = {};
        service.id = new Date().getTime().toString();
        service.name = valueFinal;
        services.push(service);
      }
    });

    this.setState({
      availableServiceSelectors: services,
      service_selector_options: this.listServiceSerlectorData(services),
    });
  };

  setServicePorts = ports => {
    this.setState({ service_ports: ports });
  };
  setAllowAccessFrom = val => {
    this.setState({ allow_access_from: val });
  };

  /**-----------------------------------------------------------------------------**/
  checkForServiceSelector = (rule, value, callback) => {
    let failMessage = null;
    if (value && value.length > 0) {
      if (value.length === 0) {
        failMessage = getCurrentlocaleText(
          "service_listener.service_selector.must.warning.text",
        );

        this.setState({
          service_selector_validate_state: "error",
          validateMessage: getCurrentlocaleText(
            "service_listener.service_selector.must.warning.text",
          ),
        });
      } else if (typeof value != "string" && value.length > 1) {
        failMessage = getCurrentlocaleText(
          "service_listener.service_selector.maximum_limit.warning.text",
        );
        this.setState({
          service_selector_validate_state: "error",
          validateMessage: getCurrentlocaleText(
            "service_listener.service_selector.maximum_limit.warning.text",
          ),
        });
      } else {
        this.setState({
          service_selector_validate_state: "",
          validateMessage: "",
        });
      }
    }
    if (failMessage) {
      callback(failMessage);
    }
    callback();
  };

  getSelectorErrorMessage = () => {
    this.setState({
      service_selector_validate_state: "error",
      validateMessage: getCurrentlocaleText(
        "service_listener.service_selector.must.warning.text",
      ),
    });
    return getCurrentlocaleText(
      "service_listener.service_selector.must.warning.text",
    );
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form
        noValidate
        onSubmit={this.props.onSubmit}
        autoComplete="off"
        className="listener-form"
      >
        <Row gutter={2} type="flex" align="top">
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 13 }}
            lg={{ span: 13 }}
            xl={{ span: 12 }}
            className="card-grid"
            style={{
              paddingRight: 20,
              borderRight: "solid 1px rgba(0,0,0,0.2)",
            }}
          >
            <FormItem
              label={
                <span
                  title={getCurrentlocaleText(
                    "service_listener.service_selector.label.text",
                  )}
                >
                  {getCurrentlocaleText(
                    "service_listener.service_selector.label.text",
                  )}
                </span>
              }
              {...formItemLayout}
              validateStatus={this.state.service_selector_validate_state}
              help={this.state.validateMessage}
            >
              {getFieldDecorator("service_selector", {
                rules: [
                  {
                    required: true,
                    message: this.getSelectorErrorMessage,
                  },
                  {
                    validator: this.checkForServiceSelector,
                  },
                ],
                initialValue: this.state.service_selector,
              })(
                <Select
                  className="firstInput"
                  mode="tags"
                  style={{ width: "100%" }}
                  disabled={
                    this.props.viewOnly || this.state.listenerId ? true : false
                  }
                  onFocus={() => {
                    this.updateHelp({
                      title: getCurrentlocaleText(
                        "service_listener.service_selector.help.title",
                      ),
                      data: [
                        {
                          subtitle: "",
                          content: getCurrentlocaleText(
                            "service_listener.service_selector.help.message",
                          ),
                        },
                      ],
                    });
                  }}
                  placeholder={getCurrentlocaleText(
                    "service_listener.service_selector.placeholder.text",
                  )}
                  onChange={this.selectedServiceSelector}
                  filterOption={(input, option) =>
                    option.props.title
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onDeselect={this.handleOnDeselect}
                  notFoundContent={getCurrentlocaleText(
                    "general.notfound.placeholder.text",
                  )}
                >
                  {this.state.service_selector_options}
                </Select>,
              )}
            </FormItem>

            <FormItem
              label={getCurrentlocaleText("service_listener.name.text")}
              {...formItemLayout}
            >
              {getFieldDecorator("name", {
                rules: [
                  {
                    required: true,
                    message: getCurrentlocaleText(
                      "service_listener.service.must.warning.text",
                    ),
                  },
                  {
                    min: 2,
                    max: 30,
                    message: getCurrentlocaleText(
                      "service_listener.form.name.errormessage2.text",
                    ),
                  },
                  {
                    pattern: /^[A-Za-z0-9 ._-]*$/,
                    message: getCurrentlocaleText(
                      "service_listener.form.name.errormessage3.text",
                    ),
                  },
                ],
              })(
                <Input
                  prefix={<Icons type="ai" name="AiOutlineIdcard" />}
                  placeholder={getCurrentlocaleText(
                    "service_listener.form.name.placeholder.text",
                  )}
                  disabled={this.props.viewOnly || this.state.isDisabled}
                  title={getCurrentlocaleText(
                    "service_listener.form.name.placeholder.text",
                  )}
                  onFocus={this.updateHelp.bind(this, {
                    title: getCurrentlocaleText(
                      "service_listener.name.help.title",
                    ),
                    data: [
                      {
                        subtitle: "",
                        content: getCurrentlocaleText(
                          "service_listener.name.help.content",
                        ),
                      },
                    ],
                  })}
                />,
              )}
            </FormItem>
          </Col>
          <Col
            xs={{ span: 0 }}
            sm={{ span: 0 }}
            md={{ span: 11 }}
            lg={{ span: 11 }}
            xl={{ span: 12 }}
          >
            <HelpCard
              customStyle={{
                borderLeft: "none",
              }}
              span={24}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 24 }}>
          <Col span={24}>
            <ServicePortForm
              {...this.props}
              servicePorts={this.state.service_ports}
              isCollapsible={true}
              setServicePorts={this.setServicePorts}
              viewOnly={this.props.viewOnly || this.state.isVirtualNode}
              isVirtualNode={this.state.isVirtualNode}
            />
          </Col>
        </Row>

        <Row style={{ marginTop: 24 }}>
          <Col span={24}>
            <AllowAccessFromForm
              {...this.props}
              allowAccessFroms={this.state.allow_access_from}
              isCollapsible={true}
              setAllowAccessFrom={this.setAllowAccessFrom}
              viewOnly={this.props.viewOnly || this.state.isVirtualNode}
              isVirtualNode={this.state.isVirtualNode}
              onFocus={this.updateHelp.bind(this, {
                title: getCurrentlocaleText(
                  "service_listener.allow_access_from.text",
                ),
                data: [
                  {
                    subtitle: "",
                    content: getCurrentlocaleText(
                      "service_listener.allow_access_from.cidr.help.content",
                    ),
                  },
                ],
              })}
            />
          </Col>
        </Row>

        {/* <Button className="hidden-submit" htmlType="submit" /> */}
        <Row>
          <Col span={24} className="col-center">
            <div style={{ paddingTop: 15 }}>
              <Button
                onClick={this.props.onCancel}
                style={{ marginRight: 10 }}
                key="cancel"
              >
                {getCurrentlocaleText(
                  "service_listener.form.button.cancel.text",
                )}
              </Button>
              {!this.props.viewOnly && (
                <Button
                  type="primary"
                  onSubmit={this.submitForm}
                  onClick={this.submitForm}
                  loading={this.props.isloading}
                >
                  <Icons type="ai" name="AiOutlineSave" style={{ margin: 0 }} />
                  {this.state.listenerId
                    ? getCurrentlocaleText(
                        "service_listener.form.button.update.text",
                      )
                    : getCurrentlocaleText(
                        "service_listener.form.button.submit.text",
                      )}
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}
const ListenersFormPage = Form.create()(ListenerFormPage);
export default ListenersFormPage;
