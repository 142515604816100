import React, { Component } from "react";
import { Icon } from "antd";
import propTypes from "prop-types";
import {
  FaBarcode,
  FaBell,
  FaBook,
  FaChartLine,
  FaChevronCircleDown,
  FaChevronCircleRight,
  FaCogs,
  FaConnectdevelop,
  FaDesktop,
  FaDownload,
  FaExclamationCircle,
  FaExclamationTriangle,
  FaEye,
  FaEyeSlash,
  FaFire,
  FaHome,
  FaInfoCircle,
  FaKey,
  FaLinux,
  FaListAlt,
  FaPlus,
  FaQrcode,
  FaRegCalendarCheck,
  FaRegCalendar,
  FaRegClock,
  FaRegFileArchive,
  FaRegHdd,
  FaSearch,
  FaSpinner,
  FaShieldAlt,
  FaSignOutAlt,
  FaSitemap,
  FaTicketAlt,
  FaTimesCircle,
  FaUser,
  FaWindows,
  FaSquare,
  FaStar,
  FaCaretUp,
  FaCog,
  FaMemory,
  FaBoxOpen,
  FaCheck,
  FaStarOfLife,
  FaWrench,
  FaBan,
  FaAngleDoubleRight,
  FaToolbox,
  FaExternalLinkAlt,
  FaRocket,
} from "react-icons/fa";
import {
  MdInfoOutline,
  MdHeadsetMic,
  MdAccountCircle,
  MdCardMembership,
  MdGroup,
  MdHelp,
  MdPerson,
  MdRefresh,
  MdSend,
  MdSettings,
  MdCloudQueue,
  MdArrowForward,
  MdKeyboardBackspace,
  MdCompareArrows,
  MdDevicesOther,
  MdSettingsEthernet,
  MdSimCard,
  MdWifi,
  MdDeviceHub,
  MdDomain,
  MdPhonelinkLock,
  MdPhonelinkErase,
  MdMoreVert,
  MdDone,
  MdHighlightOff,
  MdEmail,
  MdError,
  MdErrorOutline,
  MdTextFormat,
  MdBrightness5,
  MdBrightness2,
  MdVpnKey,
  MdMemory,
  MdEventNote,
  MdEvent,
} from "react-icons/md";

import { GoSync, GoTools, GoAlert } from "react-icons/go";
import {
  IoIosArrowRoundDown,
  IoIosArrowRoundUp,
  IoIosArrowForward,
  IoIosArrowBack,
  IoIosSend,
} from "react-icons/io";

import {
  BsBoundingBox,
  BsArrowLeftRight,
  BsArrowUpRight,
  BsCircleFill,
} from "react-icons/bs";
import {
  HiOutlineDocumentDuplicate,
  HiOutlineShoppingBag,
} from "react-icons/hi";
import {
  AiOutlineIdcard,
  AiOutlineSolution,
  AiOutlineDelete,
  AiOutlineEye,
  AiOutlineUser,
  AiOutlineUserAdd,
  AiOutlineMail,
  AiOutlineLock,
  AiOutlineCheckCircle,
  AiOutlineEdit,
  AiOutlineCheckSquare,
  AiOutlineCheck,
  AiOutlineGlobal,
  AiOutlinePlusSquare,
  AiOutlineMinusCircle,
  AiOutlineSchedule,
  AiOutlineFilter,
  AiOutlineReload,
  AiOutlineHome,
  AiOutlinePlus,
  AiOutlineMinus,
  AiOutlineFileSync,
  AiOutlineExport,
  AiOutlineFileText,
  AiOutlineKey,
  AiOutlineMinusSquare,
  AiOutlineSave,
  AiOutlineDown,
  AiOutlineExclamationCircle,
  AiOutlineFund,
  AiFillExclamationCircle,
  AiOutlineClose,
  AiOutlineCloseCircle,
  AiOutlineSync,
  AiOutlineDownload,
  AiOutlineLoading,
  AiOutlineTag,
  AiOutlineCalculator,
  AiOutlineFork,
  AiOutlineFullscreen,
  AiOutlineFrown,
  AiOutlineRollback,
  AiOutlineQrcode,
  AiOutlineDatabase,
  AiOutlineArrowRight,
  AiOutlineWifi,
  AiOutlineApi,
  AiOutlineArrowLeft,
  AiOutlineUpload,
  AiOutlineUsb,
  AiOutlineClockCircle,
  AiOutlineLogout,
  AiOutlineArrowUp,
  AiOutlineArrowDown,
  AiOutlineDisconnect,
  AiOutlineException,
  AiFillCaretUp,
  AiFillCaretDown,
  AiOutlineLeft,
  AiOutlineRight,
  AiOutlineCopy,
  AiOutlineLoading3Quarters,
  AiOutlineSearch,
  AiOutlineLogin,
  AiOutlinePlusCircle,
  AiOutlineSwitcher,
  AiOutlineShopping,
  AiFillTool,
} from "react-icons/ai";
import { GrDocumentDownload } from "react-icons/gr";
import { TbWorldPin } from "react-icons/tb";

const FontAwesome = {
  FaBarcode: FaBarcode,
  FaBell: FaBell,
  FaBook: FaBook,
  FaChartLine: FaChartLine,
  FaChevronCircleDown: FaChevronCircleDown,
  FaChevronCircleRight: FaChevronCircleRight,
  FaCogs: FaCogs,
  FaConnectdevelop: FaConnectdevelop,
  FaDesktop: FaDesktop,
  FaDownload: FaDownload,
  FaExclamationCircle: FaExclamationCircle,
  FaExclamationTriangle: FaExclamationTriangle,
  FaEye: FaEye,
  FaEyeSlash: FaEyeSlash,
  FaFire: FaFire,
  FaHome: FaHome,
  FaInfoCircle: FaInfoCircle,
  FaKey: FaKey,
  FaLinux: FaLinux,
  FaListAlt: FaListAlt,
  FaPlus: FaPlus,
  FaQrcode: FaQrcode,
  FaRegCalendarCheck: FaRegCalendarCheck,
  FaRegCalendar: FaRegCalendar,
  FaRegClock: FaRegClock,
  FaRegFileArchive: FaRegFileArchive,
  FaRegHdd: FaRegHdd,
  FaSearch: FaSearch,
  FaSpinner: FaSpinner,
  FaShieldAlt: FaShieldAlt,
  FaSignOutAlt: FaSignOutAlt,
  FaSitemap: FaSitemap,
  FaTicketAlt: FaTicketAlt,
  FaTimesCircle: FaTimesCircle,
  FaUser: FaUser,
  FaWindows: FaWindows,
  FaSquare: FaSquare,
  FaStar: FaStar,
  FaCaretUp: FaCaretUp,
  FaCog: FaCog,
  FaMemory: FaMemory,
  FaBoxOpen: FaBoxOpen,
  FaCheck: FaCheck,
  FaStarOfLife: FaStarOfLife,
  FaWrench: FaWrench,
  FaBan: FaBan,
  FaAngleDoubleRight: FaAngleDoubleRight,
  FaToolbox: FaToolbox,
  FaExternalLinkAlt: FaExternalLinkAlt,
  FaRocket: FaRocket,
};
const Ionicons = {
  IoIosArrowRoundDown: IoIosArrowRoundDown,
  IoIosArrowRoundUp: IoIosArrowRoundUp,
  IoIosArrowBack: IoIosArrowBack,
  IoIosArrowForward: IoIosArrowForward,
  IoIosSend: IoIosSend,
};
const MaterialDesignIcons = {
  MdInfoOutline: MdInfoOutline,
  MdHeadsetMic: MdHeadsetMic,
  MdAccountCircle: MdAccountCircle,
  MdCardMembership: MdCardMembership,
  MdGroup: MdGroup,
  MdHelp: MdHelp,
  MdPerson: MdPerson,
  MdRefresh: MdRefresh,
  MdSend: MdSend,
  MdSettings: MdSettings,
  MdCloudQueue: MdCloudQueue,
  MdArrowForward: MdArrowForward,
  MdKeyboardBackspace: MdKeyboardBackspace,
  MdCompareArrows: MdCompareArrows,
  MdDevicesOther: MdDevicesOther,
  MdSettingsEthernet: MdSettingsEthernet,
  MdSimCard: MdSimCard,
  MdWifi: MdWifi,
  MdDeviceHub: MdDeviceHub,
  MdDomain: MdDomain,
  MdPhonelinkLock: MdPhonelinkLock,
  MdPhonelinkErase: MdPhonelinkErase,
  MdMoreVert: MdMoreVert,
  MdDone: MdDone,
  MdHighlightOff: MdHighlightOff,
  MdEmail: MdEmail,
  MdError: MdError,
  MdErrorOutline: MdErrorOutline,
  MdTextFormat: MdTextFormat,
  MdBrightness5: MdBrightness5,
  MdBrightness2: MdBrightness2,
  MdVpnKey: MdVpnKey,
  MdMemory: MdMemory,
  MdEventNote: MdEventNote,
  MdEvent: MdEvent,
};

const Typicons = {};
const GithubOcticons = { GoSync: GoSync, GoTools: GoTools, GoAlert: GoAlert };
const Feather = {};
const GameIcons = {};
const WeatherIcons = {};
const Devicons = {};
const HeroIcons = {
  HiOutlineDocumentDuplicate: HiOutlineDocumentDuplicate,
  HiOutlineShoppingBag: HiOutlineShoppingBag,
};
const AntDesignIcons = {
  AiOutlineIdcard: AiOutlineIdcard,
  AiOutlineSolution: AiOutlineSolution,
  AiOutlineDelete: AiOutlineDelete,
  AiOutlineEye: AiOutlineEye,
  AiOutlineUser: AiOutlineUser,
  AiOutlineUserAdd: AiOutlineUserAdd,
  AiOutlineMail: AiOutlineMail,
  AiOutlineLock: AiOutlineLock,
  AiOutlineCheckCircle: AiOutlineCheckCircle,
  AiOutlineEdit: AiOutlineEdit,
  AiOutlineCheckSquare: AiOutlineCheckSquare,
  AiOutlineCheck: AiOutlineCheck,
  AiOutlineGlobal: AiOutlineGlobal,
  AiOutlinePlusSquare: AiOutlinePlusSquare,
  AiOutlineMinusCircle: AiOutlineMinusCircle,
  AiOutlineSchedule: AiOutlineSchedule,
  AiOutlineFilter: AiOutlineFilter,
  AiOutlineReload: AiOutlineReload,
  AiOutlineHome: AiOutlineHome,
  AiOutlinePlus: AiOutlinePlus,
  AiOutlineMinus: AiOutlineMinus,
  AiOutlineFileSync: AiOutlineFileSync,
  AiOutlineExport: AiOutlineExport,
  AiOutlineFileText: AiOutlineFileText,
  AiOutlineKey: AiOutlineKey,
  AiOutlineMinusSquare: AiOutlineMinusSquare,
  AiOutlineSave: AiOutlineSave,
  AiOutlineDown: AiOutlineDown,
  AiOutlineExclamationCircle: AiOutlineExclamationCircle,
  AiOutlineFund: AiOutlineFund,
  AiFillExclamationCircle: AiFillExclamationCircle,
  AiOutlineClose: AiOutlineClose,
  AiOutlineCloseCircle: AiOutlineCloseCircle,
  AiOutlineSync: AiOutlineSync,
  AiOutlineDownload: AiOutlineDownload,
  AiOutlineLoading: AiOutlineLoading,
  AiOutlineTag: AiOutlineTag,
  AiOutlineCalculator: AiOutlineCalculator,
  AiOutlineFork: AiOutlineFork,
  AiOutlineFullscreen: AiOutlineFullscreen,
  AiOutlineFrown: AiOutlineFrown,
  AiOutlineRollback: AiOutlineRollback,
  AiOutlineQrcode: AiOutlineQrcode,
  AiOutlineDatabase: AiOutlineDatabase,
  AiOutlineArrowRight: AiOutlineArrowRight,
  AiOutlineWifi: AiOutlineWifi,
  AiOutlineApi: AiOutlineApi,
  AiOutlineArrowLeft: AiOutlineArrowLeft,
  AiOutlineUpload: AiOutlineUpload,
  AiOutlineUsb: AiOutlineUsb,
  AiOutlineClockCircle: AiOutlineClockCircle,
  AiOutlineLogout: AiOutlineLogout,
  AiOutlineArrowUp: AiOutlineArrowUp,
  AiOutlineArrowDown: AiOutlineArrowDown,
  AiOutlineDisconnect: AiOutlineDisconnect,
  AiOutlineException: AiOutlineException,
  AiFillCaretUp: AiFillCaretUp,
  AiFillCaretDown: AiFillCaretDown,
  AiOutlineLeft: AiOutlineLeft,
  AiOutlineRight: AiOutlineRight,
  AiOutlineCopy: AiOutlineCopy,
  AiOutlineLoading3Quarters: AiOutlineLoading3Quarters,
  AiOutlineSearch: AiOutlineSearch,
  AiOutlineLogin: AiOutlineLogin,
  AiOutlinePlusCircle: AiOutlinePlusCircle,
  AiOutlineSwitcher: AiOutlineSwitcher,
  AiOutlineShopping: AiOutlineShopping,
  AiFillTool: AiFillTool,
};

const BootStarpIcons = {
  BsBoundingBox: BsBoundingBox,
  BsArrowLeftRight: BsArrowLeftRight,
  BsArrowUpRight: BsArrowUpRight,
  BsCircleFill: BsCircleFill,
};

const GrommetIcons = {
  GrDocumentDownload: GrDocumentDownload,
};

const TbIcons = {
  TbWorldPin: TbWorldPin,
};

class Icons extends Component {
  constructor(props) {
    super(props);
  }

  getIcon = () => {
    /* fetch icons based on props */
    let name = this.props.name,
      type = this.props.type.toLowerCase();
    if (type != "ant") {
      if (type === "fa") {
        return FontAwesome[name];
      } else if (type === "io") {
        return Ionicons.hasOwnProperty(name) ? Ionicons[name] : Icon;
      } else if (type === "md") {
        return MaterialDesignIcons.hasOwnProperty(name)
          ? MaterialDesignIcons[name]
          : Icon;
      } else if (type === "ti") {
        return Typicons.hasOwnProperty(name) ? Typicons[name] : Icon;
      } else if (type === "go") {
        return GithubOcticons.hasOwnProperty(name)
          ? GithubOcticons[name]
          : Icon;
      } else if (type === "fi") {
        return Feather.hasOwnProperty(name) ? Feather[name] : Icon;
      } else if (type === "gi") {
        return GameIcons.hasOwnProperty(name) ? GameIcons[name] : Icon;
      } else if (type === "wi") {
        return WeatherIcons.hasOwnProperty(name) ? WeatherIcons[name] : Icon;
      } else if (type === "di") {
        return Devicons.hasOwnProperty(name) ? Devicons[name] : Icon;
      } else if (type === "hi") {
        return HeroIcons.hasOwnProperty(name) ? HeroIcons[name] : Icon;
      } else if (type === "ai") {
        return AntDesignIcons.hasOwnProperty(name)
          ? AntDesignIcons[name]
          : Icon;
      } else if (type === "bs") {
        return BootStarpIcons.hasOwnProperty(name)
          ? BootStarpIcons[name]
          : Icon;
      } else if (type === "gr") {
        return GrommetIcons.hasOwnProperty(name) ? GrommetIcons[name] : Icon;
      } else if (type === "tb") {
        return TbIcons.hasOwnProperty(name) ? TbIcons[name] : Icon;
      } else {
        return Icon && Icon;
      }
    } else {
      return Icon && Icon;
    }
  };

  render() {
    const type = this.props.type;
    const name = this.props.name;
    const defaultClass = "anticon valign-sub";
    const ReactIcon = this.getIcon();
    const prop =
      type === "ant" ? { spin: this.props.spin ? this.props.spin : false } : [];
    return (
      <ReactIcon
        type={name}
        style={this.props.style ? this.props.style : {}}
        className={
          type === "ant"
            ? this.props.className
              ? this.props.className
              : ""
            : this.props.className
            ? `${defaultClass} ${this.props.className}`
            : `${defaultClass}`
        }
        {...prop}
      />
    );
  }
}

Icons.propTypes = {
  type: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
};

export default Icons;
