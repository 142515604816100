import React, { Component } from "react";
import { Doughnut as PieChart } from "react-chartjs-2";
import { observer, inject } from "mobx-react";

@inject("OrgViewModel", "AuthStore")
@observer
export default class CTDPieChart extends Component {
  constructor(props) {
    super(props);
    this.GLOBAL_SETTINGS = GLOBAL_SETTINGS;
  }

  handleClick = chartElement => {
    const currentOrgId =
      this.props.OrgViewModel.org.id != undefined
        ? this.props.OrgViewModel.org.id
        : this.props.AuthStore.loggedInUser.organization &&
          this.props.AuthStore.loggedInUser.organization.id;
    let url = `/dashboard/` + currentOrgId + `/threatintelligence`;
    if (this.props.history) {
      this.props.history.push(url);
    }
  };

  render() {
    const customColors = [
      "#5c8edf",
      "#7de0d4",
      "#7e86d0",
      "#68bfe4",
      "#f3aaa1",
      "#cbc1fb",
    ];

    let dataCounts = [],
      dataLables = [],
      dataBackgroundcolors = [];
    let statusCount = this.props.data;

    statusCount.forEach(function(value, i) {
      dataLables.push(value.classtype);
      dataCounts.push(value.count);
      dataBackgroundcolors.push(customColors[i]);
    });
    var data = {
      datasets: [
        {
          data: dataCounts,
          backgroundColor: dataBackgroundcolors,
          hoverBackgroundColor: dataBackgroundcolors,
        },
      ],
      // These labels appear in the legend and in the tooltips when hovering different arcs
      labels: dataLables,
    };

    const graphData = {};
    if (this.props.data && this.props.data.length) {
      this.props.data.forEach(item => {
        graphData[item.classtype] = item.count;
      });
    }

    const options = {
      responsive: true,
      animation: {
        segmentShowStroke: false,
        animateScale: true,
        easing: "easeOutBounce",
      },
      title: {
        display: true,
        fontSize: 25,
        fontColor: "#373737",
      },
      cutoutPercentage: 65,
      legend: {
        display: true,
        position: "bottom",
        fullWidth: true,
        labels: {
          usePointStyle: true,
          fontStyle: "bold",
          fontSize: 9,
          padding: 18,
          fontColor:
            this.GLOBAL_SETTINGS && this.GLOBAL_SETTINGS.text_color
              ? this.GLOBAL_SETTINGS.text_color
              : "#365c6f",
          generateLabels: function(chart) {
            var data = chart.data;
            if (data.labels.length && data.datasets.length) {
              return data.labels.map(function(label, i) {
                var meta = chart.getDatasetMeta(0);
                var ds = data.datasets[0];
                var arc = meta.data[i];
                var custom = (arc && arc.custom) || {};
                var getValueAtIndexOrDefault =
                  Chart.helpers.getValueAtIndexOrDefault;
                var arcOpts = chart.options.elements.arc;
                var fill = custom.backgroundColor
                  ? custom.backgroundColor
                  : getValueAtIndexOrDefault(
                      ds.backgroundColor,
                      i,
                      arcOpts.backgroundColor,
                    );
                var stroke = custom.borderColor
                  ? custom.borderColor
                  : getValueAtIndexOrDefault(
                      ds.borderColor,
                      i,
                      arcOpts.borderColor,
                    );
                var bw = custom.borderWidth
                  ? custom.borderWidth
                  : getValueAtIndexOrDefault(
                      ds.borderWidth,
                      i,
                      arcOpts.borderWidth,
                    );

                // Get the value of the current label
                var value =
                  chart.config.data.datasets[arc._datasetIndex].data[
                    arc._index
                  ];

                return {
                  text: label + " : " + value,
                  fillStyle: fill,
                  strokeStyle: stroke,
                  lineWidth: bw,
                  hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                  index: i,
                };
              });
            } else {
              return [];
            }
          },
        },
        onHover: function(e) {
          e.target.style.cursor = "pointer";
        },
      },
      tooltips: {
        enabled: true,
        mode: "single",
        intersect: true,
        labelColor: function(tooltipItem, chart) {
          return {
            borderColor: "rgb(255, 0, 0)",
            backgroundColor: "rgb(255, 0, 0)",
          };
        },
      },
      hover: {
        onHover: function(e) {
          var point = this.getElementAtEvent(e);
          if (point.length) e.target.style.cursor = "pointer";
          else e.target.style.cursor = "default";
        },
      },
    };

    return (
      <div>
        {dataCounts && dataCounts.length > 0 ? (
          <div>
            <PieChart
              height={400}
              width={400}
              options={options}
              data={data}
              getElementAtEvent={this.handleClick}
            />
          </div>
        ) : (
          <i>No Threats</i>
        )}
      </div>
    );
  }
}
