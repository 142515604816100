import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Row, Col, Empty, Select } from "antd";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import TableLayout from "components/layout/TableLayout";
import ActivityController from "controller/ActivityController";
import { getCurrentlocaleText } from "Core/Utils";
import { Link } from "react-router-dom";
import Icons from "../UI-Components/Icons";

const Option = Select.Option;
const extraRowStyle = { marginTop: 5, marginBottom: 5 };

@inject("ActivityViewModel", "UiStore", "AuthStore")
@observer
class InodeActivityList extends Component {
  constructor(props) {
    super(props);
    this.UiStore = this.props.UiStore;
    this.activityModel = this.props.ActivityViewModel;
    this.state = {
      group: ["NODE_ACTIVITIES", "NETWORK_ACTIVITIES", "SERVICE_ACTIVITIES"],
      startDate: null,
      endDate: null,
      expandedRowKeys: [],
    };
  }

  componentDidMount() {
    if (this.props.AuthStore.IsPermitted("ACTIVITY:VIEW")) {
      let resource_id = this.props.nodeId;
      this.activityList(true, 0, resource_id, this.state.group);
    }
  }

  activityList = (loading = true, page = 0, forcecall = false) => {
    let params = this.props.params || {
      resource_id: this.props.match.params.nodeId
        ? this.props.match.params.nodeId
        : this.props.nodeId,
    };
    params.group = this.state.group;
    params.page = page;
    params.size = 5;

    // For all the activities
    ActivityController.getActivities(params, loading, forcecall);
  };

  onGroupChange = value => {
    this.setState({ group: value });
  };

  expandRow = () => {
    let keys = this.activityModel.activities.map(item => {
      return item.id;
    });
    this.setExpandedRowKeys(
      this.state.expandedRowKeys.length !== keys.length ? keys : [],
    );
  };

  onExpand = (expanded, record) => {
    let keys = this.state.expandedRowKeys;
    let index = keys.findIndex(val => {
      return val === record.id;
    });
    if (expanded) {
      if (index === -1) {
        keys.push(record.id);
      }
      this.setExpandedRowKeys(keys);
    } else {
      if (index !== -1) {
        keys.splice(index, 1);
      }
      this.setExpandedRowKeys(keys);
    }
  };

  setExpandedRowKeys = keys => {
    this.setState({ expandedRowKeys: keys });
  };

  getMoreInfo = record => {
    return (
      <div>
        <Row style={extraRowStyle}>
          <Col span={6}>
            <strong>{getCurrentlocaleText("activity.group.text")}</strong>
          </Col>
          <Col span={2}>{":"}</Col>
          <Col>{record.display_group}</Col>
        </Row>
        <Row style={extraRowStyle}>
          <Col span={6}>
            <strong>{getCurrentlocaleText("organizationName.text")}</strong>
          </Col>
          <Col span={2}>{":"}</Col>
          <Col>{record.organization.name}</Col>
        </Row>
      </div>
    );
  };

  render() {
    let count = this.activityModel.count || 0;
    let path = window.location.pathname.split("/");
    let hasNextPage =
      this.activityModel.pageable.page <
      this.activityModel.pageable.total_page - 1;
    let activities = this.activityModel.activityList || [];
    let columns = [
      {
        title: getCurrentlocaleText("activity.time.text"),
        key: "time",
        width: "30%",
        render: (text, record, index) => {
          return (
            <span>
              {record.date ? (
                <span>{record.display_longdate}</span>
              ) : (
                <span>-</span>
              )}
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText("activity.type.text"),
        key: "type",
        width: "40%",
        render: (text, record, index) => {
          return (
            <span>
              {record.display_type ? (
                <span>
                  <strong>{record.display_type}</strong>
                </span>
              ) : (
                <span>-</span>
              )}
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText("activity.description.text"),
        key: "description",
        width: "50%",
        render: (text, record, index) => {
          return (
            <span>
              {record ? (
                <span>{record.display_description_without_time}</span>
              ) : (
                <span>-</span>
              )}
            </span>
          );
        },
      },
    ];

    let container = (
      <div>
        {/* Activity List */}
        <div className="listing-page-data-container">
          {this.activityModel.activities.length > 0 && (
            <div>
              <div
                style={{
                  display: "inline-flex",
                  width: "50%",
                  marginBottom: 15,
                }}
              >
                <span>
                  <a
                    style={{ fontSize: 14, marginLeft: 20 }}
                    onClick={this.expandRow}
                  >
                    <span>
                      <Icons
                        className={"valign-middle"}
                        type="ai"
                        name={
                          this.state.expandedRowKeys.length !==
                          activities.length
                            ? "AiOutlinePlusSquare"
                            : "AiOutlineMinusSquare"
                        }
                      />
                      <span style={{ marginLeft: 5 }}>
                        {this.state.expandedRowKeys.length !== activities.length
                          ? getCurrentlocaleText(
                              "node.list.table.expand_all.label.text",
                            )
                          : getCurrentlocaleText(
                              "node.list.table.collapse_all.label.text",
                            )}
                      </span>
                    </span>
                  </a>
                </span>
              </div>
            </div>
          )}
          <Row gutter={16} type="flex" align="middle">
            <Col span={24}>
              {this.activityModel.activities.length > 0 ? (
                <TableLayout
                  pagination={false}
                  columns={columns}
                  hideRowSelection={true}
                  dataSource={activities}
                  expandedRowRender={record => {
                    return this.getMoreInfo(record);
                  }}
                  expandedRowKeys={this.state.expandedRowKeys}
                  onExpand={this.onExpand}
                  className="activityList"
                  hasLazyLoading={false}
                  footer={
                    hasNextPage
                      ? () => {
                          return (
                            <div style={{ textAlign: "center" }}>
                              <Link to={path.join("/") + "/inodeactivities"}>
                                <strong>
                                  {getCurrentlocaleText("moreActivity.text")}
                                </strong>
                              </Link>
                            </div>
                          );
                        }
                      : null
                  }
                />
              ) : (
                <div>
                  {!this.activityModel.loading &&
                    (this.activityModel.activities ||
                      this.activityModel.activities.length === 0) && (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={getCurrentlocaleText("no.activity.text")}
                      />
                    )}
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    );

    return (
      <LoadingComponent loading={this.activityModel.loading}>
        {container}
      </LoadingComponent>
    );
  }
}

export default InodeActivityList;
