import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Modal, Button } from "antd";
import TableLayout from "components/layout/TableLayout";
import ModalLoadingComponent from "components/UI-Components/ModalLoadingComponent";
import {
  getCurrentlocaleText,
  getMomentDate,
  TIME_DATE_FORMAT,
  getTimezoneToDisplay,
} from "Core/Utils";
import moment from "moment";
import UserController from "controller/UserController";
import Icons from "../UI-Components/Icons";

@inject(
  "UserViewModel",
  "UiStore",
  "ModelStore",
  "AuthStore",
  "TimezoneViewModel",
)
@observer
class UserSessionView extends Component {
  constructor(props) {
    super(props);
    this.userModel = this.props.UserViewModel;
    this.modelStore = this.props.ModelStore;
    this.uiStore = this.props.UiStore;
    this.authStore = this.props.AuthStore;
    this.state = {
      showModel: false,
    };
  }

  handleClick = e => {
    this.setLoading(true);
    if (this.props.isMe) {
      UserController.getUserSessions(null, true)
        .then(resp => {
          this.setLoading(false);
        })
        .catch(error => {
          this.setLoading(false);
        });
    } else {
      UserController.getUserSessions(this.props.userId)
        .then(resp => {
          this.setLoading(false);
        })
        .catch(error => {
          this.setLoading(false);
        });
    }
    this.setState({ showModel: true });
  };

  setLoading = loading => {
    this.uiStore.setModelLoading(loading);
  };

  handleCancel = () => {
    this.setState({ showModel: false });
  };

  clearSessions = e => {
    if (this.props.isMe) {
      UserController.clearUserSessions(null, true).then(response => {
        this.handleCancel();
      });
      // Logout
      setTimeout(() => {
        this.modelStore.Logout();
        this.uiStore.SetLoginStatus(false);
        this.authStore.resetStore();
      }, 1000);
    } else {
      UserController.clearUserSessions(this.props.userId).then(response => {
        this.handleCancel();
      });
    }
  };

  render() {
    let userSessions = this.userModel.userSessions;
    this.columns = [
      {
        title: getCurrentlocaleText("user.session.started_at.text"),
        dataIndex: "started_at",
        key: "started_at",
        render: (text, record, index) => {
          let currentDate = moment(new Date().getTime());
          return (
            <div>
              {currentDate.diff(
                moment(new Date(record.started_at).getTime()),
                "hours",
              ) < 24
                ? getMomentDate(record.started_at)
                : `${moment(new Date(record.started_at).getTime()).format(
                    TIME_DATE_FORMAT,
                  )} ${getTimezoneToDisplay(record.started_at)}`}
              {record.is_current_session && (
                <span>
                  {" "}
                  ({getCurrentlocaleText("user.session.current_session.text")})
                </span>
              )}
            </div>
          );
        },
      },
      {
        title: getCurrentlocaleText("user.session.expired_at.text"),
        dataIndex: "expired_at",
        key: "expired_at",
        render: (text, record, index) => {
          return (
            <div>
              {record.expired_at
                ? `${moment(new Date(record.expired_at).getTime()).format(
                    TIME_DATE_FORMAT,
                  )} ${getTimezoneToDisplay(record.expired_at)}`
                : "-"}
            </div>
          );
        },
      },
    ];

    return (
      <span>
        {this.props.type === "button" ? (
          <Button
            onClick={this.handleClick}
            title={getCurrentlocaleText("myprofile.session_details_text")}
            style={{ zIndex: 1, minWidth: 50 }}
          >
            <Icons type="ai" name="AiOutlineClockCircle" />
          </Button>
        ) : (
          <a className="hover">
            <span onClick={this.handleClick}>
              {" "}
              {getCurrentlocaleText("myprofile.session_details_text")}
            </span>
          </a>
        )}
        {this.state.showModel && (
          <Modal
            visible={this.state.showModel}
            title={getCurrentlocaleText("user.session.text")}
            onCancel={this.handleCancel}
            maskClosable={false}
            footer={null}
            width={600}
          >
            <ModalLoadingComponent>
              <div style={{ minHeight: 70 }}>
                {userSessions.length > 0 ? (
                  <div>
                    <TableLayout
                      columns={this.columns}
                      dataSource={userSessions}
                      pagination={true}
                      hideExpandedRow={true}
                      hideRowSelection={true}
                      paginationSize={"small"}
                      itemPerPage={10}
                      tableSize={"small"}
                    />
                    {!this.props.isCentralAuthUser && (
                      <div style={{ margin: "-40px 0 0" }}>
                        <Button
                          disabled={!this.props.isMe}
                          onClick={this.clearSessions}
                        >
                          {getCurrentlocaleText(
                            "user.session.clear_all_sessions.text",
                          )}
                        </Button>
                      </div>
                    )}
                  </div>
                ) : (
                  <h3>
                    {getCurrentlocaleText(
                      "user.session.no_session_to_display.text",
                    )}
                  </h3>
                )}
              </div>
            </ModalLoadingComponent>
          </Modal>
        )}
      </span>
    );
  }
}

export default UserSessionView;
