import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Card, Row, Col } from "antd";
import Icons from "components/UI-Components/Icons";
import BreadcrumbComponent from "components/UI-Components/BreadcrumbComponent";
import { getCurrentlocaleText } from "Core/Utils";
import WebhookForm from "components/Webhooks/WebhookStepsForm";
import WebhookController from "controller/WebhookController";

@inject("UiStore")
@observer
class ManageWebhooksForm extends Component {
  constructor(props) {
    super(props);
    this.UiStore = this.props.UiStore;
    this.state = {
      loading: false,
      isSubmitting: false,
      currrentWebhookData: {},
      BreadcrumbList: [
        {
          name: getCurrentlocaleText("manage_webhooks.text"),
          link: "managewebhooks",
        },
        {
          name: getCurrentlocaleText("webhook.addchannel.text"),
          link: "managewebhooks/add",
        },
      ],
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({ loading: true });
  }

  componentDidMount() {
    this.setState({ loading: false });
    if (this.props.match.params && this.props.match.params.webhookId) {
      WebhookController.getWebhook(this.props.match.params.webhookId).then(
        res => {
          this.setState({ currrentWebhookData: res });
        },
      );
    }
  }

  submitAlert = values => {
    this.setState({ isSubmitting: true });
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.webhookId
    ) {
      let currrentWebhookData = this.state.currrentWebhookData;
      if (
        currrentWebhookData.url != values.url ||
        values.secret != currrentWebhookData.secret ||
        values.name != currrentWebhookData.name ||
        values.headers != currrentWebhookData.headers ||
        values.metadata != currrentWebhookData.metadata
      ) {
        WebhookController.update(values, {
          id: this.props.match.params.webhookId,
        })
          .then(response => {
            this.setState({ currrentWebhookData: response });
            this.setState({ isSubmitting: false });
          })
          .catch(error => {
            this.setState({ isSubmitting: false });
          });
      } else {
        this.setState({ isSubmitting: false });
      }
    } else {
      WebhookController.create(values)
        .then(response => {
          this.setState({ currrentWebhookData: response });
          let link = window.location.pathname.replace(
            "/add",
            "/edit/" + response.id,
          );
          this.props.history.push(link);
          this.setState({ isSubmitting: false });
        })
        .catch(error => {
          this.setState({ isSubmitting: false });
        });
    }
  };

  verifyChannel = () => {
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.webhookId
    ) {
      WebhookController.verify({}, { id: this.props.match.params.webhookId })
        .then(response => {
          WebhookController.getWebhook(this.props.match.params.webhookId).then(
            res => {
              this.setState({ currrentWebhookData: res });
            },
          );
          this.setState({ isSubmitting: false });
        })
        .catch(error => {
          this.setState({ isSubmitting: false });
        });
    }
  };

  checkChannel = () => {
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.webhookId
    ) {
      let formData = this.props.UiStore.webhookFormData.test;
      WebhookController.test(formData, {
        id: this.props.match.params.webhookId,
      })
        .then(response => {
          WebhookController.getWebhook(this.props.match.params.webhookId).then(
            res => {
              this.setState({ currrentWebhookData: res });
            },
          );
          this.setState({ isSubmitting: false });
        })
        .catch(error => {
          this.setState({ isSubmitting: false });
        });
    }
  };

  render() {
    return (
      <div>
        <div>
          {" "}
          <Row gutter={24} type="flex" align="middle">
            <Col span={24}>
              <h2 className="page-title">
                <Icons type="ai" name="AiOutlineGlobal" />
                {"  "}
                {getCurrentlocaleText("webhook.channel.title.text")}
              </h2>
              <BreadcrumbComponent
                {...this.props}
                BreadcrumbList={this.state.BreadcrumbList}
              />
            </Col>
          </Row>
        </div>
        <div>
          <Row gutter={2} type="flex" align="top">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
              xl={{ span: 24 }}
            >
              <Card loading={this.state.loading}>
                <WebhookForm
                  onSubmit={this.submitAlert}
                  isSubmitting={this.state.isSubmitting}
                  currrentWebhookData={this.state.currrentWebhookData}
                  webhookId={
                    this.props.match &&
                    this.props.match.params &&
                    this.props.match.params.webhookId
                      ? this.props.match.params.webhookId
                      : null
                  }
                  verifyChannel={this.verifyChannel}
                  checkChannel={this.checkChannel}
                />
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default ManageWebhooksForm;
