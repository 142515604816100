import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Form, Select, Radio } from "antd";
import Input from "components/UI-Components/InputBox";
import {
  getCurrentlocaleText,
  ALERT,
  customSort,
  checkforFeatureEnabled,
  isEmpty,
} from "Core/Utils";
import { WebhookController } from "controller/Controllers";
import Icons from "components/UI-Components/Icons";

const FormItem = Form.Item;
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const Option = Select.Option;

@inject("UiStore", "AuthStore")
@observer
class AlertNotificationItems extends Component {
  constructor(props) {
    super(props);
    this.UiStore = this.props.UiStore;
    this.AuthStore = this.props.AuthStore;
    this.state = {
      isWebhookEnabled: false,
      isWebhookChannel: false,
      isEmailChannel: true,
      webhookList: [],
      formItem: {
        notificationType: ALERT.CHANNELS.EMAIL,
        email: undefined,
        webhookId: undefined,
      },
      webhook_verified_state: "",
      validateMessage: "",
    };
  }

  UNSAFE_componentWillMount() {
    if (checkforFeatureEnabled(this.AuthStore.loggedInUserOrg, "webhook")) {
      this.setState({ isWebhookEnabled: true }, () => {
        this.fetchWebhook();
      });
    } else {
      this.setState({
        isWebhookEnabled: false,
      });
    }
  }

  componentDidMount() {
    this.updateHelp({
      title: getCurrentlocaleText("managealerts.notification.text"),
      data: [
        {
          subtitle: "",
          content: "",
        },
      ],
    });
    // Update help content
    setTimeout(() => {
      let elm = document.querySelector(
        ".alert-form .help-container .help-content",
      );
      if (elm) {
        elm.innerHTML = getCurrentlocaleText(
          "alertform.notification.help.text",
          {
            0: '<a target="_blank" href="/help/kb"> Online help </a>',
          },
        );
      }
    }, 50);
    if (
      this.UiStore.alertFormData &&
      !isEmpty(this.UiStore.alertFormData.notification)
    ) {
      let notification = this.UiStore.alertFormData.notification;
      // Set Alert type, status, duration
      let formItem = this.state.formItem;
      formItem.notificationType = notification.channel_type;
      if (formItem.notificationType === ALERT.CHANNELS.EMAIL) {
        this.setState({ isEmailChannel: true, isWebhookChannel: false });
      } else {
        this.setState({ isEmailChannel: false, isWebhookChannel: true });
        formItem.webhookId = notification.channel.id;
      }

      let data = {
        notificationType: {
          value: formItem.notificationType,
        },
        webhookId: {
          value: formItem.webhookId,
        },
      };
      this.props.formReference.setFields(data);
    }
  }

  updateHelp = data => {
    this.UiStore.SetStoreData("helpData", data);
  };

  handleTypeChange = value => {
    let channel = value.target.value;
    let isEmail = channel === ALERT.CHANNELS.EMAIL ? true : false;
    let isWebhook = channel === ALERT.CHANNELS.WEBHOOK ? true : false;
    this.setState({
      isEmailChannel: isEmail,
      isWebhookChannel: isWebhook,
    });
  };

  fetchWebhook = () => {
    let params = {
      page: 0,
      size: 1,
    };
    WebhookController.getWebhooks(params)
      .then(response => {
        if (response && response.total_count) {
          let allParams = [],
            totalCount = response.total_count / 100;
          if (totalCount > 0) {
            for (let page = 0; page < totalCount; page++) {
              let params = { page: page, size: 100 };
              allParams.push(params);
            }
            WebhookController.getAllWebhooks(allParams)
              .then(response => {
                let webhook = this.state.webhookList;
                webhook = response;
                this.setState({ loading: false, webhookList: webhook });
              })
              .catch(error => {
                this.setState({ loading: false });
              });
          } else {
            this.setState({ loading: false });
          }
        } else {
          this.setState({ loading: false });
        }
      })
      .catch(error => {
        this.setState({ loading: false });
      });
  };

  renderWebhookOptions = () => {
    let webhooks = this.state.webhookList,
      options = [];
    options = webhooks
      .sort((x, y) => customSort(x.name, y.name))
      .map((webhook, index) => {
        return (
          <Option
            key={webhook.id}
            value={webhook.id}
            title={
              !webhook.is_enabled
                ? getCurrentlocaleText("webhook.notverified.title.message")
                : webhook.name
            }
          >
            <div>
              <span
                style={{
                  paddingLeft: "5px",
                  textAlign: "left",
                }}
              >
                {!webhook.is_enabled && (
                  <Icons type="fa" name="FaBan" className="webhook-warning" />
                )}
                {webhook.name}
              </span>
            </div>
          </Option>
        );
      });
    return options;
  };

  verifyWebhook = (rule, value, callback) => {
    let webhooks = this.state.webhookList;
    let verifiedWebhook = [];
    if (value && value.length > 0) {
      verifiedWebhook = webhooks.filter(val => {
        if (val.id === value) return val;
      });

      if (verifiedWebhook[0] && verifiedWebhook[0].is_enabled == false) {
        callback(getCurrentlocaleText("webhook.notverified.title.message"));
        this.setState({
          webhook_verified_state: "error",
          validateMessage: getCurrentlocaleText(
            "webhook.notverified.title.message",
          ),
        });
      } else {
        callback();
        this.setState({ webhook_verified_state: "", validateMessage: "" });
      }
    } else {
      this.setState({
        webhook_verified_state: "error",
        validateMessage: "Please select webhook",
      });
    }
  };

  render() {
    let { getFieldDecorator } = this.props.formReference;
    let { formItem } = this.state;
    return (
      <div style={{ marginTop: 10 }}>
        <FormItem
          label={
            <span> {getCurrentlocaleText("alertform.notification.text")}</span>
          }
          {...formItemLayout}
        >
          {getFieldDecorator("notificationType", {
            rules: [
              {
                required: true,
              },
            ],
            initialValue: this.state.formItem.notificationType,
          })(
            <RadioGroup
              size="small"
              onChange={this.handleTypeChange}
              disabled={this.props.formType == "view"}
            >
              <RadioButton value={ALERT.CHANNELS.EMAIL}>
                {getCurrentlocaleText("alertform.email.text")}
              </RadioButton>
              <RadioButton
                disabled={!this.state.isWebhookEnabled}
                value={ALERT.CHANNELS.WEBHOOK}
              >
                {getCurrentlocaleText("alertform.webhook.text")}
              </RadioButton>
            </RadioGroup>,
          )}
        </FormItem>

        {/**email */}
        {this.state.isEmailChannel && (
          <FormItem
            label={<span>{getCurrentlocaleText("alertform.email.text")}</span>}
            {...formItemLayout}
          >
            {getFieldDecorator("email", {
              rules: [{ type: "email", message: "Invalid email address." }],
              initialValue: this.UiStore.currentUser
                ? this.UiStore.currentUser.username
                : undefined,
            })(
              <Input
                type="email"
                placeholder={getCurrentlocaleText(
                  "alertform.email.placeholder.text",
                )}
                autoComplete="off"
                disabled={true}
              />,
            )}
          </FormItem>
        )}

        {/**webhook */}
        {this.state.isWebhookEnabled && this.state.isWebhookChannel && (
          <FormItem
            label={
              <span>{getCurrentlocaleText("alertform.webhook.text")}</span>
            }
            {...formItemLayout}
            validateStatus={this.state.webhook_verified_state}
            help={this.state.validateMessage}
          >
            {getFieldDecorator("webhookId", {
              rules: [
                {
                  required: true,
                  message: "Please select webhook",
                },
                {
                  validator: this.verifyWebhook,
                },
              ],
              initialValue: this.state.formItem.webhookId,
            })(
              <Select
                disabled={this.props.formType == "view"}
                placeholder={getCurrentlocaleText(
                  "alertform.webhook.placeholder.text",
                )}
              >
                {this.renderWebhookOptions()}
              </Select>,
            )}
          </FormItem>
        )}
      </div>
    );
  }
}

export default AlertNotificationItems;
