import React, { Component } from "react";
import { Button, Tag, Popover, AutoComplete } from "antd";
import Input from "components/UI-Components/InputBox";
import {
  getCurrentlocaleText,
  getOrgPolicyCounInfo,
  randomAlphaNumericString,
} from "Core/Utils";
import { observer, inject } from "mobx-react";
import LabelContainer from "components/UI-Components/LabelContainer";
import GenerateLabelHelp from "components/UI-Components/GenerateLabelHelp";
import Icons from "./Icons";

const { Option } = AutoComplete;
const LableErrorCodes = {
  0: getCurrentlocaleText("general.label.input.error1.text"),
  default: "",
};

@inject("UiStore", "AuthStore", "OrgViewModel")
@observer
class LabelProvider extends Component {
  constructor(props) {
    super(props);
    this.orgModel = this.props.OrgViewModel;
    this.valueInputRef = React.createRef();
    this.DefaultLabels = {
      default: "",
      owner: "",
      version: "",
      release: "",
      deployment: "",
    };
    this.state = {
      tags: this.props.tags || [],
      inputVisible: false,
      inputValue: "",
      inputKey: "",
      labels: this.props.metadata || {},
      currentLength: this.currentLabelCount(this.props.metadata),
      showDefault: false,
      UnderscoreValidation: [],
      colon_validation: [],
      dataSource: [],
      isDisabled: false,
      viewOnly: this.props.viewOnly || false,
      Errorcode: "default",
      isOnEditMode: false,
      reservedLabels: null,
      count: 5,
    };
  }

  UNSAFE_componentWillReceiveProps(newprops) {
    if (this.props != newprops) {
      this.setState({
        tags: newprops.tags,
        labels: newprops.metadata,
        currentLength: this.currentLabelCount(this.props.metadata),
        reservedLabels: newprops.reservedLabels,
        count: newprops.count,
      });
    }
  }

  UNSAFE_componentWillMount() {
    let dataSource = [];
    // Add additional default labels given via props
    if (this.props.defaultLabels) {
      this.DefaultLabels = Object.assign(
        this.DefaultLabels,
        this.props.defaultLabels,
      );
    }
    dataSource = Object.keys(this.DefaultLabels).map(labelKey => {
      return (
        <Option key={labelKey} value={labelKey} title={labelKey}>
          {labelKey}
        </Option>
      );
    });
    this.setState({
      dataSource: dataSource,
      tags: this.props.tags,
      labels: this.props.metadata,
      currentLength: this.currentLabelCount(this.props.metadata),
      reservedLabels: this.props.reservedLabels,
      count: this.props.count,
    });
  }

  defaultLabelKeys = () => {
    return this.DefaultLabels ? Object.keys(this.DefaultLabels) : [];
  };

  /**
   * Handle TAG Remove
   */
  handleClose = removedTag => {
    let removedLabel = removedTag.split(":");
    let currentTags = this.state.tags;
    let currentTagIndex = currentTags.indexOf(removedTag);
    if (currentTagIndex > -1) {
      currentTags.splice(currentTagIndex, 1);
    }
    this.setState({ tags: currentTags });
    let labels = this.state.labels;
    delete labels[removedLabel[0].trim()];
    this.setState({
      tags: currentTags,
    });
    this.setState({
      labels: labels,
      currentLength: this.currentLabelCount(labels),
    });
    this.props.handleChange(labels);
  };
  /**
   * Check Object count
   */
  currentLabelCount = labels => {
    var count = 0;
    for (var key in labels) {
      if (
        Object.prototype.hasOwnProperty.call(labels, key) &&
        !key.includes("_iotium")
      ) {
        count++;
      }
    }
    return count;
  };

  reservedLabelCount = reservedLabels => {
    let count = 0;
    if (reservedLabels && reservedLabels.length > 0) {
      count = reservedLabels.filter(label => label && label.length > 0).length;
    }
    return count;
  };

  updateHelp = data => {
    this.props.UiStore.SetStoreData("helpData", data);
  };
  /**
   * Toggle INPUT and BUTTON
   */
  showInput = () => {
    this.setState({
      inputVisible: true,
      showDefault: false,
      isDisabled: false,
      inputKey: "",
      inputValue: "",
      colon_validation: [],
      UnderscoreValidation: [],
    });
  };
  /**
   * Handle Input Change
   */
  handleInputValueChange = e => {
    this.setState({ inputValue: e.target.value });
  };
  /**
   * Handle Input Key Change
   */
  handleInputKeyChange = e => {
    let tags = this.state.tags ? this.state.tags : [],
      labels = this.state.labels ? this.state.labels : {};
    let key = e;
    let { isOnEditMode, inputKey, inputValue } = this.state;
    if (key != "") {
      if (
        !this.defaultLabelKeys().includes(key) &&
        !this.validateLabelInput(key)
      ) {
        let UnderscoreValidation = this.state.UnderscoreValidation;
        UnderscoreValidation.push("new");
        this.setState({
          inputKey: key,
          UnderscoreValidation: UnderscoreValidation,
        });
      } else {
        if (!key.includes(":")) {
          let UnderscoreValidation = this.state.UnderscoreValidation;
          let index = UnderscoreValidation.indexOf("new");
          if (index !== -1) {
            UnderscoreValidation.splice(index, 1);
            this.setState({
              inputKey: key,
              UnderscoreValidation: [],
            });
          } else {
            this.setState({ inputKey: key, UnderscoreValidation: [] });
            // If selected key has any default value, then consider that value as input value
            if (
              this.DefaultLabels.hasOwnProperty(key) &&
              this.DefaultLabels[key] &&
              this.DefaultLabels[key] !== ""
            ) {
              if (!labels.hasOwnProperty(key)) {
                let value = this.DefaultLabels[key];
                tags.push(key + " : " + value);
                labels[key] = value;
                if (
                  value &&
                  tags.indexOf(value) === -1 &&
                  key &&
                  tags.indexOf(key) === -1
                ) {
                  this.setState({
                    tags: tags,
                    inputValue: "",
                    inputKey: "",
                    inputVisible: false,
                    labels: labels,
                    isOnEditMode: false,
                  });
                  this.props.handleChange(labels);
                }
              }
            } else if (isOnEditMode && !labels.hasOwnProperty(key)) {
              let value = inputValue;
              // Remove old key
              if (labels.hasOwnProperty(inputKey)) {
                delete labels[inputKey];
              }
              // Dont allow empty values
              if (value && value != "") {
                labels[key] = value;
              }
              if (
                value &&
                tags.indexOf(value) === -1 &&
                key &&
                tags.indexOf(key) === -1
              ) {
                this.setState({
                  inputKey: key,
                  labels: labels,
                });
              }
            }
          }
        } else {
          let UnderscoreValidation = this.state.UnderscoreValidation;
          UnderscoreValidation.push("new");
          this.setState({
            inputKey: key,
            UnderscoreValidation: [],
          });
        }
      }
    } else {
      this.setState({
        inputKey: "",
        UnderscoreValidation: [],
      });
    }
  };
  /**
   * Validate Label input
   */
  validateLabelInput = value => {
    if (
      !LabelContainer.checkForAphanumericValue(
        value && String(value).substring(0, 1),
      )
    ) {
      this.setState({ Errorcode: "0" });
      return false;
    }
    if (
      !LabelContainer.checkForAphanumericValue(
        value && String(value).substring(value.length - 1, value.length),
      )
    ) {
      this.setState({ Errorcode: "0" });
      return false;
    }
    if (!LabelContainer.checForAllowedValue(value)) {
      this.setState({ Errorcode: "0" });
      return false;
    }
    if (value.length > 63) {
      this.setState({ Errorcode: "0" });
      return false;
    } else {
      this.setState({ Errorcode: "default" });
      return true;
    }
  };

  /**
   * Handle Default Input value Change
   */
  handleDefaultInputValueChange = e => {
    let value = e.target.value;
    if (value != "") {
      if (!this.validateLabelInput(value)) {
        let colon_validation = this.state.colon_validation;
        colon_validation.push("new");
        this.setState({
          inputValue: e.target.value,
          colon_validation: colon_validation,
        });
      } else {
        if (!value.includes(":")) {
          let colon_validation = this.state.colon_validation;
          let index = colon_validation.indexOf("new");
          if (index !== -1) {
            colon_validation.splice(index, 1);
            this.setState({
              inputValue: value,
              colon_validation: [],
            });
          } else {
            this.setState({ inputValue: value });
          }
        } else {
          let colon_validation = this.state.colon_validation;
          colon_validation.push("new");
          this.setState({
            inputValue: e.target.value,
            colon_validation: colon_validation,
          });
        }
      }
    } else {
      let colon_validation = this.state.colon_validation;
      let index = colon_validation.indexOf("new");
      if (index !== -1) {
        colon_validation.splice(index, 1);
        this.setState({
          inputValue: "",
          colon_validation: [],
        });
      } else {
        this.setState({ inputValue: e.target.value });
      }
    }
  };
  /**
   *
   */
  handleDefaultInputKeyChange = key => {
    this.setState({ inputKey: key });
  };
  /**
   * Handle Confirmation
   */
  handleInputConfirm = key => {
    const state = this.state;
    const inputValue = state.inputValue;
    const inputKey = state.inputKey;
    let tags = state.tags ? state.tags : [],
      labels = state.labels ? state.labels : {};
    if (inputKey && inputKey.trim().length === 0) {
      this.setState({
        inputVisible: false,
        inputValue: "",
        inputKey: "",
        isOnEditMode: false,
      });
    }
    if (
      !this.state.UnderscoreValidation.includes(key) &&
      !this.state.colon_validation.includes(key) &&
      inputKey != ""
    ) {
      if (!labels.hasOwnProperty(inputKey)) {
        labels[inputKey] = inputValue;
      }
      if (
        inputValue &&
        tags.indexOf(inputValue) === -1 &&
        inputKey &&
        tags.indexOf(inputKey) === -1
      ) {
        tags.push(inputKey + " : " + inputValue);
        this.setState({
          tags: tags,
          inputValue: "",
          inputKey: "",
          inputVisible: false,
          labels: labels,
          isOnEditMode: false,
        });
      } else {
        this.setState({
          inputVisible: false,
          inputValue: "",
          inputKey: "",
          isOnEditMode: false,
        });
      }
      if (
        !inputKey ||
        (this.defaultLabelKeys().includes(inputKey) && !inputValue)
      ) {
        this.setState({
          inputVisible: false,
          inputValue: "",
          inputKey: "",
          isOnEditMode: false,
        });
      }
      this.props.handleChange(labels);
    } else if (
      !this.defaultLabelKeys().includes(inputKey) &&
      !this.validateLabelInput(inputKey)
    ) {
      this.validateLabelInput(inputKey);
    } else if (!this.validateLabelInput(inputValue)) {
      this.validateLabelInput(inputValue);
    }
  };

  getTagsValue = (tags, count) => {
    return tags.length > 0
      ? tags
          .sort((a, b) => {
            return a.isReserved - b.isReserved;
          })
          .filter(tag => tag && tag.label && tag.label.length > 0)
          .map((tag, index) => {
            if (!count || index < count) {
              const isLongTag = tag.label.length > 25;
              const tagElem = (
                <span
                  key={index + randomAlphaNumericString(3)}
                  style={{ marginBottom: 5 }}
                >
                  <Tag
                    className={
                      tag.isReserved
                        ? "iot-reserved-tag-label-color"
                        : "iot-tag-label-color"
                    }
                    closable={
                      !this.props.viewOnly &&
                      !this.state.isOnEditMode &&
                      !tag.isReserved
                        ? true
                        : false
                    }
                    onClose={() => this.handleClose(tag.label)}
                    style={{ marginBottom: 5 }}
                  >
                    <span>
                      <Icons
                        type="ai"
                        className={"valign-text-bottom"}
                        name="AiOutlineTag"
                      />
                      <span style={{ marginLeft: 5 }} title={tag.label}>
                        {isLongTag ? `${tag.label.slice(0, 25)}...` : tag.label}
                      </span>
                      {!this.props.viewOnly &&
                        !this.state.isOnEditMode &&
                        !tag.isReserved && (
                          <span
                            title={getCurrentlocaleText(
                              "labels.edit.hover.title.text",
                            )}
                            onClick={() => {
                              this.editLabel(tag.label);
                            }}
                            style={{ cursor: "pointer", marginLeft: 5 }}
                          >
                            <Icons
                              type="ai"
                              name="AiOutlineEdit"
                              className={"valign-text-bottom"}
                            />
                          </span>
                        )}
                    </span>
                  </Tag>
                </span>
              );
              return tagElem;
            }
          })
      : this.props.viewOnly && <span>{"-"}</span>;
  };

  // Edit Label input
  editLabel = tag => {
    let TagContent = tag.split(":"),
      TagKey = TagContent[0] ? TagContent[0].trim() : "",
      TagValue = TagContent[1] ? TagContent[1].trim() : "",
      isDisabledState = false;
    if (this.defaultLabelKeys().includes(TagKey)) {
      isDisabledState = true;
    }
    this.handleClose(tag);
    this.setState(
      {
        inputVisible: true,
        showDefault: false,
        isDisabled: isDisabledState,
        isOnEditMode: true,
        inputKey: TagKey,
        inputValue: TagValue,
        colon_validation: [],
        UnderscoreValidation: [],
      },
      () => {
        // Focus the value input box
        if (
          this.valueInputRef &&
          this.valueInputRef.current &&
          this.valueInputRef.current.focus
        ) {
          this.valueInputRef.current.focus();
        }
      },
    );
  };

  checkforHelp = () => {
    if (!this.props.showPopupLabel) {
      this.updateHelp({
        title: getCurrentlocaleText("general.form.label.text").replace(
          ":",
          " ",
        ),
        data: [
          {
            subtitle: "",
            content: getCurrentlocaleText("general.form.label.field.help.text"),
          },
          {
            subtitle: getCurrentlocaleText(
              "general.form.label.field.help2.text",
            ),
            content: <GenerateLabelHelp />,
          },
        ],
      });
    }
    //Labels are key-value pairs that can be attached to IoTium objects including iNodes, networks, services, security policies, etc. Labels allow you to organize your objects by specifying attributes in any way that makes sense for you.
  };

  render() {
    let {
      tags,
      inputVisible,
      inputValue,
      inputKey,
      currentLength,
      dataSource,
      labels,
      reservedLabels,
      count,
    } = this.state;
    let userDefinedLabelsCount = this.currentLabelCount(labels);
    let reservedLabelsCount = this.reservedLabelCount(reservedLabels);
    let labelsCount = userDefinedLabelsCount + reservedLabelsCount;
    let displayTags = [];
    // default count 5
    count = count ? count : 5;
    // Reserved labels
    if (reservedLabels && reservedLabels.length > 0) {
      reservedLabels.forEach(label => {
        if (label && label.length > 0) {
          displayTags.push({ label: label, isReserved: true });
        }
      });
    }
    // User defined labels
    if (tags && tags.length > 0) {
      tags.forEach(label => {
        if (label && label.length > 0) {
          displayTags.push({ label: label, isReserved: false });
        }
      });
    }
    let remaining = [];
    if (count && displayTags.length > count) {
      displayTags.slice(count).forEach(label => {
        remaining.push(label);
      });
    }
    return (
      <div>
        {count && this.getTagsValue(displayTags, count)}
        {labelsCount > (count ? count : 5) ? (
          <Popover
            title={
              getCurrentlocaleText("labels.moreCap") +
              " " +
              getCurrentlocaleText("labels.text")
            }
            content={
              <div style={{ width: "auto" }}>
                {this.getTagsValue(remaining, count)}
              </div>
            }
            placement={this.props.isModal ? "topLeft" : "top"}
            overlayStyle={{ width: this.props.viewOnly ? 215 : 250 }}
          >
            {count
              ? labelsCount - count + " " + getCurrentlocaleText("labels.more")
              : labelsCount + " Labels"}
            &nbsp;&nbsp;
          </Popover>
        ) : !count ? (
          this.getTagsValue(displayTags, null)
        ) : null}

        {this.state.inputVisible && !this.state.showDefault && (
          <span>
            <AutoComplete
              dataSource={dataSource}
              style={{ width: 100 }}
              placeholder="Key"
              onChange={this.handleInputKeyChange}
              autoFocus={true}
              disabled={this.state.isDisabled}
              maxLength={64}
              value={inputKey}
              onFocus={this.checkforHelp}
              onBlur={() => {
                this.props.handleChange(labels);
              }}
              onSelect={(key, option) => {
                this.setState({ isDisabled: true });
                this.props.handleChange(labels);
              }}
            />
            <span style={{ marginRight: 3, marginLeft: 3 }}>:</span>
            <Input
              ref={this.valueInputRef}
              type="text"
              maxLength={64}
              size="default"
              style={{ width: 78 }}
              value={inputValue}
              onChange={this.handleDefaultInputValueChange}
              onBlur={() => {
                this.handleInputConfirm("new");
              }}
              onPressEnter={() => {
                this.handleInputConfirm("new");
              }}
              placeholder="Value"
            />
            {(this.state.UnderscoreValidation.includes("new") ||
              this.state.colon_validation.includes("new") ||
              this.state.colon_validation.includes(this.state.inputKey) ||
              this.state.UnderscoreValidation.includes(
                this.state.inputKey,
              )) && (
              <p className="ant-form-explain" style={{ color: "red" }}>
                {LableErrorCodes[this.state.Errorcode]}
              </p>
            )}
          </span>
        )}
        {!this.state.inputVisible &&
          !this.state.viewOnly &&
          this.currentLabelCount(this.state.labels) <
            getOrgPolicyCounInfo(this.orgModel.org, "label_count") && (
            <Button size="small" type="dashed" onClick={this.showInput}>
              {getCurrentlocaleText("general.label.input.new_label.text")}
            </Button>
          )}
      </div>
    );
  }
}
export default LabelProvider;
