import React, { Component } from "react";
import { Row, Col, Tag, Popover, Table, Button, Select } from "antd";
import CellularSignal from "../../assets/img/cellular_signal.svg";

import LoadingComponent from "components/UI-Components/LoadingComponent";
import { observer, inject } from "mobx-react";
import InterfaceContainer from "components/INodes/InterfaceContainer";
import SerialImage from "../../assets/img/serial.svg";
import EthernetImage from "../../assets/img/ethernet.svg";

import Cellular0 from "../../assets/img/cellular_signal_0.svg";
import Cellular1 from "../../assets/img/cellular_signal_1.svg";
import Cellular2 from "../../assets/img/cellular_signal_2.svg";
import Cellular3 from "../../assets/img/cellular_signal_3.svg";
import Cellular4 from "../../assets/img/cellular_signal_4.svg";

import {
  getCurrentlocaleText,
  copyObject,
  INTERFACE_DIAGRAM_AVAILABLE_HARDWARES,
  NOT_APPLICAPLE,
} from "Core/Utils";
import Icons from "../UI-Components/Icons";
import InodeController from "../../controller/InodeController";
import InterfaceGroupReAssignModal from "components/Containers/InterfaceGroupReAssignModal";
import InterfaceDiagram from "components/UI-Components/InterfaceDiagram";
import PopOverHelp from "components/UI-Components/PopOverHelp";

// default Signal Quality
const SIGNALSTRENGTH_STRINGS = {
  EXCELLENT: {
    text: "Excellent",
    color: "green",
    class: "sgl-qty-excellent",
    icon: Cellular4,
    title: getCurrentlocaleText("wwan-ineterface-signalstrength4"),
  },
  GOOD: {
    text: "Good",
    color: "yellow",
    class: "sgl-qty-good",
    icon: Cellular3,
    title: getCurrentlocaleText("wwan-ineterface-signalstrength3"),
  },
  FAIR: {
    text: "Fair",
    color: "yellow",
    class: "sgl-qty-fair",
    icon: Cellular2,
    title: getCurrentlocaleText("wwan-ineterface-signalstrength2"),
  },
  POOR: {
    text: "Poor",
    color: "red",
    class: "sgl-qty-poor",
    icon: Cellular1,
    title: getCurrentlocaleText("wwan-ineterface-signalstrength1"),
  },
  NO_SIGNAL: {
    text: "No Signal",
    color: "",
    class: "",
    icon: Cellular0,
    title: getCurrentlocaleText("wwan-ineterface-signalstrength0"),
  },
};
@inject(
  "ModelStore",
  "AuthStore",
  "UiStore",
  "InodeViewModel",
  "NetworkViewModel",
)
@observer
class InodeInterfaces extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = this.props.InodeViewModel;
    this.networkModel = this.props.NetworkViewModel;
    this.state = {
      BreadcrumbList: [],
      selectedNode: {},
      disableReboot: true,
      availableNetwork: [],
      expandedRow: [],
      orgsNetwork: [],
      loading: false,
      isEditMode: false,
      netplan: [],
      tanInterfaces: [],
      freeInterfaces: [],
      wanInterfaces: [],
      showModal: false,
      currentRecord: null,
      fromGroup: null,
      toGroup: null,
      showInterfaceDiagram: false,
    };
  }

  onExpand = (expanded, record) => {
    if (record.network_id != null) {
      let keys = this.state.expandedRow;
      let index = keys.findIndex(val => {
        return val === record.key;
      });
      if (expanded) {
        if (index === -1) {
          keys.push(record.key);
        }
        this.setExpandedRowKeys(keys);
      } else {
        if (index !== -1) {
          keys.splice(index, 1);
        }
        this.setExpandedRowKeys(keys);
      }
    }
  };
  setExpandedRowKeys = keys => {
    this.setState({ expandedRow: keys, defaultActiveKey: "1" });
  };

  componentDidMount() {
    setTimeout(() => {
      this.showRsi();
    }, 1000);
    this.callRsiAtInterval();
    this.fetchNodeNetplan(this.props.nodeId);
  }

  componentWillUnmount() {
    clearInterval(this.timeInterval);
    this.inodeModel.signalStrength = null;
  }

  fetchNodeNetplan = nodeId => {
    this.setState({ loading: true, netplan: [] });
    InodeController.getNodeNetplan(nodeId)
      .then(response => {
        let netplan = this.formNetplanArray(response);
        this.setState({ netplan: netplan, loading: false });
      })
      .catch(err => {
        this.setState({ netplan: [], loading: false });
      });
  };

  formNetplanArray = netplanObject => {
    let netplan = [];
    if (netplanObject) {
      if (netplanObject.wan) {
        netplan = netplan.concat(
          ...netplanObject.wan.map(item => {
            item.group = "wan";
            item.originalGroup = item.group;
            return item;
          }),
        );
      }
      if (netplanObject.tan) {
        netplan = netplan.concat(
          ...netplanObject.tan.map(item => {
            item.group = "tan";
            item.originalGroup = item.group;
            return item;
          }),
        );
      }
      if (netplanObject.free) {
        netplan = netplan.concat(
          ...netplanObject.free.map(item => {
            item.group = "free";
            item.originalGroup = item.group;
            return item;
          }),
        );
      }
    }
    return netplan;
  };

  callRsiAtInterval = () => {
    this.timeInterval = setInterval(() => {
      this.showRsi();
    }, 30000);
  };

  showRsi = () => {
    if (
      this.inodeModel.inode &&
      this.inodeModel.inode.status &&
      this.inodeModel.inode.status.node &&
      this.inodeModel.inode.status.node.interfaces &&
      this.inodeModel.inode.status.node.interfaces.length > 0
    ) {
      /* check for wan or lan in interface for RSI value */
      let wInterface = null;
      this.inodeModel.inode.status.node.interfaces.map(val => {
        if (
          val.type.toLowerCase() === "wwan" ||
          val.type.toLowerCase() === "ppp"
        ) {
          wInterface = val.name;
        }
      });

      if (wInterface) {
        this.props.ModelStore.Get(
          "netstats",
          { id: this.inodeModel.inode.id },
          { filter_by: wInterface, size: 1 },
        ).then(resp => {
          if (resp.length > 0) {
            let x = resp[resp.length - 1];
            this.inodeModel.signalStrength = x.signalStrength
              ? parseInt(x.signalStrength)
              : null;
          }
        });
      }
    }
  };

  showAttrs = (allAttrs, record, attrs) => {
    return attrs.map((val, i) => {
      if (allAttrs[val] != null) {
        return (
          <Row key={val}>
            <Col span={6}>
              <strong>{InterfaceContainer.getLabelName(val)} :</strong>
            </Col>
            <Col span={18}>
              {val === "flags" ? (
                this.generateFlags(allAttrs[val], record)
              ) : val === "signalstrength" ? (
                this.computeSignalStrengthTag(allAttrs[val], record)
              ) : (
                <code>{allAttrs[val]}</code>
              )}
            </Col>
          </Row>
        );
      }
    });
  };

  showInfo = (allAttrs, record) => {
    let orderedAttrs = [
      "ip_address",
      "mtu",
      "speed",
      "ssid",
      "last_connected_time",
      "operator_name",
      "apn",
      "band",
      "signalstrength",
      "imsi",
      "iccid",
      "uart",
      "irq",
      "flags",
      "mac_address",
      "wlan_mode",
      "port_address",
      "wan_vlan_id",
    ];

    return (
      <div key={1}>
        <div>{this.showAttrs(allAttrs, record, orderedAttrs)}</div>
      </div>
    );
  };
  /**
   * Generate more info dynamically
   */
  getMoreInfo(record) {
    let attributes = record.innerdata;
    const data = [];
    data.push(this.showInfo(attributes, record));
    return data;
  }
  /**
   * Generate Flags for serial
   */
  generateFlags = (flags, record) => {
    // default DB9 PINOUTS
    const default_flags = [
      "CD",
      "RD",
      "TD",
      "DTR",
      "GND",
      "DSR",
      "RTS",
      "CTS",
      "RI",
    ];
    // enabled pinouts array separated by '|' symbol
    let available_flags = flags.split("|");
    //generate tags
    return default_flags.map(f => {
      const tagElem = (
        <span key={f + "span"}>
          <Tag
            key={f}
            className={
              (record.node_state &&
                record.node_state.toLowerCase() === "unreachable") ||
              (record.node_state &&
                record.node_state.toLowerCase() === "rebooting")
                ? ""
                : available_flags.includes(f)
                ? "iotium-success"
                : ""
            }
          >
            {f}
          </Tag>
        </span>
      );
      return tagElem;
    });
  };

  /**
   * Generate Signal Strength Tag
   */
  computeSignalStrengthTag = (strength_value, record) => {
    // strength_value = parseInt(strength_value,10);
    let status = "NO_SIGNAL";
    if (strength_value > 30 && strength_value <= 46) status = "POOR";
    else if (strength_value > 46 && strength_value <= 62) status = "FAIR";
    else if (strength_value > 62 && strength_value <= 78) status = "GOOD";
    else if (strength_value > 78) status = "EXCELLENT";
    else status = "NO_SIGNAL";

    var IconType = SIGNALSTRENGTH_STRINGS[status].icon;

    return (
      <span title={SIGNALSTRENGTH_STRINGS[status].title}>
        <Tag className={SIGNALSTRENGTH_STRINGS[status].class}>
          <IconType
            style={{
              marginBottom: -2,
              marginRight: 2,
              width: 20,
              height: 20,
            }}
          />
          {SIGNALSTRENGTH_STRINGS[status].text}
        </Tag>
      </span>
    );
  };

  computeInterfaceData = () => {
    let interface_data = copyObject(this.inodeModel.InterfaceInfo);
    let netplan = this.state.netplan;
    netplan.forEach(netInterface => {
      let index = interface_data.findIndex(item => {
        return item.name == netInterface.name;
      });
      if (index !== -1) {
        interface_data[index].group = netInterface.group;
        interface_data[index].originalGroup = netInterface.originalGroup;
        interface_data[index].name = netInterface.label;
        interface_data[index].label = netInterface.label;
        interface_data[index].originalName = netInterface.name;
        interface_data[index].is_available = netInterface.is_available;
      }
    });
    return interface_data;
  };

  handleInterfaceGroupChange = (value, record) => {
    let toGroup = value;
    let tanInterfaces = this.state.tanInterfaces;
    let freeInterfaces = this.state.freeInterfaces;
    let wanInterfaces = this.state.wanInterfaces;
    let netplan = this.state.netplan;
    switch (toGroup) {
      case "tan":
        {
          if (record.originalGroup !== toGroup) {
            tanInterfaces.push(record);
          }
          let index = freeInterfaces.findIndex(item => {
            return item && item.display_name === record.display_name;
          });
          let wanIndex = wanInterfaces.findIndex(item => {
            return item && item.display_name === record.display_name;
          });
          if (index !== -1) {
            freeInterfaces.splice(index, 1);
          }
          if (wanIndex !== -1) {
            wanInterfaces.splice(wanIndex, 1);
          }
          // Update the netplan
          index = this.state.netplan.findIndex(item => {
            return item && item.name === record.originalName;
          });
          if (index !== -1) {
            netplan[index].group = toGroup;
          }
        }
        break;
      case "free":
        {
          if (record.originalGroup !== toGroup) {
            freeInterfaces.push(record);
          }
          let index = tanInterfaces.findIndex(item => {
            return item && item.display_name === record.display_name;
          });
          let wanIndex = wanInterfaces.findIndex(item => {
            return item && item.display_name === record.display_name;
          });
          if (wanIndex !== -1) {
            wanInterfaces.splice(wanIndex, 1);
          }
          if (index !== -1) {
            tanInterfaces.splice(index, 1);
          }
          // Update the netplan
          index = this.state.netplan.findIndex(item => {
            return item && item.name === record.originalName;
          });
          if (index !== -1) {
            netplan[index].group = toGroup;
          }
        }
        break;

      case "wan":
        {
          if (record.originalGroup !== toGroup) {
            wanInterfaces.push(record);
          }
          let index = tanInterfaces.findIndex(item => {
            return item && item.display_name === record.display_name;
          });
          if (index !== -1) {
            tanInterfaces.splice(index, 1);
          }
          let freeIndex = freeInterfaces.findIndex(item => {
            return item && item.display_name === record.display_name;
          });
          if (freeIndex !== -1) {
            freeInterfaces.splice(freeIndex, 1);
          }
          // Update the netplan
          index = this.state.netplan.findIndex(item => {
            return item && item.name === record.originalName;
          });
          if (index !== -1) {
            netplan[index].group = toGroup;
          }
        }
        break;
    }
    this.setState({
      netplan: netplan,
      tanInterfaces: tanInterfaces,
      freeInterfaces: freeInterfaces,
      wanInterfaces: wanInterfaces,
    });
  };

  handleConfirm = () => {
    if (
      this.state.tanInterfaces.length > 0 ||
      this.state.freeInterfaces.length > 0 ||
      this.state.wanInterfaces.length > 0
    ) {
      let netplanUpdateData = {
        TAN: {
          interfaces: this.state.tanInterfaces.map(
            item => item && item.display_name,
          ),
        },
        FREE: {
          interfaces: this.state.freeInterfaces.map(
            item => item && item.display_name,
          ),
        },
        WAN: {
          interfaces: this.state.wanInterfaces.map(
            item => item && item.display_name,
          ),
        },
      };
      this.setState({ loading: true }, () => {
        InodeController.updateNodeNetplan(this.props.nodeId, netplanUpdateData)
          .then(response => {
            let netplan = this.formNetplanArray(response);
            this.setState({
              loading: false,
              showModal: false,
              netplan: netplan,
              isEditMode: false,
              tanInterfaces: [],
              freeInterfaces: [],
              wanInterfaces: [],
            });
          })
          .catch(error => {
            this.setState({
              loading: false,
              isEditMode: false,
              tanInterfaces: [],
              freeInterfaces: [],
              wanInterfaces: [],
            });
            this.fetchNodeNetplan(this.props.nodeId);
          });
      });
    } else {
      this.setState({
        loading: false,
        isEditMode: false,
      });
    }
  };

  closeModal = () => {
    this.setState({
      showModal: false,
    });
  };

  checkImageAvailable = vendorDetails => {
    let vendorDetail = vendorDetails.split("/");
    if (vendorDetail.length > 1) {
      let modelName = vendorDetail[1];
      modelName = modelName.replace(/ /g, "_");
      if (INTERFACE_DIAGRAM_AVAILABLE_HARDWARES.includes(modelName)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  onClickInterfaceDetails = () => {
    this.setState({ showInterfaceDiagram: !this.state.showInterfaceDiagram });
  };
  render() {
    let cluster_id =
      this.inodeModel.inode.cluster && this.inodeModel.inode.cluster.id
        ? this.inodeModel.inode.cluster.id
        : null;
    let interface_data = this.computeInterfaceData();
    let NetworkinterfaceIconStyle = {
      height: 20,
      width: 24,
      marginTop: 3,
      marginBottom: -4,
    };

    let node_data = this.props.currentInode;
    const isInterfaceDiagramAvailable =
      node_data.status &&
      node_data.status.node &&
      node_data.status.node.info &&
      node_data.status.node.info.device_vendor_details
        ? this.checkImageAvailable(
            node_data.status.node.info.device_vendor_details,
          )
        : false;

    let multinic_mode =
      this.inodeModel.inode &&
      this.inodeModel.inode.multinic_mode &&
      this.inodeModel.inode.multinic_mode.enable
        ? this.inodeModel.inode.multinic_mode.enable
        : false;
    this.columns = [
      {
        title: getCurrentlocaleText("orgs.tablelist.namecolumn.text"),
        dataIndex: "name",
        sorter: (a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          return 0;
        },
        key: "name",
        render: (text, record, index) => {
          return (
            <span>
              <code>
                {record.type &&
                record.type.toLowerCase() === "serial" &&
                record.tty_name
                  ? record.name + " (" + record.tty_name + ")"
                  : record.type &&
                    (record.type.toLowerCase() === "wwan" ||
                      record.type.toLowerCase() === "ppp")
                  ? getCurrentlocaleText("inode.interface.cellular.label.text")
                  : (record.type && record.type.toLowerCase() === "wlan") ||
                    record.type.toLowerCase() === "wireless"
                  ? getCurrentlocaleText("inode.interface.wireless.label.text")
                  : record.originalName
                  ? record.name + " (" + record.originalName + ")"
                  : record.name}
              </code>
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText("inode.interface.type.label.text"),
        dataIndex: "type",
        key: "type",
        render: (text, record, index) => {
          return <span>{InterfaceContainer.getLabelName(record.type)}</span>;
        },
      },
      {
        title: getCurrentlocaleText(
          "inode.interface.hardware_identifier.label.text",
        ),
        dataIndex: "address",
        key: "address",
        render: (text, record, index) => {
          return (
            <span
              style={{
                textAlign: !record.hardware_address ? "center" : "left",
              }}
            >
              <code>
                {record.hardware_address ? record.hardware_address : "-"}
              </code>
            </span>
          );
        },
      },
      {
        title: (
          <span>
            {getCurrentlocaleText("inode.interface.link_status.label.text")}
            <PopOverHelp
              isHelpOnForm={true}
              height={210}
              width={250}
              placement="right"
              helpData={{
                data: [
                  {
                    subtitle: "",
                    content: getCurrentlocaleText(
                      "inode.interfaces.down.legend.message",
                    ),
                  },
                  {
                    subtitle: "",
                    content: getCurrentlocaleText(
                      "inode.interfaces.up.legend.message",
                    ),
                  },
                  {
                    subtitle: "",
                    content: getCurrentlocaleText(
                      "inode.interfaces.active.legend.message",
                    ),
                  },
                  {
                    subtitle: "",
                    content: getCurrentlocaleText(
                      "inode.interfaces.unknown.legend.message",
                    ),
                  },
                ],
              }}
            />
          </span>
        ),
        dataIndex: "status",
        key: "status",
        render: (text, record, index) => {
          return (record.node_state &&
            record.node_state.toLowerCase() === "unreachable") ||
            (record.node_state &&
              record.node_state.toLowerCase() === "rebooting") ||
            (record.status && record.status.toLowerCase() === "down") ? (
            <Tag>
              <strong>
                {record.status && record.status.toLowerCase() === "up"
                  ? "UNKNOWN"
                  : record.status && record.status.toUpperCase()}
              </strong>
            </Tag>
          ) : (
            <Popover
              content={
                <span>
                  <span>
                    {InterfaceContainer.getLabelName("mtu") + ":" + " "}
                    {record.mtu}
                  </span>
                  <br />
                  <span>
                    {InterfaceContainer.getLabelName("speed") + ":" + " "}
                    {record.speed ? record.speed : "-"}
                  </span>
                </span>
              }
              trigger="hover"
            >
              <span>
                <Tag
                  className={
                    (record.status && record.status.toLowerCase() === "up") ||
                    (record.status &&
                      record.status.toLowerCase() === "active" &&
                      record.node_state &&
                      record.node_state.toLowerCase() != "unreachable" &&
                      record.node_state &&
                      record.node_state.toLowerCase() != "rebooting")
                      ? "tag-iotium-success"
                      : null
                  }
                >
                  <strong>
                    {record.status
                      ? (record.node_state &&
                          record.node_state.toLowerCase() === "unreachable") ||
                        (record.node_state &&
                          record.node_state.toLowerCase() === "rebooting")
                        ? "UNKNOWN"
                        : record.status.toLowerCase() === "active" &&
                          !multinic_mode
                        ? "UP"
                        : record.status.toUpperCase()
                      : "DOWN"}
                  </strong>
                </Tag>
              </span>
            </Popover>
          );
        },
      },
      {
        title: getCurrentlocaleText("general.available.text"),
        key: "availability",
        render: (text, record, index) => {
          return (
            <span>
              {record.is_available !== undefined &&
              record.is_available !== null ? (
                <span>{record.is_available ? "Yes" : "No"}</span>
              ) : (
                "-"
              )}
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText("service_listener.port.text"),
        key: "port",
        render: (text, record, index) => {
          let type = record.type && record.type.toLowerCase();
          return (
            <span title={InterfaceContainer.getLabelName(record.type)}>
              {type === "wired" ? (
                <EthernetImage style={NetworkinterfaceIconStyle} />
              ) : type === "wwan" || type === "ppp" ? (
                <CellularSignal className="custom-icon-celluar " />
              ) : type === "wireless" || type === "wlan" ? (
                <Icons
                  type="ai"
                  name="AiOutlineWifi"
                  className="interface-iconstyle"
                />
              ) : type === "vlan" ? (
                <Icons
                  type="md"
                  name="MdDeviceHub"
                  className="interface-iconstyle"
                />
              ) : type === "lag" || type === "bond" ? (
                <Icons
                  type="md"
                  name="MdDomain"
                  className="interface-iconstyle"
                />
              ) : type === "serial" ? (
                <SerialImage
                  style={{
                    height: 25,
                    width: 35,
                    marginLeft: -5,
                    marginBttom: -1,
                  }}
                />
              ) : (
                <Icons
                  type="md"
                  name="MdSettingsEthernet"
                  className="interface-iconstyle"
                />
              )}
            </span>
          );
        },
      },
      {
        title: (
          <span>
            {getCurrentlocaleText("assignto.text")}
            <PopOverHelp
              isHelpOnForm={true}
              height={180}
              width={250}
              placement="right"
              helpData={{
                data: [
                  {
                    subtitle: "",
                    content: getCurrentlocaleText(
                      "re-assign.interface.group.help.text1",
                    ),
                  },
                  {
                    subtitle: "",
                    content: getCurrentlocaleText(
                      "re-assign.interface.group.help.text2",
                    ),
                  },
                  {
                    subtitle: "",
                    content: getCurrentlocaleText(
                      "re-assign.interface.group.help.text3",
                    ),
                  },
                ],
              }}
            />
          </span>
        ),
        key: "assignto",
        render: (text, record, index) => {
          return (
            <span>
              {record.group ? (
                <Select
                  defaultValue={record.group}
                  value={record.group}
                  disabled={
                    !this.state.isEditMode ||
                    !record.is_available ||
                    record.is_default
                  }
                  onChange={value => {
                    this.handleInterfaceGroupChange(value, record);
                  }}
                  options={[
                    {
                      value: "wan",
                      label: "WAN",
                    },
                    {
                      value: "tan",
                      label: "TAN",
                    },
                    {
                      value: "free",
                      label: "FLOATING",
                    },
                  ]}
                />
              ) : (
                "-"
              )}
            </span>
          );
        },
      },
    ];

    if (cluster_id || !multinic_mode) {
      // Remove Available column
      this.columns.splice(4, 1);
      // Remove AssignTo column
      this.columns.splice(5, 1);
    }

    let container = (
      <div>
        {/* Action Bar */}
        {this.props.AuthStore.IsPermitted("NODE:CREATE") &&
          !cluster_id &&
          multinic_mode && (
            <div className="action-bar">
              <div className="action-bar-container">
                {/* Edit Button */}
                {!this.state.isEditMode && (
                  <span className="action-bar-item">
                    <span>
                      <Button
                        className="action-btn action-bar-button"
                        title={getCurrentlocaleText("general.edit.text")}
                        disabled={true}
                        onClick={() => {
                          this.setState({ isEditMode: !this.state.isEditMode });
                        }}
                      >
                        <Icons
                          type="ai"
                          name="AiOutlineEdit"
                          className="valign-middle"
                          style={{ marginRight: 5 }}
                        />
                        <span style={{ margin: 0 }}>
                          {getCurrentlocaleText("general.edit.text")}
                        </span>
                      </Button>
                    </span>
                  </span>
                )}
                {/* Cancel Button */}
                {this.state.isEditMode && (
                  <span className="action-bar-item">
                    <span>
                      <Button
                        className="action-btn action-bar-button"
                        title={getCurrentlocaleText(
                          "general.form.button.cancel.text",
                        )}
                        onClick={() => {
                          if (
                            this.state.tanInterfaces.length > 0 ||
                            this.state.freeInterfaces.length > 0
                          ) {
                            this.fetchNodeNetplan(this.props.nodeId);
                          }
                          this.setState({
                            isEditMode: false,
                            tanInterfaces: [],
                            freeInterfaces: [],
                          });
                        }}
                      >
                        <Icons
                          type="ai"
                          name="AiOutlineClose"
                          className="valign-middle"
                          style={{ marginRight: 5 }}
                        />
                        <span style={{ margin: 0 }}>
                          {getCurrentlocaleText(
                            "general.form.button.cancel.text",
                          )}
                        </span>
                      </Button>
                    </span>
                  </span>
                )}
                {/* Save Button */}
                {this.state.isEditMode && (
                  <span className="action-bar-item">
                    <span>
                      <Button
                        className="action-btn action-bar-button"
                        title={getCurrentlocaleText("save.button.text")}
                        onClick={() => {
                          if (
                            this.state.tanInterfaces.length > 0 ||
                            this.state.freeInterfaces.length > 0 ||
                            this.state.wanInterfaces.length > 0
                          ) {
                            this.setState({ showModal: true });
                          } else {
                            this.setState({
                              loading: false,
                              isEditMode: false,
                            });
                          }
                        }}
                      >
                        <Icons
                          type="ai"
                          name="AiOutlineSave"
                          className="valign-middle"
                          style={{ marginRight: 5 }}
                        />
                        <span style={{ margin: 0 }}>
                          {getCurrentlocaleText("save.button.text")}
                        </span>
                      </Button>
                    </span>
                  </span>
                )}
              </div>
            </div>
          )}
        <div>
          <Row gutter={2} type="flex" align="top">
            <Col className="card-grid" span={24}>
              {interface_data.length > 0 ? (
                <Table
                  columns={this.columns}
                  dataSource={interface_data}
                  size={"middle"}
                  pagination={false}
                  indentSize={5}
                  onExpand={this.onExpand}
                  expandedRowRender={record => {
                    if (record.innerdata) return this.getMoreInfo(record);
                    else return false;
                  }}
                />
              ) : (
                getCurrentlocaleText("inode.interface.nocontent.label.text")
              )}
            </Col>
          </Row>
          <div>
            {this.state.showModal && (
              <InterfaceGroupReAssignModal
                visible={this.state.showModal}
                closeModal={this.closeModal}
                handleConfirm={this.handleConfirm}
                interfaceData={{
                  tan: this.state.tanInterfaces,
                  free: this.state.freeInterfaces,
                  wan: this.state.wanInterfaces,
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
    return (
      <div>
        <LoadingComponent
          loading={
            !this.inodeModel.loading &&
            !this.networkModel.loading &&
            !this.state.loading
              ? false
              : true
          }
        >
          {node_data.profile &&
            node_data.profile.name.toLowerCase() === "edge" &&
            isInterfaceDiagramAvailable && (
              <div
                style={{ marginBottom: 32, marginLeft: 48 }}
                className="node-interface-vendor-link "
              >
                {node_data.status &&
                node_data.status.node &&
                node_data.status.node.info &&
                node_data.status.node.info.device_vendor_details
                  ? `${getCurrentlocaleText(
                      "inode.interface.hardware.text",
                    )}  ${node_data.status.node.info.device_vendor_details}`
                  : NOT_APPLICAPLE}
                <span
                  className="iot-pointer-cursor iot-primary-color-style node-vendor-link"
                  style={{ float: "right" }}
                  onClick={this.onClickInterfaceDetails}
                >
                  {getCurrentlocaleText("inode.interface.see.diagram.text")}{" "}
                  <Icons
                    type="fa"
                    name="FaExternalLinkAlt"
                    className="middle"
                  />
                </span>
              </div>
            )}
          {this.state.showInterfaceDiagram && (
            <InterfaceDiagram
              visibility={this.state.showInterfaceDiagram}
              vendorDetails={
                node_data.status &&
                node_data.status.node &&
                node_data.status.node.info &&
                node_data.status.node.info.device_vendor_details
                  ? `${node_data.status.node.info.device_vendor_details}`
                  : NOT_APPLICAPLE
              }
              onClose={this.onClickInterfaceDetails}
            />
          )}
          {container}
        </LoadingComponent>
      </div>
    );
  }
}

export default InodeInterfaces;
