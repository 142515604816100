import ReactResource from "./ReactResource";

/* 'Configuration for individual resources'
-------------------------------------------------------------- */
const config = {
  baseUrl: "/api/v1",
  resources: {
    orgs: {
      url: "/org/{:id}",
      map: { id: ":id" },
      params: {},
      header: {},
    },
    inodes: {
      url: "/node/{:id}",
      map: { id: ":id" },
      params: {},
      header: {},
    },
    clustertrafficinsight: {
      url: "/cluster/{:id}/trafficinsight",
      map: { id: ":id" },
      params: {},
      header: {},
    },

    trafficinsight: {
      url: "/node/{:id}/trafficinsight",
      map: { id: ":id" },
      params: {},
      header: {},
    },
    resource_limits: {
      url: "/node/{:id}/resource_limits",
      map: { id: ":id" },
      params: {},
      header: {},
    },
    device_discovery_scan_list: {
      url: "/{:point}/{:id}/discovery/scan",
      map: { id: ":id" },
      params: {},
    },
    device_discovery_scan: {
      url: "/network/{:id}/discovery/scan",
      map: { id: ":id" },
      params: {},
    },
    device_discovery_scan_run_now: {
      url: "/network/{:id}/discovery/scan/{:scanId}/run",
      map: { id: ":id" },
      params: {},
    },
    device_discovery_scan_unique: {
      url: "/network/{:nwId}/discovery/scan/{:id}",
      map: { id: ":id" },
      params: {},
    },
    device_discovery_reports_list: {
      url: "/{:point}/{:id}/discovery/report",
      map: { id: ":id" },
      params: {},
    },
    device_discovery_report_by_id: {
      url: "/{:point}/{:id}/discovery/report/{:reportId}",
      map: { id: ":id" },
      params: {},
    },
    device_discovery_report_download: {
      url: "/{:point}/{:id}/discovery/report/{:reportId}/download",
      map: { id: ":id" },
      params: {},
    },
    device_discovery_report_diff: {
      url: "/network/{:nwId}/discovery/report-diff",
      map: { id: ":id" },
      params: {},
    },
    profiles: {
      url: "/profile/{:id}",
      map: { id: ":id" },
      params: {},
      header: {},
    },
    users: {
      url: "/user/{:id}",
      map: { id: ":id" },
      params: {},
      header: {},
    },
    roles: {
      url: "/role/{:id}",
      map: { id: ":id" },
      params: {},
      header: {},
    },
    pki: {
      url: "/pki/{:id}",
      map: { id: ":id" },
      params: {},
      header: {},
    },
    downloaddefaultsshkey: {
      url: "/pki/{:id}/defaultsshkey",
      map: { id: ":id" },
      params: {},
      header: {},
    },
    networks: {
      url: "/network/{:id}",
      map: { id: ":id" },
      params: {},
      header: {},
    },
    repNet: {
      url: "/repnet/",
      params: {},
    },
    downloadcert: {
      url: "/node/{:id}/download",
      map: { id: ":id" },
      params: {},
      header: { Accept: "application/octet-stream" },
    },
    resetpassword: {
      url: "/user/{:id}/resetpassword",
      map: { id: ":id" },
      params: {},
    },
    unlock: {
      url: "/user/{:id}/unlock",
      map: { id: ":id" },
      params: {},
    },
    reboot: {
      url: "/node/{:id}/reboot",
      map: { id: ":id" },
      params: {},
    },
    changepassword: {
      url: "/user/changepassword",
      map: { id: ":id" },
      params: {},
    },
    discoveryreport: {
      url: "/report/network/{:network_id}/discovery",
      map: { id: ":id", network_id: ":network_id" },
      params: {},
    },
    devicediscoveryreport: {
      url: "/node/{:node_id}/discovery/result",
      map: { network_id: ":network_id" },
      params: {},
    },
    netstats: {
      url: "/report/node/{:id}/networkstats",
      map: { id: ":id" },
      params: {},
    },
    permission: {
      url: "/permission",
      params: {},
    },
    techdump: {
      url: "/node/{:id}/techdump",
      map: { id: ":id" },
      params: {},
    },
    service: {
      url: "/service/{:id}",
      map: { id: ":id" },
      params: {},
      header: {},
    },
    secret: {
      url: "/secret/{:id}",
      map: { id: ":id" },
      params: {},
      header: {},
    },
    serviceLogs: {
      url: "/report/node/{:node_id}/service/{:service_id}/log",
      map: { node_id: ":node_id", service_id: ":service_id" },
      params: {},
    },
    firewallgroup: {
      url: "/firewallgroup/{:id}",
      map: { id: ":id" },
      params: {},
      header: {},
    },
    activity: {
      url: "/activity/{:id}",
      map: { id: ":id" },
      params: {},
      header: {},
    },
    current_user: {
      url: "/user/current",
      map: {},
      params: {},
      header: {},
    },
    releases: {
      url: "/releases/{:id}",
      map: {},
      params: {},
      header: {},
    },
    force_get_release: {
      url: "/releasesync",
      map: {},
      params: {},
      header: {},
    },
    inodeLogs: {
      url: "/report/node/{:node_id}/log",
      map: { node_id: ":node_id" },
      params: {},
    },
    releases_latest: {
      url: "/releases/latest",
      map: {},
      params: {},
      header: {},
    },
    user_sessions: {
      url: "/user/{:id}/sessions",
      map: { id: ":id" },
      params: {},
    },
    current_user_sessions: {
      url: "/user/me/sessions",
      map: {},
      params: {},
    },
    mysubscriptions: {
      url: "/mysubscriptions/{:id}",
      map: {},
      params: {},
    },
    subscriptions: {
      url: "/subscriptions/{:id}",
      map: {},
      params: {},
    },
    alerts: {
      url: "/alerts",
      map: {},
      params: {},
    },
    servicetemplate: {
      url: "/servicetemplate/{:id}",
      map: {},
      params: {},
    },
    servicetemplatesync: {
      url: "/servicetemplatesync/{:id}",
      map: {},
      params: {},
    },
    verifyemail: {
      url: "/user/{:id}/verifyemail",
      map: { id: ":id" },
      params: {},
    },
    inodeEvents: {
      url: "/notification/node/{:id}/event",
      map: { id: ":id" },
      params: {},
    },
    events: {
      url: "/notification",
      map: {},
      params: {},
    },
    upgradehistory: {
      url: "/node/{:id}/upgradehistory",
      map: {},
      params: {},
    },
    inodeimages: {
      url: "/node/{:id}/image",
      map: { id: ":id" },
      params: {},
    },
    imageDeleteByName: {
      url: "/node/{:id}/image/{:image_name}",
      map: { id: ":id" },
    },
    sshkeys: {
      url: "/sshkey",
      map: {},
      params: {},
    },
    users_api_key: {
      url: "/user/current/apikey",
      map: {},
      params: {},
    },
    other_user_api_key: {
      url: "/user/{:id}/apikey",
      map: {},
      params: {},
    },
    delete_apikey: {
      url: "/user/{:id}/apikey/{:apikey_id}",
      map: {},
      params: {},
    },
    inode_logs: {
      url: "/report/node/{:node_id}/log",
      map: {},
      params: {},
    },
    download_inode_logs: {
      url: "/report/node/{:node_id}/downloadlog",
      map: {},
      params: {},
    },
    service_logs: {
      url: "/report/service/{:service_id}/{:container_id}/log",
      map: {},
      params: {},
    },
    download_service_logs: {
      url: "/report/service/{:service_id}/{:container_id}/download",
      map: {},
      params: {},
    },
    upgrade: {
      url: "/node/{:id}/upgrade",
      map: {},
      params: {},
    },
    tan_dad: {
      url: "/node/{:id}/duplicate-address-detection",
      map: {},
      params: {},
    },
    tan_dad_scan: {
      url: "/node/{:id}/duplicate-address-detection/scan-now",
      map: {},
      params: {},
    },
    hwm: {
      url: "/node/{:id}/monitoring",
      map: {},
      params: {},
    },
    topthreatsnode: {
      url: "/node/{:id}/trafficinsight/top-threats-type",
      map: {},
      params: {},
    },
    topthreatsorg: {
      url: "/org/{:id}/trafficinsight/top-threats-type",
      map: {},
      params: {},
    },
    topthreatsclassnode: {
      url: "/node/{:id}/trafficinsight/top-threats-class",
      map: {},
      params: {},
    },
    topthreatsclassorg: {
      url: "/org/{:id}/trafficinsight/top-threats-class",
      map: {},
      params: {},
    },
    topnodethreatsbycountry: {
      url: "/node/{:id}/trafficinsight/top-threats-location",
      map: {},
      params: {},
    },
    toporgthreatsbycountry: {
      url: "/org/{:id}/trafficinsight/top-threats-location",
      map: {},
      params: {},
    },
    topaffectednodes: {
      url: "/org/{:id}/trafficinsight/top-affected-inodes",
      map: {},
      params: {},
    },
    topaffecteddevices: {
      url: "/node/{:id}/trafficinsight/top-affected-devices",
      map: {},
      params: {},
    },
    topnodethreatsseverity: {
      url: "/node/{:id}/trafficinsight/threats-severity",
      map: {},
      params: {},
    },
    toporgthreatsseverity: {
      url: "/org/{:id}/trafficinsight/threats-severity",
      map: {},
      params: {},
    },
    topnodethreatsnwsummary: {
      url: "/node/{:id}/trafficinsight/threats-network-summary",
      map: {},
      params: {},
    },
    toporgthreatsnwsummary: {
      url: "/org/{:id}/trafficinsight/threats-network-summary",
      map: {},
      params: {},
    },
    nodestats: {
      url: "/report/node/{:id}/stats",
      map: {},
      params: {},
    },
    nodeoverviewstats: {
      url: "/report/node/{:id}/statssummary",
      map: {},
      params: {},
    },
    nodeservicecontainersstatssummary: {
      url: "/report/node/{:id}/servicecontainersstatssummary",
      map: {},
      params: {},
    },
    servicestats: {
      url: "/report/service/{:id}/networkstats",
      map: {},
      params: {},
    },
    branding: {
      url: "/org/{:id}/branding",
      map: {},
      params: {},
    },
    containerstats: {
      url: "/report/service/{:id}/{:container_id}/stats",
      map: {},
      params: {},
    },
    activity_downloads: {
      url: "/download/activity",
      map: {},
      params: {},
    },
    activity_downloads_delete: {
      url: "/download/activity/{:id}",
      map: {},
      params: {},
    },
    download_activity_file: {
      url: "/download/activity/{:id}/file",
      map: {},
      params: {},
    },
    event_downloads: {
      url: "/download/event",
      map: {},
      params: {},
    },
    event_downloads_delete: {
      url: "/download/event/{:id}",
      map: {},
      params: {},
    },
    download_event_file: {
      url: "/download/event/{:id}/file",
      map: {},
      params: {},
    },
    fromverifyemail: {
      url: "/org/{:id}/branding/verifyemail",
      map: { id: ":id" },
      params: {},
    },
    service_restart: {
      url: "/service/{:service_id}/restart",
      map: { id: ":id" },
      params: {},
    },
    webhook: {
      url: "/webhook/{:id}",
      map: { id: ":id" },
      params: {},
    },
    webhook_verify: {
      url: "/webhook/{:id}/verify",
      map: { id: ":id" },
      params: {},
    },
    webhook_test: {
      url: "/webhook/{:id}/test",
      map: { id: ":id" },
      params: {},
    },
    user_channel: {
      url: "/user/{:user_id}/channel/{:channel_id}",
      map: {},
      params: {},
    },
    clusters: {
      url: "/cluster/{:id}",
      map: {},
      params: {},
    },
    clusterHwm: {
      url: "/cluster/{:id}/monitoring",
      map: {},
      params: {},
    },
    defaultsshkey: {
      url: "/node/{:id}/defaultsshkey",
      map: {},
      params: {},
    },
    cluster_upgrade: {
      url: "/cluster/{:id}/upgrade",
      map: {},
      params: {},
    },
    cluster_tan_dad: {
      url: "/cluster/{:id}/duplicate-address-detection",
      map: {},
      params: {},
    },
    convert_to_cluster: {
      url: "/node/{:id}/convert-to-cluster",
      map: {},
      params: {},
    },
    cluster_tan_dad_scan: {
      url: "/cluster/{:id}/duplicate-address-detection/scan-now",
      map: {},
      params: {},
    },
    inodes_dad: {
      url: "/node/{:id}/duplicate-address-detection",
      map: { id: ":id" },
      params: {},
      header: {},
    },
    listener: {
      url: "/listener/{:id}",
      map: { id: ":id" },
      params: {},
    },
    cloudroute: {
      url: "/node/{:id}/cloudroute",
      map: { id: ":id" },
      params: {},
    },
    uploadLogo: {
      url: "/org/{:id}/branding/upload/{:logoId}",
      params: {},
      header: {},
    },
    analyticsSettings: {
      url: "/settings/analytics",
      map: {},
      params: {},
    },
    update_hsn: {
      url: "/node/{:id}/replace-hsn",
      map: { id: ":id" },
      params: {},
      header: {},
    },
    device_discovery_inode: {
      url: "/node/{:id}/discovery",
      map: {},
      params: {},
    },
    device_discovery_cluster: {
      url: "/cluster/{:id}/discovery",
      map: {},
      params: {},
    },
    device_discovery_summary: {
      url: "/{:from}/{:id}/discovery/scan-summary",
      map: {},
      params: {},
    },
    device_discovery_node: {
      url: "/node/{:id}/discovery/scan?size=1",
      map: {},
      params: {},
    },
    device_discovery_cluster_scan: {
      url: "/cluster/{:id}/discovery/scan?size=1",
      map: {},
      params: {},
    },
    node_ctd_alert_log: {
      url: "/node/{:id}/trafficinsight/threats-detail",
      map: {},
      params: {},
    },
    org_ctd_alert_log: {
      url: "/org/{:id}/trafficinsight/threats-detail",
      map: {},
      params: {},
    },
    node_threats_summary: {
      url: "/node/{:id}/trafficinsight/threats-summary",
      map: {},
      params: {},
    },
    org_threats_summary: {
      url: "/org/{:id}/trafficinsight/threats-summary",
      map: {},
      params: {},
    },
    node_download_threats: {
      url: "/node/{:id}/trafficinsight/download-threats",
      map: {},
      params: {},
    },
    org_download_threats: {
      url: "/org/{:id}/trafficinsight/download-threats",
      map: {},
      params: {},
    },
    threat_configstatus: {
      url: "/org/{:id}/trafficinsight/configstatus",
      map: {},
      params: {},
    },
    bulkUserCreate: {
      url: "/user/bulk-create",
      params: {},
      map: {},
    },
    bulkUserEdit: {
      url: "/user/bulk-edit",
      params: {},
      map: {},
    },
    bulkNodeCreate: {
      url: "/node/bulk-create",
      params: {},
      map: {},
    },
    bulkNodeEdit: {
      url: "/node/bulk-edit",
      params: {},
      map: {},
    },
    bulkNetworkCreate: {
      url: "/network/bulk-create",
      params: {},
      map: {},
    },
    bulkNetworkEdit: {
      url: "/network/bulk-edit",
      params: {},
      map: {},
    },
    bulkJobHistory: {
      url: "/bulk-job",
      params: {},
      map: {},
    },
    bulkJobStatusCsv: {
      url: "/bulk-job/{:jobId}/download",
      params: {},
      map: {},
    },
    bulkJobZipNode: {
      url: "/bulk-job/{:jobId}/inode-certificates",
      params: {},
      map: {},
    },
    bulkJobStatus: {
      url: "/bulk-job/{:jobId}",
      params: {},
      map: {},
    },
    downloadUsersTemplate: {
      url: "/user/bulk-create-template",
      params: {},
      map: {},
    },
    downloadNodesTemplate: {
      url: "/node/bulk-create-template",
      params: {},
      map: {},
    },
    downloadNetworksTemplate: {
      url: "/network/bulk-create-template",
      params: {},
      map: {},
    },
    downloadUsers: {
      url: "/user/download",
      params: {},
      map: {},
    },
    inodes_location: {
      url: "/dashboard-location-graph-information",
      params: {},
      map: {},
    },
    dashboard: {
      url: "/dashboard-graph-information",
      params: {},
      map: {},
    },
    node_netplan: {
      url: "/node/{:id}/netplan",
      params: {},
      map: {},
    },
    cluster_netplan: {
      url: "/cluster/{:id}/netplan",
      params: {},
      map: {},
    },
    downloadNodes: {
      url: "/node/download",
      params: {},
      map: {},
    },
    downloadNetworks: {
      url: "/network/download",
      params: {},
      map: {},
    },
    node_unified_cloud_gateway: {
      url: "/node/{:id}/unified-cloud-gateway",
      params: {},
      map: { id: ":id" },
    },
  },
};

class APIResource {
  constructor(config) {
    this.config = config;
    this.resourceRegistered = false;
  }

  //Instantaniate the resource objects with auth token if not.
  /**
   * @deprecated This Method is will be deleted soon.
   * @param {*} store
   */
  registerResources(store) {
    if (!this.resourceRegistered) {
      let resources = this.config.resources;
      for (var key in resources) {
        let url = this.config.baseUrl
            ? this.config.baseUrl + resources[key].url
            : resources[key].url,
          map = resources[key].map,
          header = resources[key].header || {},
          actions = resources[key].actions;
        // Instantiate the resource
        this[key] = ReactResource(url, map, header, actions);
      }
      this.resourceRegistered = true;
    }
  }

  /**
   * @deprecated This Method is will be deleted soon.
   * @param {*} store
   */
  forceRegisterResources(store) {
    this.resourceRegistered = false;
    this.registerResources(store);
  }

  /**
   * @deprecated This Method is will be deleted soon.
   */
  unregisterResources() {
    this.resourceRegistered = false;
  }

  getUrl(resource) {
    return config.baseUrl + config.resources[resource].url;
  }
}

const Resources = new APIResource(config);
export default Resources;
