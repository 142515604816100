import React, { useState } from "react";
import { Row, Col, Button, Modal, Form, Input } from "antd";
import * as ip from "ip";
import {
  getCurrentlocaleText,
  getIPListFromCidr,
  IPADDRESSREGEX,
} from "../../Core/Utils";
import { downloadLookup } from "../../controller/RepNetController";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileExport,
  faCodeCompare,
  faEquals,
} from "@view/pro-regular-svg-icons";

const RepNetTableLookup = props => {
  const [form] = Form.useForm();
  const [disableLookupBtn, setDisableLookupBtn] = useState(true);
  const wholeObj = props.cidrData;
  const recordsCount = 25;

  const handleNativeIpChange = () => {
    form.setFieldsValue({
      repnetIp: "",
    });
  };

  const handleRepNetIpChange = () => {
    form.setFieldsValue({
      nativeIp: "",
    });
  };

  const onFinish = values => {
    if (values && values.nativeIp) {
      form.setFieldsValue({
        repnetIp: convertIP(
          wholeObj.native_cidr.cidr,
          wholeObj.repnet_cidr.cidr,
          values.nativeIp,
        ),
      });
    }

    if (values && values.repnetIp) {
      form.setFieldsValue({
        nativeIp: convertIP(
          wholeObj.repnet_cidr.cidr,
          wholeObj.native_cidr.cidr,
          values.repnetIp,
        ),
      });
    }

    setDisableLookupBtn(true);
  };

  const convertIP = (fromCidr, toCidr, fromIp) => {
    // Parse the from IP
    const parsedFromIP = ip.toLong(fromIp);

    // Get the network address difference
    const nativeBase = ip.cidrSubnet(fromCidr).firstAddress;
    const repnetBase = ip.cidrSubnet(toCidr).firstAddress;
    const diff = parsedFromIP - ip.toLong(nativeBase);

    // Calculate the corresponding repnet IP
    const repnetLong = ip.toLong(repnetBase) + diff;
    const toIp = ip.fromLong(repnetLong);

    return toIp;
  };

  const checkIpV4 = (rule, value, callback, fromField, cidr) => {
    let message;
    if (!IPADDRESSREGEX.test(value)) {
      message = "Invalid IP address";
      setDisableLookupBtn(true);
    } else if (!ip.cidrSubnet(cidr).contains(value)) {
      message = "IP address is not in the CIDR range";
      setDisableLookupBtn(true);
    } else {
      //No error case
      setDisableLookupBtn(false);
    }

    // Reset the error msg on submit to make sure only one field is required
    if (fromField == "nativeIp" && form && form.getFieldValue("repnetIp")) {
      message = undefined;
    }
    if (fromField == "repnetIp" && form && form.getFieldValue("nativeIp")) {
      message = undefined;
    }

    callback(message);
  };

  const getLookupContent = recordsCount => {
    let nativeCidr = [];
    let repNetCidr = [];
    if (
      wholeObj &&
      wholeObj.native_cidr &&
      wholeObj.native_cidr.start_ip &&
      wholeObj.native_cidr.start_ip
    )
      nativeCidr = getIPListFromCidr(
        wholeObj.native_cidr.start_ip,
        wholeObj.native_cidr.end_ip,
        recordsCount,
      );
    if (
      wholeObj &&
      wholeObj.repnet_cidr &&
      wholeObj.repnet_cidr.start_ip &&
      wholeObj.repnet_cidr.start_ip
    )
      repNetCidr = getIPListFromCidr(
        wholeObj.repnet_cidr.start_ip,
        wholeObj.repnet_cidr.end_ip,
        recordsCount,
      );

    const convertedObjects = nativeCidr.map((element, index) => {
      return [element, repNetCidr[index]];
    });

    return convertedObjects;
  };

  const handleExportLookup = () => {
    const val = [["Native IP", "RepNet IP"]];
    const allObjects = getLookupContent();
    downloadLookup([...val, ...allObjects]);
  };

  const convertedObjects = getLookupContent(recordsCount);

  return (
    <Modal
      title={getCurrentlocaleText("repnet.table.lookup.title")}
      visible={true}
      closable={true}
      maskClosable={false}
      className="repnet-lookup"
      footer={[
        <div key={"lookup-actions"}>
          <Button
            key="handleCancel"
            type="link"
            size="large"
            className="border-radius-4"
            onClick={props.handleCancel}
          >
            {getCurrentlocaleText("roles.details.button.go_back.text")}
          </Button>
          <Button
            key="handleOk"
            type="primary"
            ghost
            size="large"
            className="border-radius-4 btn-rounded"
            onClick={handleExportLookup}
          >
            <FontAwesomeIcon icon={faFileExport} className="pr-10" />
            {getCurrentlocaleText("bulk.job.export")}
          </Button>
        </div>,
      ]}
      disableFooter={true}
      width={600}
      onCancel={props.handleCancel}
    >
      <Form
        name="lookup"
        form={form}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row className="form-wrapper">
          <Col span={10}>
            <Form.Item
              label={getCurrentlocaleText("repnet.table.native.ip.title")}
              name="nativeIp"
              className="m-0"
              rules={[
                {
                  validator: (rule, value, callback) => {
                    let cidr =
                      wholeObj &&
                      wholeObj.native_cidr &&
                      wholeObj.native_cidr.cidr
                        ? wholeObj.native_cidr.cidr
                        : null;
                    checkIpV4(rule, value, callback, "nativeIp", cidr);
                  },
                },
              ]}
            >
              <Input
                placeholder={getCurrentlocaleText(
                  "repnet.table.native.ip.title",
                )}
                onChange={handleNativeIpChange}
              />
            </Form.Item>
          </Col>
          <Col span={4} className="btn-wrapper">
            <Button
              type={disableLookupBtn ? "default" : "primary"}
              htmlType="submit"
              className="border-radius-8"
              disabled={disableLookupBtn}
            >
              <div style={{ display: disableLookupBtn ? "none" : "block" }}>
                <FontAwesomeIcon
                  icon={faCodeCompare}
                  style={{ transform: "rotate(90deg)" }}
                />
              </div>
              <div style={{ display: !disableLookupBtn ? "none" : "block" }}>
                <FontAwesomeIcon icon={faEquals} />
              </div>
            </Button>
          </Col>
          <Col span={10}>
            <Form.Item
              label={getCurrentlocaleText("repnet.table.repnet.ip.title")}
              name="repnetIp"
              className="m-0"
              rules={[
                {
                  validator: (rule, value, callback) => {
                    let cidr =
                      wholeObj &&
                      wholeObj.repnet_cidr &&
                      wholeObj.repnet_cidr.cidr
                        ? wholeObj.repnet_cidr.cidr
                        : null;
                    checkIpV4(rule, value, callback, "repnetIp", cidr);
                  },
                },
              ]}
            >
              <Input
                placeholder={getCurrentlocaleText(
                  "repnet.table.repnet.ip.title",
                )}
                onChange={handleRepNetIpChange}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <div className="content">
        <Row className="content-header">
          <Col span={12}>
            {getCurrentlocaleText("repnet.table.native.ip.title")}
          </Col>
          <Col span={12}>
            {getCurrentlocaleText("repnet.table.repnet.ip.title")}
          </Col>
        </Row>
        {convertedObjects.map((obj, index) => {
          return (
            <Row className="content-wrapper" key={index}>
              <Col span={12}>{obj && obj[0] ? obj[0] : "-"}</Col>
              <Col span={12}>{obj && obj[1] ? obj[1] : "-"}</Col>
            </Row>
          );
        })}
      </div>
    </Modal>
  );
};

export default RepNetTableLookup;
