import React, { Component } from "react";
import { Col, Row, Button, Card } from "antd";
import ValidateAndComputeTemplate from "./ValidateAndComputeTemplate";
import {
  ServiceController,
  InodeController,
  serviceTemplateController,
  NetworkController,
} from "controller/Controllers";
import BreadcrumbComponent from "components/UI-Components/BreadcrumbComponent";
import Icons from "components/UI-Components/Icons";
import { inject } from "mobx-react";
import { getCurrentlocaleText, getErrorMessage } from "Core/Utils";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import ClusterController from "controller/ClusterController";

@inject(
  "UiStore",
  "ServiceViewModel",
  "InodeViewModel",
  "NetworkViewModel",
  "ClusterViewModel",
)
export default class ServiceRender extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uiSchema: {},
      apiResponse: {},
      BreadcrumbList: [],
      error: null,
      fetchingTemplates: true,
    };
    this.Podspec = {};
    this.networkModel = this.props.NetworkViewModel;
    this.clusterModel = this.props.ClusterViewModel;
    this.inodeModel = this.props.InodeViewModel;
    this.props.UiStore.setDocumentTitle("services");
    this.selectedServiceName = "";
    this.templateId = this.props.match.params.spec.toLowerCase();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.uiSchema != nextProps.uiSchema) {
      this.setState({
        uiSchema: this.Podspec[this.props.match.params.spec],
      });
    }
  }

  getCurrentNodeNetworks = (networks, node_id) => {
    const viewService = window.location.href.indexOf("serviceview") > -1;
    let currentNetworks = networks.filter(network => {
      if (!viewService && network && network.node) {
        return (
          network.node.id === node_id &&
          (!network.is_wan_network || network.is_wan_network === false)
        );
      } else {
        return network.node && network.node.id === node_id;
      }
    });
    return currentNetworks;
  };

  getCurrentClusterNetworks = (networks, cluster_id) => {
    let currentNetworks = networks.filter(network => {
      if (network && network.cluster) {
        return (
          network.cluster.id === cluster_id &&
          (!network.is_wan_network || network.is_wan_network === false)
        );
      }
    });
    return currentNetworks;
  };

  componentDidMount() {
    /* Fetch network details for current org */
    let nw_params = this.props.match.params.node_id
      ? {
          node_id: this.props.match.params.node_id,
        }
      : {
          cluster_id: this.props.match.params.cluster_id,
        };

    nw_params.size = 1;
    nw_params.page = 0;
    NetworkController.getNetworks(nw_params, true, false, true).then(resp => {
      if (resp && resp.length > 0) {
        let totalCount = this.networkModel.pageable.total_count / 100;
        let availableNetworks = [];
        let loading = true;

        if (totalCount > 0) {
          let allParams = [];
          for (let page = 0; page < totalCount; page++) {
            let p = {
              size: 100,
              page: page,
            };

            this.props.match.params.node_id
              ? (p.node_id = this.props.match.params.node_id)
              : (p.cluster_id = this.props.match.params.cluster_id);
            allParams.push(p);
          }
          NetworkController.getAllNetworksForDropdown(allParams, loading).then(
            networkResp => {
              if (networkResp) {
                availableNetworks = availableNetworks.concat(networkResp);
                serviceTemplateController
                  .getTemplate(this.props.match.params.spec)
                  .then(
                    res => {
                      this.Podspec[this.props.match.params.spec.toLowerCase()] =
                        res.template;

                      this.selectedServiceName = res.template_metadata.name;
                      this.getInode();
                    },
                    error => {
                      if (error.response.status === 404) {
                        /* LAT-5532 */
                        serviceTemplateController
                          .getTemplate("custom")
                          .then(custResp => {
                            this.templateId = `custom`;
                            this.Podspec[`custom`] = custResp.template;

                            this.selectedServiceName =
                              custResp.template_metadata.name;
                            this.getInode();
                          });
                      } else {
                        this.setState({ fetchingTemplates: false });
                        this.setState({
                          error: getCurrentlocaleText(
                            "service.templates.incorrect.templateid",
                          ),
                        });
                        this.props.UiStore.setErrorMessage(
                          getErrorMessage(error, "servicetemplate"),
                        );
                      }
                    },
                  );
              }
            },
            networkErr => {
              this.setState({
                error: getCurrentlocaleText(
                  "network.form.network.notfound.text",
                ),
                fetchingTemplates: false,
              });
            },
          );
        }
      }
    });
  }

  getInode = () => {
    if (this.props.match.params.cluster_id) {
      ClusterController.getCluster(this.props.match.params.cluster_id).then(
        clusterObj => {
          let Clusterlist = [
            {
              name: clusterObj.name,
              id: clusterObj.id,
              organization: clusterObj.organization,
            },
          ];

          /* get current networks for the selected inode */
          let currentClusterNetworks = this.getCurrentClusterNetworks(
              this.networkModel.networks,
              clusterObj.id,
            ),
            currentNetworks = [];
          currentClusterNetworks.map(network => {
            currentNetworks.push({
              name: `${network.name} (Network CIDR : ${network.config.network.cidr})`,
              id: network.id,
              config: network.config,
              type: network.type,
              status: network.status,
              display_name: network.name,
            });
            return null;
          });

          this.props.UiStore.templatingService.nodeDetails = {
            nodeList: Clusterlist,
            networkList: currentNetworks,
          };
          this.setState({ fetchingTemplates: false });
          this.props.match.params.spec_id
            ? ServiceController.getService(
                this.props.match.params.spec_id,
                true,
                true,
              ).then(
                response => {
                  this.updateBreadCrumb();
                  if (response.spec && response.spec.dns)
                    this.props.UiStore.templatingService.dns =
                      response.spec.dns;
                  if (
                    response.status &&
                    response.status.dns &&
                    response.status.dns.nameserver
                  )
                    this.props.UiStore.templatingService.dns_status =
                      response.status.dns.nameserver;
                  this.setState({
                    uiSchema: this.Podspec[this.templateId],
                    apiResponse: response,
                  });
                },
                err => {
                  this.setState({ fetchingTemplates: false });
                  this.setState({
                    error: getCurrentlocaleText(
                      "service.templates.incorrect.serviceid",
                    ),
                  });
                },
              )
            : (this.setState({
                uiSchema: this.Podspec[this.templateId],
              }),
              this.updateBreadCrumb());
        },
      );
    } else {
      InodeController.getInode(
        this.props.match.params.node_id,
        true,
        true,
      ).then(
        inodeObj => {
          let nodelist = [
            {
              name: this.inodeModel.inode.name,
              id: this.inodeModel.inode.id,
              organization: this.inodeModel.inode.organization,
            },
          ];

          /* get current networks for the selected inode */
          let currentNodeNetworks = this.getCurrentNodeNetworks(
              this.networkModel.networks,
              this.inodeModel.inode.id,
            ),
            currentNetworks = [];
          currentNodeNetworks.map(network => {
            let nameCidr = network.config.network.cidr
              ? network.config.network.cidr
              : "NA";
            currentNetworks.push({
              name: `${network.name} (Network CIDR : ${nameCidr})`,
              id: network.id,
              config: network.config,
              type: network.type,
              status: network.status,
              display_name: network.name,
            });
            return null;
          });

          this.props.UiStore.templatingService.nodeDetails = {
            nodeList: nodelist,
            networkList: currentNetworks,
          };
          this.setState({ fetchingTemplates: false });
          this.props.match.params.spec_id
            ? ServiceController.getService(
                this.props.match.params.spec_id,
                true,
                true,
              ).then(
                response => {
                  this.updateBreadCrumb();
                  if (response && response.spec && response.spec.dns)
                    this.props.UiStore.templatingService.dns =
                      response.spec.dns;
                  if (
                    response &&
                    response.status &&
                    response.status.dns &&
                    response.status.dns.nameserver
                  )
                    this.props.UiStore.templatingService.dns_status =
                      response.status.dns.nameserver;
                  this.setState({
                    uiSchema: this.Podspec[this.templateId],
                    apiResponse: response,
                  });
                },
                err => {
                  this.setState({ fetchingTemplates: false });
                  this.setState({
                    error: getCurrentlocaleText(
                      "service.templates.incorrect.serviceid",
                    ),
                  });
                },
              )
            : (this.setState({
                uiSchema: this.Podspec[this.templateId],
              }),
              this.updateBreadCrumb());
        },
        error => {
          this.setState({ fetchingTemplates: false });
          this.setState({
            error: getCurrentlocaleText("service.templates.incorrect.nodeid"),
          });
        },
      );
    }
  };

  updateBreadCrumb() {
    /* BreadCrumb */
    let navigateList = [];
    let pathName = this.props.location.pathname,
      curr_link = pathName.split("/");
    curr_link.splice(0, 1);
    curr_link = curr_link.join("/");
    let node_list =
      this.props.UiStore.templatingService.nodeDetails.nodeList &&
      this.props.UiStore.templatingService.nodeDetails.nodeList[0];
    if (
      Object.keys(this.props.UiStore.templatingService.nodeDetails).length > 0
    ) {
      /*  page rendered from cluster page*/
      if (
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.cluster_id
      ) {
        navigateList.push(
          {
            name: `${node_list &&
              node_list.organization &&
              node_list.organization.name &&
              node_list.organization.name}`,
            link: `orgs/${node_list &&
              node_list.organization &&
              node_list.organization.id &&
              node_list.organization.id}`,
          },
          {
            name: `${node_list && node_list.name && node_list.name}`,
            link: `orgs/${this.props.UiStore.templatingService.nodeDetails
              .nodeList[0] &&
              node_list.organization &&
              node_list.organization.id}/clusters/${node_list.id}/services`,
          },
        );
      } else {
        /* page loaded from inode page*/
        navigateList.push(
          {
            name: `${this.props.UiStore.templatingService.nodeDetails
              .nodeList[0].organization.name && node_list.organization.name}`,
            link: `orgs/${node_list.organization.id}`,
          },
          {
            name: `${node_list.name}`,
            link: `orgs/${node_list.organization.id}/inodes/${node_list.id}/servicetemplates`,
          },
        );
      }
    } else {
      /* page loaded from /services page*/
      if (
        this.props.ServiceViewModel.service &&
        this.props.ServiceViewModel.service.organization
      ) {
        navigateList.push(
          {
            name: `${this.props.ServiceViewModel.service.organization.name}`,
            link: `orgs/${this.props.ServiceViewModel.service.organization.id}`,
          },
          {
            name: `${this.props.ServiceViewModel.service.node.name}`,
            link: `orgs/${this.props.ServiceViewModel.service.organization.id}/inodes/${this.props.ServiceViewModel.service.node.id}/servicetemplates`,
          },
        );
      }
    }
    if (!this.state.error) {
      let x, y;
      this.props.match.params.spec_id
        ? ServiceController.getService(this.props.match.params.spec_id).then(
            response => {
              if (response.spec.labels)
                response.spec.labels["io_iotium_template"] = this.templateId;
              this.setState({
                uiSchema: this.Podspec[this.templateId],
                apiResponse: response,
              });
              navigateList.push({ name: response.name, link: `${curr_link}` });
              this.setState({ BreadcrumbList: navigateList });
            },
          )
        : /*convert to startCase LAT-4246 */
          ((x = this.selectedServiceName.split("")),
          (y = x[0].toUpperCase()),
          (x[0] = y),
          (x = x.join("")),
          this.setState({
            uiSchema: this.Podspec[this.templateId],
            BreadcrumbList: navigateList.concat({
              name: `${x}`,
              link: `${curr_link}`,
            }),
          }));
    }
  }

  navigateBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <div>
        {!this.state.error && (
          <div>
            <LoadingComponent loading={this.state.fetchingTemplates}>
              <Row gutter={16} type="flex" align="middle">
                <Col span={12}>
                  <h2 className="page-title">
                    <Icons
                      type="hi"
                      name="HiOutlineShoppingBag"
                      className="pr-5 mr-5"
                    />
                    {getCurrentlocaleText("marketplace")}
                  </h2>
                  <BreadcrumbComponent
                    {...this.props}
                    BreadcrumbList={this.state.BreadcrumbList}
                  />
                </Col>
              </Row>

              {this.state.uiSchema &&
                Object.keys(this.state.uiSchema).length > 0 && (
                  <ValidateAndComputeTemplate
                    uiSchema={this.state.uiSchema}
                    apiResponse={this.state.apiResponse}
                    {...this.props}
                    templateId={this.templateId}
                    isCluster={
                      this.props.match &&
                      this.props.match.params &&
                      this.props.match.params.cluster_id
                        ? true
                        : false
                    }
                  />
                )}
            </LoadingComponent>
          </div>
        )}
        {this.state.error && (
          <div>
            <Card
              title="Error"
              style={{ top: "30px" }}
              extra={
                <Button onClick={this.navigateBack} type="primary">
                  <Icons type="ai" name="AiOutlineArrowLeft" /> Back
                </Button>
              }
            >
              <h3 style={{ color: "red" }}>{this.state.error}</h3>
            </Card>
          </div>
        )}
      </div>
    );
  }
}
