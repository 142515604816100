import React, { Component } from "react";
import BulkConfigHeaderMenu from "./BulkConfigHeaderMenu";
import BulkConfigureModal from "./BulkConfigureModal";
import BulkConfigController from "../../controller/BulkConfigController";
import { getCurrentlocaleText } from "Core/Utils";
import { Row, Popover, Col } from "antd";

import { LoadingOutlined, CloseCircleOutlined } from "@ant-design/icons";
import Resources from "../../Core/API/Resources";
import BulkPartialSuccess from "../../assets/img/bulk-partial-success.svg";
import BulkSuccess from "../../assets/img/bulk-success.svg";
import CsvIcon from "../../assets/img/icon-csv.svg";
import ZipIcon from "../../assets/img/icon-zip.svg";
import DisabledZipIcon from "../../assets/img/disabled-icon-zip.svg";
const BULK_JOB_CSV_DOWNLOAD_URL = Resources.getUrl("bulkJobStatusCsv");
const BULK_JOB_ZIP_DOWNLOAD_URL = Resources.getUrl("bulkJobZipNode");
class BulkConfigure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      dataSource: [],
    };
  }
  getTableData = (status = "0") => {
    switch (status) {
      case "0":
        status = "";
        break;
      case "1":
        status = "SUCCESS";
        break;
      case "2":
        status = "FAILURE";
        break;
      case "3":
        status = "PARTIAL_SUCCESS";
        break;
      case "4":
        status = "INPROGRESS";
        break;
      default:
        status = "";
        break;
    }

    let params = [
      {
        size: 10,
        own: true,
      },
    ];

    if (this.props.resource) {
      if (this.props.resource.toLowerCase() === "user") {
        params[0].type = ["USER_CREATE", "USER_EDIT"];
      } else if (this.props.resource.toLowerCase() === "node") {
        params[0].type = ["NODE_CREATE", "NODE_EDIT"];
      } else if (this.props.resource.toLowerCase() === "network") {
        params[0].type = ["NETWORK_CREATE", "NETWORK_EDIT"];
      }
    }

    if (status) {
      params[0].job_status = status;
    }

    if (this.props.orgId) {
      params[0].org_id = this.props.orgId;
    }

    BulkConfigController.getBulkJobs(params).then(response => {
      this.setState({
        dataSource: response[0].results,
      });
    });
  };

  showUploadModal = () => {
    if (this.props.resource === "User") {
      this.props.changeRoute("users", "bulkconfig/upload");
    } else if (this.props.resource === "Node") {
      this.props.changeRoute("inodes", "bulkconfig/upload");
    } else if (this.props.resource === "Network") {
      this.props.changeRoute("networks", "bulkconfig/upload");
    }
    this.setState({ showUpload: true });
    this.setState({ showTable: false });
  };
  showTableModal = () => {
    if (this.props.resource === "User") {
      this.props.changeRoute("users", "bulkconfig/view-history");
    } else if (this.props.resource === "Node") {
      this.props.changeRoute("inodes", "bulkconfig/view-history");
    } else if (this.props.resource === "Network") {
      this.props.changeRoute("networks", "bulkconfig/view-history");
    }
    this.setState({ showUpload: false });
    this.setState({ showTable: true });
    this.getTableData("0");
  };
  closeModal = () => {
    if (this.props.resource === "User") {
      this.props.onAfterClick("users/bulkconfig");
    } else if (this.props.resource === "Node") {
      this.props.onAfterClick("inodes/bulkconfig");
    } else if (this.props.resource === "Network") {
      this.props.onAfterClick("networks/bulkconfig");
    }
    this.setState({ showUpload: false });
    this.setState({ showTable: false });
    this.props.getList();
  };

  statusType = status => {
    const statusType = {
      SUCCESS: "Success",
      FAILURE: "Failure",
      INPROGRESS: "In Progress",
      PARTIAL_SUCCESS: "Partial Success",
    };
    return statusType[status] || status;
  };

  jobType = (text, record) => {
    const jobTypes = {
      USER_CREATE: "User Create",
      USER_EDIT: "User Edit",
      NODE_CREATE: "Node Create",
      NODE_EDIT: "Node Edit",
      NETWORK_CREATE: "Network Create",
      NETWORK_EDIT: "Network Edit",
    };
    const jobType = record.type;
    return jobTypes[jobType] || jobType;
  };

  formatTime = time => {
    let dateObj = new Date(time);
    let day = String(dateObj.getDate()).padStart(2, "0");
    let month = dateObj.toLocaleString("default", { month: "short" });
    let year = dateObj.getFullYear();
    let hours = dateObj.getHours();
    if (hours > 12) {
      hours -= 12;
      var meridian = "PM";
    } else {
      var meridian = "AM";
    }
    hours = String(hours).padStart(2, "0");
    let minutes = String(dateObj.getMinutes()).padStart(2, "0");
    let formattedTime = `${day} ${month} ${year} ${hours}:${minutes} ${meridian}`;
    return formattedTime;
  };

  tableStatus = (text, record) => {
    let statusCount = "";
    if (record.job_status !== "INPROGRESS") {
      statusCount = record.count.success + "/" + record.count.total;
    }
    let job_status = this.statusType(record.job_status);
    let statusIcon;
    if (record.job_status == "SUCCESS") {
      statusIcon = <BulkSuccess style={{ color: "#00804F" }} />;
    } else if (record.job_status == "FAILURE") {
      statusIcon = <CloseCircleOutlined style={{ color: "#D82C0D" }} />;
    } else if (record.job_status == "INPROGRESS") {
      statusIcon = <LoadingOutlined style={{ color: "#FC9162" }} />;
    } else if (record.job_status == "PARTIAL_SUCCESS") {
      statusIcon = <BulkPartialSuccess style={{ color: "#FFC453" }} />;
    }
    return (
      <div className="bulk-job-status-container">
        <span className="bulk-job-status-text">{job_status}</span>
        <span className="bulk-job-status-count">{statusCount}</span>
        <span className="bulk-job-status-icon">{statusIcon}</span>
      </div>
    );
  };
  render() {
    const columns = [
      {
        title: getCurrentlocaleText("bulk.job.type"),
        dataIndex: "type",
        colSpan: 1,
        render: (text, record) => this.jobType(text, record),
      },
      {
        title: getCurrentlocaleText("bulk.job.time.updated"),
        dataIndex: "created_at",
        colSpan: 1,
        sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
        render: (text, record) => this.formatTime(text),
      },
      {
        title: getCurrentlocaleText("bulk.job.time.completed"),
        dataIndex: "updated_at",
        colSpan: 1,
        render: (text, record) => this.formatTime(text),
      },
      {
        title: getCurrentlocaleText("bulk.job.status"),
        dataIndex: "job_status",
        colSpan: 1,
        width: "30%",
        render: (text, record) => this.tableStatus(text, record),
      },
      {
        title: getCurrentlocaleText("bulk.job.download"),
        dataIndex: "download",
        colSpan: 1,
        render: (text, record) => {
          return (
            <div className="d-flex justify-content-center">
              <Row>
                <Col>
                  {record.job_status !== "INPROGRESS" ? (
                    <div className="bulk-download-icon mx-2 mr-4">
                      <CsvIcon
                        onClick={() => {
                          let url = BULK_JOB_CSV_DOWNLOAD_URL.replace(
                            "{:jobId}",
                            record.id,
                          );
                          window.open(url, "_blank");
                        }}
                      />
                    </div>
                  ) : null}
                </Col>
                <Col>
                  <Popover content={"Inode Certificates"}>
                    {record.type == "NODE_CREATE" &&
                    record.job_status !== "FAILURE" &&
                    record.job_status !== "INPROGRESS" ? (
                      <div className="bulk-download-icon mx-2 mr-4">
                        {record.is_node_certs_downloaded ? (
                          <DisabledZipIcon
                            title={getCurrentlocaleText(
                              "bulk.job.download.inode.certificates.disable",
                            )}
                          />
                        ) : (
                          <ZipIcon
                            title={getCurrentlocaleText(
                              "bulk.job.download.inode.certificates",
                            )}
                            onClick={() => {
                              let url = BULK_JOB_ZIP_DOWNLOAD_URL.replace(
                                "{:jobId}",
                                record.id,
                              );
                              this.getTableData("0");
                              window.open(url, "_blank");
                            }}
                          />
                        )}
                      </div>
                    ) : null}
                  </Popover>
                </Col>
              </Row>
            </div>
          );
        },
      },
    ];

    return (
      <div>
        <BulkConfigHeaderMenu
          readOnly={this.props.readOnly}
          download={this.props.download}
          createTemplate={this.props.createTemplate}
          resource={this.props.resource}
          changeRoute={this.props.changeRoute}
          onAfterClick={this.props.onAfterClick}
          showUploadModal={this.showUploadModal}
          showTableModal={this.showTableModal}
        />
        <BulkConfigureModal
          closeModal={this.closeModal}
          orgId={this.props.orgId}
          showUpload={this.state.showUpload}
          showTable={this.state.showTable}
          resource={this.props.resource}
          columns={columns}
          dataSource={this.state.dataSource}
          getTableData={this.getTableData}
          showTableModal={this.showTableModal}
        />
      </div>
    );
  }
}
export default BulkConfigure;
