import React, { Component } from "react";
import { Checkbox, Button, Badge, Alert } from "antd";
import { Form } from "@ant-design/compatible";
import { observer, inject } from "mobx-react";

import * as eula from "./eula.js";
import {
  milliToDateString,
  highlightSearchText,
  customSort,
  getCurrentlocaleText,
  ipAddressSort,
} from "Core/Utils";
import SearchInput from "components/UI-Components/SearchInput";
import TableLayout from "components/layout/TableLayout";
import FormErrorMessage from "components/UI-Components/FormErrorMessage";
import Icons from "components/UI-Components/Icons";
import ModalLoadingComponent from "components/UI-Components/ModalLoadingComponent";
import NetworkController from "controller/NetworkController";

const FormItem = Form.Item;

@inject("UiStore", "NetworkViewModel")
@observer
class NetworkDiscoveryForm extends Component {
  constructor(props) {
    super(props);
    this.uistore = this.props.UiStore;
    this.networkModel = this.props.NetworkViewModel;
    this.newDiscovery = false;
    this.state = {
      showDivEula: false,
      discoveryState: this.props.discoveryState,
      discoveryCheckBox: false,
      data: this.props.extendData,
      discoveredInterval: 30,
      tableData: [],
      discoveryresponseStatus: 200,
      current: 1,
      expandedRowKeys: [],
      searchText: "",
      filterData: [],
      currentNetwork: {},
      discoveryArray: [],
      lastUpdated: "",
      discoveryData: false,
      newdiscoveryData: false,
    };
    if (this.newDiscovery) {
      // New Discovery data currently disabled
      this.fetchDeviceDiscovery();
    } else {
      if (this.state.discoveryState) {
        this.fetchDiscovery();
      }
    }
  }

  // Show Eula for discovery
  showEula(e) {
    this.setState({
      discoveryCheckBox: e.target.checked,
      showDivEula: e.target.checked && !this.state.discoveryState,
      errorMsg: "",
    });

    if (e.target.checked === false && !this.state.showDivEula) {
      this.onChangeDiscovery();
    }
  }
  // Hide EULA
  hideEula(e) {
    this.setState({
      discoveryCheckBox: false,
      showDivEula: false,
    });
    this.props.onCancel();
  }
  getcurrentNetworkData = () => {
    this.networkModel.networks.forEach(network => {
      if (network.id === this.props.networkId) {
        this.setState({ currentNetwork: network });
      }
    });
  };

  onExpand = (expanded, record) => {
    let keys = this.state.expandedRowKeys;
    let index = keys.findIndex(val => {
      return val === record.id;
    });
    if (expanded) {
      if (index === -1) {
        keys.push(record.id);
      }
      this.setExpandedRowKeys(keys);
    } else {
      if (index > -1) {
        keys.splice(index, 1);
      }
      this.setExpandedRowKeys(keys);
    }
  };

  setExpandedRowKeys = keys => {
    this.setState({ expandedRowKeys: keys });
  };

  showExtraDetails = record => {
    // Defining conatiner table columns
    const RecordData = [];
    const container_columns = [
      {
        title: getCurrentlocaleText("service_listener.port.text"),
        dataIndex: "port",
        key: "port",
      },
      {
        title: getCurrentlocaleText("node.list.table.node_status.label.text"),
        dataIndex: "status",
        key: "status",
      },
      {
        title: getCurrentlocaleText(
          "service_listener.ports.protocol.help.title",
        ),
        dataIndex: "protocol",
        key: "protocol",
      },
      {
        title: getCurrentlocaleText("service.name"),
        dataIndex: "service_name",
        key: "service_name",
      },
      {
        title: getCurrentlocaleText("service.version"),
        dataIndex: "service_version",
        key: "service_version",
      },
    ];

    if (record.active_ports && record.active_ports.length > 0) {
      record.active_ports.forEach(port => {
        let newData = [];
        newData.push({
          port: port.port ? port.port : "-",
          status: port.status ? port.status.toUpperCase() : "-",
          protocol: port.protocol ? port.protocol.toUpperCase() : "-",
          service_name: port.service_name
            ? port.service_name.toUpperCase()
            : "-",
          service_version: port.service_version ? port.service_version : "-",
        });
        RecordData.push(newData[0]);
      });
    }
    return (
      <div>
        <div>
          <TableLayout
            columns={container_columns}
            dataSource={RecordData}
            pagination={false}
          />
        </div>
      </div>
    );
  };

  fetchDeviceDiscovery = () => {
    //Get Discovery data
    this.uistore.modalLoading = true;

    NetworkController.getDeviceDiscoveryReport(
      {
        id: this.props.nodeId,
      },
      { network_id: this.props.networkId },
    )
      .then(response => {
        if (response) {
          if (
            response.data &&
            response.data.hosts &&
            response.data.hosts.length > 0
          ) {
            let hostArray = [];
            response.data.hosts.forEach((host, index) => {
              hostArray.push({
                ip_address: host.ip_address ? host.ip_address : "-",
                mac_address: host.mac_address ? host.mac_address : "-",
                status: host.status ? host.status.toUpperCase() : "-",
                os: host.os ? host.os : "-",
                os_accuracy_percent:
                  host.os && host.os.accuracy_percent
                    ? host.os.accuracy_percent
                    : "-",
                os_type: host.os && host.os.type ? host.os.type : "-",
                active_ports: host.active_ports ? host.active_ports : "-",
                last_boot: host.last_boot ? host.last_boot : "-",
                uptime: host.uptime ? host.uptime : "-",
                id: index,
                key: index,
              });
            });
            this.setState({
              discoveryArray: hostArray,
              discoveryData: true,
              lastUpdated: response.data.last_updated_at
                ? response.data.last_updated_at
                : "-",
            });
          }

          this.uistore.modalLoading = false;
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  fetchDiscovery = () => {
    //Get Discovery data
    this.uistore.modalLoading = true;
    /*this.store
      .Get(
        "discoveryreport",
        {
          id: this.props.nodeId,
          network_id: this.props.networkId,
        },
        {},
      )*/
    NetworkController.getDiscoveryReport({
      id: this.props.nodeId,
      network_id: this.props.networkId,
    })
      .then(response => {
        if (response.request.status === 200) {
          if (response.data && response.data[0]) {
            this.setState({
              discoveryData: response.data[0].device,
              discoveryresponseStatus: 200,
            });
            // Create table compatible array of objects
            let tableData = this.prepareDiscovery(response.data[0].device);
            let devicesCount_Up = tableData.filter(val => {
              return val.state.toLowerCase() === "up";
            }).length;
            let devicesCount_Down = tableData.filter(val => {
              return val.state.toLowerCase() === "down";
            }).length;
            this.setState({
              tableData: tableData,
              errorMsg: "",
              showDivEula: false,
              current: 1,
              devicesCount_Up: devicesCount_Up,
              devicesCount_Down: devicesCount_Down,
            });
            if (response.data[0].timestamp) {
              this.setState({
                lastDiscoverdDate: milliToDateString(
                  new Date(response.data[0].timestamp).getTime(),
                ),
              });
            }
          }
          this.uistore.modalLoading = false;
        } else if (response.request.status === 206) {
          this.setState({ discoveryresponseStatus: 206 });
          this.uistore.modalLoading = false;
        }
      })
      .catch(error => {
        this.uistore.modalLoading = false;
      });
  };

  //Status Icon display
  getStatusState(state) {
    switch (state) {
      case "up":
        return "success";
        break;
      case "down":
        return "error";
      default:
        return "default";
        break;
    }
  }

  getStatus(state) {
    switch (state) {
      case "up":
        return "Active";
        break;
      case "down":
        return "Inactive";
        break;
      default:
        return "-";
        break;
    }
  }

  // Create table compatible array of objects
  prepareDiscovery(hosts) {
    let data = [];

    if (hosts.length >= 1) {
      hosts.map((host, i) => {
        //Fix null issue
        if (!host.state) {
          host.state = "unavailable";
        }

        let temp = {
          id: i,
          ipAddress: "-",
          macAddress: "-",
          hostName: "-",
          vendor: "-",
          state: host.state,
          state_text: this.getStatus(
            host.state ? host.state.toLowerCase() : "",
          ),
        };

        //Add IPV4 amd MAC data if present
        if (host.ipv4) {
          temp.ipAddress = host.ipv4;
        }
        if (host.mac) {
          temp.macAddress = host.mac;
        }

        //Add Vendor Info
        if (host.vendor) {
          temp.vendor = host.vendor;
        }

        //Host names Array
        if (host.hostname.length === 1) {
          temp.hostName = host.hostname[0];
        }
        if (host.hostname.length > 1) {
          temp.hostName = host.hostname[0] + ", ...";
        }
        data.push(temp);
      });
      return data;
    }
  }

  // Onchange Discoverydata
  onChangeDiscovery() {
    let policy = {
      policy: { discovery: { enable: !this.state.discoveryState } },
    };
    /*this.props.ModelStore
      .Update("networks", policy, { id: this.store.networks[0].id }, {})*/
    NetworkController.update(policy, {
      id: this.props.networkId,
    }).then(response => {
      if (response) {
        this.setState({
          discoveryState: !this.state.discoveryState,
          discoveryCheckBox: !this.state.discoveryState,
          discoveredInterval: response.policy.discovery.interval_in_minutes,
        });
        if (this.state.discoveryState) {
          this.fetchDiscovery.bind();
        }
        // Hide EULA
        this.setState({ showDivEula: false });
      } else {
        this.setState({
          discoveryState: this.state.discoveryState,
          discoveryresponseStatus: 200,
          discoveryCheckBox: this.state.discoveryState,
        });
        this.setState({ showDivEula: false });
      }
    });
  }
  UNSAFE_componentWillMount() {
    this.setState({
      discoveryCheckBox: this.props.discoveryState,
      discoveryState: this.props.discoveryState,
    });
  }

  filterDiscoveryData = e => {
    let value = e.target.value;
    let data = this.state.tableData;
    this.setState({ searchText: value });
    this.setState({
      filterData:
        value.trim().length > 0
          ? data.filter(val => {
              val.ipHighlight = val.macHighlight = val.statusHighlight = val.hostHighlight = false;
              value = value.toLowerCase();
              if (val.ipAddress.toLowerCase().indexOf(value) !== -1) {
                val.ipHighlight = true;
              }
              if (val.macAddress.toLowerCase().indexOf(value) !== -1) {
                val.macHighlight = true;
              }
              if (val.state_text.toLowerCase().indexOf(value) !== -1) {
                val.statusHighlight = true;
              }
              if (val.hostName.toLowerCase().indexOf(value) !== -1) {
                val.hostHighlight = true;
              }
              if (
                val.ipHighlight ||
                val.macHighlight ||
                val.statusHighlight ||
                val.hostHighlight
              ) {
                return val;
              }
            })
          : data,
    });
  };

  clearSearchFilter = () => {
    this.setState({ searchText: "" });
  };

  render() {
    let resultData = this.state.discoveryArray;
    let data = this.state.searchText
      ? this.state.filterData
      : this.state.tableData;
    const { getFieldDecorator } = this.props.form;
    this.columns = [
      {
        title: "IP Address",
        dataIndex: "ipAddress",
        key: "ipAddress",
        width: "120px",
        sorter: (a, b) => {
          return ipAddressSort(a.ipAddress, b.ipAddress);
        },
        render: (text, render, index) => {
          return (
            <span>
              {this.state.searchText.trim().length > 0 && render.ipHighlight
                ? highlightSearchText(text, this.state.searchText)
                : text}
            </span>
          );
        },
      },
      {
        title: "MAC Address",
        dataIndex: "macAddress",
        key: "macAddress",
        width: "120px",
        sorter: (a, b) => {
          return customSort(a.macAddress, b.macAddress);
        },
        render: (text, render, index) => {
          return (
            <span>
              {this.state.searchText.trim().length > 0 && render.macHighlight
                ? highlightSearchText(text, this.state.searchText)
                : text}
            </span>
          );
        },
      },
      {
        title: "Status",
        key: "status",
        width: "120px",
        sorter: (a, b) => {
          return customSort(a.state, b.state);
        },
        render: (text, render, index) => {
          let status_state = this.getStatusState(
            render.state ? render.state.toLowerCase() : render.state,
          );
          return (
            <span>
              <Badge status={status_state} />
              {this.state.searchText.trim().length > 0 && render.statusHighlight
                ? highlightSearchText(
                    render.state_text.toUpperCase(),
                    this.state.searchText,
                  )
                : render.state_text.toUpperCase()}
            </span>
          );
        },
      },
      {
        title: "Host Name",
        dataIndex: "hostName",
        key: "hostName",
        width: "180px",
        render: (text, render, index) => {
          return (
            <span>
              {this.state.searchText.trim().length > 0 && render.hostHighlight
                ? highlightSearchText(text, this.state.searchText)
                : text}
            </span>
          );
        },
      },
    ];
    this.column = [
      {
        title: getCurrentlocaleText("inode.interface.mac_address.label.text"),
        width: 80,
        dataIndex: "mac_address",
        key: "mac_address",
        fixed: "left",
      },
      {
        title: getCurrentlocaleText("inode.interface.ipv4.label.text"),
        width: 90,
        dataIndex: "ip_address",
        key: "ip_address",
        fixed: "left",
      },
      {
        title: getCurrentlocaleText("inode.interface.last_boot.label.text"),
        width: 100,
        dataIndex: "last_boot",
        key: "last_boot",
        fixed: "left",
      },
      {
        title: getCurrentlocaleText("inode.os_type.label.text"),
        width: 100,
        dataIndex: "os_type",
        key: "os_type",
        fixed: "left",
      },
      {
        title: getCurrentlocaleText("inode.os_accuracy.label.text"),
        width: 120,
        dataIndex: "os_accuracy_percent",
        key: "os_accuracy_percent",
        fixed: "left",
      },
      {
        title: getCurrentlocaleText("node.list.table.node_status.label.text"),
        width: 80,
        dataIndex: "status",
        key: "status",
        fixed: "left",
      },
      {
        title: getCurrentlocaleText("inode.os_up_time.label.text"),
        width: 100,
        dataIndex: "uptime",
        key: "uptime",
        fixed: "left",
      },
    ];
    let form_content = (
      <div>
        {!this.state.newdiscoveryData && (
          <div>
            <FormItem style={{ display: "inline-flex", marginRight: 25 }}>
              <Checkbox
                defaultChecked={this.state.discoveryState}
                onChange={this.showEula.bind(this)}
                checked={this.state.discoveryCheckBox}
              >
                Enable Discovery
              </Checkbox>
            </FormItem>
            {this.state.tableData &&
              this.state.tableData.length >= 1 &&
              this.state.discoveryState && (
                <FormItem style={{ display: "inline-block", float: "right" }}>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <SearchInput
                      placeholder="Search..."
                      onChange={this.filterDiscoveryData}
                      value={this.state.searchText}
                      clearSearch={this.clearSearchFilter}
                    />
                    {this.state.discoveryState ? (
                      <Button
                        type="primary"
                        onClick={this.fetchDiscovery.bind(this)}
                        style={{ marginLeft: 10 }}
                      >
                        <Icons
                          type="md"
                          name="MdRefresh"
                          style={{ fontSize: 16 }}
                        />
                        Reload
                      </Button>
                    ) : null}
                  </div>
                </FormItem>
              )}
            <FormItem style={{ marginBottom: 0 }}>
              {this.state.showDivEula ? (
                <div>
                  {/*EULA Aggrement*/}
                  <div
                    className="text-display"
                    style={{
                      lineHeight: "25px",
                      marginBottom: 30,
                      fontSize: "13px",
                    }}
                  >
                    {eula.eula}
                  </div>
                  <div style={{ float: "right", margin: "10px 0" }}>
                    <button
                      type="button"
                      onClick={this.hideEula.bind(this)}
                      className="ant-btn ant-btn-lg m-r-1 m-b-2"
                    >
                      I do not accept
                    </button>
                    <span style={{ margin: 5 }} />
                    <button
                      type="button"
                      onClick={this.onChangeDiscovery.bind(this)}
                      className="ant-btn ant-btn-primary ant-btn-lg m-r-1 m-b-2"
                    >
                      I Accept
                    </button>
                  </div>
                </div>
              ) : null}
              {!this.state.discoveryState && !this.state.showDivEula ? (
                <Alert
                  message="Please Note"
                  description="This is an experimental feature."
                  type="warning"
                  showIcon
                />
              ) : null}
              {this.state.tableData &&
              this.state.tableData.length >= 1 &&
              this.state.discoveryState ? (
                <div>
                  <div
                    className="discovery-details"
                    style={{ margin: "-25px 0 5px 0" }}
                  >
                    <div className="discovery-device-container">
                      <span className="discovery-device-status">
                        <span>
                          {" "}
                          Total Devices:{" "}
                          <strong>{this.state.tableData.length}</strong>
                        </span>
                        <span>
                          {"   "}Active Devices:{" "}
                          <span style={{ color: "#00a854" }}>
                            <strong>{this.state.devicesCount_Up}</strong>
                          </span>
                        </span>
                        <span>
                          {"   "}Inactive Devices:{" "}
                          <span style={{ color: "#f04134" }}>
                            <strong>{this.state.devicesCount_Down}</strong>
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                  <TableLayout
                    columns={this.columns}
                    dataSource={data}
                    itemPerPage={5}
                    paginationSize="small"
                    tableSize="small"
                    hideRowSelection={true}
                    hideExpandedRow={true}
                    style={data.length ? { marginBottom: -50 } : {}}
                  />
                  <div
                    className="discovery-details"
                    style={{
                      display: "inline-block",
                      marginTop: data.length === 0 ? 10 : 0,
                    }}
                  >
                    <h3
                      className="title"
                      style={{ fontWeight: "normal", fontSize: 15 }}
                    >
                      <span>Last discovered on: </span>
                      <strong>{this.state.lastDiscoverdDate}</strong>
                    </h3>
                    <p>Runs every {this.state.discoveredInterval} minutes.</p>
                  </div>
                </div>
              ) : (
                <div>
                  {this.state.errorMsg ? (
                    <Alert
                      message="Error"
                      description={this.state.errorMsg}
                      type="error"
                      showIcon
                    />
                  ) : (
                    <div>
                      {this.state.discoveryState &&
                      this.state.discoveryresponseStatus === 200 ? (
                        <Alert
                          message="Discovery"
                          description="The discovery information is unavailable for this network. Please wait for some time to get the information."
                          type="info"
                          showIcon
                        />
                      ) : this.state.discoveryState &&
                        this.state.discoveryresponseStatus === 206 ? (
                        <Alert
                          message="Discovery"
                          description="No devices Discovered for the network."
                          type="success"
                          showIcon
                        />
                      ) : null}
                    </div>
                  )}
                </div>
              )}
            </FormItem>
            <Button
              className="hidden-submit"
              htmlType="submit"
              style={{ display: "none" }}
            />
            <FormErrorMessage />
          </div>
        )}
        {this.state.newdiscoveryData && (
          <div>
            <TableLayout
              style={{ marginTop: "15px" }}
              columns={this.column}
              dataSource={resultData}
              bordered
              footer={() =>
                this.state.lastUpdated
                  ? "Last Updated: " + this.state.lastUpdated
                  : "NA"
              }
              expandedRowRender={record => {
                return this.showExtraDetails(record);
              }}
              expandedRowKeys={this.state.expandedRowKeys}
              onExpand={this.onExpand}
              className={"iot-table"}
              scroll={this.props.scroll ? this.props.scroll : {}}
              pagination={{
                pageSize: 5,
                showSizeChanger: true,
                pageSizeOptions: ["5", "10"],
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} items`,
              }}
            />
          </div>
        )}
      </div>
    );

    return (
      <Form noValidate>
        <ModalLoadingComponent>{form_content}</ModalLoadingComponent>
      </Form>
    );
  }
}

const DiscoveryForm = Form.create()(NetworkDiscoveryForm);
export default DiscoveryForm;
