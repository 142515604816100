// Stores
import AuthStore from "./AuthStore";
import ModelStore from "./ModelStore";
import UiStore from "./UiStore";
import DashboardStore from "./DashboardStore";

const Stores = {
  AuthStore: AuthStore,
  ModelStore: ModelStore,
  UiStore: UiStore,
  DashboardStore: DashboardStore,
};

export default Stores;
