import React, { Component } from "react";
import AddVolume from "./addVolume";

export default class volumeContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <AddVolume
          title={this.props.title}
          actionType={this.props.actionType}
          afterCall={this.props.afterCall}
          {...this.props}
        />
      </div>
    );
  }
}
