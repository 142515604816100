import React, { Component } from "react";
import { getCurrentlocaleText } from "Core/Utils";
import Input from "components/UI-Components/InputBox";
import Icons from "./Icons";

class CidrProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cidrIP: null,
      cidrMask: null,
      cidr: null,
    };
  }

  UNSAFE_componentWillReceiveProps(newprops) {
    if (
      newprops &&
      newprops.tancidr &&
      this.props.tancidr != newprops.tancidr
    ) {
      let cidrGiven = newprops.tancidr;
      // update cidr ip and mask
      if (cidrGiven && cidrGiven.split("/")[0] && cidrGiven.split("/")[1]) {
        this.setState({
          cidr: cidrGiven,
          cidrIP: cidrGiven.split("/")[0],
          cidrMask: cidrGiven.split("/")[1],
        });
      }
      // update only cidr IP
      else if (cidrGiven && cidrGiven.split("/")[0]) {
        this.setState({
          cidr: cidrGiven,
          cidrIP: cidrGiven.split("/")[0],
        });
      }
      // update cidr mask only
      else if (cidrGiven && cidrGiven.split("/")[1]) {
        this.setState({
          cidr: cidrGiven,
          cidrMask: cidrGiven.split("/")[1],
        });
      }
    }
    // To reset cidr provider
    if (newprops && !newprops.tancidr && newprops.reset) {
      this.setState({
        cidr: null,
        cidrIP: null,
        cidrMask: null,
      });
    }
  }

  UNSAFE_componentWillMount() {
    if (this.props.tancidr) {
      this.setState({
        cidr: this.props.tancidr,
        cidrIP: this.props.tancidr.split("/")[0],
        cidrMask: this.props.tancidr.split("/")[1],
      });
    }
  }
  /**
   * Handle Input Change
   */
  handleCidrMaskChange = event => {
    let value = event.target && event.target.value ? event.target.value : "";
    this.setState({ cidrMask: value });
    let cidrIP = this.state.cidrIP ? this.state.cidrIP : "";
    let cidr = cidrIP + "/" + value;
    this.props.handleChange(cidr);
  };
  /**
   * Handle Input Key Change
   */
  handleCidrIPChange = event => {
    let cidr = this.state.cidr ? this.state.cidr : "";
    cidr = event.target && event.target.value ? event.target.value : "";
    cidr =
      cidr +
      "/" +
      (cidr.split("/")[1]
        ? cidr.split("/")[1]
        : this.state.cidrMask
        ? this.state.cidrMask
        : "");
    this.setState({
      cidrIP: event.target && event.target.value ? event.target.value : "",
      cidr: cidr,
    });
    this.props.handleChange(cidr);
  };
  render() {
    return (
      <span>
        <Input
          title={this.props.title ? this.props.title : null}
          type="text"
          id="ipAddress"
          prefix={<Icons type="ai" name="AiOutlineCalculator" />}
          style={{ width: this.props.ipLength ? this.props.ipLength : "61.5%" }}
          placeholder={getCurrentlocaleText("network.form.ipAddress.text")}
          onChange={this.handleCidrIPChange.bind(this)}
          maxLength={65}
          disabled={this.props.isDisabled}
          value={this.state.cidrIP}
          onFocus={this.props.onFocus}
        />
        <span style={{ paddingRight: 5, paddingLeft: 5 }}>/</span>
        <Input
          title={this.props.title ? this.props.title : null}
          type="text"
          id="maskLength"
          style={{
            width: this.props.maskLength ? this.props.maskLength : "34%",
          }}
          maxLength={2}
          disabled={this.props.isDisabled || this.props.maskDisabled}
          placeholder={getCurrentlocaleText("network.form.maskLength.text")}
          onFocus={this.props.onFocus}
          value={this.state.cidrMask}
          onChange={this.handleCidrMaskChange.bind(this)}
        />
      </span>
    );
  }
}
export default CidrProvider;
