import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Spin, Row, Col } from "antd";
import {
  ALERT,
  getCurrentlocaleText,
  getAlertOrgScope,
  getTagKeyValues,
} from "Core/Utils";
import OrgController from "controller/OrgController";
import InodeController from "controller/InodeController";
import NetworkController from "controller/NetworkController";
import LabelProvider from "components/UI-Components/LabelProvider";
import ClusterController from "controller/ClusterController";
import ServiceController from "controller/ServiceController";

const alert_types = [
  ALERT.TYPE.SERVICE_STATE_CHANGE,
  ALERT.TYPE.NODE_STATE_CHANGE,
  ALERT.TYPE.TUNNEL_STATE_CHANGE,
  ALERT.TYPE.HEADLESS_EXPIRY,
  ALERT.TYPE.CERT_EXPIRY,
  ALERT.TYPE.NODE_IP_CHANGE,
  ALERT.TYPE.NODE_UPGRADE,
  ALERT.TYPE.NODE_METRICS,
  ALERT.TYPE.AGGREGATED_ROUTE_CHANGE,
  ALERT.TYPE.DUPLICATE_ADDRESS_DETECTION,
  ALERT.TYPE.THREAT_INTELLIGENCE,
];

@inject("UiStore", "OrgViewModel")
@observer
class AlertExpandableRow extends Component {
  constructor(props) {
    super(props);
    this.UiStore = this.props.UiStore;
    this.state = {
      org: { data: null, loading: false },
      node: { data: null, loading: false },
      network: { data: null, loading: false },
      service: { data: null, loading: false },
      label: { data: [], loading: false },
      cluster: { data: [], loading: false },
    };
  }

  componentDidMount() {
    if (
      this.props.record &&
      this.props.record.type &&
      this.props.record.settings
    ) {
      let settings = this.props.record.settings,
        type = this.props.record.type;
      // For Org
      let org = this.state.org;
      if (settings.org_id) {
        if (
          this.UiStore.currentUser &&
          this.UiStore.currentUser.organization &&
          this.UiStore.currentUser.organization.id === settings.org_id
        ) {
          org.data = this.UiStore.currentUser.organization;
          org.loading = false;
          this.setState({ org });
        } else {
          org.loading = true;
          this.setState({ org });
          OrgController.getOrgById(settings.org_id)
            .then(response => {
              if (response) {
                org.data = response;
                this.props.OrgViewModel.addOrg(response);
              } else {
                org.data = null;
              }
              org.loading = false;
              this.setState({ org });
            })
            .catch(error => {
              org.data = null;
              org.loading = false;
              this.setState({ org });
            });
        }
      } else {
        org.data = null;
        org.loading = false;
        this.setState({ org });
      }
      // For Label
      let label = this.state.label;
      if (this.props.record.metadata) {
        label.data = getTagKeyValues(this.props.record.metadata);
        label.loading = false;
        this.setState({ label });
      }

      // For iNode, Network & service
      let node = this.state.node,
        network = this.state.network,
        service = this.state.service,
        cluster = this.state.cluster;
      if (settings.node_id) {
        node.loading = true;
        this.setState({ node });
        InodeController.getInodeById(settings.node_id)
          .then(node_response => {
            if (node_response) {
              node.data = node_response;
              node.loading = false;
              this.setState({ node });
              if (type === ALERT.TYPE.TUNNEL_STATE_CHANGE) {
                if (!settings.network_id) {
                  network.data = {
                    name: `${getCurrentlocaleText(
                      "alertform.allnetworks.text",
                    )} \u27F7 ${getCurrentlocaleText(
                      "alertform.allremotenetworks.text",
                    )}`,
                  };
                  network.loading = false;
                  this.setState({ network });
                } else if (settings.network_id && !settings.tunnel_id) {
                  network.loading = true;
                  this.setState({ network });
                  if (node_response.networks) {
                    let nw = node_response.networks.find(n => {
                      return n.id === settings.network_id;
                    });
                    if (nw) {
                      network.data = {
                        name:
                          nw.name +
                          " \u27F7 " +
                          getCurrentlocaleText(
                            "alertform.allremotenetworks.text",
                          ),
                      };
                    } else {
                      network.data = null;
                    }
                  } else {
                    network.data = null;
                  }
                  network.loading = false;
                  this.setState({ network });
                } else if (settings.network_id && settings.tunnel_id) {
                  network.loading = true;
                  this.setState({ network });
                  NetworkController.getSingleNetworkById(settings.network_id)
                    .then(response => {
                      if (response && response.connected_networks) {
                        let cnw = response.connected_networks.find(rnw => {
                          return rnw.id === settings.tunnel_id;
                        });
                        if (cnw) {
                          network.data = {
                            name:
                              response.name +
                              " \u27F7 " +
                              cnw.node.name +
                              " / " +
                              cnw.network.name,
                          };
                        } else {
                          network.data = null;
                        }
                      } else {
                        network.data = null;
                      }
                      network.loading = false;
                      this.setState({ network });
                    })
                    .catch(error => {
                      network.data = null;
                      network.loading = false;
                      this.setState({ network });
                    });
                }
              } else if (type === ALERT.TYPE.AGGREGATED_ROUTE_CHANGE) {
                if (!settings.network_id) {
                  network.data = {
                    name: `${getCurrentlocaleText(
                      "alertform.allnetworks.text",
                    )}`,
                  };
                  network.loading = false;
                  this.setState({ network });
                } else if (settings.network_id) {
                  network.loading = true;
                  this.setState({ network });
                  if (node_response.networks) {
                    let nw = node_response.networks.find(n => {
                      return n.id === settings.network_id;
                    });
                    if (nw) {
                      network.data = {
                        name: nw.name,
                      };
                    } else {
                      network.data = null;
                    }
                  } else {
                    network.data = null;
                  }
                  network.loading = false;
                  this.setState({ network });
                }
              } else if (type === ALERT.TYPE.SERVICE_STATE_CHANGE) {
                if (!settings.service_id) {
                  service.data = {
                    name: getCurrentlocaleText("alertform.allservices.text"),
                  };
                  service.loading = false;
                  this.setState({ service });
                } else {
                  service.loading = true;
                  this.setState({ service });
                  if (node_response.services) {
                    let serv = node_response.services.find(s => {
                      return s.id === settings.service_id;
                    });
                    if (serv) {
                      service.data = serv;
                    } else {
                      service.data = null;
                    }
                  } else {
                    service.data = null;
                  }
                  service.loading = false;
                  this.setState({ service });
                }
              }
            } else {
              node.data = null;
              node.loading = false;
              this.setState({ node });
            }
          })
          .catch(error => {
            node.data = null;
            node.loading = false;
            this.setState({ node });
          });
      } else if (settings.node_label) {
        node.loading = true;
        this.setState({ node });
        node.data = {};
        node.data.name = settings.node_label;
        node.loading = false;
        this.setState({ node });
        if (type === ALERT.TYPE.TUNNEL_STATE_CHANGE) {
          if (!settings.network_id) {
            network.data = {
              name: `${getCurrentlocaleText(
                "alertform.allnetworks.text",
              )} \u27F7 ${getCurrentlocaleText(
                "alertform.allremotenetworks.text",
              )}`,
            };
            network.loading = false;
            this.setState({ network });
          }
        } else if (type === ALERT.TYPE.AGGREGATED_ROUTE_CHANGE) {
          if (!settings.network_id) {
            network.data = {
              name: `${getCurrentlocaleText("alertform.allnetworks.text")}`,
            };
            network.loading = false;
            this.setState({ network });
          }
        } else if (type === ALERT.TYPE.SERVICE_STATE_CHANGE) {
          if (!settings.service_id) {
            service.data = {
              name: getCurrentlocaleText("alertform.allservices.text"),
            };
            service.loading = false;
            this.setState({ service });
          }
        }
      }
      // for cluster
      else if (settings.cluster_id) {
        cluster.loading = true;
        this.setState({ cluster });
        ClusterController.getClusterById(settings.cluster_id)
          .then(cluster_response => {
            if (cluster_response) {
              cluster.data = cluster_response;
              cluster.loading = false;
              this.setState({ cluster });
              if (type === ALERT.TYPE.TUNNEL_STATE_CHANGE) {
                if (!settings.network_id) {
                  network.data = {
                    name: `${getCurrentlocaleText(
                      "alertform.allnetworks.text",
                    )} \u27F7 ${getCurrentlocaleText(
                      "alertform.allremotenetworks.text",
                    )}`,
                  };
                  network.loading = false;
                  this.setState({ network });
                } else if (settings.network_id && !settings.tunnel_id) {
                  network.loading = true;
                  NetworkController.getSingleNetworkById(
                    settings.network_id,
                  ).then(response => {
                    this.setState({ network });
                    if (response) {
                      let nw = response;
                      if (nw) {
                        network.data = {
                          name:
                            nw.name +
                            " \u27F7 " +
                            getCurrentlocaleText(
                              "alertform.allremotenetworks.text",
                            ),
                        };
                      } else {
                        network.data = null;
                      }
                    } else {
                      network.data = null;
                    }
                    network.loading = false;
                    this.setState({ network });
                  });
                } else if (settings.network_id && settings.tunnel_id) {
                  network.loading = true;
                  this.setState({ network });
                  NetworkController.getSingleNetworkById(settings.network_id)
                    .then(response => {
                      if (response && response.connected_networks) {
                        let cnw = response.connected_networks.find(rnw => {
                          return rnw.id === settings.tunnel_id;
                        });
                        if (cnw) {
                          network.data = {
                            name:
                              response.name +
                              " \u27F7 " +
                              cnw.node.name +
                              " / " +
                              cnw.network.name,
                          };
                        } else {
                          network.data = null;
                        }
                      } else {
                        network.data = null;
                      }
                      network.loading = false;
                      this.setState({ network });
                    })
                    .catch(error => {
                      network.data = null;
                      network.loading = false;
                      this.setState({ network });
                    });
                }
              }
              if (type === ALERT.TYPE.AGGREGATED_ROUTE_CHANGE) {
                if (!settings.network_id) {
                  network.data = {
                    name: `${getCurrentlocaleText(
                      "alertform.allnetworks.text",
                    )}`,
                  };
                  network.loading = false;
                  this.setState({ network });
                } else if (settings.network_id) {
                  network.loading = true;
                  NetworkController.getSingleNetworkById(
                    settings.network_id,
                  ).then(response => {
                    this.setState({ network });
                    if (response) {
                      let nw = response;
                      if (nw) {
                        network.data = {
                          name: nw.name,
                        };
                      } else {
                        network.data = null;
                      }
                    } else {
                      network.data = null;
                    }
                    network.loading = false;
                    this.setState({ network });
                  });
                }
              } else if (type === ALERT.TYPE.SERVICE_STATE_CHANGE) {
                if (!settings.service_id) {
                  service.data = {
                    name: getCurrentlocaleText("alertform.allservices.text"),
                  };
                  service.loading = false;
                  this.setState({ service });
                } else {
                  service.loading = true;
                  this.setState({ service });
                  ServiceController.getSingleServiceById(settings.service_id)
                    .then(service_response => {
                      if (service_response) {
                        let serv = service_response;
                        if (serv) {
                          service.data = serv;
                        }
                      } else {
                        service.data = null;
                      }

                      service.loading = false;
                      this.setState({ service });
                    })
                    .catch(error => {
                      service.data = null;
                      service.loading = false;
                      this.setState({ cluster });
                    });
                }
              }
            } else {
              cluster.data = null;
              cluster.loading = false;
              this.setState({ cluster });
            }
          })
          .catch(error => {
            cluster.data = null;
            cluster.loading = false;
            this.setState({ cluster });
          });
      } else if (settings.cluster_label) {
        cluster.loading = true;
        this.setState({ cluster });

        cluster.data = {};
        cluster.data.name = settings.cluster_label;
        cluster.loading = false;
        this.setState({ cluster });
        if (type === ALERT.TYPE.TUNNEL_STATE_CHANGE) {
          if (!settings.network_id) {
            network.data = {
              name: `${getCurrentlocaleText(
                "alertform.allnetworks.text",
              )} \u27F7 ${getCurrentlocaleText(
                "alertform.allremotenetworks.text",
              )}`,
            };
            network.loading = false;
            this.setState({ network });
          }
        } else if (type === ALERT.TYPE.AGGREGATED_ROUTE_CHANGE) {
          if (!settings.network_id) {
            network.data = {
              name: `${getCurrentlocaleText("alertform.allnetworks.text")}`,
            };
            network.loading = false;
            this.setState({ network });
          }
        } else if (type === ALERT.TYPE.SERVICE_STATE_CHANGE) {
          if (!settings.service_id) {
            service.data = {
              name: getCurrentlocaleText("alertform.allservices.text"),
            };
            service.loading = false;
            this.setState({ service });
          }
        }
      }
    }
  }

  getOrgName = org_id => {
    let orgs = this.props.OrgViewModel.orgs;
    let org_name = getCurrentlocaleText("deleted.org.text");
    if (orgs && orgs.length > 0) {
      let current_org = orgs.filter(org => {
        if (org.id === org_id) return org;
      });
      org_name =
        current_org.length > 0
          ? current_org[0].name
          : getCurrentlocaleText("deleted.org.text");
    } else {
      return org_name;
    }
    return org_name;
  };

  render() {
    let { org, node, network, service, label, cluster } = this.state;
    let record = this.props.record;
    let orgSpan = (
        <span>
          {org.loading ? (
            <span>
              <Spin size="small" className="iot-tag-label" />
              {getCurrentlocaleText("loading.text")}
            </span>
          ) : org.data ? (
            org.data.name
          ) : (
            getCurrentlocaleText("deleted.org.text")
          )}
        </span>
      ),
      nodeSpan = (
        <span>
          {node.loading ? (
            <span>
              <Spin size="small" className="iot-tag-label" />
              {getCurrentlocaleText("loading.text")}
            </span>
          ) : node.data ? (
            node.data.name
          ) : (
            getCurrentlocaleText("deleted.inode.text")
          )}
        </span>
      ),
      networkSpan = (
        <span>
          {network.loading ? (
            <span>
              <Spin size="small" className="iot-tag-label" />
              {getCurrentlocaleText("loading.text")}
            </span>
          ) : network.data ? (
            network.data.name
          ) : (
            getCurrentlocaleText("deleted.network.text")
          )}
        </span>
      ),
      serviceSpan = (
        <span>
          {service.loading ? (
            <span>
              <Spin size="small" className="iot-tag-label" />
              {getCurrentlocaleText("loading.text")}
            </span>
          ) : service.data ? (
            service.data.name
          ) : (
            getCurrentlocaleText("deleted.service.text")
          )}
        </span>
      ),
      labelSpan = (
        <span>
          {label.loading ? (
            <span>
              <Spin size="small" className="iot-tag-label" />
              {getCurrentlocaleText("loading.text")}
            </span>
          ) : label.data && label.data.length > 0 ? (
            <LabelProvider
              metadata={this.props.record.metadata}
              tags={label.data}
              showPopupLabel={false}
              viewOnly={true}
            />
          ) : (
            "-"
          )}
        </span>
      ),
      clusterSpan = (
        <span>
          {node.loading ? (
            <span>
              <Spin size="small" className="iot-tag-label" />
              {getCurrentlocaleText("loading.text")}
            </span>
          ) : cluster.data ? (
            cluster.data.name
          ) : (
            getCurrentlocaleText("deleted.cluster.text")
          )}
        </span>
      );

    return (
      <div>
        {!this.props.isTargetDisplay && (
          <div>
            {this.props.record.metadata && (
              <div className="mb-10">
                <Row>
                  <Col span={5}>
                    <strong>{getCurrentlocaleText("label.text")}</strong>
                  </Col>
                  <Col span={1}>{":"}</Col>
                  <Col span={18}>{labelSpan}</Col>
                </Row>
              </div>
            )}
            {this.props.record.settings.org_id && (
              <div className="mb-10">
                <Row>
                  <Col span={5}>
                    <strong>
                      {getCurrentlocaleText("alertform.organization.text")}
                    </strong>
                  </Col>
                  <Col span={1}>{":"}</Col>
                  <Col span={18}>{orgSpan}</Col>
                </Row>
              </div>
            )}
            {this.props.record.settings.node_id && (
              <div>
                <div className="mb-10">
                  <Row>
                    <Col span={5}>
                      <strong>
                        {getCurrentlocaleText("alertform.inode.text")}
                      </strong>
                    </Col>
                    <Col span={1}>{":"}</Col>
                    <Col span={18}>{nodeSpan}</Col>
                  </Row>
                </div>
                {this.props.record.type ===
                  ALERT.TYPE.AGGREGATED_ROUTE_CHANGE && (
                  <div className="mb-10">
                    <Row>
                      <Col span={5}>
                        <strong>
                          {getCurrentlocaleText(
                            "alertform.aggregated_route_change.text",
                          )}
                        </strong>
                      </Col>
                      <Col span={1}>{":"}</Col>
                      <Col span={18}>{networkSpan}</Col>
                    </Row>
                  </div>
                )}{" "}
                {this.props.record.type === ALERT.TYPE.TUNNEL_STATE_CHANGE && (
                  <div className="mb-10">
                    <Row>
                      <Col span={5}>
                        <strong>
                          {getCurrentlocaleText(
                            "alertform.network_remote_network.text",
                          )}
                        </strong>
                      </Col>
                      <Col span={1}>{":"}</Col>
                      <Col span={18}>{networkSpan}</Col>
                    </Row>
                  </div>
                )}
                {this.props.record.type === ALERT.TYPE.SERVICE_STATE_CHANGE && (
                  <div className="mb-10">
                    <Row>
                      <Col span={5}>
                        <strong>
                          {getCurrentlocaleText("alertform.service.text")}
                        </strong>
                      </Col>
                      <Col span={1}>{":"}</Col>
                      <Col span={18}>{serviceSpan}</Col>
                    </Row>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {this.props.isTargetDisplay && (
          <div>
            {/*Org scope*/}
            {record.scope.scope === ALERT.SCOPE.ORG_SCOPE && (
              <div>
                {alert_types.includes(record.type) && (
                  <div>
                    <span>{`${
                      record.settings &&
                      record.settings.org_id &&
                      this.UiStore.currentUser.organization &&
                      this.UiStore.currentUser.organization.id &&
                      record.settings.org_id !=
                        this.UiStore.currentUser.organization.id
                        ? this.getOrgName(record.settings.org_id)
                        : getAlertOrgScope(record.scope.organization)
                    } / ${getCurrentlocaleText(
                      "alertform.allinodes.text",
                    )}`}</span>
                    {record.type === ALERT.TYPE.TUNNEL_STATE_CHANGE && (
                      <span>{` / ${getCurrentlocaleText(
                        "alertform.allnetworks.text",
                      )} - ${getCurrentlocaleText(
                        "alertform.allremotenetworks.text",
                      )}`}</span>
                    )}
                    {record.type === ALERT.TYPE.AGGREGATED_ROUTE_CHANGE && (
                      <span>{` / ${getCurrentlocaleText(
                        "alertform.allnetworks.text",
                      )}`}</span>
                    )}

                    {record.type === ALERT.TYPE.SERVICE_STATE_CHANGE && (
                      <span>{` / ${getCurrentlocaleText(
                        "alertform.allservices.text",
                      )}`}</span>
                    )}
                  </div>
                )}
              </div>
            )}
            {/*iNode scope*/}
            {record.scope.scope === ALERT.SCOPE.NODE_SCOPE && (
              <div>
                {alert_types.includes(record.type) && (
                  <div>
                    <span>{orgSpan}</span>
                    <span>{" / "}</span>
                    <span>{nodeSpan}</span>
                    {record.type === ALERT.TYPE.TUNNEL_STATE_CHANGE && (
                      <div>
                        <span>{" / "}</span>
                        <span>{networkSpan}</span>
                      </div>
                    )}
                    {record.type === ALERT.TYPE.AGGREGATED_ROUTE_CHANGE && (
                      <div>
                        <span>{" / "}</span>
                        <span>{networkSpan}</span>
                      </div>
                    )}{" "}
                    {record.type === ALERT.TYPE.SERVICE_STATE_CHANGE && (
                      <div>
                        <span>{" / "}</span>
                        <span>{serviceSpan}</span>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            {/*cluster scope*/}
            {record.scope.scope === ALERT.SCOPE.CLUSTER_SCOPE && (
              <div>
                {alert_types.includes(record.type) && (
                  <div>
                    <span>{orgSpan}</span>
                    <span>{" / "}</span>
                    <span>{clusterSpan}</span>
                    {record.type === ALERT.TYPE.TUNNEL_STATE_CHANGE && (
                      <div>
                        <span>{" / "}</span>
                        <span>{networkSpan}</span>
                      </div>
                    )}
                    {record.type === ALERT.TYPE.AGGREGATED_ROUTE_CHANGE && (
                      <div>
                        <span>{" / "}</span>
                        <span>{networkSpan}</span>
                      </div>
                    )}
                    {record.type === ALERT.TYPE.SERVICE_STATE_CHANGE && (
                      <div>
                        <span>{" / "}</span>
                        <span>{serviceSpan}</span>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default AlertExpandableRow;
