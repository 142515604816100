import React from "react";
import propTypes from "prop-types";
import { Modal, Select } from "antd";
const Option = Select.Option;

const AddWidgetModal = ({
  widgets,
  isModalOpen,
  onRequestClose,
  onWidgetSelect,
}) => {
  const widgetItems = Object.keys(widgets).map((widget, key) => {
    return (
      <Option key={widgets[widget].title} value={widgets[widget].key}>
        {widgets[widget].title}
      </Option>
    );
  });
  return (
    <div>
      {isModalOpen && (
        <Modal
          title="Change Widget"
          visible={isModalOpen}
          onCancel={onRequestClose}
          onOk={onRequestClose}
          cancelText={"Close"}
          maskClosable={false}
        >
          <Select
            placeholder="Select a widget"
            onChange={onWidgetSelect}
            style={{ width: 200 }}
          >
            {widgetItems}
          </Select>
        </Modal>
      )}
    </div>
  );
};

AddWidgetModal.propTypes = {
  widgets: propTypes.object,
  isModalOpen: propTypes.bool,
  onRequestClose: propTypes.func,
  onWidgetSelect: propTypes.func,
};

export default AddWidgetModal;
