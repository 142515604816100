import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Timeline, Col, Popover, Tooltip, Spin, Empty } from "antd";
import { observer, inject } from "mobx-react";
import "../../assets/styles/Activity.css";
import { getCurrentlocaleText } from "Core/Utils";
import ActivityController from "controller/ActivityController";

@inject(
  "DashboardStore",
  "ActivityViewModel",
  "OrgViewModel",
  "AuthStore",
  "UiStore",
)
@observer
class RecentActivity extends Component {
  constructor(props) {
    super(props);
    this.UiStore = this.props.UiStore;
    this.activityModel = this.props.ActivityViewModel;
  }

  UNSAFE_componentWillMount() {
    this.UiStore.setActivityDivScroll({
      height: null,
    });
  }

  componentDidMount() {
    this.getActivity();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentOrgId != this.props.currentOrgId) {
      this.getActivity();
    }
  }

  getActivity = () => {
    let params = {
      org_id: this.props.currentOrgId,
      size: 25,
    };
    ActivityController.getActivities(params, true, false);
  };

  computeHeight = className => {
    if (className) {
      let element = document.querySelector(className);
      if (element) {
        let bounding = element.getBoundingClientRect();
        if (bounding) {
          return window.innerHeight - 0.3 * window.innerHeight;
        }
      }
    }
  };

  // Activities Timeline element
  renderActivities = activities => {
    if (activities) {
      return activities.map((val, index) => {
        let desc = val.display_description;
        return (
          <Timeline.Item pending={false} key={"timeleine" + index}>
            <Row>
              <Col push={1} span={23} className="act-action-type">
                <b>{val.display_type}</b>
              </Col>
            </Row>
            <Row>
              <Popover
                content={desc}
                title="Description"
                trigger="hover"
                autoAdjustOverflow={false}
              >
                <Col push={1} span={23} className="act-description">
                  {desc.replace("firewall group", "security policy")};
                </Col>
              </Popover>
            </Row>
            <Row type="flex">
              <Col push={1} span={12}>
                <div className="act-org-name">
                  {val.organization.name &&
                  val.organization.name.length > 20 ? (
                    <Tooltip
                      placement="topRight"
                      title={val.organization.name ? val.organization.name : ""}
                      arrowPointAtCenter
                    >
                      {val.organization.name ? val.organization.name : ""}
                    </Tooltip>
                  ) : val.organization.name ? (
                    val.organization.name
                  ) : (
                    ""
                  )}
                </div>
              </Col>
              <Col span={12} className="act-timestamp">
                {val.relative_date}
              </Col>
            </Row>
          </Timeline.Item>
        );
      });
    }
  };

  render() {
    let path = window.location.pathname.split("/");
    let activities = this.activityModel.activityList;
    let hasNextPage =
      this.activityModel.pageable.page <
      this.activityModel.pageable.total_page - 1;
    return (
      <div>
        <Row gutter={16}>
          <Col span={24}>
            <div
              className="recentActivityList"
              style={{
                height:
                  this.UiStore.activityDivScroll &&
                  this.UiStore.activityDivScroll.height
                    ? this.UiStore.activityDivScroll.height
                    : this.computeHeight(".recentActivityList"),
                overflow: "auto",
              }}
            >
              {this.activityModel.loading ? (
                <div className="chart-spinner">
                  <Spin tip="Loading..." />
                </div>
              ) : activities && activities.length > 0 ? (
                <Timeline className="activity-timeline">
                  {this.renderActivities(activities)}
                </Timeline>
              ) : (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={getCurrentlocaleText("no.activity.text")}
                />
              )}
            </div>
            {!this.activityModel.loading &&
              activities &&
              activities.length > 0 &&
              hasNextPage && (
                <div style={{ textAlign: "center", marginTop: 20 }}>
                  <Link to={path.join("/") + "/activities"}>
                    <strong>{getCurrentlocaleText("moreActivity.text")}</strong>
                  </Link>
                </div>
              )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default RecentActivity;
