import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { isLoggedIn } from "Core/Utils";

class PublicRoute extends Component {
  render() {
    return <Route component={this.props.component} path={this.props.path} />;
  }
}

export default PublicRoute;
