import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Button, Modal } from "antd";
import { focusFirstInput } from "Core/Utils";
import ChangePasswordForm from "components/Forms/ChangePasswordForm";
import UserController from "controller/UserController";
import axios from "axios";
@inject("ModelStore", "UiStore", "AuthStore")
@observer
class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  handleCancel = () => {
    this.setState({ loading: false });
    this.props.UiStore.ChangePasswordMdl = false;
  };

  componentDidMount() {
    /* Focus First Input */
    focusFirstInput();
  }

  handleSubmit = e => {
    e.preventDefault();

    this.actionForm.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        // Remove confirm password field
        if (values && values.confirm_password) {
          delete values.confirm_password;
        }
        UserController.ChangeUserPassword(values)
          .then(response => {
            this.handleCancel();
            setTimeout(() => {
              let org = this.props.AuthStore.loggedInUserOrg;
              if (org && org.is_central_auth && org.is_central_auth === true) {
                this.props.ModelStore.Logout();
              } else {
                this.props.ModelStore.Logout();
                this.props.UiStore.SetLoginStatus(false);
                this.props.AuthStore.resetStore();
              }
            }, 1000);
          })
          .catch(error => {
            this.setState({ loading: false });
          });
      }
    });
  };
  render() {
    return (
      <div>
        <Modal
          visible={this.props.UiStore.ChangePasswordMdl}
          title="Change Password"
          onCancel={this.handleCancel.bind(this)}
          maskClosable={false}
          confirmLoading={this.state.loading}
          closable={!this.state.loading}
          footer={[
            <Button
              key="back"
              size="large"
              onClick={this.handleCancel.bind(this)}
              disabled={this.state.loading}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              size="large"
              onClick={this.handleSubmit.bind(this)}
            >
              {" "}
              Change
            </Button>,
          ]}
        >
          <ChangePasswordForm
            onSubmit={this.handleSubmit}
            ref={c => (this.actionForm = c)}
            oldPasswordLabel="Current Password"
            newPasswordLabel="New Password"
            confirmPasswordLabel="Confirm New Password"
            showFormErrorMessage={true}
            errorMessage="Please enter your current password"
            showHeader={false}
          />
        </Modal>
      </div>
    );
  }
}

export default ChangePassword;
