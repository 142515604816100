import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import { Upload, Button } from "antd";
import {
  randomAlphaNumericString,
  isEmpty,
  getCurrentlocaleText,
  copyObject,
} from "Core/Utils";
import { inject } from "mobx-react";
import propTypes from "prop-types";
import { computed } from "mobx";
import Twig from "twig";
import Icons from "../../../../UI-Components/Icons";

/*Define form elements*/
const FormItem = Form.Item;

@inject("UiStore")
export default class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.isFileSizeExceed = false;
    this.fileSize = null;
    this.state = {
      fileList: [],
      uploading: false,
    };
    this.validationMessage =
      this.props.properties.constraints &&
      this.props.properties.constraints.validationMessage
        ? this.props.properties.constraints.validationMessage
        : `${this.props.properties.label} is required`;
  }

  componentDidMount() {
    if (this.props.id.indexOf("fileupload") >= 0) {
      /* fileupload is used in templates. check renderSpec() in validator */
      this.parent_id = this.props.id;
    } else {
      /* file upload is used inside license component */
      let ele_id = this.props.id;
      this.parent_id = ele_id.split("_");
      this.parent_id = this.parent_id
        .splice(0, this.parent_id.length - 1)
        .join("_");
    }

    this.props.UiStore.templatingService.secrets[this.parent_id] = {
      secret_volume: {},
    };
  }
  @computed
  get componentVisibility() {
    let visibility = null;
    if (
      this.props.UiStore.computedOutputObject &&
      this.props.UiStore.computedOutputObject.steps
    ) {
      if (this.props.properties.visibility) {
        var template = Twig.twig({
          data: this.props.properties.visibility,
        });
        let computedOutputObject = copyObject(
          this.props.UiStore.computedOutputObject,
        );
        visibility = template.render(computedOutputObject);
        try {
          visibility = JSON.parse(visibility);
        } catch {}
      }
    }
    return visibility ? true : false;
  }
  handleChange = info => {
    let file = info.file;
    if (file.status == "removed") {
      return;
    }
    const reader = new FileReader();
    let newFileName;
    let data = [];
    if (file.size === 0 || file.size > this.props.maxSize) {
      file.response = "error";
      file.error = "error";
      file.status = "error";
    }
    reader.onload = evt => {
      let allfileNames = this.props.UiStore.templatingService.secrets[
        this.parent_id
      ].uploadedLicenseFileName
        ? this.props.UiStore.templatingService.secrets[this.parent_id]
            .uploadedLicenseFileName
        : [];
      let isFileNameAvailable = allfileNames.indexOf(file.name);
      if (isFileNameAvailable >= 0) {
        /*file name exists. Find the last integer value*/
        let filecount = allfileNames.map(val => {
          let nametosearch = val.split("_");
          nametosearch.pop();
          return (
            nametosearch.toString() === file.name &&
            val.indexOf("_") >= 0 &&
            parseInt(val.split("_")[val.split("_").length - 1])
          );
        });
        filecount = filecount.filter(
          x => !!x,
        ); /*To remove falsy from the array*/

        newFileName =
          filecount.length > 0
            ? `${file.name}_${Math.max(...filecount) + 1}`
            : `${file.name}_1`;
      } else {
        newFileName = `${file.name}`;
      }
      let val =
        this.props.properties.options &&
        this.props.properties.options.isBase64EncodingRequired
          ? btoa(unescape(encodeURIComponent(evt.target.result)))
          : evt.target.result;

      this.props.UiStore.templatingService.secrets[this.parent_id]
        .uploadedLicenseFileName
        ? this.props.UiStore.templatingService.secrets[
            this.parent_id
          ].uploadedLicenseFileName.push(newFileName)
        : ((this.props.UiStore.templatingService.secrets[
            this.parent_id
          ].uploadedLicenseFileName = []),
          this.props.UiStore.templatingService.secrets[
            this.parent_id
          ].uploadedLicenseFileName.push(newFileName));

      /*update the uploaded license list*/

      this.props.UiStore.templatingService.secrets[
        this.parent_id
      ].secret_volume[newFileName] = val;
      if (this.props.updateCurrentValueOnChange) {
        data.push({ [newFileName]: val });
        this.props.updateCurrentValueOnChange(data);
      }

      /*clear selected license from drop-down if new license is created*/
      if (
        !isEmpty(this.props.UiStore.templatingService.secrets[this.parent_id])
      ) {
        this.props.UiStore.templatingService.secrets[
          this.parent_id
        ].previousSelectedLicense = null;
      }
    };
    reader.readAsBinaryString(file);
  };

  fileRemove = info => {
    this.props.UiStore.templatingService.secrets[
      this.parent_id
    ].uploadedLicenseFileName.pop(info.name);
    this.props.UiStore.templatingService.secrets[this.parent_id].secret_volume[
      info.name
    ] = undefined;
  };

  validateFileSize = (rule, value, callback) => {
    if (value.fileList.length === 0) {
      this.isFileSizeExceed = false;
      callback(this.validationMessage);
    }
    for (let i = 0; i < value.fileList.length; i++) {
      // Check the  file size
      let isFileSizeError = false;
      if (
        value.fileList[i].size !== undefined &&
        value.fileList[i].size !== null &&
        this.props.maxSize
      ) {
        // File size is zero or File size is more than 1 MB
        if (
          value.fileList[i].size === 0 ||
          value.fileList[i].size > this.props.maxSize
        ) {
          isFileSizeError = true;
        }
      }
      if (isFileSizeError) {
        this.isFileSizeExceed = true;
        this.fileSize = value.fileList[i].size;
        break;
      } else {
        this.isFileSizeExceed = false;
        this.fileSize = value.fileList[i].size;
      }
    }
    if (value && this.isFileSizeExceed) {
      if (this.fileSize === 0) {
        callback(
          getCurrentlocaleText("volume.zero_file_size_error_message.text"),
        );
      } else if (this.fileSize > this.props.maxSize) {
        callback(getCurrentlocaleText("volume.file_size_error_message.text"));
      }
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.referenceForm; // reference of base form
    let required =
      this.props.properties.constraints &&
      this.props.properties.constraints.required
        ? this.props.properties.constraints.required
        : false;

    let id = this.props.id
      ? this.props.id
      : `textbox${randomAlphaNumericString(3)}`;

    let disable = false;
    if (this.props.editmode) {
      disable = this.props.properties.noneditable
        ? this.props.properties.noneditable
        : false;
    }
    disable = this.props.properties.disabled
      ? this.props.properties.disabled
      : disable;

    // For View only
    disable = this.props.viewOnly === true ? true : disable;

    let visibility = this.props.properties.visibility
      ? this.componentVisibility
      : true;
    return visibility ? (
      <span>
        <FormItem
          label={this.props.properties.label}
          hasFeedback
          key="uploader"
        >
          {getFieldDecorator(id, {
            rules: [
              {
                required: required,
                message: this.validationMessage,
              },
              {
                validator: this.props.maxSize ? this.validateFileSize : null,
              },
            ],
          })(
            <Upload
              className="iot-uploader"
              showUploadList={true}
              beforeUpload={() => false}
              onChange={this.handleChange}
              onRemove={this.fileRemove}
            >
              <Button className="iot-uploader-button">
                <Icons type="ai" name="AiOutlineUpload" />{" "}
                {this.props.properties.options &&
                this.props.properties.options.multiple
                  ? getCurrentlocaleText("servicetemplates.choose_files.label")
                  : getCurrentlocaleText("servicetemplates.choose_file.label")}
              </Button>
            </Upload>,
          )}
        </FormItem>
      </span>
    ) : (
      <span></span>
    );
  }
}

FileUpload.propTypes = {
  referenceForm: propTypes.any,
  properties: propTypes.shape({
    label: propTypes.string,
    disabled: propTypes.bool,
    constraints: propTypes.shape({
      required: propTypes.bool,
      validationMessage: propTypes.string,
    }),
    options: propTypes.shape({
      multiple: propTypes.bool,
      isBase64EncodingRequired: propTypes.bool,
    }),
  }),
};

/* sample schema

{
  "type": "FileUpload",
  "visible": true,
  "props": {
    "label": "upload a file",
    "help" : "<div>help for file upload</div>",
    "disabled" : false,
    "noneditable" : true,
    "options" : {
      "isBase64EncodingRequired" : false,
      "multiple" : false
    },
    "constraints" : {
      "validationMessage" : "validate me",
      "required" : true

    }
  }
}

*/
