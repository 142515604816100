import React, { Component } from "react";
import { Form, Select, Spin } from "antd";
import { customSort, getCurrentlocaleText } from "Core/Utils";
import RoleController from "controller/RoleController";
import Icons from "./Icons";

const FormItem = Form.Item;
const Option = Select.Option;

class RolesSelect extends Component {
  constructor(props) {
    super(props);
    this.searchTimeout = null;
    this.state = {
      data: [],
      mounting: false,
    };
  }

  componentDidMount() {
    let params = this.props.params || {};
    params.page = 0;
    RoleController.getRolesForDropdown(params).then(response => {
      // Only for edit
      if (this.props.initialValue && this.props.initialValue.length !== 0) {
        if (response) {
          let in_dx = response.findIndex(role => {
            return role.id === this.props.initialValue[0];
          });
          if (in_dx === -1) {
            RoleController.getRole(this.props.initialValue[0], false).then(
              resp => {
                response.push(resp);
                this.setState({
                  data: response ? this.renderList(response) : [],
                  mounting: true,
                });
              },
            );
          } else {
            this.setState({
              data: response ? this.renderList(response) : [],
              mounting: true,
            });
          }
        } else {
          RoleController.getRole(this.props.initialValue[0], false).then(
            resp => {
              response.push(resp);
              this.setState({
                data: response ? this.renderList(response) : [],
                mounting: true,
              });
            },
          );
        }
      } else {
        this.setState({
          data: response ? this.renderList(response) : [],
          mounting: true,
        });
      }
    });
  }

  fetchRoles = (search = "") => {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      let params = this.props.params || {};
      params.page = 0;
      params.search = search;
      RoleController.getRolesForDropdown(params).then(response => {
        this.setState({
          data: response ? this.renderList(response) : [],
        });
      });
    }, 500);
  };

  renderList(data) {
    return data.length > 0 ? (
      data
        .sort((x, y) => customSort(x.name, y.name))
        .map((item, i) => {
          return (
            <Option key={item.id} name={item.name}>
              {item.name}
            </Option>
          );
        })
    ) : (
      <Option key={1} disabled>
        Not found
      </Option>
    );
  }

  setValue = val => {
    let value = Array(val);
    this.props.setSelectVal({
      value,
      type: "roles",
    });
  };

  render() {
    let roles = this.state.data;
    return (
      <span>
        {this.state.mounting && (
          <Select
            showSearch
            onChange={this.setValue}
            placeholder={getCurrentlocaleText(
              "user.role.select.placeholder.text",
            )}
            prefix={
              <Icons
                type="ai"
                name="AiOutlineIdcard"
                style={{ fontSize: 13 }}
              />
            }
            defaultValue={this.props.initialValue}
            disabled={this.props.disabled ? this.props.disabled : false}
            notFoundContent={
              this.state.fetching ? <Spin size="small" /> : <div>No data</div>
            }
            filterOption={false}
            onSearch={this.fetchRoles}
            optionLabelProp="name"
          >
            {roles}
          </Select>
        )}
      </span>
    );
  }
}

export default RolesSelect;
