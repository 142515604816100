import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Row, Col } from "antd";
import BreadcrumbComponent from "components/UI-Components/BreadcrumbComponent";
import Icons from "components/UI-Components/Icons";
import InodeController from "controller/InodeController";
import { getCurrentlocaleText } from "Core/Utils";
import OrgSelector from "components/Orgs/OrgSelector";
import InodeImageList from "../NodeImages/InodeImageList";

@inject("UiStore", "InodeViewModel")
@observer
class InodeImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nodeId: this.props.match.params.nodeId || this.props.match.params.id,
      orgId: this.props.match.params.id,
      BreadcrumbList: [],
    };
  }

  componentDidMount() {
    InodeController.getInode(this.state.nodeId, true, false).then(res => {
      if (res) {
        let BreadcrumbList = [];
        BreadcrumbList.push({
          name: res.organization ? res.organization.name : "-",
          link: `orgs/${res.organization.id}`,
        });
        BreadcrumbList.push({
          name: getCurrentlocaleText("inodes.navigation_menu.text"),
          link: `orgs/${res.organization.id}/inodes`,
        });
        BreadcrumbList.push({
          name: res.name,
          link: `orgs/${res.organization.id}/inodes/${res.id}`,
        });
        BreadcrumbList.push({
          name: getCurrentlocaleText("inodes.images.tabheader.link.text"),
          link: `orgs/${res.organization.id}/inodes/${res.id}/images`,
        });
        this.setState({ BreadcrumbList });
        this.props.UiStore.setInodeName(res.name);
        this.props.UiStore.setOrgName(res.organization.name);
      }
    });
  }

  render() {
    return (
      <div>
        <Row gutter={24} type="flex" align="middle">
          <Col lg={{ span: 18 }} xl={{ span: 20 }}>
            <h2 className="page-title">
              <Icons
                type="fa"
                name="FaRegFileArchive"
                className="valign-middle"
              />
              {"  "}
              {getCurrentlocaleText("inodes.images.tabheader.link.text")}
            </h2>
            <BreadcrumbComponent
              {...this.props}
              BreadcrumbList={this.state.BreadcrumbList}
            />
          </Col>
          <Col lg={{ span: 6 }} xl={{ span: 4 }} className="flex flex-h-right">
            <OrgSelector {...this.props} />
          </Col>
        </Row>
        <div style={{ minHeight: 360 }}>
          <Row gutter={16} type="flex" align="middle">
            <Col span={24}>
              <InodeImageList
                showMoreImages={false}
                nodeId={this.state.nodeId}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default InodeImages;
