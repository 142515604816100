import React, { Component } from "react";
import { Breadcrumb } from "antd";
import { NavLink } from "react-router-dom";
import _ from "lodash";
import propTypes from "prop-types";
import { observer, inject } from "mobx-react";
import { getCurrentlocaleText } from "Core/Utils";

const renderList = BreadcrumbList => {
  return BreadcrumbList.map((item, index) => {
    return (
      <Breadcrumb.Item key={item.name ? item.name : item.link}>
        <NavLink
          exact
          activeClassName="active"
          to={`/${item.link}`}
          disabled={!item.link ? true : false}
          className={!item.link ? "disabled-link" : ""}
        >
          {item.name}
        </NavLink>
      </Breadcrumb.Item>
    );
  });
};

@inject("UiStore")
@observer
class BreadcrumbComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadCrumbList: [],
    };
  }

  componentDidMount() {
    this.setState({
      breadCrumbList:
        this.props.BreadcrumbList && renderList(this.props.BreadcrumbList),
    });
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      breadCrumbList: props.BreadcrumbList && renderList(props.BreadcrumbList),
    });
  }

  isActive = (match, location) => {
    if (location && location.pathname === "/") {
      this.props.UiStore.SetStoreData(
        "currentRouteLocation",
        location.pathname,
      );
    }
  };

  render() {
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item key="home">
            <NavLink
              exact
              activeClassName="active"
              to="/home"
              isActive={this.isActive}
            >
              {getCurrentlocaleText("general.home.text")}
            </NavLink>
          </Breadcrumb.Item>
          {this.state.breadCrumbList}
        </Breadcrumb>
      </div>
    );
  }
}

BreadcrumbComponent.propTypes = {
  BreadcrumbList: propTypes.array,
};

export default BreadcrumbComponent;
