import React, { Component } from "react";
import { Card, Row, Col, Progress, Tag, Typography } from "antd";
import { NodeStatus } from "components/INodes";
import Icons from "components/UI-Components/Icons";
import {
  getIP,
  getNodeVersion,
  getLastContactedAt,
  NOT_APPLICAPLE,
  isEllipsisActive,
  getCurrentlocaleText,
  PlotTimeDiff,
  formatRemainingTimeDiff,
  getTagKeyValues,
  getTagFromReservedLabels,
  INTERFACE_DIAGRAM_AVAILABLE_HARDWARES,
  getNetworkStatusColorCode,
  getNetworkStatusMessage,
} from "Core/Utils";
import InodeAdvanceInfo from "components/INodes/InodeAdvanceInfo";
import { inject, observer } from "mobx-react";
import LabelProvider from "components/UI-Components/LabelProvider";
import RMAReactivationForm from "components/Forms/RMAReactivationForm";
import InterfaceDiagram from "components/UI-Components/InterfaceDiagram";
import PopOverHelp from "components/UI-Components/PopOverHelp";
import InodeViewModel from "../../view-model/InodeViewModel";
const { Paragraph } = Typography;

@inject("AuthStore")
@observer
class InodeInfoCard extends Component {
  constructor(props) {
    super(props);
    this.EnvConfig = ENV_CONFIG;
    this.state = {
      node_data: {},
      showInterfaceDiagram: false,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ node_data: props.node_data });
    this.setSerialNumberTitle();
  }

  setSerialNumberTitle = () => {
    let e = document.getElementById("inode-serial-number");
    if (e && isEllipsisActive(e)) {
      if (this.state.node_data.hardware_serial_number) {
        e.title = this.state.node_data.hardware_serial_number;
      }
    }
  };

  onClickInterfaceDetails = () => {
    this.setState({ showInterfaceDiagram: !this.state.showInterfaceDiagram });
  };

  computeLocation = location => {
    if (
      location &&
      location.street &&
      location.city &&
      location.state &&
      location.country &&
      location.zipcode
    ) {
      return (
        <span>
          <p>{`${location.street}`}</p>
          <p>{`${location.city}, ${location.state}`}</p>
          <p>{`${location.country}, ${location.zipcode}`}</p>
        </span>
      );
    }
  };

  checkImageAvailable = vendorDetails => {
    let vendorDetail = vendorDetails.split("/");
    if (vendorDetail.length > 1) {
      let modelName = vendorDetail[1];
      modelName = modelName.replace(/ /g, "_");
      if (INTERFACE_DIAGRAM_AVAILABLE_HARDWARES.includes(modelName)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  getGatewayPublicIP(ucgGateway) {
    let ip = [];
    if (ucgGateway && ucgGateway.gateway && ucgGateway.gateway.public_ip) {
      ucgGateway.gateway.public_ip.forEach(value => {
        ip.push(value);
      });
      return ip.join(", ");
    } else {
      return "";
    }
  }

  render() {
    const statusColorCode = getNetworkStatusColorCode(
      this.props.ucgGateway.status,
    );
    const statusClassName = `tag-${statusColorCode}`;
    let node_data = this.state.node_data;
    let link =
      node_data &&
      node_data.cluster &&
      node_data.cluster.id &&
      node_data.organization
        ? `/orgs/${node_data.organization.id}/clusters/${node_data.cluster.id}`
        : "";
    let tags = node_data.metadata
      ? getTagKeyValues(node_data.metadata.labels)
      : [];
    let reservedLabels = node_data.metadata
      ? getTagFromReservedLabels(node_data.metadata.labels)
      : [];
    let cluster;
    const isUcgDataEnabled =
      this.props.ucgGateway && this.props.ucgGateway.enable;
    const isUcgStatus = this.props.ucgGateway && this.props.ucgGateway.status;
    if (node_data.cluster) {
      cluster = Object.assign({}, node_data.cluster);
      cluster.status =
        node_data &&
        node_data.status &&
        node_data.status.node &&
        node_data.status.node.node_cluster_status &&
        node_data.status.node.node_cluster_status.state
          ? node_data.status.node.node_cluster_status.state
          : null;
      cluster.isMaster =
        cluster.status && cluster.status.toUpperCase() === "MASTER"
          ? true
          : false;
    }

    const isInterfaceDiagramAvailable =
      node_data.status &&
      node_data.status.node &&
      node_data.status.node.info &&
      node_data.status.node.info.device_vendor_details
        ? this.checkImageAvailable(
            node_data.status.node.info.device_vendor_details,
          )
        : false;
    const helpData = {
      data: [
        {
          subtitle: "",
          content: getNetworkStatusMessage({
            state: this.props.ucgGateway && this.props.ucgGateway.status,
            properties:
              this.props.ucgGateway && this.props.ucgGateway.properties
                ? {
                    currentConnectAt: this.props.ucgGateway.properties
                      .currentConnectAt,
                    message: this.props.ucgGateway.properties.message,
                    errorCode: this.props.ucgGateway.properties.errorCode,
                  }
                : {},
          }),
        },
      ],
    };

    const height =
      this.props.ucgGateway && this.props.ucgGateway.status === "UNKNOWN"
        ? 30
        : 70;
    const width =
      this.props.ucgGateway && this.props.ucgGateway.status === "UNKNOWN"
        ? 120
        : 150;

    return (
      <Card
        title={
          <span>
            <Icons type="md" name="MdSettings" style={{ fontSize: 16 }} />
            &nbsp;{getCurrentlocaleText("inode.systeminfo.text")}{" "}
          </span>
        }
      >
        <Row gutter={0} type="flex" justify="center">
          <Col span={24}>
            <Row>
              <Col span={8} className="node-info-top-row">
                <strong>
                  {getCurrentlocaleText("inode.systeminfo.name.text")}
                </strong>
              </Col>
              <Col span={16} className="node-info-top-row">
                <div
                  className="ellipsis node-info-right-column"
                  title={node_data.name}
                >
                  {node_data.name}
                </div>
                {cluster &&
                  cluster.status &&
                  cluster.status.toUpperCase() === "MASTER" && (
                    <div>
                      <Tag className="iot-tag-label-color" closable={false}>
                        {getCurrentlocaleText(
                          "cluster.node_list.master.cidr.text",
                        )}
                      </Tag>
                    </div>
                  )}
              </Col>
            </Row>
            <Row>
              <Col span={8} className="node-info-top-row">
                <strong>
                  {getCurrentlocaleText("inode.location.label.text")}
                </strong>
              </Col>
              <Col span={16} className="node-info-top-row">
                <div className="ellipsis node-info-right-column node-location-info">
                  {node_data.location_info
                    ? this.computeLocation(node_data.location_info)
                    : "NA"}
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={8} className="node-info-middle-row">
                <strong>
                  {getCurrentlocaleText("inode.systeminfo.profile.text")}
                </strong>
              </Col>
              <Col span={16} className="node-info-middle-row">
                <div className="node-info-right-column">
                  {node_data.profile ? node_data.profile.name : "-"}
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={8} className="node-info-middle-row">
                <strong>
                  {getCurrentlocaleText("inode.systeminfo.publicip.text")}
                </strong>
              </Col>
              <Col span={16} className="node-info-middle-row">
                <div className="node-info-right-column">
                  {getIP(node_data, "public")}
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={8} className="node-info-middle-row">
                <strong>
                  {getCurrentlocaleText("inode.systeminfo.privateip.text")}
                </strong>
              </Col>
              <Col span={16} className="node-info-middle-row">
                <div className="node-info-right-column">
                  {getIP(node_data, "private")}
                </div>
              </Col>
            </Row>
            {this.props.isVirtual && (
              <Row>
                <Col span={8} className="node-info-middle-row">
                  <strong>
                    {getCurrentlocaleText("inode.systeminfo.virtualfqdn.text")}
                  </strong>
                </Col>
                <Col span={16} className="node-info-middle-row">
                  <div
                    className="node-info-right-column"
                    title={`${node_data.id}.${this.EnvConfig.VIRTUAL_FQDN}`}
                  >
                    {this.EnvConfig && this.EnvConfig.VIRTUAL_FQDN ? (
                      <Paragraph
                        copyable
                        ellipsis={true}
                      >{`${node_data.id}.${this.EnvConfig.VIRTUAL_FQDN}`}</Paragraph>
                    ) : (
                      "N/A"
                    )}
                  </div>
                </Col>
              </Row>
            )}
            <Row>
              <Col span={8} className="node-info-middle-row">
                <strong>
                  {getCurrentlocaleText("inode.systeminfo.health_status.text")}
                </strong>
              </Col>
              <Col span={16} className="node-info-middle-row">
                <div className="node-info-right-column">
                  <NodeStatus
                    nodeState={node_data.node_state}
                    nodeProfile={node_data.profile}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={8} className="node-info-middle-row">
                <strong>{getCurrentlocaleText("label.text")}</strong>
              </Col>
              <Col span={16} className="node-info-middle-row">
                <div className="node-info-right-column">
                  {node_data.metadata &&
                  tags.length + reservedLabels.length > 0 ? (
                    <LabelProvider
                      tags={tags}
                      metadata={node_data.metadata.labels}
                      showPopupLabel={false}
                      viewOnly={true}
                      reservedLabels={reservedLabels}
                    />
                  ) : (
                    "-"
                  )}
                </div>
              </Col>
            </Row>
            {isUcgDataEnabled ? (
              <React.Fragment>
                <Row className="expand-content-left">
                  <Col span={8} className="node-info-middle-row">
                    <strong>
                      {getCurrentlocaleText(
                        "inode.unified.cloud.gateway.ip.text",
                      )}
                    </strong>
                  </Col>
                  <Col span={16} className="node-info-middle-row">
                    <div className="node-info-right-column">
                      {this.getGatewayPublicIP(this.props.ucgGateway)}
                    </div>
                  </Col>
                </Row>
                <Row className="expand-content-left">
                  <Col span={8} className="node-info-middle-row">
                    <strong>
                      {getCurrentlocaleText(
                        "inode.unified.cloud.gatway.status.text",
                      )}
                    </strong>
                  </Col>
                  <Col span={16} className="node-info-right-column-ucg">
                    <Tag
                      className={statusClassName}
                      style={{ marginRight: "0px" }}
                    >
                      {isUcgStatus}
                    </Tag>
                    <PopOverHelp
                      className="mb-10"
                      style={{ marginTop: "-3px", fontSize: "16px" }}
                      isHelpOnForm={true}
                      height={height}
                      width={width}
                      placement="right"
                      helpData={helpData}
                    />
                  </Col>
                </Row>
              </React.Fragment>
            ) : null}
            <Row>
              <Col span={8} className="node-info-middle-row">
                <strong>
                  {getCurrentlocaleText("inode.systeminfo.serialnumber.text")}
                </strong>
              </Col>
              <Col
                span={16}
                className="node-info-middle-row"
                style={{ display: "flex" }}
              >
                {node_data.hardware_serial_number ? (
                  <div>
                    <Row>
                      <Col span={22}>
                        <div
                          id="inode-serial-number"
                          className="node-info-right-column"
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            width: 125,
                          }}
                        >
                          {node_data.hardware_serial_number}
                        </div>
                      </Col>
                      <Col span={2}>
                        <span>
                          <RMAReactivationForm
                            nodeId={this.props.nodeId}
                            node_data={node_data}
                            className="iot-manage-dropdown-actbtn"
                            oldSerialNumber={
                              node_data && node_data.hardware_serial_number
                            }
                          />
                        </span>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <div className="node-info-right-column">{NOT_APPLICAPLE}</div>
                )}
              </Col>
            </Row>
            <Row>
              <Col span={8} className="node-info-middle-row">
                <strong>
                  {getCurrentlocaleText("inode.systeminfo.version.text")}
                </strong>
              </Col>
              <Col span={16} className="node-info-middle-row">
                <div className="node-info-right-column">
                  {getNodeVersion(node_data)}
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={8} className="node-info-middle-row">
                <strong>
                  {getCurrentlocaleText("inode.systeminfo.device_vendor.text")}
                </strong>
              </Col>
              <Col span={16} className="node-info-middle-row">
                {node_data.profile &&
                node_data.profile.name.toLowerCase() === "edge" &&
                isInterfaceDiagramAvailable ? (
                  <div className="node-vendor-link node-info-right-column iot-primary-color-style iot-pointer-cursor">
                    <span onClick={this.onClickInterfaceDetails}>
                      {node_data.status &&
                      node_data.status.node &&
                      node_data.status.node.info &&
                      node_data.status.node.info.device_vendor_details
                        ? `${node_data.status.node.info.device_vendor_details}`
                        : NOT_APPLICAPLE}{" "}
                      <Icons
                        type="fa"
                        name="FaExternalLinkAlt"
                        className="middle"
                      />
                    </span>
                  </div>
                ) : (
                  <div className="node-info-right-column">
                    {node_data.status &&
                    node_data.status.node &&
                    node_data.status.node.info &&
                    node_data.status.node.info.device_vendor_details
                      ? `${node_data.status.node.info.device_vendor_details}`
                      : NOT_APPLICAPLE}
                  </div>
                )}
              </Col>
            </Row>
            {this.state.showInterfaceDiagram && (
              <InterfaceDiagram
                visibility={this.state.showInterfaceDiagram}
                vendorDetails={
                  node_data.status &&
                  node_data.status.node &&
                  node_data.status.node.info &&
                  node_data.status.node.info.device_vendor_details
                    ? `${node_data.status.node.info.device_vendor_details}`
                    : NOT_APPLICAPLE
                }
                onClose={this.onClickInterfaceDetails}
              />
            )}
            {cluster && (
              <Row>
                <Col span={8} className="node-info-middle-row">
                  <strong>{getCurrentlocaleText("cluster.field.text")}</strong>
                </Col>
                <Col span={16} className="node-info-middle-row">
                  {cluster && (
                    <div className="ellipsis node-info-right-column">
                      <Tag title={cluster.name}>
                        <Icons
                          className={"text-top"}
                          type="bs"
                          name="BsBoundingBox"
                        />
                        <a href={link} style={{ marginLeft: 5 }}>
                          {cluster.name}
                        </a>
                      </Tag>
                    </div>
                  )}
                </Col>
              </Row>
            )}
            {(node_data.node_state === "UNREACHABLE" ||
              node_data.node_state === "REBOOTING") && (
              <Row>
                <Col span={8} className="node-info-middle-row">
                  <strong>
                    {getCurrentlocaleText("inode.systeminfo.lastcontact.text")}
                  </strong>
                </Col>
                <Col span={16} className="node-info-middle-row">
                  {(node_data.node_state === "UNREACHABLE" ||
                    node_data.node_state === "REBOOTING") && (
                    <div className="node-info-right-column">
                      {getLastContactedAt(node_data)}
                    </div>
                  )}
                </Col>
              </Row>
            )}
            {node_data.status &&
              node_data.status.node &&
              node_data.status.node.headless_expiry_at && (
                <Row>
                  <Col span={8} className="node-info-middle-row">
                    <strong>
                      {getCurrentlocaleText(
                        "inode.systeminfo.lastcontact.text",
                      )}
                    </strong>
                  </Col>
                  <Col span={16} className="node-info-middle-row">
                    {node_data.status &&
                      node_data.status.node &&
                      node_data.status.node.headless_expiry_at && (
                        <div className="node-info-right-column">
                          <span>
                            {formatRemainingTimeDiff(
                              PlotTimeDiff(
                                node_data.status &&
                                  node_data.status.node.headless_expiry_at,
                                node_data.max_headless_time,
                              ),
                            )}
                          </span>
                          <Progress
                            strokeWidth={5}
                            percent={Math.floor(
                              PlotTimeDiff(
                                node_data.status &&
                                  node_data.status.node.headless_expiry_at,
                                node_data.max_headless_time,
                              ).percentage > 0
                                ? PlotTimeDiff(
                                    node_data.status &&
                                      node_data.status.node.headless_expiry_at,
                                    node_data.max_headless_time,
                                  ).percentage
                                : 0,
                            )}
                            format={percent => {
                              return percent + "%";
                            }}
                            status={
                              Math.floor(
                                PlotTimeDiff(
                                  node_data.status &&
                                    node_data.status.node.headless_expiry_at,
                                  node_data.max_headless_time,
                                ).percentage,
                              ) <= 20
                                ? "exception"
                                : "active"
                            }
                          />
                        </div>
                      )}
                  </Col>
                </Row>
              )}
          </Col>
        </Row>
        {node_data.status &&
          node_data.status.node &&
          node_data.status.node.info &&
          (node_data.status.node.info.architecture ||
            node_data.status.node.info.cores ||
            node_data.status.node.info.cpu_frequency ||
            node_data.status.node.info.memory ||
            node_data.status.node.info.kernel_version ||
            node_data.status.node.info.vendor_oemid ||
            node_data.status.node.info.boot_time ||
            (node_data.status.node.ntp_server &&
              node_data.status.node.ntp_server.configured_servers &&
              node_data.status.node.ntp_server.configured_servers.length > 0 &&
              node_data.status.node.ntp_server.current_server)) && (
            <InodeAdvanceInfo node_data={node_data} />
          )}
      </Card>
    );
  }
}

export default InodeInfoCard;
