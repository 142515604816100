import React, { Component } from "react";
import { Row, Col } from "antd";
import BreadcrumbComponent from "components/UI-Components/BreadcrumbComponent";
import Icons from "components/UI-Components/Icons";
import DadList from "components/DataLists/InodeDadList";
import OrgSelector from "components/Orgs/OrgSelector";
import { getCurrentlocaleText } from "Core/Utils";
import { observer, inject } from "mobx-react";
import InodeController from "controller/InodeController";

@inject("UiStore", "DadViewModel")
@observer
class InodeDad extends Component {
  constructor(props) {
    super(props);
    this.dadModel = this.props.DadViewModel;
    this.state = {
      nodeId: this.props.match.params.nodeId || this.props.match.params.id,
      orgId: this.props.match.params.id,
      BreadcrumbList: [],
    };
  }

  componentDidMount() {
    InodeController.getInode(this.state.nodeId).then(res => {
      this.setState(() => {
        let newObj = {},
          BreadcrumbList = [];

        let nodeCrumb = [
          {
            name: res.name ? res.name : "Details",
            link: `orgs/${res.organization.id}/inodes/${res.id}`,
          },
        ];
        BreadcrumbList.push(
          {
            name: res.organization.name,
            link: `dashboard/${res.organization.id}`,
          },
          { name: "iNodes", link: `orgs/${res.organization.id}/inodes` },
          ...nodeCrumb,
          {
            name: "Duplicate Address Detection",
            link: `orgs/${res.organization.id}/inodes/${res.id}/inodedads`,
          },
        );
        this.props.UiStore.setInodeName(res.name);
        this.props.UiStore.setOrgName(res.organization.name);
        newObj["BreadcrumbList"] = BreadcrumbList;
        return newObj;
      });
    });
  }

  render() {
    return (
      <div>
        <Row gutter={24} type="flex" align="middle">
          <Col lg={{ span: 18 }} xl={{ span: 20 }}>
            <h2 className="page-title">
              <Icons type="md" name="MdEventNote" className="valign-middle" />
              <Icons type="md" name="MdPerson" className="event-header-icon" />
              {"  "}
              {getCurrentlocaleText("dad.text")}
            </h2>
            <BreadcrumbComponent
              {...this.props}
              BreadcrumbList={this.state.BreadcrumbList}
            />
          </Col>
          <Col lg={{ span: 6 }} xl={{ span: 4 }} className="flex flex-h-right">
            <OrgSelector {...this.props} />
          </Col>
        </Row>
        <div style={{ minHeight: 360 }}>
          <Row gutter={16} type="flex" align="middle">
            <Col span={24}>
              <DadList nodeId={this.state.nodeId} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default InodeDad;
