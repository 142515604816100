import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Row, Col, Button, Divider, Select } from "antd";
//import { Divider, Select } from "antd/lib/index";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import {
  SearchOutlined,
  UndoOutlined,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import Input from "../UI-Components/InputBox";

const FormItem = Form.Item;
const { Option } = Select;

@observer
class TableSearchContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearch: false,
    };
  }

  toggleSearch = () => {
    this.setState({
      showSearch: !this.state.showSearch,
    });
  };

  formSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSearchClick(values);
      }
    });
  };

  render() {
    let { getFieldDecorator } = this.props.form;
    let formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    };

    return (
      <div>
        <Divider
          orientation="left"
          onClick={this.toggleSearch}
          className="pointer divider-orange"
        >
          {this.state.showSearch ? (
            <UpOutlined className="arrow-toggle" />
          ) : (
            <DownOutlined className="arrow-toggle" />
          )}
          Search
        </Divider>
        <div
          className={
            "filter-content " +
            (this.state.showSearch ? "slide-in" : "slide-out")
          }
        >
          <Form
            {...formItemLayout}
            onSubmit={this.formSubmit}
            labelAlign="left"
            className="table-filter-wrapper"
          >
            <Row>
              {this.props.fileds &&
                this.props.fileds.map((obj, index) => (
                  <Col span={obj.span} key={index}>
                    {(() => {
                      switch (obj.type) {
                        case "text":
                          return (
                            <FormItem label={obj.name} key={obj.id + index}>
                              {getFieldDecorator(
                                obj.id,
                                {},
                              )(<Input allowClear placeholder={obj.name} />)}
                            </FormItem>
                          );
                        case "select":
                          return (
                            <FormItem label={obj.name}>
                              {getFieldDecorator(
                                obj.id,
                                {},
                              )(
                                <Select
                                  allowClear
                                  filterSort={(optionA, optionB) =>
                                    optionA.children
                                      .toLowerCase()
                                      .localeCompare(
                                        optionB.children.toLowerCase(),
                                      )
                                  }
                                  optionFilterProp="children"
                                  placeholder={obj.name}
                                  mode={obj.mode ? obj.mode : null}
                                  filterOption={(input, option) => {
                                    if (option.children) {
                                      if (
                                        option.children
                                          .toLowerCase()
                                          .includes(input.toLowerCase())
                                      ) {
                                        return true;
                                      }
                                    }
                                  }}
                                >
                                  {obj.options.map(optionsObj => {
                                    return (
                                      <Option
                                        key={optionsObj.value}
                                        value={optionsObj.value}
                                      >
                                        {optionsObj.text}
                                      </Option>
                                    );
                                  })}
                                </Select>,
                              )}
                            </FormItem>
                          );
                      }
                    })()}
                  </Col>
                ))}
            </Row>
            <Row>
              <Col
                span="24"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  type="default"
                  className="table-margin"
                  icon={<UndoOutlined />}
                  onClick={() => {
                    this.props.form.resetFields();
                    this.props.onResetClick();
                  }}
                >
                  Reset
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="table-margin"
                  icon={<SearchOutlined />}
                >
                  Search
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}
const TableSearch = Form.create()(TableSearchContainer);
export default TableSearch;
