import React, { Component } from "react";
import { Redirect, withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import { inject, observer } from "mobx-react";
import axios from "axios";
import OrgsList from "components/DataLists/OrgsList";

// Routes
import ProtectedRoute from "Core/ProtectedRoute";

import {
  isLoggedIn,
  notificationModal,
  DefaultStatsWidget,
  checkforFeatureEnabled,
} from "Core/Utils";

// Pages
import Orgs from "components/Containers/Orgs";
import Dashboard from "components/Containers/Dashboard";
import OrgDetails from "components/Containers/OrgDetails";
import Clusters from "components/Clusters/Clusters";
import Inodes from "components/Containers/Inodes";
import Listeners from "components/Containers/ListenerDetails";
import InodesDetails from "components/Containers/InodesDetails";
import RoleDetails from "components/Containers/RoleDetails";
import NetworkDetails from "components/Containers/NetworkDetails";
import Users from "components/Containers/Users";
import Logout from "components/Containers/Logout";
import AccessDenied from "components/Containers/AccessDenied";
import Loading from "components/Containers/Loading";
import MyProfile from "components/Containers/MyProfile";
import SettingsPage from "components/Containers/settings";
import DashboardDetails from "components/Containers/DashboardDetails";
import InodeSecuirtyPolicy from "components/INodes/InodeSecuirtyPolicy";
import ListenerStatus from "components/INodes/ListenerStatus";
import KnowledgeBase from "components/Containers/UserGuide";
import Releases from "components/Containers/Releases";
import ManageAlerts from "components/Alerts/ManageAlerts";
import ManageWebhooks from "components/Webhooks/ManageWebhooks";
import ManageWebhooksForm from "components/Webhooks/ManageWebhooksForm";
import ManageAlertForm from "components/Alerts/ManageAlertForm";
import Events from "components/Events/Events";
import InodeEvents from "components/Events/InodeEvents";
import ClusterEvents from "components/Events/ClusterEvents";
import InodeImages from "components/NodeImages/InodeImages";
import ApiKeys from "components/UserApiKeys/ApiKeys";
import InodeLogs from "components/Logs/Containers/InodeLogs";
import ServiceLogs from "components/Logs/Containers/ServiceLogs";
import { ConfigProvider } from "antd";
import enUS from "antd/lib/locale-provider/en_US";
import OrgController from "controller/OrgController";
import UserController from "controller/UserController";
import UserApiKeyContainer from "components/UserApiKeys/UserApiKeyContainer";
import UserApiKeyView from "components/UserApiKeys/UserApiKeyView";
import ServiceStatsContanier from "components/Stats/ServiceStatsContanier";
import ActivityDownloadManager from "components/DownloadManager/Containers/ActivityDownloadManager";
import EventDownloadManager from "components/DownloadManager/Containers/EventDownloadManager";
import BrandingPage from "components/Containers/BrandingPage";
import PolicySettings from "components/Containers/PolicySettings";
import Activity from "components/Containers/Activity";
/*Service Templates*/
import { ServicePlayground, ServiceRender } from "components/serviceTemplates";
import NotFoundResult from "components/NotFoundResult";
import ClusterDetails from "./components/Clusters/ClusterDetails";
import InodeActivityList from "./components/DataLists/InodeActivityList";
import InodeActivity from "components/DataLists/InodeActivity";
import InodeDad from "./components/DataLists/InodeDad";
import ClusterDad from "./components/DataLists/ClusterDad";
import StatsWrapper from "./components/Containers/StatsWrapper";

import ReactGA from "react-ga4";
import { getDocumentTitle, getGaTitle } from "Core/Utils";
import { Modal } from "antd";
import CTD from "./components/Containers/CTD";
import DeviceDiscoveryContainer from "./components/Containers/DeviceDiscoveryContainer";
import DeviceDiscoveryReportDetails from "./components/Containers/DeviceDiscoveryReportDetails";
import ServiceListing from "./components/Containers/ServiceListing";
import MyServices from "./components/Containers/MyServices";
import { toJS } from "mobx";
import RepNet from "./components/Containers/RepNet";
/* 'Routing Start'
-------------------------------------------------------------- */

const UserDetails = props => {
  return <MyProfile {...props} />;
};

const ForceLogout = props => {
  return <Logout {...props} />;
};

@inject(
  "AuthStore",
  "ModelStore",
  "UiStore",
  "DashboardStore",
  "TimezoneViewModel",
  "OrgViewModel",
)
@observer
class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forceLogout: false,
      trackingId: null,
    };

    /*Creating BroadcastChannels*/
    try {
      this.logoutChannel = new BroadcastChannel("logoutChannel");
      this.broadCastChannel = new BroadcastChannel("resetTimer");
    } catch (error) {
      this.logoutChannel = null;
      this.broadCastChannel = null;
    }

    /*Implementing session Timeout 30 mins*/
    this.timer = 1800;
    this.timerObj = null;
    this.isTimerCalled = null;
    /*Events to identify user interaction*/
    document.onclick = () => {
      this.checkForCurrentSession();
      this.resetTimer();
      if (this.broadCastChannel) {
        this.broadCastChannel.postMessage("resetTimer");
      }
    };

    document.onmousemove = () => {
      this.checkForCurrentSession();
      this.resetTimer();
      if (this.broadCastChannel) {
        this.broadCastChannel.postMessage("resetTimer");
      }
    };

    document.onmousewheel = () => {
      this.checkForCurrentSession();
      this.resetTimer();
      if (this.broadCastChannel) {
        this.broadCastChannel.postMessage("resetTimer");
      }
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.props.UiStore.SetStoreData("currentRouteLocation", location.pathname);
    let path = nextProps.location.pathname;
    let orgname =
      this.props.OrgViewModel.org && this.props.OrgViewModel.org.name
        ? this.props.OrgViewModel.org.name
        : this.props.AuthStore.loggedInUser.organization &&
          this.props.AuthStore.loggedInUser.organization.name;

    this.props.UiStore.setDocumentTitle(
      getDocumentTitle(path, nextProps.AuthStore, orgname),
    );
    this.props.UiStore.setGaTitle(
      getGaTitle(path, nextProps.AuthStore, orgname),
    );
  }

  checkForCurrentSession = () => {
    if (this.props.AuthStore.isCurrentSessionExpired) {
      let alreadyAnnounced = document.getElementsByClassName(
        "session-expired-id",
      ).length;

      this.props.UiStore.errorMessage = null;
      this.timerObj = null;
      /*TO remove events when logged out*/
      document.onclick = null;
      document.onmousemove = null;
      document.onmousewheel = null;
      if (this.broadCastChannel && alreadyAnnounced == 0) {
        this.broadCastChannel.postMessage("timeout");
        let content = {
          title: "Session Timeout",
          data: "Your session has expired. Please login again",
          className: "session-expired-id",
        };
        notificationModal("error", content, this.logout);
        this.props.UiStore.errorMessage = null;
        clearInterval(this.timerObj);
      }
    }
  };

  trackRouteChanges = () => {
    return new Promise((resolve, reject) => {
      try {
        let gaTitle = this.props.UiStore.gaTitle
          ? this.props.UiStore.gaTitle
          : null;
        if (gaTitle) {
          ReactGA.send({
            hitType: "pageview",
            page: location.pathname,
            title: gaTitle,
          });
        }
        if (this.props.AuthStore.loggedInUser && window.onRouteChange) {
          window.onRouteChange(toJS(this.props.AuthStore.loggedInUser));
        }
        resolve();
      } catch (err) {
        reject();
      }
    });
  };

  resetTimer = () => {
    /*To avoid calling 'startTimer()' multiple times*/

    clearTimeout(this.isTimerCalled);
    this.isTimerCalled = null;

    this.isTimerCalled = setTimeout(() => {
      clearInterval(this.timerObj);
      this.timerObj = null;

      this.timer = 1800;
      this.startTimer();
    }, 2000);
  };

  startTimer = () => {
    /*execute every 100 secs*/
    if (this.timerObj == null) {
      this.timerObj = setInterval(() => {
        this.timer = this.timer - 100;
        let alreadyAnnounced = document.getElementsByClassName(
          "session-expired-id",
        ).length;
        if (this.timer <= 0 && alreadyAnnounced == 0) {
          this.props.UiStore.errorMessage = null;
          /*Clear time interval*/
          clearInterval(this.timerObj);
          this.timerObj = null;
          /*TO remove events when logged out*/
          document.onclick = null;
          document.onmousemove = null;
          document.onmousewheel = null;

          if (this.broadCastChannel) {
            this.broadCastChannel.postMessage("timeout");
          }
          let content = {
            title: "Session Timeout",
            data: "Your session has expired. Please login again",
            className: "session-expired-id",
          };
          let org = this.props.AuthStore.loggedInUserOrg;
          notificationModal("error", content, this.logout);
          if (org && !org.is_central_auth) {
            let Logout = axios.post("/api/v1/logout");
            Logout.then(response => {
              clearInterval(this.timerObj);
              this.timerObj = null;
            }).catch(error => {
              console.log("logout failed on remote server.");
              console.log(error.response.data);
            });
            this.props.history.push("/login");
          } else {
            this.props.AuthStore.SetStoreData("isLogutInprogress", true);
          }
        }
      }, 100000);
    }
  };

  logout = modal => {
    modal.destroy(); // TO close the notification model
    clearInterval(this.timerObj);
    this.timerObj = null;
    /*Timeout to remove modal before loading login page*/
    setTimeout(() => {
      this.setState({ forceLogout: true });
      Modal.destroyAll();
    }, 300);
  };
  componentWillUnmount = () => {
    this.props.AuthStore.resetStore();
  };

  UNSAFE_componentWillUpdate(prevProps, prevState) {
    if (this.props.match.url != prevProps.match.url) {
      let currentOrg =
        this.props &&
        this.props.match &&
        this.props.match.url &&
        this.props.match.url.split("/").length > 3
          ? this.props.match.url.split("/")[1] === "dashboard" &&
            this.props.match.url.split("/").length > 4
            ? this.props.match.url.split("/")[3] === "view"
              ? this.props.match.url.split("/")[2]
              : this.props.match.url.split("/")[3]
            : this.props.match.url.split("/")[1] === "dashboard" &&
              this.props.match.url.split("/").length > 4
            ? this.props.match.url.split("/")[2]
            : this.props.match.url.split("/")[1] === "managealerts"
            ? null
            : this.props.match.url.split("/")[1] != "managewebhooks"
            ? this.props.match.url.split("/")[2]
            : null
          : null;
      if (
        this.props.OrgViewModel.org.id != undefined &&
        currentOrg &&
        this.props.OrgViewModel.org.id != currentOrg
      ) {
        this.props.AuthStore.isCurrentOrgAPILoading = true;
        OrgController.getOrg(currentOrg, false, true).then(response => {
          this.props.AuthStore.isCurrentOrgAPILoading = false;
        });
      }
      this.trackRouteChanges();
    } else {
      if (this.props.AuthStore.loggedInUser && window.onRouteChange) {
        window.onRouteChange(toJS(this.props.AuthStore.loggedInUser));
      }
    }
  }

  UNSAFE_componentWillMount() {
    if (localStorage.isSiderFold) {
      let fold = false;
      try {
        fold = JSON.parse(localStorage.isSiderFold);
      } catch (e) {
        // do nothing.
      }
      const trackingId =
        this.GLOBAL_SETTINGS &&
        this.GLOBAL_SETTINGS.settings.analytics &&
        this.GLOBAL_SETTINGS.settings.analytics.ga4 &&
        this.GLOBAL_SETTINGS.settings.analytics.ga4.id
          ? this.GLOBAL_SETTINGS.settings.analytics.ga4.id
          : null;

      if (trackingId && trackingId.trim().length > 0) {
        this.setState({ trackingId: trackingId });
      }
      this.props.UiStore.SetSiderFold(fold);
    }
    // TODO - The value should be cached somewhere. Do not need to call everytime.
    UserController.getCurrentUser()
      .then(response => {
        if (response) {
          this.props.AuthStore.SetStoreData("loggedInUser", response);
          this.isBetaCustomers(response.organization.id);
          if (!localStorage.statsLayout) {
            localStorage.setItem(
              "statsLayout",
              JSON.stringify(DefaultStatsWidget),
            );
          }

          /*Start session timer on successful log-in*/
          this.resetTimer();
          if (location.pathname === "/") this.props.history.push("/home");
          let currentOrg =
            this.props &&
            this.props.match &&
            this.props.match.url &&
            this.props.match.url.split("/").length > 3
              ? this.props.match.url.split("/")[1] === "dashboard" &&
                this.props.match.url.split("/").length > 4
                ? this.props.match.url.split("/")[3] === "view"
                  ? this.props.match.url.split("/")[2]
                  : this.props.match.url.split("/")[3]
                : this.props.match.url.split("/")[1] === "dashboard" &&
                  this.props.match.url.split("/").length > 4
                ? this.props.match.url.split("/")[2]
                : this.props.match.url.split("/")[1] === "managealerts"
                ? null
                : this.props.match.url.split("/")[2]
              : null;
          if (
            (this.props.OrgViewModel.org.id != undefined &&
              currentOrg &&
              this.props.OrgViewModel.org.id != currentOrg) ||
            (!this.props.OrgViewModel.org.id && currentOrg)
          ) {
            this.props.AuthStore.isCurrentOrgAPILoading = true;
            OrgController.getOrg(currentOrg, false, true).then(response => {
              this.props.AuthStore.isCurrentOrgAPILoading = false;
            });
          }
        }
      })
      .catch(error => {
        // Logout only for 401
        if (error && error.response && error.response.status === 401) {
          this.setState({ forceLogout: true });
        } else {
          console.log("Error in getting current user info. But not 401.");
          console.debug(error);
        }
      });
  }

  isBetaCustomers(org_id) {
    if (this.props.AuthStore.IsPermitted("ORG:VIEW")) {
      OrgController.getOrg(org_id).then(response => {
        this.props.AuthStore.SetStoreData("loggedInUserOrg", response);
        if (response) {
          this.props.TimezoneViewModel.setOrgTimezone(response.timezone);
        }
      });
    } else {
      this.props.AuthStore.SetStoreData(
        "loggedInUserOrg",
        this.props.AuthStore.loggedInUser.organization,
      );
    }
  }

  get LandingPage() {
    if (
      this.props.AuthStore.IsPermitted("ORG:VIEW") ||
      this.props.AuthStore.IsPermitted("USER:VIEW") ||
      this.props.AuthStore.IsPermitted("NETWORK:VIEW") ||
      this.props.AuthStore.IsPermitted("NODE:VIEW") ||
      this.props.AuthStore.IsPermitted("ROLE:VIEW")
    ) {
      return Dashboard;
    } else return AccessDenied;
  }

  PermittedComponent = (scope, component) => {
    if (this.props.AuthStore.IsPermitted(scope)) {
      return component;
    } else return AccessDenied;
  };

  isOrgLevelPolicyEnabled = (policy, component) => {
    if (checkforFeatureEnabled(this.props.AuthStore.loggedInUserOrg, policy)) {
      return component;
    } else {
      return AccessDenied;
    }
  };

  componentDidMount() {
    /*Listen for logout event and logout from any other active tab */
    if (this.logoutChannel) {
      this.logoutChannel.onmessage = ev => {
        if (ev.data === "logout") {
          /*TO remove events when logged out*/
          document.onclick = null;
          document.onmousemove = null;
          document.onmousewheel = null;
          /* on logout remove cookie accceptance from session storage - LAT-4321 */
          window.sessionStorage.removeItem("acceptedCookie");
          this.props.AuthStore.isCurrentSessionExpired = true;
          /*clear time interval*/
          this.timerObj
            ? (clearInterval(this.timerObj), (this.timerObj = null))
            : "";
          this.checkForCurrentSession();
          let org = this.props.AuthStore.loggedInUserOrg;
          if (!org || (org && !org.is_central_auth)) {
            <Redirect
              to={{ pathname: "/login", state: { from: this.props.location } }}
            />;
          }
        }
      };
    }
    if (this.broadCastChannel) {
      this.broadCastChannel.onmessage = ev => {
        if (ev.data === "resetTimer") {
          clearInterval(this.timerObj);
          this.timerObj = null;
          this.timer = 1800;
          this.resetTimer();
        } else if (ev.data === "timeout") {
          setTimeout(() => {
            let alreadyAnnounced = document.getElementsByClassName(
              "session-expired-id",
            ).length;
            if (alreadyAnnounced == 0 && document.hidden) {
              let content = {
                title: "Session Timeout",
                data: "Your session has expired. Please login again",
                className: "session-expired-id",
                keyboard: false,
              };
              notificationModal("error", content, this.logout);
              /*TO remove events when logged out*/
              document.onclick = null;
              document.onmousemove = null;
              document.onmousewheel = null;
              clearInterval(this.timerObj);
            }
          }, 500);
        }
      };
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      let containerDiv = document.getElementById("main-content-wrapper");
      if (containerDiv) {
        containerDiv.scrollTo(0, 0);
      }
    }
  }

  render() {
    let isAPISuccess =
      this.props.AuthStore.isCurrentUserAPISuccess &&
      !this.props.AuthStore.isCurrentOrgAPILoading
        ? true
        : false;
    let container = (
      <ConfigProvider locale={enUS}>
        <Switch>
          <ProtectedRoute
            exact
            path="/home"
            component={isAPISuccess ? this.LandingPage : Loading}
          />
          <ProtectedRoute
            exact
            path="/"
            component={isAPISuccess ? this.LandingPage : Loading}
          />
          <ProtectedRoute
            exact
            path="/orgs"
            component={
              isAPISuccess
                ? this.props.AuthStore.IsPermitted("ORG:VIEW")
                  ? this.PermittedComponent("ORG:VIEW", Orgs)
                  : this.props.AuthStore.IsPermitted("NODE:VIEW")
                  ? this.PermittedComponent("NODE:VIEW", Orgs)
                  : this.props.AuthStore.IsPermitted("USER:VIEW")
                  ? this.PermittedComponent("USER:VIEW", Orgs)
                  : Loading
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/dashboard"
            component={isAPISuccess ? Dashboard : Loading}
          />
          <ProtectedRoute
            exact
            path="/home/activities"
            component={
              isAPISuccess
                ? this.PermittedComponent("ACTIVITY:VIEW", Activity)
                : Loading
            }
          />{" "}
          <ProtectedRoute
            exact
            path="/orgs/:id/inodes/:nodeId/inodedads"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", InodeDad)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/dashboard/activities"
            component={
              isAPISuccess
                ? this.PermittedComponent("ACTIVITY:VIEW", Activity)
                : Loading
            }
          />
          {/* This is not working if we change the order */}
          <ProtectedRoute
            path="/dashboard/network/:id/modify/:networkId"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", NetworkDetails)
                : Loading
            }
          />
          <ProtectedRoute
            path="/dashboard/service/:id/networks/modify/:networkId"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", NetworkDetails)
                : Loading
            }
          />
          <ProtectedRoute
            path="/dashboard/service/:id/modify/:networkId"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", NetworkDetails)
                : Loading
            }
          />
          <ProtectedRoute
            path="/dashboard/inodes/status/:status/:id/networks/modify/:networkId"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", NetworkDetails)
                : Loading
            }
          />
          <ProtectedRoute
            path="/dashboard/inodes/status/:status/:id/modify/:networkId"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", NetworkDetails)
                : Loading
            }
          />
          <ProtectedRoute
            path="/dashboard/inodes/status/:status/:id"
            component={
              isAPISuccess
                ? this.PermittedComponent("NODE:VIEW", InodesDetails)
                : Loading
            }
          />
          <ProtectedRoute
            path="/dashboard/inodes/status/:status"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", DashboardDetails)
                : Loading
            }
          />
          <ProtectedRoute
            path="/dashboard/inodes/profile/:profile/status/:status/:id/networks/modify/:networkId"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", NetworkDetails)
                : Loading
            }
          />
          <ProtectedRoute
            path="/dashboard/inodes/profile/:profile/status/:status/:id/modify/:networkId"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", NetworkDetails)
                : Loading
            }
          />
          <ProtectedRoute
            path="/dashboard/inodes/profile/:profile/status/:status/:id"
            component={
              isAPISuccess
                ? this.PermittedComponent("NODE:VIEW", InodesDetails)
                : Loading
            }
          />
          <ProtectedRoute
            path="/dashboard/inodes/profile/:profile/status/:status"
            component={
              isAPISuccess
                ? this.PermittedComponent("NODE:VIEW", DashboardDetails)
                : Loading
            }
          />
          <ProtectedRoute
            path="/dashboard/inodes/profile/:profile/:id"
            component={
              isAPISuccess
                ? this.PermittedComponent("NODE:VIEW", InodesDetails)
                : Loading
            }
          />
          <ProtectedRoute
            path="/dashboard/inodes/profile/:profile"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", DashboardDetails)
                : Loading
            }
          />
          <ProtectedRoute
            path="/dashboard/inodes/:id"
            component={
              isAPISuccess
                ? this.PermittedComponent("NODE:VIEW", DashboardDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/dashboard/:id"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", Dashboard)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/dashboard/:id/activities"
            component={
              isAPISuccess
                ? this.PermittedComponent("ACTIVITY:VIEW", Activity)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/dashboard/:id/orgs"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", DashboardDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/dashboard/:id/clusters"
            component={
              isAPISuccess
                ? this.props.AuthStore.IsPermitted("CLUSTER:VIEW") &&
                  this.props.OrgViewModel.checkForRoutepermitted &&
                  checkforFeatureEnabled(
                    this.props.OrgViewModel.checkForRoutepermitted,
                    "cluster",
                  )
                  ? DashboardDetails
                  : AccessDenied
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/dashboard/:id/branding"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", BrandingPage)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/dashboard/:id/policies"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", PolicySettings)
                : Loading
            }
          />{" "}
          <ProtectedRoute
            exact
            path="/dashboard/:id/view/policies"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", PolicySettings)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/roles"
            component={
              isAPISuccess
                ? this.PermittedComponent("USER:VIEW", Users)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/roles/create"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", Users)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/roles/:roleid/edit"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", Users)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/roles/:roleid/view"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", Users)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/dashboard/:id/users"
            component={
              isAPISuccess
                ? this.PermittedComponent("USER:VIEW", DashboardDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/dashboard/:id/networks"
            component={
              isAPISuccess
                ? this.PermittedComponent("NETWORK:VIEW", DashboardDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/dashboard/:id/securitypolicies/create"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", DashboardDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/dashboard/:id/securitypolicies/:securitypolicyId/edit"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", DashboardDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/dashboard/:id/securitypolicies/:securitypolicyId/view"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", DashboardDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/dashboard/:id/securitypolicies"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", DashboardDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/repnet"
            component={
              isAPISuccess
                ? this.PermittedComponent("REPNET:ADMIIN", RepNet)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/repnet/:type"
            component={
              isAPISuccess
                ? this.PermittedComponent("REPNET:ADMIIN", RepNet)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/dashboard/:id/roles"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", DashboardDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/dashboard/:id/services"
            component={
              isAPISuccess
                ? this.PermittedComponent("SERVICE:VIEW", DashboardDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/dashboard/:id/inodes/:nodeId/servicetemplates/:serviceId/logs"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", ServiceLogs)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/dashboard/:id/serials"
            component={
              isAPISuccess
                ? this.PermittedComponent("SERVICE:VIEW", DashboardDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/dashboard/:id/inodes"
            component={
              isAPISuccess
                ? this.PermittedComponent("NODE:VIEW", DashboardDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/dashboard/:id/licenses"
            component={
              isAPISuccess
                ? this.PermittedComponent("NODE:VIEW", DashboardDetails)
                : Loading
            }
          />
          <ProtectedRoute
            path="/dashboard/orgs"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", DashboardDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/branding"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", BrandingPage)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/policies"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", PolicySettings)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/view/policies"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", PolicySettings)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/inodes/create"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", OrgDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/inodes/bulkconfig"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", OrgDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/inodes/bulkconfig/upload"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", OrgDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/inodes/bulkconfig/view-history"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", OrgDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/inodes/:nodeId/view"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", InodesDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/inodes/:nodeId/edit"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", InodesDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/inodes/:nodeId/scandad"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", InodesDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/inodes/:nodeId"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", InodesDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/inodes/:nodeId/logs"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", InodeLogs)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/inodes/:nodeId/networks"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", InodesDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/inodes/:nodeId/modify"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", NetworkDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/clusters/:cluster_id/modify"
            component={
              isAPISuccess
                ? this.props.AuthStore.IsPermitted("CLUSTER:VIEW") &&
                  this.props.OrgViewModel.checkForRoutepermitted &&
                  checkforFeatureEnabled(
                    this.props.OrgViewModel.checkForRoutepermitted,
                    "cluster",
                  )
                  ? NetworkDetails
                  : AccessDenied
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/clusters/:cluster_id/modify/:networkId"
            component={
              isAPISuccess
                ? this.props.AuthStore.IsPermitted("CLUSTER:VIEW") &&
                  this.props.OrgViewModel.checkForRoutepermitted &&
                  checkforFeatureEnabled(
                    this.props.OrgViewModel.checkForRoutepermitted,
                    "cluster",
                  )
                  ? NetworkDetails
                  : AccessDenied
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/clusters/:cluster_id/view/:networkId"
            component={
              isAPISuccess
                ? this.props.AuthStore.IsPermitted("CLUSTER:VIEW") &&
                  this.props.OrgViewModel.checkForRoutepermitted &&
                  checkforFeatureEnabled(
                    this.props.OrgViewModel.checkForRoutepermitted,
                    "cluster",
                  )
                  ? NetworkDetails
                  : AccessDenied
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/clusters/:cluster_id/services/:serviceId/logs"
            component={
              isAPISuccess
                ? this.props.AuthStore.IsPermitted("CLUSTER:VIEW") &&
                  this.props.OrgViewModel.checkForRoutepermitted &&
                  checkforFeatureEnabled(
                    this.props.OrgViewModel.checkForRoutepermitted,
                    "cluster",
                  )
                  ? ServiceLogs
                  : AccessDenied
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/clusters/:cluster_id/clusterdads"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", ClusterDad)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/inodes/:nodeId/modify/:networkId"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", NetworkDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/inodes/:nodeId/view/:networkId"
            component={
              isAPISuccess
                ? this.PermittedComponent("NETWORK:VIEW", NetworkDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/inodes/:nodeId/networks/view/:networkId"
            component={
              isAPISuccess
                ? this.PermittedComponent("NETWORK:VIEW", NetworkDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/inodes/:nodeId/networks/modify/:networkId"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", NetworkDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/inodes/:nodeId/networks/modify/"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", NetworkDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/inodes/:nodeId/mactable"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", InodesDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/inodes/:nodeId/servicetemplates"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", InodesDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/inodes/:nodeId/listeners"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", InodesDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/inodes/:nodeId/listeners/modify"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", Listeners)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/inodes/:nodeId/listeners/modify/:listenerId"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", Listeners)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/inodes/:nodeId/listeners/view/:listenerId"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", Listeners)
                : Loading
            }
          />{" "}
          <ProtectedRoute
            exact
            path="/orgs/:id/inodes/:nodeId/listeners/:listenerId/status"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", ListenerStatus)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/inodes/:nodeId/servicetemplates/:serviceId/logs"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", ServiceLogs)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/inodes/:nodeId/securitypolicy"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", InodeSecuirtyPolicy)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/inodes/:nodeId/interfaces"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", InodesDetails)
                : Loading
            }
          />
          {this.props.AuthStore.IsPermitted("EVENTS:VIEW") && (
            <ProtectedRoute
              exact
              path="/orgs/:id/inodes/:nodeId/events/inodeevents"
              component={
                isAPISuccess
                  ? this.PermittedComponent("NODE:VIEW", InodeEvents)
                  : Loading
              }
            />
          )}
          {this.props.AuthStore.IsPermitted("ACTIVITY:VIEW") && (
            <ProtectedRoute
              exact
              path="/orgs/:id/inodes/:nodeId/activities/inodeactivities"
              component={
                isAPISuccess
                  ? this.PermittedComponent("NODE:VIEW", InodeActivity)
                  : Loading
              }
            />
          )}
          <ProtectedRoute
            exact
            path="/orgs/:id/inodes/:nodeId/images/inodeimages"
            component={
              isAPISuccess
                ? this.PermittedComponent("NODE:VIEW", InodeImages)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/inodes/:nodeId/images"
            component={
              isAPISuccess
                ? this.PermittedComponent("NODE:VIEW", InodesDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/inodes/:nodeId/events"
            component={
              isAPISuccess
                ? this.PermittedComponent("NODE:VIEW", InodesDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/inodes/:nodeId/activities"
            component={
              isAPISuccess
                ? this.PermittedComponent("NODE:VIEW", InodesDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/inodes/:nodeId/stats/"
            component={isAPISuccess ? StatsWrapper : Loading}
          />
          <ProtectedRoute
            exact
            path="/orgs/:orgId/threatintelligence"
            component={
              isAPISuccess ? this.PermittedComponent("ORG:VIEW", CTD) : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:orgId/inodes/:nodeId/threatintelligence"
            component={
              isAPISuccess ? this.PermittedComponent("ORG:VIEW", CTD) : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:orgId/inodes/:nodeId/devicediscovery"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", DeviceDiscoveryContainer)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:orgId/inodes/:nodeId/devicediscovery/:tab"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", DeviceDiscoveryContainer)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:orgId/inodes/:nodeId/devicediscovery/:tab/:scanId"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", DeviceDiscoveryContainer)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:orgId/inodes/:nodeId/devicediscovery/report"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", DeviceDiscoveryContainer)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:orgId/inodes/:nodeId/devicediscovery/report/:id"
            component={
              isAPISuccess
                ? this.PermittedComponent(
                    "ORG:VIEW",
                    DeviceDiscoveryReportDetails,
                  )
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:orgId/clusters/:clusterId/devicediscovery"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", DeviceDiscoveryContainer)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:orgId/clusters/:clusterId/devicediscovery/:tab"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", DeviceDiscoveryContainer)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:orgId/clusters/:clusterId/devicediscovery/:tab/:reportId"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", DeviceDiscoveryContainer)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/inodes/:nodeId/service/:spec/stats"
            component={isAPISuccess ? ServiceStatsContanier : Loading}
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/inodes/:nodeId/service/:spec/:containerId/stats"
            component={isAPISuccess ? ServiceStatsContanier : Loading}
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/inodes/:nodeId/upgradehistory"
            component={
              isAPISuccess
                ? this.PermittedComponent("NODE:VIEW", InodesDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:org_id/inodes/:node_id/services/:spec"
            component={
              isAPISuccess
                ? this.PermittedComponent("SERVICE:CREATE", ServiceRender)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:org_id/inodes/:node_id/services/:spec/:spec_id"
            component={
              isAPISuccess
                ? this.PermittedComponent("SERVICE:CREATE", ServiceRender)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:org_id/inodes/:node_id/serviceview/:spec/:spec_id/"
            component={
              isAPISuccess
                ? this.PermittedComponent("SERVICE:VIEW", ServiceRender)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/users/keys/:user_id"
            component={
              isAPISuccess
                ? this.isOrgLevelPolicyEnabled("apikey", UserApiKeyView)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/activity/downloads"
            component={
              isAPISuccess
                ? this.PermittedComponent(
                    "ACTIVITY:DOWNLOAD",
                    ActivityDownloadManager,
                  )
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/event/downloads"
            component={
              isAPISuccess
                ? this.PermittedComponent(
                    "EVENTS:DOWNLOAD",
                    EventDownloadManager,
                  )
                : Loading
            }
          />
          <ProtectedRoute
            path="/orgs/:id/clusters/:cluster_id/inodes/status/:status"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", OrgDetails)
                : Loading
            }
          />
          <ProtectedRoute
            path="/orgs/:id/clusters"
            exact
            component={
              isAPISuccess
                ? this.props.AuthStore.IsPermitted("CLUSTER:VIEW") &&
                  this.props.OrgViewModel.checkForRoutepermitted &&
                  checkforFeatureEnabled(
                    this.props.OrgViewModel.checkForRoutepermitted,
                    "cluster",
                  )
                  ? Clusters
                  : AccessDenied
                : Loading
            }
          />
          <ProtectedRoute
            path="/orgs/:id/clusters/create"
            exact
            component={
              isAPISuccess
                ? this.props.AuthStore.IsPermitted("CLUSTER:VIEW") &&
                  this.props.OrgViewModel.checkForRoutepermitted &&
                  checkforFeatureEnabled(
                    this.props.OrgViewModel.checkForRoutepermitted,
                    "cluster",
                  )
                  ? Clusters
                  : AccessDenied
                : Loading
            }
          />
          <ProtectedRoute
            path="/orgs/:id/clusters/:cluster_id"
            exact
            component={
              isAPISuccess
                ? this.props.AuthStore.IsPermitted("CLUSTER:VIEW") &&
                  this.props.OrgViewModel.checkForRoutepermitted &&
                  checkforFeatureEnabled(
                    this.props.OrgViewModel.checkForRoutepermitted,
                    "cluster",
                  )
                  ? ClusterDetails
                  : AccessDenied
                : Loading
            }
          />
          {this.props.AuthStore.IsPermitted("EVENTS:VIEW") && (
            <ProtectedRoute
              exact
              path="/orgs/:id/clusters/:cluster_id/events/clusterevents"
              component={
                isAPISuccess
                  ? this.props.AuthStore.IsPermitted("NODE:VIEW") &&
                    this.props.OrgViewModel.checkForRoutepermitted &&
                    checkforFeatureEnabled(
                      this.props.OrgViewModel.checkForRoutepermitted,
                      "cluster",
                    )
                    ? ClusterEvents
                    : AccessDenied
                  : Loading
              }
            />
          )}
          <ProtectedRoute
            path="/orgs/:id/clusters/:cluster_id/events"
            exact
            component={
              isAPISuccess
                ? this.props.AuthStore.IsPermitted("CLUSTER:VIEW") &&
                  this.props.OrgViewModel.checkForRoutepermitted &&
                  checkforFeatureEnabled(
                    this.props.OrgViewModel.checkForRoutepermitted,
                    "cluster",
                  )
                  ? ClusterDetails
                  : AccessDenied
                : Loading
            }
          />
          <ProtectedRoute
            path="/orgs/:id/clusters/:cluster_id/inodes"
            exact
            component={
              isAPISuccess
                ? this.props.AuthStore.IsPermitted("CLUSTER:VIEW") &&
                  this.props.OrgViewModel.checkForRoutepermitted &&
                  checkforFeatureEnabled(
                    this.props.OrgViewModel.checkForRoutepermitted,
                    "cluster",
                  )
                  ? ClusterDetails
                  : AccessDenied
                : Loading
            }
          />
          <ProtectedRoute
            path="/orgs/:id/clusters/:cluster_id/networks"
            exact
            component={
              isAPISuccess
                ? this.props.AuthStore.IsPermitted("CLUSTER:VIEW") &&
                  this.props.OrgViewModel.checkForRoutepermitted &&
                  checkforFeatureEnabled(
                    this.props.OrgViewModel.checkForRoutepermitted,
                    "cluster",
                  )
                  ? ClusterDetails
                  : AccessDenied
                : Loading
            }
          />
          <ProtectedRoute
            path="/orgs/:id/clusters/:cluster_id/services"
            exact
            component={
              isAPISuccess
                ? this.props.AuthStore.IsPermitted("CLUSTER:VIEW") &&
                  this.props.OrgViewModel.checkForRoutepermitted &&
                  checkforFeatureEnabled(
                    this.props.OrgViewModel.checkForRoutepermitted,
                    "cluster",
                  )
                  ? ClusterDetails
                  : AccessDenied
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:org_id/clusters/:cluster_id/serviceview/:spec/:spec_id/"
            component={
              isAPISuccess
                ? this.PermittedComponent("SERVICE:VIEW", ServiceRender)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:org_id/clusters/:cluster_id/services/:spec"
            component={
              isAPISuccess
                ? this.PermittedComponent("SERVICE:CREATE", ServiceRender)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:org_id/clusters/:cluster_id/services/:spec/:spec_id"
            component={
              isAPISuccess
                ? this.PermittedComponent("SERVICE:CREATE", ServiceRender)
                : Loading
            }
          />
          <ProtectedRoute
            path="/orgs/:id/clusters/edit/:cluster_id"
            exact
            component={
              isAPISuccess
                ? this.props.AuthStore.IsPermitted("CLUSTER:VIEW") &&
                  this.props.OrgViewModel.checkForRoutepermitted &&
                  checkforFeatureEnabled(
                    this.props.OrgViewModel.checkForRoutepermitted,
                    "cluster",
                  )
                  ? Clusters
                  : AccessDenied
                : Loading
            }
          />
          <ProtectedRoute
            path="/orgs/:id/clusters/view/:cluster_id"
            exact
            component={
              isAPISuccess
                ? this.props.AuthStore.IsPermitted("CLUSTER:VIEW") &&
                  this.props.OrgViewModel.checkForRoutepermitted &&
                  checkforFeatureEnabled(
                    this.props.OrgViewModel.checkForRoutepermitted,
                    "cluster",
                  )
                  ? Clusters
                  : AccessDenied
                : Loading
            }
          />
          <ProtectedRoute
            path="/orgs/:id"
            exact
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", OrgDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/inodes/:nodeId/inodedads"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", InodeDad)
                : Loading
            }
          />
          <ProtectedRoute
            path="/orgs/:id/inodes"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", OrgDetails)
                : Loading
            }
          />
          <ProtectedRoute
            path="/orgs/:id/services"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", OrgDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:orgId/servicelisting"
            component={
              isAPISuccess
                ? this.PermittedComponent("SERVICE:CREATE", ServiceListing)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:orgId/myservices"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", MyServices)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/users"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", OrgDetails)
                : Loading
            }
          />
          <ProtectedRoute
            path="/orgs/:id/roles"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", OrgDetails)
                : Loading
            }
          />
          <ProtectedRoute
            path="/orgs/:id/licenses"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", OrgDetails)
                : Loading
            }
          />
          <ProtectedRoute
            path="/orgs/:id/servicelistener"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", OrgDetails)
                : Loading
            }
          />
          <ProtectedRoute
            path="/orgs/:id/serials"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", OrgDetails)
                : Loading
            }
          />
          <ProtectedRoute
            path="/orgs/:id/certificates"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", OrgDetails)
                : Loading
            }
          />
          <ProtectedRoute
            path="/orgs/:id/orgs"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", OrgDetails)
                : Loading
            }
          />
          <ProtectedRoute
            path="/orgs/:id/securitypolicies"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", OrgDetails)
                : Loading
            }
          />
          <ProtectedRoute
            path="/orgs/:id/networks"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", OrgDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/networks/bulkconfig"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", OrgDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/networks/bulkconfig/upload"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", OrgDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/networks/bulkconfig/view-history"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", OrgDetails)
                : Loading
            }
          />
          <ProtectedRoute
            path="/orgs/:id/activities"
            component={
              isAPISuccess
                ? this.PermittedComponent("ACTIVITY:VIEW", OrgDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/inodes"
            component={
              isAPISuccess
                ? this.PermittedComponent("NODE:VIEW", Inodes)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/inodes/:id"
            component={
              isAPISuccess
                ? this.PermittedComponent("NODE:VIEW", InodesDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/inodes/:id/networks/modify/:networkId"
            component={
              isAPISuccess
                ? this.PermittedComponent("NODE:VIEW", NetworkDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/inodes/:id/networks/modify"
            component={
              isAPISuccess
                ? this.PermittedComponent("NODE:VIEW", NetworkDetails)
                : Loading
            }
          />
          <ProtectedRoute
            path="/inodes/:id/services"
            component={
              isAPISuccess
                ? this.PermittedComponent("NODE:VIEW", InodesDetails)
                : Loading
            }
          />
          <ProtectedRoute
            path="/inodes/:id/listeners"
            component={
              isAPISuccess
                ? this.PermittedComponent("NODE:VIEW", InodesDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/inodes/:id/listeners/view/:listenerId"
            component={
              isAPISuccess
                ? this.PermittedComponent("NODE:VIEW", Listeners)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/inodes/:id/listeners/modify/:listenerId"
            component={
              isAPISuccess
                ? this.PermittedComponent("NODE:VIEW", Listeners)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/inodes/:id/listeners/modify"
            component={
              isAPISuccess
                ? this.PermittedComponent("NODE:VIEW", Listeners)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/inodes/:id/listeners/:listenerId/status"
            component={
              isAPISuccess
                ? this.PermittedComponent("NODE:VIEW", ListenerStatus)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/users"
            component={
              isAPISuccess
                ? this.PermittedComponent("USER:VIEW", Users)
                : Loading
            }
          />
          <ProtectedRoute
            path="/orgs/:id/users/:user_id/edit"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", OrgDetails)
                : Loading
            }
          />
          <ProtectedRoute
            path="/orgs/:id/usersdetails/:user_id"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", UserDetails)
                : Loading
            }
          />
          <ProtectedRoute
            path="/orgs/:id/users/create"
            exact
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", OrgDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/users/bulkconfig"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", OrgDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/users/bulkconfig/upload"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", OrgDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/users/bulkconfig/view-history"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", OrgDetails)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/users/:id"
            component={
              isAPISuccess
                ? this.PermittedComponent("USER:VIEW", MyProfile)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/servicetemplate"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", ServicePlayground)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/template/playground"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", ServicePlayground)
                : Loading
            }
          />
          <ProtectedRoute exact path="/profile" component={MyProfile} />
          <ProtectedRoute path="/logout" component={Logout} />
          <ProtectedRoute path="/help/releases" component={Releases} />
          <ProtectedRoute
            path="/settings"
            component={
              isAPISuccess
                ? this.props.AuthStore.IsPermitted("SUPER:ADMIN")
                  ? SettingsPage
                  : AccessDenied
                : Loading
            }
          />
          <ProtectedRoute
            path="/managealerts/add"
            component={ManageAlertForm}
          />
          <ProtectedRoute
            exact
            path="/managealerts/edit/:id"
            component={ManageAlertForm}
          />
          <ProtectedRoute
            exact
            path="/managealerts/view/:id"
            component={ManageAlertForm}
          />
          <ProtectedRoute
            exact
            path="/managewebhooks/edit/:webhookId"
            component={ManageWebhooksForm}
          />
          <ProtectedRoute
            path="/managewebhooks/add"
            component={ManageWebhooksForm}
          />
          <ProtectedRoute path="/managealerts" component={ManageAlerts} />
          <ProtectedRoute path="/managewebhooks" component={ManageWebhooks} />
          <ProtectedRoute
            path="/events"
            component={
              isAPISuccess
                ? this.PermittedComponent("EVENTS:VIEW", Events)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/keys"
            component={
              isAPISuccess
                ? this.isOrgLevelPolicyEnabled("apikey", ApiKeys)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/keys/add"
            component={
              isAPISuccess
                ? this.isOrgLevelPolicyEnabled("apikey", UserApiKeyContainer)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/orgs/create"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", OrgsList)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/orgs/:currentOrgId/edit"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", OrgsList)
                : Loading
            }
          />
          <ProtectedRoute
            exact
            path="/orgs/:id/orgs/:currentOrgId/view"
            component={
              isAPISuccess
                ? this.PermittedComponent("ORG:VIEW", OrgsList)
                : Loading
            }
          />
          <ProtectedRoute path="*" component={NotFoundResult} />
        </Switch>
      </ConfigProvider>
    );

    return this.state.forceLogout ? <ForceLogout {...this.props} /> : container;
  }
}

// fix for mobx observer https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/blocked-updates.md
export default withRouter(Routes);
