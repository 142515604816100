import React, { Component } from "react";
import { Menu } from "antd";
import { observer, inject } from "mobx-react";
import Icons from "components/UI-Components/Icons";
import QuickActionDownloads from "./QuickActionDownloads";
import QuickActionSupport from "./QuickActionSupport";
import QuickActionUser from "./QuickActionUser";

const MenuItemGroup = Menu.ItemGroup;
@inject("AuthStore", "ModelStore", "UiStore", "OrgViewModel")
@observer
class QuickAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: false,
    };
  }

  toggleSideBar = () => {
    this.props.UiStore.SetSiderFold(!this.state.isCollapsed);
    this.setState({ isCollapsed: !this.state.isCollapsed });
  };

  render() {
    return (
      <span>
        <Menu
          key="newbottomMenu"
          style={{
            backgroundColor: "transparent",
            bottom: 0,
            position: "absolute",
            left: !this.props.UiStore.isSiderFold ? 10 : 0,
            border: "none",
            display: "flex",
            alignItems: "center",
            width: "100%",
            flexDirection: !this.props.UiStore.isSiderFold ? "row" : "column",
          }}
        >
          <QuickActionDownloads />
          <QuickActionSupport {...this.props} />
          <QuickActionUser {...this.props} />
          <span
            onClick={this.toggleSideBar}
            style={{
              color: "#778aa1",
              marginLeft: !this.props.UiStore.isSiderFold ? 25 : 0,
              fontWeight: "bolder",
              fontSize: 17,
              display: "block",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            <Icons
              type="ai"
              name={
                this.props.UiStore.isSiderFold
                  ? "AiOutlineRight"
                  : "AiOutlineLeft"
              }
            />
          </span>
        </Menu>
      </span>
    );
  }
}

export default QuickAction;
