import React, { Component } from "react";
import { Dropdown, Button } from "antd";
import Icons from "components/UI-Components/Icons";

class DropdownActionBtn extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        {this.props.menu && (
          <Dropdown overlay={this.props.menu}>
            <Button
              className="dropdown-btn"
              disabled={this.props.disabled ? this.props.disabled : false}
            >
              <Icons type="md" name="MdMoreVert" style={{ fontSize: 22 }} />
            </Button>
          </Dropdown>
        )}
      </div>
    );
  }
}

export default DropdownActionBtn;
