import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Row, Col, Card, Button, Tabs, Radio } from "antd";
import LoadingComponent from "../UI-Components/LoadingComponent";
import { getCurrentlocaleText } from "../../Core/Utils";
import BreadcrumbComponent from "components/UI-Components/BreadcrumbComponent";
import OrgSelector from "../Orgs/OrgSelector";
import ServiceList from "./Services";
import LicenseComponent from "./License";
import ServiceListenerList from "../DataLists/ServiceListenerList";
import Icons from "../UI-Components/Icons";
import OrgController from "../../controller/OrgController";

@inject("InodeViewModel", "OrgViewModel", "AuthStore")
@observer
export default class MyServices extends Component {
  constructor(props) {
    super(props);
    this.AuthStore = this.props.AuthStore;
    this.orgModel = this.props.OrgViewModel;
    this.state = {
      BreadcrumbList: [],
      loading: false,
      activeTab: this.props.AuthStore.IsPermitted("SERVICE:VIEW")
        ? "installed"
        : "listeners",
      orgId: this.props.match.params.orgId
        ? this.props.match.params.orgId
        : this.props.AuthStore.loggedInUser.organization.id,
    };
  }

  componentDidMount() {
    this.setBreadcrumb();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params &&
      this.props.match.params.orgId &&
      prevProps.match.params &&
      prevProps.match.params.orgId
    ) {
      if (prevProps.match.params.orgId != this.props.match.params.orgId) {
        this.setState({
          orgId: this.props.match.params.orgId
            ? this.props.match.params.orgId
            : this.props.AuthStore.loggedInUser.organization.id,
        });
        OrgController.getOrg(this.props.match.params.orgId, false, true).then(
          res => {
            this.setBreadcrumb();
          },
        );
      }
    }
  }

  setBreadcrumb() {
    let BreadcrumbList = [];
    BreadcrumbList.push({
      name:
        this.props.OrgViewModel &&
        this.props.OrgViewModel.org &&
        this.props.OrgViewModel.org.name
          ? this.props.OrgViewModel.org.name
          : this.props.AuthStore.loggedInUser.organization.name,
      link: `dashboard/${this.state.orgId}`,
    });

    BreadcrumbList.push(
      {
        name: getCurrentlocaleText("marketplace"),
        link: "orgs/" + this.state.orgId + "/servicelisting",
      },
      {
        name: getCurrentlocaleText("services.my"),
        link: "orgs/" + this.state.orgId + "/myservices",
      },
    );

    this.setState({ BreadcrumbList });
  }

  handleTabChange = key => {
    this.setState({ activeTab: key });
  };

  render() {
    let { BreadcrumbList, loading, activeTab, orgId } = this.state;

    return (
      <LoadingComponent loading={loading}>
        <div className="usage-wrapper ctd-wrapper-all">
          <Row gutter={16} type="flex" align="middle">
            <Col span={18}>
              <h2 className="page-title">
                {getCurrentlocaleText("marketplace")}
              </h2>
              <BreadcrumbComponent
                {...this.props}
                BreadcrumbList={BreadcrumbList}
              />
            </Col>
            <Col span={6} align="right" className="flex flex-h-right">
              <OrgSelector
                {...this.props}
                org_id={orgId}
                className="orgslector-ellipse-menu"
              />
            </Col>
          </Row>
          <div>
            <Tabs
              className="tab-no-border"
              activeKey={activeTab}
              onChange={this.handleTabChange}
            >
              {this.props.AuthStore.IsPermitted("SERVICE:VIEW") && (
                <Tabs.TabPane
                  tab={
                    <span>
                      <Icons type="fa" name="FaCogs" />
                      Installed
                    </span>
                  }
                  key="installed"
                >
                  <ServiceList
                    params={{
                      org_id: orgId,
                      own: true,
                    }}
                    {...this}
                  />
                </Tabs.TabPane>
              )}
              {this.props.AuthStore.IsPermitted("NODE:VIEW") && (
                <Tabs.TabPane
                  tab={
                    <span>
                      <Icons type="fa" name="FaKey" />
                      Secrets
                    </span>
                  }
                  key="secrets"
                >
                  <LicenseComponent
                    params={{
                      org_id: orgId,
                      own: true,
                    }}
                    orgId={orgId}
                    {...this}
                  />
                </Tabs.TabPane>
              )}
              {this.props.AuthStore.IsPermitted("ORG:VIEW") && (
                <Tabs.TabPane
                  tab={
                    <span>
                      <Icons type="bs" name="BsArrowLeftRight" />
                      Listeners
                    </span>
                  }
                  key="listeners"
                >
                  <ServiceListenerList orgId={orgId} />
                </Tabs.TabPane>
              )}
            </Tabs>
          </div>
        </div>
      </LoadingComponent>
    );
  }
}
