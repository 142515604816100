import React, { Component } from "react";
import {
  Row,
  Col,
  Tabs,
  Tag,
  Select,
  Form,
  Collapse,
  Popover,
  Checkbox,
} from "antd";
import { observer, inject } from "mobx-react";
import ActionBtn from "Core/API/ActionBtn";
import TableLayout from "components/layout/TableLayout";
import SearchInput from "components/UI-Components/SearchInput";
import {
  highlightSearchText,
  copyObject,
  getCurrentlocaleText,
} from "Core/Utils";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import SecretController from "controller/SecretController";
import VolumeComponent from "components/volumes/volumeContainer";
import Icons from "../UI-Components/Icons";
import TableLayoutV1 from "../layout/TableLayoutV1";

const FormItem = Form.Item;
const Option = Select.Option;
const Panel = Collapse.Panel;
const TabPane = Tabs.TabPane;

@inject("ModelStore", "AuthStore", "UiStore", "SecretViewModel", "OrgViewModel")
@observer
class LicenseComponent extends Component {
  constructor(props) {
    super(props);
    this.secretModel = this.props.SecretViewModel;
    this.searchTimeout = null;
    this.state = {
      selectedVolumes: {},
      searchText: "",
      currentPage: 1,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (this.props.orgId !== props.orgId) {
      this.secretList(true, 0, this.state.searchText, null, null, props.params);
    }
  }

  componentDidMount() {
    this.props.UiStore.temporaryLicenseData = {};
    this.props.UiStore.showChildVolumes = this.props.cameFromDashboard
      ? true
      : false;
    this.secretList(true);
  }

  componentWillUnmount() {
    this.props.UiStore.showChildVolumes = false;
  }

  createAfterCall = resp => {
    this.setState({
      currentPage: 1,
    });
    this.secretList(true, 0, this.state.searchText);
    this.resetStates();
  };

  deleteAfterCall = () => {
    let page = this.state.currentPage ? this.state.currentPage : 0;
    let secObj = this.LicenseTableData(this.secretModel.secretsObj["volume"]);
    if (secObj.length === 1) {
      page--;
    }
    this.secretList(true, page - 1, this.state.searchText);
    this.resetStates();
  };

  secretList = (
    loading = true,
    page = 0,
    search = "",
    sortBy = null,
    sortOrder = null,
    params = this.props.params || {},
  ) => {
    params = copyObject(params);
    params.page = page;
    params.search = search;
    // For sort
    if (sortBy && sortOrder) {
      params.sort = `${sortBy}:${sortOrder}`;
    }
    if (this.props.UiStore.showChildVolumes) {
      delete params.own;
    }
    params.type = "Opaque";
    SecretController.getSecrets(params, true);
  };

  LicenseTableData = volumeList => {
    let service = [];
    if (volumeList && volumeList.length > 0) {
      service = volumeList.map((val, index) => {
        let files,
          assignedService = [];
        files = _.keys(val.data);
        /*fetching services name*/
        if (val.services) {
          val.services.map(obj => {
            assignedService.push(obj.name);
          });
        }
        return {
          id: val.id,
          name: val.name,
          organization: val.organization.name,
          assignedServiceCount: assignedService.length,
          assignedService:
            assignedService.length > 0
              ? assignedService.join(",")
              : "Unassigned",
          disabled:
            !this.props.AuthStore.IsPermitted("SERVICE:CREATE") ||
            assignedService.length > 0 ||
            val.system_service,
          licenseFiles: files.length,
          selectedLicenseDetails: val,
          systemService: val.system_service ? val.system_service : false,
        };
      });
    }
    return service;
  };

  resetStates = () => {
    this.setState({
      selectedVolumes: {},
    });
  };

  onEditVol = record => {
    this.props.UiStore.volumes.volumeName = record.name;
    this.props.UiStore.volumes.assignedServiceCount =
      record.assignedServiceCount;
  };

  rowClick = (record, index, event) => {
    this.props.UiStore.serviceFormData.rowDetails = record; //saving the license object

    /*Temporarily save previous license content so, on cancel can update the license with original content*/
    if (
      this.props.UiStore.temporaryLicenseData &&
      this.props.UiStore.temporaryLicenseData[record.name] &&
      this.props.UiStore.temporaryLicenseData[record.name]
        .tempInitialLicenseFileName
    ) {
      this.props.UiStore.serviceFormData.uploadedLicenseFileName = [];
      this.props.UiStore.serviceFormData.uploadedLicenseFileName = JSON.parse(
        this.props.UiStore.temporaryLicenseData[record.name]
          .tempInitialLicenseFileName,
      );
    } else {
      this.props.UiStore.serviceFormData.uploadedLicenseFileName = _.keys(
        record.selectedLicenseDetails.data,
      );
      this.props.UiStore.temporaryLicenseData[record.name] = {
        tempInitialLicenseFileName: [],
      };
      this.props.UiStore.temporaryLicenseData[
        record.name
      ].tempInitialLicenseFileName = JSON.stringify(
        this.props.UiStore.serviceFormData.uploadedLicenseFileName,
      );
    }
  };

  selectedLicenseRows = (record, selected, selectedRows) => {
    this.setState({
      selectedVolumes: selectedRows,
    });
  };

  selectLicenseAll = (selected, selectedRows, changeRows) => {
    this.setState({
      selectedVolumes: selectedRows,
    });
  };

  licenseListFilter = e => {
    clearTimeout(this.searchTimeout);
    let searchVal = e.target.value;
    this.setState({ searchText: searchVal });
    this.searchTimeout = setTimeout(() => {
      this.secretList(false, 0, searchVal);
    }, 500);
  };

  clearLicenseSearchFilter = () => {
    this.setState({ searchText: "", currentPage: 1 });
    this.secretList(true, 0);
  };

  lazyLoad = () => {
    let nextLink = this.secretModel.pageable["volume"].next;

    if (nextLink) {
      let page = this.secretModel.pageable["volume"].page + 1;
      this.secretList(true, page, this.state.searchText);
    }
  };

  onTableChange = pagination => {
    this.setState({
      currentPage: pagination.current,
    });
    let page = pagination.current - 1;
    this.secretList(true, page);
  };

  changeListview = e => {
    this.setState({
      currentPage: 1,
    });
    this.props.UiStore.showChildVolumes = e.target.checked;
    this.secretList(true, 0, this.state.searchText);
  };

  render() {
    let listLicense = this.LicenseTableData(this.secretModel.secretsObj.volume);
    this.LicenseTableColumns = [
      {
        title: (
          <span
            title={getCurrentlocaleText("network.form.name.placeholder.text")}
          >
            {getCurrentlocaleText("network.form.name.placeholder.text")}
          </span>
        ),
        dataIndex: "name",
        key: "name",
        width: "25%",
        render: (text, record, index) => {
          return (
            <div>
              {this.state.searchText.trim().length > 0
                ? highlightSearchText(text, this.state.searchText)
                : text}
            </div>
          );
        },
      },
      {
        title: (
          <span title={getCurrentlocaleText("assigned.services")}>
            {getCurrentlocaleText("assigned.services")}
          </span>
        ),
        dataIndex: "assignedService",
        key: "assignedService",
        width: "28%",
        render: (text, record, index) => {
          let name = text.split(",");
          let popoverContent = name.map((val, i) => {
            return (
              <Tag key={i} className="licensepopoverContent" title={val}>
                {val}
              </Tag>
            );
          });

          return name.length == 1 && name[0].toLowerCase() != "unassigned" ? (
            <div className="licensepopoverContent" title={text}>
              <Tag>{text}</Tag>
            </div>
          ) : (
            <Popover
              content={popoverContent}
              title={getCurrentlocaleText("assigned.services")}
              placement="right"
            >
              <Tag>
                {name[0].toLowerCase() === "unassigned"
                  ? `${name[0]}`
                  : ` ${name.length} service(s)`}
              </Tag>
            </Popover>
          );
        },
      },
      {
        title: (
          <span title={getCurrentlocaleText("uploaded.files")}>
            {getCurrentlocaleText("uploaded.files")}
          </span>
        ),
        dataIndex: "licenseFiles",
        key: "licenseFiles",
        width: "22%",
        render: (text, record, index) => {
          return <span style={{ marginLeft: 35 }}>{record.licenseFiles}</span>;
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "edit_node",
        width: "15%",
        render: (text, record, index) => {
          return (
            <div>
              {this.props.AuthStore.IsPermitted("SERVICE:CREATE") &&
                !record.systemService && (
                  <div
                    onClick={() => this.onEditVol(record)}
                    title={getCurrentlocaleText("edit.volume")}
                  >
                    <VolumeComponent
                      title={getCurrentlocaleText("edit.volume")}
                      actionType="edit"
                      afterCall={this.createAfterCall}
                      buttonName={<Icons type="ai" name="AiOutlineEdit" />}
                      disableName={
                        record.assignedServiceCount > 0 ? true : false
                      }
                      {...this.props}
                    />
                  </div>
                )}
            </div>
          );
        },
      },
    ];

    let org_column = {
      title: (
        <span title={getCurrentlocaleText("myprofile.organization.text")}>
          {getCurrentlocaleText("myprofile.organization.text")}
        </span>
      ),
      dataIndex: "",
      key: "organization",
      width: "16%",
      render: (text, record, index) => {
        return (
          <a href={`/orgs/${record.selectedLicenseDetails.organization.id}`}>
            {record.selectedLicenseDetails.organization.name}
          </a>
        );
      },
    };

    if (this.props.UiStore.showChildVolumes) {
      this.LicenseTableColumns.splice(2, 0, org_column);
    }

    let container = (
      <div style={{ minHeight: 250 }}>
        <div>
          <div>
            <div
              style={{
                marginBottom: "10px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 7,
                }}
              >
                <Checkbox
                  checked={this.props.UiStore.showChildVolumes}
                  onChange={this.changeListview}
                  disabled={
                    this.props.OrgViewModel.org &&
                    this.props.OrgViewModel.org.org_count > 0
                      ? false
                      : true
                  }
                >
                  {getCurrentlocaleText("volumes.table_view.checkbox.label")}
                </Checkbox>
              </div>

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <SearchInput
                  placeholder="Filter Volumes by name"
                  onChange={this.licenseListFilter}
                  value={this.state.searchText}
                  clearSearch={this.clearLicenseSearchFilter}
                />
              </div>
              <div
                style={{
                  marginLeft: "20px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {this.props.AuthStore.IsPermitted("SERVICE:CREATE") && (
                  <VolumeComponent
                    title={getCurrentlocaleText("add.volume")}
                    actionType="create"
                    afterCall={this.createAfterCall}
                    {...this.props}
                  />
                )}

                {this.props.AuthStore.IsPermitted("SERVICE:CREATE") && (
                  <ActionBtn
                    title={
                      <span>
                        <Icons type="ai" name="AiOutlineDelete" />{" "}
                        {getCurrentlocaleText(
                          "inode.list.table.delete.button.title.text",
                          {
                            0:
                              this.state.selectedVolumes.length > 1
                                ? "Volumes"
                                : "Volume",
                          },
                        )}
                      </span>
                    }
                    HoverText={
                      <span>
                        {getCurrentlocaleText(
                          "inode.list.table.delete.button.hover.text",
                          {
                            0:
                              this.state.selectedVolumes.length > 1
                                ? "Volumes"
                                : "Volume",
                          },
                        )}
                      </span>
                    }
                    resource="secret"
                    action="delete"
                    disabled={_.isEmpty(this.state.selectedVolumes)}
                    iconButton={true}
                    icon="AiOutlineDelete"
                    formData={this.state.selectedVolumes}
                    afterCall={this.deleteAfterCall}
                    secretType="license"
                    controller={SecretController}
                    messageContent={
                      <span>
                        <p>
                          {getCurrentlocaleText(
                            this.state.selectedVolumes.length > 1
                              ? "volume.multiple.delete.confirmation.message.text"
                              : "volume.delete.confirmation.message.text",
                          )}
                        </p>
                      </span>
                    }
                    cancelText={getCurrentlocaleText(
                      "inode.delete.confirmation.cancel.text",
                      {
                        0:
                          this.state.selectedVolumes.length > 1
                            ? "Volumes"
                            : "Volume",
                      },
                    )}
                    warningMessage={getCurrentlocaleText(
                      this.state.selectedVolumes.length > 1
                        ? "volume.multiple.delete.warning.message.text"
                        : "volume.delete.warning.message.text",
                    )}
                    okText={
                      <span>
                        <Icons
                          type="ai"
                          name="AiOutlineDelete"
                          style={{ margin: 0 }}
                          className="iot-delete-icon"
                        />
                        {getCurrentlocaleText(
                          "inode.delete.confirmation.ok.text",
                          {
                            0:
                              this.state.selectedVolumes.length > 1
                                ? "Volumes"
                                : "Volume",
                          },
                        )}
                      </span>
                    }
                  ></ActionBtn>
                )}
              </div>
            </div>
          </div>
          <Row>
            <Col span={24}>
              {listLicense.length > 0 ? (
                <TableLayoutV1
                  columns={this.LicenseTableColumns}
                  dataSource={listLicense}
                  pagination={{
                    pageSize:
                      this.secretModel && this.secretModel.pageable.volume.size,
                    total:
                      this.secretModel &&
                      this.secretModel.pageable.volume.total_count,
                    current: this.state.currentPage,
                  }}
                  hideExpandedRow={true}
                  rowClick={this.rowClick}
                  selectedRows={this.selectedLicenseRows}
                  selectAll={this.selectLicenseAll}
                  onChange={this.onTableChange}
                  className="secretLicenseList"
                />
              ) : (
                <div>
                  {!this.secretModel.loading && (
                    <h3>{getCurrentlocaleText("no.volumes.available")}</h3>
                  )}
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    );

    return (
      <LoadingComponent loading={this.secretModel.loading}>
        {container}
      </LoadingComponent>
    );
  }
}

export default LicenseComponent;
