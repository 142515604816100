import React, { Component } from "react";
import { Icon, Button } from "antd";
import { Link } from "react-router-dom";
import Icons from "components/UI-Components/Icons";

class ActionRoute extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="action-btn">
        <Link to={this.props.route}>
          <Button
            title={this.props.title}
            type={this.props.primaryButton ? "primary" : ""}
            disabled={this.props.disabled ? this.props.disabled : false}
            style={this.props.buttonStyle}
            className={this.props.className}
          >
            {this.props.isCustomIcon ? (
              this.props.iscustomText ? (
                <span>
                  <span style={this.props.customTextStyle}>
                    {this.props.iscustomText}
                  </span>
                  <Icons
                    type={this.props.iconType}
                    name={this.props.iconName}
                    className="valign-middle"
                  />
                </span>
              ) : (
                <Icons
                  type={this.props.iconType}
                  name={this.props.iconName}
                  className="valign-middle"
                />
              )
            ) : this.props.iconButton ? (
              <Icons
                type="ai"
                name={this.props.actionIcon}
                style={this.props.iconBtnStyle}
                className="valign-middle"
              />
            ) : (
              <span>
                <Icons
                  type="ai"
                  name={this.props.actionIcon}
                  style={this.props.iconBtnStyle}
                  className="valign-middle"
                />
                <span style={{ marginLeft: 5 }}>{this.props.actionLabel}</span>
              </span>
            )}
          </Button>
        </Link>
      </div>
    );
  }
}

export default ActionRoute;
