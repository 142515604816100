import React, { Component } from "react";
import { Button } from "antd";

class ImageButton extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const btnStyle = {
      height: 65,
      marginRight: 8,
      marginTop: 10,
    };
    const imgStyle = {
      height: 40,
      width: 110,
    };
    var ElementType = this.props.src;

    return (
      <Button
        className={this.props.className ? this.props.className : ""}
        style={this.props.btnStyle ? this.props.btnStyle : btnStyle}
        onClick={this.props.onClick.bind(this, this.props.value)}
      >
        {!this.props.isSvg ? (
          <img
            src={this.props.src}
            style={this.props.imgStyle ? this.props.imgStyle : imgStyle}
          />
        ) : (
          <ElementType
            style={this.props.imgStyle ? this.props.imgStyle : imgStyle}
          />
        )}
      </Button>
    );
  }
}

export default ImageButton;
