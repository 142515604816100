import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Table, Button, Row, Col, Collapse, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import {
  getCIDRdata,
  getCurrentlocaleText,
  isCidr,
  isRestrictedCIDR,
} from "Core/Utils";
const FormItem = Form.Item;
const Panel = Collapse.Panel;
import CidrProvider from "components/UI-Components/CidrProvider";
import Icons from "../UI-Components/Icons";
@inject("UiStore")
@observer
class AllowAccessFromConfiguration extends Component {
  constructor(props) {
    super(props);
    this.formErrorResponse = {};
    this.state = {
      isCidrExist: false,
      cidrs: [],
      options: [],
      activeKey: [],
      loading: false,
      viewOnly: false,
      count: 0,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.allowAccessFroms && props.allowAccessFroms.length > 0) {
      let cidrs = [];
      props.allowAccessFroms.forEach((cidr, i) => {
        let key = i;
        let item = {
          key: key,
          cidr: cidr,
        };
        cidrs.push(item);
      });
      this.setState({
        cidrs: cidrs,
        count: cidrs.length,
      });
    }
    this.setState({
      viewOnly: props.viewOnly,
    });
  }
  UNSAFE_componentWillMount() {
    if (this.props.allowAccessFroms && this.props.allowAccessFroms.length > 0) {
      let cidrs = [];
      this.props.allowAccessFroms.forEach((cidr, i) => {
        let key = i;
        let item = {
          key: key,
          cidr: cidr,
        };
        cidrs.push(item);
      });
      this.setState({
        cidrs: cidrs,
        count: cidrs.length,
      });
    }
    this.setState({
      viewOnly: this.props.viewOnly,
    });
  }

  updateHelp = data => {
    this.props.UiStore.SetStoreData("helpData", data);
  };

  handleOnChangeCollapse = key => {
    this.setState({ activeKey: key });
    if (key && key.length) {
      this.setState({ loading: true });
      setTimeout(() => {
        this.setState({ loading: false });
      }, 200);
    }
  };

  pushData = cidrs => {
    if (this.props.setAllowAccessFrom) {
      this.props.setAllowAccessFrom(cidrs);
    }
  };

  getAllowAccessFromCount = () => {
    return this.state.cidrs.filter(val => {
      return val.isdeleted === false;
    }).length;
  };

  //Update cidr changes coming from the cidrprovider component
  handleCidrChange = (record, value) => {
    this.props.form.setFields({
      ["allow_access_from-" + record.key]: {
        value: value,
      },
    });
    this.props.form.validateFields(
      [`allow_access_from-${record.key}`],
      (err, value) => {
        if (!err) {
          this.setState({ isCidrExist: true });
        }
      },
    );
  };

  setValue = (key, property, value) => {
    let existingCidrs = this.state.cidrs;
    let index = this.state.cidrs.findIndex(val => {
      return val.key === key;
    });
    existingCidrs[index][property] = value;
    this.setState({
      cidrs: existingCidrs,
    });
    let cidrs = [];
    existingCidrs.forEach(cidr => {
      cidrs.push(cidr.cidr);
    });
    this.pushData(cidrs);
  };

  addItem = () => {
    let key = this.state.cidrs.length + 1;
    let newItem = {
      key: key,
      cidr: "",
    };
    let cidrs = this.state.cidrs;
    cidrs.push(newItem);
    this.setState({
      cidrs: cidrs,
      count: cidrs.length,
    });
    this.setState({ count: this.getAllowAccessFromCount() });
    // this timeout event is to make the last added table row  into view
    setTimeout(() => {
      if (document.querySelector(".allow_access_from-"))
        document.querySelector(".allow_access_from-").scrollIntoView();
    }, 300);
    let existingCidrs = [];
    cidrs.forEach(cidr => {
      if (!cidr.isdeleted) {
        existingCidrs.push(cidr.cidr);
      }
    });
    this.pushData(existingCidrs);
  };
  removeItem = record => {
    let existingCidrs = this.state.cidrs;
    let row_index = existingCidrs
      .filter(val => {
        return val.isdeleted === false;
      })
      .findIndex(val => {
        return val.key === record.key;
      });
    let index = existingCidrs.findIndex(val => {
      return val.key === record.key;
    });
    existingCidrs = existingCidrs.filter((val, i) => {
      return i != index;
    });
    this.setState({
      cidrs: existingCidrs,
      count: existingCidrs.length,
    });
    let cidrs = [];
    existingCidrs.forEach(cidr => {
      if (!cidr.isdeleted) {
        cidrs.push(cidr.cidr);
      }
    });
    this.setState({ count: cidrs.length });
    this.pushData(cidrs);
  };

  /*-----------------------------CIDR validation ----------------------------------*/
  checkCidr = (rule, value, callback, key) => {
    let form = this.props.form;
    let message = null;
    //this.setState({ tanCIDR: value });
    if (value == null) {
    } else {
      let isCidrValid = value && isCidr(value, false),
        currentCIDR = getCIDRdata(value);
      if (!isCidrValid) {
        message = getCurrentlocaleText("network.form.cidr.errormessage2.text");
      }
      if (currentCIDR) {
        if (isRestrictedCIDR(currentCIDR)) {
          message = getCurrentlocaleText(
            "network.form.cidr.errormessage3.text",
          );
        }
      }
    }
    if (message) {
      callback(message);
    } else {
      this.setValue(key, "cidr", value);
      callback();
    }
  };
  render() {
    const { getFieldDecorator } = this.props.form;

    this.columns = [
      {
        title: <span>CIDR</span>,
        dataIndex: "allow_access_from",
        width: "8%",
        render: (text, record) => {
          return (
            <FormItem style={{ marginTop: "10px" }}>
              {getFieldDecorator(`allow_access_from-${record.key}`, {
                rules: [
                  {
                    message: "Please select allow access from.",
                    whitespace: true,
                  },
                  {
                    validator: (rule, value, callback) => {
                      this.checkCidr(rule, value, callback, record.key);
                    },
                  },
                ],
                initialValue: record.cidr,
              })(
                <CidrProvider
                  {...this.props}
                  maskLength={"34%"}
                  ipLength={"57%"}
                  className={"allow-access-from"}
                  tancidr={record.cidr}
                  isDisabled={this.state.viewOnly}
                  handleChange={val => {
                    this.handleCidrChange(record, val);
                  }}
                />,
              )}
            </FormItem>
          );
        },
      },
      {
        title: "",
        dataIndex: "",
        width: "1%",
        render: (text, record) => {
          return (
            <FormItem style={{ marginTop: "10px" }}>
              {getFieldDecorator(`isdeleted-${record.key}`, {
                initialValue: record.isdeleted,
              })(
                <Button
                  className="all-center"
                  icon={<Icons type="ai" name="AiOutlineMinus" />}
                  shape={"circle"}
                  onClick={this.removeItem.bind(this, record)}
                  title={"Remove"}
                  disabled={this.state.viewOnly}
                />,
              )}
            </FormItem>
          );
        },
      },
    ];

    let content = (
      <Row gutter={2}>
        <Col>
          {!this.state.viewOnly && (
            <div style={{ textAlign: "right" }}>
              <Button
                style={{
                  marginRight: 3,
                  marginBottom: 16,
                }}
                icon={<PlusOutlined />}
                onClick={this.addItem.bind()}
                title={getCurrentlocaleText(
                  "service_listener.add.text.button.label",
                )}
                disabled={
                  this.state.viewOnly
                    ? true
                    : this.state.count >= 5
                    ? true
                    : false
                }
              >
                {getCurrentlocaleText("service_listener.add.text.button.label")}
              </Button>
            </div>
          )}
          <Table
            disabled={this.props.viewOnly ? true : false}
            className="allow-access-from-Form-Table scroll-auto"
            columns={this.columns}
            dataSource={this.state.cidrs}
            size="small"
            pagination={false}
            scroll={{ y: 240 }}
          />
        </Col>
      </Row>
    );

    return (
      <div>
        {this.props.isCollapsible ? (
          <Collapse
            onChange={this.handleOnChangeCollapse}
            className="allowAccessFroms-config-collapse"
            activeKey={this.state.activeKey}
          >
            <Panel
              key={"1"}
              header={
                <div className="panel-header-text">
                  {getCurrentlocaleText(
                    "service_listener.allow_access_from.text",
                  )}
                </div>
              }
            >
              {this.state.loading ? (
                <div style={{ textAlign: "center" }}>
                  <Spin tip="Loading..." />
                </div>
              ) : (
                <div>{content}</div>
              )}
            </Panel>
          </Collapse>
        ) : (
          content
        )}
      </div>
    );
  }
}

const AllowAccessFromForm = Form.create()(AllowAccessFromConfiguration);
export default AllowAccessFromForm;
