import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Button, Tag, Menu } from "antd";
import { Link } from "react-router-dom";
import TableLayout from "components/layout/TableLayout";
import { getCurrentlocaleText } from "Core/Utils";
import AlertConditionDisplay from "components/Alerts/components/AlertConditionDisplay";
import AlertNotificationDisplay from "components/Alerts/components/AlertNotificationDisplay";
import AlertExpandableRow from "components/Alerts/components/AlertExpandableRow";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import OrgFilter from "components/UI-Components/OrgFilter";
import AlertController from "controller/AlertController";
import Icons from "components/UI-Components/Icons";
import "../../assets/styles/Alert.css";
import ManageAlertForm from "components/Alerts/ManageAlertForm";
import ActionBtn from "Core/API/ActionBtn";
import DropdownActionBtn from "components/UI-Components/DropdownActionBtn";
import ActionRoute from "Core/API/ActionRoute";
import TableLayoutV1 from "../layout/TableLayoutV1";

@inject(
  "UiStore",
  "AlertViewModel",
  "AuthStore",
  "WebhookViewModel",
  "ChannelViewModel",
)
@observer
class AlertList extends Component {
  constructor(props) {
    super(props);
    this.AuthStore = this.props.AuthStore;
    this.UiStore = this.props.UiStore;
    this.alertModel = this.props.AlertViewModel;
    this.webhookModel = this.props.WebhookViewModel;
    this.channelModel = this.props.ChannelViewModel;
    this.orgId = undefined;
    this.state = {
      expandedRowKeys: [],
      currentPage: 1,
      modalContent: "",
      modalTitle: "",
      isTableModalToggle: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.alertList(true, 0);
  }

  alertList = (loading = true, page = 0) => {
    let params = { page: page },
      ownAlerts = true;
    if (this.orgId) {
      params.org_id = this.orgId;
      params.own = true;
      ownAlerts = false;
    }
    AlertController.getAlerts(params, loading, ownAlerts);
  };

  unsubscribe = response => {
    if (response) {
      this.UiStore.setSuccessMessage(
        getCurrentlocaleText("manage_alerts.unsubscribe_message.text"),
      );
      // Call alert list
      let page = this.alertModel.pageable.page;
      if (this.alertModel.alertData.length == 0) {
        page--;
      }
      this.alertList(true, page);
      this.setState({ expandedRowKeys: [] });
    }
  };

  filterByOrg = value => {
    this.orgId = value;
    setTimeout(() => {
      this.alertList(true, 0);
      this.setState({ expandedRowKeys: [] });
    }, 100);
  };

  expandRow = () => {
    let keys = this.alertModel.alerts.map(item => {
      return item.id;
    });
    this.setExpandedRowKeys(
      this.state.expandedRowKeys.length !== keys.length ? keys : [],
    );
  };

  onExpand = (expanded, record) => {
    let keys = this.state.expandedRowKeys;
    let index = keys.findIndex(val => {
      return val === record.id;
    });
    if (expanded) {
      if (index === -1) {
        keys.push(record.id);
      }
      this.setExpandedRowKeys(keys);
    } else {
      if (index !== -1) {
        keys.splice(index, 1);
      }
      this.setExpandedRowKeys(keys);
    }
  };

  setExpandedRowKeys = keys => {
    this.setState({ expandedRowKeys: keys });
  };

  onTableChange = pagination => {
    this.setState({
      currentPage: pagination.current,
    });
    let page = pagination.current - 1;
    this.alertList(true, page);
  };

  lazyLoad = () => {
    if (this.alertModel.pageable.next) {
      let page = this.alertModel.pageable.page + 1;
      this.alertList(true, page);
    }
  };

  componentWillUnmount() {
    this.webhookModel.resetWebhooks();
    this.channelModel.resetChannels();
  }

  getModalContentJSX = record => {
    this.setState({
      modalTitle: record && record.name ? record.name : "",
      modalContent: <AlertExpandableRow record={record} key={record.id} />,
    });
  };

  handleModalOk(e) {
    this.setState({
      isTableModalToggle: !this.state.isTableModalToggle,
    });
  }

  render() {
    const { isTableModalToggle } = this.state;
    let alertData = this.alertModel.alertData;
    this.columns = [
      {
        title: getCurrentlocaleText("alertform.alertname.text"),
        key: "name",
        width: "20%",
        render: (text, record, index) => {
          return (
            <div>
              <span>
                {record.is_generated && record.type === "CERT_EXPIRY"
                  ? getCurrentlocaleText("inode.cert_status_alerts.text")
                  : record.is_generated && record.type === "HEADLESS_EXPIRY"
                  ? getCurrentlocaleText("headless_status_alerts.text")
                  : record.name}
              </span>
              {record.is_generated && (
                <span>
                  <br />
                  <Tag>
                    <Icons
                      type="fa"
                      className="alert-list-icon"
                      name="FaBell"
                    />{" "}
                    {getCurrentlocaleText("subscription.auto.label.text")}
                  </Tag>
                </span>
              )}
            </div>
          );
        },
      },
      {
        title: getCurrentlocaleText("managealerts.condition.text"),
        key: "condition",
        width: this.orgId ? "20%" : "25%",
        render: (text, record, index) => {
          return <AlertConditionDisplay record={record} />;
        },
      },
      {
        title: getCurrentlocaleText("managealerts.target.text"),
        key: "target",
        width: this.orgId ? "20%" : "25%",
        render: (text, record, index) => {
          return (
            <AlertExpandableRow
              record={record}
              key={record.id}
              isTargetDisplay={true}
            />
          );
        },
      },
      {
        title: getCurrentlocaleText("managealerts.notification.text"),
        key: "notification",
        width: this.orgId ? "15%" : "20%",
        render: (text, record, index) => {
          return <AlertNotificationDisplay record={record} />;
        },
      },
      {
        title: getCurrentlocaleText("general.component.users.text"),
        key: "user",
        width: "15%",
        render: (text, record, index) => {
          return (
            <span>
              {record.createdBy ? <span>{record.createdBy.name}</span> : "-"}
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText("alertform.organization.text"),
        key: "organization",
        width: "15%",
        render: (text, record, index) => {
          return (
            <span>
              {record.organization ? (
                <span>{record.organization.name}</span>
              ) : (
                "-"
              )}
            </span>
          );
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "deleteAlert",
        width: "10%",
        render: (text, record, index) => {
          const view = (
            <span>
              <ActionRoute
                actionIcon="AiOutlineEye"
                actionLabel={getCurrentlocaleText(
                  "managealerts.viewalert.text",
                )}
                iconButton
                title={getCurrentlocaleText("managealerts.viewalert.text")}
                route={{
                  pathname: `/managealerts/view/${record.id}`,
                  state: {
                    formValues: record,
                  },
                }}
                iconBtnStyle={{ fontSize: 14 }}
              />
            </span>
          );

          const edit = (
            <span>
              <ActionRoute
                actionIcon="AiOutlineEdit"
                actionLabel={getCurrentlocaleText(
                  "managealerts.editalert.text",
                )}
                iconButton
                title={getCurrentlocaleText("managealerts.editalert.text")}
                route={{
                  pathname: `/managealerts/edit/${record.id}`,
                  state: {
                    formValues: record,
                  },
                }}
                disabled={record.is_generated ? true : false}
                iconBtnStyle={{ fontSize: 14 }}
              />
            </span>
          );
          const del = (
            <span>
              {" "}
              <ActionBtn
                title={
                  <span>
                    <Icons type="ai" name="AiOutlineDelete" />{" "}
                    {getCurrentlocaleText("managealerts.deletealert.text")}
                  </span>
                }
                iconButton
                icon="AiOutlineDelete"
                formData={record}
                map={{ id: record.id }}
                resource="alerts"
                action="delete"
                actionForm={ManageAlertForm}
                disabled={
                  record.createdBy &&
                  this.UiStore.currentUser &&
                  record.createdBy.id !== this.UiStore.currentUser.id
                    ? true
                    : false
                }
                afterCall={this.unsubscribe}
                controller={AlertController}
                messageContent={getCurrentlocaleText(
                  "managealerts.deletealert.message.text",
                )}
                cancelText={getCurrentlocaleText(
                  "delete.confirmation.cancel.text",
                )}
              >
                <Icons type="ai" name="AiOutlineDelete" style={{ margin: 0 }} />{" "}
                {getCurrentlocaleText("delete.confirmation.ok.text")}
              </ActionBtn>
            </span>
          );
          const menu = (
            <Menu>
              <Menu.Item key={0}>{view}</Menu.Item>
              <Menu.Item key={1}>{edit}</Menu.Item>
              <Menu.Item key={3}>{del}</Menu.Item>
            </Menu>
          );
          return (
            <div
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <DropdownActionBtn menu={menu} />
            </div>
          );
        },
      },
    ];

    // Remove (user & organization) columns if org filter is not selected
    if (!this.orgId) {
      this.columns.splice(4, 2);
    }

    let container = (
      <div className="alert-list-container">
        <div className="alert-list-container-div">
          <span className="headertext">
            {getCurrentlocaleText("manage_alerts.text")}
          </span>
        </div>
        <div className="list-container">
          <div className="list-container-child">
            {/* LAT-5944 Enable Support roles to view alert subscriptions configured by customers*/}
            {this.AuthStore.IsPermitted("USERALERT:VIEW") && (
              <div className="alert-filter-header">
                <OrgFilter
                  className="alert-list-org-selector"
                  placeholder={getCurrentlocaleText(
                    "general.filter.by.org.text",
                  )}
                  allowClear={true}
                  onChange={this.filterByOrg}
                />
              </div>
            )}
            <div className="action-btn">
              <Link to="/managealerts/add">
                <Button
                  type="primary"
                  title={getCurrentlocaleText("managealerts.addalert.text")}
                >
                  <Icons type="ai" name="AiOutlinePlus" />
                  {getCurrentlocaleText("managealerts.addalert.text")}
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div>
          {alertData.length > 0 ? (
            <TableLayoutV1
              columns={this.columns}
              dataSource={alertData}
              pagination={{
                pageSize: this.alertModel && this.alertModel.pageable.size,
                total: this.alertModel && this.alertModel.pageable.total_count,
                current: this.state.currentPage,
              }}
              className="alertList"
              expandedRowRender={record => (
                <AlertExpandableRow record={record} key={record.id} />
              )}
              expandedRowKeys={this.state.expandedRowKeys}
              onExpand={this.onExpand}
              hideRowSelection={true}
              onChange={this.onTableChange}
              getModalContent={record => {
                return this.getModalContentJSX(record);
              }}
              handleModalOk={e => this.handleModalOk(e)}
              modalTitle={this.state.modalTitle}
              modalContent={this.state.modalContent}
              isTableModalToggle={isTableModalToggle}
              isModalLoading={false}
              disableFooter={true}
            />
          ) : (
            <div>
              {!this.alertModel.loading && (
                <div>{getCurrentlocaleText("managealerts.nocontent.text")}</div>
              )}
            </div>
          )}
        </div>
      </div>
    );

    return (
      <LoadingComponent loading={this.alertModel.loading}>
        {container}
      </LoadingComponent>
    );
  }
}

export default AlertList;
