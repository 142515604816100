import React, { Component } from "react";
import { Link } from "react-router-dom";
import { observer, inject } from "mobx-react";
import ActionBtn from "Core/API/ActionBtn";
import CertificateForm from "components/Forms/CertificateForm";
import TableLayout from "components/layout/TableLayout";
import { highlightSearchText, getCurrentlocaleText } from "Core/Utils";
import SearchInput from "components/UI-Components/SearchInput";

import { Row, Col, Checkbox } from "antd";
import Icons from "components/UI-Components/Icons";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import CertificateController from "controller/CertificateController";
import propTypes from "prop-types";
import TableLayoutV1 from "../layout/TableLayoutV1";

@inject("AuthStore", "CertificateViewModel", "UiStore", "OrgViewModel")
@observer
class CertificateList extends Component {
  constructor(props) {
    super(props);
    this.certificateModel = this.props.CertificateViewModel;
    this.searchTimeout = null;
    this.state = {
      selectedCerts: {},
      selectedRowKeys: [], // for table to identify selected rows
      deletableCerts: {
        certs: [],
      },
      searchText: "",
      filterInfo: null,
      sorterInfo: { sorter: {}, sort: {} },
      expandedRowKeys: [],
      currentPage: 1,
      modalContent: "",
      modalTitle: "",
      isTableModalToggle: false,
    };
  }

  CertificateList = (
    loading = true,
    page = 0,
    search = "",
    params = this.props.params || {},
  ) => {
    params.page = page;
    params.search = search;
    if (this.props.UiStore.showChildCertificates) delete params.own;
    else params.own = true;
    CertificateController.getSSHKeys(params, loading);
  };

  componentWillUnmount() {
    this.props.UiStore.showChildCertificates = false;
  }
  componentDidMount() {
    this.props.UiStore.showChildCertificates = this.props.cameFromDashboard
      ? true
      : false;
    this.CertificateList(true);
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (this.props.orgId != props.orgId) {
      this.CertificateList(true, 0, this.state.searchText, props.params);
    }
  }

  resetState = () => {
    // on deselect all
    this.setState({
      selectedCerts: {},
      deletableCerts: {
        certs: [],
      },
    });
  };

  createAfterCall = resp => {
    this.setState({
      currentPage: 1,
    });
    this.CertificateList(true, 0, this.state.searchText);
    this.resetState();
  };

  deleteAfterCall = () => {
    let page = this.state.currentPage ? this.state.currentPage : 0;
    if (this.certificateModel.sshKeys.length == 0) {
      page--;
    }
    this.CertificateList(true, page - 1, this.state.searchText);
    this.resetState();
  };

  setSelectedStates = (record = {}, selected, selectedRows) => {
    for (let i = 0; i < selectedRows.length; i++) {
      let name = selectedRows[i].name;

      this.setState(() => {
        let newObj = {
            deletableCerts: {
              certs: [],
            },
          },
          selectedCert = this.state.selectedCerts,
          item = selectedRows[i],
          tempArray = [];

        selectedCert[name] = {
          value: true,
          name: item.name,
          id: item.id,
        };

        //Temp array for deletable certificates list

        for (let i in selectedRows) {
          selectedCert[selectedRows[i].name]
            ? tempArray.push(selectedCert[selectedRows[i].name])
            : "";
        }
        // To set the value : false , when orgs is deselected and remove it from delete list
        !selected && selectedCert[record.name]
          ? ((selectedCert[record.name].value = false),
            (tempArray = _.filter(tempArray, val => {
              return val.id !== record.id;
            })))
          : null;

        newObj["selectedCerts"] = selectedCert;
        newObj.deletableCerts["certs"] = tempArray;
        return newObj;
      });
    }
  };

  onSelectAllChange = (selected, selectedRows, changeRows) => {
    if (selected) {
      this.setSelectedStates(null, selected, selectedRows);
    } else {
      // on deselect all
      this.resetState();
    }
  };

  handleTableChange = (record, selected, selectedRows) => {
    selectedRows.length == 0 ? this.resetState() : "";
    this.setSelectedStates(record, selected, selectedRows);
  };

  certificateListFilter = e => {
    clearTimeout(this.searchTimeout);
    let searchText = e.target.value;
    this.setState({ searchText: searchText });
    this.searchTimeout = setTimeout(() => {
      this.CertificateList(false, 0, searchText);
    }, 500);
  };

  clearSearchFilter = () => {
    this.setState({
      searchText: "",
      currentPage: 1,
    });
    this.CertificateList(true, 0, "");
  };

  onTableChange = (pagination, filter, sorter) => {
    this.setState({
      currentPage: pagination.current,
      filterInfo: filter,
      sorterInfo: sorter,
    });
    let page = pagination.current - 1;
    this.CertificateList(true, page);
  };

  expandRow = () => {
    let keys = this.certificateModel.sshKeys.map(item => {
      return item.id;
    });
    this.setExpandedRowKeys(
      this.state.expandedRowKeys.length !== keys.length ? keys : [],
    );
  };

  lazyLoad = () => {
    if (this.certificateModel.pageable_ssh.next) {
      let page = this.certificateModel.pageable_ssh.page + 1;
      this.CertificateList(true, page, this.state.searchText);
    }
  };

  setExpandedRowKeys = keys => {
    this.setState({ expandedRowKeys: keys });
  };

  onExpand = (expanded, record) => {
    let keys = this.state.expandedRowKeys;
    let index = keys.findIndex(val => {
      return val === record.id;
    });
    if (expanded) {
      if (index === -1) {
        keys.push(record.id);
      }
      this.setExpandedRowKeys(keys);
    } else {
      if (index !== -1) {
        keys.splice(index, 1);
      }
      this.setExpandedRowKeys(keys);
    }
  };

  changeListview = e => {
    this.props.UiStore.showChildCertificates = e.target.checked;
    this.setState({ currentPage: 1 });
    this.CertificateList(true, 0, this.state.searchText);
  };

  getMoreInfo(record) {
    return (
      <div>
        <Row>
          <div style={{ display: "flex", width: "550px" }}>
            <Col span={3}>
              <strong>
                {getCurrentlocaleText("certificate.list.key.text") + " : "}
              </strong>
            </Col>
            <Col span={20}>{record.public_key}</Col>
          </div>
        </Row>
      </div>
    );
  }

  getModalContentJSX = record => {
    this.setState({
      modalTitle: record && record.name ? record.name : "",
      modalContent: this.getMoreInfo(record),
    });
  };
  handleModalOk(e) {
    this.setState({
      isTableModalToggle: !this.state.isTableModalToggle,
    });
  }

  render() {
    const { isTableModalToggle } = this.state;
    let sshKeys = this.certificateModel.sshKeys;
    let width = this.props.UiStore.showChildCertificates ? "50%" : "90%";

    this.columns = [
      {
        title: (
          <span
            title={getCurrentlocaleText(
              "certificate.number.column.header.text",
            )}
          >
            {getCurrentlocaleText("certificate.number.column.header.text")}
          </span>
        ),
        dataIndex: "name",
        key: "name",
        width: width,
        render: (text, record, index) => {
          return (
            <div>
              {this.state.searchText.trim().length > 0
                ? highlightSearchText(text, this.state.searchText)
                : text}
            </div>
          );
        },
      },
    ];

    let org_column = {
      title: (
        <span title={getCurrentlocaleText("myprofile.organization.text")}>
          {getCurrentlocaleText("myprofile.organization.text")}
        </span>
      ),
      dataIndex: "current_org",
      key: "orgs",
      width: "40%",
      render: (text, record, index) => {
        return (
          <div>
            <Link to={"/dashboard/" + record.organization.id}>
              {record.organization.name}
            </Link>
          </div>
        );
      },
    };

    if (this.props.UiStore.showChildCertificates) {
      this.columns.splice(1, 0, org_column);
    }

    let container = (
      <div style={{ minHeight: 250 }}>
        <div style={{ display: "inline-flex", width: "40%" }}>
          <span className="headertext">
            {getCurrentlocaleText("general.assets_certificates.label.text")}
          </span>
        </div>
        <div
          style={{
            marginBottom: "10px",
            display: "inline-flex",
            justifyContent: "flex-end",
            width: "60%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 7,
            }}
          >
            <Checkbox
              checked={this.props.UiStore.showChildCertificates}
              onChange={this.changeListview}
              disabled={
                this.props.OrgViewModel.org &&
                this.props.OrgViewModel.org.org_count > 0
                  ? false
                  : true
              }
            >
              {getCurrentlocaleText("certificate.table_view.checkbox.label")}
            </Checkbox>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <SearchInput
              placeholder={getCurrentlocaleText(
                "certificate.search.placeholder.text",
              )}
              onChange={this.certificateListFilter}
              value={this.state.searchText}
              clearSearch={this.clearSearchFilter}
            />
          </div>
          <div
            style={{
              marginLeft: "20px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {this.props.AuthStore.IsPermitted("SSHKEY:ADMIN") && (
              <div>
                <ActionBtn
                  type="primary"
                  title={
                    <span>
                      <Icons type="fa" name="FaKey" />{" "}
                      {getCurrentlocaleText("certificate.add.button.text")}
                    </span>
                  }
                  resource={this.props.resource}
                  icon="AiOutlinePlus"
                  width={500}
                  iconButton
                  action={this.props.action ? this.props.action : "create"}
                  extendData={{ create_under: this.props.params.org_id }}
                  actionForm={CertificateForm}
                  afterCall={this.createAfterCall}
                  controller={CertificateController}
                >
                  <Icons type="ai" name="AiOutlinePlus" style={{ margin: 0 }} />
                  {getCurrentlocaleText("certificate.add.button.text")}
                </ActionBtn>
                <ActionBtn
                  title={
                    <span>
                      <Icons type="ai" name="AiOutlineDelete" />{" "}
                      {getCurrentlocaleText(
                        "inode.list.table.delete.button.title.text",
                        {
                          0:
                            this.state.deletableCerts.certs.length > 1
                              ? "SSH Keys"
                              : "SSH Key",
                        },
                      )}
                    </span>
                  }
                  HoverText={
                    <span>
                      {getCurrentlocaleText(
                        "inode.list.table.delete.button.hover.text",
                        {
                          0:
                            this.state.deletableCerts.certs.length > 1
                              ? "SSH Keys"
                              : "SSH Key",
                        },
                      )}
                    </span>
                  }
                  orgId={this.props.orgId}
                  icon="AiOutlineDelete"
                  formData={this.state.deletableCerts.certs}
                  iconButton
                  resource="certificates"
                  afterCall={this.deleteAfterCall}
                  action="delete"
                  disabled={this.state.deletableCerts.certs.length === 0}
                  controller={CertificateController}
                  messageContent={
                    <span>
                      <p>
                        {getCurrentlocaleText(
                          this.state.deletableCerts.certs.length > 1
                            ? "ssh.multiple.delete.confirmation.message.text"
                            : "ssh.delete.confirmation.message.text",
                        )}
                      </p>
                    </span>
                  }
                  warningMessage={getCurrentlocaleText(
                    this.state.deletableCerts.certs.length > 1
                      ? "ssh.multiple.delete.warning.message1.text"
                      : "ssh.single.delete.warning.message1.text",
                  )}
                  cancelText={getCurrentlocaleText(
                    "inode.delete.confirmation.cancel.text",
                    {
                      0:
                        this.state.deletableCerts.certs.length > 1
                          ? "SSH Keys"
                          : "SSH Key",
                    },
                  )}
                  okText={
                    <span>
                      <Icons
                        type="ai"
                        name="AiOutlineDelete"
                        style={{ margin: 0 }}
                        className="iot-delete-icon"
                      />
                      {getCurrentlocaleText(
                        "inode.delete.confirmation.ok.text",
                        {
                          0:
                            this.state.deletableCerts.certs.length > 1
                              ? "SSH Keys"
                              : "SSH Key",
                        },
                      )}
                    </span>
                  }
                ></ActionBtn>
              </div>
            )}
          </div>
        </div>
        {this.certificateModel.sshKeys.length > 0 ? (
          <div>
            {
              <TableLayoutV1
                columns={this.columns}
                dataSource={sshKeys}
                pagination={{
                  pageSize:
                    this.certificateModel &&
                    this.certificateModel.pageable_ssh.size,
                  total:
                    this.certificateModel &&
                    this.certificateModel.pageable_ssh.total_count,
                  current: this.state.currentPage,
                }}
                getModalContent={record => {
                  return this.getModalContentJSX(record);
                }}
                selectedRows={this.handleTableChange}
                selectAll={this.onSelectAllChange}
                onChange={this.onTableChange}
                className="CertificateList"
                modalTitle={this.state.modalTitle}
                modalContent={this.state.modalContent}
                isTableModalToggle={isTableModalToggle}
                isModalLoading={false}
                disableFooter={true}
              />
            }
          </div>
        ) : (
          <div>
            {!this.certificateModel.loading && (
              <h3>{getCurrentlocaleText("certificate.list.empty.text")}</h3>
            )}
          </div>
        )}
      </div>
    );

    return (
      <LoadingComponent loading={this.certificateModel.loading}>
        {container}
      </LoadingComponent>
    );
  }
}

CertificateList.propTypes = {
  resource: propTypes.string,
  action: propTypes.string,
  orgId: propTypes.string,
  params: propTypes.object,
  map: propTypes.object,
  cache: propTypes.bool,
};

export default CertificateList;
