import React, { Component } from "react";
import { Tag, Popover } from "antd";
import Icons from "components/UI-Components/Icons";

class RemoteNetworkTag extends Component {
  constructor(props) {
    super(props);
    this.tagContainer;
    this.tagContent;
    this.state = {
      tagContainerWidth: 0,
      tagContentWidth: 0,
      data: null,
    };
  }

  UNSAFE_componentWillMount() {
    // Set data before component render
    this.setState({ data: this.props.data });
  }

  componentDidMount() {
    // Set container & content width after render
    this.setState({
      tagContainerWidth:
        this.tagContainer && this.tagContainer.offsetWidth
          ? this.tagContainer.offsetWidth
          : 0,
      tagContentWidth:
        this.tagContent && this.tagContent.offsetWidth
          ? this.tagContent.offsetWidth
          : 0,
    });
  }

  render() {
    let { tagContainerWidth, tagContentWidth, data } = this.state;
    let iconType = data && data.isCluster ? "bs" : "fa",
      iconName = data && data.isCluster ? "BsBoundingBox" : "FaRegHdd",
      networkIconType = "fa",
      networkIconName = "FaConnectdevelop",
      className = "valign-text-bottom iot-tag-icon-style";
    return (
      <div
        ref={c => (this.tagContainer = c)}
        style={{
          maxWidth: this.props.maxWidth ? this.props.maxWidth : "100%",
          display: "inline-block",
        }}
      >
        <span ref={c => (this.tagContent = c)}>
          {data && (
            <span>
              {tagContentWidth > tagContainerWidth ? (
                <Popover
                  content={
                    <Tag>
                      <Icons
                        type={iconType}
                        name={iconName}
                        className={className}
                      />{" "}
                      {data.node_name}&nbsp; /{" "}
                      <Icons
                        type={networkIconType}
                        name={networkIconName}
                        className={className}
                      />{" "}
                      {data.network_name}&nbsp;
                    </Tag>
                  }
                  trigger="hover"
                >
                  <Tag className="remote-network-tag">
                    <Icons
                      type={iconType}
                      name={iconName}
                      className={className}
                    />{" "}
                    {data.node_name}&nbsp; /{" "}
                    <Icons
                      type={networkIconType}
                      name={networkIconName}
                      className={className}
                    />{" "}
                    {data.network_name}&nbsp;
                  </Tag>
                </Popover>
              ) : (
                <Tag>
                  <Icons
                    type={iconType}
                    name={iconName}
                    className={className}
                  />{" "}
                  {data.node_name}&nbsp; /{" "}
                  <Icons
                    type={networkIconType}
                    name={networkIconName}
                    className={className}
                  />{" "}
                  {data.network_name}&nbsp;
                </Tag>
              )}
            </span>
          )}
        </span>
      </div>
    );
  }
}

export default RemoteNetworkTag;
