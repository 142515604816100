import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Link } from "react-router-dom";
import { Row, Col, Steps, Button, Card } from "antd";
import { Form } from "@ant-design/compatible";
import Icons from "components/UI-Components/Icons";
import BreadcrumbComponent from "components/UI-Components/BreadcrumbComponent";
import { getCurrentlocaleText } from "Core/Utils";
import UserApiKeyForm from "components/UserApiKeys/UserApiKeyForm";
import HelpCard from "components/UI-Components/HelpCard";
import ApiKeySuccessModal from "components/UserApiKeys/ApiKeySuccessModal";
import UserAPIKeyController from "controller/UserAPIKeyController";
import FileSaver from "file-saver";

const Step = Steps.Step;
const steps = [
  {
    title: getCurrentlocaleText("user.api_keys.Header.step1.text"),
  },
  {
    title: getCurrentlocaleText("user.api_keys.success.title"),
  },
];

@inject("UiStore", "ApiKeyViewModel")
@observer
class UserApiKeyFormInfo extends Component {
  constructor(props) {
    super(props);
    this.UiStore = this.props.UiStore;
    this.ApiKeyModel = this.props.ApiKeyViewModel;

    this.state = {
      loading: false,
      isSubmitting: false,
      current: 0,
      formValues: {},
      BreadcrumbList: [
        {
          name: getCurrentlocaleText("user.api_keys.leftmenu.text"),
          link: "keys",
        },
        {
          name: getCurrentlocaleText("user.api_keys.create.title"),
          link: "keys/add",
        },
      ],
    };
  }

  createApiKey = () => {
    // Here Values is final step form values
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let formValues = values;
        formValues.minutes_to_expire = this.state.formValues.minutes_to_expire;
        if (
          !formValues.minutes_to_expire ||
          formValues.minutes_to_expire === null
        ) {
          delete formValues.minutes_to_expire;
        }
        UserAPIKeyController.create(formValues)
          .then(res => {
            this.setState({ current: 1 });
          })
          .catch();
      }
    });
  };

  downloadKey = () => {
    let fileData =
      this.ApiKeyModel.keyInfo && this.ApiKeyModel.keyInfo.key
        ? this.ApiKeyModel.keyInfo.key
        : null;
    let fileName =
      this.ApiKeyModel.keyInfo && this.ApiKeyModel.keyInfo.name
        ? this.ApiKeyModel.keyInfo.name
        : null;
    let blob = new Blob([fileData], { type: "text/plain;charset=utf-8" });
    FileSaver.saveAs(blob, fileName);
  };

  setVal = (key, values) => {
    let data = {
      [key]: {
        value: values,
      },
    };
    this.props.form.setFields(data);
    let formValues = this.props.form.getFieldsValue();
    this.setState({ formValues: formValues });
  };

  render() {
    const { current } = this.state;

    return (
      <div>
        <div>
          {" "}
          <Row gutter={24} type="flex" align="middle">
            <Col span={24}>
              <h2 className="page-title">
                {
                  <span
                    style={{
                      fontSize: 10,
                      marginRight: 2,
                      display: "block",
                      marginBottom: -13,
                    }}
                  >
                    {"API"}
                  </span>
                }
                <Icons
                  type="md"
                  name="MdVpnKey"
                  style={{ fontSize: 20, marginRight: 8, marginLeft: -1 }}
                  className="valign-middle"
                />
                {"  "}
                {getCurrentlocaleText("user.api_keys.leftmenu.text")}
              </h2>
              <BreadcrumbComponent
                {...this.props}
                BreadcrumbList={this.state.BreadcrumbList}
              />
            </Col>
          </Row>
        </div>
        <div>
          <Row gutter={2} type="flex" align="top">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
              xl={{ span: 24 }}
            >
              <Card loading={this.state.loading}>
                {/*Steps*/}
                <Row gutter={2} type="flex" align="top">
                  <Col span={24}>
                    <Steps current={current}>
                      {steps.map(item => (
                        <Step key={item.title} title={item.title} />
                      ))}
                    </Steps>
                  </Col>
                </Row>
                <Row
                  gutter={2}
                  type="flex"
                  align="top"
                  style={{ marginTop: 25 }}
                >
                  <Col
                    xs={{ span: 12 }}
                    sm={{ span: 12 }}
                    md={{ span: 13 }}
                    lg={{ span: 13 }}
                    xl={{ span: 12 }}
                    className="card-grid"
                    style={{
                      paddingRight: 20,
                      borderRight: "solid 1px rgba(0,0,0,0.2)",
                    }}
                  >
                    <div>
                      {current === 0 && (
                        <Form layout="vertical" noValidate>
                          <UserApiKeyForm
                            SetExpiryTime={this.setVal}
                            formReference={this.props.form}
                          />
                        </Form>
                      )}
                      {current === 1 && (
                        <ApiKeySuccessModal
                          api_key={
                            this.ApiKeyModel.keyInfo &&
                            this.ApiKeyModel.keyInfo.key
                              ? this.ApiKeyModel.keyInfo.key
                              : null
                          }
                          key_name={
                            this.ApiKeyModel.keyInfo &&
                            this.ApiKeyModel.keyInfo.name
                              ? this.ApiKeyModel.keyInfo.name
                              : null
                          }
                        />
                      )}
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 12 }}
                    sm={{ span: 12 }}
                    md={{ span: 11 }}
                    lg={{ span: 11 }}
                    xl={{ span: 12 }}
                  >
                    <HelpCard
                      customStyle={{
                        borderLeft: "none",
                      }}
                      span={24}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24} className="col-center">
                    <div style={{ marginTop: 25 }}>
                      <Link to="/keys">
                        <Button
                          style={{ marginRight: 10 }}
                          disabled={this.state.isSubmitting}
                          title={
                            current === steps.length - 1
                              ? "Done"
                              : getCurrentlocaleText("cancel.button.text")
                          }
                        >
                          {current === steps.length - 1
                            ? "Done"
                            : getCurrentlocaleText("cancel.button.text")}
                        </Button>
                      </Link>
                      {current < steps.length - 1 && (
                        <Button
                          type="primary"
                          title={getCurrentlocaleText(
                            "user.api_keys.Header.step1.text",
                          )}
                          onClick={this.createApiKey}
                        >
                          {getCurrentlocaleText(
                            "user.api_keys.Header.step1.text",
                          )}
                        </Button>
                      )}
                      {current === steps.length - 1 && (
                        <Button
                          type="primary"
                          onClick={this.downloadKey}
                          title={getCurrentlocaleText(
                            "user.api_keys.download.button.text",
                          )}
                        >
                          <Icons
                            type="ai"
                            name="AiOutlineDownload"
                            style={{ margin: 0 }}
                          />{" "}
                          {getCurrentlocaleText(
                            "user.api_keys.download.button.text",
                          )}
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
const UserApiKeyContainer = Form.create()(UserApiKeyFormInfo);
export default UserApiKeyContainer;
