import React, { Component } from "react";
import { Tag, Tabs, Icon, Popover, Radio, Row, Col } from "antd";
import { Form } from "@ant-design/compatible";
import { observer, inject } from "mobx-react";
import _ from "lodash";
import { getFirewallProtocolAndPort, getCurrentlocaleText } from "Core/Utils";
import WanFirewallFunc from "components/Containers/WanFirewallFunc";
import LabelSelector from "components/UI-Components/LabelSelector";
import NetworkController from "controller/NetworkController";
import LabelProvider from "components/UI-Components/LabelProvider";
import TableLayout from "../layout/TableLayout";
import Icons from "../UI-Components/Icons";

const TabPane = Tabs.TabPane;

const FormItem = Form.Item;
const radioStyle = {
  display: "block",
  height: "30px",
  lineHeight: "30px",
};
const RadioGroup = Radio.Group;

@inject("FirewallGroupViewModel", "NetworkViewModel", "InodeViewModel")
@observer
class FirewallViewForm extends Component {
  constructor(props) {
    super(props);
    this.store = this.props.FirewallGroupViewModel;
    this.networkModel = this.props.NetworkViewModel;
    this.inodeModel = this.props.InodeViewModel;

    this.state = {
      firewallName: "",
      firewallData: [],
      InterfaceLabels: [],
      customLoading: false,
      expandedRow: [],
      defaultActiveKey: "2",
    };
  }

  componentDidMount() {
    this.firewallGroupId = this.props.id;
    this.getNetworkInterface();
    this.setState({
      firewallName: this.firewallGroupName(),
      firewallData: this.firewallGroupData(),
      firewallLabelName: this.firewallLabelName(),
    });
  }
  getSelectorValues = selectors => {
    let Selectedselectors = [];
    for (let key in selectors) {
      Selectedselectors.push(key + ":" + selectors[key]);
    }
    return Selectedselectors;
  };
  getNetworkInterface = (
    loading = true,
    page = 0,
    search = "",
    sortBy = null,
    sortOrder = null,
  ) => {
    let params = {
      org_id: this.props.currentFirewallOrgId
        ? this.props.currentFirewallOrgId
        : this.props.orgId,
      own: true,
      page: 0,
    };
    params.page = page;
    params.search = search;

    let interface_labels = [];
    // For sort
    if (sortBy && sortOrder) {
      params.sort = `${sortBy}:${sortOrder}`;
    }
    params.size = 1;
    this.setState({ customLoading: true });
    NetworkController.getNetworks(params, loading).then(res => {
      this.updateOrgsNetworkData(
        this.networkModel.pageable.total_count / 100,
        [],
      );
    });
  };

  updateOrgsNetworkData(totalCount, availableNetworks) {
    if (totalCount > 0) {
      let allParams = [];
      for (let page = 0; page < totalCount; page++) {
        allParams.push({
          org_id: this.props.currentFirewallOrgId
            ? this.props.currentFirewallOrgId
            : this.props.orgId
            ? this.props.orgId
            : this.props.match.params.id
            ? this.props.match.params.id
            : this.inodeModel.inode.organization
            ? this.inodeModel.inode.organization.id
            : "",
          own: true,
          size: 100,
          page: page,
        });
      }
      NetworkController.getAllNetworksForDropdown(allParams).then(resp => {
        if (resp) {
          availableNetworks = availableNetworks.concat(resp);
          let interface_labels = [];
          availableNetworks.forEach(l => {
            if (l.metadata && l.metadata.labels) {
              for (var key in l.metadata.labels) {
                interface_labels.push(key + ":" + l.metadata.labels[key]);
              }
            }
          });
          this.setState({
            InterfaceLabels: availableNetworks,
            customLoading: false,
          });
        }
      });
    }
  }

  firewallGroupData = () => {
    let data = [];
    let firewall = this.props.initialVal
      ? this.props.initialVal
      : this.store.firewallGroups.find(val => {
          return val.id === this.firewallGroupId;
        });
    if (firewall.is_default) {
      return data.concat(
        this.getFirewallRules(firewall.rules, "Inbound", true),
      );
    } else {
      return data.concat(
        this.getFirewallRules(firewall.rules, "Inbound", false),
      );
    }
  };
  getPortValue = val => {
    return val.source_port
      ? val.source_port.start === val.source_port && val.source_port.end
        ? val.source_port && val.source_port.start !== -1
          ? `${val.source_port && val.source_port.start}`
          : `1 - 65535`
        : val.source_port && val.source_port.start != val.source_port.end
        ? `${val.source_port && val.source_port.start} - ${val.source_port &&
            val.source_port.end}`
        : `${val.source_port.start}`
      : "1 - 65535";
  };
  getFirewallRules = (rules, type, is_default_firewall) => {
    let data = [];
    let directionkeys = [];
    if (rules) {
      rules.forEach((val, index) => {
        let obj = getFirewallProtocolAndPort(
          val.destination_port.start !== -1 && val.destination_port.end !== -1
            ? `${val.protocol.toLowerCase()}${val.destination_port.start}-${
                val.destination_port.end
              }`
            : `${val.protocol.toLowerCase()}`,
        );
        if (is_default_firewall) {
          let rule_data = {
            key: `${type}${index}`,
            id: `${type}${index}`,
            type: type,
            protocol: !_.isEmpty(obj.key)
              ? obj.applicationProtocol
              : val.protocol,
            port:
              val.destination_port.start === val.destination_port.end
                ? val.destination_port.start !== -1
                  ? `${val.destination_port.start}`
                  : `1 - 65535`
                : `${val.destination_port.start} - ${val.destination_port.end}`,
            source_port: this.getPortValue(val),
            source_ipaddress: val.source_ip,
            source_tag: val.source_tag,
            destination_ipaddress: val.destination_ip,
            destination_tag: val.destination_tag,
            priority: val.priority,
            source_network: val.source_network,
            destination_network: val.destination_network,
            selector:
              val.selector &&
              val.selector.length > 0 &&
              val.selector[0].match_label.label,
            action: val.action,
            direction: val.direction,
          };
          if (rule_data.direction) {
            directionkeys.push(rule_data.key);
          }
          data.push(rule_data);
        } else {
          // Filtering only not default generated rules
          if (!val.generated) {
            let rule_data = {
              key: `${type}${index}`,
              id: `${type}${index}`,
              type: type,
              protocol: !_.isEmpty(obj.key)
                ? obj.applicationProtocol
                : val.protocol,
              port:
                val.destination_port.start === val.destination_port.end
                  ? val.destination_port.start !== -1
                    ? `${val.destination_port.start}`
                    : `1 - 65535`
                  : `${val.destination_port.start} - ${val.destination_port.end}`,
              source_port: this.getPortValue(val),
              source_ipaddress: val.source_ip,
              destination_ipaddress: val.destination_ip,
              priority: val.priority,
              source_network: this.getSelectorValues(val.source_network),
              destination_network: this.getSelectorValues(
                val.destination_network,
              ),
              selector:
                val.selector &&
                val.selector.length > 0 &&
                val.selector[0].match_label.label,
              action: val.action,
              direction: val.direction,
            };
            if (rule_data.direction) {
              directionkeys.push(rule_data.key);
            }
            data.push(rule_data);
          }
        }
      });
    }
    //Update keys to expand row
    this.setState({ expandedRow: directionkeys });
    // priority sort in UI
    data.sort(function(a, b) {
      return a["priority"] - b["priority"];
    });
    return data;
  };

  firewallGroupName = () => {
    let firewall = this.props.initialVal
      ? this.props.initialVal
      : this.store.firewallGroups.find(val => {
          return val.id === this.firewallGroupId;
        });
    return firewall.name;
  };

  firewallLabelName = () => {
    let firewall = this.props.initialVal
      ? this.props.initialVal
      : this.store.firewallGroups.find(val => {
          return val.id === this.firewallGroupId;
        });
    return firewall;
  };
  getTagKeyValues = labels => {
    let tags = [];
    for (let key in labels) {
      if (!key.includes("_iotium")) tags.push(key + " : " + labels[key]);
    }
    return tags;
  };
  /**
   * Selector contructor
   */
  selectorConstructor = values => {
    let fw_selector_values = {};
    if (values) {
      values.forEach(value => {
        let data = value.split(":");
        fw_selector_values[data[0].trim()] = data[1].trim();
      });
    }
    return fw_selector_values;
  };
  computeInterfaceTag(tag) {
    let tagdata = this.selectorConstructor(tag);
    if (tagdata["_iotium.network.name"]) {
      for (let key in tagdata) {
        let string = [key] + " : " + tagdata[key];
        let filtered_key = [key];
        return string.length < 15 ? (
          <span>
            {" "}
            <Icon
              type="tag-o"
              className="iot-tag-label"
              style={{ marginRight: 5 }}
            />
            {tagdata["_iotium.network.name"]}
          </span>
        ) : (
          <Popover
            placement="top"
            content={
              <Tag>
                <Icon
                  type="tag-o"
                  className="iot-tag-label"
                  style={{ marginRight: 5 }}
                />
                {key.replace("_iotium.", " ") + " : " + tagdata[key]}
              </Tag>
            }
          >
            {" "}
            <span>
              <Icon
                type="tag-o"
                className="iot-tag-label"
                style={{ marginRight: 5 }}
              />
              <span className={"firewall-rules-tag-content"}>
                {(key.replace("_iotium.", " ") + " : " + tagdata[key]).slice(
                  0,
                  15,
                ) + "..."}
              </span>
            </span>{" "}
          </Popover>
        );
      }
    } else if (
      tagdata["_iotium.network.id"] ||
      tagdata["_iotium.cluster.wan.network.id"] ||
      tagdata["_iotium.cluster.tan.network.id"]
    ) {
      return WanFirewallFunc.getNetworkGroupInfo(
        tagdata["_iotium.network.id"]
          ? tagdata["_iotium.network.id"].trim()
          : tagdata["_iotium.cluster.tan.network.id"]
          ? tagdata["_iotium.cluster.tan.network.id"]
          : tagdata["_iotium.cluster.wan.network.id"].trim(),
        this.state.InterfaceLabels,
      );
    } else if (tag.length <= 0) {
      return WanFirewallFunc.checkforAnyString("*", this.state.InterfaceLabels);
    } else {
      for (let key in tagdata) {
        let string = [key] + " : " + tagdata[key];
        let filtered_key = [key];
        return string.length < 15 ? (
          <span>
            <Icon
              type="tag-o"
              className="iot-tag-label"
              style={{ marginRight: 5 }}
            />
            {key.replace("_iotium.", " ") + ":" + tagdata[key]}
          </span>
        ) : (
          <Popover
            placement="top"
            content={
              <Tag>
                <Icon
                  type="tag-o"
                  className="iot-tag-label"
                  style={{ marginRight: 5 }}
                />
                {key.replace("_iotium.", " ") + " : " + tagdata[key]}
              </Tag>
            }
          >
            {" "}
            <span>
              <Icon
                type="tag-o"
                className="iot-tag-label"
                style={{ marginRight: 5 }}
              />
              <span className={"firewall-rules-tag-content"}>
                {(key.replace("_iotium.", " ") + " : " + tagdata[key]).slice(
                  0,
                  15,
                ) + "..."}
              </span>
            </span>{" "}
          </Popover>
        );
      }
    }
  }

  /**
   *  Get more Info...
   */
  getMoreInfo = record => {
    return (
      <span
        onClick={() => {
          this.setState({ currentRowKey: record.key });
        }}
      >
        <Tabs tabPosition={"top"}>
          <TabPane
            tab={
              <span>
                {getCurrentlocaleText("networks.diode.tab.header.text")}
              </span>
            }
            key="1"
          >
            <Row type="flex" gutter={2}>
              <Col style={{ marginBottom: 25, marginTop: 20 }} span={24}>
                <RadioGroup disabled={true} value={record.direction}>
                  <Radio style={radioStyle} value={"FromServiceToDevice"}>
                    {getCurrentlocaleText(
                      "networks.southside.direction1.label.text",
                    )}
                  </Radio>
                  <Radio style={radioStyle} value={"FromDeviceToService"}>
                    {getCurrentlocaleText(
                      "networks.southside.direction2.label.text",
                    )}
                  </Radio>
                </RadioGroup>
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </span>
    );
  };
  onExpand = (expanded, record) => {
    if (record.key != null) {
      let keys = this.state.expandedRow;
      let index = keys.findIndex(val => {
        return val === record.key;
      });
      if (expanded) {
        if (index === -1) {
          keys.push(record.key);
        }
        this.setExpandedRowKeys(keys);
      } else {
        if (index !== -1) {
          keys.splice(index, 1);
        }
        this.setExpandedRowKeys(keys);
      }
    }
  };
  setExpandedRowKeys = keys => {
    this.setState({ expandedRow: keys, defaultActiveKey: "1" });
  };
  render() {
    this.columns = [
      {
        title: getCurrentlocaleText("general.firewall.from_network.label.text"),
        dataIndex: "source_network",
        width: "15%",
        render: (text, record, index) => {
          return (
            <span>
              {this.state.customLoading ? (
                <Icons type="ai" name="AiOutlineLoading" />
              ) : (
                this.computeInterfaceTag(record.source_network)
              )}
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText("general.firewall.to_network.label.text"),
        dataIndex: "destination_network",
        width: "15%",
        render: (text, record, index) => {
          return (
            <span>
              {this.state.customLoading ? (
                <Icons type="ai" name="AiOutlineLoading" />
              ) : (
                this.computeInterfaceTag(record.destination_network)
              )}
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText("firewall.list.table.header.priority.text"),
        dataIndex: "priority",
        render: (text, record, index) => {
          return <span>{record.priority}</span>;
        },
      },
      {
        title: getCurrentlocaleText("firewall.list.table.header.protocol.text"),
        dataIndex: "protocol",
        render: (text, record, index) => {
          return <span>{record.protocol}</span>;
        },
      },
      {
        title: (
          <span style={{ wordBreak: "break-word" }}>
            {getCurrentlocaleText(
              "firewall.list.table.header.source_port.text",
            )}
          </span>
        ),
        dataIndex: "source_port",
        width: "10%",
        render: (text, record, index) => {
          return (
            <span>{WanFirewallFunc.checkforAnyString(record.source_port)}</span>
          );
        },
      },
      {
        title: getCurrentlocaleText(
          "firewall.list.table.header.destination_port.text",
        ),
        dataIndex: "port",
        width: "10%",
        render: (text, record, index) => {
          return <span>{WanFirewallFunc.checkforAnyString(record.port)}</span>;
        },
      },
      {
        title: getCurrentlocaleText("firewall.list.table.header.source.text"),
        dataIndex: "src_ipaddress",
        render: (text, record, index) => {
          return (
            <span>
              {record.source_tag && record.source_tag.includes("@tag:") ? (
                <Tag>{record.source_tag.replace("@tag:", " ")}</Tag>
              ) : (
                WanFirewallFunc.checkforAnyString(record.source_ipaddress)
              )}
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText(
          "firewall.list.table.header.destination.text",
        ),
        dataIndex: "destination_ipaddress",
        render: (text, record, index) => {
          return (
            <span>
              {record.destination_tag &&
              record.destination_tag.includes("@tag:") ? (
                <Tag>{record.destination_tag.replace("@tag:", " ")}</Tag>
              ) : (
                WanFirewallFunc.checkforAnyString(record.destination_ipaddress)
              )}
            </span>
          );
        },
      },
      {
        title: "Action",
        dataIndex: "action",
        render: (text, record, index) => {
          return (
            <span>
              <Tag
                style={{ minWidth: 85, textAlign: "center" }}
                className={WanFirewallFunc.getTagColor(record.action)}
                key={index}
              >
                {record.action}
              </Tag>
            </span>
          );
        },
      },
    ];
    return (
      <Form noValidate>
        <FormItem
          label={getCurrentlocaleText(
            "firewall.security_policy_name.label.text",
          )}
          style={{ display: "inline-flex" }}
        >
          <span>{this.state.firewallName}</span>
        </FormItem>
        {this.state.firewallLabelName &&
          this.state.firewallLabelName.metadata &&
          this.state.firewallLabelName.metadata.labels && (
            <FormItem label="Label:">
              {this.getTagKeyValues(
                this.state.firewallLabelName &&
                  this.state.firewallLabelName.metadata &&
                  this.state.firewallLabelName.metadata.labels,
              ).length > 0 ? (
                <span>
                  {" "}
                  <LabelProvider
                    metadata={
                      this.state.firewallLabelName &&
                      this.state.firewallLabelName.metadata &&
                      this.state.firewallLabelName.metadata.labels
                    }
                    tags={this.getTagKeyValues(
                      this.state.firewallLabelName &&
                        this.state.firewallLabelName.metadata &&
                        this.state.firewallLabelName.metadata.labels,
                    )}
                    showPopupLabel={false}
                    viewOnly={true}
                    isModal={true}
                  />
                </span>
              ) : (
                <span>{"-"}</span>
              )}
            </FormItem>
          )}
        <FormItem
          label={
            <strong>
              {getCurrentlocaleText("security_policy.rules.labels.text")}
            </strong>
          }
        >
          <TableLayout
            columns={this.columns}
            pagination={false}
            dataSource={WanFirewallFunc.getfilteredData(
              this.state.firewallData,
            )}
            tableSize="small"
            scroll={{ y: 240 }}
            hideRowSelection={true}
            style={{ marginTop: 20 }}
            expandedRowKeys={this.state.expandedRow}
            onExpand={this.onExpand}
            expandedRowRender={record => {
              return record.direction ? this.getMoreInfo(record) : null;
            }}
          />
        </FormItem>
      </Form>
    );
  }
}

const FireWallViewForm = Form.create()(FirewallViewForm);
export default FireWallViewForm;
