import React, { Component } from "react";
import { Form, Switch } from "antd";
import { observer, inject } from "mobx-react";
import Input from "components/UI-Components/InputBox";
import {
  getCurrentlocaleText,
  focusFirstInput,
  computeDurationToMinutes,
} from "Core/Utils";
import DurationSelector from "components/UI-Components/DurationSelector";
import Icons from "../UI-Components/Icons";

const FormItem = Form.Item;

@inject("UiStore", "TimezoneViewModel")
@observer
class UserApiKeyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expiryMode: true,
      presetVal: {
        duration: 90,
        units: "DAYS",
      },
      formItem: {
        name: undefined,
        minutes_to_expire: computeDurationToMinutes({
          duration: 90,
          units: "DAYS",
        }),
      },
    };
  }

  componentDidMount() {
    /* Focus First Input */
    focusFirstInput();
    let defaultval = {
      duration: 90,
      units: "DAYS",
    };
    this.updateExpiryDate(defaultval);
  }

  /**
   * API KEY expiry Mode Change
   */
  onExpiryChange = value => {
    let defaultval = {
      duration: 90,
      units: "DAYS",
    };
    if (!value) {
      let data = {
        minutes_to_expire: {
          value: null,
        },
      };
      this.props.formReference.setFields(data);
      this.setState({
        presetVal: defaultval,
        expiryMode: value,
      });
      this.updateExpiryDate(null);
    } else {
      this.setState({
        expiryMode: value,
        presetVal: defaultval,
      });
      this.updateExpiryDate(defaultval);
    }
  };

  onChangePreset = value => {
    this.updateExpiryDate(value);
    this.setState({ presetVal: value });
  };

  updateExpiryDate = value => {
    if (value != null) {
      let formItem = this.state.formItem;
      formItem.minutes_to_expire = computeDurationToMinutes(value);
      this.props.SetExpiryTime("minutes_to_expire", formItem.minutes_to_expire);
      this.setState({ formItem: formItem });
    } else {
      this.props.SetExpiryTime("minutes_to_expire", null);
    }
  };

  updateHelp = data => {
    this.props.UiStore.SetStoreData("helpData", data);
    setTimeout(() => {
      let elm = document.querySelector(".help-container .help-content");
      if (elm) {
        elm.innerHTML =
          data && data.data && data.data[0] && data.data[0].content
            ? data.data[0].content
            : "";
      }
    }, 10);
  };

  render() {
    const { getFieldDecorator, getFieldError } = this.props.formReference;
    return (
      <div>
        <FormItem
          label={getCurrentlocaleText("user.api_keys.form.name.text")}
          hasFeedback
        >
          {getFieldDecorator("name", {
            rules: [
              {
                required: true,
                message: getCurrentlocaleText(
                  "user.api_keys.form.name.required.message",
                ),
                whitespace: true,
              },
              {
                max: 255,
                message: getCurrentlocaleText(
                  "user.api_keys.form.name.longer.message",
                ),
              },
              {
                pattern: /^[A-Za-z0-9 .&,_-]*$/,
                message: getCurrentlocaleText(
                  "user.api_keys.form.name.spl.message",
                ),
              },
            ],
            initialValue: this.props.initialVal
              ? this.props.initialVal.name
              : "",
          })(
            <Input
              className="firstInput"
              autoComplete="off"
              onFocus={this.updateHelp.bind(this, {
                title: getCurrentlocaleText("user.api_keys.form.name.text"),
                data: [
                  {
                    subtitle: "",
                    content: getCurrentlocaleText(
                      "user.api_keys.name.help.text",
                    ),
                  },
                ],
              })}
              prefix={<Icons type="ai" name="AiOutlineUser" />}
              placeholder={getCurrentlocaleText(
                "user.api_keys.name.placeholder.text",
              )}
            />,
          )}
        </FormItem>
        <FormItem
          label={getCurrentlocaleText("user.api_keys.key.validity.text")}
        >
          {getFieldDecorator(
            "minutes_to_expire",
            {},
          )(
            <span
              onClick={this.updateHelp.bind(this, {
                title: getCurrentlocaleText(
                  "user.api_keys.key.validity.help.title",
                ),
                data: [
                  {
                    subtitle: "",
                    content: getCurrentlocaleText(
                      "user.api_keys.key.validity.help.text",
                    ),
                  },
                ],
              })}
            >
              <Switch
                style={{ margin: "15px 0px" }}
                checkedChildren={getCurrentlocaleText(
                  "user.api_keys.key.validity.button.text1",
                )}
                unCheckedChildren={getCurrentlocaleText(
                  "user.api_keys.key.validity.button.text2",
                )}
                onChange={this.onExpiryChange}
                checked={this.state.expiryMode}
                disabled={this.props.action === "view" ? true : false}
              />
              <br />
              {this.state.expiryMode && (
                <span>
                  <DurationSelector
                    onChange={this.onChangePreset}
                    minutesDuration={60}
                    minutesMinimum={60}
                    minutesMaximum={60}
                    minutesStep={0}
                    hourDuration={1}
                    hourMinimum={1}
                    hourMaximum={23}
                    hourStep={1}
                    dayDuration={1}
                    dayMinimum={1}
                    dayMaximum={365}
                    dayStep={1}
                    disabled={this.props.action === "view" ? true : false}
                    Val={this.state.presetVal}
                  />
                </span>
              )}
            </span>,
          )}
        </FormItem>
      </div>
    );
  }
}
export default UserApiKeyForm;
