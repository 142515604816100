import React, { Component } from "react";
import { Button } from "antd";
import { Form } from "@ant-design/compatible";
import { observer, inject } from "mobx-react";
import {
  focusFirstInput,
  getCurrentlocaleText,
  passwordValidator,
} from "Core/Utils";
import PasswordInput from "components/UI-Components/PasswordInput";
import ReCAPTCHA from "react-google-recaptcha";
import Icons from "../UI-Components/Icons";

const FormItem = Form.Item;

@inject("UiStore")
@observer
class ResetPassword extends Component {
  constructor(props) {
    super(props);
    // ENV_CONFIG is generate form the node server dynamically
    this.EnvConfig = ENV_CONFIG;
    this.recaptchaRef = null;
  }

  componentDidMount() {
    /* Focus First Input */
    focusFirstInput();
    this.props.UiStore.setDocumentTitle(
      getCurrentlocaleText("user.reset_password_form.title"),
    );
  }

  ResetPassword = e => {
    e.preventDefault();
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      /* To avoid double click on submit */
      this.props.form.validateFields((err, values) => {
        if (!err) {
          // Remove confirm password field
          if (values && values.confirm_password) {
            delete values.confirm_password;
          }
          this.props.ResetPassword(values, false, this.recaptchaRef);
        }
      });
    }, 500);
  };

  validatePassword = (rule, value, callback) => {
    let validation = passwordValidator(value);
    if (validation) {
      return callback(validation);
    }
    // Check confirm password
    let new_password = this.props.form.getFieldValue("confirm_password");
    if (new_password) {
      this.checkConfirmNewPassword(new_password);
    }
    return callback();
  };

  checkConfirmNewPassword = new_password => {
    let obj = {};
    let confirm_password = this.props.form.getFieldValue("new_password");
    obj["confirm_password"] = { value: `${new_password}` };
    if (new_password !== confirm_password) {
      obj["confirm_password"].errors = [
        new Error(
          getCurrentlocaleText(
            "user.reset_password_form.confirm_password_error",
          ),
        ),
      ];
    }
    this.props.form.setFields(obj);
  };

  UNSAFE_componentWillReceiveProps(newprops) {
    if (newprops.autoFocus && this.props.autoFocus != newprops.autoFocus) {
      focusFirstInput();
    }
  }

  checkPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue("new_password")) {
      callback(
        getCurrentlocaleText("user.reset_password_form.confirm_password_error"),
      );
    } else {
      callback();
    }
  };

  handleCaptchaChange = value => {
    this.props.form.setFields({
      captcha: {
        value: value,
      },
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div style={{ margin: 15 }}>
        <Form noValidate onSubmit={this.ResetPassword}>
          <FormItem>
            {getFieldDecorator("new_password", {
              rules: [
                {
                  required: true,
                  message: getCurrentlocaleText(
                    "user.reset_password_form.new_password_error",
                  ),
                },
                { validator: this.validatePassword },
              ],
            })(
              <PasswordInput
                prefix={
                  <Icons
                    type="ai"
                    name="AiOutlineLock"
                    style={{ fontSize: 13 }}
                  />
                }
                type="password"
                placeholder={getCurrentlocaleText(
                  "user.reset_password_form.new_password_placeholder",
                )}
                onChange={e => {}}
                className="firstInput"
              />,
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator("confirm_password", {
              rules: [
                {
                  required: true,
                  message: getCurrentlocaleText(
                    "user.reset_password_form.confirm_password_required_error",
                  ),
                },
                { validator: this.checkPassword },
              ],
            })(
              <PasswordInput
                prefix={
                  <Icons
                    type="ai"
                    name="AiOutlineLock"
                    style={{ fontSize: 13 }}
                  />
                }
                type="password"
                placeholder={getCurrentlocaleText(
                  "user.reset_password_form.confirm_password_placeholder",
                )}
                onChange={e => {}}
              />,
            )}
          </FormItem>
          <FormItem>
            {" "}
            {getFieldDecorator("captcha", {
              rules: [
                {
                  required: true,
                  message: getCurrentlocaleText("captcha.error.message"),
                },
              ],
            })(
              <ReCAPTCHA
                tabindex={2}
                ref={c => (this.recaptchaRef = c)}
                sitekey={
                  this.EnvConfig && this.EnvConfig.RECAPTCHA_CLIENT_SITE_KEY
                }
                onChange={this.handleCaptchaChange}
              />,
            )}
          </FormItem>
          <FormItem>
            <Button type="primary" htmlType="submit" className="login-btn">
              {getCurrentlocaleText("user.reset_password_form.title")}
            </Button>
          </FormItem>
        </Form>
      </div>
    );
  }
}

const ResetPasswordForm = Form.create()(ResetPassword);
export default ResetPasswordForm;
