import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import Input from "components/UI-Components/InputBox";
/*------------------N/W CIDR ----------------------*/
import * as ip from "ip";
import { getCurrentlocaleText, copyObject } from "Core/Utils";
import { inject } from "mobx-react";
import { computed } from "mobx";
import Twig from "twig";

/*Define form elements*/
const FormItem = Form.Item;

@inject("UiStore")
export default class ipAddress extends Component {
  constructor(props) {
    super(props);
  }

  checkIpV4 = (rule, value, callback, field) => {
    let failMessage = null;

    if (value && ip.isV4Format(value) !== true) {
      failMessage = getCurrentlocaleText(
        "network.form.startip.errormessage1.text",
      );
    }

    if (failMessage) {
      callback(failMessage);
    }
    callback();
  };

  componentDidMount() {
    if (this.props.updateCurrentValueOnChange) {
      this.props.updateCurrentValueOnChange(
        this.props.properties.defaultValue &&
          this.props.properties.defaultValue.value
          ? this.props.properties.defaultValue.value
          : typeof this.props.properties.defaultValue === "string" &&
            this.props.properties.defaultValue
          ? this.props.properties.defaultValue
          : "",
        "value",
      );
    }
  }

  inputChange = e => {};

  @computed
  get componentVisibility() {
    let visibility = null;
    if (
      this.props.UiStore.computedOutputObject &&
      this.props.UiStore.computedOutputObject.steps
    ) {
      if (this.props.properties.visibility) {
        var template = Twig.twig({
          data: this.props.properties.visibility,
        });
        let computedOutputObject = copyObject(
          this.props.UiStore.computedOutputObject,
        );
        visibility = template.render(computedOutputObject);
        try {
          visibility = JSON.parse(visibility);
        } catch {}
      }
    }
    return visibility ? true : false;
  }

  render() {
    const { getFieldDecorator } = this.props.referenceForm; // reference of base form
    let required =
        this.props.properties.constraints &&
        this.props.properties.constraints.required
          ? this.props.properties.constraints.required
          : false,
      validationMessage =
        this.props.properties.constraints &&
        this.props.properties.constraints.validationMessage
          ? this.props.properties.constraints.validationMessage
          : `${this.props.properties.label} is required`;

    let id = this.props.id
      ? this.props.id
      : `textbox${randomAlphaNumericString(3)}`;

    let disable = false;
    if (this.props.editmode) {
      disable = this.props.properties.noneditable
        ? this.props.properties.noneditable
        : false;
    }
    disable = this.props.properties.disabled
      ? this.props.properties.disabled
      : disable;

    // For View only
    disable = this.props.viewOnly === true ? true : disable;

    let visibility = this.props.properties.visibility
      ? this.componentVisibility
      : true;
    return visibility ? (
      <div
        onClick={
          this.props.onFocus &&
          this.props.onFocus.bind(null, this.props.properties.help)
        }
      >
        <FormItem label={this.props.properties.label} hasFeedback>
          {getFieldDecorator(id, {
            rules: [
              {
                required: required,
                message: ` ${getCurrentlocaleText(
                  "network.form.startip.errormessage1.text",
                )}`,
              },
              {
                validator: this.checkIpV4,
              },
            ],
            initialValue:
              this.props.properties.defaultValue &&
              this.props.properties.defaultValue.value
                ? this.props.properties.defaultValue.value
                : typeof this.props.properties.defaultValue === "string" &&
                  this.props.properties.defaultValue
                ? this.props.properties.defaultValue
                : "",
          })(
            <Input
              placeholder={this.props.properties.placeholder}
              autoComplete="off"
              disabled={disable}
              onFocus={
                this.props.onFocus &&
                this.props.onFocus.bind(null, this.props.properties.help)
              }
              onChange={e => {
                this.props.properties.inputChange
                  ? this.props.properties.inputChange(e.target.value)
                  : this.inputChange;
                this.props.updateCurrentValueOnChange(e.target.value, "value");
              }}
            />,
          )}
        </FormItem>
      </div>
    ) : (
      <div></div>
    );
  }
}

/*
  sample schema

{
  "type": "ipAddress",
  "visible": true,
  "id": "12",
  "props": {
    "label": "Enable Secure Shell",
    "defaultValue": "10.10 .10 .1",
    "placeholder": "placeholder",
    "help": "<div>help for ip box</div>",
    "disabled": false,
    "noneditable" : true,
    "constraints": {
      "required": false,
      "validationMessage": " validate me"
    }
  }
}


*/
