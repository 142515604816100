import React, { Component } from "react";
import { Select } from "antd";
import { customSort, getCurrentlocaleText } from "Core/Utils";
import ProfileController from "controller/ProfileController";
import Icons from "./Icons";

const Option = Select.Option;

class ProfileSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profiles: [],
    };
  }

  componentDidMount() {
    let params = this.props.params || {};
    ProfileController.getProfiles(params, false, true).then(resp => {
      this.setState({ profiles: resp ? resp : [] });
    });
  }

  renderList() {
    if (this.state.profiles.length > 0) {
      return this.filterEdgeRemoteAccess(this.state.profiles)
        .sort((x, y) => customSort(x.name, y.name))
        .map((item, i) => {
          return (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          );
        });
    }
  }

  //filter edge remote access
  filterEdgeRemoteAccess(profiles) {
    let profileData = [];
    profiles.forEach(function(value, key) {
      if (
        value.name &&
        value.name.toLowerCase().trim() !== "edge remote access"
      ) {
        profileData.push(value);
      }
    });
    return profileData;
  }

  setValue = value => {
    let vpnServer = false;
    let profiles = [];
    let profileName = null;
    this.state.profiles.map(profile => {
      if (
        value === profile.id &&
        profile.config.vpnServer &&
        profile.config.pki.hsn != "PREDEFINED"
      ) {
        vpnServer = true;
      }
      if (
        value === profile.id &&
        profile.config.pki.hsn === "GENERATED" &&
        !profile.config.vpnServer
      ) {
        vpnServer = true;
      }
      if (value === profile.id) {
        profileName = profile.name;
      }
    });
    this.props.setSelectVal({
      vpnServer,
      value,
      type: "profile_id",
      profileName,
    });
  };

  render() {
    return (
      <Select
        onChange={this.setValue}
        notFoundContent={getCurrentlocaleText(
          "general.notfound.placeholder.text",
        )}
        placeholder={getCurrentlocaleText("profile.select.placeholder.text")}
        prefix={
          <Icons type="ai" name="AiOutlineIdcard" style={{ fontSize: 13 }} />
        }
      >
        {this.renderList()}
      </Select>
    );
  }
}

export default ProfileSelect;
