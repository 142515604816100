import React, { Component } from "react";
import { Result, Button } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import DarkLogo from "components/UI-Components/DarkLogo";
import DarkAppName from "components/UI-Components/DarkAppName";
import { getCurrentlocaleText } from "Core/Utils";

class NotFoundResult extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Result
        title={
          <div>
            <div>
              <strong>{getCurrentlocaleText("pagenotfound.title")}</strong>
            </div>
            <div style={{ fontSize: 14, opacity: 0.7 }}>
              <strong>{getCurrentlocaleText("pagenotfound.message")}</strong>
            </div>
          </div>
        }
        icon={
          <div>
            <DarkLogo />
            <DarkAppName />
          </div>
        }
        extra={
          <Button type="primary" href="/home" icon={<HomeOutlined />}>
            <strong> {getCurrentlocaleText("general.home.text")}</strong>
          </Button>
        }
      />
    );
  }
}

export default NotFoundResult;
