import React, { Component } from "react";
import {
  Row,
  Col,
  Select,
  Button,
  Table,
  Menu,
  Tag,
  Popover,
  InputNumber,
  Cascader,
  Card,
  Tabs,
  Radio,
  Switch,
  Collapse,
} from "antd";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import Input from "components/UI-Components/InputBox";
import {
  focusFirstInput,
  isCidr,
  getFirewallProtocolAndPort,
  customSort,
  getCurrentlocaleText,
  isEmpty,
  getOrgPolicyCounInfo,
} from "Core/Utils";
import * as ip from "ip";
import _ from "lodash";

import LabelProvider from "components/UI-Components/LabelProvider";
import PopOverHelp from "components/UI-Components/PopOverHelp";

import FirewallGroupController from "controller/FirewallGroupController";
import NetworkController from "controller/NetworkController";
import FirewallContainer from "components/Containers/WanFirewallFunc";
import Icons from "components/UI-Components/Icons";
import GenerateLabelHelp from "components/UI-Components/GenerateLabelHelp";
import { observer, inject } from "mobx-react";
import NetworkContainer from "components/Containers/NetworkContainer";

const FormItem = Form.Item;
const Option = Select.Option;
const formItemLayout2 = {
  labelCol: { span: 0 },
  wrapperCol: { span: 18 },
};
const radioStyle = {
  display: "block",
  height: "30px",
  lineHeight: "30px",
};
const TabPane = Tabs.TabPane;
const RadioGroup = Radio.Group;
const Panel = Collapse.Panel;
@inject("OrgViewModel", "UiStore", "NetworkViewModel", "InodeViewModel")
@observer
class FirewallForm extends Component {
  constructor(props) {
    super(props);
    this.orgModel = this.props.OrgViewModel;
    this.networkModel = this.props.NetworkViewModel;
    this.inodeModel = this.props.InodeViewModel;

    this.currentTime = new Date().getTime();
    this.state = {
      firewall: {
        name: "",
        rules: [
          {
            key: this.currentTime.toString(),
            type: null,
            protocol: null,
            source_port: null,
            destination_port: null,
            src_ipaddress: null,
            source_network: [],
            destination_network: [],
            destination_ipaddress: null,
            isdeleted: false,
            from_source_to_device: false,
            from_device_to_service: false,
            expandedRowKeys: [],
          },
        ],
      },
      count: 1, // Firewall rule count,
      tags: [],
      metadata: {},
      disabledRowsPort: [],
      NetworkInterfaceOptions: [],
      InterfaceLabels: [],
      InterfaceOptions: [],
      UserdefinedInterfaces: [],
      currentRowKey: null,
      customizedTags: [],
      customLoading: false,
      DirectionSpecifiedRules: [],
      disabledRowsSourcePort: [],
      initialName: "",
      directions: [],
    };
  }

  componentDidMount() {
    focusFirstInput();
    this.getNetworkInterface();
    if (this.props.initialVal) {
      let firewall = this.props.initialVal;
      let rules = [...this.getFirewallRules(firewall.rules)];
      let DirectionSpecifiedRules = FirewallContainer.getAllSpecifiedRules(
        firewall.rules,
      );
      this.setState({
        initialName: firewall.name,
        firewall: {
          name: firewall.name,
          rules: rules,
        },
        DirectionSpecifiedRules: DirectionSpecifiedRules,
      });
      this.setState({ count: rules.length });
      if (firewall.metadata && firewall.metadata.labels) {
        this.props.form.setFieldsValue({
          ["metadata"]: firewall.metadata,
        });
        this.setState({ metadata: firewall.metadata.labels });
        this.setState({ tags: this.getTagKeyValues(firewall.metadata.labels) });
      }
    }
  }
  updateFocus = key => {
    if (document.querySelector(key)) {
      document.querySelector(key).focus();
    }
  };

  getNetworkInterface = (
    loading = true,
    page = 0,
    search = "",
    sortBy = null,
    sortOrder = null,
  ) => {
    let params = { org_id: this.props.orgId, own: true, size: 1, page: 0 };
    params.page = page;
    params.search = search;

    let interface_labels = [];
    // For sort
    if (sortBy && sortOrder) {
      params.sort = `${sortBy}:${sortOrder}`;
    }
    this.setState({ customLoading: true });

    NetworkController.getNetworks(params, loading).then(res => {
      if (this.networkModel.pageable.total_count != 0) {
        this.updateOrgsNetworkData(
          this.networkModel.pageable.total_count / 100,
          [],
        );
      } else {
        this.setState({
          customLoading: false,
          InterfaceLabels: [],
          NetworkInterfaceOptions: [],
          InterfaceOptions: FirewallContainer.computeCascaderInterfaceOptions(
            [],
          ),
        });
      }
    });
  };

  updateOrgsNetworkData(totalCount, availableNetworks) {
    if (totalCount > 0) {
      let allParams = [];
      for (let page = 0; page < totalCount; page++) {
        allParams.push({
          org_id: this.props.orgId
            ? this.props.orgId
            : this.props.match.params.id
            ? this.props.match.params.id
            : this.inodeModel.inode.organization
            ? this.inodeModel.inode.organization.id
            : "",
          own: true,
          size: 100,
          page: page,
        });
      }
      NetworkController.getAllNetworksForDropdown(allParams).then(resp => {
        if (resp) {
          let interface_labels = [];
          availableNetworks = availableNetworks.concat(resp);
          availableNetworks.forEach(l => {
            if (l.metadata && l.metadata.labels) {
              for (var key in l.metadata.labels) {
                interface_labels.push(key + ":" + l.metadata.labels[key]);
              }
            }
          });
          let options = FirewallContainer.computeCascaderInterfaceOptions(
            availableNetworks,
          );
          this.setState({
            InterfaceLabels: availableNetworks,
            NetworkInterfaceOptions: interface_labels,
            InterfaceOptions: options,
            customLoading: false,
          });
        }
      });
    }
  }
  getTagKeyValues = labels => {
    let tags = [];
    if (labels && labels["_iotium.firewall.name"]) {
      delete labels["_iotium.firewall.name"];
    }
    for (let key in labels) {
      tags.push(key + " : " + labels[key]);
    }
    return tags;
  };

  getFirewallRules = (rules, type, startIndex) => {
    let data = [];
    let directions = [];
    let directionkeys = [];
    if (rules && rules.length > 0) {
      rules.forEach((val, index) => {
        // Filtering only not default generated rules
        if (!val.generated) {
          let obj = getFirewallProtocolAndPort(
            val.destination_port.start !== -1 && val.destination_port.end !== -1
              ? `${val.protocol.toLowerCase()}${val.destination_port.start}-${
                  val.destination_port.end
                }`
              : `${val.protocol.toLowerCase()}`,
          );

          let rule_data = {
            key: `${type}${index}`,
            id: `${type}${index}`,
            type: type,
            protocol: !_.isEmpty(obj.key)
              ? obj.key
              : val.protocol.toLowerCase(),
            destination_port:
              val.destination_port.start === val.destination_port.end
                ? val.destination_port.start !== -1
                  ? `${val.destination_port.start}`
                  : `1-65535`
                : `${val.destination_port.start}-${val.destination_port.end}`,
            source_port:
              val.source_port.start === val.source_port.end
                ? val.source_port.start !== -1
                  ? `${val.source_port.start}`
                  : `1-65535`
                : `${val.source_port.start}-${val.source_port.end}`,
            src_ipaddress: val.source_ip,
            destination_ipaddress: val.destination_ip,
            priority: val.priority,
            source_network: this.getTagKeyValues(val.source_network),
            destination_network: this.getTagKeyValues(val.destination_network),
            action: val.action,
            isdeleted: false,
            direction: val.direction,
          };
          if (rule_data.direction) {
            directionkeys.push(rule_data.key);
          }

          let rule_protocol = !_.isEmpty(obj.key)
            ? obj.key
            : val.protocol.toLowerCase();
          if (rule_protocol === "tcp22-22") {
            // disabling destination port on edit
            let disabledRowsPort = this.state.disabledRowsPort;
            disabledRowsPort.push(`${type}${index}`);
            this.setState({ disabledRowsPort: disabledRowsPort });
          }
          let direction = {};
          direction.key = rule_data.key;
          direction.direction = rule_data.direction;
          directions.push(direction);
          data.push(rule_data);
        }
      });
    }

    this.setState({ expandedRowKeys: directionkeys });
    // priority sort in UI
    data.sort(function(a, b) {
      return a["priority"] - b["priority"];
    });
    this.setData(directions);
    return data;
  };
  setData = value => {
    setTimeout(() => {
      this.setState({ directions: value });
      this.props.setDirections(value);
    }, 500);
  };

  /**
   * Selector contructor
   */
  selectorConstructor = values => {
    let fw_selector_values = {};
    if (values) {
      values.forEach(value => {
        let data = value.split(":");
        fw_selector_values[data[0].trim()] = data[1].trim();
      });
    }
    return fw_selector_values;
  };

  UpdateCurrentKeyValues = value => {
    let DirectionSpecifiedRules = this.state.DirectionSpecifiedRules;
    value = value.replace("from_network-", "").replace("to_network-", "");
    if (this.state.DirectionSpecifiedRules.indexOf(value) === -1) {
      DirectionSpecifiedRules.push(value);
    }
    this.setState({ DirectionSpecifiedRules: DirectionSpecifiedRules });
  };

  checkIsClusterNetworkIsWan = networkId => {
    let availableNetworks =
      this.props.NetworkViewModel && this.props.NetworkViewModel.networks
        ? this.props.NetworkViewModel.networks
        : [];
    let network = availableNetworks.find(n => n.id === networkId);
    if (network && network.is_wan_network) {
      return true;
    } else {
      return false;
    }
  };

  onChangeInterface = (value, selectedOptions, key) => {
    if (
      value &&
      value.length > 0 &&
      selectedOptions &&
      selectedOptions.length > 0
    ) {
      // update current row key values
      this.UpdateCurrentKeyValues(this.state.currentRowKey);
      let UserdefinedInterfaces = this.state.UserdefinedInterfaces;
      if (value[0] === "#newlabel") {
        UserdefinedInterfaces.push(this.state.currentRowKey);
      } else {
        if (UserdefinedInterfaces.includes(this.state.currentRowKey))
          UserdefinedInterfaces.splice(
            UserdefinedInterfaces.indexOf(this.state.currentRowKey),
            1,
          );
        let selector = {};
        if (value[0] === "use_existing_network") {
          if (value[3]) {
            let networkId = value[3];
            selector = { "_iotium.network.id": networkId };
          }
        } else if (value[0] === "use_existing_label") {
          let data = value[1].split(":");
          selector[data[0].trim()] = data[1].trim();
        } else if (value[0] === "use_existing_cluster") {
          if (value[2]) {
            if (value[3]) {
              let cnetworkId = value[3];
              if (this.checkIsClusterNetworkIsWan(cnetworkId)) {
                selector = { "_iotium.cluster.wan.network.id": cnetworkId };
              } else {
                selector = { "_iotium.cluster.tan.network.id": cnetworkId };
              }
            }
          }
        }
        if (!isEmpty(selector)) {
          this.props.form.setFieldsValue({
            [this.state.currentRowKey]: selector,
          });
          this.setState({ UserdefinedInterfaces: UserdefinedInterfaces });
        }
      }
    } else {
      setTimeout(() => {
        this.props.form.resetFields([key]);
      }, 100);
    }
    let ct = this.state.currentRowKey.split("-")[1];
    let directionKey = `direction-${ct}`,
      newDirections = [],
      keyExist = false,
      directionCurrent = {};
    if (this.state.directions) {
      this.state.directions.forEach(direction => {
        if (direction.key === ct) {
          keyExist = true;
          directionCurrent = direction;
        } else {
          newDirections.push(direction);
        }
      });
    }

    if (this.props.form.getFieldValue(directionKey) || keyExist === true) {
      this.props.form.setFieldsValue({
        [directionKey]: undefined,
      });

      directionCurrent.direction = undefined;
      newDirections.push(directionCurrent);
      this.setData(newDirections);
    }
    if (
      !(!this.props.form.getFieldValue(`from_network-${ct}`) ||
      !this.props.form.getFieldValue(`to_network-${ct}`) ||
      !this.areSourceDestinationEqual(
        this.props.form.getFieldValue(`from_network-${ct}`),
        this.props.form.getFieldValue(`to_network-${ct}`),
      ) ||
      this.props.form.getFieldValue(`from_network-${ct}`).length === 0 ||
      this.props.form.getFieldValue(`to_network-${ct}`).length === 0
        ? true
        : false)
    ) {
      let record = {};
      record.key = ct;
      this.onExpand(true, record);
    }
  };

  removeCustomInput = element => {
    this.props.form.resetFields([{ names: [element] }]);
    let UserdefinedInterfaces = this.state.UserdefinedInterfaces,
      currentRowKey = this.state.currentRowKey;
    UserdefinedInterfaces.splice(UserdefinedInterfaces.indexOf(element), 1);
    this.setState({ UserdefinedInterfaces: UserdefinedInterfaces });
    this.props.form.setFields({
      [element]: {
        value: undefined,
      },
    });
  };
  removeCurrentTag = element => {
    let OtherField = FirewallContainer.getRowCurrentKey(element);
    let rowKey = FirewallContainer.filterCurrentKey(element);
    let OtherFieldValue = this.props.form.getFieldValue(OtherField + rowKey);
    let DirectionSpecifiedRules = this.state.DirectionSpecifiedRules;
    this.removeDirectionField(
      OtherField,
      rowKey,
      OtherFieldValue,
      DirectionSpecifiedRules,
    );
    let currentTags = this.state.customizedTags;
    currentTags.push(element);
    this.setState({ customizedTags: currentTags });
    this.props.form.setFields({
      [element]: {
        value: undefined,
      },
    });
    let directionKey = `direction-${rowKey}`,
      newDirections = [],
      keyExist = false,
      directionCurrent = {};
    if (this.state.directions) {
      this.state.directions.forEach(direction => {
        if (direction.key === rowKey) {
          keyExist = true;
          directionCurrent = direction;
        } else {
          newDirections.push(direction);
        }
      });
    }

    if (this.props.form.getFieldValue(directionKey) || keyExist === true) {
      this.props.form.setFieldsValue({
        [directionKey]: undefined,
      });
      let directionkeys = this.state.expandedRowKeys
        ? this.state.expandedRowKeys
        : [];
      directionkeys.pop(rowKey);
      this.setState({ expandedRowKeys: directionkeys });
      directionCurrent.direction = undefined;
      newDirections.push(directionCurrent);
      this.setData(newDirections);
    }
  };
  removeDirectionField = (
    OtherField,
    rowKey,
    OtherFieldValue,
    DirectionSpecifiedRules,
  ) => {
    this.setState({
      DirectionSpecifiedRules: FirewallContainer.getDirectionSpecificRules(
        OtherFieldValue,
        DirectionSpecifiedRules,
        rowKey,
      ),
    });
  };
  addItem = () => {
    let currentTime = new Date().getTime();
    let newItem = {
      key: currentTime.toString(),
      type: null,
      protocol: null,
      source_port: null,
      source_network: [],
      destination_port: null,
      destination_network: [],
      src_ipaddress: null,
      destination_ipaddress: null,
      isdeleted: false,
      islast: true,
    };
    let rules = this.state.firewall.rules;
    rules.push(newItem);
    this.setState({
      firewall: {
        name: this.state.firewall.name,
        rules: rules,
      },
    });
    this.setState({ count: this.getFirewallRulesCount() });
    // this timeout event is to make the last added table row  into view
    setTimeout(() => {
      if (document.querySelector(".destination_port-" + currentTime))
        document
          .querySelector(".destination_port-" + currentTime)
          .scrollIntoView();
    }, 300);
  };

  removeItem = record => {
    let rules = this.state.firewall.rules;
    this.removeCustomInput(`to_network-${record.key}`);
    this.removeCustomInput(`from_network-${record.key}`);
    let row_index = rules
      .filter(val => {
        return val.isdeleted === false;
      })
      .findIndex(val => {
        return val.key === record.key;
      });
    let index = rules.findIndex(val => {
      return val.key === record.key;
    });
    rules.splice(index, 1);

    /* LAT-5282 */
    let x = this.props.form.getFieldsError();
    Object.keys(x).forEach(key => x[key] === undefined && delete x[key]);
    /* fetch all columns with error values and reset to initial value */
    let errorfields = Object.keys(x);
    errorfields.length > 0 && this.props.form.resetFields(errorfields);
    this.setState({
      firewall: {
        name: this.state.firewall.name,
        rules: rules,
      },
    });
    this.setState({ count: this.getFirewallRulesCount() });
  };

  firewallNameChange = e => {
    this.setState({
      firewall: {
        name: e.target.value,
        rules: this.state.firewall.rules,
      },
    });
    if (this.props.initialVal && this.state.initialName != e.target.value) {
      this.props.UiStore.isFirewallNameChanged = true;
      this.props.UiStore.FirewallNameOld = this.state.initialName;
      this.props.UiStore.FirewallNameNew = e.target.value;
    } else {
      this.props.UiStore.isFirewallNameChanged = false;
      this.props.UiStore.FirewallNameOld = "";
      this.props.UiStore.FirewallNameNew = "";
    }
  };

  typeOnSelect = (value, option) => {
    this.setValue(option.props.rowId, "type", value);
  };

  protocolOnSelect = (value, option) => {
    this.setValue(option.props.rowId, "protocol", value);
    let port_obj = getFirewallProtocolAndPort(value);
    let port_val = null,
      disabledRowsPort = this.state.disabledRowsPort,
      disabledRowsSourcePort = this.state.disabledRowsSourcePort;
    if (!_.isEmpty(port_obj.startPort) && !_.isEmpty(port_obj.endPort)) {
      if (port_obj.startPort === port_obj.endPort) {
        port_val = port_obj.startPort;
      } else {
        port_val = `${port_obj.startPort}-${port_obj.endPort}`;
      }
    }
    let port = `destination_port-${option.props.rowId}`;
    let obj = {};
    obj[port] = { value: port_val };
    this.props.form.setFields(obj);
    if (value === "tcp22-22" || value === "icmp" || value === "any") {
      if (!disabledRowsPort.includes(option.props.rowId))
        disabledRowsPort.push(option.props.rowId);
      else {
        disabledRowsSourcePort = this.state.disabledRowsSourcePort.filter(
          function(data) {
            return data != option.props.rowId;
          },
        );
      }
      if (value === "icmp" || value === "any") {
        if (!disabledRowsSourcePort.includes(option.props.rowId))
          disabledRowsSourcePort.push(option.props.rowId);
      }
    } else {
      disabledRowsPort = this.state.disabledRowsPort.filter(function(data) {
        return data != option.props.rowId;
      });
      disabledRowsSourcePort = this.state.disabledRowsSourcePort.filter(
        function(data) {
          return data != option.props.rowId;
        },
      );
    }
    this.setState({
      disabledRowsPort: disabledRowsPort,
      disabledRowsSourcePort: disabledRowsSourcePort,
    });
    this.setValue(option.props.rowId, "destination_port", port_val);
  };

  portOnChange = (record, e) => {
    // this check needed to avoid removeItem() error is unclear
    if (!this.props.form.getFieldError(`destination_port-${record.key}`)) {
      this.setValue(record.key, "destination_port", e.target.value);
    }
  };
  SourceportOnChange = (record, e) => {
    // this check needed to avoid removeItem() error is unclear
    if (!this.props.form.getFieldError(`source_port-${record.key}`)) {
      this.setValue(record.key, "source_port", e.target.value);
    }
  };
  updateDirection = (record, e) => {
    // this check needed to avoid removeItem() error is unclear
    if (!this.props.form.getFieldError(`direction-${record.key}`)) {
      this.setValue(record.key, "direction", e.target.value);
    }
  };
  ipAddressOnChange = (record, e, type) => {
    if (e.target) {
      if (type === "src")
        this.setValue(record.key, "src_ipaddress", e.target.value);
      else this.setValue(record.key, "destination_ipaddress", e.target.value);
    }
  };

  setValue = (key, property, value) => {
    let rules = this.state.firewall.rules;
    let index = this.state.firewall.rules.findIndex(val => {
      return val.key === key;
    });
    rules[index][property] = value;
    this.setState({
      firewall: {
        name: this.state.firewall.name,
        rules: rules,
      },
    });
  };

  getFirewallRulesCount = () => {
    return this.state.firewall.rules.filter(val => {
      return val.isdeleted === false;
    }).length;
  };

  checkIpV4 = (rule, value, callback) => {
    let message;
    if (!value) {
      message = undefined;
    } else {
      let value_arr = value.split("/");
      if (value_arr.length === 1) {
        if (value_arr[0] && ip.isV4Format(value_arr[0]) !== true) {
          message = "Invalid CIDR/IPv4 address.";
        }
      } else {
        let isCidrValid = value && isCidr(value, false);
        if (!isCidrValid) {
          message = "Invalid CIDR/IPv4 address.";
        }
      }
    }
    if (message) {
      callback(message);
    } else {
      callback();
    }
  };
  checkPriorityRange = (rule, value, callback) => {
    let val = parseInt(value);
    if (val < 1000 || val > 10000)
      callback(
        getCurrentlocaleText("firewall.rule.priority.error.message1.text"),
      );
    else callback();
  };
  checkPortRange = (rule, value, callback) => {
    let pattern1 = /^[0-9]{1,5}$/g,
      pattern2 = /^[0-9]{1,5}$/g;
    if (value) {
      let port_arr = value.trim().split("-");
      if (port_arr.length == 1) {
        if (pattern1.test(port_arr[0])) {
          if (parseInt(port_arr[0]) >= 0 && parseInt(port_arr[0]) <= 65535) {
            callback();
          } else {
            callback(getCurrentlocaleText("custom.security_policy.port.error"));
          }
        } else {
          callback(getCurrentlocaleText("custom.security_policy.port.error"));
        }
      } else {
        if (pattern1.test(port_arr[0]) && pattern2.test(port_arr[1])) {
          if (
            parseInt(port_arr[0]) >= 0 &&
            parseInt(port_arr[1]) <= 65535 &&
            parseInt(port_arr[0]) <= parseInt(port_arr[1])
          ) {
            callback();
          } else {
            callback(getCurrentlocaleText("custom.security_policy.port.error"));
          }
        } else {
          callback("Invalid port");
        }
      }
    } else {
      callback();
    }
  };
  checkFirewallGroupName = (rule, value, callback) => {
    if (value && value.trim().toLowerCase() === `default`) {
      callback("Invalid security policy name.");
    } else {
      callback();
    }
  };
  areSourceDestinationEqual = (first, second) => {
    if (!first || !second) {
      return false;
    }
    if (!first["_iotium.network.id"] || !second["_iotium.network.id"]) {
      return false;
    } else if (second["_iotium.network.id"] !== first["_iotium.network.id"]) {
      return false;
    } else {
      let firstNetwork = {},
        secondNetwork = {};
      if (this.state.InterfaceLabels && this.state.InterfaceLabels.length > 0) {
        this.state.InterfaceLabels.forEach(l => {
          if (
            l.id &&
            first["_iotium.network.id"] &&
            first["_iotium.network.id"].includes(l.id)
          ) {
            firstNetwork = l;
          }
          if (
            l.id &&
            second["_iotium.network.id"] &&
            second["_iotium.network.id"].includes(l.id)
          ) {
            secondNetwork = l;
          }
        });
      }
      if (
        firstNetwork &&
        (firstNetwork.is_default_network || firstNetwork.is_wan_network)
      ) {
        return false;
      }
      if (
        secondNetwork &&
        (secondNetwork.is_default_network || secondNetwork.is_wan_network)
      ) {
        return false;
      }
    }

    return true;
  };
  // render list for permission options
  renderList = () => {
    let optionArray = [{ name: "production" }, { name: "lab" }];
    return optionArray
      .sort((x, y) => customSort(x.name, y.name))
      .map((val, index) => {
        return <Option key={val.name}>{val.name.toUpperCase()}</Option>;
      });
  };

  getNetworkGroupInfo = networkId => {
    let Networkdata = this.state.InterfaceLabels || [];
    let data = getCurrentlocaleText("general.unknown.text.value");
    if (Networkdata.length > 0) {
      // Filter only the node networks
      let network = Networkdata.filter(
        network => (network && network.node) || (network && network.cluster),
      ).find(
        network =>
          network && network.id && networkId && network.id === networkId,
      );
      if (network) {
        let displayText =
          network.cluster && network.cluster
            ? network.cluster.name + network.name
            : network.node && network.node.name + network.name;
        data =
          displayText.length < 15 ? (
            <span
              key={network.id}
              style={{ height: displayText.length > 10 ? "auto" : 22 }}
              className="iot-tag-label iot-tag-label-color"
            >
              {" "}
              <Icons
                type={network.cluster ? "bs" : "fa"}
                name={network.cluster ? "BsBoundingBox" : "FaRegHdd"}
                className="iot-tag-label"
              />{" "}
              {network.cluster ? network.cluster.name : network.node.name}/{" "}
              {displayText.length > 15 ? <br /> : null}
              <Icons
                type={"fa"}
                name="FaConnectdevelop"
                className="iot-tag-label"
              />{" "}
              {network.name}
            </span>
          ) : (
            <Popover
              placement="top"
              content={
                <Tag
                  key={network.id}
                  className="iot-tag-label iot-tag-label-color"
                >
                  {" "}
                  <Icons
                    type={network.cluster ? "bs" : "fa"}
                    name={network.cluster ? "BsBoundingBox" : "FaRegHdd"}
                    className="iot-tag-label"
                  />{" "}
                  {network.cluster ? network.cluster.name : network.node.name}/{" "}
                  <Icons
                    type={"fa"}
                    className="iot-tag-label"
                    name="FaConnectdevelop"
                  />{" "}
                  {network.name}
                </Tag>
              }
            >
              <span className="iot-tag-label">
                <Icons
                  type={"fa"}
                  className="iot-tag-label"
                  name="FaConnectdevelop"
                />
                <span className="iot-tag-label">{network.name}</span>
              </span>
            </Popover>
          );
      }
    }
    return data;
  };

  getClusterInfo = clusterId => {
    let networks = this.state.InterfaceLabels || [];
    let data = getCurrentlocaleText("deleted.cluster.text");
    if (networks.length > 0) {
      // Filter only the cluster networks
      let network = networks
        .filter(network => network && network.cluster)
        .find(
          network =>
            network &&
            network.cluster &&
            clusterId &&
            network.cluster.id === clusterId,
        );
      if (network) {
        let displayText = network.cluster.name;
        data = (
          <span
            key={network.id}
            style={{ height: displayText.length > 10 ? "auto" : 22 }}
            className="iot-tag-label iot-tag-label-color"
          >
            {" "}
            <Icons
              type={"bs"}
              name={"BsBoundingBox"}
              className="iot-tag-label"
            />{" "}
            {network.cluster.name}
          </span>
        );
      }
    }
    return data;
  };

  handleLabelChange = values => {
    this.props.form.setFields({
      metadata: {
        value: { labels: values },
      },
    });
    this.setState({ metadata: values, tags: this.getTagKeyValues(values) });
  };
  computeInterfaceTag(tag) {
    let tagdata = this.selectorConstructor(tag);
    if (tagdata["_iotium.network.name"]) {
      for (let key in tagdata) {
        let string = [key] + " : " + tagdata[key];
        let filtered_key = [key];
        return string.length < 15 ? (
          <span>
            {" "}
            <Icons
              type="ai"
              name="AiOutlineTag"
              className="iot-tag-label"
              style={{ marginRight: 5 }}
            />
            {tagdata["_iotium.network.name"]}
          </span>
        ) : (
          <Popover
            placement="top"
            content={
              <Tag>
                <Icons
                  type="ai"
                  name="AiOutlineTag"
                  className="iot-tag-label"
                  style={{ marginRight: 5 }}
                />
                {key.replace("_iotium.", " ") + " : " + tagdata[key]}
              </Tag>
            }
          >
            {" "}
            <span>
              <Icons
                type="ai"
                name="AiOutlineTag"
                className="iot-tag-label"
                style={{ marginRight: 5 }}
              />
              <span className={"firewall-rules-tag-content"}>
                {(key.replace("_iotium.", " ") + " : " + tagdata[key]).slice(
                  0,
                  15,
                ) + "..."}
              </span>
            </span>{" "}
          </Popover>
        );
      }
    } else if (
      tagdata["_iotium.network.id"] ||
      tagdata["_iotium.cluster.wan.network.id"] ||
      tagdata["_iotium.cluster.tan.network.id"]
    ) {
      return this.getNetworkGroupInfo(
        tagdata["_iotium.network.id"]
          ? tagdata["_iotium.network.id"].trim()
          : tagdata["_iotium.cluster.tan.network.id"]
          ? tagdata["_iotium.cluster.tan.network.id"].trim()
          : tagdata["_iotium.cluster.wan.network.id"].trim(),
      );
    } else if (tagdata["_iotium.cluster.id"]) {
      return this.getClusterInfo(tagdata["_iotium.cluster.id"].trim());
    } else {
      for (let key in tagdata) {
        let string = [key] + " : " + tagdata[key];
        let filtered_key = [key];
        return string.length < 15 ? (
          <span>
            <Icons
              type="ai"
              name="AiOutlineTag"
              className="iot-tag-label"
              style={{ marginRight: 5 }}
            />
            {key.replace("_iotium.", " ") + ":" + tagdata[key]}
          </span>
        ) : (
          <Popover
            placement="top"
            content={
              <Tag>
                <Icons
                  type="ai"
                  name="AiOutlineTag"
                  className="iot-tag-label"
                  style={{ marginRight: 5 }}
                />
                {key.replace("_iotium.", " ") + " : " + tagdata[key]}
              </Tag>
            }
          >
            {" "}
            <span>
              <Icons
                type="ai"
                name="AiOutlineTag"
                className="iot-tag-label"
                style={{ marginRight: 5 }}
              />
              <span className={"firewall-rules-tag-content"}>
                {(key.replace("_iotium.", " ") + " : " + tagdata[key]).slice(
                  0,
                  15,
                ) + "..."}
              </span>
            </span>{" "}
          </Popover>
        );
      }
    }
  }
  handleOptionChange = e => {
    if (e.key === "wan_ip") {
      FirewallGroupController.checkmyPublicAddress().then(response => {
        this.props.form.setFieldsValue({
          [this.state.currentField]: response,
        });
      });
    } else if (e.key === "all") {
      this.props.form.setFieldsValue({
        [this.state.currentField]: "0.0.0.0/0",
      });
    } else {
      this.props.form.resetFields([this.state.currentField]);
    }
  };
  /**
   * CheckForValidLabel
   */
  CheckForValidLabel = (rule, value, callback) => {
    if (!NetworkContainer.checkForValidLabel(value))
      callback(getCurrentlocaleText("general.label.input.error1.text"));
    else callback();
  };

  /**
   *  Get more Info...
   */
  getMoreInfo = record => {
    const children = [];
    const { getFieldDecorator } = this.props.form;

    return (
      <span
        onClick={() => {
          this.setState({ currentRowKey: record.key });
        }}
      >
        <Tabs
          tabPosition={"top"}
          defaultActiveKey={this.state.defaultActiveKey}
        >
          <TabPane
            tab={
              <span>
                {getCurrentlocaleText("networks.diode.tab.header.text")}
              </span>
            }
            key="1"
          >
            <Row type="flex" gutter={2}>
              <Col style={{ marginBottom: 25, marginTop: 20 }} span={24}>
                <FormItem
                  colon={false}
                  label={<span className={"iot-label-not-required"} />}
                  {...formItemLayout2}
                >
                  {getFieldDecorator("direction-" + record.key, {
                    initialValue: record.direction,
                    rules: [
                      {
                        required:
                          !this.props.form.getFieldValue(
                            `from_network-${record.key}`,
                          ) ||
                          !this.props.form.getFieldValue(
                            `to_network-${record.key}`,
                          ) ||
                          !this.areSourceDestinationEqual(
                            this.props.form.getFieldValue(
                              `from_network-${record.key}`,
                            ),
                            this.props.form.getFieldValue(
                              `to_network-${record.key}`,
                            ),
                          ) ||
                          this.props.form.getFieldValue(
                            `from_network-${record.key}`,
                          ).length === 0 ||
                          this.props.form.getFieldValue(
                            `to_network-${record.key}`,
                          ).length === 0
                            ? false
                            : true,
                        message: getCurrentlocaleText(
                          "firewall.traffic_direction.error_message.text",
                        ),
                        whitespace: true,
                      },
                    ],
                  })(
                    <RadioGroup
                      onChange={this.updateDirection.bind(this, record)}
                      disabled={
                        !this.props.form.getFieldValue(
                          `from_network-${record.key}`,
                        ) ||
                        !this.props.form.getFieldValue(
                          `to_network-${record.key}`,
                        ) ||
                        !this.areSourceDestinationEqual(
                          this.props.form.getFieldValue(
                            `from_network-${record.key}`,
                          ),
                          this.props.form.getFieldValue(
                            `to_network-${record.key}`,
                          ),
                        ) ||
                        this.props.form.getFieldValue(
                          `from_network-${record.key}`,
                        ).length === 0 ||
                        this.props.form.getFieldValue(
                          `to_network-${record.key}`,
                        ).length === 0
                          ? true
                          : false
                      }
                    >
                      <Radio style={radioStyle} value={"FromServiceToDevice"}>
                        {getCurrentlocaleText(
                          "networks.southside.direction1.label.text",
                        )}
                      </Radio>
                      <Radio style={radioStyle} value={"FromDeviceToService"}>
                        {getCurrentlocaleText(
                          "networks.southside.direction2.label.text",
                        )}
                      </Radio>
                    </RadioGroup>,
                  )}
                </FormItem>
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </span>
    );
  };
  onExpand = (expanded, record) => {
    let keys = this.state.expandedRowKeys ? this.state.expandedRowKeys : [];
    let index = keys.findIndex(val => {
      return val === record.key;
    });
    if (expanded) {
      if (index === -1) {
        keys.push(record.key);
      }
      this.setExpandedRowKeys(keys);
    } else {
      if (index !== -1) {
        keys.splice(index, 1);
      }
      this.setExpandedRowKeys(keys);
    }
  };

  setExpandedRowKeys = keys => {
    this.setState({ expandedRowKeys: keys });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const menu = (
      <Menu onClick={this.handleOptionChange}>
        {/*commented this as it requires public client IP address*/}
        {/* <Menu.Item key="wan_ip">Use My IP</Menu.Item> */}
        <Menu.Item key="all">Any</Menu.Item>
        <Menu.Item key="custom">Custom Input</Menu.Item>
      </Menu>
    );
    let NetworkInterfaceOptions = this.state.NetworkInterfaceOptions,
      children = [];
    for (let i = 0; i < NetworkInterfaceOptions.length; i++) {
      children.push(
        <Option key={i} value={NetworkInterfaceOptions[i]}>
          {NetworkInterfaceOptions[i]}
        </Option>,
      );
    }
    const filter = (inputValue, path) => {
      return path.some(
        option =>
          option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
      );
    };

    const displayRender = (labels, selectedOptions) => {
      if (selectedOptions.length > 0) {
        return (
          <Tag key={selectedOptions}>
            {labels.map((label, i) => {
              const option = selectedOptions[i];
              if (i != 0) {
                return (
                  <span className="iot-tag-label" key={option.value}>
                    {option.tag}&nbsp;{label}
                  </span>
                );
              }
            })}
          </Tag>
        );
      }
    };

    this.columns = [
      {
        title: (
          <span>
            <span>From Network</span>
          </span>
        ),
        dataIndex: "from_network",
        width: "18%",
        onCell: record => {
          return {
            onClick: () => {
              this.setState({ currentRowKey: `from_network-${record.key}` });
            },
          };
        },
        render: (text, record) => {
          return !this.state.UserdefinedInterfaces.includes(
            `from_network-${record.key}`,
          ) ? (
            <FormItem style={{ marginTop: "10px" }}>
              {getFieldDecorator(`from_network-${record.key}`, {
                initialValue: this.selectorConstructor(record.source_network),
                rules: !this.state.DirectionSpecifiedRules.includes(record.key)
                  ? [
                      {
                        required: true,
                        message: getCurrentlocaleText(
                          "firewall.network.error_message1.text",
                        ),
                        whitespace: true,
                      },
                    ]
                  : [],
              })(
                <span>
                  {(record.source_network &&
                    record.source_network.length <= 0) ||
                  this.state.customizedTags.includes(
                    `from_network-${record.key}`,
                  ) ? (
                    this.state.customLoading ? (
                      <LoadingOutlined />
                    ) : (
                      <Cascader
                        options={this.state.InterfaceOptions}
                        size="large"
                        expandTrigger="hover"
                        placeholder={getCurrentlocaleText(
                          "firewall.network_Interface.placeholder.text",
                        )}
                        allowClear={true}
                        key={record.key}
                        showSearch={{ filter }}
                        onChange={(value, selectedOptions) =>
                          this.onChangeInterface(
                            value,
                            selectedOptions,
                            `from_network-${record.key}`,
                          )
                        }
                        displayRender={displayRender}
                        notFoundContent={getCurrentlocaleText(
                          "general.notfound.placeholder.text",
                        )}
                      />
                    )
                  ) : this.state.customLoading ? (
                    <LoadingOutlined />
                  ) : (
                    <Tag
                      closable={true}
                      onClose={() => {
                        this.removeCurrentTag(`from_network-${record.key}`);
                      }}
                      className="iot-tag-label iot-tag-label-color"
                    >
                      {this.computeInterfaceTag(record.source_network)}
                    </Tag>
                  )}
                </span>,
              )}
            </FormItem>
          ) : (
            <FormItem style={{ marginTop: "10px" }}>
              {getFieldDecorator(`from_network-${record.key}`, {
                initialValue: this.selectorConstructor(record.source_network),
                validateTrigger: "onKeyUp",
                trigger: "onKeyUp",
                rules: !this.state.DirectionSpecifiedRules.includes(record.key)
                  ? [
                      {
                        required: true,
                        whitespace: true,
                        message: getCurrentlocaleText(
                          "firewall.network.error_message1.text",
                        ),
                      },
                      {
                        validator: this.CheckForValidLabel,
                      },
                    ]
                  : [
                      {
                        required: true,
                        whitespace: true,
                        message: getCurrentlocaleText(
                          "firewall.network.error_message1.text",
                        ),
                      },
                      {
                        validator: this.CheckForValidLabel,
                      },
                    ],
              })(
                <span>
                  {(record.source_network &&
                    record.source_network.length <= 0) ||
                  this.state.customizedTags.includes(
                    `from_network-${record.key}`,
                  ) ? (
                    this.state.customLoading ? (
                      <LoadingOutlined />
                    ) : (
                      <Input
                        maxLength={127}
                        addonAfter={
                          <span
                            onClick={() => {
                              this.removeCustomInput(
                                `from_network-${record.key}`,
                              );
                            }}
                          >
                            <Icons type="ai" name="AiOutlineCloseCircle" />
                          </span>
                        }
                      />
                    )
                  ) : this.state.customLoading ? (
                    <LoadingOutlined />
                  ) : (
                    <Tag
                      closable={true}
                      onClose={() => {
                        this.removeCurrentTag(`from_network-${record.key}`);
                      }}
                      className="iot-tag-label iot-tag-label-color"
                    >
                      {this.computeInterfaceTag(record.source_network)}
                    </Tag>
                  )}
                </span>,
              )}
            </FormItem>
          );
        },
      },
      {
        title: (
          <span>
            <span>To Network</span>
          </span>
        ),
        dataIndex: "to_network",
        width: "18%",
        onCell: record => {
          return {
            onClick: () => {
              this.setState({ currentRowKey: `to_network-${record.key}` });
            },
          };
        },
        render: (text, record) => {
          return !this.state.UserdefinedInterfaces.includes(
            `to_network-${record.key}`,
          ) ? (
            <FormItem style={{ marginTop: "10px" }}>
              {getFieldDecorator(`to_network-${record.key}`, {
                rules: !this.state.DirectionSpecifiedRules.includes(record.key)
                  ? [
                      {
                        required: true,
                        message: getCurrentlocaleText(
                          "firewall.network.error_message1.text",
                        ),
                        whitespace: true,
                      },
                    ]
                  : [],
                initialValue: this.selectorConstructor(
                  record.destination_network,
                ),
              })(
                <span>
                  {(record.destination_network &&
                    record.destination_network.length <= 0) ||
                  this.state.customizedTags.includes(
                    `to_network-${record.key}`,
                  ) ? (
                    this.state.customLoading ? (
                      <LoadingOutlined />
                    ) : (
                      <Cascader
                        key={`to_network-${record.key}`}
                        options={this.state.InterfaceOptions}
                        size="large"
                        expandTrigger="hover"
                        showSearch={{ filter }}
                        placeholder={getCurrentlocaleText(
                          "firewall.network_Interface.placeholder.text",
                        )}
                        allowClear={true}
                        onChange={(value, selectedOptions) =>
                          this.onChangeInterface(
                            value,
                            selectedOptions,
                            `to_network-${record.key}`,
                          )
                        }
                        displayRender={displayRender}
                        notFoundContent={getCurrentlocaleText(
                          "general.notfound.placeholder.text",
                        )}
                      />
                    )
                  ) : this.state.customLoading ? (
                    <LoadingOutlined />
                  ) : (
                    <Tag
                      closable={true}
                      onClose={() => {
                        this.removeCurrentTag(`to_network-${record.key}`);
                      }}
                      className="iot-tag-label iot-tag-label-color"
                    >
                      {this.computeInterfaceTag(record.destination_network)}
                    </Tag>
                  )}
                </span>,
              )}
            </FormItem>
          ) : (
            <FormItem style={{ marginTop: "10px" }}>
              {getFieldDecorator(`to_network-${record.key}`, {
                rules: !this.state.DirectionSpecifiedRules.includes(record.key)
                  ? [
                      {
                        required: true,
                        whitespace: true,
                        message: getCurrentlocaleText(
                          "firewall.network.error_message1.text",
                        ),
                      },
                      {
                        validator: this.CheckForValidLabel,
                      },
                    ]
                  : [
                      {
                        required: true,
                        whitespace: true,
                        message: getCurrentlocaleText(
                          "firewall.network.error_message1.text",
                        ),
                      },
                      {
                        validator: this.CheckForValidLabel,
                      },
                    ],
                initialValue: this.selectorConstructor(
                  record.destination_network,
                ),
                validateTrigger: "onKeyUp",
                trigger: "onKeyUp",
              })(
                <span>
                  {(record.destination_network &&
                    record.destination_network.length <= 0) ||
                  this.state.customizedTags.includes(
                    `to_network-${record.key}`,
                  ) ? (
                    this.state.customLoading ? (
                      <LoadingOutlined />
                    ) : (
                      <Input
                        maxLength={127}
                        addonAfter={
                          <span
                            onClick={() => {
                              this.removeCustomInput(
                                `to_network-${record.key}`,
                              );
                            }}
                          >
                            <Icons type="ai" name="AiOutlineCloseCircle" />
                          </span>
                        }
                      />
                    )
                  ) : this.state.customLoading ? (
                    <LoadingOutlined />
                  ) : (
                    <Tag
                      closable={true}
                      onClose={() => {
                        this.removeCurrentTag(`to_network-${record.key}`);
                      }}
                      className="iot-tag-label iot-tag-label-color"
                    >
                      {this.computeInterfaceTag(record.destination_network)}
                    </Tag>
                  )}
                </span>,
              )}
            </FormItem>
          );
        },
      },
      {
        title: (
          <span>
            {"Priority"}
            <PopOverHelp
              isHelpOnForm={true}
              height={120}
              width={225}
              placement="right"
              helpData={{
                title: getCurrentlocaleText(
                  "firewall.rule.priority.help.title.text",
                ),
                data: [
                  {
                    subtitle: "",
                    content: getCurrentlocaleText(
                      "firewall.rule.priority.help.message.text",
                    ),
                  },
                ],
              }}
            />
          </span>
        ),
        dataIndex: "priority",
        width: "10%",
        render: (text, record) => {
          return (
            <FormItem style={{ marginTop: "10px", marginRight: "-26px" }}>
              {getFieldDecorator(`priority-${record.key}`, {
                initialValue: record.priority || 1000,
                rules: [
                  {
                    required: true,
                    message: "Please specify Priority.",
                  },
                  {
                    validator: this.checkPriorityRange,
                  },
                ],
              })(
                <InputNumber
                  className={`priority-${record.key}`}
                  onChange={() => {
                    this.updateFocus(`#priority-${record.key}`);
                  }}
                  placeholder="Priority"
                />,
              )}
            </FormItem>
          );
        },
      },
      {
        title: <span>Protocol</span>,
        dataIndex: "protocol",
        width: "8%",
        render: (text, record) => {
          return (
            <FormItem style={{ marginTop: "10px" }}>
              {getFieldDecorator(`protocol-${record.key}`, {
                rules: [
                  {
                    message: "Please select protocol.",
                    whitespace: true,
                  },
                ],
                initialValue: record.protocol ? record.protocol : "any",
              })(
                <Select
                  placeholder="Select protocol"
                  onSelect={this.protocolOnSelect.bind()}
                  notFoundContent={getCurrentlocaleText(
                    "general.notfound.placeholder.text",
                  )}
                >
                  <Option value="any" rowId={record.key}>
                    ANY
                  </Option>
                  <Option value="tcp443-443" rowId={record.key}>
                    HTTPS
                  </Option>
                  <Option value="icmp" rowId={record.key}>
                    ICMP
                  </Option>
                  <Option value="tcp22-22" rowId={record.key}>
                    SSH
                  </Option>
                  <Option value="tcp" rowId={record.key}>
                    TCP
                  </Option>
                  <Option value="udp" rowId={record.key}>
                    UDP
                  </Option>
                </Select>,
              )}
            </FormItem>
          );
        },
      },
      {
        title: <span style={{ wordBreak: "break-word" }}>{"Source Port"}</span>,
        dataIndex: "source_port",

        width: "6%",
        render: (text, record) => {
          return (
            <FormItem style={{ marginTop: "10px" }}>
              {getFieldDecorator(`source_port-${record.key}`, {
                rules: [
                  {
                    required: true,
                    validator: this.checkPortRange,
                  },
                ],
                initialValue: record.source_port || "0-65535",
              })(
                <Input
                  onBlur={this.SourceportOnChange.bind(this, record)}
                  autoComplete="off"
                  placeholder="Port"
                  disabled={
                    record.protocol
                      ? record.protocol === "any"
                        ? true
                        : record.protocol === "icmp"
                        ? true
                        : this.state.disabledRowsSourcePort.includes(record.key)
                      : true
                  }
                />,
              )}
            </FormItem>
          );
        },
      },
      {
        title: (
          <span style={{ wordBreak: "keep-all" }}>{"Destination Port"}</span>
        ),
        dataIndex: "destination_port",
        width: "6%",
        render: (text, record) => {
          return (
            <FormItem style={{ marginTop: "10px" }}>
              {getFieldDecorator(`destination_port-${record.key}`, {
                rules: [
                  {
                    required: true,
                    validator: this.checkPortRange,
                  },
                ],
                initialValue: record.destination_port,
              })(
                <Input
                  onBlur={this.portOnChange.bind(this, record)}
                  className={`destination_port-${record.key}`}
                  autoComplete="off"
                  placeholder="Port"
                  disabled={
                    record.protocol
                      ? record.protocol === "any"
                        ? true
                        : record.protocol === "icmp"
                        ? true
                        : this.state.disabledRowsPort.includes(record.key)
                      : true
                  }
                />,
              )}
            </FormItem>
          );
        },
      },
      {
        title: (
          <span>
            {"Source"}
            <br />
            {"CIDR"}
          </span>
        ),
        dataIndex: "src_ipaddress",
        width: "10%",
        render: (text, record) => {
          return (
            <div>
              <FormItem style={{ marginTop: "10px" }}>
                {getFieldDecorator(`src_ipaddress-${record.key}`, {
                  rules: [
                    {
                      required: false,
                      validator: this.checkIpV4,
                    },
                  ],
                  initialValue: record.src_ipaddress
                    ? record.src_ipaddress
                    : "0.0.0.0/0",
                })(
                  <Input
                    onChange={this.ipAddressOnChange.bind(this, record, "src")}
                    autoComplete="off"
                    placeholder="CIDR, IP"
                  />,
                )}
              </FormItem>
            </div>
          );
        },
      },
      {
        title: (
          <span>
            {"Destination"}
            <br />
            {"CIDR"}
          </span>
        ),
        dataIndex: "destination_address",
        width: "10%",
        render: (text, record) => {
          return (
            <div>
              <FormItem style={{ marginTop: "10px" }}>
                {getFieldDecorator(`destination_ipaddress-${record.key}`, {
                  rules: [
                    {
                      required: false,
                      validator: this.checkIpV4,
                    },
                  ],
                  initialValue: record.destination_ipaddress
                    ? record.destination_ipaddress
                    : "0.0.0.0/0",
                })(
                  <Input
                    onChange={this.ipAddressOnChange.bind(this, record, "dest")}
                    autoComplete="off"
                    placeholder="CIDR, IP"
                  />,
                )}
              </FormItem>
            </div>
          );
        },
      },
      {
        title: "Action",
        dataIndex: "",
        width: "10%",
        render: (text, record) => {
          return (
            <FormItem style={{ marginTop: "10px" }}>
              {getFieldDecorator(`action-${record.key}`, {
                initialValue: record.action || "DENY",
              })(
                <Select
                  placeholder="Select Action"
                  notFoundContent={getCurrentlocaleText(
                    "general.notfound.placeholder.text",
                  )}
                >
                  <Option value="ALLOW" rowId={record.key}>
                    ALLOW
                  </Option>
                  <Option value="DENY" rowId={record.key}>
                    DENY
                  </Option>
                </Select>,
              )}
            </FormItem>
          );
        },
      },
      {
        title: "",
        dataIndex: "",
        width: "1%",
        render: (text, record) => {
          return (
            <FormItem style={{ marginTop: "10px" }}>
              {getFieldDecorator(`isdeleted-${record.key}`, {
                initialValue: record.isdeleted,
              })(
                <Button
                  icon={<Icons type="ai" name="AiOutlineMinus" />}
                  shape={"circle"}
                  onClick={this.removeItem.bind(this, record)}
                  title={"Remove"}
                  disabled={this.state.count === 1}
                />,
              )}
            </FormItem>
          );
        },
      },
    ];
    return (
      <div>
        <Form noValidate onSubmit={this.props.onSubmit}>
          <Row gutter={24}>
            <Col span={24}>
              <FormItem label="Name" hasFeedback>
                {getFieldDecorator("name", {
                  rules: [
                    {
                      required: true,
                      message: getCurrentlocaleText(
                        "firewall.security_policy_name.input.error.message",
                      ),
                      whitespace: true,
                    },
                    {
                      max: 63,
                      message: getCurrentlocaleText(
                        "firewall.security_policy_name.maxCharacter.error.message",
                      ),
                    },
                    {
                      pattern: /^[a-zA-Z0-9][a-zA-Z0-9-_.]*[a-zA-Z0-9]$/,
                      message: getCurrentlocaleText(
                        "firewall.security_policy_name.splCharacter.error.message",
                      ),
                    },
                    {
                      validator: this.checkFirewallGroupName,
                    },
                  ],
                  initialValue: this.state.firewall.name,
                })(
                  <Input
                    className="firstInput"
                    autoComplete="off"
                    placeholder="Security policy name"
                    onChange={this.firewallNameChange.bind()}
                  />,
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <FormItem
                colon={false}
                label={
                  <span
                    className={"iot-label-not-required"}
                    title={getCurrentlocaleText(
                      "general.form.label.text",
                    ).replace(":", " ")}
                  >
                    {getCurrentlocaleText("general.form.label.text")}
                    <PopOverHelp
                      isHelpOnForm={true}
                      height={285}
                      width={475}
                      placement={"rightTop"}
                      helpData={{
                        title: getCurrentlocaleText(
                          "general.form.label.text",
                        ).replace(":", " "),
                        data: [
                          {
                            subtitle: "",
                            content: getCurrentlocaleText(
                              "general.form.label.field.help.text",
                            ),
                          },
                          {
                            subtitle: getCurrentlocaleText(
                              "general.form.label.field.help2.text",
                            ),
                            content: <GenerateLabelHelp />,
                          },
                        ],
                      }}
                    />
                  </span>
                }
              >
                {getFieldDecorator("metadata", {
                  rules: [],
                })(
                  <LabelProvider
                    tags={this.state.tags}
                    metadata={this.state.metadata}
                    handleChange={this.handleLabelChange}
                    isModal={true}
                  />,
                )}
              </FormItem>
            </Col>
          </Row>
          <div>
            <Card
              bordered={false}
              title={
                <Row>
                  <Col span={12}>
                    <strong className="networkspage-card-title">
                      {getCurrentlocaleText(
                        "security_policy.rules.labels.text",
                      )}
                    </strong>
                  </Col>

                  <Col
                    span={12}
                    style={{
                      marginBottom: "5px",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      style={{
                        marginRight: 3,
                      }}
                      icon={<PlusOutlined />}
                      onClick={this.addItem.bind()}
                      disabled={
                        this.state.count &&
                        this.state.count >=
                          getOrgPolicyCounInfo(this.orgModel.org, "rules_count")
                          ? true
                          : false
                      }
                      title={
                        this.state.count &&
                        this.state.count >=
                          getOrgPolicyCounInfo(this.orgModel.org, "rules_count")
                          ? getCurrentlocaleText(
                              "security_policy.rule.maximum_limit.group.warning.text",
                              {
                                0: getOrgPolicyCounInfo(
                                  this.orgModel.org,
                                  "rules_count",
                                ),
                              },
                            )
                          : getCurrentlocaleText(
                              "firewall.add.text.button.label",
                            )
                      }
                    >
                      {getCurrentlocaleText("firewall.add.text.button.label")}
                    </Button>
                  </Col>
                </Row>
              }
              key="default"
            >
              <Table
                className="firewall-Form-Table"
                columns={this.columns}
                dataSource={[...this.state.firewall.rules]}
                size="small"
                pagination={false}
                scroll={{ y: 240 }}
                onExpand={this.onExpand}
                expandedRowKeys={
                  this.state.expandedRowKeys ? this.state.expandedRowKeys : []
                }
                expandedRowRender={record => {
                  if (
                    !(!this.props.form.getFieldValue(
                      `from_network-${record.key}`,
                    ) ||
                    !this.props.form.getFieldValue(
                      `to_network-${record.key}`,
                    ) ||
                    !this.areSourceDestinationEqual(
                      this.props.form.getFieldValue(
                        `from_network-${record.key}`,
                      ),
                      this.props.form.getFieldValue(`to_network-${record.key}`),
                    ) ||
                    this.props.form.getFieldValue(`from_network-${record.key}`)
                      .length === 0 ||
                    this.props.form.getFieldValue(`to_network-${record.key}`)
                      .length === 0
                      ? true
                      : false)
                  ) {
                    return this.getMoreInfo(record);
                  } else {
                    return (
                      <div className="p-15">
                        {getCurrentlocaleText(
                          "firewall.list.table.from_to_tan.text",
                        )}
                      </div>
                    );
                  }
                }}
              />
            </Card>
          </div>
        </Form>
      </div>
    );
  }
}

const FireWallForm = Form.create()(FirewallForm);
export default FireWallForm;
