import React, { Component } from "react";
import { Card, Modal, Checkbox } from "antd";
import { getCurrentlocaleText } from "Core/Utils";

class NetworkCreateAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibility: false,
      skipStatus: false,
      skipText: "Skip Now",
    };
  }
  UNSAFE_componentWillReceiveProps(newprops) {
    if (this.props !== newprops) {
      this.setState({ visibility: newprops.visiblity });
    }
  }
  gotoCreateNetwork = () => {
    if (this.state.skipStatus) {
      let showediNodes = [];
      if (localStorage.IsCreateNetworkPopShowed) {
        showediNodes = JSON.parse(localStorage.IsCreateNetworkPopShowed);
      }
      this.setState({ visibility: false });
      this.props.history.push(this.props.route);
      showediNodes.push(this.props.nodeID);
      localStorage.setItem(
        "IsCreateNetworkPopShowed",
        JSON.stringify(showediNodes),
      );
      this.props.checkForAlert();
    } else {
      this.setState({ visibility: false });
      this.props.history.push(this.props.route);
    }
  };
  ignoreAlert = () => {
    if (this.state.skipStatus) {
      let showediNodes = [];
      this.setState({ visibility: false });
      showediNodes.push(this.props.nodeID);
      localStorage.setItem(
        "IsCreateNetworkPopShowed",
        JSON.stringify(showediNodes),
      );
      this.props.checkForAlert();
    } else {
      this.setState({ visibility: false });
      this.props.hidePopup();
    }
  };
  changeMessage = event => {
    if (event.target.checked) {
      this.setState({
        skipStatus: true,
        skipText: getCurrentlocaleText(
          "network.create.popup.confirm_skip.text2",
        ),
      });
    } else {
      this.setState({
        skipStatus: false,
        skipText: getCurrentlocaleText(
          "network.create.popup.confirm_skip.text1",
        ),
      });
    }
  };
  render() {
    return (
      <div>
        <Modal
          title={getCurrentlocaleText("network.create.popup.title")}
          visible={this.state.visibility}
          onOk={this.gotoCreateNetwork}
          onCancel={this.ignoreAlert}
          maskClosable={false}
          okText={getCurrentlocaleText(
            "network.create.popup.create.button.text",
          )}
          cancelText={this.state.skipText}
        >
          <p>
            {getCurrentlocaleText("network.create.popup.content", {
              0: this.props.isCluster ? "cluster" : "iNode",
            })}
          </p>
          <br />
          <p>
            <Checkbox
              onChange={e => {
                this.changeMessage(e);
              }}
            >
              {getCurrentlocaleText("network.create.popup.confirm_flag.text", {
                0: this.props.isCluster ? "cluster" : "iNode",
              })}
            </Checkbox>
          </p>
        </Modal>
      </div>
    );
  }
}

export default NetworkCreateAlert;
