import React, { Component } from "react";
import { Switch, Input, Col, Row, Button, Card } from "antd";
import ValidateAndComputeTemplate from "./ValidateAndComputeTemplate";
import ReactJson from "react-json-view";
import Icons from "components/UI-Components/Icons";
import { Thingworx_Edge } from "components/serviceTemplates/Data/ServiceSpecTemplates";
import { inject } from "mobx-react";

const { TextArea } = Input;
@inject("UiStore", "ServiceViewModel")
export default class ServicePlayground extends Component {
  constructor(props) {
    super(props);
    this.serviceModel = this.props.ServiceViewModel;
    this.state = {
      uiSchema: {},
      startValidation: false,
    };
    this.props.UiStore.setDocumentTitle("services");
  }

  callTemplateValidator = () => {
    this.setState({
      startValidation: true,
    });
  };

  inputSchema = e => {
    this.setState({
      startValidation: false,
    });

    this.uiSchema = e.target.value;
  };

  onClusterModeChange = value => {
    this.props.ServiceViewModel.clusterMode = value;
  };
  componentDidMount() {
    // to make the version check/other functionalities disabled when spec is rendered form this component.
    this.props.UiStore.isServicePlayGroundVisible = true;
  }
  componentWillUnmount() {
    this.props.UiStore.isServicePlayGroundVisible = false;
  }

  render() {
    return (
      <div className="App">
        <Row gutter={24} type="flex" style={{ marginBottom: "10px" }}>
          <Col span={24}>
            <h2> Service Templating </h2>
          </Col>
        </Row>

        <Row gutter={24} type="flex">
          <Col span={8}>
            <div style={{ marginBottom: "10px" }}>
              <h3> Cluster Mode </h3>
              <span>
                <Switch
                  name="Cluster"
                  style={{ marginTop: -5 }}
                  checkedChildren="On"
                  unCheckedChildren="Off"
                  onChange={this.onClusterModeChange}
                />
              </span>
              <h3> Paste the UI template schema here </h3>
              <TextArea
                style={{ height: "350px" }}
                onChange={this.inputSchema}
              />
              <Button
                style={{ left: "75%", marginTop: "10px" }}
                type="primary"
                onClick={this.callTemplateValidator}
              >
                Render Form
              </Button>
            </div>

            <Card
              title={
                <div>
                  <span style={{ marginRight: 5 }}>
                    <Icons
                      type="fa"
                      name="FaInfoCircle"
                      style={{ fontSize: 16 }}
                    />
                  </span>
                  Sample reference schema from input template
                </div>
              }
            >
              <div
                style={{
                  height: "350px",
                  overflow: "scroll",
                  marginTop: "10px",
                }}
              >
                <ReactJson src={Thingworx_Edge} collapsed={true} />
              </div>
            </Card>
          </Col>
          <Col span={16}>
            {this.state.startValidation && (
              <ValidateAndComputeTemplate
                uiSchema={this.uiSchema}
                {...this.props}
              />
            )}
          </Col>
        </Row>
      </div>
    );
  }
}
