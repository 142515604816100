import React, { Component } from "react";
import { Row, Col, Card, Tabs, Button, Modal, Tag, Menu, Popover } from "antd";
import { Route } from "react-router-dom";
import { observer, inject } from "mobx-react";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import Icons from "components/UI-Components/Icons";
import ActionBtn from "Core/API/ActionBtn";
import AppStore from "components/serviceTemplates/Components/views/AppStore.js";
import TableLayout from "components/layout/TableLayout";
import { NetworksList } from "components/DataLists";
import ActionRoute from "Core/API/ActionRoute";
import DropdownActionBtn from "components/UI-Components/DropdownActionBtn";
import ServiceController from "controller/ServiceController";
import NetworkController from "controller/NetworkController";
import InodeController from "controller/InodeController";
import ClusteriNodesList from "components/Clusters/ClusteriNodesList";
import ClusterEventList from "../Events/ClusterEventList";
import {
  notificationModal,
  getCurrentlocaleText,
  getNodeVersion,
  RestartServiceBaseVersion,
  capitalize,
  LiveLogInfraBaseversion,
  isArray,
} from "Core/Utils";

const TabPane = Tabs.TabPane;
const RouteTab = props => (
  <Route
    render={({ history }) => (
      <Tabs
        onTabClick={key => {
          let path =
            props.match.url
              .replace("/networks", "")
              .replace("/services", "")
              .replace("/inodes", "")
              .replace("/events", "") +
            "/" +
            key;
          history.push(path);
          props.UiStore.setCurrentPage(1);
        }}
        {...props}
      >
        {props.children}
      </Tabs>
    )}
  />
);
@inject(
  "AuthStore",
  "UiStore",
  "ClusterViewModel",
  "ServiceViewModel",
  "NetworkViewModel",
)
@observer
class ClusterTabs extends Component {
  constructor(props) {
    super(props);
    this.clusterModel = this.props.ClusterViewModel;
    this.serviceModel = this.props.ServiceViewModel;
    this.networkModel = this.props.NetworkViewModel;
    this.networkApiByInterval = null;
    this.serviceInterval = null;
    this.interval = null;
    this.isNetworkThere = false;
    this.TAB_KEYS = {
      default: "inodes",
      networks: "networks",
      services: "services",
      inodes: "inodes",
      events: "events",
    };
    this.state = {
      tabKey: this.TAB_KEYS.default,
      showAppStore: false,
      selectedService: {},
      searchText: "",
      expandedRowKeys: [],
      loading: false,
      showRestartmod: false,
      showRestartCnm: true,
      message: null,
      currentService: null,
      //service states
      NewLogSupportedNodes: [],
      olderNodes: [],
      isALiveNodes: [],
      RestartServiceSupportedNodes: [],
      key: "123", // Random Key generated and reassigned in order to re-render the component to update the `show status` text.
    };
    this.color = {};
    this.ServiceTemplatesTablecolumns = [
      {
        title: getCurrentlocaleText("orgs.tablelist.namecolumn.text"),
        dataIndex: "name",
        key: "name",
        width: "20%",
        ellipsis: true,
        render: (text, record, index) => {
          return (
            <span>
              {" "}
              {this.state.searchText.trim().length > 0
                ? highlightSearchText(text, this.state.searchText)
                : text}
              {record.system_service && (
                <div>
                  <Tag closable={false}>
                    <Icons
                      type="fa"
                      name="FaCogs"
                      style={{ height: 14, width: 14 }}
                    />{" "}
                    {getCurrentlocaleText("serial_list.system_service.text")}
                  </Tag>
                </div>
              )}
              {record.deleted_at && (
                <span className="iotium-danger">
                  {" "}
                  {getCurrentlocaleText("deleting.text")}
                </span>
              )}
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText("services.kind.label"),
        dataIndex: "kind",
        key: "kind",
        width: "20%",
        ellipsis: true,
        render: (text, record, index) => {
          return <span>{record.kind ? capitalize(record.kind) : "-"}</span>;
        },
      },
      {
        title: getCurrentlocaleText("node.list.table.node_status.label.text"),
        dataIndex: "pod_status",
        key: "pod_status",
        width: "22%",
        ellipsis: true,
        render: (text, record) => {
          // Popover Content
          let unknownStatusContent = (
            <div>
              {record.pod && record.pod.message
                ? record.pod.message
                : getCurrentlocaleText("service.status.unavailable.text")}
            </div>
          );
          let nonUnknownStatusContent = (
            <div>
              <p>
                <strong>
                  {getCurrentlocaleText("node.list.table.popover.created")}
                  {" : "}
                </strong>
                {record.pod && record.pod.start_time
                  ? record.pod.start_time
                  : "-"}
              </p>
              <p>
                <strong>
                  {getCurrentlocaleText("node.list.table.popover.message")}
                  {" : "}
                </strong>
                {record.pod && record.pod.message ? record.pod.message : "-"}
              </p>
            </div>
          );
          return (
            <div>
              {record.pod && record.pod.status ? (
                <Popover
                  content={
                    record.pod.status.toLowerCase() === "unknown"
                      ? unknownStatusContent
                      : nonUnknownStatusContent
                  }
                  title={
                    record.pod.status.toLowerCase() === "unknown"
                      ? null
                      : getCurrentlocaleText("service.status")
                  }
                >
                  <Tag className={"tag-" + record.pod.status.toLowerCase()}>
                    {" "}
                    {record.pod.status}{" "}
                  </Tag>
                </Popover>
              ) : (record.kind && record.kind.toLowerCase() === "replica") ||
                record.kind.toLowerCase() === "daemon" ? (
                <span onClick={this.showStatus.bind(this, record)}>
                  <a>
                    {this.isExpandedRow(record.id)
                      ? "Hide Status"
                      : "Show Status"}
                  </a>
                </span>
              ) : (
                <span>{"-"}</span>
              )}
            </div>
          );
        },
      },
      {
        title: (
          <span title={getCurrentlocaleText("general.component.networks.text")}>
            {getCurrentlocaleText("general.component.networks.text")}
          </span>
        ),
        dataIndex: "network_id",
        key: "network",
        width: "15%",
        ellipsis: true,
        render: (text, record, index) => {
          if (
            record &&
            isArray(record.networkDetails) &&
            record.networkDetails.length == 1
          ) {
            return (
              <a
                href={`/orgs/${record.serviceData &&
                  record.serviceData.organization &&
                  record.serviceData.organization
                    .id}/clusters/${record.serviceData &&
                  record.serviceData.cluster &&
                  record.serviceData.cluster.id}/view/${
                  record.networkDetails[0].networkId
                }`}
              >
                {record.networkDetails[0].network_name}
              </a>
            );
          } else if (
            isArray(record.networkDetails) &&
            record.networkDetails.length > 1
          ) {
            let popoverContentData = [];
            let networkIpAddress =
              record &&
              record.serviceData &&
              record.serviceData.spec &&
              record.serviceData.spec.networks;
            // Needed copy the array for popover content
            let popoverNetworkDetails =
              record &&
              record.networkDetails.map(detail => ({
                ...detail,
              }));
            networkIpAddress.length > 0 &&
              networkIpAddress.forEach((network, i, arr) => {
                if (
                  network.is_default === true &&
                  network.ip_address === record.networkDetails[i].ip_address
                ) {
                  popoverNetworkDetails[i].network_name += " (Default)";
                }
                popoverContentData.push(
                  <div key={i}>
                    {popoverNetworkDetails[i].networkId && (
                      <a
                        href={`/orgs/${record.serviceData &&
                          record.serviceData.organization &&
                          record.serviceData.organization.id &&
                          record.serviceData.organization.id}/clusters/${
                          record.serviceData.cluster.id
                        }/view/${record.networkDetails[i].networkId}`}
                      >
                        {popoverNetworkDetails[i].network_name}
                      </a>
                    )}
                    {!popoverNetworkDetails[i].network_name && <span>-</span>}
                    {arr.length - 1 !== i && <span>,&nbsp;</span>}
                  </div>,
                );
              });
            return (
              <Popover content={popoverContentData}>
                <a
                  href={`/orgs/${record.serviceData &&
                    record.serviceData.organization &&
                    record.serviceData.organization.id &&
                    record.serviceData.organization.id}/clusters/${
                    record.serviceData.cluster.id
                  }/view/${record.networkDetails[0].networkId}`}
                >
                  {record.networkDetails[0].network_name}
                </a>
                ...
              </Popover>
            );
          } else {
            return "-";
          }
        },
      },
      {
        title: (
          <span title={getCurrentlocaleText("inode.interface.ipv4.label.text")}>
            {getCurrentlocaleText("inode.interface.ipv4.label.text")}
          </span>
        ),
        dataIndex: "ip_address",
        key: "ipAddress",
        width: "20%",
        ellipsis: true,
        render: (text, record, index) => {
          if (record.networkDetails && record.networkDetails.length === 1) {
            return record.networkDetails.map((network, i) => {
              return (
                <p
                  title={network.ip_address}
                  className="iot-table-data-ellipse"
                  key={i}
                >
                  {network.ip_address}
                </p>
              );
            });
          } else if (
            record.networkDetails &&
            record.networkDetails.length > 1
          ) {
            let popoverContentData = [];
            let networkIpAddress =
              record &&
              record.serviceData &&
              record.serviceData.spec &&
              record.serviceData.spec.networks;
            networkIpAddress.length > 0 &&
              networkIpAddress.map((network, i, arr) => {
                let showIpAddress = network.ip_address;
                if (network.is_default === true) {
                  showIpAddress += " (Default)";
                }
                popoverContentData.push(
                  <div key={i}>
                    {showIpAddress ? showIpAddress : "-"}
                    {arr.length - 1 !== i && <span>,&nbsp;</span>}
                  </div>,
                );
              });
            return (
              <Popover content={popoverContentData}>
                <div>{record.networkDetails[0].ip_address}...</div>
              </Popover>
            );
          } else {
            return "-";
          }
        },
      },
      {
        title: (
          <span title={getCurrentlocaleText("running.containers")}>
            {getCurrentlocaleText("running.containers")}
          </span>
        ),
        dataIndex: "running_container",
        key: "running_container",
        width: "18%",
        ellipsis: true,
        render: (text, record) => {
          return (
            <span style={{ paddingLeft: "20px" }}>
              {record.running_container}
            </span>
          );
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "edit_node",
        width: "5%",
        render: (text, record, index) => {
          /*  LAT-5077  */
          let serType =
            record.serviceData.spec.labels &&
            record.serviceData.spec.labels.io_iotium_template
              ? record.serviceData.spec.labels["io_iotium_template"]
              : record.serviceData.spec.labels &&
                record.serviceData.spec.labels["io-iotium-template"]
              ? record.serviceData.spec.labels["io-iotium-template"]
              : "custom";

          const view = (
            <span>
              <ActionRoute
                actionIcon="AiOutlineEye"
                actionLabel={getCurrentlocaleText("view.service.text")}
                iconButton
                title={getCurrentlocaleText("view.service.text")}
                route={window.location.pathname.replace(
                  "/services",
                  `/serviceview/${serType.toLowerCase()}/${record.id}`,
                )}
                iconBtnStyle={{ fontSize: 14 }}
              />
            </span>
          );
          const edit = (
            <span>
              <ActionRoute
                actionIcon="AiOutlineEdit"
                actionLabel={getCurrentlocaleText(
                  "network.form.useraction_edit.label.text",
                )}
                iconButton
                title={getCurrentlocaleText("service.edit.header.text")}
                disabled={record.disabled ? record.disabled : false}
                route={window.location.pathname.replace(
                  "/services",
                  `/services/${serType.toLowerCase()}/${record.id}`,
                )}
              />
            </span>
          );
          const serviceLog = (
            <span>
              {/* New log Infra menu */}
              {
                <ActionRoute
                  actionIcon="AiOutlineFileText"
                  actionLabel={"Service Logs"}
                  disabled={
                    (record.serviceData &&
                      record.serviceData.spec &&
                      record.serviceData.spec.kind &&
                      record.serviceData.spec.kind != "SINGLETON") ||
                    (record.pod &&
                      record.pod.status &&
                      record.pod.status.toLowerCase() === "pending") ||
                    (record.pod &&
                      record.pod.status &&
                      record.pod.status.toLowerCase() === "unknown" &&
                      record.serviceData &&
                      record.serviceData.spec &&
                      record.serviceData.spec.kind &&
                      record.serviceData.spec.kind === "SINGLETON")
                      ? true
                      : false
                  }
                  iconButton
                  title={"Service Logs"}
                  route={window.location.pathname.replace(
                    "/services",
                    `/services/${record.id}/logs`,
                  )}
                />
              }
            </span>
          );
          const restart = (
            <div>
              <Button
                title={
                  record.pod.restart_policy &&
                  record.pod.restart_policy === "Never"
                    ? getCurrentlocaleText(
                        "Service.restart.disabled.help.text1",
                      )
                    : this.props.ClusterediNodeList.length == 0
                    ? getCurrentlocaleText(
                        "Service.restart.disabled.help.text2",
                      )
                    : "Restart Service"
                }
                onClick={() => {
                  this.showServiceRestartConfirm(record);
                }}
                disabled={
                  (record.pod.restart_policy &&
                    record.pod.restart_policy === "Never") ||
                  (record.pod &&
                    record.pod.status &&
                    record.pod.status.toLowerCase() === "restarting") ||
                  this.props.ClusterediNodeList.length == 0
                    ? true
                    : false
                }
                style={{ minWidth: 50 }}
              >
                <Icons
                  style={{ margin: 0, fontSize: 16 }}
                  type="md"
                  name="MdRefresh"
                />
              </Button>
            </div>
          );
          const menu = (
            <Menu>
              {(this.props.AuthStore.IsPermitted("SERVICE:VIEW") ||
                this.props.AuthStore.IsPermitted("ORG:SUPPORT")) && (
                <Menu.Item key={0}>{view}</Menu.Item>
              )}
              {this.props.AuthStore.IsPermitted("SERVICE:CREATE") && (
                <Menu.Item key={1}>{edit}</Menu.Item>
              )}
              {record.serviceData &&
                record.serviceData.spec &&
                record.serviceData.spec.kind &&
                record.serviceData.spec.kind === "SINGLETON" && (
                  <Menu.Item key={2}>{serviceLog}</Menu.Item>
                )}
              {this.props.AuthStore.IsPermitted("SERVICE:RESTART") &&
                record.serviceData &&
                record.serviceData.spec &&
                record.serviceData.spec.kind &&
                record.serviceData.spec.kind === "SINGLETON" && (
                  <Menu.Item key={3}>{restart}</Menu.Item>
                )}
            </Menu>
          );

          return (
            <div>
              <DropdownActionBtn menu={menu} />
            </div>
          );
        },
      },
    ];
  }

  showServiceRestartConfirm = record => {
    this.setState({
      showRestartmod: true,
      loading: false,
      currentService: record,
    });
  };

  onTabChange = tab => {
    this.setState({
      tabKey: tab,
    });
  };

  componentDidMount() {
    // preventive fix on to clear previous data
    this.serviceModel.resetServices();
    clearInterval(this.interval);

    let paths = this.props.location.pathname.split("/");
    let tabKey = paths[paths.length - 1];
    if (tabKey && Object.values(this.TAB_KEYS).includes(tabKey)) {
      this.setState({
        tabKey: tabKey,
      });
    }
    this.updateNetworkData();
    if (this.props.AuthStore.IsPermitted("SERVICE:VIEW")) {
      this.resetSelectedService();
      this.serviceList(true, 0, "");
      this.getServicesByInterval();
    }
    this.interval = setInterval(() => {
      this.updateiNodeDetails();
    }, 30000);
    this.updateiNodeDetails();
  }

  resetSelectedService = () => {
    this.setState({ selectedService: {} });
  };

  selectService = (record, selected, selectedRows) => {
    this.setState({
      selectedService: this.extractActiveServiceRows(selectedRows),
    });
  };

  selectServiceAll = (selected, selectedRows, changeRows) => {
    this.setState({
      selectedService: this.extractActiveServiceRows(selectedRows),
    });
  };

  extractActiveServiceRows = selectedRows => {
    // Return only active service rows
    return selectedRows.filter(row => {
      return row.disabled === false;
    });
  };

  updateNetworkData = () => {
    let params = this.props.params || {
      org_id: this.clusterModel.cluster.organization
        ? this.clusterModel.cluster.organization.id
        : this.props.match.params.id,
      own: true,
      size: 1,
      page: 0,
    };
    params.page = 0;
    NetworkController.getNetworks(params, true, false, true).then(resp => {
      if (resp && resp.length > 0) {
        this.updateOrgsNetworkData(
          this.networkModel.pageable.total_count / 100,
          [],
          true,
        );
      }
    });
  };

  isNetworkDeleted(networks) {
    if (networks) {
      let deleted_networks = networks.filter(network => {
        return network.deleted_at;
      });
      return deleted_networks.length > 0 ? true : false;
    }
    return false;
  }

  hasNetworkStatus(networks) {
    if (networks) {
      let connected_networks = networks.filter(network => {
        return network.status && network.status.connected_networks;
      });
      return connected_networks.length > 0 ? true : false;
    }
    return false;
  }
  componentWillUnmount() {
    // clear all network/services related periodic api
    clearInterval(this.networkApiByInterval);
    clearInterval(this.serviceInterval);
    clearInterval(this.interval);
  }

  enableAppStore = e => {
    this.setState({ showAppStore: true });
  };

  closeModal = () => {
    this.setState({ showAppStore: false });
  };

  getNetworksByInterval = () => {
    let networks = this.networkModel.networks.filter(value => {
      if (value && value.cluster)
        return value.cluster.id === this.props.cluster_id;
    });
    if (networks && networks.length > 1) {
      this.isNetworkThere = true;
    } else {
      this.isNetworkThere = false;
    }
    if (this.isNetworkDeleted(networks) || this.hasNetworkStatus(networks)) {
      clearInterval(this.networkApiByInterval);
      this.networkApiByInterval = setInterval(() => {
        let params = this.props.params || {
          org_id: this.clusterModel.cluster.organization
            ? this.clusterModel.cluster.organization.id
            : this.props.match.params.id,
          own: true,
          size: 1,
          page: 0,
        };
        NetworkController.getNetworks(params, false, true, false).then(
          response => {
            if (response && response.config && response.config.params) {
              if (response.data && response.data.results.length === 0) {
                this.networkModel.resetNetworks();
              }

              this.updateOrgsNetworkData(
                response.data.total_count / 100,
                [],
                false,
              );
              // check for clear interval
              if (this.networkModel.networks.length === 1) {
                clearInterval(this.networkApiByInterval);
              }
            }
          },
        );
      }, 30000);
    }
  };

  getCurrentNodeNetworks = (networks, node_id) => {
    let currentNetworks = networks.filter(network => {
      if (network && network.cluster) {
        return (
          network.cluster.id === node_id &&
          (!network.is_wan_network || network.is_wan_network === false)
        );
      }
    });
    return currentNetworks;
  };

  appModalFooter = () => {
    return [
      <Button key="back" onClick={this.closeModal} type="primary">
        Cancel
      </Button>,
    ];
  };

  onExpand = (expanded, record) => {
    let keys = this.state.expandedRowKeys;
    let index = keys.findIndex(val => {
      return val === record.id;
    });
    if (expanded) {
      if (index === -1) {
        keys.push(record.id);
      }
      this.setExpandedRowKeys(keys);
    } else {
      if (index !== -1) {
        keys.splice(index, 1);
      }
      this.setExpandedRowKeys(keys);
    }
  };

  expandRow = () => {
    let keys = this.serviceModel.services.map(item => {
      return item.id;
    });
    this.setExpandedRowKeys(
      this.state.expandedRowKeys.length !== keys.length ? keys : [],
    );
  };

  setExpandedRowKeys = keys => {
    this.setState({ expandedRowKeys: keys });
  };

  showStatus = record => {
    if (record) {
      let isExpandedRow = this.isExpandedRow(record.id);
      this.onExpand(!isExpandedRow, record);
      this.setState({ key: Math.random() }); // Random Key generated and reassigned in order to re-render the component to update the `show status` text.
    }
  };

  isExpandedRow = rowId => {
    if (rowId) {
      let expandedRowKeys = this.state.expandedRowKeys;
      if (expandedRowKeys) {
        return expandedRowKeys.includes(rowId);
      }
    }
    return false;
  };

  createAfterCall = resp => {
    this.serviceList(true, 0, this.state.searchText);
    this.resetSelectedService();
  };

  deleteAfterCall = () => {
    this.resetSelectedService();
    this.serviceList(true, 0, this.state.searchText);
  };

  serviceListFilter = e => {
    clearTimeout(this.searchTimeout);
    let searchText = e.target.value;
    this.setState({ searchText: searchText });
    this.searchTimeout = setTimeout(() => {
      this.serviceList(false, 0, searchText);
    }, 500);
  };

  clearSearchFilter = () => {
    this.setState({ searchText: "" });
    this.serviceList(true, 0, "");
  };

  serviceList = (
    loading = true,
    page = 0,
    search = "",
    forceCall = false,
    resolveData = true,
  ) => {
    let params = this.props.params || {
      cluster_id: this.props.match.params.cluster_id
        ? this.props.match.params.cluster_id
        : this.props.cluster_id,
    };
    params.page = page;
    params.search = search;
    params.inheritance = false;
    ServiceController.getServices(params, loading, forceCall, resolveData);
  };

  lazyLoad = () => {
    if (this.serviceModel.pageable.next) {
      let page = this.serviceModel.pageable.page + 1;
      this.serviceList(true, page, this.state.searchText);
    }
  };

  updateServiceList = () => {
    for (let i = 0; i <= this.serviceModel.pageable.page; i++) {
      this.serviceList(false, i, this.state.searchText, true);
    }
  };

  getServicesByInterval = () => {
    /* call service api at regular intervals */
    clearInterval(this.serviceInterval);
    this.serviceInterval = setInterval(() => {
      let pageCount =
        this.serviceModel &&
        this.serviceModel.pageable &&
        this.serviceModel.pageable.page
          ? this.serviceModel.pageable.page
          : 0;
      let params = [];
      for (let page = 0; page <= pageCount; page++) {
        params.push({
          cluster_id: this.props.match.params.cluster_id
            ? this.props.match.params.cluster_id
            : this.props.cluster_id,
          page: page,
          inheritance: false,
          search: this.state.searchText,
        });
      }
      ServiceController.QueryAllServices(params, false);
    }, 30000);
  };

  updateOrgsNetworkData(totalCount, availableNetworks, loading) {
    if (totalCount > 0) {
      let allParams = [];
      for (let page = 0; page < totalCount; page++) {
        allParams.push({
          org_id: this.clusterModel.cluster.organization
            ? this.clusterModel.cluster.organization.id
            : this.props.match.params.id,
          own: true,
          size: 100,
          page: page,
        });
      }
      NetworkController.getAllNetworksForDropdown(allParams, loading).then(
        resp => {
          if (resp) {
            availableNetworks = availableNetworks.concat(resp);
            this.setState({ availableNetworks: availableNetworks });
            let clusterNetworks = availableNetworks.filter(networks => {
              if (
                networks.cluster &&
                networks.cluster.id === this.clusterModel.cluster.id
              )
                return networks;
            });
            if (clusterNetworks.length === 1) {
              this.networkModel.resetNetworks();
              this.networkModel.addNetworks(clusterNetworks);
            }
            if (clusterNetworks.length > 1) {
              this.isNetworkThere = true;
            }
            this.setState({ isNetworkDataLoaded: true });
            /*API CALL every 1 Minute Intiated HERE. Based on delete_at constarins*/
            this.getNetworksByInterval();

            /*update templatingService model with node and network details. LAT-4069 */
            let nodelist = [
              {
                name: this.clusterModel.cluster.name,
                id: this.clusterModel.cluster.id,
                organization: this.clusterModel.cluster.organization,
              },
            ];
            let currentNodeNetworks = this.getCurrentNodeNetworks(
                this.networkModel.networks,
                this.clusterModel.cluster.id,
              ),
              currentNetworks = [];
            currentNodeNetworks.map(network => {
              currentNetworks.push({
                name: `${network.name} (Network CIDR : ${network.config.network.cidr})`,
                id: network.id,
                config: network.config,
                type: network.type,
                status: network.status,
                display_name: network.name,
              });
              return null;
            });

            this.props.UiStore.templatingService.nodeDetails = {
              nodeList: nodelist,
              networkList: currentNetworks, //this.inodeModel.inode.networks,
            };
          }
        },
      );
    }
  }

  handleRestart = () => {
    let record = this.state.currentService ? this.state.currentService : {};
    //API REQ BEGINS HERE
    ServiceController.restart(record && record.id)
      .then(response => {
        this.setState(
          {
            message: response.message,
            showRestartmod: false,
            loading: false,
            currentService: {},
          },
          () => {
            this.serviceList(true, 0, this.state.searchText);
          },
        );
      })
      .catch(error => {
        this.setState({
          showRestartmod: false,
          loading: false,
          currentService: {},
        });
      });
  };

  updateiNodeDetails = () => {
    let params = {
      cluster_id: this.props.cluster_id,
      profile_name: ["Virtual Edge", "Edge"],
      state: ["UNREACHABLE", "ALIVE"],
    };
    (params.page = 0), (params.size = 1);
    InodeController.getInodesForDropdown(params, false).then(response => {
      if (response) {
        this.updateOrgsiNodeData(response.total_count / 100, []);
      }
    });
  };

  /**
   *  UPDATE all inodes Data
   */
  updateOrgsiNodeData(totalCount, availableNodes) {
    let NewLogSupportedNodes = [];
    let RestartServiceSupportedNodes = [];
    let isALiveNodes = [];
    let olderNodes = [];
    if (totalCount > 0) {
      let allParams = [];
      for (let page = 0; page < totalCount; page++) {
        allParams.push({
          cluster_id: this.props.cluster_id,
          size: 100,
          page: page,
          profile_name: ["Virtual Edge", "Edge"],
          state: ["UNREACHABLE", "ALIVE"],
        });
      }
      InodeController.getAllInodesForDropdown(allParams, false).then(resp => {
        if (resp) {
          availableNodes = availableNodes.concat(resp);
          if (availableNodes.length > 0) {
            availableNodes.forEach(nodes => {
              if (
                parseFloat(getNodeVersion(nodes)) >= LiveLogInfraBaseversion
              ) {
                NewLogSupportedNodes.push(nodes.id);
                if (nodes.node_state.toLowerCase() === "alive") {
                  isALiveNodes.push(nodes.id);
                }
              } else if (
                parseFloat(getNodeVersion(nodes)) <= LiveLogInfraBaseversion
              ) {
                olderNodes.push(nodes.id);
              }
              if (
                parseFloat(getNodeVersion(nodes)) >= RestartServiceBaseVersion
              ) {
                RestartServiceSupportedNodes.push(nodes.id);
              }
            });
          }
          this.setState({
            RestartServiceSupportedNodes: RestartServiceSupportedNodes,
            NewLogSupportedNodes: NewLogSupportedNodes,
            isALiveNodes: isALiveNodes,
            olderNodes: olderNodes,
          });
        }
      });
    }
  }

  render() {
    let iconStyle = { height: 16, width: 16 };
    let container = (
      <div>
        <Card>
          <RouteTab
            activeKey={this.state.tabKey}
            onChange={this.onTabChange}
            {...this.props}
          >
            {this.props.AuthStore.IsPermitted("NODE:VIEW") && (
              <TabPane
                tab={
                  <span>
                    <Icons type="fa" name="FaRegHdd" style={iconStyle} />
                    {getCurrentlocaleText("clusters.inodes.table.column.text")}
                  </span>
                }
                key={this.TAB_KEYS.inodes}
              >
                <ClusteriNodesList
                  ClusterediNodeList={this.props.ClusterediNodeList}
                  fetchInodeList={this.props.fetchInodeList}
                  showMasterWarning={this.props.showMasterWarning}
                  isMasterUnAvailable={this.props.isMasterUnAvailable}
                  className="clusterNodeList"
                  clusterId={
                    this.props.match.params.cluster_id
                      ? this.props.match.params.cluster_id
                      : this.props.cluster_id
                  }
                />
              </TabPane>
            )}
            {this.props.AuthStore.IsPermitted("NETWORK:CONNECT_DISCONNECT") && (
              <TabPane
                tab={
                  <span>
                    <Icons
                      type="fa"
                      name="FaConnectdevelop"
                      style={iconStyle}
                    />
                    {getCurrentlocaleText("networks.tabheader.label.text")}
                  </span>
                }
                key={this.TAB_KEYS.networks}
              >
                <NetworksList
                  params={{
                    cluster_id: this.props.cluster_id,
                    inheritance: false,
                  }}
                  orgId={this.props.orgId}
                  currentClusterDetails={this.props.currentClusterDetails}
                  updateINodeDetails={this.props.updateINodeDetails}
                  updateServiceList={this.updateServiceList}
                  cameFromOrgDetailPage={
                    this.props.match.params.nodeId ? true : false
                  }
                  tabIndex={this.state.tabKey}
                  getNetworksByInterval={this.getNetworksByInterval}
                  isNetworkDataLoaded={this.state.isNetworkDataLoaded}
                  availableNetworks={this.state.availableNetworks}
                  {...this.props}
                  scroll={{ y: 340 }}
                  className="clusterNetworkList"
                />
              </TabPane>
            )}

            {/* service templates implementation - start*/}
            {this.props.AuthStore.IsPermitted("SERVICE:VIEW") && (
              <TabPane
                tab={
                  <span>
                    <Icons type="fa" name="FaCogs" style={iconStyle} />{" "}
                    {getCurrentlocaleText(
                      "node.list.table.node_services.label.text",
                    )}
                  </span>
                }
                key={this.TAB_KEYS.services}
              >
                <LoadingComponent loading={this.props.ClusterViewModel.loading}>
                  <div>
                    <div
                      style={{
                        marginBottom: "10px",
                        display: "inline-flex",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      {this.serviceModel.services.length > 0 && (
                        <span>
                          <a
                            style={{
                              fontSize: 14,
                              marginLeft: "20px",
                              display: "inline-flex",
                            }}
                            onClick={this.expandRow}
                          >
                            <span>
                              <Icons
                                className={"valign-middle"}
                                type="ai"
                                name={
                                  this.state.expandedRowKeys.length !==
                                  this.serviceModel.services.length
                                    ? "AiOutlinePlusSquare"
                                    : "AiOutlineMinusSquare"
                                }
                              />
                              <span style={{ marginLeft: 5 }}>
                                {this.state.expandedRowKeys.length !==
                                this.serviceModel.services.length
                                  ? getCurrentlocaleText(
                                      "node.list.table.expand_all.label.text",
                                    )
                                  : getCurrentlocaleText(
                                      "node.list.table.collapse_all.label.text",
                                    )}
                              </span>
                            </span>
                          </a>
                        </span>
                      )}
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      ></div>
                      <div
                        style={{
                          marginLeft: "20px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        {this.props.AuthStore.IsPermitted("SERVICE:CREATE") && (
                          <div>
                            <Button
                              title={
                                this.isNetworkThere
                                  ? "Add Service"
                                  : "No network available to create services"
                              }
                              type="primary"
                              onClick={this.enableAppStore}
                              disabled={!this.isNetworkThere}
                            >
                              <Icons type="ai" name="AiOutlinePlus" />
                            </Button>
                            {this.state.showAppStore && (
                              <Modal
                                visible={this.state.showAppStore}
                                onCancel={this.closeModal}
                                width="70%"
                                maskClosable={false}
                                footer={this.appModalFooter()}
                              >
                                <Row>
                                  <div style={{ marginTop: "25px" }}>
                                    <AppStore
                                      {...this.props}
                                      isCluster={true}
                                    />
                                  </div>
                                </Row>
                              </Modal>
                            )}
                          </div>
                        )}

                        {this.props.AuthStore.IsPermitted("SERVICE:CREATE") && (
                          <ActionBtn
                            title={
                              <span>
                                <Icons type="ai" name="AiOutlineDelete" />{" "}
                                Delete Services
                              </span>
                            }
                            resource="service"
                            action="delete"
                            disabled={_.isEmpty(this.state.selectedService)}
                            iconButton={true}
                            icon="AiOutlineDelete"
                            formData={this.state.selectedService}
                            afterCall={this.deleteAfterCall}
                            controller={ServiceController}
                            cancelText={getCurrentlocaleText(
                              "delete.confirmation.cancel.text",
                            )}
                          >
                            <Icons type="ai" name="AiOutlineDelete" />{" "}
                            {getCurrentlocaleText(
                              "delete.confirmation.ok.text",
                            )}
                          </ActionBtn>
                        )}
                        {this.props.AuthStore.IsPermitted("SERVICE:CREATE") &&
                          parseFloat(
                            getNodeVersion(this.props.ClusterViewModel.cluster),
                          ) < RestartServiceBaseVersion && (
                            <Button
                              type=""
                              title="Reboot Services"
                              disabled={_.isEmpty(this.state.selectedService)}
                              onClick={() => {
                                notificationModal("info", {
                                  title: "Restart Services",
                                  data:
                                    "To restart the Services, please reboot the iNode.",
                                });
                              }}
                            >
                              <Icons
                                type="md"
                                name="MdRefresh"
                                style={{ fontSize: 16 }}
                              />
                            </Button>
                          )}
                      </div>
                    </div>
                    <div>
                      <Modal
                        title={getCurrentlocaleText(
                          "service.restart.title.text",
                        )}
                        visible={this.state.showRestartmod}
                        onCancel={this.closeServiceRestartModal}
                        onOk={this.handleRestart}
                        maskClosable={false}
                        confirmLoading={this.state.loading}
                        closable={!this.state.loading}
                        okText={getCurrentlocaleText("service.restart.ok.text")}
                        cancelText={getCurrentlocaleText(
                          "service.restart.cancel.text",
                        )}
                      >
                        <span>
                          <h4 style={{ marginBottom: 15 }}>
                            <p>
                              {getCurrentlocaleText("service.restart.text1")}
                            </p>
                            <p>
                              <Tag className="tag-iotium-info">
                                {this.state.currentService &&
                                  this.state.currentService.name}
                              </Tag>
                            </p>
                            <p>
                              {getCurrentlocaleText("service.restart.text2")}
                            </p>
                          </h4>
                        </span>
                      </Modal>
                      <Row />
                      <Row>
                        <Col span={24}>
                          {this.serviceModel.services.length > 0 ? (
                            <div>
                              <TableLayout
                                key={this.state.key}
                                columns={this.ServiceTemplatesTablecolumns}
                                dataSource={this.serviceModel.serviceTableData}
                                pagination={false}
                                selectedRows={this.selectService.bind(null)}
                                selectAll={this.selectServiceAll.bind(null)}
                                rowClick={this.rowClick}
                                expandedRowRender={(
                                  record,
                                  index,
                                  intent,
                                  expanded,
                                ) => {
                                  let extraInfo = {
                                    skysparkWarningPopover: this.state
                                      .skysparkWarningPopover,
                                    NewLogSupportedNodes: this.state
                                      .NewLogSupportedNodes,
                                    isALiveNodes: this.state.isALiveNodes,
                                    RestartServiceSupportedNodes: this.state
                                      .RestartServiceSupportedNodes,
                                  };

                                  return this.serviceModel.showExtraDetails(
                                    record.serviceData,
                                    index,
                                    intent,
                                    expanded,
                                    true,
                                    extraInfo,
                                  );
                                }}
                                expandedRowKeys={[
                                  ...this.state.expandedRowKeys,
                                ]}
                                onExpand={this.onExpand}
                                className="clusterServiceList"
                                hasLazyLoading={true}
                                lazyLoad={this.lazyLoad}
                                scroll={{ y: 320 }}
                              />
                            </div>
                          ) : (
                            <div style={{ minHeight: 208 }}>
                              {!this.serviceModel.loading && (
                                <span>
                                  <h3>
                                    {getCurrentlocaleText(
                                      "no.services.to.display",
                                    )}
                                  </h3>
                                </span>
                              )}
                            </div>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </LoadingComponent>
              </TabPane>
            )}
            <TabPane
              tab={
                <span>
                  <Icons type="md" name="MdEvent" />{" "}
                  <Icons type="fa" name="FaCog" className="event-tab-icon" />
                  {getCurrentlocaleText("events.text")}
                </span>
              }
              key={this.TAB_KEYS.events}
            >
              <ClusterEventList
                clusterId={
                  this.props.match.params.cluster_id
                    ? this.props.match.params.cluster_id
                    : this.props.cluster_id
                }
              />
            </TabPane>
          </RouteTab>
        </Card>
      </div>
    );

    return (
      <LoadingComponent loading={this.clusterModel.loading}>
        {container}
      </LoadingComponent>
    );
  }
}

export default ClusterTabs;
