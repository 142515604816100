import IoSidebar from "./Sidebar";
import IoMenu from "./Menu";
import IoMain from "./Main";
import IoHeader from "./Header";

const IoLayouts = {
  IoSidebar,
  IoMenu,
  IoMain,
  IoHeader,
};

export default IoLayouts;
