import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { LoadingOutlined } from "@ant-design/icons";
import NetworkController from "controller/NetworkController";
import ConnectedNodesContainer from "./ConnectedNodesContainer";

@inject("NetworkViewModel")
@observer
class ConnectedNodes extends Component {
  static NOT_APPLICAPLE = (<p style={{ marginLeft: 60 }}>{"-"}</p>);

  constructor(props) {
    super(props);
    this.networkModel = this.props.NetworkViewModel;
    this.state = {
      connectedNetworks: [],
      connectedNodesTemplate: null,
      networkId: null,
      nodeId: null,
      connectionStatus: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.updateNetworkInfo(this.props.nodeId);
    this.setState({ connectionStatus: true });
    if (this.props.networkId) {
      this.setState({ networkId: this.props.networkId });
    }

    if (this.props.nodeId) {
      this.setState({ nodeId: this.props.nodeId });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.nodeId !== nextProps.nodeId) {
      this.setState({ nodeId: this.props.nodeId });
      this.updateNetworkInfo(nextProps.nodeId);
    }
  }

  /**
   *  Method to get network count to intiate paginated api call
   * @param {*} nodeId
   */
  updateNetworkInfo(nodeId) {
    let params = {
      node_id: nodeId,
    };
    (params.page = 0), (params.size = 1);
    NetworkController.getNetworkByNodeId(params, false).then(response => {
      if (response) {
        //paginated api call based on todatl count
        this.updateNodeNetworkInfo(response.total_count / 100, [], nodeId);
      }
    });
  }

  /**
   *  Method to get all network for an iNode
   * @param {*} totalCount
   * @param {*} availabelNetworks
   * @param {*} nodeId
   */
  updateNodeNetworkInfo(totalCount, availabelNetworks = [], nodeId) {
    if (nodeId) {
      if (totalCount > 0) {
        let allParams = [];
        for (let page = 0; page < totalCount; page++) {
          allParams.push({
            node_id: nodeId,
            size: 100,
            page: page,
          });
        }
        NetworkController.getAllNetworksForDropdown(
          allParams,
          false,
          true,
        ).then(response => {
          if (response) {
            availabelNetworks = availabelNetworks.concat(response);
            availabelNetworks.map(network => {
              if (
                network &&
                network.connected_networks &&
                network.connected_networks.length > 0
              ) {
                this.state.connectedNetworks.push(
                  ...network.connected_networks,
                );
              }
            });
            // Because axios reponse is asyn that's why its placed inside of then.
            if (this.state.connectedNetworks) {
              this.setState({
                connectedNodesTemplate: ConnectedNodesContainer.templateConnectedNodesAsTag(
                  this.state.connectedNetworks,
                  this.props.networkId,
                  this.props.showRepNetwork,
                  this.props.tanCIDR,
                  this.props.isVirtualNode,
                  this.props.canShowPopOverHelp,
                  this.props.OnClick,
                  this.props.cursor,
                  this.props.showPeers,
                ),
                connectionStatus: true,
              });
            } else {
              this.setState({
                connectedNodesTemplate: (
                  <span>{ConnectedNodes.NOT_APPLICAPLE}</span>
                ),
                connectionStatus: true,
              });
            }
          }
        });
      }
    } else {
      this.setState({ connectionStatus: true });
    }
  }
  connectedNodes(networks, networkId) {
    let connectedNetworks = [];
    if (networks && networkId) {
      let network = null;
      networks.forEach(n => {
        if (n.id === networkId) {
          network = n;
        }
      });
      if (
        network &&
        network.connected_networks &&
        network.connected_networks.length > 0
      ) {
        connectedNetworks.push(...network.connected_networks);
      }
    }

    return ConnectedNodesContainer.templateConnectedNodesAsTag(
      connectedNetworks,
      this.props.networkId,
      this.props.showRepNetwork,
      this.props.tanCIDR,
      this.props.isVirtualNode,
      this.props.canShowPopOverHelp,
      this.props.OnClick,
      this.props.cursor,
      this.props.showPeers,
    );
  }

  render() {
    return (
      <div>
        {!this.state.connectionStatus ? (
          <LoadingOutlined spin>
            <span className="table-loading-font">Loading</span>
          </LoadingOutlined>
        ) : this.props.nodeId ? (
          ConnectedNodesContainer.templateConnectedNodesAsTag(
            this.state.connectedNetworks,
            this.props.networkId,
            this.props.showRepNetwork,
            this.props.tanCIDR,
            this.props.isVirtualNode,
            this.props.canShowPopOverHelp,
            this.props.OnClick,
            this.props.cursor,
            this.props.showPeers,
          )
        ) : (
          this.connectedNodes(
            this.networkModel.connectedNetworks,
            this.props.networkId,
          )
        )}
      </div>
    );
  }
}

export default ConnectedNodes;
