import React, { Component } from "react";
import { string } from "prop-types";
import { Button, Row, Col, Modal, Icon, Popover } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

import Icons from "components/UI-Components/Icons";
import { observer, inject } from "mobx-react";
import { elapsedTimeString, getCurrentlocaleText } from "Core/Utils";
import InodeController from "controller/InodeController";

const DONE = "done";
const CANCELLED = "cancelled";
const EOF = "eof";

@inject("UiStore", "InodeViewModel")
@observer
class TechDumpStatus extends Component {
  static propTypes = {
    Status: string.isRequired,
    nodeId: string.isRequired,
  };
  static defaultProps = {
    Status: "IN PROGRESS",
  };
  constructor(props) {
    super(props);
    this.techdumpStatusByInterval = null;
    this.inodeModel = this.props.InodeViewModel;
    this.state = {
      visible: false,
      loading: false,
    };
    //Color code for different request status
    this.colorCode = {
      INITIATED: "#108ee9",
      CANCELLED: "#f04134",
      done: "#00a854",
      "IN PROGRESS": "#ffbf00",
    };
  }

  componentDidMount() {
    if (this.techdumpStatusByInterval) {
      clearInterval(this.techdumpStatusByInterval);
    }
    // /*API CALL EVERY 60 SEC Intiated HERE */
    if (
      this.inodeModel.techdumpStatus &&
      !this.inodeModel.techdumpStatus.completed_at &&
      this.inodeModel.techdumpStatus.status &&
      this.inodeModel.techdumpStatus.status.toLowerCase() !== DONE
    ) {
      this.techdumpStatusByInterval = setInterval(() => {
        InodeController.getTechdump(this.props.nodeId);
      }, 60000);
    }
  }

  componentWillUnmount = () => {
    clearInterval(this.techdumpStatusByInterval);
  };

  // change the visbility status of the Modal
  changeModalState = status => {
    this.setState({ visible: status, loading: false });
  };

  // abort request for techdump trigger
  abortRequest = () => {
    this.setState({ loading: true });
    InodeController.cancelTechdump(this.props.nodeId)
      .then(response => {
        if (response) {
          let val = this.inodeModel.techdumpStatus;
          if (
            val &&
            val.completed_at &&
            val.status &&
            val.status.toLowerCase() === DONE
          ) {
            this.props.UiStore.successMessage = getCurrentlocaleText(
              "techdump.send.success.text",
            );
          } else {
            this.props.UiStore.successMessage = getCurrentlocaleText(
              "techdump.send.abort.text",
            );
          }
          this.inodeModel.setTechdumpStatus([]);
          clearInterval(this.techdumpStatusByInterval);
          this.getTechdump();
        }
      })
      .catch(error => {
        this.changeModalState(false);
        clearInterval(this.techdumpStatusByInterval);
      });
  };

  // Fetch techdump
  getTechdump = () => {
    InodeController.getTechdump(this.props.nodeId);
  };

  render() {
    return (
      <>
        <Col
          xs={{ span: 12 }}
          sm={{ span: 12 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
          style={{ display: "flex" }}
        >
          {this.inodeModel.techdumpStatus.status.toLowerCase() !== CANCELLED &&
          this.inodeModel.techdumpStatus.status.toLowerCase() !== EOF ? (
            <div>
              <Button loading>
                {getCurrentlocaleText("techdump.sending.text")}
              </Button>
              <Popover
                placement="top"
                content={
                  <span>
                    <span>
                      {getCurrentlocaleText("techdump.elapsed_time.text")}
                    </span>
                    {elapsedTimeString(
                      this.inodeModel.techdumpStatus.created_at,
                    )}
                  </span>
                }
              >
                <a>
                  <Icons type="fa" name="FaRegClock" />
                </a>
              </Popover>
            </div>
          ) : null}
        </Col>
        <Col
          xs={{ span: 12 }}
          sm={{ span: 12 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          {this.inodeModel.techdumpStatus.status.toLowerCase() !== CANCELLED &&
          this.inodeModel.techdumpStatus.status.toLowerCase() !== EOF ? (
            <Button
              icon={<CloseCircleOutlined />}
              onClick={this.changeModalState.bind(this, true)}
            >
              {getCurrentlocaleText("techdump.abort.button.text")}
            </Button>
          ) : null}
        </Col>
        <Modal
          title={
            <span>
              <Icons type="ai" name="AiOutlineCloseCircle" /> &nbsp;{" "}
              {getCurrentlocaleText("techdump.abort.modal.caption")}
            </span>
          }
          visible={this.state.visible}
          onOk={this.triggerRequest}
          onCancel={this.hideTechDumpTriggerModal}
          maskClosable={false}
          confirmLoading={this.state.loading}
          closable={!this.state.loading}
          width={800}
          footer={[
            <Button
              key="cancel"
              size="large"
              onClick={this.changeModalState.bind(this, false)}
              disabled={this.state.loading}
            >
              {getCurrentlocaleText("techdump.cancel.text")}
            </Button>,
            <Button
              key="Abort"
              size="large"
              type="primary"
              icon={<CloseCircleOutlined />}
              onClick={this.abortRequest}
            >
              {" "}
              {getCurrentlocaleText("techdump.abort.text")}
            </Button>,
          ]}
        >
          <p>{getCurrentlocaleText("techdump.abort.modal.content")}</p>
        </Modal>
      </>
    );
  }
}

export default TechDumpStatus;
