import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Row, Col } from "antd";
import BreadcrumbComponent from "components/UI-Components/BreadcrumbComponent";
import Icons from "components/UI-Components/Icons";
import EventList from "components/Events/EventList";
import ClusterController from "controller/ClusterController";
import { getCurrentlocaleText } from "Core/Utils";
import OrgSelector from "components/Orgs/OrgSelector";

@inject("UiStore")
@observer
class ClusterEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clusterId: this.props.match.params.cluster_id,
      orgId: this.props.match.params.id,
      BreadcrumbList: [],
    };
  }

  componentDidMount() {
    ClusterController.getCluster(this.state.clusterId, true, false).then(
      res => {
        if (res) {
          let BreadcrumbList = [];
          BreadcrumbList.push({
            name: res.organization ? res.organization.name : "-",
            link: `orgs/${res.organization.id}`,
          });
          BreadcrumbList.push({
            name: getCurrentlocaleText("clusters.navigation_menu.text"),
            link: `orgs/${res.organization.id}/clusters`,
          });
          BreadcrumbList.push({
            name: res.name,
            link: `orgs/${res.organization.id}/clusters/${res.id}`,
          });
          BreadcrumbList.push({
            name: getCurrentlocaleText("events.text"),
            link: `orgs/${res.organization.id}/clusters/${res.id}/events`,
          });
          this.setState({ BreadcrumbList });
          this.props.UiStore.setOrgName(res.organization.name);
        }
      },
    );
  }

  render() {
    return (
      <div>
        <Row gutter={24} type="flex" align="middle">
          <Col lg={{ span: 18 }} xl={{ span: 20 }}>
            <h2 className="page-title">
              <Icons type="md" name="MdEvent" className="valign-middle" />
              <Icons type="fa" name="FaCog" className="event-header-icon" />
              {"  "}
              {getCurrentlocaleText("events.text")}
            </h2>
            <BreadcrumbComponent
              {...this.props}
              BreadcrumbList={this.state.BreadcrumbList}
            />
          </Col>
          <Col lg={{ span: 6 }} xl={{ span: 4 }} className="flex flex-h-right">
            <OrgSelector {...this.props} />
          </Col>
        </Row>
        <div style={{ minHeight: 360 }}>
          <Row gutter={16} type="flex" align="middle">
            <Col span={24}>
              <EventList clusterId={this.state.clusterId} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default ClusterEvents;
