import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Modal, Button, Badge, Tag } from "antd";
import Icons from "components/UI-Components/Icons";
import UserController from "controller/UserController";
import {
  getCurrentlocaleText,
  formatTimestampToIotiumLastAtTimeString,
} from "Core/Utils";

@inject("ModelStore", "UiStore")
@observer
class ResetUserPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showResetCnm: true,
      showResetStr: false,
      showResetmod: false,
      PassStrg: null,
      isResetRequestPending: true,
      userName: this.props.userName,
      userId: this.props.userId,
      reset_password_triggered_at: this.props.reset_password_triggered_at,
      password_count: this.props.password_count,
      updateAfterCall: this.props.updateAfterCall,
      NewPassword: null,
      loading: false,
      user_info: this.props.user_info,
    };
  }
  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.isResetRequestPending !== newProps.isResetRequestPending) {
      this.setState({
        isResetRequestPending: newprops.isResetRequestPending,
      });
    }
  }

  ShwConfirm = () => {
    this.setState({ showResetmod: true, loading: false });
  };
  ClsConfirm = () => {
    this.setState({ showResetmod: false, loading: false });
    setTimeout(() => {
      this.setState({
        isResetRequestPending: true,
        showResetStr: false,
        showResetCnm: true,
      });
    }, 500);
  };

  handleReset = () => {
    let params = {};
    if (this.state.isResetRequestPending) {
      params.force = true;
    }
    //API REQ BEGINS HERE
    this.setState({ showResetmod: false, loading: true });
    UserController.resetPassword({ id: this.state.userId }, params)
      .then(response => {
        this.props.UiStore.successMessage = getCurrentlocaleText(
          "user.support.reset_password.successmessage",
        );
        this.setState({
          NewPassword: response.message,
          isResetRequestPending: true,
          showResetStr: false,
          showResetCnm: true,
          loading: false,
        });
        this.props.updateAfterCall();
      })
      .catch(error => {
        this.setState({ loading: false });
      });
  };
  render() {
    let {
      reset_password_triggered_at,
      isResetRequestPending,
      userName,
    } = this.state;
    return (
      <div>
        {/* TODO - TS - move to action button  */}
        <Modal
          orgId={this.props.orgId}
          title={
            isResetRequestPending && reset_password_triggered_at
              ? getCurrentlocaleText("user.support.force_reset_password.title")
              : getCurrentlocaleText("user.support.reset_password.title")
          }
          visible={this.state.showResetmod}
          onCancel={this.ClsConfirm}
          onOk={this.handleReset}
          maskClosable={false}
          confirmLoading={this.state.loading}
          closable={!this.state.loading}
          okText={
            isResetRequestPending && reset_password_triggered_at
              ? getCurrentlocaleText(
                  "user.support.resend_reset.forcebutton.text",
                )
              : getCurrentlocaleText("user.support.resend_reset.button.text")
          }
          cancelText={
            isResetRequestPending && reset_password_triggered_at
              ? getCurrentlocaleText(
                  "user.support.cancel_reset.forcebutton.text",
                )
              : getCurrentlocaleText("user.support.cancel_reset.button.text")
          }
        >
          {isResetRequestPending && reset_password_triggered_at ? (
            <span>
              <h4 style={{ marginBottom: 15 }}>
                <p>
                  {getCurrentlocaleText(
                    "user.support.force_reset_password.requestpending.message1",
                  )}
                </p>
                <p>
                  <Tag className="tag-iotium-info">{userName}</Tag>
                </p>
                <p>
                  {getCurrentlocaleText(
                    "user.support.force_reset_password.requestpending.message2",
                    {
                      0: formatTimestampToIotiumLastAtTimeString(
                        reset_password_triggered_at,
                      ),
                    },
                  )}
                </p>
                <p>
                  {getCurrentlocaleText(
                    "user.support.force_reset_password.requestpending.message3",
                  )}
                </p>
              </h4>{" "}
            </span>
          ) : (
            <span>
              <h4 style={{ marginBottom: 20 }}>
                <p>
                  {getCurrentlocaleText(
                    "user.support.reset_password.requestpending.message1",
                  )}
                </p>
                <p>
                  <Tag className="tag-iotium-info">{userName}</Tag>
                </p>
                <p>
                  {getCurrentlocaleText(
                    "user.support.reset_password.requestpending.message2",
                    {
                      0: 24,
                    },
                  )}
                </p>
              </h4>{" "}
            </span>
          )}
        </Modal>
        <Badge count={this.state.password_count}>
          <Button
            title="Reset Password"
            onClick={this.ShwConfirm}
            style={{ minWidth: 50 }}
            disabled={this.props.disabled}
          >
            <Icons
              style={{ margin: 0, fontSize: 16 }}
              type="go"
              name="GoSync"
            />
          </Button>
        </Badge>
      </div>
    );
  }
}

export default ResetUserPassword;
