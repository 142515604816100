import Resources from "../Core/API/Resources";
import BaseController from "controller/BaseController";
import moment from "moment";
import axios from "axios";
import Qs from "qs";
import { getErrorMessage } from "Core/Utils";
import UiStore from "Stores/UiStore";

const BULK_USER_CREATE = Resources.getUrl("bulkUserCreate");
const BULK_USER_EDIT = Resources.getUrl("bulkUserEdit");
const BULK_NODE_CREATE = Resources.getUrl("bulkNodeCreate");
const BULK_NODE_EDIT = Resources.getUrl("bulkNodeEdit");
const BULK_NETWORK_CREATE = Resources.getUrl("bulkNetworkCreate");
const BULK_NETWORK_EDIT = Resources.getUrl("bulkNetworkEdit");
const BULK_JOBS_URL = Resources.getUrl("bulkJobHistory");
const BULK_JOB_URL = Resources.getUrl("bulkJobStatus");
const BULK_JOB_CSV_DOWNLOAD_URL = Resources.getUrl("bulkJobStatusCsv");
const BULK_USER_CREATE_TEMPLATE_URL = Resources.getUrl("downloadUsersTemplate");
const BULK_NODE_CREATE_TEMPLATE_URL = Resources.getUrl("downloadNodesTemplate");
const BULK_NOETWORK_CREATE_TEMPLATE_URL = Resources.getUrl(
  "downloadNetworksTemplate",
);
const DOWNLOAD_USERS_URL = Resources.getUrl("downloadUsers");
const DOWNLOAD_NODES_URL = Resources.getUrl("downloadNodes");
const DOWNLOAD_NETWORKS_URL = Resources.getUrl("downloadNetworks");
class BulkConfigController {
  /**
   *  Method to get users list
   *  @param noparams
   */
  getBulkJobs = (params = []) => {
    return new Promise((resolve, reject) => {
      let urls = [];
      params.forEach(param => {
        urls.push({ url: BULK_JOBS_URL, params: param });
      });
      BaseController.queryAll(urls)
        .then(response => {
          if (response) {
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  downloadUsers = (params = {}, loading = true, forceCall = false) => {
    let url = DOWNLOAD_USERS_URL.replace("v1", "v2");
    if (Object.keys(params).length > 0) {
      let queryString = Qs.stringify(params, { arrayFormat: "repeat" });
      url = url + "?" + queryString;
    }
    window.open(url, "_blank");
  };
  downloadNodes = (params = {}, loading = true, forceCall = false) => {
    let url = DOWNLOAD_NODES_URL.replace("v1", "v2");
    if (Object.keys(params).length > 0) {
      let queryString = Qs.stringify(params, { arrayFormat: "repeat" });
      url = url + "?" + queryString;
    }
    window.open(url, "_blank");
  };

  downloadNetworks = (params = {}, loading = true, forceCall = false) => {
    let url = DOWNLOAD_NETWORKS_URL.replace("v1", "v2");
    if (Object.keys(params).length > 0) {
      let queryString = Qs.stringify(params, { arrayFormat: "repeat" });
      url = url + "?" + queryString;
    }
    window.open(url, "_blank");
  };

  downloadUsersTemplate = () => {
    return new Promise((resolve, reject) => {
      let url = BULK_USER_CREATE_TEMPLATE_URL;
      window.open(url, "_blank");
    });
  };
  downloadNodesTemplate = () => {
    return new Promise((resolve, reject) => {
      let url = BULK_NODE_CREATE_TEMPLATE_URL;
      window.open(url, "_blank");
    });
  };
  downloadNetworksTemplate = () => {
    return new Promise((resolve, reject) => {
      let url = BULK_NOETWORK_CREATE_TEMPLATE_URL;
      window.open(url, "_blank");
    });
  };

  getBulkJob = (jobId, params = {}) => {
    let BULK_JOB_BY_ID_FULL = BULK_JOB_URL.replace("{:jobId}", jobId);
    params.timezone = moment.tz.guess();
    return new Promise((resolve, reject) => {
      BaseController.query(BULK_JOB_BY_ID_FULL, {}, params)
        .then(response => {
          if (response) {
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
  getBulkJobCsv = (jobId, params = {}) => {
    let BULK_JOB_CSV_DOWNLOAD_FULL = BULK_JOB_CSV_DOWNLOAD_URL.replace(
      "{:jobId}",
      jobId,
    );
    params.timezone = moment.tz.guess();
    return new Promise((resolve, reject) => {
      BaseController.query(BULK_JOB_CSV_DOWNLOAD_FULL, {}, params)
        .then(response => {
          if (response.error_status === false && response.data) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
  /**
   * Method to upload csv file
   */
  bulkJob = (resource, formData, action, params, callback) => {
    return new Promise((resolve, reject) => {
      let url;
      if (resource === "User") {
        if (action === "create") {
          url = BULK_USER_CREATE;
        } else if (action === "edit") {
          url = BULK_USER_EDIT;
        }
      }
      if (resource === "Node") {
        if (action === "create") {
          url = BULK_NODE_CREATE;
        } else if (action === "edit") {
          url = BULK_NODE_EDIT;
        }
      }
      if (resource === "Network") {
        if (action === "create") {
          url = BULK_NETWORK_CREATE;
        } else if (action === "edit") {
          url = BULK_NETWORK_EDIT;
        }
      }
      axios
        .post(url, formData, {
          onUploadProgress: callback,
        })
        .then(({ data: response }) => {
          if (response) {
            if (response.data && response.data.s3url) {
              response.data["url"] = response.data.s3url;
            }
            resolve(response);
          }
        })
        .catch(error => {
          UiStore.setErrorMessage(getErrorMessage(error));
          reject(error);
        });
    });
  };
}
export default new BulkConfigController();
