import React, { Component } from "react";
import { Row, Col, Input, Tabs, Button, Card, Avatar } from "antd";
import { Form } from "@ant-design/compatible";
import createReactClass from "create-react-class";
import { observer, inject } from "mobx-react";
import Twig from "twig";
import {
  isArray,
  isEmpty,
  deepMapValues,
  isObjectOrFunction,
  getCurrentlocaleText,
  isRegExp,
  isDate,
  isFunction,
  isArrayOfStrings,
  removeByObjectKey,
  copyObject,
  getMatches,
} from "Core/Utils";
import Jsonpath from "jsonpath";
import { toJS } from "mobx";
import ReactJson from "react-json-view";
import customImg from "assets/img/logo_custom.png";
import {
  SecretController,
  ServiceController,
  serviceTemplateController,
} from "controller/Controllers";
import ServiceTemplatesUtils from "components/serviceTemplates/Utils/ServiceTemplatesUtils";
/*import HTML elements*/
import {
  License,
  Secret,
  IpAddress,
  NetworkList,
  SecretList,
  EULA,
  NodeNetworkList,
  PasswordBox,
  ServiceNameTextBox,
  DropDown,
  TextBox,
  FileUpload,
  CheckBox,
  RadioBox,
  Textarea,
  JsonInputBox,
  DnsComponent,
  ToggleInput,
  NICList,
} from "components/serviceTemplates/lib/HtmlElements";
import DockerConfig from "components/DataLists/SecretDockerConfig";
import Icons from "../../../UI-Components/Icons";

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const { TextArea } = Input;
const ElementsList = {
  servicenametextbox: ServiceNameTextBox,
  dropdown: DropDown,
  license: License,
  ipaddress: IpAddress,
  fileupload: FileUpload,
  textarea: Textarea,
  eula: EULA,
  networklist: NetworkList,
  secretlist: SecretList,
  checkbox: CheckBox,
  radiobox: RadioBox,
  secret: Secret,
  textbox: TextBox,
  nodenetworklist: NodeNetworkList,
  passwordbox: PasswordBox,
  dockerconfig: DockerConfig,
  jsoninputbox: JsonInputBox,
  dnscomponent: DnsComponent,
  toggleinput: ToggleInput,
  niclist: NICList,
};
var CustomComponent = createReactClass({
  render: function() {
    var ElementType = this.props.elementType;
    return (
      <ElementType
        properties={this.props.elementProps}
        referenceForm={this.props.referenceForm}
        onFocus={this.props.onFocus}
        id={this.props.id}
        urlParams={this.props.urlParams}
        editmode={this.props.editmode}
        viewOnly={this.props.viewOnly}
        defaultValue={this.props.defaultValue}
        isSystemService={this.props.isSystemService}
        {...this.props}
        updateCurrentValueOnChange={(value, props) => {
          this.props.updateCurrentValueOnChange(value, props);
        }}
        className={
          this.props.className && this.props.className
            ? this.props.className
            : ""
        }
        uiSchema={this.props.baseSchema}
        secretLoaded={this.props.secretLoaded}
        computedOutputObject={this.props.computedOutputObject}
      />
    );
  },
});
var hasErrors = fieldsError => {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
};

@inject(
  "UiStore",
  "SecretViewModel",
  "InodeViewModel",
  "NetworkViewModel",
  "AuthStore",
  "ServiceViewModel",
)
@observer
class ServiceFormRender extends Component {
  constructor(props) {
    super(props);
    this.serviceViewModel = this.props.ServiceViewModel;
    this.state = {
      isView:
        this.props.match.path &&
        this.props.match.path.indexOf("/serviceview/") !== -1,
      currentTabKey: 1,
      copySchema: {},
      showResult: null,
      showHTMLComponentHelpContent: false,
      availableServices: [],
      showTabComponentHelpContent: false,
      disableSubmit: false,
      imageIcon: [],
      defaultValue: {},
      computedOutputObject: this.props.UiStore.computedOutputObject,
    };
    this.deepMapValues = deepMapValues;
    this.finalSpec = {};
    this.TabContents = [];
    this.specObj = {};
    (this.volumeObj = {}), (this.volume_mounts = {}), (this.vol_content = {});
    this.dockerConfigObj = {};
    this.selectedServiceName = "";
    this.customSpecObject = {};
  }

  onFocus = val => {
    if (val) {
      this.setState({
        showHTMLComponentHelpContent: true,
        showTabComponentHelpContent: false,
      });
      setTimeout(() => {
        /* timeout required for state to render */
        let ele = document.getElementById("htmlComponentHelpDiv");
        if (ele) {
          ele.innerHTML = val;
        }
      }, 300);
    } else {
      this.setState({
        showHTMLComponentHelpContent: false,
        showTabComponentHelpContent: false,
      });
    }
  };

  updateCurrentValueOnChange = (value, props, type, subtype, uniqId = null) => {
    let steps = this.props.uiSchema.steps;
    if (!steps) {
      steps = toJS(this.props.baseSchema).steps;
    }
    if (steps.length > 0) {
      steps.forEach((step, i) => {
        let elements = steps[i].elements;
        elements.forEach((element, index) => {
          if (
            element.type === type &&
            (subtype === null || subtype === element.props.subtype) &&
            uniqId === element["__id"]
          ) {
            if (
              this.props.ServiceViewModel.isNewLicsense &&
              this.props.ServiceViewModel.isNewLicsense === "NEW" &&
              element.props.subtype != "ssh"
            ) {
              if (this.props.ServiceViewModel.secretElementPath) {
                this.props.ServiceViewModel.secretElementPath = {
                  stepIndex: i,
                  elementIndex: index,
                };
                this.props.ServiceViewModel.secretElementPathMap[
                  element.__id
                ] = this.props.ServiceViewModel.secretElementPath;
              }
            } else if (
              this.props.ServiceViewModel.isNewSSH &&
              this.props.ServiceViewModel.isNewSSH === "NEW" &&
              element.props.subtype === "ssh"
            ) {
              if (this.props.ServiceViewModel.sshElementPath) {
                this.props.ServiceViewModel.sshElementPath = {
                  stepIndex: i,
                  elementIndex: index,
                };
                this.props.ServiceViewModel.secretElementPathMap[
                  element.__id
                ] = this.props.ServiceViewModel.sshElementPath;
              }
            } else if (element.props.subtype === "license") {
              if (this.props.ServiceViewModel.secretElementPath) {
                this.props.ServiceViewModel.secretElementPath = {
                  stepIndex: i,
                  elementIndex: index,
                };
                this.props.ServiceViewModel.secretElementPathMap[
                  element.__id
                ] = this.props.ServiceViewModel.secretElementPath;
              }
            }
            if (
              this.props.UiStore.computedOutputObject.steps[i].elements[index]
            ) {
              this.props.UiStore.computedOutputObject.steps[i].elements[index][
                props
              ] = value;
            }
            this.setState({
              computedOutputObject: copyObject(
                this.props.UiStore.computedOutputObject,
              ),
            });
          }
        });
      });
    }
  };

  componentDidMount() {
    /*  on load , activate first tab help content  */
    this.setState({
      showTabComponentHelpContent: true,
      showHTMLComponentHelpContent: false,
    });

    setTimeout(() => {
      /* timeout required for state to render */
      let ele = document.getElementById("tabComponentHelpDiv");
      if (ele) {
        ele.innerHTML = this.TabContents[0].help
          ? this.TabContents[0].help
          : "";
      }
    }, 500);

    let templateParams = {
      org_id: this.props.match.params.org_id
        ? this.props.match.params.org_id
        : this.props.UiStore.currentUser.organization.id,
      own: true,
    };
    serviceTemplateController.getTemplates(templateParams).then(res => {
      /* add services list */
      let serviceList =
        res &&
        res.length &&
        res.map(val => {
          return {
            id: val.id,
            ...val.template_metadata,
          };
        });
      this.setState({ availableServices: serviceList, imageIcon: [] });
      this.renderIcon();
    });

    /* LAT-5566 : To make tabPane non-clickable */
    let x = document.getElementsByClassName("ant-tabs-tab");
    let allDoms = x.length > 0 && Object.values(x);
    allDoms.forEach(dom => {
      dom.style.cursor = "not-allowed";
    });
    setTimeout(() => {
      if (
        document.getElementsByClassName("0firstInput") &&
        document.getElementsByClassName("0firstInput")[0]
      )
        document.getElementsByClassName("0firstInput")[0].focus();
    }, 300);
  }

  showServiceImage = (val, blob) => {
    return (
      <div>
        <Col
          span={4}
          style={{
            display: "flex",
            marginBottom: "10px",
          }}
        >
          <div className="service-card">
            {val.id.toLowerCase() !== "custom" && val.logo && (
              <div
                id={val.id}
                title={val.name}
                className="service-image"
                style={{
                  backgroundImage: "url(" + blob + ")",
                  backgroundSize: "100%",
                  backgroundRepeat: "no-repeat",
                  cursor: "pointer",
                  left: "10%",
                }}
              />
            )}
            {val.id.toLowerCase() !== "custom" && !val.logo && (
              <div className="service-image">
                <Avatar
                  style={{
                    backgroundColor: "#dc7c3a",
                    verticalAlign: "middle",
                    cursor: "pointer",
                    left: "10%",
                  }}
                  size="large"
                  title={val.name}
                >
                  {val.name}
                </Avatar>
              </div>
            )}
            {val.id.toLowerCase() === "custom" && (
              <div title={val.name}>
                <img src={customImg} className="service-custom-icon" />
              </div>
            )}
            <div className="service_text" title={val.name}>
              {val.name}
            </div>
          </div>
        </Col>
      </div>
    );
  };

  renderIcon = () => {
    let output = null;
    if (this.props.baseSchema && this.props.baseSchema.version === "2.0") {
      try {
        output = JSON.parse(this.props.baseSchema.output);
      } catch (err) {
        console.log("Service template output is not a json " + err);
        output = this.props.baseSchema.output;
      }
    } else output = this.props.baseSchema.output;
    let selectedService =
      output && output.labels
        ? output.labels.io_iotium_template
        : this.props.baseSchema.labels
        ? this.props.baseSchema.labels.io_iotium_template
        : null;
    let service = this.state.availableServices.find(val => {
      return (
        val &&
        val.id &&
        selectedService &&
        val.id.toLowerCase() === selectedService.toLowerCase()
      );
    });
    if (service) {
      if (service.logo) {
        let url = "/api/v2/servicetemplate/" + service.id + "/" + service.logo;
        serviceTemplateController.getImage(url).then(blobResponse => {
          if (blobResponse) {
            this.setState({
              imageIcon: this.showServiceImage(service, blobResponse),
            });
          }
        });
      } else {
        this.setState({ imageIcon: this.showServiceImage(service) });
      }
    }
  };

  renderUIElements = (elements, index) => {
    /*
      For ref :
      https://github.com/Azure/azure-resource-manager-schemas/blob/master/schemas/0.0.1-preview/CreateUIDefinition.CommonControl.json
    */
    return elements.map((ele, i) => {
      let x =
        ele.type.toLowerCase() === "dockerconfig" ? "servicetemplate" : null;

      let inEditMode = false;
      if (
        this.props.apiResponse &&
        Object.keys(this.props.apiResponse).length > 0 &&
        ele.props["noneditable"]
      ) {
        /* in edit mode. check for 'noneditable' attrs and disable resp HTML elements */
        /*  LAT-5143 */
        inEditMode = true;
      }
      return (
        ElementsList[ele.type.toLowerCase()] &&
        ele.visible && (
          <CustomComponent
            key={ele["__id"]}
            elementType={ElementsList[ele.type.toLowerCase()]}
            elementProps={ele.props}
            referenceForm={this.props.form}
            onFocus={this.onFocus}
            id={ele["__id"]}
            urlParams={this.props.match}
            renderFrom={x}
            editmode={inEditMode}
            {...this.props}
            viewOnly={this.state.isView}
            defaultValue={ele.defaultValue}
            secretLoaded={this.props.secretLoaded}
            isSystemService={this.props.isSystemService}
            updateCurrentValueOnChange={(value, props) => {
              if (this.props.baseSchema.version === "2.0") {
                let subtype =
                  ele.props && ele.props.subtype ? ele.props.subtype : null;
                this.updateCurrentValueOnChange(
                  value,
                  props,
                  ele.type,
                  subtype,
                  ele["__id"],
                );
              }
            }}
            computedOutputObject={this.state.computedOutputObject}
            uiSchema={this.props.baseSchema}
            className={i == 0 ? index + "firstInput" : ""}
          />
        )
      );
    });
  };

  showNextTab = () => {
    let x = this.props.match.url;
    if (x.includes("serviceview")) {
      /* for view only , do not validate as network may not be present if cidr is edited */
      /*set tab activeKey to next tab*/
      let next = parseInt(this.state.currentTabKey) + 1;
      this.TabContents && this.state.currentTabKey < this.TabContents.length
        ? this.setState({ currentTabKey: next })
        : "";
      /*  show resp tab help content. */
      this.showTabHelp();
    } else {
      /*move to next tab , when no error*/
      this.props.form.validateFields((err, values) => {
        if (!err) {
          /*set tab activeKey to next tab*/
          let next = parseInt(this.state.currentTabKey) + 1;
          this.state.currentTabKey < this.TabContents.length
            ? this.setState({ currentTabKey: next })
            : "";
          /*  show resp tab help content. */
          this.showTabHelp();
        }
      });
    }
    setTimeout(() => {
      let classSelector = parseInt(this.state.currentTabKey) - 1 + "firstInput";
      if (
        document.getElementsByClassName(classSelector) &&
        document.getElementsByClassName(classSelector)[0]
      )
        document.getElementsByClassName(classSelector)[0].focus();
    }, 300);
  };

  getServiceList = indexFromChild => {
    if (this.props.uiSchema && this.props.uiSchema.steps) {
      let steps = this.props.uiSchema.steps;
      let tabList = steps.find((step, i) => {
        let tabContentList = this.TabContents.find(tab => {
          return tab.label && tab.position === this.state.currentTabKey;
        });
        return tabContentList.label === step.label;
      });
      let ele = document.getElementById("tabClickHelpDiv_" + indexFromChild);
      if (ele) {
        ele.innerHTML = tabList.help;
      }
    }
  };

  renderTabElements = () => {
    return (
      this.TabContents &&
      this.TabContents.map((val, index) => {
        if (val.elements && val.elements.length > 0) {
          this.getServiceList(index);
          return (
            <TabPane tab={val.label} key={val.position}>
              <div
                style={{ marginBottom: 40, marginLeft: 5 }}
                id={"tabClickHelpDiv_" + index}
              ></div>
              {this.renderUIElements(val.elements, index)}
            </TabPane>
          );
        }
      })
    );
  };

  showTabHelp = () => {
    this.setState({
      showTabComponentHelpContent: true,
      showHTMLComponentHelpContent: false,
    });

    setTimeout(() => {
      /*
        timeout required for state to render.
        show help of current selected tab else help of first tab else none

      */
      let e = this.state.currentTabKey;

      let FirstElementID =
        this.TabContents &&
        this.TabContents[e - 1].elements &&
        this.TabContents[e - 1].elements[0] &&
        this.TabContents[e - 1].elements[0].__id;
      try {
        if (
          document.querySelector("#" + FirstElementID) &&
          document.querySelector("#" + FirstElementID).firstElementChild
        ) {
          document.getElementById(FirstElementID).focus();
          document
            .querySelector("#" + FirstElementID)
            .firstElementChild.focus();
        } else {
          document.getElementById(FirstElementID).focus();
        }
      } catch {
        let element = document.getElementById("tabComponentHelpDiv");
        if (element) {
          element.innerHTML = this.TabContents[e - 1].help
            ? this.TabContents[e - 1].help
            : this.TabContents[0].help
            ? this.TabContents[0].help
            : "";
        }
      }
    }, 400);
  };

  tabClick = e => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        /*if the clicked tab is <= the currentTab , move to that tab*/
        this.state.currentTabKey >= e
          ? this.setState({ currentTabKey: e })
          : "";
        /*  show resp tab help content. */
        this.showTabHelp();
      }
    });
  };

  gotoPrevious = e => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        /*if the clicked tab is <= the currentTab , move to that tab*/
        this.state.currentTabKey !== 1
          ? this.setState({ currentTabKey: this.state.currentTabKey - 1 })
          : "";
        /*  show resp tab help content. */
        this.showTabHelp();
      }
    });
    setTimeout(() => {
      let classSelector = parseInt(this.state.currentTabKey) - 1 + "firstInput";
      if (
        document.getElementsByClassName(classSelector) &&
        document.getElementsByClassName(classSelector)[0]
      )
        document.getElementsByClassName(classSelector)[0].focus();
    }, 300);
  };

  renderBaseTab = () => {
    return (
      <Tabs
        activeKey={this.state.currentTabKey.toString()}
        key="baseTab"
        type="card"
      >
        {this.renderTabElements()}
      </Tabs>
    );
  };

  addPropsForTabs = () => {
    /*adding uniqId for tabs*/
    this.TabContents &&
      this.TabContents.length > 0 &&
      this.TabContents.map((tab, index) => {
        /*Add isLast attribute to identify if it is last tab*/
        tab.isLast = ++index === this.TabContents.length ? true : false;
        /*Add tab position. Sequence is as per input */
        tab.position = index;
      });
  };

  showElements = () => {
    let keys = Object.keys(this.props.UiStore.templatingService.computedSchema);
    return keys.map((val, index) => {
      if (val === "steps") {
        this.TabContents = this.props.UiStore.templatingService.computedSchema[
          val
        ]; /* assign tab elements to this.Tabcontents*/
        this.addPropsForTabs(); /*Adding unique Id's for all tabs*/
        return this.renderBaseTab();
      }
    });
  };

  CreateSecretObj = (formkey, Formvalues, secretId) => {
    /*to find the volume type*/
    let subtype = formkey.split("_");
    subtype = subtype[subtype.length - 2];
    /*to find element id*/
    let ele_id = formkey.split("_");
    ele_id.splice(ele_id.length - 1, 1);
    ele_id = ele_id.join("_");
    if (formkey.indexOf("name") >= 0) {
      this.vol_content[ele_id] = {
        ...this.vol_content[ele_id],
        name: Formvalues[formkey],
      };
    } else if (formkey.indexOf("mountPath") >= 0) {
      this.vol_content[ele_id] = {
        ...this.vol_content[ele_id],
        mountPath: Formvalues[formkey],
      };
    } else if (formkey.indexOf("uploader") >= 0) {
      if (Formvalues[formkey]) {
        if (Formvalues[formkey] instanceof String) {
          let file = Formvalues[formkey].split("\\");
          this.vol_content[ele_id] = {
            ...this.vol_content[ele_id],
            fileName: file[file.length - 1],
          };
        } else {
          this.vol_content[ele_id] = {
            ...this.vol_content[ele_id],
            fileName: Formvalues[formkey].file.name,
          };
        }
      } else if (secretId) {
        Object.entries(Formvalues).forEach(([key, value]) => {
          if (secretId === key && secretId.length === key.length) {
            this.fetchVolume(value).then(
              response => {
                if (response.data) {
                  Object.entries(response.data).forEach(
                    ([dataKey, dataValue]) => {
                      this.vol_content[secretId] = {
                        ...this.vol_content[secretId],
                        fileName: dataKey,
                      };
                    },
                  );
                }
              },
              error => {
                console.log("error in secret api " + error);
                this.setState({
                  disableSubmit: false,
                });
                return true;
              },
            );
          }
        });
      }
    }
    if (!secretId) {
      /*create obj based on subtype*/
      this.createVolumeObject(subtype, ele_id);
      this.createVolumemountObject(subtype, ele_id);
    }
  };

  createVolumeObject = (subtype, ele_id) => {
    if (subtype.toLowerCase() === "emptydir") {
      /*volume obj*/
      this.volumeObj[ele_id] = {
        name: this.vol_content[ele_id].name,
        emptyDir: {},
      };
    }
  };

  createVolumemountObject = (subtype, ele_id) => {
    if (subtype.toLowerCase() === "emptydir") {
      /*volume_mounts obj*/
      this.volume_mounts[ele_id] = {
        name: this.vol_content[ele_id].name,
        mount_path: this.vol_content[ele_id].mountPath,
      };
    }
  };

  updateSecretObj = (response, secret, Formvalues, type) => {
    return new Promise((resolve, rej) => {
      /*create secret volume*/
      let secretVolume = {
        name: response.name,
        secret_volume: {
          secret: response.id,
        },
      };
      if (this.props.baseSchema.version === "2.0") {
        let fileNames = this.getFileKeys(response.data);
        secretVolume = {
          name: response.name,
          secret_volume: {
            secret: response.id,
          },
          fileNames: fileNames,
        };
      }
      this.volumeObj[secret] = secretVolume;
      this.volume_mounts[secret] = {
        name: response.name,
        mount_path: this.vol_content[secret].mountPath,
      };

      resolve(true);
    });
  };

  fetchVolume = id => {
    return new Promise((resolve, reject) => {
      if (id) resolve(SecretController.getSecret(id));
    });
  };

  getFileKeys = data => {
    let files = [];
    Object.entries(data).forEach(([key, value]) => {
      files.push(key);
    });
    return files;
  };

  postSecret = (secret, Formvalues) => {
    return new Promise((resolve, reject) => {
      let currentOrg = ServiceTemplatesUtils.getSelectedServiceOrg(
        Formvalues,
        this.props.InodeViewModel,
      );
      /* if org is null , fallback to currentUser.organization.id */
      currentOrg =
        currentOrg === null
          ? this.props.match.params.org_id
            ? this.props.match.params.org_id
            : this.props.UiStore.currentUser.organization.id
          : currentOrg;

      let secretObj = {
        name: this.vol_content[secret].name,
        data: {
          ...this.props.UiStore.templatingService.secrets[secret].secret_volume,
        },
        type: "Opaque",
        /* LAT-5697 no labels required */
        // labels: {
        //   type: "license",
        // },
        create_under: currentOrg, //this.props.UiStore.currentUser.organization.id,
      };

      resolve(SecretController.create(secretObj));
    });
  };

  fetchSecretDetails = secretId => {
    return new Promise((resolve, reject) => {
      SecretController.getSecret(secretId, false).then(
        secretRes => {
          resolve(secretRes);
        },
        err => {
          reject(err);
        },
      );
    });
  };

  updateNodeNetworkIdToFinalSpec = (mappedNetworkEle, toJSObj) => {
    /*add network and node_id to the final podspec*/
    let nodekeys = Object.keys(this.props.UiStore.templatingService.node);
    if (nodekeys.length > 0) {
      /*LAT-4289*/
      toJSObj.networks.forEach((ele, index) => {
        if (ele === mappedNetworkEle) {
          /* replace the mapped nodenetwork ele with network obj */
          toJSObj.networks[index] = this.props.UiStore.templatingService.node[
            mappedNetworkEle
          ].network;

          // if cluster based v1 services
          if (this.props.isCluster || this.serviceViewModel.clusterMode) {
            toJSObj.kind = this.props.UiStore.templatingService.node[
              mappedNetworkEle
            ].kind;
            toJSObj.cluster_id = this.props.UiStore.templatingService.node[
              mappedNetworkEle
            ].cluster_id;
            if (
              this.props.UiStore.templatingService.node[mappedNetworkEle]
                .kind &&
              this.props.UiStore.templatingService.node[mappedNetworkEle]
                .kind === "REPLICA"
            ) {
              toJSObj.node_selector = this.props.UiStore.templatingService.node[
                mappedNetworkEle
              ].node_selector;
            } else {
              delete toJSObj.node_selector;
            }
          }

          /* LAT-6758 */
          if (
            this.props.apiResponse &&
            Object.keys(this.props.apiResponse).length > 0
          ) {
            /* In edit mode . For dynamic service addressing , remove ip_address from PUT body */
            this.props.NetworkViewModel.networks.map((network, i) => {
              if (
                network.id ===
                this.props.UiStore.templatingService.node[mappedNetworkEle]
                  .network.network_id
              ) {
                if (
                  !this.props.UiStore.templatingService.allowIPAddress &&
                  network.config &&
                  network.config.network &&
                  network.config.network.service_addressing &&
                  network.config.network.service_addressing.toLowerCase() ===
                    "auto"
                ) {
                  delete toJSObj.networks[index].ip_address;
                }
              }
            });
          }
        }
      });
      if (
        this.props.UiStore.templatingService.node[mappedNetworkEle] &&
        this.props.UiStore.templatingService.node[mappedNetworkEle].node_id
      ) {
        toJSObj.node_id = this.props.UiStore.templatingService.node[
          mappedNetworkEle
        ].node_id;
      }
      return toJSObj;
    } else {
      return toJSObj;
    }
  };

  mapValuestoOutputSpec = Formvalues => {
    if (this.props.uiSchema.version != "2.0") {
      let formKeys = Object.keys(Formvalues),
        jp = Jsonpath;
      let mappedNetworkEle = null;
      /*stringify is required to convert mobx obj to js obj*/
      let output = JSON.stringify(
          this.props.UiStore.templatingService.computedSchema.output,
        ),
        toJSObj = toJS(JSON.parse(output), true);
      /* check if docker is available */
      let dockerkey = Object.keys(this.props.UiStore.templatingService.secrets);
      dockerkey.map(key => {
        if (key.indexOf("dockerconfig") >= 0) {
          this.dockerConfigObj[key] = [];
          this.props.SecretViewModel.secretsObj.dockerconfig.map(val => {
            /*create docker volume*/

            Formvalues[key] &&
              Formvalues[key].forEach &&
              Formvalues[key].forEach(selectedconfig => {
                if (val.id === selectedconfig) {
                  // LAT-4114. change in implementation logic
                  this.dockerConfigObj[key].push(val.name);
                }
              });
          });
        }
        return null;
      });
      this.deepMapValues(toJSObj, (value, path) => {
        //converting value and path to string to avoid errors while checking the index
        if (path && value) {
          value = value.toString();
          path = path.toString();
          formKeys.map(formkey => {
            /*map secret to output podspec*/
            if (value.indexOf("secret") >= 0) {
              /*check if secret is present in volumes obj*/
              if (path.indexOf("volumes") >= 0) {
                /*travserse to the path in output spec*/
                let targetAttr = jp.query(toJSObj, `$.${path}`);
                if (targetAttr.length == 1 && this.volumeObj[value]) {
                  jp.value(toJSObj, `$.${path}`, this.volumeObj[value]);
                }
              } else if (path.indexOf("services") >= 0) {
                /*create volume object*/

                /*travserse to the path in output spec*/
                let targetAttr = jp.query(toJSObj, `$.${path}`);
                if (targetAttr.length == 1 && this.volume_mounts[value]) {
                  jp.value(toJSObj, `$.${path}`, this.volume_mounts[value]);
                }
              }
            } else if (value === formkey) {
              /*if form key is found replace with user value*/
              /*query the path to reach the obj attribute*/
              let ele = jp.query(toJSObj, `$.${path}`);
              if (ele.length == 1) {
                jp.apply(toJSObj, `$.${path}`, val => {
                  /* check if docker config , replace with docker obj */
                  if (formkey.indexOf("dockerconfig") >= 0) {
                    //val = this.volumeObj[value] ? this.volumeObj[value] : null;
                    // LAT-4114 replace 'dockerconfig' element with dockerconfig array
                    val =
                      this.dockerConfigObj[formkey] &&
                      this.dockerConfigObj[formkey].length > 0
                        ? this.dockerConfigObj[formkey] //: []
                        : [];
                  } else {
                    if (formkey.indexOf("fileupload") >= 0) {
                      /* it's file upload component. replace with uploaded text */
                      let fileData = Object.values(
                        this.props.UiStore.templatingService.secrets[formkey]
                          .secret_volume,
                      );
                      /* replace data as ',' separated values in the output */
                      val = fileData.length > 0 ? fileData.join(",") : "";
                    } else {
                      /*replace key in output obj with user input value*/
                      val = Formvalues[formkey];
                    }
                  }
                  return val;
                });
              }
            } else if (value.indexOf("nodenetwork") >= 0) {
              /* adding network and node_id to the final podspec LAT-4289 */
              toJSObj = this.updateNodeNetworkIdToFinalSpec(value, toJSObj);
            } else if (formkey.indexOf("dns_policy") >= 0) {
              // LAT-8645 setting DNS value if component rendered
              toJSObj.dns = toJS(this.props.UiStore.templatingService.dns);
              toJSObj.dns_policy = this.props.UiStore.templatingService.dns_policy;
            } else if (formkey.indexOf("custom") >= 0) {
              /* for custom services */
              try {
                /* find html component with key 'custom' */
                let parsed = JSON.parse(Formvalues[formkey]);
                // deleteing defaults from from custom service final json
                if (toJSObj.services) delete toJSObj.services;
                if (toJSObj.volumes) delete toJSObj.volumes;
                if (toJSObj.dns_policy && toJSObj.dns_policy === "Default") {
                  toJSObj.dns = [];
                }
                /* assign custom spec to final service obj */
                toJSObj = ServiceTemplatesUtils.createCustomObj(
                  parsed,
                  toJSObj,
                );
              } catch (e) {
                this.props.UiStore.errorMessage = getCurrentlocaleText(
                  "custom.service.spec.validator",
                );
                this.finalSpec.services = null;
              }
            }
          });
        }
      });
      /* LAT-8645 removing DNS info from payload if not required (During version check if iNode does'nt support DNS for backward compatability,
    we are hiding the DNS component in UI. But the template will have the output spec for dns component in this case in request payload dns and dns_policy value will go. To avoid that unwanted payload we have this check)*/
      if (
        !(toJSObj.dns_policy === "Default" || toJSObj.dns_policy === "None")
      ) {
        delete toJSObj.dns;
        delete toJSObj.dns_policy;
      }
      this.finalSpec = toJSObj;
      if (typeof this.finalSpec === "string") {
        try {
          this.finalSpec = JSON.parse(this.finalSpec);
        } catch (err) {
          this.finalSpec = this.finalSpec.replace(/\\\"/g, '"');
          this.finalSpec = JSON.parse(this.finalSpec);
        }
      }
      /*
      LAT-4603 : remove ssh service from pod spec when ssh is not selected.

      TODO : better implementation at input template level to conditionally add spec obj
      (or) conditionally select pod spec based on user selection.

      check LAT-3931

    */
      let SSHSelectedKey = formKeys.filter(val => {
        /*  check if ssh is selected */
        let regex = /^(secret_)[a-zA-Z0-9]*_(ssh)_(selectlicensetype)$/;
        return regex.test(val) && val;
      });
      if (
        SSHSelectedKey.length === 0 ||
        (Formvalues[SSHSelectedKey[0]] &&
          Formvalues[SSHSelectedKey[0]].length === 0)
      ) {
        /* pod spec should always have labels attr. */
        let serviceType =
          this.finalSpec.labels && this.finalSpec.labels["io_iotium_template"]
            ? this.finalSpec.labels["io_iotium_template"]
            : this.finalSpec.labels &&
              this.finalSpec.labels["io-iotium-template"]
            ? this.finalSpec.labels["io-iotium-template"]
            : "custom";

        this.finalSpec.services &&
          this.finalSpec.services.forEach((val, index) => {
            if (
              val.image.name === "iotium/edge_ssh_1.2" &&
              serviceType.toLowerCase() === "skyspark"
            ) {
              this.finalSpec.services.splice(index, 1);
            }
          });
        this.submitService();
      } else {
        /*
        ssh is selected - LAT-5291
        The below code is for the scenario when previous ssh is selected
        Code assigns keymaps to ssh volume obj based on data[<key>] of selected ssh object
      */
        let SshFormId = formKeys.filter(val => {
          /*  check if previous ssh is selected */
          let regex = /^(secret_)[a-zA-Z0-9]*_(ssh)$/;
          return regex.test(val) && val;
        });

        if (SshFormId.length > 0) {
          /*
          need keymaps only when previous ssh is selected.
          new ssh creation will always have key as 'authorized.keys'
        */
          /* TODO : handle if multiple ssh components are added. */
          let selectedsshId = Formvalues[SshFormId[0]];
          /* get secret id */
          if (selectedsshId) {
            this.fetchSecretDetails(selectedsshId, false).then(
              secretResp => {
                let mapKeyName = Object.keys(secretResp.data)[0];
                let keymap = {};
                keymap[mapKeyName] = "authorized_keys";

                this.finalSpec.volumes.forEach((val, index) => {
                  if (
                    val["secret_volume"] &&
                    Object.keys(val["secret_volume"].length > 0)
                  ) {
                    if (val["secret_volume"].secret === secretResp.id) {
                      val["secret_volume"] = {
                        ...val["secret_volume"],
                        secret_keymap: {
                          ...keymap,
                        },
                      };
                    }
                  }
                });
                this.submitService();
              },
              secretError => {
                this.submitService();
              },
            );
          }
        } else {
          this.submitService();
        }
      }
    } else {
      this.submitService();
    }
  };
  getLicenseVol = type => {
    let val = null;
    Object.entries(this.volumeObj).forEach(([key, value]) => {
      if (key.indexOf(type) >= 0) {
        val = key;
      }
    });
    return val;
  };
  getLicenseVolContent = type => {
    let val = null;
    Object.entries(this.vol_content).forEach(([key, value]) => {
      if (key.indexOf(type) >= 0) val = value;
    });
    return val;
  };
  submitService = () => {
    if (this.props.baseSchema && this.props.baseSchema.version === "2.0") {
      Object.entries(this.volumeObj).forEach(([key, value]) => {
        if (key.indexOf("license") >= 0) {
          if (
            this.props.ServiceViewModel.isNewLicsenseV2 &&
            this.props.ServiceViewModel.isNewLicsenseV2[key] &&
            this.props.ServiceViewModel.isNewLicsenseV2[key] === "NEW"
          ) {
            let volume = this.volumeObj[key];
            let volInfo = {
              secret: {
                name: volume.name,
                secret_volume: volume.secret_volume,
                mount_path: this.vol_content[key].mountPath,
                files: this.vol_content[key].fileName,
              },
            };
            if (this.props.ServiceViewModel.secretElementPathMap[key]) {
              if (typeof this.props.uiSchema === "object") {
                this.props.uiSchema.steps[
                  this.props.ServiceViewModel.secretElementPathMap[
                    key
                  ].stepIndex
                ].elements[
                  this.props.ServiceViewModel.secretElementPathMap[
                    key
                  ].elementIndex
                ].props.defaultValue = volInfo;
              }
              if (
                this.vol_content[key] &&
                this.vol_content[key].fileName &&
                this.vol_content[key].fileName.length > 0
              ) {
                this.props.UiStore.computedOutputObject.steps[
                  this.props.ServiceViewModel.secretElementPathMap[
                    key
                  ].stepIndex
                ].elements[
                  this.props.ServiceViewModel.secretElementPathMap[
                    key
                  ].elementIndex
                ].files = [volInfo.secret.files];
              }
              this.props.UiStore.computedOutputObject.steps[
                this.props.ServiceViewModel.secretElementPathMap[key].stepIndex
              ].elements[
                this.props.ServiceViewModel.secretElementPathMap[
                  key
                ].elementIndex
              ].id = volInfo.secret.secret_volume.secret;
              this.props.UiStore.computedOutputObject.steps[
                this.props.ServiceViewModel.secretElementPathMap[key].stepIndex
              ].elements[
                this.props.ServiceViewModel.secretElementPathMap[
                  key
                ].elementIndex
              ].name = volInfo.secret.name;
              this.props.UiStore.computedOutputObject.steps[
                this.props.ServiceViewModel.secretElementPathMap[key].stepIndex
              ].elements[
                this.props.ServiceViewModel.secretElementPathMap[
                  key
                ].elementIndex
              ].mount_path = volInfo.secret.mount_path;
            }
          } else if (
            this.volumeObj[key] &&
            this.volumeObj[key].fileNames &&
            this.volumeObj[key].fileNames.length > 0
          ) {
            let steps = toJS(
              this.props.UiStore.templatingService.computedSchema.steps,
            );
            if (steps && steps.length > 0) {
              steps.forEach((step, i) => {
                let elements = steps[i].elements;
                elements.forEach((ele, index) => {
                  if (ele["__id"] === key) {
                    this.props.UiStore.computedOutputObject.steps[i].elements[
                      index
                    ].files = this.volumeObj[key].fileNames;
                    this.props.UiStore.computedOutputObject.steps[i].elements[
                      index
                    ].id = this.volumeObj[key].secret_volume.secret;
                    this.props.UiStore.computedOutputObject.steps[i].elements[
                      index
                    ].name = this.volumeObj[key].name;
                    this.props.UiStore.computedOutputObject.steps[i].elements[
                      index
                    ].mount_path = ele.props.mountpath;
                  }
                });
              });
            }
          }
        }
      });

      if (this.props.ServiceViewModel.isNewSSH === "NEW") {
        Object.entries(this.volumeObj).forEach(([key, value]) => {
          if (key.indexOf("ssh") >= 0) {
            let volume = this.volumeObj[key];
            let volInfo = {
              secret: {
                name: volume.name,
                secret_volume: volume.secret_volume,
                mount_path: this.vol_content[key].mountPath,
                files: this.vol_content[key].fileName,
              },
            };
            if (this.props.ServiceViewModel.secretElementPathMap[key]) {
              if (typeof this.props.uiSchema === "object") {
                this.props.uiSchema.steps[
                  this.props.ServiceViewModel.secretElementPathMap[
                    key
                  ].stepIndex
                ].elements[
                  this.props.ServiceViewModel.secretElementPathMap[
                    key
                  ].elementIndex
                ].props.defaultValue = volInfo;
              }
              if (
                this.vol_content[key].fileName &&
                this.vol_content[key].fileName.length > 0
              ) {
                this.props.UiStore.computedOutputObject.steps[
                  this.props.ServiceViewModel.secretElementPathMap[
                    key
                  ].stepIndex
                ].elements[
                  this.props.ServiceViewModel.secretElementPathMap[
                    key
                  ].elementIndex
                ].files = [volInfo.secret.files];
              }
              this.props.UiStore.computedOutputObject.steps[
                this.props.ServiceViewModel.secretElementPathMap[key].stepIndex
              ].elements[
                this.props.ServiceViewModel.secretElementPathMap[
                  key
                ].elementIndex
              ].id = volInfo.secret.secret_volume.secret;
              this.props.UiStore.computedOutputObject.steps[
                this.props.ServiceViewModel.secretElementPathMap[key].stepIndex
              ].elements[
                this.props.ServiceViewModel.secretElementPathMap[
                  key
                ].elementIndex
              ].name = volInfo.secret.name;

              this.props.UiStore.computedOutputObject.steps[
                this.props.ServiceViewModel.secretElementPathMap[key].stepIndex
              ].elements[
                this.props.ServiceViewModel.secretElementPathMap[
                  key
                ].elementIndex
              ].mount_path = volInfo.secret.mount_path;
            }
          }
        });
      }
      let finalOutputString = null;
      let isSchema = false;

      if (typeof this.props.uiSchema === "object") {
        if (
          typeof this.props.uiSchema.output === "object" &&
          typeof this.props.uiSchema.output !== "string"
        ) {
          finalOutputString = JSON.stringify(this.props.uiSchema.output);
        } else {
          finalOutputString = this.props.uiSchema.output;
        }
      } else {
        finalOutputString = this.props.uiSchema;
        isSchema = true;
      }
      let conditions = getMatches(finalOutputString, /{% (.*?) %}/);
      var template = Twig.twig({
        data: finalOutputString,
      });

      let computedOutputObject = copyObject(
        this.props.UiStore.computedOutputObject,
      );
      let finalOutput = template.render(computedOutputObject);
      this.finalSpec = JSON.parse(finalOutput);
      try {
        this.finalSpec = JSON.parse(this.finalSpec);
      } catch (err) {
        this.finalSpec = this.finalSpec;
      }
      if (isSchema) this.finalSpec = this.finalSpec.output;
      let labels = null;
      try {
        labels = JSON.parse(this.finalSpec).labels;
        this.finalSpec = JSON.parse(this.finalSpec);
      } catch (err) {
        labels = this.finalSpec.labels;
      }
      let jp = Jsonpath;
      if (conditions) {
        conditions.forEach(val => {
          val.split(" ").forEach(values => {
            let result = [];
            try {
              result = jp.query(computedOutputObject, `$.${values}`);
            } catch (err) {
              result = [];
            }

            if (
              result &&
              result.length > 0 &&
              result[0] &&
              result[0].length > 0
            )
              labels[values] = typeof result[0] === "object" ? true : result[0];
          });
        });
      }
      let schema = this.props.baseSchema;
      if (typeof this.props.baseSchema === "string") {
        schema = JSON.parse(this.props.baseSchema);
      }
      let selectedService = labels
        ? labels.io_iotium_template
        : this.props.baseSchema.labels.io_iotium_template;
      //custom services spec update
      /* for custom services */
      schema.steps.forEach((step, s_index) => {
        step.elements.forEach((element, e_index) => {
          if (
            selectedService === "custom" &&
            element.type.toLowerCase() === "jsoninputbox"
          ) {
            if (
              this.props.baseSchema &&
              this.props.baseSchema.version === "2.0"
            ) {
              try {
                /* find html component with key 'custom' */
                let parsed = JSON.parse(
                  computedOutputObject.steps[s_index].elements[e_index].value,
                );
                this.customSpecObject = parsed;
                if (this.finalSpec.services) delete this.finalSpec.services;
                if (this.finalSpec.volumes) delete this.finalSpec.volumes;
                /* assign custom spec to final service obj */
                this.finalSpec = ServiceTemplatesUtils.createCustomObj(
                  parsed,
                  this.finalSpec,
                );
              } catch (e) {
                this.props.UiStore.errorMessage = getCurrentlocaleText(
                  "custom.service.spec.validator",
                );
                this.finalSpec.services = null;
              }
            }
          }
        });
      });
      // Add dynamic component values to labels
      let steps = toJS(
        this.props.UiStore.templatingService.computedSchema.steps,
      );
      if (steps && steps.length > 0) {
        let output = this.props.UiStore.templatingService.computedSchema.output;

        var outputObject = JSON.parse(output);
        outputObject = toJS(outputObject, true);
        steps.forEach((step, i) => {
          let elements = steps[i].elements;
          elements.forEach((ele, index) => {
            if (ele.type && ele.type.toLowerCase() != "nodenetworklist") {
              let dynamicValue =
                computedOutputObject.steps &&
                computedOutputObject.steps[i] &&
                computedOutputObject.steps[i].elements[index] &&
                computedOutputObject.steps[i].elements[index].value
                  ? computedOutputObject.steps[i].elements[index].value
                  : null;
              if (dynamicValue) {
                let p = '"steps[' + i + "].elements[" + index + '].value"';
                let y = jp.paths(outputObject, "$..[?(@.includes(" + p + "))]");
                if (!y || y.length <= 0) {
                  try {
                    labels[p.replace('"', "").replace('"', "")] =
                      typeof JSON.parse(dynamicValue) === "object"
                        ? true
                        : JSON.parse(dynamicValue);
                  } catch (err) {
                    labels[p.replace('"', "").replace('"', "")] =
                      typeof dynamicValue === "object" ? true : dynamicValue;
                  }
                }
              }
            } else if (ele.type.toLowerCase() === "secret") {
              let dynamicValue =
                computedOutputObject.steps &&
                computedOutputObject.steps[i] &&
                computedOutputObject.steps[i].elements[index] &&
                computedOutputObject.steps[i].elements[index].id
                  ? computedOutputObject.steps[i].elements[index].id
                  : null;
              if (dynamicValue) {
                let p = '"steps[' + i + "].elements[" + index + '].id"';
                let y = jp.paths(outputObject, "$..[?(@.includes(" + p + "))]");
                if (!y || y.length <= 0) {
                  labels[p.replace('"', "").replace('"', "")] = JSON.parse(
                    dynamicValue,
                  );
                }
              }
            } else if (ele.type && ele.type.toLowerCase() === "dropdown") {
              let dynamicValue =
                computedOutputObject.steps &&
                computedOutputObject.steps[i] &&
                computedOutputObject.steps[i].elements[index] &&
                computedOutputObject.steps[i].elements[index].value[0]
                  ? computedOutputObject.steps[i].elements[index].value[0]
                  : null;
              if (dynamicValue) {
                let p = '"steps[' + i + "].elements[" + index + '].value[0]"';
                let y = jp.paths(outputObject, "$..[?(@.includes(" + p + "))]");
                if (!y || y.length <= 0) {
                  labels[p.replace('"', "").replace('"', "")] = JSON.parse(
                    dynamicValue,
                  );
                }
              }
            } else if (
              ele.type &&
              ele.type.toLowerCase() === "nodenetworklist"
            ) {
              let dynamicValue =
                computedOutputObject.steps &&
                computedOutputObject.steps[i] &&
                computedOutputObject.steps[i].elements[index] &&
                computedOutputObject.steps[i].elements[index].network
                  ? computedOutputObject.steps[i].elements[index].network
                  : null;
              if (dynamicValue) {
                let p = '"steps[' + i + "].elements[" + index + '].networks"';
                let y = jp.paths(outputObject, "$..[?(@.includes(" + p + "))]");
                if (!y || y.length <= 0) {
                  labels[
                    p.replace('"', "").replace('"', "")
                  ] = dynamicValue.toString();
                }
                let dynamicMultiNetworksValue =
                  computedOutputObject.steps &&
                  computedOutputObject.steps[i] &&
                  computedOutputObject.steps[i].elements[index] &&
                  computedOutputObject.steps[i].elements[index].networks
                    ? computedOutputObject.steps[i].elements[index].networks
                    : null;
                if (dynamicMultiNetworksValue) {
                  this.finalSpec.networks = dynamicMultiNetworksValue;
                }
                // check for mac_address and other input values in custom spec
                if (selectedService === "custom") {
                  if (this.customSpecObject && this.customSpecObject.networks) {
                    let assigned_networks = this.finalSpec.networks;
                    let custom_object_networks = this.customSpecObject.networks;
                    custom_object_networks.forEach(net => {
                      let networkId = net.network_id;
                      let networkIndex = assigned_networks.findIndex(
                        network => network.network_id === networkId,
                      );
                      if (net.mac_address && networkIndex != -1) {
                        assigned_networks[networkIndex].mac_address =
                          net.mac_address;
                      }
                      // delete from payload as the value is cleared
                      else if (
                        assigned_networks[networkIndex] &&
                        assigned_networks[networkIndex].mac_address
                      ) {
                        delete assigned_networks[networkIndex].mac_address;
                      }
                    });
                    this.finalSpec.networks = assigned_networks;
                  }
                } else {
                  if (this.outputObject && this.outputObject.networks) {
                    let assigned_networks = this.finalSpec.networks;
                    let output_object_networks = this.outputObject.networks;
                    output_object_networks.forEach(net => {
                      let networkId = net.network_id;
                      let networkIndex = assigned_networks.findIndex(
                        network => network.network_id === networkId,
                      );
                      if (net.mac_address && networkIndex != -1) {
                        assigned_networks[networkIndex].mac_address =
                          net.mac_address;
                      }
                    });
                    this.finalSpec.networks = assigned_networks;
                  }
                }
              }
            } else if (ele.type && ele.type.toLowerCase() === "dnscomponent") {
              let dynamicValue =
                computedOutputObject.steps &&
                computedOutputObject.steps[i] &&
                computedOutputObject.steps[i].elements[index] &&
                computedOutputObject.steps[i].elements[index].dns
                  ? computedOutputObject.steps[i].elements[index].dns
                  : null;
              if (dynamicValue) {
                let p = '"steps[' + i + "].elements[" + index + '].dns"';
                let y = jp.paths(outputObject, "$..[?(@.includes(" + p + "))]");
                if (!y || y.length <= 0) {
                  labels[p.replace('"', "").replace('"', "")] = JSON.parse(
                    dynamicValue,
                  );
                }
              }
            }
          });
        });
      }

      this.finalSpec.labels = labels;
      if (
        this.finalSpec.dns &&
        this.finalSpec.dns.length === 1 &&
        this.finalSpec.dns[0].length === 0
      ) {
        this.finalSpec.dns = [];
      }
      // deleteing defaults from from custom service final json if (
      if (
        this.finalSpec.dns_policy &&
        this.finalSpec.dns_policy === "Default"
      ) {
        this.finalSpec.dns = [];
      }
      if (
        this.finalSpec.image_pull_secrets &&
        this.finalSpec.image_pull_secrets.length === 1 &&
        this.finalSpec.image_pull_secrets[0].length === 0
      ) {
        this.finalSpec.image_pull_secrets = [];
      }
      /* In edit mode . For dynamic service addressing , remove ip_address from PUT body */
      if (this.finalSpec.networks) {
        this.props.NetworkViewModel.networks.map((network, i) => {
          if (network.id === this.finalSpec.networks[0].network_id) {
            if (
              (network.type === "DYNAMIC" &&
                !this.props.UiStore.templatingService.allowIPAddress) ||
              (network.type === "STATIC" &&
                network.config &&
                network.config.network &&
                network.config.network.service_addressing &&
                network.config.network.service_addressing.toLowerCase() ===
                  "auto")
            ) {
              this.finalSpec.networks[0].ip_address = "";
            }
          }
        });
      }
    }
    /* remove falsy values from pod spec */
    this.removeFalsy(this.finalSpec);
    if (this.props.isCluster || this.serviceViewModel.clusterMode) {
      let computedOutputObject = copyObject(
        this.props.UiStore.computedOutputObject,
      );
      let schema = this.props.baseSchema;
      if (typeof this.props.baseSchema === "string") {
        schema = JSON.parse(this.props.baseSchema);
      }
      schema.steps.forEach((step, s_index) => {
        step.elements.forEach((element, e_index) => {
          if (element.type.toLowerCase() === "nodenetworklist") {
            if (
              this.props.baseSchema &&
              this.props.baseSchema.version === "2.0"
            ) {
              this.finalSpec.kind =
                computedOutputObject.steps[s_index].elements[e_index].kind;
              this.finalSpec.cluster_id =
                computedOutputObject.steps[s_index].elements[
                  e_index
                ].cluster_id;
              if (
                computedOutputObject.steps[s_index].elements[e_index].kind ===
                "REPLICA"
              ) {
                this.finalSpec.node_selector =
                  computedOutputObject.steps[s_index].elements[
                    e_index
                  ].node_selector;
              }
            }
          }
        });
      });
      delete this.finalSpec.node_id;
    }
    if (
      this.finalSpec.kind &&
      this.finalSpec.kind != "SINGLETON" &&
      (this.props.isCluster || this.serviceViewModel.clusterMode)
    ) {
      if (
        this.finalSpec &&
        this.finalSpec.networks &&
        this.finalSpec.networks[0]
      )
        delete this.finalSpec.networks[0].ip_address;
    }
    let add_path = this.props.match.path,
      y = add_path.split("/");
    if (y.indexOf("services") >= 0 && !this.props.match.params.spec_id) {
      this.setState({
        disableSubmit: true,
      });
      /*in services create mode. send post api*/
      ServiceController.create(this.finalSpec).then(
        res => {
          setTimeout(() => {
            this.props.UiStore.templatingService.secrets = {};
            if (
              Object.keys(this.props.UiStore.templatingService.nodeDetails)
                .length > 0
            ) {
              /* page called from inode details page */
              this.props.history.goBack();
            } else {
              this.props.history.push(`/services/`);
            }
          }, 2000);
        },
        error => {
          this.setState({
            disableSubmit: false,
          });
          console.log(error);
        },
      );
    } else if (y.indexOf("services") >= 0 && this.props.match.params.spec_id) {
      this.setState({
        disableSubmit: true,
      });
      /*in services edit mode. send put api*/
      ServiceController.update(this.finalSpec, {
        id: this.props.match.params.spec_id,
      }).then(
        res => {
          setTimeout(() => {
            this.props.UiStore.templatingService.secrets = {};
            if (
              Object.keys(this.props.UiStore.templatingService.nodeDetails)
                .length > 0
            ) {
              /* page called from inode details page */
              this.props.history.goBack();
            } else {
              this.props.history.push(`/services/`);
            }
          }, 2000);
        },
        error => {
          this.setState({
            disableSubmit: false,
          });
          console.log(error);
        },
      );
    } else {
      /* for servicetemplates url */
      console.log("output podspec : ");
      console.log(this.finalSpec);
      this.setState({ showResult: this.finalSpec });
    }
  };

  removeFalsy = object => {
    if (isArray(object)) {
      object.map((val, i) => {
        /* remove if value is undefined  */
        !val && object.splice(i, 1);
      });
      object.map((val, i) => {
        /* remove if value is not an object which is string */
        if (this.props.baseSchema.version === "2.0") {
          if (typeof val === "string" && val.startsWith("{")) {
            object[i] = JSON.parse(val);
          } else {
            !isObjectOrFunction(val) && object.splice(i, 1);
          }
        } else {
          !isObjectOrFunction(val) && object.splice(i, 1);
        }
      });
      object.indexOf(null) >= 0 ? object.splice(object.indexOf(null), 1) : null;
      object.map(val => {
        if (
          isObjectOrFunction(val) &&
          !isDate(val) &&
          !isRegExp(val) &&
          !isFunction(val)
        ) {
          this.removeFalsy(val);
        }
      });
    } else if (
      isObjectOrFunction(object) &&
      !isDate(object) &&
      !isRegExp(object) &&
      !isFunction(object) &&
      (!this.props.baseSchema.version ||
        !this.props.baseSchema.version === "2.0")
    ) {
      object = removeByObjectKey(object, undefined);
      let allkeys = Object.keys(object);
      allkeys.forEach(val => {
        //LAT-8534 to avoid DNS array of strings since empty string is removed
        if (isArray(object[val]) && !isArrayOfStrings(object[val]))
          this.removeFalsy(object[val]);
      });
    }
  };

  validateAndpostSecret = (secret, Formvalues) => {
    return new Promise((resolve, reject) => {
      let val;
      ServiceTemplatesUtils.validateRegex(
        secret,
        this.props.UiStore.templatingService.secrets[secret],
        this.props.UiStore,
      ).then(
        val => {
          let currentOrg = ServiceTemplatesUtils.getSelectedServiceOrg(
            Formvalues,
            this.props.InodeViewModel,
          );

          /* if org is null , fallback to currentUser.organization.id */
          currentOrg =
            currentOrg === null
              ? this.props.match.params.org_id
                ? this.props.match.params.org_id
                : this.props.UiStore.currentUser.organization.id
              : currentOrg;
          /*Secret object creation*/
          let name = `${secret}_name`;
          let obj = {
            name: `${Formvalues[name]}`,
            data: {},
            type: "Opaque",
            create_under: currentOrg, //this.props.UiStore.currentUser.organization.id,
            /* LAT-5697 no labels required */
            // labels: {
            //   type: "ssh",
            // },
          };
          obj.data[`authorized_keys`] = val; /* LAT-5291 */
          /* TODO : https://iotium.atlassian.net/browse/LAT-3931 */
          resolve(SecretController.create(obj));
        },
        error => {
          console.debug(error);
          reject(false);
        },
      );
    });
  };

  /**
   *  Create Secret Object
   */
  checkSecretObject = (formKeys, Formvalues) => {
    formKeys.map(formkey => {
      /*check if form key contains secret , save secret values for all secret*/
      if (formkey.indexOf("secret") >= 0) {
        this.CreateSecretObj(formkey, Formvalues, null);
        if (Formvalues["uploader"]) {
          this.CreateSecretObj(
            "uploader",
            Formvalues,
            formkey.split("_select")[0],
          );
        }
      }
      return null;
    });
  };

  /**
   *  Create a New secret post request
   */
  createNewSecretRequest = (secretKeys, Formvalues, apicount, sshKeyCount) => {
    /* ssh + license */
    secretKeys.map((secret, index) => {
      /*For any secret an store object will be created*/
      let secretInfo = this.props.UiStore.templatingService.secrets[secret];
      if (secretInfo) {
        if (isEmpty(secretInfo.secret_volume) && secret.indexOf("ssh") >= 0) {
          /* previous secret is selected*/
          let secretId = secretInfo.previousSelectedLicense;
          // get volume details
          this.fetchVolume(secretId).then(
            response => {
              this.updateSecretObj(response, secret, Formvalues, "ssh").then(
                success => {
                  --apicount;
                  --sshKeyCount;
                  if (apicount == 0) {
                    /*call ouput spec when all api's are resolved*/
                    this.mapValuestoOutputSpec(Formvalues);
                  } else if (apicount > 0 && sshKeyCount == 0) {
                    if (success) {
                      this.createAndMapLicense(
                        secretKeys,
                        Formvalues,
                        apicount,
                      ).then(success => {});
                    }
                  }
                },
              );
            },
            error => {
              console.log("error in secret api " + error);
              this.setState({
                disableSubmit: false,
              });
              return true;
            },
          );
        } else {
          /* new secret is uploaded*/
          if (secret.indexOf("ssh") >= 0) {
            /* it is an ssh */
            this.validateAndpostSecret(secret, Formvalues).then(
              response => {
                this.updateSecretObj(response, secret, Formvalues, "ssh").then(
                  success => {
                    --apicount;
                    --sshKeyCount;
                    if (apicount == 0) {
                      /*call ouput spec when all api's are resolved*/
                      this.mapValuestoOutputSpec(Formvalues);
                    } else if (apicount > 0 && sshKeyCount == 0) {
                      /*all ssh are validated and vol objects are created. Now create license*/
                      this.createAndMapLicense(
                        secretKeys,
                        Formvalues,
                        apicount,
                      );
                    }
                  },
                );
              },
              error => {
                console.log("Error in secret creation.");
                this.setState({
                  disableSubmit: false,
                });
              },
            );
          }
        }
      }
      return null;
    });
  };

  handleSubmit = e => {
    this.setState({
      disableSubmit: true,
    });
    e.preventDefault();
    this.props.form.validateFields((err, Formvalues) => {
      if (!err) {
        let formKeys = Object.keys(Formvalues);
        /*check if form key contains secret*/
        this.checkSecretObject(formKeys, Formvalues);

        /*check if any secret is added / post in secret api , create secret obj*/
        let secretKeys = Object.keys(this.vol_content),
          apicount = secretKeys.length;

        let sshKeyCount = secretKeys.reduce((acc, currentVal) => {
          /*return total count of ssh*/
          return currentVal.indexOf("ssh") >= 0 ? ++acc : acc;
        }, 0);

        let licenseKeyCount = apicount - sshKeyCount;
        /* check if secret_volume / previous secret selected for any secret*/
        if (secretKeys.length > 0) {
          if (sshKeyCount > 0) {
            /* ssh + license */
            this.createNewSecretRequest(
              secretKeys,
              Formvalues,
              apicount,
              sshKeyCount,
            );
          } else if (licenseKeyCount > 0) {
            /* only license*/
            this.createAndMapLicense(secretKeys, Formvalues, apicount);
          }
        } else {
          /*
              no secret volume. directly call final spec object.
              map input values to output podspec
            */
          this.mapValuestoOutputSpec(Formvalues);
        }
      } else {
        this.setState({
          disableSubmit: false,
        });
        console.log("Error values of form: ", err);
      }
    });
  };

  createAndMapLicense = (secretKeys, Formvalues, apicount) => {
    return new Promise((resolve, reject) => {
      secretKeys.map(secret => {
        /*For any secret an store object will be created*/
        if (this.props.UiStore.templatingService.secrets[secret]) {
          if (
            isEmpty(
              this.props.UiStore.templatingService.secrets[secret]
                .secret_volume,
            ) &&
            secret.indexOf("license") >= 0
          ) {
            /* previous secret is selected*/
            if (
              this.props.UiStore.templatingService.secrets[secret]
                .previousSelectedLicense
            ) {
              this.fetchVolume(
                this.props.UiStore.templatingService.secrets[secret]
                  .previousSelectedLicense,
              ).then(
                response => {
                  this.updateSecretObj(
                    response,
                    secret,
                    Formvalues,
                    "license",
                  ).then(success => {
                    --apicount;
                    if (apicount == 0) {
                      this.mapValuestoOutputSpec(Formvalues);
                    }
                  });
                },
                error => {
                  console.log("error in secret api " + error);
                  this.setState({
                    disableSubmit: false,
                  });
                  return true;
                },
              );
            } else {
              --apicount;
              this.props.UiStore.templatingService.secrets[secret] = {};
            }
          } else {
            if (secret.indexOf("license") >= 0) {
              this.postSecret(secret, Formvalues).then(
                response => {
                  this.updateSecretObj(
                    response,
                    secret,
                    Formvalues,
                    "license",
                  ).then(success => {
                    --apicount;
                    if (apicount == 0) {
                      /*call ouput spec when all api's are resolved*/
                      this.mapValuestoOutputSpec(Formvalues);
                    }
                  });
                },
                error => {
                  console.log("Error in secret creation.");
                  this.setState({
                    disableSubmit: false,
                  });
                },
              );
            } else {
              /* previous secret is selected*/
              this.fetchVolume(
                this.props.UiStore.templatingService.secrets[secret]
                  .previousSelectedLicense,
              ).then(
                response => {
                  this.updateSecretObj(
                    response,
                    secret,
                    Formvalues,
                    "ssh",
                  ).then(success => {});
                },
                error => {
                  console.log("error in secret api " + error);
                  this.setState({
                    disableSubmit: false,
                  });
                  return true;
                },
              );
            }
          }
        } else {
          --apicount;
        }
      });
      resolve(true);
    });
  };

  navigateBack = () => {
    if (this.props.match.path === "/servicetemplate") {
      //remians in the same page
      this.props.history.go("/servicetemplate");
    } else {
      try {
        this.props.history.goBack();
      } catch (e) {
        this.props.history.go("/services");
      }
    }
  };
  IsJsonString = str => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };
  render() {
    this.selectedServiceName = null;
    let output = null;
    if (this.props.baseSchema && this.props.baseSchema.version === "2.0") {
      try {
        output = JSON.parse(this.props.baseSchema.output);
      } catch (err) {
        output = this.props.baseSchema.output;
      }
    } else output = this.props.baseSchema.output;
    if (output.labels) {
      this.selectedServiceName = output.labels.io_iotium_template.replace(
        "_",
        " ",
      );
    } else if (output && this.props.baseSchema.labels) {
      this.selectedServiceName = this.props.baseSchema.labels.io_iotium_template.replace(
        "_",
        " ",
      );
    }

    const { getFieldDecorator, getFieldsError } = this.props.form;
    return (
      <div>
        <Row>
          <Col
            span={14}
            style={{
              borderRight: "2px solid #f3f3f3",
              marginBottom: "5px",
              paddingRight: "5px",
            }}
          >
            <Form
              id="service-template-form"
              onSubmit={this.handleSubmit}
              className=""
            >
              <Row className="flex-reverse">
                <Col span={24}>{this.showElements()}</Col>

                {this.TabContents &&
                  this.TabContents.length &&
                  this.state.currentTabKey != this.TabContents.length &&
                  this.TabContents.length > 1 && (
                    <div style={{ marginRight: "8px" }}>
                      <FormItem>
                        <Button
                          type={
                            hasErrors(this.props.form.getFieldsError()) ||
                            this.props.SecretViewModel.isSecretLoading
                              ? ""
                              : "primary"
                          }
                          disabled={
                            hasErrors(this.props.form.getFieldsError()) ||
                            this.props.SecretViewModel.isSecretLoading
                          }
                          onClick={this.showNextTab}
                        >
                          Next
                        </Button>
                      </FormItem>
                    </div>
                  )}

                {!this.state.isView &&
                  this.state.currentTabKey == this.TabContents.length && (
                    <div style={{ marginRight: "8px" }}>
                      <FormItem>
                        <Button
                          htmlType="submit"
                          disabled={
                            hasErrors(getFieldsError()) ||
                            this.state.disableSubmit
                          }
                          type={hasErrors(getFieldsError()) ? "" : "primary"}
                        >
                          Submit
                        </Button>
                      </FormItem>
                    </div>
                  )}

                <div style={{ marginRight: "8px" }}>
                  <FormItem>
                    <Button onClick={this.navigateBack}>
                      {getCurrentlocaleText("support.cancel.text")}
                    </Button>
                  </FormItem>
                </div>

                {this.state.currentTabKey !== 1 && this.TabContents.length > 1 && (
                  <div style={{ marginRight: "8px" }}>
                    <FormItem>
                      <Button
                        disabled={hasErrors(this.props.form.getFieldsError())}
                        onClick={this.gotoPrevious}
                      >
                        {getCurrentlocaleText("previous")}
                      </Button>
                    </FormItem>
                  </div>
                )}
              </Row>
            </Form>
          </Col>
          <Col span={10}>
            <Row>
              <Col span={16}>
                {this.state.showHTMLComponentHelpContent && (
                  <div
                    style={{
                      margin: "10px",
                    }}
                    id="htmlComponentHelpDiv"
                  />
                )}

                {this.state.showTabComponentHelpContent && (
                  <div
                    style={{
                      margin: "10px",
                    }}
                    id="tabComponentHelpDiv"
                  />
                )}
              </Col>

              <Col>
                <div
                  style={{
                    float: "right",
                    padding: "10px",
                    borderRadius: "20%",
                    border: "1px solid #f3f3f3",
                  }}
                >
                  {this.state.availableServices &&
                    this.state.availableServices.length > 0 &&
                    this.state.imageIcon}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        {this.state.showResult && (
          <Card title="Service specification output">
            <div
              style={{ height: "350px", overflow: "scroll", marginTop: "10px" }}
            >
              <ReactJson src={this.state.showResult} theme="monokai" />
            </div>
          </Card>
        )}
      </div>
    );
  }
}

const ServiceFormRenderer = Form.create()(ServiceFormRender);
export default ServiceFormRenderer;
