import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Row, Col, Popover } from "antd";
import { Link } from "react-router-dom";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import TableLayout from "components/layout/TableLayout";
import Icons from "components/UI-Components/Icons";
import EventController from "controller/EventController";
import {
  getCurrentlocaleText,
  getNodeStatusColorCode,
  getNetworkStatusColorCode,
  getPodStatusColorCode,
  getTranslatedString,
  customSort,
  capitalize,
} from "Core/Utils";

const iconStyle = {
  fontSize: 16,
  lineHeight: 1.3,
};

const extraRowStyle = { marginTop: 5, marginBottom: 5 };

@inject("AuthStore", "UiStore", "EventViewModel")
@observer
class ClusterEventList extends Component {
  constructor(props) {
    super(props);
    this.UiStore = this.props.UiStore;
    this.eventModel = this.props.EventViewModel;
    this.state = {
      expandedRowKeys: [],
    };
  }

  componentDidMount() {
    if (this.props.AuthStore.IsPermitted("EVENTS:VIEW")) {
      // Event list loading, page, size
      this.eventList(true, 0, 5);
    }
  }

  expandRow = () => {
    let keys = this.eventModel.events.map(item => {
      return item.id;
    });
    this.setExpandedRowKeys(
      this.state.expandedRowKeys.length !== keys.length ? keys : [],
    );
  };

  onExpand = (expanded, record) => {
    let keys = this.state.expandedRowKeys;
    let index = keys.findIndex(val => {
      return val === record.id;
    });
    if (expanded) {
      if (index === -1) {
        keys.push(record.id);
      }
      this.setExpandedRowKeys(keys);
    } else {
      if (index !== -1) {
        keys.splice(index, 1);
      }
      this.setExpandedRowKeys(keys);
    }
  };

  setExpandedRowKeys = keys => {
    this.setState({ expandedRowKeys: keys });
  };

  getMoreInfo = record => {
    let data = [];
    if (record.container_status) {
      let keys = ["exitCode", "reason", "message"];
      for (let key in record.container_status) {
        let status_reason = [];
        for (let val in record.container_status[key]) {
          if (keys.includes(val)) {
            status_reason.push({
              key: val,
              name:
                val === "exitCode"
                  ? getCurrentlocaleText(
                      "inode.events.container_status.exitCode.text",
                    )
                  : val === "reason"
                  ? getCurrentlocaleText(
                      "inode.events.container_status.reason.text",
                    )
                  : getCurrentlocaleText(
                      "inode.events.container_status.message.text",
                    ),
              status: record.container_status[key][val],
            });
          }
        }
        if (
          !keys.includes(key) &&
          record.container_status.hasOwnProperty(key)
        ) {
          data.push({
            key: key,
            name: key,
            status:
              record.container_status[key] && record.container_status[key].state
                ? record.container_status[key].state
                : record.container_status[key],
            extraData: status_reason,
          });
        }
      }
    }

    return (
      <div>
        {record.type === "node" && (
          <div>
            {(record.from || record.to) &&
              record.status.toLowerCase() != "failed" && (
                <Row style={extraRowStyle}>
                  <Col span={5}>
                    <strong>
                      {record.status &&
                      record.status.toLowerCase() === "successful"
                        ? getCurrentlocaleText(
                            "inode.events.upgrade_status.text",
                          )
                        : getCurrentlocaleText("ipaddress.text")}
                    </strong>
                  </Col>
                  <Col span={19}>
                    <Row gutter={0}>
                      <Col span={4}>
                        <strong>
                          {getCurrentlocaleText(
                            "inode.events.ipchange.from.text",
                          )}
                        </strong>
                      </Col>
                      <Col span={1}>{":"}</Col>
                      <Col span={4} pull={2}>
                        {record.from ? record.from : "-"}
                      </Col>
                    </Row>
                    <Row gutter={0}>
                      <Col span={4}>
                        <strong>
                          {getCurrentlocaleText(
                            "inode.events.ipchange.to.text",
                          )}
                        </strong>
                      </Col>
                      <Col span={1}>{":"}</Col>
                      <Col span={4} pull={2}>
                        {record.to ? record.to : "-"}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            {record.display_type &&
              record.display_type.toLowerCase() === "cluster master change" && (
                <Row style={extraRowStyle}>
                  <Col span={5}>
                    <strong>{getCurrentlocaleText("fromInode.text")}</strong>
                  </Col>
                  <Col span={1}>{":"}</Col>
                  <Col>
                    {record.from_node && record.from_node.name
                      ? record.from_node.name
                      : "-"}
                  </Col>
                </Row>
              )}
            {record.cluster && (
              <Row style={extraRowStyle}>
                <Col span={5}>
                  <strong>{getCurrentlocaleText("clusterName.text")}</strong>
                </Col>
                <Col span={1}>{":"}</Col>
                <Col>{record.cluster.name}</Col>
              </Row>
            )}
            <Row style={extraRowStyle}>
              <Col span={5}>
                <strong>{getCurrentlocaleText("organizationName.text")}</strong>
              </Col>
              <Col span={1}>{":"}</Col>
              <Col>{record.organization.name}</Col>
            </Row>
          </div>
        )}
        {record.type === "network" && (
          <div>
            {record.node && (
              <Row style={extraRowStyle}>
                <Col span={5}>
                  <strong>{getCurrentlocaleText("inodeName.text")}</strong>
                </Col>
                <Col span={1}>{":"}</Col>
                <Col>{record.node.name}</Col>
              </Row>
            )}
            {record.cluster && (
              <Row style={extraRowStyle}>
                <Col span={5}>
                  <strong>{getCurrentlocaleText("clusterName.text")}</strong>
                </Col>
                <Col span={1}>{":"}</Col>
                <Col>{record.cluster.name}</Col>
              </Row>
            )}
            <Row style={extraRowStyle}>
              <Col span={5}>
                <strong>{getCurrentlocaleText("organizationName.text")}</strong>
              </Col>
              <Col span={1}>{":"}</Col>
              <Col>{record.organization.name}</Col>
            </Row>
          </div>
        )}
        {record.type === "service" && (
          <div>
            {record.running_containers && (
              <Row style={extraRowStyle}>
                <Col span={5}>
                  <strong>
                    {getCurrentlocaleText("running.containers") + " :"}
                  </strong>
                </Col>
                <Col>{record.running_containers}</Col>
              </Row>
            )}
            {record.container_status && (
              <Row style={extraRowStyle}>
                <Col span={5}>
                  <strong>
                    {getCurrentlocaleText("events.container_name_status.text") +
                      " :"}
                  </strong>
                </Col>
                <Col span={15}>{this.getContainerStatusTag(data)}</Col>
              </Row>
            )}
            {record.kind && (
              <Row style={extraRowStyle}>
                <Col span={5}>
                  <strong>
                    {getCurrentlocaleText("services.kind.label") + " :"}
                  </strong>
                </Col>
                <Col>{capitalize(record.kind)}</Col>
              </Row>
            )}
            {record.node && (
              <Row style={extraRowStyle}>
                <Col span={5}>
                  <strong>{getCurrentlocaleText("inodeName.text")}</strong>
                </Col>
                <Col span={1}>{":"}</Col>
                <Col>{record.node.name}</Col>
              </Row>
            )}
            {record.cluster && (
              <Row style={extraRowStyle}>
                <Col span={5}>
                  <strong>{getCurrentlocaleText("clusterName.text")}</strong>
                </Col>
                <Col span={1}>{":"}</Col>
                <Col>{record.cluster.name}</Col>
              </Row>
            )}
            <Row style={extraRowStyle}>
              <Col span={5}>
                <strong>{getCurrentlocaleText("organizationName.text")}</strong>
              </Col>
              <Col span={1}>{":"}</Col>
              <Col>{record.organization.name}</Col>
            </Row>
          </div>
        )}
      </div>
    );
  };

  eventList = (loading = true, page = 0, size = 25) => {
    let params = {};
    params.page = page;
    params.size = size;
    params.cluster_id = this.props.clusterId;
    EventController.getEvents(params, loading);
  };

  getContainerStatusTag = data => {
    if (data.length > 0) {
      return data
        .sort((x, y) => customSort(x.name, y.name))
        .map((item, i) => {
          let popoverContent = item.extraData
            ? item.extraData.map((extra, i) => {
                return (
                  <div key={extra.name + i}>
                    <strong>{extra.name}</strong>
                    {extra.status}
                  </div>
                );
              })
            : undefined;

          return (
            <Row key={item.name + i}>
              <p key={item.name + i} value={item.name}>
                <span>{item.name + " / " + item.status}&nbsp;</span>
                {item.extraData && item.extraData.length > 0 && (
                  <Popover
                    content={popoverContent}
                    title={<strong>Information</strong>}
                    placement="top"
                  >
                    <span>
                      <Icons
                        type="fa"
                        name="FaInfoCircle"
                        className="profile-2fa-icon-style"
                      />
                    </span>
                  </Popover>
                )}
              </p>
            </Row>
          );
        });
    } else {
      return "-";
    }
  };

  render() {
    let path = window.location.pathname.split("/");
    let hasNextPage =
      this.eventModel.pageable.page < this.eventModel.pageable.total_page - 1;
    let events = this.eventModel.eventList || [];
    let columns = [
      {
        title: getCurrentlocaleText("event.occurred.at.text"),
        key: "createdat",
        width: "25%",
        ellipsis: true,
        render: (text, record, index) => {
          return (
            <span>
              {record.created_at ? (
                <span title={record.displayLongDate}>
                  {record.displayLongDate}
                </span>
              ) : (
                <span>-</span>
              )}
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText("resource.name.text"),
        key: "name",
        width: "25%",
        ellipsis: true,
        render: (text, record, index) => {
          return (
            <span>
              {record.type === "node" && (
                <span title={record.node.name}>
                  <Icons
                    type="fa"
                    name="FaRegHdd"
                    className="middle"
                    style={iconStyle}
                  />{" "}
                  <span>&nbsp;{record.node.name}</span>
                </span>
              )}
              {record.type === "network" && record.peer_network && (
                <span
                  title={
                    record.network.name +
                    "\u27F7" +
                    record.peer_node.name +
                    "/" +
                    record.peer_network.name
                  }
                >
                  <Icons
                    type="fa"
                    name="FaConnectdevelop"
                    className="middle"
                    style={iconStyle}
                  />
                  <span>&nbsp;{record.network.name}</span>
                  {record.peer_network && (
                    <span>
                      &nbsp;{"\u27F7"}&nbsp;{record.peer_node.name}/
                      {record.peer_network.name}
                    </span>
                  )}
                </span>
              )}
              {record.type === "service" && (
                <span title={record.service.name}>
                  <Icons
                    type="fa"
                    name="FaCogs"
                    className="middle"
                    style={iconStyle}
                  />
                  <span>&nbsp;{record.service.name}</span>
                </span>
              )}
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText("event.event_type.text"),
        key: "type",
        width: "25%",
        ellipsis: true,
        render: (text, record, index) => {
          return (
            <span>
              {record.display_type ? (
                <span title={record.display_type}>{record.display_type}</span>
              ) : (
                <span>-</span>
              )}
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText("event.status.text"),
        key: "event",
        width: "25%",
        ellipsis: true,
        render: (text, record, index) => {
          return (
            <span title={getTranslatedString(record.status)}>
              {record.type === "node" && (
                <strong
                  className={getNodeStatusColorCode(
                    record.status === "REBOOT"
                      ? "rebooting"
                      : record.status === "SUCCESSFUL"
                      ? "alive"
                      : record.status === "FAILED"
                      ? "unreachable"
                      : record.status,
                  )}
                >
                  {getTranslatedString(record.status)}
                </strong>
              )}
              {record.type === "network" && (
                <strong className={getNetworkStatusColorCode(record.status)}>
                  {getTranslatedString(record.status)}
                </strong>
              )}
              {record.type === "service" && (
                <strong className={getPodStatusColorCode(record.status)}>
                  {getTranslatedString(record.status)}
                </strong>
              )}
            </span>
          );
        },
      },
    ];

    let container = (
      <div>
        {/* Event List */}
        <div
          style={{
            padding: 24,
            background: "#fff",
            minHeight: 250,
          }}
        >
          {this.eventModel.events.length > 0 && (
            <div
              style={{ display: "inline-flex", width: "50%", marginBottom: 15 }}
            >
              <span>
                <a
                  style={{ fontSize: 14, marginLeft: 20 }}
                  onClick={this.expandRow}
                >
                  <span>
                    <Icons
                      className={"valign-middle"}
                      type="ai"
                      name={
                        this.state.expandedRowKeys.length !== events.length
                          ? "AiOutlinePlusSquare"
                          : "AiOutlineMinusSquare"
                      }
                    />
                    <span style={{ marginLeft: 5 }}>
                      {this.state.expandedRowKeys.length !== events.length
                        ? getCurrentlocaleText(
                            "node.list.table.expand_all.label.text",
                          )
                        : getCurrentlocaleText(
                            "node.list.table.collapse_all.label.text",
                          )}
                    </span>
                  </span>
                </a>
              </span>
            </div>
          )}
          <Row gutter={16} type="flex" align="middle">
            <Col span={24}>
              {this.eventModel.events.length > 0 ? (
                <TableLayout
                  pagination={false}
                  columns={columns}
                  dataSource={events}
                  hideRowSelection={true}
                  expandedRowRender={record => {
                    return this.getMoreInfo(record);
                  }}
                  expandedRowKeys={this.state.expandedRowKeys}
                  onExpand={this.onExpand}
                  hasLazyLoading={false}
                  footer={
                    hasNextPage
                      ? () => {
                          return (
                            <div style={{ textAlign: "center" }}>
                              <Link to={path.join("/") + "/clusterevents"}>
                                <strong>
                                  {getCurrentlocaleText("moreEvents.text")}
                                </strong>
                              </Link>
                            </div>
                          );
                        }
                      : null
                  }
                />
              ) : (
                <div>
                  {!this.eventModel.loading && (
                    <div>
                      <h3>{getCurrentlocaleText("noevents.text")}</h3>
                    </div>
                  )}
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    );

    return (
      <LoadingComponent loading={this.eventModel.loading}>
        {container}
      </LoadingComponent>
    );
  }
}

export default ClusterEventList;
