import React, { Component } from "react";
import { Popover, Button } from "antd";
import { inject, observer } from "mobx-react";
import Icons from "components/UI-Components/Icons";
import propTypes from "prop-types";

@inject("ModelStore", "UiStore")
@observer
class PopOverHelp extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (!this.props.isHelpOnForm) {
      if (this.props.helpData && this.props.helpData.data) {
        this.props.UiStore.SetStoreData("helpData", this.props.helpData);
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextprops) {
    if (!nextprops.isHelpOnForm) {
      if (nextprops.helpData && nextprops.helpData.data) {
        this.props.UiStore.SetStoreData("helpData", nextprops.helpData);
      }
    }
  }

  render() {
    let helpContent =
        this.props.isHelpOnForm || this.props.isHelpDataGiven
          ? this.props.helpData
          : this.props.UiStore.getHelpcontent,
      height = this.props.isHelpOnForm ? this.props.height : 450,
      width = this.props.isHelpOnForm ? this.props.width : 500;
    const HelpCard = (
      <div style={{ width: width, height: height }}>
        <h3 className="help-title">
          {helpContent.title ? helpContent.title : null}
        </h3>
        {helpContent.data &&
          helpContent.data.map((item, i) => {
            return (
              <div key={i + "help-div"}>
                <h3 className="help-subtitle" key={i + "help-subtitle"}>
                  {item.subtitle}
                </h3>
                <span className="help-content" key={i + "help-content"}>
                  {item.content}
                </span>
                <br />
              </div>
            );
          })}
      </div>
    );
    return !this.props.disabled ? (
      <Popover
        content={HelpCard}
        arrowPointAtCenter
        placement={this.props.placement ? this.props.placement : "leftBottom"}
        trigger="hover"
        overlayClassName={
          this.props.overlayClassName ? this.props.overlayClassName : ""
        }
      >
        <Button className="popover-help-button" shape="circle">
          <Icons
            type="fa"
            name="FaInfoCircle"
            style={this.props.style ? this.props.style : { fontSize: 16 }}
            className={this.props.className}
          />
        </Button>
      </Popover>
    ) : (
      <Button
        disabled={this.props.disabled ? this.props.disabled : false}
        className="popover-help-button"
        shape="circle"
      >
        <Icons
          type="fa"
          name="FaInfoCircle"
          style={this.props.style ? this.props.style : { fontSize: 16 }}
          className={this.props.className}
        />
      </Button>
    );
  }
}

PopOverHelp.propTypes = {
  isHelpOnForm: propTypes.bool,
  helpData: propTypes.any.isRequired,
};

export default PopOverHelp;
