import React, { Component } from "react";

class DarkAppName extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <p style={{ fontSize: 17, margin: "0 8px", paddingTop: 5, opacity: 0.7 }}>
        {"Secure Edge"}
      </p>
    );
  }
}

export default DarkAppName;
