import Resources from "Core/API/Resources";
import BaseController from "controller/BaseController";
import UiStore from "Stores/UiStore";
import AuthStore from "Stores/AuthStore";
import {
  copyObject,
  getErrorMessage,
  getCurrentlocaleText,
  isoToDateString,
  getScanType,
} from "Core/Utils";
import DeviceDiscoveryViewModel from "../view-model/DeviceDiscoveryViewModel";
import FileSaver from "file-saver";
const RESOURCE = "inodes";
const DEVICE_DISCOVERY_SCAN_LIST_URL = Resources.getUrl(
  "device_discovery_scan_list",
);
const DEVICE_DISCOVERY_SCAN_URL = Resources.getUrl("device_discovery_scan");
const DEVICE_DISCOVERY_SCAN_RUN_NOW_URL = Resources.getUrl(
  "device_discovery_scan_run_now",
);
const DEVICE_DISCOVERY_SCAN_UNIQUE_URL = Resources.getUrl(
  "device_discovery_scan_unique",
);
const DEVICE_DISCOVERY_REPORT_LIST_URL = Resources.getUrl(
  "device_discovery_reports_list",
);
const DEVICE_DISCOVERY_REPORT_DOWNLOAD_URL = Resources.getUrl(
  "device_discovery_report_download",
);
const DEVICE_DISCOVERY_REPORT_BY_ID_URL = Resources.getUrl(
  "device_discovery_report_by_id",
);
const DEVICE_DISCOVERY_SUMMARY = Resources.getUrl("device_discovery_summary");
const DEVICE_DISCOVERY_REPORT_DIFF_URL = Resources.getUrl(
  "device_discovery_report_diff",
);
const scanType = getScanType();

class DeviceDiscoveryController {
  getScans = (id, nodeOrClus, params = {}, forceCall) => {
    return new Promise((resolve, reject) => {
      let url = DEVICE_DISCOVERY_SCAN_LIST_URL.replace("v1", "v2")
        .replace("{:point}", nodeOrClus)
        .replace("{:id}", id);

      // remove search param if it is empty
      if (params.hasOwnProperty("search") && params.search.length === 0) {
        delete params.search;
      }

      // remove scan type param if it is empty
      if (params.hasOwnProperty("scantype") && params.scantype.length === 0) {
        delete params.scantype;
      }

      // page size setting
      params.size = params.size ? params.size : 100;
      BaseController.query(RESOURCE, url, copyObject(params))
        .then(resp => {
          if (resp) {
            if (forceCall) {
              DeviceDiscoveryViewModel.resetScans();
            }
            DeviceDiscoveryViewModel.setPageable(resp);
            DeviceDiscoveryViewModel.setScanList(resp.results);
          }
          DeviceDiscoveryViewModel.setLoading(false);
          resolve(resp);
        })
        .catch(err => {
          DeviceDiscoveryViewModel.setLoading(false);
          reject(err);
        });
    });
  };

  createScan = (id, formData) => {
    return new Promise((resolve, reject) => {
      let url = DEVICE_DISCOVERY_SCAN_URL.replace("v1", "v2").replace(
        "{:id}",
        id,
      );
      BaseController.create(url, formData)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          UiStore.setErrorMessage(getErrorMessage(err, RESOURCE));
          resolve(err);
        });
    });
  };

  runNowScan = (id, scanId, formData) => {
    return new Promise((resolve, reject) => {
      let url = DEVICE_DISCOVERY_SCAN_RUN_NOW_URL.replace("v1", "v2")
        .replace("{:id}", id)
        .replace("{:scanId}", scanId);

      BaseController.create(url, formData)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          UiStore.setErrorMessage(getErrorMessage(err, RESOURCE));
          resolve(err);
        });
    });
  };

  editScan = (selScan, formData) => {
    return new Promise((resolve, reject) => {
      let url = DEVICE_DISCOVERY_SCAN_UNIQUE_URL.replace("v1", "v2")
        .replace("{:id}", selScan.id)
        .replace("{:nwId}", selScan.network.id);
      BaseController.update(url, formData)
        .then(resp => {
          if (resp) {
            UiStore.setSuccessMessage(
              getCurrentlocaleText("general.api.update.success.text", {
                0: "Scan",
              }),
            );
          }
          resolve(response);
        })
        .catch(error => {
          UiStore.setErrorMessage(getErrorMessage(error, RESOURCE));
          reject(error);
        });
    });
  };

  deleteScan = data => {
    return new Promise((resolve, reject) => {
      let urls = data.map(object => {
        return DEVICE_DISCOVERY_SCAN_UNIQUE_URL.replace("v1", "v2")
          .replace("{:id}", object.id)
          .replace("{:nwId}", object.network.id);
      });
      BaseController.delete(urls)
        .then(resp => {
          if (resp) {
            UiStore.setSuccessMessage(
              getCurrentlocaleText("general.api.delete.success.text"),
            );
          }
          resolve(response);
        })
        .catch(error => {
          UiStore.setErrorMessage(getErrorMessage(error, RESOURCE));
          reject(error);
        });
    });
  };

  getReports = (id, nodeOrClus, params = {}, forceCall = false) => {
    return new Promise((resolve, reject) => {
      let url = DEVICE_DISCOVERY_REPORT_LIST_URL.replace("v1", "v2")
        .replace("{:point}", nodeOrClus)
        .replace("{:id}", id);

      // remove search param if it is empty
      if (params.hasOwnProperty("search") && params.search.length === 0) {
        delete params.search;
      }

      // remove scan type param if it is empty
      if (params.hasOwnProperty("scantype") && params.scantype.length === 0) {
        delete params.scantype;
      }

      // page size setting
      params.size = params.size ? params.size : 25; //InodeViewModel.pageable.size;
      BaseController.query(RESOURCE, url, copyObject(params))
        .then(resp => {
          if (resp) {
            if (forceCall) {
              DeviceDiscoveryViewModel.resetReports();
            }
            if (!params.page || params.page === 0) {
              DeviceDiscoveryViewModel.resetReports();
            }
            DeviceDiscoveryViewModel.setReportsPageable(resp);
            DeviceDiscoveryViewModel.setReportsList(resp.results);
          }
          DeviceDiscoveryViewModel.setReportsLoading(false);
          resolve(resp);
        })
        .catch(err => {
          DeviceDiscoveryViewModel.setReportsLoading(false);
          reject(err);
        });
    });
  };

  downloadReport = (nodeOrClus, id, reportId, fileName) => {
    return new Promise((resolve, reject) => {
      let url = DEVICE_DISCOVERY_REPORT_DOWNLOAD_URL.replace("v1", "v2")
        .replace("{:point}", nodeOrClus)
        .replace("{:id}", id)
        .replace("{:reportId}", reportId);

      BaseController.query(RESOURCE, url)
        .then(response => {
          let blob = new Blob([response], {
            type: "text/csv;charset=utf-8;",
          });
          FileSaver.saveAs(blob, fileName);
          resolve(response);
        })
        .catch(err => {
          UiStore.setErrorMessage(getErrorMessage(err, RESOURCE));
          resolve(err);
        });
    });
  };

  diffReport = (nwId, postData) => {
    return new Promise((resolve, reject) => {
      let url = DEVICE_DISCOVERY_REPORT_DIFF_URL.replace("v1", "v2").replace(
        "{:nwId}",
        nwId,
      );

      BaseController.create(url, postData)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          UiStore.setErrorMessage(getErrorMessage(err, RESOURCE));
          reject(err);
        });
    });
  };

  downloadDiffReport = (nwId, postData, fileName, params = {}) => {
    return new Promise((resolve, reject) => {
      let url = DEVICE_DISCOVERY_REPORT_DIFF_URL.replace("v1", "v2").replace(
        "{:nwId}",
        nwId,
      );

      BaseController.create(url, postData, copyObject(params))
        .then(response => {
          let blob = new Blob([response], {
            type: "text/csv;charset=utf-8;",
          });
          FileSaver.saveAs(blob, fileName);
          resolve(response);
        })
        .catch(err => {
          UiStore.setErrorMessage(getErrorMessage(err, RESOURCE));
          reject(err);
        });
    });
  };

  getReportById = (nodeOrClus, id, reportId) => {
    return new Promise((resolve, reject) => {
      let url = DEVICE_DISCOVERY_REPORT_BY_ID_URL.replace("v1", "v2")
        .replace("{:point}", nodeOrClus)
        .replace("{:id}", id)
        .replace("{:reportId}", reportId);

      BaseController.query(RESOURCE, url, {})
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          UiStore.setErrorMessage(getErrorMessage(err, RESOURCE));
          resolve(err);
        });
    });
  };

  getDeviceDiscoverySummary = (requestFrom, id) => {
    return new Promise((resolve, reject) => {
      let url = DEVICE_DISCOVERY_SUMMARY.replace("{:from}", requestFrom)
        .replace("{:id}", id)
        .replace("v1", "v2");
      BaseController.query(RESOURCE, url)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          UiStore.setErrorMessage(getErrorMessage(error, RESOURCE));
          reject(error);
        });
    });
  };
}

export default new DeviceDiscoveryController();
