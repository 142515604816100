import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Row, Col, Card, Skeleton } from "antd";
const moment = require("moment");
import { CTDDevicesAtRisk } from "./CTDDevicesAtRisk";
import CTDBarChart from "./CTDBarChart";
import { CTDSourceDest } from "./CTDSourceDest";
import InodeController from "../../controller/InodeController";
import OrgController from "../../controller/OrgController";
import { CTDSeverity } from "./CTDSeverity";
import { isEmpty } from "Core/Utils";
import Icons from "../UI-Components/Icons";
import LoadingComponent from "../UI-Components/LoadingComponent";

@inject("InodeViewModel", "OrgViewModel")
@observer
export default class CTDDashboard extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = this.props.InodeViewModel;
    this.orgModel = this.props.OrgViewModel;
    this.orgId = this.props.orgId;
    this.nodeId = this.props.nodeId;
    this.refreshData = "";
  }

  componentDidMount() {
    clearInterval(this.refreshData);
    this.getData();
    this.refreshData = setInterval(() => {
      this.getData();
    }, 5 * 60 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.refreshData);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.startDate !== prevProps.startDate ||
      this.props.endDate !== prevProps.endDate
    ) {
      this.getData();
    }
  }

  getData = () => {
    let params = {};
    if (this.props.startDate && this.props.endDate) {
      params = {
        start_date: this.props.startDate.toISOString(),
        end_date: this.props.endDate.toISOString(),
        size: 5,
      };
      if (this.nodeId) {
        this.getTopClassThreatsNode(params);
        this.getTopThreatsTypeNode(params);
        if (
          this.inodeModel.trafficInsightData &&
          !this.inodeModel.trafficInsightData.response
        ) {
          this.getTrafficInsight(this.nodeId);
        }
      } else {
        this.getTopClassThreatsOrg(params);
        this.getTopThreatsTypeOrg(params);
      }
    }
  };

  getTopClassThreatsNode = params => {
    this.inodeModel.topThreatsClassLoading = true;
    InodeController.getTopClassThreats(this.nodeId, params)
      .then(resp => {
        if (resp) {
          this.inodeModel.setTopThreatsClassification(resp);
        }
        this.inodeModel.topThreatsClassLoading = false;
      })
      .catch(err => {
        this.inodeModel.topThreatsClassLoading = false;
        this.inodeModel.setTopThreatsClassification({});
      });
  };

  getTopClassThreatsOrg = params => {
    this.orgModel.topThreatsClassLoading = true;
    OrgController.getTopClassThreats(this.orgId, params)
      .then(resp => {
        if (resp) {
          this.orgModel.setTopThreatsClassification(resp);
        }
        this.orgModel.topThreatsClassLoading = false;
      })
      .catch(err => {
        this.orgModel.topThreatsClassLoading = false;
        this.orgModel.setTopThreatsClassification({});
      });
  };

  getTopThreatsTypeNode = params => {
    this.inodeModel.topThreatsTypeLoading = true;
    InodeController.getTopThreatsNwSummary(this.nodeId, params)
      .then(resp => {
        this.inodeModel.setThreatsByType(resp);
        this.inodeModel.topThreatsTypeLoading = false;
      })
      .catch(err => {
        this.inodeModel.topThreatsTypeLoading = false;
      });
  };

  getTopThreatsTypeOrg = params => {
    this.orgModel.topThreatsTypeLoading = true;
    OrgController.getTopThreatsNwSummary(this.orgId, params)
      .then(resp => {
        this.orgModel.setThreatsByType(resp);
        this.orgModel.topThreatsTypeLoading = false;
      })
      .catch(err => {
        this.orgModel.topThreatsTypeLoading = false;
      });
  };

  getTrafficInsight = nodeId => {
    this.inodeModel.getTrafficInsightLoading = true;
    InodeController.getTrafficInsight(nodeId)
      .then(response => {
        if (response) {
          this.inodeModel.setTrafficInsightData({
            response,
          });
          this.inodeModel.getTrafficInsightLoading = false;
        }
      })
      .catch(err => {
        this.inodeModel.getTrafficInsightLoading = false;
      });
  };

  render() {
    let model = this.nodeId ? "inodeModel" : "orgModel";
    let threatsClass = this[model].topThreatsClassification;

    return (
      <LoadingComponent loading={this[model].topThreatsTypeLoading}>
        {threatsClass && !threatsClass.results && (
          <div
            className="all-center flex-column"
            style={{ minHeight: "450px" }}
          >
            {threatsClass.stats && (
              <div className="all-center p-15">
                <div className="p-15">
                  <h2 className="fs-2">
                    {threatsClass.stats.packets_analyzed}
                  </h2>
                  <div className=" text-muted">No. of Packets Analysed</div>
                </div>
                <div className="p-15">
                  <h2 className="fs-2">
                    {threatsClass.stats.packet_capture_files_processed}
                  </h2>
                  <div className=" text-muted">
                    Packet Capture Files Processed
                  </div>
                </div>
                {this.nodeId && (
                  <div className="p-15">
                    <h2 className="fs-2">
                      1/
                      {this[model].trafficInsightData &&
                      this[model].trafficInsightData.response &&
                      this[model].trafficInsightData.response
                        .sampling_frequency_in_pkts
                        ? this[model].trafficInsightData.response
                            .sampling_frequency_in_pkts
                        : 50000}
                      <div className="d-inline-block pl-10 fs-1">[packets]</div>
                    </h2>
                    <div className=" text-muted">Sampling Frequency</div>
                  </div>
                )}
              </div>
            )}
            <div className="all-center flex-column p-15 mt-15">
              <h2>No Threats Detected</h2>
              <div className=" text-muted">
                Select different timeline to see the threats
              </div>
            </div>
          </div>
        )}
        {threatsClass &&
          threatsClass.results &&
          threatsClass.results.length > 0 && (
            <div>
              <Row>
                <Col span={12} className="pr-10">
                  <CTDSeverity
                    requestFrom={model}
                    orgId={this.orgId}
                    nodeId={this.nodeId}
                    duration={this.props.duration}
                    startDate={this.props.startDate}
                    endDate={this.props.endDate}
                  />
                  <Row>
                    <Col span={24} className="mt-10 px-5 pt-5">
                      <CTDDevicesAtRisk
                        requestFrom={model}
                        orgId={this.orgId}
                        nodeId={this.nodeId}
                        duration={this.props.duration}
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={12} className="d-flex">
                  <CTDSourceDest
                    requestFrom={model}
                    orgId={this.orgId}
                    nodeId={this.nodeId}
                    duration={this.props.duration}
                    startDate={this.props.startDate}
                    endDate={this.props.endDate}
                    activeTab={this.props.activeTab}
                  />
                </Col>
              </Row>
              <Row className="mt-10">
                <Col span={4} className="pr-10">
                  <Card className="ctd-threat">
                    <b>Top Destination Port</b>
                    {this[model].topThreatsTypeLoading && (
                      <Skeleton active block size="small" className="py-15" />
                    )}
                    {!this[model].topThreatsTypeLoading &&
                      this[model].getThreatsByType &&
                      this[model].getThreatsByType.destination_port &&
                      isEmpty(
                        this[model].getThreatsByType.destination_port,
                      ) && (
                        <div className="all-center p-15">No Threats found</div>
                      )}
                    {!this[model].topThreatsTypeLoading &&
                      this[model].getThreatsByType &&
                      this[model].getThreatsByType.destination_port &&
                      !isEmpty(
                        this[model].getThreatsByType.destination_port,
                      ) && (
                        <div style={{ fontSize: "13px" }}>
                          <CTDBarChart
                            data={
                              this[model].getThreatsByType.destination_port
                                .results
                                ? this[model].getThreatsByType.destination_port
                                    .results
                                : []
                            }
                            objKey="port"
                            headingName="Port"
                          />
                        </div>
                      )}
                  </Card>
                </Col>
                <Col span={4} className="pr-10">
                  <Card className="ctd-threat">
                    <b>Top Protocol</b>
                    {this[model].topThreatsTypeLoading && (
                      <Skeleton active block size="small" className="py-15" />
                    )}
                    {!this[model].topThreatsTypeLoading &&
                      this[model].getThreatsByType &&
                      this[model].getThreatsByType.protocols &&
                      isEmpty(this[model].getThreatsByType.protocols) && (
                        <div className="all-center p-15">No Threats found</div>
                      )}
                    {!this[model].topThreatsTypeLoading &&
                      this[model].getThreatsByType &&
                      this[model].getThreatsByType.protocols &&
                      !isEmpty(this[model].getThreatsByType.protocols) && (
                        <div style={{ fontSize: "13px" }}>
                          <CTDBarChart
                            data={
                              this[model].getThreatsByType.protocols.results
                                ? this[model].getThreatsByType.protocols.results
                                : []
                            }
                            objKey="protocol"
                            headingName="Protocols"
                          />
                        </div>
                      )}
                  </Card>
                </Col>
                <Col span={4} className="pr-15">
                  <Card className="ctd-threat">
                    <b>Top Threats</b>
                    {this[model].topThreatsClassLoading && (
                      <Skeleton active block size="small" className="py-15" />
                    )}
                    {!this[model].topThreatsClassLoading &&
                      threatsClass &&
                      threatsClass.results &&
                      threatsClass.results.length == 0 && (
                        <div className="all-center p-15">No Threats found</div>
                      )}
                    {!this[model].topThreatsClassLoading &&
                      threatsClass &&
                      threatsClass.results &&
                      threatsClass.results.length > 0 && (
                        <div style={{ fontSize: "13px" }}>
                          <CTDBarChart
                            data={threatsClass.results}
                            objKey="classification"
                            headingName="Threats"
                          />
                        </div>
                      )}
                  </Card>
                </Col>
                <Col span={6} className="pr-10">
                  <Card className="ctd-threat">
                    <b>Top Source [IP`s/Addresses]</b>
                    {this[model].topThreatsTypeLoading && (
                      <Skeleton active block size="small" className="py-15" />
                    )}
                    {!this[model].topThreatsTypeLoading &&
                      this[model].getThreatsByType &&
                      this[model].getThreatsByType.source_ip &&
                      isEmpty(this[model].getThreatsByType.source_ip) && (
                        <div className="all-center p-15">No Threats found</div>
                      )}
                    {!this[model].topThreatsTypeLoading &&
                      this[model].getThreatsByType &&
                      this[model].getThreatsByType.source_ip &&
                      !isEmpty(this[model].getThreatsByType.source_ip) && (
                        <div style={{ fontSize: "13px" }}>
                          <CTDBarChart
                            data={
                              this[model].getThreatsByType.source_ip.results
                                ? this[model].getThreatsByType.source_ip.results
                                : []
                            }
                            objKey="ip"
                            headingName="Sources"
                          />
                        </div>
                      )}
                  </Card>
                </Col>
                <Col span={6}>
                  <Card className="ctd-threat">
                    <b>Top Destination [IP`s/Addresses]</b>
                    {this[model].topThreatsTypeLoading && (
                      <Skeleton active block size="small" className="py-15" />
                    )}
                    {!this[model].topThreatsTypeLoading &&
                      this[model].getThreatsByType &&
                      this[model].getThreatsByType.destination_ip &&
                      isEmpty(this[model].getThreatsByType.destination_ip) && (
                        <div className="all-center p-15">No Threats found</div>
                      )}
                    {!this[model].topThreatsTypeLoading &&
                      this[model].getThreatsByType &&
                      this[model].getThreatsByType.destination_ip &&
                      !isEmpty(this[model].getThreatsByType.destination_ip) && (
                        <div style={{ fontSize: "13px" }}>
                          <CTDBarChart
                            data={
                              this[model].getThreatsByType.destination_ip
                                .results
                                ? this[model].getThreatsByType.destination_ip
                                    .results
                                : []
                            }
                            objKey="ip"
                            headingName="Destinations"
                          />
                        </div>
                      )}
                  </Card>
                </Col>
              </Row>
            </div>
          )}
      </LoadingComponent>
    );
  }
}
