import React, { Component } from "react";
import propTypes from "prop-types";
import { Modal, Select } from "antd";
const Option = Select.Option;

const ResetDashboardModal = ({
  isModalOpen,
  onRequestClose,
  onConfirm,
  title = null,
  message = null,
}) => {
  return (
    <div>
      <Modal
        title={title ? title : "Reset Dashboard"}
        visible={isModalOpen}
        onCancel={onRequestClose}
        onOk={onConfirm}
        cancelText={"No - Don't Reset"}
        okText={"Yes - Reset"}
        maskClosable={false}
      >
        <div>{message ? message : "Do you really want to reset default?"}</div>
      </Modal>
    </div>
  );
};

ResetDashboardModal.propTypes = {
  isModalOpen: propTypes.bool,
  onRequestClose: propTypes.func,
  onConfirm: propTypes.func,
  title: propTypes.string,
  message: propTypes.string,
};

export default ResetDashboardModal;
