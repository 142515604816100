import React, { Component } from "react";
import { Modal, Icon } from "antd";
import { getCurrentlocaleText } from "Core/Utils";
import Icons from "../../../UI-Components/Icons";
class NetworkHairpinConfirmDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: this.props.isVisible,
      loading: false,
      currentState: this.props.currentState,
    };
  }
  UNSAFE_componentWillReceiveProps() {
    this.setState({
      visible: this.props.isVisible,
      currentState: this.props.currentState,
    });
  }

  handleOk = e => {
    if (this.props.onOk) {
      this.props.onOk();
      this.setState({ loading: true });
    }
  };

  handleCancel = e => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  render() {
    return (
      <div>
        <Modal
          title={
            <span>
              {" "}
              {this.state.currentState === "hairpin-allow"
                ? getCurrentlocaleText(
                    "networks.hairpin.deny.confirm.title.text",
                  )
                : getCurrentlocaleText(
                    "networks.hairpin.allow.confirm.title.text",
                  )}
            </span>
          }
          visible={this.state.visible}
          okText={
            <span>
              <Icons type="ai" name="AiOutlineSync" />
              {this.state.currentState === "hairpin-allow"
                ? getCurrentlocaleText(
                    "networks.hairpin.deny.confirm.action.text",
                  )
                : getCurrentlocaleText(
                    "networks.hairpin.allow.confirm.action.text",
                  )}
            </span>
          }
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          maskClosable={false}
          confirmLoading={this.state.loading}
        >
          <p>
            {this.state.currentState === "hairpin-allow"
              ? getCurrentlocaleText(
                  "networks.hairpin.deny.confirm.message.text",
                )
              : getCurrentlocaleText(
                  "networks.hairpin.allow.confirm.message.text",
                )}
          </p>
        </Modal>
      </div>
    );
  }
}

export default NetworkHairpinConfirmDialog;
