import React, { Component } from "react";
import { Menu, Dropdown, Button } from "antd";
import { observer, inject } from "mobx-react";
import QuickActionCreate from "./QuickActionCreate";

const MenuItemGroup = Menu.ItemGroup;
@inject("AuthStore", "ModelStore", "UiStore", "OrgViewModel")
@observer
class QuickAction extends Component {
  render() {
    return (
      <span>
        {/* <QuickActionSearch /> */}
        {(this.props.AuthStore.IsPermitted("NODE:CREATE") ||
          this.props.AuthStore.IsPermitted("USER:CREATE") ||
          this.props.AuthStore.IsPermitted("ORG:CREATE")) && (
          <QuickActionCreate />
        )}
        {!this.props.UiStore.isSiderFold && <br />}
      </span>
    );
  }
}

export default QuickAction;
