import React, { Component } from "react";
import Input from "components/UI-Components/InputBox";
import propTypes from "prop-types";
import Icons from "./Icons";

class SearchInput extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Input
        key={this.props.key}
        placeholder={this.props.placeholder}
        suffix={
          <span
            onClick={this.props.value ? this.props.clearSearch : () => {}}
            style={this.props.value ? { cursor: "pointer" } : {}}
          >
            <Icons
              type="ai"
              name={this.props.value ? "AiOutlineClose" : "AiOutlineSearch"}
            />
          </span>
        }
        type="text"
        size={this.props.size ? this.props.size : ""}
        onChange={this.props.onChange}
        value={this.props.value}
        disabled={this.props.disabled}
        className={this.props.className ? this.props.className : ""}
      />
    );
  }
}

SearchInput.propTypes = {
  placeholder: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
  value: propTypes.string.isRequired,
  clearSearch: propTypes.func.isRequired,
  disabled: propTypes.bool,
  className: propTypes.string,
};

export default SearchInput;
