import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Input, InputNumber, Select } from "antd";
import { ALERT, getCurrentlocaleText } from "Core/Utils";

const InputGroup = Input.Group;
const Option = Select.Option;

@inject("UiStore")
@observer
class DurationSelector extends Component {
  constructor(props) {
    super(props);
    this.UiStore = this.props.UiStore;
    this.state = {
      duration: this.props.minutesDuration,
      units: ALERT.DURATION.MINUTES,
      min: this.props.minutesMinimum,
      max: this.props.minutesMaximum,
      step: this.props.minutesStep,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    let value = {};
    value.duration = props.Val.duration;
    value.units = props.Val.units;
    if (props.Val.duration !== this.state.duration) {
      this.setState({ duration: value.duration });
      this.triggerChange({ duration: value.duration, units: value.units });
    }
    if (props.Val.units !== this.state.units) {
      if (value.units === ALERT.DURATION.MINUTES) {
        value.min = this.props.minutesMinimum;
        value.max = this.props.minutesMaximum;
        value.step = this.props.minutesStep;
      } else if (value.units === ALERT.DURATION.HOURS) {
        value.min = this.props.hourMinimum;
        value.max = this.props.hourMaximum;
        value.step = props.hourStep;
      } else {
        value.min = this.props.dayMinimum;
        value.max = this.props.dayMaximum;
        value.step = this.props.dayStep;
      }
      this.setState({
        min: value.min,
        max: value.max,
        step: value.step,
        units: value.units,
      });
      this.triggerChange({ duration: value.duration, units: value.units });
    }
  }

  UNSAFE_componentWillMount() {
    let value = {};
    value.units = this.props.Val && this.props.Val.units;
    value.duration = this.props.Val && this.props.Val.duration;
    if (value.units === ALERT.DURATION.MINUTES) {
      value.min = this.props.minutesMinimum;
      value.max = this.props.minutesMaximum;
      value.step = this.props.minutesStep;
    } else if (value.units === ALERT.DURATION.HOURS) {
      value.min = this.props.hourMinimum;
      value.max = this.props.hourMaximum;
      value.step = this.props.hourStep;
    } else {
      value.min = this.props.dayMinimum;
      value.max = this.props.dayMaximum;
      value.step = this.props.dayStep;
    }
    this.setState({
      duration: value.duration,
      units: value.units,
      min: value.min,
      max: value.max,
      step: value.step,
    });
  }

  handleDuration = duration => {
    let value = {};
    if (/^[0-9]+$/.test(duration)) {
      value.duration = duration ? duration : this.state.min;
      this.setState({ duration: value.duration });
      this.triggerChange({ duration: value.duration, units: this.state.units });
    }
  };

  handleUnits = units => {
    let value = {};
    value.units = units;
    if (units === ALERT.DURATION.MINUTES) {
      value.duration = this.props.minutesDuration;
      value.min = this.props.minutesMinimum;
      value.max = this.props.minutesMaximum;
      value.step = this.props.minutesStep;
    } else if (units === ALERT.DURATION.HOURS) {
      value.duration = this.props.hourDuration;
      value.min = this.props.hourMinimum;
      value.max = this.props.hourMaximum;
      value.step = this.props.hourStep;
    } else {
      value.duration = this.props.dayDuration;
      value.min = this.props.dayMinimum;
      value.max = this.props.dayMaximum;
      value.step = this.props.dayStep;
    }
    this.setState({
      duration: value.duration,
      min: value.min,
      max: value.max,
      step: value.step,
      units: value.units,
    });
    this.triggerChange({ duration: value.duration, units: value.units });
  };

  triggerChange = value => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  render() {
    return (
      <InputGroup className="expires-in">
        <InputNumber
          min={this.state.min}
          max={this.state.max}
          step={this.state.step}
          value={this.state.duration}
          onChange={this.handleDuration}
          disabled={this.props.disabled ? this.props.disabled : false}
        />
        <Select
          value={this.state.units}
          onChange={this.handleUnits}
          disabled={this.props.disabled ? this.props.disabled : false}
          notFoundContent={getCurrentlocaleText(
            "general.notfound.placeholder.text",
          )}
        >
          <Option key={ALERT.DURATION.MINUTES} value={ALERT.DURATION.MINUTES}>
            {getCurrentlocaleText("alert.duration.minutes.text")}
          </Option>
          <Option key={ALERT.DURATION.HOURS} value={ALERT.DURATION.HOURS}>
            {getCurrentlocaleText("alert.duration.hours.text")}
          </Option>
          <Option key={ALERT.DURATION.DAYS} value={ALERT.DURATION.DAYS}>
            {getCurrentlocaleText("alert.duration.days.text")}
          </Option>
        </Select>
      </InputGroup>
    );
  }
}

export default DurationSelector;
