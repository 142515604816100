import React, { Component } from "react";
import { Modal, Divider } from "antd";
import { observer, inject } from "mobx-react";
import DarkAppInfo from "components/UI-Components/DarkAppInfo";
import IoFooter from "components/layout/Footer";

@inject("UiStore")
@observer
class About extends Component {
  constructor(props) {
    super(props);
  }

  closeModal = () => {
    this.setState({ loading: false });
    this.props.UiStore.AboutMdl = false;
  };

  render() {
    return (
      <Modal
        visible={this.props.UiStore.AboutMdl}
        maskClosable={false}
        onCancel={this.closeModal}
        width={410}
        footer={null}
      >
        <center>
          <DarkAppInfo forAbout={true} showDefault={true} />
          <IoFooter isNotChrome={false} showCopyRight={true} />
        </center>
      </Modal>
    );
  }
}

export default About;
