import React, { Component } from "react";
import { Row, Switch, Button, Col } from "antd";
import { getCurrentlocaleText } from "../../Core/Utils";
import BulkDownload from "../../assets/img/bulk-download.svg";
import BulkExport from "../../assets/img/bulk-export.svg";
import BulkUpload from "../../assets/img/bulk-upload.svg";
import BulkViewHistory from "../../assets/img/bulk-Viewhistory.svg";
import BulkTemplate from "../../assets/img/bulk-template.svg";

class BulkConfigHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };
  }

  componentDidMount() {
    let pathname = location.pathname;
    let path = pathname.split("/");
    if (pathname) {
      if (
        path[3] === "users" ||
        path[3] === "inodes" ||
        path[3] === "networks"
      ) {
        if (
          path[1] === "orgs" &&
          path[2] &&
          path[4] === "bulkconfig" &&
          !path[5]
        ) {
          this.setState({
            checked: true,
          });
        }
      }
      if (
        path[3] === "users" ||
        path[3] === "inodes" ||
        path[3] === "networks"
      ) {
        if (
          path[1] === "orgs" &&
          path[2] &&
          path[4] === "bulkconfig" &&
          path[5] === "view-history"
        ) {
          this.setState({
            checked: true,
          });
          this.props.showTableModal();
        }
      }
      if (
        path[3] === "users" ||
        path[3] === "inodes" ||
        path[3] === "networks"
      ) {
        if (
          path[1] === "orgs" &&
          path[2] &&
          path[4] === "bulkconfig" &&
          path[5] === "upload"
        ) {
          this.setState({
            checked: true,
          });
          if (!this.props.readOnly) {
            this.props.showUploadModal();
          }
        }
      }
    }
  }

  onchangeswitch = checked => {
    const { resource, changeRoute, onAfterClick } = this.props;

    if (resource === "User") {
      if (checked) {
        changeRoute("users", "bulkconfig");
      } else {
        onAfterClick("users");
      }
    } else if (resource === "Node") {
      if (checked) {
        changeRoute("inodes", "bulkconfig");
      } else {
        onAfterClick("inodes");
      }
    } else if (resource === "Network") {
      if (checked) {
        changeRoute("networks", "bulkconfig");
      } else {
        onAfterClick("networks");
      }
    }

    this.setState({ checked });
  };

  render() {
    const { checked } = this.state;

    const iconStyle = {
      width: "20px",
      height: "24px",
      verticalAlign: "top",
      fill: "currentColor",
      overflow: "hidden",
    };

    const bulkActionButton = [
      {
        text: getCurrentlocaleText("bulk.new.template", {
          0: this.props.resource,
        }),
        icon: <BulkTemplate style={iconStyle} />,
        disabled: this.props.readOnly,
        onclick: () => {
          this.props.createTemplate();
        },
      },
      {
        text: getCurrentlocaleText("bulk.job.download"),
        icon: <BulkDownload style={iconStyle} />,
        disabled: this.props.readOnly,
        onclick: () => {
          this.props.download(true);
        },
      },
      {
        text: getCurrentlocaleText("bulk.job.upload"),
        icon: <BulkUpload style={iconStyle} />,
        disabled: this.props.readOnly,
        onclick: () => {
          this.props.showUploadModal();
        },
      },
      {
        text: getCurrentlocaleText("bulk.job.view.history"),
        icon: <BulkViewHistory style={iconStyle} />,
        onclick: () => {
          this.props.showTableModal();
        },
      },
    ];

    const actionButton = [
      {
        text: getCurrentlocaleText("bulk.job.export"),
        icon: <BulkExport style={iconStyle} />,
        onclick: () => {
          this.props.download();
        },
      },
    ];

    return (
      <div className="bulk-config-header">
        <Row>
          <Col style={{ paddingLeft: "20px" }} span={10}>
            <div className="bulk-config-button">
              <h4>{getCurrentlocaleText("bulk.config")} </h4>
              <Switch
                size="small"
                checked={this.state.checked}
                onChange={this.onchangeswitch}
              />
            </div>
          </Col>
          <Col span={14}>
            <Row className="bulk-config-header-action">
              {checked
                ? bulkActionButton.map((button, index) => (
                    <React.Fragment key={button.text}>
                      <Col key={button.text}>
                        <h2>
                          <Button
                            type="text"
                            size="small"
                            disabled={button.disabled}
                            className="bulk-menu-button-style"
                            onClick={button.onclick}
                            key={button.text}
                          >
                            {button.icon}
                            {button.text}
                          </Button>
                        </h2>
                      </Col>
                      {index === 0 && <div className="bulk-col-button"></div>}
                    </React.Fragment>
                  ))
                : actionButton.map(button => (
                    <Col span={5} key={button.text}>
                      <h2>
                        <Button
                          type="text"
                          size="small"
                          key={button.text}
                          className="bulk-menu-button-style"
                          onClick={button.onclick}
                        >
                          {button.icon}
                          {button.text}
                        </Button>
                      </h2>
                    </Col>
                  ))}
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}
export default BulkConfigHeader;
