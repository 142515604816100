import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Skeleton } from "antd";
import { inject, observer } from "mobx-react";
import Icons from "../UI-Components/Icons";
const moment = require("moment");
import InodeController from "../../controller/InodeController";
import OrgController from "../../controller/OrgController";

@inject("InodeViewModel", "OrgViewModel")
@observer
export class CTDDevicesAtRisk extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = this.props.InodeViewModel;
    this.orgModel = this.props.OrgViewModel;
    this.orgId = this.props.orgId;
    this.nodeId = this.props.nodeId;
    this.refreshData = "";
  }

  componentDidMount() {
    clearInterval(this.refreshData);
    this.getData();
    this.refreshData = setInterval(() => {
      this.getData();
    }, 5 * 60 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.refreshData);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.startDate &&
      this.props.endDate &&
      (this.props.startDate !== prevProps.startDate ||
        this.props.endDate !== prevProps.endDate)
    ) {
      this.getData();
    }
  }

  getData = () => {
    let params = {};
    if (this.props.startDate && this.props.endDate) {
      params = {
        start_date: this.props.startDate.toISOString(),
        end_date: this.props.endDate.toISOString(),
        size: 5,
      };
    }

    this.props.requestFrom == "inodeModel"
      ? this.getTopAffectedDevices(params)
      : this.getTopAffectedNodes(params);
  };

  getTopAffectedDevices = params => {
    this.inodeModel.threatsAffectedLoading = true;
    InodeController.getTopAffectedDevices(this.nodeId, params)
      .then(resp => {
        if (resp.results) {
          this.inodeModel.setThreatsAffected(resp.results);
        } else {
          this.inodeModel.setThreatsAffected([]);
        }
        this.inodeModel.threatsAffectedLoading = false;
      })
      .catch(err => {
        this.inodeModel.threatsAffectedLoading = false;
      });
  };

  getTopAffectedNodes = params => {
    this.orgModel.threatsAffectedLoading = true;
    OrgController.getTopAffectedNodes(this.orgId, params)
      .then(resp => {
        if (resp.results) {
          this.orgModel.setThreatsAffected(resp.results);
        } else {
          this.orgModel.setThreatsAffected([]);
        }
        this.orgModel.threatsAffectedLoading = false;
      })
      .catch(err => {
        this.orgModel.threatsAffectedLoading = false;
      });
  };

  formatData(wholeObj) {
    let model = this.nodeId ? "inodeModel" : "orgModel";

    return wholeObj.map((obj, index) => (
      <Row key={index} className="justify-space-between pb-5">
        <Col span={6}>
          {this.nodeId
            ? obj.device_ip
            : (() => {
                const nodeName = obj.node && obj.node.name && obj.node.name;

                if (nodeName.toLowerCase() === "others") {
                  return <div>{nodeName}</div>;
                } else {
                  return (
                    <Link
                      to={`inodes/${obj.node._id}`}
                      style={{ textDecoration: "underline" }}
                    >
                      {nodeName}
                    </Link>
                  );
                }
              })()}
        </Col>
        <Col span={6}>
          {obj.threats_count && obj.threats_count.high
            ? obj.threats_count.high
            : 0}
        </Col>
        <Col span={6}>
          {obj.threats_count && obj.threats_count.medium
            ? obj.threats_count.medium
            : 0}
        </Col>
        <Col span={6}>
          {obj.threats_count && obj.threats_count.low
            ? obj.threats_count.low
            : 0}
        </Col>
      </Row>
    ));
  }

  render() {
    let model = this.nodeId ? "inodeModel" : "orgModel";

    return (
      <Card style={{ height: "235px", overflowY: "auto" }}>
        <div className="pb-15 active">
          {this.nodeId ? "Devices" : "iNodes"} Most at risk
        </div>
        <Row style={{ fontWeight: 500 }}>
          <Col span={6}>{this.nodeId ? "Device" : "iNode"} Name / IP</Col>
          <Col span={6}>High Severity</Col>
          <Col span={6}>Medium Severity</Col>
          <Col span={6}>Low Severity</Col>
        </Row>
        {this[model].threatsAffectedLoading && (
          <Skeleton active block size="small" className="py-15" />
        )}
        {!this[model].threatsAffectedLoading &&
          this[model].threatsAffected &&
          this[model].threatsAffected.length == 0 && (
            <div className="all-center p-15">No Threats found</div>
          )}
        {!this[model].threatsAffectedLoading &&
          this[model].threatsAffected &&
          this[model].threatsAffected.length != 0 &&
          this.formatData(this[model].threatsAffected)}
      </Card>
    );
  }
}
