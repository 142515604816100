import React, { Component } from "react";
import { Row, Col, Button, Modal, Input, Select } from "antd";
import { Form } from "@ant-design/compatible";
import { LoadingOutlined } from "@ant-design/icons";
import { observer, inject } from "mobx-react";
import _ from "lodash";
import propTypes from "prop-types";

/*------------------N/W CIDR ----------------------*/
import Icons from "../UI-Components/Icons";

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

@inject("ModelStore", "UiStore", "AuthStore")
@observer
class ModalForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showFormModal: false,
      errorMsg: null,
    };
  }

  showModal = () => {
    this.setState({ showFormModal: true, errorMsg: null });
    this.props.UiStore.showFormModal(this.props.resource);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    /* required to stop rendering of modal on window resize */
    nextProps.afterClose && this.resetState();
  }

  closeModal = e => {
    // close Modal and reset states
    this.props.UiStore.closeFormModal(this.props.resource);
    //this.resetState();
    this.props.closeModal ? this.props.closeModal(e) : null;
  };

  resetState = () => {
    this.setState({ showFormModal: false, errorMsg: null });
  };

  validateSubmit = () => {
    /*Validate form values before service creation*/
    this.refForm.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.onSubmit(values);
      } else {
        this.props.onError && this.props.onError(err);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const ActionForm = this.props.actionForm;
    /*Default footer when cutom footer is not required*/
    const defaultFooter = [
      <Button
        key="cancel"
        onClick={this.closeModal}
        disabled={this.props.confirmLoading ? this.props.confirmLoading : false}
      >
        Cancel
      </Button>,
      <Button
        type="primary"
        key="next"
        onClick={this.validateSubmit.bind(this)}
        disabled={this.props.confirmLoading ? this.props.confirmLoading : false}
      >
        {this.props.confirmLoading && (
          <LoadingOutlined
            spin={this.props.confirmLoading ? this.props.confirmLoading : false}
          />
        )}{" "}
        {this.props.okText ? this.props.okText : "OK"}
      </Button>,
    ];
    return (
      <div style={{ display: "inline-block" }}>
        <Button
          type={this.props.type}
          onClick={this.showModal}
          disabled={this.props.disabled}
          title={this.props.title}
        >
          {this.props.buttonName ? (
            this.props.buttonName
          ) : (
            <Icons type="ai" name="AiOutlinePlus" />
          )}
        </Button>

        {//this.props.UiStore[this.props.resource] &&
        //this.props.UiStore[this.props.resource].showFormModal &&
        this.state.showFormModal && (
          <Modal
            visible={this.props.UiStore[this.props.resource].showFormModal}
            title={
              this.props.modalTitle ? this.props.modalTitle : this.props.title
            }
            onOk={this.validateSubmit}
            onCancel={this.closeModal}
            okText={this.props.okText}
            width={this.props.modalWidth}
            footer={this.props.footer ? this.props.footer : defaultFooter}
            style={this.props.modalStyle}
            maskClosable={
              this.props.maskClosable ? this.props.maskClosable : false
            }
            confirmLoading={
              this.props.confirmLoading ? this.props.confirmLoading : false
            }
            closable={
              this.props.confirmLoading ? !this.props.confirmLoading : true
            }
          >
            <div>
              <Row gutter={24} type="flex">
                <Col span={this.props.help ? 14 : 24}>
                  <ActionForm
                    onSubmit={this.validateSubmit}
                    actionType={this.props.actionType}
                    tabKey={this.props.tabKey}
                    error={this.props.error}
                    extra={this.props.extra ? this.props.extra : ""}
                    ref={c => {
                      this.refForm = c;
                    }}
                    disableName={this.props.disableName}
                    // {...this.props}
                  />
                </Col>

                {this.props.help ? (
                  <Col span={10}>
                    <h2 className="help-title">
                      {this.props.UiStore.helpData.title
                        ? this.props.UiStore.getHelpcontent.title
                        : null}
                    </h2>
                    {this.props.UiStore.getHelpcontent.data &&
                      this.props.UiStore.getHelpcontent.data.map((item, i) => {
                        return (
                          <div key={i + "help-div"}>
                            <h3
                              className="help-subtitle"
                              key={i + "help-subtitle"}
                            >
                              {item.subtitle}
                            </h3>
                            <p
                              className="help-content"
                              key={i + "help-content"}
                            >
                              {item.content}
                            </p>
                            <br />
                          </div>
                        );
                      })}
                  </Col>
                ) : null}
              </Row>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

ModalForm.propTypes = {
  onSubmit: propTypes.func.isRequired,
  actionForm: propTypes.func.isRequired, // instance of antd form
  resource: propTypes.string.isRequired,
  buttonName: propTypes.object,
  title: propTypes.string,
  actionType: propTypes.string,
};

const ModalForms = Form.create()(ModalForm);
export default ModalForms;
