import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Table, Button, Modal } from "antd";
import propTypes from "prop-types";
import _ from "lodash";
import { getCurrentlocaleText } from "../../Core/Utils";

@inject("ModelStore", "UiStore")
@observer
class TableLayoutV1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      expandRowProps: [],
      isTableModalToggle: false,
      isModalLoading: false,
    };
    this.columns = this.props.columns || [];
    this.dataSource = this.props.dataSource || [];
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props &&
      prevProps &&
      this.props.isTableModalToggle != prevProps.isTableModalToggle
    ) {
      this.setState({ isTableModalToggle: false });
    }
  }

  showModal = () => {
    this.setState({ isTableModalToggle: true });
  };

  handleCancel = () => {
    this.setState({ isTableModalToggle: false });
  };

  UNSAFE_componentWillReceiveProps(newprops) {
    // Unselect all rows if parent component has changed selected rows to empty
    if (
      this.props.selctedRows &&
      newprops.selctedRows &&
      this.props.selctedRows != newprops.selctedRows &&
      newprops.selctedRows.length == 0
    ) {
      this.setState({ selectedRowKeys: [] });
    }
  }

  // To set the state of the table - rows checked/unchecked
  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys });
  };

  onSelect = (record, selected, selectedRows) => {
    this.props.selectedRows
      ? this.props.selectedRows(record, selected, selectedRows)
      : "";
  };

  onSelectAll = (selected, selectedRows, changeRows) => {
    this.props.selectAll
      ? this.props.selectAll(selected, selectedRows, changeRows)
      : "";
  };

  rowClick = (record, index, event) => {
    this.props.rowClick ? this.props.rowClick(record, index, event) : "";
  };

  render() {
    const { selectedRowKeys } = this.state;
    let { isTableModalToggle, isModalLoading } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      onSelect: this.onSelect,
      onSelectAll: this.onSelectAll,
      getCheckboxProps: record => ({
        disabled: record.disabled, // Column configuration not to be checked
      }),
      type: this.props.selectionType ? this.props.selectionType : "checkbox",
    };

    return (
      <div>
        {this.dataSource ? (
          <Table
            className={this.props.className}
            pagination={
              this.props.pagination
                ? {
                    ...this.props.pagination,
                    showSizeChanger: this.props.showSizeChanger
                      ? this.props.showSizeChanger
                      : false,
                    showQuickJumper: true,
                  }
                : false
            }
            rowKey={record => record.id}
            columns={this.props.columns}
            dataSource={this.props.dataSource}
            rowSelection={this.props.hideRowSelection ? null : rowSelection}
            onRow={(record, index) => {
              return {
                onClick: event => {
                  this.rowClick(record, index, event);
                  if (this.props.getModalContent) {
                    this.props.getModalContent(record);
                    this.showModal();
                  }
                },
              };
            }}
            onChange={this.props.onChange}
            size={this.props.tableSize ? this.props.tableSize : "default"}
            style={this.props.style ? this.props.style : {}}
            scroll={this.props.scroll}
          />
        ) : (
          "No dataSource"
        )}
        {/* Modal */}
        {isTableModalToggle && (
          <Modal
            title={this.props.modalTitle}
            visible={true}
            closable={true}
            maskClosable={false}
            footer={
              !this.props.disableFooter
                ? [
                    <div key={""}>
                      <Button
                        key="handleCancel"
                        type="default"
                        onClick={this.handleCancel}
                        className="border-radius-4"
                      >
                        {getCurrentlocaleText("cancel.button.text")}
                      </Button>
                      <Button
                        key="handleOk"
                        type="primary"
                        onClick={this.props.handleModalOk}
                        className="border-radius-4"
                        disabled={isModalLoading}
                        loading={isModalLoading}
                      >
                        {getCurrentlocaleText("ok.button.text")}
                      </Button>
                    </div>,
                  ]
                : null
            }
            disableFooter={true}
            width={600}
            onCancel={this.handleCancel}
          >
            {this.props.modalContent}
          </Modal>
        )}
      </div>
    );
  }
}

TableLayoutV1.propTypes = {
  columns: propTypes.array.isRequired,
  dataSource: propTypes.array.isRequired,
  pagination: propTypes.any,
  selectedRows: propTypes.func,
  selectAll: propTypes.func,
  onChange: propTypes.func,
  itemPerPage: propTypes.any,
  paginationSize: propTypes.any,
  tableSize: propTypes.any,
  hideExpandedRow: propTypes.any,
  hideRowSelection: propTypes.any,
  scroll: propTypes.any,
  hidePagination: propTypes.any,
  showHeader: propTypes.any,
  footer: propTypes.func,
};

export default TableLayoutV1;
