import React, { Component } from "react";
import { Button } from "antd";
import { Form } from "@ant-design/compatible";
import { observer, inject } from "mobx-react";
import { focusFirstInput, getCurrentlocaleText } from "Core/Utils";
import ReCAPTCHA from "react-google-recaptcha";

const FormItem = Form.Item;

@inject("UiStore")
@observer
class VerifyEmail extends Component {
  constructor(props) {
    super(props);
    // ENV_CONFIG is generate form the node server dynamically
    this.EnvConfig = ENV_CONFIG;
    this.recaptchaRef = null;
  }

  componentDidMount() {
    /* Focus First Input */
    focusFirstInput();
    this.props.UiStore.setDocumentTitle(
      getCurrentlocaleText("user.verify_email_form.document.title"),
    );
  }

  VerifyEmail = e => {
    e.preventDefault();
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      /* To avoid double click on submit */
      this.props.form.validateFields((err, loginData) => {
        if (!err) {
          this.props.VerifyEmail(loginData, false, this.recaptchaRef);
        }
      });
    }, 500);
  };

  handleCaptchaChange = value => {
    this.props.form.setFields({
      captcha: {
        value: value,
      },
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div style={{ margin: 15 }}>
        <Form onSubmit={this.VerifyEmail} noValidate className="login-form">
          <FormItem>
            {" "}
            {getFieldDecorator("captcha", {
              rules: [
                {
                  required: true,
                  message: getCurrentlocaleText("captcha.error.message"),
                },
              ],
            })(
              <ReCAPTCHA
                tabindex={0}
                ref={c => (this.recaptchaRef = c)}
                sitekey={
                  this.EnvConfig && this.EnvConfig.RECAPTCHA_CLIENT_SITE_KEY
                }
                onChange={this.handleCaptchaChange}
              />,
            )}
          </FormItem>
          <FormItem>
            <Button type="primary" htmlType="submit" className="login-btn">
              {getCurrentlocaleText("user_profiles.2fa.setup.verify.text")}
            </Button>
          </FormItem>
        </Form>
      </div>
    );
  }
}

const VerifyEmailForm = Form.create()(VerifyEmail);
export default VerifyEmailForm;
