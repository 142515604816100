import React, { Component } from "react";
import { Layout } from "antd";
import Routes from "../../routes";

const { Content } = Layout;

class IoMain extends Component {
  render() {
    return (
      <Content style={{ margin: "0 16px", fontSize: 12 }}>
        {/*--------------------------------*/}
        <Routes />
        {/*--------------------------------*/}
        {/* <ul className="footer-view">
          <li>
            <a href="https://view.com/" target="blank">
              View.com
            </a>
          </li>
          <li>
            <a href="https://view.com/terms" target="blank">
              Terms
            </a>
          </li>
          <li>
            <a href="https://view.com/contact" target="blank">
              Help
            </a>
          </li>
          <li>US/Canada: +1.855.478.8468</li>
          <li>International: +1.408.263.9200</li>
        </ul> */}
      </Content>
    );
  }
}

export default IoMain;
