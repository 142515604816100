import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Tag, Cascader, Icon } from "antd";
import { Form } from "@ant-design/compatible";
import Input from "components/UI-Components/InputBox";
import {
  getCurrentlocaleText,
  getNodeVersion,
  isCidr,
  isCidrRange,
  StaticRoutesBaseVersion,
} from "Core/Utils";
import Icons from "components/UI-Components/Icons";
import RouteHelp from "components/Forms/Components/Network/RouteHelp";
import * as ip from "ip";

const FormItem = Form.Item;
const nodeBaseVersion = 1269.0;

@inject("UiStore", "InodeViewModel")
@observer
class DefaultRouteConfig extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = this.props.InodeViewModel;
    this.tanCIDR = this.props.tanCIDR;
    this.cidrRangeInfo = this.props.cidrRangeInfo;
    this.state = {
      selectedVnode: [],
      availableNetwork: [],
      routes: [],
      options: [],
      tanCIDR: null,
      cidrRangeInfo: null,
    };
  }

  isSameNw(arr1, arr2) {
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
      return false;
    }

    if (arr1.length !== arr2.length) {
      return false;
    }

    // Sort both arrays based on the id property
    arr1.sort((a, b) => a.network_id - b.network_id);
    arr2.sort((a, b) => a.network_id - b.network_id);

    // Compare the values of each object's id property
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i].network_id !== arr2[i].network_id) {
        return false;
      }
    }
    // All values are the same
    return true;
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (
      props.availableNetwork &&
      !this.isSameNw(props.availableNetwork, this.state.availableNetwork)
    ) {
      this.setState({ availableNetwork: props.availableNetwork });
      this.renderOptions(props.availableNetwork, props.selectedVnode);
      let routes = this.state.routes;
      if (routes && routes.length > 0) {
        routes.forEach((route, i) => {
          if (!route.isIpAddress) {
            routes[i].via = this.getValue(
              route.network_id,
              props.availableNetwork,
            );
            routes[i].labels = this.getLabels(
              route.network_id,
              props.availableNetwork,
            );
          }
        });
        this.setState({ routes: routes });
        this.pushData(routes);
      }
    }
    if (
      props.selectedVnode &&
      !this.isSameNw(props.selectedVnode, this.state.selectedVnode)
    ) {
      this.setState({ selectedVnode: props.selectedVnode });
      this.renderOptions(props.availableNetwork, props.selectedVnode);
      // Handling remote networks value dynamically
      let routes = this.state.routes;
      if (routes && routes.length > 0) {
        // To form display format while refersh
        routes.forEach((route, i) => {
          if (!route.isIpAddress) {
            routes[i].via = this.getValue(
              route.network_id,
              props.availableNetwork,
            );
            routes[i].labels = this.getLabels(
              route.network_id,
              props.availableNetwork,
            );
          }
          if (
            route.network_id &&
            route.network_id !== props.wanNetworkId &&
            props.selectedVnode
          ) {
            let nw = props.selectedVnode.find(item => {
              return route.network_id === item.network_id;
            });
            if (!nw) {
              routes[i].via = [null];
              routes[i].network_id = null;
              routes[i].isIpAddress = false;
              routes[i].disabled = false;
              let obj = {},
                field = "via_default";
              obj[field] = { value: null };
              this.props.form.setFields(obj);
            }
          }
        });
        this.setState({ routes: routes });
        this.pushData(routes);
      }
    }
    if (this.cidrRangeInfo !== props.cidrRangeInfo) {
      this.cidrRangeInfo = props.cidrRangeInfo;
    }
    if (this.tanCIDR !== props.tanCIDR) {
      this.tanCIDR = props.tanCIDR;
      let routes = this.state.routes;
      if (routes && routes.length > 0) {
        routes.forEach(route => {
          if (route && route.destination === "0.0.0.0/0" && route.isIpAddress) {
            let formItem = "via_default";
            this.isCidrAndIpValid(
              props.tanCIDR,
              route.via && route.via[0],
              formItem,
            );
          }
        });
      }
    }
  }

  UNSAFE_componentWillMount() {
    // Setting initial value
    this.renderOptions(this.state.availableNetwork, this.state.selectedVnode);
    if (this.props.formData) {
      if (this.props.formData.routes && this.props.formData.routes.length > 0) {
        let routes = [];
        this.props.formData.routes.forEach(route => {
          if (route.dest === "0.0.0.0/0") {
            routes.push({
              destination: route.dest,
              via: [route.via],
              network_id: ip.isV4Format(route.via) ? null : route.via,
              labels: [],
              isIpAddress: route.via && ip.isV4Format(route.via) ? true : false,
              disabled: false,
            });
          }
        });
        if (routes && routes.length > 0) {
          routes.forEach((route, i) => {
            if (!route.isIpAddress) {
              routes[i].via = this.getValue(
                route.network_id,
                this.state.availableNetwork,
              );
              routes[i].labels = this.getLabels(
                route.network_id,
                this.state.availableNetwork,
              );
            }
          });
          this.setState({ routes: routes });
          this.pushData(routes);
        } else {
          this.addRoute();
        }
      } else {
        this.addRoute();
      }
    }
    this.setState({
      cidrRangeInfo: this.props.cidrRangeInfo,
      tanCIDR: this.props.tanCIDR,
    });
  }

  getLabels = (networkId, availableNetwork) => {
    let value = [];
    if (networkId && availableNetwork) {
      let network = availableNetwork.find(nw => {
        return nw.id === networkId;
      });
      if (network) {
        if (network.is_wan_network) {
          value.push(getCurrentlocaleText("wan_network.text"));
        } else {
          value.push(getCurrentlocaleText("network.remote_network.text"));
          value.push(network.organization.name);
          value.push(network.node.name);
          value.push(network.name);
        }
        return value;
      }
    }
    value.push(getCurrentlocaleText("route_config_via_default.text"));
    return value;
  };

  getValue = (networkId, availableNetwork) => {
    let value = [];
    if (networkId && availableNetwork) {
      let network = availableNetwork.find(nw => {
        return nw.id === networkId;
      });
      if (network) {
        if (network.is_wan_network) {
          value.push(network.id);
        } else {
          value.push(getCurrentlocaleText("network.remote_network.text"));
          value.push(network.organization.id);
          value.push(network.node.id);
          value.push(network.id);
        }
        return value;
      }
    }
    value.push(networkId);
    return value;
  };

  renderOptions = (availableNetwork, remoteNetworks) => {
    let options = [],
      option = {};
    // Default option
    option = {
      label: getCurrentlocaleText("route_config_via_default.text"),
      value: null,
    };
    options.push(option);
    // Ip Address Option
    if (
      parseFloat(getNodeVersion(this.inodeModel.inode)) >=
        StaticRoutesBaseVersion ||
      (this.inodeModel.inode && this.inodeModel.inode.node_state === "NEW") ||
      this.props.cluster_id
    ) {
      option = {
        label: getCurrentlocaleText("route_config_via_ipaddress.text"),
        value: "IPADDRESS",
      };
      options.push(option);
    }
    // Wan network and remote network options
    if (availableNetwork) {
      // Wan network options
      // LAT-6031 WAN Network on New Edge iNode is not populated for setting default destination
      if (
        parseFloat(getNodeVersion(this.inodeModel.inode)) >= nodeBaseVersion ||
        (this.inodeModel.inode && this.inodeModel.inode.node_state === "NEW") ||
        this.props.cluster_id
      ) {
        let wanNetwork = availableNetwork.find(nw => {
          if (this.props.cluster_id) {
            return (
              nw.is_wan_network &&
              nw.cluster &&
              nw.cluster.id === this.props.cluster_id
            );
          } else {
            return (
              nw.is_wan_network && nw.node && nw.node.id === this.props.nodeId
            );
          }
        });
        if (wanNetwork) {
          option = {
            label: getCurrentlocaleText("wan_network.text"),
            value: wanNetwork.id,
          };
          options.push(option);
        }
      }
      // Remote networks
      if (remoteNetworks) {
        option = {
          label: getCurrentlocaleText("network.remote_network.text"),
          value: getCurrentlocaleText("network.remote_network.text"),
          children: [],
        };
        let currentOrgId, currentNodeId;
        remoteNetworks.forEach(rnw => {
          let network = availableNetwork.find(nw => {
            return nw.id === rnw.network_id;
          });
          if (network) {
            // Group based on org
            if (currentOrgId !== network.organization.id) {
              option.children.push({
                label: network.organization.name,
                value: network.organization.id,
                children: [],
              });
            }
            // Find org index
            let org_index = option.children.findIndex(org => {
              return org.value === network.organization.id;
            });
            if (org_index !== -1) {
              // Group based on node
              if (
                currentNodeId !==
                (network.node && network.node.id
                  ? network.node.id
                  : network.cluster && network.cluster.id)
              ) {
                option.children[org_index].children.push({
                  label:
                    network.node && network.node.name
                      ? network.node.name
                      : network.cluster && network.cluster.name,
                  value:
                    network.node && network.node.id
                      ? network.node.id
                      : network.cluster && network.cluster.id,
                  children: [],
                });
              }
              // Find node index
              let node_index = option.children[org_index].children.findIndex(
                node => {
                  return (
                    node.value ===
                    (network.node && network.node.id
                      ? network.node.id
                      : network.cluster && network.cluster.id)
                  );
                },
              );
              if (node_index !== -1) {
                // Add network
                option.children[org_index].children[node_index].children.push({
                  label:
                    network && network.name
                      ? network.name
                      : network.cluster && network.cluster.name,
                  value:
                    network && network.id
                      ? network.id
                      : network.cluster && network.cluster.id,
                });
              }
            }
            // Check the remote network already added or not
            let in_dx = options.findIndex(option => {
              return (
                option.value ===
                getCurrentlocaleText("network.remote_network.text")
              );
            });
            if (in_dx === -1) {
              options.push(option);
            } else {
              options[in_dx] = option;
            }
            currentOrgId = network.organization.id;
            currentNodeId =
              network.node && network.node.id
                ? network.node.id
                : network.cluster && network.cluster.id;
          }
        });
      }
    }
    this.setState({ options: options });
  };

  displayRender = (labels, selectedOptions) => {
    if (labels) {
      // For None option
      if (labels.length === 1) {
        return <Tag style={{ border: "none" }}>{labels[0]}</Tag>;
      }
      // For other options
      if (labels.length === 4) {
        return this.getDisplayTag(labels);
      }
    }
  };

  updateHelp = data => {
    this.props.UiStore.SetStoreData("helpData", data);
  };

  handleOnChange = (index, value, selectedOptions) => {
    let routes = this.state.routes;
    // For other options excluding none too
    if (value && value[0] !== "IPADDRESS") {
      routes[index].via = value;
      routes[index].network_id = value[value.length - 1];
      routes[index].labels = this.getLabels(
        routes[index].network_id,
        this.state.availableNetwork,
      );
      routes[index].isIpAddress = false;
      routes[index].disabled = false;
    } else {
      // For IpAddress option only
      routes[index].via = null;
      routes[index].isIpAddress = true;
      routes[index].network_id = null;
      routes[index].labels = [];
      routes[index].disabled = false;
      this.focusInput("default-route-via-" + index);
    }
    this.setState({ routes: routes });
    this.pushData(routes);
  };

  getDisplayTag = labels => {
    let type = "fa",
      className = "valign-text-bottom iot-tag-icon-style";
    if (labels && labels.length > 1) {
      return (
        <Tag key={labels}>
          <Icons type={type} name="FaSitemap" className={className} />{" "}
          {labels[1]}&nbsp; /{" "}
          <Icons type={type} name="FaRegHdd" className={className} />{" "}
          {labels[2]}&nbsp; /{" "}
          <Icons type={type} name="FaConnectdevelop" className={className} />{" "}
          {labels[3]}&nbsp;
        </Tag>
      );
    }
    return <span />;
  };

  addRoute = () => {
    let routes = this.state.routes;
    let route = {
      key: Math.random(),
      destination: "0.0.0.0/0",
      via: [null],
      network_id: null,
      labels: this.getLabels(null, []),
      isIpAddress: false,
      disabled: false,
    };
    routes.push(route);
    this.setState({ routes: routes });
    this.pushData(routes);
  };

  pushData = routes => {
    if (this.props.setRoutes) {
      this.props.setRoutes(routes);
    }
  };

  focusInput = elmId => {
    setTimeout(() => {
      if (elmId) {
        let elm = document.getElementById(elmId);
        if (elm) {
          elm.focus();
        }
      }
    }, 200);
  };

  handleIpAddressOnChange = (index, e) => {
    if (e && e.target) {
      let routes = this.state.routes;
      routes[index].via = [e.target.value];
      this.setState({ routes: routes });
      this.pushData(routes);
    }
  };

  removeViaFromIpAddressMode = index => {
    let routes = this.state.routes;
    routes.splice(index, 1);
    this.setState({ routes: routes });
    this.pushData(routes);
    this.addRoute();
    let obj = {};
    obj["via_default"] = {
      value: this.props.form.getFieldValue(`via_default`),
    };
    obj["via_default"].errors = null;
    this.props.form.setFields(obj);
  };

  checkIpV4 = (rule, value, callback) => {
    let failMessage = null;
    try {
      if (value && ip.isV4Format(value) !== true) {
        failMessage = getCurrentlocaleText(
          "network.form.startip.errormessage1.text",
        );
      } else if (
        value &&
        ip.isV4Format(value) === true &&
        (isCidr(value) === true || !isCidrRange(this.tanCIDR, value))
      ) {
        failMessage =
          this.cidrRangeInfo != null
            ? getCurrentlocaleText("static.route.ipaddress.errormessage.text") +
              " " +
              this.cidrRangeInfo
            : getCurrentlocaleText("static.route.ipaddress.errormessage.text");
      }
    } catch (e) {
      failMessage =
        this.cidrRangeInfo != null
          ? getCurrentlocaleText("static.route.ipaddress.errormessage.text") +
            " " +
            this.cidrRangeInfo
          : getCurrentlocaleText("static.route.ipaddress.errormessage.text");
    }

    if (failMessage) {
      callback(failMessage);
    } else {
      callback();
    }
  };

  isCidrAndIpValid = (cidrValue, ipValue, fieldName) => {
    let isCidrValid =
      cidrValue && isCidr(cidrValue) && isCidrRange(cidrValue, ipValue);
    let obj = {};
    if (ipValue) {
      obj[fieldName] = { value: this.props.form.getFieldValue(`${fieldName}`) };
      if (isCidrValid && ip.isV4Format(ipValue)) {
        this.props.form.setFields(obj);
      } else if (ip.isV4Format(ipValue)) {
        obj[fieldName].errors = [
          new Error(
            this.cidrRangeInfo != null
              ? getCurrentlocaleText(
                  "static.route.ipaddress.errormessage.text",
                ) +
                " " +
                this.cidrRangeInfo
              : getCurrentlocaleText(
                  "static.route.ipaddress.errormessage.text",
                ),
          ),
        ];
        this.props.form.setFields(obj);
      } else {
        obj[fieldName].errors = [
          new Error(
            getCurrentlocaleText("network.form.startip.errormessage1.text"),
          ),
        ];
        this.props.form.setFields(obj);
      }
    }
  };

  render() {
    let { routes, options } = this.state;
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        {!this.props.showCascaderAlone ? (
          <FormItem>
            {
              <>
                {routes && routes[0] && !routes[0].isIpAddress ? (
                  <Cascader
                    popupClassName="route-config-cascade-popup"
                    placeholder={getCurrentlocaleText(
                      "static_route_config.via_placeholder.text",
                    )}
                    notFoundContent={getCurrentlocaleText(
                      "general.notfound.placeholder.text",
                    )}
                    style={{ width: "100%" }}
                    options={options}
                    allowClear={false}
                    displayRender={this.displayRender.bind(this)}
                    expandTrigger={"hover"}
                    onChange={this.handleOnChange.bind(this, 0)}
                    title={
                      routes && routes[0] && routes[0].labels
                        ? routes[0].labels.length === 1
                          ? routes[0].labels[0]
                          : `${routes[0].labels[1]} / ${routes[0].labels[2]} / ${routes[0].labels[3]}`
                        : ""
                    }
                    value={
                      routes && routes[0] && routes[0].via
                        ? routes[0].via
                        : null
                    }
                    onFocus={() => {
                      if (!this.props.viewOnly) {
                        this.updateHelp({
                          title: getCurrentlocaleText(
                            "default_destination_text",
                          ),
                          data: [
                            {
                              subtitle: "",
                              content: (
                                <RouteHelp
                                  selectedVnode={this.state.selectedVnode}
                                  isSpecificIPSupported={
                                    parseFloat(
                                      getNodeVersion(this.inodeModel.inode),
                                    ) >= StaticRoutesBaseVersion ||
                                    (this.inodeModel.inode &&
                                      this.inodeModel.inode.node_state ===
                                        "NEW")
                                  }
                                />
                              ),
                            },
                          ],
                        });
                      }
                    }}
                    disabled={this.props.viewOnly}
                  />
                ) : (
                  getFieldDecorator("via_default", {
                    rules: [
                      {
                        validator:
                          routes && routes[0] && !routes[0].isIpAddress
                            ? null
                            : this.checkIpV4,
                      },
                    ],
                    initialValue:
                      routes && routes[0] && routes[0].isIpAddress
                        ? routes &&
                          routes[0] &&
                          routes[0].via &&
                          routes[0].via[0]
                          ? routes[0].via[0]
                          : null
                        : null,
                  })(
                    <Input
                      id="default-route-via-0"
                      placeholder={getCurrentlocaleText(
                        "static_route_config.via_placeholder.text",
                      )}
                      value={
                        routes && routes[0] && routes[0].via && routes[0].via[0]
                          ? routes[0].via[0]
                          : null
                      }
                      onChange={this.handleIpAddressOnChange.bind(this, 0)}
                      addonAfter={
                        !this.props.viewOnly ? (
                          <span
                            onClick={this.removeViaFromIpAddressMode.bind(
                              this,
                              0,
                            )}
                            title={getCurrentlocaleText(
                              "route_config.remove.ipaddress.text",
                            )}
                          >
                            <Icons type="ai" name="AiOutlineCloseCircle" />
                          </span>
                        ) : null
                      }
                      disabled={this.props.viewOnly}
                      onFocus={this.updateHelp.bind(this, {
                        title: getCurrentlocaleText("route_config.via.text"),
                        data: [
                          {
                            subtitle: "",
                            content: (
                              <RouteHelp
                                selectedVnode={this.state.selectedVnode}
                              />
                            ),
                          },
                        ],
                      })}
                    />,
                  )
                )}
              </>
            }
          </FormItem>
        ) : (
          <span
            title={
              routes && routes[0] && routes[0].labels
                ? routes[0].labels.length === 1
                  ? routes[0].labels[0]
                  : `${routes[0].labels[1]} / ${routes[0].labels[2]} / ${routes[0].labels[3]}`
                : ""
            }
          >
            {this.displayRender(routes && routes[0] && routes[0].labels)}
          </span>
        )}
      </div>
    );
  }
}

const DefaultRoute = Form.create()(DefaultRouteConfig);
export default DefaultRoute;
