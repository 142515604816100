import React, { Component } from "react";
import { Row, Col } from "antd";
import BreadcrumbComponent from "components/UI-Components/BreadcrumbComponent";
import Icons from "components/UI-Components/Icons";
import ApiKeyList from "components/UserApiKeys/ApiKeyList";
import { getCurrentlocaleText } from "Core/Utils";

class ApiKeys extends Component {
  constructor(props) {
    super(props);
    this.state = {
      BreadcrumbList: [
        {
          name: getCurrentlocaleText("user.api_keys.leftmenu.text"),
          link: "keys",
        },
      ],
    };
  }

  render() {
    return (
      <div>
        <Row gutter={24} type="flex" align="middle">
          <Col span={24}>
            <h2 className="page-title">
              {
                <span
                  style={{
                    fontSize: 10,
                    marginRight: 2,
                    display: "block",
                    marginBottom: -13,
                  }}
                >
                  {"API"}
                </span>
              }
              <Icons
                type="md"
                name="MdVpnKey"
                style={{ fontSize: 20, marginRight: 8, marginLeft: -1 }}
                className="valign-middle"
              />
              {"  "}
              {getCurrentlocaleText("user.api_keys.leftmenu.text")}
            </h2>
            <BreadcrumbComponent
              {...this.props}
              BreadcrumbList={this.state.BreadcrumbList}
            />
          </Col>
        </Row>
        <div style={{ minHeight: 360 }}>
          <Row gutter={16} type="flex" align="middle">
            <Col span={24}>
              <ApiKeyList isMe={true} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default ApiKeys;
