import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import {
  Button,
  Modal,
  Row,
  Col,
  Space,
  Input,
  Alert,
  Radio,
  Form,
  Tabs,
} from "antd";
import InodeController from "controller/InodeController";
import ProfileController from "controller/ProfileController";
import ImageButton from "components/UI-Components/ImageButton";
import amazonEC2 from "../../assets/img/aws-logo.png";
import azure from "../../assets/img/azure-logo.png";
import vmware from "../../assets/img/vmware-logo.png";
import OpenStack from "../../assets/img/openstack-logo.svg";
import GCP from "../../assets/img/gcp-logo.png";
import OvaImage from "../../assets/img/ova.png";
import TarImage from "../../assets/img/tar_icon.png";
import DownloadImage from "../../assets/img/download.png";
import Icons from "components/UI-Components/Icons";
import ReleaseController from "controller/ReleaseController";
import { EditOutlined } from "@ant-design/icons";
import SerialNumberHistory from "components/INodes/SerialNumberHistory";
import {
  getCurrentlocaleText,
  isDevelopmentMode,
  humanizeMetric,
} from "../../Core/Utils";

const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const EXTENSION = "ova.bz2";
const GCP_EXTENSION = "tar";

@inject(
  "ModelStore",
  "UiStore",
  "ProfileViewModel",
  "OrgViewModel",
  "InodeViewModel",
  "TimezoneViewModel",
  "AuthStore",
  "ReleaseViewModel",
)
@observer
class RMAReactivationForm extends Component {
  formRef = React.createRef();
  edgeFromRef = React.createRef();
  constructor(props) {
    super(props);
    this.releaseModel = this.props.ReleaseViewModel;
    this.state = {
      hardwareSerialNumber: null,
      visibility: false,
      isEdgeiNode: false,
      isVirtualiNode: false,
      isVirtualEdgeiNode: false,
      showOpenStack: false,
      downloadOptions: {
        ec2: "",
        azure: "",
        vmware: "",
        openstack: "",
        gcp: "",
      },
      confirmText: "",
      defaultActiveKey: "1",
      showHSNInfo: false,
    };
    this.checkNodeByProfile();
  }

  componentDidMount() {
    /* LAT-2770 Provide option to download yaml for openstack and gcp Virtual Edge */
    this.setState({ showOpenStack: isDevelopmentMode() });
  }

  callLatestReleaseList = () => {
    // Call latest release api
    if (!this.releaseModel.latestRelease && !this.state.isEdgeiNode) {
      ReleaseController.getLatestRelease();
    }
  };
  checkNodeByProfile = () => {
    if (
      this.props.node_data &&
      this.props.node_data.profile &&
      this.props.node_data.profile.id
    ) {
      ProfileController.getProfile(this.props.node_data.profile.id).then(
        profile => {
          if (profile && profile.config && profile.config.vpn_server) {
            this.setState({ isVirtualiNode: true });
          } else {
            this.setState({ isVirtualiNode: false });
          }
          if (
            profile &&
            profile.config &&
            !profile.config.vpn_server &&
            profile.config.pki.hsn === "GENERATED"
          ) {
            this.setState({ isVirtualEdgeiNode: true }, () => {
              this.callLatestReleaseList();
            });
          } else {
            this.setState({ isVirtualEdgeiNode: false });
          }
          if (
            profile &&
            profile.config &&
            !profile.config.vpn_server &&
            profile.config.pki.hsn === "PREDEFINED"
          ) {
            this.setState({ isEdgeiNode: true });
          } else {
            this.setState({ isEdgeiNode: false }, () => {
              this.callLatestReleaseList();
            });
          }
        },
      );
    }
  };

  updateHardwareSerialNumber = () => {
    let nodeId = this.props.nodeId;
    let hardwareSerialNumber =
      this.state.hardwareSerialNumber &&
      this.state.hardwareSerialNumber.toUpperCase();
    return new Promise((resolve, reject) => {
      InodeController.updateHardwareSerialNumber(nodeId, hardwareSerialNumber)
        .then(resp => {
          InodeController.getInode(nodeId, true, true)
            .then(result => {
              if (result) {
                resolve(result);
                this.setState({
                  visibility: false,
                  hardwareSerialNumber: null,
                  isVirtualEdgeiNode: false,
                  isVirtualiNode: false,
                  isEdgeiNode: false,
                  confirmText: "",
                });
              }
            })
            .catch(err => {
              reject(err);
              this.setState({
                visibility: false,
                hardwareSerialNumber: null,
                isVirtualEdgeiNode: false,
                isVirtualiNode: false,
                isEdgeiNode: false,
                confirmText: "",
              });
            });
        })
        .catch(error => {
          reject(error);
          this.setState({ visibility: false, hardwareSerialNumber: null });
        });
    });
  };

  updateReactivateRMA = () => {
    if (!this.state.isEdgeiNode) {
      this.formRef.current.validateFields().then(values => {
        this.updateHardwareSerialNumber().then(result => {
          InodeController.downloadInodeCert(
            this.props.nodeId,
            values.cloud_config,
            { name: result.name },
            true,
          ).then(resp => {
            this.setState({
              visibility: false,
              hardwareSerialNumber: null,
              isVirtualEdgeiNode: false,
              isVirtualiNode: false,
              isEdgeiNode: false,
              confirmText: "",
            });
            this.resetVal();
          });
        });
      });
    } else {
      this.edgeFromRef.current.validateFields().then(values => {
        this.updateHardwareSerialNumber();
      });
    }
  };

  cancelRMAReactivation = () => {
    this.setState({ visibility: false, hardwareSerialNumber: null });
    this.resetVal();
  };

  resetVal = () => {
    this.setState({
      hardwareSerialNumber: null,
      downloadOptions: {
        ec2: "",
        azure: "",
        vmware: "",
        openstack: "",
        gcp: "",
      },
      confirmText: "",
      defaultActiveKey: "1",
      showHSNInfo: false,
    });
  };

  showRMAReactivation = () => {
    this.setState({ visibility: true });
  };

  checkforButtonEnabled = () => {
    let { hardwareSerialNumber } = this.state;
    if (
      hardwareSerialNumber ||
      this.state.confirmText === "Regenerate Credentials"
    ) {
      return false;
    } else {
      return true;
    }
  };

  onHSNChange = e => {
    this.setState({ hardwareSerialNumber: e.target.value });
  };

  onConfirmChange = e => {
    this.setState({ confirmText: e.target.value });
  };

  /**
   * Method to download latest released VMDK/OVA file
   */
  downloadFile = url => {
    ReleaseController.downloadFile(url).then(resp => {
      if (resp && resp.download_url) {
        window.location = resp.download_url;
      }
    });
  };

  latestRelease = () => {
    if (
      this.releaseModel.latestRelease &&
      this.releaseModel.latestRelease.files
    ) {
      let files = this.releaseModel.latestRelease.files.filter(file => {
        if (file.name.toLowerCase().endsWith(EXTENSION.toLowerCase())) {
          return file;
        }
      });
      files = files.map((file, indx) => {
        return (
          <div key={file.url}>
            <div>
              <span>
                <div style={{ display: "inline-block" }}>
                  <img
                    src={OvaImage}
                    height="25"
                    width="25"
                    alt="File"
                    style={{ verticalAlign: "middle" }}
                    title={getCurrentlocaleText("ovafile.text")}
                  />
                </div>
              </span>
              <div style={{ display: "inline-block" }}>
                <span>
                  <span>&nbsp;&nbsp;Download OVA file</span>
                  <span>
                    {"  "}({humanizeMetric(file.size, true)})
                  </span>
                </span>
                <span
                  className="releases_download_icon"
                  onClick={this.downloadFile.bind(this, file.url)}
                  title="Download"
                >
                  <div style={{ display: "inline-block" }}>
                    <img
                      src={DownloadImage}
                      height="18"
                      width="18"
                      style={{ verticalAlign: "left", marginLeft: "318px" }}
                      title={"Download"}
                    />
                  </div>
                </span>
              </div>
            </div>
          </div>
        );
      });
      this.setState({ vmdkFiles: files });
    }
  };

  handleDownloadType = (value, e) => {
    if (value === "ec2") {
      this.setState({
        downloadOptions: {
          ec2: "download-active",
          azure: "",
          vmware: "",
          openstack: "",
          gcp: "",
        },
      });
    } else if (value === "azure") {
      this.setState({
        downloadOptions: {
          ec2: "",
          azure: "download-active",
          vmware: "",
          openstack: "",
          gcp: "",
        },
      });
    } else if (value === "vmware") {
      this.setState({
        downloadOptions: {
          ec2: "",
          azure: "",
          vmware: "download-active",
          openstack: "",
          gcp: "",
        },
      });
      this.latestRelease();
    } else if (value === "openstack") {
      this.setState({
        downloadOptions: {
          ec2: "",
          azure: "",
          vmware: "",
          openstack: "download-active",
          gcp: "",
        },
      });
    } else if (value === "gcp") {
      this.setState({
        downloadOptions: {
          ec2: "",
          azure: "",
          vmware: "",
          gcp: "download-active",
          openstack: "",
        },
      });
      this.gcpLatestRelease();
    }
    this.formRef.current.setFieldsValue({
      cloud_config: {
        cloud_provider: value,
        download_type: value === "vmware" ? "ct" : "yaml",
      },
    });
  };

  gcpLatestRelease = () => {
    if (
      this.releaseModel.latestRelease &&
      this.releaseModel.latestRelease.files
    ) {
      let files = this.releaseModel.latestRelease.files.filter(file => {
        if (
          file &&
          file.name &&
          file.name.toLowerCase().endsWith(GCP_EXTENSION.toLowerCase())
        ) {
          return file;
        }
      });
      files = files.map((file, indx) => {
        return (
          <div key={file.url}>
            <div>
              <span>
                <div style={{ display: "inline-block" }}>
                  <img
                    src={TarImage}
                    height="21"
                    width="21"
                    alt="File"
                    style={{ verticalAlign: "middle" }}
                    title={getCurrentlocaleText("tarfile.text")}
                  />
                </div>
              </span>
              <div style={{ display: "inline-block" }}>
                <span>
                  <span>&nbsp;&nbsp;Download TAR file</span>
                  <span>
                    {"  "}({humanizeMetric(file.size, true)})
                  </span>
                </span>
                <span
                  className="releases_download_icon"
                  onClick={this.downloadFile.bind(this, file.url)}
                  title="Download"
                >
                  <img
                    src={DownloadImage}
                    height="18"
                    width="18"
                    style={{ verticalAlign: "middle", marginLeft: "323px" }}
                    title={"Download"}
                  />
                </span>
              </div>
            </div>
          </div>
        );
      });
      this.setState({ vmdkFiles: files });
    }
  };

  render() {
    const {
      visibility,
      isEdgeiNode,
      isVirtualEdgeiNode,
      isVirtualiNode,
    } = this.state;
    const WarningBox = props => (
      <p className="cert-warning-msg">{props.children}</p>
    );
    return (
      <div>
        {visibility && (
          <Modal
            wrapClassName="action-modal-wrap"
            visible={visibility}
            onCancel={this.cancelRMAReactivation}
            footer={[
              <Button key="cancel" onClick={this.cancelRMAReactivation}>
                {this.state.defaultActiveKey == "2" ? "Close" : "Cancel"}
              </Button>,
              this.state.defaultActiveKey == "1" ? (
                <Button
                  key="ok"
                  type={!this.checkforButtonEnabled() ? "primary" : "default"}
                  onClick={this.updateReactivateRMA}
                  disabled={this.checkforButtonEnabled()}
                >
                  {this.state.isEdgeiNode
                    ? getCurrentlocaleText("inode.rma.button.info.text")
                    : getCurrentlocaleText("inode.rma.vedge.button.info.text")}
                </Button>
              ) : null,
            ]}
            width={
              this.state.defaultActiveKey == "2" &&
              this.props.node_data &&
              this.props.node_data.node_replace_history &&
              this.props.node_data.node_replace_history.length > 0
                ? 900
                : 650
            }
          >
            <div>
              <Tabs
                activeKey={this.state.defaultActiveKey}
                defaultActiveKey={this.state.defaultActiveKey}
                onChange={key => {
                  this.setState({ defaultActiveKey: key });
                }}
              >
                <Tabs.TabPane
                  tab={
                    isEdgeiNode
                      ? getCurrentlocaleText("inode.rma.modal.title.text")
                      : "Regenerate Credentials?"
                  }
                  key="1"
                >
                  <Space direction="vertical">
                    {isEdgeiNode && (
                      <div>
                        <Row>
                          <Col>
                            <strong>
                              {getCurrentlocaleText(
                                "inode.rma.edge.sequence.title.text",
                              )}
                            </strong>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {getCurrentlocaleText(
                              "inode.rma.edge.sequence.step1.text",
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {getCurrentlocaleText(
                              "inode.rma.edge.sequence.step2.text",
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {getCurrentlocaleText(
                              "inode.rma.edge.sequence.step3.text",
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <span
                              onClick={() => {
                                this.setState({
                                  showHSNInfo: !this.state.showHSNInfo,
                                });
                              }}
                            >
                              <a>
                                {"Can't find your Hardware Serial Number?"}{" "}
                              </a>
                            </span>
                            <br />
                            {this.state.showHSNInfo && (
                              <span>
                                {
                                  ' You can find your Hardware Serial number on rear side of the Edge iNode. It is a 8 character alphanumeric string. For example: "AB23-C678". So, please check for the serial number on rear side of the Edge iNode.'
                                }
                              </span>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Form
                            ref={this.edgeFromRef}
                            className="login-form"
                            noValidate
                            layout="vertical"
                          >
                            <FormItem
                              label={getCurrentlocaleText(
                                "inode.rma.edge.newhsn.label.text",
                              )}
                              name="hardware_serial_number"
                              style={{ marginTop: 15 }}
                              rules={[
                                {
                                  required: true,
                                  message: getCurrentlocaleText(
                                    "inode.rma.edge.required.text",
                                  ),
                                },
                                {
                                  pattern: /^[A-Za-z0-9]{4}-[A-Za-z0-9]{4}$/,
                                  message: getCurrentlocaleText(
                                    "inode.rma.edge.hsn.validity.check.text",
                                  ),
                                },
                              ]}
                            >
                              <Input
                                className="capitalize"
                                placeholder="XXXX-XXXX"
                                onChange={this.onHSNChange}
                              />
                            </FormItem>
                          </Form>
                        </Row>
                        <Row>
                          <Col>
                            <strong>Note:</strong>
                            {getCurrentlocaleText(
                              "inode.rma.edge.reboot.warning.text",
                            )}
                          </Col>
                        </Row>
                      </div>
                    )}
                    {!isEdgeiNode && (
                      <div>
                        <Row>
                          <Col>
                            <Alert
                              message={
                                <span>
                                  <p>
                                    {getCurrentlocaleText(
                                      "inode.rma.vedge.warninginfo.text",
                                    )}
                                  </p>
                                  <p>
                                    {getCurrentlocaleText(
                                      "inode.rma.vedge.warning.message2",
                                    )}
                                  </p>
                                </span>
                              }
                              type="warning"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form
                              ref={this.formRef}
                              className="login-form"
                              noValidate
                              layout="vertical"
                            >
                              <FormItem
                                label={getCurrentlocaleText(
                                  "inode.rma.cloudprovider.label.text",
                                )}
                                name="cloud_config"
                                style={{ marginTop: 15 }}
                                rules={[
                                  {
                                    required: true,
                                    message: !this.state.showVirtualEdgeOnly
                                      ? getCurrentlocaleText(
                                          "inode.rma.cloudprovider.error.text",
                                        )
                                      : getCurrentlocaleText(
                                          "inode.rma.cloudprovider.error2.text",
                                        ),
                                  },
                                ]}
                              >
                                <RadioGroup
                                  style={{
                                    margin: "10px 10px",
                                  }}
                                >
                                  {
                                    <ImageButton
                                      className={
                                        this.state.downloadOptions[`ec2`]
                                      }
                                      onClick={this.handleDownloadType}
                                      value="ec2"
                                      src={amazonEC2}
                                      imgStyle={{
                                        height: 50,
                                        width: 100,
                                        paddingTop: 10,
                                      }}
                                    />
                                  }

                                  {
                                    <ImageButton
                                      className={
                                        this.state.downloadOptions[`azure`]
                                      }
                                      onClick={this.handleDownloadType}
                                      value="azure"
                                      src={azure}
                                      imgStyle={{ height: 36, width: 100 }}
                                    />
                                  }

                                  <ImageButton
                                    className={
                                      this.state.downloadOptions[`vmware`]
                                    }
                                    onClick={this.handleDownloadType}
                                    value="vmware"
                                    imgStyle={{ height: 36, width: 100 }}
                                    src={vmware}
                                  />

                                  {this.state.isVirtualEdgeiNode &&
                                    this.state.showOpenStack && (
                                      <ImageButton
                                        className={
                                          this.state.downloadOptions[
                                            `openstack`
                                          ]
                                        }
                                        onClick={this.handleDownloadType}
                                        value="openstack"
                                        src={OpenStack}
                                        imgStyle={{ height: 14, width: 100 }}
                                        isSvg={true}
                                      />
                                    )}
                                  {
                                    <ImageButton
                                      className={
                                        this.state.downloadOptions[`gcp`]
                                      }
                                      onClick={this.handleDownloadType}
                                      value="gcp"
                                      imgStyle={{ height: 20, width: 100 }}
                                      src={GCP}
                                    />
                                  }
                                </RadioGroup>
                              </FormItem>
                              {((this.state.downloadOptions.vmware &&
                                this.state.downloadOptions.vmware.length > 0) ||
                                (this.state.downloadOptions.gcp &&
                                  this.state.downloadOptions.gcp.length > 0)) &&
                                this.releaseModel.latestRelease &&
                                this.state.vmdkFiles &&
                                this.state.vmdkFiles.length > 0 && (
                                  <FormItem>
                                    <div>{this.state.vmdkFiles}</div>
                                  </FormItem>
                                )}
                            </Form>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <WarningBox value={200}>
                              <strong>{"Note: "}</strong>
                              {getCurrentlocaleText(
                                "inode.rma.cloudprovider.download.text2",
                              )}
                              {getCurrentlocaleText(
                                "inode.rma.cloudprovider.download.text3",
                              )}
                            </WarningBox>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <WarningBox value={200}>
                              {getCurrentlocaleText(
                                "inode.rma.unreversible.message",
                              )}
                            </WarningBox>
                            <Input
                              placeholder={getCurrentlocaleText(
                                "inode.rma.confirm.text",
                              )}
                              onChange={this.onConfirmChange}
                            ></Input>
                          </Col>
                          <Col></Col>
                        </Row>
                      </div>
                    )}
                  </Space>
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={getCurrentlocaleText("inode.hsn.history.title")}
                  key="2"
                >
                  {this.props.node_data &&
                  this.props.node_data.node_replace_history &&
                  this.props.node_data.node_replace_history.length > 0 ? (
                    <SerialNumberHistory
                      hsn_history={this.props.node_data.node_replace_history.sort(
                        (a, b) => {
                          return (
                            new Date(b.updated_at).getTime() -
                            new Date(a.updated_at).getTime()
                          );
                        },
                      )}
                    />
                  ) : (
                    "No History to display"
                  )}
                </Tabs.TabPane>
              </Tabs>
            </div>
          </Modal>
        )}
        <EditOutlined
          onClick={this.showRMAReactivation}
          size={"small"}
          className={
            this.props.className + " iot-pointer-cursor iot-primary-color-style"
          }
          title={"Update Serial Number"}
        />
      </div>
    );
  }
}

export default RMAReactivationForm;
