import React, { Component } from "react";
import InputBox from "components/UI-Components/InputBox";
import { Input } from "antd";
import propTypes from "prop-types";
import Icons from "components/UI-Components/Icons";

class PasswordInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "password",
      isCapsLockOn: false,
    };
  }

  componentDidMount() {
    this.setState({ type: this.props.type });
    // For all major browsers, except IE 8 and earlier
    if (document.addEventListener) {
      document.addEventListener("keydown", this.onKeyDown);
    } else {
      // For IE 8 and earlier versions
      document.attachEvent("onkeydown", this.onKeyDown);
    }
  }

  componentWillUnmount() {
    // For all major browsers, except IE 8 and earlier
    if (document.removeEventListener) {
      document.removeEventListener("keydown", this.onKeyDown);
    } else {
      // For IE 8 and earlier versions
      document.detachEvent("onkeydown", this.onKeyDown);
    }
  }

  togglePassword = () => {
    this.setState({
      type: this.state.type === `password` ? `text` : `password`,
    });
  };

  onKeyDown = e => {
    let kc = e.keyCode || e.which;
    if (kc === 20) {
      this.setState({ isCapsLockOn: !e.getModifierState(e.key) });
    }
  };

  onKeyPress = e => {
    let kc = e.keyCode ? e.keyCode : e.which;
    let sk = e.shiftKey ? e.shiftKey : kc === 16 ? true : false;
    if ((kc >= 65 && kc <= 90 && !sk) || (kc >= 97 && kc <= 122 && sk)) {
      // Caps lock is on
      this.setState({ isCapsLockOn: true });
    } else {
      // Caps lock is off.
      if (kc >= 97 && kc <= 122 && !sk) {
        this.setState({ isCapsLockOn: false });
      }
    }
  };

  render() {
    let className = this.props.className
      ? `password-input ${this.props.className}`
      : `password-input`;
    // To allow autocomplete use antd Input component. Otherwise use our InputBox component
    let InputElement = this.props.allowAutoComplete ? Input : InputBox;
    return (
      <InputElement
        id={this.props.id ? this.props.id : ""}
        className={className}
        placeholder={this.props.placeholder}
        addonBefore={
          this.props.addonBefore ? this.props.addonBefore : this.props.prefix
        }
        suffix={
          <span>
            {this.state.isCapsLockOn && (
              <span style={{ marginRight: 5 }} title="Caps Lock On">
                <Icons type="fa" name="FaExclamationTriangle" />
              </span>
            )}
            <span
              onClick={this.togglePassword}
              style={{ cursor: "pointer" }}
              title={this.state.type === `password` ? `Show` : "Hide"}
            >
              <Icons
                type="fa"
                name={this.state.type === `password` ? `FaEyeSlash` : `FaEye`}
              />
            </span>
          </span>
        }
        type={this.state.type}
        onChange={this.props.onChange}
        style={this.props.style ? this.props.style : {}}
        onKeyPress={this.onKeyPress}
      />
    );
  }
}

PasswordInput.propTypes = {
  placeholder: propTypes.string.isRequired,
  type: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
};

export default PasswordInput;
