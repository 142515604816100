import {
  getNetworkStatus,
  getNetworkStatusColorCode,
  getNetworkStatusMessage,
} from "../../../../Core/Utils";

class Status {
  /**
   * Method to return network status details
   */
  getNetworkStatus = (status = {}) => {
    return {
      state: getNetworkStatus(status.state),
      colorCode: getNetworkStatusColorCode(status.state),
      message: getNetworkStatusMessage(status),
    };
  };
}

export default new Status();
