import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Link } from "react-router-dom";
import { Table, Menu, Popover, Tag, Row, Col, Icon, Checkbox } from "antd";
import {
  getCurrentlocaleText,
  customSort,
  highlightSearchText,
} from "Core/Utils";
import ActionBtn from "../../Core/API/ActionBtn";
import ActionRoute from "../../Core/API/ActionRoute";
import SearchInput from "../UI-Components/SearchInput";
import DropdownActionBtn from "../UI-Components/DropdownActionBtn";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import ServiceListenerController from "../../controller/ServiceListenerController";
import Icons from "../UI-Components/Icons";
import TableLayoutV1 from "../layout/TableLayoutV1";

@inject("AuthStore", "OrgViewModel")
@observer
class ServiceListenerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedRowKeys: [],
      dataSource: [],
      searchText: "",
      selectedService: [],
      pageSize: 25,
      pageNumber: 0,
      total_count: 0,
      loading: true,
      lastPageCalled: 0,
      showChildListeners: false,
      currentPage: 1,
      modalContent: "",
      modalTitle: "",
      isTableModalToggle: false,
      sorterInfo: { sorter: {}, sort: {} },
    };
    this.defaultInterval = 30000;
    this.syncInterval = null;
  }

  componentDidMount() {
    this.getListeners();
    this.getListenerInterval();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.orgId != this.props.orgId) {
      this.getListeners();
    }
  }

  getListenerInterval() {
    this.syncInterval = setInterval(() => {
      this.getListeners();
    }, this.defaultInterval);
  }

  changeListenerState = val => {
    this.syncInterval = null;
    this.setState(
      {
        showChildListeners: val.target.checked,
        pageNumber: 0,
        loading: true,
      },
      () => {
        this.getListeners();
      },
    );
  };

  getListeners() {
    let params = {
      page: this.state.pageNumber,
      size: this.state.pageSize,
      search: this.state.searchText,
      org_id: this.props.orgId,
    };
    if (!this.state.showChildListeners) {
      params.own = true;
    }

    if (
      this.state.sorterInfo &&
      this.state.sorterInfo.sort.by &&
      this.state.sorterInfo.sort.order
    ) {
      params.sort =
        this.state.sorterInfo.sort.by + ":" + this.state.sorterInfo.sort.order;
    }

    ServiceListenerController.getListeners(params)
      .then(resp => {
        if (resp) {
          let mergedData = resp.results;
          this.setState({
            dataSource: mergedData,
            total_count: resp.total_count,
            loading: false,
          });
        }
      })
      .catch(error => {
        this.setState({
          loading: false,
        });
      });
  }

  onTableChange = (pagination, filter, sorter) => {
    let sort = {};
    if (sorter.columnKey && sorter.order) {
      if (sorter.order === "ascend") {
        sort.order = "ASC";
      } else {
        sort.order = "DESC";
      }
      sort.by = sorter.columnKey;
    }
    this.setState(
      {
        pageNumber: pagination.current - 1,
        currentPage: pagination.current,
        loading: true,
        sorterInfo: { sorter: sorter, sort: sort },
      },
      () => {
        this.getListeners();
      },
    );
  };

  showExtraDetails = record => {
    // Defining service port table columns
    const container_columns = [
      {
        title: getCurrentlocaleText(
          "service_listener.ports.protocol.help.title",
        ),
        dataIndex: "protocol",
        key: "protocol",
      },
      {
        title: getCurrentlocaleText(
          "service_listener.ports.node_port.help.title",
        ),
        dataIndex: "node_port",
        key: "node_port",
      },
      {
        title: getCurrentlocaleText("service_listener.ports.port.help.title"),
        dataIndex: "port",
        key: "port",
      },
      {
        title: getCurrentlocaleText("service_listener.ports.state.title"),
        key: "state",
        render: (text, record) => {
          const content = (
            <div>
              <p>{record.state ? record.state : "UNKNOWN"}</p>
            </div>
          );

          return (
            <Popover
              content={content}
              title={getCurrentlocaleText("service_listener.ports.state.title")}
            >
              <Tag
                className={
                  record.state
                    ? "tag-" + record.state.toLowerCase()
                    : "tag-unknown"
                }
              >
                {" "}
                {record.state ? record.state : "UNKNOWN"}{" "}
              </Tag>
            </Popover>
          );
        },
      },
    ];

    return (
      <div>
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          {record.service_ports && record.service_ports.length > 0 && (
            <Table
              size={"small"}
              bordered={false}
              columns={container_columns}
              dataSource={record.service_ports}
              rowKey={record => record.id}
              pagination={false}
            />
          )}
        </div>
      </div>
    );
  };

  onExpand = (expanded, record) => {
    let keys = this.state.expandedRowKeys;
    let index = keys.findIndex(val => {
      return val === record.id;
    });
    if (expanded) {
      if (index === -1) {
        keys.push(record.id);
      }
      this.setExpandedRowKeys(keys);
    } else {
      if (index !== -1) {
        keys.splice(index, 1);
      }
      this.setExpandedRowKeys(keys);
    }
  };

  expandRow = () => {
    let keys = this.state.dataSource.map(item => {
      return item.id;
    });
    this.setExpandedRowKeys(
      this.state.expandedRowKeys.length !== keys.length ? keys : [],
    );
  };

  setExpandedRowKeys = keys => {
    this.setState({ expandedRowKeys: keys });
  };

  // To set the state of the table - rows checked/unchecked
  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys });
  };

  onSelect = (record, selected, selectedRows) => {
    this.selectService(record, selected, selectedRows);
  };

  onSelectAll = (selected, selectedRows, changeRows) => {
    this.selectServiceAll(selected, selectedRows, changeRows);
  };

  selectService = (record, selected, selectedRows) => {
    this.setState({
      selectedService: this.extractActiveServiceRows(selectedRows),
    });
  };

  selectServiceAll = (selected, selectedRows, changeRows) => {
    this.setState({
      selectedService: this.extractActiveServiceRows(selectedRows),
    });
  };

  extractActiveServiceRows = selectedRows => {
    return selectedRows.filter(row => row !== undefined);
    return selectedRows;
    // Return only active service rows
    return selectedRows.filter(row => {
      return row.disabled === false;
    });
  };

  deleteListenerAfterCall = () => {
    let page = this.state.currentPage ? this.state.currentPage : 0;
    if (this.state.dataSource.length == 1) {
      page--;
    }
    this.setState(
      {
        selectedService: [],
        pageNumber: page - 1,
        loading: true,
      },
      () => {
        this.getListeners();
      },
    );
  };

  clearSearchFilter = () => {
    this.setState(
      {
        searchText: "",
        loading: true,
        currentPage: 1,
      },
      () => {
        this.getListeners();
      },
    );
  };

  iNodeListFilter = e => {
    let searchText = e.target.value;
    this.setState(
      {
        searchText: searchText,
        pageNumber: 0,
      },
      () => {
        this.getListeners();
      },
    );
  };

  getModalContentJSX = record => {
    this.setState({
      modalTitle: record && record.name ? record.name : "",
      modalContent: this.showExtraDetails(record),
    });
    return this.showExtraDetails(record);
  };

  handleModalOk(e) {
    this.setState({
      isTableModalToggle: !this.state.isTableModalToggle,
    });
  }

  render() {
    const { isTableModalToggle } = this.state;
    this.columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: "25%",
        sorter: (a, b) => {
          return customSort(a.name, b.name);
        },
        render: (text, record, index) => {
          return this.state.searchText.trim().length > 0
            ? highlightSearchText(text, this.state.searchText)
            : text;
        },
      },
      {
        title: (
          <span
            title={getCurrentlocaleText("general.table.inode_name.header.text")}
          >
            {getCurrentlocaleText("general.table.inode_name.header.text")}
          </span>
        ),
        dataIndex: "nodename",
        key: "nodename",
        width: "20%",
        ellipsis: true,
        render: (text, record, index) => {
          let navLink;
          if (record.node && record.node.id) {
            // For Node
            navLink =
              "/orgs/" + record.organization.id + "/inodes/" + record.node.id;
          }
          let nodeName =
            record.node && record.node.name ? record.node.name : "-";
          return (
            <div title={nodeName} className="col-wrap">
              {navLink ? <Link to={navLink}>{nodeName}</Link> : nodeName}
            </div>
          );
        },
      },
      {
        title: "Service Selector",
        dataIndex: "service_selector",
        key: "service_selector",
        width: "20%",
        ellipsis: true,
        render: (text, record, index) => {
          return (
            <span className="col-wrap">{record.service_selector.name}</span>
          );
        },
      },
      {
        title: "Ports",
        dataIndex: "ports",
        key: "ports",
        width: "20%",
        ellipsis: true,
        render: (text, record, index) => {
          let returnObj;
          switch (true) {
            case record.service_ports.length > 1:
              var popoverContent = [];
              record.service_ports.map((obj, i) => {
                popoverContent.push(
                  <div key={i}>
                    {obj.node_port + ":" + obj.port + "/" + obj.protocol}
                  </div>,
                );
              });
              returnObj = <span>{popoverContent}</span>;
              break;
            case record.service_ports.length == 1:
              returnObj = (
                <div>
                  {" "}
                  {record.service_ports[0].node_port +
                    ":" +
                    record.service_ports[0].port +
                    "/" +
                    record.service_ports[0].protocol}{" "}
                </div>
              );
              break;
            default:
              returnObj = <div>{"-"}</div>;
              break;
          }
          return returnObj;
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "all_actions",
        width: "10%",
        render: (text, record, index) => {
          const view = (
            <ActionRoute
              iconButton
              actionIcon="AiOutlineEye"
              title={getCurrentlocaleText(
                "service_listener.form.useraction_view.label.text",
              )}
              actionLabel={getCurrentlocaleText(
                "service_listener.form.useraction_view.label.text",
              )}
              route={
                "/orgs/" +
                record.organization.id +
                "/inodes/" +
                record.node.id +
                "/listeners/view/" +
                record.id
              }
            />
          );
          const edit = (
            <ActionRoute
              iconButton
              actionIcon="AiOutlineEdit"
              title={getCurrentlocaleText(
                "service_listener.form.useraction_edit.label.text",
              )}
              actionLabel={getCurrentlocaleText(
                "service_listener.form.useraction_edit.label.text",
              )}
              route={
                "/orgs/" +
                record.organization.id +
                "/inodes/" +
                record.node.id +
                "/listeners/modify/" +
                record.id
              }
            />
          );
          const view_status = (
            <ActionRoute
              iconType="fa"
              iconName="FaShieldAlt"
              actionLabel={getCurrentlocaleText(
                "service_listener.view_status.label.text",
              )}
              isCustomIcon
              title={getCurrentlocaleText(
                "service_listener.view_status.label.text",
              )}
              route={
                "/orgs/" +
                record.organization.id +
                "/inodes/" +
                record.node.id +
                "/listeners/" +
                record.id +
                "/status"
              }
            />
          );

          const menu = (
            <Menu>
              <Menu.Item key="view_listener">{view}</Menu.Item>
              <Menu.Item key="edit_listener">{edit}</Menu.Item>
              <Menu.Item key="view_status">{view_status}</Menu.Item>
            </Menu>
          );

          return (
            <div
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <DropdownActionBtn menu={menu} />
            </div>
          );
        },
      },
    ];

    // Org column
    let orgColumn = {
      title: (
        <span title={getCurrentlocaleText("myprofile.organization.text")}>
          {getCurrentlocaleText("myprofile.organization.text")}
        </span>
      ),
      dataIndex: "",
      key: "organization",
      width: "15%",
      ellipsis: true,
      render: (text, record, index) => {
        return (
          <a
            href={`/orgs/${record.organization.id}`}
            title={record.organization.name}
          >
            <span className="col-wrap">{record.organization.name}</span>
          </a>
        );
      },
    };

    // Add Org column if
    if (this.state.showChildListeners) {
      this.columns.splice(1, 0, orgColumn);
    }

    let dataContenet = (
      <div>
        <Row>
          <Col
            style={{
              display: "flex",
              marginBottom: "20px",
              width: "100%",
            }}
          >
            <div>
              <span className="headertext">
                {getCurrentlocaleText("services.my")}
              </span>
            </div>
            <div
              style={{
                marginLeft: "auto",
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <Checkbox
                checked={this.state.showChildListeners}
                onChange={this.changeListenerState}
                disabled={
                  this.props.OrgViewModel.org &&
                  this.props.OrgViewModel.org.org_count > 0
                    ? false
                    : true
                }
              >
                {getCurrentlocaleText(
                  "service_listeners.table_view.checkbox.label",
                )}
              </Checkbox>
              <div>
                <SearchInput
                  placeholder={getCurrentlocaleText(
                    "inodes.service_listener.filter_listener_by_name.text",
                  )}
                  onChange={this.iNodeListFilter}
                  value={this.state.searchText}
                  clearSearch={this.clearSearchFilter}
                />
              </div>
              {this.props.AuthStore.IsPermitted("SERVICE:CREATE") && (
                <div style={{ paddingLeft: "15px" }}>
                  <ActionBtn
                    title={
                      <span>
                        <Icons type="ai" name="AiOutlineDelete" /> Delete
                        Listeners
                      </span>
                    }
                    resource="listener"
                    bottomConfirmationDisable={true}
                    action="delete"
                    disabled={_.isEmpty(this.state.selectedService)}
                    iconButton={true}
                    icon="AiOutlineDelete"
                    formData={this.state.selectedService}
                    afterCall={this.deleteListenerAfterCall}
                    controller={ServiceListenerController}
                    cancelText={getCurrentlocaleText(
                      "delete.confirmation.cancel.text",
                    )}
                  >
                    <Icons type="ai" name="AiOutlineDelete" />{" "}
                    {getCurrentlocaleText("delete.confirmation.ok.text")}
                  </ActionBtn>
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          {this.state.dataSource.length > 0 ? (
            <TableLayoutV1
              columns={this.columns}
              dataSource={this.state.dataSource}
              pagination={{
                pageSize: this.state.pageSize,
                total: this.state.total_count,
                current: this.state.currentPage,
              }}
              onExpand={this.onExpand}
              expandedRowKeys={this.state.expandedRowKeys}
              expandedRowRender={record => {
                return this.showExtraDetails(record);
              }}
              selectedRows={this.selectService}
              selectAll={this.selectServiceAll}
              onChange={this.onTableChange}
              className={
                this.props.className
                  ? this.props.className + " iot-table"
                  : "clusterNodeList"
              }
              getModalContent={record => {
                return this.getModalContentJSX(record);
              }}
              modalTitle={this.state.modalTitle}
              modalContent={this.state.modalContent}
              isTableModalToggle={isTableModalToggle}
              disableFooter={true}
            />
          ) : (
            !this.state.loading && (
              <div className={"col-center"}>
                {getCurrentlocaleText("no.listeners.to.display")}
              </div>
            )
          )}
        </Row>
      </div>
    );

    return (
      <LoadingComponent loading={this.state.loading}>
        {dataContenet}
      </LoadingComponent>
    );
  }

  componentWillUnmount = () => {
    clearInterval(this.syncInterval);
  };
}

export default ServiceListenerList;
