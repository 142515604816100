import React, { Component } from "react";
import { Tag, Popover } from "antd";
import propTypes from "prop-types";
import { getCurrentlocaleText } from "../../../../Core/Utils";
import Status from "../../../../components/Forms/Components/Network/Status";

class NetworkStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: null,
    };
  }

  componentDidMount() {
    this.setState({ status: this.getNetworkStatus(this.props.status) });
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ status: this.getNetworkStatus(props.status) });
  }

  getNetworkStatus(status) {
    let networkStatus = Status.getNetworkStatus(status);
    let message = (
      <div style={{ maxWidth: 350 }}>
        <div>{networkStatus.message}</div>
      </div>
    );
    return (
      <Popover content={message}>
        <Tag className={"tag-" + networkStatus.colorCode}>
          {networkStatus.state}
        </Tag>
      </Popover>
    );
  }

  render() {
    return <div>{this.state.status}</div>;
  }
}

NetworkStatus.propTypes = {
  status: propTypes.object.isRequired,
};

export default NetworkStatus;
