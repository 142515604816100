import React, { Component } from "react";
import {
  Spin,
  Table,
  Tag,
  Popover,
  Empty,
  Row,
  Col,
  Menu,
  Button,
  Modal,
} from "antd";
import { getCurrentlocaleText, EpochToUserTime, isArray } from "Core/Utils";
import { inject, observer } from "mobx-react";
import ServiceController from "controller/ServiceController";
import NetworkController from "controller/NetworkController";
import DropdownActionBtn from "components/UI-Components/DropdownActionBtn";
import ActionRoute from "Core/API/ActionRoute";
import Icons from "components/UI-Components/Icons";

@inject("AuthStore", "InodeViewModel")
@observer
class ClusterInheritanceServiceList extends Component {
  constructor(props) {
    super(props);
    this.nodeModel = this.props.InodeViewModel;
    this.state = {
      loading: false,
      services: [],
      expandedRowKeys: [],
      skysparkWarningPopover: this.props.skysparkWarningPopover
        ? this.props.skysparkWarningPopover
        : {},
      showRestartmod: false,
      currentService: {},
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({ loading: true });
    // Fetch cluster network
    if (this.props.clusterNetworkId) {
      NetworkController.getNetwork(this.props.clusterNetworkId);
    }
    this.fetchInheritanceServices(this.props.clusterId, this.props.serviceName);
  }

  fetchInheritanceServices = (clusterId, serviceName) => {
    // Fetch single inheritance service
    ServiceController.getSingleInheritanceService(clusterId, serviceName)
      .then(response => {
        if (response && response.total_count) {
          let allParams = [],
            totalCount = response.total_count / 100;
          if (totalCount > 0) {
            for (let page = 0; page < totalCount; page++) {
              let params = { page: page, size: 100 };
              allParams.push(params);
            }
            // Fetch all inheritance service
            ServiceController.getAllInheritanceServices(
              clusterId,
              serviceName,
              allParams,
            )
              .then(response => {
                this.setState({ loading: false, services: response });
              })
              .catch(error => {
                this.setState({ loading: false });
              });
          } else {
            this.setState({ loading: false });
          }
        } else {
          this.setState({ loading: false });
        }
      })
      .catch(error => {
        this.setState({ loading: false });
      });
  };

  onExpand = (expanded, record) => {
    let keys = this.state.expandedRowKeys;
    let index = keys.findIndex(val => {
      return val === record.id;
    });
    if (expanded) {
      if (index === -1) {
        keys.push(record.id);
      }
      this.setExpandedRowKeys(keys);
    } else {
      if (index !== -1) {
        keys.splice(index, 1);
      }
      this.setExpandedRowKeys(keys);
    }
  };

  setExpandedRowKeys = keys => {
    this.setState({ expandedRowKeys: keys });
  };

  containerExtraData = record => {
    // Container table extra data
    if (record) {
      return (
        <div>
          <Row>
            <Col span={18}>
              <div>
                <strong> Image : </strong>
                {record.value ? record.value.image : "-"}
              </div>

              <div>
                <strong> Message : </strong>
                {record.container ? record.container.message : "-"}
              </div>
            </Col>
          </Row>
        </div>
      );
    } else {
      return <span>{"-"}</span>;
    }
  };

  serviceExtraData = record => {
    // Defining conatiner table columns
    let containerColumns = [
      {
        title: getCurrentlocaleText("container.name"),
        dataIndex: "container_name",
        key: "container_name",
        ellipsis: true,
      },
      {
        title: getCurrentlocaleText("container.status"),
        key: "container_status",
        ellipsis: true,
        render: (text, record) => {
          let content = (
            <div>
              <p>
                <b>Created </b>: {record.container.started_at}
              </p>
              {record.container.finished_at && (
                <p>
                  <b>Exited</b>: {record.container.finished_at}
                </p>
              )}
            </div>
          );
          return (
            <Popover
              content={content}
              title={getCurrentlocaleText("container.status")}
            >
              <Tag className={"tag-" + record.container.status.toLowerCase()}>
                {" "}
                {record.container.status}{" "}
              </Tag>
            </Popover>
          );
        },
      },
      {
        title: getCurrentlocaleText("restart.count"),
        dataIndex: "restart_count",
        key: "container_restart_count",
        ellipsis: true,
      },
    ];
    // If no status available, dont show container table
    let containerData =
      record.status &&
      record.status.container_status &&
      record.status.container_status.length > 0
        ? record.status.container_status.map((val, index) => {
            // Generating container table data for each row
            return {
              id: index,
              container_name: val.name,
              restart_count: val.restart_count ? val.restart_count : 0,
              container_status: val.state.state ? val.state.state : "-",
              container: {
                status: val.state.state ? val.state.state : "-",
                message:
                  val.state.message && val.state.message.trim().length > 0
                    ? val.state.message
                    : "-",
                started_at: val.state.started_at
                  ? EpochToUserTime(val.state.started_at)
                  : "-",
                finished_at: val.state.finished_at
                  ? EpochToUserTime(val.state.finished_at)
                  : "",
              },
              value: val,
            };
          })
        : [];
    let hostname =
      record.status && record.status.hostname
        ? record.status.hostname
        : record.spec && record.spec.hostname
        ? record.spec.hostname
        : null;
    return (
      <div>
        {((record.spec && record.spec.hostname) ||
          (record.status && record.status.hostname)) && (
          <Row style={{ marginTop: 5, marginBottom: 5 }}>
            <Col span={8}>
              <strong>{getCurrentlocaleText("hostname.text")}</strong>
              <strong>{" : "}</strong>
            </Col>
            <Col span={12}>
              <span> {hostname ? hostname : "-"}</span>
            </Col>
          </Row>
        )}
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          {containerData && containerData.length > 0 && (
            <Table
              size={"small"}
              bordered={false}
              columns={containerColumns}
              dataSource={containerData}
              rowKey={record => record.id}
              pagination={false}
              expandedRowRender={record => (
                <div style={{ margin: 0 }}>
                  {this.containerExtraData(record)}
                </div>
              )}
              scroll={{ y: 120 }}
            />
          )}
        </div>
      </div>
    );
  };

  handleVisibleChange = (record, popoverState) => {
    let imgVersion;
    let serType =
      record.serviceData.spec.labels &&
      record.serviceData.spec.labels["io_iotium_template"]
        ? record.serviceData.spec.labels["io_iotium_template"]
        : record.serviceData.spec.labels &&
          record.serviceData.spec.labels["io-iotium-template"]
        ? record.serviceData.spec.labels["io-iotium-template"]
        : "custom";

    if (serType.toLowerCase() === "skyspark") {
      imgVersion = record.serviceData.spec.services[0].image.version;
    }

    imgVersion !== "3.0.12"
      ? this.editOrViewService(record)
      : this.setState({
          skysparkWarningPopover: {
            [record.id]: popoverState,
          },
        });
  };

  hideSkysparkWarningPopover = record => {
    this.setState({
      skysparkWarningPopover: {
        [record.id]: false,
      },
    });
  };

  editOrViewService = (record, isView = false) => {
    let service_type =
      record.spec.labels && record.spec.labels["io-iotium-template"]
        ? record.spec.labels["io-iotium-template"]
        : record.spec.labels && record.spec.labels["io_iotium_template"]
        ? record.spec.labels["io_iotium_template"]
        : "custom";

    let serviceRoute = isView === true ? "serviceview" : "services";
    let url = null;
    if (record.cluster && record.cluster.id) {
      url = `/orgs/${record.organization.id}/clusters/${
        record.cluster.id
      }/${serviceRoute}/${service_type.toLowerCase()}/${record.id}`;
    } else {
      url = `/orgs/${record.organization.id}/inodes/${
        record.node.id
      }/${serviceRoute}/${service_type.toLowerCase()}/${record.id}`;
    }
    /*  goto edit or view service page */
    if (url) window.location = url;
  };

  showServiceRestartConfirm = record => {
    this.setState({
      showRestartmod: true,
      loading: false,
      currentService: record,
    });
  };

  closeServiceRestartModal = () => {
    this.setState({ showRestartmod: false });
  };

  handleRestart = () => {
    let record = this.state.currentService ? this.state.currentService : {};
    //API REQ BEGINS HERE
    ServiceController.restart(record && record.id)
      .then(response => {
        this.setState({
          message: response.message,
          showRestartmod: false,
          loading: false,
          currentService: {},
        });
        this.fetchInheritanceServices(
          this.props.clusterId,
          this.props.serviceName,
        );
      })
      .catch(error => {
        this.setState({
          showRestartmod: false,
          loading: false,
          currentService: {},
        });
      });
  };

  render() {
    let { services } = this.state;
    let columns = [
      {
        title: getCurrentlocaleText("node.list.table.node_name.label.text"),
        key: "serviceName",
        ellipsis: true,
        render: (text, record) => {
          let serType =
            record.spec.labels && record.spec.labels.io_iotium_template
              ? record.spec.labels["io_iotium_template"]
              : record.spec.labels && record.spec.labels["io-iotium-template"]
              ? record.spec.labels["io-iotium-template"]
              : "custom";
          return (
            <span>
              <a
                href={`/orgs/${record.organization.id}/inodes/${
                  record.node.id
                }/serviceview/${serType.toLowerCase()}/${record.id}`}
              >
                {record.name}
              </a>
              {record.deleted_at && (
                <span className="iotium-danger">
                  {" "}
                  {getCurrentlocaleText("deleting.text")}
                </span>
              )}
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText("general.component.inode.text"),
        key: "nodeName",
        ellipsis: true,
        render: (text, record) => {
          return (
            <span>
              <a
                href={`/orgs/${record.organization.id}/inodes/${record.node.id}`}
              >
                {record.node.name}
              </a>
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText("general.component.networks.text"),
        key: "networkName",
        ellipsis: true,
        render: (text, record) => {
          let podNetworks =
            record.spec && record.spec.networks ? record.spec.networks : [];
          let iNode =
            this.nodeModel.inodes && this.nodeModel.inodes.length > 0
              ? this.nodeModel.inodes.find(node => {
                  return record.node.id === node.id;
                })
              : undefined;
          let podNetworksWithName = [];
          if (iNode) {
            podNetworks.forEach(nw => {
              let network =
                iNode.networks &&
                iNode.networks.find(value => {
                  return value.id === nw.network_id;
                });
              if (network) {
                podNetworksWithName.push(network);
              }
            });
          }
          if (isArray(podNetworksWithName) && podNetworksWithName.length == 1) {
            return (
              <a
                href={`/orgs/${record.organization.id}/inodes/${record.node.id}/view/${podNetworksWithName[0].id}`}
              >
                {podNetworksWithName[0].name}
              </a>
            );
          } else if (
            isArray(podNetworksWithName) &&
            podNetworksWithName.length > 1
          ) {
            let popoverContentData = [];
            podNetworksWithName.map((network_id, i, arr) => {
              popoverContentData.push(
                <div>
                  {podNetworksWithName[i].id && (
                    <a
                      href={`/orgs/${record.organization.id}/inodes/${record.node.id}/view/${podNetworksWithName[i].id}`}
                    >
                      {podNetworksWithName[i].name}
                    </a>
                  )}
                  {!podNetworksWithName[i].name && <span>-</span>}
                  {arr.length - 1 !== i && <span>,&nbsp;</span>}
                </div>,
              );
            });
            return (
              <Popover content={popoverContentData}>
                <a
                  href={`/orgs/${record.organization.id}/inodes/${record.node.id}/view/${podNetworksWithName[0].id}`}
                >
                  {podNetworksWithName[0].name}
                </a>
                ...
              </Popover>
            );
          } else {
            return "-";
          }
        },
      },
      {
        title: getCurrentlocaleText("node.list.table.node_status.label.text"),
        key: "status",
        ellipsis: true,
        render: (text, record) => {
          let status =
            record.status && record.status.status ? record.status.status : null;
          let message =
            record.status &&
            record.status.message &&
            record.status.message.trim().length > 0
              ? record.status.message
              : null;
          let startTime =
            record.status && record.status.start_time
              ? EpochToUserTime(record.status.start_time)
              : null;
          // Popover Content
          let unknownStatusContent = (
            <div>
              {message
                ? message
                : getCurrentlocaleText("service.status.unavailable.text")}
            </div>
          );
          let nonUnknownStatusContent = (
            <div>
              <p>
                <strong>
                  {getCurrentlocaleText("node.list.table.popover.created")}
                  {" : "}
                </strong>
                {startTime ? startTime : "-"}
              </p>
              <p>
                <strong>
                  {getCurrentlocaleText("node.list.table.popover.message")}
                  {" : "}
                </strong>{" "}
                {message ? message : "-"}
              </p>
            </div>
          );
          return (
            <div>
              {status ? (
                <Popover
                  content={
                    status.toLowerCase() === "unknown"
                      ? unknownStatusContent
                      : nonUnknownStatusContent
                  }
                  title={
                    status.toLowerCase() === "unknown"
                      ? null
                      : getCurrentlocaleText("service.status")
                  }
                >
                  <Tag className={"tag-" + status.toLowerCase()}>
                    {" "}
                    {status}{" "}
                  </Tag>
                </Popover>
              ) : (
                <span>{"-"}</span>
              )}
            </div>
          );
        },
      },
      {
        title: getCurrentlocaleText("inode.interface.ipv4.label.text"),
        key: "ipAddress",
        ellipsis: true,
        render: (text, record) => {
          let ipAddresses = [];
          // Get ip address from status

          // Get ip address from spec
          if (ipAddresses.length === 0) {
            if (
              record.spec &&
              record.spec.networks &&
              record.spec.networks.length > 0
            ) {
              record.spec.networks.forEach(network => {
                let exist = false;
                if (
                  record.status &&
                  record.status.service_ip &&
                  record.status.service_ip.length > 0
                ) {
                  record.status.service_ip
                    .filter(
                      serviceIp =>
                        serviceIp &&
                        serviceIp.network_id === network.network_id &&
                        serviceIp.ip_address,
                    )
                    .forEach(serviceIp => {
                      exist = true;
                      ipAddresses.push(serviceIp.ip_address);
                    });
                }
                if (exist === false) {
                  ipAddresses.push(network.ip_address);
                }
              });
            }
          }

          if (isArray(ipAddresses) && ipAddresses.length == 1) {
            return <div>{ipAddresses[0]}</div>;
          } else if (isArray(ipAddresses) && ipAddresses.length > 1) {
            let popoverContentData = [];
            ipAddresses.map((ip, i, arr) => {
              popoverContentData.push(
                <div key={i}>
                  {ip ? ip : "-"}
                  {arr.length - 1 !== i && <span>,&nbsp;</span>}
                </div>,
              );
            });

            return (
              <Popover content={popoverContentData}>
                <div>{ipAddresses[0]}...</div>
              </Popover>
            );
          } else {
            return "-";
          }
        },
      },
      {
        title: getCurrentlocaleText("running.containers"),
        key: "runningContainers",
        ellipsis: true,
        render: (text, record) => {
          let runningContainers =
            record.status && record.status.running ? record.status.running : 0;
          return (
            <span style={{ paddingLeft: "20px" }}>{runningContainers}</span>
          );
        },
      },
    ];

    // adding new col for action to which we need to show or not
    if (this.props.showAction) {
      let action_obj = {
        title: "",
        key: "runningContainers",
        render: (text, record) => {
          const view = (
            <span>
              <Button
                title={getCurrentlocaleText("view.service.text")}
                onClick={this.editOrViewService.bind(null, record, true)}
                style={{ cursor: "pointer", padding: "0 10px" }}
              >
                {" "}
                <Icons type="ai" name="AiOutlineEye" style={{ fontSize: 14 }} />
              </Button>
            </span>
          );
          const edit = (
            <span>
              <Popover
                overlayClassName="skysparkWarnPopover"
                overlayStyle={{ width: "45%" }}
                visible={
                  this.state.skysparkWarningPopover[record.id]
                    ? this.state.skysparkWarningPopover[record.id]
                    : false
                }
                onVisibleChange={this.handleVisibleChange.bind(null, record)}
                placement="topRight"
                content={
                  <div className="popoverContentContainer">
                    <p className="popoverContent">
                      {getCurrentlocaleText("skyspark.warning.popover.content")}
                    </p>
                    <Row>
                      <Button
                        className="popoverProceedBtn"
                        type={record.disabled ? "" : "primary"}
                        onClick={this.editOrViewService.bind(null, record)}
                      >
                        Proceed with Edit Service
                      </Button>
                    </Row>
                    <Row>
                      <Button
                        className="popoverCancelBtn"
                        onClick={this.hideSkysparkWarningPopover.bind(
                          null,
                          record,
                        )}
                      >
                        Cancel
                      </Button>
                    </Row>
                  </div>
                }
                title={
                  <div className="popovertitleContainer">
                    <Icons
                      type="ai"
                      name="AiOutlineExclamationCircle"
                      className="warningIcon"
                    />
                    {getCurrentlocaleText("skyspark.warning.popover.title")}

                    <Button
                      className="popoverTitleCancelBtn"
                      onClick={this.hideSkysparkWarningPopover.bind(
                        null,
                        record,
                      )}
                    >
                      <Icons type="ai" name="AiOutlineCloseCircle" />
                    </Button>
                  </div>
                }
                trigger="click"
              >
                <Button
                  style={{ cursor: "pointer" }}
                  title={getCurrentlocaleText("service.edit.header.text")}
                  disabled={record.disabled ? record.disabled : false}
                >
                  <Icons type="ai" name="AiOutlineEdit" />
                </Button>
              </Popover>
            </span>
          );
          const serviceLog = (
            <span>
              {!record.cluster ||
                (record.is_inheritance &&
                  record.spec &&
                  record.spec.kind != "SINGLETON") ||
                (!record.is_inheritance &&
                  record.spec &&
                  record.spec.kind === "SINGLETON" && (
                    <ActionRoute
                      actionIcon="AiOutlineFileText"
                      actionLabel={"Service Logs"}
                      iconButton
                      title={"Service Logs"}
                      disabled={
                        (!record.node &&
                          !record.is_inheritance &&
                          !(record.spec && record.spec.kind === "SINGLETON")) ||
                        (record.spec &&
                          record.spec.status &&
                          record.spec.status.toLowerCase() === "pending")
                          ? true
                          : false
                      }
                      route={
                        window.location.pathname.includes(
                          "/clusters/" + this.props.clusterId + "/services",
                        )
                          ? window.location.pathname.replace(
                              "/clusters/" + this.props.clusterId + "/services",
                              `/inodes/${record.node &&
                                record.node.id}/servicetemplates/${
                                record.id
                              }/logs`,
                            )
                          : window.location.pathname.includes("/dashboard/")
                          ? "/orgs/" +
                            record.organization.id +
                            `/inodes/${record.node &&
                              record.node.id}/servicetemplates/${
                              record.id
                            }/logs`
                          : window.location.pathname.replace(
                              "/services",
                              record.cluster && record.cluster.id
                                ? `/clusters/${record.cluster &&
                                    record.cluster.id}/services/${
                                    record.id
                                  }/logs`
                                : `/inodes/${record.node &&
                                    record.node.id}/servicetemplates/${
                                    record.id
                                  }/logs`,
                            )
                      }
                    />
                  ))}
            </span>
          );
          const restart = (
            <div>
              <div>
                {
                  <Button
                    title={
                      record.spec.restart_policy &&
                      record.spec.restart_policy === "Never"
                        ? getCurrentlocaleText(
                            "Service.restart.disabled.help.text1",
                          )
                        : "Restart Service"
                    }
                    onClick={() => {
                      this.showServiceRestartConfirm(record);
                    }}
                    disabled={
                      (record.spec.restart_policy &&
                        record.spec.restart_policy === "Never") ||
                      (record.spec &&
                        record.spec.status &&
                        record.spec.status.toLowerCase() === "restarting")
                        ? true
                        : false
                    }
                    style={{ minWidth: 50 }}
                  >
                    <Icons
                      style={{ margin: 0, fontSize: 16 }}
                      type="md"
                      name="MdRefresh"
                    />
                  </Button>
                }
              </div>
            </div>
          );
          const menu = (
            <Menu>
              {(this.props.AuthStore.IsPermitted("SERVICE:VIEW") ||
                this.props.AuthStore.IsPermitted("ORG:SUPPORT")) && (
                <Menu.Item key={0}>{view}</Menu.Item>
              )}
              {this.props.AuthStore.IsPermitted("SERVICE:CREATE") &&
                !record.is_inheritance &&
                !record.deleted_at && <Menu.Item key={1}>{edit}</Menu.Item>}
              {((record.node && !record.is_inheritance) ||
                (record.is_inheritance &&
                record.spec &&
                record.spec.kind != "SINGLETON"
                  ? true
                  : false) ||
                (!record.is_inheritance &&
                  record.spec &&
                  record.spec.kind === "SINGLETON")) &&
                !record.deleted_at && (
                  <Menu.Item key={2}>{serviceLog}</Menu.Item>
                )}
              {this.props.AuthStore.IsPermitted("SERVICE:RESTART") &&
                (record.node ||
                  (record.spec && record.spec.kind === "SINGLETON")) &&
                !record.deleted_at && <Menu.Item key={3}>{restart}</Menu.Item>}
            </Menu>
          );
          return (
            <div>
              <DropdownActionBtn menu={menu} />
            </div>
          );
        },
      };
      columns.push(action_obj);
    }

    let content = (
      <div>
        {services && services.length > 0 ? (
          <Table
            size="middle"
            columns={columns}
            dataSource={services}
            rowKey={record => record.id}
            pagination={false}
            expandedRowRender={record => {
              return this.serviceExtraData(record);
            }}
            expandedRowKeys={this.state.expandedRowKeys}
            onExpand={this.onExpand}
            scroll={{ y: 120 }}
          />
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={getCurrentlocaleText("no.services.to.display")}
          />
        )}
        <Modal
          title={getCurrentlocaleText("service.restart.title.text")}
          visible={this.state.showRestartmod}
          onCancel={this.closeServiceRestartModal}
          onOk={this.handleRestart}
          maskClosable={false}
          confirmLoading={this.state.loading}
          closable={!this.state.loading}
          okText={getCurrentlocaleText("service.restart.ok.text")}
          cancelText={getCurrentlocaleText("service.restart.cancel.text")}
        >
          <span>
            <h4 style={{ marginBottom: 15 }}>
              <p>{getCurrentlocaleText("service.restart.text1")}</p>
              <p>
                <Tag className="tag-iotium-info">
                  {this.state.currentService.name}
                </Tag>
              </p>
              <p>{getCurrentlocaleText("service.restart.text2")}</p>
            </h4>
          </span>
        </Modal>
      </div>
    );
    return (
      <div>
        {this.state.loading ? (
          <div className="chart-spinner">
            <Spin tip="Loading..." />
          </div>
        ) : (
          <div>{content}</div>
        )}
      </div>
    );
  }
}

export default ClusterInheritanceServiceList;
