import React, { Component } from "react";
import { Row, Col, Popover } from "antd";
import {
  NOT_APPLICAPLE,
  frequenceConvertion,
  humanizeMetric,
  getCurrentlocaleText,
  getLastSSHAccess,
  getLastConsoleAccess,
  getNodeUptime,
} from "Core/Utils";
import { inject, observer } from "mobx-react";
import Icons from "components/UI-Components/Icons";

const AMD_64 = "amd64";
const X86_64 = "x86 64-bit";

@inject("AuthStore")
@observer
class InodeAdvanceInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      node_data: {},
      visibility: false,
      showCliConfig: false,
    };
  }

  componentDidMount() {
    this.setState({ node_data: this.props.node_data });
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ node_data: props.node_data });
  }

  toggle = () => {
    this.setState({ visibility: !this.state.visibility });
  };

  getNTPServers = (servers = [], current, ntp_synchronized) => {
    if (servers.length > 0) {
      let isCurrentServerPresent = servers.includes(current) ? true : false;
      // if current server is not from configured server push current in server list to show LAT-14737 show fallback servers as well
      if (!isCurrentServerPresent) {
        servers.push(current);
      }
      return servers.map(server => {
        return (
          <Row key={server}>
            <Col key={server}>
              {server === current && ntp_synchronized && (
                <Popover
                  content={getCurrentlocaleText(
                    "inode.systeminfo.configured_server.text",
                  )}
                  trigger="hover"
                >
                  <span>
                    <Icons
                      type="fa"
                      className="node-ntp-status-active"
                      name="FaCheck"
                    />
                  </span>
                </Popover>
              )}{" "}
              {server}
            </Col>
          </Row>
        );
      });
    } else {
      return (
        <Row key={current}>
          <Col key={current}>
            {
              <Popover
                content={getCurrentlocaleText(
                  "inode.systeminfo.configured_server.text",
                )}
                trigger="hover"
              >
                <span>
                  {ntp_synchronized && (
                    <Icons
                      type="fa"
                      className="node-ntp-status-active"
                      name="FaCheck"
                    />
                  )}
                </span>
              </Popover>
            }{" "}
            {current}
          </Col>
        </Row>
      );
    }
  };

  renderCliConfig = status => {
    let config = status && status.cli_config ? status.cli_config : null;
    return (
      <>
        <Row>
          {status &&
            status.dns &&
            status.dns.nameserver &&
            status.dns.nameserver.length > 0 && (
              <Col span={8} className="node-info-middle-row">
                <strong>
                  {getCurrentlocaleText(
                    "inode.interface.nameserver.label.text",
                  )}
                </strong>
              </Col>
            )}
          {status &&
            status.dns &&
            status.dns.nameserver &&
            status.dns.nameserver.length > 0 && (
              <Col span={16} className="node-info-middle-row">
                {status.dns.nameserver.join(", ")}
              </Col>
            )}
        </Row>
        {config && config.proxy && (
          <>
            <Row>
              <Col span={24} className="node-info-middle-row">
                <strong>
                  {getCurrentlocaleText(
                    "inode.interface.proxydetails.label.text",
                  )}
                </strong>
              </Col>
            </Row>
            <Row>
              <Col span={24} className="node-info-middle-row">
                {config && config.proxy && config.proxy.url && (
                  <Row>
                    <Col span={8}>
                      {getCurrentlocaleText("inode.interface.host.label.text")}
                      {": "}
                    </Col>
                    <Col span={16}>{config.proxy.url}</Col>
                  </Row>
                )}
                {config && config.proxy && config.proxy.protocol && (
                  <Row>
                    <Col span={8}>
                      {getCurrentlocaleText(
                        "firewall.list.table.header.protocol.text",
                      )}
                      {": "}
                    </Col>
                    <Col span={16}>{config.proxy.protocol}</Col>
                  </Row>
                )}
                {config && config.proxy && config.proxy.username && (
                  <Row>
                    <Col span={8}>
                      {getCurrentlocaleText("inode.cli.username.label.text")}
                      {": "}
                    </Col>
                    <Col span={16}>{config.proxy.username}</Col>
                  </Row>
                )}
              </Col>
            </Row>
          </>
        )}
      </>
    );
  };

  render() {
    let { node_data, visibility } = this.state;
    let node_status_info =
      node_data.status && node_data.status.node && node_data.status.node.info
        ? node_data.status.node.info
        : null;
    let node_state =
      node_data && node_data.node_state ? node_data.node_state : null;
    return (
      <div>
        <div
          style={{
            margin: "30px 0px 20px",
            textAlign: "center",
          }}
          className="inode-advanced-info-card"
        >
          <a
            style={{ cursor: "pointer" }}
            onClick={this.toggle}
            title={getCurrentlocaleText(
              "inode.systeminfo.more_information.text",
            )}
          >
            <Icons
              type="ai"
              name={visibility ? "AiFillCaretUp" : "AiFillCaretDown"}
              style={{ fontSize: 14 }}
            />
          </a>
        </div>
        {visibility ? (
          <div className="inode-advanced-info-card">
            <Row gutter={0} type="flex" justify="center">
              <Col span={24}>
                <Row>
                  <Col span={8} className="node-info-top-row">
                    <strong>
                      {getCurrentlocaleText("inode.systeminfo.uptime.text")}
                    </strong>
                  </Col>
                  <Col span={16} className="node-info-top-row">
                    <div className="node-info-right-column">
                      {node_state && node_state.toUpperCase() === "ALIVE"
                        ? getNodeUptime(node_data)
                        : NOT_APPLICAPLE}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={8} className="node-info-middle-row">
                    <strong>
                      {getCurrentlocaleText("inode.systeminfo.sshcontact.text")}
                    </strong>
                  </Col>
                  <Col span={16} className="node-info-middle-row">
                    <div className="node-info-right-column">
                      {getLastSSHAccess(node_data)}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={8} className="node-info-middle-row">
                    <strong>
                      {getCurrentlocaleText(
                        "inode.systeminfo.consolecontact.text",
                      )}
                    </strong>
                  </Col>
                  <Col span={16} className="node-info-middle-row">
                    <div className="node-info-right-column">
                      {getLastConsoleAccess(node_data)}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={8} className="node-info-middle-row">
                    <strong>
                      {getCurrentlocaleText(
                        "inode.systeminfo.cpu_architecture.text",
                      )}
                    </strong>
                  </Col>
                  <Col span={16} className="node-info-middle-row">
                    <div className="node-info-right-column">
                      {node_status_info && node_status_info.architecture
                        ? node_status_info.architecture === AMD_64
                          ? `${X86_64}`
                          : `${node_status_info.architecture}`
                        : NOT_APPLICAPLE}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={8} className="node-info-middle-row">
                    <strong>
                      {getCurrentlocaleText("inode.systeminfo.cpu_cores.text")}
                    </strong>
                  </Col>
                  <Col span={16} className="node-info-middle-row">
                    <div className="node-info-right-column">
                      {node_status_info && node_status_info.cores
                        ? `${node_status_info.cores}`
                        : NOT_APPLICAPLE}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={8} className="node-info-middle-row">
                    <strong>
                      {getCurrentlocaleText(
                        "inode.systeminfo.cpu_frequency.text",
                      )}
                    </strong>
                  </Col>
                  <Col span={16} className="node-info-middle-row">
                    <div className="node-info-right-column">
                      {node_status_info && node_status_info.cpu_frequency
                        ? `${frequenceConvertion(
                            node_status_info.cpu_frequency,
                          )}`
                        : NOT_APPLICAPLE}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={8} className="node-info-middle-row">
                    <strong>
                      {getCurrentlocaleText("inode.systeminfo.memory.text")}
                    </strong>
                  </Col>
                  <Col span={16} className="node-info-middle-row">
                    <div className="node-info-right-column">
                      {node_status_info && node_status_info.memory
                        ? `${humanizeMetric(node_status_info.memory, true)}`
                        : NOT_APPLICAPLE}
                    </div>
                  </Col>
                </Row>
                {/* Verifying SUPER:ADMIN permissions */}
                {this.props.AuthStore.IsPermitted("KERNEL:VIEW") && (
                  <Row>
                    <Col span={8} className="node-info-middle-row">
                      <strong>
                        {getCurrentlocaleText(
                          "inode.systeminfo.kernel_version.text",
                        )}
                      </strong>
                    </Col>
                    <Col span={16} className="node-info-middle-row">
                      <div className="node-info-right-column">
                        {node_status_info && node_status_info.kernel_version
                          ? node_status_info.kernel_version
                          : NOT_APPLICAPLE}
                      </div>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col span={8} className="node-info-middle-row">
                    <strong>
                      {getCurrentlocaleText(
                        "inode.systeminfo.vendor_oemid.text",
                      )}
                    </strong>
                  </Col>
                  <Col span={16} className="node-info-middle-row">
                    <div className="node-info-right-column">
                      {node_status_info && node_status_info.vendor_oemid
                        ? `${node_status_info.vendor_oemid}`
                        : NOT_APPLICAPLE}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={8} className="node-info-middle-row">
                    <strong>
                      {getCurrentlocaleText("inode.systeminfo.timeserver.text")}
                      {node_data &&
                        node_data.status &&
                        node_data.status.node &&
                        node_data.status.node.ntp_server &&
                        !node_data.status.node.ntp_server.ntp_synchronized && (
                          <Popover
                            content={getCurrentlocaleText(
                              "inode.systeminfo.nonsynchronized.text",
                            )}
                            trigger="hover"
                          >
                            <span>
                              <Icons
                                style={{
                                  fontSize: 15,
                                  marginLeft: 5,
                                  color: "orange",
                                }}
                                type="fa"
                                name="FaExclamationCircle"
                              />
                            </span>
                          </Popover>
                        )}
                    </strong>
                  </Col>
                  <Col span={16} className="node-info-middle-row">
                    <div className="node-info-right-column">
                      {(node_data &&
                        node_data.status &&
                        node_data.status.node &&
                        node_data.status.node.ntp_server &&
                        node_data.status.node.ntp_server.configured_servers &&
                        node_data.status.node.ntp_server.configured_servers
                          .length > 0 &&
                        node_data &&
                        node_data.status &&
                        node_data.status.node &&
                        node_data.status.node.ntp_server &&
                        node_data.status.node.ntp_server.current_server &&
                        node_data.status.node.ntp_server.current_server) ||
                      (node_data &&
                        node_data.status &&
                        node_data.status.node &&
                        node_data.status.node.ntp_server &&
                        node_data.status.node.ntp_server.current_server &&
                        node_data.status.node.ntp_server.current_server)
                        ? this.getNTPServers(
                            node_data.status.node.ntp_server.configured_servers,
                            node_data.status.node.ntp_server.current_server,
                            node_data.status.node.ntp_server &&
                              node_data.status.node.ntp_server
                                .ntp_synchronized &&
                              node_data.status.node.ntp_server.ntp_synchronized,
                          )
                        : NOT_APPLICAPLE}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={8} className="node-info-middle-row">
                    <strong>
                      {getCurrentlocaleText("inode.systeminfo.distro.text")}
                    </strong>
                  </Col>
                  <Col span={16} className="node-info-middle-row">
                    <div className="node-info-right-column">
                      {node_status_info &&
                      node_status_info.distro &&
                      node_status_info.distro.id
                        ? `${node_status_info.distro.id}`
                        : NOT_APPLICAPLE}
                    </div>
                  </Col>
                </Row>
                {/* <Row>
                    <Col span={8} className="node-info-middle-row">
                      <strong>
                        {getCurrentlocaleText(
                          "inode.systeminfo.cliconfig.text",
                        )}
                      </strong>
                    </Col>
                    <Col span={16} className="node-info-middle-row"></Col>
                  </Row> */}
                {this.renderCliConfig(node_data.status.node)}
              </Col>
            </Row>
          </div>
        ) : null}
      </div>
    );
  }
}

export default InodeAdvanceInfo;
