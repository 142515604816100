import React from "react";
import { observable, action, computed } from "mobx";
import Pageable from "./Pageable";

class RepNetViewModel {
  @observable networks = [];
  @observable pageSize = 25;

  @observable loading = {
    fromEdge: false,
    fromVirtual: false,
    edgeStaticRoutes: false,
  };

  @observable pageable = {
    fromEdge: new Pageable(),
    fromVirtual: new Pageable(),
    edgeStaticRoutes: new Pageable(),
  };

  @action
  resetNetworks = from => {
    this.networks = [];
    this.pageable[from] = new Pageable();
  };

  @action
  setLoading = (loading, from) => {
    this.loading[from] = loading;
  };

  @action
  setPageable = (resp = {}, from) => {
    this.pageable[from] = new Pageable(resp);
  };

  @action
  setPageSize = size => {
    this.pageSize = size;
  };
}

export default new RepNetViewModel();
