import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import {
  Row,
  Cascader,
  Col,
  Select,
  DatePicker,
  Icon,
  Button,
  TreeSelect,
  Popover,
} from "antd";
import { FilterOutlined, ReloadOutlined } from "@ant-design/icons";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import TableLayout from "components/layout/TableLayout";
import Icons from "components/UI-Components/Icons";
import EventController from "controller/EventController";
import InodeController from "controller/InodeController";
import OrgController from "controller/OrgController";
import ClusterController from "controller/ClusterController";
import {
  getCurrentlocaleText,
  customSort,
  getDefaultTimezone,
  getNodeStatusColorCode,
  getNetworkStatusColorCode,
  getPodStatusColorCode,
  getTranslatedString,
  getWebhookStatusColorCode,
  capitalize,
} from "Core/Utils";
import moment from "moment";
import TableLayoutV1 from "../layout/TableLayoutV1";

const Option = Select.Option;
const iconStyle = {
  fontSize: 16,
  lineHeight: 1.3,
};
const extraRowStyle = { marginTop: 5, marginBottom: 5 };

@inject("AuthStore", "EventViewModel", "TimezoneViewModel")
@observer
class EventList extends Component {
  constructor(props) {
    super(props);
    this.eventModel = this.props.EventViewModel;
    this.timezoneModel = this.props.TimezoneViewModel;
    this.startDateString = null;
    this.endDateString = null;
    this.state = {
      type: null,
      orgId: null,
      nodeId: null,
      clusterId: this.props.clusterId,
      startDate: null,
      endDate: null,
      orgInodeClusterTree: [],
      expandedRowKeys: [],
      isInvalidRange: false,
      currentPage: 1,
      isTableModalToggle: false,
      modalTitle: "",
      modalContent: "",
    };
    this.EVENT_TYPES = [
      { label: "iNode", value: "node" },
      { label: "Network", value: "network" },
      { label: "Service", value: "service" },
    ];
  }

  componentDidMount() {
    this.eventList(
      true,
      0,
      false,
      this.state.type,
      this.state.orgId,
      this.state.nodeId,
      this.state.clusterId,
      this.state.startDate
        ? this.state.startDate.format()
        : this.state.startDate,
      this.state.endDate ? this.state.endDate.format() : this.state.endDate,
    );
    if (!this.props.nodeId && !this.props.clusterId) {
      this.EVENT_TYPES.push({ label: "Webhook", value: "webhook" });
      this.fetchOrgInodeClusters();
    }
  }

  refreshEvents = () => {
    this.eventList(
      true,
      0,
      true,
      this.state.type,
      this.state.orgId,
      this.state.nodeId,
      this.state.clusterId,
      this.state.startDate
        ? this.state.startDate.format()
        : this.state.startDate,
      this.state.endDate ? this.state.endDate.format() : this.state.endDate,
    );
    this.setState({
      expandedRowKeys: [],
    });
  };

  fetchClusterDataForDropdown = () => {
    return new Promise((resolve, reject) => {
      ClusterController.getClustersForDropdown({ size: 1 }).then(response => {
        if (response && response.total_count) {
          let allParams = [],
            totalCount = response.total_count / 100;
          if (totalCount > 0) {
            for (let page = 0; page < totalCount; page++) {
              let params = { page: page, size: 100 };
              allParams.push(params);
            }
            ClusterController.getAllClustersForDropdown(allParams, false)
              .then(response => {
                resolve(response);
              })
              .catch(error => {
                resolve([]);
              });
          } else {
            resolve([]);
          }
        } else {
          resolve([]);
        }
      });
    });
  };

  fetchOrgInodeClusters = () => {
    let params = { size: 1 },
      orgs = null,
      nodes = null,
      clusters = null;
    OrgController.getOrgsForDropdown(params, true, false).then(response => {
      if (response && response.total_count) {
        let allParams = [],
          totalCount = response.total_count / 100;
        if (totalCount > 0) {
          for (let page = 0; page < totalCount; page++) {
            params = { page: page, size: 100 };
            allParams.push(params);
          }
          OrgController.getAllOrgsForDropdown(allParams).then(response => {
            if (response) {
              orgs = response;
              InodeController.getInodesForDropdown(params).then(response => {
                if (response && response.total_count) {
                  let allParams = [],
                    totalCount = response.total_count / 100;
                  if (totalCount > 0) {
                    for (let page = 0; page < totalCount; page++) {
                      params = { page: page, size: 100 };
                      allParams.push(params);
                    }
                    InodeController.getAllInodesForDropdown(allParams).then(
                      response => {
                        nodes = response;
                        this.fetchClusterDataForDropdown().then(response => {
                          clusters = response;
                          this.formOrgInodeClusterTree(orgs, nodes, clusters);
                        });
                      },
                    );
                  }
                } else {
                  this.fetchClusterDataForDropdown().then(response => {
                    clusters = response;
                    this.formOrgInodeClusterTree(orgs, nodes, clusters);
                  });
                }
              });
            }
          });
        }
      }
    });
  };

  formOrgInodeClusterTree = (orgs, nodes, clusters) => {
    let orgInodeClusterTree = [];
    if (orgs) {
      orgs.forEach(org => {
        let children = [],
          node,
          cluster,
          nodeChildren = [],
          clusterChildren = [];
        let nodeList =
          nodes &&
          nodes.filter(node => {
            return node && node.organization && node.organization.id === org.id;
          });
        let clusterList =
          clusters &&
          clusters.filter(cluster => {
            return (
              cluster &&
              cluster.organization &&
              cluster.organization.id === org.id
            );
          });
        // For Node
        if (nodeList && nodeList.length > 0) {
          nodeChildren.push(
            ...nodeList.map(node => {
              return {
                title: node.name,
                value: `node/${node.organization.id}/${node.id}`,
                key: `${node.organization.id}/${node.id}`,
                label: `${node.name}`,
              };
            }),
          );
          node = {
            title: getCurrentlocaleText("inodes.text"),
            value: `node/${org.id}`,
            key: `node/${org.id}/${org.id}`,
            label: `${getCurrentlocaleText("inodes.text")}`,

            children: nodeChildren,
          };
        }
        // For cluster
        if (clusterList && clusterList.length > 0) {
          clusterChildren.push(
            ...clusterList.map(cluster => {
              return {
                title: cluster.name,
                value: `cluster/${cluster.organization.id}/${cluster.id}`,
                key: `${cluster.organization.id}/${cluster.id}`,
                label: `${cluster.name}`,
              };
            }),
          );
          cluster = {
            title: getCurrentlocaleText("clusters.text"),
            value: `cluster/${org.id}/${org.id}`,
            key: `cluster/${org.id}/${org.id}`,
            label: `${getCurrentlocaleText("clusters.text")}`,

            children: clusterChildren,
          };
        }
        let all = {
          title: getCurrentlocaleText("all.events"),
          value: `all/${org.id}`,
          key: `all/${org.id}`,
          label: getCurrentlocaleText("all.events"),
        };
        children.push(all);
        // Push node if org has nodes
        if (node) {
          children.push(node);
        }
        // Push cluster if org has cluster
        if (cluster) {
          children.push(cluster);
        }
        orgInodeClusterTree.push({
          title: org.name,
          value: org.id,
          key: org.id,
          label: org.name,
          children: children,
        });
      });
    }
    // Apply sorting
    orgInodeClusterTree.sort((x, y) => customSort(x.title, y.title));
    if (orgInodeClusterTree.children) {
      orgInodeClusterTree.children.forEach(orgChildren => {
        if (orgChildren) {
          orgChildren.children.forEach(children => {
            if (children) {
              children.sort((x, y) => {
                customSort(x.title, y.title);
              });
            }
          });
        }
      });
    }

    this.setState({ orgInodeClusterTree });
  };

  handleOrgInodeClusterChange = values => {
    let orgId = null,
      nodeId = null,
      clusterId = null;
    if (values) {
      values.forEach(value => {
        value = value.split("/");
        if (value[0] === "node") {
          orgId = value[1];
          nodeId = value[2];
        } else if (value[0] === "cluster") {
          orgId = value[1];
          clusterId = value[2];
        } else if (value[0] === "all") {
          orgId = value[1];
        } else if (value[0]) {
          orgId = value[0];
        }
      });
    }
    this.setState({ orgId: orgId, nodeId: nodeId, clusterId: clusterId });
  };

  handleEventTypeChange = value => {
    let type = value;
    this.setState({ type: type });
  };

  disabledDate = current => {
    // Can not select days after today and today
    return current && current.valueOf() > Date.now();
  };

  handleStartDateChange = (date, dateString) => {
    this.state.endDate && this.state.endDate.diff(date) < 0
      ? this.setState({
          isInvalidRange: true,
        })
      : this.setState({
          isInvalidRange: false,
        });
    // _z referes to zone.
    let timezone =
      this.props.TimezoneViewModel.userTimezone ||
      this.props.TimezoneViewModel.orgTimezone ||
      getDefaultTimezone().value;

    date = date
      ? date._z
        ? date.tz(timezone)
        : moment.tz(dateString, timezone)
      : date;
    this.setState({
      startDate: date,
    });
  };

  handleEndDateChange = (date, dateString) => {
    date.diff(this.state.startDate) < 0
      ? this.setState({
          isInvalidRange: true,
        })
      : this.setState({
          isInvalidRange: false,
        });
    // _z referes to zone.
    let timezone =
      this.props.TimezoneViewModel.userTimezone ||
      this.props.TimezoneViewModel.orgTimezone ||
      getDefaultTimezone().value;

    date = date
      ? date._z
        ? date.tz(timezone)
        : moment.tz(dateString, timezone)
      : date;
    this.setState({
      endDate: date,
    });
  };

  expandRow = () => {
    let keys = this.eventModel.events.map(item => {
      return item.id;
    });
    this.setExpandedRowKeys(
      this.state.expandedRowKeys.length !== keys.length ? keys : [],
    );
  };

  onExpand = (expanded, record) => {
    let keys = this.state.expandedRowKeys;
    let index = keys.findIndex(val => {
      return val === record.id;
    });
    if (expanded) {
      if (index === -1) {
        keys.push(record.id);
      }
      this.setExpandedRowKeys(keys);
    } else {
      if (index !== -1) {
        keys.splice(index, 1);
      }
      this.setExpandedRowKeys(keys);
    }
  };

  setExpandedRowKeys = keys => {
    this.setState({ expandedRowKeys: keys });
  };

  getContainerStatusTag = data => {
    if (data.length > 0) {
      return data
        .sort((x, y) => customSort(x.name, y.name))
        .map((item, i) => {
          let popoverContent = item.extraData
            ? item.extraData.map((extra, i) => {
                return (
                  <div key={extra.name + i}>
                    <strong>{extra.name}</strong>
                    {extra.status}
                  </div>
                );
              })
            : undefined;

          return (
            <Row key={item.name + i}>
              <p key={item.name + i} value={item.name}>
                <span>{item.name + " / " + item.status}&nbsp;</span>
                {item.extraData && item.extraData.length > 0 && (
                  <Popover
                    content={popoverContent}
                    title={<strong>Information</strong>}
                    placement="top"
                  >
                    <span>
                      <Icons
                        type="fa"
                        name="FaInfoCircle"
                        className="profile-2fa-icon-style"
                      />
                    </span>
                  </Popover>
                )}
              </p>
            </Row>
          );
        });
    } else {
      return "-";
    }
  };

  getModalContentJSX = record => {
    this.setState({
      modalTitle:
        record && record.displayLongDate ? record.displayLongDate : "",
      modalContent: this.getMoreInfo(record),
    });
  };

  getMoreInfo = record => {
    let data = [];
    if (record.container_status) {
      let keys = ["exitCode", "reason", "message"];
      for (let key in record.container_status) {
        let status_reason = [];
        for (let val in record.container_status[key]) {
          if (keys.includes(val)) {
            status_reason.push({
              key: val,
              name:
                val === "exitCode"
                  ? getCurrentlocaleText(
                      "inode.events.container_status.exitCode.text",
                    )
                  : val === "reason"
                  ? getCurrentlocaleText(
                      "inode.events.container_status.reason.text",
                    )
                  : getCurrentlocaleText(
                      "inode.events.container_status.message.text",
                    ),
              status: record.container_status[key][val],
            });
          }
        }
        if (
          !keys.includes(key) &&
          record.container_status.hasOwnProperty(key)
        ) {
          data.push({
            key: key,
            name: key,
            status:
              record.container_status[key] && record.container_status[key].state
                ? record.container_status[key].state
                : record.container_status[key],
            extraData: status_reason,
          });
        }
      }
    }

    return (
      <div>
        {record.type === "node" && (
          <div>
            {(record.from || record.to) &&
              record.status.toLowerCase() != "failed" && (
                <Row style={extraRowStyle}>
                  <Col span={5}>
                    <strong>
                      {record.status &&
                      record.status.toLowerCase() === "successful"
                        ? getCurrentlocaleText(
                            "inode.events.upgrade_status.text",
                          )
                        : record.status.toLowerCase() === "node replace"
                        ? getCurrentlocaleText("hsnchange.text")
                        : getCurrentlocaleText("ipaddress.text")}
                    </strong>
                  </Col>
                  <Col span={19}>
                    <Row gutter={0}>
                      <Col span={8}>
                        <strong>
                          {getCurrentlocaleText(
                            "inode.events.ipchange.from.text",
                          )}
                        </strong>
                      </Col>
                      <Col span={2}>
                        <strong>{":"}</strong>
                      </Col>
                      <Col span={4} pull={2}>
                        {record.from ? record.from : "-"}
                      </Col>
                    </Row>
                    <Row gutter={0}>
                      <Col span={8}>
                        <strong>
                          {getCurrentlocaleText(
                            "inode.events.ipchange.to.text",
                          )}
                        </strong>
                      </Col>
                      <Col span={2}>
                        <strong>{":"}</strong>
                      </Col>
                      <Col span={4} pull={2}>
                        {record.to ? record.to : "-"}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            {record.display_type &&
              record.display_type.toLowerCase() === "cluster master change" && (
                <Row style={extraRowStyle}>
                  <Col span={8}>
                    <strong>{getCurrentlocaleText("fromInode.text")}</strong>
                  </Col>
                  <Col span={2}>
                    <strong>{":"}</strong>
                  </Col>
                  <Col>
                    {record.from_node && record.from_node.name
                      ? record.from_node.name
                      : "-"}
                  </Col>
                </Row>
              )}
            {record.cluster && (
              <Row style={extraRowStyle}>
                <Col span={8}>
                  <strong>{getCurrentlocaleText("clusterName.text")}</strong>
                </Col>
                <Col span={2}>
                  <strong>{":"}</strong>
                </Col>
                <Col>{record.cluster.name}</Col>
              </Row>
            )}
            <Row style={extraRowStyle}>
              <Col span={8}>
                <strong>{getCurrentlocaleText("organizationName.text")}</strong>
              </Col>
              <Col span={2}>
                <strong>{":"}</strong>
              </Col>
              <Col>{record.organization.name}</Col>
            </Row>
          </div>
        )}
        {record.type === "network" && (
          <div>
            {record.node && (
              <Row style={extraRowStyle}>
                <Col span={8}>
                  <strong>{getCurrentlocaleText("inodeName.text")}</strong>
                </Col>
                <Col span={2}>
                  <strong>{":"}</strong>
                </Col>
                <Col>{record.node.name}</Col>
              </Row>
            )}
            {record.cluster && (
              <Row style={extraRowStyle}>
                <Col span={8}>
                  <strong>{getCurrentlocaleText("clusterName.text")}</strong>
                </Col>
                <Col span={2}>
                  <strong>{":"}</strong>
                </Col>
                <Col>{record.cluster.name}</Col>
              </Row>
            )}
            <Row style={extraRowStyle}>
              <Col span={8}>
                <strong>{getCurrentlocaleText("organizationName.text")}</strong>
              </Col>
              <Col span={2}>
                <strong>{":"}</strong>
              </Col>
              <Col>{record.organization.name}</Col>
            </Row>
          </div>
        )}
        {record.type === "service" && (
          <div>
            {record.running_containers && (
              <Row style={extraRowStyle}>
                <Col span={8}>
                  <strong>{getCurrentlocaleText("running.containers")}</strong>
                </Col>
                <Col span={2}>
                  <strong>{":"}</strong>
                </Col>
                <Col>{record.running_containers}</Col>
              </Row>
            )}
            {record.container_status && (
              <Row style={extraRowStyle}>
                <Col span={8}>
                  <strong>
                    {getCurrentlocaleText("events.container_name_status.text")}
                  </strong>
                </Col>
                <Col span={2}>
                  <strong>{":"}</strong>
                </Col>
                <Col span={14}>{this.getContainerStatusTag(data)}</Col>
              </Row>
            )}
            {record.kind && (
              <Row style={extraRowStyle}>
                <Col span={8}>
                  <strong>{getCurrentlocaleText("services.kind.label")}</strong>
                </Col>
                <Col span={2}>
                  <strong>{":"}</strong>
                </Col>
                <Col>{capitalize(record.kind)}</Col>
              </Row>
            )}
            {record.node && (
              <Row style={extraRowStyle}>
                <Col span={8}>
                  <strong>{getCurrentlocaleText("inodeName.text")}</strong>
                </Col>
                <Col span={2}>
                  <strong>{":"}</strong>
                </Col>
                <Col>{record.node.name}</Col>
              </Row>
            )}
            {record.cluster && (
              <Row style={extraRowStyle}>
                <Col span={8}>
                  <strong>{getCurrentlocaleText("clusterName.text")}</strong>
                </Col>
                <Col span={2}>
                  <strong>{":"}</strong>
                </Col>
                <Col>{record.cluster.name}</Col>
              </Row>
            )}
            <Row style={extraRowStyle}>
              <Col span={8}>
                <strong>{getCurrentlocaleText("organizationName.text")}</strong>
              </Col>
              <Col span={2}>
                <strong>{":"}</strong>
              </Col>
              <Col>{record.organization.name}</Col>
            </Row>
          </div>
        )}
        {record.type === "webhook" && (
          <div>
            <Row style={extraRowStyle}>
              <Col span={8}>
                <strong>
                  {getCurrentlocaleText("alert.notification.webhook.url")}
                </strong>
              </Col>
              <Col span={2}>
                <strong>{":"}</strong>
              </Col>
              <Col>{record.webhook_url}</Col>
            </Row>
            <Row style={extraRowStyle}>
              <Col span={8}>
                <strong>
                  {getCurrentlocaleText("general.component.users.text")}
                </strong>
              </Col>
              <Col span={2}>
                <strong>{":"}</strong>
              </Col>
              <Col>{record.user ? record.user.name : "-"}</Col>
            </Row>
            <Row style={extraRowStyle}>
              <Col span={8}>
                <strong>{getCurrentlocaleText("organizationName.text")}</strong>
              </Col>
              <Col span={2}>
                <strong>{":"}</strong>
              </Col>
              <Col>{record.organization.name}</Col>
            </Row>
          </div>
        )}
      </div>
    );
  };

  eventList = (
    loading = true,
    page = 0,
    forcecall = false,
    type = null,
    orgId = null,
    nodeId = null,
    clusterId = null,
    startDate = null,
    endDate = null,
  ) => {
    let params = {};
    params.page = page;
    params.size = 25;
    // Type filter
    if (type) {
      params.type = type;
    }
    // Org Filter
    if (orgId) {
      params.org_id = orgId;
      params.own = true;
    }
    // Node Filter
    if (nodeId) {
      params.node_id = nodeId;
    }
    // Cluster Filter
    if (clusterId) {
      params.cluster_id = clusterId;
    }
    // Start date Filter
    if (startDate) {
      params.start_date = startDate;
    }
    // End date Filter
    if (endDate) {
      params.end_date = endDate;
    }
    // For specific node events
    if (this.props.nodeId) {
      EventController.getInodeEvents(
        this.props.nodeId,
        params,
        loading,
        forcecall,
      );
    } else {
      // For all the events
      EventController.getEvents(params, loading, forcecall);
    }
  };

  lazyLoad = () => {
    if (
      this.eventModel.pageable.page <
      this.eventModel.pageable.total_page - 1
    ) {
      let page = this.eventModel.pageable.page + 1;
      this.eventList(
        true,
        page,
        false,
        this.state.type,
        this.state.orgId,
        this.state.nodeId,
        this.state.clusterId,
        this.state.startDate
          ? this.state.startDate.format()
          : this.state.startDate,
        this.state.endDate ? this.state.endDate.format() : this.state.endDate,
      );
    }
  };

  getEventList = () => {
    this.eventList(
      true,
      0,
      false,
      this.state.type,
      this.state.orgId,
      this.state.nodeId,
      this.state.clusterId,
      this.state.startDate
        ? this.state.startDate.format()
        : this.state.startDate,
      this.state.endDate ? this.state.endDate.format() : this.state.endDate,
    );
    this.setState({ expandedRowKeys: [] });
  };

  renderEventTypeOptions = () => {
    let options = [];
    options = this.EVENT_TYPES.map(type => {
      return (
        <Option key={type.value} value={type.value}>
          {type.label}
        </Option>
      );
    });
    return options;
  };

  onTableChange = pagination => {
    this.setState({
      currentPage: pagination.current,
    });
    let page = pagination.current - 1;
    this.eventList(
      true,
      page,
      false,
      this.state.type,
      this.state.orgId,
      this.state.nodeId,
      this.state.clusterId,
      this.state.startDate
        ? this.state.startDate.format()
        : this.state.startDate,
      this.state.endDate ? this.state.endDate.format() : this.state.endDate,
    );
  };

  handleModalOk(e) {
    this.setState({
      isTableModalToggle: !this.state.isTableModalToggle,
    });
  }

  render() {
    const { isTableModalToggle } = this.state;
    let count = this.eventModel.count || 0;
    let events = this.eventModel.eventList || [];
    const currentOrgId =
      this.state.orgId != undefined
        ? this.state.orgId
        : this.props.AuthStore.loggedInUser.organization &&
          this.props.AuthStore.loggedInUser.organization.id;
    let columns = [
      {
        title: getCurrentlocaleText("event.occurred.at.text"),
        key: "event_occured_at",
        width: "30%",
        ellipsis: true,
        render: (text, record, index) => {
          return (
            <span className="col-wrap">
              {record.created_at ? (
                <span title={record.displayLongDate}>
                  {record.displayLongDate}
                </span>
              ) : (
                <span>-</span>
              )}
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText("resource.name.text"),
        key: "name",
        width: "30%",
        ellipsis: true,
        render: (text, record, index) => {
          return (
            <span className="col-wrap">
              {record.type === "node" && (
                <span title={record.node.name}>
                  <Icons
                    type="fa"
                    name="FaRegHdd"
                    className="middle"
                    style={iconStyle}
                  />{" "}
                  <span>&nbsp;{record.node.name}</span>
                </span>
              )}
              {record.type === "network" && record.peer_network ? (
                <span
                  title={
                    record.cluster
                      ? record.cluster.name +
                        "/" +
                        record.network.name +
                        "\u27F7" +
                        record.peer_node.name +
                        "/" +
                        record.peer_network.name
                      : record.node.name +
                        "/" +
                        record.network.name +
                        "\u27F7" +
                        record.peer_node.name +
                        "/" +
                        record.peer_network.name
                  }
                >
                  <Icons
                    type="fa"
                    name="FaConnectdevelop"
                    className="middle"
                    style={iconStyle}
                  />
                  {record.cluster ? (
                    <span>
                      &nbsp;{record.cluster.name}/{record.network.name}
                    </span>
                  ) : (
                    <span>
                      &nbsp;{record.node.name}/{record.network.name}
                    </span>
                  )}
                  {record.peer_network && (
                    <span>
                      &nbsp;{"\u27F7"}&nbsp;{record.peer_node.name}/
                      {record.peer_network.name}
                    </span>
                  )}
                </span>
              ) : (
                record.type === "network" && (
                  <span>
                    <Icons
                      type="fa"
                      name="FaConnectdevelop"
                      className="middle"
                      style={iconStyle}
                    />
                    <span title={record.network.name}>
                      &nbsp;{record.network.name}
                    </span>
                  </span>
                )
              )}
              {record.type === "service" && (
                <span title={record.service.name}>
                  <Icons
                    type="fa"
                    name="FaCogs"
                    className="middle"
                    style={iconStyle}
                  />
                  <span>&nbsp;{record.service.name}</span>
                </span>
              )}
              {record.type === "webhook" && (
                <span>
                  <Icons
                    type="ai"
                    name="AiOutlineGlobal"
                    className="valign-sub"
                    style={iconStyle}
                  />
                  {record.webhook ? (
                    <span title={record.webhook.name}>
                      &nbsp;{record.webhook.name}
                    </span>
                  ) : (
                    <span>-</span>
                  )}
                </span>
              )}
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText("event.event_type.text"),
        key: "type",
        width: "30%",
        ellipsis: true,
        render: (text, record, index) => {
          return (
            <span className="col-wrap">
              {record.display_type ? (
                <span title={record.display_type}>{record.display_type}</span>
              ) : (
                <span>-</span>
              )}
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText("event.status.text"),
        key: "event",
        width: "20%",
        ellipsis: true,
        render: (text, record, index) => {
          return (
            <span
              className="col-wrap"
              title={getTranslatedString(record.status)}
            >
              {record.type === "node" && (
                <strong
                  className={getNodeStatusColorCode(
                    record.status === "REBOOT"
                      ? "rebooting"
                      : record.status === "SUCCESSFUL"
                      ? "alive"
                      : record.status === "FAILED"
                      ? "unreachable"
                      : record.status,
                  )}
                >
                  {getTranslatedString(record.status)}
                </strong>
              )}
              {record.type === "network" && (
                <strong className={getNetworkStatusColorCode(record.status)}>
                  {getTranslatedString(record.status)}
                </strong>
              )}
              {record.type === "service" && (
                <strong className={getPodStatusColorCode(record.status)}>
                  {getTranslatedString(record.status)}
                </strong>
              )}
              {record.type === "webhook" && (
                <strong className={getWebhookStatusColorCode(record.status)}>
                  {getTranslatedString(record.status)}
                </strong>
              )}
            </span>
          );
        },
      },
    ];

    let container = (
      <div>
        {/* Event Filters */}
        <div className="listing-page-top-container">
          <Row type="flex" align="middle">
            <Col span={2} className="d-flex">
              <div className="events-header">
                {getCurrentlocaleText("events.text")}
              </div>
              <div className="number-circle">
                {"("}
                {count}
                {")"}
              </div>
            </Col>
            <Col span={22}>
              <div className="listing-page-filter-container">
                {/* Event Type Filter */}
                <span className="listing-page-filter">
                  <Select
                    placeholder={getCurrentlocaleText(
                      "event.typefilter.placeholder",
                    )}
                    style={{ width: 120 }}
                    allowClear={true}
                    onChange={this.handleEventTypeChange}
                    notFoundContent={getCurrentlocaleText(
                      "general.notfound.placeholder.text",
                    )}
                  >
                    {this.renderEventTypeOptions()}
                  </Select>
                </span>
                {/* Org & iNode or Cluster Filter */}
                {!this.props.nodeId && !this.props.clusterId && (
                  <span className="listing-page-filter">
                    <Cascader
                      allowClear
                      style={{ width: 180 }}
                      dropdownClassName="event-org-node-cluster-selector-dd"
                      placeholder={getCurrentlocaleText(
                        "event.orgfilter.placeholder",
                      )}
                      dropdownStyle={{ maxHeight: 250, overflow: "auto" }}
                      options={this.state.orgInodeClusterTree}
                      onChange={this.handleOrgInodeClusterChange}
                      treeNodeLabelProp="label"
                      notFoundContent={getCurrentlocaleText(
                        "general.notfound.placeholder.text",
                      )}
                    />
                  </span>
                )}
                {/* Start Date Filter */}
                <span
                  className="listing-page-filter"
                  style={{ display: "inline-grid" }}
                >
                  <DatePicker
                    showTime={{
                      defaultValue: moment(),
                    }}
                    format="YYYY-MM-DD HH:mm:ss"
                    placeholder={getCurrentlocaleText(
                      "event.startdatefilter.placeholder",
                    )}
                    style={{ width: 120 }}
                    onChange={this.handleStartDateChange}
                    disabledDate={this.disabledDate}
                    title={getCurrentlocaleText(
                      "event.startdatefilter.placeholder",
                    )}
                  />
                  {this.state.isInvalidRange && (
                    <span className="invalid-range">
                      {getCurrentlocaleText(
                        "download.fromto.invalid_date_range.error.text",
                      )}
                    </span>
                  )}
                </span>
                {/* End Date Filter */}
                <span
                  className="listing-page-filter"
                  style={{ display: "inline-grid" }}
                >
                  <DatePicker
                    showTime={{
                      defaultValue: moment(),
                    }}
                    format="YYYY-MM-DD HH:mm:ss"
                    placeholder={getCurrentlocaleText(
                      "event.enddatefilter.placeholder",
                    )}
                    style={{ width: 120 }}
                    onChange={this.handleEndDateChange}
                    disabledDate={this.disabledDate}
                    title={getCurrentlocaleText(
                      "event.enddatefilter.placeholder",
                    )}
                  />
                  {this.state.isInvalidRange && (
                    <span className="invalid-range">
                      {getCurrentlocaleText(
                        "download.fromto.invalid_date_range.error_1.text",
                      )}
                    </span>
                  )}
                </span>
                {/* Apply button */}
                <span className="listing-page-btn">
                  <Button
                    type="primary"
                    className="act-button"
                    onClick={this.getEventList}
                    style={{ height: "-webkit-fill-available" }}
                    icon={<FilterOutlined />}
                    size="small"
                    title={getCurrentlocaleText(
                      "events.apply.button.title.text",
                    )}
                  >
                    <i className="activity-icon"> </i>
                  </Button>
                </span>
                {/* Refresh button */}
                <span className="listing-page-btn">
                  <Button
                    type="primary"
                    className="act-button"
                    style={{ height: "-webkit-fill-available" }}
                    icon={<ReloadOutlined />}
                    size="small"
                    onClick={this.refreshEvents}
                    title={getCurrentlocaleText(
                      "events.refresh.button.title.text",
                    )}
                  >
                    <i className="activity-icon"> </i>
                  </Button>
                </span>
              </div>
            </Col>
          </Row>
        </div>

        {/* Event List */}
        <div className="listing-page-data-container">
          <Row gutter={16} type="flex" align="middle">
            <Col span={24}>
              {this.eventModel.events.length > 0 ? (
                <TableLayoutV1
                  pagination={{
                    pageSize: this.eventModel && this.eventModel.pageable.size,
                    total:
                      this.eventModel && this.eventModel.pageable.total_count,
                    current: this.state.currentPage,
                  }}
                  columns={columns}
                  hideRowSelection={true}
                  dataSource={events}
                  getModalContent={record => {
                    return this.getModalContentJSX(record);
                  }}
                  onChange={this.onTableChange}
                  handleModalOk={e => this.handleModalOk(e)}
                  modalTitle={this.state.modalTitle}
                  modalContent={this.state.modalContent}
                  isTableModalToggle={isTableModalToggle}
                  isModalLoading={false}
                  disableFooter={true}
                  className="eventList"
                />
              ) : (
                <div>
                  {!this.eventModel.loading && (
                    <h3 style={{ paddingLeft: 24 }}>
                      {getCurrentlocaleText("noevents.text")}
                    </h3>
                  )}
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    );

    return (
      <LoadingComponent loading={this.eventModel.loading}>
        {container}
      </LoadingComponent>
    );
  }
}

export default EventList;
