import React, { Component } from "react";
import { Row, Col } from "antd";
import { Form } from "@ant-design/compatible";
import Input from "components/UI-Components/InputBox";
import propTypes from "prop-types";
import { focusFirstInput } from "Core/Utils";
import Icons from "./Icons";

const FormItem = Form.Item;

class EditableForminput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      editable: false,
    };
  }

  componentDidMount() {
    this.setState({ value: this.props.value });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ value: nextProps.value });
  }

  onSave = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (values[this.props.formItemName] !== this.state.value) {
          this.props.submit(values, this.props.field, this.props.extraField);
        }
        this.setState({ editable: false });
      }
    });
  };

  onEdit = () => {
    this.setState({ editable: true });
    // Focus editable input while edit
    focusFirstInput();
  };

  onClose = () => {
    this.setState({ editable: false });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        {this.state.editable ? (
          <div>
            <Row gutter={0}>
              <Col span={18} style={{ fontSize: 13 }}>
                <Form noValidate>
                  <FormItem
                    hasFeedback
                    style={{
                      display: "inline-block",
                      marginTop: "-7px",
                      width: "100%",
                    }}
                  >
                    {getFieldDecorator(this.props.formItemName, {
                      rules: this.props.rules,
                      initialValue: this.state.value,
                    })(
                      <Input
                        style={{ width: "100%" }}
                        className="firstInput"
                        type={this.props.type}
                        autoComplete="off"
                        prefix={
                          this.props.prefix ? this.props.prefix : undefined
                        }
                        placeholder={this.props.placeholder}
                        onPressEnter={this.onSave}
                      />,
                    )}
                  </FormItem>
                </Form>
              </Col>
              <Col span={6} style={{ fontSize: 13 }}>
                <span className="iot-pointer-cursor" onClick={this.onSave}>
                  <Icons
                    type="ai"
                    name="AiOutlineCheckCircle"
                    className="editable-input-icon valign-middle"
                    title="Save"
                  />
                </span>
                <span className="iot-pointer-cursor" onClick={this.onClose}>
                  <Icons
                    type="ai"
                    name="AiOutlineCloseCircle"
                    className="editable-input-icon valign-middle"
                    title="Close"
                  />
                </span>
              </Col>
            </Row>
          </div>
        ) : (
          <div style={{ height: 50 }}>
            {this.state.value || " "}
            <span onClick={this.onEdit} className="iot-pointer-cursor">
              <Icons
                type="ai"
                name="AiOutlineEdit"
                className="editable-input-icon valign-middle"
                title="Edit"
              />
            </span>
          </div>
        )}
      </div>
    );
  }
}

EditableForminput.propTypes = {
  type: propTypes.string.isRequired,
  placeholder: propTypes.string.isRequired,
  value: propTypes.string.isRequired,
  formItemName: propTypes.string.isRequired,
  submit: propTypes.any,
  field: propTypes.string,
  rules: propTypes.array,
};

const EditableFormInput = Form.create()(EditableForminput);
export default EditableFormInput;
