import React, { Component } from "react";
import { Link } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { Menu, Dropdown, Button } from "antd";
import Icons from "components/UI-Components/Icons";
import { getCurrentlocaleText } from "Core/Utils";
import TranslatedText from "components/UI-Components/TranslatedText";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const menuItem = {
  height: 40,
};
const iconStyle = {
  fontSize: 16,
  lineHeight: 1.3,
};
const menuItemStyle = {
  lineHeight: 1.5,
};

@inject("AuthStore", "ModelStore", "UiStore", "OrgViewModel")
@observer
class QuickActionDownloads extends Component {
  constructor(props) {
    super(props);
    // GLOBAL_SETTINGS configured from the node js
    this.GLOBAL_SETTINGS = GLOBAL_SETTINGS;
  }
  render() {
    const currentOrgId =
      this.props.OrgViewModel.org.id != undefined
        ? this.props.OrgViewModel.org.id
        : this.props.AuthStore.loggedInUser.organization
        ? this.props.AuthStore.loggedInUser.organization.id
        : "";

    let downloadSoftware = (
        <Menu.Item key="/help/releases" style={menuItem}>
          <Link to="/help/releases">
            <span>
              <Icons
                type="fa"
                name="FaBoxOpen"
                style={iconStyle}
                className={this.props.UiStore.isSiderFold && "middle"}
              />
              <span style={menuItemStyle}>
                {" "}
                <TranslatedText UItextID="release.navigation_menu.text" />
              </span>
            </span>
          </Link>
        </Menu.Item>
      ),
      downloadActivities = (
        <Menu.Item key="download-activities" style={menuItem}>
          <Link to={"/orgs/" + currentOrgId + "/activity/downloads"}>
            <span>
              <Icons
                type="md"
                name="MdEventNote"
                style={iconStyle}
                className={this.props.UiStore.isSiderFold && "middle"}
              />
              <Icons
                type="md"
                name="MdPerson"
                className={
                  !this.props.UiStore.isSiderFold
                    ? "activity-menu-icon"
                    : "activity-menu-icon-expanded"
                }
              />
              <span style={menuItemStyle}>
                {" "}
                <TranslatedText UItextID="download.activity.button.text" />
              </span>
            </span>
          </Link>
        </Menu.Item>
      ),
      downloadEvents = (
        <Menu.Item key="download-events" style={menuItem}>
          <Link to={"/orgs/" + currentOrgId + "/event/downloads"}>
            <span>
              <Icons
                type="md"
                name="MdEvent"
                style={iconStyle}
                className={this.props.UiStore.isSiderFold && "middle"}
              />
              <Icons
                type="fa"
                name="FaCog"
                className={
                  !this.props.UiStore.isSiderFold
                    ? "event-menu-icon"
                    : "event-menu-icon-expanded"
                }
              />
              <span style={menuItemStyle}>
                {" "}
                <TranslatedText UItextID="download.events.button.text" />
              </span>
            </span>
          </Link>
        </Menu.Item>
      );

    const downloadMenu = (
      <Menu
        key="newMneu"
        theme={
          this.GLOBAL_SETTINGS && this.GLOBAL_SETTINGS.sidebar_mode
            ? this.GLOBAL_SETTINGS.sidebar_mode
            : "dark"
        }
      >
        {this.props.AuthStore.IsPermitted("ACTIVITY:DOWNLOAD") &&
          downloadActivities}
        {this.props.AuthStore.IsPermitted("EVENTS:DOWNLOAD") && downloadEvents}
        {downloadSoftware}
      </Menu>
    );
    return (
      <span>
        {this.props.UiStore.isSiderFold ? (
          <Menu
            key="newMneu"
            mode="vertical"
            style={{
              marginLeft: "-70%",
            }}
            theme={
              this.GLOBAL_SETTINGS && this.GLOBAL_SETTINGS.sidebar_mode
                ? this.GLOBAL_SETTINGS.sidebar_mode
                : "dark"
            }
          >
            <SubMenu
              key="quick-action-download"
              title={
                <span>
                  <Button
                    className="quick-menu-button"
                    shape="circle"
                    size={"large"}
                    style={
                      !this.props.UiStore.isSiderFold ? { float: "left" } : {}
                    }
                  >
                    {" "}
                    <Icons
                      type="fa"
                      name="FaDownload"
                      style={{
                        fontSize: 20,
                      }}
                      className="middle quick-icon-style"
                    />
                  </Button>
                </span>
              }
            >
              {this.props.AuthStore.IsPermitted("ACTIVITY:DOWNLOAD") &&
                downloadActivities}
              {this.props.AuthStore.IsPermitted("EVENTS:DOWNLOAD") &&
                downloadEvents}
              {downloadSoftware}
            </SubMenu>
          </Menu>
        ) : (
          <Dropdown size="small" trigger={["click"]} overlay={downloadMenu}>
            <Button
              className="quick-menu-button"
              shape="circle"
              title={getCurrentlocaleText(
                "download.activity.download.button.text",
              )}
              size={"large"}
              style={!this.props.UiStore.isSiderFold ? { float: "left" } : {}}
            >
              {" "}
              <Icons
                type="fa"
                name="FaDownload"
                style={{
                  marginLeft: this.props.UiStore.isSiderFold ? 20 : 0,
                }}
                className="middle quick-icon-style"
              />
            </Button>
          </Dropdown>
        )}
      </span>
    );
  }
}
export default QuickActionDownloads;
