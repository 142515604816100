import React from "react";

class WanFirewallFunc {
  /**
   * Check for First Character
   */
  checkForAphanumericValue = value => {
    var regex = /^[a-z0-9]+$/i;
    if (!regex.test(value)) {
      return false;
    } else {
      return true;
    }
  };

  checForAllowedValue = value => {
    var regex = /^[-A-Za-z0-9_.]+$/i;
    if (!regex.test(value)) {
      return false;
    } else {
      return true;
    }
  };

  validateLabelInput = value => {
    if (
      !this.checkForAphanumericValue(value && String(value).substring(0, 1))
    ) {
      return false;
    }
    if (
      !this.checkForAphanumericValue(
        value && String(value).substring(value.length - 1, value.length),
      )
    ) {
      return false;
    }
    if (!this.checForAllowedValue(value)) {
      return false;
    }
    if (value.length > 63) {
      return false;
    } else {
      return true;
    }
  };
}
export default new WanFirewallFunc();
