import React, { Component } from "react";
import { Card, Row, Col, Table, Button, Tag } from "antd";
import { Form } from "@ant-design/compatible";
import { observer, inject } from "mobx-react";

import LoadingComponent from "components/UI-Components/LoadingComponent";
import BreadcrumbComponent from "components/UI-Components/BreadcrumbComponent";
import Icons from "components/UI-Components/Icons";

import OrgSelector from "components/Orgs/OrgSelector";
import RoleController from "controller/RoleController";

import { getCurrentlocaleText } from "Core/Utils";

@inject("ModelStore", "AuthStore", "UiStore", "RoleViewModel")
@observer
class UserRolesDetails extends Component {
  constructor(props) {
    super(props);
    this.roleModel = this.props.RoleViewModel;
    this.state = {
      BreadcrumbList: [
        {
          name: getCurrentlocaleText("general.left_navigation.all_users.text"),
          link: "orgs/" + this.props.match.params.id + "/roles",
        },
      ],
      roleDetails: {},
    };
  }
  UNSAFE_componentWillMount() {
    let role_id = this.props.match.params.role_id;
    if (this.props.match.params.role_id !== "create") {
      RoleController.getRole(role_id).then(role_data => {
        let UpdatedBreadCrumbList = [
          {
            name: role_data.organization.name,
            link: "orgs/" + role_data.organization.id + "/orgs",
          },
          {
            name: getCurrentlocaleText(
              "general.left_navigation.all_roles.text",
            ),
            link: "orgs/" + role_data.organization.id + "/roles",
          },
          {
            name: role_data.name,
            link:
              "orgs/" + role_data.organization.id + "/roles/" + role_data.id,
          },
        ];
        this.setState({
          roleDetails: role_data,
          BreadcrumbList: UpdatedBreadCrumbList,
        });
      });
    }
  }
  onCancel = () => {
    let role_info = this.state.roleDetails;
    this.props.history.push("orgs/" + role_info.organization.id + "/roles");
  };

  render() {
    let role_data = this.state.roleDetails;
    this.columns = [
      {
        title: getCurrentlocaleText(
          "roles.details.table.permission _name.text",
        ),
        dataIndex: "name",
        key: "name",
        render: (text, record, index) => {
          return (
            <div>
              <Tag>{record.permission}</Tag>
            </div>
          );
        },
      },
      {
        title: getCurrentlocaleText("support.description.text"),
        dataIndex: "description",
        key: "description",
        render: (text, record, index) => {
          return (
            <div>
              <p>{record.description}</p>
            </div>
          );
        },
      },
    ];
    let container = (
      <div>
        <Row gutter={16} type="flex" align="middle">
          <Col span={12}>
            <h2 className="page-title">
              <Icons type="ai" name="AiOutlineIdcard" />
              {"  "}
              {role_data.name}
            </h2>
            <BreadcrumbComponent
              {...this.props}
              BreadcrumbList={this.state.BreadcrumbList}
            />
          </Col>
          <Col span={12} className="flex flex-h-right">
            <OrgSelector {...this.props} />
          </Col>
        </Row>
        <div>
          <Row gutter={2} type="flex" align="top">
            <Card
              title={
                <span className="iot-main-card-title">
                  <Icons type="ai" name="AiOutlineIdcard" />
                  <span>
                    {getCurrentlocaleText("roles.details.card.title.text")}
                  </span>
                </span>
              }
            >
              <div style={{ width: 1550 }}>
                <Row gutter={0} type="flex" justify="left">
                  <Col className="node-details-card" span={4}>
                    <div>
                      <strong>
                        {/*getCurrentlocaleText("roles.details.name.label.text")*/}
                        {getCurrentlocaleText("roles.details.name.label.text")}
                      </strong>
                    </div>
                    <div>
                      <strong>
                        {getCurrentlocaleText("support.description.text")}
                      </strong>
                    </div>
                    <div>
                      <strong>
                        {getCurrentlocaleText(
                          "roles.details.user_count.label.text",
                        )}
                      </strong>
                    </div>
                    <div>
                      <strong>
                        {getCurrentlocaleText("roles.details.name.label.text")}
                      </strong>
                    </div>
                  </Col>
                  <Col className="node-details-card" span={12}>
                    <div>{role_data.name ? role_data.name : null}</div>
                    <div>
                      {role_data.description ? role_data.description : "-"}
                    </div>
                    <div>
                      {role_data.user_count
                        ? role_data.user_count > 0
                          ? role_data.user_count
                          : "-"
                        : "-"}
                    </div>
                  </Col>
                </Row>
                <Row gutter={0} type="flex" justify="left">
                  <Col span={24}>
                    <Table
                      size={"small"}
                      bordered={false}
                      columns={this.columns}
                      dataSource={role_data.permissions || []}
                      pagination={false}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24} push={6} className="col-center">
                    <div style={{ paddingTop: 15 }}>
                      <Button
                        onClick={this.onCancel}
                        style={{ marginRight: 10 }}
                        key="cancel"
                        icon={"rollback"}
                      >
                        {getCurrentlocaleText(
                          "roles.details.button.go_back.text",
                        )}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          </Row>
        </div>
      </div>
    );

    return (
      <LoadingComponent loading={this.roleModel.loading}>
        {container}
      </LoadingComponent>
    );
  }
}
const RoleDetails = Form.create()(UserRolesDetails);

export default RoleDetails;
