import React, { Component } from "react";
import { string, array, bool, func } from "prop-types";
import { Modal } from "antd";
import Icons from "components/UI-Components/Icons";

class VinetChangeConfirmDialog extends Component {
  static propTypes = {
    networkName: string,
    networkId: string,
    visible: bool,
    serviceNames: array,
    onOk: func,
    onCancel: func,
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      loading: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({ visible: this.props.visible });
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState({ visible: this.props.visible });
  }

  handleOk = e => {
    if (this.props.onOk) {
      this.props.onOk();
      this.setState({ loading: true });
    }
  };

  handleCancel = e => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  render() {
    return (
      <div>
        <Modal
          title={
            <span>
              <Icons type="fa" name="FaConnectdevelop" />
              &nbsp;Network Reconnect Needed
            </span>
          }
          visible={this.state.visible}
          okText={
            <span>
              <Icons type="ai" name="AiOutlineSync" /> Yes - Reconnect
            </span>
          }
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          closable={!this.state.loading}
          confirmLoading={this.state.loading}
        >
          <p>
            iNode has been updated since the first remote network was connected.
            To connect a second remote network, the first remote network will
            need to be momentarily disconnected and reconnected.
          </p>
        </Modal>
      </div>
    );
  }
}

export default VinetChangeConfirmDialog;
