import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Row, Col } from "antd";
import BreadcrumbComponent from "components/UI-Components/BreadcrumbComponent";
import Icons from "components/UI-Components/Icons";
import ApiKeyList from "components/UserApiKeys/ApiKeyList";
import { getCurrentlocaleText } from "Core/Utils";
import UserAPIKeyController from "controller/UserAPIKeyController";
import UserController from "controller/UserController";

@inject(
  "UserViewModel",
  "UiStore",
  "ModelStore",
  "AuthStore",
  "TimezoneViewModel",
)
@observer
class UserApiKeyView extends Component {
  constructor(props) {
    super(props);
    this.userModel = this.props.UserViewModel;
    this.modelStore = this.props.ModelStore;
    this.uiStore = this.props.UiStore;
    this.authStore = this.props.AuthStore;
    this.state = {
      BreadcrumbList: [
        {
          name: getCurrentlocaleText("dashboard.counts.users.tooltip"),
          link: "orgs/" + this.props.match.params.id + "/users",
        },
      ],
      userInfo: {},
    };
  }

  componentDidMount() {
    let user_id = this.props.match.params.user_id;
    UserAPIKeyController.getUserKeys(user_id, true)
      .then(resp => {})
      .catch(error => {});
    UserController.getUser(user_id).then(result => {
      let BreadcrumbList = this.state.BreadcrumbList;
      BreadcrumbList.splice(2, 0, {
        name: result.name,
        link:
          "orgs/" +
          this.props.match.params.id +
          "/usersdetails/" +
          this.props.match.params.user_id,
      });
      BreadcrumbList.splice(0, 0, {
        name: result.organization.name,
        link: "orgs/" + result.organization.id + "/orgs",
      });

      BreadcrumbList.push({
        name: getCurrentlocaleText("user.api_keys.leftmenu.text"),
        link:
          "orgs/" +
          this.props.match.params.id +
          "/users/keys/" +
          this.props.match.params.user_id,
      });

      this.setState({ userInfo: result, BreadcrumbList: BreadcrumbList });
    });
  }

  render() {
    return (
      <div>
        <Row gutter={24} type="flex" align="middle">
          <Col span={24}>
            <h2 className="page-title">
              {
                <span
                  style={{
                    fontSize: 10,
                    marginRight: 2,
                    display: "block",
                    marginBottom: -13,
                  }}
                >
                  {"API"}
                </span>
              }
              <Icons
                type="md"
                name="MdVpnKey"
                style={{ fontSize: 20, marginRight: 8, marginLeft: -1 }}
                className="valign-middle"
              />
              {"  "}
              {getCurrentlocaleText("user.api_keys.leftmenu.text")}
            </h2>
            <BreadcrumbComponent
              {...this.props}
              BreadcrumbList={this.state.BreadcrumbList}
            />
          </Col>
        </Row>
        <div style={{ minHeight: 360 }}>
          <Row gutter={16} type="flex" align="middle">
            <Col span={24}>
              <ApiKeyList
                isMe={false}
                userId={this.props.match.params.user_id}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default UserApiKeyView;
