import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Form, Select, Input, Row, Col } from "antd";
import AlertDuration from "components/Alerts/components/AlertDuration";
import {
  getCurrentlocaleText,
  ALERT,
  isEmpty,
  focusFirstInput,
  isDevelopmentMode,
  customSort,
} from "Core/Utils";
import AlertController from "controller/AlertController";

const InputGroup = Input.Group;
const FormItem = Form.Item;
const Option = Select.Option;
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

@inject("UiStore", "AuthStore")
@observer
class AlertConditionItems extends Component {
  constructor(props) {
    super(props);
    this.UiStore = this.props.UiStore;
    this.state = {
      avilableAlerts: {},
      formItem: {
        name: undefined,
        type: undefined,
        status: [],
        duration: { duration: 5, units: ALERT.DURATION.MINUTES },
        hideDuration: false,
        isHeadlessType: false,
        isNodeUpgradeType: false,
        isNodeMetricsType: false,
        isAggregatedRouteType: false,
        hideAlertMe: false,
        isDadType: false,
        isCTDType: false,
      },
      status: [],
    };
  }

  UNSAFE_componentWillMount() {
    // Get Available alerts
    AlertController.getAvailableAlerts().then(response => {
      if (response && response.available_alerts) {
        this.setState({ avilableAlerts: response.available_alert_types });
      }
    });
  }

  componentDidMount() {
    // Focus first input
    focusFirstInput();
    if (
      this.UiStore.alertFormData &&
      !isEmpty(this.UiStore.getAlertCondition)
    ) {
      let condition = this.UiStore.getAlertCondition;
      // Set Alert type, status, duration
      let formItem = this.state.formItem;
      formItem.name = condition.name;
      formItem.type = condition.type;
      formItem.status = condition.status
        ? condition.status.map(x => {
            return x;
          })
        : [];
      if (this.props.formType !== "add") {
        let options = [];

        if (
          formItem.type === ALERT.TYPE.NODE_METRICS &&
          condition.status.length > 0 &&
          !condition.status[0].value
        ) {
          // this is for cpu, memory and fs
          options = condition.status
            ? condition.status.map(x => {
                let option = {};
                option.value = x;
                option.operator =
                  x != ALERT.STATUS.NODE_METRICS_VALUES.OFF
                    ? ALERT.OPERATOR.ABOVE
                    : ALERT.OPERATOR.NONE;
                return option;
              })
            : [];

          formItem.status = options;
        }
      }
      if (typeof condition.duration == "object") {
        formItem.duration = condition.duration;
      } else {
        formItem.duration.duration = condition.duration;
      }
      let data = {
        name: {
          value: formItem.name,
        },
        type: {
          value: formItem.type,
        },
        status: {
          value: formItem.status,
        },
        duration: {
          value: formItem.duration,
        },
      };
      // check for headless type and hide duration
      if (condition.type === ALERT.TYPE.HEADLESS_EXPIRY) {
        delete formItem.duration;
        delete data.duration;
        this.setState({
          hideDuration: true,
          isHeadlessType: true,
          status: formItem.status,
        });
        this.updateHelp({
          title: getCurrentlocaleText("managealerts.condition.text"),
          data: [
            {
              subtitle: "",
              content: getCurrentlocaleText(
                "alertform.condition.non_duration_headless.help.text",
              ),
            },
          ],
        });
      } else if (condition.type === ALERT.TYPE.NODE_UPGRADE) {
        delete formItem.duration;
        delete data.duration;
        this.setState({
          hideDuration: true,
          isHeadlessType: false,
          isCTDType: false,
          isNodeUpgradeType: true,
          isNodeMetricsType: false,
          isAggregatedRouteType: false,
          status: formItem.status,
        });
        this.updateHelp({
          title: getCurrentlocaleText("managealerts.condition.text"),
          data: [
            {
              subtitle: "",
              content: getCurrentlocaleText(
                "alertform.condition.non_duration_node_upgrade.help.text",
              ),
            },
          ],
        });
      } else if (condition.type === ALERT.TYPE.NODE_METRICS) {
        this.setState({
          hideDuration: false,
          isCTDType: false,
          isHeadlessType: false,
          isNodeUpgradeType: false,
          isNodeMetricsType: true,
          isAggregatedRouteType: false,
          status: formItem.status,
        });
        this.updateHelp({
          title: getCurrentlocaleText("managealerts.condition.text"),
          data: [
            {
              subtitle: "",
              content: getCurrentlocaleText(
                "alertform.condition.non_duration_node_metrics.help.text",
              ),
            },
          ],
        });
      } else if (condition.type === ALERT.TYPE.CERT_EXPIRY) {
        // check for headless type and hide duration
        delete formItem.duration;
        delete data.duration;
        this.setState({
          hideDuration: true,
          isCTDType: false,
          isHeadlessType: false,
          isNodeUpgradeType: false,
          isNodeMetricsType: false,
          isAggregatedRouteType: false,
          status: formItem.status,
        });
        this.updateHelp({
          title: getCurrentlocaleText("managealerts.condition.text"),
          data: [
            {
              subtitle: "",
              content: getCurrentlocaleText(
                "alertform.condition.non_duration_credentials.help.text",
              ),
            },
          ],
        });
      } else if (condition.type === ALERT.TYPE.AGGREGATED_ROUTE_CHANGE) {
        // check for headless type and hide duration
        delete formItem.duration;
        delete data.duration;
        this.setState({
          hideDuration: true,
          isCTDType: false,
          isHeadlessType: false,
          isNodeUpgradeType: false,
          isNodeMetricsType: false,
          isAggregatedRouteType: false,
          status: formItem.status,
        });
        this.updateHelp({
          title: getCurrentlocaleText("managealerts.condition.text"),
          data: [
            {
              subtitle: "",
              content: getCurrentlocaleText(
                "alertform.condition.non_duration_credentials.help.text",
              ),
            },
          ],
        });
      } else if (condition.type === ALERT.TYPE.DUPLICATE_ADDRESS_DETECTION) {
        // check for headless type and hide duration
        delete formItem.duration;
        delete data.duration;
        this.setState({
          hideDuration: true,
          hideAlertMe: true,
          isDadType: true,
          isCTDType: false,
          isHeadlessType: false,
          isNodeUpgradeType: false,
          isNodeMetricsType: false,
          isAggregatedRouteType: false,
          status: formItem.status,
        });
        this.updateHelp({
          title: getCurrentlocaleText("managealerts.condition.text"),
          data: [
            {
              subtitle: "",
              content: getCurrentlocaleText(
                "alertform.condition.non_duration_duplicate_address_detection.help.text",
              ),
            },
          ],
        });
      } else if (condition.type === ALERT.TYPE.THREAT_INTELLIGENCE) {
        // check for headless type and hide duration
        delete formItem.duration;
        delete data.duration;
        this.setState({
          hideDuration: true,
          hideAlertMe: true,
          isDadType: false,
          isCTDType: true,
          isHeadlessType: false,
          isNodeUpgradeType: false,
          isNodeMetricsType: false,
          isAggregatedRouteType: false,
          status: formItem.status,
        });
        this.updateHelp({
          title: getCurrentlocaleText("managealerts.condition.text"),
          data: [
            {
              subtitle: "",
              content: getCurrentlocaleText(
                "alertform.condition.non_duration_continuous_threat_detection.help.text",
              ),
            },
          ],
        });
      } else {
        this.setState({
          hideDuration: false,
          isHeadlessType: false,
          isNodeUpgradeType: false,
          isNodeMetricsType: false,
          isAggregatedRouteType: false,
          status: formItem.status,
        });
        // Update help content
        setTimeout(() => {
          let elm = document.querySelector(
            ".alert-form .help-container .help-content",
          );
          if (elm) {
            elm.innerHTML = getCurrentlocaleText(
              "alertform.condition.help.text",
            );
          }
        }, 50);
      }
      this.props.formReference.setFields(data);
    } else {
      // Update help text
      this.updateHelp({
        title: getCurrentlocaleText("managealerts.condition.text"),
        data: [
          {
            subtitle: "",
            content: "",
          },
        ],
      });
      // Update help content
      setTimeout(() => {
        let elm = document.querySelector(
          ".alert-form .help-container .help-content",
        );
        if (elm) {
          elm.innerHTML = getCurrentlocaleText("alertform.condition.help.text");
        }
      }, 50);
    }
  }

  handleTypeChange = value => {
    let formItem = this.state.formItem;
    formItem.type = value;
    formItem.status = this.getDefaultStatus();
    formItem.duration = { duration: 5, units: ALERT.DURATION.MINUTES };
    let data = {
      type: {
        value: formItem.type,
      },
      status: {
        value: formItem.status,
      },
      duration: {
        value: formItem.duration,
      },
    };
    if (value === ALERT.TYPE.HEADLESS_EXPIRY) {
      this.setState({
        hideDuration: true,
        isHeadlessType: true,
        isNodeUpgradeType: false,
        isNodeMetricsType: false,
        isAggregatedRouteType: false,
        hideAlertMe: false,
      });
      delete formItem.duration;
      delete data.duration;
      this.updateHelp({
        title: getCurrentlocaleText("managealerts.condition.text"),
        data: [
          {
            subtitle: "",
            content: getCurrentlocaleText(
              "alertform.condition.non_duration_headless.help.text",
            ),
          },
        ],
      });
    } else if (value === ALERT.TYPE.CERT_EXPIRY) {
      this.setState({
        hideDuration: true,
        isHeadlessType: false,
        isNodeUpgradeType: false,
        isNodeMetricsType: false,
        isAggregatedRouteType: false,
        hideAlertMe: false,
      });
      delete formItem.duration;
      delete data.duration;
      this.updateHelp({
        title: getCurrentlocaleText("managealerts.condition.text"),
        data: [
          {
            subtitle: "",
            content: getCurrentlocaleText(
              "alertform.condition.non_duration_credentials.help.text",
            ),
          },
        ],
      });
    } else if (value === ALERT.TYPE.NODE_UPGRADE) {
      this.setState({
        hideDuration: true,
        isHeadlessType: false,
        isNodeUpgradeType: true,
        isNodeMetricsType: false,
        isAggregatedRouteType: false,
        hideAlertMe: false,
      });
      delete formItem.duration;
      delete data.duration;
      this.updateHelp({
        title: getCurrentlocaleText("managealerts.condition.text"),
        data: [
          {
            subtitle: "",
            content: getCurrentlocaleText(
              "alertform.condition.non_duration_node_upgrade.help.text",
            ),
          },
        ],
      });
    } else if (value === ALERT.TYPE.AGGREGATED_ROUTE_CHANGE) {
      this.setState({
        hideDuration: true,
        isHeadlessType: false,
        isNodeUpgradeType: false,
        isNodeMetricsType: false,
        isAggregatedRouteType: true,
      });
      delete formItem.duration;
      delete data.duration;
      this.updateHelp({
        title: getCurrentlocaleText("managealerts.condition.text"),
        data: [
          {
            subtitle: "",
            content: getCurrentlocaleText(
              "alertform.condition.non_duration_aggregated_route_change.help.text",
            ),
          },
        ],
      });
    } else if (value === ALERT.TYPE.DUPLICATE_ADDRESS_DETECTION) {
      this.setState({
        hideDuration: true,
        isDadType: true,
        isCTDType: false,
        isHeadlessType: false,
        isNodeUpgradeType: false,
        isNodeMetricsType: false,
        isAggregatedRouteType: false,
        hideAlertMe: true,
      });
      delete formItem.duration;
      delete data.duration;
      this.updateHelp({
        title: getCurrentlocaleText("managealerts.condition.text"),
        data: [
          {
            subtitle: "",
            content: getCurrentlocaleText(
              "alertform.condition.non_duration_duplicate_address_detection.help.text",
            ),
          },
        ],
      });
    } else if (value === ALERT.TYPE.THREAT_INTELLIGENCE) {
      this.setState({
        hideDuration: true,
        isDadType: false,
        isCTDType: true,
        isHeadlessType: false,
        isNodeUpgradeType: false,
        isNodeMetricsType: false,
        isAggregatedRouteType: false,
        hideAlertMe: true,
      });
      delete formItem.duration;
      delete data.duration;
      this.updateHelp({
        title: getCurrentlocaleText("managealerts.condition.text"),
        data: [
          {
            subtitle: "",
            content: getCurrentlocaleText(
              "alertform.condition.non_duration_continuous_threat_detection.help.text",
            ),
          },
        ],
      });
    } else if (value === ALERT.TYPE.NODE_METRICS) {
      this.setState({
        hideDuration: false,
        isHeadlessType: false,
        isNodeUpgradeType: false,
        isNodeMetricsType: true,
        isAggregatedRouteType: false,
        hideAlertMe: false,
      });

      this.updateHelp({
        title: getCurrentlocaleText("managealerts.condition.text"),
        data: [
          {
            subtitle: "",
            content: getCurrentlocaleText(
              "alertform.condition.non_duration_node_metrics.help.text",
            ),
          },
        ],
      });
    } else {
      this.setState({
        hideDuration: false,
        isHeadlessType: false,
        isNodeUpgradeType: false,
        isNodeMetricsType: false,
        isAggregatedRouteType: false,
        hideAlertMe: false,
      });
      this.updateHelp({
        title: getCurrentlocaleText("managealerts.condition.text"),
        data: [
          {
            subtitle: "",
            content: getCurrentlocaleText("alertform.condition.help.text"),
          },
        ],
      });
    }
    this.setState({ formItem, status: formItem.status }, () => {
      // reset data
      this.props.formReference.setFields(data);
    });
  };

  handleDurationChange = value => {
    let formItem = this.state.formItem;
    formItem.duration = value;
    // reset data
    this.props.formReference.setFields({
      duration: {
        value: formItem.duration,
      },
    });
  };

  validateDuration = (rule, value, callback) => {
    if (value && value.duration) {
      if (value.duration % 1 !== 0) {
        callback("Invalid input.");
      }
    }
    callback();
  };

  updateHelp = data => {
    this.UiStore.SetStoreData("helpData", data);
    // Update help content
    setTimeout(() => {
      let elm = document.querySelector(
        ".alert-form .help-container .help-content",
      );
      if (elm) {
        elm.innerHTML =
          data && data.data && data.data[0] && data.data[0].content
            ? data.data[0].content
            : "";
      }
    }, 10);
  };

  renderAlertTypeOptions = () => {
    let options = [],
      alerts = this.state.avilableAlerts;
    if (alerts.length > 0) {
      alerts.forEach(alert => {
        if (
          alert.type.includes(ALERT.TYPE.HEADLESS_EXPIRY) &&
          (this.props.AuthStore.IsPermitted("NODE:CREATE") ||
            this.props.AuthStore.IsPermitted("ORG:SUPPORT"))
        ) {
          options.push({
            label: alert.display_type,
            value: alert.type,
          });
        } else if (!alert.type.includes(ALERT.TYPE.HEADLESS_EXPIRY)) {
          options.push({
            label: alert.display_type,
            value: alert.type,
          });
        }
      });
    }
    // Sort alert type based on labels (display text) instead of option value
    options.sort((a, b) => customSort(a.label, b.label));
    return options.map(option => {
      return (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      );
    });
  };

  renderStatusOptions = () => {
    let formItem = this.state.formItem;
    let options = this.getDefaultStatus();
    return options.map(option => {
      let label =
        formItem.type === ALERT.TYPE.TUNNEL_STATE_CHANGE &&
        option === ALERT.STATUS.TUNNEL_STATE_CHANGE.TERMINATED
          ? "TERMINATED"
          : option === ALERT.STATUS.HEADLESS_EXPIRY.REACHED_80
          ? "EXPIRES SOON"
          : option === ALERT.STATUS.HEADLESS_EXPIRY.EXPIRED
          ? "EXPIRED"
          : option === ALERT.STATUS.NODE_IP_CHANGE.PUBLIC_IP
          ? "PUBLIC IP"
          : option;
      if (formItem.type === ALERT.TYPE.CERT_EXPIRY) {
        label = Object.keys(ALERT.STATUS.CERT_EXPIRY).find(
          key => ALERT.STATUS.CERT_EXPIRY[key] === option,
        );
      }

      return (
        <Option key={option} value={option}>
          {label.replace(new RegExp("_", "g"), " ")}
        </Option>
      );
    });
  };

  renderNodeMetricsOptions = () => {
    let formItem = this.state.formItem;
    let options = this.getDefaultNodeMetrics();
    return options.map(option => {
      let label =
        formItem.type === ALERT.TYPE.NODE_METRICS &&
        option === ALERT.STATUS.NODE_METRICS_VALUES.ABOVE_70
          ? "70%"
          : option === ALERT.STATUS.NODE_METRICS_VALUES.ABOVE_80
          ? "80%"
          : option === ALERT.STATUS.NODE_METRICS_VALUES.ABOVE_90
          ? "90%"
          : option === ALERT.STATUS.NODE_METRICS_VALUES.ABOVE_99
          ? "99%"
          : option;

      return (
        <Option key={option} value={option}>
          {label.replace(new RegExp("_", "g"), " ")}
        </Option>
      );
    });
  };

  getDefaultStatus = () => {
    let formItem = this.state.formItem;
    let options = [];
    if (formItem.type === ALERT.TYPE.NODE_STATE_CHANGE) {
      options = [
        ALERT.STATUS.NODE_STATE_CHANGE.ALIVE,
        ALERT.STATUS.NODE_STATE_CHANGE.UNREACHABLE,
      ];
    } else if (formItem.type === ALERT.TYPE.TUNNEL_STATE_CHANGE) {
      // LAT-6211 Tunnel & pod status should be in development mode
      options = [
        ALERT.STATUS.TUNNEL_STATE_CHANGE.CONNECTED,
        ALERT.STATUS.TUNNEL_STATE_CHANGE.TERMINATED,
      ];
    } else if (formItem.type === ALERT.TYPE.SERVICE_STATE_CHANGE) {
      // LAT-6211 Tunnel & pod status should be in development mode
      options = [
        ALERT.STATUS.SERVICE_STATE_CHANGE.HEALTHY,
        ALERT.STATUS.SERVICE_STATE_CHANGE.UNHEALTHY,
        ALERT.STATUS.SERVICE_STATE_CHANGE.TERMINATED,
      ];
    } else if (formItem.type === ALERT.TYPE.HEADLESS_EXPIRY) {
      options = [
        ALERT.STATUS.HEADLESS_EXPIRY.EXPIRED,
        ALERT.STATUS.HEADLESS_EXPIRY.REACHED_80,
      ];
    } else if (formItem.type === ALERT.TYPE.CERT_EXPIRY) {
      options = [
        ALERT.STATUS.CERT_EXPIRY.EXPIRED,
        ALERT.STATUS.CERT_EXPIRY.EXPIRES_IN_90_DAYS,
        ALERT.STATUS.CERT_EXPIRY.EXPIRES_IN_45_DAYS,
        ALERT.STATUS.CERT_EXPIRY.EXPIRES_IN_30_DAYS,
        ALERT.STATUS.CERT_EXPIRY.EXPIRES_IN_15_DAYS,
      ];
    } else if (formItem.type === ALERT.TYPE.NODE_IP_CHANGE) {
      options = [
        ALERT.STATUS.NODE_IP_CHANGE.PRIVATE_IP,
        ALERT.STATUS.NODE_IP_CHANGE.PUBLIC_IP,
      ];
    } else if (formItem.type === ALERT.TYPE.AGGREGATED_ROUTE_CHANGE) {
      options = [
        ALERT.STATUS.AGGREGATED_ROUTE_CHANGE.ADD,
        ALERT.STATUS.AGGREGATED_ROUTE_CHANGE.DELETE,
      ];
    } else if (formItem.type === ALERT.TYPE.NODE_UPGRADE) {
      if (isDevelopmentMode()) {
        options = [
          ALERT.STATUS.NODE_UPGRADE.ENABLED,
          ALERT.STATUS.NODE_UPGRADE.DISABLED,
          ALERT.STATUS.NODE_UPGRADE.SUCCESSFUL,
          ALERT.STATUS.NODE_UPGRADE.FAILED,
        ];
      } else {
        options = [
          ALERT.STATUS.NODE_UPGRADE.SUCCESSFUL,
          ALERT.STATUS.NODE_UPGRADE.FAILED,
        ];
      }
    } else if (formItem.type === ALERT.TYPE.NODE_METRICS) {
      // this is for cpu, memory and fs
      options = [
        {
          operator: ALERT.OPERATOR.ABOVE,
          value: ALERT.STATUS.NODE_METRICS_VALUES.ABOVE_80,
        },
        {
          operator: ALERT.OPERATOR.ABOVE,
          value: ALERT.STATUS.NODE_METRICS_VALUES.ABOVE_80,
        },
        {
          operator: ALERT.OPERATOR.ABOVE,
          value: ALERT.STATUS.NODE_METRICS_VALUES.ABOVE_80,
        },
      ];
    }
    return options;
  };

  getDefaultNodeMetrics = () => {
    let options = [];
    options = [
      ALERT.STATUS.NODE_METRICS_VALUES.ABOVE_70,
      ALERT.STATUS.NODE_METRICS_VALUES.ABOVE_80,
      ALERT.STATUS.NODE_METRICS_VALUES.ABOVE_90,
      ALERT.STATUS.NODE_METRICS_VALUES.ABOVE_99,
    ];
    return options;
  };

  validateStatus = (rule, value, callback) => {
    if (value && value.length <= 0) {
      callback(getCurrentlocaleText("alertform.status.required.message.text"));
    } else {
      let numberOfNone = 0;
      value.forEach(status => {
        if (status.operator && status.operator === ALERT.OPERATOR.NONE) {
          numberOfNone++;
        }
      });
      if (numberOfNone == 3) {
        callback(
          getCurrentlocaleText("alertform.status.required.message.text"),
        );
      }
    }
    callback();
  };

  StatusAddOn = () => {
    return (
      <span>
        <InputGroup>
          <Row gutter={2} type="flex" align="top">
            <Col span={6}>
              <Select
                disabled={this.props.formType == "view" || this.props.isDefault}
                defaultValue={"Equal to"}
                className="alert-conditional-selector"
              >
                <Option value={"Equal to"}>{"Equal to"}</Option>
              </Select>
            </Col>
            <Col span={18}>
              <Select
                disabled={this.props.formType == "view" || this.props.isDefault}
                className="alert-status-selector"
                mode="multiple"
                placeholder={getCurrentlocaleText(
                  "alertform.status.placeholder.text",
                )}
                value={this.state.status}
                onChange={value => {
                  let data = {
                    status: {
                      value: value,
                    },
                  };
                  this.props.formReference.setFields(data);
                  this.setState({ status: value });
                }}
                onFocus={this.updateHelp.bind(this, {
                  title: getCurrentlocaleText("managealerts.condition.text"),
                  data: [
                    {
                      subtitle: "",
                      content: this.state.hideDuration
                        ? this.state.isHeadlessType
                          ? getCurrentlocaleText(
                              "alertform.condition.non_duration_headless.help.text",
                            )
                          : this.state.isNodeUpgradeType
                          ? getCurrentlocaleText(
                              "alertform.condition.non_duration_node_upgrade.help.text",
                            )
                          : this.state.isAggregatedRouteType
                          ? getCurrentlocaleText(
                              "alertform.condition.non_duration_aggregated_route_change.help.text",
                            )
                          : this.state.isDadType
                          ? getCurrentlocaleText(
                              "alertform.condition.non_duration_duplicate_address_detection.help.text",
                            )
                          : this.state.isCTDType
                          ? getCurrentlocaleText(
                              "alertform.condition.non_duration_continuous_threat_detection.help.text",
                            )
                          : getCurrentlocaleText(
                              "alertform.condition.non_duration_credentials.help.text",
                            )
                        : getCurrentlocaleText("alertform.condition.help.text"),
                    },
                  ],
                })}
              >
                {this.renderStatusOptions()}
              </Select>
            </Col>
          </Row>
        </InputGroup>
      </span>
    );
  };

  NodeMetricsStatusAddOn = () => {
    let nodeMetricsArray = this.state.status;
    let nodeMetricsOptions = this.renderNodeMetricsOptions();
    return (
      <span>
        <InputGroup>
          <Row gutter={2} type="flex" align="top">
            <Col span={6} style={{ paddingTop: 7 }}>
              <span>
                {getCurrentlocaleText(
                  "alertform.alerttype.nodemetrics.cpu.text",
                )}{" "}
                :
              </span>
            </Col>
            <Col span={6}>
              <Select
                disabled={this.props.formType == "view" || this.props.isDefault}
                value={nodeMetricsArray[0].operator}
                className="alert-conditional-selector"
                onChange={value => {
                  nodeMetricsArray[0].operator = value;
                  // Set default value of cpu 80%
                  if (value === ALERT.OPERATOR.NONE) {
                    nodeMetricsArray[0].value =
                      ALERT.STATUS.NODE_METRICS_VALUES.ABOVE_80;
                  }
                  let data = {
                    status: {
                      value: nodeMetricsArray,
                    },
                  };
                  this.props.formReference.setFields(data);
                  this.setState({ status: nodeMetricsArray });
                }}
              >
                <Option value={ALERT.OPERATOR.ABOVE}>
                  {getCurrentlocaleText("alerts-conditions-above-text")}
                </Option>
                <Option value={ALERT.OPERATOR.NONE}>
                  {getCurrentlocaleText("alerts-conditions-none-text")}
                </Option>
              </Select>
            </Col>
            <Col span={12}>
              <Row>
                {this.state.status &&
                  this.state.status.length !== 0 &&
                  this.state.status[0] &&
                  this.state.status[0].operator !== ALERT.OPERATOR.NONE && (
                    <Col span={12}>
                      <Select
                        disabled={
                          this.props.formType == "view" || this.props.isDefault
                        }
                        className="alert-status-selector"
                        value={nodeMetricsArray[0].value}
                        onChange={value => {
                          nodeMetricsArray[0].value = value;
                          let data = {
                            status: {
                              value: nodeMetricsArray,
                            },
                          };
                          this.props.formReference.setFields(data);
                          this.setState({ status: nodeMetricsArray });
                        }}
                      >
                        {nodeMetricsOptions}
                      </Select>
                    </Col>
                  )}
                {this.state.status &&
                this.state.status.length !== 0 &&
                this.state.status[0] &&
                this.state.status[0].operator !== ALERT.OPERATOR.NONE &&
                ((this.state.status[1] &&
                  this.state.status[1].operator !== ALERT.OPERATOR.NONE) ||
                  (this.state.status[2] &&
                    this.state.status[2].operator !== ALERT.OPERATOR.NONE)) ? (
                  <Col span={4} style={{ paddingTop: 7 }}>
                    <span style={{ paddingLeft: 7 }}>
                      {getCurrentlocaleText(
                        "alertform.alerttype.nodemetrics.or.text",
                      )}
                    </span>
                  </Col>
                ) : null}
              </Row>
            </Col>
          </Row>
          <Row gutter={2} type="flex" align="top">
            <Col span={6} style={{ paddingTop: 7 }}>
              <span>
                {getCurrentlocaleText(
                  "alertform.alerttype.nodemetrics.memory.text",
                )}{" "}
                :
              </span>
            </Col>
            <Col span={6}>
              <Select
                disabled={this.props.formType == "view" || this.props.isDefault}
                value={nodeMetricsArray[1].operator}
                className="alert-conditional-selector"
                onChange={value => {
                  nodeMetricsArray[1].operator = value;
                  // Set default value of memory 80%
                  if (value === ALERT.OPERATOR.NONE) {
                    nodeMetricsArray[1].value =
                      ALERT.STATUS.NODE_METRICS_VALUES.ABOVE_80;
                  }
                  let data = {
                    status: {
                      value: nodeMetricsArray,
                    },
                  };
                  this.props.formReference.setFields(data);
                  this.setState({ status: nodeMetricsArray });
                }}
              >
                <Option value={ALERT.OPERATOR.ABOVE}>
                  {getCurrentlocaleText("alerts-conditions-above-text")}
                </Option>
                <Option value={ALERT.OPERATOR.NONE}>
                  {getCurrentlocaleText("alerts-conditions-none-text")}
                </Option>
              </Select>
            </Col>
            <Col span={12}>
              <Row>
                {this.state.status &&
                  this.state.status.length !== 0 &&
                  this.state.status[1] &&
                  this.state.status[1].operator !== ALERT.OPERATOR.NONE && (
                    <Col span={12}>
                      <Select
                        disabled={
                          this.props.formType == "view" || this.props.isDefault
                        }
                        className="alert-status-selector"
                        value={nodeMetricsArray[1].value}
                        onChange={value => {
                          nodeMetricsArray[1].value = value;
                          let data = {
                            status: {
                              value: nodeMetricsArray,
                            },
                          };
                          this.props.formReference.setFields(data);
                          this.setState({ status: nodeMetricsArray });
                        }}
                      >
                        {nodeMetricsOptions}
                      </Select>
                    </Col>
                  )}
                {this.state.status &&
                this.state.status.length !== 0 &&
                this.state.status[1] &&
                this.state.status[1].operator !== ALERT.OPERATOR.NONE &&
                this.state.status[2] &&
                this.state.status[2].operator !== ALERT.OPERATOR.NONE ? (
                  <Col span={4} style={{ paddingTop: 7 }}>
                    <span style={{ paddingLeft: 7 }}>
                      {getCurrentlocaleText(
                        "alertform.alerttype.nodemetrics.or.text",
                      )}
                    </span>
                  </Col>
                ) : null}
              </Row>
            </Col>
          </Row>
          <Row gutter={2} type="flex" align="top">
            <Col span={6} style={{ paddingTop: 7 }}>
              <span>
                {getCurrentlocaleText(
                  "alertform.alerttype.nodemetrics.filesystem.text",
                )}{" "}
                :
              </span>
            </Col>
            <Col span={6}>
              <Select
                disabled={this.props.formType == "view" || this.props.isDefault}
                value={nodeMetricsArray[2].operator}
                className="alert-conditional-selector"
                onChange={value => {
                  nodeMetricsArray[2].operator = value;
                  // Set default value of file system 80%
                  if (value === ALERT.OPERATOR.NONE) {
                    nodeMetricsArray[2].value =
                      ALERT.STATUS.NODE_METRICS_VALUES.ABOVE_80;
                  }
                  let data = {
                    status: {
                      value: nodeMetricsArray,
                    },
                  };
                  this.props.formReference.setFields(data);
                  this.setState({ status: nodeMetricsArray });
                }}
              >
                <Option value={ALERT.OPERATOR.ABOVE}>
                  {getCurrentlocaleText("alerts-conditions-above-text")}
                </Option>
                <Option value={ALERT.OPERATOR.NONE}>
                  {getCurrentlocaleText("alerts-conditions-none-text")}
                </Option>
              </Select>
            </Col>
            {this.state.status &&
              this.state.status.length !== 0 &&
              this.state.status[2] &&
              this.state.status[2].operator !== ALERT.OPERATOR.NONE && (
                <Col span={6}>
                  <Select
                    disabled={
                      this.props.formType == "view" || this.props.isDefault
                    }
                    className="alert-status-selector"
                    value={nodeMetricsArray[2].value}
                    onChange={value => {
                      nodeMetricsArray[2].value = value;
                      let data = {
                        status: {
                          value: nodeMetricsArray,
                        },
                      };
                      this.props.formReference.setFields(data);
                      this.setState({ status: nodeMetricsArray });
                    }}
                  >
                    {nodeMetricsOptions}
                  </Select>
                </Col>
              )}
          </Row>
        </InputGroup>
      </span>
    );
  };

  render() {
    let { getFieldDecorator } = this.props.formReference;
    let { formItem } = this.state;
    return (
      <div className="alert-form-container">
        <FormItem
          label={
            <span>{getCurrentlocaleText("alertform.alerttype.text")}</span>
          }
          {...formItemLayout}
        >
          {getFieldDecorator("type", {
            rules: [
              {
                required: true,
                message: getCurrentlocaleText(
                  "alertform.alerttype.required.message.text",
                ),
              },
            ],
            initialValue: formItem.type,
          })(
            <Select
              disabled={this.props.formType != "add"}
              placeholder={getCurrentlocaleText(
                "alertform.alerttype.placeholder.text",
              )}
              onChange={this.handleTypeChange}
              onFocus={this.updateHelp.bind(this, {
                title: getCurrentlocaleText("managealerts.condition.text"),
                data: [
                  {
                    subtitle: "",
                    content: this.state.hideDuration
                      ? this.state.isHeadlessType
                        ? getCurrentlocaleText(
                            "alertform.condition.non_duration_headless.help.text",
                          )
                        : this.state.isNodeUpgradeType
                        ? getCurrentlocaleText(
                            "alertform.condition.non_duration_node_upgrade.help.text",
                          )
                        : this.state.isAggregatedRouteType
                        ? getCurrentlocaleText(
                            "alertform.condition.non_duration_aggregated_route_change.help.text",
                          )
                        : this.state.isDadType
                        ? getCurrentlocaleText(
                            "alertform.condition.non_duration_duplicate_address_detection.help.text",
                          )
                        : getCurrentlocaleText(
                            "alertform.condition.non_duration_credentials.help.text",
                          )
                      : getCurrentlocaleText("alertform.condition.help.text"),
                  },
                ],
              })}
            >
              {this.renderAlertTypeOptions()}
            </Select>,
          )}
        </FormItem>
        {!this.state.hideAlertMe && (
          <FormItem
            label={<span>{getCurrentlocaleText("alertform.status.text")}</span>}
            {...formItemLayout}
          >
            {getFieldDecorator("status", {
              rules: [
                {
                  required: true,
                  message: getCurrentlocaleText(
                    "alertform.status.required.message.text",
                  ),
                },
                { validator: this.validateStatus },
              ],
              initialValue: this.state.status,
            })(
              this.state.isNodeMetricsType
                ? this.NodeMetricsStatusAddOn()
                : this.StatusAddOn(),
            )}
          </FormItem>
        )}
        {!this.state.hideDuration && (
          <FormItem
            label={
              <span>{getCurrentlocaleText("alertform.duration.text")}</span>
            }
            {...formItemLayout}
          >
            {getFieldDecorator("duration", {
              rules: [{ validator: this.validateDuration }],
            })(
              <AlertDuration
                formType={this.props.formType}
                isDefault={this.props.isDefault}
                default={formItem.duration}
                onChange={this.handleDurationChange}
                onFocus={this.updateHelp.bind(this, {
                  title: getCurrentlocaleText("managealerts.condition.text"),
                  data: [
                    {
                      subtitle: "",
                      content: getCurrentlocaleText(
                        "alertform.condition.help.text",
                      ),
                    },
                  ],
                })}
              />,
            )}
          </FormItem>
        )}
      </div>
    );
  }
}

export default AlertConditionItems;
