import React, { Component } from "react";
import { Link } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { Menu, Button, Modal, Checkbox } from "antd";
import Icons from "components/UI-Components/Icons";
import { getCurrentlocaleText, checkforFeatureEnabled } from "Core/Utils";
import axios from "axios";

const SubMenu = Menu.SubMenu;
let menuItem = {
  height: 40,
};
let iconStyle = {
  fontSize: 16,
  lineHeight: 1.3,
  marginRight: 8,
};
let menuItemStyle = {
  lineHeight: 1.5,
};

@inject("AuthStore", "ModelStore", "UiStore", "OrgViewModel")
@observer
class QuickActionUser extends Component {
  constructor(props) {
    super(props);
    // GLOBAL_SETTINGS configured from the node js
    this.GLOBAL_SETTINGS = GLOBAL_SETTINGS;
    this.state = {
      visibility: false,
      skipStatus: false,
      logoutInprogress: false,
      skipLogoutConfirmation: false,
    };
  }

  componentDidMount() {
    if (
      localStorage.skipLogoutConfirmation &&
      localStorage.skipLogoutConfirmation == "true"
    ) {
      this.setState({ skipLogoutConfirmation: true });
    } else {
      this.setState({ skipLogoutConfirmation: false });
    }
  }

  changeMessage = event => {
    if (event.target.checked) {
      this.setState({
        skipStatus: true,
      });
    } else {
      this.setState({
        skipStatus: false,
      });
    }
  };

  cancelAlert = () => {
    this.setState({
      visibility: false,
    });
  };

  logOut = () => {
    if (!this.state.logoutInprogress) {
      this.setState({
        logoutInprogress: true,
      });
      if (
        this.props.AuthStore.loggedInUserOrg &&
        this.props.AuthStore.loggedInUserOrg.is_central_auth
      ) {
        //Clear stores before logout, this is only sso org users
        localStorage.removeItem("currentOrgId");
        // whatever happens clears client data
        this.props.ModelStore.Logout(this.props.history);
        this.props.UiStore.SetLoginStatus(false);
        this.props.AuthStore.resetStore();
        this.props.UiStore.resetStore();
        Modal.destroyAll();
        this.props.AuthStore.SetStoreData("isLogutInprogress", true);
        if (this.state.skipStatus) {
          localStorage.setItem("skipLogoutConfirmation", "true");
          window.location.pathname = "/api/v1/logout";
        } else {
          window.location.pathname = "/api/v1/logout";
        }
      } else {
        if (this.state.skipStatus) {
          localStorage.setItem("skipLogoutConfirmation", "true");
          window.location.pathname = "/logout";
        } else {
          window.location.pathname = "/logout";
        }
      }
    }
  };

  render() {
    // To re-render when logged in user permission changed
    this.props.AuthStore.loggedInUser.permissions;
    this.props.AuthStore.loggedInUserOrg;
    return (
      <span>
        {this.state.skipLogoutConfirmation ? (
          <Link to="/logout">
            <Button
              className="quick-menu-button"
              style={{
                float: "left",
                marginLeft: this.props.UiStore.isSiderFold ? "-12%" : "",
              }}
              shape="circle"
              size={"large"}
              title={getCurrentlocaleText("logout.text")}
            >
              <span>
                <Icons
                  type="fa"
                  name="FaSignOutAlt"
                  style={{
                    marginLeft: this.props.UiStore.isSiderFold ? 31 : 0,
                  }}
                  title={getCurrentlocaleText("logout.text")}
                  className="middle quick-icon-style"
                />
              </span>
            </Button>
          </Link>
        ) : (
          <Button
            className="quick-menu-button"
            style={{
              float: "left",
              marginLeft: this.props.UiStore.isSiderFold ? "-12%" : "",
            }}
            shape="circle"
            size={"large"}
            title={getCurrentlocaleText("logout.text")}
            onClick={() => {
              this.setState({ visibility: true });
            }}
          >
            <span>
              <Icons
                type="fa"
                name="FaSignOutAlt"
                title={getCurrentlocaleText("logout.text")}
                className="middle quick-icon-style"
              />
            </span>
          </Button>
        )}

        <Modal
          title={getCurrentlocaleText("iot-logout-heading")}
          visible={this.state.visibility}
          onOk={this.logOut}
          onCancel={this.cancelAlert}
          maskClosable={false}
          okText={getCurrentlocaleText("iot-logout-primary-button-text")}
          cancelText={getCurrentlocaleText("iot-logout-cancel-button-text")}
        >
          <span>
            <h4 style={{ marginBottom: 15 }}>
              {getCurrentlocaleText("iot-logout-message")}
            </h4>{" "}
            <p>
              <Checkbox
                onChange={e => {
                  this.changeMessage(e);
                }}
              >
                {getCurrentlocaleText("iot-logout-skip-label-text")}
              </Checkbox>
            </p>
          </span>
        </Modal>
      </span>
    );
  }
}
export default QuickActionUser;
