import React, { Component } from "react";
import { Row, Col } from "antd";
import { getCurrentlocaleText } from "Core/Utils";
class GenerateLabelHelp extends Component {
  render() {
    return (
      <span>
        <Row>
          <Col span={12}>
            {getCurrentlocaleText("general.form.label.field.help3.text")}
            <span>
              {getCurrentlocaleText(
                "general.form.label.field.help3_value1.text",
              )}
            </span>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            {getCurrentlocaleText("general.form.label.field.help4.text")}
            <span>
              {getCurrentlocaleText(
                "general.form.label.field.help4_value2.text",
              )}
            </span>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            {getCurrentlocaleText("general.form.label.field.help6.text")}
            <span>
              {getCurrentlocaleText(
                "general.form.label.field.help6_value2.text",
              )}
            </span>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            {getCurrentlocaleText("general.form.label.field.help5_value1.text")}
          </Col>
        </Row>
      </span>
    );
  }
}
export default GenerateLabelHelp;
