import React, { Component } from "react";
import { Tag, Tooltip } from "antd";
import Icons from "./Icons";

class LabelSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: this.props.tags || [],
      inputVisible: false,
      inputValue: "",
      inputKey: "",
      labels: this.props.metadata || {},
    };
  }
  UNSAFE_componentWillReceiveProps(newprops) {
    if (this.props != newprops) {
      this.setState({ tags: newprops.tags, labels: newprops.metadata });
    }
  }
  /**
   * Handle TAG Remove
   */
  handleClose = removedTag => {
    let removedLabel = removedTag.split(":");
    const tags = this.state.tags.filter(tag => tag !== removedTag);
    let labels = this.state.labels;
    delete labels[removedLabel[0].trim()];
    this.setState({ tags: tags, labels: labels });
    this.props.handleChange(labels);
  };
  /**
   * Toggle INPUT and BUTTON
   */
  showInput = () => {
    this.setState({ inputVisible: true });
  };

  /**
   * Handle Input Change
   */
  handleInputValueChange = e => {
    this.setState({ inputValue: e.target.value });
  };
  /**
   * Handle Input Change
   */
  handleInputKeyChange = e => {
    this.setState({ inputKey: e.target.value });
  };
  /**
   * Handle Confirmation
   */
  handleInputConfirm = () => {
    const state = this.state;
    const inputValue = state.inputValue;
    const inputKey = state.inputKey;
    let tags = state.tags,
      labels = state.labels;
    if (
      inputValue &&
      tags.indexOf(inputValue) === -1 &&
      !labels.hasOwnProperty(inputKey)
    ) {
      tags.push(inputKey + ":" + inputValue);
      labels[inputKey] = inputValue;
    }
    if (
      inputValue &&
      tags.indexOf(inputValue) === -1 &&
      inputKey &&
      tags.indexOf(inputKey) === -1
    ) {
      this.setState({
        tags: tags,
        inputValue: "",
        inputKey: "",
        inputVisible: false,
        labels: labels,
      });
    }
    this.props.handleChange(labels);
  };

  saveInputRef = input => (this.input = input);
  render() {
    const { tags, inputVisible, inputValue, inputKey } = this.state;

    return (
      <div>
        {!this.props.noLabel && "Firewall Tag:"}&nbsp;
        {tags.map((tag, index) => {
          const isLongTag = tag.length > 20;
          const tagElem = (
            <Tag
              key={tag}
              className="iot-tag-label-color"
              closable={false}
              afterClose={() => this.handleClose(tag)}
            >
              {isLongTag ? (
                `${tag.slice(0, 20)}...`
              ) : (
                <span>
                  <Icons type="ai" name="AiOutlineTag" />
                  {tag}
                </span>
              )}
            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={tag} key={tag}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
      </div>
    );
  }
}
export default LabelSelector;
