import React, { Component } from "react";
import { Row, Col, Button, Icon, Modal, Tabs } from "antd";
import "../../assets/styles/CookieBanner.css";
import { getCurrentlocaleText, isLoggedIn } from "Core/Utils";

const TabPane = Tabs.TabPane;
const DEFAULT_PRIVACY_POLICY_URL = "https://view.com/privacy";

export default class CookieBanner extends Component {
  constructor(props) {
    super(props);
    // GLOBAL_SETTINGS configured from the node js
    this.GLOBAL_SETTINGS = GLOBAL_SETTINGS;
    this.state = {
      showPrivacy: false,
    };
  }

  componentWillUnmount() {
    /* on successful login, update the session. Avoid showing banner unless session is cleared */
    if (isLoggedIn()) {
      window.sessionStorage.acceptedCookie = true;
    }
  }

  hideCookieBanner = () => {
    this.cookieBanner.style.display = "none";
    window.sessionStorage.acceptedCookie = true;
  };

  showPrivacySettings = () => {
    this.setState({
      showPrivacy: !this.state.showPrivacy,
    });
  };

  render() {
    let cookie = !window.sessionStorage.acceptedCookie;
    return (
      <div>
        {/* cookie banner - LAT-4321 */
        cookie && (
          <div id="cookieBanner" ref={e => (this.cookieBanner = e)}>
            <Row>
              <Col span={24} style={{ textAlign: "center" }}>
                <span>{getCurrentlocaleText("cookie.banner.text")}</span>
                <span style={{ marginLeft: 10 }}>
                  <Button id="cookieCloseBtn" onClick={this.hideCookieBanner}>
                    {" "}
                    OK{" "}
                  </Button>
                </span>
                <span style={{ marginLeft: 10 }}>
                  <Button
                    id="cookieLearnBtn"
                    onClick={this.showPrivacySettings}
                  >
                    {" "}
                    Learn More{" "}
                  </Button>
                </span>
              </Col>
            </Row>
          </div>
        )}
        <Modal
          title={getCurrentlocaleText("cookie.modal.heading.text")}
          visible={this.state.showPrivacy}
          onCancel={this.showPrivacySettings}
          maskClosable={false}
          width="50%"
          footer={[]}
          bodyStyle={{ fontSize: 17 }}
        >
          <div>
            <Tabs tabPosition="left" type="card">
              <TabPane
                tab={getCurrentlocaleText("cookie.modal.tab.headingone.text")}
                key="1"
              >
                <div className="tabContent">
                  <p>
                    {getCurrentlocaleText(
                      "cookie.modal.tab.headingone.contentone.text",
                    )}
                  </p>

                  <p>
                    {getCurrentlocaleText(
                      "cookie.modal.tab.headingone.contenttwo.text",
                    )}
                  </p>

                  <p>
                    {getCurrentlocaleText(
                      "cookie.modal.tab.headingone.contentthree.text",
                    )}
                  </p>

                  <p>
                    {getCurrentlocaleText(
                      "cookie.modal.tab.headingone.contentfour.text",
                    )}
                  </p>

                  <p>
                    {getCurrentlocaleText(
                      "cookie.modal.tab.headingone.contentfive.text",
                    )}
                  </p>
                </div>
              </TabPane>
              <TabPane
                tab={getCurrentlocaleText("cookie.modal.tab.headingtwo.text")}
                key="2"
              >
                <div className="tabContent">
                  <p>
                    {getCurrentlocaleText(
                      "cookie.modal.tab.headingtwo.contentone.text",
                    )}
                  </p>
                  <p>
                    <a
                      target="blank"
                      href={
                        this.GLOBAL_SETTINGS &&
                        this.GLOBAL_SETTINGS.privacy_policy_url
                          ? this.GLOBAL_SETTINGS.privacy_policy_url
                          : DEFAULT_PRIVACY_POLICY_URL
                      }
                    >
                      {getCurrentlocaleText(
                        "cookie.modal.tab.headingtwo.contenttwo.text",
                      )}
                    </a>
                  </p>
                </div>
              </TabPane>
            </Tabs>
          </div>
        </Modal>
      </div>
    );
  }
}
