/**
 *
 * This is where the state of the application is recorded. The app states
 * like "isSidebarOpen" should be added here. This will then be available
 * all over the app
 *
 */
import { observable, action, computed } from "mobx";
import service_templates from "../assets/data/service-template.json";
import service_Obj from "../assets/data/serviceObj.json";
import img_Obj from "../assets/data/SkysparkImageVersion.json";

/*Services image icons*/
import skySparkLogo from "assets/img/skyspark-logo.png";
import thingWorxLogo from "assets/img/thingworx-logo.svg";
import niagaraLogo from "assets/img/niagara-4.png";
import opcEdgeLogo from "assets/img/opcEdge-logo.png";

class UiStore {
  @observable orgs = {};
  @observable inodes = {};
  @observable networks = {};
  @observable ChangePasswordMdl = false;
  @observable isSiderFold = false;
  @observable helpData = {};
  @observable currentRouteLocation = "/";
  @observable service_template = service_templates;
  @observable
  serviceFormData = {
    uploadedLicenseFileName: [],
  };
  @observable serviceObj = service_Obj.object;
  @observable SkysparkImages = img_Obj.object;
  @observable OrgInorgView = false;
  @observable ContactSupportMdl = false;
  @observable AboutMdl = false;
  @observable isTechDumpTrigger = false;
  @observable
  baseGuideUrl = window.location.origin;
  @observable currentPage = 1;
  @observable pageSize = 5;
  @observable actionModalClose = true;
  @observable documentTitle = "View Secure Edge";
  @observable gaTitle = "View Secure Edge";
  @observable orgName = "";
  @observable inodeName = "";
  @observable listenerName = "";
  @observable currentDocTitle = "View Secure Edge";
  @observable availableLicenseName = [];
  @observable availableSystemServicesTags = [];
  @observable ioTiumVersion = "";
  @observable isFirewallNameChanged = false;
  @observable FirewallNameOld = "";
  @observable FirewallNameNew = "";
  @observable showChildNodes = false;
  @observable showChildOrgs = false;
  @observable showChildUsers = false;
  @observable showChildRoles = false;
  @observable showChildNetworks = false;
  @observable is2faEnabled = false;
  @observable showCaptcha = false;
  @observable showChildSerials = false;
  @observable showChildSecurityGroups = false;
  @observable showChildServices = false;
  @observable showChildSecrets = false;
  @observable showChildVolumes = false;
  @observable defaultRoutes = [];
  @observable
  AvailableServiceImages = [
    {
      id: "s1",
      name: "Custom",
      label: "Custom",
      key: "custom",
    },
    {
      id: "s2",
      name: "Niagara4",
      label: "Niagara4",
      key: "Niagara4",
      icon: niagaraLogo,
      description: "Launch the niagara service",
    },
    {
      id: "s3",
      name: "SkySpark",
      label: "SkySpark",
      key: "Skyspark",
      icon: skySparkLogo,
    },
    {
      id: "s4",
      name: "Thingworx_Edge",
      label: "ThingWorx",
      key: "Thingworx_Edge",
      icon: thingWorxLogo,
    },
    {
      id: "s5",
      name: "OpcEdge",
      label: "OpcEdge",
      key: "OpcEdge",
      icon: opcEdgeLogo,
    },
  ];

  @observable showRepNetwork = false;
  @observable networkErrorResponseMsg = "";
  @observable networksuccessResponse = "";
  @observable lastfailedremoteiNode = null;
  @observable userGuideUrl = "";
  @observable connectingNetworkstate = false;
  @observable errorMessage = null;
  @observable successMessage = null;
  @observable infoMessage = null;
  @observable loading = false;
  @observable disconnectingNetworkstate = false;
  @observable formErrorMessage = null;
  @observable formErrorResponse = [];
  @observable modalLoading = false;
  @observable permissions = [];
  @observable tableScroll = { y: 200 };
  @observable activityDivScroll = { height: null };
  @observable currentUser = {};
  @observable isCidrChanged = false;
  @observable isForceMessage = false;
  @observable passwordExpiredMessage = null;
  @observable force2FaMessage = null;
  @observable isServicePlayGroundVisible = false;
  @observable nodeScopeContainsError = false;
  /*object for service template*/
  @observable
  templatingService = {
    originalSchema: {},
    computedSchema: {},
    secrets: {},
    node: {
      node_id: null,
      network: {
        network_id: "",
        ip_address: "",
      },
    },
    networkList: [],
    nodeDetails: {},
    dns: [],
    dns_policy: "Default",
    serviceCurrentNode: {},
    serviceCurrentNetwork: {},
    allowIPAddress: false,
  };
  @observable firewallSelector = {};
  @observable parentOrgData = {};
  @observable
  volumes = {
    singleVolUniqueKey: null,
    volumeName: "",
    data: {},
    uploadedFileName: [],
    fileName: {},
    fileContentTextBox: {},
    base64: {},
  };
  /* Alert form data */
  @observable
  alertFormData = {
    condition: {},
    target: {},
    notification: {},
    duration: {},
    genInfo: {},
  };

  /* Org list filter, sort, search */
  @observable
  orgParams = {
    searchText: "",
    filterInfo: null,
    sorterInfo: { sorter: {}, sort: {} },
  };

  /* Inode list filter, sort, search */
  @observable
  nodeParams = {
    searchText: "",
    filterInfo: null,
    sorterInfo: { sorter: {}, sort: {} },
  };

  /* User list filter, sort, search */
  @observable
  userParams = {
    searchText: "",
    filterInfo: null,
    sorterInfo: { sorter: {}, sort: {} },
  };

  @observable computedOutputObject = {};
  @observable showChildClusters = false;

  /* Webhook form data */
  @observable
  webhookFormData = {
    test: {},
    configuration: {},
    genInfo: {},
  };

  /* Alert log filter, sort, search */
  @observable
  ctdAlertLogParams = {
    searchText: "",
    filterInfo: null,
    sorterInfo: { sorter: {}, sort: {} },
  };

  // Set Login Status
  @action
  SetLoginStatus = status => {
    this.isLoggedIn = status;
  };

  @action
  SetStoreData(type, data) {
    this[type] = data;
  }

  @action
  showFormModal(resource) {
    if (!this[resource]) {
      this[resource] = observable({});
    }
    this[resource].showFormModal = true;
  }

  @action
  closeFormModal(resource) {
    if (!this[resource]) {
      this[resource] = observable({});
    }
    this[resource].showFormModal = false;
  }

  @action
  SetSiderFold(fold = false) {
    localStorage.setItem("isSiderFold", fold);
    this.isSiderFold = fold;
  }

  @computed
  get getHelpcontent() {
    return this.helpData;
  }
  @computed
  get getAlertGenInfo() {
    return this.alertFormData.genInfo;
  }
  @computed
  get getAlertCondition() {
    return this.alertFormData.condition;
  }
  @computed
  get getAlertTarget() {
    return this.alertFormData.target;
  }
  @action
  UpdateserviceObj() {
    /*Required to update serviceObj after successful service creation - LAT-2295*/
    this.serviceObj = service_Obj.object;
  }

  @action
  resetServiceSpec() {
    this.templatingService.dns = [];
    this.templatingService.dns_policy = "Default";
    this.templatingService.node = {};
  }

  @action
  resetStore() {
    this.orgs = {};
    this.inodes = {};
    this.networks = {};
    this.ChangePasswordMdl = false;
    this.Discovery = false;
    this.serviceFormData = {
      uploadedLicenseFileName: [],
    };
    this.availableLicenseName = [];
    this.errorMessage = null;
    this.nodeScopeContainsError = null;
    this.successMessage = null;
    this.infoMessage = null;
    this.loading = false;
    this.formErrorMessage = null;
    this.formErrorResponse = [];
    this.modalLoading = false;
    this.permissions = [];
    this.defaultRoutes = [];
    this.templatingService = {
      originalSchema: {},
      computedSchema: {},
      secrets: {},
      node: {
        node_id: null,
        network: {
          network_id: "",
          ip_address: "",
        },
      },
      networkList: [],
      nodeDetails: {},
      serviceCurrentNode: {},
      serviceCurrentNetwork: {},
    };
    this.volumes = {
      assignedServiceCount: [],
      singleVolUniqueKey: null,
      volumeName: "",
      data: {},
      uploadedFileName: [],
      fileName: {},
      fileContentTextBox: {},
      base64: {},
    };
    this.alertFormData = {
      condition: {},
      target: {},
      notification: {},
      duration: {},
      genInfo: {},
    };

    this.orgParams = {
      searchText: "",
      filterInfo: null,
      sorterInfo: { sorter: {}, sort: {} },
    };

    this.nodeParams = {
      searchText: "",
      filterInfo: null,
      sorterInfo: { sorter: {}, sort: {} },
    };

    this.userParams = {
      searchText: "",
      filterInfo: null,
      sorterInfo: { sorter: {}, sort: {} },
    };
  }
  @action
  techDumpTrigger = status => {
    this.isTechDumpTrigger = status;
  };

  @action
  refreshUserGuide = (version = "latest") => {
    this.userGuideUrl = `${window.location.origin}/kb/${version}/index.html`;
  };

  @action
  setCurrentPage = pageNumber => {
    this.currentPage = pageNumber;
  };

  @action
  setPageSize = size => {
    this.pageSize = size;
  };

  @action
  setDocumentTitle = title => {
    this.documentTitle = title;
  };

  @action
  setGaTitle = title => {
    this.gaTitle = title;
  };

  @action
  setOrgName = orgName => {
    this.orgName = orgName;
  };

  @action
  setInodeName = inodeName => {
    this.inodeName = inodeName;
  };

  @action
  setListenerName = listenerName => {
    this.listenerName = listenerName;
  };
  @action
  setCurrentDocTitle = title => {
    this.currentDocTitle = title;
  };

  @action
  setErrorMessage = message => {
    this.errorMessage = message;
  };

  @action
  setNodeScopeContainsError = message => {
    this.nodeScopeContainsError = message;
  };
  @action
  getNodeScopeContainsError = () => {
    return this.nodeScopeContainsError;
  };
  @action
  setSuccessMessage = message => {
    this.successMessage = message;
  };

  @action
  setInfoMessage = message => {
    this.infoMessage = message;
  };

  @action
  resetMessages() {
    this.errorMessage = null;
    this.nodeScopeContainsError = null;
    this.successMessage = null;
    this.infoMessage = null;
  }

  @action
  setLoading = loading => {
    this.loading = loading;
  };

  @action
  setModelLoading = loading => {
    this.modalLoading = loading;
  };

  @action
  setFormErrorMessage = message => {
    this.formErrorMessage = message;
  };

  @action
  setFormErrorResponse = response => {
    this.formErrorResponse = response;
  };

  @action
  setTableScroll = scroll => {
    this.tableScroll = scroll;
  };

  @action
  setActivityDivScroll = scroll => {
    this.activityDivScroll = scroll;
  };

  @action
  setioTiumVersion = version => {
    this.ioTiumVersion = version;
  };

  @action
  resetVolumes() {
    this.volumes = {
      singleVolUniqueKey: null,
      volumeName: "",
      data: {},
      uploadedFileName: [],
      fileName: {},
      fileContentTextBox: {},
      base64: {},
    };
  }

  @action
  resetAlertFormData = () => {
    this.alertFormData = {
      condition: {},
      target: {},
      notification: {},
      duration: {},
      genInfo: {},
    };
  };

  @action
  SetSystemServicesTags = services => {
    this.availableSystemServicesTags = services;
  };

  @computed
  get getSystemServicesTags() {
    return this.availableSystemServicesTags;
  }
}

export default new UiStore();
