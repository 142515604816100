import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import Icons from "components/UI-Components/Icons";
import { getStatsConfig, DEFAULT_STAT_MODE } from "Core/Utils";

const STAT_MODE_COLOR = {
  slow: "#ffbf00",
  fast: "#00a854",
  off: "#f04134",
};

@inject("InodeViewModel")
@observer
class InterfaceTrafficView extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = this.props.InodeViewModel;
  }

  render() {
    let stat_mode =
      this.inodeModel.inode && this.inodeModel.inode.stat_config
        ? this.inodeModel.inode.stat_config.stat_mode
        : DEFAULT_STAT_MODE;
    return (
      <div>
        <Icons
          type="fa"
          name="FaChartLine"
          style={{
            fontSize: 16,
            color: STAT_MODE_COLOR[stat_mode.toLowerCase()],
          }}
        />
        &nbsp;&nbsp;<i>{getStatsConfig(stat_mode)}</i>
      </div>
    );
  }
}

export default InterfaceTrafficView;
