import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { TechDumpData } from "components/DataLists";
import TechDumpTrigger from "components/Containers/TechDumpTrigger";
import InodeController from "controller/InodeController";

@inject("AuthStore", "InodeViewModel")
@observer
class TechDump extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  UNSAFE_componentWillMount() {
    this.getTechdump();
  }
  // Fetch techdump data for getting the status and list of data
  getTechdump = () => {
    InodeController.getTechdump(this.props.nodeId);
  };

  componentWillUnmount() {
    this.props.InodeViewModel.techdumpStatus = [];
    this.props.InodeViewModel.techdumpData = [];
  }

  render = () => {
    return (
      <div>
        <TechDumpTrigger
          nodeDetails={this.props.nodeDetails}
          node_id={this.props.nodeId}
        />
        {this.props.AuthStore.IsPermitted("TECHDUMP:VIEW") && (
          <TechDumpData node_id={this.props.nodeId} />
        )}
      </div>
    );
  };
}

export default TechDump;
