import React, { Component } from "react";
import { Button, Tag } from "antd";
import { observer, inject } from "mobx-react";
import FormErrorMessage from "components/UI-Components/FormErrorMessage";
import { Form } from "@ant-design/compatible";

const FormItem = Form.Item;

@inject("ModelStore", "UiStore")
@observer
class TanDadForm extends Component {
  constructor(props) {
    super(props);
    this.renderList = this.renderList.bind(this);
  }

  renderList = () => {
    let tempArray = [];
    for (const [key, value] of Object.entries(this.props.selectedNode)) {
      tempArray.push(value);
    }

    if (!this.props.fromCluster) {
      return tempArray.map((val, index) => {
        return val.status.toLowerCase() == "alive" && val.value == true ? (
          <span key={index}>
            <Tag className="tag-iotium-info">{val.name}</Tag>
          </span>
        ) : null;
      });
    } else {
      return this.props.selectedNode.name ? (
        <span>
          <Tag className="tag-iotium-info">{this.props.selectedNode.name}</Tag>
        </span>
      ) : null;
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form noValidate onSubmit={this.props.onSubmit} className="login-form">
        <FormItem>
          <div>
            {!this.props.fromCluster ? (
              !this.props.selectedNode ? (
                <strong>No iNode available for Scan Duplicate Addresses</strong>
              ) : (
                <div>
                  <strong>
                    Are you sure you want to scan duplicate addresses in the
                    iNode below?
                  </strong>
                  <br />
                  {this.renderList()}
                </div>
              )
            ) : _.isEmpty(this.props.selectedNode) ? (
              <strong>No Cluster available to Scan Duplicate Addresses</strong>
            ) : (
              <div>
                <strong>
                  Are you sure you want to scan duplicate addresses in the iNode
                  in the Cluster below?
                </strong>
                <br />
                {this.renderList()}
              </div>
            )}
          </div>
        </FormItem>
        <Button className="hidden-submit" htmlType="submit" />
        <FormErrorMessage />
      </Form>
    );
  }
}

const TanDadForms = Form.create()(TanDadForm);
export default TanDadForms;
