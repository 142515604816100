import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Form, Select, Input, Button, AutoComplete } from "antd";
import {
  getCurrentlocaleText,
  ALERT,
  isEmpty,
  focusFirstInput,
  isValidURL,
  getdefaultHeaderValue,
} from "Core/Utils";
import Icons from "components/UI-Components/Icons";

const InputGroup = Input.Group;
const FormItem = Form.Item;
const Option = Select.Option;
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

@inject("UiStore", "AuthStore")
@observer
class WebhookConfig extends Component {
  constructor(props) {
    super(props);
    this.UiStore = this.props.UiStore;
    this.state = {
      avilableAlerts: {},
      formItem: {
        name: undefined,
        type: undefined,
        status: [],
        duration: { duration: 5, units: ALERT.DURATION.MINUTES },
        hideDuration: false,
        isHeadlessType: false,
        isNodeUpgradeType: false,
        isNodeMetricsType: false,
      },
      status: [],
      headers: [],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currrentWebhookData != this.props.currrentWebhookData) {
      if (
        this.props.currrentWebhookData &&
        !isEmpty(this.props.currrentWebhookData)
      ) {
        let genInfo = this.props.currrentWebhookData;
        let formItem = this.state.formItem;
        formItem.url = genInfo.url;
        formItem.secret = genInfo.secret;
        let data = {
          url: {
            value: formItem.url,
          },
          secret: {
            value: formItem.secret,
          },
        };
        this.props.formReference.setFields(data);
      }
    }
  }

  componentDidMount() {
    // Focus first input
    focusFirstInput();
    if (
      this.props.currrentWebhookData &&
      !isEmpty(this.props.currrentWebhookData)
    ) {
      let genInfo = this.props.currrentWebhookData;
      let formItem = this.state.formItem;
      formItem.url = genInfo.url;
      formItem.secret = genInfo.secret;
      let data = {
        url: {
          value: formItem.url,
        },
        secret: {
          value: formItem.secret,
        },
        headers: {
          value: formItem.headers,
        },
      };
      this.props.formReference.setFields(data);
    } else {
      this.setState({
        headers: [
          {
            key: null,
            value: null,
          },
        ],
      });
    }
    this.props.UiStore.webhookFormData.configuration["headers"] = this.props
      .currrentWebhookData.headers
      ? this.props.currrentWebhookData.headers
      : {};
    let headers = this.props.UiStore.webhookFormData.configuration.headers
      ? this.props.UiStore.webhookFormData.configuration.headers
      : this.props.currrentWebhookData.headers;
    let headerFormValue = [];
    for (var key in headers) {
      // check if the property/key is defined in the object itself, not in parent
      if (headers.hasOwnProperty(key)) {
        headerFormValue.push({ key: key, value: headers[key] });
      }
      this.setState({ headers: headerFormValue });
    }
    this.UiStore.webhookFormData.configuration.headers = headers;
  }

  validateURL = (rule, value, callback) => {
    if (value && !isValidURL(value)) {
      callback(getCurrentlocaleText("branding.url.error.message"));
    } else {
      callback();
    }
  };

  handleheaderOnChange = (e, index, type) => {
    let headers = this.state.headers;
    if (type != "key") {
      headers[index][type] =
        e &&
        e.currentTarget &&
        e.currentTarget.value &&
        e.currentTarget.value.length &&
        e.currentTarget.value;
    } else {
      headers[index][type] = e;
    }
    this.setState({ headers: headers });
    this.getcomputedObject();
  };

  getcomputedObject = () => {
    let computedHeaders = {};
    let headers = this.state.headers;
    if (headers.length > 0) {
      headers.forEach(header => {
        let key = header.key;
        computedHeaders[key] = header.value;
      });
    }
    this.props.UiStore.webhookFormData.configuration[
      "headers"
    ] = computedHeaders;
  };

  updateHelp = data => {
    this.UiStore.SetStoreData("helpData", data);
    // Update help content
    setTimeout(() => {
      let elm = document.querySelector(
        ".alert-form .help-container .help-content",
      );
      if (elm) {
        elm.innerHTML =
          data && data.data && data.data[0] && data.data[0].content
            ? data.data[0].content
            : "";
      }
    }, 10);
  };

  addHeader = () => {
    let headers = this.state.headers;
    headers.push({});
    this.setState({ headers: headers });
  };

  removeHeader = index => {
    let headers = this.state.headers;
    headers.splice(index, 1);
    this.setState({ headers: headers });
    this.getcomputedObject();
  };

  render() {
    let { getFieldDecorator } = this.props.formReference;
    let { formItem, headers } = this.state;
    let headerOptions = getdefaultHeaderValue();

    let renderHeaderFormItems = headers.map((item, i) => {
      return (
        <FormItem
          className={"background-url-item"}
          key={i + "parent"}
          colon={false}
          label={
            i === 0 ? (
              <span>
                {"Headers"}
                {" :"}
              </span>
            ) : (
              " "
            )
          }
          {...formItemLayout}
        >
          {" "}
          {getFieldDecorator("headers_value", {
            rules: [{ required: false }],
            initialValue: item.value,
          })(
            <div>
              <div style={{ display: "inline-block", width: "90%" }}>
                <AutoComplete
                  style={{ display: "inline-block", width: "45%" }}
                  dataSource={headerOptions}
                  onBlur={e => {
                    this.handleheaderOnChange(e.target.value, i, "key");
                  }}
                  placeholder="Header Name"
                  filterOption={true}
                  defaultValue={this.state.headers[i].key}
                  onFocus={this.updateHelp.bind(this, {
                    title: getCurrentlocaleText("webhook.header.text"),
                    data: [
                      {
                        subtitle: "",
                        content: getCurrentlocaleText(
                          "webhook.header.help.message",
                        ),
                      },
                    ],
                  })}
                />
                :
                <Input
                  autoComplete="off"
                  placeholder="Value"
                  disabled={this.props.viewOnly}
                  style={{ display: "inline-block", width: "45%" }}
                  onChange={e => {
                    this.handleheaderOnChange(e, i, "value");
                  }}
                  onFocus={this.updateHelp.bind(this, {
                    title: getCurrentlocaleText("webhook.header.text"),
                    data: [
                      {
                        subtitle: "",
                        content: getCurrentlocaleText(
                          "webhook.header.help.message",
                        ),
                      },
                    ],
                  })}
                  value={this.state.headers[i]["value"]}
                />
              </div>
              <div
                style={{
                  display: "inline-block",
                  width: "10%",
                  textAlign: "center",
                }}
              >
                {i === 0 ? (
                  <Button
                    className="no-border"
                    onClick={this.addHeader}
                    disabled={this.props.viewOnly || headers.length === 3}
                    title={"Add"}
                  >
                    <Icons
                      type="ai"
                      name="AiOutlinePlusSquare"
                      className="valign-sub middle"
                      style={{ fontSize: 24 }}
                    />
                  </Button>
                ) : (
                  <Button
                    disabled={this.props.viewOnly}
                    className="no-border"
                    shape="circle"
                    onClick={this.removeHeader.bind(this, i)}
                    title={"Remove"}
                  >
                    <Icons
                      type="ai"
                      name="AiOutlineMinusCircle"
                      className="valign-sub middle"
                      style={{ fontSize: 24 }}
                    />
                  </Button>
                )}
              </div>
            </div>,
          )}
        </FormItem>
      );
    });

    return (
      <div style={{ marginTop: 10 }}>
        <FormItem
          label={<span>{getCurrentlocaleText("webhookform.url.text")}</span>}
          {...formItemLayout}
        >
          {getFieldDecorator("url", {
            rules: [
              {
                required: true,
                message: getCurrentlocaleText("branding.url.error.message"),
                whitespace: true,
              },
              {
                validator: this.validateURL,
              },
            ],
            initialValue: formItem.url,
          })(
            <Input
              autoComplete="off"
              className="firstInput"
              placeholder={getCurrentlocaleText("webhookform.url.text")}
              onFocus={this.updateHelp.bind(this, {
                title: getCurrentlocaleText("webhookform.url.text"),
                data: [
                  {
                    subtitle: "",
                    content: getCurrentlocaleText(
                      "webhookform.url.help.message",
                    ),
                  },
                ],
              })}
            />,
          )}
        </FormItem>
        <FormItem
          label={<span>{getCurrentlocaleText("webhookform.secret.text")}</span>}
          {...formItemLayout}
        >
          {getFieldDecorator("secret", {
            rules: [],
            initialValue: formItem.secret,
          })(
            <Input
              autoComplete="off"
              placeholder={getCurrentlocaleText("webhookform.secret.text")}
              onFocus={this.updateHelp.bind(this, {
                title: getCurrentlocaleText("webhookform.secret.text"),
                data: [
                  {
                    subtitle: "",
                    content: getCurrentlocaleText(
                      "webhookform.secret.help.message1",
                    ),
                  },
                ],
              })}
            />,
          )}
        </FormItem>
        {renderHeaderFormItems}
      </div>
    );
  }
}

export default WebhookConfig;
