import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import { Checkbox } from "antd";
import { randomAlphaNumericString, copyObject } from "Core/Utils";
import propTypes from "prop-types";
import { inject } from "mobx-react";
import { computed } from "mobx";
import Twig from "twig";

/*Define form elements*/
const FormItem = Form.Item;

@inject("UiStore")
export default class CheckBox extends Component {
  constructor(props) {
    super(props);
    this.validationMessage =
      this.props.properties.constraints &&
      this.props.properties.constraints.validationMessage
        ? this.props.properties.constraints.validationMessage
        : `${this.props.properties.label} is required`;

    this.state = {
      isChecked: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.properties.defaultValue != nextProps.properties.defaultValue
    ) {
      let isChecked =
        nextProps.properties.defaultValue &&
        nextProps.properties.defaultValue.value
          ? nextProps.properties.defaultValue.value
          : typeof nextProps.properties.defaultValue === "boolean" &&
            nextProps.properties.defaultValue
          ? nextProps.properties.defaultValue
          : false;
      this.setState({
        isChecked: isChecked,
      });
    }
  }

  componentDidMount() {
    let isChecked =
      this.props.properties.defaultValue &&
      this.props.properties.defaultValue.value
        ? this.props.properties.defaultValue.value
        : typeof this.props.properties.defaultValue === "boolean" &&
          this.props.properties.defaultValue
        ? this.props.properties.defaultValue
        : false;
    this.setState({ isChecked: isChecked });
  }

  onChange = e => {
    this.setState({ isChecked: e.target.checked });
    this.props.properties.onChange && this.props.properties.onChange(e);
    if (this.props.updateCurrentValueOnChange) {
      this.props.updateCurrentValueOnChange(e.target.checked, "value");
    }
  };

  validateAcceptance = (rule, value, callback, field) => {
    let failMessage = null;
    if (value === null) {
      failMessage = this.validationMessage;
    }

    if (failMessage) {
      callback(failMessage);
    }
    callback();
  };

  @computed
  get componentVisibility() {
    let visibility = null;
    if (
      this.props.UiStore.computedOutputObject &&
      this.props.UiStore.computedOutputObject.steps
    ) {
      if (this.props.properties.visibility) {
        var template = Twig.twig({
          data: this.props.properties.visibility,
        });
        let computedOutputObject = copyObject(
          this.props.UiStore.computedOutputObject,
        );
        visibility = template.render(computedOutputObject);
        try {
          visibility = JSON.parse(visibility);
        } catch {}
      }
    }
    return visibility ? true : false;
  }

  render() {
    const { getFieldDecorator } = this.props.referenceForm; // reference of base form
    let required =
      this.props.properties.constraints &&
      this.props.properties.constraints.required
        ? this.props.properties.constraints.required
        : false;

    let id = this.props.id
      ? this.props.id
      : `checkbox${randomAlphaNumericString(3)}`;

    let disable = false;
    if (this.props.editmode) {
      disable = this.props.properties.noneditable
        ? this.props.properties.noneditable
        : false;
    }
    disable = this.props.properties.disabled
      ? this.props.properties.disabled
      : disable;

    // For View only
    disable = this.props.viewOnly === true ? true : disable;
    let visibility = this.props.properties.visibility
      ? this.componentVisibility
      : true;
    return visibility ? (
      <div>
        <FormItem
          style={
            this.props.properties.options && this.props.properties.options.style
              ? this.props.properties.options.style
              : {}
          }
        >
          {getFieldDecorator(id, {
            rules: [
              {
                required: required,
                message: this.validationMessage,
              },
              {
                validator: this.validateAcceptance,
              },
            ],
            initialValue:
              this.props.properties.defaultValue &&
              this.props.properties.defaultValue.value
                ? this.props.properties.defaultValue.value
                : typeof this.props.properties.defaultValue === "boolean" &&
                  this.props.properties.defaultValue
                ? this.props.properties.defaultValue
                : false,
          })(
            <Checkbox
              disabled={disable}
              checked={
                typeof this.state.isChecked === "boolean"
                  ? this.state.isChecked
                  : typeof this.state.isChecked === "string" &&
                    this.state.isChecked === "true"
                  ? true
                  : false
              }
              onChange={this.onChange}
              onFocus={
                this.props.onFocus &&
                this.props.onFocus.bind(null, this.props.properties.help)
              }
            >
              <span>{this.props.properties.label}</span>
            </Checkbox>,
          )}
        </FormItem>
      </div>
    ) : (
      <div></div>
    );
  }
}

CheckBox.propTypes = {
  referenceForm: propTypes.any,
  id: propTypes.string,
  properties: propTypes.shape({
    label: propTypes.string.isRequired,
    defaultValue: propTypes.bool,
    onChange: propTypes.func,
    constraints: propTypes.shape({
      validationMessage: propTypes.string,
      required: propTypes.bool,
    }),
    options: propTypes.shape({
      style: propTypes.object,
    }),
  }),
};

/*
  sample schema

  {
    "type": "CheckBox",
    "visible": true,
    "props": {
      "label": "Enable Secure Shell",
      "defaultValue": true,
      "help": "<div>help for checkbox</div>",
      "disabled": false,
      "noneditable" : true,
      "constraints": {
        "required": false,
        "validationMessage": " validate me"
      }
    }
  }



*/
