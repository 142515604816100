import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import {
  Row,
  Col,
  Card,
  Button,
  Tag,
  Radio,
  Modal,
  Cascader,
  Form,
} from "antd";
import LogoWithText from "assets/img/view-logo.png";
import customImg from "assets/img/logo_custom.png";
import BreadcrumbComponent from "components/UI-Components/BreadcrumbComponent";
import Icons from "../UI-Components/Icons";
import OrgSelector from "../Orgs/OrgSelector";
import LoadingComponent from "../UI-Components/LoadingComponent";
import { getCurrentlocaleText } from "../../Core/Utils";
import InodeController from "../../controller/InodeController";
import ClusterController from "../../controller/ClusterController";
import serviceTemplateController from "../../controller/serviceTemplateController";
import NetworkController from "../../controller/NetworkController";
import OrgController from "../../controller/OrgController";

@inject(
  "AuthStore",
  "OrgViewModel",
  "InodeViewModel",
  "ClusterViewModel",
  "NetworkViewModel",
)
@observer
export default class ServiceListing extends Component {
  constructor(props) {
    super(props);
    this.orgModel = this.props.OrgViewModel;
    this.inodeModel = this.props.InodeViewModel;
    this.clusterModel = this.props.ClusterViewModel;
    this.networkModel = this.props.NetworkViewModel;

    this.state = {
      BreadcrumbList: [],
      loading: true,
      loadingNodes: true,
      loadingClusters: true,
      availableServices: [],
      filteredServices: [],
      imageIcons: [],
      selectedServiceType: "all",
      selectedService: "",
      isModalOpen: false,
      service_type: [],
      networksList: [],
      orgId: this.props.match.params.orgId
        ? this.props.match.params.orgId
        : this.props.AuthStore.loggedInUser.organization.id,
    };
    this.pageSize = 100;
  }

  componentDidMount() {
    this.setBreadcrumb();
    this.getTemplates();
    this.getInodes();
    this.getClusters();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params &&
      this.props.match.params.orgId &&
      prevProps.match.params &&
      prevProps.match.params.orgId
    ) {
      if (prevProps.match.params.orgId != this.props.match.params.orgId) {
        this.setState({
          orgId: this.props.match.params.orgId
            ? this.props.match.params.orgId
            : this.props.AuthStore.loggedInUser.organization.id,
        });
        OrgController.getOrg(this.props.match.params.orgId, false, true).then(
          res => {
            this.setBreadcrumb();
            this.getTemplates();
            this.getInodes();
            this.getClusters();
          },
        );
      }
    }
  }

  setBreadcrumb() {
    let BreadcrumbList = [];
    BreadcrumbList.push({
      name:
        this.props.OrgViewModel &&
        this.props.OrgViewModel.org &&
        this.props.OrgViewModel.org.name
          ? this.props.OrgViewModel.org.name
          : this.props.AuthStore.loggedInUser.organization.name,
      link: `dashboard/${this.state.orgId}`,
    });

    BreadcrumbList.push({
      name: getCurrentlocaleText("marketplace"),
      link: "orgs/" + this.state.orgId + "/servicelisting",
    });

    this.setState({ BreadcrumbList });
  }

  getTemplates() {
    let templateParams = {
      org_id: this.state.orgId,
      own: true,
    };
    serviceTemplateController.getTemplates(templateParams).then(res => {
      /* add services list */
      let serviceList =
        res &&
        res.map(val => {
          return {
            id: val.id,
            ...val.template_metadata,
          };
        });

      serviceList = serviceList.sort((a, b) => a.name.localeCompare(b.name));
      serviceList &&
        serviceList.length > 0 &&
        serviceList.forEach((item, i) => {
          if (item.name.toLowerCase() === "custom") {
            serviceList.splice(i, 1);
            serviceList.unshift(item);
          }
        });
      let uniqueServiceType = [
        ...new Set(serviceList.map(item => item.service_type)),
      ];

      this.setState({
        availableServices: serviceList,
        filteredServices: serviceList,
        service_type: uniqueServiceType.filter(Boolean),
        loading: false,
      });
    });
  }

  getInodes() {
    this.inodeModel.resetInodes();
    let params = {
      org_id: this.state.orgId,
      own: true,
      size: this.pageSize,
      page: 0,
    };
    InodeController.getInodesForDropdown(params)
      .then(response => {
        if (response && response.total_count) {
          let allParams = [],
            totalCount = response.total_count / this.pageSize;
          if (totalCount > 0) {
            for (let page = 0; page < totalCount; page++) {
              allParams.push({
                org_id: this.state.orgId,
                own: true,
                size: this.pageSize,
                page: page,
              });
            }
            InodeController.getAllInodesForDropdown(allParams, true, true)
              .then(response => {
                this.setState({ loadingNodes: false });
              })
              .catch(error => {
                this.setState({ loadingNodes: false });
              });
          } else {
            this.setState({ loadingNodes: false });
          }
        } else {
          this.setState({ loadingNodes: false });
        }
      })
      .catch(error => {
        this.setState({ loadingNodes: false });
      });
  }

  getClusters() {
    this.clusterModel.resetClusters();
    let params = {
      org_id: this.state.orgId,
      own: true,
      size: this.pageSize,
      page: 0,
    };

    ClusterController.getClustersForDropdown(params)
      .then(response => {
        if (response && response.total_count) {
          let allParams = [],
            totalCount = response.total_count / this.pageSize;

          if (totalCount > 0) {
            for (let page = 0; page < totalCount; page++) {
              allParams.push({
                org_id: this.state.orgId,
                own: true,
                size: this.pageSize,
                page: page,
              });
            }
            ClusterController.getAllClustersForDropdown(allParams, false)
              .then(response => {
                this.setState({ loadingClusters: false });
                this.updateNetworkData();
              })
              .catch(error => {
                this.setState({ loadingClusters: false });
              });
          } else {
            this.setState({ loadingClusters: false });
          }
        } else {
          this.setState({ loadingClusters: false });
        }
      })
      .catch(error => {
        this.setState({ loadingClusters: false });
      });
  }

  updateNetworkData() {
    let params = this.props.params || {
      org_id: this.state.orgId,
      own: true,
      size: 1,
      page: 0,
    };
    params.page = 0;
    NetworkController.getNetworks(params, true, false, true).then(resp => {
      if (resp && resp.length > 0) {
        this.updateOrgsNetworkData(
          this.networkModel.pageable.total_count / 100,
          [],
          true,
        );
      }
    });
  }

  updateOrgsNetworkData(totalCount, availableNetworks, loading) {
    if (totalCount > 0) {
      let allParams = [];
      for (let page = 0; page < totalCount; page++) {
        allParams.push({
          org_id: this.state.orgId,
          own: true,
          size: 100,
          page: page,
        });
      }
      NetworkController.getAllNetworksForDropdown(allParams, loading).then(
        resp => {
          if (resp) {
            this.setState({
              networksList: this.networkModel.networks,
            });
          }
        },
      );
    }
  }

  getImg(service) {
    let returnval = "";
    if (service.id == "custom") {
      returnval = (
        <img
          src={customImg}
          style={{
            width: "50px",
            height: "50px",
          }}
          className="service-custom-icon"
        />
      );
    } else if (service.id && service.logo) {
      returnval = (
        <img
          src={"/api/v2/servicetemplate/" + service.id + "/" + service.logo}
          style={{ width: "50px" }}
        />
      );
    } else {
      returnval = <img src={LogoWithText} style={{ width: "50px" }} />;
    }
    return returnval;
  }

  filterService = val => {
    let { availableServices } = this.state;
    let filteredServices = "";
    if (val == "all") {
      filteredServices = availableServices;
    } else {
      filteredServices = availableServices.filter(obj => {
        return obj.service_type == val;
      });
    }
    this.setState({
      selectedServiceType: val,
      filteredServices: filteredServices,
    });
  };

  addService = service => {
    this.setState({
      selectedService: service,
      isModalOpen: true,
    });
  };

  onFinish = values => {
    if (values.nodeCluster.length && values.nodeCluster.length == 2) {
      this.props.history.push(
        values.nodeCluster[0] +
          "/" +
          values.nodeCluster[1] +
          "/services/" +
          this.state.selectedService.id,
      );
    }
  };

  handleCancel = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    let {
      BreadcrumbList,
      loading,
      filteredServices,
      selectedServiceType,
      selectedService,
      isModalOpen,
      service_type,
      networksList,
    } = this.state;

    const inodesList = [];
    for (let i = 0; i < this.inodeModel.inodes.length; i++) {
      let inode = this.inodeModel.inodes[i];
      if (
        inode.profile &&
        inode.profile.name &&
        inode.profile.name.toLowerCase() !== "virtual" &&
        !(inode.cluster && inode.cluster.id) &&
        inode.networks &&
        inode.networks.length > 1
      ) {
        inodesList.push({
          value: inode.id,
          label: inode.name,
        });
      }
    }

    const clusterList = [];
    for (let i = 0; i < this.clusterModel.clusters.length; i++) {
      let cluster = this.clusterModel.clusters[i];
      for (let j = 0; j < networksList.length; j++) {
        if (
          networksList[j].cluster &&
          networksList[j].cluster.id &&
          networksList[j].cluster.id == cluster.id &&
          networksList[j].name &&
          networksList[j].name !== "WAN Network"
        ) {
          clusterList.push({
            value: cluster.id,
            label: cluster.name,
          });
          break;
        }
      }
    }

    const options = [
      {
        value: "inodes",
        label: "iNode",
        children: inodesList,
        disabled: inodesList.length == 0 ? true : false,
      },
      {
        value: "clusters",
        label: "Cluster",
        children: clusterList,
        disabled: clusterList.length == 0 ? true : false,
      },
    ];

    const displayRender = (labels, selectedOptions) =>
      labels.map((label, i) => {
        const option = selectedOptions[i];
        if (i === labels.length - 1) {
          return <span key={option.value}>{label}</span>;
        }
        return <span key={option.value}>{label} / </span>;
      });

    return (
      <LoadingComponent loading={loading}>
        <div className="usage-wrapper ctd-wrapper-all">
          <Row gutter={16} type="flex" align="middle">
            <Col span={18}>
              <h2 className="page-title">
                {getCurrentlocaleText("service.listing")}
              </h2>
              <BreadcrumbComponent
                {...this.props}
                BreadcrumbList={BreadcrumbList}
              />
            </Col>
            <Col span={6} align="right" className="flex flex-h-right">
              <OrgSelector
                {...this.props}
                org_id={this.state.orgId}
                className="orgslector-ellipse-menu"
              />
            </Col>
          </Row>
          <Card>
            <div className="service-listening-header">
              <h2 className="p-10">
                Add Services Engineered For Secure Performance
              </h2>
              <Radio.Group
                value={selectedServiceType}
                shape="round"
                onChange={e => this.filterService(e.target.value)}
                className="service-tab"
              >
                <Radio.Button
                  size="large"
                  value="all"
                  className={selectedServiceType == "all" && "bg-color"}
                >
                  All
                </Radio.Button>
                {service_type.map(name => {
                  return (
                    <Radio.Button
                      key={name}
                      size="large"
                      value={name}
                      className={selectedServiceType == name && "bg-color"}
                    >
                      <span className="text-capitalize">{name}</span>
                    </Radio.Button>
                  );
                })}
              </Radio.Group>
            </div>
            <div className="service-listening-body">
              <div className="pb-15">
                <b>{filteredServices.length} Available Services</b>
              </div>

              {filteredServices.map(service => {
                return (
                  <div className="service-list-row" key={service.id}>
                    <div className="align-items-center">
                      <div>{this.getImg(service)}</div>
                      <div style={{ padding: "0px 10px" }}>
                        <div>
                          <b className="pr-5">{service.name}</b>
                          {service.service_type &&
                            selectedServiceType == "all" && (
                              <div className="service-list-tag">
                                {service.service_type}
                              </div>
                            )}
                        </div>
                        <div className="text-muted">{service.description}</div>
                        {service.link && (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={service.link}
                          >
                            <div
                              className="py-5 font-weight-bold align-items-center"
                              style={{ width: "fit-content" }}
                            >
                              <span className="pr-5">Learn more</span>
                              <Icons
                                type="fa"
                                name="FaExternalLinkAlt"
                                className="middle"
                              />
                            </div>
                          </a>
                        )}
                      </div>
                    </div>
                    <div>
                      <Button
                        key="submit"
                        className="service-list-add"
                        size="large"
                        onClick={() => this.addService(service)}
                      >
                        Add
                        <Icons type="ai" name="AiOutlinePlus" id={service.id} />
                      </Button>
                    </div>
                  </div>
                );
              })}
            </div>
          </Card>
          {isModalOpen && (
            <Modal
              title={"Select iNode Or Cluster"}
              visible={true}
              closable={true}
              onCancel={() => this.handleCancel()}
              maskClosable={false}
              footer={
                <div className="justify-space-between">
                  <div className="text-muted">
                    {inodesList.length} iNodes and {clusterList.length} cluster
                    available
                  </div>
                  <div>
                    <Button
                      type="link"
                      key="cancel"
                      onClick={() => this.handleCancel()}
                    >
                      {getCurrentlocaleText("back")}
                    </Button>
                    <Button
                      key="submit"
                      type="primary"
                      form="nodeClusForm"
                      htmlType="submit"
                      className="btn-rounded"
                      // disabled={true}
                    >
                      {getCurrentlocaleText("support.submit.text")}
                    </Button>
                  </div>
                </div>
              }
              width={600}
            >
              <div className="service-node-cluster">
                <Form
                  name="nodeClusForm"
                  layout="vertical"
                  onFinish={this.onFinish}
                >
                  <Form.Item
                    label={<b>Select where to Add {selectedService.name} :</b>}
                    name="nodeCluster"
                    rules={[
                      {
                        required: true,
                        message: "Please select where to add",
                      },
                    ]}
                  >
                    <Cascader options={options} displayRender={displayRender} />
                  </Form.Item>
                </Form>
              </div>
            </Modal>
          )}
        </div>
      </LoadingComponent>
    );
  }
}
