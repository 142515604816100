import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import ErrorMessageDisplay from "Core/ErrorMessageDisplay";
import SuccessMessageDisplay from "Core/SuccessMessageDisplay";
import InfoMessageDisplay from "Core/InfoMessageDisplay";

@inject("UiStore")
@observer
class MessageDisplay extends Component {
  constructor(props) {
    super(props);
    this.UiStore = this.props.UiStore;
  }

  UNSAFE_componentWillMount() {
    // Once showed reset the message. Otherwise message will be shown when new routes comes.
    if (!this.UiStore.isForceMessage) this.UiStore.resetMessages();
  }

  componentDidMount() {
    this.UiStore.isForceMessage = false;
  }

  render() {
    return (
      <span>
        <ErrorMessageDisplay message={this.UiStore.errorMessage} />
        {this.UiStore.successMessage && (
          <SuccessMessageDisplay message={this.UiStore.successMessage} />
        )}
        {this.UiStore.infoMessage && (
          <InfoMessageDisplay message={this.UiStore.infoMessage} />
        )}
      </span>
    );
  }
}

export default MessageDisplay;
