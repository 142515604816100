import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Card, Row, Col } from "antd";
import Icons from "components/UI-Components/Icons";
import BreadcrumbComponent from "components/UI-Components/BreadcrumbComponent";
import { getCurrentlocaleText, ALERT, removeDefaultLabels } from "Core/Utils";
import AlertForm from "components/Alerts/AlertStepsForm";
import AlertController from "controller/AlertController";

@inject("UiStore")
@observer
class ManageAlertForm extends Component {
  constructor(props) {
    super(props);
    this.UiStore = this.props.UiStore;
    let path, name, formType;
    if (this.props.match && this.props.match) {
      if (this.props.match.params && this.props.match.params.id) {
        this.getAlertById(this.props.match.params.id);
        if (this.props.match.path && this.props.match.path.includes("view")) {
          name = "managealerts.viewalert.text";
          path = "managealerts/view/:id";
          formType = "view";
        } else {
          name = "managealerts.editalert.text";
          path = "managealerts/edit/:id";
          formType = "modify";
        }
      } else {
        name = "managealerts.addalert.text";
        path = "managealerts/add";
        formType = "add";
      }
    }
    this.state = {
      alertId:
        this.props.match.params && this.props.match.params.id
          ? this.props.match.params.id
          : undefined,
      loading: false,
      isSubmitting: false,
      formType: formType,
      BreadcrumbList: [
        {
          name: getCurrentlocaleText("manage_alerts.text"),
          link: "managealerts",
        },
        {
          name: getCurrentlocaleText(name),
          link: path,
        },
      ],
      isDefault: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({ loading: true });
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  submitAlert = values => {
    this.setState({ isSubmitting: true });
    let formData = this.getAlertFormData(values);
    if (this.state.alertId) {
      AlertController.update(this.state.alertId, formData, {})
        .then(res => {
          let link = window.location.pathname.replace(
            "/edit/" + this.state.alertId,
            "",
          );
          this.props.history.push(link);
        })
        .catch(error => {
          this.setState({ isSubmitting: false });
        });
    } else {
      AlertController.create(formData)
        .then(response => {
          let link = window.location.pathname.replace("/add", "");
          this.props.history.push(link);
        })
        .catch(error => {
          this.setState({ isSubmitting: false });
        });
    }
  };

  getAlertById = id => {
    AlertController.getAlertById(id)
      .then(res => {
        //GenInfo
        let genInfo = {};
        genInfo.name = res.name;
        genInfo.type = res.type;
        genInfo.metadata = res.metadata;

        //Condition
        let condition = res.settings;
        condition.status = condition.alert_me
          ? condition.alert_me.split(",")
          : [];
        condition.type = res.type;

        //Target
        let target = {};
        target = res.settings;
        target.name = res.name;
        target.type = res.type;
        target.cluster = [];
        if (res.settings.cluster_id) {
          target.cluster.push("name");
          target.cluster.push(res.settings.org_id);
          target.cluster.push(res.settings.cluster_id);
        } else if (res.settings.cluster_label) {
          target.cluster.push("label");
          target.cluster.push(res.settings.cluster_label);
        }
        target.organization = res.settings.org_id;
        let organizationField;
        if (
          target.organization &&
          this.UiStore.currentUser.organization &&
          this.UiStore.currentUser.organization.id &&
          target.organization != this.UiStore.currentUser.organization.id
        ) {
          organizationField = [
            ALERT.ORGANIZATION.SPECIFIC_ORGS,
            target.organization,
          ];
        } else {
          if (target.include_child === "true") {
            organizationField = [ALERT.ORGANIZATION.MY_ORG_AND_MY_CHILD_ORGS];
          } else {
            organizationField = [
              ALERT.ORGANIZATION.MY_ORG,
              target.organization,
            ];
          }
        }
        target.organization = organizationField;
        target.network = [];
        if (res.settings.network_id) {
          target.network.push(res.settings.network_id);
          target.network.push(res.settings.network_id);
        }
        if (res.settings.service_id) {
          target.service = res.settings.service_id;
        }
        target.inode = [];
        if (res.settings.node_id) {
          target.inode.push("name");
          target.inode.push(res.settings.org_id);
          target.inode.push(res.settings.node_id);
        } else if (res.settings.node_label) {
          target.inode.push("label");
          target.inode.push(res.settings.node_label);
        }
        let notification = {};
        notification.channel_type = res.channel_type;
        notification.channel = res.channel;
        notification.name = res.name;
        notification.type = res.type;
        this.UiStore.alertFormData.genInfo = genInfo;
        this.UiStore.alertFormData.condition = condition;
        this.UiStore.alertFormData.duration = condition.duration;
        this.UiStore.alertFormData.target = target;
        this.UiStore.alertFormData.notification = notification;
        if (res && res.generated) {
          this.setState({ isDefault: true });
        }
      })
      .catch(err => {});
  };

  getAlertFormData = values => {
    let alertObj = {},
      currentUserId = this.UiStore.currentUser
        ? this.UiStore.currentUser.id
        : null,
      currentUserOrgId =
        this.UiStore.currentUser && this.UiStore.currentUser.organization
          ? this.UiStore.currentUser.organization.id
          : null;
    if (values) {
      alertObj.name = values.name;
      if (values.metadata) {
        let labels = removeDefaultLabels(values.metadata.labels);

        alertObj.metadata = {};
        alertObj.metadata.labels = labels;
      }
      alertObj.type = values.type;
      alertObj.settings = {
        org_id:
          values.organization && values.organization.length > 1
            ? values.organization[1]
            : currentUserOrgId,
      };
      // Scope
      if (values.scope === ALERT.SCOPE.ORG_SCOPE) {
        // child org inclusion check
        if (values.organization) {
          alertObj.settings.include_child =
            values.organization &&
            values.organization.length > 0 &&
            values.organization[0] ===
              ALERT.ORGANIZATION.MY_ORG_AND_MY_CHILD_ORGS
              ? true
              : false;
        }
      }
      // For status settings
      if (values.status) {
        // For Node metrics alert
        if (values.type === ALERT.TYPE.NODE_METRICS) {
          let metricsAlertMe = values.status
            .filter(metrics => metrics)
            .map(metrics => {
              if (
                metrics.operator &&
                metrics.operator === ALERT.OPERATOR.NONE
              ) {
                return ALERT.STATUS.NODE_METRICS_VALUES.OFF;
              } else {
                return metrics.value;
              }
            });
          alertObj.settings.alert_me = metricsAlertMe.join();
        } else {
          // For other alerts
          alertObj.settings.alert_me = values.status.join();
        }
      }
      // For node settings
      if (values.inode) {
        if (values.inode[0] === "name") {
          alertObj.settings.org_id = values.inode[1];
          alertObj.settings.node_id = values.inode[2];
        } else {
          alertObj.settings.node_label =
            typeof values.inode === "string" ? values.inode : values.inode[1];
        }
        // Include child org setting if node is not belong to the current user org.
        alertObj.settings.include_child =
          alertObj.settings.org_id === currentUserOrgId ? false : true;
      }
      // For cluster settings
      if (values.cluster) {
        if (values.cluster[0] === "name") {
          alertObj.settings.org_id = values.cluster[1];
          alertObj.settings.cluster_id = values.cluster[2];
        } else {
          alertObj.settings.cluster_label =
            typeof values.cluster === "string"
              ? values.cluster
              : values.cluster[1];
        }
        // Include child org setting if node is not belong to the current user org.
        alertObj.settings.include_child =
          alertObj.settings.org_id === currentUserOrgId ? false : true;
      }
      // For network settings
      if (values.network) {
        // For all networks of node or cluster
        if (
          alertObj.settings.node_label ||
          alertObj.settings.cluster_label ||
          values.network[0] === alertObj.settings.node_id ||
          values.network[0] === alertObj.settings.cluster_id
        ) {
          // Do nothing
        } else {
          // For specific TAN network
          if (values.network[0] === values.network[1]) {
            alertObj.settings.network_id = values.network[0];
          } else {
            // For specific Remote network
            alertObj.settings.network_id = values.network[0];
            alertObj.settings.tunnel_id = values.network[1];
          }
        }
      }
      // For service settings
      if (values.service) {
        // For all services of node or cluster
        if (
          alertObj.settings.node_label ||
          alertObj.settings.cluster_label ||
          values.service === alertObj.settings.node_id ||
          values.service === alertObj.settings.cluster_id
        ) {
          // Do nothing
        } else {
          // For specific service
          alertObj.settings.service_id = values.service;
        }
      }
      // For duration settings
      if (values.duration) {
        let duration = values.duration;
        // Minutes
        if (duration.units === ALERT.DURATION.MINUTES) {
          alertObj.settings.duration = duration.duration;
        } else if (duration.units === ALERT.DURATION.HOURS) {
          // Hours
          alertObj.settings.duration = duration.duration * 60;
        } else {
          // Days
          if (duration.units === ALERT.DURATION.DAYS) {
            alertObj.settings.duration = duration.duration * 1440;
          } else {
            // default settings (5 minutes)
            alertObj.settings.duration = 5;
          }
        }
      }
      // Alert Channel
      if (values.notificationType == ALERT.CHANNELS.EMAIL) {
        alertObj.channel_type = ALERT.CHANNELS.EMAIL;
      } else if (values.notificationType == ALERT.CHANNELS.WEBHOOK) {
        alertObj.channel_type = ALERT.CHANNELS.WEBHOOK;
        if (values.webhookId) {
          alertObj.channel_id = values.webhookId;
        }
      }
    }

    return alertObj;
  };

  render() {
    return (
      <div>
        <div>
          {" "}
          <Row gutter={24} type="flex" align="middle">
            <Col span={24}>
              <h2 className="page-title">
                <Icons type="fa" name="FaBell" />
                {"  "}
                {getCurrentlocaleText("manage_alerts.text")}
              </h2>
              <BreadcrumbComponent
                {...this.props}
                BreadcrumbList={this.state.BreadcrumbList}
              />
            </Col>
          </Row>
        </div>
        <div>
          <Row gutter={2} type="flex" align="top">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
              xl={{ span: 24 }}
            >
              <Card loading={this.state.loading}>
                <AlertForm
                  {...this.props}
                  alert_id={
                    this.props.match &&
                    this.props.match.params &&
                    this.props.match.params.id
                      ? this.props.match.params.id
                      : undefined
                  }
                  formType={this.state.formType}
                  onSubmit={this.submitAlert}
                  isDefault={this.state.isDefault}
                  isSubmitting={this.state.isSubmitting}
                />
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default ManageAlertForm;
