import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import { Select } from "antd";
import { customSort, copyObject, getCurrentlocaleText } from "Core/Utils";
import { observer, inject } from "mobx-react";
import { computed } from "mobx";
import Twig from "twig";

/*Define form elements*/
const FormItem = Form.Item;
const Option = Select.Option;

@inject("UiStore")
@observer
export default class NetworkList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listData: [],
    };
  }

  listData = data => {
    return (
      data &&
      data
        .sort((x, y) => {
          return customSort(x.name, y.name);
        })
        .map((val, index) => {
          return <Option key={val.id}>{val.name}</Option>;
        })
    );
  };

  componentDidMount() {
    this.props.UiStore.templatingService.node = {
      id: "",
      network: {
        network_id: "",
        ip_address: "",
      },
    };
  }

  @computed
  get componentVisibility() {
    let visibility = null;
    if (
      this.props.UiStore.computedOutputObject &&
      this.props.UiStore.computedOutputObject.steps
    ) {
      if (this.props.properties.visibility) {
        var template = Twig.twig({
          data: this.props.properties.visibility,
        });
        let computedOutputObject = copyObject(
          this.props.UiStore.computedOutputObject,
        );
        visibility = template.render(computedOutputObject);
        try {
          visibility = JSON.parse(visibility);
        } catch {}
      }
    }
    return visibility ? true : false;
  }

  selectednetwork = value => {
    this.props.UiStore.templatingService.node.network.network_id = value;
  };

  render() {
    const { getFieldDecorator } = this.props.referenceForm; // reference of base form
    let required =
      this.props.properties.constraints &&
      this.props.properties.constraints.required
        ? this.props.properties.constraints.required
        : false;

    let validationMessage =
      this.props.properties.constraints &&
      this.props.properties.constraints.validationMessage
        ? this.props.properties.constraints.validationMessage
        : `${this.props.properties.label} is required`;

    let visibility = this.props.properties.visibility
      ? this.componentVisibility
      : true;
    return (
      <div>
        {this.props.UiStore.templatingService.networkList.length > 0 &&
          (visibility ? (
            <div>
              <FormItem label={this.props.properties.label}>
                {getFieldDecorator(`${this.props.id}`, {
                  rules: [
                    {
                      required: true,
                      message: `Please select the Network`,
                    },
                  ],
                  initialValue: this.props.UiStore.templatingService.node
                    .network.network_id
                    ? this.props.UiStore.templatingService.node.network
                        .network_id
                    : [],
                })(
                  <Select
                    placeholder="Network"
                    mode=""
                    onSelect={this.selectednetwork}
                    onFocus={
                      this.props.onFocus &&
                      this.props.onFocus.bind(null, this.props.properties.help)
                    }
                    disabled={this.props.viewOnly === true ? true : false}
                    notFoundContent={getCurrentlocaleText(
                      "general.notfound.placeholder.text",
                    )}
                  >
                    {this.props.UiStore.templatingService.networkList.length >
                      0 &&
                      this.listData(
                        this.props.UiStore.templatingService.networkList,
                      )}
                  </Select>,
                )}
              </FormItem>
            </div>
          ) : (
            <div></div>
          ))}
      </div>
    );
  }
}
