import React, { Component } from "react";
import { Modal, Button, Tag } from "antd";
import propTypes from "prop-types";
import { getCurrentlocaleText } from "Core/Utils";
import ReactDOMServer from "react-dom/server";

class RemoteNetworkRemoveConfirmDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      visible: props.isVisible,
    });
    this.updateContent(props.networkName);
  }

  updateContent(networkName = "") {
    setTimeout(() => {
      let elm = document.querySelector(
        ".remote_network_remove_confirm .modal-content",
      );
      if (elm) {
        elm.innerHTML = getCurrentlocaleText(
          "remove_remote_network_confirm_message_text",
          {
            0: ReactDOMServer.renderToStaticMarkup(
              <Tag className="tag-iotium-info" style={{ marginRight: 0 }}>
                {networkName}
              </Tag>,
            ),
          },
        );
      }
    }, 50);
  }

  handleOk = e => {
    this.setState({ visible: false });
    if (this.props.onCancel) {
      this.props.onOk();
    }
  };

  handleCancel = e => {
    this.setState({ visible: false });
    if (this.props.onOk) {
      this.props.onCancel();
    }
  };

  render() {
    return (
      <div>
        <Modal
          wrapClassName="remote_network_remove_confirm"
          title={`${getCurrentlocaleText("network.form.remove.title.text")}?`}
          visible={this.state.visible}
          maskClosable={false}
          onCancel={this.handleCancel}
          footer={[
            <Button key="cancel" size="small" onClick={this.handleCancel}>
              {getCurrentlocaleText("general.form.button.cancel.text")}
            </Button>,
            <Button
              key="submit"
              type="primary"
              size="small"
              onClick={this.handleOk}
            >
              {getCurrentlocaleText("allow_remove_remote_network_text")}
            </Button>,
          ]}
        >
          <p className="modal-content" />
        </Modal>
      </div>
    );
  }
}

RemoteNetworkRemoveConfirmDialog.propTypes = {
  isVisible: propTypes.bool.isRequired,
  onOk: propTypes.func.isRequired,
  onCancel: propTypes.func.isRequired,
  networkName: propTypes.string,
};

export default RemoteNetworkRemoveConfirmDialog;
