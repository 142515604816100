import React, { Component } from "react";
import { Row, Col } from "antd";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import BreadcrumbComponent from "components/UI-Components/BreadcrumbComponent";
import Icons from "components/UI-Components/Icons";
import { observer, inject } from "mobx-react";
import {
  isCameFromDashboardPage,
  getInodeDetailsBreadcrumbUrl,
  getInodeDetailsBreadcrumbLinkName,
  getCurrentlocaleText,
} from "Core/Utils";
import ListenerFirewall from "components/Containers/ListenerFirewall";
import ServiceListenerController from "controller/ServiceListenerController";
import NetworkController from "controller/NetworkController";
import OrgController from "controller/OrgController";
import OrgSelector from "components/Orgs/OrgSelector";

@inject(
  "ModelStore",
  "AuthStore",
  "UiStore",
  "InodeViewModel",
  "NetworkViewModel",
)
@observer
class ListenerStatus extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = this.props.InodeViewModel;
    this.networkModel = this.props.NetworkViewModel;

    this.state = {
      nodeId: this.props.match.params.nodeId || this.props.match.params.id,
      listenerId:
        this.props.match.params.listenerId || this.props.match.params.id,
      listenerDetails: {},
      BreadcrumbList: [],
      selectedListener: {},
      disableReboot: true,
      availableNetwork: [],
      orgId: this.props.match.params.id,
      orgsNetwork: [],
    };
  }
  componentDidMount() {
    OrgController.getOrg(
      this.inodeModel.inode.organization
        ? this.inodeModel.inode.organization.id
        : this.props.orgId || this.props.match.params.id,
      false,
      this.props.UiStore.parentOrgData && this.props.UiStore.parentOrgData.id
        ? false
        : true,
    ).then(res => {
      if (res.parent_organization)
        this.props.UiStore.parentOrgData = res.parent_organization;
      else this.props.UiStore.parentOrgData = {};
    });
    this.listenerDetails(null, true, true);
    this.getNetworkdata();
  }
  get cameFromOrgDetailPage() {
    if (this.props.match.params.nodeId) {
      return true;
    }
    return false;
  }

  get cameFromDashboardPage() {
    return isCameFromDashboardPage(window.location.pathname);
  }

  get dashboardiNodeURL() {
    return getInodeDetailsBreadcrumbUrl(window.location.pathname);
  }

  get linkNameForiNode() {
    return getInodeDetailsBreadcrumbLinkName(window.location.pathname);
  }
  /**
   * Get Network data for the page
   */
  getNetworkdata = () => {
    NetworkController.getNetworks({
      org_id: this.inodeModel.inode.organization
        ? this.inodeModel.inode.organization.id
        : this.props.orgId || this.props.match.params.id,
      own: true,
      size: 1,
      page: 0,
    }).then(res => {
      if (res) {
        this.updateOrgsNetworkData(
          this.networkModel.pageable.total_count / 100,
          [],
        );
      }
    });
  };

  updateOrgsNetworkData(totalCount, availableNetworks) {
    if (totalCount > 0) {
      let allParams = [];
      for (let page = 0; page < totalCount; page++) {
        if (this.networkModel.pageable.next) {
          allParams.push({
            org_id: this.inodeModel.inode.organization
              ? this.inodeModel.inode.organization.id
              : this.props.orgId || this.props.match.params.id,
            own: true,
            size: 100,
            page: page,
          });
        }
      }
      NetworkController.getAllNetworksForDropdown(allParams).then(resp => {
        if (resp) {
          availableNetworks = availableNetworks.concat(resp);

          let currentNodeID =
            this.props.match.params.nodeId || this.props.match.params.id;
          if (currentNodeID) {
            let networks = availableNetworks.filter(network => {
              if (network.node && network.node.id === currentNodeID)
                return network;
            });
            this.setState({
              availableNetwork: networks,
              orgsNetwork: availableNetworks,
              orgId: this.inodeModel.inode.organization
                ? this.inodeModel.inode.organization.id
                : this.props.orgId || this.props.match.params.id,
              nodeId: currentNodeID,
            });
          }
        }
      });
    }
  }
  listenerDetails = (res = null, loading = true, forceCall = false) => {
    //let resource = "inodes",
    let map = {
      id: res !== null ? res.id : this.state.listenerId,
    };
    ServiceListenerController.getServiceListenerDetail(
      map.id,
      loading,
      forceCall,
    ).then(res => {
      return this.updateListenerDetails(res);
    });
  };
  updateListenerDetails = res => {
    this.setState(() => {
      let newObj = {},
        listenerDetails = this.state.listenerDetails,
        selectedListener = this.state.selectedListener,
        BreadcrumbList = [];

      listenerDetails = res;
      selectedListener[res.id] = {
        value: true,
        status: res.node_state ? res.node_state.toLowerCase() : "UNKNOWN",
        name: res.name,
        id: res.id,
      };

      let nodeCrumb = [
        {
          name: res.node.name ? res.node.name : "Details",
          link: `orgs/${res.organization.id}/inodes/${res.node.id}`,
        },
      ];
      let listenerCrumb = [
        {
          name: res.name ? res.name : "Details",
          link: `orgs/${res.organization.id}/inodes/${res.node.id}/listeners/view/${res.id}`,
        },
      ];
      if (this.cameFromOrgDetailPage) {
        BreadcrumbList.push(
          // { name: "Organizations", link: "orgs" },
          {
            name: res.organization.name,
            link: `orgs/${res.organization.id}`,
          },
          {
            name: getCurrentlocaleText("clusters.inodes.table.column.text"),
            link: `orgs/${res.organization.id}/inodes`,
          },
          ...nodeCrumb,
          {
            name: getCurrentlocaleText(
              "node.list.table.node_service_listener.label.text",
            ),
            link: `orgs/${res.organization.id}/inodes/${res.node.id}/listeners`,
          },
          ...listenerCrumb,
          {
            name: getCurrentlocaleText(
              "service_listener.status.show_link.text",
            ),
            link: `orgs/${res.organization.id}/inodes/${this.state.nodeId}/listeners/${this.state.listenerId}/status`,
          },
        );
      } else if (this.cameFromDashboardPage) {
        BreadcrumbList.push(
          {
            name: this.linkNameForiNode,
            link: this.dashboardiNodeURL,
          },
          ...nodeCrumb,
        );
      } else {
        BreadcrumbList.push(
          {
            name: getCurrentlocaleText("clusters.inodes.table.column.text"),
            link: "inodes",
          },
          ...nodeCrumb,
        );
      }
      this.props.UiStore.setListenerName(res.name);
      this.props.UiStore.setOrgName(res.organization.name);
      newObj["listenerDetails"] = listenerDetails;
      newObj["BreadcrumbList"] = BreadcrumbList;
      newObj["selectedListener"] = selectedListener;
      return newObj;
    });
  };

  render() {
    let node_data = this.inodeModel.inode;
    let container = (
      <div>
        <Row gutter={16} type="flex" align="middle">
          <Col lg={{ span: 18 }} xl={{ span: 20 }}>
            <h2 className="page-title">
              <Icons type="fa" name="FaRegHdd" />
              {"  "}
              {node_data.name ? node_data.name : null}
            </h2>
            <BreadcrumbComponent
              {...this.props}
              BreadcrumbList={this.state.BreadcrumbList}
            />
          </Col>
          <Col lg={{ span: 6 }} xl={{ span: 4 }} className="flex flex-h-right">
            <OrgSelector {...this.props} />
          </Col>
        </Row>
        <div>
          <Row gutter={2} type="flex" align="top">
            <Col className="card-grid" span={24}>
              <ListenerFirewall
                {...this.props}
                firewallResource="firewallgroup"
                orgId={this.props.orgId}
                FirewallStatus={
                  this.state.listenerDetails
                    ? this.state.listenerDetails.status
                    : null
                }
                availableNetwork={this.state.availableNetwork}
                orgsNetwork={this.state.orgsNetwork}
                NetworkID={this.props.netWorkId}
                listenerID={this.state.listenerId}
                ShowAll={true}
                nodeState={node_data.node_state ? node_data.node_state : null}
                nodeId={this.props.match.params.nodeId}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
    return (
      <div>
        <LoadingComponent
          loading={
            !this.inodeModel.loading && !this.networkModel.loading
              ? false
              : true
          }
        >
          {container}
        </LoadingComponent>
      </div>
    );
  }
}

export default ListenerStatus;
