import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Table, Button, Popover } from "antd";
import {
  milliToDateString,
  getCurrentlocaleText,
  TECHDUMP_PROFILE,
} from "Core/Utils";
import InodeController from "controller/InodeController";
import Icons from "../UI-Components/Icons";

const { Column } = Table;

@inject("UiStore", "InodeViewModel")
@observer
class TechDumpData extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = this.props.InodeViewModel;
    this.state = {
      current: 1,
    };
  }

  /*-------------------convert timestamp to time ----------------------------------*/
  getTimestatus = time => {
    let dateString = milliToDateString(time);
    let parsedDate = dateString ? dateString.split(" ") : "";
    parsedDate.splice(0, 1);
    return parsedDate.join(" ");
  };

  /*-------------------Download request url ----------------------------------*/
  downloadTechdump = file => {
    InodeController.downloadTechdump(this.props.node_id, file);
  };

  hoverState = text => {
    return (
      <div>
        <p>
          <b>{getCurrentlocaleText("techdump.requestor.text")}</b>
          {text.requestor}
        </p>
        <p>
          <b>{getCurrentlocaleText("techdump.requestid.text")}</b>
          {text.request_id}
        </p>
        {text.profile && (
          <p>
            <b>{getCurrentlocaleText("techdump.profile.text")}</b>
            {TECHDUMP_PROFILE[text.profile] &&
            TECHDUMP_PROFILE[text.profile].displayName
              ? TECHDUMP_PROFILE[text.profile].displayName
              : TECHDUMP_PROFILE["DEFAULT"].displayName}
          </p>
        )}
      </div>
    );
  };

  render() {
    let isDownloadDisable = this.props.UiStore.isTechDumpTrigger;
    let data = [];
    let techDumpData = this.inodeModel.techdumpData || [];
    techDumpData.forEach((val, i) => {
      val.id = i; /* unique table key */
      data.push(val);
    });

    return (
      <div>
        {data.length > 0 ? (
          <Table
            pagination={{
              defaultCurrent: 1,
              current: this.state.current,
              pageSize: 5,
              total: data.length,
              size: "small",
              onChange: page => {
                this.setState({ current: page });
              },
            }}
            rowKey={record => record.id}
            size={"small"}
            bordered={true}
            dataSource={data.length > 0 ? data : []}
          >
            <Column
              title={getCurrentlocaleText("techdump.createdon.text")}
              key="created_at"
              width="70%"
              render={text => (
                <Popover
                  key="created_at_popover"
                  content={this.hoverState(text)}
                  title="Details"
                >
                  <a key={"created_at_popover" + text.created_at}>
                    {this.getTimestatus(text.created_at)}
                  </a>
                </Popover>
              )}
            />
            <Column
              title={getCurrentlocaleText("techdump.download.text")}
              key="file_path"
              width="30%"
              render={(text, record) => (
                <span key={"file_path_download" + record.file_name}>
                  <Button
                    key={"file_path" + record.file_name}
                    icon={<Icons type="ai" name="AiOutlineDownload" />}
                    onClick={this.downloadTechdump.bind(this, record.file_name)}
                    size={"small"}
                    disabled={isDownloadDisable}
                  />
                </span>
              )}
            />
          </Table>
        ) : (
          <span>{getCurrentlocaleText("techdump.nodisplay.text")}</span>
        )}
      </div>
    );
  }
}
export default TechDumpData;
