import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import { Radio } from "antd";
import { observer, inject } from "mobx-react";
import {
  getCurrentlocaleText,
  DataSaverAndPodDnsBaseVersion,
  getNodeVersion,
  copyObject,
} from "Core/Utils";
import { randomAlphaNumericString } from "Core/Utils";
import DNSinputBox from "components/serviceTemplates/lib/HtmlElements/custom/DNSinputBox";
import DnsStatus from "components/serviceTemplates/lib/HtmlElements/custom/DnsStatus";
import PopOverHelp from "components/UI-Components/PopOverHelp";
import { computed } from "mobx";
import Twig from "twig";
/*------------------N/W CIDR ----------------------*/
import * as ip from "ip";

/*Define form elements*/
const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const radioStyle = {
  display: "block",
  height: "30px",
  lineHeight: "30px",
};

@inject("UiStore", "InodeViewModel", "NetworkViewModel", "ServiceViewModel")
@observer
export default class DnsComponent extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = this.props.InodeViewModel;
    this.networkViewModel = this.props.NetworkViewModel;
    this.serviceModel = this.props.ServiceViewModel;
    this.state = {
      policyValue: this.props.UiStore.templatingService.dns_policy
        ? this.props.UiStore.templatingService.dns_policy
        : "Default",
      dnsPolicyHelp: null,
      dnsHelp: null,
      errors: { errorList: [], repeatedList: [] },
      errroStatus: "",
      errroMessage: "",
      isCustomToDefault: false,
    };
  }

  componentDidMount() {
    /* initializing Help text */
    let allkeys;
    if (this.props.properties.help) {
      if (Object.keys(this.props.properties.help).length >= 0) {
        allkeys = Object.keys(this.props.properties.help);
        allkeys.forEach(val => {
          if (val.indexOf("dns") >= 0) {
            this.setState({ dnsHelp: this.props.properties.help["dns"] });
          }
          if (val.indexOf("dns_policy") >= 0) {
            this.setState({
              dnsPolicyHelp: this.props.properties.help["dns_policy"],
            });
          }
        });
      }
    }
    if (
      !this.props.editmode &&
      this.props.properties.defaultValue &&
      this.props.properties.defaultValue.dns_policy &&
      this.props.properties.defaultValue.dns
    ) {
      this.props.UiStore.templatingService.dns_policy = this.props.properties.defaultValue.dns_policy;
      this.props.UiStore.templatingService.dns = this.props.properties.defaultValue.dns;
      this.props.updateCurrentValueOnChange(
        this.props.UiStore.templatingService.dns_policy
          ? this.props.UiStore.templatingService.dns_policy
          : this.props.value,
        "dns_policy",
      );
      this.props.updateCurrentValueOnChange(
        this.props.UiStore.templatingService.dns &&
          this.props.UiStore.templatingService.dns.length > 0
          ? this.props.UiStore.templatingService.dns
          : this.props.defaultValue,
        "dns",
      );
    }
    if (this.props.editmode) {
      this.props.updateCurrentValueOnChange(
        this.props.UiStore.templatingService.dns_policy
          ? this.props.UiStore.templatingService.dns_policy
          : this.props.value,
        "dns_policy",
      );
      this.props.updateCurrentValueOnChange(
        this.props.UiStore.templatingService.dns &&
          this.props.UiStore.templatingService.dns.length > 0
          ? this.props.UiStore.templatingService.dns
          : this.props.defaultValue,
        "dns",
      );
    }
  }
  @computed
  get componentVisibility() {
    let visibility = null;
    if (
      this.props.UiStore.computedOutputObject &&
      this.props.UiStore.computedOutputObject.steps
    ) {
      if (this.props.properties.visibility) {
        var template = Twig.twig({
          data: this.props.properties.visibility,
        });
        let computedOutputObject = copyObject(
          this.props.UiStore.computedOutputObject,
        );
        visibility = template.render(computedOutputObject);
        try {
          visibility = JSON.parse(visibility);
        } catch {}
      }
    }
    return visibility ? true : false;
  }

  checkIpV4 = (rule, value, callback, field) => {
    let failMessage = null;

    if (value && ip.isV4Format(value) !== true) {
      failMessage = getCurrentlocaleText(
        "general.invalid.ipv4_address.error_message",
      );
    }

    if (failMessage) {
      callback(failMessage);
    }
    callback();
  };

  checkDNS = (rule, value, callback, field) => {
    if (value) {
      if (
        value.length > 0 &&
        this.state.errors.errorList.length === 0 &&
        this.state.errors.repeatedList.length === 0
      ) {
        callback();
      } else {
        this.props.UiStore.templatingService.dns = undefined;
        callback("  ");
      }
    } else {
      this.props.UiStore.templatingService.dns = undefined;
      callback();
    }
  };

  onChangeValue = e => {
    if (e.target.value === "Default") {
      this.props.referenceForm.resetFields([`${this.props.id}_dns`]);
      this.props.UiStore.templatingService.dns = [];
      if (this.serviceModel.service && this.serviceModel.service.id) {
        this.setState({ isCustomToDefault: true });
      }
    } else {
      if (this.serviceModel.service && this.serviceModel.service.id) {
        this.setState({ isCustomToDefault: false });
      }
    }
    this.props.UiStore.templatingService.dns_policy = e.target.value;
    this.props.updateCurrentValueOnChange(e.target.value, "dns_policy");
    if (e.target.value === "Default") {
      this.props.updateCurrentValueOnChange([], "dns");
    }
  };

  render() {
    const { getFieldDecorator } = this.props.referenceForm; // reference of base form
    let isComponentVisible =
      (this.inodeModel.inode &&
        parseFloat(getNodeVersion(this.inodeModel.inode)) >=
          DataSaverAndPodDnsBaseVersion) ||
      (this.props.urlParams &&
        this.props.urlParams.params &&
        this.props.urlParams.params.cluster_id) ||
      this.inodeModel.inode.node_state === "NEW" ||
      this.props.UiStore.isServicePlayGroundVisible;

    let id = this.props.id
      ? this.props.id
      : `textbox${randomAlphaNumericString(3)}`;

    let disable = false;
    if (this.props.editmode) {
      disable = this.props.properties.noneditable
        ? this.props.properties.noneditable
        : false;
    }
    disable = this.props.properties.disabled
      ? this.props.properties.disabled
      : disable;
    // For View only
    disable = this.props.viewOnly === true ? true : disable;

    let visibility = this.props.properties.visibility
      ? this.componentVisibility
      : true;

    let network_id = null;
    for (var key in this.props.UiStore.templatingService.node) {
      if (
        this.props.UiStore.templatingService.node.hasOwnProperty(key) &&
        this.props.UiStore.templatingService.node[key].network &&
        this.props.UiStore.templatingService.node[key].network.network_id
      ) {
        network_id = this.props.UiStore.templatingService.node[key].network
          .network_id;
      }
    }
    let networks = this.networkViewModel.networks;
    let network = null;
    networks.forEach(x => {
      if (network_id && x.id === network_id) network = x;
    });

    let dns_data =
      this.state.isCustomToDefault === true
        ? []
        : network && network.type && network.type == "DYNAMIC"
        ? this.props.UiStore.templatingService.dns_status
          ? this.props.UiStore.templatingService.dns_status
          : []
        : this.inodeModel.dnsInfo;
    return (
      isComponentVisible &&
      (visibility ? (
        <div>
          <div
            onClick={
              this.props.onFocus &&
              this.props.onFocus.bind(null, this.state.dnsPolicyHelp)
            }
          >
            <FormItem label={getCurrentlocaleText("services.dns_policy.label")}>
              {getFieldDecorator(`${this.props.id}_dns_policy`, {
                rules: [],
                initialValue: this.props.UiStore.templatingService.dns_policy
                  ? this.props.UiStore.templatingService.dns_policy
                  : this.state.policyValue,
              })(
                <RadioGroup
                  onChange={this.onChangeValue}
                  disabled={this.props.viewOnly ? true : false}
                >
                  <Radio value={"Default"}>
                    {getCurrentlocaleText("services.dns_policy.value1.label")}
                  </Radio>
                  <Radio value={"None"}>
                    {getCurrentlocaleText("services.dns_policy.value2.label")}
                  </Radio>
                  <PopOverHelp
                    isHelpOnForm={true}
                    height={100}
                    width={200}
                    placement="right"
                    helpData={{
                      title:
                        this.props.UiStore.templatingService.dns_policy ===
                        "None"
                          ? getCurrentlocaleText(
                              "services.dns_policy.hover_message_custom.title",
                            )
                          : getCurrentlocaleText(
                              "services.dns_policy.hover_message_default.title",
                            ),
                      data: [
                        {
                          subtitle: "",
                          content:
                            this.props.UiStore.templatingService.dns_policy ===
                            "None"
                              ? getCurrentlocaleText(
                                  "services.dns_policy.hover_message_custom.message",
                                )
                              : getCurrentlocaleText(
                                  "services.dns_policy.hover_message_default.message",
                                ),
                        },
                      ],
                    }}
                  />
                </RadioGroup>,
              )}
            </FormItem>
          </div>
          <div
            onClick={
              this.props.onFocus &&
              this.props.onFocus.bind(null, this.state.dnsHelp)
            }
          >
            {this.props.UiStore.templatingService.dns_policy === "None" ? (
              <FormItem label={getCurrentlocaleText("services.dns.label")}>
                {getFieldDecorator(`${this.props.id}_dns`, {
                  rules: [
                    {
                      required: true,
                      message: getCurrentlocaleText(
                        "services.dns.required.error.message",
                      ),
                    },
                    {
                      validator: this.checkDNS,
                    },
                  ],
                  initialValue:
                    this.props.UiStore.templatingService.dns &&
                    this.props.UiStore.templatingService.dns.length > 0
                      ? this.props.UiStore.templatingService.dns
                      : this.props.defaultValue,
                })(
                  <DNSinputBox
                    onChange={(value = []) => {
                      this.props.UiStore.templatingService.dns = value;
                      this.props.properties.inputChange
                        ? this.props.properties.inputChange
                        : this.inputChange;
                      // Dont allow null and empty values
                      if (value && value.length > 0) {
                        value = value.filter(item => {
                          return item && item.trim().length > 0;
                        });
                      }
                      this.props.updateCurrentValueOnChange(value, "dns");
                    }}
                    onError={errors => {
                      this.setState({ errors: errors });
                    }}
                    defaultDNSValue={
                      this.props.UiStore.templatingService.dns
                        ? this.props.UiStore.templatingService.dns
                        : []
                    }
                    isDisabled={
                      this.props.UiStore.templatingService.dns_policy ===
                      "Default"
                        ? true
                        : this.props.viewOnly
                        ? true
                        : false
                    }
                    onFocus={
                      this.props.onFocus &&
                      this.props.onFocus.bind(null, this.state.dnsHelp)
                    }
                  />,
                )}
              </FormItem>
            ) : (
              <FormItem label={getCurrentlocaleText("services.dns.label")}>
                {getFieldDecorator(`status`, {
                  rules: [],
                  initialValue:
                    this.props.UiStore.templatingService.dns_policy ===
                    "Default"
                      ? []
                      : this.props.UiStore.templatingService.dns
                      ? this.props.UiStore.templatingService.dns
                      : this.state.policyValue,
                })(<DnsStatus defaultDNSValue={dns_data} />)}
              </FormItem>
            )}
          </div>
        </div>
      ) : (
        <div></div>
      ))
    );
  }
}

/*
  sample schema
{
    "type": "DnsComponent",
    "visible": true,
    "props": {
        "label": "DNS",
        "noneditable": false,
        "help": {
            "dns": "<div> <h3 style=\"font-weight:bold;\">DNS </h3> Specify the DNS for your network. </div>",
            "dns_policy": "<div> <h3 style=\"font-weight:bold;\">DNS Policy </h3> Specify the DNS Policy for your network. Default is iNodes Default DNS policy </div>"
        }
    }
}


*/
