class SizeUnit {
  formatBytesSize(bytes = 0, decimalPoint = 2) {
    if (bytes == 0) return "0 bytes";
    var k = 1000,
      dm = decimalPoint || 2,
      sizes = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
}
export default new SizeUnit();
