import Resources from "Core/API/Resources";
import BaseController from "controller/BaseController";
import UiStore from "Stores/UiStore";
const RESOURCE = "analyticsSettings";
const SETTINGS_URL = Resources.getUrl(RESOURCE);

class SettingsController {
  /**
   * Get analystics (GA4) settings
   *
   */
  getAnalytics = () => {
    return new Promise((resolve, reject) => {
      BaseController.query(RESOURCE, SETTINGS_URL)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          UiStore.errorMessage =
            error.response &&
            error.response.data &&
            error.response.data.error_message
              ? error.response.data.error_message
              : "Error";
          reject(error);
        });
    });
  };

  removeAnalytics = () => {
    return new Promise((resolve, reject) => {
      let urls = [];
      urls.push(SETTINGS_URL);
      BaseController.delete(urls)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          UiStore.errorMessage =
            error.response &&
            error.response.data &&
            error.response.data.error_message
              ? error.response.data.error_message
              : "Error";
          reject(error);
        });
    });
  };

  /**
   * Add/Update analystics (GA4) settings
   * @param formData
   */
  addAnalytics = formData => {
    return new Promise((resolve, reject) => {
      BaseController.create(SETTINGS_URL, formData, {})
        .then(response => {
          if (response && response.data) {
            resolve(response.data);
          }
          resolve(response);
        })
        .catch(error => {
          UiStore.errorMessage =
            error.response &&
            error.response.data &&
            error.response.data.error_message
              ? error.response.data.error_message
              : "Error";
          reject(error);
        });
    });
  };
}

export default new SettingsController();
