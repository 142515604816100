import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import { Link } from "react-router-dom";
import { Button, Collapse, Select } from "antd";
import Input from "components/UI-Components/InputBox";
import { observer, inject } from "mobx-react";

import {
  focusFirstInput,
  getCurrentlocaleText,
  markCollapseHeader,
  IOTIUM_USER,
  isValidDomainName,
} from "Core/Utils";
import FormErrorMessage from "components/UI-Components/FormErrorMessage";
import TimezoneComponent from "components/Logs/Components/TimezoneComponent";
import OrgController from "controller/OrgController";
import Icons from "../UI-Components/Icons";

const Option = Select.Option;
const FormItem = Form.Item;
const Panel = Collapse.Panel;

@inject("ModelStore", "UiStore", "AuthStore")
@observer
class OrgForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is2FaEnabled: false,
      isView: false,
      headlessEnabled: false,
      initialVal: this.props.initialVal,
      vlanEnabled: false,
      upgradeChannelSelected: "STABLE",
      apikeyEnabled: false,
      blockEditPolicy: false,
      softwareUpgrade: false,
      branding: false,
      oneArmModeEnabled: false,
      cluster: false,
      webhook: false,
      base_fqdn:
        GLOBAL_SETTINGS && GLOBAL_SETTINGS.base_fqdn
          ? GLOBAL_SETTINGS.base_fqdn
          : ".secureedge.view.com",
    };
    this.GLOBAL_SETTINGS = GLOBAL_SETTINGS;
    this.name =
      this.GLOBAL_SETTINGS && this.GLOBAL_SETTINGS.name
        ? this.GLOBAL_SETTINGS.name
        : "View";
  }

  componentDidMount() {
    let blockEditPolicy = this.state.blockEditPolicy;
    // Block org policy edit if logged in user org himself.
    if (
      this.props.AuthStore.loggedInUser &&
      this.props.AuthStore.loggedInUser.organization &&
      this.props.AuthStore.loggedInUser.organization.id &&
      this.props.initialVal &&
      this.props.initialVal.id ===
        this.props.AuthStore.loggedInUser.organization.id
    ) {
      blockEditPolicy = true;
    }
    // Alow org policy edit only if logged in user is IOTIUM Admin plus user
    if (
      this.props.AuthStore.loggedInUser &&
      this.props.AuthStore.loggedInUser.id === IOTIUM_USER
    ) {
      blockEditPolicy = false;
    }
    this.setState({
      isView: this.props.action === "view" ? true : false,
      blockEditPolicy: blockEditPolicy,
    });
    /* Focus First Input */
    focusFirstInput();
    if (this.props.initialVal && this.props.initialVal.id) {
      OrgController.getOrgById(this.props.initialVal.id, true).then(
        response => {
          if (response) {
            this.setState({ initialVal: response });
          }
          if (response && response.policy) {
            let is2FaEnabled,
              headlessEnabled,
              vlanEnabled,
              upgradeChannelSelected,
              apikeyEnabled,
              branding,
              oneArmModeEnabled,
              cluster,
              webhook;
            is2FaEnabled =
              response.policy.two_factor && response.policy.two_factor.enable
                ? true
                : false;
            headlessEnabled =
              response.policy.headless_mode &&
              response.policy.headless_mode.enable
                ? true
                : false;
            vlanEnabled =
              response.policy.vlan_support &&
              response.policy.vlan_support.enable
                ? true
                : false;
            upgradeChannelSelected =
              response.policy &&
              response.policy.software_upgrade &&
              response.policy.software_upgrade.channel;
            apikeyEnabled =
              response.policy.apikey && response.policy.apikey.enable
                ? true
                : false;
            branding =
              response.policy.branding && response.policy.branding.enable
                ? true
                : false;
            (oneArmModeEnabled =
              response.policy.one_arm_mode &&
              response.policy.one_arm_mode.enable
                ? true
                : false),
              (cluster =
                response.policy.cluster && response.policy.cluster.enable
                  ? true
                  : false);
            webhook =
              response.policy.webhook && response.policy.webhook.enable
                ? true
                : false;

            if (
              response.policy.software_upgrade &&
              response.policy.software_upgrade.channel &&
              response.policy.software_upgrade.channel === "OFF"
            ) {
              this.setState({ softwareUpgrade: false });
              upgradeChannelSelected = null;
            } else if (
              response.policy &&
              (!response.policy.software_upgrade ||
                !response.policy.software_upgrade.channel)
            ) {
              // set default value as ON stable for ORG level software upgrade policy on next update
              this.setState({ softwareUpgrade: true });
              this.props.form.setFields({
                upgrade_policy: {
                  value: true,
                },
                upgrade_policy_channel: {
                  value: "STABLE",
                },
              });
            } else {
              this.setState({ softwareUpgrade: true });
            }
            this.setState({
              is2FaEnabled: is2FaEnabled,
              headlessEnabled: headlessEnabled,
              vlanEnabled: vlanEnabled,
              apikeyEnabled: apikeyEnabled,
              upgradeChannelSelected: upgradeChannelSelected,
              branding: branding,
              oneArmModeEnabled: oneArmModeEnabled,
              cluster: cluster,
              webhook: webhook,
            });
            markCollapseHeader(
              "org-advanced-settings-collapse",
              is2FaEnabled ||
                headlessEnabled ||
                vlanEnabled ||
                upgradeChannelSelected ||
                apikeyEnabled ||
                branding ||
                oneArmModeEnabled ||
                cluster,
              webhook,
            );
          }
        },
      );
    } else {
      // set default value as ON stable for ORG level software upgrade policy
      this.setState({ softwareUpgrade: true });
      this.props.form.setFields({
        upgrade_policy: {
          value: true,
        },
        upgrade_policy_channel: {
          value: "STABLE",
        },
      });
    }
  }

  selectedTimezoneValue = value => {
    this.props.form.setFields({
      timezone: {
        value: value,
      },
    });
  };
  upgradeChannelOnSelect = value => {
    this.props.form.setFields({
      upgrade_policy_channel: {
        value: value,
      },
    });

    this.setState({
      upgradeChannelSelected: value,
    });
  };

  changeSoftwareUpdate = () => {
    let softwareUpgrade = !this.state.softwareUpgrade;
    if (softwareUpgrade) {
      this.props.form.setFields({
        upgrade_policy_channel: {
          value: "STABLE",
        },
      });
    } else {
      this.props.form.setFields({
        upgrade_policy_channel: {
          value: null,
        },
      });
    }
    this.props.form.setFields({
      upgrade_policy: {
        value: !this.state.softwareUpgrade,
      },
    });

    this.setState({
      softwareUpgrade: !this.state.softwareUpgrade,
    });
  };

  validateDomainName = (rule, value, callback) => {
    if (value && !isValidDomainName(value)) {
      callback(getCurrentlocaleText("domain.name.error.message"));
    } else {
      callback();
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { isView, blockEditPolicy } = this.state;
    return (
      <Form noValidate onSubmit={this.props.onSubmit} className="login-form">
        <FormItem label="Organization Name" hasFeedback>
          {getFieldDecorator("name", {
            rules: [
              {
                required: true,
                message: "Please enter your organization name.",
                whitespace: true,
              },
              {
                max: 255,
                message:
                  "Organization name cannot be longer than 255 characters.",
              },
              {
                pattern: /^[A-Za-z0-9 .&,_-]*$/,
                message:
                  "Organization name may contain only the special characters . , & - _",
              },
            ],
            initialValue: this.props.initialVal
              ? this.props.initialVal.name
              : "",
          })(
            <Input
              className="firstInput"
              autoComplete="off"
              prefix={<Icons type="ai" name="AiOutlineDatabase" />}
              placeholder="Organization Name"
              disabled={isView}
            />,
          )}
        </FormItem>
        <FormItem label="Billing Name" hasFeedback>
          {getFieldDecorator("billing_name", {
            rules: [
              {
                max: 255,
                message: "Billing name cannot be longer than 255 characters.",
              },
              {
                pattern: /^[A-Za-z0-9 .&,_-]*$/,
                message:
                  "Billing name may contain only the special characters . , & - _",
              },
            ],
            initialValue: this.props.initialVal
              ? this.props.initialVal.billing_name
              : "",
          })(
            <Input
              autoComplete="off"
              prefix={<Icons type="ai" name="AiOutlineUser" />}
              placeholder="Billing Name"
              disabled={isView}
            />,
          )}
        </FormItem>
        <FormItem label="Billing Email" hasFeedback>
          {getFieldDecorator("billing_email", {
            rules: [
              { type: "email", message: "Please enter a valid email address." },
            ],
            initialValue: this.props.initialVal
              ? this.props.initialVal.billing_email
              : "",
          })(
            <Input
              autoComplete="off"
              type="email"
              prefix={<Icons type="ai" name="AiOutlineMail" />}
              placeholder="Billing Email address"
              disabled={isView}
            />,
          )}
        </FormItem>
        <FormItem label={getCurrentlocaleText("domain.name.text")} hasFeedback>
          {getFieldDecorator("domain_name", {
            rules: [
              {
                required: true,
                message: getCurrentlocaleText(
                  "domain.name.required.error.message",
                ),
              },
              {
                min: 3,
                message: getCurrentlocaleText(
                  "domain.name.length.error.message",
                ),
              },
              {
                max: 50,
                message: getCurrentlocaleText(
                  "domain.name.length.error.message",
                ),
              },
              {
                whitespace: true,
                message: getCurrentlocaleText("domain.name.error.message"),
              },
              {
                validator: this.validateDomainName,
              },
            ],
            initialValue:
              this.props.initialVal && this.props.initialVal.domain_name
                ? this.props.initialVal.domain_name
                : undefined,
          })(
            <Input
              autoComplete="off"
              prefix={<Icons type="ai" name="AiOutlineGlobal" />}
              placeholder={getCurrentlocaleText("domain.name.text")}
              disabled={isView}
              addonBefore={window.location.protocol + "//"}
              addonAfter={<div className="mr-15">{this.state.base_fqdn}</div>}
            />,
          )}
        </FormItem>
        <FormItem label="Time Zone" hasFeedback>
          {getFieldDecorator("timezone", {
            initialValue:
              this.props.initialVal && this.props.initialVal.timezone
                ? this.props.initialVal.timezone
                : "",
          })(
            <TimezoneComponent
              placeholder="Time Zone"
              initialValue={
                this.props.initialVal && this.props.initialVal.timezone
                  ? this.props.initialVal.timezone
                  : ""
              }
              selectedValue={this.selectedTimezoneValue}
              style={{}}
              defaultTimezone={"browser"}
              disabled={isView}
            />,
          )}
        </FormItem>

        {this.props.AuthStore.IsPermitted("ORG:VIEW") &&
          this.props.initialVal &&
          this.props.initialVal.id && (
            <Link
              target={"_blank"}
              to={
                isView
                  ? "/dashboard/" + this.props.initialVal.id + "/view/policies"
                  : "/dashboard/" + this.props.initialVal.id + "/policies"
              }
            >
              Advanced Settings
              <Icons
                type="fa"
                name="FaAngleDoubleRight"
                style={{ marginLeft: "5px" }}
              />
            </Link>
          )}
        <Button className="hidden-submit" htmlType="submit" />
        <FormErrorMessage />
      </Form>
    );
  }
}

const OrgsForm = Form.create()(OrgForm);
export default OrgsForm;
