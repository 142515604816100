import React, { Component } from "react";
import { Button } from "antd";
import UserController from "controller/UserController";
import Icons from "./Icons";

class UnlockUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: this.props.userId,
    };
  }

  openLockedUser = () => {
    UserController.openLockedUser({ id: this.state.userId });
  };

  render() {
    return (
      <div>
        <Button onClick={this.openLockedUser.bind(this)} title="Unlock User">
          <Icons type="ai" style={{ margin: 0 }} name="AiOutlineUnlock" />
        </Button>
      </div>
    );
  }
}
export default UnlockUser;
