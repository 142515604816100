import React, { Component } from "react";
import { Row, Col } from "antd";
import { inject } from "mobx-react";
import { isEmpty, copyObject } from "Core/Utils";
import { computed } from "mobx";
import License from "./License";
import SSH from "./ssh";
/*Import html elements*/
import TextBox from "../common/TextBox";
import Twig from "twig";

@inject("UiStore", "SecretViewModel", "ServiceViewModel", "InodeViewModel")
export default class Secret extends Component {
  constructor(props) {
    super(props);
    this.emptyDirHelpText = {
      mountpath: "",
      name: "",
    };
  }

  componentDidMount() {
    /*  initialize license help text */
    if (this.props.properties.help && this.props.properties.help["emptydir"]) {
      let helpText = this.props.properties.help["emptydir"];
      this.emptyDirHelpText = {
        mountpath: helpText["mountpath"] ? helpText["mountpath"] : "",
        name: helpText["name"] ? helpText["name"] : "",
      };
    }
    if (
      this.props.properties &&
      this.props.properties.defaultValue &&
      this.props.properties.defaultValue.id &&
      !this.props.editmode &&
      !this.props.viewmode
    ) {
      this.props.UiStore.templatingService.secrets[this.props.id] = {
        previousSelectedLicense: this.props.properties.defaultValue.id,
      };
      this.props.updateCurrentValueOnChange(
        this.props.properties.defaultValue.name,
        "name",
      );
      this.props.updateCurrentValueOnChange(
        this.props.properties.defaultValue.id,
        "id",
      );
      this.props.updateCurrentValueOnChange(
        this.props.properties.defaultValue.mountpath,
        "mount_path",
      );
    }
  }

  @computed
  get componentVisibility() {
    let visibility = null;
    if (
      this.props.UiStore.computedOutputObject &&
      this.props.UiStore.computedOutputObject.steps
    ) {
      if (this.props.properties.visibility) {
        var template = Twig.twig({
          data: this.props.properties.visibility,
        });
        let computedOutputObject = copyObject(
          this.props.UiStore.computedOutputObject,
        );
        visibility = template.render(computedOutputObject);
        try {
          visibility = JSON.parse(visibility);
        } catch {}
      }
    }
    return visibility ? true : false;
  }

  inputName = e => {
    /*clear selected license from drop-down if new license is created*/
    if (!isEmpty(this.props.UiStore.templatingService.secrets[this.props.id])) {
      this.props.UiStore.templatingService.secrets[
        this.props.id
      ].previousSelectedLicense = null;
    }
  };

  render() {
    let required = this.props.properties.constraints
      ? this.props.properties.constraints.required
      : false;

    let visibility = this.props.properties.visibility
      ? this.componentVisibility
      : true;
    return visibility ? (
      <div>
        {this.props.properties.subtype.toLowerCase() === "emptydir" && (
          <Row gutter={24} type="flex">
            <Col span={10}>
              <TextBox
                referenceForm={this.props.referenceForm}
                id={`${this.props.id}_name`}
                onFocus={this.props.onFocus}
                properties={{
                  label: "Volume Name",
                  placeholder: "Volume Name",
                  defaultValue: "",
                  disabled: this.props.viewOnly === true ? true : false,
                  constraints: {
                    required: required,
                    validationMessage: "Please enter a volume name",
                  },

                  inputChange: this.inputName,
                  help: this.emptyDirHelpText["name"],
                }}
              />
            </Col>
            <Col span={12}>
              <TextBox
                referenceForm={this.props.referenceForm}
                id={`${this.props.id}_mountPath`}
                onFocus={this.props.onFocus}
                properties={{
                  label: "Volume mountPath",
                  placeholder: "Volume mountPath",
                  disabled:
                    this.props.viewOnly === true
                      ? true
                      : this.props.properties.disabled,
                  defaultValue: this.props.properties.mountpath
                    ? this.props.properties.mountpath
                    : "",
                  constraints: {
                    required: required,
                    validationMessage: "Please give a path for volume mount",
                  },
                  help: this.emptyDirHelpText["mountpath"],
                }}
              />
            </Col>
          </Row>
        )}
        {this.props.properties.subtype.toLowerCase() === "license" && (
          <License
            showUnassigned={this.props.properties.showUnassigned}
            constraints={required}
            referenceForm={this.props.referenceForm}
            updateCurrentValueOnChange={value => {
              this.props.updateCurrentValueOnChange(value, "secret");
            }}
            {...this.props}
            className={this.props.className ? this.props.className : ""}
          />
        )}
        {this.props.properties.subtype.toLowerCase() === "ssh" && (
          <SSH
            referenceForm={this.props.referenceForm}
            updateCurrentValueOnChange={value => {
              this.props.updateCurrentValueOnChange(value, "secret");
            }}
            {...this.props}
            className={this.props.className ? this.props.className : ""}
          />
        )}
      </div>
    ) : (
      <span></span>
    );
  }
}

/*
  sample schema

  {
    "type": "Secret",
    "visible": true,
    "props": {
      "subtype": "emptyDir",
      "disabled": true,
      "mountpath": "/root/.ssh_new",
      "help": {
        "emptydir": {
          "name": "<div>Specify a name for the emptydir </div>",
          "mountpath": "some mountpath text for  emptydir "
        }

      }
    }
  }

*/
