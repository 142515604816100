import React, { Component } from "react";
import { Card, Row, Col } from "antd";
import { observer, inject } from "mobx-react";
import axios from "axios";

import VerifyEmailForm from "components/Containers/VerifyEmailForm";

import MessageDisplay from "Core/MessageDisplay";
import Icons from "components/UI-Components/Icons";
import { getCurrentlocaleText, isLoggedIn } from "Core/Utils";

import DarkAppInfo from "components/UI-Components/DarkAppInfo";

import IoFooter from "components/layout//Footer";
// Reactive Login Page
@inject("UiStore", "ModelStore", "AuthStore")
@observer
class VerifyEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isrequestTriggered: false,
      disableSubmit: false,
      isRequestSuccess: false,
      isRequestFailed: false,
      isLinkExpired: false,
    };
  }

  UNSAFE_componentWillMount() {
    if (!isLoggedIn) {
      this.VerifyEmailReq({}, true);
    }
  }
  //Verify Email api call
  VerifyEmailReq = (formData, initial = false, recaptchaRef = null) => {
    this.setState({ disableSubmit: true });
    let token =
      this.props &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.token
        ? this.props.match.params.token
        : null;
    if (token) {
      let loginReq = axios.post(
        "/api/v1/verifyemail?temp_token=" + token,
        formData,
      );
      loginReq
        .then(response => {
          let Logout = axios.post("/api/v1/logout");
          Logout.then(response => {
            localStorage.removeItem("currentOrgId");
            // just skip
          }).catch(error => {
            console.log("logout failed on remote server.");
            console.log(error.response.data);
          });

          // whatever happens clears client data
          this.props.ModelStore.Logout(this.props.history);
          this.props.UiStore.SetLoginStatus(false);
          this.props.AuthStore.resetStore();
          if (this.props.history) {
            this.props.history.push("/login");
            this.props.UiStore.successMessage = getCurrentlocaleText(
              "user.verify_email_form.success_message",
            );
            this.props.UiStore.isForceMessage = true;
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            if (this.props.history) {
              // whatever happens clears client data
              this.props.ModelStore.Logout(this.props.history);
              this.props.history.push("/login");
              this.props.UiStore.errorMessage = getCurrentlocaleText(
                "user.verify_email_form.failure_message",
              );
              this.props.UiStore.isForceMessage = true;
            }
          } else if (!initial) {
            this.setState({
              isrequestTriggered: true,
              isRequestFailed: true,
              isRequestSuccess: false,
              disableSubmit: false,
              isLinkExpired: false,
              failureMessage:
                error.response &&
                error.response.data &&
                error.response.data.message
                  ? error.response.data.message
                  : getCurrentlocaleText(
                      "user.reset_password_form.info_message2",
                    ),
            });
          }
          if (this.verifyEmailForm) {
            try {
              this.verifyEmailForm.resetFields(["captcha"]);
            } catch (e) {}
          }
          if (recaptchaRef) {
            try {
              recaptchaRef.reset();
            } catch (e) {}
          }
        });
    }
  };

  render() {
    let {
      isrequestTriggered,
      disableSubmit,
      isRequestSuccess,
      isRequestFailed,
      isLinkExpired,
    } = this.state;
    return (
      <div>
        <MessageDisplay />
        <Row type="flex" className="login-card" align="middle" justify="center">
          <Col
            className="login-col"
            lg={{ span: 7 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            xs={{ span: 16 }}
          >
            <div className="login-card-layout-container">
              <center>
                <DarkAppInfo />
              </center>
            </div>
            <Card bordered={false}>
              <center>
                {isrequestTriggered &&
                  (isRequestSuccess ? (
                    <Icons
                      type="ai"
                      name="AiOutlineCheckCircle"
                      style={{ fontSize: 40, color: "green" }}
                    />
                  ) : (
                    <Icons
                      style={{ fontSize: 40, color: "red" }}
                      type="md"
                      name="MdErrorOutline"
                    />
                  ))}
                <h1
                  style={{
                    paddingTop: 20,
                    paddingBottom: 20,
                    color: isRequestSuccess
                      ? "green"
                      : isRequestFailed
                      ? "red"
                      : "",
                    fontSize: 15,
                  }}
                >
                  {!isrequestTriggered
                    ? getCurrentlocaleText(
                        "user.verify_email_form.initial_message",
                      )
                    : getCurrentlocaleText(
                        "user.verify_email_form.failure_message",
                      )}
                </h1>
                {!isrequestTriggered && (
                  <span style={{ fontSize: 14 }}>
                    {getCurrentlocaleText(
                      "user.verify_email_form.reference_message",
                    )}
                  </span>
                )}
                {!isrequestTriggered && (
                  <VerifyEmailForm
                    VerifyEmail={this.VerifyEmailReq}
                    disable={this.state.disableSubmit}
                    ref={c => (this.verifyEmailForm = c)}
                  />
                )}
              </center>
            </Card>
          </Col>
        </Row>
        <IoFooter
          isNotChrome={this.props.isNotChrome}
          centerAlignFooter={true}
        />
      </div>
    );
  }
}
export default VerifyEmail;
