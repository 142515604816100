import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Row, Col } from "antd";
import "../../../assets/styles/DashboardHeader.css";
import OrgSelector from "components/Orgs/OrgSelector";
import OrgController from "controller/OrgController";

@inject("AuthStore", "DashboardStore", "OrgViewModel")
@observer
class Header extends Component {
  constructor(props) {
    super(props);
    this.color = {};
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.match.params && props.match.params.id) {
      // if org id changes or force update required it will be called
      if (this.props.match.params.id !== props.match.params.id) {
        this.updateOrgModel(props.match.params.id);
      }
    } else if (
      this.props.match.params.id &&
      props.match.params &&
      !props.match.params.id
    ) {
      this.updateOrgModel("all_orgs");
    }
  }

  UNSAFE_componentWillMount() {
    if (this.props.match.params && this.props.match.params.id) {
      this.updateOrgModel(this.props.match.params.id);
    } else {
      this.updateOrgModel("all_orgs");
    }
  }

  // this will update/reset the current selected org
  updateOrgModel = org_value => {
    return new Promise((resolve, reject) => {
      //clears the org model changes the orgselector value
      if (org_value === "all_orgs") {
        this.props.OrgViewModel.resetOrg();
        resolve(org_value);
      } else {
        //updates the org-model based on currently selected organiazation
        OrgController.getOrg(org_value, false)
          .then(response => {
            if (response && response.parent_organization) {
              this.props.UiStore.parentOrgData = response.parent_organization;
            } else {
              this.props.UiStore.parentOrgData = {};
            }
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      }
    });
  };

  render() {
    return (
      <div>
        <Row gutter={16}>
          <Col
            lg={{ span: 24 }}
            xl={{ span: 24 }}
            className="flex flex-h-right d-flex"
            align="right"
          >
            <OrgSelector
              className="org-selector-menu-padding"
              {...this.props}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default Header;
