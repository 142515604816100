import React, { Component } from "react";
import { message } from "antd";
import { observer, inject } from "mobx-react";

@inject("UiStore")
@observer
class InfoMessageDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      infoMsg: null,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.displayInfoMessage(props.message);
  }

  componentDidMount() {
    this.setState({ infoMsg: this.props.message });
    this.displayInfoMessage(this.props.message);
  }

  displayInfoMessage(info_message) {
    if (info_message && info_message !== this.state.infoMsg) {
      let msg_content = <span style={{ fontSize: 14 }}>{info_message}</span>;
      /* 
        https://github.com/ant-design/ant-design/issues/7793#issuecomment-354249673
      */
      setTimeout(() => {
        message.info(msg_content, 8, () => {
          this.props.UiStore.setInfoMessage(null);
        });
      }, 100);
    }
  }

  render() {
    return null;
  }
}

export default InfoMessageDisplay;
