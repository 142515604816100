import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import { Row, Col, Select, Spin, Radio } from "antd";
import { inject } from "mobx-react";
import _ from "lodash";
import {
  isEmpty,
  getCurrentlocaleText,
  copyObject,
  toTitleCase,
} from "Core/Utils";
import { computed } from "mobx";
import Twig from "twig";
/*Import html elements*/
import TextBox from "../common/TextBox";
import FileUpload from "../common/FileUpload";
import RadioBox from "../common/RadioBox";
import SecretList from "./SecretList";

const FormItem = Form.Item;
const Option = Select.Option;
const RadioGroup = Radio.Group;

@inject("UiStore", "SecretViewModel", "ServiceViewModel", "InodeViewModel")
export default class License extends Component {
  constructor(props) {
    super(props);
    this.secretModel = this.props.SecretViewModel;
    this.serviceModel = this.props.ServiceViewModel;
    this.inodeModel = this.props.InodeViewModel;
    this.searchTimeout = null;
    this.state = {
      data: [],
      licenseType: null,
      secretDataList: [],
      showmountpath: false,
      loading: true,
    };

    this.licenseHelpText = {
      mountpath: "",
      name: "",
      new: "",
      previous: "",
      fileupload: "",
    };
  }

  componentDidUpdate(newprops, prevStates) {
    if (this.props != newprops) {
      //LAT-13011 delay is required when same comoponent used twice due to props change
      //TS: TODO find better way to avoid this delay ( handling the props change from parent to child properly)

      setTimeout(() => {
        let secrets = copyObject(newprops.UiStore.templatingService.secrets);
        let secretValue = secrets[newprops.id.trim()]
          ? secrets[newprops.id.trim()].previousSelectedLicense
          : null;
        /* Editing the service. update with previous license when secret value exist*/
        secretValue &&
          this.setState({ licenseType: "PREVIOUS", showmountpath: true });
        if (
          !newprops.urlParams.params.spec_id &&
          newprops.properties.defaultValue &&
          newprops.properties.defaultValue.id &&
          newprops.properties.defaultValue.id != ""
        ) {
          this.setState({ licenseType: "PREVIOUS", showmountpath: true });
        }
      }, 500);
    }
  }

  @computed
  get componentVisibility() {
    let visibility = null;
    if (
      this.props.UiStore.computedOutputObject &&
      this.props.UiStore.computedOutputObject.steps
    ) {
      if (this.props.properties.visibility) {
        var template = Twig.twig({
          data: this.props.properties.visibility,
        });
        let computedOutputObject = copyObject(
          this.props.UiStore.computedOutputObject,
        );
        visibility = template.render(computedOutputObject);
        try {
          visibility = JSON.parse(visibility);
        } catch {}
      }
    }
    return visibility ? true : false;
  }

  componentDidMount() {
    let secrets = copyObject(this.props.UiStore.templatingService.secrets);
    let secretValue = secrets[this.props.id.trim()]
      ? secrets[this.props.id.trim()].previousSelectedLicense
      : null;
    /* Editing the service. update with previous license when secret value exist*/
    secretValue &&
      this.setState({ licenseType: "PREVIOUS", showmountpath: true });

    if (
      !this.props.urlParams.params.spec_id &&
      this.props.properties.defaultValue &&
      this.props.properties.defaultValue.id &&
      this.props.properties.defaultValue.id != ""
    ) {
      this.setState({ licenseType: "PREVIOUS", showmountpath: true });
    }

    /*  initialize license help text */
    if (this.props.properties.help && this.props.properties.help["license"]) {
      let helpText = this.props.properties.help["license"];
      this.licenseHelpText = {
        mountpath: helpText["mountpath"] ? helpText["mountpath"] : "",
        previous: helpText["previous"] ? helpText["previous"] : "",
        new: helpText["new"] ? helpText["new"] : "",
        name: helpText["name"] ? helpText["name"] : "",
        fileupload: helpText["fileupload"] ? helpText["fileupload"] : "",
      };
    }
  }

  inputName = e => {
    /*clear selected license from drop-down if new license is created*/
    if (!isEmpty(this.props.UiStore.templatingService.secrets[this.props.id])) {
      this.props.UiStore.templatingService.secrets[
        this.props.id
      ].previousSelectedLicense = null;
    }
  };

  selectlicensetype = type => {
    this.setState({ licenseType: type, showmountpath: true });
    this.props.ServiceViewModel.isNewLicsense = type;
    this.props.ServiceViewModel.isNewLicsenseV2[this.props.id] = type;
    if (type === "NEW") {
      this.props.updateCurrentValueOnChange(null, "name");
      this.props.updateCurrentValueOnChange(null, "id");
      this.props.updateCurrentValueOnChange(null, "mount_path");
    }
  };

  selectedValue = (e, type = "volume") => {
    let keys = Object.keys(this.props.UiStore.templatingService.secrets);
    keys.indexOf(this.props.id) < 0
      ? (this.props.UiStore.templatingService.secrets[this.props.id] = {})
      : /*clear uploaded license if previous license selected from drop-down*/
        (this.props.UiStore.templatingService.secrets[
          this.props.id
        ].secret_volume = {});

    this.props.UiStore.templatingService.secrets[
      this.props.id
    ].previousSelectedLicense = e;
    let in_dx = this.secretModel.secretsObj[type].findIndex(object => {
      return object.id === e;
    });
    if (in_dx != -1) {
      this.props.updateCurrentValueOnChange(
        this.secretModel.secretsObj[type][in_dx].name,
        "name",
      );
      this.props.updateCurrentValueOnChange(e, "id");
      this.props.updateCurrentValueOnChange(
        this.props.properties.mountpath,
        "mount_path",
      );
    }
  };

  renderList = () => {
    let isBase64EncodingRequired = this.props.properties.options
      .isBase64EncodingRequired
      ? this.props.properties.options.isBase64EncodingRequired
      : true;
    let display_name =
      this.props &&
      this.props.properties &&
      this.props.properties.display_props &&
      this.props.properties.display_props.display_name
        ? this.props.properties.display_props.display_name
        : "License";

    let secrets = copyObject(this.props.UiStore.templatingService.secrets);
    let secretValue = secrets[this.props.id.trim()]
      ? secrets[this.props.id.trim()].previousSelectedLicense
      : null;

    // For Custom label
    let custom_label =
      this.props &&
      this.props.properties &&
      this.props.properties.display_props &&
      this.props.properties.display_props.custom_label;
    // Header label & error message
    let header_label = custom_label && custom_label.label;
    let general_error_message = custom_label && custom_label.error_message;
    // RadioButton Option1 labels & error messages
    let radiobutton1_label =
      custom_label &&
      custom_label.radiobutton1 &&
      custom_label.radiobutton1.label;
    let radiobutton1_textbox_label =
      custom_label &&
      custom_label.radiobutton1 &&
      custom_label.radiobutton1.textbox &&
      custom_label.radiobutton1.textbox.label;
    let radiobutton1_textbox_error_message =
      custom_label &&
      custom_label.radiobutton1 &&
      custom_label.radiobutton1.textbox &&
      custom_label.radiobutton1.textbox.error_message;
    let radiobutton1_uploader_label =
      custom_label &&
      custom_label.radiobutton1 &&
      custom_label.radiobutton1.uploader &&
      custom_label.radiobutton1.uploader.label;
    let radiobutton1_uploader_error_message =
      custom_label &&
      custom_label.radiobutton1 &&
      custom_label.radiobutton1.uploader &&
      custom_label.radiobutton1.uploader.error_message;
    // RadioButton Option2 labels && error message
    let radiobutton2_label =
      custom_label &&
      custom_label.radiobutton2 &&
      custom_label.radiobutton2.label;
    let radiobutton2_selectbox_label =
      custom_label &&
      custom_label.radiobutton2 &&
      custom_label.radiobutton2.selectbox &&
      custom_label.radiobutton2.selectbox.label;
    let radiobutton2_selectbox_error_message =
      custom_label &&
      custom_label.radiobutton2 &&
      custom_label.radiobutton2.selectbox &&
      custom_label.radiobutton2.selectbox.error_message;
    // Textbox label & error message
    let textbox_label =
      custom_label && custom_label.textbox && custom_label.textbox.label;
    let textbox_error_message =
      custom_label &&
      custom_label.textbox &&
      custom_label.textbox.error_message;

    return (
      <div>
        <Row gutter={24}>
          {(this.props &&
            this.props.urlParams &&
            this.props.urlParams.params &&
            this.props.urlParams.params.spec_id &&
            this.state.licenseType != null) ||
          !(
            this.props &&
            this.props.urlParams &&
            this.props.urlParams.params &&
            this.props.urlParams.params.spec_id
          ) ||
          !secretValue ? (
            <Col span={24}>
              <RadioBox
                id={`${this.props.id}_selectlicensetype`}
                referenceForm={this.props.referenceForm}
                onFocus={
                  !this.state.licenseType
                    ? this.props.onFocus.bind(
                        null,
                        getCurrentlocaleText(
                          "servicetemplates.license.defaulr.help",
                          { 0: display_name },
                        ),
                      )
                    : this.props.onFocus
                }
                className={this.props.className ? this.props.className : ""}
                properties={{
                  label: header_label
                    ? header_label
                    : getCurrentlocaleText("servicetemplates.license.label", {
                        0: display_name,
                      }) + ":",
                  onChange: this.selectlicensetype,
                  defaultValue:
                    secretValue ||
                    (this.props.properties.defaultValue &&
                      this.props.properties.defaultValue.id &&
                      this.props.properties.defaultValue.id != "")
                      ? "PREVIOUS"
                      : "",
                  disabled: this.props.viewOnly === true ? true : false,
                  options: {
                    list: [
                      {
                        value: "NEW",
                        label: radiobutton1_label
                          ? radiobutton1_label
                          : getCurrentlocaleText(
                              "servicetemplates.license.upload.label",
                              { 0: display_name },
                            ),
                      },
                      {
                        value: "PREVIOUS",
                        label: radiobutton2_label
                          ? radiobutton2_label
                          : getCurrentlocaleText(
                              "servicetemplates.license.existing.label",
                              { 0: display_name },
                            ),
                      },
                    ],
                  },
                  constraints: {
                    required: this.props.constraints,
                    validationMessage: general_error_message
                      ? general_error_message
                      : getCurrentlocaleText(
                          "servicetemplates.license.previous.validation.msg",
                          { 0: display_name },
                        ),
                  },
                  help: this.licenseHelpText,
                }}
              />
              <Row>
                <Col span={10}>
                  {this.state.showmountpath && (
                    <div
                      onFocus={this.props.onFocus}
                      style={{ display: "none" }}
                    >
                      <TextBox
                        referenceForm={this.props.referenceForm}
                        id={`${this.props.id}_mountPath`}
                        onFocus={this.props.onFocus}
                        properties={{
                          label: toTitleCase(
                            textbox_label
                              ? textbox_label
                              : getCurrentlocaleText(
                                  "servicetemplates.license.mount_path.label",
                                  { 0: display_name },
                                ),
                          ),
                          placeholder: toTitleCase(
                            textbox_label
                              ? textbox_label
                              : getCurrentlocaleText(
                                  "servicetemplates.license.mount_path.label",
                                  { 0: display_name },
                                ),
                          ),
                          disabled:
                            this.props.viewOnly === true
                              ? true
                              : this.props.properties.disabled,
                          defaultValue: this.props.properties.mountpath
                            ? this.props.properties.mountpath
                            : "",
                          constraints: {
                            required: this.props.constraints,
                            validationMessage: textbox_error_message
                              ? textbox_error_message
                              : getCurrentlocaleText(
                                  "servicetemplates.ssh.mountpath.validation.msg",
                                ),
                          },
                          help: this.licenseHelpText["mountpath"],
                        }}
                      />
                    </div>
                  )}
                </Col>
              </Row>

              <Row gutter={24}>
                {this.state.licenseType === "NEW" && (
                  <>
                    <Col span={10}>
                      <TextBox
                        referenceForm={this.props.referenceForm}
                        id={`${this.props.id}_name`}
                        onFocus={this.props.onFocus}
                        properties={{
                          label: toTitleCase(
                            radiobutton1_textbox_label
                              ? radiobutton1_textbox_label
                              : getCurrentlocaleText(
                                  "servicetemplates.license.create_new.label",
                                  { 0: display_name },
                                ),
                          ),
                          placeholder: toTitleCase(
                            radiobutton1_textbox_label
                              ? radiobutton1_textbox_label
                              : getCurrentlocaleText(
                                  "servicetemplates.license.create_new.label",
                                  { 0: display_name },
                                ),
                          ),
                          disabled: this.props.viewOnly === true ? true : false,
                          defaultValue: "",
                          constraints: {
                            required: this.props.constraints,
                            validationMessage: radiobutton1_textbox_error_message
                              ? radiobutton1_textbox_error_message
                              : getCurrentlocaleText(
                                  "servicetemplates.license.name.validation.msg",
                                  { 0: display_name },
                                ),
                          },
                          inputChange: this.inputName,
                          help: this.licenseHelpText["name"],
                        }}
                        updateCurrentValueOnChange={value => {
                          this.props.updateCurrentValueOnChange(
                            value,
                            "custom_lics_name",
                          );
                        }}
                      />
                    </Col>
                    <Col span={10} push={1}>
                      <FileUpload
                        //id="licenseUploader"
                        id={`${this.props.id}_uploader`}
                        onFocus={this.props.onFocus}
                        referenceForm={this.props.referenceForm}
                        properties={{
                          label: toTitleCase(
                            radiobutton1_uploader_label
                              ? radiobutton1_uploader_label
                              : getCurrentlocaleText(
                                  "servicetemplates.license.uploadfiles.label",
                                  { 0: display_name },
                                ),
                          ),
                          disabled: this.props.viewOnly === true ? true : false,
                          constraints: {
                            required: this.props.constraints,
                            validationMessage: radiobutton1_uploader_error_message
                              ? radiobutton1_uploader_error_message
                              : getCurrentlocaleText(
                                  "servicetemplates.license.upload.validation.msg",
                                  { 0: display_name },
                                ),
                          },
                          options: {
                            multiple: true,
                            isBase64EncodingRequired: isBase64EncodingRequired,
                          },
                          help: this.licenseHelpText["fileupload"],
                        }}
                        maxSize={1048576}
                        updateCurrentValueOnChange={value => {
                          this.props.updateCurrentValueOnChange(
                            value,
                            "custom_lics_content",
                          );
                        }}
                      />
                    </Col>
                  </>
                )}

                <Col span={12}>
                  {this.state.licenseType === "PREVIOUS" && (
                    <div>
                      <SecretList
                        showUnassigned={this.props.showUnassigned}
                        referenceForm={this.props.referenceForm}
                        id={`${this.props.id}`}
                        defaultValue=""
                        onFocus={this.props.onFocus}
                        org_id={
                          this.props.urlParams.params.org_id
                            ? this.props.urlParams.params.org_id
                            : this.props.UiStore.currentUser.organization.id
                        }
                        urlParams={this.props.urlParams}
                        properties={{
                          label: toTitleCase(
                            radiobutton2_selectbox_label
                              ? radiobutton2_selectbox_label
                              : getCurrentlocaleText(
                                  "servicetemplates.license.select_files.label",
                                  { 0: display_name },
                                ),
                          ),
                          disabled: this.props.viewOnly === true ? true : false,
                          selectedValue: e => {
                            this.selectedValue(e, "volume");
                          },
                          options: {
                            mode: "",
                            type: "license",
                          },
                          constraints: {
                            required: this.props.constraints,
                            validationMessage: radiobutton2_selectbox_error_message
                              ? radiobutton2_selectbox_error_message
                              : getCurrentlocaleText(
                                  "servicetemplates.license.previous.validation.msg",
                                  { 0: display_name },
                                ),
                          },
                          help: this.licenseHelpText["previous"],
                        }}
                      />
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          ) : (
            <Col span={24}>
              <div className="chart-spinner">
                <Spin tip="Loading..." />
              </div>
            </Col>
          )}
        </Row>
      </div>
    );
  };

  render() {
    let visibility = this.props.properties.visibility
      ? this.componentVisibility
      : true;
    return visibility ? <div>{this.renderList()}</div> : <div></div>;
  }
}

/*
  sample schema

  {
    "type": "Secret",
    "visible": true,
    "props": {
      "subtype": "license",
      "disabled": true,
      "display_props" : {
        "display_name": "License",
        "custom_label": {
          "label": "A config file is required to run this service. Please select a config file",
          "error_message": "Please select config file",
          "radiobutton1": {
            "label": "Select new config file",
            "textbox": {
              "label": "Config file name",
              "error_message": "Please enter a config file name"
            },
            "uploader": {
              "label": "Upload a config file",
              "error_message": "Please upload config file"
            }
          },
          "radiobutton2": {
            "label": "Select existing config file",
            "selectbox": {
              "label": "Select a config file",
              "error_message": "Please select a config file"
            }
          },
          "textbox": {
            "label": "Mount Path",
            "error_message": "Error in mount path"
          }
        }
      }
      "help" : {
        "license" : {
          "previous" : "select from previous license",
          "new" : "upload a new license",
          "name" : "<div>Specify a name for the License </div>",
          "fileupload" : "Upload a valid license file ",
          "mountpath" : "some text "
        }

      },
      "mountpath": "/skyspark/var/lic",
      "options": {
        "isBase64EncodingRequired": true
      }
    }
  }


*/
