import React, { Component } from "react";
import { Alert } from "antd";
import { getCurrentlocaleText, isChrome } from "Core/Utils";

class BrowserCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  componentDidMount() {
    var chrome = isChrome();
    if (!chrome) {
      this.setState({ visible: true });
    }
    this.updateHelpText();
  }

  handleOk = () => {
    this.setState({
      visible: false,
    });
  };

  updateHelpText = () => {
    setTimeout(() => {
      let elm = document.querySelector(
        ".browser-check-action-btn .ant-alert-description",
      );
      if (elm) {
        elm.innerHTML = getCurrentlocaleText("browser.invalid.error.message");
      }
    }, 100);
  };

  render() {
    return (
      <>
        {this.state.visible ? (
          <div
            className="browser-check-action-btn"
            style={{
              textAlign: "center",
            }}
          >
            <Alert
              description={getCurrentlocaleText(
                "browser.invalid.error.message",
              )}
              closable
            />
          </div>
        ) : null}
      </>
    );
  }
}

export default BrowserCheck;
