import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Row, Col, Card } from "antd";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import CountElements from "components/Dashboard/CountElements";
import Charts from "components/Dashboard/Charts";
import NodeProfiles from "components/Dashboard/NodeProfiles";
import DashboardHeader from "components/Dashboard/components/DashboardHeader";
// Recent Activity component
import RecentActivity from "components/Dashboard/RecentActivity";
import { getCurrentlocaleText } from "Core/Utils";
import Icons from "components/UI-Components/Icons";
import Threats from "../Dashboard/Threats";
import OrgController from "controller/OrgController";
import DashboardMap from "./DashboardMap";
import DashboardController from "../../controller/DashboardController";

@inject("UiStore", "AuthStore", "DashboardStore", "OrgViewModel")
@observer
class Dashboards extends Component {
  constructor(props) {
    super(props);
    this.dashboardStore = this.props.DashboardStore;
    this.state = {
      BreadcrumbList: [{ name: "home", link: "dashboard" }],
      allDetailsLoading: true,
      allDetails: {},
    };
    this.currentOrgId =
      props.match.params && props.match.params.id
        ? props.match.params.id
        : props.match &&
          props.match.path != "/home" &&
          this.props.OrgViewModel &&
          this.props.OrgViewModel.org &&
          this.props.OrgViewModel.org.id != undefined
        ? this.props.OrgViewModel.org.id
        : this.props.AuthStore &&
          this.props.AuthStore.loggedInUser &&
          this.props.AuthStore.loggedInUser.organization &&
          this.props.AuthStore.loggedInUser.organization.id;
    if (this.currentOrgId) {
      OrgController.getTrafficInsightConfigStatus(this.currentOrgId);
      this.getDashboardDetails(this.currentOrgId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.match &&
      this.props.match.params &&
      prevProps.match.params.id != this.props.match.params.id
    ) {
      this.currentOrgId =
        this.props.match.params && this.props.match.params.id
          ? this.props.match.params.id
          : this.props.match &&
            this.props.match.path != "/home" &&
            this.props.OrgViewModel &&
            this.props.OrgViewModel.org &&
            this.props.OrgViewModel.org.id
          ? this.props.OrgViewModel.org.id
          : this.props.AuthStore &&
            this.props.AuthStore.loggedInUser &&
            this.props.AuthStore.loggedInUser.organization &&
            this.props.AuthStore.loggedInUser.organization.id;
      this.props.UiStore.currentRouteLocation = this.props.match.path;
      this.getDashboardDetails(this.currentOrgId);
    }
  }

  getDashboardDetails = currentOrgId => {
    this.setState({
      allDetailsLoading: true,
    });
    DashboardController.getDashboardDetails(currentOrgId)
      .then(resp => {
        this.setState({
          allDetailsLoading: false,
          allDetails: resp,
        });
      })
      .catch(err => {
        this.setState({
          allDetailsLoading: false,
          allDetails: {},
        });
      });
  };

  render() {
    if (
      this.props.AuthStore.loggedInUser &&
      this.props.AuthStore.loggedInUser.organization
    ) {
    }

    let trafficInsightconfigStatus = this.props.OrgViewModel
      .orgTrafficInsightConfig;
    let trafficInsightConfig =
      trafficInsightconfigStatus &&
      trafficInsightconfigStatus.threat_detection_enable &&
      trafficInsightconfigStatus.threat_detection_enable == true
        ? true
        : false;
    let container = (
      <div>
        {this.props.AuthStore.loggedInUser && (
          <Row gutter={16} type="flex" align="middle">
            <Col lg={{ span: 16 }} xl={{ span: 12 }} xxl={{ span: 16 }}>
              <h2 className="page-title">
                <Icons type="fa" name="FaDesktop" />
                {"  "}
                Dashboard
              </h2>
            </Col>
            <Col lg={{ span: 8 }} xl={{ span: 12 }} xxl={{ span: 8 }}>
              <DashboardHeader {...this.props} />
            </Col>
          </Row>
        )}
        <div className="list-contianer">
          <Row gutter={16}>
            <Col
              xs={{
                span: this.props.AuthStore.IsPermitted("ACTIVITY:VIEW")
                  ? 16
                  : 24,
              }}
              sm={{
                span: this.props.AuthStore.IsPermitted("ACTIVITY:VIEW")
                  ? 16
                  : 24,
              }}
              md={{
                span: this.props.AuthStore.IsPermitted("ACTIVITY:VIEW")
                  ? 16
                  : 24,
              }}
              lg={{
                span: this.props.AuthStore.IsPermitted("ACTIVITY:VIEW")
                  ? 16
                  : 24,
              }}
              xl={{
                span: this.props.AuthStore.IsPermitted("ACTIVITY:VIEW")
                  ? 17
                  : 24,
              }}
            >
              <div gutter={16}>
                <Row>
                  <Col span={24}>
                    <Card>
                      {!this.state.allDetailsLoading && (
                        <CountElements
                          currentOrgId={this.currentOrgId}
                          allDetails={this.state.allDetails}
                          {...this.props}
                        />
                      )}
                    </Card>
                  </Col>
                </Row>
                <Row className="mt-10">
                  <Col span={trafficInsightConfig ? 8 : 12}>
                    <div className="dashboard-widget-wrapper">
                      {!this.state.allDetailsLoading && (
                        <NodeProfiles
                          currentOrgId={this.currentOrgId}
                          {...this.props}
                        />
                      )}
                    </div>
                  </Col>
                  <Col span={trafficInsightConfig ? 8 : 12}>
                    <div className="dashboard-widget-wrapper">
                      {!this.state.allDetailsLoading && (
                        <Charts
                          currentOrgId={this.currentOrgId}
                          {...this.props}
                        />
                      )}
                    </div>
                  </Col>
                  {trafficInsightConfig && (
                    <Col span={8}>
                      <div className="dashboard-widget-wrapper">
                        <Threats {...this.props} />
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
            </Col>
            {this.props.AuthStore.IsPermitted("ACTIVITY:VIEW") && (
              <Col
                xs={{ span: 8 }}
                sm={{ span: 8 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
                xl={{ span: 7 }}
              >
                <Card
                  className="act-card-hovering"
                  bordered={false}
                  title={
                    <span>
                      <Icons
                        type="md"
                        name="MdEventNote"
                        className="valign-middle"
                      />
                      <Icons
                        type="md"
                        name="MdPerson"
                        style={{ fontSize: 12, marginLeft: -4 }}
                      />{" "}
                      {getCurrentlocaleText(
                        "dashboard.activity.card_title.text",
                      )}
                    </span>
                  }
                >
                  <RecentActivity currentOrgId={this.currentOrgId} />
                </Card>
              </Col>
            )}
          </Row>
          <Row>
            <Col span={24}>
              <DashboardMap {...this.props} />
            </Col>
          </Row>
        </div>
      </div>
    );

    let loading = this.state.allDetailsLoading;
    this.dashboardStore.orgLoading;
    return <LoadingComponent loading={loading}>{container}</LoadingComponent>;
  }
}

export default Dashboards;
