import React, { Component } from "react";
import propTypes from "prop-types";
import { Row, Col, Button } from "antd";
import Icons from "../../UI-Components/Icons";

class AddWidget extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Row>
          <Col onClick={this.props.onClick}>
            <Button title="Add new" />
            <Icons type="ai" name="AiOutlinePlusCircle" />
          </Col>
        </Row>
      </div>
    );
  }
}

AddWidget.propTypes = {
  text: propTypes.string,
  onClick: propTypes.func,
};

export default AddWidget;
