import React, { Component } from "react";
import { Select, Button, Tag } from "antd";
import { Form } from "@ant-design/compatible";
import Input from "components/UI-Components/InputBox";
import { observer, inject } from "mobx-react";
import { customSort, getCurrentlocaleText } from "Core/Utils";
import FormErrorMessage from "components/UI-Components/FormErrorMessage";
import OrgController from "controller/OrgController";
import Icons from "../UI-Components/Icons";

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

@inject("UiStore", "OrgViewModel")
@observer
class SerialMoveForm extends Component {
  constructor(props) {
    super(props);
    this.orgModel = this.props.OrgViewModel;
    this.searchTimeout = null;
    this.state = {
      serial_id: this.props.formData.name,
      data: [],
    };
  }

  componentDidMount() {
    this.props.form.setFields({
      hardware_serial_number: {
        value: this.state.serial_id,
      },
    });
    let params = this.props.params || {};
    params.page = 0;
    OrgController.getOrgsForDropdown(params).then(response => {
      this.setState({ data: response ? this.renderList(response) : [] });
    });
  }

  fetchOrgs = (search = "") => {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      let params = this.props.params || {};
      params.page = 0;
      params.search = search;
      OrgController.getOrgsForDropdown(params).then(response => {
        this.setState({ data: response ? this.renderList(response) : [] });
      });
    }, 500);
  };

  renderList(orgs) {
    return (
      orgs &&
      orgs
        .filter((item, i) => {
          if (this.orgModel.org && this.orgModel.org.id === item.id) {
            return false;
          }
          return true;
        })
        .sort((x, y) => customSort(x.name, y.name))
        .map((item, i) => {
          return (
            <Option key={item.id} name={item.name}>
              {item.name}
            </Option>
          );
        })
    );
  }

  // render serial number as tags for multiple serial number move to specific org
  renderSerialNumberTags = () => {
    let selectedCerts =
      (this.props.formData &&
        this.props.formData.selectedCerts &&
        this.props.formData.selectedCerts) ||
      [];
    if (selectedCerts && selectedCerts.length > 0) {
      return (
        <div>
          <span>{"Move serial numbers"}</span>
          <div>
            {selectedCerts.map((val, i) => {
              return val ? (
                <Tag
                  key={i}
                  title={val}
                  className="tag-iotium-info tag-iotium-ellipse"
                >
                  {val}
                </Tag>
              ) : null;
            })}
          </div>
        </div>
      );
    }
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Form noValidate onSubmit={this.props.onSubmit} className="login-form">
          {this.props.isMultipleSelected ? (
            this.renderSerialNumberTags()
          ) : (
            <FormItem label="Serial Number" hasFeedback>
              {getFieldDecorator("hardware_serial_number", {
                rules: [],
              })(
                <Input
                  type="text"
                  autoComplete="off"
                  prefix={<Icons type="ai" name="AiOutlineDatabase" />}
                  placeholder="Serial Number"
                  disabled={true}
                />,
              )}
            </FormItem>
          )}
          <FormItem label="To Organization" hasFeedback>
            {getFieldDecorator("move_under", {
              rules: [
                { required: true, message: "Please select Organization!" },
              ],
            })(
              <Select
                showSearch
                placeholder={getCurrentlocaleText(
                  "serial.move.select.org.placeholder.text",
                )}
                prefix={
                  <Icons
                    type="ai"
                    name="AiOutlineIdcard"
                    style={{ fontSize: 13 }}
                  />
                }
                filterOption={false}
                onSearch={this.fetchOrgs}
                optionLabelProp="name"
                notFoundContent={getCurrentlocaleText(
                  "general.notfound.placeholder.text",
                )}
              >
                {this.state.data}
              </Select>,
            )}
          </FormItem>
          <Button className="hidden-submit" htmlType="submit" />
          <FormErrorMessage />
        </Form>
      </div>
    );
  }
}

const SerialMove = Form.create()(SerialMoveForm);
export default SerialMove;
