import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { computed } from "mobx";
import { observer, inject } from "mobx-react";
import { DETAULT_DOCUMENT_TITLE } from "Core/Utils";

@inject("UiStore", "AuthStore", "OrgViewModel")
@observer
class DocumentTitle extends Component {
  constructor(props) {
    super(props);
    // GLOBAL_SETTINGS configured from the node js
    this.GLOBAL_SETTINGS = GLOBAL_SETTINGS;
  }

  @computed
  get gettitle() {
    let title = this.props.UiStore.documentTitle;
    let orgName = this.props.UiStore.orgName;
    let inodeName = this.props.UiStore.inodeName;

    let default_document_title =
      this.props.OrgViewModel &&
      this.props.OrgViewModel.org &&
      this.props.OrgViewModel.org.name
        ? this.props.OrgViewModel.org.name + " Secure Edge"
        : this.GLOBAL_SETTINGS && this.GLOBAL_SETTINGS.name
        ? this.GLOBAL_SETTINGS.name + " Secure Edge"
        : this.props.AuthStore.loggedInUser.organization &&
          this.props.AuthStore.loggedInUser.organization.name
        ? this.props.AuthStore.loggedInUser.organization.name + " Secure Edge"
        : DETAULT_DOCUMENT_TITLE;

    if (title !== default_document_title) {
      if (title.indexOf(":OrgName") !== -1) {
        title = `${title.replace(":OrgName", orgName)}`;
      }
      if (title.indexOf(":iNodeName") !== -1) {
        title = `${title.replace(":iNodeName", inodeName)}`;
      }
    }
    let currentTitle =
      title !== default_document_title
        ? `${title} - ${default_document_title}`
        : title;
    this.props.UiStore.setCurrentDocTitle(currentTitle);
    return currentTitle;
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>{this.gettitle}</title>
        </Helmet>
      </div>
    );
  }
}

export default DocumentTitle;
