import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Row, Col, Card, Tabs, Radio, DatePicker, Select, Popover } from "antd";
import BreadcrumbComponent from "../UI-Components/BreadcrumbComponent";
import LoadingComponent from "../UI-Components/LoadingComponent";
import { getCurrentlocaleText } from "../../Core/Utils";
import CTDDashboard from "./CTDDashboard";
import { CTDAlertLog } from "./CTDAlertLog";
import InodeController from "../../controller/InodeController";
import { CTDDownloadReport } from "./CTDDownloadReport";
import NotFoundResult from "../NotFoundResult";
import moment from "moment";
import Icons from "../UI-Components/Icons";

const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;
const RangePicker = DatePicker.RangePicker;
const Option = Select.Option;
@inject("UiStore", "AuthStore", "InodeViewModel", "OrgViewModel")
@observer
export default class CTD extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = this.props.InodeViewModel;
    this.orgModel = this.props.OrgViewModel;

    this.state = {
      BreadcrumbList: [],
      loading: true,
      size: "",
      activeTab: "dashboard",
      selectedTime: "today",
      isVirtual: true,
      showRangePicker: false,
      isInvalidRange: false,
      startDate: null,
      endDate: null,
      customStartDate: null,
      customEndDate: null,
    };
    this.orgId = this.props.match.params.orgId;
    this.nodeId = this.props.match.params.nodeId;
  }

  componentDidMount() {
    if (this.nodeId && !this.inodeModel.inode.name) {
      InodeController.getInode(this.nodeId, true, false).then(res => {
        this.inodeModel.inode.name = res.name;

        let isVirtual =
          this.inodeModel.inode &&
          this.inodeModel.inode.profile &&
          this.inodeModel.inode.profile.name &&
          this.inodeModel.inode.profile.name.toLowerCase() == "virtual"
            ? true
            : false;
        this.setTimeRange();
        this.setState({ isVirtual }, () => {
          this.setBreadcrumb();
        });
      });
    } else {
      this.setTimeRange();
      this.setState({ isVirtual: false }, () => {
        this.setBreadcrumb();
      });
    }
  }

  setBreadcrumb() {
    let BreadcrumbList = [];
    BreadcrumbList.push({
      name:
        this.props.OrgViewModel &&
        this.props.OrgViewModel.org &&
        this.props.OrgViewModel.org.name
          ? this.props.OrgViewModel.org.name
          : this.props.AuthStore.loggedInUser.organization.name,
      link: `dashboard/${this.orgId}`,
    });
    if (this.nodeId) {
      let nodeName =
        this.inodeModel && this.inodeModel.inode && this.inodeModel.inode.name
          ? this.inodeModel.inode.name
          : "";

      BreadcrumbList.push(
        { name: "iNodes", link: `orgs/${this.orgId}/inodes` },
        {
          name: nodeName ? nodeName : "Details",
          link: `orgs/${this.orgId}/inodes/${this.nodeId}`,
        },
      );
    }
    BreadcrumbList.push({
      name: "Threat Intelligence Dashboard",
      link: `orgs/${this.props.OrgViewModel.org.id}/threatintelligence`,
    });
    this.setState({ BreadcrumbList });
  }

  handleTabChange = key => {
    this.setState({ activeTab: key });
  };

  handleTimeRange = e => {
    if (e && e.target && e.target.value) {
      this.setState(
        {
          selectedTime: e.target.value,
        },
        () => {
          if (e.target.value != "custom") {
            this.setState({
              customStartDate: null,
              customEndDate: null,
            });
            this.setTimeRange();
          }
        },
      );
    }
  };

  setTimeRange = fromRangePicker => {
    this.setState({ loading: true });
    let startDate, endDate;
    switch (this.state.selectedTime) {
      case "today":
        startDate = moment().startOf("day");
        endDate = moment();
        break;
      case "yesterday":
        startDate = moment()
          .subtract(1, "day")
          .startOf("day");
        endDate = moment()
          .subtract(1, "day")
          .endOf("day");
        break;
      case "7days":
        startDate = moment()
          .subtract(7, "day")
          .startOf("day");
        endDate = moment();
        break;
      case "30days":
        startDate = moment()
          .subtract(30, "day")
          .startOf("day");
        endDate = moment();
        break;
      case "custom":
        startDate = moment(this.state.customStartDate);
        endDate = moment(this.state.customEndDate);
        break;
    }
    this.setState(
      {
        startDate: startDate,
        endDate: endDate,
        loading: false,
      },
      () => {
        if (
          this.state.selectedTime == "custom" &&
          fromRangePicker == "custom"
        ) {
          this.handleTimeRange();
        }
      },
    );
  };

  disabledDate = current => {
    const thirtyDaysAgo = moment().subtract(30, "days");
    return (
      current && (current > moment().endOf("day") || current < thirtyDaysAgo)
    );
  };

  render() {
    let {
      BreadcrumbList,
      activeTab,
      loading,
      selectedTime,
      customEndDate,
      customStartDate,
      isVirtual,
    } = this.state;

    customStartDate = moment(customStartDate).format("ll");
    customEndDate = moment(customEndDate).format("ll");
    const isValidStartDate = moment(customStartDate).isValid();
    const isValidEndDate = moment(customStartDate).isValid();

    return (
      <LoadingComponent loading={loading}>
        {!isVirtual && !loading ? (
          <div className="usage-wrapper ctd-wrapper-all">
            <Row gutter={16} type="flex" align="middle">
              <Col lg={{ span: 12 }} xl={{ span: 12 }}>
                <h2 className="page-title">
                  {getCurrentlocaleText(
                    "inode.continuous_threat_detection_alerts.text",
                  )}
                </h2>
                <BreadcrumbComponent
                  {...this.props}
                  BreadcrumbList={BreadcrumbList}
                />
              </Col>
            </Row>
            <Card className="mb-5">
              <div
                style={{
                  marginBottom: "-35px",
                  position: "absolute",
                  width: "auto",
                  right: "10px",
                  top: "27px",
                  zIndex: 1,
                  display: "flex",
                }}
              >
                {activeTab == "alert-log" && (
                  <CTDDownloadReport
                    {...this.props}
                    duration={this.state.selectedTime}
                    orgId={this.orgId}
                    nodeId={this.nodeId}
                  />
                )}
                <Col className="ctd-group flex-reverse">
                  <RadioGroup
                    size={"small"}
                    onChange={this.handleTimeRange}
                    value={this.state.selectedTime}
                  >
                    <RadioButton value="today">
                      {getCurrentlocaleText("threat.datepicker.today.text")}
                    </RadioButton>
                    <RadioButton value="yesterday">
                      {getCurrentlocaleText("threat.datepicker.yesterday.text")}
                    </RadioButton>
                    <RadioButton value="7days">
                      {getCurrentlocaleText("threat.datepicker.sevendays.text")}
                    </RadioButton>
                    <RadioButton value="30days">
                      {getCurrentlocaleText(
                        "threat.datepicker.thirtydays.text",
                      )}
                    </RadioButton>
                    <Popover
                      placement="bottomRight"
                      trigger="click"
                      title=""
                      content={
                        <span className="range-ctd">
                          <RangePicker
                            disabledDate={this.disabledDate}
                            onChange={e => {
                              if (e && e[0] && e[1]) {
                                this.setState(
                                  {
                                    customStartDate: moment(e[0]),
                                    customEndDate: moment(e[1]),
                                  },
                                  () => {
                                    this.setTimeRange("custom");
                                  },
                                );
                              }
                            }}
                          />
                        </span>
                      }
                    >
                      <RadioButton value="custom">
                        <Icons
                          type="fa"
                          name="FaRegCalendarCheck"
                          className="valign-middle mr-5"
                        />
                        {selectedTime == "custom" &&
                        isValidStartDate &&
                        isValidEndDate ? (
                          <span className="fs-075">
                            ({moment(customStartDate).format("ll")} ~{" "}
                            {moment(customEndDate).format("ll")})
                          </span>
                        ) : (
                          getCurrentlocaleText(
                            "threat.datepicker.customdate.text",
                          )
                        )}
                        <Icons
                          type="ai"
                          name="AiOutlineDown"
                          className="valign-middle ml-5"
                        />
                      </RadioButton>
                    </Popover>
                  </RadioGroup>
                </Col>
              </div>
              <Tabs
                className="tab-no-border"
                activeKey={activeTab}
                onChange={this.handleTabChange}
              >
                <Tabs.TabPane tab="Dashboard" key="dashboard">
                  <CTDDashboard
                    duration={this.state.selectedTime}
                    orgId={this.orgId}
                    nodeId={this.nodeId}
                    activeTab={activeTab}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Alert Log" key="alert-log">
                  <CTDAlertLog
                    {...this.props}
                    duration={this.state.selectedTime}
                    orgId={this.orgId}
                    nodeId={this.nodeId}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                  />
                </Tabs.TabPane>
              </Tabs>
            </Card>
          </div>
        ) : (
          <NotFoundResult />
        )}
      </LoadingComponent>
    );
  }
}
