import React, { Component } from "react";
//View Logo
import ViewLogo from "assets/img/view-logo.png";

const style = { maxWidth: 180, maxHeight: 60, minWidth: 90, minHeight: 30 };

class DarkLogo extends Component {
  constructor(props) {
    super(props);
    // GLOBAL_SETTINGS configured from the node js
    this.GLOBAL_SETTINGS = GLOBAL_SETTINGS;
  }

  render() {
    return (
      <div>
        {this.GLOBAL_SETTINGS && !this.props.showDefault ? (
          !this.GLOBAL_SETTINGS.is_default &&
          this.GLOBAL_SETTINGS.dark_logo_url ? (
            <img src={this.GLOBAL_SETTINGS.dark_logo_url} style={style} />
          ) : this.GLOBAL_SETTINGS && this.GLOBAL_SETTINGS.dark_logo_url ? (
            <a
              href="https://view.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                src={this.GLOBAL_SETTINGS.dark_logo_url}
                alt="view.com"
                title="view.com"
                style={style}
              />
            </a>
          ) : (
            <a
              href={"https://www.view.com"}
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                src={ViewLogo}
                alt="view.com"
                title="view.com"
                style={style}
              />
            </a>
          )
        ) : (
          <a
            href={"https://www.view.com"}
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={ViewLogo} alt="view.com" title="view.com" style={style} />
          </a>
        )}
      </div>
    );
  }
}

export default DarkLogo;
