import { observable, action, computed } from "mobx";
import axios from "axios";
import Resources from "Core/API/Resources";
import UiStore from "Stores/UiStore";
import TimezoneViewModel from "view-model/TimezoneViewModel";
import Qs from "qs";

import FileSaver from "file-saver";
import {
  getCurrentlocaleText,
  toTimezone,
  getDefaultTimezone,
} from "Core/Utils";
import AuthStore from "./AuthStore";

const CHANGE_PASSWORD = "changepassword";

/**
 *
 * This is the application model store which stores all the application data.
 * These data are usually recieved from API responses from server. Any app data
 * should be stored here and not anywhere else.
 *
 */

// ModelStore Store
class ModelStore {
  /*@observable orgs = [];
  @observable inodes = [];
  @observable profiles = [];
  @observable users = [];
  @observable roles = [];
  @observable posts = [];
  @observable pki = [];
  @observable networks = [];*/
  @observable token = null;
  //@observable errorMessage = null;
  //@observable successMessage = null;
  @observable infoMessage = null;
  @observable lastQuery = null;
  @observable lastGet = null;
  @observable lastCreate = null;
  @observable lastUpdate = null;
  @observable lastDelete = null;
  @observable
  current = {
    inode: null,
    profile: null,
    network: null,
    org: null,
    user: null,
    role: null,
  };
  @observable last = {};
  @observable next = {};
  //@observable permissions = [];
  @observable currentOrg = {};
  /*@observable service = {};
  @observable secret = {};*/
  @observable loading = false;
  @observable serviceLogs = [];
  //@observable formErrorMessage = null;
  //@observable firewallgroup = [];
  @observable activityData = [];
  //@observable modalLoading = false;
  // @observable formErrorResponse = [];
  @observable
  logs = {
    logsData: [],
    tagList: [],
    severityList: [],
    sort: "DESC",
    lastTimeStamp: "",
    isApiError: false,
    selectedTimezone:
      TimezoneViewModel.userTimezone ||
      TimezoneViewModel.orgTimezone ||
      getDefaultTimezone().value,
    isLoading: false,
    containerList: [],
    logType: "",
  };
  @observable logsApiResponseCount = 200;

  @action
  resetLogs() {
    this.logs = {
      logsData: [],
      tagList: [],
      severityList: [],
      sort: "DESC",
      lastTimeStamp: "",
      isApiError: false,
      selectedTimezone:
        TimezoneViewModel.userTimezone ||
        TimezoneViewModel.orgTimezone ||
        getDefaultTimezone().value,
      isLoading: false,
      containerList: [],
      logType: "",
    };
  }
  @observable SourceTokens = {};

  @computed
  get getVirtualNodes() {
    let vNodes = [];

    let vProfileIds = [];

    vProfileIds = this.profiles
      .filter(profile => {
        if (profile.config.vpn_server) {
          return true;
        }
      })
      .map(profile => {
        return profile.id;
      });

    vNodes = this.inodes.filter(node => {
      if (vProfileIds.includes(node.profile.id)) {
        return node;
      } else {
        return null;
      }
    });
    return vNodes;
  }

  // Set login and user data
  @action
  SetToken = (token, history) => {
    this.token = token;

    // Set authorization headed to axios
    axios.defaults.headers.common["Accept"] = "application/json";
    axios.defaults.withCredentials = true;
    axios.defaults.withXSRFToken = true;

    // Register Resources when the computed Resource is not used
    if (history) {
      Resources.forceRegisterResources(this);
    } else {
      Resources.registerResources(this);
    }

    //Login success. Redirect to default page
    if (history) {
      let redirectURL = window.sessionStorage.redirectSuccessLoginURL;
      history.push(redirectURL ? redirectURL : "/");
      redirectURL && delete window.sessionStorage.redirectSuccessLoginURL;
    }
  };

  @action
  Logout = history => {
    // Delete expire password url if exist
    if (sessionStorage.changePasswordUrl) {
      delete sessionStorage.changePasswordUrl;
    }
    let org = AuthStore.loggedInUserOrg;

    // Delete cookie storage
    axios.defaults.headers.common = {};
    Resources.unregisterResources();
    this.resetStore();
    UiStore.resetStore();
    UiStore.SetLoginStatus(false);
    /* LAT-1438 : To logout from multiple active tabs
      refer - https://developer.mozilla.org/en-US/docs/Web/API/BroadcastChannel
    */
    try {
      let bc = new BroadcastChannel("logoutChannel");
      bc.postMessage("logout");
    } catch (error) {}

    // on logout redirect to login page.
    if (history && org && !org.is_central_auth) {
      history.push("/login");
    }
  };

  @action
  resetStore() {
    /*this.orgs = [];
    this.inodes = [];
    this.profiles = [];
    this.users = [];
    this.roles = [];
    this.posts = [];
    this.pki = [];
    this.networks = [];*/
    this.token = null;
    //this.errorMessage = null;
    //this.successMessage = null;
    this.infoMessage = null;
    this.lastQuery = null;
    this.lastGet = null;
    this.lastCreate = null;
    this.lastUpdate = null;
    this.lastDelete = null;
    this.current = {};
    this.last = {};
    this.next = {};
    //this.permissions = [];
    this.currentOrg = {};
    /*this.service = {};
    this.secret = {};*/
    this.serviceLogs = [];
    this.logs = {
      logsData: [],
      tagList: [],
      severityList: [],
      sort: "DESC",
      lastTimeStamp: "",
      isApiError: false,
      selectedTimezone:
        TimezoneViewModel.userTimezone ||
        TimezoneViewModel.orgTimezone ||
        getDefaultTimezone().value,
      isLoading: false,
    };
  }

  @action
  SetStoreData(type, data) {
    this[type] = data;
  }

  /*=============================================
    =       Resource and API call actions'            =
    =============================================*/

  /* Query action which sets the resource array to store and
       returns promise
    -------------------------------------------------------------- */

  @action
  BeforeRequest(fn) {
    fn();
  }

  @action
  AfterRequest(fn) {
    fn();
  }

  /**
   * @deprecated This Method is will be deleted soon.
   * @param {*} resource
   * @param {*} params
   * @param {*} map
   * @param {*} cache
   * @param {*} isShowLoading
   */
  @action
  Query(resource, params = {}, map = {}, cache = false, isShowLoading = true) {
    if (cache && this[resource].length > 0) {
      return new Promise((resolve, reject) => {
        resolve(this[resource]);
      });
    } else {
      // Resource not in store. So fetch it from server
      if (resource) {
        if (isShowLoading) this.loading = true;
        // TODO - AV - Change to axios
        let request = Resources[resource].query(params);
        request
          .then(response => {
            //Default success actions
            this[resource] = response;
            this.lastQuery = response;

            // Save query params
            if (this.last[resource]) {
              this.last[resource].queryParams = params;
            } else {
              this.last[resource] = {};
              this.last[resource].queryParams = params;
            }
            this.loading = false;
            return response;
          })
          .catch(error => {
            this.loading = false;
            return new Promise((resolve, reject) => {
              reject(error);
            });
          });
        return request;
      } else {
        console.warn(
          "Query without resource name in action Query in ModelStore",
        );
        return null;
      }
    }
  }

  /**
   * @deprecated This Method is will be deleted soon.
   * @param {*} resource
   * @param {*} params
   * @param {*} map
   * @param {*} cache
   * @param {*} isShowLoading
   */
  @action
  QueryResource(
    resource,
    params = {},
    map = {},
    cache = false,
    isShowLoading = true,
  ) {
    if (cache && this[resource].length > 0) {
      return new Promise((resolve, reject) => {
        resolve(this[resource]);
      });
    } else {
      // Resource not in store. So fetch it from server
      if (resource) {
        var CancelToken = axios.CancelToken;
        var source = CancelToken.source();
        this.SourceTokens[resource] = source;
        let url = Resources.getUrl(resource);
        if (map.id || map.network_id || map.node_id || map.service_id) {
          // Replace url variable from map data
          url = url.replace(`{:id}`, map.id);
          url = url.replace(`{:network_id}`, map.network_id);
          url = url.replace(`{:node_id}`, map.node_id);
          url = url.replace(`{:service_id}`, map.service_id);
        } else {
          url = url.replace(`/{:id}`, "");
          url = url.replace(`/{:network_id}`, "");
          url = url.replace(`/{:node_id}`, "");
          url = url.replace(`/{:service_id}`, "");
        }
        if (!this.isEmpty(params)) {
          let queryParams = "?";
          for (var key in params) {
            if (queryParams !== "?") queryParams = queryParams + "&";

            queryParams = queryParams + key + "=" + params[key];
          }
          url = url + queryParams;
        }
        if (isShowLoading) this.loading = true;
        // Update resource
        let request = axios
          .get(url, { cancelToken: source.token }, { params })
          .then(response => {
            //Default success actions
            response = response.data;
            this[resource] = response;
            this.lastQuery = response;
            this.lastGet = response;
            this.loading = false;
            if (this.last[resource]) {
              this.last[resource].queryParams = params;
            } else {
              this.last[resource] = {};
              this.last[resource].queryParams = params;
            }
            return response;
          })
          .catch(error => {
            if (error.message) {
              let defaultMessage = `${resource}: ${
                error.status
              } - ${JSON.stringify(error.statusText)}`;
              /*this.errorMessage =
                error.response.data && error.response.data.message
                  ? `${error.response.data.message}`
                  : defaultMessage;*/
            }
            return new Promise((resolve, reject) => {
              reject(error);
            });
          });
        return request;
      } else {
        console.warn(
          "Query without resource name in action Query in ModelStore",
        );
        return null;
      }
    }
  }
  /* Create action creates a single resource and returns promise
    -------------------------------------------------------------- */
  /**
   * @deprecated This Method is will be deleted soon.
   * @param {*} resource
   * @param {*} data
   * @param {*} map
   * @param {*} params
   */
  @action
  Create(resource, data = {}, map = {}, params = {}) {
    if (resource) {
      // TODO - AV - Change to axios
      let request = Resources[resource].create(data, map, params);
      request
        .then(response => {
          // if (params && this.current.org) {
          //     Object.assign(params, { org_id: this.current.org.id });
          // }
          this.lastCreate = response;
          let queryParams = this.last[resource]
            ? this.last[resource].queryParams
            : {};
          // After change password no need call any service
          if (resource !== CHANGE_PASSWORD) {
            //Default success actions
            /*if (resource !== "networks") {
              this.successMessage =
                resource !== "inodes"
                  ? getCurrentlocaleText("general.api.create.success.text", {
                      0: capitalize(getResourceForDisplay(resource)),
                    })
                  : null;
              this.Query(resource, queryParams);
            } else {
              this.successMessage =
                resource !== "inodes"
                  ? getCurrentlocaleText("general.api.create.success.text", {
                      0: capitalize(getResourceForDisplay(resource)),
                    })
                  : null;
            }*/
            if (resource == "service") {
              /*LAT-2454 : For Service list , including timeout as ip_address
              should be updated from NLC before service is queried*/
              setTimeout(() => {
                this.Query(resource, queryParams);
              }, 30000);
            }
          }
          return response;
        })
        .catch(error => {
          let defaultMessage = `${resource}: ${
            error.statusCode
          } - ${JSON.stringify(error.message)}`;

          // TODO - AV - these server side validation message should bind with corresponding forms.
          /*if (error.validationErrors) {
            this.errorMessage = error.validationErrors[0].message;
          } else {
            this.errorMessage = error.message
              ? `${error.message}`
              : defaultMessage;
          }
          this.formErrorMessage = this.errorMessage;*/
          return new Promise((resolve, reject) => {
            reject(error);
          });
        });
      return request;
    } else {
      console.warn("Query without resource name in action Query in ModelStore");
      return null;
    }
  }
  /*-----------------------CANCEL Pending Req-----------------------*/
  /**
   *@deprecated This Method is will be deleted soon.
   * @param {*} resources
   */
  @action
  cancelPendingReq(resources = []) {
    let d = this.SourceTokens;
    resources.forEach(function(item) {
      d[item].cancel();
    });
  }
  /*----------------------------------------------------------------*/
  /* Get action gets a single resource and returns promise
    -------------------------------------------------------------- */
  /**
   *@deprecated This Method is will be deleted soon.
   * @param {*} resource
   * @param {*} map
   * @param {*} params
   */
  @action
  Get(resource, map = {}, params = {}) {
    if (resource) {
      let url = Resources.getUrl(resource);

      // Replace url variable from map data
      url = url.replace(`{:id}`, map.id);
      url = url.replace(`{:network_id}`, map.network_id);
      url = url.replace(`{:node_id}`, map.node_id);
      url = url.replace(`{:service_id}`, map.service_id);
      // Update resource
      let request = axios({
        url: url,
        method: "get",
        params: params,
        withCredentials: true,
        paramsSerializer: function(params) {
          return Qs.stringify(params, { arrayFormat: "repeat" });
        },
      })
        .then(response => {
          if (
            resource === "netstats" &&
            response.data &&
            response.data.results
          ) {
            //Default success actions
            this.lastGet = response.data.results;
            this.loading = false;
            return response.data.results;
          } else if (
            resource === "netstats" &&
            response.data &&
            !response.data.results
          ) {
            //When no results response in netstats
            this.lastGet = [];
            this.loading = false;
            return [];
          } else {
            //Default success actions
            this.lastGet = response;
            this.loading = false;
            return response;
          }
        })
        .catch(error => {
          let defaultMessage = `${resource}: ${error.response &&
            error.response.status} - ${JSON.stringify(
            error.response && error.response.statusText,
          )}`;
          /*this.errorMessage =
            error.response.data && error.response.data.message
              ? `${error.response.data.message}`
              : defaultMessage;*/
          return new Promise((resolve, reject) => {
            reject(error);
          });
        });
      return request;
    } else {
      console.warn("Query without resource name in action Query in ModelStore");
      return null;
    }
  }

  /**
   * @deprecated This Method is will be deleted soon.
   * @param {*} resource
   * @param {*} map
   * @param {*} params
   * @param {*} fileName
   */
  @action
  Download(resource, map = {}, params = {}, fileName = "") {
    if (resource) {
      let url = Resources.getUrl(resource);

      // Replace url variable from map data
      url = url.replace(`{:id}`, map.id);
      url = url.replace(`{:node_id}`, map.node_id);
      url = url.replace(`{:service_id}`, map.service_id);
      // download request
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.setRequestHeader("Content-type", "application/octet-stream");
      xhr.responseType = "blob";
      xhr.withCredentials = true;
      xhr.onreadystatechange = function() {
        if (xhr.readyState === 4 && xhr.status === 200) {
          var blob = xhr.response.data;
          FileSaver.saveAs(blob, fileName);
          params = {};
        }
      };
      xhr.send();
    } else {
      console.warn("Query without resource name in action Query in ModelStore");
      return null;
    }
  }
  /* Edit action sets a single resource and returns promise
    -------------------------------------------------------------- */
  /**
   * @deprecated This Method is will be deleted soon.
   * @param {*} resource
   * @param {*} data
   * @param {*} map
   * @param {*} params
   */
  @action
  Update(resource, data = {}, map = {}, params = {}) {
    if (resource) {
      let url = Resources.getUrl(resource);

      // Replace url variable from map data
      url = url.replace(`{:id}`, map.id);

      // Update resource
      let request = axios
        .put(url, data)
        .then(response => {
          //Default success actionsnull
          if (resource !== "networks") {
            this.successMessage = getCurrentlocaleText(
              "general.api.update.success.text",
              {
                0: response.data.name ? response.data.name : "",
              },
            );
          } else {
            UiStore.networksuccessResponse = getCurrentlocaleText(
              "general.api.update.success.text",
              {
                0: response.data.name ? response.data.name : "",
              },
            );
          }
          this.lastUpdate = response;
          let queryParams = this.last[resource]
            ? this.last[resource].queryParams
            : {};

          resource != "secret" && this.Query(resource, queryParams);

          return response;
        })
        .catch(error => {
          let defaultMessage = `${resource}: ${
            error.response.status
          } - ${JSON.stringify(error.response.statusText)}`;

          // TODO - AV - these server side validation message should bind with corresponding forms.
          /*if (error.response.data && error.response.data.validationErrors) {
            this.errorMessage = error.response.data.validationErrors[0].message;
          } else {
            this.errorMessage =
              error.response.data && error.response.data.message
                ? `${error.response.data.message}`
                : defaultMessage;
          }*/
          //this.formErrorMessage = this.errorMessage;
          //this.formErrorResponse = error.response;
          return new Promise((resolve, reject) => {
            reject(error);
          });
        });
      return request;
    } else {
      console.warn("Query without resource name in action Query in ModelStore");
      return null;
    }
  }

  /* Delete action sets a single resource and returns promise
    -------------------------------------------------------------- */
  /**
   * @deprecated This Method is will be deleted soon.
   * @param {*} resource
   * @param {*} data
   * @param {*} map
   * @param {*} params
   */
  @action
  Delete(resource, data = {}, map = {}, params = {}) {
    if (resource) {
      let url = Resources.getUrl(resource);

      // Replace url variable from map data
      url = url.replace(`{:id}`, map.id);

      // Update resource
      let request = axios
        .delete(url)
        .then(response => {
          //Default success actions
          this.successMessage = getCurrentlocaleText(
            "general.api.delete.success.text",
          );
          this.lastDelete = response;
          // if (params && this.current.org) {
          //     Object.assign(params, { org_id: this.current.org.id });
          // }
          // console.log(params)
          let queryParams = this.last[resource]
            ? this.last[resource].queryParams
            : {};
          //LAT-2932 Commented as its implemented to resolve bulk delete api call issue and the same query is implemented on actionbtn.js delete resource function.
          //this.Query(resource, queryParams);
          //return response;
        })
        .catch(error => {
          let defaultMessage = `${resource}: ${
            error.response.status
          } - ${JSON.stringify(error.response.statusText)}`;
          /*this.errorMessage =
            error.response.data && error.response.data.message
              ? `${error.response.data.message}`
              : defaultMessage;*/
          return new Promise((resolve, reject) => {
            reject(error);
          });
        });
      return request;
    } else {
      console.warn("Query without resource name in action Query in ModelStore");
      return null;
    }
  }

  /*=====  End of Resource and API call actions'  ======*/

  @action
  getServiceLogs = (nodeId, serviceId) => {
    this.Get(
      "serviceLogs",
      { node_id: nodeId, service_id: serviceId },
      {},
    ).then(res => {
      /*Assigning service logs*/
      this.serviceLogs = res.data.map((val, index) => {
        return {
          timestamp: toTimezone(
            new Date(val.timestamp).getTime(),
            TimezoneViewModel.userTimezone ||
              TimezoneViewModel.orgTimezone ||
              getDefaultTimezone().value,
          ),
          message: val.message,
        };
      });
    });
  };
  @computed
  get logsObj() {
    if (this.logs.logsData.length > 0) {
      var logObj = this.logs.logsData.map((val, index) => {
        return {
          timestamp: toTimezone(
            new Date(val.timestamp).getTime(),
            this.logs.selectedTimezone,
          ),
          message: val.message,
          severity: val.severity || null,
          tag: val.tag || null,
          time: val.timestamp || null,
          name: val.name ? val.name : null,
        };
      });
      return logObj;
    } else return [];
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }
}

export default new ModelStore();
