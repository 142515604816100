import React, { Component } from "react";
import { message, Icon } from "antd";
import { observer, inject } from "mobx-react";
import Icons from "../components/UI-Components/Icons";

@inject("UiStore")
@observer
class ErrorMessageDisplay extends Component {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    this.errorMessages = [];
    this.errMsgIndex = [];
    this.errMsg = [];
  }

  UNSAFE_componentWillReceiveProps(props) {
    /*
      https://github.com/ant-design/ant-design/issues/7793#issuecomment-354249673
    */
    let $timer = setTimeout(() => {
      clearTimeout($timer);
      $timer = null;
      this.displayErrorMessage(props.message);
    }, 100);
  }

  componentDidMount() {
    this.displayErrorMessage(this.props.message);
  }

  componentWillUnmount() {
    // To remove all the error message while unmount.
    if (this.errMsgIndex && this.errorMessages) {
      this.errMsgIndex.forEach(index => {
        setTimeout(this.errorMessages[index], 0);
      });
    }
  }

  displayErrorMessage(error_message) {
    let errMsg =
      error_message && typeof error_message === "object"
        ? error_message.errorMessage
        : error_message;
    if (errMsg) {
      // Check whether error message displayed already
      let index = this.errMsg.findIndex(val => {
        return val.message === errMsg;
      });
      if (index === -1) {
        let currentTime = new Date().getTime();
        let error_msg = (
          <span
            data-index={currentTime}
            onClick={this.closeErrorMessage}
            style={{ cursor: "pointer", fontSize: 14 }}
          >
            <span className="portal-err-msg">{errMsg}</span>
            <span
              data-index={currentTime}
              onClick={this.closeErrorMessage}
              className="portal-error-close-btn"
            >
              <Icons type="ai" name="AiOutlineClose" />
            </span>
          </span>
        );
        this.errMsg.push({
          currentTime: `${currentTime}`,
          message: errMsg,
        });
        // (24 * 60 * 60 * 1000) Milliseconds for 24 hour
        let milliSeconds = 24 * 60 * 60 * 1000;
        this.errorMessages[currentTime] = message.error(error_msg, 0);
        this.errMsgIndex.push(currentTime);
        // Dismiss manually and asynchronously
        setTimeout(this.errorMessages[currentTime], milliSeconds);
      }
    }
  }

  closeErrorMessage = e => {
    let index = e.currentTarget
      ? e.currentTarget.getAttribute("data-index")
      : null;
    setTimeout(this.errorMessages[index], 0);
    delete this.errorMessages[index];
    this.props.UiStore.setErrorMessage(null);
    let inx = this.errMsg.findIndex(val => {
      return val.currentTime === index;
    });
    if (inx != -1) {
      this.errMsg.splice(inx, 1);
    }
  };

  render() {
    return null;
  }
}

export default ErrorMessageDisplay;
