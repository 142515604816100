import React, { Component } from "react";
import {
  Card,
  Cascader,
  Row,
  Col,
  Select,
  Button,
  DatePicker,
  Popover,
  Spin,
} from "antd";
import { observer, inject } from "mobx-react";
import {
  getCurrentlocaleText,
  getDefaultTimezone,
  TIME_DATE_FORMAT,
  getTimezoneToDisplay,
  customSort,
  getOrgPolicyCounInfo,
} from "Core/Utils";
import Icons from "components/UI-Components/Icons";
import moment from "moment";
import TableLayout from "components/layout/TableLayout";
import ActionBtn from "Core/API/ActionBtn";

import DownloadController from "../../../controller/DownloadController";
import InodeController from "controller/InodeController";
import OrgController from "controller/OrgController";
import ClusterController from "controller/ClusterController";
import PopOverHelp from "components/UI-Components/PopOverHelp";

const Option = Select.Option;

@inject("DownloadViewModal", "TimezoneViewModel", "OrgViewModel")
@observer
export default class DownloadsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedRowKeys: [],
      startDate: null,
      endDate: null,
      filterType: [],
      deletableKeys: {
        keys: [],
      },
      orgId: null,
      nodeId: null,
      clusterId: null,
      selectedKey: {},
      orgPolicy: null,
      orgList: [],
      nodeList: [],
    };
    this.lastIndex = null;
    this.LogViewModel = this.props.LogViewModel;
  }

  componentDidMount() {
    this.fetchOrgInodeClusters();
  }

  getInfoDownloadInfo = record => {
    let menu = null;
    switch (record.status) {
      case "COMPLETED":
        return (
          <span>
            <Button
              title={getCurrentlocaleText("download.report.button.text")}
              onClick={() => {
                this.downLoadFile(record.id);
              }}
              style={{ marginRight: 10 }}
            >
              <Icons type="fa" name="FaDownload" />
            </Button>
            <ActionBtn
              title={
                <span>
                  <Icons type="ai" name="AiOutlineDelete" />{" "}
                  {getCurrentlocaleText("download.delete.title.text")}
                </span>
              }
              HoverText={
                <span>
                  {getCurrentlocaleText("download.delete.button.text")}
                </span>
              }
              resource="downloads"
              orgId={this.props.orgId}
              icon="AiOutlineDelete"
              formData={record}
              iconButton
              map={{ id: record.id, resource: this.props.type + "_delete" }}
              action="delete"
              controller={DownloadController}
              afterCall={this.getDownloadRequestLists}
              cancelText={getCurrentlocaleText("download.delete.cancel.text")}
              warningMessage={getCurrentlocaleText(
                "download.delete.confirmation.message",
              )}
            >
              <Icons type="ai" name="AiOutlineDelete" style={{ margin: 0 }} />{" "}
              {getCurrentlocaleText("download.delete.ok.text")}
            </ActionBtn>
          </span>
        );
      case "PENDING":
        return (
          <span>
            <ActionBtn
              title={
                <span>
                  <Icons type="ai" name="AiOutlineCloseCircle" />{" "}
                  {getCurrentlocaleText("download.cancel.request.title")}
                </span>
              }
              HoverText={
                <span>
                  {getCurrentlocaleText("download.cancel_req.button.text")}
                </span>
              }
              resource="downloads"
              orgId={this.props.orgId}
              icon="AiOutlineCloseCircle"
              formData={record}
              iconButton
              map={{ id: record.id, resource: this.props.type + "_delete" }}
              action="delete"
              controller={DownloadController}
              afterCall={this.getDownloadRequestLists}
              messageContent={getCurrentlocaleText(
                "download.request.confirmation.cancel.message",
              )}
              cancelText={getCurrentlocaleText(
                "download.delete.confirmation.cancel.text",
              )}
            >
              <Icons
                type="ai"
                name="AiOutlineCloseCircle"
                style={{ margin: 0 }}
              />{" "}
              {getCurrentlocaleText("download.delete.confirmation.ok.text")}
            </ActionBtn>
          </span>
        );

      case "ERROR":
        return (
          <span>
            <Row gutter={2}>
              <Col span={4}>
                <ActionBtn
                  title={
                    <span>
                      <Icons type="ai" name="AiOutlineCloseCircle" />{" "}
                      {getCurrentlocaleText("download.cancel.request.title")}
                    </span>
                  }
                  HoverText={
                    <span>
                      {getCurrentlocaleText("download.cancel_req.button.text")}
                    </span>
                  }
                  resource="downloads"
                  orgId={this.props.orgId}
                  icon="AiOutlineCloseCircle"
                  formData={record}
                  iconButton
                  map={{ id: record.id, resource: this.props.type + "_delete" }}
                  action="delete"
                  controller={DownloadController}
                  afterCall={this.getDownloadRequestLists}
                  messageContent={getCurrentlocaleText(
                    "download.request.confirmation.cancel.message",
                  )}
                  cancelText={getCurrentlocaleText(
                    "download.delete.confirmation.cancel.text",
                  )}
                >
                  <Icons
                    type="ai"
                    name="AiOutlineCloseCircle"
                    style={{ margin: 0 }}
                  />{" "}
                  {getCurrentlocaleText("download.delete.confirmation.ok.text")}
                </ActionBtn>
              </Col>
              <Col span={12}>
                <Popover
                  overlayStyle={{ maxWidth: "30%", float: "right" }}
                  placement="left"
                  title={getCurrentlocaleText(
                    "inodes.images.error_in_delete.text",
                  )}
                  content={<div>{record.error_message}</div>}
                >
                  <Button
                    style={{ border: "none", verticalAlign: "sub", left: 5 }}
                    shape="circle"
                    size={"small"}
                  >
                    <Icons
                      type="fa"
                      name="FaTimesCircle"
                      className="remote-network-action-btn error-on-remote-network-icon"
                    />
                  </Button>
                </Popover>
              </Col>
            </Row>
          </span>
        );
    }
  };

  sendDownloadRequest = () => {
    let params =
      (this.state.startDate &&
        this.state.endDate && {
          start_date: this.state.startDate.format(),
          end_date: this.state.endDate.format(),
        }) ||
      (!this.state.startDate &&
        this.state.endDate && {
          end_date: this.state.endDate.format(),
        }) ||
      (this.state.startDate &&
        !this.state.endDate && {
          start_date: this.state.startDate.format(),
        }) ||
      {};
    if (this.state.orgId) {
      params.org_id = this.state.orgId;
      params.own = true;
    }
    if (this.state.nodeId) {
      params.node_id = this.state.nodeId;
    }
    if (this.state.clusterId) {
      params.cluster_id = this.state.clusterId;
    }

    if (
      this.props.type === "event_downloads" &&
      this.state.filterType &&
      this.state.filterType.length > 0
    ) {
      params.resource_group = this.state.filterType;
    }
    this.props.sendDownloadRequest(params);
  };

  expandRow = () => {
    let keys = this.props.DownloadViewModal.getDownloadRequestLists.map(
      item => {
        return item.id;
      },
    );
    this.setExpandedRowKeys(
      this.state.expandedRowKeys.length !== keys.length ? keys : [],
    );
  };

  downLoadFile = id => {
    this.props.downLoadFile(id);
  };

  onExpand = (expanded, record) => {
    let keys = this.state.expandedRowKeys;
    let index = keys.findIndex(val => {
      return val === record.id;
    });
    if (expanded) {
      if (index === -1) {
        keys.push(record.id);
      }
      this.setExpandedRowKeys(keys);
    } else {
      if (index !== -1) {
        keys.splice(index, 1);
      }
      this.setExpandedRowKeys(keys);
    }
  };

  setExpandedRowKeys = keys => {
    this.setState({ expandedRowKeys: keys });
  };

  getDownloadRequestLists = () => {
    this.props.getDownloadRequestLists();
  };

  handleTableChange = (record, selected, selectedRows) => {
    selectedRows.length == 0 ? this.resetState() : "";
    this.setSelectedKeys(record, selected, selectedRows);
  };

  onSelectAllChange = (selected, selectedRows, changeRows) => {
    if (selected) {
      this.setSelectedKeys(null, selected, selectedRows);
    } else {
      // on deselect all
      this.resetState();
    }
  };

  resetState = () => {
    this.setState({
      selectedKey: {},
      deletableKeys: {
        keys: [],
      },
    });
  };

  setSelectedKeys = (record = {}, selected, selectedRows) => {
    for (let i = 0; i < selectedRows.length; i++) {
      let id = selectedRows[i].id;

      this.setState(() => {
        let newObj = {
            deletableKeys: {
              keys: [],
            },
          },
          selectedKey = this.state.selectedKey,
          item = selectedRows[i],
          tempArray = [];

        selectedKey[id] = {
          id: item.id,
          resource: this.props.type + "_delete",
        };

        //Temp array for deletable key list
        for (let i in selectedRows) {
          selectedKey[selectedRows[i].id]
            ? tempArray.push(selectedKey[selectedRows[i].id])
            : "";
        }

        // To set the value : false , when key is deselected and remove it from delete list
        !selected && selectedKey[record.id]
          ? ((selectedKey[record.id].value = false),
            (tempArray = _.filter(tempArray, val => {
              return val.id !== record.id;
            })))
          : null;

        newObj["selectedKey"] = selectedKey;
        newObj.deletableKeys["keys"] = tempArray;
        return newObj;
      });
    }
  };

  getMoreInfo(record) {
    return (
      <div>
        {this.getOrgInfo(record)}
        <Row>
          <Col span={3}>
            <strong>
              {getCurrentlocaleText("download.date.range.label.text")}
            </strong>
          </Col>
          <Col span={1}>
            <strong>{":"}</strong>
          </Col>
          <Col span={14}>
            {record.parameters &&
              record.parameters.start_date &&
              `${moment(
                new Date(record.parameters.start_date).getTime(),
              ).format(TIME_DATE_FORMAT)} ${getTimezoneToDisplay(
                record.parameters.start_date,
              )} - `}
            {record.parameters &&
              record.parameters.end_date &&
              `${moment(new Date(record.parameters.end_date).getTime()).format(
                TIME_DATE_FORMAT,
              )}
              ${getTimezoneToDisplay(record.parameters.end_date)}`}
          </Col>
        </Row>
      </div>
    );
  }

  handleEventTypeChange = value => {
    this.setState({ filterType: value });
  };

  fetchClusterDataForDropdown = () => {
    return new Promise((resolve, reject) => {
      ClusterController.getClustersForDropdown({ size: 1 }).then(response => {
        if (response && response.total_count) {
          let allParams = [],
            totalCount = response.total_count / 100;
          if (totalCount > 0) {
            for (let page = 0; page < totalCount; page++) {
              let params = { page: page, size: 100 };
              allParams.push(params);
            }
            ClusterController.getAllClustersForDropdown(allParams, false)
              .then(response => {
                resolve(response);
              })
              .catch(error => {
                resolve([]);
              });
          } else {
            resolve([]);
          }
        } else {
          resolve([]);
        }
      });
    });
  };

  fetchOrgInodeClusters = () => {
    let params = { size: 1 },
      orgs = null,
      nodes = null,
      clusters = null,
      orgInodeClusterTree = [];
    // Adding loading option in cascader.
    orgInodeClusterTree.push({
      label: (
        <span key={"spinner"}>
          <Spin size="small" style={{ marginRight: 5 }} />{" "}
          {getCurrentlocaleText("loading.text")}
        </span>
      ),
      value: "orgs-tree-loading",
      key: "orgs-tree-loading",
      disabled: true,
    });
    this.setState({ orgInodeClusterTree: orgInodeClusterTree });
    OrgController.getOrgsForDropdown(params, true, false).then(response => {
      if (response && response.total_count) {
        let allParams = [],
          totalCount = response.total_count / 100;
        if (totalCount > 0) {
          for (let page = 0; page < totalCount; page++) {
            params = { page: page, size: 100 };
            allParams.push(params);
          }
          OrgController.getAllOrgsForDropdown(allParams).then(response => {
            if (response) {
              orgs = response;
              this.setState({ orgList: orgs });
              if (
                this.props.match &&
                this.props.match.params &&
                this.props.match.params.id
              ) {
                this.setState({
                  orgPolicy: orgs.find(org => {
                    return org && org.id === this.props.match.params.id;
                  }),
                });
              }
              if (this.props.type === "event_downloads") {
                // For events download
                InodeController.getInodesForDropdown(params).then(response => {
                  if (response && response.total_count) {
                    let allParams = [],
                      totalCount = response.total_count / 100;
                    if (totalCount > 0) {
                      for (let page = 0; page < totalCount; page++) {
                        params = {
                          page: page,
                          size: 100,
                        };
                        allParams.push(params);
                      }
                      InodeController.getAllInodesForDropdown(allParams).then(
                        response => {
                          nodes = response;
                          this.fetchClusterDataForDropdown().then(response => {
                            clusters = response;
                            this.formOrgInodeClusterTree(orgs, nodes, clusters);
                          });
                        },
                      );
                    }
                  } else {
                    this.fetchClusterDataForDropdown().then(response => {
                      clusters = response;
                      this.formOrgInodeClusterTree(orgs, nodes, clusters);
                    });
                  }
                });
              } else {
                // For activities download
                this.formOrgInodeClusterTree(orgs);
              }
            }
          });
        }
      }
    });
  };

  formOrgInodeClusterTree = (orgs, nodes, clusters) => {
    let orgInodeClusterTree = [];
    if (orgs) {
      orgs.forEach(org => {
        let children = [],
          node,
          cluster,
          nodeChildren = [],
          clusterChildren = [];
        let nodeList =
          nodes &&
          nodes.filter(node => {
            return node && node.organization && node.organization.id === org.id;
          });
        let clusterList =
          clusters &&
          clusters.filter(cluster => {
            return (
              cluster &&
              cluster.organization &&
              cluster.organization.id === org.id
            );
          });
        // For Node
        if (nodeList && nodeList.length > 0) {
          nodeChildren.push(
            ...nodeList.map(node => {
              return {
                title: node.name,
                value: `node/${node.organization.id}/${node.id}`,
                key: `${node.organization.id}/${node.id}`,
                label: `${node.name}`,
              };
            }),
          );
          node = {
            title: getCurrentlocaleText("inodes.text"),
            value: `node/${org.id}`,
            key: `node/${org.id}/${org.id}`,
            label: `${getCurrentlocaleText("inodes.text")}`,
            children: nodeChildren,
          };
        }
        // For cluster
        if (clusterList && clusterList.length > 0) {
          clusterChildren.push(
            ...clusterList.map(cluster => {
              return {
                title: cluster.name,
                value: `cluster/${cluster.organization.id}/${cluster.id}`,
                key: `${cluster.organization.id}/${cluster.id}`,
                label: `${cluster.name}`,
              };
            }),
          );
          cluster = {
            title: getCurrentlocaleText("clusters.text"),
            value: `cluster/${org.id}`,
            key: `cluster/${org.id}/${org.id}`,
            label: `${getCurrentlocaleText("clusters.text")}`,
            children: clusterChildren,
          };
        }
        let all = {
          title: getCurrentlocaleText("all.events"),
          value: `all/${org.id}`,
          key: `all/${org.id}`,
          label: getCurrentlocaleText("all.events"),
        };
        if (this.props.type === "event_downloads") {
          children.push(all);
        }
        // Push node if org has nodes
        if (node) {
          children.push(node);
        }
        // Push cluster if org has cluster
        if (cluster) {
          children.push(cluster);
        }
        orgInodeClusterTree.push({
          title: org.name,
          value: org.id,
          key: org.id,
          label: org.name,
          children: this.props.type === "activity_downloads" ? null : children,
        });
      });
    }
    // Apply sorting
    orgInodeClusterTree.sort((x, y) => customSort(x.title, y.title));
    if (orgInodeClusterTree.children) {
      orgInodeClusterTree.children.forEach(orgChildren => {
        if (orgChildren) {
          orgChildren.children.forEach(children => {
            if (children) {
              children.sort((x, y) => {
                customSort(x.title, y.title);
              });
            }
          });
        }
      });
    }
    this.setState({ orgInodeClusterTree: orgInodeClusterTree });
  };

  handleOrgInodeClusterChange = values => {
    let orgId = null,
      nodeId = null,
      clusterId = null;
    if (values) {
      values.forEach(value => {
        value = value.split("/");
        if (value[0] === "node") {
          orgId = value[1];
          nodeId = value[2];
        } else if (value[0] === "cluster") {
          orgId = value[1];
          clusterId = value[2];
        } else if (value[0] === "all") {
          orgId = value[1];
        } else if (value[0]) {
          orgId = value[0];
        }
      });
    }
    this.setState({ orgId: orgId, nodeId: nodeId, clusterId: clusterId });
  };

  getOrgInfo = record => {
    let orgId, nodeId;
    if (record && record.parameters) {
      orgId = record.parameters.org_id;
      nodeId = record.parameters.node_id;
    }
    let orgList = this.state.orgList ? this.state.orgList : [];
    let nodeList = this.state.nodeList ? this.state.nodeList : [];
    let orgDetail = orgList.find(res => {
      return res.id === orgId;
    });
    let nodeDetail = nodeList.find(res => {
      return res.id === nodeId;
    });
    if (orgDetail) {
      return (
        <div>
          <Row>
            <Col span={3}>
              <strong>
                {getCurrentlocaleText("alertform.organization.text")}
              </strong>
            </Col>
            <Col span={1}>
              <strong>{":"}</strong>
            </Col>
            <Col span={7}>{orgDetail.name}</Col>
          </Row>
          {nodeDetail && (
            <Row>
              <Col span={3}>
                <strong>
                  {getCurrentlocaleText("general.component.inode.text")}
                </strong>
              </Col>
              <Col span={1}>
                <strong>{":"}</strong>
              </Col>
              <Col>{nodeDetail.name}</Col>
            </Row>
          )}
          {record.parameters &&
            record.parameters.resource_group &&
            this.props.type === "event_downloads" && (
              <Row>
                <Col span={3}>
                  <strong>{getCurrentlocaleText("resource.name.text")}</strong>
                </Col>
                <Col span={1}>
                  <strong>{":"}</strong>
                </Col>
                <Col>{record.parameters.resource_group}</Col>
              </Row>
            )}
        </div>
      );
    } else {
      return (
        <div>
          <Row>
            <Col span={3}>
              <strong>
                {getCurrentlocaleText("alertform.organization.text")}
              </strong>
            </Col>
            <Col span={1}>
              <strong>{":"}</strong>
            </Col>
            <Col>
              {this.props.AuthStore.loggedInUser.organization &&
                this.props.AuthStore.loggedInUser.organization.name}
            </Col>
          </Row>
          {nodeDetail && (
            <Row>
              <Col span={3}>
                <strong>
                  {getCurrentlocaleText("general.component.inode.text")}
                </strong>
              </Col>
              <Col span={1}>
                <strong>{":"}</strong>
              </Col>
              <Col>{nodeDetail.name}</Col>
            </Row>
          )}
          {record.parameters &&
            record.parameters.resource_group &&
            this.props.type === "event_downloads" && (
              <Row>
                <Col span={3}>
                  <strong>{getCurrentlocaleText("resource.name.text")}</strong>
                </Col>
                <Col span={1}>
                  <strong>{":"}</strong>
                </Col>
                <Col span={6}>
                  {this.getResourceName(record.parameters.resource_group)}
                </Col>
              </Row>
            )}
        </div>
      );
    }
  };

  getResourceName = group => {
    switch (group) {
      case "node":
        return getCurrentlocaleText("general.component.inode.text");
      case "network":
        return getCurrentlocaleText("general.component.networks.text");
      case "service":
        return getCurrentlocaleText("general.component.service.text");
      case "webhook":
        return getCurrentlocaleText("general.component.webhook.text");
    }
  };

  DownloadStatusColor = status => {
    switch (status) {
      case "completed":
        return "iot-status-green";
      case "error":
        return "iot-status-red";
      case "pending":
        return "iot-status-yellow";
    }
  };

  lazyLoad = () => {
    if (this.props.DownloadViewModal.pageable.next) {
      let page = this.props.DownloadViewModal.pageable.page + 1;
      this.props.lazyLoad(page);
    }
  };

  disabledDate = current => {
    // Can not select days after today and today
    return current && current.valueOf() > Date.now();
  };
  render() {
    let data = this.props.DownloadViewModal.getDownloadRequestLists;
    let orgPolicy = this.state.orgPolicy;
    let columns = [
      {
        title: (
          <span>
            {getCurrentlocaleText("events.requested.header.text")}
            <PopOverHelp
              isHelpOnForm={true}
              height={80}
              width={225}
              placement="right"
              helpData={{
                title: "",
                data: [
                  {
                    subtitle: "",
                    content: getCurrentlocaleText(
                      "download.delete.activity.message.info.text",
                    ),
                  },
                ],
              }}
            />
          </span>
        ),
        key: "requested_on",
        width: "35%",
        render: (text, record, index) => {
          return (
            <span>
              {record.requested_on ? (
                <span>
                  {record.requested_on &&
                    `${moment(new Date(record.requested_on).getTime()).format(
                      TIME_DATE_FORMAT,
                    )} ${getTimezoneToDisplay(record.requested_on)}`}
                </span>
              ) : (
                <span>-</span>
              )}
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText("event.status.text"),
        key: "status",
        width: "35%",
        render: (text, record, index) => {
          return (
            <div>
              {record.status && (
                <strong
                  className={this.DownloadStatusColor(
                    record.status.toLowerCase(),
                  )}
                >
                  {record.status === "PENDING" ? (
                    <Popover
                      placement="top"
                      content={getCurrentlocaleText(
                        "download.pending.info.text",
                      )}
                    >
                      {record.status}
                    </Popover>
                  ) : (
                    record.status
                  )}
                </strong>
              )}
            </div>
          );
        },
      },
      {
        title: "",
        key: "action",
        render: (text, record, index) => {
          return <div>{this.getInfoDownloadInfo(record)}</div>;
        },
      },
    ];
    return (
      <div
        style={{
          padding: "10px 24px",
          background: "#fff",
          minHeight: 50,
        }}
      >
        <Row gutter={16}>
          <Col span={24} style={{ display: "grid" }}>
            <div
              style={{
                marginBottom: "10px",
                justifyContent: "flex-start",
                float: "left",
              }}
            >
              <Row>
                <Col span={24}>
                  <Card bordered={false} className="download-top-pane">
                    <strong className="download-top-pane-input">
                      {this.props.type === "event_downloads"
                        ? getCurrentlocaleText(
                            "download.events.request.label.text",
                          )
                        : getCurrentlocaleText(
                            "download.activity.request.label.text",
                          )}
                    </strong>
                    {/* Event Type Filter */}
                    {this.props.type === "event_downloads" && (
                      <Select
                        placeholder={getCurrentlocaleText(
                          "event.typefilter.placeholder",
                        )}
                        className="download-top-pane-input"
                        allowClear={true}
                        onChange={this.handleEventTypeChange}
                        notFoundContent={getCurrentlocaleText(
                          "general.notfound.placeholder.text",
                        )}
                      >
                        <Option value="node">
                          {getCurrentlocaleText("general.component.inode.text")}
                        </Option>
                        <Option value="network">
                          {getCurrentlocaleText(
                            "general.component.networks.text",
                          )}
                        </Option>
                        <Option value="service">
                          {getCurrentlocaleText(
                            "general.component.service.text",
                          )}
                        </Option>
                        <Option value="webhook">
                          {getCurrentlocaleText(
                            "general.component.webhook.text",
                          )}
                        </Option>
                      </Select>
                    )}
                    {/* Org & iNode & cluster Filter */}
                    <Cascader
                      allowClear
                      className="download-top-pane-input"
                      dropdownClassName="event-org-node-cluster-selector-dd"
                      placeholder={
                        this.props.type === "event_downloads"
                          ? getCurrentlocaleText("event.orgfilter.placeholder")
                          : getCurrentlocaleText("general.filter.by.org.text")
                      }
                      dropdownStyle={{ maxHeight: 250, overflow: "auto" }}
                      options={this.state.orgInodeClusterTree}
                      onChange={this.handleOrgInodeClusterChange}
                      treeNodeLabelProp="label"
                      notFoundContent={getCurrentlocaleText(
                        "general.notfound.placeholder.text",
                      )}
                    />
                    {/* Start Date Filter */}
                    <span className="download-top-pane-input-error">
                      <DatePicker
                        showTime={{
                          defaultValue: moment(),
                        }}
                        format="YYYY-MM-DD HH:mm:ss"
                        placeholder={getCurrentlocaleText(
                          "event.startdatefilter.placeholder",
                        )}
                        onChange={(date, dateString) => {
                          this.state.endDate &&
                          this.state.endDate.diff(date) < 0
                            ? this.setState({
                                isInvalidRange: true,
                              })
                            : this.setState({
                                isInvalidRange: false,
                              });
                          let timezone =
                            this.props.TimezoneViewModel.userTimezone ||
                            this.props.TimezoneViewModel.orgTimezone ||
                            getDefaultTimezone().value;
                          date = date
                            ? date._z
                              ? date.tz(timezone)
                              : moment.tz(dateString, timezone)
                            : date;
                          this.setState({
                            startDate: date,
                          });
                        }}
                        disabledDate={this.disabledDate}
                        title={getCurrentlocaleText(
                          "event.startdatefilter.placeholder",
                        )}
                      />
                      {this.state.isInvalidRange && (
                        <span className="download-invalid-range">
                          {getCurrentlocaleText(
                            "download.fromto.invalid_date_range.error.text",
                          )}
                        </span>
                      )}
                    </span>
                    {/* End Date Filter */}
                    <span className="download-top-pane-input-error">
                      <DatePicker
                        showTime={{
                          defaultValue: moment(),
                        }}
                        format="YYYY-MM-DD HH:mm:ss"
                        placeholder={getCurrentlocaleText(
                          "event.enddatefilter.placeholder",
                        )}
                        onChange={(date, dateString) => {
                          this.state.startDate &&
                          date.diff(this.state.startDate) < 0
                            ? this.setState({
                                isInvalidRange: true,
                              })
                            : this.setState({
                                isInvalidRange: false,
                              });
                          let timezone =
                            this.props.TimezoneViewModel.userTimezone ||
                            this.props.TimezoneViewModel.orgTimezone ||
                            getDefaultTimezone().value;
                          date = date
                            ? date._z
                              ? date.tz(timezone)
                              : moment.tz(dateString, timezone)
                            : date;
                          this.setState({
                            endDate: date,
                          });
                        }}
                        disabledDate={this.disabledDate}
                        title={getCurrentlocaleText(
                          "event.enddatefilter.placeholder",
                        )}
                      />
                      {this.state.isInvalidRange && (
                        <span className="download-invalid-range">
                          {getCurrentlocaleText(
                            "download.fromto.invalid_date_range.error_1.text",
                          )}
                        </span>
                      )}
                    </span>
                    {/* Apply button */}
                    <span>
                      <Button
                        type={
                          orgPolicy &&
                          data &&
                          data.length >=
                            getOrgPolicyCounInfo(
                              orgPolicy,
                              this.props.type === "event_downloads"
                                ? "event_download_count"
                                : "activity_download_count",
                            )
                            ? ""
                            : "primary"
                        }
                        className="download-req-btn"
                        onClick={this.sendDownloadRequest}
                        title={
                          orgPolicy &&
                          data &&
                          data.length >=
                            getOrgPolicyCounInfo(
                              orgPolicy,
                              this.props.type === "event_downloads"
                                ? "event_download_count"
                                : "activity_download_count",
                            )
                            ? getCurrentlocaleText(
                                "download.send_request.button.disable.text",
                                {
                                  0: getOrgPolicyCounInfo(
                                    orgPolicy,
                                    this.props.type === "event_downloads"
                                      ? "event_download_count"
                                      : "activity_download_count",
                                  ),
                                },
                              )
                            : getCurrentlocaleText(
                                "download.send_request.button.text",
                              )
                        }
                        size="small"
                        disabled={
                          orgPolicy &&
                          data &&
                          data.length >=
                            getOrgPolicyCounInfo(
                              orgPolicy,
                              this.props.type === "event_downloads"
                                ? "event_download_count"
                                : "activity_download_count",
                            )
                            ? true
                            : false
                        }
                      >
                        <Icons
                          type="io"
                          name="IoIosSend"
                          style={{ fontSize: 16, marginLeft: 10 }}
                        />{" "}
                      </Button>
                    </span>
                    <span>
                      <PopOverHelp
                        isHelpOnForm={true}
                        height={140}
                        width={225}
                        placement="right"
                        helpData={{
                          title: "",
                          data: [
                            orgPolicy &&
                            data &&
                            data.length >=
                              getOrgPolicyCounInfo(
                                orgPolicy,
                                this.props.type === "event_downloads"
                                  ? "event_download_count"
                                  : "activity_download_count",
                              )
                              ? {
                                  subtitle: "",
                                  content: getCurrentlocaleText(
                                    "download.reports.delay_time.disable.info.message",
                                  ),
                                }
                              : {
                                  subtitle: "",
                                  content: getCurrentlocaleText(
                                    "download.reports.delay_time.info.message",
                                  ),
                                },
                          ],
                        }}
                      />
                    </span>
                  </Card>
                </Col>
              </Row>
            </div>
            {/* Event List */}
            <div
              style={{
                background: "#fff",
                display: "inline-block",
                minHeight: 360,
              }}
            >
              {data && data.length > 0 && (
                <div>
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <Row>
                      <Col span={12}>
                        <span>
                          <a
                            style={{ fontSize: 14, marginLeft: 20 }}
                            onClick={this.expandRow}
                          >
                            <span>
                              <Icons
                                className={"valign-middle"}
                                type="ai"
                                name={
                                  this.state.expandedRowKeys.length !==
                                  data.length
                                    ? "AiOutlinePlusSquare"
                                    : "AiOutlineMinusSquare"
                                }
                              />
                              <span style={{ marginLeft: 5 }}>
                                {this.state.expandedRowKeys.length !==
                                data.length
                                  ? getCurrentlocaleText(
                                      "node.list.table.expand_all.label.text",
                                    )
                                  : getCurrentlocaleText(
                                      "node.list.table.collapse_all.label.text",
                                    )}
                              </span>
                            </span>
                          </a>
                        </span>
                      </Col>
                      <Col span={12}>
                        <span style={{ float: "right" }}>
                          <ActionBtn
                            title={
                              <span>
                                <Icons type="ai" name="AiOutlineDelete" />{" "}
                                {getCurrentlocaleText(
                                  this.state.deletableKeys.keys.length > 1
                                    ? "download.delete_file.title.text"
                                    : "download.delete.title.text",
                                )}
                              </span>
                            }
                            HoverText={
                              <span>
                                {getCurrentlocaleText(
                                  this.state.deletableKeys.keys.length > 1
                                    ? "download.delete_file.button.text"
                                    : "download.delete.button.text",
                                )}
                              </span>
                            }
                            resource="downloads"
                            orgId={this.props.orgId}
                            iconButton={true}
                            controller={DownloadController}
                            icon="AiOutlineDelete"
                            cancelText={getCurrentlocaleText(
                              this.state.deletableKeys.keys.length > 1
                                ? "download.delete_file.cancel.text"
                                : "download.delete.cancel.text",
                            )}
                            action="delete"
                            disabled={this.state.deletableKeys.keys.length <= 0}
                            formData={this.state.deletableKeys.keys}
                            warningMessage={getCurrentlocaleText(
                              "download.multidelete.confirmation.message",
                            )}
                            afterCall={() => {
                              this.getDownloadRequestLists();
                              this.resetState();
                            }}
                          >
                            <Icons type="ai" name="AiOutlineDelete" />{" "}
                            {getCurrentlocaleText(
                              this.state.deletableKeys.keys.length <= 1
                                ? "download.delete.ok.text"
                                : "download.delete_file.ok.text",
                            )}
                          </ActionBtn>
                        </span>
                      </Col>
                    </Row>
                  </div>
                </div>
              )}
              {data && data.length > 0 ? (
                <Row gutter={16} type="flex" align="middle">
                  <Col span={24}>
                    <TableLayout
                      pagination={false}
                      columns={columns}
                      dataSource={data}
                      lazyLoad={this.lazyLoad}
                      selectedRows={this.handleTableChange}
                      selectAll={this.onSelectAllChange}
                      expandedRowKeys={this.state.expandedRowKeys}
                      onExpand={this.onExpand}
                      expandedRowRender={record => {
                        return this.getMoreInfo(record);
                      }}
                      className="inodeList"
                      hasLazyLoading={true}
                      scroll={{ y: 200 }}
                    />
                  </Col>
                </Row>
              ) : (
                <Row gutter={16} type="flex" align="middle">
                  <Col span={24}>
                    <div>
                      <h3 style={{ paddingLeft: 24 }}>
                        {this.props.type === "event_downloads"
                          ? getCurrentlocaleText(
                              "download.nocontent.display.text",
                            )
                          : getCurrentlocaleText(
                              "download.nocontent.activity.display.text",
                            )}
                      </h3>
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
