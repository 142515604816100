import { React } from "react";
import BaseController from "./BaseController";
import Resources from "../Core/API/Resources";
import { copyObject } from "../Core/Utils";
import RepNetViewModel from "../view-model/RepNetViewModel";
import FileSaver from "file-saver";

const RESOURCE = "repNet";
const REPNET_URL = Resources.getUrl(RESOURCE);

const getNetworks = (from, params) => {
  return new Promise((resolve, reject) => {
    RepNetViewModel.setLoading(true);

    let url = REPNET_URL + getUrl(from);

    BaseController.query(RESOURCE, url, copyObject(params))
      .then(response => {
        if (response) {
          if (!params.page || params.page === 0) {
            RepNetViewModel.resetNetworks(from);
          }
          RepNetViewModel.setPageable(response, from);
          RepNetViewModel.setLoading(false, from);
        }
        resolve(response);
      })
      .catch(error => {
        RepNetViewModel.setLoading(false);
        reject(error);
      });
  });
};

const downloadRepNet = (from, params) => {
  return new Promise((resolve, reject) => {
    let url = REPNET_URL + getUrl(from) + "/download?" + params;
    window.open(url, "_blank");
  });
};

const getUrl = from => {
  let url;
  switch (from) {
    case "fromVirtual":
      url = "from_virtual";
      break;
    case "edgeStaticRoutes":
      url = "edge_static_route";
      break;
    case "fromEdge":
    default:
      url = "from_edge";
      break;
  }
  return url;
};

const downloadLookup = response => {
  const csvContent = response.map(row => row.join(",")).join("\n");

  let blob = new Blob([csvContent], {
    type: "text/csv;charset=utf-8;",
  });
  let file = "Lookup";
  FileSaver.saveAs(blob, file);
};

export { getNetworks, downloadRepNet, downloadLookup };
