import React, { Component } from "react";
import ActionBtn from "Core/API/ActionBtn";
import UsersForm from "components/Forms/UsersForm";
import { observer, inject } from "mobx-react";
import { Row, Col } from "antd";
import UserController from "controller/UserController";
import { getCurrentlocaleText } from "Core/Utils";
import Icons from "./Icons";

@inject("ModelStore")
@observer
class DeleteUser extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Row gutter={24}>
          <Col span={8}>
            <ActionBtn
              title={
                <span>
                  <Icons type="ai" name="AiOutlineDelete" />{" "}
                  {getCurrentlocaleText(
                    "single.user.delete.confirmation.title",
                  )}
                </span>
              }
              messageContent={getCurrentlocaleText(
                "single.user.delete.confirmation.message",
              )}
              resource="users"
              orgId={this.props.orgId}
              icon="AiOutlineDelete"
              formData={this.props.formData}
              iconButton
              map={{ id: this.props.userId }}
              afterCall={this.props.afterCall}
              action="delete"
              actionForm={UsersForm}
              disabled={this.props.disabled}
              controller={UserController}
              cancelText={getCurrentlocaleText(
                "single.user.delete.confirmation.cancel.text",
              )}
              warningMessage={getCurrentlocaleText(
                "single.user.delete.warning.message",
              )}
            >
              <Icons type="ai" name="AiOutlineDelete" style={{ margin: 0 }} />{" "}
              {getCurrentlocaleText("single.user.delete.confirmation.ok.text")}
            </ActionBtn>
          </Col>
        </Row>
      </div>
    );
  }
}
export default DeleteUser;
