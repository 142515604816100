import React, { Component } from "react";
import { Tag, Row, Col, Popover, Popconfirm, Checkbox } from "antd";
import TableLayout from "components/layout/TableLayout";
import { observer, inject } from "mobx-react";
import { getCurrentlocaleText } from "Core/Utils";
import SizeUnit from "Core/SizeUnit";
import ListenerFirewallFunc from "components/Containers/ListenerFirewallFunc";
import StatusContent from "components/Containers/StatusContent";
import NetworkController from "controller/NetworkController";
import ResetCounterIcon from "../../assets/img/reset_counter.svg";
import ServiceListenerController from "../../controller/ServiceListenerController";
import Icons from "../UI-Components/Icons";

@inject(
  "AuthStore",
  "InodeViewModel",
  "NetworkViewModel",
  "ServiceListenerViewModel",
)
@observer
class ListenerFirewall extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = this.props.InodeViewModel;
    this.networkModel = this.props.NetworkViewModel;
    this.listenerViewModel = this.props.ServiceListenerViewModel;
    this.updateHitcounterInterval = null;
    this.state = {
      showUnResolvedRules: false,
      FirewallStatus: this.props.FirewallStatus,
      listenerFirewallTableData: [],
      showResetHitcounter: false,
      isDontShowChecked: false,
      resetShowedNetworks: [],
      visible: false,
      resetShowedNodes: [],
      NetworkIds: [],
      listenerId: null,
    };
  }

  prepareFormData = values => {
    let firewall = values.firewallgroup;
    let policy = {
      policy: {
        firewall: firewall.map(val => {
          return val;
        }),
      },
    };
    return policy;
  };

  componentDidMount() {
    let NetworkIds = this.state.NetworkIds || [],
      listeners = this.props.FirewallStatus;
    if (
      listeners &&
      listeners.listener_statuses &&
      listeners.listener_statuses.length > 0
    ) {
      listeners.listener_statuses.forEach(listener => {
        NetworkIds.push(listener.network);
      });
    }
    this.refreshRulesHitCounters();
    this.setState({
      listenerFirewallTableData: ListenerFirewallFunc.FirewallTableAllData(
        this.props.FirewallStatus,
        this.props.availableNetwork,
        this.state.showUnResolvedRules,
      ),
      NetworkIds: NetworkIds,
      listenerId: this.props.listenerID,
    });
    let showedNetworks = [];
    let currentUserId = this.props.AuthStore.loggedInUser.id,
      showediNodes = [];
    if (localStorage[currentUserId]) {
      if (JSON.parse(localStorage[currentUserId]).CounterResetSkippedNetworks)
        showedNetworks = JSON.parse(localStorage[currentUserId])
          .CounterResetSkippedNetworks;
      if (JSON.parse(localStorage[currentUserId]).CounterResetSkippediNodes)
        showediNodes = JSON.parse(localStorage[currentUserId])
          .CounterResetSkippediNodes;
    }
    this.setState({
      resetShowedNetworks: showedNetworks,
      resetShowedNodes: showediNodes,
    });
  }

  refreshRulesHitCounters = () => {
    this.updateAllListenersStatus();
  };
  componentWillUnmount() {
    /*Required to clear interval on component unmount*/
    this.setState({ listenerId: null });
    clearInterval(this.updateHitcounterInterval);
  }

  updateAllListenersStatus = () => {
    this.updateHitcounterInterval = setInterval(() => {
      this.getServiceListenerDetails();
    }, 30000);
  };

  checkForDebug = () => {
    let Networks = this.props.availableNetwork,
      status = false;
    if (Networks && Networks.length > 0) {
      Networks.forEach(Network => {
        if (ListenerFirewallFunc.checkForDebugEnabled(Network)) status = true;
      });
    }
    return status;
  };

  AddiNodeDontShowList = () => {
    let currentUserId = this.props.AuthStore.loggedInUser.id;
    let showedNetworks = [],
      showediNodes = [];
    if (localStorage[currentUserId]) {
      if (JSON.parse(localStorage[currentUserId]).CounterResetSkippedNetworks)
        showedNetworks = JSON.parse(localStorage[currentUserId])
          .CounterResetSkippedNetworks;
      if (JSON.parse(localStorage[currentUserId]).CounterResetSkippediNodes)
        showediNodes = JSON.parse(localStorage[currentUserId])
          .CounterResetSkippediNodes;
    }
    showediNodes.push(this.props.nodeId);
    localStorage.setItem(
      currentUserId,
      JSON.stringify({
        CounterResetSkippedNetworks: showedNetworks,
        CounterResetSkippediNodes: showediNodes,
      }),
    );
    this.setState({
      resetShowedNetworks: showedNetworks,
      isDontShowChecked: false,
      resetShowedNodes: showediNodes,
    });
  };
  handleVisibleChange = visible => {
    if (!visible) {
      this.setState({ visible });
      return;
    }
    // Determining condition before show the popconfirm.
    if (
      this.props.ShowAll &&
      this.state.resetShowedNodes.includes(this.props.nodeId)
    ) {
      this.resetHitCounter(); // next step
    } else {
      this.setState({ visible }); // show the popconfirm
    }
  };
  resetHitCounter = () => {
    let Networks = this.props.availableNetwork;
    let NetworkIds = [];
    if (Networks && Networks.length > 0) {
      Networks.forEach(Network => {
        if (ListenerFirewallFunc.checkForDebugEnabled(Network))
          NetworkIds.push(Network.id);
      });
      if (Networks.length > 0) {
        if (this.state.isDontShowChecked) this.AddiNodeDontShowList(NetworkIds);
        NetworkController.resetCounterByNetworkId(NetworkIds, true)
          .then(response => {
            this.triggerResetHitCounter(false);
          })
          .catch(error => {
            this.triggerResetHitCounter(false);
          });
      }
    }
  };

  UNSAFE_componentWillReceiveProps(newprops) {
    if (
      newprops.FirewallStatus != this.props.FirewallStatus ||
      newprops.availableNetwork != this.props.availableNetwork
    ) {
      let NetworkIds = this.state.NetworkIds || [],
        listeners = newprops.FirewallStatus;
      if (
        listeners &&
        listeners.listener_statuses &&
        listeners.listener_statuses.length > 0
      ) {
        listeners.listener_statuses.forEach(listener => {
          NetworkIds.push(listener.network);
        });
      }
      this.setState({
        FirewallStatus: newprops.FirewallStatus,
        NetworkIds: NetworkIds,
        listenerId: newprops.listenerID,
      });
      this.setState({
        listenerFirewallTableData: !newprops.ShowAll
          ? ListenerFirewallFunc.FirewallTableData(
              newprops.FirewallStatus,
              newprops.availableNetwork,
              this.state.showUnResolvedRules,
            )
          : ListenerFirewallFunc.FirewallTableAllData(
              newprops.FirewallStatus,
              newprops.availableNetwork,
              this.state.showUnResolvedRules,
            ),
      });
    }
  }
  getServiceListenerDetails = () => {
    if (this.state.listenerId) {
      ServiceListenerController.getServiceListenerDetail(
        this.state.listenerId,
      ).then(res => {
        this.setState({
          listenerFirewallTableData: ListenerFirewallFunc.FirewallTableAllData(
            this.updateEnabledNetwork(res),
            this.props.availableNetwork,
            this.state.showUnResolvedRules,
          ),
        });
      });
    }
  };

  updateEnabledNetwork(result) {
    this.setState({ FirewallStatus: result ? result.status : null });
    return result ? result.status : null;
  }

  changeListview = e => {
    this.setState({ showUnResolvedRules: e.target.checked });
  };
  triggerResetHitCounter = status => {
    this.setState({ showResetHitcounter: status });
  };
  prepareNetworkData = (record, interface_type) => {
    if (
      record.source_network &&
      record.source_network.length > 0 &&
      interface_type === "source"
    ) {
      if (
        record.source_network.toString() != "AnyPeer" &&
        !record.source_network.toString().includes("_iotium") &&
        !record.source_network.toString().includes(":")
      ) {
        return ListenerFirewallFunc.getNetworkGroupInfo(
          record.source_network.toString(),
          this.props.orgsNetwork,
        );
      } else {
        if (record.source_network.toString().includes("_iotium.network.id")) {
          return ListenerFirewallFunc.getNetworkGroupInfo(
            record.source_network
              .toString()
              .split(":")[1]
              .trim(),
            this.props.orgsNetwork,
          );
        } else {
          return ListenerFirewallFunc.checkforAnyString(
            record.source_network.toString(),
          ).length > 15 ? (
            <Popover
              placement="top"
              content={ListenerFirewallFunc.checkforAnyString(
                record.source_network.toString(),
              )}
            >
              <Tag className={"firewall-rules-tag-content iot-tag-label-color"}>
                {" "}
                <Icons
                  type="ai"
                  name="AiOutlineTag"
                  style={{ marginRight: 5 }}
                />
                {ListenerFirewallFunc.checkforAnyString(
                  record.source_network.toString(),
                )}
              </Tag>
            </Popover>
          ) : (
            <Tag className="iot-tag-label-color">
              {" "}
              <Icons type="ai" name="AiOutlineTag" style={{ marginRight: 5 }} />
              {ListenerFirewallFunc.checkforAnyString(
                record.source_network.toString(),
              )}
            </Tag>
          );
        }
      }
    } else if (
      record.destination_network &&
      record.destination_network.length > 0 &&
      interface_type === "destination"
    ) {
      if (
        record.destination_network.toString() != "AnyPeer" &&
        !record.destination_network.toString().includes("_iotium") &&
        !record.destination_network.toString().includes(":")
      ) {
        return ListenerFirewallFunc.getNetworkGroupInfo(
          record.destination_network.toString(),
          this.props.orgsNetwork,
        );
      } else {
        if (
          record.destination_network.toString().includes("_iotium.network.id")
        ) {
          return ListenerFirewallFunc.getNetworkGroupInfo(
            record.destination_network
              .toString()
              .split(":")[1]
              .trim(),
            this.props.orgsNetwork,
          );
        } else {
          return ListenerFirewallFunc.checkforAnyString(
            record.destination_network.toString(),
          ).length > 15 ? (
            <Popover
              placement="top"
              content={ListenerFirewallFunc.checkforAnyString(
                record.destination_network.toString(),
              )}
            >
              <Tag className={"firewall-rules-tag-content iot-tag-label-color"}>
                {" "}
                <Icons
                  type="ai"
                  name="AiOutlineTag"
                  style={{ marginRight: 5 }}
                />
                {ListenerFirewallFunc.checkforAnyString(
                  record.destination_network.toString(),
                )}
              </Tag>
            </Popover>
          ) : (
            <Tag className="iot-tag-label-color">
              {" "}
              <Icons type="ai" name="AiOutlineTag" style={{ marginRight: 5 }} />
              {ListenerFirewallFunc.checkforAnyString(
                record.destination_network.toString(),
              )}
            </Tag>
          );
        }
      }
    } else {
      return ListenerFirewallFunc.checkforAnyString("*");
    }
  };
  ChangePopupVisibility = () => {
    this.setState({ isDontShowChecked: !this.state.isDontShowChecked });
  };
  render() {
    this.columns = [
      {
        title: (
          <span className={"pheonix-table-content-wrap"}>
            {getCurrentlocaleText("firewall.all_rules.rules.table.header.text")}
          </span>
        ),
        dataIndex: "network_name",
        render: (text, record, index) => {
          return (
            <span style={{ wordBreak: "break-word" }} key={index}>
              {record.network_name}
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText("firewall.list.table.header.priority.text"),
        dataIndex: "priority",
        sorter: (a, b) => a.priority - b.priority,
        render: (text, record, index) => {
          return <span>{record.priority}</span>;
        },
      },
      {
        title: (
          <span className={"pheonix-table-content-wrap"}>
            {getCurrentlocaleText("general.firewall.from_network.label.text")}
          </span>
        ),
        dataIndex: "source_network",
        render: (text, record, index) => {
          return <span>{this.prepareNetworkData(record, "source")}</span>;
        },
      },
      {
        title: (
          <span className={"pheonix-table-content-wrap"}>
            {getCurrentlocaleText("general.firewall.to_network.label.text")}
          </span>
        ),
        dataIndex: "destination_network",
        render: (text, record, index) => {
          return <span>{this.prepareNetworkData(record, "destination")}</span>;
        },
      },
      {
        title: getCurrentlocaleText("firewall.list.table.header.protocol.text"),
        dataIndex: "protocol",
        render: (text, record, index) => {
          return <span>{record.protocol}</span>;
        },
      },
      {
        title: (
          <span className={"pheonix-table-content-wrap"}>
            {getCurrentlocaleText(
              "firewall.list.table.header.source_port.text",
            )}
          </span>
        ),
        dataIndex: "sourceport",
        render: (text, record, index) => {
          return (
            <span>
              {ListenerFirewallFunc.checkforAnyString(record.source_port)}
            </span>
          );
        },
      },
      {
        title: (
          <span className={"pheonix-table-content-wrap"}>
            {getCurrentlocaleText(
              "firewall.list.table.header.destination_port.text",
            )}
          </span>
        ),
        dataIndex: "port",
        render: (text, record, index) => {
          return (
            <span>{ListenerFirewallFunc.checkforAnyString(record.port)}</span>
          );
        },
      },
      {
        title: (
          <span className={"pheonix-table-content-wrap"}>
            {getCurrentlocaleText("firewall.list.table.header.source.text")}
          </span>
        ),
        dataIndex: "source_ipaddress",
        render: (text, record, index) => {
          return (
            <span key={index}>
              {record.source_tag && record.source_tag.includes("@tag:") ? (
                <Popover
                  content={ListenerFirewallFunc.checkforAnyString(
                    record.source_ipaddress,
                  )}
                  title={getCurrentlocaleText(
                    "firewall.list.table.source.hover.title.text",
                  )}
                >
                  <Tag>{record.source_tag.replace("@tag:", " ")}</Tag>
                </Popover>
              ) : record.source_ipaddress ? (
                ListenerFirewallFunc.checkforAnyString(record.source_ipaddress)
              ) : (
                ListenerFirewallFunc.checkforAnyString("*")
              )}
            </span>
          );
        },
      },
      {
        title: (
          <span className={"pheonix-table-content-wrap"}>
            {getCurrentlocaleText(
              "firewall.list.table.header.destination.text",
            )}
          </span>
        ),
        dataIndex: "destination_ipaddress",
        render: (text, record, index) => {
          return (
            <span key={index}>
              {record.destination_tag &&
              record.destination_tag.includes("@tag:") ? (
                <Popover
                  content={ListenerFirewallFunc.checkforAnyString(
                    record.destination_ipaddress,
                  )}
                  title={getCurrentlocaleText(
                    "firewall.list.table.destination.hover.title.text",
                  )}
                >
                  <Tag>{record.destination_tag.replace("@tag:", " ")}</Tag>
                </Popover>
              ) : record.destination_ipaddress ? (
                ListenerFirewallFunc.checkforAnyString(
                  record.destination_ipaddress,
                )
              ) : (
                ListenerFirewallFunc.checkforAnyString("*")
              )}
            </span>
          );
        },
      },
      {
        title: (
          <span className={"pheonix-table-content-wrap"}>
            {getCurrentlocaleText("general.table.actions.header.text")}
          </span>
        ),
        dataIndex: "action",
        render: (text, record, index) => {
          return (
            <span key={index}>
              <Tag
                style={{ minWidth: 85, textAlign: "center" }}
                className={ListenerFirewallFunc.getTagColor(record.action)}
                key={index}
              >
                {record.action}
              </Tag>
            </span>
          );
        },
      },
      {
        title: (
          <span className={"pheonix-table-content-wrap"}>
            {getCurrentlocaleText("firewall.rule.byte_header.text")}
            {((!this.props.ShowAll &&
              !this.props.viewOnly &&
              this.props.debugEnabled &&
              !this.props.cluster_id) ||
              (this.props.ShowAll && this.state.NetworkIds.length > 0)) && (
              <Popconfirm
                overlayClassName={"custom-inline-popup"}
                placement="rightTop"
                title={
                  <div className="inline-popup" style={{ marginLeft: -20 }}>
                    <div className="inline-popup-header">
                      {" "}
                      <p className="inline-popup-title">
                        {getCurrentlocaleText(
                          "firewall.reset.counter.inline.popup.title",
                        )}
                      </p>
                    </div>
                    <p className="inline-popup-body">
                      {
                        <span>
                          <p>
                            {getCurrentlocaleText(
                              "firewall.reset.counter.all_rules.message",
                              { 0: this.props.ShowAll ? "iNode" : "network" },
                            )}
                          </p>
                        </span>
                      }
                    </p>
                    <Checkbox
                      checked={this.state.isDontShowChecked}
                      onChange={this.ChangePopupVisibility}
                    >
                      {getCurrentlocaleText("firewall.reset.skip.text", {
                        0: this.props.ShowAll ? "iNode" : "network",
                      })}
                    </Checkbox>
                  </div>
                }
                visible={this.state.visible}
                onVisibleChange={this.handleVisibleChange}
                overlayStyle={{ width: "25%" }}
                onConfirm={this.resetHitCounter}
                okText={getCurrentlocaleText(
                  "firewall.reset.counter.confirm.button.text",
                )}
                cancelText="No"
              >
                <span
                  title={getCurrentlocaleText(
                    "firewall.reset.counter.button.label.text",
                  )}
                >
                  <ResetCounterIcon className="custom-icon-reset-counter" />
                </span>
              </Popconfirm>
            )}
          </span>
        ),
        dataIndex: "packet_count",
        key: "packet_count",
        width: "8%",
        render: (text, record, index) => {
          return (
            <span className={"pheonix-table-content-wrap"} key={index}>
              {record.packet_count && record.byte_count ? (
                <Popover
                  key={index}
                  content={
                    <StatusContent
                      packets={ListenerFirewallFunc.getStatusPacketsData(
                        record,
                      )}
                      bytes={SizeUnit.formatBytesSize(record.byte_count)}
                      key={index}
                    />
                  }
                  title={getCurrentlocaleText("firewall.rule.byte_header.text")}
                >
                  <span>
                    {ListenerFirewallFunc.getStatusPacketsData(record) +
                      "/" +
                      SizeUnit.formatBytesSize(record.byte_count)}
                  </span>
                </Popover>
              ) : (
                <span>{"-"}</span>
              )}
            </span>
          );
        },
      },
    ];

    let content = (
      <Row gutter={2}>
        <Col span={24}>
          <div style={{ textAlign: "right" }}>
            {this.props.nodeState === "ALIVE" &&
              (this.props.debugEnabled
                ? this.props.debugEnabled
                  ? true
                  : false
                : this.checkForDebug()) && (
                <span style={{ fontWeight: "normal", marginRight: 20 }} />
              )}{" "}
            {/* <span style={{ fontWeight: "normal", marginRight: 20 }}>
              <Checkbox onChange={this.changeListview}>
                {getCurrentlocaleText(
                  "firewall.list.table_view.checkbox.label",
                )}
              </Checkbox>
            </span> */}
          </div>
          <TableLayout
            columns={this.columns}
            dataSource={this.state.listenerFirewallTableData}
            rowClassName={(record, index) => {
              return record.is_generated ? "iot-generated-table-row-data" : "";
            }}
            tableSize="small"
            hideRowSelection={true}
            hideExpandedRow={true}
            pagination={false}
            style={{ marginTop: 20 }}
          />
        </Col>
      </Row>
    );

    return <div>{content}</div>;
  }
}

export default ListenerFirewall;
