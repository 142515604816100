import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Button, Menu, Tag, Row, Col, Popover } from "antd";
import { Link } from "react-router-dom";
import {
  getCurrentlocaleText,
  getTagKeyValues,
  getTagFromReservedLabels,
} from "Core/Utils";
import ActionRoute from "../../Core/API/ActionRoute";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import Icons from "components/UI-Components/Icons";
import "../../assets/styles/Webhook.css";
import { WebhookController } from "../../controller/Controllers";
import LabelProvider from "components/UI-Components/LabelProvider";
import ActionBtn from "Core/API/ActionBtn";
import DropdownActionBtn from "components/UI-Components/DropdownActionBtn";
import TableLayoutV1 from "../layout/TableLayoutV1";

@inject(
  "UiStore",
  "AlertViewModel",
  "AuthStore",
  "WebhookViewModel",
  "ChannelViewModel",
)
@observer
class WebhookList extends Component {
  constructor(props) {
    super(props);
    this.AuthStore = this.props.AuthStore;
    this.UiStore = this.props.UiStore;
    this.alertModel = this.props.AlertViewModel;
    this.webhookModel = this.props.WebhookViewModel;
    this.channelModel = this.props.ChannelViewModel;
    this.orgId = undefined;
    this.state = {
      expandedRowKeys: [],
      currentPage: 1,
      modalContent: "",
      modalTitle: "",
      isTableModalToggle: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.webhookList(true, 0);
  }

  webhookList = (loading = true, page = 0) => {
    let params = { page: page };
    if (this.orgId) {
      params.org_id = this.orgId;
      params.own = true;
    }
    WebhookController.getWebhooks(params, loading);
  };

  unsubscribe = subscription_id => {
    let obj = { id: subscription_id },
      data = [];
    data.push(obj);
    WebhookController.delete(data).then(response => {
      if (response) {
        this.UiStore.setSuccessMessage(
          getCurrentlocaleText("webhook.channel.delete.success.text"),
        );
        // Call webhook list
        this.webhookList(true, 0);
        this.setState({ expandedRowKeys: [] });
      }
    });
  };

  filterByOrg = value => {
    this.orgId = value;
    setTimeout(() => {
      this.alertList(true, 0);
      this.setState({ expandedRowKeys: [] });
    }, 100);
  };

  expandRow = () => {
    let keys = this.alertModel.alerts.map(item => {
      return item.id;
    });
    this.setExpandedRowKeys(
      this.state.expandedRowKeys.length !== keys.length ? keys : [],
    );
  };

  onExpand = (expanded, record) => {
    let keys = this.state.expandedRowKeys;
    let index = keys.findIndex(val => {
      return val === record.id;
    });
    if (expanded) {
      if (index === -1) {
        keys.push(record.id);
      }
      this.setExpandedRowKeys(keys);
    } else {
      if (index !== -1) {
        keys.splice(index, 1);
      }
      this.setExpandedRowKeys(keys);
    }
  };

  setExpandedRowKeys = keys => {
    this.setState({ expandedRowKeys: keys });
  };

  lazyLoad = () => {
    if (this.alertModel.pageable.next) {
      let page = this.alertModel.pageable.page + 1;
      this.alertList(true, page);
    }
  };

  componentWillUnmount() {
    this.webhookModel.resetWebhooks();
    this.channelModel.resetChannels();
  }

  getHeadersTag = headers => {
    if (headers && Object.keys(headers).length > 0) {
      return (
        <>
          {Object.entries(headers).map(([key, value]) => (
            <Tag key={key}>
              <span title={value}>
                {key}: {value}
              </span>
            </Tag>
          ))}
        </>
      );
    } else {
      return <span style={{ paddingLeft: "3px" }}> - </span>;
    }
  };

  getMoreInfo = record => {
    let tags = record.metadata ? getTagKeyValues(record.metadata.labels) : [];
    let reservedLabels = record.metadata
      ? getTagFromReservedLabels(record.metadata.labels)
      : [];
    return (
      <div>
        <Row style={{ marginBottom: 5 }} />
        <Row>
          <Col span={5}>
            <strong>{"Label"}</strong>
          </Col>
          <Col span={1}>
            <strong>{":"}</strong>
          </Col>
          <Col span={18}>
            {record.metadata && tags.length + reservedLabels.length > 0 ? (
              <div style={{ display: "inline-block", marginLeft: 2 }}>
                <LabelProvider
                  key={record.id}
                  tags={tags}
                  metadata={record.metadata.labels}
                  showPopupLabel={false}
                  viewOnly={true}
                  reservedLabels={reservedLabels}
                />
              </div>
            ) : (
              <span style={{ marginLeft: 2 }}>{"-"}</span>
            )}
          </Col>
        </Row>
        <Row style={{ marginTop: 5, marginBottom: 5 }} />
        <Row>
          <Col span={5}>
            <strong>{"Headers"}</strong>
          </Col>
          <Col span={1}>
            <strong>{":"}</strong>
          </Col>
          <Col span={18}>{this.getHeadersTag(record.headers)}</Col>
        </Row>
      </div>
    );
  };

  verifyWebhook = id => {
    WebhookController.verify({}, { id: id })
      .then(response => {
        this.webhookList(true, 0);
      })
      .catch(error => {});
  };

  getModalContentJSX = record => {
    this.setState({
      modalTitle: record && record.name ? record.name : "",
      modalContent: this.getMoreInfo(record),
    });
  };
  handleModalOk(e) {
    this.setState({
      isTableModalToggle: !this.state.isTableModalToggle,
    });
  }

  onTableChange = pagination => {
    this.setState({
      currentPage: pagination.current,
    });
    let page = pagination.current - 1;
    this.webhookList(true, page);
  };

  render() {
    const { isTableModalToggle } = this.state;
    let channelList = this.webhookModel.channelList;
    this.columns = [
      {
        title: getCurrentlocaleText("webhookform.channel_name.text"),
        key: "name",
        width: "20%",
        render: (text, record, index) => {
          return (
            <div>
              <span>
                {record.is_generated && record.type === "CERT_EXPIRY"
                  ? getCurrentlocaleText("inode.cert_status_alerts.text")
                  : record.is_generated && record.type === "HEADLESS_EXPIRY"
                  ? getCurrentlocaleText("headless_status_alerts.text")
                  : record.name}
              </span>
              {record.is_generated && (
                <span>
                  <br />
                  <Tag>
                    <Icons
                      type="fa"
                      className="alert-list-icon"
                      name="FaBell"
                    />{" "}
                    {getCurrentlocaleText("subscription.auto.label.text")}
                  </Tag>
                </span>
              )}
            </div>
          );
        },
      },
      {
        title: "URL",
        key: "url",
        width: this.orgId ? "20%" : "25%",
        render: (text, record, index) => {
          return <span>{record.url}</span>;
        },
      },
      {
        title: "Verified",
        key: "verify",
        width: this.orgId ? "20%" : "25%",
        render: (text, record, index) => {
          return (
            <span>
              {record.is_enabled ? (
                <Popover
                  content={getCurrentlocaleText("managewebhook.verified.text")}
                >
                  <span>
                    <Icons
                      type="ai"
                      name="AiOutlineCheckCircle"
                      className="iot-status-green webhook-list-verify-status"
                    />
                  </span>
                </Popover>
              ) : (
                <span style={{ display: "flex" }}>
                  <Popover
                    content={getCurrentlocaleText(
                      "managewebhook.unverified.text",
                    )}
                  >
                    <span>
                      <Icons
                        type="ai"
                        name="AiOutlineExclamationCircle"
                        className="iot-status-yellow webhook-list-verify-status"
                      />
                    </span>
                  </Popover>
                  <Button
                    size="small"
                    className="webhook-verify-button"
                    onClick={e => {
                      e.stopPropagation();
                      this.verifyWebhook(record.id);
                    }}
                  >
                    Retry
                  </Button>
                </span>
              )}
            </span>
          );
        },
      },
      {
        title: "",
        key: "action",
        width: "10%",
        render: (text, record, index) => {
          const edit = (
            <span
              onClick={() => {
                this.setState({ showRemoteInodelistview: false });
              }}
            >
              <ActionRoute
                actionIcon="AiOutlineEdit"
                actionLabel={getCurrentlocaleText(
                  "network.form.useraction_edit.label.text",
                )}
                iconButton
                title={getCurrentlocaleText(
                  "network.form.useraction_edit.label.text",
                )}
                route={window.location.pathname + "/edit/" + record.id}
              />
            </span>
          );
          const deletewebhook = (
            <span>
              <ActionBtn
                className="inline"
                title={
                  <span>
                    <Icons type="ai" name="AiOutlineDelete" />{" "}
                    {getCurrentlocaleText(
                      "managewebhook.unsubscribe.confirmation.text",
                    )}
                  </span>
                }
                messageContent={getCurrentlocaleText(
                  "managewebhook.unsubscribe.confirmation.message",
                )}
                orgId={this.props.orgId}
                HoverText={
                  <span>
                    {getCurrentlocaleText(
                      "managewebhook.unsubscribe.confirmation.text",
                    ).replace("?", " ")}
                  </span>
                }
                icon="AiOutlineDelete"
                // TODO - AV - This has to read from record.
                formData={record}
                map={{
                  id: record.id,
                }}
                resource="webhook"
                iconButton
                action="delete"
                afterCall={this.webhookList.bind()}
                controller={WebhookController}
                cancelText={getCurrentlocaleText(
                  "managewebhook.delete.cancel.text",
                )}
                okText={getCurrentlocaleText("managewebhook.delete.ok.text")}
              >
                <Icons type="ai" name="AiOutlineDelete" style={{ margin: 0 }} />{" "}
                {getCurrentlocaleText("delete.confirmation.ok.text")}
              </ActionBtn>
            </span>
          );
          const menu = (
            <Menu>
              {
                <Menu.Item key="1" style={this.color}>
                  {edit}
                </Menu.Item>
              }
              {
                <Menu.Item key="3" style={this.color}>
                  {deletewebhook}
                </Menu.Item>
              }
            </Menu>
          );
          return (
            <div
              onClick={e => {
                e.stopPropagation();
              }}
            >
              {/* actions */}
              <DropdownActionBtn menu={menu} />
            </div>
          );
        },
      },
    ];

    // Remove (user & organization) columns if org filter is not selected
    if (!this.orgId) {
      this.columns.splice(4, 2);
    }

    let container = (
      <div className="webhook-list-container">
        <div className="webhook-list-container-div">
          <span className="headertext">
            {getCurrentlocaleText("manage_webhooks.text")}
          </span>
        </div>
        <div className="list-container">
          <div className="list-container-child">
            <div className="action-btn">
              <Link to="/managewebhooks/add">
                <Button
                  type="primary"
                  title={getCurrentlocaleText("webhook.addchannel.text")}
                >
                  <Icons type="ai" name="AiOutlinePlus" />{" "}
                  {getCurrentlocaleText("webhook.addchannel.text")}
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div>
          {channelList.length > 0 ? (
            <TableLayoutV1
              columns={this.columns}
              dataSource={channelList}
              pagination={{
                pageSize: this.webhookModel && this.webhookModel.pageable.size,
                total:
                  this.webhookModel && this.webhookModel.pageable.total_count,
                current: this.state.currentPage,
              }}
              className="alertList"
              expandedRowKeys={this.state.expandedRowKeys}
              onExpand={this.onExpand}
              hideRowSelection={true}
              getModalContent={record => {
                return this.getModalContentJSX(record);
              }}
              onChange={this.onTableChange}
              handleModalOk={e => this.handleModalOk(e)}
              modalTitle={this.state.modalTitle}
              modalContent={this.state.modalContent}
              isTableModalToggle={isTableModalToggle}
              isModalLoading={false}
              disableFooter={true}
            />
          ) : (
            <div>
              {!this.webhookModel.loading && (
                <div>{getCurrentlocaleText("webhook.nocontent.text")}</div>
              )}
            </div>
          )}
        </div>
      </div>
    );

    return (
      <LoadingComponent loading={this.webhookModel.loading}>
        {container}
      </LoadingComponent>
    );
  }
}

export default WebhookList;
