import React, { Component } from "react";
import Iframe from "react-iframe";
import { Layout } from "antd";
import { observer, inject } from "mobx-react";
import LoadingComponent from "components/UI-Components/LoadingComponent";

@inject("UiStore")
@observer
class KnowledgeBase extends Component {
  constructor(props) {
    super(props);
    this.ENV_CONFIG = ENV_CONFIG;
    this.state = {
      loading: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({ loading: true });
    let kbDocVersion =
      this.ENV_CONFIG &&
      this.ENV_CONFIG.KB_DOCUMENT_VERSION &&
      this.ENV_CONFIG.KB_DOCUMENT_VERSION.trim().length > 0
        ? this.ENV_CONFIG.KB_DOCUMENT_VERSION
        : "latest";
    this.props.UiStore.refreshUserGuide(kbDocVersion);
  }

  handleOnLoad = () => {
    this.setState({ loading: false });
    setTimeout(() => {
      try {
        // Send origin to the iframe
        let frames = window.frames;
        if (frames) {
          let frame = frames["OnlineHelpDoc"];
          if (frame) {
            try {
              frame.postMessage(
                {
                  apidocHostUrl: window.location.origin,
                  sender: "view",
                },
                "*",
              );
            } catch (err) {
              console.error(err);
            }
          }
        }
      } catch (err) {
        console.error(err);
      }
    }, 500);
  };

  render() {
    let userGuide = (
      <Layout type="flex">
        <Iframe
          name="OnlineHelpDoc"
          url={this.props.UiStore.userGuideUrl}
          position="relative"
          display="initial"
          className="online-help-doc"
          onLoad={this.handleOnLoad.bind(this)}
        />
      </Layout>
    );
    return (
      <LoadingComponent loading={this.state.loading}>
        {userGuide}
      </LoadingComponent>
    );
  }
}

export default KnowledgeBase;
