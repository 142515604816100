import React, { Component } from "react";
import ActionBtn from "Core/API/ActionBtn";
import UsersForm from "components/Forms/UsersForm";
import { observer, inject } from "mobx-react";
import { Row, Col } from "antd";
import UserController from "controller/UserController";
import Icons from "./Icons";

@inject("ModelStore", "UiStore", "AuthStore")
@observer
class EditUser extends Component {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    this.initialVal = this.props.initialVal;
  }

  logout = resp => {
    this.props.onAfterClick("users");
    if (this.props.isMe) {
      if (resp && resp.username && this.initialVal) {
        if (resp.username !== this.initialVal.username) {
          setTimeout(() => {
            this.props.ModelStore.Logout();
            this.props.UiStore.SetLoginStatus(false);
            this.props.AuthStore.resetStore();
          }, 1000);
        }
      }
    }
  };

  render() {
    return (
      <div>
        <Row gutter={24}>
          <Col span={8}>
            <ActionBtn
              title={
                <span>
                  <Icons type="ai" name="AiOutlineUser" /> Edit User
                </span>
              }
              orgId={this.props.orgId}
              width={600}
              resource="users"
              icon="AiOutlineEdit"
              iconButton
              initialVal={this.props.initialVal}
              map={{ id: this.props.userId }}
              afterCall={this.logout}
              action="edit"
              changeRoute={this.props.changeRoute}
              actionForm={UsersForm}
              disabled={this.props.disabled}
              disabledPassword={true}
              disabledRole={this.props.isMe}
              disabledEmail={true}
              isMe={this.props.isMe}
              okText={
                <span>
                  <Icons type="ai" name="AiOutlineEdit" /> Update{" "}
                </span>
              }
              controller={UserController}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
export default EditUser;
