import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import { Select, Radio, Tag, TreeSelect } from "antd";
import Input from "components/UI-Components/InputBox";
import {
  getCurrentlocaleText,
  copyObject,
  isCidr,
  isCidrRange,
} from "Core/Utils";
import { observer, inject } from "mobx-react";
import { OrgController, NetworkController } from "controller/Controllers";
import InodeController from "controller/InodeController";
import ServiceTemplatesUtils from "components/serviceTemplates/Utils/ServiceTemplatesUtils";
/*------------------N/W CIDR ----------------------*/
import * as ip from "ip";
import { computed } from "mobx";
import Twig from "twig";
import Icons from "../../../../UI-Components/Icons";
import MultipleIpAddressInputList from "../../../../UI-Components/MultipleIpAddressInputList";

/*Define form elements*/
const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const Option = Select.Option;
const LABEL_MAP = { candidate: "_iotium.cluster.candidate" };
const { SHOW_PARENT } = TreeSelect;

@inject(
  "UiStore",
  "ServiceViewModel",
  "NetworkViewModel",
  "InodeViewModel",
  "ClusterViewModel",
)
@observer
export default class NodeNetworkList extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = this.props.InodeViewModel;
    this.clusterModel = this.props.ClusterViewModel;
    this.networkViewModel = this.props.NetworkViewModel;
    this.serviceViewModel = this.props.ServiceViewModel;
    this.state = {
      listData: [],
      availablenodesList: [],
      availablenetworkList: [],
      isIpRequired: false,
      isNetworkMissing: false,
      showIpOnLoad: true,
      showNodeSelector: false,
      node_selector_value: [],
      selectedLabels: [],
      selectors: [],
      invalidSelector: false,
      nodeselector_validate_state: "",
      validateMessage: "",
      NodeSelectorLabel_options: [],
      invalidLabels: [],
      onlySingleton: false,
      selected_networks: [],
      selected_network_ids: [],
      networkListObject: [],
      selected_networks_payload: [],
    };
    this.allnodesList = [];
    this.networkList = {};
    this.ipValue = "";
    this.networkMode = "";
    this.kindHelp = "";
    this.networkCidr = null;
  }

  listData = data => {
    return ServiceTemplatesUtils.listDataForSelect(data);
  };

  onChangeValue = e => {
    this.props.UiStore.templatingService.node[this.props.id].kind =
      e.target.value;
    this.props.updateCurrentValueOnChange(e.target.value, "kind");
    if (e.target.value === "REPLICA") {
      this.setState({ showNodeSelector: true });
    } else {
      this.setState({ showNodeSelector: false });
    }
  };

  componentDidMount() {
    if (this.props.urlParams.params.cluster_id) {
      // update cluster nodes labes
      let NodeSelectorLabel_options = [
        ...new Set(this.state.NodeSelectorLabel_options),
      ];
      NodeSelectorLabel_options.push({
        value: "_iotium.cluster.candidate:true",
        label: "Candidate",
      });
      NodeSelectorLabel_options.push({
        value: "_iotium.cluster.candidate:false",
        label: "Non-Candidate",
      });
      if (
        this.props &&
        this.props.ClusterViewModel &&
        this.props.ClusterViewModel.cluster &&
        this.props.ClusterViewModel.cluster.id &&
        this.props.ClusterViewModel.cluster.name
      ) {
        NodeSelectorLabel_options.push({
          value: "_iotium.cluster.id:" + this.props.ClusterViewModel.cluster.id,
          label: this.props.ClusterViewModel.cluster.name,
        });
      }
      this.setState(
        { NodeSelectorLabel_options: NodeSelectorLabel_options },
        this.props.urlParams &&
          this.props.urlParams.params &&
          this.props.urlParams.params.cluster_id &&
          this.getClusteriNodesLabels(
            this.props.urlParams.params.cluster_id,
            NodeSelectorLabel_options,
          ),
      );
    }
    /* initializing Help text */
    (this.nodeHelpText = null),
      (this.networkHelpText = null),
      (this.ipaddressHelpText = null);
    let allkeys;
    this.kindHelp =
      '<div> <h3 style="font-weight:bold;"> ' +
      getCurrentlocaleText("services.kind.label") +
      " </h3> " +
      getCurrentlocaleText("services.kind.help_text.message") +
      ' <h3 style="font-weight:bold;"> ' +
      getCurrentlocaleText("services.kind.replica.label") +
      " </h3> " +
      getCurrentlocaleText("services.kind.replica.help_text.message") +
      '<h3 style="font-weight:bold;"> ' +
      getCurrentlocaleText("services.kind.daemon.label") +
      " </h3>" +
      getCurrentlocaleText("services.kind.daemon.help_text.message") +
      '<h3 style="font-weight:bold;"> ' +
      getCurrentlocaleText("services.kind.singleton.label") +
      " </h3>" +
      getCurrentlocaleText("services.kind.singleton.help_text.message") +
      "</div>";
    if (this.props.properties.help) {
      if (Object.keys(this.props.properties.help).length >= 0) {
        allkeys = Object.keys(this.props.properties.help);
        allkeys.forEach(val => {
          if (val.indexOf("node") >= 0) {
            this.nodeHelpText = this.props.properties.help[val];
          }
          if (val.indexOf("network") >= 0) {
            this.networkHelpText = this.props.properties.help[val];
          }
          if (val.indexOf("ipaddress") >= 0) {
            this.ipaddressHelpText = this.props.properties.help[val];
          }
          if (val.indexOf("kind") >= 0) {
            this.kindHelp = this.props.properties.help[val];
          }
        });
      }
    }
    if (this.props.properties.allowipaddress) {
      this.props.UiStore.templatingService.allowIPAddress = this.props.properties.allowipaddress;
    }
    if (!this.props.UiStore.templatingService.node[this.props.id]) {
      /*initialize model if undefined*/
      this.props.UiStore.templatingService.node[this.props.id] = {
        node_id:
          this.props.properties.defaultValue &&
          this.props.properties.defaultValue.node_id
            ? this.props.properties.defaultValue.node_id
            : null,
        cluster_id:
          this.props.properties.defaultValue &&
          this.props.properties.defaultValue.cluster_id
            ? this.props.properties.defaultValue.cluster_id
            : null,
        kind:
          this.props.properties.defaultValue &&
          this.props.properties.defaultValue.kind
            ? this.props.properties.defaultValue.kind
            : "SINGLETON",
        node_selector:
          this.props.properties.defaultValue &&
          this.props.properties.defaultValue.node_selector
            ? this.props.properties.defaultValue.node_selector
            : null,
        network: {
          network_id:
            this.props.properties.defaultValue &&
            this.props.properties.defaultValue.network_id
              ? this.props.properties.defaultValue.network_id
              : null,
          ip_address:
            this.props.properties.defaultValue &&
            this.props.properties.defaultValue.ip_address
              ? this.props.properties.defaultValue.ip_address
              : null,
        },
      };
      this.props.updateCurrentValueOnChange(
        this.props.UiStore.templatingService.node[this.props.id].node_id,
        "node_id",
      );
      if (
        this.serviceViewModel.clusterMode ||
        (this.props.urlParams &&
          this.props.urlParams.params &&
          this.props.urlParams.params.cluster_id)
      ) {
        this.props.updateCurrentValueOnChange(
          this.props.UiStore.templatingService.node[this.props.id].cluster_id,
          "cluster_id",
        );
        this.props.updateCurrentValueOnChange(
          this.props.UiStore.templatingService.node[this.props.id].kind,
          "kind",
        );
        this.props.updateCurrentValueOnChange(
          this.props.UiStore.templatingService.node[this.props.id]
            .node_selector,
          "node_selector",
        );
      }
      this.props.updateCurrentValueOnChange(
        this.props.UiStore.templatingService.node[this.props.id].network
          .network_id,
        "network_id",
      );
      if (this.props.UiStore.templatingService.node[this.props.id].networks) {
        this.props.updateCurrentValueOnChange(
          this.props.UiStore.templatingService.node[this.props.id].networks
            .network_id,
          "networks",
        );
      } else {
        this.props.updateCurrentValueOnChange([], "networks");
      }

      this.props.updateCurrentValueOnChange(
        this.props.UiStore.templatingService.node[this.props.id].network
          .ip_address,
        "ip",
      );
      if (
        this.props.UiStore.templatingService.node[this.props.id].kind ===
        "REPLICA"
      ) {
        this.setState({ showNodeSelector: true });
      }
      let nodeSelectors = this.getNodeSelectorForView(
        this.props.UiStore.templatingService.node[this.props.id].node_selector,
      );
      let NodeSelectorLabel_options = [
        ...new Set(this.state.NodeSelectorLabel_options),
      ];
      NodeSelectorLabel_options.push({
        value: "_iotium.cluster.candidate:true",
        label: "Candidate",
      });
      NodeSelectorLabel_options.push({
        value: "_iotium.cluster.candidate:false",
        label: "Non-Candidate",
      });
      if (
        this.props &&
        this.props.ClusterViewModel &&
        this.props.ClusterViewModel.cluster &&
        this.props.ClusterViewModel.cluster.id &&
        this.props.ClusterViewModel.cluster.name
      ) {
        NodeSelectorLabel_options.push({
          value: "_iotium.cluster.id:" + this.props.ClusterViewModel.cluster.id,
          label: this.props.ClusterViewModel.cluster.name,
        });
      }
      this.setState({
        node_selector_value: nodeSelectors || [],
      });
    }
    if (
      Object.keys(this.props.UiStore.templatingService.nodeDetails).length > 0
    ) {
      /* page load from inode details page. use node_id from 'templatingService' model*/
      this.setState({
        availablenodesList: this.props.UiStore.templatingService.nodeDetails
          .nodeList,
        availablenetworkList: this.props.UiStore.templatingService.nodeDetails
          .networkList,
      });
      this.props.UiStore.templatingService.node[
        this.props.id
      ].node_id = this.props.UiStore.templatingService.nodeDetails.nodeList[0].id;
      // if default ip given
      if (
        this.props.properties.defaultValue &&
        this.props.properties.defaultValue.ip
      ) {
        this.props.UiStore.templatingService.node[
          this.props.id
        ].network.ip_address =
          this.props.properties.defaultValue &&
          this.props.properties.defaultValue.ip &&
          this.props.properties.defaultValue.ip
            ? this.props.properties.defaultValue.ip
            : undefined;
      }
      if (
        this.serviceViewModel.clusterMode ||
        (this.props.urlParams &&
          this.props.urlParams.params &&
          this.props.urlParams.params.cluster_id)
      ) {
        // adding cluster id
        this.props.UiStore.templatingService.node[
          this.props.id
        ].cluster_id = this.props.UiStore.templatingService.nodeDetails.nodeList[0].id;
        let clusters = this.clusterModel.clusters;
        this.props.UiStore.templatingService.node[this.props.id].kind = this
          .props.UiStore.templatingService.node[this.props.id].kind
          ? this.props.UiStore.templatingService.node[this.props.id].kind
          : "SINGLETON";
        if (this.props.UiStore.templatingService.nodeDetails.nodeList[0].id) {
          let x = clusters.find(
            element =>
              element.id ===
              this.props.UiStore.templatingService.nodeDetails.nodeList[0].id,
          );
          if (
            x &&
            this.props.UiStore.templatingService.computedSchema.version &&
            this.props.UiStore.templatingService.computedSchema.version ===
              "2.0"
          )
            this.props.UiStore.templatingService.serviceCurrentCluster = x;
          this.props.updateCurrentValueOnChange(x, "node");
        }

        this.loadedfromInodesPage = true;
      } else {
        if (this.props.UiStore.templatingService.nodeDetails.nodeList[0].id) {
          let nodes = this.inodeModel.inodes;
          let x = nodes.find(
            element =>
              element.id ===
              this.props.UiStore.templatingService.nodeDetails.nodeList[0].id,
          );
          if (
            x &&
            this.props.UiStore.templatingService.computedSchema.version &&
            this.props.UiStore.templatingService.computedSchema.version ===
              "2.0"
          )
            this.props.UiStore.templatingService.serviceCurrentNode = x;
          this.props.updateCurrentValueOnChange(x, "node");
        }

        this.loadedfromInodesPage = true;
      }
    } else {
      /* control not from inode details page. use api to fetch data */
      let org_id = [];

      OrgController.getOrgsForDropdown().then(orgs => {
        orgs.map(val => {
          org_id.push(val.id);
        });

        ServiceTemplatesUtils.getNodesFromOrgsList(org_id).then(nodes => {
          this.allnodesList = nodes.concat(this.allnodesList);
          let list = [],
            selectednetwork = [];
          if (nodes && nodes.length >= 1) {
            nodes.map((val, index) => {
              this.createNodeList(val).then(
                list => {
                  /* initialize the node component with default val if it has a valid default node_id*/
                  if (
                    this.props.properties.defaultValue &&
                    Object.keys(this.props.properties.defaultValue).length > 0
                  ) {
                    /* default value is present. */
                    if (this.props.properties.defaultValue.node_id) {
                      /*node_id is valid. assign as default*/
                      list.map(val => {
                        if (
                          val.id === this.props.properties.defaultValue.node_id
                        ) {
                          this.props.UiStore.templatingService.node[
                            this.props.id
                          ].node_id = this.props.properties.defaultValue.node_id;
                        }
                        return null;
                      });

                      this.allnodesList.map((node, index) => {
                        /*node_id is valid. show network for selected node*/
                        if (
                          node.id == this.props.properties.defaultValue.node_id
                        ) {
                          /* given node_id has networks */
                          list.map(nodes => {
                            if (
                              nodes.id ===
                              this.props.properties.defaultValue.node_id
                            ) {
                              selectednetwork = selectednetwork.concat(
                                this.networkList[nodes.id],
                              );
                            }
                          });
                        }
                        return null;
                      });
                      this.setState({ availablenetworkList: selectednetwork });

                      if (this.props.properties.defaultValue.network_id) {
                        /* network is present */
                        selectednetwork.map(network => {
                          if (
                            network.id ===
                            this.props.properties.defaultValue.network_id
                          ) {
                            /*given network_id is valid*/

                            this.props.UiStore.templatingService.node[
                              this.props.id
                            ].network.network_id = this.props.properties.defaultValue.network_id;
                          }
                          return null;
                        });
                      }
                    }
                    if (this.props.properties.defaultValue.ip) {
                      this.props.UiStore.templatingService.node[
                        this.props.id
                      ].network.ip_address = this.props.properties.defaultValue.ip;
                    }
                  }

                  /* if values are received from api resp. "edit" mode */
                  if (
                    this.props.UiStore.templatingService.node[this.props.id]
                      .node_id
                  ) {
                    /*initialize values from api resp as node_id is present*/
                    let networks = [];
                    list.map((node, index) => {
                      /*show network for selected node*/
                      if (
                        node.id ==
                        this.props.UiStore.templatingService.node[this.props.id]
                          .node_id
                      ) {
                        networks = networks.concat(this.networkList[node.id]); //networks.concat(node.networks);
                      }
                      return null;
                    });
                    networks.length > 0 &&
                      this.setState({ availablenetworkList: networks });
                  }
                },
                error => {
                  console.log(error);
                },
              );
              return null;
            });
          }
        });
      });
    }
    let addressing_type;
    if (this.props.urlParams.params.spec_id) {
      let service_networks = this.props.UiStore.templatingService.node[
        this.props.id
      ].networks
        ? this.props.UiStore.templatingService.node[this.props.id].networks
        : [];
      service_networks = service_networks.sort((a, b) =>
        a.network_id > b.network_id ? 1 : -1,
      );
      let selected_network_ids = service_networks.map(nw => nw.network_id);
      let available_networks =
        this.props.UiStore.templatingService.nodeDetails.networkList || [];
      let selected_networks = available_networks.filter(network =>
        selected_network_ids.includes(network.id),
      );
      selected_networks = selected_networks.sort((a, b) =>
        a.network_id > b.network_id ? 1 : -1,
      );
      if (service_networks && service_networks.length > 0) {
        service_networks.forEach((net, index) => {
          let current_network = available_networks.filter(
            nw => nw.id === net.network_id,
          );
          if (current_network && current_network.length > 0) {
            if (
              current_network[0].config &&
              current_network[0].config.network &&
              current_network[0].config.network.service_addressing.toLowerCase() ===
                "auto"
            ) {
              service_networks[index].ip_address = "";
            }
          }
        });
        this.setState({
          showIpOnLoad: true,
          selected_networks: selected_networks,
          selected_networks_payload: service_networks,
          selected_network_ids: selected_network_ids,
        });
      }
    }

    if (this.props.editmode) {
      let service_networks = this.props.UiStore.templatingService.node[
        this.props.id
      ].networks
        ? this.props.UiStore.templatingService.node[this.props.id].networks
        : [];
      service_networks = service_networks.sort((a, b) =>
        a.network_id > b.network_id ? 1 : -1,
      );

      let selected_network_ids = service_networks.map(nw => nw.network_id);
      let available_networks =
        this.props.UiStore.templatingService.nodeDetails.networkList || [];
      let selected_networks = available_networks.filter(network =>
        selected_network_ids.includes(network.id),
      );
      selected_networks = selected_networks.sort((a, b) =>
        a.network_id > b.network_id ? 1 : -1,
      );
      if (service_networks && service_networks.length > 0) {
        service_networks.forEach((net, index) => {
          let current_network = available_networks.filter(
            nw => nw.id === net.network_id,
          );
          if (current_network && current_network.length > 0) {
            if (
              current_network[0].config &&
              current_network[0].config.network &&
              current_network[0].config.network.service_addressing.toLowerCase() ===
                "auto"
            ) {
              service_networks[index].ip_address = "";
            }
          }
        });
        this.setState({
          showIpOnLoad: true,
          selected_networks: selected_networks,
          selected_networks_payload: service_networks,
          selected_network_ids: selected_network_ids,
        });
      }

      this.props.updateCurrentValueOnChange(
        this.props.UiStore.templatingService.node[this.props.id].node_id,
        "node_id",
      );
      if (
        this.serviceViewModel.clusterMode ||
        (this.props.urlParams &&
          this.props.urlParams.params &&
          this.props.urlParams.params.cluster_id)
      ) {
        this.props.updateCurrentValueOnChange(
          this.props.UiStore.templatingService.node[this.props.id].cluster_id,
          "cluster_id",
        );
        this.props.updateCurrentValueOnChange(
          this.props.UiStore.templatingService.node[this.props.id].kind,
          "kind",
        );
      }
      this.props.updateCurrentValueOnChange(
        this.props.UiStore.templatingService.node[this.props.id].network
          .network_id,
        "network_id",
      );
      this.props.updateCurrentValueOnChange(service_networks, "networks");
      this.props.updateCurrentValueOnChange(
        this.props.UiStore.templatingService.node[this.props.id].network
          .ip_address,
        "ip",
      );
      if (
        this.props.UiStore.templatingService.node[this.props.id].kind ===
        "REPLICA"
      ) {
        this.setState({ showNodeSelector: true });
      }
      let nodeSelectors = this.getNodeSelectorForView(
        this.props.UiStore.templatingService.node[this.props.id].node_selector,
      );
      let NodeSelectorLabel_options = [
        ...new Set(this.state.NodeSelectorLabel_options),
      ];
      NodeSelectorLabel_options.push({
        value: "_iotium.cluster.candidate:true",
        label: "Candidate",
      });
      NodeSelectorLabel_options.push({
        value: "_iotium.cluster.candidate:false",
        label: "Non-Candidate",
      });
      if (
        this.props &&
        this.props.ClusterViewModel &&
        this.props.ClusterViewModel.cluster &&
        this.props.ClusterViewModel.cluster.id &&
        this.props.ClusterViewModel.cluster.name
      ) {
        NodeSelectorLabel_options.push({
          value: "_iotium.cluster.id:" + this.props.ClusterViewModel.cluster.id,
          label: this.props.ClusterViewModel.cluster.name,
        });
      }
      if (nodeSelectors.length > 0) {
        nodeSelectors.forEach(selectors => {
          let existing_selector = NodeSelectorLabel_options;
          let filtered_index = existing_selector.filter(options =>
            options.value === selectors ? true : false,
          );
          if (filtered_index.length <= 0) {
            NodeSelectorLabel_options.push({
              value: selectors,
              label: selectors,
            });
          }
        });
        this.setState(
          {
            NodeSelectorLabel_options: NodeSelectorLabel_options,
          },
          this.props.urlParams &&
            this.props.urlParams.params &&
            this.props.urlParams.params.cluster_id &&
            this.getClusteriNodesLabels(
              this.props.urlParams.params.cluster_id,
              NodeSelectorLabel_options,
            ),
        );
      }
      this.setState({
        node_selector_value: nodeSelectors || [],
      });

      this.updateExtraElementStyle();
    }
    // when network addressing is static and service addressing is dynamic
    if (
      this.networkMode &&
      this.networkMode.toLowerCase() === "static" &&
      addressing_type.toLowerCase() != "manual"
    ) {
      this.setState({ isIpRequired: true });
    }
    // when network addressing is static and service addressing is static
    if (
      this.networkMode &&
      this.networkMode.toLowerCase() === "static" &&
      addressing_type.toLowerCase() === "manual"
    ) {
      this.setState({ isIpRequired: true });
    }
    // when network addressing is dynamic and service addressing is also dynamic
    if (
      this.networkMode &&
      this.networkMode.toLowerCase() === "dynamic" &&
      this.props.properties.allowipaddress
    ) {
      this.setState({ isIpRequired: true });
    }
  }

  updateExtraElementStyle = () => {
    setTimeout(() => {
      let extraElement =
        document.getElementsByClassName(
          "ant-select-selection-overflow-item-rest",
        ) &&
        document.getElementsByClassName(
          "ant-select-selection-overflow-item-rest",
        ).length > 0
          ? document.getElementsByClassName(
              "ant-select-selection-overflow-item-rest",
            )[0]
          : null;
      if (extraElement) {
        document.getElementsByClassName(
          "ant-select-selection-overflow-item-rest",
        )[0].firstChild.style = "background:none;border:none;";
      }
    }, 100);
  };

  getClusteriNodesLabels = (cluster_id, NodeSelectorLabel_optionsValues) => {
    let intialParams = {};
    intialParams.cluster_id = cluster_id;
    intialParams.size = 1;
    intialParams.page = 0;
    InodeController.getInodes(intialParams, false, true, false).then(result => {
      let allParams = [];
      if (result && result.total_count > 0) {
        for (let page = 0; page < Math.ceil(result.total_count / 100); page++) {
          allParams.push({ cluster_id: cluster_id, size: 100 });
        }
        if (allParams.length > 0) {
          let nodeSelector_Label_options = NodeSelectorLabel_optionsValues;
          InodeController.getAllInodesForDropdown(allParams).then(response => {
            let inode_labels = [];
            if (response && response.length > 0) {
              response.forEach(data => {
                if (data.metadata && data.metadata.labels) {
                  let lables = data.metadata.labels;
                  for (const [key, value] of Object.entries(lables)) {
                    if (!key.startsWith("_iotium")) {
                      let keyValue = key + ":" + value;
                      let nodeSelectorIndex = nodeSelector_Label_options.findIndex(
                        option => option.value === keyValue,
                      );
                      let iNodeLabelsIndex = inode_labels.findIndex(
                        label => label.value === keyValue,
                      );
                      if (nodeSelectorIndex === -1 && iNodeLabelsIndex === -1) {
                        inode_labels.push({
                          value: keyValue,
                          label: keyValue,
                        });
                      }
                    }
                  }
                }
              });
              nodeSelector_Label_options = nodeSelector_Label_options.concat(
                inode_labels,
              );
              this.setState({
                NodeSelectorLabel_options: nodeSelector_Label_options,
              });
            }
          });
        }
      }
    });
  };

  @computed
  get componentVisibility() {
    let visibility = null;
    if (
      this.props.UiStore.computedOutputObject &&
      this.props.UiStore.computedOutputObject.steps
    ) {
      if (this.props.properties.visibility) {
        var template = Twig.twig({
          data: this.props.properties.visibility,
        });
        let computedOutputObject = copyObject(
          this.props.UiStore.computedOutputObject,
        );
        visibility = template.render(computedOutputObject);
        try {
          visibility = JSON.parse(visibility);
        } catch {}
      }
    }
    return visibility ? true : false;
  }

  createNodeList = node => {
    /* LAT-4900 */
    let params = {
      node_id: node.id,
      page: 0,
      size: 100,
    };

    if (!this.networkList[node.id] || !this.networkList[node.id].id) {
      /*  initialize if not present */
      this.networkList[node.id] = [];
    }

    return new Promise((resolve, reject) => {
      NetworkController.getNetworks(params, true, true).then(
        resp => {
          let nodelist = this.state.availablenodesList; //[];
          if (resp && resp.length > 0) {
            resp.forEach(network => {
              if (!network.is_wan_network || network.is_wan_network === false) {
                /* networks available in node */
                this.networkList[node.id].push({
                  id: network.id,
                  name: `${network.name} (Network CIDR : ${network.config.network.cidr})`,
                  config: network.config,
                  type: network.type,
                  status: network.status,
                  display_name: `${network.name}`,
                });
                /*  network available in node. so list nodes */
                nodelist.push({
                  name: `${node.name} (Organization : ${node.organization.name})`,
                  id: node.id,
                });
              }
            });
          }
          this.setState({
            availablenodesList: nodelist,
            networkListObject: resp,
          });
          resolve(nodelist);
        },
        error => {
          reject(error);
        },
      );
    });
  };

  selectedInodeValue = value => {
    /* reset mandatory ip address */
    this.props.UiStore.templatingService.node[this.props.id].node_id = value;
    this.props.updateCurrentValueOnChange(value, "node_id");
    this.props.UiStore.templatingService.node[this.props.id].network = {
      ...this.props.UiStore.templatingService.node[this.props.id].network,
      network_id: null,
    };
    this.setState({ availablenetworkList: [] });
    let selectednetwork = [];

    this.allnodesList.map((node, index) => {
      if (node.id == value) {
        if (this.networkList[node.id] && this.networkList[node.id].length > 0) {
          selectednetwork = selectednetwork.concat(this.networkList[node.id]);
        }
      }
    });
    let nodes = this.inodeModel.inodes;
    let x = nodes.find(element => element.id === value);
    if (
      x &&
      this.props.UiStore.templatingService.computedSchema.version &&
      this.props.UiStore.templatingService.computedSchema.version === "2.0"
    ) {
      this.props.updateCurrentValueOnChange(x, "node");
      this.props.UiStore.templatingService.serviceCurrentNode = x;
    }
    if (x && x.cluster && x.cluster.id) {
      this.props.updateCurrentValueOnChange(x.cluster.id, "cluster_id");
      this.props.UiStore.templatingService.node[this.props.id].cluster_id =
        x.cluster.id;
    }

    /*todo - have an alternative implementation*/
    setTimeout(() => {
      /*required to reset DOM*/
      this.setState({ availablenetworkList: selectednetwork });
    }, 50);
  };

  /**-----------------------------------------------------------------------------**/
  checkForNodeSelector = (rule, value, callback) => {
    let invalidLabels = [];
    if (value && value.length > 0) {
      value.forEach(val => {
        if (!this.checkForValidLabel(val) && !val.includes("_iotium."))
          invalidLabels.push(val);
      });
      this.setState({ invalidLabels: invalidLabels });
      if (invalidLabels.length > 0) {
        callback(getCurrentlocaleText("general.label.input.error1.text"));
        this.setState({
          nodeselector_validate_state: "error",
          validateMessage: getCurrentlocaleText(
            "general.label.input.error1.text",
          ),
        });
      } else {
        callback();
        this.setState({ nodeselector_validate_state: "", validateMessage: "" });
      }
    } else {
      callback();
      this.setState({ nodeselector_validate_state: "", validateMessage: "" });
    }
  };

  selectednetwork = value => {
    /*  LAT-5252  */
    let x = this.state.availablenetworkList.filter(val => {
      return val.id === value;
    });
    this.networkMode = x[0].type;
    let service_addressing =
      x[0].config.network && x[0].config.network.service_addressing
        ? x[0].config.network.service_addressing
        : "AUTO";

    this.networkCidr =
      x[0] && x[0].config && x[0].config.network && x[0].config.network.cidr;
    // when network addressing is static and service addressing is also static
    if (service_addressing.toLowerCase() === "manual") {
      this.ipValue = "";
      this.setState({ isIpRequired: true });
    } else {
      this.ipValue = getCurrentlocaleText("dynamic");
    }
    // when network addressing is static and service addressing is also static
    if (
      this.networkMode.toLowerCase() === "static" &&
      service_addressing.toLowerCase() != "manual"
    ) {
      this.setState({ isIpRequired: false });
    }
    // when network addressing is static and service addressing is also static
    if (
      this.networkMode.toLowerCase() === "static" &&
      service_addressing.toLowerCase() === "manual"
    ) {
      this.setState({ isIpRequired: true });
    }
    // when network addressing is dynamic and service addressing is also static
    if (
      this.networkMode.toLowerCase() === "dynamic" &&
      this.props.properties.allowipaddress
    ) {
      this.setState({ isIpRequired: true });
    }

    this.props.updateCurrentValueOnChange(value, "network_id");
    //TS: this will removed when node select is allowed
    this.props.updateCurrentValueOnChange(
      this.props.UiStore.templatingService.node[this.props.id].node_id,
      "node_id",
    );
    let nodes = this.inodeModel.inodes;
    let node = nodes.find(
      element =>
        element.id ===
        this.props.UiStore.templatingService.node[this.props.id].node_id,
    );
    if (
      this.props.UiStore.templatingService.computedSchema.version &&
      this.props.UiStore.templatingService.computedSchema.version === "2.0"
    ) {
      this.props.updateCurrentValueOnChange(x, "network");
      this.props.updateCurrentValueOnChange(node, "node");
      this.props.UiStore.templatingService.serviceCurrentNode = node;
    }
    if (node && node.cluster && node.cluster.id) {
      this.props.updateCurrentValueOnChange(node.cluster.id, "cluster_id");
      this.props.UiStore.templatingService.node[this.props.id].cluster_id =
        node.cluster.id;
    } else {
      this.props.updateCurrentValueOnChange(
        this.props.UiStore.templatingService.node[this.props.id].cluster_id,
        "cluster_id",
      );
    }
    if (
      this.props.UiStore.templatingService.node[this.props.id] &&
      this.props.UiStore.templatingService.node[this.props.id].cluster_id &&
      this.networkMode.toLowerCase() === "static"
    ) {
      this.setState({ onlySingleton: true });
    }
    let networks = this.networkViewModel.networks;
    let network = networks.find(element => element.id === value);
    if (
      network &&
      this.props.UiStore.templatingService.computedSchema.version &&
      this.props.UiStore.templatingService.computedSchema.version === "2.0"
    ) {
      this.props.UiStore.templatingService.serviceCurrentNetwork = network;
      this.props.updateCurrentValueOnChange(network, "network");
    }

    this.props.UiStore.templatingService.node[this.props.id].network = {
      ...this.props.UiStore.templatingService.node[this.props.id].network,
      network_id: value,
    };
    this.setState({
      //isNetworkMissing : true,
      showIpOnLoad: true,
    });
  };

  selectednetworks = networks => {
    let selected_networks = [];
    let computed_networks_object = [];
    if (networks.length > 0) {
      networks.forEach(nw => {
        let filtered_nws = this.state.availablenetworkList.filter(val => {
          return val.id === nw;
        });
        selected_networks = selected_networks.concat(filtered_nws);
        let ip_address = "";
        let ipAddressValue = this.state.selected_networks_payload.filter(
          snw => snw.network_id === nw,
        );
        if (ipAddressValue && ipAddressValue.length > 0) {
          ip_address = ipAddressValue[0].ip_address;
        }
        computed_networks_object.push({
          network_id: nw,
          ip_address: ip_address,
        });
      });

      computed_networks_object = computed_networks_object.sort((a, b) =>
        a.network_id > b.network_id ? 1 : -1,
      );
      this.setState({
        selected_networks,
        selected_networks_payload: computed_networks_object,
      });
      // this.props.updateCurrentValueOnChange(value, "network_id");
      //TS: this will removed when node select is allowed
      this.props.updateCurrentValueOnChange(
        this.props.UiStore.templatingService.node[this.props.id].node_id,
        "node_id",
      );

      let nodes = this.inodeModel.inodes;
      let node = nodes.find(
        element =>
          element.id ===
          this.props.UiStore.templatingService.node[this.props.id].node_id,
      );
      if (
        this.props.UiStore.templatingService.computedSchema.version &&
        this.props.UiStore.templatingService.computedSchema.version === "2.0"
      ) {
        this.props.updateCurrentValueOnChange(
          this.state.networkListObject,
          "network",
        );
        this.props.updateCurrentValueOnChange(
          computed_networks_object,
          "networks",
        );
        this.props.updateCurrentValueOnChange(node, "node");
        this.props.UiStore.templatingService.serviceCurrentNode = node;
      }
      if (node && node.cluster && node.cluster.id) {
        this.props.updateCurrentValueOnChange(node.cluster.id, "cluster_id");
        this.props.UiStore.templatingService.node[this.props.id].cluster_id =
          node.cluster.id;
      } else {
        this.props.updateCurrentValueOnChange(
          this.props.UiStore.templatingService.node[this.props.id].cluster_id,
          "cluster_id",
        );
      }
      if (
        this.props.UiStore.templatingService.node[this.props.id] &&
        this.props.UiStore.templatingService.node[this.props.id].cluster_id &&
        this.networkMode.toLowerCase() === "static"
      ) {
        this.setState({ onlySingleton: true });
      }
    } else {
      this.setState({
        selected_networks: [],
        selected_networks_payload: [],
      });
      this.props.updateCurrentValueOnChange([], "networks");
      if (this.props.UiStore.templatingService.node[this.props.id]) {
        this.props.UiStore.templatingService.node[this.props.id].networks = [];
      }
    }
    if (networks.length > 3) {
      this.updateExtraElementStyle();
    }
  };

  updateIp = e => {
    this.props.UiStore.templatingService.node[this.props.id].network = {
      ...this.props.UiStore.templatingService.node[this.props.id].network,
      ip_address: e.target.value,
    };
    this.props.updateCurrentValueOnChange(e.target.value, "ip");
  };

  checkIpV4 = (rule, values, callback, field) => {
    if (typeof values === "string") {
      values = [values];
    }
    if (values && values.length > 0) {
      values.forEach(value => {
        let failMessage = null;
        value = value.toString();
        if (value == "") {
          value = "dynamic";
        }
        if (
          value &&
          value.toLowerCase() !== "dynamic" &&
          ip.isV4Format(value) !== true
        ) {
          failMessage = getCurrentlocaleText(
            "network.form.startip.errormessage1.text",
          );
        }

        if (
          value &&
          value.toLowerCase() !== "dynamic" &&
          ip.isV4Format(value) &&
          this.networkCidr
        ) {
          let cidrValue = this.networkCidr;
          let cidrRangeInfo = null,
            cidrSubnet = isCidr(cidrValue) && ip.cidrSubnet(cidrValue);
          if (cidrSubnet) {
            cidrRangeInfo = `${cidrSubnet.firstAddress} - ${cidrSubnet.lastAddress}`;
          }
          if (!isCidrRange(this.networkCidr, value) && cidrRangeInfo) {
            failMessage =
              getCurrentlocaleText("network.form.startip.errormessage2.text") +
              cidrRangeInfo;
          }
        }
        if (failMessage) {
          callback(failMessage);
        }
        callback();
      });
    }
  };

  /**
   * From API to UI
   */
  getNodeSelectorForView = selectorObj => {
    let node_selectors = [];
    if (selectorObj && Object.keys(selectorObj).length > 0) {
      let selectors = copyObject(selectorObj);
      for (const [key, value] of Object.entries(selectors)) {
        node_selectors.push(`${key}:${value}`);
      }
    }
    return node_selectors;
  };

  /**
   * Check for First Character
   */
  checkForAphanumericValue = value => {
    var regex = /^[a-z0-9]+$/i;
    if (!regex.test(value)) {
      return false;
    } else {
      return true;
    }
  };
  checForAllowedValue = value => {
    var regex = /^[-A-Za-z0-9_.]+$/i;
    if (!regex.test(value)) {
      return false;
    } else {
      return true;
    }
  };

  /**
   * Check for nodeselector Label
   */
  checkForValidLabel = value => {
    if (value && value.length > 0) {
      let data = value ? value.split(":") : [];

      if (data.length <= 1) {
        return false;
      }
      if (data.length > 2) {
        return false;
      }
      if (data.length === 2) {
        if (data[0].length > 63 || data[1].length > 63) return false;
      }
      if (
        !this.checkForAphanumericValue(
          data[0] && String(data[0].trim()).substring(0, 1),
        ) ||
        (!this.checkForAphanumericValue(
          data[1] && String(data[1].trim()).substring(0, 1),
        ) &&
          String(data[1].trim()).length >= 1)
      ) {
        return false;
      }
      if (
        !this.checkForAphanumericValue(
          data[0] &&
            String(data[0].trim()).substring(
              data[0].trim().length - 1,
              data[0].trim().length,
            ),
        ) ||
        (!this.checkForAphanumericValue(
          data[1] &&
            String(data[1].trim()).substring(
              data[1].trim().length - 1,
              data[1].trim().length,
            ),
        ) &&
          String(data[1].trim()).length >= 1)
      ) {
        return false;
      }
      if (
        data.length <= 2 &&
        !data[0].trim().includes("_iotium.cluster.id") &&
        data[0].trim().includes("_iotium.")
      ) {
        return false;
      }

      if (
        !this.checForAllowedValue(data[0].trim()) ||
        (!this.checForAllowedValue(data[1].trim()) &&
          String(data[1].trim()).length >= 1)
      ) {
        return false;
      }
      if (
        data.length <= 2 &&
        !data[0].includes("_iotium.cluster.id") &&
        data[0].charAt(0) === "_"
      ) {
        return false;
      } else return true;
    } else {
      false;
    }
  };

  /**
   * Handle On Deselect
   */
  handleOnDeselect = value => {
    this.clearUnselectedLabels(
      value,
      this.state.selectedLabels,
      this.state.selectors,
    );
    let index1 = this.state.NodeSelectorLabel_options.indexOf(value);
    let availableTag = this.state.NodeSelectorLabel_options;

    if (this.state.NodeSelectorLabel_options.indexOf(value) != -1) {
      availableTag.splice(index1);
      // this.setState({ NodeSelectorLabel_options: availableTag });
    }
  };
  /**
   * Check for Unselected Labels
   */
  clearUnselectedLabels = (value, selectedLabels, availabelSelectors) => {
    let key = value.split(":")[0].trim(),
      index = availabelSelectors.indexOf(value);
    availabelSelectors.splice(index, 1);
    selectedLabels.splice(selectedLabels.indexOf(key), 1);
    return {
      selected_values: selectedLabels,
      available_selectors: availabelSelectors,
    };
  };
  handleLabelChange = values => {
    let nodeSelectors = {};
    let availableTag = this.state.NodeSelectorLabel_options;
    let selectedLabels = this.state.selectedLabels;
    values.forEach(value => {
      let data = value.split(":");
      let check_index = availableTag.filter(tag =>
        tag.value === value ? true : false,
      );
      if (check_index.length === 0) {
        if (!this.checkForValidLabel(value)) {
          this.setState({
            invalidSelector: true,
          });
          if (data.length > 1) {
            availableTag.push({
              value: data[0].trim() + ":" + data[1].trim(),
              label: data[0].trim() + ":" + data[1].trim(),
            });
          } else if (data.length === 1) {
            availableTag.push({ value: data[0].trim(), label: data[0].trim() });
          }
        } else {
          if (data.length > 1)
            availableTag.push({
              value: data[0].trim() + ":" + data[1].trim(),
              label: data[0].trim() + ":" + data[1].trim(),
            });
        }
      }
      if (data.length > 1) nodeSelectors[data[0].trim()] = data[1].trim();
      if (selectedLabels.indexOf(data[0]) === -1) selectedLabels.push(data[0]);
    });
    this.setState({
      selectedLabels: selectedLabels,
      selectors: values,
      node_selector_value: values,
    });
    this.props.updateCurrentValueOnChange(nodeSelectors, "node_selector");
    this.props.UiStore.templatingService.node[
      this.props.id
    ].node_selector = nodeSelectors;

    this.props.referenceForm.setFields({
      [`${this.props.id}_node_selector`]: {
        value: selectedLabels,
      },
    });
  };
  checkFordisabledState = key => {
    let selected_values = this.state.node_selector_value || [];
    if (key === "_iotium.cluster.candidate:false") {
      if (selected_values.includes("_iotium.cluster.candidate:true"))
        return true;
      else return false;
    } else if (key === "_iotium.cluster.candidate:true") {
      if (selected_values.includes("_iotium.cluster.candidate:false"))
        return true;
      else return false;
    } else {
      return false;
    }
  };

  fetchTreeData = networkList => {
    let treeOptions = [];
    networkList.forEach((network, index) => {
      let addressing_type =
        network.config &&
        network.config.network &&
        network.config.network.service_addressing
          ? network.config.network.service_addressing.toLowerCase()
          : "auto";
      treeOptions.push({
        title: (
          <div className="nw-sel-container">
            <span>{network.display_name}</span>
            <span className="nw-sel-addressing" style={{ float: "right" }}>
              {addressing_type == "auto" ? "Dynamic" : "Static"}
            </span>
          </div>
        ),
        value: network.id,
        key: network.id,
        children: [],
      });
    });
    return treeOptions;
  };

  removeNetwork = networkId => {
    let { selected_networks, selected_networks_payload } = this.state;
    let filtered_networks = selected_networks.filter(nw => nw.id != networkId);
    let filtered_network_ids = filtered_networks.map(nw => nw.id);
    selected_networks_payload = selected_networks_payload.filter(
      nw => nw.network_id != networkId,
    );
    this.setState({
      selected_networks: filtered_networks,
      selected_network_ids: filtered_network_ids,
      selected_networks_payload: selected_networks_payload,
    });
    this.props.referenceForm.setFields({
      [`${this.props.id}_networkid`]: {
        value: filtered_network_ids,
      },
    });

    if (
      this.props.UiStore.templatingService.computedSchema.version &&
      this.props.UiStore.templatingService.computedSchema.version === "2.0"
    ) {
      this.props.updateCurrentValueOnChange(
        selected_networks_payload,
        "networks",
      );
    }
    if (filtered_network_ids.length === 0) {
      if (this.props.UiStore.templatingService.node[this.props.id]) {
        this.props.UiStore.templatingService.node[this.props.id].networks = [];
      }
    }
  };

  updateResource = (value, networkId, isDefault) => {
    let { selected_networks_payload } = this.state;
    if (selected_networks_payload && selected_networks_payload.length > 0) {
      let index = selected_networks_payload.findIndex(payload => {
        return payload.network_id === networkId;
      });
      if (isDefault) {
        selected_networks_payload[index].is_default = value;
      } else {
        selected_networks_payload[index].ip_address = value;
      }
    }

    if (selected_networks_payload.length > 0) {
      selected_networks_payload.forEach(network => {
        if (network.network_id !== networkId) {
          delete network.is_default;
        }
      });
    }

    selected_networks_payload = selected_networks_payload.sort((a, b) =>
      a.network_id > b.network_id ? 1 : -1,
    );
    this.setState({ selected_networks_payload });
    if (
      this.props.UiStore.templatingService.computedSchema.version &&
      this.props.UiStore.templatingService.computedSchema.version === "2.0"
    ) {
      this.props.updateCurrentValueOnChange(
        selected_networks_payload,
        "networks",
      );
    }
  };

  render() {
    let staticNetwork = false;
    this.state.availablenetworkList &&
      this.state.availablenetworkList.length > 0 &&
      this.state.availablenetworkList.forEach(network => {
        if (network.type === "STATIC") {
          staticNetwork = true;
        }
      });
    let showIp = false;
    let isCluster =
      this.props.urlParams &&
      this.props.urlParams.params &&
      this.props.urlParams.params.cluster_id
        ? true
        : this.serviceViewModel.clusterMode
        ? this.serviceViewModel.clusterMode
        : false;
    if (
      this.networkMode === "DYNAMIC" &&
      this.props.properties.allowipaddress
    ) {
      this.ipValue = "";
      showIp = true;
    }
    const { getFieldDecorator } = this.props.referenceForm; // reference of base form
    let disable = {
      node: false,
      network: false,
      ipaddress: false,
    };
    let required =
      this.props.properties.constraints &&
      this.props.properties.constraints.required
        ? this.props.properties.constraints.required
        : false;

    let validationMessage =
      this.props.properties.constraints &&
      this.props.properties.constraints.validationMessage
        ? this.props.properties.constraints.validationMessage
        : `${this.props.properties.label} is required`;

    let children = [];
    let nodeSelector_Label_options = [
      ...new Set(
        this.state.NodeSelectorLabel_options.sort((a, b) => {
          return a.value.includes("_iot") - b.value.includes("_iot");
        }),
      ),
    ];
    let visibility = this.props.properties.visibility
      ? this.componentVisibility
      : true;
    let isClusterEnabled =
      this.serviceViewModel && this.serviceViewModel.clusterMode
        ? this.serviceViewModel.clusterMode
        : false;

    const treeData =
      (this.state.availablenetworkList.length > 0 &&
        this.fetchTreeData(this.state.availablenetworkList)) ||
      [];

    for (let i = 0; i < nodeSelector_Label_options.length; i++) {
      if (
        nodeSelector_Label_options[i].label &&
        nodeSelector_Label_options[i].value
      ) {
        children.push(
          <Option
            key={i}
            title={nodeSelector_Label_options[i].label}
            value={nodeSelector_Label_options[i].value}
            disabled={this.checkFordisabledState(
              nodeSelector_Label_options[i].value,
            )}
          >
            <Tag
              className={
                this.state.invalidLabels.includes(
                  nodeSelector_Label_options[i].value,
                )
                  ? "pheonix-tag-invalid iot-selector-tag"
                  : nodeSelector_Label_options[i].value.includes("_iot")
                  ? "iot-reserved-tag-label-color iot-selector-tag"
                  : "iot-tag-label-color iot-selector-tag"
              }
            >
              {nodeSelector_Label_options[i].value.includes("_iot") ? (
                <span
                  className={
                    nodeSelector_Label_options[i].value.includes("_iot")
                      ? "isreserved"
                      : ""
                  }
                >
                  <Icons
                    type="ai"
                    name="AiOutlineTag"
                    key={i}
                    style={{ marginRight: 5 }}
                  />{" "}
                  {nodeSelector_Label_options[i].label}
                </span>
              ) : (
                <span className={"iot-primary-color-style"}>
                  <Icons
                    type="ai"
                    name="AiOutlineTag"
                    key={i}
                    className="node-selector-tag-icon"
                    style={{ marginRight: 5 }}
                  />{" "}
                  {nodeSelector_Label_options[i].label}
                </span>
              )}
            </Tag>
          </Option>,
        );
      }
    }
    if (this.props.editmode && this.props.properties.noneditable) {
      /*  disable controls on edit */
      let noneditableKeys = Object.keys(this.props.properties["noneditable"]);
      noneditableKeys.length > 0 &&
        noneditableKeys.forEach(val => {
          disable[val] = this.props.properties["noneditable"][val];
        });
    }

    /* Between noneditable and disabled, priority will be given to disabled attrs */
    if (
      this.props.properties.disabled &&
      Object.keys(this.props.properties.disabled).length > 0
    ) {
      let disableKeys = Object.keys(this.props.properties.disabled);
      disableKeys.length > 0 &&
        disableKeys.forEach(val => {
          disable[val] = this.props.properties.disabled[val];
        });
    }
    /* LAT-5252 : update ip help text */
    let ipHelpRender;
    if (
      this.ipaddressHelpText &&
      Object.keys(this.ipaddressHelpText).length > 0
    ) {
      ipHelpRender = this.state.isIpRequired
        ? this.ipaddressHelpText["static"]
        : this.ipaddressHelpText["dynamic"];
    }
    return visibility ? (
      <div>
        {this.state.availablenodesList.length > 0 && !isClusterEnabled && (
          <div
            style={{
              display: this.props.urlParams.path.includes("servicetemplate")
                ? "block"
                : "none",
            }}
          >
            <FormItem label={this.props.properties.label} hasFeedback>
              {getFieldDecorator(`${this.props.id}_nodeid`, {
                rules: [
                  {
                    required: required,
                    message: validationMessage,
                  },
                ],
                initialValue: this.props.UiStore.templatingService.node[
                  this.props.id
                ].node_id
                  ? this.props.UiStore.templatingService.node[this.props.id]
                      .node_id
                  : "",
              })(
                <Select
                  mode={
                    this.props.properties.options &&
                    this.props.properties.options.mode
                      ? this.props.properties.options.mode
                      : null
                  }
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={this.selectedInodeValue}
                  //disabled={this.props.properties.disabled}
                  disabled={
                    this.props.viewOnly === true ||
                    disable["node"] ||
                    this.loadedfromInodesPage ||
                    this.props.urlParams.params.spec_id
                      ? true
                      : false
                  }
                  onFocus={
                    this.props.onFocus &&
                    this.props.onFocus.bind(null, this.nodeHelpText)
                  }
                  notFoundContent={getCurrentlocaleText(
                    "general.notfound.placeholder.text",
                  )}
                >
                  {this.state.availablenodesList.length > 0 &&
                    this.listData(this.state.availablenodesList)}
                </Select>,
              )}
            </FormItem>
          </div>
        )}
        {/**  MULTIPLE NETWORK **/}
        {this.state.availablenetworkList.length > 0 && (
          <div>
            <FormItem label="Network" hasFeedback>
              {getFieldDecorator(`${this.props.id}_networkid`, {
                rules: [
                  {
                    required: true,
                    message: `Please select a Network`,
                  },
                ],
                initialValue: this.state.selected_network_ids
                  ? this.state.selected_network_ids
                  : this.props.UiStore.templatingService.node[this.props.id]
                      .network.network_id
                  ? this.props.UiStore.templatingService.node[this.props.id]
                      .network.network_id
                  : [],
              })(
                <TreeSelect
                  treeData={treeData}
                  onChange={this.selectednetworks}
                  treeCheckable
                  maxTagCount={3}
                  maxTagPlaceholder={omittedValues => {
                    return `${omittedValues.length} Other(s)`;
                  }}
                  showCheckedStrategy={SHOW_PARENT}
                  placeholder="Please select Network"
                  onFocus={
                    this.props.onFocus &&
                    this.props.onFocus.bind(null, this.networkHelpText)
                  }
                  disabled={this.props.viewOnly === true ? true : false}
                  style={{
                    width: "100%",
                  }}
                />,
              )}
            </FormItem>
          </div>
        )}
        {(this.props.viewOnly || this.state.showIpOnLoad) && (
          <div
            onClick={
              this.props.onFocus &&
              this.props.onFocus.bind(
                null,
                this.props.properties.allowipaddress
                  ? this.props.properties.help &&
                      this.props.properties.help.ipaddress &&
                      this.props.properties.help.ipaddress.static
                  : ipHelpRender
                  ? ipHelpRender
                  : this.props.properties.help &&
                    this.props.properties.help.ipaddress &&
                    this.props.properties.help.ipaddress.static,
              )
            }
          >
            {!isCluster && (
              <FormItem label="IP Address">
                {getFieldDecorator(`${this.props.id}_ipaddress`, {
                  rules: [
                    {
                      required: this.state.isIpRequired,
                      message: ` ${getCurrentlocaleText(
                        "network.form.startip.errormessage1.text",
                      )}`,
                    },
                    {
                      validator: this.checkIpV4,
                    },
                  ],
                  initialValue:
                    this.props.UiStore.templatingService.node[this.props.id] &&
                    this.props.UiStore.templatingService.node[this.props.id]
                      .network.ip_address
                      ? this.props.UiStore.templatingService.node[this.props.id]
                          .network.ip_address
                      : this.ipValue,
                })(
                  <MultipleIpAddressInputList
                    removeNetwork={this.removeNetwork}
                    selected_networks={this.state.selected_networks}
                    referenceForm={this.props.referenceForm}
                    checkIpV4={this.checkIpV4}
                    {...this.props}
                    updateDefaultNetwork={this.updateDefaultNetwork}
                    updateResource={this.updateResource}
                    updatedValue={
                      this.props.UiStore.templatingService.node[
                        this.props.id
                      ] &&
                      this.props.UiStore.templatingService.node[this.props.id]
                        .networks &&
                      this.props.UiStore.templatingService.node[this.props.id]
                        .networks
                        ? this.props.UiStore.templatingService.node[
                            this.props.id
                          ].networks
                        : []
                    }
                    disabled={
                      this.props.viewOnly === true
                        ? true
                        : !this.state.isNetworkMissing
                        ? disable["ipaddress"]
                        : false
                    }
                  />,
                )}
              </FormItem>
            )}
          </div>
        )}
        {isCluster && (
          <div
            onClick={
              this.props.onFocus && this.props.onFocus.bind(null, this.kindHelp)
            }
          >
            <FormItem label={getCurrentlocaleText("services.kind.label")}>
              {getFieldDecorator(`${this.props.id}_kind`, {
                rules: [
                  {
                    required: true,
                    message: ` ${getCurrentlocaleText(
                      "services.kind.error.message",
                    )}`,
                  },
                ],
                initialValue:
                  this.props.UiStore.templatingService.node[this.props.id] &&
                  this.props.UiStore.templatingService.node[this.props.id].kind
                    ? this.props.UiStore.templatingService.node[this.props.id]
                        .kind
                    : "SINGLETON",
              })(
                <RadioGroup
                  onChange={this.onChangeValue}
                  disabled={
                    this.props.viewOnly ||
                    disable["kind"] ||
                    this.props.editmode
                      ? true
                      : false
                  }
                  onFocus={
                    this.props.onFocus &&
                    this.props.onFocus.bind(null, this.kindHelp)
                  }
                >
                  <Radio
                    disabled={
                      this.state.onlySingleton === true ? true : staticNetwork
                    }
                    value={"DAEMON"}
                  >
                    {getCurrentlocaleText("services.kind.daemon.label")}
                  </Radio>
                  <Radio
                    disabled={
                      this.state.onlySingleton === true ? true : staticNetwork
                    }
                    value={"REPLICA"}
                  >
                    {getCurrentlocaleText("services.kind.replica.label")}
                  </Radio>
                  <Radio value={"SINGLETON"}>
                    {getCurrentlocaleText("services.kind.singleton.label")}
                  </Radio>
                </RadioGroup>,
              )}
            </FormItem>
            {((this.props.UiStore.templatingService.node[this.props.id] &&
              this.props.UiStore.templatingService.node[this.props.id].kind &&
              this.props.UiStore.templatingService.node[this.props.id].kind ===
                "SINGLETON") ||
              (isCluster && this.state.isIpRequired)) && (
              <FormItem label="IP Address">
                {getFieldDecorator(`${this.props.id}_ipaddress`, {
                  rules: [
                    {
                      required: this.state.isIpRequired,
                      message: ` ${getCurrentlocaleText(
                        "network.form.startip.errormessage1.text",
                      )}`,
                    },
                    {
                      validator: this.checkIpV4,
                    },
                  ],
                  initialValue:
                    this.props.UiStore.templatingService.node[this.props.id] &&
                    this.props.UiStore.templatingService.node[this.props.id]
                      .network.ip_address
                      ? this.props.UiStore.templatingService.node[this.props.id]
                          .network.ip_address
                      : this.ipValue,
                })(
                  <MultipleIpAddressInputList
                    removeNetwork={this.removeNetwork}
                    selected_networks={this.state.selected_networks}
                    referenceForm={this.props.referenceForm}
                    checkIpV4={this.checkIpV4}
                    {...this.props}
                    updateResource={this.updateResource}
                    updatedValue={
                      this.props.UiStore.templatingService.node[
                        this.props.id
                      ] &&
                      this.props.UiStore.templatingService.node[this.props.id]
                        .networks &&
                      this.props.UiStore.templatingService.node[this.props.id]
                        .networks
                        ? this.props.UiStore.templatingService.node[
                            this.props.id
                          ].networks
                        : []
                    }
                    disabled={
                      this.props.viewOnly === true
                        ? true
                        : !this.state.isNetworkMissing
                        ? disable["ipaddress"]
                        : false
                    }
                  />,
                )}
              </FormItem>
            )}
          </div>
        )}
        {isCluster && this.state.showNodeSelector && (
          <div
            onClick={
              this.props.onFocus && this.props.onFocus.bind(null, this.kindHelp)
            }
          >
            <FormItem
              label={getCurrentlocaleText("services.node_selector.label")}
              validateStatus={this.state.nodeselector_validate_state}
              help={this.state.validateMessage}
            >
              {getFieldDecorator(`${this.props.id}_node_selector`, {
                rules: [
                  {
                    required: true,
                    message: ` ${getCurrentlocaleText(
                      "services.node_selector.error.message",
                    )}`,
                  },
                  {
                    validator: this.checkForNodeSelector,
                  },
                ],
                initialValue: this.state.node_selector_value.sort((a, b) => {
                  return a.includes("_iot") - b.includes("_iot");
                }),
              })(
                <Select
                  mode="tags"
                  style={{ width: "100%" }}
                  disabled={
                    this.props.viewOnly || disable["node_selector"]
                      ? true
                      : false
                  }
                  onFocus={
                    this.props.onFocus &&
                    this.props.onFocus.bind(null, this.kindHelp)
                  }
                  className={"iot-node-selector"}
                  placeholder={getCurrentlocaleText(
                    "services.node_selector.placeholder",
                  )}
                  onChange={this.handleLabelChange}
                  filterOption={(input, option) =>
                    option.props.title
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onDeselect={this.handleOnDeselect}
                  notFoundContent={getCurrentlocaleText(
                    "general.notfound.placeholder.text",
                  )}
                >
                  {children}
                </Select>,
              )}
            </FormItem>
          </div>
        )}
      </div>
    ) : (
      <div></div>
    );
  }
}

/*
sample schema
{
  "type": "NodeNetworkList",
  "visible": true,
  "props": {
    "label": "Select Node",
    "defaultValue": {
      "node_id": "",
      "network_id": "",
      "ip": ""
    },
    "disabled" : {
      "node" : false,
      "ipaddress" : false

    },
    "noneditable":{
      "ipaddress" : true
    },
    "help" : {
      "ipaddress" : "<div>help for ip</div>",
      "node" : "<div>help for node</div>",
      "network" : "<div>help for network</div>"
    },
    "constraints": {
      "required": true,
      "validationMessage": "Please select a node."
    }
  }
}


*/
