import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Row, Col, Empty, Icon, Select } from "antd";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import TableLayout from "components/layout/TableLayout";
import ClusterController from "controller/ClusterController";
import InodeController from "controller/InodeController";
import { customSort, getCurrentlocaleText } from "Core/Utils";
import TanDadForm from "components/Forms/TanDadForm";
import ActionBtn from "Core/API/ActionBtn";
import Icons from "../UI-Components/Icons";

const Option = Select.Option;
@inject("DadViewModel", "UiStore", "AuthStore")
@observer
class ClusterDadList extends Component {
  constructor(props) {
    super(props);
    this.UiStore = this.props.UiStore;
    this.dadModel = this.props.DadViewModel;
    this.state = {
      isDontShowChecked: false,
      disableScanDad: false,
      selectedCluster: {},
      clusterNodes: [],
    };
  }

  componentDidMount() {
    if (this.props.AuthStore.IsPermitted("NODE:VIEW")) {
      let resource_id = this.props.clusterId;
      this.clusterDetails(resource_id);
      ClusterController.getClusterDad(resource_id, true, true);
    }
  }
  clusterDetails = (resource_id = null) => {
    //let resource = "inodes",
    let map = {
      id: resource_id !== null ? resource_id : this.props.cluster_id,
    };
    ClusterController.getCluster(map.id).then(res => {
      return this.updateClusterDetails(res);
    });
  };
  updateClusterDetails = res => {
    this.setState({ clusterNodes: res.nodes });
    if (
      res.nodes &&
      res.nodes.length > 0 &&
      res.nodes[0].node &&
      res.nodes[0].node.id
    ) {
      this.setState({ selectedNodeName: res.nodes[0].node.name });
      InodeController.getInodeDad(res.nodes[0].node.id);
    }

    this.setState(() => {
      let newObj = {},
        selectedCluster = this.state.selectedCluster,
        disableScanDad = this.state.disableScanDad;
      selectedCluster = {
        value: true,
        name: res.name,
        id: res.id,
      };
      newObj["selectedCluster"] = selectedCluster;
      return newObj;
    });
  };
  ChangePopupVisibility = () => {
    this.setState({ isDontShowChecked: !this.state.isDontShowChecked });
    if (!this.state.isDontShowChecked === true) {
      ClusterController.scanNowNodeDad([this.props.nodeId]);
      this.setState({ isDontShowChecked: false });
    }
  };

  onNodeChanged = (values, record) => {
    if (record && record.props && record.props.title) {
      this.setState({ selectedNodeName: record.props.title });
    }
    InodeController.getInodeDad(values);
  };
  //render list for permission options
  renderList = () => {
    let optionArray = [];
    this.state.clusterNodes &&
      this.state.clusterNodes.map((val, index) => {
        optionArray.push(val);
      });
    return optionArray
      .sort((x, y) => customSort(x.node.name, y.node.name))
      .map((val, index) => {
        return (
          <Option title={val.node.name} key={val.node.id}>
            {val.node.name}
          </Option>
        );
      });
  };
  render() {
    let dads = this.dadModel.getInterfacesWithDuplicates || [];
    let dadScanAt = this.dadModel.getDadStatusAt;
    let columns = [
      {
        title: getCurrentlocaleText("dad.address.text"),
        key: "Duplicate Address",
        render: (text, record, index) => {
          return (
            <span>
              {record ? <span>{record.address}</span> : <span>-</span>}
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText("dad.type.text"),
        key: "type",
        render: (text, record, index) => {
          return (
            <span>
              {record.dup_type ? (
                <span>
                  <strong>{record.dup_type}</strong>
                </span>
              ) : (
                <span>-</span>
              )}
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText("dad.interface_name.text"),
        key: "Name",
        render: (text, record, index) => {
          return (
            <span>
              {record.tan_name ? (
                <span>{record.tan_name}</span>
              ) : (
                <span>-</span>
              )}
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText("dad.service.text"),
        key: "ServiceName",
        render: (text, record, index) => {
          return (
            <span>
              {record.service_name ? (
                <span>
                  {record.service_name ? (
                    <span>{record.service_name}</span>
                  ) : (
                    <span></span>
                  )}
                </span>
              ) : (
                <span>-</span>
              )}
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText("dad.local_mac_address.text"),
        key: "Local Mac Address",
        render: (text, record, index) => {
          return (
            <span>
              {record.mac_address ? (
                <span>{record.local_mac_address}</span>
              ) : (
                <span>-</span>
              )}
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText("dad.mac_address.text"),
        key: "Mac Address",
        render: (text, record, index) => {
          return (
            <span>
              {record.mac_address ? (
                <span>{record.mac_address}</span>
              ) : (
                <span>-</span>
              )}
            </span>
          );
        },
      },
    ];

    let container = (
      <div>
        {/* Dad List */}
        <div className="listing-page-data-container">
          {dads && dads.length > 0 && (
            <div
              style={{
                display: "inline-flex",
                width: "50%",
                marginBottom: 15,
              }}
            ></div>
          )}
          <Row gutter={16} type="flex" align="middle">
            <Col span={24}>
              {((dads && dads.length > 0 && dads[0].status_at) ||
                dadScanAt) && (
                <span className="listing-page-top-label">
                  <strong>{getCurrentlocaleText("dad.time.text")}</strong>
                  {dads && dads[0] && dads[0].status_at
                    ? dads[0].status_at
                    : dadScanAt}
                </span>
              )}
              <div
                style={{
                  marginBottom: "10px",
                  display: "inline-flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 7,
                    paddingRight: 5,
                    width: "20%",
                  }}
                >
                  {this.state.clusterNodes && (
                    <Select
                      value={this.state.selectedNodeName}
                      placeholder={getCurrentlocaleText(
                        "alertform.inode.placeholder.text",
                      )}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={this.onNodeChanged}
                      notFoundContent={getCurrentlocaleText(
                        "general.notfound.placeholder.text",
                      )}
                    >
                      {this.renderList()}
                    </Select>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 7,
                  }}
                >
                  <ActionBtn
                    title={
                      <span>
                        <Icons
                          type="ai"
                          name="AiOutlineFileSync"
                          style={{ marginRight: 8 }}
                        />
                        {getCurrentlocaleText("dad.force_button.text")}
                      </span>
                    }
                    resource="scanNowNodeDad"
                    type="primary"
                    action="scanNowNodeDad"
                    actionForm={TanDadForm}
                    fromCluster={true}
                    disabled={this.state.disableScanDad}
                    selectedNode={this.state.selectedCluster}
                    nodes={[this.props.clusterId]}
                    controller={ClusterController}
                    afterCall={() => {
                      this.iNodeDetails(null, false, true);
                    }}
                    okText={
                      <span>
                        <Icons
                          type="ai"
                          name="AiOutlineFileSync"
                          style={{ marginRight: 8 }}
                        />
                        {getCurrentlocaleText("dad.force_button.text")}
                      </span>
                    }
                  >
                    <Icons
                      type="ai"
                      name="AiOutlineFileSync"
                      style={{ marginRight: 8 }}
                    />
                    <span>{getCurrentlocaleText("dad.force_button.text")}</span>
                  </ActionBtn>
                </div>
              </div>
              {dads && dads.length > 0 ? (
                <TableLayout
                  pagination={false}
                  columns={columns}
                  hideRowSelection={true}
                  dataSource={dads}
                  className="dadList"
                  hideExpandedRow={true}
                />
              ) : (
                <div>
                  {<h3>{getCurrentlocaleText("no.dad.text")}</h3>}
                  <div>
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={getCurrentlocaleText("no.dad.text")}
                    />
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    );

    return (
      <LoadingComponent loading={this.dadModel.loading}>
        {container}
      </LoadingComponent>
    );
  }
}

export default ClusterDadList;
