import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Table } from "antd";
import propTypes from "prop-types";
import _ from "lodash";

@inject("ModelStore", "UiStore")
@observer
class TableLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      current: 1,
      expandRowProps: [],
    };
    this.columns = this.props.columns || [];
    this.dataSource = this.props.dataSource || [];
  }

  UNSAFE_componentWillReceiveProps(newprops) {
    // Unselect all rows if parent component has changed selected rows to empty
    if (
      this.props.selctedRows &&
      newprops.selctedRows &&
      this.props.selctedRows != newprops.selctedRows &&
      newprops.selctedRows.length == 0
    ) {
      this.setState({ selectedRowKeys: [] });
    }
  }

  // To set the state of the table - rows checked/unchecked
  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys });
  };

  onSelect = (record, selected, selectedRows) => {
    this.props.selectedRows
      ? this.props.selectedRows(record, selected, selectedRows)
      : "";
  };

  onSelectAll = (selected, selectedRows, changeRows) => {
    this.props.selectAll
      ? this.props.selectAll(selected, selectedRows, changeRows)
      : "";
  };

  rowClick = (record, index, event) => {
    this.props.rowClick ? this.props.rowClick(record, index, event) : "";
  };

  UNSAFE_componentWillMount() {
    this.props.UiStore.setCurrentPage(1);
    this.props.UiStore.setPageSize(10);
  }

  componentDidMount() {
    if (this.props.hasLazyLoading) {
      this.lazyLoading();
    }
  }

  lazyLoading = () => {
    const reduce = 50;
    let className = `.${this.props.className} .ant-table-body`;
    let element = document.querySelector(className);
    if (element) {
      let bounding = element.getBoundingClientRect();
      if (bounding) {
        let top = bounding.y || bounding.top;
        let y = window.innerHeight - top - reduce;
        this.updateScroll(y);
      }
      setTimeout(() => {
        element.addEventListener("scroll", event => {
          let maxScroll = event.target.scrollHeight - event.target.clientHeight;
          let currentScroll = event.target.scrollTop;
          if (
            Math.floor(currentScroll) === Math.floor(maxScroll) ||
            Math.ceil(currentScroll) === Math.ceil(maxScroll)
          ) {
            if (this.props.lazyLoad) {
              this.props.lazyLoad();
            }
          }
        });
      }, 1000);
      // For mac remove right padding of table header
      let headerElement = document.querySelector(
        `.${this.props.className} .ant-table-header`,
      );
      if (headerElement) {
        // Platform neither windows nor linux
        if (navigator && navigator.platform) {
          let isWindowsLike = navigator.platform.match(/windows|linux/i)
            ? true
            : false;
          if (isWindowsLike) {
            headerElement.style.paddingRight = "12px";
          } else {
            headerElement.style.paddingRight = "0px";
          }
        }
      }
    }
  };

  updateScroll = y => {
    /*
     * table height will be updated only when available height is above 200px
     * setting min-table height to 200px -> (this.props.UiStore.tableScroll)
     */
    y > 200 && this.props.UiStore.setTableScroll({ y: y });
  };

  render() {
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      onSelect: this.onSelect,
      onSelectAll: this.onSelectAll,
      getCheckboxProps: record => ({
        disabled: record.disabled, // Column configuration not to be checked
      }),
      type: this.props.selectionType ? this.props.selectionType : "checkbox",
    };

    return (
      <div>
        {this.dataSource ? (
          <Table
            className={this.props.className}
            pagination={
              this.props.pagination == false
                ? false
                : {
                    defaultCurrent: 1,
                    current: this.props.UiStore.currentPage,
                    pageSize: this.props.itemPerPage
                      ? this.props.itemPerPage
                      : this.props.UiStore.pageSize,
                    size: this.props.paginationSize
                      ? this.props.paginationSize
                      : "large",
                    onChange: (page, size) => {
                      this.props.UiStore.setCurrentPage(page);
                    },
                  }
            }
            rowKey={record => record.id}
            columns={this.props.columns}
            rowClassName={
              this.props.rowClassName
                ? this.props.rowClassName
                : () => {
                    return "";
                  }
            }
            // TODO - TS - Remove this extra props
            expandedRowRender={
              !this.props.hideExpandedRow
                ? this.props.expandedRowRender
                  ? this.props.expandedRowRender
                  : record => record.extraRow
                : false
            }
            dataSource={this.props.dataSource}
            rowSelection={this.props.hideRowSelection ? null : rowSelection}
            // TODO - define actions for pagination
            onRow={(record, index) => {
              return {
                onClick: event => {
                  this.rowClick(record, index, event);
                },
              };
            }}
            onChange={this.props.onChange}
            bordered={this.props.bordered}
            size={this.props.tableSize ? this.props.tableSize : "default"}
            style={this.props.style ? this.props.style : {}}
            scroll={
              this.props.scroll
                ? this.props.hasLazyLoading
                  ? this.props.UiStore.tableScroll
                  : this.props.scroll
                : {}
            }
            expandedRowKeys={
              this.props.expandedRowKeys ? this.props.expandedRowKeys : []
            }
            showExpandColumn={
              this.props.showExpandColumn !== null
                ? this.props.showExpandColumn
                : true
            }
            onExpand={this.props.onExpand ? this.props.onExpand : null}
            title={this.props.title ? this.props.title : null}
            showHeader={
              this.props.showHeader !== null ? this.props.showHeader : true
            }
            footer={this.props.footer ? this.props.footer : null}
          />
        ) : (
          "No dataSource"
        )}
      </div>
    );
  }
}

TableLayout.propTypes = {
  columns: propTypes.array.isRequired,
  dataSource: propTypes.array.isRequired,
  pagination: propTypes.any,
  selectedRows: propTypes.func,
  selectAll: propTypes.func,
  onChange: propTypes.func,
  itemPerPage: propTypes.any,
  paginationSize: propTypes.any,
  tableSize: propTypes.any,
  hideExpandedRow: propTypes.any,
  hideRowSelection: propTypes.any,
  scroll: propTypes.any,
  hidePagination: propTypes.any,
  showHeader: propTypes.any,
  footer: propTypes.func,
};

export default TableLayout;
