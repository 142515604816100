import React, { Component } from "react";
import { Spin } from "antd";
import { observer, inject } from "mobx-react";
import IoTiumSpin from "components/UI-Components/spin";

@inject("UiStore")
@observer
class LoadingComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let loading = this.props.loading
      ? this.props.loading
      : this.props.UiStore.loading;
    const antDSpin = (
      <div style={{ textAlign: "justify" }}>
        <Spin spinning={loading} tip="Loading...">
          {this.props.children}
        </Spin>
      </div>
    );

    const iotiumSpin = (
      <IoTiumSpin spinning={loading} tip="Loading...">
        {this.props.children}
      </IoTiumSpin>
    );

    return iotiumSpin;
  }
}

export default LoadingComponent;
