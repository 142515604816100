import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { getNodeVersion, InterTanRouteBaseVersion } from "Core/Utils";
import StatsContainer from "../Stats/NodeStatsContainer";
import InodeController from "controller/InodeController";
import { toJS } from "mobx";
@inject("InodeViewModel")
@observer
class StatsWrapper extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = toJS(this.props.InodeViewModel);
    this.checkNodeVersion();
  }

  checkNodeVersion = () => {
    if (
      !(
        this.props.InodeViewModel.inode &&
        toJS(this.props.InodeViewModel.inode).name
      )
    ) {
      let nodeId = this.props.match.params.nodeId;
      InodeController.getInode(nodeId, true, true).then(res => {});
    }
  };

  render() {
    const { ...props } = this.props;
    return <StatsContainer {...props} />;
  }
}
export default StatsWrapper;
