import React from "react";
import { observer, inject } from "mobx-react";
import ProfileViewModel from "../../../view-model/ProfileViewModel.js";
import Icons from "../../../components/UI-Components/Icons";
import {
  getCurrentlocaleText,
  isRestrictedCIDR,
  getCIDRdata,
  copyObject,
  isEmpty,
} from "../../../Core/Utils";
class NetworkMulticonnectFunc {
  computeCascaderOptions = (
    vNodes,
    SelectedNetworks,
    availableNetworks = [],
    inodeVersion = "NA",
    cluster_id = null,
  ) => {
    vNodes = this.removeDuplicates(vNodes, "id");
    let options = [],
      computedInfo = {},
      currentOrgId = null,
      currentNodeId = null;
    let x = 0,
      y = 0;
    vNodes.filter((vNodes, i) => {
      let localNetwork = this.CheckForNonWanNetworks(
        vNodes.networks,
        availableNetworks,
      );

      if (localNetwork.length > 0) {
        if (options.length <= 0) {
          if (
            vNodes.networks &&
            vNodes.networks.length > 0 &&
            (vNodes.networks &&
              SelectedNetworks.indexOf(localNetwork[0].id)) === -1
          ) {
            computedInfo = {
              value: vNodes.organization.id,
              label: vNodes.organization.name,
              tag: (
                <Icons
                  type="fa"
                  name="FaSitemap"
                  className="valign-text-bottom iot-tag-icon-style"
                />
              ),
              children: [
                {
                  value: vNodes.id,
                  label: vNodes.name,
                  tag: (
                    <Icons
                      type="fa"
                      name="FaRegHdd"
                      className="valign-text-bottom iot-tag-icon-style"
                    />
                  ),
                  children: localNetwork.map(n => {
                    return {
                      value: n.id,
                      label: n.name,
                      tag: (
                        <Icons
                          type="fa"
                          name="FaConnectdevelop"
                          className="valign-text-bottom iot-tag-icon-style"
                        />
                      ),
                    };
                  }),
                },
              ],
            };
            options.push(computedInfo);
          }
        } else {
          if (currentOrgId === vNodes.organization.id) {
            if (
              vNodes.networks &&
              SelectedNetworks.indexOf(localNetwork[0].id.id) === -1
            ) {
              if (currentNodeId === vNodes.id) {
                options[x].children.push({
                  value: vNodes.id,
                  label: vNodes.name,
                  tag: (
                    <Icons
                      type="fa"
                      name="FaRegHdd"
                      className="valign-text-bottom iot-tag-icon-style"
                    />
                  ),
                });
                options[x].children[y].children.push({
                  value: localNetwork[0].id,
                  label: localNetwork[0].name,
                  tag: (
                    <Icons
                      type="fa"
                      name="FaConnectdevelop"
                      className="valign-text-bottom iot-tag-icon-style"
                    />
                  ),
                });
                y++;
              } else {
                options[x].children.push({
                  value: vNodes.id,
                  label: vNodes.name,
                  tag: (
                    <Icons
                      type="fa"
                      name="FaRegHdd"
                      className="valign-text-bottom iot-tag-icon-style"
                    />
                  ),
                  children: localNetwork.map(n => {
                    return {
                      value: n.id,
                      label: n.name,
                      tag: (
                        <Icons
                          type="fa"
                          name="FaConnectdevelop"
                          className="valign-text-bottom iot-tag-icon-style"
                        />
                      ),
                    };
                  }),
                });
              }
            }
          } else {
            if (vNodes.networks && vNodes.networks.length > 0) {
              computedInfo = {
                value: vNodes.organization.id,
                label: vNodes.organization.name,
                tag: (
                  <Icons
                    type="fa"
                    name="FaSitemap"
                    className="valign-text-bottom iot-tag-icon-style"
                  />
                ),
                children: [
                  {
                    value: vNodes.id,
                    label: vNodes.name,
                    tag: (
                      <Icons
                        type="fa"
                        name="FaRegHdd"
                        className="valign-text-bottom iot-tag-icon-style"
                      />
                    ),
                    children: localNetwork.map(n => {
                      return {
                        value: n.id,
                        label: n.name,
                        tag: (
                          <Icons
                            type="fa"
                            name="FaConnectdevelop"
                            className="valign-text-bottom iot-tag-icon-style"
                          />
                        ),
                      };
                    }),
                  },
                ],
              };
              options.push(computedInfo);
              x++;
            }
          }
        }
      }
      currentOrgId = vNodes.organization.id;
      currentNodeId = vNodes.id;
    });
    return options;
  };

  computeCascaderOptionsWithClusters = (
    vNodes,
    SelectedNetworks,
    availableNetworks = [],
    inodeVersion = "NA",
    cluster_id = null,
  ) => {
    vNodes = this.removeDuplicates(vNodes, "id");
    let options = [],
      computedInfo = {},
      currentOrgId = null,
      currentNodeId = null;
    let x = 0,
      y = 0;
    vNodes.filter((vNodes, i) => {
      let localNetwork = this.CheckForNonWanNetworks(
        vNodes.networks,
        availableNetworks,
      );

      if (localNetwork.length > 0) {
        if (options.length <= 0) {
          if (
            vNodes.networks &&
            vNodes.networks.length > 0 &&
            (vNodes.networks &&
              SelectedNetworks.indexOf(localNetwork[0].id)) === -1
          ) {
            computedInfo = {
              value: vNodes.organization.id,
              label: vNodes.organization.name,
              tag: (
                <Icons
                  type="fa"
                  name="FaSitemap"
                  className="valign-text-bottom iot-tag-icon-style"
                />
              ),
              children: [
                {
                  value: vNodes.id,
                  label: vNodes.name,
                  tag: (
                    <Icons
                      type={!vNodes.profile ? "bs" : "fa"}
                      name={!vNodes.profile ? "BsBoundingBox" : "FaRegHdd"}
                      className="valign-text-bottom iot-tag-icon-style"
                    />
                  ),
                  isCluster: !vNodes.profile ? true : false,
                  children: localNetwork.map(n => {
                    return {
                      value: n.id,
                      label: n.name,
                      tag: (
                        <Icons
                          type="fa"
                          name="FaConnectdevelop"
                          className="valign-text-bottom iot-tag-icon-style"
                        />
                      ),
                    };
                  }),
                },
              ],
            };
            options.push(computedInfo);
          }
        } else {
          if (currentOrgId === vNodes.organization.id) {
            if (
              vNodes.networks &&
              SelectedNetworks.indexOf(localNetwork[0].id.id) === -1
            ) {
              if (currentNodeId === vNodes.id) {
                options[x].children.push({
                  value: vNodes.id,
                  label: vNodes.name,
                  isCluster: !vNodes.profile ? true : false,
                  tag: (
                    <Icons
                      type={!vNodes.profile ? "bs" : "fa"}
                      name={!vNodes.profile ? "BsBoundingBox" : "FaRegHdd"}
                      className="valign-text-bottom iot-tag-icon-style"
                    />
                  ),
                });
                options[x].children[y].children.push({
                  value: localNetwork[0].id,
                  label: localNetwork[0].name,
                  tag: (
                    <Icons
                      type="fa"
                      name="FaConnectdevelop"
                      className="valign-text-bottom iot-tag-icon-style"
                    />
                  ),
                });
                y++;
              } else {
                options[x].children.push({
                  value: vNodes.id,
                  label: vNodes.name,
                  isCluster: !vNodes.profile ? true : false,
                  tag: (
                    <Icons
                      type={!vNodes.profile ? "bs" : "fa"}
                      name={!vNodes.profile ? "BsBoundingBox" : "FaRegHdd"}
                      className="valign-text-bottom iot-tag-icon-style"
                    />
                  ),
                  children: localNetwork.map(n => {
                    return {
                      value: n.id,
                      label: n.name,
                      tag: (
                        <Icons
                          type="fa"
                          name="FaConnectdevelop"
                          className="valign-text-bottom iot-tag-icon-style"
                        />
                      ),
                    };
                  }),
                });
              }
            }
          } else {
            if (vNodes.networks && vNodes.networks.length > 0) {
              computedInfo = {
                value: vNodes.organization.id,
                label: vNodes.organization.name,
                tag: (
                  <Icons
                    type="fa"
                    name="FaSitemap"
                    className="valign-text-bottom iot-tag-icon-style"
                  />
                ),
                children: [
                  {
                    value: vNodes.id,
                    label: vNodes.name,
                    isCluster: !vNodes.profile ? true : false,
                    tag: (
                      <Icons
                        type={!vNodes.profile ? "bs" : "fa"}
                        name={!vNodes.profile ? "BsBoundingBox" : "FaRegHdd"}
                        className="valign-text-bottom iot-tag-icon-style"
                      />
                    ),
                    children: localNetwork.map(n => {
                      return {
                        value: n.id,
                        label: n.name,
                        tag: (
                          <Icons
                            type="fa"
                            name="FaConnectdevelop"
                            className="valign-text-bottom iot-tag-icon-style"
                          />
                        ),
                      };
                    }),
                  },
                ],
              };
              options.push(computedInfo);
              x++;
            }
          }
        }
      }
      currentOrgId = vNodes.organization.id;
      currentNodeId = vNodes.id;
    });
    return options;
  };

  filterVirtualNodes = inodes => {
    let vNodes = [];
    let vProfileIds = [];
    vProfileIds = ProfileViewModel.profiles
      .filter(profile => {
        if (profile.config.vpn_server) {
          return true;
        }
      })
      .map(profile => {
        return profile.id;
      });
    vNodes = inodes.filter(node => {
      if (vProfileIds.includes(node.profile.id) && node.node_state !== "NEW") {
        return node;
      } else {
        return null;
      }
    });
    return vNodes;
  };

  filterEdgeNodes = inodes => {
    let eNodes = [];
    let eProfileIds = [];

    eProfileIds = ProfileViewModel.profiles
      .filter(profile => {
        if (!profile.config.vpn_server) {
          return true;
        }
      })
      .map(profile => {
        return profile.id;
      });

    eNodes = inodes.filter(node => {
      if (eProfileIds.includes(node.profile.id) && node.node_state !== "NEW") {
        return node;
      } else {
        return null;
      }
    });
    return eNodes;
  };
  /**
   *
   */
  CheckForNonWanNetworks = (networks = [], availabelNetworks = []) => {
    let network_data = [];
    if (networks && networks.length > 0) {
      networks.forEach(network => {
        if (availabelNetworks.length > 0) {
          availabelNetworks.forEach(availabelNetwork => {
            // check for non-wan and non clustered networks
            if (
              !availabelNetwork.is_wan_network &&
              network.id === availabelNetwork.id &&
              !availabelNetwork.is_inheritance
            ) {
              network_data.push(availabelNetwork);
            }
          });
        }
      });
    }
    return network_data;
  };

  CheckForWanNetworks = (
    networks = [],
    availabelNetworks = [],
    includeInheritance = false,
  ) => {
    let network_data = [];
    if (networks && networks.length > 0) {
      networks.forEach(network => {
        if (availabelNetworks.length > 0) {
          availabelNetworks.forEach(availabelNetwork => {
            // check for non-wan and non clustered networks
            if (
              availabelNetwork.is_wan_network &&
              network.id === availabelNetwork.id
            ) {
              if (includeInheritance) {
                network_data.push(availabelNetwork);
              } else {
                if (!availabelNetwork.is_inheritance) {
                  network_data.push(availabelNetwork);
                }
              }
            }
          });
        }
      });
    }
    return network_data;
  };

  getErrorMessages = errorCode => {
    switch (errorCode) {
      case "NetworkCidrConflictsAtRemote":
      case "ConflictWithConnectedNetwork":
        return getCurrentlocaleText(
          "network.form.network.networkcidr_conflicts_at_remote.error.helptext",
        );
        break;
      case "AlreadyInUseNxid":
        return getCurrentlocaleText(
          "network.form.network.already_in_use_nxid.error.helptext",
        );
        break;
      case "ConflictWithNetworkCidr":
        return getCurrentlocaleText(
          "network.form.network.already_in_use_nxid.error.helptext",
        );
      case "RemoteNetworkCidrConflicts":
        return getCurrentlocaleText(
          "network.form.network.remotenetrork_conflict.error.helptext",
        );
        break;
      case "CidrLengthMismatch":
        return getCurrentlocaleText(
          "network.form.rep_network.errormessage4.text",
        );
        break;
      case "SelfCIDRConflict":
        return getCurrentlocaleText(
          "network.form.rep_network.self_conflict.error.text",
        );
        break;
      case "SelfCIDRmissMatch":
        return getCurrentlocaleText(
          "network.form.rep_network.self_cidr_mismatch.error.text",
        );
        break;
      default:
        return getCurrentlocaleText(
          "network.form.network.default.error.helptext",
        );
        break;
    }
  };
  //*-----------------Custom Validation ---------------------------------------------------------------------*/
  isrepCidrAllowed(value, remote_cidr, tan_cidr, local_repcidr) {
    let currentCIDR = getCIDRdata(value);
    if (currentCIDR) {
      if (isRestrictedCIDR(currentCIDR)) {
        return 1;
      }
      if (tan_cidr) {
        let networkCIDR = getCIDRdata(remote_cidr),
          tanNetworkCIDR = getCIDRdata(tan_cidr);
        if (networkCIDR && tanNetworkCIDR && !local_repcidr) {
          if (networkCIDR.subnetMaskLength !== currentCIDR.subnetMaskLength) {
            return 2;
          }
          if (
            networkCIDR.networkAddress === currentCIDR.networkAddress ||
            tanNetworkCIDR.networkAddress === currentCIDR.networkAddress
          ) {
            return 3;
          } else {
            return 0;
          }
        } else if (networkCIDR && tanNetworkCIDR && local_repcidr) {
          if (
            tanNetworkCIDR.subnetMaskLength !== currentCIDR.subnetMaskLength
          ) {
            return 2;
          }
          if (
            networkCIDR.networkAddress === currentCIDR.networkAddress ||
            tanNetworkCIDR.networkAddress === currentCIDR.networkAddress
          ) {
            return 3;
          } else {
            return 0;
          }
        }
      }
      return 0;
    }
  }
  /**
   * Check for existing rep networks
   */
  checkForvalidrepCIDR = (
    value,
    currentAddednetworks = [],
    currentNetworkId,
    isLocalRepCidr,
  ) => {
    let valueCidrData;
    if (value && value.length > 0) {
      valueCidrData = getCIDRdata(value);
    }
    let repCIDRs = 1;
    if (currentAddednetworks && currentNetworkId) {
      // Set local / peer rep cidr null based on isLocalRepCidr field
      currentAddednetworks = currentAddednetworks.map(network => {
        if (network) {
          let nw = copyObject(network);
          if (nw.key && nw.key === currentNetworkId) {
            if (isLocalRepCidr) {
              nw.local_representation_network = null;
            } else {
              nw.peer_representation_network = null;
            }
            return nw;
          } else {
            return nw;
          }
        }
      });
      currentAddednetworks.forEach(network => {
        if (network.local_representation_network) {
          let localRepCidrData = getCIDRdata(
            network.local_representation_network,
          );
          if (
            localRepCidrData &&
            valueCidrData &&
            localRepCidrData.networkAddress === valueCidrData.networkAddress
          ) {
            repCIDRs++;
          }
        }
        if (network.peer_representation_network) {
          let peerRepCidrData = getCIDRdata(
            network.peer_representation_network,
          );
          if (
            peerRepCidrData &&
            valueCidrData &&
            peerRepCidrData.networkAddress === valueCidrData.networkAddress
          ) {
            repCIDRs++;
          }
        }
      });
    }
    if (repCIDRs <= 1) {
      return true;
    } else {
      return false;
    }
  };

  computeNewconnectData = () => {
    let currentTime = new Date().getTime();
    let newData = {
      local_representation_network: undefined,
      peer_representation_network: undefined,
      key: currentTime.toString(),
      network_id: null,
      node_id: null,
      network_name: "",
      color: "default",
      org_name: "",
      org_id: "",
      node_name: "",
      remote_cidr: "",
      is_connected: false,
      record_value: [],
      firewall_selector: { match_label: {} },
      networkStatus: {},
    };

    return newData;
  };

  getConnectedNodesInfo = (
    remoteNodesdata,
    availableNetworksdata,
    networkModel,
    isUCGNode,
  ) => {
    let connectedNetworks = [],
      remoteNodes = remoteNodesdata,
      currentConnectednetwork = [],
      SelectedNetworks = [],
      availableNetworks = availableNetworksdata,
      RemoteCidr = null,
      networkStatus = {},
      tunnelStatus = [],
      remoteInterfaceIP = null,
      localInterfaceIP = null;
    if (networkModel.networks && networkModel.networks[0]) {
      networkStatus = copyObject(networkModel.networks[0].status || {});
      localInterfaceIP =
        networkModel.networks[0].config &&
        networkModel.networks[0].config.network.tan_interface_ip
          ? networkModel.networks[0].config.network.tan_interface_ip
          : null;
      tunnelStatus =
        networkModel.networks[0].status &&
        networkModel.networks[0].status.connected_networks
          ? networkModel.networks[0].status.connected_networks
          : [];
      connectedNetworks =
        (networkModel.networks[0] &&
          networkModel.networks[0].connected_networks) ||
        [];
      connectedNetworks.forEach(connected_network => {
        remoteNodes.forEach(remote_node => {
          let localNetwork = [];
          if (isUCGNode) {
            localNetwork = this.CheckForWanNetworks(
              remote_node.networks,
              availableNetworks,
              true,
            );
          } else {
            localNetwork = this.CheckForNonWanNetworks(
              remote_node.networks,
              availableNetworks,
            );
          }

          if (localNetwork && localNetwork.length > 0) {
            localNetwork.forEach(local_network => {
              if (local_network.id === connected_network.network.id) {
                // Connected network's DisableRepresentationNetworkAutomation
                let connectedNwDisableRepresentationNetworkAutomation = null;
                availableNetworks.forEach(availabelNetwork => {
                  if (availabelNetwork.id === connected_network.network.id) {
                    RemoteCidr = availabelNetwork.config.network.cidr;
                    remoteInterfaceIP =
                      availabelNetwork.config.network.tan_interface_ip;
                    if (availabelNetwork.connected_networks) {
                      let connectedNw = availabelNetwork.connected_networks.find(
                        cnw => {
                          return (
                            cnw &&
                            cnw.network &&
                            networkModel &&
                            networkModel.networks &&
                            networkModel.networks[0] &&
                            cnw.network.id === networkModel.networks[0].id
                          );
                        },
                      );
                      if (connectedNw) {
                        connectedNwDisableRepresentationNetworkAutomation =
                          connectedNw.disable_representation_network_automation;
                      }
                    }
                  }
                });
                // Tunnel status
                let status = {};
                if (tunnelStatus) {
                  status = tunnelStatus.find(status => {
                    return status.id === connected_network.id;
                  });
                }
                let networkData = {
                  peer_representation_network: connected_network.representation_network
                    ? connected_network.representation_network.peer
                    : "",
                  key: connected_network.network.id,
                  network_id: connected_network.network.id,
                  node_id: connected_network.node
                    ? connected_network.node.id
                    : connected_network.cluster && connected_network.cluster.id,
                  network_name: connected_network.network.name,
                  org_name: remote_node.organization.name,
                  org_id: remote_node.organization.id,
                  node_name: remote_node.name,
                  remote_cidr: RemoteCidr,
                  remote_interface_ip: remoteInterfaceIP,
                  local_interface_ip: localInterfaceIP,
                  is_connected: true,
                  isCluster: !remote_node.profile ? true : false,
                  record_value: [],
                  firewall_selector:
                    this.getSelectorValues(
                      connected_network.firewall_selector &&
                        connected_network.firewall_selector.match_label,
                    ).length === 0
                      ? []
                      : this.getSelectorValues(
                          connected_network.firewall_selector &&
                            connected_network.firewall_selector.match_label,
                        ),
                  networkStatus: status,
                };
                // Set connected network Disable representation network automation
                if (
                  connected_network.disable_representation_network_automation ===
                  false
                ) {
                  networkData.disable_representation_network_automation =
                    connected_network.disable_representation_network_automation;
                } else {
                  networkData.disable_representation_network_automation =
                    connectedNwDisableRepresentationNetworkAutomation ===
                      undefined ||
                    connectedNwDisableRepresentationNetworkAutomation === null
                      ? true
                      : connectedNwDisableRepresentationNetworkAutomation;
                }
                // Set connected network local representation network
                if (
                  connected_network.representation_network &&
                  connected_network.representation_network.local
                ) {
                  networkData.local_representation_network =
                    connected_network.representation_network.local;
                }
                SelectedNetworks.push(connected_network.network.id);
                currentConnectednetwork.push(networkData);
              }
            });
          }
        });
      });
    }
    return {
      SelectedNetworks: SelectedNetworks,
      currentConnectednetwork: currentConnectednetwork,
      networkStatus: networkStatus,
    };
  };
  /**
   * Filter Current Network data
   */
  getNetworkDetails(NetworkData, NetworkId) {
    return NetworkData.filter(network => {
      if (network.id === NetworkId) {
        return network;
      }
    });
  }
  getSelectorValues = selectors => {
    let Selectedselectors = [];
    if (selectors && selectors.name) {
      delete selectors.name;
    }
    for (let key in selectors) {
      if ((key && key.charAt(0) != "_") || key === "_iotium.firewall.diode")
        Selectedselectors.push(key + ":" + selectors[key]);
    }
    return Selectedselectors;
  };
  getDiodeValue = (record, profile) => {
    if (record && record.firewall_selector != undefined) {
      if (
        Array.isArray(
          record.firewall_selector != undefined ? record.firewall_selector : [],
        )
      ) {
        if (
          record.firewall_selector.includes(
            "_iotium.firewall.diode:ingress-only",
          ) &&
          !profile
        ) {
          return 1;
        } else if (
          record.firewall_selector.includes(
            "_iotium.firewall.diode:egress-only",
          ) &&
          !profile
        ) {
          return 2;
        } else if (
          record.firewall_selector.includes(
            "_iotium.firewall.diode:egress-only",
          ) &&
          profile
        ) {
          return 2;
        } else if (
          record.firewall_selector.includes(
            "_iotium.firewall.diode:ingress-only",
          ) &&
          profile
        ) {
          return 1;
        } else {
          return null;
        }
      } else {
        let tempData = [];
        tempData = this.getTagKeyValues(
          record.firewall_selector && record.firewall_selector.match_label,
        );
        if (tempData.includes("_iotium.firewall.diode:ingress-only")) {
          return 1;
        } else if (tempData.includes("_iotium.firewall.diode:egress-only")) {
          return 2;
        } else {
          return null;
        }
      }
    } else {
      return null;
    }
  };
  gettunnelUserdefined = record => {
    if (record && record.firewall_selector != undefined) {
      if (
        Array.isArray(
          record.firewall_selector != undefined ? record.firewall_selector : [],
        )
      ) {
        let tempData = record.firewall_selector.splice(
          "_iotium.firewall.diode:ingress-only",
          1,
        );
        tempData = record.firewall_selector.splice(
          "_iotium.firewall.diode:egress-only",
          1,
        );
        return tempData;
      } else {
        let tempData = [];
        tempData = this.getTagKeyValues(
          record.firewall_selector && record.firewall_selector.match_label,
          true,
        );
        tempData = tempData.splice(
          "_iotium.firewall.diode:ingress-only",
          tempData.indexOf("_iotium.firewall.diode:ingress-only"),
        );
        tempData = tempData.splice(
          "_iotium.firewall.diode:egress-only",
          tempData.indexOf("_iotium.firewall.diode:ingress-only"),
        );
        return tempData;
      }
    } else {
      return [];
    }
  };
  getTagKeyValues = (labels, status) => {
    let tags = [];
    if (labels && labels.name) {
      delete labels.name;
    }
    for (let key in labels) {
      if ((key && key.charAt(0) != "_") || key === "_iotium.firewall.name")
        tags.push(key + ":" + labels[key]);
    }
    return tags;
  };
  removeDuplicates(myArr, key = "key") {
    let tempData = [];
    return myArr.filter((obj, pos, arr) => {
      if (!tempData.includes(obj[key])) {
        tempData.push(obj[key]);
        return obj;
      }
    });
  }
}
export default new NetworkMulticonnectFunc();
