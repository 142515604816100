import React, { Component } from "react";
import propTypes from "prop-types";
import { Card, Row, Col } from "antd";
import "../../../assets/styles/CountCards.css";
import Icons from "components/UI-Components/Icons";
import { Link } from "react-router-dom";

class CountCards extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return this.props.hasChildren ? (
      <Card className="numberCard card-container">
        <Row className="content">
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 20 }}
            xl={{ span: 24 }}
            className="iconWarp"
          >
            <Icons type={this.props.type} name={this.props.icon} />
          </Col>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 20 }}
            xl={{ span: 24 }}
            className="number"
          >
            <span style={{ wordWrap: "break-word" }}>{this.props.number}</span>
          </Col>
        </Row>
        <div className="card-overlay">
          <div className="card-child-content">
            <Link to={this.props.redirect} title={this.props.title}>
              <Row
                className="content"
                style={{ borderBottom: "1px solid #e9e9e9" }}
              >
                <Col
                  xs={{ pull: 2, span: 6 }}
                  sm={{ pull: 2, span: 6 }}
                  md={{ pull: 2, span: 6 }}
                  lg={{ pull: 6, span: 10 }}
                  xl={{ pull: 6, span: 12 }}
                  className="iconWarp-overlay"
                >
                  <Icons type={this.props.type} name={this.props.icon} />
                </Col>
                <Col
                  xs={{ span: 6 }}
                  sm={{ span: 6 }}
                  md={{ push: 2, span: 6 }}
                  lg={{ push: 6, span: 10 }}
                  xl={{ push: 6, span: 12 }}
                  className="number-overlay"
                >
                  <span
                    style={{
                      wordWrap: "break-word",
                    }}
                  >
                    {this.props.number}
                  </span>
                </Col>
              </Row>
            </Link>
            <Link
              to={this.props.children.redirect}
              title={this.props.children.title}
            >
              <Row className="content">
                <Col
                  xs={{ span: 6 }}
                  sm={{ span: 6 }}
                  md={{ span: 6 }}
                  lg={{ pull: 6, span: 10 }}
                  xl={{ pull: 6, span: 12 }}
                  className="iconWarp-overlay"
                >
                  <Icons
                    type={this.props.children.type}
                    name={this.props.children.icon}
                  />
                </Col>
                <Col
                  xs={{ span: 6 }}
                  sm={{ span: 6 }}
                  md={{ span: 6 }}
                  lg={{ push: 6, span: 10 }}
                  xl={{ push: 6, span: 12 }}
                  className="number-overlay"
                >
                  <span
                    style={{
                      wordWrap: "break-word",
                    }}
                  >
                    {this.props.children.number}
                  </span>
                </Col>
              </Row>
            </Link>
          </div>
        </div>
      </Card>
    ) : (
      <Link to={this.props.redirect} title={this.props.title}>
        <Card className="numberCard">
          <Row className="content">
            <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 20 }}
              xl={{ span: 24 }}
              className="iconWarp"
            >
              <Icons type={this.props.type} name={this.props.icon} />
            </Col>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 20 }}
              xl={{ span: 24 }}
              className="number"
            >
              <span
                style={{
                  wordWrap: "break-word",
                }}
              >
                {this.props.number}
              </span>
            </Col>
          </Row>
        </Card>
      </Link>
    );
  }
}
CountCards.propTypes = {
  icon: propTypes.string,
  color: propTypes.string,
  title: propTypes.string,
  number: propTypes.number,
  type: propTypes.string,
};
export default CountCards;
