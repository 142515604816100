import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import { Select } from "antd";
import { inject } from "mobx-react";
import { computed } from "mobx";
import Twig from "twig";
import { InodeController, ClusterController } from "controller/Controllers";
import { copyObject, getCurrentlocaleText } from "Core/Utils";

const FormItem = Form.Item;
const Option = Select.Option;

@inject("UiStore")
export default class NICList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      interfaces: null,
    };
  }

  componentDidMount() {
    let node_id = this.props.urlParams.params.node_id;
    let cluster_id = this.props.urlParams.params.cluster_id;
    if (node_id) {
      InodeController.getNodeNetplan(node_id).then(response => {
        this.setState({ interfaces: response });
      });
    } else if (cluster_id) {
      ClusterController.getClusterNetplan(cluster_id).then(response => {
        this.setState({ interfaces: response });
      });
    }
  }

  listData = interfaces => {
    if (interfaces && interfaces.free && interfaces.free.length > 0) {
      return interfaces.free.map(item => {
        let disabled = !item.is_available;
        return (
          <Option
            key={item.display_name}
            label={item.display_name}
            value={`${item.display_name}`}
            disabled={disabled}
          >
            <div>
              <span style={{ float: "left" }}>{item.display_name}</span>
              <span style={{ float: "right" }}>
                {!disabled ? "Available" : "Not Available"}
              </span>
            </div>
          </Option>
        );
      });
    }
  };

  @computed
  get componentVisibility() {
    let visibility = null;
    if (
      this.props.UiStore.computedOutputObject &&
      this.props.UiStore.computedOutputObject.steps
    ) {
      if (this.props.properties.visibility) {
        var template = Twig.twig({
          data: this.props.properties.visibility,
        });
        let computedOutputObject = copyObject(
          this.props.UiStore.computedOutputObject,
        );
        visibility = template.render(computedOutputObject);
        try {
          visibility = JSON.parse(visibility);
        } catch {}
      }
    }
    return visibility ? true : false;
  }

  render() {
    const { getFieldDecorator } = this.props.referenceForm; // reference of base form
    let required =
      this.props.properties.constraints &&
      this.props.properties.constraints.required
        ? this.props.properties.constraints.required
        : false;

    let validationMessage =
      this.props.properties.constraints &&
      this.props.properties.constraints.validationMessage
        ? this.props.properties.constraints.validationMessage
        : `${this.props.properties.label} is required`;

    let disable = false;
    if (this.props.editmode) {
      disable = this.props.properties.noneditable
        ? this.props.properties.noneditable
        : false;
    }
    disable = this.props.properties.disabled
      ? this.props.properties.disabled
      : disable;

    // For View only
    disable = this.props.viewOnly === true ? true : disable;

    let visibility = this.props.properties.visibility
      ? this.componentVisibility
      : true;

    let value =
      this.props.properties.defaultValue &&
      this.props.properties.defaultValue.value
        ? this.props.properties.defaultValue.value
        : typeof this.props.properties.defaultValue === "object" &&
          this.props.properties.defaultValue
        ? this.props.properties.defaultValue
        : null;

    let initialValue = value && value[0] ? value[0] : null;

    return (
      <div>
        {visibility && (
          <FormItem label={this.props.properties.label} hasFeedback>
            {getFieldDecorator(`${this.props.id}`, {
              rules: [
                {
                  required: required,
                  message: validationMessage,
                },
              ],
              initialValue: initialValue,
            })(
              <Select
                optionLabelProp="label"
                placeholder={this.props.properties.placeholder}
                mode={this.props.properties.mode}
                onChange={value => {
                  this.props.updateCurrentValueOnChange(value, "value");
                }}
                disabled={disable}
                allowClear={true}
                onFocus={
                  this.props.onFocus &&
                  this.props.onFocus.bind(null, this.props.properties.help)
                }
                notFoundContent={getCurrentlocaleText(
                  "general.notfound.placeholder.text",
                )}
              >
                {this.state.interfaces && this.listData(this.state.interfaces)}
              </Select>,
            )}
          </FormItem>
        )}
      </div>
    );
  }
}

/*
sample schema
{
  "type": "NICList",
  "visible": true,
  "props": {
    "label": "Select an Interface",
    "constraints": {
      "required": true,
      "validationMessage": "Please select an Interface"
    },
    "disabled": false,
    "noneditable": false,
    "placeholder": "Please select an Interface"
    "help" : "Selece an interface that connects to this service",
    }
  }
}
*/
