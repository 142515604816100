import React, { Component } from "react";
import { getCurrentlocaleText } from "Core/Utils";
import Chart from "react-google-charts";
import { observer, inject } from "mobx-react";

var options = {
  width: 300,
  height: 200,
  redFrom: 80,
  redTo: 100,
  yellowFrom: 55,
  yellowTo: 90,
  greenFrom: 0,
  greenTo: 55,
  minorTicks: 5,
  greenColor: "#109618",
  chartArea: {
    left: "4%",
    top: "25%",
    width: "90%",
    height: "80%",
  },
};

@inject("AuthStore")
@observer
class UtilizationChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      interfaces: this.props.interfaces,
      loading: false,
      currentInterface: "eth0",
      data: this.props.data,
      FileSystemInfo: [],
    };
  }

  UNSAFE_componentWillReceiveProps(newprops) {
    if (this.props != newprops) {
      this.setState({
        loading: newprops.loading,
        data: newprops.data,
        interfaces: newprops.interfaces,
      });
    }
  }
  render() {
    const chartEvents = [
      {
        callback: ({ chartWrapper, google }) => {
          const chart = chartWrapper.getChart();

          chart.container.addEventListener("click", ev => {
            this.props.redirectToAdvnancedStats();
          });
        },
        eventName: "ready",
      },
    ];
    return (
      <div>
        <div className="iot-pointer-cursor">
          {this.props.data && this.props.data.length > 0 ? (
            <Chart
              width={this.props.width}
              height={this.props.height}
              chartType={this.props.chartType}
              loader={<div>Loading Chart</div>}
              data={this.props.data}
              options={this.props.options}
              chartEvents={chartEvents}
              {...this.props}
            />
          ) : (
            getCurrentlocaleText("stats.nometrics.text")
          )}
        </div>
      </div>
    );
  }
}

export default UtilizationChart;
