import React from "react";
import Icons from "components/UI-Components/Icons";
import {
  getFirewallProtocolAndPort,
  getCurrentlocaleText,
  isEmpty,
} from "Core/Utils";
import { Tag, Popover } from "antd";

class ListenerFirewallFunc {
  getNetworkGroupInfo = (networkId, InterfaceLabels) => {
    let Networkdata = InterfaceLabels || [];
    let data = getCurrentlocaleText("general.unknown.text.value");
    if (Networkdata.length > 0) {
      Networkdata.forEach(network => {
        if (network.id === networkId) {
          let string =
            (network.cluster ? network.cluster.name : network.node.name) +
            network.name;
          data =
            string.length < 15 ? (
              <Tag
                key={network.id}
                style={{ height: string.length > 10 ? "auto" : 22 }}
                className="iot-tag-label-color"
              >
                {" "}
                <Icons type={"fa"} name="FaRegHdd" />
                <span className="iot-tag-label">
                  {" "}
                  {network.cluster ? network.cluster.name : network.node.name}
                </span>
                / {string.length > 15 ? <br /> : null}
                <Icons
                  type={network.cluster ? "bs" : "fa"}
                  name={network.cluster ? "BsBoundingBox" : "FaRegHdd"}
                />{" "}
                <span className="iot-tag-label">{network.name}</span>
              </Tag>
            ) : (
              <Popover
                placement="top"
                content={
                  <Tag key={network.id} className="iot-tag-label-color">
                    {" "}
                    <Icons
                      type={network.cluster ? "bs" : "fa"}
                      name={network.cluster ? "BsBoundingBox" : "FaRegHdd"}
                    />
                    <span className="iot-tag-label">
                      {network.cluster
                        ? network.cluster.name
                        : network.node.name}
                    </span>
                    /
                    <Icons
                      type={"fa"}
                      name="FaConnectdevelop"
                      className="iot-tag-label"
                    />
                    <span className="iot-tag-label">{network.name}</span>
                  </Tag>
                }
              >
                <span className="iot-tag-label">
                  <Tag
                    key={network.id}
                    className={"firewall-rules-tag-content iot-tag-label-color"}
                  >
                    {" "}
                    <Icons
                      type={"fa"}
                      name="FaConnectdevelop"
                      className="iot-tag-label"
                    />
                    {network.name}
                  </Tag>
                </span>
              </Popover>
            );
        }
      });
    }
    return data;
  };

  /**
   * Prepare Firewall table under listeners
   * @param {*} listeners
   */
  FirewallTableData(listeners, networks, showUnResolvedRules = false) {
    let firewall_rules = {};
    let data = [],
      networkDetail = {};
    if (networks) {
      networks.forEach(network => {
        if (network.id === listeners.network) {
          networkDetail = network;
        }
      });
    }
    if (listeners && listeners.firewall_status) {
      data = data.concat(
        this.getFirewallRules(
          listeners.firewall_status,
          "Inbound",
          showUnResolvedRules,
          networkDetail,
        ),
      );
    }
    firewall_rules = data;
    return firewall_rules;
  }
  /**
   * Prepare Firewall table under Networks
   * @param {*} networks
   */
  FirewallTableAllData(listenerStatus, networks, showUnResolvedRules = false) {
    let firewall_rules = [];
    let data = [];
    if (
      listenerStatus &&
      listenerStatus.listener_statuses &&
      listenerStatus.listener_statuses.length > 0
    ) {
      listenerStatus.listener_statuses.forEach(listener => {
        data = data.concat(
          this.FirewallTableData(listener, networks, showUnResolvedRules),
        );
      });
      firewall_rules = data;
    }
    return firewall_rules;
  }
  /**
   * Prepare Rules Array for firewall
   */
  getFirewallRules = (rules, type, showUnResolvedRules = false, network) => {
    let data = [];
    let priority_list = [];
    rules.forEach((val, index) => {
      let obj = getFirewallProtocolAndPort(
        `${val.protocol.toLowerCase()}${val.destination_port &&
          val.destination_port.start}-${val.destination_port &&
          val.destination_port.end}`,
      );

      data.push({
        key: `${type}${index}`,
        id: `${type}${index}`,
        type: type,
        protocol: !isEmpty(obj.key) ? obj.applicationProtocol : val.protocol,
        port: val.destination_port
          ? val.destination_port.start === val.destination_port &&
            val.destination_port.end
            ? val.destination_port && val.destination_port.start !== -1
              ? `${val.destination_port && val.destination_port.start}`
              : `1 - 65535`
            : val.destination_port &&
              val.destination_port.start != val.destination_port.end
            ? `${val.destination_port &&
                val.destination_port.start} - ${val.destination_port &&
                val.destination_port.end}`
            : `${val.destination_port.start}`
          : "1 - 65535",
        source_port: val.source_port
          ? val.source_port.start === val.source_port && val.source_port.end
            ? val.source_port && val.source_port.start !== -1
              ? `${val.source_port && val.source_port.start}`
              : `1 - 65535`
            : val.source_port && val.source_port.start != val.source_port.end
            ? `${val.source_port &&
                val.source_port.start} - ${val.source_port &&
                val.source_port.end}`
            : `${val.source_port.start}`
          : "1 - 65535",
        source_ipaddress: val.source_ip,
        source_tag: val.source_tag,
        destination_ipaddress: val.destination_ip,
        destination_tag: val.destination_tag,
        source_network: val.source_network_domain
          ? val.source_network_domain
          : this.getSelectorValues(val.source_network),
        destination_network: val.destination_network_domain
          ? val.destination_network_domain
          : this.getSelectorValues(val.destination_network),
        byte_count: val.byte_count,
        packet_count: val.packet_count,
        priority: val.priority,
        action: val.action,
        is_generated: val.generated ? val.generated : false,
        is_resolved: val.not_resolved ? false : true,
        show_warning: priority_list.includes(val.priority) ? true : false,

        network_name: network ? network.name : undefined,
        firewall_data: val.firewall ? val.firewall : [],
      });
      priority_list.push(val.priority);
    });
    if (showUnResolvedRules) {
      return data;
    } else {
      return data.filter(d => {
        if (d.is_resolved) return d;
      });
    }
  };
  getSelectorValues = selectors => {
    let Selectedselectors = [];
    for (let key in selectors) {
      Selectedselectors.push(key + ":" + selectors[key]);
    }
    return Selectedselectors;
  };

  filterDefaultFirewallGroups(inputString, InterfaceLabels = []) {
    if (
      inputString &&
      (inputString.includes("_iotium.network.id") ||
        inputString.includes("_iotium.cluster.wan.network.id") ||
        inputString.includes("_iotium.cluster.tan.network.id"))
    ) {
      return this.getNetworkGroupInfo(
        inputString.split(":")[1].trim(),
        InterfaceLabels,
      );
    } else if (inputString && inputString.includes("_iotium.")) {
      return inputString.replace("_iotium.", " ");
    } else {
      return inputString.replace("AnyPeer", "AnyRemoteNetwork");
    }
  }
  checkforAnyString(inputString, InterfaceLabels = []) {
    if (inputString && inputString.includes("@tag:")) {
      return "CIDR Not Resolved";
    }
    switch (inputString) {
      case "0.0.0.0/0":
        return "ANY";
        break;
      case "1 - 65535":
      case "-1 - -1":
      case "-1":
      case "*":
        return "ANY";
        break;
      default:
        return this.filterDefaultFirewallGroups(inputString, InterfaceLabels);
        break;
    }
  }

  getTagColor = action => {
    switch (action) {
      case "ALLOW":
        return "iotium-success";
        break;
      case "DENY":
        return "iotium-danger";
        break;
      default:
        return "default";
        break;
    }
  };
  getStatusPacketsData = data => {
    if (data.packet_count) return data.packet_count;
  };
  checkForDebugEnabled = network_status => {
    if (
      network_status.policy &&
      network_status.policy.firewall &&
      network_status.policy.firewall.debug
    )
      return true;
    else return false;
  };
}
export default new ListenerFirewallFunc();
