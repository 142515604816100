import React, { Component } from "react";
import { Layout } from "antd";
import axios from "axios";
import { getCurrentlocaleText } from "Core/Utils";
import { observer, inject } from "mobx-react";
import Icons from "../UI-Components/Icons";

const { Footer } = Layout;

@inject("UiStore")
@observer
class IoFooter extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    if (!this.props.UiStore.ioTiumVersion) {
      axios.get("/api/v1/about").then(response => {
        this.props.UiStore.setioTiumVersion(response.data.version);
      });
    }
  }
  render() {
    const currentYear = new Date().getFullYear().toString(); // For current year

    const copyRights = `© Copyright ${currentYear}${" "}${getCurrentlocaleText(
      "iotium.footer.copyrights.text",
    )} v${this.props.UiStore.ioTiumVersion}`;

    let unknownBrowserClassName = "footer-unknown-browser-slider";
    if (this.props.isNotChrome) {
      if (this.props.centerAlignFooter) {
        unknownBrowserClassName = "footer-unknown-browser-center";
      } else if (!this.props.UiStore.isSiderFold) {
        unknownBrowserClassName = "footer-unknown-browser-noslider";
      } else {
        unknownBrowserClassName = "footer-unknown-browser-slider";
      }
    }

    return (
      <div>
        <div className="footer-padding">
          {this.props.showCopyRight && copyRights}
        </div>
        {this.props.isNotChrome && (
          <Footer
            className={
              "footer-unknown-browser-warning " + unknownBrowserClassName
            }
          >
            <Icons
              type="ai"
              name="AiFillExclamationCircle"
              className="iot-status-yellow"
            />
            {getCurrentlocaleText("browser.invalid.footer.message")}
          </Footer>
        )}
      </div>
    );
  }
}

export default IoFooter;
