import React, { Component } from "react";
import { Input, Select, Button } from "antd";
import { Form } from "@ant-design/compatible";
import { observer, inject } from "mobx-react";
import { focusFirstInput } from "Core/Utils";
import FormErrorMessage from "components/UI-Components/FormErrorMessage";
import Icons from "components/UI-Components/Icons";

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

@inject("ModelStore", "UiStore")
@observer
class SerialForm extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    /* Focus First Input */
    focusFirstInput();
  }
  setSelectVal = values => {
    let formVals = {
      org_id: this.props.extendData.orgDetails.id,
    };

    this.props.form.setFields(formVals);
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form noValidate onSubmit={this.props.onSubmit} className="login-form">
        <FormItem label="Serial Number" hasFeedback>
          {getFieldDecorator("hardware_serial_number", {
            rules: [
              {
                required: true,
                message: "Please enter your serial number.",
                whitespace: true,
              },
              {
                pattern: /^[A-Za-z0-9-, ;\n]*$/,
                message: "Invalid serial number",
              },
            ],
          })(
            <TextArea
              className="capitalize"
              type="string"
              autoComplete="off"
              prefix={<Icons type="fa" name="FaBarcode" />}
              placeholder="Enter serial numbers. Please use comma, semicolon, or space to separate multiple serial numbers."
              rows={4}
            />,
          )}
        </FormItem>
        <Button className="hidden-submit" htmlType="submit" />
        <FormErrorMessage />
      </Form>
    );
  }
}

const SerialForms = Form.create()(SerialForm);
export default SerialForms;
