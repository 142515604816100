import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import { Select } from "antd";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";
import { SecretController } from "controller/Controllers";
import ServiceTemplatesUtils from "components/serviceTemplates/Utils/ServiceTemplatesUtils";
import { copyObject, getCurrentlocaleText } from "Core/Utils";
import { computed } from "mobx";
import Twig from "twig";

/*Define form elements*/
const FormItem = Form.Item;
const Option = Select.Option;

@inject(
  "UiStore",
  "SecretViewModel",
  "ServiceViewModel",
  "NetworkViewModel",
  "InodeViewModel",
)
@observer
export default class SecretList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listData: [],
      dockerConfigs: [],
    };
    this.secretModel = this.props.SecretViewModel;
  }

  listData = data => {
    let list = [];
    data.map((val, index) => {
      if (this.props.properties.options.type.toLowerCase() === "license") {
        let assignedLic;
        if (
          this.props.UiStore.templatingService.secrets &&
          Object.keys(this.props.UiStore.templatingService.secrets).length > 0
        ) {
          /*  id of license assigned to this service */
          assignedLic =
            this.props.UiStore.templatingService.secrets[this.props.id] &&
            this.props.UiStore.templatingService.secrets[this.props.id][
              "previousSelectedLicense"
            ];
        }
        if (this.props.showUnassigned && this.props.showUnassigned === true) {
          if (val.services && val.services.length === 0) {
            /* show unassigned licenses only */
            list.push({
              name: val.name,
              id: val.id,
            });
          } else if (val.id === assignedLic) {
            list.push({
              name: val.name,
              id: val.id,
            });
          }
        } else {
          list.push({
            name: val.name,
            id: val.id,
          });
        }
      } else {
        /* for docker , ssh */
        list.push({
          name: val.name,
          id: val.id,
        });
      }
    });
    if (list.length <= 0) {
      list.push({
        name: getCurrentlocaleText("general.notfound.placeholder.text"),
        id: null,
      });
    }
    return ServiceTemplatesUtils.listDataForSelect(list);
  };

  componentWillUnmount() {
    // to enable button incase of error
    this.props.SecretViewModel.isSecretLoading = false;
  }

  UNSAFE_componentWillMount() {
    let listData =
      this.props.properties &&
      this.props.properties.options &&
      this.props.properties.options.type === "dockerconfig"
        ? toJS(this.props.SecretViewModel.secretsObj.dockerconfig)
        : toJS(this.props.SecretViewModel.secretsObj.volume);
    if (!listData || listData.length == 0) {
      let params = {
        size: 1,
        org_id: this.props.org_id,
        own: true,
        type:
          this.props.properties &&
          this.props.properties.options &&
          this.props.properties.options.type &&
          this.props.properties.options.type === "dockerconfig"
            ? "Dockerconfigjson"
            : "Opaque",
      };
      this.secretModel.isSecretLoading = true;
      SecretController.getSecrets(params, false)
        .then(resp => {
          this.updateSecretList(
            this.props.properties &&
              this.props.properties.options &&
              this.props.properties.options.type &&
              this.props.properties.options.type === "dockerconfig"
              ? this.secretModel.pageable.dockerconfig.total_count / 100
              : this.secretModel.pageable.volume.total_count / 100,
          );
        })
        .catch(err => {
          // to enable button incase of error
          this.props.SecretViewModel.isSecretLoading = false;
        });
    }
  }
  updateSecretList(totalCount) {
    let list = [];
    if (totalCount > 0) {
      for (let page = 0; page < totalCount; page++) {
        let params = {
          size: 100,
          org_id: this.props.org_id,
          own: true,
          page: page,
          type:
            this.props.properties &&
            this.props.properties.options &&
            this.props.properties.options.type &&
            this.props.properties.options.type === "dockerconfig"
              ? "Dockerconfigjson"
              : "Opaque",
        };
        this.props.SecretViewModel.isSecretLoading = true;
        SecretController.getSecrets(params, true)
          .then(resp => {
            this.props.SecretViewModel.isSecretLoading = false;
            resp.map((val, index) => {
              if (
                this.props.properties.options.type.toLowerCase() === "license"
              ) {
                let assignedLic;
                if (
                  this.props.UiStore.templatingService.secrets &&
                  Object.keys(this.props.UiStore.templatingService.secrets)
                    .length > 0
                ) {
                  /*  id of license assigned to this service */
                  assignedLic =
                    this.props.UiStore.templatingService.secrets[
                      this.props.id
                    ] &&
                    this.props.UiStore.templatingService.secrets[this.props.id][
                      "previousSelectedLicense"
                    ];
                }
                if (
                  this.props.showUnassigned &&
                  this.props.showUnassigned === true
                ) {
                  if (val.services && val.services.length === 0) {
                    /* show unassigned licenses only */
                    list.push({
                      name: val.name,
                      id: val.id,
                    });
                  } else if (val.id === assignedLic) {
                    list.push({
                      name: val.name,
                      id: val.id,
                    });
                  }
                } else {
                  list.push({
                    name: val.name,
                    id: val.id,
                  });
                }
              } else {
                /* for docker , ssh */
                list.push({
                  name: val.name,
                  id: val.id,
                });
              }
            });
            if (list.length <= 0) {
              list.push({
                name: getCurrentlocaleText("general.notfound.placeholder.text"),
                id: null,
              });
            }
            this.setState({ listData: list });
          })
          .catch(err => {
            // to enable button incase of error
            this.props.SecretViewModel.isSecretLoading = false;
          });
      }
    } else {
      this.props.SecretViewModel.isSecretLoading = false;
    }
  }
  selectedValue = (value, obj) => {};
  @computed
  get componentVisibility() {
    let visibility = null;
    if (
      this.props.UiStore.computedOutputObject &&
      this.props.UiStore.computedOutputObject.steps
    ) {
      if (this.props.properties.visibility) {
        var template = Twig.twig({
          data: this.props.properties.visibility,
        });
        let computedOutputObject = copyObject(
          this.props.UiStore.computedOutputObject,
        );
        visibility = template.render(computedOutputObject);
        try {
          visibility = JSON.parse(visibility);
        } catch {}
      }
    }
    return visibility ? true : false;
  }

  render() {
    const { getFieldDecorator } = this.props.referenceForm; // reference of base form
    let required =
      this.props.properties.constraints &&
      this.props.properties.constraints.required
        ? this.props.properties.constraints.required
        : false;

    let validationMessage =
      this.props.properties.constraints &&
      this.props.properties.constraints.validationMessage
        ? this.props.properties.constraints.validationMessage
        : `${this.props.properties.label} is required`;
    let secrets = copyObject(this.props.UiStore.templatingService.secrets);
    let defaultValue = this.props.properties.options.type
      ? secrets[this.props.id.trim()]
        ? secrets[this.props.id.trim()].previousSelectedLicense
        : []
      : this.props.defaultValue
      ? this.props.defaultValue
      : [];
    let listData =
      this.props.properties &&
      this.props.properties.options &&
      this.props.properties.options.type === "dockerconfig"
        ? toJS(this.props.SecretViewModel.secretsObj.dockerconfig)
        : toJS(this.props.SecretViewModel.secretsObj.volume);

    let visibility = this.props.properties.visibility
      ? this.componentVisibility
      : true;
    return visibility ? (
      <div>
        {listData.length >= 0 && (
          <FormItem label={this.props.properties.label}>
            {getFieldDecorator(`${this.props.id}`, {
              rules: [
                {
                  required: required,
                  message: validationMessage,
                },
              ],
              initialValue: defaultValue,
            })(
              <Select
                showSearch
                placeholder={this.props.properties.placeholder}
                mode={
                  this.props.properties.options
                    ? this.props.properties.options.mode
                    : null
                }
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                notFoundContent={getCurrentlocaleText(
                  "general.notfound.placeholder.text",
                )}
                onSelect={value => {
                  if (
                    this.props.properties.options.type &&
                    this.props.properties.options.type === "dockerconfig"
                  ) {
                    let selected = this.state.dockerConfigs;
                    if (
                      (!selected || selected.length === 0) &&
                      this.props.UiStore.templatingService.secrets[
                        this.props.id
                      ] &&
                      this.props.UiStore.templatingService.secrets[
                        this.props.id
                      ].previousSelectedLicense
                    ) {
                      for (let image in this.props.UiStore.templatingService
                        .secrets[this.props.id].previousSelectedLicense) {
                        selected.push(
                          this.props.UiStore.templatingService.secrets[
                            this.props.id
                          ].previousSelectedLicense[image],
                        );
                      }
                    }
                    if (!selected.includes(value)) {
                      selected.push(value);
                    }
                    this.props.properties.selectedValue
                      ? this.props.properties.selectedValue(selected)
                      : this.selectedValue(value);
                  } else {
                    this.props.properties.selectedValue
                      ? this.props.properties.selectedValue(value)
                      : this.selectedValue(value);
                  }
                }}
                onDeselect={value => {
                  if (
                    this.props.properties.options.type &&
                    this.props.properties.options.type === "dockerconfig"
                  ) {
                    let selected = this.state.dockerConfigs;

                    if (
                      (!selected || selected.length === 0) &&
                      this.props.UiStore.templatingService.secrets[
                        this.props.id
                      ] &&
                      this.props.UiStore.templatingService.secrets[
                        this.props.id
                      ].previousSelectedLicense
                    ) {
                      for (let image in this.props.UiStore.templatingService
                        .secrets[this.props.id].previousSelectedLicense) {
                        if (
                          value !=
                          this.props.UiStore.templatingService.secrets[
                            this.props.id
                          ].previousSelectedLicense[image]
                        ) {
                          selected.push(
                            this.props.UiStore.templatingService.secrets[
                              this.props.id
                            ].previousSelectedLicense[image],
                          );
                        }
                      }
                    }
                    let in_dx = selected.findIndex(x => {
                      return x === value;
                    });
                    if (in_dx !== -1) {
                      selected.splice(in_dx, 1);
                    }
                    this.props.properties.selectedValue
                      ? this.props.properties.selectedValue(selected)
                      : this.selectedValue(selected);
                  }
                }}
                disabled={
                  this.props.viewOnly === true ||
                  this.props.properties.disabled ||
                  listData.length < 0
                }
                onFocus={
                  this.props.onFocus &&
                  this.props.onFocus.bind(null, this.props.properties.help)
                }
              >
                {listData.length >= 0 && this.listData(listData)}
              </Select>,
            )}
          </FormItem>
        )}
      </div>
    ) : (
      <div></div>
    );
  }
}
