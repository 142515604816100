import React, { Component } from "react";
import {
  Row,
  Col,
  Icon,
  Radio,
  Switch,
  Collapse,
  DatePicker,
  Slider,
} from "antd";
import { Form } from "@ant-design/compatible";
import Input from "components/UI-Components/InputBox";
import moment from "moment";
import { observer, inject } from "mobx-react";
import PopOverHelp from "components/UI-Components/PopOverHelp";
import ProfileSelect from "components/UI-Components/ProfileSelect";
import SerialSelect from "components/UI-Components/SerialNumSelect";
import CertificateSelect from "components/UI-Components/CertificateSelect";
import LabelProvider from "components/UI-Components/LabelProvider";
import {
  focusFirstInput,
  isDevelopmentMode,
  humanizeMetric,
  getCurrentlocaleText,
  checkforFeatureEnabled,
  DEFAULT_STAT_MODE,
  getDefaultTimezone,
  isCidr,
  isTanCidrRange,
  getSamplingFreqMarks,
} from "Core/Utils";
import FormErrorMessage from "components/UI-Components/FormErrorMessage";
import ImageButton from "components/UI-Components/ImageButton";
import amazonEC2 from "../../assets/img/aws-logo.png";
import azure from "../../assets/img/azure-logo.png";
import vmware from "../../assets/img/vmware-logo.png";
import OpenStack from "../../assets/img/openstack-logo.svg";
import GCP from "../../assets/img/gcp-logo.png";
import OvaImage from "../../assets/img/ova.png";
import TarImage from "../../assets/img/tar_icon.png";
import DownloadImage from "../../assets/img/download.png";
import Icons from "components/UI-Components/Icons";
import ReleaseController from "controller/ReleaseController";
import GenerateLabelHelp from "components/UI-Components/GenerateLabelHelp";
import GenerateSSHKeyLinkHelp from "components/UI-Components/GenerateSSHKeyLinkHelp";
import DurationSelector from "components/UI-Components/DurationSelector";
import OrgController from "controller/OrgController";
import InterfaceTrafficSelector from "components/UI-Components/InterfaceTrafficSelector";
import LocationInput from "components/UI-Components/LocationInput";

const RadioButton = Radio.Button;
const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const Panel = Collapse.Panel;
const EXTENSION = "ova.bz2";
const GCP_EXTENSION = "tar";

@inject(
  "ModelStore",
  "UiStore",
  "ReleaseViewModel",
  "TimezoneViewModel",
  "AuthStore",
  "OrgViewModel",
)
@observer
class InodeForm extends Component {
  constructor(props) {
    super(props);
    this.releaseModel = this.props.ReleaseViewModel;
    this.orgModel = this.props.OrgViewModel;
    this.state = {
      Profiles: [],
      pki: [],
      showSerial: false,
      showSSHKeys: false,
      showDownload: false,
      downloadChkbox: false,
      showVirtualEdgeOnly: false,
      showOpenStack: false,
      downloadOptions: {
        ec2: "",
        azure: "",
        vmware: "",
        openstack: "",
        gcp: "",
      },
      vmdkFiles: [],
      tags: [],
      metadata: {},
      firewallLables: {},
      orgInitialVal: {},
      headLessMode: false,
      durationValue: {
        duration: isDevelopmentMode() ? 5 : 60,
        units: "MINUTES",
      },
      intefaceTraffic: "SLOW",
      statsMode: false,
      upgradeChannelSelected: "",
      samplingFrequencySelected: 50000,
      softwareUpgrade: false,
      NetworkVisibility: false,
      threatDetection: false,
      isRepresentationNetworkAutomation: false,
      cidr: "",
      tanDadMode: false,
      showCTD: true,
      tanDadDurationValue: {
        duration: 5,
        units: "MINUTES",
      },
      isMultiNICMode: false,
    };
    this.GLOBAL_SETTINGS = GLOBAL_SETTINGS;
    this.name =
      this.GLOBAL_SETTINGS && this.GLOBAL_SETTINGS.name
        ? this.GLOBAL_SETTINGS.name
        : "View";
  }

  componentDidMount() {
    /* Focus First Input */
    focusFirstInput();

    /* LAT-2770 Provide option to download yaml for openstack and gcp Virtual Edge */
    this.setState({ showOpenStack: isDevelopmentMode() });

    // Call latest release api
    if (!this.releaseModel.latestRelease) {
      ReleaseController.getLatestRelease();
    }

    this.getorgInfo();
  }
  /**
   * GET CURRENT ORG POLICY INFO
   */
  getorgInfo = () => {
    OrgController.getOrgById(this.props.orgId, true).then(response => {
      if (response) {
        this.setState({ orgInitialVal: response });
        if (response.policy.software_upgrade.channel) {
          this.props.form.setFields({
            upgrade_policy_channel: {
              value: response.policy.software_upgrade.channel,
            },
          });
          this.setState({
            upgradeChannelSelected: response.policy.software_upgrade.channel,
          });
          if (response.policy.software_upgrade.channel !== "OFF") {
            this.setState({ softwareUpgrade: true });
          }
        }
      }
    });
  };

  handleLabelChange = values => {
    this.props.form.setFields({
      metadata: {
        value: { labels: values },
      },
    });
  };

  setSelectVal = values => {
    this.setFieldValues(values);
    if (values.vpnServer && values.type === "profile_id") {
      if (values.profileName && values.profileName === "Virtual Edge") {
        this.setState({ showSSHKeys: true });
        this.setState({ showCTD: true });
        this.setState({ showVirtualEdgeOnly: true, isVirtual: false });
      } else if (values.profileName && values.profileName === "Virtual") {
        this.setState({ showSSHKeys: false });
        this.setState({ showCTD: false });
        this.setState({ showVirtualEdgeOnly: false, isVirtual: true });
      } else {
        this.setState({ showSSHKeys: false });
        this.setState({ showCTD: true });
        this.setState({ showVirtualEdgeOnly: false, isVirtual: false });
      }
      this.setState({ showSerial: false });
      this.setState({ showDownload: true });
    } else {
      this.setState({ showSerial: true });
      this.setState({ showCTD: true });
      this.setState({ showSSHKeys: true });
      this.setState({ showDownload: false });
      this.setState({ showVirtualEdgeOnly: false, isVirtual: false });
    }
  };

  setFieldValues = values => {
    let formVals = {};
    formVals[values.type] = {
      value: values.value,
    };
    this.props.form.setFields(formVals);
  };

  agreeToDwnld = () => {
    if (this.state.downloadChkbox === false) {
      this.setState({ downloadChkbox: true });
    } else {
      this.setState({ downloadChkbox: false });
    }
  };

  handleDownloadType = (value, e) => {
    if (value === "ec2") {
      this.setState({
        downloadOptions: {
          ec2: "download-active",
          azure: "",
          vmware: "",
          openstack: "",
          gcp: "",
        },
      });
    } else if (value === "azure") {
      this.setState({
        downloadOptions: {
          ec2: "",
          azure: "download-active",
          vmware: "",
          openstack: "",
          gcp: "",
        },
      });
    } else if (value === "vmware") {
      this.setState({
        downloadOptions: {
          ec2: "",
          azure: "",
          vmware: "download-active",
          openstack: "",
          gcp: "",
        },
      });
      this.latestRelease();
    } else if (value === "openstack") {
      this.setState({
        downloadOptions: {
          ec2: "",
          azure: "",
          vmware: "",
          openstack: "download-active",
          gcp: "",
        },
      });
    } else if (value === "gcp") {
      this.setState({
        downloadOptions: {
          ec2: "",
          azure: "",
          vmware: "",
          gcp: "download-active",
          openstack: "",
        },
      });
      this.gcpLatestRelease();
    }
    this.props.form.setFields({
      cloud_config: {
        value: {
          cloud_provider: value,
          download_type: value === "vmware" ? "ct" : "yaml",
        },
      },
    });
  };

  latestRelease = () => {
    if (
      this.releaseModel.latestRelease &&
      this.releaseModel.latestRelease.files
    ) {
      let files = this.releaseModel.latestRelease.files.filter(file => {
        if (file.name.toLowerCase().endsWith(EXTENSION.toLowerCase())) {
          return file;
        }
      });
      files = files.map((file, indx) => {
        return (
          <div key={file.url}>
            <div>
              <span>
                <div style={{ display: "inline-block" }}>
                  <img
                    src={OvaImage}
                    height="25"
                    width="25"
                    alt="File"
                    style={{ verticalAlign: "middle" }}
                    title={getCurrentlocaleText("ovafile.text")}
                  />
                </div>
              </span>
              <div style={{ display: "inline-block" }}>
                <span>
                  <span>&nbsp;&nbsp;Download OVA file</span>
                  <span>
                    {"  "}({humanizeMetric(file.size, true)})
                  </span>
                </span>
                <span
                  className="releases_download_icon"
                  onClick={this.downloadFile.bind(this, file.url)}
                  title="Download"
                >
                  <img
                    src={DownloadImage}
                    height="18"
                    width="18"
                    style={{ verticalAlign: "middle", marginLeft: "280px" }}
                    title={"Download"}
                  />
                </span>
              </div>
            </div>
          </div>
        );
      });
      this.setState({ vmdkFiles: files });
    }
  };
  /**
   * Method to download latest released VMDK/OVA file
   */
  downloadFile = url => {
    ReleaseController.downloadFile(url).then(resp => {
      if (resp && resp.download_url) {
        window.location = resp.download_url;
      }
    });
  };

  onHeadlessModeChange = value => {
    this.setState({
      headLessMode: value,
      durationValue: {
        duration: isDevelopmentMode() ? 5 : 60,
        units: "MINUTES",
      },
    });
  };

  onHeadlessDurationChange = value => {
    this.props.form.setFields({
      max_headless_time: {
        value: {
          duration: value.duration,
          units: value.units,
        },
      },
    });
    this.setState({ durationValue: value });
  };

  onStatsModeChange = value => {
    this.setState({
      statsMode: value,
      intefaceTraffic: value ? "SLOW" : DEFAULT_STAT_MODE,
    });
  };

  onTanDadModeChange = value => {
    this.setState({
      tanDadMode: value,
      tanDadDurationValue: {
        duration: 5,
        units: "MINUTES",
      },
    });
  };

  onTanDadModeDurationChange = value => {
    this.props.form.setFields({
      max_tandad_time: {
        value: {
          duration: value.duration,
          units: value.units,
        },
      },
    });
    this.setState({ tanDadDurationValue: value });
  };
  changeSoftwareUpdate = () => {
    this.setState({
      softwareUpgrade: !this.state.softwareUpgrade,
      upgradeChannelSelected: "STABLE",
      upgradeAfter: moment.tz(
        new Date().getTime(),
        this.props.TimezoneViewModel.userTimezone ||
          this.props.TimezoneViewModel.orgTimezone ||
          getDefaultTimezone().value,
      ),
    });
  };

  upgradeChannelOnSelect = e => {
    let value = e.target.value;
    this.setState({
      upgradeChannelSelected: value,
    });
  };

  samplingFrequencyOnSelect = value => {
    this.setState({
      samplingFrequencySelected: value,
    });
  };

  handleInterfaceTraffic = value => {
    this.props.form.setFields({
      stat_mode: {
        value: value,
      },
    });
    this.setState({ intefaceTraffic: value });
  };

  changeCtd = () => {
    this.setState({
      threatDetection: !this.state.threatDetection,
    });
  };

  /**
   * Updgrade After change event
   */
  onStartAfterChange = (date, dateString) => {
    this.setState({
      upgradeAfter: date,
    });
    this.props.form.setFields({
      upgrade_after: {
        value: date,
      },
    });
  };

  /**  Get cloud route */
  setCloudroute = value => {
    let currentOrgInfo = this.orgModel.org;
    if (
      currentOrgInfo.policy &&
      currentOrgInfo.policy.network &&
      currentOrgInfo.policy.network.representation_network_automation &&
      currentOrgInfo.policy.network.representation_network_automation.enable
    ) {
      this.setState({ cidr: null, isRepresentationNetworkAutomation: value });
    } else {
      message.error({
        content: getCurrentlocaleText(
          "inode.repNet.automation.policy.disable.error",
        ),
      });
    }
  };

  checkCidr = (rule, value, callback, key) => {
    let message = null;
    if (value == null) {
    } else {
      let isCidrValid = value && isCidr(value, false);
      if (!isCidrValid) {
        message = getCurrentlocaleText("network.form.cidr.errormessage2.text");
      } else {
        let isCidrIPInRange = isTanCidrRange(value, value);
        if (isCidrIPInRange) {
          message = getCurrentlocaleText(
            "network.form.cidr.errormessage4.text",
          );
        }
      }
    }
    if (message) {
      callback(message);
    } else {
      callback();
    }
  };

  disabledDate = current => {
    // Can not select days before today and today and after 90 days
    let timezone =
      this.props.TimezoneViewModel.userTimezone ||
      this.props.TimezoneViewModel.orgTimezone ||
      getDefaultTimezone().value;
    let endDate = moment.tz(timezone).add(90, "days");
    return (
      (current && current.valueOf() < moment.tz(timezone).valueOf()) ||
      (current && current.isAfter(endDate))
    );
  };

  gcpLatestRelease = () => {
    if (
      this.releaseModel.latestRelease &&
      this.releaseModel.latestRelease.files
    ) {
      let files = this.releaseModel.latestRelease.files.filter(file => {
        if (
          file &&
          file.name &&
          file.name.toLowerCase().endsWith(GCP_EXTENSION.toLowerCase())
        ) {
          return file;
        }
      });
      files = files.map((file, indx) => {
        return (
          <div key={file.url}>
            <div>
              <span>
                <div style={{ display: "inline-block" }}>
                  <img
                    src={TarImage}
                    height="20"
                    width="20"
                    alt="File"
                    style={{ verticalAlign: "middle" }}
                    title={getCurrentlocaleText("tarfile.text")}
                  />
                </div>
              </span>
              <div style={{ display: "inline-block" }}>
                <span>
                  <span>&nbsp;&nbsp;Download TAR file</span>
                  <span>
                    {"  "}({humanizeMetric(file.size, true)})
                  </span>
                </span>
                <span
                  className="releases_download_icon"
                  onClick={this.downloadFile.bind(this, file.url)}
                  title="Download"
                >
                  <div style={{ display: "inline-block" }}>
                    <img
                      src={DownloadImage}
                      height="18"
                      width="18"
                      style={{ verticalAlign: "middle", marginLeft: "280px" }}
                      title={"Download"}
                    />
                  </div>
                </span>
              </div>
            </div>
          </div>
        );
      });
      this.setState({ vmdkFiles: files });
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const WarningBox = props => (
      <p className="cert-warning-msg">{props.children}</p>
    );
    let isHeadlessModeVisible =
      (this.state.showSerial || this.state.showVirtualEdgeOnly) &&
      checkforFeatureEnabled(this.state.orgInitialVal, "headless_mode");

    let isAutoRepnetVisible = checkforFeatureEnabled(
      this.state.orgInitialVal,
      "autorepnet",
    );
    let isTanDadModeVisible =
      this.state.showSerial || this.state.showVirtualEdgeOnly;
    return (
      <Form noValidate onSubmit={this.props.onSubmit}>
        <FormItem label="iNode Name" hasFeedback>
          {getFieldDecorator("name", {
            rules: [
              {
                required: true,
                message: getCurrentlocaleText(
                  "inode.form.inode_name.required.help.text",
                ),
                whitespace: true,
              },
              {
                max: 255,
                message: getCurrentlocaleText(
                  "inode.name.length.validation.message",
                ),
              },
              {
                pattern: /^[A-Za-z0-9 .&,_-]*$/,
                message: getCurrentlocaleText(
                  "inode.name.regex.validation.message",
                ),
              },
            ],
          })(
            <Input
              className="firstInput"
              autoComplete="off"
              prefix={<Icons type="ai" name="AiOutlineFork" />}
              placeholder="iNode Name"
            />,
          )}
        </FormItem>
        <FormItem
          colon={false}
          label={
            <span
              className={"iot-label-not-required"}
              title={getCurrentlocaleText("general.form.label.text").replace(
                ":",
                " ",
              )}
            >
              {getCurrentlocaleText("general.form.label.text")}
              <PopOverHelp
                isHelpOnForm={true}
                height={285}
                width={475}
                placement={"rightTop"}
                helpData={{
                  title: getCurrentlocaleText(
                    "general.form.label.text",
                  ).replace(":", " "),
                  data: [
                    {
                      subtitle: "",
                      content: getCurrentlocaleText(
                        "general.form.label.field.help.text",
                      ),
                    },
                    {
                      subtitle: getCurrentlocaleText(
                        "general.form.label.field.help2.text",
                      ),
                      content: <GenerateLabelHelp />,
                    },
                  ],
                }}
              />
            </span>
          }
        >
          {getFieldDecorator(
            "metadata",
            {},
          )(
            <LabelProvider
              tags={this.state.tags}
              metadata={this.state.metadata}
              handleChange={this.handleLabelChange}
              isModal={true}
              showPopupLabel={true}
            />,
          )}
        </FormItem>
        <FormItem label="Profile" hasFeedback>
          {getFieldDecorator("profile_id", {
            rules: [{ required: true, message: "Select the profile" }],
          })(
            <ProfileSelect
              resource="profiles"
              setSelectVal={this.setSelectVal}
              params={{ org_id: this.props.orgId, own: true }}
              cache={false}
            />,
          )}
        </FormItem>
        {this.state.showSerial ? (
          <FormItem label="Serial Number" hasFeedback>
            {getFieldDecorator("hardware_serial_number", {
              rules: [
                {
                  required: true,
                  message: "Please select your serial number",
                },
              ],
            })(
              <SerialSelect
                setSelectVal={this.setSelectVal}
                params={{
                  org_id: this.props.orgId,
                  own: true,
                  assigned: false,
                }}
                resource="inodes"
                cache={false}
              />,
            )}
          </FormItem>
        ) : null}

        {this.state.showSSHKeys ? (
          <FormItem
            colon={false}
            label={
              <span
                className={"ssh-key"}
                title={getCurrentlocaleText("certificate.ssh_key.text")}
              >
                {getCurrentlocaleText("certificate.add.form.label.text")}
                <PopOverHelp
                  isHelpOnForm={true}
                  height={175}
                  width={475}
                  placement={"rightTop"}
                  helpData={{
                    title: getCurrentlocaleText(
                      "certificate.add.form.label.text",
                    ),
                    data: [
                      {
                        subtitle: "",
                        content: <GenerateSSHKeyLinkHelp />,
                      },
                    ],
                  }}
                />
              </span>
            }
            hasFeedback
          >
            {getFieldDecorator("ssh_key", {
              rules: [
                {
                  required: true,
                  message: getCurrentlocaleText(
                    "certificate.inode.add.required.text",
                  ),
                },
              ],
            })(
              <CertificateSelect
                setSelectVal={this.setFieldValues}
                params={{
                  org_id: this.props.orgId,
                  own: true,
                  assigned: false,
                }}
                resource="inodes"
                cache={false}
              />,
            )}
          </FormItem>
        ) : null}

        {this.state.showDownload ? (
          <div>
            <Row gutter={0} type="flex" justify="start">
              <Col>
                <small>
                  <WarningBox>
                    You have to download iNode security credentials which
                    includes the private key.
                  </WarningBox>
                </small>
              </Col>
            </Row>
            <Row gutter={0}>
              <Col>
                <WarningBox value={200}>
                  You will need to provide this file when you launch the iNode
                  instance.
                </WarningBox>
              </Col>
            </Row>
            <Row gutter={0}>
              <Col>
                <WarningBox value={200}>
                  Store it in a secure and accessible location. You will not be
                  able to download this file again.
                </WarningBox>
              </Col>
            </Row>
            <FormItem
              label="I Agree. Download credentials for:"
              style={{ marginTop: 15 }}
            >
              {getFieldDecorator("cloud_config", {
                rules: [
                  {
                    required: true,
                    message: !this.state.showVirtualEdgeOnly
                      ? "Please select your cloud / virtualization provider."
                      : "Please select your virtualization provider.",
                  },
                ],
              })(
                <RadioGroup
                  style={{
                    margin: "10px 10px",
                  }}
                >
                  <ImageButton
                    className={this.state.downloadOptions[`ec2`]}
                    onClick={this.handleDownloadType}
                    value="ec2"
                    src={amazonEC2}
                    imgStyle={{
                      height: 45,
                      paddingTop: 10,
                      width: 90,
                    }}
                  />

                  {
                    <ImageButton
                      className={this.state.downloadOptions[`azure`]}
                      onClick={this.handleDownloadType}
                      value="azure"
                      src={azure}
                      imgStyle={{ height: 36, width: 90 }}
                    />
                  }

                  <ImageButton
                    className={this.state.downloadOptions[`vmware`]}
                    onClick={this.handleDownloadType}
                    value="vmware"
                    src={vmware}
                    imgStyle={{ height: 36, width: 90 }}
                  />

                  {this.state.showVirtualEdgeOnly &&
                    this.state.showOpenStack && (
                      <ImageButton
                        className={this.state.downloadOptions[`openstack`]}
                        onClick={this.handleDownloadType}
                        value="openstack"
                        src={OpenStack}
                        imgStyle={{ height: 14, width: 90 }}
                        isSvg={true}
                      />
                    )}
                  {
                    <ImageButton
                      className={this.state.downloadOptions[`gcp`]}
                      onClick={this.handleDownloadType}
                      value="gcp"
                      imgStyle={{ height: 20, width: 90 }}
                      src={GCP}
                    />
                  }
                </RadioGroup>,
              )}
            </FormItem>
            {((this.state.downloadOptions.vmware &&
              this.state.downloadOptions.vmware.length > 0) ||
              (this.state.downloadOptions.gcp &&
                this.state.downloadOptions.gcp.length > 0)) &&
              this.releaseModel.latestRelease &&
              this.state.vmdkFiles.length > 0 && (
                <FormItem>
                  <div>{this.state.vmdkFiles}</div>
                </FormItem>
              )}
          </div>
        ) : null}
        {/* LOCATION INFO */}
        <LocationInput {...this.props} />
        <Collapse
          className="node-advanced-settings-collapse"
          style={{ marginBottom: 10 }}
        >
          <Panel
            header={
              <div className="panel-header-text" style={{ fontSize: 14 }}>
                {getCurrentlocaleText("advanced.settings.text")}
              </div>
            }
            key="1"
          >
            <div>
              {/* THIS WILL RENDER ONLY FOR NON VIRTUAL PROFILES*/}
              {isHeadlessModeVisible && (
                <Row>
                  <Col span={8}>
                    <FormItem
                      colon={false}
                      label={
                        <span>
                          {getCurrentlocaleText(
                            "inode.headless_mode.title.text",
                          ) + " :"}{" "}
                          <PopOverHelp
                            isHelpOnForm={true}
                            height={270}
                            width={300}
                            placement="right"
                            helpData={{
                              title: getCurrentlocaleText(
                                "org.form.headless.mode.label.text",
                              ),
                              data: [
                                {
                                  subtitle: "",
                                  content: getCurrentlocaleText(
                                    "headless.help.message",
                                    {
                                      0: this.name,
                                    },
                                  ),
                                },
                                {
                                  subtitle: "",
                                  content: getCurrentlocaleText(
                                    "headless.help.message3",
                                    { 0: this.name },
                                  ),
                                },
                              ],
                            }}
                          />
                        </span>
                      }
                    >
                      {getFieldDecorator("headeless_mode", {
                        initialValue: this.state.headLessMode,
                      })(
                        <span>
                          <Switch
                            style={{ marginTop: -5 }}
                            checkedChildren="Active"
                            unCheckedChildren="Not Active"
                            onChange={this.onHeadlessModeChange}
                            checked={this.state.headLessMode}
                            disabled={
                              this.props.action === "view" ? true : false
                            }
                          />
                        </span>,
                      )}
                    </FormItem>
                  </Col>
                  <Col span={16}>
                    {this.state.headLessMode && (
                      <FormItem
                        label={getCurrentlocaleText(
                          "inode.headless_duration.title.text",
                        )}
                      >
                        {getFieldDecorator("max_headless_time", {
                          initialValue: this.state.durationValue,
                        })(
                          <DurationSelector
                            onChange={this.onHeadlessDurationChange}
                            minutesDuration={isDevelopmentMode() ? 5 : 60}
                            minutesMinimum={isDevelopmentMode() ? 5 : 60}
                            minutesMaximum={60}
                            minutesStep={isDevelopmentMode() ? 5 : 0}
                            hourDuration={1}
                            hourMinimum={1}
                            hourMaximum={23}
                            hourStep={1}
                            dayDuration={1}
                            dayMinimum={1}
                            dayMaximum={365}
                            dayStep={1}
                            disabled={
                              this.props.action === "view" ? true : false
                            }
                            Val={this.state.durationValue}
                          />,
                        )}
                      </FormItem>
                    )}
                  </Col>
                </Row>
              )}
              <Row>
                <Col span={8}>
                  <FormItem
                    colon={false}
                    label={
                      <span>
                        {getCurrentlocaleText("inode.metered.connection.text") +
                          " :"}{" "}
                        <PopOverHelp
                          isHelpOnForm={true}
                          height={160}
                          width={300}
                          placement={"rightTop"}
                          helpData={{
                            title: getCurrentlocaleText(
                              "inode.metered.connection.text",
                            ),
                            data: [
                              {
                                subtitle: "",
                                content: getCurrentlocaleText(
                                  "inode.metered.connection.help.text2",
                                ),
                              },
                            ],
                          }}
                        />
                      </span>
                    }
                  >
                    {getFieldDecorator("interface_mode", {
                      initialValue: this.state.statsMode,
                    })(
                      <span>
                        <Switch
                          style={{ marginTop: -5 }}
                          checkedChildren="On"
                          unCheckedChildren="Off"
                          onChange={this.onStatsModeChange}
                          checked={this.state.statsMode}
                          disabled={this.props.action === "view" ? true : false}
                        />
                      </span>,
                    )}
                  </FormItem>
                </Col>
                <Col span={16}>
                  {this.state.statsMode && (
                    <FormItem
                      label={getCurrentlocaleText(
                        "inode.metered.connection.metrics.text",
                      )}
                    >
                      {getFieldDecorator("stat_mode", {
                        initialValue: this.state.intefaceTraffic,
                      })(
                        <InterfaceTrafficSelector
                          val={this.state.intefaceTraffic}
                          onChange={this.handleInterfaceTraffic}
                          disabled={this.props.action === "view" ? true : false}
                        />,
                      )}
                    </FormItem>
                  )}
                </Col>
              </Row>

              {/* THIS WILL RENDER ONLY FOR NON VIRTUAL PROFILES*/}
              {isTanDadModeVisible && (
                <Row>
                  <Col span={12}>
                    <FormItem
                      colon={false}
                      label={
                        <span>
                          {getCurrentlocaleText(
                            "inode.tan_dad_mode.label.text",
                          ) + " :"}{" "}
                          <PopOverHelp
                            isHelpOnForm={true}
                            height={180}
                            width={170}
                            placement="right"
                            helpData={{
                              title: getCurrentlocaleText(
                                "inode.tan_dad_mode.help.text",
                              ),
                              data: [
                                {
                                  subtitle: "",
                                  content: getCurrentlocaleText(
                                    "inode.tan_dad.help.message",
                                  ),
                                },
                              ],
                            }}
                          />
                        </span>
                      }
                    >
                      {getFieldDecorator("tan_dad_mode", {
                        initialValue: this.state.tanDadMode,
                      })(
                        <span>
                          <Switch
                            style={{ marginTop: -5 }}
                            checkedChildren="On"
                            unCheckedChildren="Off"
                            onChange={this.onTanDadModeChange}
                            checked={this.state.tanDadMode}
                          />
                        </span>,
                      )}
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    {this.state.tanDadMode && (
                      <FormItem
                        label={getCurrentlocaleText("inode.tan_dad.title.text")}
                      >
                        {getFieldDecorator("max_tandad_time", {
                          initialValue: this.state.tanDadDurationValue,
                        })(
                          <DurationSelector
                            onChange={this.onTanDadModeDurationChange}
                            minutesDuration={5}
                            minutesMinimum={5}
                            minutesMaximum={1440}
                            minutesStep={1}
                            hourDuration={1}
                            hourMinimum={1}
                            hourMaximum={23}
                            hourStep={1}
                            dayDuration={1}
                            dayMinimum={1}
                            dayMaximum={1}
                            dayStep={1}
                            Val={this.state.tanDadDurationValue}
                          />,
                        )}
                      </FormItem>
                    )}
                  </Col>
                </Row>
              )}
              {this.props.AuthStore.IsPermitted("ORG:SUPPORT") && (
                <Row>
                  <Col span={24}>
                    <Row>
                      <Col span={8}>
                        <FormItem
                          label={getCurrentlocaleText(
                            "node.software.upgrade.text",
                          )}
                        >
                          {getFieldDecorator("upgrade_policy", {
                            initialValue: this.state.softwareUpgrade,
                          })(
                            <Switch
                              checked={this.state.softwareUpgrade}
                              style={{ marginTop: -5 }}
                              onChange={this.changeSoftwareUpdate}
                              checkedChildren="On"
                              unCheckedChildren="Off"
                            />,
                          )}
                        </FormItem>
                      </Col>
                      <Col span={16}>
                        {this.state.softwareUpgrade && (
                          <FormItem
                            label={getCurrentlocaleText(
                              "org.form.upgrade.channel.label.text",
                            )}
                            colon={true}
                          >
                            {getFieldDecorator("upgrade_policy_channel", {
                              initialValue: this.state.upgradeChannelSelected,
                            })(
                              <RadioGroup
                                onChange={this.upgradeChannelOnSelect}
                                size="small"
                              >
                                <RadioButton value={"ALPHA"}>
                                  {getCurrentlocaleText(
                                    "org.form.upgrade.channel.alpha.label.text",
                                  )}
                                </RadioButton>
                                <RadioButton value={"BETA"}>
                                  {getCurrentlocaleText(
                                    "org.form.upgrade.channel.beta.label.text",
                                  )}
                                </RadioButton>
                                <RadioButton value={"STABLE"}>
                                  {getCurrentlocaleText(
                                    "org.form.upgrade.channel.stable.label.text",
                                  )}
                                </RadioButton>
                              </RadioGroup>,
                            )}
                          </FormItem>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
              {this.state.softwareUpgrade &&
                this.props.AuthStore.IsPermitted("ORG:SUPPORT") && (
                  <div>
                    <Row>
                      <Col span={24}>
                        <FormItem
                          label={getCurrentlocaleText(
                            "inode.upgrade.after.label.text",
                          )}
                        >
                          {getFieldDecorator("upgrade_after", {
                            initialValue: moment.tz(
                              this.props.TimezoneViewModel.userTimezone ||
                                this.props.TimezoneViewModel.orgTimezone ||
                                getDefaultTimezone().value,
                            ),
                          })(
                            <DatePicker
                              disabledDate={this.disabledDate}
                              showTime={{
                                defaultValue: moment(),
                              }}
                              style={{ width: 250 }}
                              format="YYYY-MM-DD HH:mm:ss"
                              placeholder="Upgrade After"
                              onChange={this.onStartAfterChange}
                              disabled={
                                this.props.action === "view" ? true : false
                              }
                            />,
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  </div>
                )}

              {this.state.showCTD && (
                <>
                  <Row>
                    <Col span={24}>
                      <FormItem
                        colon={false}
                        label={
                          <span>
                            {getCurrentlocaleText("threat.detection.text")}
                            <PopOverHelp
                              isHelpOnForm={true}
                              height={50}
                              width={230}
                              placement={"right"}
                              helpData={{
                                data: [
                                  {
                                    subtitle: "",
                                    content: getCurrentlocaleText(
                                      "threat.detection.text.desc",
                                    ),
                                  },
                                ],
                              }}
                            />
                          </span>
                        }
                      >
                        {getFieldDecorator("ctd_threat_detection", {
                          initialValue: this.state.threatDetection,
                        })(
                          <Switch
                            checked={this.state.threatDetection}
                            style={{ marginTop: -5 }}
                            onChange={this.changeCtd}
                            checkedChildren="On"
                            unCheckedChildren="Off"
                          />,
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                  {this.state.threatDetection && (
                    <Row>
                      <Col span={24}>
                        <FormItem label="Sampling Frequency" colon={true}>
                          {getFieldDecorator("ctd_sampling_frequency", {
                            rules: [
                              {
                                required: true,
                                message: getCurrentlocaleText(
                                  "threat.detection.sampling.message",
                                ),
                              },
                            ],
                            initialValue: this.state.samplingFrequencySelected,
                          })(
                            <>
                              <Slider
                                min={5000}
                                max={100000}
                                step={5000}
                                tooltipVisible={false}
                                marks={getSamplingFreqMarks()}
                                defaultValue={
                                  this.state.samplingFrequencySelected
                                }
                                onChange={this.samplingFrequencyOnSelect}
                              />
                              <div className="">
                                One sample will be processed for every{" "}
                                <b className="px-5">
                                  {this.state.samplingFrequencySelected}
                                </b>{" "}
                                packets.
                              </div>
                              <div
                                className="text-muted"
                                style={{ lineHeight: "10px" }}
                              >
                                <small>
                                  {getCurrentlocaleText(
                                    "threat.detection.sampling.note",
                                  )}
                                </small>
                              </div>
                            </>,
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  )}
                </>
              )}
              {/** Config multinic mode */}
              {this.state.showSerial && (
                <Row>
                  <Col span={24}>
                    <FormItem
                      label={
                        <span
                          title={getCurrentlocaleText("multinic.mode.text")}
                        >
                          {getCurrentlocaleText("multinic.mode.text")}
                          <PopOverHelp
                            isHelpOnForm={true}
                            height={120}
                            width={260}
                            placement="right"
                            helpData={{
                              title: getCurrentlocaleText("multinic.mode.text"),
                              data: [
                                {
                                  subtitle: "",
                                  content: getCurrentlocaleText(
                                    "multinic.mode.help.text",
                                  ),
                                },
                              ],
                            }}
                          />
                        </span>
                      }
                    >
                      {getFieldDecorator("is_multinic_mode", {
                        initialValue: this.state.isMultiNICMode,
                      })(
                        <Switch
                          checked={this.state.isMultiNICMode}
                          onChange={checked => {
                            this.setState({ isMultiNICMode: checked });
                          }}
                          checkedChildren="On"
                          unCheckedChildren="Off"
                          disabled={true}
                        />,
                      )}
                    </FormItem>
                  </Col>
                </Row>
              )}
              {/**configure and unconfigure representation network automation */}
              {isAutoRepnetVisible &&
                this.props.AuthStore.IsPermitted("SUPER:ADMIN") &&
                this.state.isVirtual && (
                  <Row>
                    <Col span={24}>
                      <Row>
                        <Col span={24}>
                          <Row>
                            <Col span={24}>
                              <FormItem
                                label={
                                  <span
                                    title={getCurrentlocaleText(
                                      "inode.cloud.route.repNet.automation.field.text",
                                    )}
                                  >
                                    {getCurrentlocaleText(
                                      "inode.cloud.route.repNet.automation.field.text",
                                    )}
                                    <PopOverHelp
                                      isHelpOnForm={true}
                                      height={160}
                                      width={260}
                                      placement="right"
                                      helpData={{
                                        title: getCurrentlocaleText(
                                          "inode.cloud.route.repNet.automation.field.text",
                                        ),
                                        data: [
                                          {
                                            subtitle: "",
                                            content: getCurrentlocaleText(
                                              "inode.cloud.route.field.help.text",
                                            ),
                                          },
                                        ],
                                      }}
                                    />
                                  </span>
                                }
                              >
                                {getFieldDecorator(
                                  "is_representation_network_automation",
                                  {
                                    initialValue: this.state
                                      .isRepresentationNetworkAutomation,
                                  },
                                )(
                                  <Switch
                                    checked={
                                      this.state
                                        .isRepresentationNetworkAutomation
                                    }
                                    onChange={this.setCloudroute}
                                    checkedChildren="On"
                                    unCheckedChildren="Off"
                                  />,
                                )}
                              </FormItem>
                            </Col>
                            {this.state.isRepresentationNetworkAutomation && (
                              <Col span={24}>
                                <FormItem
                                  {...{
                                    labelCol: { span: 8 },
                                    wrapperCol: { span: 12 },
                                  }}
                                  label={
                                    <span
                                      title={getCurrentlocaleText(
                                        "inode.cloud.route.cidr.field.text",
                                      )}
                                    >
                                      {getCurrentlocaleText(
                                        "inode.cloud.route.cidr.field.text",
                                      )}
                                    </span>
                                  }
                                >
                                  {getFieldDecorator("cidr", {
                                    rules: [
                                      {
                                        required: true,
                                        message: "Please enter CIDR.",
                                      },
                                      {
                                        validator: this.checkCidr,
                                      },
                                    ],
                                    initialValue: this.state.cidr,
                                  })(
                                    <Input
                                      prefix={
                                        <Icons
                                          type="ai"
                                          name="AiOutlineCalculator"
                                        />
                                      }
                                      placeholder={getCurrentlocaleText(
                                        "inode.cloud.route.cidr.placeholder.text",
                                      )}
                                    />,
                                  )}
                                </FormItem>
                              </Col>
                            )}
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
            </div>
          </Panel>
        </Collapse>
        <FormErrorMessage />
      </Form>
    );
  }
}

const InodesForm = Form.create()(InodeForm);
export default InodesForm;
