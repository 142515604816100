import React, { Component } from "react";
import { Button } from "antd";
import { Form } from "@ant-design/compatible";
import Input from "../UI-Components/InputBox";
import { observer, inject } from "mobx-react";
import { focusFirstInput, getCurrentlocaleText } from "Core/Utils";
import ReCAPTCHA from "react-google-recaptcha";
import Icons from "../UI-Components/Icons";

const FormItem = Form.Item;

@inject("UiStore")
@observer
class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    // ENV_CONFIG is generate form the node server dynamically
    this.EnvConfig = ENV_CONFIG;
    this.recaptchaRef = null;
  }

  componentDidMount() {
    /* Focus First Input */
    focusFirstInput();
    this.props.UiStore.setDocumentTitle(
      getCurrentlocaleText("user.forgot_password_form.title"),
    );
  }

  RecoverPassword = e => {
    e.preventDefault();
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      /* To avoid double click on submit */
      this.props.form.validateFields((err, loginData) => {
        if (!err) {
          this.props.Recover(loginData, this.recaptchaRef);
        }
      });
    }, 500);
  };

  handleCaptchaChange = value => {
    this.props.form.setFields({
      captcha: {
        value: value,
      },
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div style={{ margin: 15 }}>
        <Form onSubmit={this.RecoverPassword} noValidate className="login-form">
          <FormItem>
            {getFieldDecorator("username", {
              rules: [
                {
                  required: true,
                  message: getCurrentlocaleText(
                    "general.login.form.field.email.placeholder",
                  ),
                },
                {
                  type: "email",
                  message: getCurrentlocaleText(
                    "support.email_address_validation.text",
                  ),
                },
              ],
            })(
              <Input
                className="firstInput"
                prefix={
                  <Icons
                    type="ai"
                    name="AiOutlineUser"
                    style={{ fontSize: 13 }}
                  />
                }
                placeholder={getCurrentlocaleText(
                  "general.login.form.field.email.text",
                )}
              />,
            )}
          </FormItem>
          <FormItem>
            {" "}
            {getFieldDecorator("captcha", {
              rules: [
                {
                  required: true,
                  message: getCurrentlocaleText("captcha.error.message"),
                },
              ],
            })(
              <ReCAPTCHA
                tabindex={1}
                ref={c => (this.recaptchaRef = c)}
                sitekey={
                  this.EnvConfig && this.EnvConfig.RECAPTCHA_CLIENT_SITE_KEY
                }
                onChange={this.handleCaptchaChange}
              />,
            )}
          </FormItem>
          <FormItem>
            <Button type="primary" htmlType="submit" className="login-btn">
              {getCurrentlocaleText("support.submit.text")}
            </Button>
          </FormItem>
        </Form>
      </div>
    );
  }
}

const ForgotPasswordForm = Form.create()(ForgotPassword);
export default ForgotPasswordForm;
