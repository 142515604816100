import React, { Component } from "react";
import { milliToDateString, getCurrentlocaleText } from "Core/Utils";
import TableLayout from "components/layout/TableLayout";

class SerialNumberHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    this.columns = [
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        render: (text, record, index) => {
          return (
            <div>
              {getCurrentlocaleText("inode.rma.history.description.text", {
                0: record.old_hardware_serial_number,
                1: record.hardware_serial_number,
              })}
            </div>
          );
        },
      },
      {
        title: "Updated By",
        dataIndex: "user_info",
        key: "user_info",
        render: (text, record, index) => {
          return (
            <div>
              {record.updated_by && record.updated_by.name
                ? record.updated_by.name
                : "NA"}
            </div>
          );
        },
      },
      {
        title: "Updated At",
        dataIndex: "user_info",
        key: "user_info",
        render: (text, record, index) => {
          return <div>{milliToDateString(record.updated_at)}</div>;
        },
      },
    ];
    return (
      <div className="inode-serial-number-history">
        <TableLayout
          columns={this.columns}
          dataSource={this.props.hsn_history}
          itemPerPage={5}
          className="nodeList"
          hideRowSelection={true}
          hasLazyLoading={false}
          hideExpandedRow={true}
        />
      </div>
    );
  }
}

export default SerialNumberHistory;
