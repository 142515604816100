import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getCurrentlocaleText } from "Core/Utils";
class GenerateSSHKeyLinkHelp extends Component {
  render() {
    return (
      <div>
        <p>
          <strong>
            {getCurrentlocaleText("certificate.add.form.help.text.1")}
          </strong>
        </p>
        <div style={{ textIndent: "15px" }}>
          <p>
            1. {getCurrentlocaleText("certificate.add.form.help.text.3.1")}
            <strong>
              {getCurrentlocaleText("certificate.ssh-keygen.text")}
            </strong>
            {getCurrentlocaleText("certificate.add.form.help.text.3.2")}
          </p>
          <p>2. {getCurrentlocaleText("certificate.add.form.help.text.4")}</p>
        </div>
        <br />
        <p>
          {getCurrentlocaleText("certificate.add.form.help.text.5.1")}
          <Link to="/help/kb">
            {getCurrentlocaleText("certificate.add.form.help.text.5.2")}
          </Link>
          .
        </p>
      </div>
    );
  }
}
export default GenerateSSHKeyLinkHelp;
