import { getCurrentlocaleText } from "../../Core/Utils";

class InterfaceContainer {
  getLabelName = current_string => {
    switch (current_string && current_string.toLowerCase()) {
      case "wired":
        return getCurrentlocaleText("inode.interface.ethernet.label.text");
      case "wireless":
      case "wlan":
        return getCurrentlocaleText("inode.interface.wireless.label.text");
      case "wwan":
      case "ppp":
        return getCurrentlocaleText("inode.interface.cellular.label.text");
      case "vlan":
        return getCurrentlocaleText("inode.interface.vlan.label.text");
      case "lag":
      case "bond":
        return getCurrentlocaleText("inode.interface.lag.label.text");
      case "name":
        return getCurrentlocaleText("inode.interface.name.label.text");
      case "ssid":
        return getCurrentlocaleText("inode.interface.ssid.label.text");
      case "operator_name":
        return getCurrentlocaleText("inode.interface.operator_name.label.text");
      case "imsi":
        return getCurrentlocaleText("inode.interface.imsi.label.text");
      case "iccid":
        return getCurrentlocaleText("inode.interface.iccid.label.text");
      case "wlan_mode":
        return getCurrentlocaleText("inode.interface.wlan_mode.label.text");
      case "ip_address":
        return getCurrentlocaleText("inode.interface.ipv4.label.text");
      case "mtu":
        return getCurrentlocaleText("inode.interface.mtu.label.text");
      case "speed":
        return getCurrentlocaleText("inode.interface.speed.label.text");
      case "uart":
        return getCurrentlocaleText("inode.interface.uart.label.text");
      case "flags":
        return getCurrentlocaleText("inode.interface.flags.label.text");
      case "irq":
        return getCurrentlocaleText("inode.interface.irq.label.text");
      case "serial":
        return getCurrentlocaleText("inode.interface.serial.label.text");
      case "mac_address":
        return getCurrentlocaleText("inode.interface.mac_address.label.text");
      case "port_address":
        return getCurrentlocaleText("firewall.list.table.header.port.text");
      case "band":
        return getCurrentlocaleText("inode.interface.band.label.text");
      case "apn":
        return getCurrentlocaleText("inode.interface.apn.label.text");
      case "last_connected_time":
        return getCurrentlocaleText(
          "inode.interface.last_connected_time.label.text",
        );
      case "frequency":
        return getCurrentlocaleText("inode.interface.frequency.label.text");
      case "signalstrength":
        return getCurrentlocaleText(
          "inode.interface.signal.quality.label.text",
        );
      case "wan_vlan_id":
        return getCurrentlocaleText("inode.interface.wvlan.label.text");
      default:
        return getCurrentlocaleText("inode.interface.ethernet.label.text");
    }
  };
}

export default new InterfaceContainer();
