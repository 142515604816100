import React from "react";
import { Tag, Popover } from "antd";
import PopOverHelp from "../../components/UI-Components/PopOverHelp";
import RouteInfo from "../../components/INodes/RouteInfo";
import Icons from "../../components/UI-Components/Icons";
import { getCurrentlocaleText } from "../../Core/Utils";

const iconStyle = { fontSize: "small" };

class ConnectedNodesContainer {
  static NOT_APPLICAPLE = (<p style={{ marginLeft: 60 }}>{"-"}</p>);
  templateConnectedNodesAsTag(
    connectedNetworks,
    networkId,
    showRepNetwork,
    tanCIDR,
    isVirtualNode,
    canShowPopOverHelp,
    OnClick = {},
    cursor,
    showPeers,
  ) {
    if (!showPeers) connectedNetworks = [];
    let repNetwork = connectedNetworks.map(c => {
      return (
        <span
          key={
            (c.node && c.node.id + networkId ? networkId : "NA") +
            Math.random().toString(36)
          }
          className={
            showRepNetwork
              ? "rep-network-view"
              : "rep-network-view rep-network-form"
          }
        >
          {"Representational Network:"}
          {connectedNetworks[0].representation_network
            ? connectedNetworks[0].representation_network.peer
            : null}
        </span>
      );
    });
    let content = (
      <div className="connected-nodes">
        {connectedNetworks.map(c => {
          // var uniqueId = Math.random().toString(36).substr(2, 16);
          return (
            <span key={Math.random().toString(36)}>
              {showRepNetwork &&
              c.representation_network &&
              c.representation_network.peer ? (
                <Popover
                  key={
                    c.node
                      ? c.node.id
                      : c.cluster && c.cluster.id + networkId
                      ? networkId + Math.random().toString(36)
                      : "NA"
                  }
                  content={
                    <span
                      key={c.node ? c.node.id : c.cluster && c.cluster.id}
                      className="rep-network-view"
                    >
                      {"Representational Network:"}
                      {c.representation_network
                        ? c.representation_network.peer
                        : null}
                    </span>
                  }
                  trigger="hover"
                >
                  <Tag
                    key={
                      c.node
                        ? c.node.id
                        : c.cluster && c.cluster.id + networkId
                        ? networkId
                        : "NA"
                    }
                    color=""
                    style={{
                      cursor: cursor ? cursor : "default",
                    }}
                    className="rep-connected-network"
                  >
                    <Icons
                      type={c.cluster && c.cluster.id ? "bs" : "fa"}
                      name={
                        c.cluster && c.cluster.id ? "BsBoundingBox" : "FaRegHdd"
                      }
                      className="valign-text-bottom iot-tag-label"
                      style={iconStyle}
                    />
                    {c.node ? c.node.name : c.cluster && c.cluster.name}/
                    <Icons
                      type="fa"
                      name="FaConnectdevelop"
                      className="valign-text-bottom iot-tag-label"
                      style={iconStyle}
                    />
                    {c.network.name}
                  </Tag>
                </Popover>
              ) : (
                <span
                  key={
                    c.node
                      ? c.node.id
                      : c.cluster && c.cluster.id + networkId
                      ? networkId
                      : "NA"
                  }
                >
                  <Tag
                    key={
                      c.node
                        ? c.node.id
                        : c.cluster && c.cluster.id + networkId
                        ? networkId
                        : "NA"
                    }
                    color=""
                    style={{
                      cursor: cursor ? cursor : "default",
                    }}
                    className={
                      connectedNetworks.length === 1 && "connected-inode"
                    }
                    title={c.network ? c.network.name : ""}
                  >
                    <Icons
                      type={c.cluster && c.cluster.id ? "bs" : "fa"}
                      name={
                        c.cluster && c.cluster.id ? "BsBoundingBox" : "FaRegHdd"
                      }
                      className="valign-text-bottom iot-tag-label"
                      style={iconStyle}
                    />
                    {c.node ? c.node.name : c.cluster && c.cluster.name}
                    <Icons
                      type="fa"
                      name="FaConnectdevelop"
                      className="valign-text-bottom iot-tag-label"
                      style={iconStyle}
                    />
                    {c.network ? c.network.name : ""}
                  </Tag>
                </span>
              )}
            </span>
          );
        })}
      </div>
    );

    if (connectedNetworks.length > 1) {
      return (
        <Popover
          content={content}
          title={getCurrentlocaleText(
            "node.list.table.node_connected_network.label.text",
          )}
          trigger="hover"
        >
          <span
            className="inodes-badge"
            style={{
              cursor: cursor ? cursor : "default",
            }}
          >
            {connectedNetworks.length} Networks
          </span>
        </Popover>
      );
    } else {
      if (connectedNetworks[0]) {
        return (
          <div>
            <span>
              {showRepNetwork &&
              connectedNetworks[0].representation_network &&
              connectedNetworks[0].representation_network.peer ? (
                <div>
                  {" "}
                  <Popover content={repNetwork} trigger="hover">
                    <Tag
                      key={
                        connectedNetworks[0].node
                          ? connectedNetworks[0].node.id
                          : connectedNetworks[0].cluster &&
                            connectedNetworks[0].cluster.id + networkId
                          ? networkId
                          : "-"
                      }
                      color=""
                      style={{
                        cursor: cursor ? cursor : "default",
                      }}
                      className="rep-connected-network"
                    >
                      <Icons
                        type={
                          connectedNetworks[0].cluster &&
                          connectedNetworks[0].cluster.id
                            ? "bs"
                            : "fa"
                        }
                        name={
                          connectedNetworks[0].cluster &&
                          connectedNetworks[0].cluster.id
                            ? "BsBoundingBox"
                            : "FaRegHdd"
                        }
                        className="valign-text-bottom iot-tag-label"
                        style={iconStyle}
                      />
                      {connectedNetworks[0].node
                        ? connectedNetworks[0].node.name
                        : connectedNetworks[0].cluster &&
                          connectedNetworks[0].cluster.name}
                      /
                      <Icons
                        type="fa"
                        name="FaConnectdevelop"
                        className="valign-text-bottom iot-tag-label"
                        style={iconStyle}
                      />
                      {connectedNetworks[0].network.name}
                    </Tag>
                  </Popover>
                  {!isVirtualNode ? (
                    canShowPopOverHelp ? (
                      <PopOverHelp
                        helpData={{
                          title: getCurrentlocaleText(
                            "network.routeinfo.help.title",
                          ),
                          data: [
                            {
                              subtitle: "",
                              content: (
                                <RouteInfo
                                  tanCIDR={tanCIDR}
                                  ViNodeId={
                                    connectedNetworks[0].node
                                      ? connectedNetworks[0].node.id
                                      : connectedNetworks[0].cluster &&
                                        connectedNetworks[0].cluster.id
                                  }
                                />
                              ),
                            },
                          ],
                        }}
                      />
                    ) : null
                  ) : null}
                </div>
              ) : (
                <span>
                  <Tag
                    key={
                      connectedNetworks[0].node
                        ? connectedNetworks[0].node.id
                        : connectedNetworks[0].cluster &&
                          connectedNetworks[0].cluster.id + networkId
                        ? networkId
                        : "NA"
                    }
                    color=""
                    style={{
                      cursor: cursor ? cursor : "default",
                    }}
                    title={
                      connectedNetworks[0].network
                        ? connectedNetworks[0].network.name
                        : ""
                    }
                    className={
                      !canShowPopOverHelp &&
                      connectedNetworks.length === 1 &&
                      "connected-inode"
                    }
                  >
                    <Icons
                      type={
                        connectedNetworks[0] &&
                        connectedNetworks[0].cluster &&
                        connectedNetworks[0].cluster.id
                          ? "bs"
                          : "fa"
                      }
                      name={
                        connectedNetworks[0] &&
                        connectedNetworks[0].cluster &&
                        connectedNetworks[0].cluster.id
                          ? "BsBoundingBox"
                          : "FaRegHdd"
                      }
                      className="valign-text-bottom iot-tag-label"
                      style={iconStyle}
                    />
                    {connectedNetworks[0].node
                      ? connectedNetworks[0].node.name
                      : connectedNetworks[0].cluster &&
                        connectedNetworks[0].cluster.name}
                    /
                    <Icons
                      type="fa"
                      name="FaConnectdevelop"
                      className="valign-text-bottom iot-tag-label"
                      style={iconStyle}
                    />
                    {connectedNetworks[0].network
                      ? connectedNetworks[0].network.name
                      : ""}
                  </Tag>
                  {!isVirtualNode ? (
                    canShowPopOverHelp ? (
                      <PopOverHelp
                        helpData={{
                          title: getCurrentlocaleText(
                            "network.routeinfo.help.title",
                          ),
                          data: [
                            {
                              subtitle: "",
                              content: (
                                <RouteInfo
                                  tanCIDR={tanCIDR}
                                  ViNodeId={
                                    connectedNetworks[0].node
                                      ? connectedNetworks[0].node.id
                                      : connectedNetworks[0].cluster &&
                                        connectedNetworks[0].cluster.id
                                  }
                                />
                              ),
                            },
                          ],
                        }}
                      />
                    ) : null
                  ) : null}
                  {/*as Network Modal form was removed this render is no more required*/}
                  {/* {connectedNetworks[0].representation_network
                      ? repNetwork
                      : null} */}
                </span>
              )}
            </span>
          </div>
        );
      } else {
        return (
          <span style={{ cursor: cursor ? cursor : "text" }}>
            {ConnectedNodesContainer.NOT_APPLICAPLE}
          </span>
        );
      }
    }
  }
}
export default new ConnectedNodesContainer();
