import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Row, Col } from "antd";
import InodesList from "components/DataLists/InodesList";
import OrgsList from "components/DataLists/OrgsList";
import SerialList from "components/DataLists/SerialList";
import BreadcrumbComponent from "components/UI-Components/BreadcrumbComponent";
import { Link } from "react-router-dom";
import OrgSelector from "components/Orgs/OrgSelector";
import { getTagKeyValues, getCurrentlocaleText } from "Core/Utils";
import UserController from "controller/UserController";
import RoleController from "controller/RoleController";
import NetworkController from "controller/NetworkController";
import LabelProvider from "components/UI-Components/LabelProvider";
import NetworkList from "components/Containers/Networks";
import OrgUsers from "components/DataLists/OrgUsers";
import OrgController from "controller/OrgController";
import RolesList from "components/DataLists/RolesList";
import FirewallList from "components/DataLists/FirewallList";
import Icons from "components/UI-Components/Icons";
import FirewallGroupController from "controller/FirewallGroupController";
import SerialNumberController from "controller/SerialNumberController";
import Services from "./Services";
import LicenseComponent from "./License";
import Clusters from "components/Clusters/Clusters";

@inject(
  "UiStore",
  "AuthStore",
  "DashboardStore",
  "UserViewModel",
  "NetworkViewModel",
  "ServiceViewModel",
  "OrgViewModel",
)
@observer
class DashboardDetails extends Component {
  constructor(props) {
    super(props);
    this.userModel = this.props.UserViewModel;
    this.networkModel = this.props.NetworkViewModel;
    this.serviceModel = this.props.ServiceViewModel;
    this.state = {
      BreadcrumbList: [],
      showUsers: false,
      showiNodes: false,
      showNetworks: false,
      showServices: false,
      showOrgs: false,
      showRoles: false,
      showSecrets: false,
      columns: [],
      dataSource: [],
      loading: true,
      key: null,
      currentOrgId: this.props.DashboardStore.orgs,
    };
  }

  UNSAFE_componentWillMount() {
    let name;
    let path = window.location.pathname;
    const currentOrgId =
      this.props.match.params && this.props.match.params.id
        ? this.props.match.params.id
        : this.props.OrgViewModel.org.id != undefined
        ? this.props.OrgViewModel.org.id
        : this.props.AuthStore.loggedInUser.organization &&
          this.props.AuthStore.loggedInUser.organization.id;
    let params = currentOrgId ? { org_id: currentOrgId } : {};
    params.page = 0;
    this.setState({ currentOrgId: currentOrgId });
    if (
      path.indexOf("/dashboard/inodes/" + currentOrgId + "/status/") !== -1 &&
      this.props.AuthStore.IsPermitted("NODE:VIEW")
    ) {
      name = "iNodes Status";
      this.setState({ showiNodes: true });
    } else if (
      path.indexOf("/dashboard/inodes/" + currentOrgId + "/profile/") !== -1 &&
      this.props.AuthStore.IsPermitted("NODE:VIEW")
    ) {
      name = "iNodes Profile";
      this.setState({ showiNodes: true });
    } else if (
      path.indexOf("/dashboard/" + currentOrgId + "/users") !== -1 &&
      this.props.AuthStore.IsPermitted("USER:VIEW")
    ) {
      name = "Users";
      this.setState({ showUsers: true, key: "users" });
      this.loading(true);
      this.formUserData(params);
    } else if (
      path.indexOf("/dashboard/" + currentOrgId + "/inodes") !== -1 &&
      this.props.AuthStore.IsPermitted("NODE:VIEW")
    ) {
      name = "iNodes";
      this.setState({ showiNodes: true });
    } else if (
      path.indexOf("/dashboard/" + currentOrgId + "/roles") !== -1 &&
      this.props.AuthStore.IsPermitted("ROLE:VIEW")
    ) {
      name = "Roles";
      this.setState({ showRoles: true });
      this.formRolesData(params);
    } else if (
      path.indexOf("/dashboard/" + currentOrgId + "/securitypolicies") !== -1 &&
      this.props.AuthStore.IsPermitted("ORG:VIEW") &&
      this.props.AuthStore.IsPermitted("NODE:VIEW")
    ) {
      name = "Custom Security Policy";
      this.setState({ ShowSecurityGroups: true });
      this.formSecurityGroupData(params);
    } else if (
      path.indexOf("/dashboard/" + currentOrgId + "/orgs") !== -1 &&
      this.props.AuthStore.IsPermitted("ORG:VIEW")
    ) {
      name = "Organization";
      this.setState({ showOrgs: true });
    } else if (
      path.indexOf("/dashboard/" + currentOrgId + "/clusters") !== -1 &&
      this.props.AuthStore.IsPermitted("CLUSTER:VIEW")
    ) {
      name = getCurrentlocaleText("clusters.navigation_menu.text");
      this.setState({ showClusters: true });
    } else if (
      path.indexOf("/dashboard/" + currentOrgId + "/serials") !== -1 &&
      this.props.AuthStore.IsPermitted("ORG:VIEW")
    ) {
      name = "Serial Numbers";
      this.setState({ showSerial: true });
      this.formSerialNumberData(params);
    } else if (
      path.indexOf("/dashboard/" + currentOrgId + "/networks") !== -1 &&
      this.props.AuthStore.IsPermitted("NETWORK:VIEW")
    ) {
      name = "Networks";
      this.setState({ showNetworks: true });
      this.loading(true);
      //this.formNetworkData(params);
    } else if (
      path.indexOf("/dashboard/" + currentOrgId + "/services") !== -1 &&
      this.props.AuthStore.IsPermitted("SERVICE:VIEW")
    ) {
      name = "Services";
      this.setState({ showServices: true, key: "services" });
      this.loading(true);
    } else if (
      path.indexOf("/dashboard/" + currentOrgId + "/licenses") !== -1 &&
      this.props.AuthStore.IsPermitted("NODE:VIEW")
    ) {
      name = "Service Secrets";
      this.setState({ showSecrets: true, key: "secrets" });
      this.loading(true);
    } else {
      name = "iNodes";
    }
    this.setState({
      BreadcrumbList: [
        { name: name, link: window.location.pathname.substring(1) },
      ],
    });
    this.updateOrgModel(currentOrgId);
  }

  componentWillUnmount() {
    this.props.UiStore.showChildNetworks = false;
    this.props.UiStore.showChildNodes = false;
    this.props.UiStore.showChildOrgs = false;
    this.props.UiStore.showChildRoles = false;
    this.props.UiStore.showChildSerials = false;
    this.props.UiStore.showChildSecurityGroups = false;
    this.props.UiStore.showChildServices = false;
    this.props.UiStore.showChildUsers = false;
    this.props.UiStore.showChildSecrets = false;
    this.props.UiStore.showChildVolumes = false;
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (
      (this.props.match.params && this.props.match.params.id) !=
      (props.match.params && props.match.params.id)
    ) {
      const currentOrgId =
        props.match.params && props.match.params.id
          ? props.match.params.id
          : this.props.OrgViewModel.org.id != undefined
          ? this.props.OrgViewModel.org.id
          : this.props.AuthStore.loggedInUser.organization &&
            this.props.AuthStore.loggedInUser.organization.id;
      let params = currentOrgId ? { org_id: currentOrgId } : {};
      params.page = 0;
      this.updateFormData(params, currentOrgId);
    }
  }

  updateFormData = (params, currentOrgId) => {
    let path = window.location.pathname;
    let pathdata = path.split("/");
    let current_element = pathdata && pathdata[3] ? pathdata[3] : "";
    switch (current_element) {
      case "users":
        this.formUserData(params);
        break;
      case "network":
        this.formNetworkData(params);
        break;
      case "service":
        /* service component will be loaded */
        break;
      case "roles":
        this.formRolesData(params);
        break;
      case "securitypolicies":
        this.formSecurityGroupData(params);
        break;
    }
    this.updateOrgModel(currentOrgId);
  };

  // this will update/reset the current selected org
  updateOrgModel = org_value => {
    //updates the org-model based on currently selected organization
    OrgController.getOrg(org_value, false, true).then(response => {
      if (response && response.parent_organization)
        this.props.UiStore.parentOrgData = response.parent_organization;
      else this.props.UiStore.parentOrgData = {};
    });
  };

  formUserData = params => {
    let columns = [
      {
        title: "Full Name",
        dataIndex: "name",
        key: "name",
        width: "25%",
        render: (text, record, index) => {
          return <div>{record.name}</div>;
        },
      },
      {
        title: "Email Address",
        dataIndex: "email",
        key: "email",
        width: "25%",
      },
      {
        title: "Role",
        dataIndex: "role",
        key: "role",
        width: "25%",
      },
      {
        title: "Organization",
        dataIndex: "organization",
        key: "organization",
        width: "25%",
        render: (text, record, index) => {
          let url = `/orgs/${record.org_id}`;
          return (
            <div>
              <Link to={url}>{record.organization}</Link>
            </div>
          );
        },
      },
    ];
    UserController.getUsers(params, true)
      .then(resp => {
        this.setState({
          columns: columns,
          dataSource: this.userModel.dashboardUserData,
        });
        this.loading(false);
      })
      .catch(error => {
        this.loading(false);
      });
  };

  formNetworkData = params => {
    let columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: "20%",
        render: (text, record, index) => {
          return <div>{record.name}</div>;
        },
      },
      {
        title: "Label",
        dataIndex: "label",
        key: "label",
        width: "10%",
        render: (text, record) => {
          let labels = record.metadata ? record.metadata : {};
          return (
            <div>
              <span>
                {getTagKeyValues(labels).length > 0 ? (
                  <LabelProvider
                    key={record.id}
                    metadata={labels}
                    tags={getTagKeyValues(labels)}
                    showPopupLabel={false}
                    viewOnly={true}
                  />
                ) : (
                  "-"
                )}
              </span>
            </div>
          );
        },
      },
      {
        title: "Network Addressing",
        dataIndex: "type",
        key: "type",
        width: "10%",
      },
      {
        title: "CIDR",
        dataIndex: "cidr",
        key: "cidr",
        width: "20%",
      },
      {
        title: "Organization",
        dataIndex: "organization",
        key: "organization",
        width: "20%",
        render: (text, record, index) => {
          let url = `/orgs/${record.org_id}`;
          return (
            <div>
              <Link to={url}>{record.organization}</Link>
            </div>
          );
        },
      },
      {
        title: "iNode",
        dataIndex: "inode",
        key: "inode",
        width: "20%",
        render: (text, record, index) => {
          let url = `/orgs/${record.org_id}/inodes/${record.inode_id}`;
          return <Link to={url}>{record.inode_name}</Link>;
        },
      },
    ];
    NetworkController.getNetworks(params, true)
      .then(resp => {
        this.setState({
          columns: columns,
          dataSource: this.networkModel.dashboardNetworkData,
        });
        this.loading(false);
      })
      .catch(error => {
        this.loading(false);
      });
  };
  formRolesData = params => {
    RoleController.getRoles(params, true);
  };
  formSecurityGroupData = params => {
    params.isDefault = false;
    FirewallGroupController.getFirewallGroups(params, true);
  };
  formSerialNumberData = params => {
    //Serial Numbers & child orgs Serial Numbers list
    params.assigned = false;
    SerialNumberController.getSerialNumbers(params);
  };

  loading = value => {
    this.setState({ loading: value });
  };

  render() {
    const currentOrgId =
      this.props.match.params && this.props.match.params.id
        ? this.props.match.params.id
        : this.props.OrgViewModel.org.id != undefined
        ? this.props.OrgViewModel.org.id
        : this.props.AuthStore.loggedInUser.organization &&
          this.props.AuthStore.loggedInUser.organization.id;
    return (
      <div>
        {this.props.AuthStore.loggedInUser && (
          <Row gutter={16} type="flex" align="middle">
            <Col lg={{ span: 17 }} xl={{ span: 17 }}>
              <h2 className="page-title">
                <Icons type="fa" name="FaDesktop" /> {"  "}
                Dashboard
              </h2>
              <BreadcrumbComponent
                {...this.props}
                BreadcrumbList={this.state.BreadcrumbList}
              />
            </Col>
            <Col
              lg={{ span: 6, push: 1 }}
              xl={{ span: 6, push: 1 }}
              className="flex flex-h-right"
            >
              <OrgSelector {...this.props} />
            </Col>
          </Row>
        )}
        <div className="list-contianer">
          {this.state.showiNodes && (
            <InodesList
              isFromDashboard={true}
              params={currentOrgId ? { org_id: currentOrgId } : {}}
              orgId={
                this.props.match.params && this.props.match.params.id
                  ? this.props.match.params.id
                  : this.props.AuthStore.loggedInUser.organization &&
                    this.props.AuthStore.loggedInUser.organization.id
              }
              {...this.props}
            />
          )}
          {this.state.showRoles && (
            <RolesList
              isFromDashboard={true}
              orgId={currentOrgId}
              params={{
                org_id: currentOrgId ? currentOrgId : null,
              }}
            />
          )}
          {this.state.showOrgs && (
            <OrgsList
              isFromDashboard={true}
              params={currentOrgId ? { org_id: currentOrgId } : {}}
              orgId={currentOrgId}
              {...this.props}
            />
          )}
          {this.state.showClusters && (
            <Clusters
              isFromDashboard={true}
              params={currentOrgId ? { org_id: currentOrgId } : {}}
              orgId={currentOrgId}
              {...this.props}
            />
          )}
          {this.state.ShowSecurityGroups && (
            <FirewallList
              orgId={currentOrgId ? currentOrgId : null}
              resource="firewallgroup"
              params={{
                org_id: currentOrgId ? currentOrgId : null,
              }}
              cameFromDashboard={true}
            />
          )}

          {this.state.showNetworks && (
            <NetworkList
              isFromDashboard={true}
              params={{
                org_id: currentOrgId ? currentOrgId : null,
                own: true,
              }}
              {...this}
            />
          )}
          {this.state.showSerial && (
            <SerialList
              title={
                <span>
                  <Icons type="ai" name="AiOutlineSolution" /> Add Serial
                </span>
              }
              icon={<Icons type="ai" name="AiOutlinePlus" />}
              orgId={currentOrgId}
              params={{
                org_id: currentOrgId ? currentOrgId : null,
                own: true,
              }}
              resource="pki"
              action="create"
              cameFromDashboard={true}
            />
          )}
          {this.state.showUsers && (
            <OrgUsers
              resource="users"
              orgId={currentOrgId ? currentOrgId : null}
              params={{
                org_id: currentOrgId ? currentOrgId : null,
                own: true,
              }}
              cache={false}
              icon={<Icons type="ai" name="AiOutlineUser" />}
              title="Users"
              loadOrgState={true}
              cameFromDashboard={true}
              {...this.props}
            />
          )}
          {this.state.showServices && (
            <Services
              orgId={currentOrgId ? currentOrgId : null}
              params={{
                org_id: currentOrgId ? currentOrgId : null,
                own: true,
              }}
              cameFromDashboard={true}
              {...this}
            />
          )}
          {this.state.showSecrets && (
            <LicenseComponent
              orgId={currentOrgId ? currentOrgId : null}
              params={{
                org_id: currentOrgId ? currentOrgId : null,
                own: true,
              }}
              cameFromDashboard={true}
              {...this}
            />
          )}
        </div>
      </div>
    );
  }
}

export default DashboardDetails;
