import React, { Component } from "react";
import { Card, Empty } from "antd";
import { Link } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { getCurrentlocaleText } from "Core/Utils";
import Icons from "../UI-Components/Icons";
import CTDBarChart from "./CTDBarChart";
import InodeController from "../../controller/InodeController";
import LoadingComponent from "../UI-Components/LoadingComponent";

const moment = require("moment");

@inject("InodeViewModel", "OrgViewModel", "AuthStore")
@observer
class InodeCTDDashboard extends Component {
  constructor(props) {
    super(props);
    this.orgModel = this.props.OrgViewModel;
    this.inodeModel = this.props.InodeViewModel;
    this.authStore = this.props.AuthStore;
    this.orgId =
      this.orgModel.org && this.orgModel.org.id
        ? this.orgModel.org.id
        : this.authStore.loggedInUser.organization &&
          this.authStore.loggedInUser.organization.id;
    this.inodeId =
      this.inodeModel.inode && this.inodeModel.inode.id
        ? this.inodeModel.inode.id
        : this.props.match.params.nodeId;
    this.getTopThreats(this.inodeId);
  }

  getTopThreats = nodeId => {
    this.inodeModel.getTopThreatsLoading = true;
    let params = {
      start_date: moment().subtract(7, "days"),
      end_date: moment(),
    };
    InodeController.getTopThreats(nodeId, params)
      .then(resp => {
        if (resp) {
          this.inodeModel.setTopThreats(resp);
        }
        this.inodeModel.getTopThreatsLoading = false;
      })
      .catch(err => {
        this.inodeModel.getTopThreatsLoading = false;
      });
  };

  render() {
    const getTopThreats = this.inodeModel.getTopThreats;

    return (
      <LoadingComponent loading={this.inodeModel.getTopThreatsLoading}>
        <Link
          to={`/orgs/${this.orgId}/inodes/${this.inodeId}/threatintelligence`}
        >
          <Card
            hoverable={false}
            title={
              <div className="justify-space-between">
                <span>
                  {getCurrentlocaleText("threats.overview.title.text")}
                </span>
                <Icons type="bs" name="BsArrowUpRight" />
              </div>
            }
            style={{ minHeight: "340px", marginBottom: 0 }}
          >
            {getTopThreats && getTopThreats.stats && !getTopThreats.results && (
              <div className="p-15 all-center-vertical col-center">
                <Icons
                  type="fa"
                  name="FaSpinner"
                  className="spinner spineer-icon-rotate"
                />
                <h2 className="all-center primary-color">
                  {getTopThreats.stats.packets_analyzed}
                </h2>
                <div className="all-center text-muted">
                  {getCurrentlocaleText("threats.overview.title.desc")}
                </div>
              </div>
            )}
            {getTopThreats &&
              getTopThreats.results &&
              getTopThreats.results.length &&
              getTopThreats.results.length > 0 && (
                <>
                  <b>Top Threats</b>
                  <div style={{ fontSize: "13px" }}>
                    <CTDBarChart
                      headingName="Threats"
                      data={getTopThreats.results}
                      objKey="classtype"
                    />
                  </div>
                </>
              )}
          </Card>
        </Link>
      </LoadingComponent>
    );
  }
}

export default InodeCTDDashboard;
