import React, { Component } from "react";
import propTypes from "prop-types";
import Icons from "components/UI-Components/Icons";

class Logos extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <span>
        <span>
          <Icons
            type={this.props.type}
            name={this.props.name}
            style={this.props.style}
          />
        </span>
        {this.props.label && (
          <span className="logo-label" style={this.props.lableStyle}>
            {this.props.label}
          </span>
        )}
      </span>
    );
  }
}

Logos.propTypes = {
  type: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
  label: propTypes.string,
  lableStyle: propTypes.object,
};

export default Logos;
