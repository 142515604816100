import React, { Component } from "react";
import {
  getCurrentlocaleText,
  getAlertStatusDisplay,
  getAlertDurationDisplay,
} from "Core/Utils";

class AlertConditionDisplay extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let record = this.props.record;
    let alertStatusDisplayText =
      record && record.type && record.settings.alert_me
        ? getAlertStatusDisplay(record.type, record.settings.alert_me)
        : "";
    let alertDurationDisplayText =
      record && record.settings && record.settings.duration
        ? getAlertDurationDisplay(record.settings.duration)
        : "";
    return (
      <div>
        {/*Alert type*/}
        {record.display_type && (
          <div>{`${getCurrentlocaleText("alertform.alerttype.text")} ${
            record.display_type
          }`}</div>
        )}
        {/*Alert me*/}
        {alertStatusDisplayText && alertStatusDisplayText.trim() !== "" && (
          <div>{`${getCurrentlocaleText(
            "alertform.status.text",
          )} ${alertStatusDisplayText}`}</div>
        )}
        {/*Duration*/}
        {alertDurationDisplayText && alertDurationDisplayText.trim() !== "" && (
          <div>{`For ${alertDurationDisplayText}`}</div>
        )}
      </div>
    );
  }
}

export default AlertConditionDisplay;
