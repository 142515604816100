import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import TableLayoutV1 from "../layout/TableLayoutV1";
import { getCurrentlocaleText } from "../../Core/Utils";
import RepNetViewModel from "../../view-model/RepNetViewModel";
import { AiOutlineRight } from "react-icons/ai";
import { getNetworks } from "../../controller/RepNetController";
import RepNetTableFromEdge from "./RepNetTableFromEdge";
import RepNetTableFromVirtual from "./RepNetTableFromVirtual";
import RepNetTableEdgestaticRoutes from "./RepNetTableEdgestaticRoutes";
import LoadingComponent from "../UI-Components/LoadingComponent";
import RepNetTableLookup from "./RepNetTableLookup";

const RepNetTable = props => {
  const [dataSource, setDataSource] = useState([]);
  const [pagination, setPagination] = useState({});
  const [sortedInfo, setSortedInfo] = useState({
    [props.from]: { sorter: {}, sort: {} },
  });
  const [modalVisibility, setModalVisibility] = useState();
  const [cidrData, setCidrData] = useState();
  const [showChildNodes, setShowChildNodes] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (
      showChildNodes != props.showChildNodes ||
      searchText != props.searchText
    ) {
      setShowChildNodes(props.showChildNodes);
      setSearchText(props.searchText);
    }
    getRepNetList();
  }, [
    props.orgId,
    props.showChildNodes,
    props.searchText,
    pagination,
    sortedInfo,
    getRepNetList,
  ]);

  const getRepNetList = useCallback(() => {
    let params = {
      page: pagination && pagination.current ? pagination.current - 1 : 0,
      size:
        pagination && pagination.pageSize
          ? pagination.pageSize
          : RepNetViewModel.pageSize,
    };

    if (
      sortedInfo &&
      props.from &&
      sortedInfo[props.from] &&
      sortedInfo[props.from].sort &&
      sortedInfo[props.from].sort.by &&
      sortedInfo[props.from].sort.order
    ) {
      params.sort = `${sortedInfo[props.from].sort.by}:${
        sortedInfo[props.from].sort.order
      }`;
    }

    if (
      props.searchText &&
      props.searchText.length &&
      props.searchText.length > 0
    ) {
      params.search = props.searchText;
    }

    if (!props.showChildNodes) {
      params.own = true;
    }

    if (props.orgId) {
      params.org_id = props.orgId;
    }

    RepNetViewModel.loading[props.from] = true;
    getNetworks(props.from, params)
      .then(resp => {
        setDataSource(resp.results);
        setTimeout(() => {
          RepNetViewModel.loading[props.from] = false;
        }, 1000);
      })
      .catch(err => {
        RepNetViewModel.loading[props.from] = false;
      });
  }, [
    props.orgId,
    props.showChildNodes,
    props.searchText,
    pagination,
    sortedInfo,
  ]);

  const getColumns = () => {
    let columns = [];
    switch (props.from) {
      case "fromEdge":
        columns = RepNetTableFromEdge(sortedInfo[props.from]);
        break;
      case "fromVirtual":
        columns = RepNetTableFromVirtual(sortedInfo[props.from]);
        break;
      case "edgeStaticRoutes":
        columns = RepNetTableEdgestaticRoutes(sortedInfo[props.from]);
        break;
    }

    if (props.showChildNodes) {
      columns.push({
        title: (
          <span title={getCurrentlocaleText("general.component.orgs.text")}>
            {getCurrentlocaleText("general.component.orgs.text")}
          </span>
        ),
        dataIndex: "organization",
        key: "organization",
        render: (textObj, record, index) => {
          return (
            <>
              {textObj && textObj.id && (
                <Link to={"/orgs/" + textObj.id}>{textObj.name}</Link>
              )}
            </>
          );
        },
      });
    }

    columns.push({
      title: " ",
      dataIndex: "action",
      width: "5%",
      key: "action",
      render: (text, record, index) => {
        return (
          <Button type="text" className="p-5" onClick={() => showModal(record)}>
            <AiOutlineRight className="ota-primary-color" />
          </Button>
        );
      },
    });
    return columns;
  };
  let columns = getColumns();

  const onTableChange = (pagination, filter, sorter) => {
    let sort = {};
    if (sorter.columnKey && sorter.order) {
      sort.order = sorter.order === "ascend" ? "ASC" : "DESC";
      sort.by = sorter.columnKey;
    }
    setSortedInfo({
      [props.from]: { sorter, sort },
    });

    setPagination(pagination);

    if (RepNetViewModel.pageSize) {
      RepNetViewModel.setPageSize(pagination.pageSize);
    }
  };

  const showModal = record => {
    setModalVisibility(true);
    setCidrData(record);
  };

  const handleCancel = () => {
    setModalVisibility(false);
  };

  let pageData =
    props.from &&
    RepNetViewModel &&
    RepNetViewModel.pageable &&
    RepNetViewModel.pageable[props.from];

  return (
    <>
      <LoadingComponent loading={RepNetViewModel.loading[props.from]}>
        <TableLayoutV1
          columns={columns}
          dataSource={[...dataSource]}
          pagination={{
            pageSize: pageData && pageData.size,
            total: pageData && pageData.total_count,
            current: pageData && pageData.page + 1,
            showSizeChanger: true,
            pageSizeOptions: [25, 50, 100],
            defaultPageSize: RepNetViewModel.pageSize,
          }}
          showSizeChanger={true}
          hideRowSelection={true}
          onChange={onTableChange}
        />
        {modalVisibility && (
          <RepNetTableLookup handleCancel={handleCancel} cidrData={cidrData} />
        )}
      </LoadingComponent>
    </>
  );
};

export default RepNetTable;
