import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import Input from "components/UI-Components/InputBox";
import propTypes from "prop-types";
import { randomAlphaNumericString, copyObject } from "Core/Utils";
import ServiceTemplatesUtils from "components/serviceTemplates/Utils/ServiceTemplatesUtils";
import { inject } from "mobx-react";
import { computed } from "mobx";
import Twig from "twig";

/*Define form elements*/
const FormItem = Form.Item;

@inject("UiStore")
export default class TextBox extends Component {
  constructor(props) {
    super(props);

    this.validationMessage =
      this.props.properties.constraints &&
      this.props.properties.constraints.validationMessage
        ? this.props.properties.constraints.validationMessage
        : `${this.props.properties.label} is required`;
  }

  validateInput = (rule, value, callback, field) => {
    if (
      this.props.properties.constraints &&
      !this.props.properties.constraints.required &&
      !value
    ) {
      callback();
    }
    if (
      this.props.properties.constraints &&
      this.props.properties.constraints.regex
    ) {
      let failMessage = null;
      let result = ServiceTemplatesUtils.validateUserInput(
        this.props.properties.constraints.regex,
        value,
      );

      if (!result) {
        failMessage = this.props.properties.constraints.validationMessage
          ? this.props.properties.constraints.validationMessage
          : `Input should evaluate to this regex - ${this.props.properties.constraints.regex}`;
      }

      if (failMessage) {
        callback(failMessage);
      }
    }
    callback();
  };

  componentDidMount() {
    if (this.props.updateCurrentValueOnChange) {
      this.props.updateCurrentValueOnChange(
        this.props.properties.defaultValue &&
          this.props.properties.defaultValue.value
          ? this.props.properties.defaultValue.value
          : typeof this.props.properties.defaultValue === "string" &&
            this.props.properties.defaultValue
          ? this.props.properties.defaultValue
          : "",
        "value",
      );
    }
  }

  inputChange = e => {};

  @computed
  get componentVisibility() {
    let visibility = null;
    if (
      this.props.UiStore.computedOutputObject &&
      this.props.UiStore.computedOutputObject.steps
    ) {
      if (this.props.properties.visibility) {
        var template = Twig.twig({
          data: this.props.properties.visibility,
        });
        let computedOutputObject = copyObject(
          this.props.UiStore.computedOutputObject,
        );
        visibility = template.render(computedOutputObject);
        try {
          visibility = JSON.parse(visibility);
        } catch {
          visibility = null;
        }
      }
    }
    return visibility ? true : false;
  }

  render() {
    const { getFieldDecorator } = this.props.referenceForm; // reference of base form
    let required =
      this.props.properties.constraints &&
      this.props.properties.constraints.required
        ? this.props.properties.constraints.required
        : false;
    let id = this.props.id
      ? this.props.id
      : `textbox${randomAlphaNumericString(3)}`;

    let disable = false;
    if (this.props.editmode) {
      disable = this.props.properties.noneditable
        ? this.props.properties.noneditable
        : false;
    }
    disable = this.props.properties.disabled
      ? this.props.properties.disabled
      : disable;

    // For View only
    disable = this.props.viewOnly === true ? true : disable;

    let visibility = this.props.properties.visibility
      ? this.componentVisibility
      : true;

    return visibility ? (
      <div
        onClick={
          this.props.onFocus &&
          this.props.onFocus.bind(null, this.props.properties.help)
        }
      >
        <FormItem label={this.props.properties.label} hasFeedback>
          {getFieldDecorator(id, {
            rules: [
              {
                required: required,
                message: this.validationMessage,
              },
              {
                validator: this.validateInput,
              },
            ],
            initialValue:
              this.props.properties.defaultValue &&
              this.props.properties.defaultValue.value
                ? this.props.properties.defaultValue.value
                : typeof this.props.properties.defaultValue === "string" &&
                  this.props.properties.defaultValue
                ? this.props.properties.defaultValue
                : "",
          })(
            <Input
              placeholder={this.props.properties.placeholder}
              autoComplete="off"
              disabled={disable}
              onFocus={
                this.props.onFocus &&
                this.props.onFocus.bind(null, this.props.properties.help)
              }
              className={this.props.className ? this.props.className : ""}
              onChange={e => {
                this.props.properties.inputChange
                  ? this.props.properties.inputChange(e.target.value)
                  : this.inputChange;
                if (!this.props.properties.inputChange)
                  this.props.updateCurrentValueOnChange(
                    e.target.value,
                    "value",
                  );
              }}
            />,
          )}
        </FormItem>
      </div>
    ) : (
      <div></div>
    );
  }
}

TextBox.propTypes = {
  referenceForm: propTypes.any,
  id: propTypes.string,
  properties: propTypes.shape({
    label: propTypes.string.isRequired,
    disabled: propTypes.bool,
    placeholder: propTypes.string,
    inputChange: propTypes.func,
    defaultValue: propTypes.string,
    constraints: propTypes.shape({
      validationMessage: propTypes.string,
      required: propTypes.bool,
      regex: propTypes.any,
    }),
  }),
};

/* sample schema

{
  "type": "TextBox",
  "visible": true,
  "props": {
    "label": "Service name",
    "defaultValue": "",
    "help": "<div>help for textbox</div>",
    "placeholder": "Enter Service name",
    "disabled": false,
    "noneditable" : true,
    "constraints": {
      "validationMessage": "validate me",
      "required": true,
      "regex": "^[a-z0-9A-Z]{1,30}$"

    }
  }
}

*/
