import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { Checkbox, Button, Tag, Modal, Cascader, Form } from "antd";
import _ from "lodash";
import serviceTemplateController from "../../../controller/serviceTemplateController";
import InodeController from "../../../controller/InodeController";
import ClusterController from "../../../controller/ClusterController";
import { getCurrentlocaleText } from "../../../Core/Utils";
import Icons from "../../UI-Components/Icons";
import customImg from "assets/img/logo_custom.png";
import LoadingComponent from "../../UI-Components/LoadingComponent";
import ReleaseController from "../../../controller/ReleaseController";
import NetworkController from "../../../controller/NetworkController";

@inject(
  "AuthStore",
  "OrgViewModel",
  "InodeViewModel",
  "ClusterViewModel",
  "NetworkViewModel",
)
@observer
class AddService extends Component {
  constructor(props) {
    super(props);
    this.orgModel = this.props.OrgViewModel;
    this.inodeModel = this.props.InodeViewModel;
    this.clusterModel = this.props.ClusterViewModel;
    this.networkModel = this.props.NetworkViewModel;

    this.state = {
      BreadcrumbList: [],
      loading: true,
      loadingNodes: true,
      loadingClusters: true,
      availableServices: [],
      imageIcons: [],
      selectedService: "",
      step: 1,
      networksList: [],
    };
    this.orgId = this.props.orgId
      ? this.props.orgId
      : this.props.AuthStore.loggedInUser.organization.id;
    this.pageSize = 100;
  }

  componentDidMount() {
    this.getTemplates();
    this.getInodes();
    this.getClusters();
  }

  getTemplates() {
    this.setState({
      loading: true,
    });
    let templateParams = {
      org_id: this.orgId,
      own: true,
    };
    serviceTemplateController.getTemplates(templateParams).then(res => {
      /* add services list */
      let serviceList =
        res &&
        res.map(val => {
          return {
            id: val.id,
            checked: false,
            ...val.template_metadata,
          };
        });

      if (serviceList) {
        serviceList = serviceList.sort((a, b) => a.name.localeCompare(b.name));
      }

      this.setState({
        availableServices: serviceList,
        loading: false,
      });
    });
  }

  rescan() {
    this.setState({ loading: true });
    ReleaseController.forceGetReleases({}, true, true)
      .then(resp => {
        this.getTemplates();
      })
      .catch(error => {
        this.getTemplates();
      });
  }

  getInodes() {
    let params = {
      org_id: this.orgId,
      own: true,
      size: this.pageSize,
      page: 0,
    };
    InodeController.getInodesForDropdown(params)
      .then(response => {
        if (response && response.total_count) {
          let allParams = [],
            totalCount = response.total_count / this.pageSize;
          if (totalCount > 0) {
            for (let page = 0; page < totalCount; page++) {
              allParams.push({
                org_id: this.orgId,
                own: true,
                size: this.pageSize,
                page: page,
              });
            }
            InodeController.getAllInodesForDropdown(allParams, true, true)
              .then(response => {
                this.setState({ loadingNodes: false });
              })
              .catch(error => {
                this.setState({ loadingNodes: false });
              });
          } else {
            this.setState({ loadingNodes: false });
          }
        } else {
          this.setState({ loadingNodes: false });
        }
      })
      .catch(error => {
        this.setState({ loadingNodes: false });
      });
  }

  getClusters() {
    let params = {
      org_id: this.orgId,
      own: true,
      size: this.pageSize,
      page: 0,
    };

    ClusterController.getClustersForDropdown(params)
      .then(response => {
        if (response && response.total_count) {
          let allParams = [],
            totalCount = response.total_count / this.pageSize;

          if (totalCount > 0) {
            for (let page = 0; page < totalCount; page++) {
              allParams.push({
                org_id: this.orgId,
                own: true,
                size: this.pageSize,
                page: page,
              });
            }
            ClusterController.getAllClustersForDropdown(allParams, false)
              .then(response => {
                this.setState({ loadingClusters: false });
                this.updateNetworkData();
              })
              .catch(error => {
                this.setState({ loadingClusters: false });
              });
          } else {
            this.setState({ loadingClusters: false });
          }
        } else {
          this.setState({ loadingClusters: false });
        }
      })
      .catch(error => {
        this.setState({ loadingClusters: false });
      });
  }

  updateNetworkData() {
    let params = this.props.params || {
      org_id: this.orgId,
      own: true,
      size: 1,
      page: 0,
    };
    params.page = 0;
    NetworkController.getNetworks(params, true, false, true).then(resp => {
      if (resp && resp.length > 0) {
        this.updateOrgsNetworkData(
          this.networkModel.pageable.total_count / 100,
          [],
          true,
        );
      }
    });
  }

  updateOrgsNetworkData(totalCount, availableNetworks, loading) {
    if (totalCount > 0) {
      let allParams = [];
      for (let page = 0; page < totalCount; page++) {
        allParams.push({
          org_id: this.orgId,
          own: true,
          size: 100,
          page: page,
        });
      }
      NetworkController.getAllNetworksForDropdown(allParams, loading).then(
        resp => {
          if (resp) {
            this.setState({
              networksList: this.networkModel.networks,
            });
          }
        },
      );
    }
  }

  getImg(service) {
    let returnval = "";
    if (service.id == "custom") {
      returnval = (
        <img
          src={customImg}
          style={{
            width: "50px",
            height: "50px",
          }}
          className="service-custom-icon"
        />
      );
    } else if (service.id && service.logo) {
      returnval = (
        <img
          src={"/api/v2/servicetemplate/" + service.id + "/" + service.logo}
          style={{ width: "50px" }}
        />
      );
    } else {
      returnval = <img src={LogoWithText} style={{ width: "50px" }} />;
    }
    return returnval;
  }

  addService = service => {
    let availableServices = this.state.availableServices;
    availableServices = availableServices.map(obj => {
      obj.checked = obj.id == service.id ? true : false;
      return obj;
    });
    this.setState({
      selectedService: service,
      availableServices,
    });
  };

  onFinish = values => {
    if (values.nodeCluster.length && values.nodeCluster.length == 2) {
      this.handleCancel();
      let url =
        "/orgs/" +
        this.orgId +
        "/" +
        values.nodeCluster[0] +
        "/" +
        values.nodeCluster[1] +
        "/services/" +
        this.state.selectedService.id;
      this.props.history.push(url);
    }
  };

  handleCancel = () => {
    this.props.handleCancel();
  };

  render() {
    let {
      loading,
      selectedService,
      availableServices,
      step,
      networksList,
    } = this.state;

    const inodesList = [];
    for (let i = 0; i < this.inodeModel.inodes.length; i++) {
      let inode = this.inodeModel.inodes[i];
      if (
        inode.profile &&
        inode.profile.name &&
        inode.profile.name.toLowerCase() !== "virtual" &&
        !(inode.cluster && inode.cluster.id) &&
        inode.networks &&
        inode.networks.length > 1
      ) {
        inodesList.push({
          value: inode.id,
          label: inode.name,
        });
      }
    }

    const clusterList = [];
    for (let i = 0; i < this.clusterModel.clusters.length; i++) {
      let cluster = this.clusterModel.clusters[i];
      for (let j = 0; j < networksList.length; j++) {
        if (
          networksList[j].cluster &&
          networksList[j].cluster.id &&
          networksList[j].cluster.id == cluster.id &&
          networksList[j].name &&
          networksList[j].name !== "WAN Network"
        ) {
          clusterList.push({
            value: cluster.id,
            label: cluster.name,
          });
          break;
        }
      }
    }

    const options = [
      {
        value: "inodes",
        label: "iNode",
        children: inodesList,
      },
      {
        value: "clusters",
        label: "Cluster",
        children: clusterList,
      },
    ];

    const displayRender = (labels, selectedOptions) =>
      labels.map((label, i) => {
        const option = selectedOptions[i];
        if (i === labels.length - 1) {
          return <span key={option.value}>{label}</span>;
        }
        return <span key={option.value}>{label} / </span>;
      });

    return (
      <Modal
        title={step == 1 ? "Select Service To Add" : "Select iNode Or Cluster"}
        visible={true}
        closable={true}
        onCancel={() => this.handleCancel()}
        maskClosable={false}
        footer={
          step == 1 ? (
            <div className="justify-space-between">
              <div>
                {(this.props.AuthStore.IsPermitted("ORG:SUPPORT") ||
                  this.props.AuthStore.IsPermitted("SUPER:ADMIN")) &&
                  this.props.AuthStore.isLoggedInUserOrgIsRoot() && (
                    <Button
                      primary
                      key="scan"
                      className="mr-10 btn-rounded"
                      icon={<Icons type="ai" name="AiOutlineSync" />}
                      onClick={() => this.rescan()}
                    >
                      {getCurrentlocaleText("sync.to.s3")}
                    </Button>
                  )}
                <span className="text-muted">
                  {availableServices && availableServices.length} Available
                  services
                </span>
              </div>
              <div>
                <Button
                  type="link"
                  key="cancel"
                  onClick={() => this.handleCancel()}
                >
                  {getCurrentlocaleText("cancel.button.text")}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-rounded"
                  onClick={() =>
                    this.setState({
                      step: 2,
                    })
                  }
                  disabled={_.isEmpty(this.state.selectedService)}
                >
                  {getCurrentlocaleText("next.text")}
                </Button>
              </div>
            </div>
          ) : (
            <div className="justify-space-between">
              <div className="text-muted">
                {inodesList.length} iNodes and {clusterList.length} cluster
                available
              </div>
              <div>
                <Button
                  type="link"
                  key="cancel"
                  onClick={() =>
                    this.setState({
                      step: 1,
                    })
                  }
                >
                  {getCurrentlocaleText("back")}
                </Button>
                <Button
                  key="submit"
                  type="primary"
                  form="myForm"
                  htmlType="submit"
                  className="btn-rounded"
                >
                  {getCurrentlocaleText("support.submit.text")}
                </Button>
              </div>
            </div>
          )
        }
        style={{
          width: "770px",
          minHeight: "400px",
        }}
        width={770}
      >
        <LoadingComponent loading={loading}>
          <div className="service-modal">
            {/* Step 1 */}
            {step == 1 ? (
              <div className="whole-wrapper">
                {availableServices &&
                  availableServices.map(service => {
                    return (
                      <div
                        key={service.id}
                        onClick={() => this.addService(service)}
                        className={
                          "item-wrapper" +
                          (service.checked == true ? " item-selected" : "")
                        }
                      >
                        <Checkbox
                          className="checkbox"
                          id={service.id}
                          checked={service.checked}
                        />
                        <div className="img-wrapper">
                          {this.getImg(service)}
                        </div>
                        <div className="p-5">
                          <div className="pb-5">{service.name}</div>
                          {service.service_type && (
                            <div className="service-list-tag mx-0">
                              {service.service_type}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              // Step 2
              <div className="service-node-cluster">
                <Form name="myForm" layout="vertical" onFinish={this.onFinish}>
                  <Form.Item
                    label={<b>Select where to Add {selectedService.name} :</b>}
                    name="nodeCluster"
                    rules={[
                      {
                        required: true,
                        message: "Please select where to add",
                      },
                    ]}
                  >
                    <Cascader options={options} displayRender={displayRender} />
                  </Form.Item>
                </Form>
              </div>
            )}
          </div>
        </LoadingComponent>
      </Modal>
    );
  }
}
export default withRouter(AddService);
