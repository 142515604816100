import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Row, Col, Pagination } from "antd";
import { copyObject, getCurrentlocaleText } from "../../Core/Utils";
import InodeController from "controller/InodeController";
import { toJS } from "mobx";
import OrgController from "../../controller/OrgController";
const moment = require("moment");
import TableSearch from "components/UI-Components/TableSearch";
import TableLayout from "components/layout/TableLayout";
import LoadingComponent from "../UI-Components/LoadingComponent";

@inject("InodeViewModel", "OrgViewModel", "AuthStore", "UiStore")
@observer
export class CTDAlertLog extends Component {
  constructor(props) {
    super(props);
    this.authStore = this.props.AuthStore;
    this.inodeModel = this.props.InodeViewModel;
    this.orgModel = this.props.OrgViewModel;
    this.orgId = this.props.orgId;
    this.inodeId = this.props.nodeId;
    this.refreshData = "";
    this.state = {
      isNodeAlertLogPage: false,
      alertLogParams: {
        start_date: moment().startOf("day"),
        end_date: moment(),
      },
      percentages: {},
      searchText: "",
      filterInfo: null,
      sorterInfo: { sorter: {}, sort: {} },
      description: null,
      threat_type: null,
      source_ip: null,
      destination_ip: null,
      destination_port: null,
      cve: null,
      name: null,
      currentPage: 0,
      loading: false,
    };
  }

  componentDidMount() {
    clearInterval(this.refreshData);
    this.getData();
    this.refreshData = setInterval(() => {
      this.getData();
    }, 5 * 60 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.refreshData);
    if (this.inodeId) {
      this.inodeModel.resetNodeThreatAlertLog();
    } else {
      this.orgModel.resetOrgThreatAlertLog();
    }
    this.props.UiStore.ctdAlertLogParams = {
      searchText: "",
      filterInfo: null,
      sorterInfo: { sorter: {}, sort: {} },
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.startDate &&
      this.props.endDate &&
      (this.props.startDate !== prevProps.startDate ||
        this.props.endDate !== prevProps.endDate)
    ) {
      this.getData();
    }
  }

  getData = () => {
    let params = {};
    if (this.props.startDate && this.props.endDate) {
      params = {
        start_date: this.props.startDate.toISOString(),
        end_date: this.props.endDate.toISOString(),
        size: 5,
      };
    }

    // Threat Alert Log (Not for Threat Summary)
    let allParams = {};
    if (this.props.startDate && this.props.endDate) {
      allParams.start_date = this.props.startDate.toISOString();
      allParams.end_date = this.props.endDate.toISOString();
    }

    for (let page = 0; page <= this.inodeModel.pageable.page; page++) {
      // For page and size
      (allParams.size = 25), (allParams.page = page);

      // For search
      if (this.state.searchText) {
        allParams.search = this.state.searchText;
      } else {
        if (allParams.search) {
          delete allParams.search;
        }
      }

      // For hsn
      if (this.state.hardware_serial_number) {
        params.hardware_serial_number = this.state.hardware_serial_number;
      } else {
        if (params.hardware_serial_number) {
          delete params.hardware_serial_number;
        }
      }
      // search by description
      if (this.state.description) {
        allParams.description = this.state.description;
      } else {
        if (allParams.description) {
          delete allParams.description;
        }
      }
      // search by threat type
      if (this.state.threat_type) {
        allParams.threat_type = this.state.threat_type;
      } else {
        if (allParams.threat_type) {
          delete allParams.threat_type;
        }
      }
      // search by source ip
      if (this.state.source_ip) {
        allParams.source_ip = this.state.source_ip;
      } else {
        if (allParams.source_ip) {
          delete allParams.source_ip;
        }
      }
      // search by destination ip
      if (this.state.destination_ip) {
        allParams.destination_ip = this.state.destination_ip;
      } else {
        if (allParams.destination_ip) {
          delete allParams.destination_ip;
        }
      }
      // search by destination port
      if (this.state.destination_port) {
        allParams.destination_port = this.state.destination_port;
      } else {
        if (allParams.destination_port) {
          delete allParams.destination_port;
        }
      }
      // search by cve
      if (this.state.cve) {
        allParams.cve = this.state.cve;
      } else {
        if (allParams.cve) {
          delete allParams.cve;
        }
      }

      // search by name
      if (this.state.name) {
        allParams.name = this.state.name;
      } else {
        if (allParams.name) {
          delete allParams.name;
        }
      }

      // For sort
      if (this.state.sorterInfo.sort.by && this.state.sorterInfo.sort.order) {
        allParams.sort = `${this.state.sorterInfo.sort.by}:${this.state.sorterInfo.sort.order}`;
      } else {
        if (allParams.sort) {
          delete allParams.sort;
        }
      }

      // For Filter
      if (this.state.filterInfo) {
        let keys = Object.keys(this.state.filterInfo);
        keys.forEach(key => {
          if (this.state.filterInfo[key]) {
            allParams[key] = this.state.filterInfo[key];
          }
        });
      } else {
        if (allParams.protocol) {
          delete allParams.protocol;
        }
        if (allParams.severity) {
          delete allParams.severity;
        }
      }
      setTimeout(() => {
        this.setState({ alertLogParams: allParams }, () => {
          if (this.inodeId) {
            this.getNodeAlertLog();
            this.getNodeThreatsSummary(params);
          } else {
            this.getOrgAlertLog();
            this.getOrgThreatsSummary(params);
          }
        });
      }, 200);
    }
  };

  getNodeAlertLog = (
    loading = true,
    page = 0,
    search = "",
    description = "",
    threat_type = "",
    source_ip = "",
    destination_ip = "",
    destination_port = "",
    cve = "",
    sortBy = null,
    sortOrder = null,
    filter = null,
    forcecall = false,
    query_params = this.state.alertLogParams,
  ) => {
    this.inodeModel.getNodeThreatAlertLogLoading = true;
    let params = copyObject(query_params);
    params.page = page;
    params.size = 25;
    params.search = search;
    // search by description
    if (description) {
      params.description = description;
    } else {
      delete params.description;
    }
    // search by threat type
    if (threat_type) {
      params.threat_type = threat_type;
    } else {
      delete params.threat_type;
    }
    // search by source ip
    if (source_ip) {
      params.source_ip = source_ip;
    } else {
      delete params.source_ip;
    }
    // search by destination ip
    if (destination_ip) {
      params.destination_ip = destination_ip;
    } else {
      delete params.destination_ip;
    }
    // search by destination port
    if (destination_port) {
      params.destination_port = destination_port;
    } else {
      delete params.destination_port;
    }

    // search by start date and end date
    if (
      this.props.startDate &&
      this.props.startDate.toISOString() &&
      this.props.endDate &&
      this.props.endDate.toISOString()
    ) {
      params.start_date = this.props.startDate.toISOString();
      params.end_date = this.props.endDate.toISOString();
    } else {
      delete params.start_date;
      delete params.end_date;
    }

    // search by cve
    if (cve) {
      params.cve = cve;
    } else {
      delete params.cve;
    }
    // Sort
    if (sortBy && sortOrder) {
      params.sort = `${sortBy}:${sortOrder}`;
    }
    // Filter
    if (filter) {
      let keys = Object.keys(filter);
      keys.forEach(key => {
        if (filter[key]) {
          params[key] = filter[key];
        }
      });
    }

    this.inodeModel.setNodeThreatReportParams(params);

    InodeController.getNodeThreatAlertLog(
      this.inodeId,
      params,
      loading,
      forcecall,
    )
      .then(response => {
        if (response) {
          this.inodeModel.setNodeThreatAlertLog(response);
          this.setState({
            isNodeAlertLogPage: true,
          });
          this.inodeModel.getNodeThreatAlertLogLoading = false;
        }
      })
      .catch(err => {
        this.inodeModel.getNodeThreatAlertLogLoading = false;
      });
  };

  getNodeThreatsSummary = params => {
    this.inodeModel.getNodeThreatsSummaryLoading = true;
    InodeController.getNodeThreatsSummary(this.inodeId, params)
      .then(response => {
        if (response && response.results) {
          this.inodeModel.setNodeThreatsSummary(response.results);
          this.calculatePercentage(response.results.threats_by_severity),
            this.setState({
              isNodeAlertLogPage: true,
            });
          this.inodeModel.getNodeThreatsSummaryLoading = false;
        }
      })
      .catch(err => {
        this.inodeModel.getNodeThreatsSummaryLoading = false;
      });
  };

  getOrgAlertLog = (
    loading = true,
    page = 0,
    search = "",
    description = "",
    threat_type = "",
    source_ip = "",
    destination_ip = "",
    destination_port = "",
    cve = "",
    name = "",
    sortBy = null,
    sortOrder = null,
    filter = null,
    forcecall = false,
    query_params = this.state.alertLogParams,
  ) => {
    this.orgModel.getOrgThreatAlertLogLoading = true;
    let params = copyObject(query_params);
    params.page = page;
    params.size = 25;
    params.search = search;
    // search by description
    if (description) {
      params.description = description;
    } else {
      delete params.description;
    }
    // search by threat type
    if (threat_type) {
      params.threat_type = threat_type;
    } else {
      delete params.threat_type;
    }
    // search by source ip
    if (source_ip) {
      params.source_ip = source_ip;
    } else {
      delete params.source_ip;
    }
    // search by destination ip
    if (destination_ip) {
      params.destination_ip = destination_ip;
    } else {
      delete params.destination_ip;
    }
    // search by destination port
    if (destination_port) {
      params.destination_port = destination_port;
    } else {
      delete params.destination_port;
    }
    // search by cve
    if (cve) {
      params.cve = cve;
    } else {
      delete params.cve;
    }
    // search by node/cluster name
    if (name) {
      params.name = name;
    } else {
      delete params.name;
    }
    // search by start date and end date
    if (
      this.props.startDate &&
      this.props.startDate.toISOString() &&
      this.props.endDate &&
      this.props.endDate.toISOString()
    ) {
      params.start_date = this.props.startDate.toISOString();
      params.end_date = this.props.endDate.toISOString();
    } else {
      delete params.start_date;
      delete params.end_date;
    }

    // Sort
    if (sortBy && sortOrder) {
      params.sort = `${sortBy}:${sortOrder}`;
    }
    // Filter
    if (filter) {
      let keys = Object.keys(filter);
      keys.forEach(key => {
        if (filter[key]) {
          params[key] = filter[key];
        }
      });
    }

    this.orgModel.setOrgThreatReportParams(params);

    OrgController.getOrgThreatAlertLog(this.orgId, params, loading, forcecall)
      .then(response => {
        if (response) {
          this.orgModel.setOrgThreatAlertLog(response);
          this.setState({
            isNodeAlertLogPage: false,
          });
        }
        this.orgModel.getOrgThreatAlertLogLoading = false;
      })
      .catch(err => {
        this.orgModel.getOrgThreatAlertLogLoading = false;
      });
  };

  getOrgThreatsSummary = params => {
    this.orgModel.getOrgThreatsSummaryLoading = true;
    OrgController.getOrgThreatsSummary(this.orgId, params)
      .then(response => {
        if (response && response.results) {
          this.orgModel.setOrgThreatsSummary(response.results);
          this.setState({
            isNodeAlertLogPage: false,
          });
          this.calculatePercentage(response.results.threats_by_severity);
        }
        this.orgModel.getOrgThreatsSummaryLoading = false;
      })
      .catch(err => {
        this.orgModel.getOrgThreatsSummaryLoading = false;
      });
  };

  onTableChange = (pagination, filter, sorter) => {
    let sort = {};
    if (sorter.columnKey && sorter.order) {
      if (sorter.order === "ascend") {
        sort.order = "ASC";
      } else {
        sort.order = "DESC";
      }
      sort.by = sorter.columnKey;
    }
    this.props.UiStore.ctdAlertLogParams.filterInfo = filter;
    this.props.UiStore.ctdAlertLogParams.sorterInfo = {
      sorter: sorter,
      sort: sort,
    };
    this.setState(
      {
        filterInfo: filter,
        sorterInfo: { sorter: sorter, sort: sort },
      },
      () => {
        if (this.inodeId) {
          this.getNodeAlertLog(
            true,
            0,
            this.state.searchText,
            this.state.description,
            this.state.threat_type,
            this.state.source_ip,
            this.state.destination_ip,
            this.state.destination_port,
            this.state.cve,
            this.state.sorterInfo.sort.by,
            this.state.sorterInfo.sort.order,
            filter,
            false,
          );
        } else {
          this.getOrgAlertLog(
            true,
            0,
            this.state.searchText,
            this.state.description,
            this.state.threat_type,
            this.state.source_ip,
            this.state.destination_ip,
            this.state.destination_port,
            this.state.cve,
            this.state.name,
            this.state.sorterInfo.sort.by,
            this.state.sorterInfo.sort.order,
            this.state.filterInfo,
            false,
          );
        }
      },
    );
  };

  clearSearchFilter = value => {
    this.setState(
      {
        searchText: "",
        description: "",
        threat_type: "",
        source_ip: "",
        destination_ip: "",
        destination_port: "",
        cve: "",
        name: "",
      },
      () => {
        if (this.inodeId) {
          this.getNodeAlertLog(
            true,
            0,
            "",
            this.state.description,
            this.state.threat_type,
            this.state.source_ip,
            this.state.destination_ip,
            this.state.destination_port,
            this.state.cve,
            this.state.sorterInfo.sort.by,
            this.state.sorterInfo.sort.order,
            this.state.filterInfo,
            false,
          );
        } else {
          this.getOrgAlertLog(
            true,
            0,
            "",
            this.state.description,
            this.state.threat_type,
            this.state.source_ip,
            this.state.destination_ip,
            this.state.destination_port,
            this.state.cve,
            this.state.name,
            this.state.sorterInfo.sort.by,
            this.state.sorterInfo.sort.order,
            this.state.filterInfo,
            false,
          );
        }
      },
    );
    this.props.UiStore.ctdAlertLogParams.searchText = "";
  };

  calculatePercentage = data => {
    const total = Object.values(data).reduce((sum, value) => sum + value, 0);
    const percentages = {};
    for (const key in data) {
      percentages[key] = ((data[key] / total) * 100).toFixed(0);
      if (percentages[key] == "NaN") {
        percentages[key] = 0;
      }
    }
    this.setState({ percentages });
  };

  formSubmit = values => {
    this.setState(
      {
        sorterInfo: { sorter: {}, sort: {} },
        description: values.description,
        threat_type: values.threat_type,
        source_ip: values.source_ip,
        destination_ip: values.destination_ip,
        destination_port: values.destination_port,
        cve: values.cve,
        name: values.name,
      },
      () => {
        if (this.inodeId) {
          this.getNodeAlertLog(
            false,
            0,
            "",
            this.state.description,
            this.state.threat_type,
            this.state.source_ip,
            this.state.destination_ip,
            this.state.destination_port,
            this.state.cve,
            this.state.sorterInfo.sort.by,
            this.state.sorterInfo.sort.order,
            this.state.filterInfo,
            false,
          );
        } else {
          this.getOrgAlertLog(
            false,
            0,
            "",
            this.state.description,
            this.state.threat_type,
            this.state.source_ip,
            this.state.destination_ip,
            this.state.destination_port,
            this.state.cve,
            this.state.name,
            this.state.sorterInfo.sort.by,
            this.state.sorterInfo.sort.order,
            this.state.filterInfo,
            false,
          );
        }
      },
    );
  };

  /**Page Changer for pagination */
  onPageChange = (page, pageSize) => {
    let currentPage = page - 1;
    this.setState({ currentPage: currentPage }, () => {
      if (this.inodeId) {
        this.getNodeAlertLog(
          false,
          currentPage,
          "",
          this.state.description,
          this.state.threat_type,
          this.state.source_ip,
          this.state.destination_ip,
          this.state.destination_port,
          this.state.cve,
          this.state.sorterInfo.sort.by,
          this.state.sorterInfo.sort.order,
          this.state.filterInfo,
          false,
        );
      } else {
        this.getOrgAlertLog(
          false,
          currentPage,
          "",
          this.state.description,
          this.state.threat_type,
          this.state.source_ip,
          this.state.destination_ip,
          this.state.destination_port,
          this.state.cve,
          this.state.name,
          this.state.sorterInfo.sort.by,
          this.state.sorterInfo.sort.order,
          this.state.filterInfo,
          false,
        );
      }
    });
  };

  render() {
    let model = this.inodeId ? "inodeModel" : "orgModel";
    let nodeAlertLog,
      orgAlertLog = [];
    let nodeThreatSummary,
      orgThreatSummary = {};
    if (model == "inodeModel") {
      nodeAlertLog = toJS(this.inodeModel.nodeThreatAlertLog);
      nodeThreatSummary = this.inodeModel.nodeThreatsSummary;
    } else {
      orgAlertLog = toJS(this.orgModel.orgThreatAlertLog);
      orgThreatSummary = this.orgModel.orgThreatsSummary;
    }
    let { filterInfo, sorterInfo } = this.state;
    filterInfo = filterInfo || {};
    sorterInfo = sorterInfo || {};
    const columns = [
      {
        title: "Time",
        dataIndex: "timestamp",
        key: "timestamp",
        width: "18%",
        sorter: true,
        ellipsis: true,
        sortOrder:
          sorterInfo.sorter.columnKey === "timestamp" &&
          sorterInfo.sorter.order,
        render: (text, record, index) => {
          let date = new Date(record.timestamp);
          return date.toString();
        },
      },
      {
        title: "Threat Description",
        dataIndex: "description",
        key: "description",
        width: "16%",
        ellipsis: true,
        render: (text, record, index) => {
          return (
            <a
              href={record.reference ? record.reference : null}
              style={{ textDecoration: record.reference ? "underline" : "" }}
              title={record.reference ? record.reference : ""}
              target="_blank"
              rel="noopener noreferrer"
            >
              {record.description}
            </a>
          );
        },
      },
      {
        title: "Threat Type",
        dataIndex: "threat_type",
        key: "threat_type",
        width: "10%",
        ellipsis: true,
      },
      {
        title: "iNode/Cluster",
        dataIndex: "name",
        key: "name",
        width: "14%",
        ellipsis: true,
        render: (text, record, index) => {
          return (
            <div>
              {record.cluster && record.cluster.name
                ? record.cluster.name
                : record.node.name}
            </div>
          );
        },
      },
      {
        title: "Severity",
        dataIndex: "severity",
        key: "severity",
        width: "10%",
        ellipsis: true,
        filters: [
          {
            text: "HIGH",
            value: "high",
          },
          {
            text: "MEDIUM",
            value: "medium",
          },
          {
            text: "LOW",
            value: "low",
          },
        ],
        filterMultiple: true,
        filteredValue: filterInfo.severity || null,
        render: (text, record, index) => {
          return <div>{record.severity}</div>;
        },
      },
      {
        title: "Source",
        dataIndex: "source_ip",
        key: "source",
        width: "12%",
        ellipsis: true,
      },
      {
        title: "Destination",
        dataIndex: "destination_ip",
        key: "destination",
        width: "12%",
        ellipsis: true,
      },
      {
        title: "Protocol",
        dataIndex: "protocol",
        key: "protocol",
        width: "10%",
        ellipsis: true,
        filters: [
          {
            text: "ICMP",
            value: "ICMP",
          },
          {
            text: "UDP",
            value: "UDP",
          },
          {
            text: "TCP",
            value: "TCP",
          },
        ],
        filteredValue: filterInfo.protocol || null,
        render: (text, record, index) => {
          return <div>{record.protocol}</div>;
        },
      },
      {
        title: "Destination Port",
        dataIndex: "destination_port",
        key: "destination_port",
        width: "8%",
        ellipsis: true,
      },
      {
        title: "Reference",
        dataIndex: "cve",
        key: "cve",
        width: "10%",
        ellipsis: true,
      },
    ];

    // Splice node/cluster column in nodelevel Alert log table.
    if (this.nodeId || this.state.isNodeAlertLogPage) {
      columns.splice(3, 1);
    }

    let searchFields = [
      {
        id: "description",
        name: "Description",
        type: "text",
        span: "4",
      },
      {
        id: "threat_type",
        name: "Threat Type",
        type: "text",
        span: "4",
      },
      {
        id: "name",
        name: "iNode/Cluster",
        type: "text",
        span: "4",
      },
      { id: "source_ip", name: "Source", type: "text", span: "4" },
      {
        id: "destination_ip",
        name: "Destination",
        type: "text",
        span: "4",
      },
      {
        id: "destination_port",
        name: "Destination Port",
        type: "text",
        span: "4",
      },
      { id: "cve", name: "Reference", type: "text", span: "4" },
    ];
    // Splice node/cluster search
    if (this.nodeId || this.state.isNodeAlertLogPage) {
      searchFields.splice(2, 1);
    }
    return (
      <>
        <LoadingComponent
          loading={
            this.state.isNodeAlertLogPage
              ? this.inodeModel.loading
              : this.orgModel.loading
          }
        >
          <Row className="ellipsis pb-15">
            <Col span={4}>
              <div style={{ fontSize: "3em" }}>
                <span>{this.state.percentages.high}%</span>
              </div>
              <b>High severity</b>
            </Col>
            <Col span={4}>
              <div style={{ fontSize: "3em" }}>
                <span>{this.state.percentages.medium}%</span>
              </div>
              <b>Medium severity</b>
            </Col>
            <Col span={4}>
              <div style={{ fontSize: "3em" }}>
                <span>{this.state.percentages.low}%</span>
              </div>
              <b>Low severity</b>
            </Col>
            <Col span={8}>
              {this.state.isNodeAlertLogPage ? (
                <div style={{ fontSize: "3em" }}>
                  {nodeThreatSummary &&
                  nodeThreatSummary.most_affected_device &&
                  nodeThreatSummary.most_affected_device.ip
                    ? nodeThreatSummary.most_affected_device.ip
                    : "N/A"}
                </div>
              ) : (
                <div
                  className="ellipsis"
                  style={{ fontSize: "2em", marginTop: "21px" }}
                >
                  {orgThreatSummary &&
                  orgThreatSummary.most_affected_inode &&
                  orgThreatSummary.most_affected_inode.node &&
                  orgThreatSummary.most_affected_inode.node.name
                    ? orgThreatSummary.most_affected_inode.node.name
                    : "N/A"}
                </div>
              )}
              {this.state.isNodeAlertLogPage ? (
                <b>Top IP facing attacks/threats</b>
              ) : (
                <b>Top Node facing attacks/threats</b>
              )}
            </Col>
            <Col span={4}>&nbsp;</Col>
          </Row>
          <TableSearch
            fileds={searchFields}
            onSearchClick={this.formSubmit}
            onResetClick={this.clearSearchFilter}
          />
          <Row>
            <TableLayout
              size={"small"}
              bordered={false}
              columns={columns}
              onChange={this.onTableChange}
              dataSource={
                this.state.isNodeAlertLogPage
                  ? nodeAlertLog && nodeAlertLog.results
                  : orgAlertLog && orgAlertLog.results
              }
              pagination={false}
              itemPerPage={25}
              className="inodeList"
              rowExpandable={false}
              hideRowSelection={true}
              showExpandColumn={false}
            />
          </Row>
          <Row style={{ marginTop: "25px", float: "right" }}>
            <Pagination
              showQuickJumper
              defaultCurrent={1}
              pageSize={25}
              total={
                this.state.isNodeAlertLogPage
                  ? nodeAlertLog && nodeAlertLog.total_count
                  : orgAlertLog && orgAlertLog.total_count
              }
              showSizeChanger={false}
              size={"small"}
              onChange={this.onPageChange}
            />
          </Row>
        </LoadingComponent>
      </>
    );
  }
}
