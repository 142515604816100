import React, { Component } from "react";
import { getCurrentlocaleText } from "Core/Utils";

class GenerateSSHKeyLinkHelp extends Component {
  render() {
    return (
      <div>
        <p>{getCurrentlocaleText("certificate.inode.add.help.text.1")}</p>
        <p>
          {getCurrentlocaleText("certificate.inode.add.help.text.2.1")}
          <strong>
            {getCurrentlocaleText("certificate.inode.add.help.text.2.2")}
          </strong>
          {getCurrentlocaleText("certificate.inode.add.help.text.2.3")}
        </p>
      </div>
    );
  }
}
export default GenerateSSHKeyLinkHelp;
