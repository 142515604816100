import React, { Component } from "react";
import { Row, Col } from "antd";
import IoTiumSpin from "components/UI-Components/spin";

class Loading extends Component {
  render() {
    return (
      <div className="invalid-access-container">
        <center>
          <Row gutter={16}>
            <Col span={24}>
              <div className="loader">
                <div className="warpper">
                  <IoTiumSpin spinning={true} tip="Loading...">
                    <div />
                  </IoTiumSpin>
                </div>
              </div>
            </Col>
          </Row>
        </center>
      </div>
    );
  }
}

export default Loading;
