import React, { Component } from "react";
import { Input, Select, Radio } from "antd";
import { Form } from "@ant-design/compatible";
import { observer, inject } from "mobx-react";
import _ from "lodash";
import LicenseFormControls from "./LicenseFormControls";
import { getCurrentlocaleText } from "Core/Utils";
import FormErrorMessage from "components/UI-Components/FormErrorMessage";

const certificateObj = {
  help: {
    title: "Select License file",
    data: [
      {
        subtitle: "",
        content: "Select licenses from the previously uploaded license files.",
      },
    ],
  },
};

@inject("ModelStore", "UiStore")
@observer
class LicenseForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        secret_volume: {},
      },
      licenseType: null,
      licenseName: null,
    };
  }

  componentDidMount() {
    let helpTitle =
      this.props.extra.type === "license"
        ? "Licenses"
        : this.props.extra.type === "ssh"
        ? getCurrentlocaleText("ssh.keys")
        : this.props.extra.type === "dockerconfig"
        ? getCurrentlocaleText("dockerconfig")
        : getCurrentlocaleText("general.service_secrets.label.text");

    this.props.UiStore.SetStoreData("helpData", {
      title: helpTitle,
      data: [],
    });
    this.props.UiStore.serviceFormData["secret_volume"] = {};
  }

  updateHelp = data => {
    this.props.UiStore.SetStoreData("helpData", data);
  };

  createLicenseFormControl = list => {
    return list.options.map((val, i) => {
      return (
        <Radio
          value={val.value}
          key={val.value}
          disabled={this.props.type === "edit" && val.value === "NEW"}
        >
          {val.label}
        </Radio>
      );
    });
  };

  onLicenseChange = (obj, e) => {
    this.setState({ licenseType: e.target.value });
    this.props.UiStore.serviceFormData["licenseType"] = e.target.value;
  };

  inputChange = input => {
    this.setState({ licenseName: input.target.value });
    this.props.UiStore.serviceFormData[input.target.id] = input.target.value;
  };

  openFile = event => {
    let input = event.target,
      newFileName;
    /*To support multiple certificate upload*/
    for (let i = 0; i < input.files.length; i++) {
      let reader = new FileReader();
      reader.onloadend = (file => {
        return evt => {
          if (
            this.props.extra.type === "license" &&
            this.props.actionType === "edit"
          ) {
            let isFileNameAvailable = this.props.UiStore.serviceFormData.uploadedLicenseFileName.indexOf(
              file.name,
            );
            let lastFileCount,
              splitLastMatchedValue = [];
            if (isFileNameAvailable >= 0) {
              /*file name exists. Find the last integer value*/

              let filecount = this.props.UiStore.serviceFormData.uploadedLicenseFileName.map(
                val => {
                  let nametosearch = val.split("_");
                  nametosearch.pop();
                  return (
                    nametosearch.toString() === file.name &&
                    val.indexOf("_") >= 0 &&
                    parseInt(val.split("_")[val.split("_").length - 1])
                  );
                },
              );
              filecount = _.compact(
                filecount,
              ); /*To remove falsy from the array*/

              _.max(filecount)
                ? (newFileName = `${file.name}_${_.max(filecount) + 1}`)
                : (newFileName = `${file.name}_1`);
            } else {
              newFileName = `${file.name}`;
            }

            let val = btoa(evt.target.result); //Base64 encoding
            this.props.UiStore.serviceFormData.uploadedLicenseFileName.push(
              newFileName,
            ); /*update the uploaded license list*/

            this.setState(() => {
              let newstate = {
                secret_volume: this.state.formData.secret_volume,
              };
              newstate.secret_volume[newFileName] = val;

              return newstate;
            });
            this.props.UiStore.serviceFormData.secret_volume[newFileName] = val;
          } else {
            let key =
                this.props.extra.type === "ssh"
                  ? "authorized_keys"
                  : `${file.name}`,
              val = btoa(evt.target.result); //Base64 encoding
            this.setState(() => {
              let newstate = {
                secret_volume: this.state.formData.secret_volume,
              };
              newstate.secret_volume[key] = val;

              return newstate;
            });
            this.props.UiStore.serviceFormData.secret_volume[key] = val;
          }
        };
      })(input.files[i]);
      reader.readAsBinaryString(input.files[i]);
    }
  };

  // Not required for now - LAT- 2461

  // serviceSelected = (value, key, instance) => {
  //   this.props.UiStore.serviceFormData.certi_list = [];
  //   this.props.UiStore.serviceFormData.certi_list.push(...key);
  //   var certDetails = {};
  //   if (key) {
  //     /*Multi-select enabled.*/

  //     if (key.length > 0) {
  //       for (let i in key) {
  //         this.props.ModelStore.secret.map(val => {
  //           val.id == key[i]
  //             ? (certDetails = {
  //                 ...certDetails,
  //                 ...val.data,
  //               })
  //             : "";
  //         });
  //       }
  //     }
  //   } else {
  //     this.props.UiStore.serviceFormData.previousCerti = {};
  //   }
  //   this.props.UiStore.serviceFormData.previousCerti = {
  //     ...this.props.UiStore.serviceFormData.previousCerti,
  //     ...certDetails,
  //   };
  // };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Form noValidate onSubmit={this.props.onSubmit} className="login-form">
          <LicenseFormControls
            fileUpload={this.openFile}
            multiple={false}
            referenceForm={this.props.form}
            updateHelp={this.updateHelp}
            inputChange={this.inputChange.bind(this)}
            //serviceSelected={this.serviceSelected}
            mode="multiple"
            renderFrom="licenseComponent"
            certificateObj={certificateObj}
            {...this.props}
          />
          <FormErrorMessage />
        </Form>
      </div>
    );
  }
}

const LicenseForms = Form.create()(LicenseForm);
export default LicenseForms;
