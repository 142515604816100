import React, { Component } from "react";
import { Modal } from "antd";
import {
  getCurrentlocaleText,
  formatTimestampToIotiumLastAtTimeString,
} from "Core/Utils";
import UserController from "controller/UserController";
import { observer, inject } from "mobx-react";

@inject("UiStore")
@observer
class ResendVerificationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibility: this.props.visibility,
      user_info: this.props.user_info,
      user_email:
        this.props.user_info && this.props.user_info.emailaddress
          ? this.props.user_info.emailaddress
          : null,
      requestInProgress: false,
    };
  }
  UNSAFE_componentWillReceiveProps(newprops) {
    if (this.prop != newprops) {
      this.setState({
        visibility: newprops.visibility,
        user_info: newprops.user_info,
        user_email: newprops.user_info.emailaddress,
        requestInProgress: newprops.requestInProgress,
      });
    }
  }
  ignoreAlert = () => {
    this.setState({ requestInProgress: false });
    this.props.hidePopup();
  };
  SendVerificationLink = () => {
    this.SendVerificationEmail();
  };

  SendVerificationEmail = isname => {
    let map = { id: this.state.user_info.id },
      params = {};
    if (this.state.requestInProgress) {
      params.force = true;
    } else {
      params = {};
    }
    UserController.SendVerifyEmailLink(map, params)
      .then(response => {
        this.props.UiStore.successMessage = getCurrentlocaleText(
          "user.support.resend_email.successmessage",
        );
        this.setState({ requestInProgress: false });
        this.props.hidePopup();
        this.props.updateAfterCall();
      })
      .catch(error => {
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.status === 409
        ) {
          if (
            this.state.requestInProgress &&
            error.response.data &&
            error.response.data.message
          ) {
            this.props.UiStore.errorMessage = error.response.data.message;
            this.props.hidePopup();
            this.props.updateAfterCall();
          }
          this.setState({ requestInProgress: true });
        }
      });
  };

  render() {
    let { user_info, user_email, requestInProgress } = this.state;
    return (
      <div>
        <Modal
          title={
            requestInProgress &&
            this.state.user_info &&
            this.state.user_info.last_triggerred_at
              ? getCurrentlocaleText("user.support.force_resend_email.title")
              : getCurrentlocaleText("user.support.resend_email.title")
          }
          visible={this.state.visibility}
          onOk={this.SendVerificationLink}
          onCancel={this.ignoreAlert}
          maskClosable={false}
          okText={
            requestInProgress &&
            this.state.user_info &&
            this.state.user_info.last_triggerred_at
              ? getCurrentlocaleText(
                  "user.support.resend_email.forcebutton.text2",
                )
              : getCurrentlocaleText("user.support.resend_email.button.text2")
          }
          cancelText={getCurrentlocaleText(
            "user.support.cancel_email.forcebutton.text2",
          )}
        >
          {requestInProgress &&
          this.state.user_info &&
          this.state.user_info.last_triggerred_at ? (
            <span>
              <h4 style={{ marginBottom: 15 }}>
                {getCurrentlocaleText(
                  "user.support.resend_email.requestpending.message",
                  {
                    0: formatTimestampToIotiumLastAtTimeString(
                      this.state.user_info.last_triggerred_at,
                    ),
                  },
                )}
              </h4>{" "}
            </span>
          ) : (
            <div>
              <h4 style={{ marginBottom: 20 }}>
                {getCurrentlocaleText(
                  "user.support.resend_email.intial.message",
                )}
              </h4>
              <span className="resend-modal-user-email">{user_email}</span>
            </div>
          )}
        </Modal>
      </div>
    );
  }
}

export default ResendVerificationModal;
