import React, { Component } from "react";
import { Card, Col, Row, Select } from "antd";
import { getInterval, getCurrentlocaleText, customSort } from "Core/Utils";
import { observer, inject } from "mobx-react";
import Icons from "components/UI-Components/Icons";
import { NetworkController } from "../../../controller/Controllers";
import { LoadingOutlined } from "@ant-design/icons";

const { Option } = Select;

@inject(
  "AuthStore",
  "EventViewModel",
  "ServiceStatsViewModel",
  "NodeStatsViewModel",
)
@observer
class ServiceNetworkOverview extends Component {
  constructor(props) {
    super(props);
    // GLOBAL_SETTINGS is configured form node js
    this.GLOBAL_SETTINGS = GLOBAL_SETTINGS;
    this.textColor =
      this.GLOBAL_SETTINGS && this.GLOBAL_SETTINGS.text_color
        ? this.GLOBAL_SETTINGS.text_color
        : "#365c6fs";
    this.primaryColor =
      this.GLOBAL_SETTINGS && this.GLOBAL_SETTINGS.primary_color
        ? this.GLOBAL_SETTINGS.primary_color
        : "#365c6f";
    this.state = {
      interfaces: this.props.NodeStatsViewModel.getStatsAndInterfaces
        .interfaces,
      currentInterface: "eth0",
      currentContainer:
        this.props.NodeStatsViewModel.service_list_view &&
        this.props.NodeStatsViewModel.service_list_view[0]
          ? this.props.NodeStatsViewModel.service_list_view[0].name
          : null,
      computed_networks: [],
    };
  }

  humanize = (num, size, units) => {
    var unit;
    for (unit = units.pop(); units.length && num >= size; unit = units.pop()) {
      num /= size;
    }
    return [num, unit];
  };

  // Get the index of the interface with the specified name.
  getNetworkInterfaceIndex = (interfaceName, interfaces) => {
    if (interfaces) {
      for (var i = 0; i < interfaces.length; i++) {
        if (interfaces[i].name == interfaceName) {
          return i;
        }
      }
    }
    return -1;
  };

  UNSAFE_componentWillReceiveProps(newprops) {
    if (this.props != newprops) {
      this.setState({
        interfaces:
          newprops.NodeStatsViewModel.getStatsAndInterfaces.interfaces,
      });
      this.updateInterface();
    }
  }

  updateInterface = () => {
    let interfaces = this.state.interfaces;
    if (interfaces && interfaces.length > 0) {
      interfaces.forEach(data => {
        if (data.name.startsWith("n-")) {
          let computed_networks = this.state.computed_networks;
          if (!computed_networks.includes(data.name)) {
            this.checkForNeworkName(data.name);
          }
        }
      });
    }
  };

  checkForNeworkName = name => {
    if (name && name.startsWith("n-")) {
      let networkIds = name.split("_");
      let fromNetwork = {};
      let toNetwork = {};
      let computed_networks = this.state.computed_networks;

      if (
        networkIds &&
        networkIds.length === 2 &&
        !computed_networks.includes(name)
      ) {
        NetworkController.getSingleNetworkById(networkIds[0]).then(res => {
          fromNetwork = res;
          NetworkController.getSingleNetworkById(networkIds[1]).then(res2 => {
            toNetwork = res2;
            this.setState({
              [name]:
                fromNetwork.name +
                " \u27F7 " +
                toNetwork.node.name +
                "/" +
                toNetwork.name,
              computed_networks: computed_networks,
            });
          });
        });
      }
    } else {
      return name;
    }
  };

  // Following the Metric naming convention
  humanizeMetric = (num, state = false) => {
    var ret = this.humanize(
      num,
      1000,
      !state
        ? ["Tbps", "Gbps", "Mbps", "Kbps", "bps"]
        : ["TB", "GB", "MB", "KB", "Bytes"],
    );
    return (ret[0] === 0 ? 0 : ret[0].toFixed(2)) + " " + ret[1];
  };
  getBytesRx = (showSpeed = true) => {
    let currentContainer = this.state.currentContainer
      ? this.state.currentContainer
      : this.props.NodeStatsViewModel.service_list_view &&
        this.props.NodeStatsViewModel.service_list_view.sort((x, y) =>
          customSort(x.name, y.name),
        )[0] &&
        this.props.NodeStatsViewModel.service_list_view.sort((x, y) =>
          customSort(x.name, y.name),
        )[0].name;
    let data = [];
    if (currentContainer) {
      data = this.props.NodeStatsViewModel.allServiceStatsOverview[
        currentContainer
      ];
    }
    let currentUsages = this.props.NodeStatsViewModel
      .allServiceCurrentStatsOverview[currentContainer];
    if (data && data.length > 2 && currentUsages && currentUsages.interfaces) {
      let cur =
        data[0].network && data[0].network.interfaces
          ? data[0].network.interfaces
          : null;
      let currentInterfaceIndex = this.getNetworkInterfaceIndex(
        this.props.NodeStatsViewModel.currentInterface
          ? this.props.NodeStatsViewModel.currentInterface
          : this.props.InodeViewModel.interfacesList
          ? this.props.InodeViewModel.interfacesList
          : cur[0].name
          ? cur[0].name
          : null,
        currentUsages.interfaces,
      );
      var intervalInSec =
        getInterval(data[0].timestamp, data[1].timestamp) / 1000000000;
      if (
        cur &&
        currentUsages.interfaces[currentInterfaceIndex] &&
        currentUsages.interfaces[currentInterfaceIndex].current_rx_bytes
      ) {
        if (showSpeed) {
          return this.humanizeMetric(
            currentUsages.interfaces[currentInterfaceIndex].current_rx_bytes,
          );
        } else {
          return this.humanizeMetric(
            currentUsages.interfaces[currentInterfaceIndex].current_rx_bytes *
              intervalInSec,
            true,
          );
        }
      } else {
        if (!showSpeed) {
          return this.humanizeMetric(0, true);
        }
      }
    }
  };

  getBytesTx = (showSpeed = true) => {
    let currentContainer = this.state.currentContainer
      ? this.state.currentContainer
      : this.props.NodeStatsViewModel.service_list_view &&
        this.props.NodeStatsViewModel.service_list_view.sort((x, y) =>
          customSort(x.name, y.name),
        )[0] &&
        this.props.NodeStatsViewModel.service_list_view.sort((x, y) =>
          customSort(x.name, y.name),
        )[0].name;
    let data = [];
    if (currentContainer) {
      data = this.props.NodeStatsViewModel.allServiceStatsOverview[
        currentContainer
      ];
    }
    let currentUsages = this.props.NodeStatsViewModel
      .allServiceCurrentStatsOverview[currentContainer];
    if (data && data.length > 2 && currentUsages && currentUsages.interfaces) {
      let cur =
        data[0].network && data[0].network.interfaces
          ? data[0].network.interfaces
          : null;
      let currentInterfaceIndex = this.getNetworkInterfaceIndex(
        this.props.NodeStatsViewModel.currentInterface
          ? this.props.NodeStatsViewModel.currentInterface
          : this.props.InodeViewModel.interfacesList
          ? this.props.InodeViewModel.interfacesList
          : cur[0].name
          ? cur[0].name
          : null,
        currentUsages.interfaces,
      );
      var intervalInSec =
        getInterval(data[0].timestamp, data[1].timestamp) / 1000000000;
      if (
        cur &&
        currentUsages.interfaces[currentInterfaceIndex] &&
        currentUsages.interfaces[currentInterfaceIndex].current_tx_bytes
      ) {
        if (showSpeed) {
          return this.humanizeMetric(
            currentUsages.interfaces[currentInterfaceIndex].current_tx_bytes,
          );
        } else {
          return this.humanizeMetric(
            currentUsages.interfaces[currentInterfaceIndex].current_tx_bytes *
              intervalInSec,
            true,
          );
        }
      } else {
        if (!showSpeed) {
          return this.humanizeMetric(0, true);
        }
      }
    }
  };

  getServiceForView = () => {
    let options = this.props.NodeStatsViewModel.service_list_view
      ? this.props.NodeStatsViewModel.service_list_view
      : [];
    if (options.length > 0)
      options = options.sort((x, y) => customSort(x.name, y.name));

    let containerOptions = [];
    if (options.length > 0) {
      options.forEach((item, i) => {
        containerOptions.push(
          <Option key={i} value={item.name} title={item.name}>
            {item.name}
          </Option>,
        );
      });
    }

    return containerOptions;
  };

  changeContainer = value => {
    this.setState({ currentContainer: value });
  };

  replaceInterfaceName = name => {
    if (name.startsWith("n-")) {
      return this.state[name] ? this.state[name] : name;
    } else {
      let multinic_interfaces = this.props.InodeViewModel
        .multiNicInterfacesList;
      if (multinic_interfaces && multinic_interfaces.length > 0) {
        let interface_name = null;
        multinic_interfaces.forEach(multiNicInterface => {
          if (multiNicInterface.name == name) {
            interface_name = multiNicInterface.display_name + " (" + name + ")";
          }
        });
        return interface_name ? interface_name : name;
      }
      return name;
    }
  };

  render() {
    let currentContainer = this.state.currentContainer
      ? this.state.currentContainer
      : this.props.NodeStatsViewModel.service_list_view &&
        this.props.NodeStatsViewModel.service_list_view.sort((x, y) =>
          customSort(x.name, y.name),
        )[0] &&
        this.props.NodeStatsViewModel.service_list_view.sort((x, y) =>
          customSort(x.name, y.name),
        )[0].name;

    return (
      <Card
        title={
          <span>
            <Row>
              <Col span={12}>
                <span>{"Service Overview"}</span>
              </Col>
              <Col span={12} style={{ top: 8 }}>
                {this.props.NodeStatsViewModel.service_list_view &&
                  this.props.NodeStatsViewModel.service_list_view.length >
                    0 && (
                    <span>
                      <Select
                        key={"interface"}
                        value={currentContainer}
                        onChange={this.changeContainer}
                        className="network-over-selector"
                        width={50}
                        notFoundContent={getCurrentlocaleText(
                          "general.notfound.placeholder.text",
                        )}
                      >
                        {this.getServiceForView()}
                      </Select>
                    </span>
                  )}
              </Col>
            </Row>
          </span>
        }
        bordered={false}
        hoverable={false}
        bodyStyle={{
          display: "grid",
          justifyContent: "center",
        }}
      >
        <div style={{ display: "flex" }}>
          {" "}
          <span>
            <Card
              bordered={false}
              hoverable={false}
              bodyStyle={{ paddingBottom: 0, paddingTop: 12 }}
            >
              <Card
                bordered={true}
                hoverable={false}
                bodyStyle={{ paddingBottom: 0 }}
              >
                <Row>
                  <Col span={24}>
                    <div
                      title={getCurrentlocaleText(
                        "stats.traffic_overview.title.text",
                      )}
                      className=" stats-dashboard-service-network-overview network-over-view-header network-over-view-header-text"
                    >
                      {getCurrentlocaleText(
                        "stats.traffic_overview.title.text",
                      )}
                      {this.replaceInterfaceName(
                        this.props.NodeStatsViewModel.currentInterface
                          ? this.props.NodeStatsViewModel.currentInterface
                          : this.props.InodeViewModel.interfacesList
                          ? this.props.InodeViewModel.interfacesList
                          : "eth0",
                      ).startsWith("n-") ? (
                        <LoadingOutlined />
                      ) : (
                        " (" +
                        this.replaceInterfaceName(
                          this.props.NodeStatsViewModel.currentInterface
                            ? this.props.NodeStatsViewModel.currentInterface
                            : this.props.InodeViewModel.interfacesList
                            ? this.props.InodeViewModel.interfacesList
                            : "eth0",
                        ) +
                        ")"
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Card bordered={false} bodyStyle={{ paddingBottom: 0 }}>
                      <Row>
                        <Col span={24}>
                          <Icons
                            className="inode-card-number-icon"
                            type="io"
                            name="IoIosArrowRoundDown"
                            style={{ paddingBottom: 16, marginBottom: -20 }}
                          />
                        </Col>
                      </Row>
                      <Row style={{ paddingBottom: 40 }}>
                        <Col span={24}>
                          <span
                            title={
                              this.getBytesRx() ? this.getBytesRx() : "0 bps"
                            }
                            className="stats-network-overview-count"
                            style={{ paddingBottom: 16, marginBottom: -20 }}
                          >
                            {this.getBytesRx() ? this.getBytesRx() : "0 bps"}
                          </span>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card bordered={false} bodyStyle={{ paddingBottom: 0 }}>
                      <Row>
                        <Col span={24}>
                          <Icons
                            type="io"
                            name="IoIosArrowRoundUp"
                            className="inode-card-number-icon"
                            style={{ paddingBottom: 16, marginBottom: -20 }}
                          />
                        </Col>
                      </Row>
                      <Row style={{ paddingBottom: 40 }}>
                        <Col span={24}>
                          <span
                            title={
                              this.getBytesTx() ? this.getBytesTx() : "0 bps"
                            }
                            className="stats-network-overview-count"
                            style={{ paddingBottom: 16 }}
                          >
                            {this.getBytesTx() ? this.getBytesTx() : "0 bps"}
                          </span>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Card>
              <Row type="flex" align="middle" style={{ marginTop: 12 }}>
                <Col span={24}>
                  <Card
                    bordered={true}
                    hoverable={false}
                    bodyStyle={{ textAlign: "center" }}
                  >
                    <Row>
                      <Col span={24}>
                        <strong>{"Transmit/Receive: "}</strong>
                        {this.getBytesTx(false) && this.getBytesRx(false)
                          ? this.getBytesTx(false) +
                            "/" +
                            this.getBytesRx(false)
                          : "NA"}
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Card>
          </span>
        </div>
      </Card>
    );
  }
}

export default ServiceNetworkOverview;
