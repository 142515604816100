import React, { Component } from "react";
import { Form, Select } from "antd";
import {
  customSort,
  highlightSearchText,
  getCurrentlocaleText,
} from "Core/Utils";
import CertificateController from "controller/CertificateController";
import Icons from "./Icons";

const FormItem = Form.Item;
const Option = Select.Option;

class CertificateSelect extends Component {
  constructor(props) {
    super(props);
    this.searthTimeout = null;
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    let params = this.props.params || {};
    params.page = 0;
    CertificateController.getSecretsForDropdown(params).then(response => {
      this.setState({
        data: response ? this.renderList(response) : [],
      });
    });
  }

  fetchCertificates = (search = "") => {
    clearTimeout(this.searthTimeout);
    this.searthTimeout = setTimeout(() => {
      let params = this.props.params || {};
      params.page = 0;
      params.search = search;
      CertificateController.getSecretsForDropdown(params).then(response => {
        this.setState({
          data: response ? this.renderList(response, search) : [],
        });
      });
    }, 500);
  };

  renderList(certificates, search = "") {
    if (certificates.length > 0) {
      let keys = certificates.map(key => {
        return {
          id: key.id,
          ssh_key: key.name,
        };
      });
      return keys.length > 0 ? (
        keys
          .sort((x, y) => customSort(x.ssh_key, y.ssh_key))
          .map(item => {
            return (
              <Option key={item.id} value={item.id} title={item.ssh_key}>
                {search.length > 0
                  ? highlightSearchText(item.ssh_key, search)
                  : item.ssh_key}
              </Option>
            );
          })
      ) : (
        <Option key={1} disabled>
          Not Found
        </Option>
      );
    } else {
      return (
        <Option key={1} disabled>
          Not Found
        </Option>
      );
    }
  }

  setValue = (value, label) => {
    this.props.setSelectVal({
      value,
      type: "ssh_key",
    });
    if (value) {
      setTimeout(() => {
        let element = document.querySelector(
          ".ant-select-selection-selected-value strong",
        );
        if (element) {
          element.style.fontWeight = "400";
        }
      }, 50);
    }
  };

  render() {
    return (
      <Select
        showSearch
        filterOption={false}
        onSearch={this.fetchCertificates}
        onChange={this.setValue}
        placeholder={getCurrentlocaleText(
          "certificate.select.placeholder.text",
        )}
        allowClear={true}
        prefix={
          <Icons type="ai" name="AiOutlineIdcard" style={{ fontSize: 13 }} />
        }
        defaultValue={this.props.initialValue}
        disabled={this.props.disabled ? this.props.disabled : false}
        notFoundContent={getCurrentlocaleText(
          "general.notfound.placeholder.text",
        )}
      >
        {this.state.data}
      </Select>
    );
  }
}

export default CertificateSelect;
