import React, { Component } from "react";
import { Layout } from "antd";
import MessageDisplay from "Core/MessageDisplay";
import DocumentTitle from "Core/DocumentTitle";
import IoLayouts from "components/layout";
import UiStore from "Stores/UiStore";
import IoFooter from "./Footer";

const { IoSidebar, IoMain } = IoLayouts;

class MainLayout extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    setTimeout(() => {
      // Resize for table height resize based on window resize.
      // This is required for lazy-loading
      window.addEventListener("resize", event => {
        const reduce = 50;
        const elements = [
          ".inodeList .ant-table-body",
          ".networkList .ant-table-body",
          ".userList .ant-table-body",
          ".roleList .ant-table-body",
          ".serialNumberList .ant-table-body",
          ".serialNumberList .ant-table-body",
          ".secretLicenseList .ant-table-body",
          ".secretSSHList .ant-table-body",
          ".serviceList .ant-table-body",
          ".firewallGroupList .ant-table-body",
          ".orgList .ant-table-body",
          ".dashboardList .ant-table-body",
          ".alertList .ant-table-body",
          ".eventList .ant-table-body",
          ".imageList .ant-table-body",
          ".activityList .ant-table-body",
          ".recentActivityList",
          ".clusterList .ant-table-body",
          ".clusterNodeList .ant-table-body",
          ".clusterServiceList .ant-table-body",
          ".clusterNetworkList .ant-table-body",
          ".iNodeServiceList .ant-table-body",
          ".iNodeNetworkList .ant-table-body",
        ];
        elements.forEach(className => {
          let element = document.querySelector(className);
          if (element) {
            let bounding = element.getBoundingClientRect();
            if (bounding) {
              let top = bounding.y || bounding.top;
              let y = window.innerHeight - top - reduce;
              UiStore.setTableScroll({ y: y });
              UiStore.setActivityDivScroll({
                height: window.innerHeight - top - 60,
              });
            }
          }
        });
      });
    }, 1000);
  }

  render() {
    return (
      <Layout className="ant-layout-has-sider">
        <DocumentTitle />
        <MessageDisplay />
        <IoSidebar />
        <Layout
          id="main-content-wrapper"
          style={{
            backgroundColor: "#ffffff",
            padding: "15px 15px 0 15px",
          }}
        >
          <IoMain />
          <IoFooter isNotChrome={this.props.isNotChrome} />
        </Layout>
      </Layout>
    );
  }
}

export default MainLayout;
