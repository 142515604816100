import React, { Component } from "react";
import { string, array, bool, func } from "prop-types";
import { Modal, Button } from "antd";
import Icons from "components/UI-Components/Icons";
import { getCurrentlocaleText } from "Core/Utils";
class FirewallNameChangeInfoDialog extends Component {
  static propTypes = {
    networkName: string,
    networkId: string,
    visible: bool,
    serviceNames: array,
    onOk: func,
    onCancel: func,
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      loading: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({ visible: this.props.visible });
  }

  componentDidMount() {
    setTimeout(() => {
      let text = getCurrentlocaleText("security.policy.name_changed.message1", {
        0: this.props.oldName,
        1: this.props.newName,
      });
      let element = document.getElementById("firewall-name-change-info");
      if (element) {
        element.innerHTML = text;
      }
    }, 10);
  }
  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      visible: this.props.visible,
      newName: newProps.newName,
      oldName: newProps.oldName,
    });
  }

  handleCancel = e => {
    this.setState({ visible: false });
    this.props.onOk();
  };

  render() {
    return (
      <div>
        <Modal
          title={
            <span>
              <Icons type="fa" name="FaShieldAlt" />
              &nbsp;{getCurrentlocaleText("security.policy.name_changed.title")}
            </span>
          }
          visible={this.state.visible}
          onOk={this.handleCancel}
          closable={false}
          footer={[
            <Button
              key="ok"
              type="primary"
              size="large"
              onClick={this.handleCancel}
            >
              {getCurrentlocaleText("security.policy.name_changed.ok.text")}
            </Button>,
          ]}
        >
          <div id="firewall-name-change-info" />
        </Modal>
      </div>
    );
  }
}

export default FirewallNameChangeInfoDialog;
