import React, { Component } from "react";
import { Row, Form, Col, Select, Checkbox, Radio, Input } from "antd";
import { observer, inject } from "mobx-react";
import propTypes from "prop-types";
import _ from "lodash";
import { getCurrentlocaleText } from "Core/Utils";
import InputBox from "../UI-Components/InputBox";
const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const { TextArea } = Input;

const helperObjects = {
  secretCertiNameHelpObj: {
    help: {
      title: getCurrentlocaleText("ssh.key.name"),
      data: [
        {
          subtitle: "",
          content: getCurrentlocaleText("ssh.key.name.helptext"),
        },
      ],
    },
  },
  sshContainerHelp: {
    help: {
      title: getCurrentlocaleText("ssh.public.key"),
      data: [
        {
          subtitle: "",
          content: getCurrentlocaleText("ssh.public.key.helptext"),
        },
      ],
    },
  },
  uploaderHelpObj: {
    help: {
      title: getCurrentlocaleText("ssh.upload.file"),
      data: [
        {
          subtitle: "",
          content: getCurrentlocaleText("ssh.upload.file.helptext"),
        },
      ],
    },
  },
};
const sshRadioOptions = {
  options: [
    {
      value: "PASTE",
      label: "Paste the SSH public key",
    },
    {
      value: "UPLOAD",
      label: "Upload a new SSH public key",
    },
  ],
};
@inject("ModelStore", "UiStore")
@observer
class licenseSSH extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sshType: null,
      showSSH: false,
    };
  }

  validateSSHName = (rule, value, callback, field) => {
    /*validate name only when creating new service*/
    //if (this.props.actionType != "edit") {
    let failMessage = null,
      x = -1;

    /*LAT-3198*/
    this.props.UiStore.serviceFormData.rowDetails
      ? value !=
          this.props.UiStore.serviceFormData.rowDetails.selectedLicenseDetails
            .name && (x = this.props.UiStore.availableSSHName.indexOf(value))
      : (x = this.props.UiStore.availableSSHName.indexOf(value));

    if (x >= 0) {
      failMessage =
        "SSH key name is already in use. Please specify a different name.";
    }

    if (failMessage) {
      callback(failMessage);
    }
    //}
    callback();
  };

  componentDidMount = () => {
    this.props.actionType === "edit"
      ? ((this.props.UiStore.serviceFormData.ssh_radio = "UPLOAD"),
        this.setState({
          sshType: "UPLOAD",
          showSSH: true,
        }),
        (this.props.UiStore.serviceFormData.secret_volume = []))
      : null;
  };

  createLicenseFormControl = list => {
    return list.options.map((val, i) => {
      return (
        <Radio
          value={val.value}
          key={val.value}
          onFocus={this.props.updateHelp.bind(null, {
            title: getCurrentlocaleText("ssh.keys"),
            data: [],
          })}

          //disabled={this.props.type === "edit" && val.value === "NEW"}
        >
          {val.label}
        </Radio>
      );
    });
  };

  onSSHChange = e => {
    this.setState({
      sshType: e.target.value,
      showSSH: true,
    });

    e.target.value.toLowerCase() === "paste"
      ? (this.props.UiStore.serviceFormData["secret_volume"] = {})
      : (this.props.UiStore.serviceFormData[`ssh_container`] = null);
  };

  base64 = e => {
    e.target.checked
      ? (this.props.UiStore.serviceFormData.base64 = true)
      : (this.props.UiStore.serviceFormData.base64 = false);
  };

  render() {
    let actionType = this.props.actionType;
    const { getFieldDecorator } = this.props.referenceForm;
    let RSAPlaceholder =
      this.props &&
      this.props.properties &&
      this.props.properties.display_props &&
      this.props.properties.display_props.display_name
        ? this.props.properties.display_props.display_name
        : "SSH (RSA) public key";
    return (
      <div>
        <FormItem>
          {getFieldDecorator(`ssh_radio`, {
            initialValue: this.props.UiStore.serviceFormData
              ? this.props.UiStore.serviceFormData.ssh_radio
              : "",
          })(
            <RadioGroup
              onChange={this.onSSHChange}
              //visible = {this.state.showSSH ? 'visible' : 'hidden'}
            >
              {this.createLicenseFormControl(sshRadioOptions)}

              {this.state.showSSH &&
                (this.state.sshType.toLowerCase() === "upload" ? (
                  <div>
                    <FormItem
                      label={getCurrentlocaleText("ssh.key.name")}
                      hasFeedback
                      style={{
                        marginBottom: "5px",
                      }}
                    >
                      {getFieldDecorator(`secretCertiName`, {
                        rules: [
                          {
                            required: true,
                            message: `Please give a name for the SSH public key`,
                          },
                          {
                            //validator: this.validateSSHName,
                          },
                          {
                            pattern: /^[A-Za-z0-9 .&,-_]*$/,
                            message:
                              "SSH public key file name may contain only the special characters . , & - _",
                          },
                        ],
                        initialValue: this.props.UiStore.serviceFormData
                          ? this.props.UiStore.serviceFormData[
                              `secretCertiName`
                            ]
                          : "",
                      })(
                        <InputBox
                          //type="password"
                          autoComplete="off"
                          placeholder={getCurrentlocaleText("ssh.key.name")}
                          id="secretCertiName"
                          onChange={this.props.inputChange}
                          //disabled={this.props.actionType === "edit"}
                          //onFocus={this.props.updateHelp.bind(null, obj.help)}
                          onFocus={this.props.updateHelp.bind(
                            null,
                            helperObjects.secretCertiNameHelpObj.help,
                          )}
                        />,
                      )}
                    </FormItem>

                    <FormItem
                      label={getCurrentlocaleText("ssh.upload.file")}
                      //label={"Upload License" + uploadoptional}
                      hasFeedback
                      key="uploader"
                      //disabled={this.props.enableDisableLicenseUpload}
                    >
                      {getFieldDecorator("uploader", {
                        rules: [
                          {
                            required: actionType === "create" ? true : false,
                            message: `Please Upload a License`,
                          },
                        ],
                      })(
                        <InputBox
                          style={{ padding: "3px" }}
                          type="file"
                          multiple={this.props.multiple}
                          onChange={this.props.fileUpload}
                          onFocus={this.props.updateHelp.bind(
                            null,
                            helperObjects.uploaderHelpObj.help,
                          )}
                          //disabled={this.props.type === "edit"}
                        />,
                      )}
                    </FormItem>
                  </div>
                ) : (
                  <div>
                    <FormItem
                      label={getCurrentlocaleText("ssh.key.name")}
                      hasFeedback
                      style={{
                        marginBottom: "5px",
                      }}
                    >
                      {getFieldDecorator(`secretCertiName`, {
                        rules: [
                          {
                            required: true,
                            message: `Please give a name for the SSH public key`,
                          },
                          {
                            //validator: this.validateSSHName,
                          },
                          {
                            pattern: /^[A-Za-z0-9 .&,-_]*$/,
                            message:
                              "License name may contain only the special characters . , & - _",
                          },
                        ],
                        initialValue: this.props.UiStore.serviceFormData
                          ? this.props.UiStore.serviceFormData[
                              `secretCertiName`
                            ]
                          : "",
                      })(
                        <InputBox
                          //type="password"
                          autoComplete="off"
                          placeholder={getCurrentlocaleText("ssh.key.name")}
                          id="secretCertiName"
                          onChange={this.props.inputChange}
                          //disabled={this.props.actionType === "edit"}
                          onFocus={this.props.updateHelp.bind(
                            null,
                            helperObjects.secretCertiNameHelpObj.help,
                          )}
                        />,
                      )}
                    </FormItem>

                    <FormItem
                      style={{
                        float: "right",
                        marginBottom: "0px",
                        zIndex: 1,
                      }}
                    >
                      {getFieldDecorator(`base64`, {
                        rules: [
                          {
                            required: false,
                          },
                        ],
                      })(
                        <Checkbox onChange={this.base64}>
                          Input is already base64 encoded
                        </Checkbox>,
                      )}
                    </FormItem>

                    <FormItem label={getCurrentlocaleText("ssh.public.key")}>
                      {getFieldDecorator(`ssh_container`, {
                        rules: [
                          {
                            required: false,
                            message: getCurrentlocaleText(
                              "ssh.public.key.error",
                            ),
                          },
                        ],
                        initialValue: this.props.UiStore.serviceFormData
                          ? this.props.UiStore.serviceFormData.ssh_container
                          : "",
                      })(
                        <TextArea
                          style={{ maxHeight: "300px" }}
                          onChange={this.props.inputChange}
                          placeholder={getCurrentlocaleText(
                            "ssh.public.key.placeholder",
                            { 0: RSAPlaceholder },
                          )}
                          onFocus={this.props.updateHelp.bind(
                            null,
                            helperObjects.sshContainerHelp.help,
                          )}
                          id="ssh_container"
                          autosize={{ minRows: 4 }}
                        />,
                      )}
                    </FormItem>
                  </div>
                ))}
            </RadioGroup>,
          )}
        </FormItem>
        <Row gutter={8}>
          <Col>
            {this.props.actionType === "edit" &&
              this.props.UiStore.serviceFormData &&
              this.props.UiStore.serviceFormData.rowDetails && (
                <span>
                  Id : {this.props.UiStore.serviceFormData.rowDetails.id}
                </span>
              )}
          </Col>
        </Row>
      </div>
    );
  }
}

licenseSSH.propTypes = {
  validateSSHName: propTypes.func,
  helperObjects: propTypes.object,
};

export default licenseSSH;
