import React, { Component } from "react";
import { Row, Col, Card, Empty, Menu, Button } from "antd";
import { observer, inject } from "mobx-react";
import NetworkStatsController from "controller/NetworkStatsController";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import OverviewChart from "components/Stats/Charts/OverviewChart";
import {
  getCurrentlocaleText,
  DataSaverAndPodDnsBaseVersion,
  getNodeVersion,
} from "../../Core/Utils";
import InterfaceTrafficView from "components/UI-Components/InterfaceTrafficView";
import Icons from "components/UI-Components/Icons";

@inject("NodeStatsViewModel", "InodeViewModel")
@observer
class StatsOverviewContainer extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = this.props.InodeViewModel;
    this.NodeStatsViewModel = this.props.NodeStatsViewModel;
    this.interval = null;
    this.defaultInterval = 30000;
    this.state = {
      data: [],
      opts: {},
      completeData: [],
      interfaces: [],
      currentInterface: "eth0",
      FileSystemInfo: [],
      loading: false,
      resetModalOpen: false,
      editMode: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.NodeStatsViewModel.loading = true;
    this.NodeStatsViewModel.hideEvents = true;
  }

  componentDidMount() {
    this.updateChart(true);
    this.setStatsInterval();
  }

  setStatsInterval = () => {
    if (this.interval) {
      clearInterval(this.interval);
    }
    /*API CALL EVERY timeoutInterval Intiated HERE */
    this.interval = setInterval(() => {
      this.updateChart(false);
    }, this.defaultInterval);
  };

  componentWillUnmount = () => {
    /*Required to clear interval on component unmount*/
    clearInterval(this.interval);
    this.NodeStatsViewModel.hideEvents = false;
    // reset on data on exiting the page
    this.inodeModel.setTrafficInsightData({});
  };

  UNSAFE_componentWillReceiveProps(props) {
    let interval = 30000; // 30 Seconds
    if (this.inodeModel.inode && this.inodeModel.inode.stat_config) {
      if (this.inodeModel.inode.stat_config.stat_mode === "SLOW") {
        interval = 120000; // 2 Minutes
      }
    }
    if (interval !== this.defaultInterval) {
      this.defaultInterval = interval;
      this.setStatsInterval();
    }
  }

  updateChart = (loading = false) => {
    this.props.NodeStatsViewModel.loading = loading;
    NetworkStatsController.getNodeOverviewStats(
      this.props.match.params.nodeId,
      {},
      loading,
    )
      .then(resp => {
        NetworkStatsController.getNodeStats(
          this.props.match.params.nodeId,
          {},
          loading,
        )
          .then(res => {
            this.props.NodeStatsViewModel.setLoading(false);
          })
          .catch(error => {
            this.props.NodeStatsViewModel.setLoading(false);
          });
      })
      .catch(error => {
        this.props.NodeStatsViewModel.setLoading(false);
      });
  };

  //restore the default state
  retoreDefault = () => {
    this.setState({
      resetModalOpen: true,
    });
  };

  //switching between edit and done for dashboard
  toggleEdit = () => {
    this.setState({
      editMode: !this.state.editMode,
    });
  };

  onRestoreClose = () => {
    this.setState({
      resetModalOpen: false,
      editMode: false,
    });
  };

  redirectToAdvnancedStats = () => {
    let path = window.location.pathname;
    let updated_path = path;
    let path_array = path.split("/");
    if (path_array.length >= 6) {
      path_array.splice(5, 1, "stats");
      path_array = path_array.join("/");
      updated_path = path_array;
    } else {
      updated_path = updated_path + "/stats";
    }
    if (!path.includes("/stats")) {
      this.props.history.push(updated_path);
    }
  };

  render() {
    let path = window.location.pathname.split("/");
    if (path.length > 5) path.splice(5);
    this.menu = (
      <Menu style={{ marginTop: -6 }}>
        {!this.state.editMode && (
          <Menu.Item key="1" style={this.color} onMouseEnter={this.toggleHover}>
            <Button
              icon={<Icons type="ai" name="AiOutlineEdit" />}
              title={getCurrentlocaleText("stats.config.edit.title.text")}
              onClick={this.toggleEdit}
              type="small"
              style={{ border: "none", position: "relative", left: "75px" }}
            >
              {getCurrentlocaleText("stats.config.edit.title.text")}
            </Button>
          </Menu.Item>
        )}
        <Menu.Item key="3" style={this.color} onMouseEnter={this.toggleHover}>
          <Button
            icon={<Icons type="ai" name="AiOutlineSync" />}
            style={{ border: "none", position: "relative", left: "75px" }}
            title={getCurrentlocaleText("stats.config.reset.button.text")}
            onClick={this.retoreDefault}
            type="small"
          >
            {getCurrentlocaleText("stats.config.reset.button.text")}
          </Button>
        </Menu.Item>
      </Menu>
    );
    return (
      <LoadingComponent loading={this.NodeStatsViewModel.loading}>
        <div
          style={{
            minHeight:
              this.NodeStatsViewModel.getStatsAndInterfaces.stats.length === 0
                ? 200
                : 360,
          }}
        >
          <Row gutter={16} type="flex" align="middle">
            <Col flex="auto">
              <OverviewChart
                loading={this.NodeStatsViewModel.loading}
                dataList={this.NodeStatsViewModel.getStatsAndInterfaces.stats}
                interfaces={
                  this.NodeStatsViewModel.getStatsAndInterfaces.interfaces
                }
                {...this.props}
                cpu_overview={this.NodeStatsViewModel.getCPUoverViewInfo}
                cpuLive={this.NodeStatsViewModel.getCurrentCPUValue}
                memoryLive={this.NodeStatsViewModel.getCurrentMemoryValue}
                fileSystem={this.NodeStatsViewModel.getCurrentFileSystemValue}
                memory_overview={this.NodeStatsViewModel.getMemoryoverViewInfo}
                fs_overview={this.NodeStatsViewModel.getFileSystemOverViewInfo}
                nw_overview={this.NodeStatsViewModel.getInterfaceOverViewInfo}
                {...this.props}
                resetModalOpen={this.state.resetModalOpen}
                editMode={this.state.editMode}
                toggleEdit={this.toggleEdit}
                redirectToAdvnancedStats={this.redirectToAdvnancedStats}
                onRestoreClose={this.onRestoreClose}
                isFromOverview={true}
                extraInfo={this.props.NodeStatsViewModel.getExtraInfoForGauge}
              />
            </Col>
          </Row>
        </div>
      </LoadingComponent>
    );
  }
}

export default StatsOverviewContainer;
