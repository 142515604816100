import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Form, Input } from "antd";
import {
  getCurrentlocaleText,
  isEmpty,
  focusFirstInput,
  getTagKeyValues,
  removeDefaultLabels,
  copyObject,
} from "Core/Utils";
import LabelProvider from "components/UI-Components/LabelProvider";

const FormItem = Form.Item;
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

@inject("UiStore")
@observer
class WebhookInfoDisplay extends Component {
  constructor(props) {
    super(props);
    this.UiStore = this.props.UiStore;
    this.state = {
      formItem: {
        name: undefined,
        metadata: {},
      },
      metadata: {},
      tags: [],
      currrentWebhookData: this.props.currrentWebhookData,
    };
  }

  componentDidMount() {
    // Focus first input
    focusFirstInput();
    if (
      this.state.currrentWebhookData &&
      !isEmpty(this.state.currrentWebhookData)
    ) {
      let genInfo = this.state.currrentWebhookData;
      let formItem = this.state.currrentWebhookData;
      formItem.metadata = genInfo.metadata;
      this.setState({ tags: getTagKeyValues(genInfo.metadata.labels) });
      let data = {
        name: {
          value: formItem.name,
        },
        metadata: {
          value: formItem.metadata,
        },
      };
      this.props.formReference.setFields(data);
    }
    this.updateHelp({
      title: getCurrentlocaleText("webhookform.channel_name.text"),
      data: [
        {
          subtitle: "",
          content: getCurrentlocaleText("webhookform.channelname.help.text"),
        },
      ],
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currrentWebhookData != this.props.currrentWebhookData) {
      if (
        this.props.currrentWebhookData &&
        !isEmpty(this.props.currrentWebhookData)
      ) {
        let genInfo = this.props.currrentWebhookData;
        let formItem = this.state.formItem;
        formItem.name = genInfo.name;
        formItem.metadata = removeDefaultLabels(
          copyObject(genInfo.metadata.labels),
        );
        this.setState({ tags: getTagKeyValues(genInfo.metadata.labels) });
        let data = {
          name: {
            value: formItem.name,
          },
          metadata: {
            value: formItem.metadata,
          },
        };
        this.props.formReference.setFields(data);
      }
    }
  }

  updateHelp = data => {
    this.UiStore.SetStoreData("helpData", data);
  };

  handleLabelChange = values => {
    let formItem = this.state.formItem;
    formItem = { metadata: values, tags: getTagKeyValues(values) };
    let data = {
      metadata: {
        value: { labels: values },
      },
    };
    this.props.formReference.setFields(data);
  };

  render() {
    let { getFieldDecorator } = this.props.formReference;
    let { formItem } = this.state;
    return (
      <div style={{ marginTop: 10 }}>
        <FormItem
          label={
            <span>{getCurrentlocaleText("webhookform.channel_name.text")}</span>
          }
          {...formItemLayout}
        >
          {getFieldDecorator("name", {
            rules: [
              {
                required: true,
                message: getCurrentlocaleText(
                  "webhookform.channelname.errormessage1.text",
                ),
                whitespace: true,
              },
              {
                max: 60,
                message: getCurrentlocaleText(
                  "webhookform.channelname.errormessage2.text",
                ),
              },
              {
                pattern: /^[A-Za-z0-9 \.&,_-]*$/,
                message: getCurrentlocaleText(
                  "webhookform.channelname.errormessage3.text",
                ),
              },
            ],
            initialValue: formItem.name,
          })(
            <Input
              autoComplete="off"
              placeholder={getCurrentlocaleText(
                "webhookform.channel_name.text",
              )}
              className="firstInput"
              onFocus={this.updateHelp.bind(this, {
                title: getCurrentlocaleText("webhookform.channel_name.text"),
                data: [
                  {
                    subtitle: "",
                    content: getCurrentlocaleText(
                      "webhookform.channelname.help.text",
                    ),
                  },
                ],
              })}
            />,
          )}
        </FormItem>
        <FormItem
          colon={false}
          label={
            <span
              className={"iot-label-not-required"}
              title={getCurrentlocaleText("general.form.label.text").replace(
                ":",
                " ",
              )}
            >
              {getCurrentlocaleText("general.form.label.text")}
            </span>
          }
          {...formItemLayout}
        >
          {getFieldDecorator("metadata", { initialValue: formItem.metadata })(
            <LabelProvider
              tags={this.state.tags}
              metadata={formItem.metadata}
              handleChange={this.handleLabelChange}
              showPopupLabel={false}
              isModal={true}
              viewOnly={this.props.viewOnly}
            />,
          )}
        </FormItem>
      </div>
    );
  }
}

export default WebhookInfoDisplay;
