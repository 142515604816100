import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Menu } from "antd";
import DeleteUser from "components/UI-Components/DeleteUser";
import ResetUserPassword from "components/UI-Components/ResetUserPassword";
import Reset2FA from "components/UI-Components/Reset2FA";
import UnlockUser from "components/UI-Components/UnlockUser";
import EditUser from "components/UI-Components/EditUser";
import UserSessionView from "components/UserSession/UserSessionView";
import DropdownActionBtn from "components/UI-Components/DropdownActionBtn";
import {
  getCurrentlocaleText,
  checkforFeatureEnabled,
  IOTIUM_USER,
} from "Core/Utils";
import ActionRoute from "Core/API/ActionRoute";
import OrgController from "../../controller/OrgController";

@inject("ModelStore", "AuthStore", "OrgViewModel")
@observer
class UserActions extends Component {
  constructor(props) {
    super(props);
    this.authStore = this.props.AuthStore;
    this.orgModel = this.props.OrgViewModel;
    this.state = {
      isResetRequestPending: false,
      userOrg: null,
    };
    this.getOrg();
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (this.props.isResetRequestPending !== props.isResetRequestPending) {
      this.setState({
        isResetRequestPending: props.isResetRequestPending,
      });
    }
  }

  getOrg() {
    let orgData = this.orgModel.getOrg(this.props.orgId);
    if (!orgData) {
      OrgController.getOrgById(this.props.orgId, false, false).then(
        response => {
          if (response) {
            this.setState({
              userOrg: response,
            });
          }
        },
      );
    }
  }
  render() {
    let orgData = this.orgModel.getOrg(this.props.orgId);
    if (!orgData && this.state.userOrg) {
      orgData = this.state.userOrg;
    }
    let is_two_factor_enabled =
      orgData &&
      orgData.policy &&
      orgData.policy.two_factor &&
      orgData.policy.two_factor.enable
        ? orgData.policy.two_factor.enable
        : false;
    let is_central_auth = orgData && orgData.is_central_auth ? true : false;
    const editUser = (
      <span>
        <EditUser
          formData={this.props.formData}
          orgId={this.props.orgId}
          userId={this.props.userId}
          changeRoute={this.props.changeRoute}
          onAfterClick={this.props.onAfterClick}
          disabled={this.props.disabledEdit}
          initialVal={this.props.initialVal}
          isMe={this.props.isMe}
        />
      </span>
    );
    const rest2FA = (
      <span>
        <Reset2FA
          user_id={this.props.userId}
          afterCall={this.props.afterCall}
        />
      </span>
    );
    const resetUserPassword = (
      <span>
        <ResetUserPassword
          userId={this.props.userId}
          disabled={
            this.props.userId === IOTIUM_USER || this.props.isMe
              ? true
              : this.props.isMe
              ? false
              : !this.authStore.IsPermitted("ORG:SUPPORT") &&
                this.props.disabled
              ? true
              : false
          }
          isResetRequestPending={this.state.isResetRequestPending}
          reset_password_triggered_at={this.props.reset_password_triggered_at}
          password_count={this.props.password_count}
          updateAfterCall={this.props.updateAfterCall}
          userName={this.props.userName}
        />
      </span>
    );

    const unlockUser = (
      <span>
        <UnlockUser userId={this.props.userId} />
      </span>
    );
    const userSessionView = (
      <span>
        {" "}
        <UserSessionView
          userId={this.props.userId}
          isMe={this.props.isMe}
          type="button"
          isCentralAuthUser={is_central_auth}
        />
      </span>
    );

    const deleteUser = (
      <span>
        <DeleteUser
          formData={this.props.formData}
          orgId={this.props.orgId}
          userId={this.props.userId}
          disabled={
            this.props.userId === IOTIUM_USER || this.props.isMe
              ? true
              : this.props.disabled
          }
          afterCall={this.props.afterCall}
        />
      </span>
    );

    const menu = (
      <Menu>
        <Menu.Item key={0}>{editUser}</Menu.Item>
        {this.authStore.IsPermitted("RESET:2FA") &&
          this.props.twoFactor &&
          !this.props.isMe &&
          is_two_factor_enabled && <Menu.Item key={1}>{rest2FA}</Menu.Item>}
        {!is_central_auth && this.authStore.IsPermitted("ORG:SUPPORT") && (
          <Menu.Item key={2}>{resetUserPassword}</Menu.Item>
        )}
        {this.props.isUnlocked && <Menu.Item key={3}>{unlockUser}</Menu.Item>}
        {(this.authStore.IsPermitted("SESSION:VIEW") || this.props.isMe) && (
          <Menu.Item key={4}>{userSessionView}</Menu.Item>
        )}
        {this.authStore.IsPermitted("APIKEY:VIEW") &&
          checkforFeatureEnabled(
            this.props.AuthStore.loggedInUserOrg,
            "apikey",
          ) && (
            <Menu.Item key={5}>
              <ActionRoute
                actionIcon="AiOutlineKey"
                actionLabel={getCurrentlocaleText(
                  "user.api_keys.leftmenu.text",
                )}
                iconButton
                isCustomIcon={true}
                buttonStyle={{ width: 50 }}
                iscustomText={"API"}
                customTextStyle={{
                  fontSize: 8,
                  marginRight: 2,
                  display: "block",
                  marginBottom: -5,
                }}
                iconType="md"
                iconName="MdVpnKey"
                title={getCurrentlocaleText("user.api_keys.leftmenu.text")}
                route={
                  "/orgs/" +
                  this.props.orgId +
                  "/users/keys/" +
                  this.props.userId
                }
                iconBtnStyle={{ fontSize: 14 }}
              />
            </Menu.Item>
          )}
        <Menu.Item key={6}>{deleteUser}</Menu.Item>
      </Menu>
    );
    return (
      <div>
        <DropdownActionBtn menu={menu} />
      </div>
    );
  }
}
export default UserActions;
