import React, { useEffect, useState } from "react";
import { Col } from "antd";

import { checkforFeatureEnabled, getCurrentlocaleText } from "Core/Utils";
import OrgViewModel from "../../view-model/OrgViewModel.js";
import DashboardStore from "../../Stores/DashboardStore.js";
import AuthStore from "../../Stores/AuthStore.js";
import CountCards from "./components/CountCards.js";

const CountElements = props => {
  let [counts, setCounts] = useState([]);
  useEffect(() => {
    getData();
  }, [props.currentOrgId]);

  const getData = () => {
    const currentOrgId =
      props.match.params && props.match.params.id
        ? props.match.params.id
        : OrgViewModel && OrgViewModel.org && OrgViewModel.org.id
        ? OrgViewModel.org.id
        : AuthStore &&
          AuthStore.loggedInUser &&
          AuthStore.loggedInUser.organization &&
          AuthStore.loggedInUser.organization.id;
    const totalCount = DashboardStore.totalCount
      ? DashboardStore.totalCount
      : {};

    const data = {
      counts: [],
    };
    if (AuthStore.IsPermitted("NODE:VIEW")) {
      data.counts.push({
        icon: "FaRegHdd",
        color: "orange",
        title: "iNodes",
        number: totalCount.inode,
        type: "fa",
        hasChildren: AuthStore.IsPermitted("ORG:VIEW") ? true : false,
        children: AuthStore.IsPermitted("ORG:VIEW")
          ? {
              icon: "FaQrcode",
              color: "orange",
              title: "Serial Numbers",
              number: totalCount.pki,
              type: "fa",
              redirect:
                totalCount.pki >= 0
                  ? "/dashboard/" + currentOrgId + "/serials"
                  : "/dashboard",
            }
          : {},
        redirect:
          totalCount.inode >= 0
            ? "/dashboard/" + currentOrgId + "/inodes"
            : "/dashboard",
      });
    }
    if (AuthStore.IsPermitted("NODE:VIEW")) {
      data.counts.push({
        icon: "FaConnectdevelop",
        color: "purple",
        title: "Networks",
        number: totalCount.network,
        type: "fa",
        hasChildren: AuthStore.IsPermitted("NODE:VIEW") ? true : false,
        children: AuthStore.IsPermitted("NODE:VIEW")
          ? {
              icon: "FaShieldAlt",
              color: "orange",
              title: "Custom Security Policy",
              number: totalCount.firewall,
              type: "fa",
              redirect:
                totalCount.firewall >= 0
                  ? "/dashboard/" + currentOrgId + "/securitypolicies"
                  : "/dashboard",
            }
          : {},
        redirect:
          totalCount.network >= 0
            ? "/dashboard/" + currentOrgId + "/networks"
            : "/dashboard",
      });
    }
    if (AuthStore.IsPermitted("SERVICE:VIEW")) {
      data.counts.push({
        icon: "FaCogs",
        color: "orange",
        title: "Services",
        number: totalCount.service,
        type: "fa",
        hasChildren: AuthStore.IsPermitted("SERVICE:VIEW") ? true : false,
        children: AuthStore.IsPermitted("SERVICE:VIEW")
          ? {
              icon: "FaKey",
              color: "orange",
              title: "Service Secrets",
              number: totalCount.secret,
              type: "fa",
              redirect:
                totalCount.secret >= 0
                  ? "/dashboard/" + currentOrgId + "/licenses"
                  : "/dashboard",
            }
          : {},
        redirect:
          totalCount.service >= 0
            ? "/dashboard/" + currentOrgId + "/services"
            : "/dashboard",
      });
    }
    if (AuthStore.IsPermitted("USER:VIEW")) {
      data.counts.push({
        icon: "MdGroup",
        color: "orange",
        title: "Users",
        number: totalCount.user,
        type: "md",
        redirect:
          totalCount.user >= 0
            ? "/dashboard/" + currentOrgId + "/users"
            : "/dashboard",
        hasChildren: AuthStore.IsPermitted("ROLE:VIEW") ? true : false,
        children: AuthStore.IsPermitted("ROLE:VIEW")
          ? {
              icon: "AiOutlineIdcard",
              color: "orange",
              title: "Roles",
              number: totalCount.role,
              type: "ai",
              redirect:
                totalCount.role >= 0
                  ? "/dashboard/" + currentOrgId + "/roles"
                  : "/dashboard",
            }
          : {},
      });
    }
    if (
      AuthStore.IsPermitted("CLUSTER:VIEW") &&
      checkforFeatureEnabled(
        OrgViewModel.org.id ? OrgViewModel.org : AuthStore.loggedInUserOrg,
        "cluster",
      )
    ) {
      data.counts.unshift({
        icon: "BsBoundingBox",
        color: "green",
        title: getCurrentlocaleText("clusters.navigation_menu.text"),
        number: totalCount.cluster,
        type: "bs",
        redirect:
          totalCount.org >= 0
            ? "/dashboard/" + currentOrgId + "/clusters"
            : "/dashboard",
      });
    }
    if (AuthStore.IsPermitted("ORG:VIEW")) {
      data.counts.unshift({
        icon: "FaSitemap",
        color: "green",
        title: "Orgs",
        number: totalCount.org,
        type: "fa",
        redirect:
          totalCount.org >= 0
            ? "/dashboard/" + currentOrgId + "/orgs"
            : "/dashboard",
      });
    }
    let width = 100 / data.counts.length;
    let result = data.counts.map((item, key) => (
      <Col
        key={key}
        style={{
          width: `20%`,
          float: "left",
          marginLeft:
            data.counts.length === 9 && key === 5
              ? "10%"
              : data.counts.length === 8 && key === 5
              ? "20%"
              : data.counts.length === 7 && key === 5
              ? "30%"
              : data.counts.length === 6 && key === 5
              ? "40%"
              : "0%",
        }}
      >
        <CountCards {...item} />
      </Col>
    ));

    setCounts(result);
  };

  return <div>{counts}</div>;
};

export default CountElements;
