import React, { Component } from "react";
import { Row, Col, Skeleton } from "antd";
import { inject, observer } from "mobx-react";
import CTDSeverityChart from "./CTDSeverityChart";
import { DotChartOutlined } from "@ant-design/icons";
const moment = require("moment");
import InodeController from "../../controller/InodeController";
import OrgController from "../../controller/OrgController";

@inject("InodeViewModel", "OrgViewModel")
@observer
export class CTDSeverity extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = this.props.InodeViewModel;
    this.orgModel = this.props.OrgViewModel;
    this.orgId = this.props.orgId;
    this.nodeId = this.props.nodeId;
    this.refreshData = "";
    this.state = {
      percentages: {},
      highSevChart: [],
      mediumSevChart: [],
      lowSevChart: [],
      highSevChartZero: 0,
      mediumSevChartZero: 0,
      lowSevChartZero: 0,
    };
  }

  componentDidMount() {
    clearInterval(this.refreshData);
    this.getData();
    this.refreshData = setInterval(() => {
      this.getData();
    }, 5 * 60 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.refreshData);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.startDate &&
      this.props.endDate &&
      (this.props.startDate !== prevProps.startDate ||
        this.props.endDate !== prevProps.endDate)
    ) {
      this.getData();
    }
  }

  getData = () => {
    let params = {};
    if (this.props.startDate && this.props.endDate) {
      params = {
        start_date: this.props.startDate.toISOString(),
        end_date: this.props.endDate.toISOString(),
        size: 5,
      };
    }

    this.props.requestFrom == "inodeModel"
      ? this.getNodeThreatsSeverity(params)
      : this.getOrgThreatsSeverity(params);
  };

  calculatePercentage = data => {
    const total = Object.values(data).reduce((sum, value) => sum + value, 0);
    const percentages = {};
    for (const key in data) {
      let val = (data[key] / total) * 100;
      if (val < 1 && val > 0) {
        val = "<1"; // To show there is data instead of showing 0 which misreprasent.
      } else {
        val = val.toFixed(1);
      }
      percentages[key] = val;
    }
    this.setState({ percentages });
  };

  rearrageTimeSeries = resp => {
    let highSevChart = [];
    let mediumSevChart = [];
    let lowSevChart = [];
    let title = ["Year", "Severity"];

    highSevChart =
      resp.high_severity &&
      resp.high_severity.severity_with_time &&
      resp.high_severity.severity_with_time.map(obj => [
        moment(obj.timestamp).format("YYYY-MM-DD HH:mm:ss"),
        obj.count,
      ]);
    highSevChart = [title, ...highSevChart];
    let highSevChartZero =
      resp.high_severity &&
      resp.high_severity.severity_with_time &&
      resp.high_severity.severity_with_time.reduce(
        (acc, obj) => acc + obj.count,
        0,
      );

    mediumSevChart =
      resp.medium_severity &&
      resp.medium_severity.severity_with_time &&
      resp.medium_severity.severity_with_time.map(obj => [
        moment(obj.timestamp).format("YYYY-MM-DD HH:mm:ss"),
        obj.count,
      ]);
    mediumSevChart = [title, ...mediumSevChart];
    let mediumSevChartZero =
      resp.medium_severity &&
      resp.medium_severity.severity_with_time &&
      resp.medium_severity.severity_with_time.reduce(
        (acc, obj) => acc + obj.count,
        0,
      );

    lowSevChart =
      resp.low_severity &&
      resp.low_severity.severity_with_time &&
      resp.low_severity.severity_with_time.map(obj => [
        moment(obj.timestamp).format("YYYY-MM-DD HH:mm:ss"),
        obj.count,
      ]);
    lowSevChart = [title, ...lowSevChart];
    let lowSevChartZero =
      resp.low_severity &&
      resp.low_severity.severity_with_time &&
      resp.low_severity.severity_with_time.reduce(
        (acc, obj) => acc + obj.count,
        0,
      );

    this.setState({
      highSevChart,
      highSevChartZero,
      mediumSevChart,
      mediumSevChartZero,
      lowSevChart,
      lowSevChartZero,
    });
  };

  getNodeThreatsSeverity = params => {
    this.inodeModel.threatsSeverityLoading = true;
    InodeController.getThreatsSeverity(this.nodeId, params)
      .then(resp => {
        this.calculatePercentage(resp.total_threats_by_severity);
        this.rearrageTimeSeries(resp);
        if (resp.entries) {
          this.inodeModel.setThreatsSeverity(resp.results);
        } else {
          this.inodeModel.setThreatsSeverity([]);
        }
        this.inodeModel.threatsSeverityLoading = false;
      })
      .catch(err => {
        this.inodeModel.threatsSeverityLoading = false;
      });
  };

  getOrgThreatsSeverity = params => {
    this.orgModel.threatsSeverityLoading = true;
    OrgController.getThreatsSeverity(this.orgId, params)
      .then(resp => {
        this.calculatePercentage(resp.total_threats_by_severity);
        this.rearrageTimeSeries(resp);
        if (resp.entries) {
          this.orgModel.setThreatsSeverity(resp.results);
        } else {
          this.orgModel.setThreatsSeverity([]);
        }
        this.orgModel.threatsSeverityLoading = false;
      })
      .catch(err => {
        this.orgModel.threatsSeverityLoading = false;
      });
  };

  render() {
    let model = this.nodeId ? "inodeModel" : "orgModel";

    return (
      <Row>
        <Col span={8} className="px-5 all-center all-inherit">
          {this[model].threatsSeverityLoading && (
            <Skeleton.Image active="true" />
          )}
          {!this[model].threatsSeverityLoading && (
            <CTDSeverityChart
              type="High Severity"
              percentage={this.state.percentages.high}
              areaData={this.state.highSevChart}
              allZero={this.state.highSevChartZero == 0 ? true : false}
              color="#b8472f"
            />
          )}
        </Col>
        <Col span={8} className="px-5 all-center all-inherit">
          {this[model].threatsSeverityLoading && (
            <Skeleton.Image active="true" />
          )}
          {!this[model].threatsSeverityLoading && (
            <CTDSeverityChart
              type="Medium Severity"
              percentage={this.state.percentages.medium}
              areaData={this.state.mediumSevChart}
              allZero={this.state.mediumSevChartZero == 0 ? true : false}
              color="#de8769"
            />
          )}
        </Col>
        <Col span={8} className="px-5 all-center all-inherit">
          {this[model].threatsSeverityLoading && (
            <Skeleton.Image active="true" />
          )}
          {!this[model].threatsSeverityLoading && (
            <CTDSeverityChart
              type="Low Severity"
              percentage={this.state.percentages.low}
              areaData={this.state.lowSevChart}
              allZero={this.state.lowSevChartZero == 0 ? true : false}
              color="#f4bf4c"
            />
          )}
        </Col>
      </Row>
    );
  }
}
