import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Row, Col, Popover } from "antd";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import TableLayout from "components/layout/TableLayout";
import UpgradeHistoryController from "controller/UpgradeHistoryController";
import {
  getCurrentlocaleText,
  formatTimestampToIotiumLastAtTimeString,
} from "Core/Utils";

const upGradeStatus = {
  2: {
    message: "granted",
    color: "",
  },
  3: {
    message: "error",
    color: "rgb(240, 65, 52)",
  },
  4: {
    message: "completed",
    color: "rgb(0, 168, 84)",
  },
  5: {
    message: "installed",
    color: "",
  },
  6: {
    message: "downloaded",
    color: "",
  },
  7: {
    message: "downloading",
    color: "",
  },
  8: {
    message: "grant denied",
    color: "rgb(240, 65, 52)",
  },
  "-1": {
    message: "unknown",
    color: "",
  },
};

@inject("AuthStore", "UiStore", "UpgradeHistoryViewModel")
@observer
class NodeUpgradeHistoryList extends Component {
  constructor(props) {
    super(props);
    this.UiStore = this.props.UiStore;
    this.upgradehistoryModel = this.props.UpgradeHistoryViewModel;
    this.state = {
      expandedRowKeys: [],
    };
  }

  componentDidMount() {
    if (this.props.AuthStore.IsPermitted("UPGRADE_HISTORY:VIEW")) {
      this.upgradehistoryList(true, 0);
    }
  }

  upgradehistoryList = (loading = true, page = 0, forcecall = false) => {
    let params = {};
    params.page = page;
    params.size = 20;
    UpgradeHistoryController.getInodeUpgradeHistorys(
      this.props.nodeId,
      params,
      loading,
      forcecall,
    );
  };

  expandRow = () => {
    let keys = this.eventModel.events.map(item => {
      return item.id;
    });
    this.setExpandedRowKeys(
      this.state.expandedRowKeys.length !== keys.length ? keys : [],
    );
  };

  onExpand = (expanded, record) => {
    let keys = this.state.expandedRowKeys;
    let index = keys.findIndex(val => {
      return val === record.id;
    });
    if (expanded) {
      if (index === -1) {
        keys.push(record.id);
      }
      this.setExpandedRowKeys(keys);
    } else {
      if (index !== -1) {
        keys.splice(index, 1);
      }
      this.setExpandedRowKeys(keys);
    }
  };

  setExpandedRowKeys = keys => {
    this.setState({ expandedRowKeys: keys });
  };

  getMoreInfo(record) {
    let time = formatTimestampToIotiumLastAtTimeString(record.createdTS);
    return (
      <div>
        <Row style={{ marginTop: 5, marginBottom: 5 }} />
        <Row>
          <Col span={24}>
            <strong>{"Staus Message"}</strong>
            <strong className="px-15">:</strong>
            {record.status}
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    let upgradehistorys = this.upgradehistoryModel.upgradehistoryList || [];
    let columns = [
      {
        title: getCurrentlocaleText("upgrade.app.text"),
        key: "appName",
        width: "15%",
        render: (text, record, index) => {
          return (
            <div>
              <span>&nbsp;{record.appName}</span>
            </div>
          );
        },
      },
      {
        title: getCurrentlocaleText("upgrade.group.text"),
        key: "groupName",
        width: "15%",
        render: (text, record, index) => {
          return (
            <div>
              <span>&nbsp;{record.groupName}</span>
            </div>
          );
        },
      },
      {
        title: getCurrentlocaleText("upgrade.status.text"),
        key: "status",
        width: "30%",
        render: (text, record, index) => {
          return (
            <div>
              {record.status_code && upGradeStatus[record.status_code] && (
                <span
                  style={{ color: upGradeStatus[record.status_code].color }}
                >
                  <strong>
                    &nbsp;
                    {upGradeStatus[record.status_code].message.toUpperCase()}
                  </strong>
                </span>
              )}
            </div>
          );
        },
      },
      {
        title: getCurrentlocaleText("upgrade.version.text"),
        key: "ver",
        width: "15%",
        render: (text, record, index) => {
          return (
            <div>
              <span>&nbsp;{record.ver}</span>
            </div>
          );
        },
      },
      {
        title: getCurrentlocaleText("upgrade.createrdTS.text"),
        key: "createdTS",
        width: "25%",
        render: (text, record, index) => {
          return (
            <div>
              {formatTimestampToIotiumLastAtTimeString(
                record.createdTS,
              ).indexOf("ago") > 0 ? (
                <Popover
                  content={formatTimestampToIotiumLastAtTimeString(
                    record.createdTS,
                    true,
                  )}
                >
                  <span>
                    {formatTimestampToIotiumLastAtTimeString(record.createdTS)}
                  </span>
                </Popover>
              ) : (
                <span>
                  {formatTimestampToIotiumLastAtTimeString(record.createdTS)}
                </span>
              )}
            </div>
          );
        },
      },
    ];

    let container = (
      <div>
        {/* UpgradeHistory List */}
        <div
          style={{
            background: "#fff",
            minHeight: 120,
          }}
        >
          <Row gutter={16} type="flex" align="middle">
            <Col span={24}>
              {this.upgradehistoryModel.upgradehistorys.length > 0 ? (
                <TableLayout
                  pagination={false}
                  columns={columns}
                  dataSource={upgradehistorys}
                  hideRowSelection={true}
                  onExpand={this.onExpand}
                  expandedRowKeys={this.state.expandedRowKeys}
                  scroll={{ y: 215 }}
                  expandedRowRender={record => {
                    return this.getMoreInfo(record);
                  }}
                />
              ) : (
                <div>
                  {!this.upgradehistoryModel.loading && (
                    <div>{getCurrentlocaleText("noupgrades.text")}</div>
                  )}
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    );

    return (
      <LoadingComponent loading={this.upgradehistoryModel.loading}>
        {container}
      </LoadingComponent>
    );
  }
}

export default NodeUpgradeHistoryList;
