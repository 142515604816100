import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Row, Col, Popover, Button } from "antd";
import { Link } from "react-router-dom";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import TableLayout from "components/layout/TableLayout";
import InodeImageController from "controller/InodeImageController";
import {
  getCurrentlocaleText,
  highlightSearchText,
  humanizeMetric,
} from "Core/Utils";
import ActionBtn from "Core/API/ActionBtn";
import SearchInput from "components/UI-Components/SearchInput";
import Icons from "components/UI-Components/Icons";

@inject("AuthStore", "UiStore", "InodeImageViewModel", "InodeViewModel")
@observer
class InodeImageList extends Component {
  constructor(props) {
    super(props);
    this.UiStore = this.props.UiStore;
    this.imageModel = this.props.InodeImageViewModel;
    this.inodeModel = this.props.InodeViewModel;
    this.state = {
      expandedRowKeys: [],
      searchText: "",
      filterInfo: null,
      sorterInfo: { sorter: {}, sort: {} },
      selectedRowKeys: [], // for table to identify selected rows
      deletableImages: {
        images: [],
      },
      selectedImage: {},
    };
    this.imageApiByInterval = null;
  }

  componentDidMount() {
    this.imageList(true, 0);

    /*API CALL EVERY 30 SEC Intiated HERE */
    this.imageApiByInterval = setInterval(() => {
      if (
        this.inodeModel.inode &&
        this.inodeModel.inode.node_state &&
        this.inodeModel.inode.node_state === "ALIVE"
      ) {
        if (!this.props.showMoreImages) {
          let existing_nodes = this.imageModel.images;
          let allParams = [];
          for (let page = 0; page <= this.imageModel.pageable.page; page++) {
            let params = {
              size: 25,
              page: page,
            };
            // For search
            if (this.state.searchText) {
              params.search = this.state.searchText;
            } else {
              if (params.search) {
                delete params.search;
              }
            }
            // For sort
            if (
              this.state.sorterInfo.sort.by &&
              this.state.sorterInfo.sort.order
            ) {
              params.sort = `${this.state.sorterInfo.sort.by}:${this.state.sorterInfo.sort.order}`;
            } else {
              if (params.sort) {
                delete params.sort;
              }
            }
            allParams.push(params);
          }
          // Quering total lsit of pages in single get with setting UI loading false
          InodeImageController.QueryAllImages(
            allParams,
            this.props.nodeId,
            false,
          ).then(updated_nodes => {});
        } else {
          this.imageList(false, 0, false);
        }
      }
    }, 30000);
  }

  componentWillUnmount = () => {
    /*Required to clear interval on component unmount*/
    clearInterval(this.imageApiByInterval);
  };

  iNodeImageListFilter = e => {
    clearTimeout(this.searchTimeout);
    let searchText = e.target.value;
    this.setState({ searchText: searchText });
    this.props.UiStore.nodeParams.searchText = searchText;
    this.searchTimeout = setTimeout(() => {
      // node image search api call
      this.imageList(true, 0, false);
    }, 500);
  };

  clearSearchFilter = () => {
    this.setState({ searchText: "" });
    this.props.UiStore.nodeParams.searchText = "";
    this.searchTimeout = setTimeout(() => {
      // node image search api call
      this.imageList(true, 0, false);
    }, 500);
  };

  imageList = (
    loading = true,
    page = 0,
    forcecall = false,
    sorter = this.state.sorterInfo,
  ) => {
    let params = {};
    params.page = page;
    params.search = this.state.searchText ? this.state.searchText : "";
    params.size = this.props.showMoreImages ? 5 : 25;

    // For sort
    if (sorter && sorter.sort && sorter.sort.by && sorter.sort.order) {
      params.sort = `${sorter.sort.by}:${sorter.sort.order}`;
    }

    InodeImageController.getInodeImages(
      this.props.nodeId,
      params,
      loading,
      forcecall,
    );
  };

  onTableChange = (pagination, filter, sorter) => {
    let sort = {};
    if (sorter.columnKey && sorter.order) {
      if (sorter.order === "ascend") {
        sort.order = "ASC";
      } else {
        sort.order = "DESC";
      }
      sort.by = sorter.columnKey;
    }
    this.setState({
      sorterInfo: { sorter: sorter, sort: sort },
    });
    this.imageList(true, 0, false, { sorter: sorter, sort: sort });
  };

  lazyLoad = () => {
    if (this.imageModel.pageable.next && !this.props.showMoreImages) {
      let page = this.imageModel.pageable.page + 1;
      this.imageList(true, page, false);
    }
  };

  onSelectAllChange = (selected, selectedRows, changeRows) => {
    if (selected) {
      this.setDeletableStates(null, selected, selectedRows);
    } else {
      // on deselect all
      this.resetState();
    }
  };

  resetState = () => {
    this.setState({
      selectedImage: {},
      deletableImages: {
        images: [],
      },
    });
  };

  setDeletableStates = (record = {}, selected, selectedRows) => {
    for (let i = 0; i < selectedRows.length; i++) {
      let id = selectedRows[i].id;

      this.setState(() => {
        let newObj = {
            deletableImages: {
              images: [],
            },
          },
          selectedImage = this.state.selectedImage,
          item = selectedRows[i],
          tempArray = [],
          isMarkedForDelete = false;

        if (item.deleted_at) {
          isMarkedForDelete = true;
        }

        if (!isMarkedForDelete) {
          selectedImage[id] = {
            value: true,
            tags: item.tags,
            name: item.name,
            id: item.id,
          };
        }
        //Temp array for deletable nodes list

        for (let i in selectedRows) {
          selectedImage[selectedRows[i].id]
            ? tempArray.push(selectedImage[selectedRows[i].id])
            : "";
        }
        // To set the value : false , when node is deselected and remove it from delete list

        !selected && selectedImage[record.id]
          ? ((selectedImage[record.id].value = false),
            (tempArray = _.filter(tempArray, val => {
              return val.id !== record.id;
            })))
          : null;
        newObj["selectedImages"] = selectedImage;
        newObj.deletableImages["images"] = tempArray;
        return newObj;
      });
    }
  };

  handleTableChange = (record, selected, selectedRows) => {
    selectedRows.length == 0 ? this.resetState() : "";
    this.setDeletableStates(record, selected, selectedRows);
  };

  render() {
    let path = window.location.pathname.split("/");
    let images = this.imageModel.getInodeImageList || [];
    let { sorterInfo } = this.state;
    const rowSelection = {
      onChange: this.onTableChange,
      selectedRows: this.handleTableChange,
      selectAll: this.onSelectAllChange,
      getCheckboxProps: record => ({
        disabled: true, // Column configuration not to be checked
      }),
      type: "checkbox",
    };

    let columns = [
      {
        title: getCurrentlocaleText("inodes.images.name.header.text"),
        key: "name",
        width: "40%",
        render: (text, record, index) => {
          return (
            <div>
              {this.state.searchText.trim().length > 0
                ? highlightSearchText(record.name, this.state.searchText)
                : record.name}
              {record.disabled && (
                <span className="iotium-danger">
                  {" "}
                  {getCurrentlocaleText("deleting.text")}
                </span>
              )}
            </div>
          );
        },
      },
      {
        title: getCurrentlocaleText("inodes.images.size.header.text"),
        key: "size",
        sorter: true,
        sortOrder:
          sorterInfo.sorter.columnKey === "size" && sorterInfo.sorter.order,
        render: (text, record, index) => {
          return <span>{humanizeMetric(record.image_size, true)}</span>;
        },
      },
      {
        title: "",
        key: "action",
        render: (text, record, index) => {
          return (
            <span>
              <ActionBtn
                title={
                  <span>
                    <Icons type="ai" name="AiOutlineDelete" />{" "}
                    {getCurrentlocaleText(
                      "inodes.images.delete.modal.title.text",
                    )}
                  </span>
                }
                HoverText={
                  <span>
                    {record.disabled
                      ? getCurrentlocaleText("deleting.text")
                      : this.inodeModel.inode &&
                        this.inodeModel.inode.node_state &&
                        this.inodeModel.inode.node_state != "ALIVE"
                      ? getCurrentlocaleText(
                          "inodes.images.delete.not_alive.error.title.text",
                        )
                      : getCurrentlocaleText(
                          "inodes.images.delete_image.button.title.text",
                        )}
                  </span>
                }
                resource="image"
                action="delete"
                formData={record}
                iconButton={true}
                map={{ id: record.id }}
                params={{
                  node_id: this.props.nodeId,
                  image_name: record.name,
                }}
                icon="AiOutlineDelete"
                controller={InodeImageController}
                disabled={
                  !this.props.AuthStore.IsPermitted("IMAGE:DELETE") ||
                  record.deleted_at ||
                  (this.inodeModel.inode &&
                    this.inodeModel.inode.node_state &&
                    this.inodeModel.inode.node_state != "ALIVE")
                    ? true
                    : false
                }
                afterCall={() => {
                  this.imageList(true, 0, false);
                }}
                cancelText={getCurrentlocaleText(
                  "delete.confirmation.cancel.text",
                )}
              >
                <Icons type="ai" name="AiOutlineDelete" />{" "}
                {getCurrentlocaleText("delete.confirmation.ok.text")}
              </ActionBtn>
              {record.message && (
                <Popover
                  overlayStyle={{ maxWidth: "30%", float: "right" }}
                  placement="left"
                  title={getCurrentlocaleText(
                    "inodes.images.error_in_delete.text",
                  )}
                  content={
                    <div>
                      {getCurrentlocaleText(
                        "inodes.images.error_in_delete.message.text",
                      )}
                    </div>
                  }
                >
                  <Button
                    style={{ border: "none", verticalAlign: "sub", left: 5 }}
                    shape="circle"
                    size={"small"}
                  >
                    <Icons
                      type="fa"
                      name="FaTimesCircle"
                      className="remote-network-action-btn error-on-remote-network-icon"
                    />
                  </Button>
                </Popover>
              )}
            </span>
          );
        },
      },
    ];

    let container = (
      <div>
        {/* Event List */}
        <div
          style={{
            padding: 24,
            background: "#fff",
            minHeight: 120,
          }}
        >
          <div
            style={{
              marginBottom: "10px",
              display: "inline-flex",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <SearchInput
                placeholder={getCurrentlocaleText(
                  "inodes.images.filter_images_by_name.text",
                )}
                onChange={this.iNodeImageListFilter}
                value={this.state.searchText}
                clearSearch={this.clearSearchFilter}
              />
            </div>
            <div
              style={{
                marginLeft: "20px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {this.props.AuthStore.IsPermitted("IMAGE:DELETE") && (
                <ActionBtn
                  title={
                    this.inodeModel.inode &&
                    this.inodeModel.inode.node_state &&
                    this.inodeModel.inode.node_state != "ALIVE" ? (
                      getCurrentlocaleText(
                        "inodes.images.delete.not_alive.error.title.text",
                      )
                    ) : (
                      <span>
                        <Icons type="ai" name="AiOutlineDelete" />{" "}
                        {getCurrentlocaleText(
                          "nodes.images.delete_images.button.title.text",
                        )}
                      </span>
                    )
                  }
                  resource="image"
                  action="delete"
                  iconButton={true}
                  controller={InodeImageController}
                  icon="AiOutlineDelete"
                  cancelText={getCurrentlocaleText(
                    "delete.confirmation.cancel.text",
                  )}
                  disabled={
                    this.state.deletableImages.images.length <= 0 ||
                    (this.inodeModel.inode &&
                      this.inodeModel.inode.node_state &&
                      this.inodeModel.inode.node_state != "ALIVE")
                  }
                  formData={this.state.deletableImages.images}
                  params={{
                    node_id: this.props.nodeId,
                  }}
                  afterCall={() => {
                    this.imageList(true, 0, false);
                  }}
                >
                  <Icons type="ai" name="AiOutlineDelete" />{" "}
                  {getCurrentlocaleText("delete.confirmation.ok.text")}
                </ActionBtn>
              )}
            </div>
          </div>
          <Row gutter={16} type="flex" align="middle">
            <Col span={24}>
              {this.imageModel.getInodeImageList.length > 0 ? (
                <TableLayout
                  pagination={false}
                  columns={columns}
                  dataSource={images}
                  selectedRows={this.handleTableChange}
                  selectAll={this.onSelectAllChange}
                  hideExpandedRow={true}
                  onExpand={this.onExpand}
                  hasLazyLoading={true}
                  className="imageList"
                  lazyLoad={this.lazyLoad}
                  onChange={this.onTableChange}
                  rowSelection={rowSelection}
                  scroll={{ y: 200 }}
                  expandedRowRender={record => {
                    return this.getMoreInfo(record);
                  }}
                  footer={
                    this.props.showMoreImages &&
                    this.imageModel.pageable &&
                    this.imageModel.pageable.total_count &&
                    this.imageModel.pageable.total_count > 5
                      ? () => {
                          return (
                            <div style={{ textAlign: "center" }}>
                              <Link to={path.join("/") + "/inodeimages"}>
                                <strong>
                                  {getCurrentlocaleText(
                                    "inodes.images.more_images.link.text",
                                  )}
                                </strong>
                              </Link>
                            </div>
                          );
                        }
                      : null
                  }
                />
              ) : (
                <div>
                  {!this.imageModel.loading && (
                    <div>
                      {getCurrentlocaleText("inodes.images.no_images.text")}
                    </div>
                  )}
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    );

    return (
      <LoadingComponent loading={this.imageModel.loading}>
        {container}
      </LoadingComponent>
    );
  }
}

export default InodeImageList;
