class MenuContainer {
  getSelectedMenu = currentLocation => {
    if (currentLocation) {
      let keys = currentLocation.split("/");
      if (keys.length >= 2 && keys[1]) {
        if (keys[1] === "help") {
          return keys[2] === "kb" ? ["kb"] : ["releases"];
        } else if (keys[1] === "dashboard") {
          return [keys[1]];
        } else if (keys[3] === "roles") {
          return ["roles"];
        } else if (keys[1] === "home") {
          return ["home"];
        } else if (keys[1] === "events") {
          return ["events-all-events"];
        } else if (keys[1] === "managealerts") {
          return ["current-user-managealerts"];
        } else if (keys[1] === "profile") {
          return ["current-user-profile"];
        } else if (keys[1] === "keys") {
          return ["current-user-apiKeys"];
        } else if (keys[1] === "managewebhooks") {
          return ["current-user-managewebhooks"];
        } else if (keys[1] === "orgs" && !keys[3]) {
          return [keys[1]];
        } else if (keys[1] === "orgs" && keys[3] && keys[3] === "policies") {
          return [keys[1]];
        } else {
          return [keys[3]];
        }
      }
    }
  };

  getActiveElement = data => {
    let key = [];
    if (data && data[0]) {
      switch (data[0]) {
        case "serials":
          key = ["inodes"];
          break;
        case "users":
          key = ["users"];
          break;
        case "services":
          key = ["services"];
          break;
        case "licenses":
          key = ["services"];
          break;
        case "securitypolicies":
          key = ["networks"];
          break;
        case "roles":
          key = ["users"];
          break;
        case "current-user-managewebhooks":
        case "current-user-managealerts":
        case "current-user-apiKeys":
        case "current-user-profile":
          key = ["current-user"];
          break;
        case "events-all-events":
          key = ["events"];
          break;
        default:
          key = data;
          break;
      }
      return key;
    }
    return key;
  };
}

export default new MenuContainer();
