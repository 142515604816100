import React, { useState, useEffect } from "react";
import { Button, Table, Typography } from "antd";
import { copyObject, getCurrentlocaleText } from "../../Core/Utils";
import SearchInput from "../UI-Components/SearchInput";
import LoadingComponent from "../UI-Components/LoadingComponent";
import NetworkViewModel from "../../view-model/NetworkViewModel";
import InodeViewModel from "../../view-model/InodeViewModel";
import ServiceViewModel from "../../view-model/ServiceViewModel";
import Icons from "../UI-Components/Icons";
import FileSaver from "file-saver";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@view/pro-solid-svg-icons";

const { Paragraph } = Typography;
const MacTable = props => {
  const [searchText, setSearchText] = useState("");
  const [dataSource, setDataSource] = useState();
  const [isDefault, setIsDefault] = useState(false);
  const [isActiveWan, setIsActiveWan] = useState(false);
  const [parsedDataSource, setParsedDataSource] = useState();
  const columns = [
    {
      title: (
        <span title={getCurrentlocaleText("mac-address.text")}>
          {getCurrentlocaleText("mac-address.text")}
        </span>
      ),
      dataIndex: "macAddress",
      key: "macAddress",
      sorter: (a, b) =>
        a.macAddress &&
        b.macAddress &&
        a.macAddress.localeCompare(b.macAddress),
      render: (text, record) => {
        return text ? (
          <Paragraph copyable ellipsis={true}>
            {text}
          </Paragraph>
        ) : (
          "-"
        );
      },
    },
    {
      title: (
        <span title={getCurrentlocaleText("dad.type.text")}>
          {getCurrentlocaleText("dad.type.text")}
        </span>
      ),
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: (
        <span title={getCurrentlocaleText("inode.systeminfo.name.text")}>
          {getCurrentlocaleText("inode.systeminfo.name.text")}
        </span>
      ),
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
  ];

  useEffect(() => {
    getMacTableList();
  }, [NetworkViewModel.loading, ServiceViewModel.loading]);

  const getMacTableList = (pagination, sort) => {
    let result = [];

    if (
      InodeViewModel &&
      InodeViewModel.inode &&
      InodeViewModel.inode.interfaces
    ) {
      let interfaces = InodeViewModel.inode.interfaces;

      // Process 'tan' interfaces
      if (Array.isArray(interfaces.tan)) {
        interfaces.tan.forEach(obj => {
          if (obj.hwaddr) {
            result.push({
              macAddress: obj.hwaddr,
              type: "Interface",
              name: obj.label && obj.name ? `${obj.label} (${obj.name})` : "-",
            });
          }
        });
      }

      // Process 'wan' interfaces
      if (Array.isArray(interfaces.wan)) {
        interfaces.wan.forEach(obj => {
          if (obj.hwaddr) {
            result.push({
              macAddress: obj.hwaddr,
              type: "Interface",
              name: obj.label && obj.name ? `${obj.label} (${obj.name})` : "-",
            });
          }
        });
      }
    }

    NetworkViewModel.networks.map(obj => {
      if (
        InodeViewModel &&
        InodeViewModel.inode &&
        InodeViewModel.inode.id &&
        obj &&
        obj.node &&
        obj.node.id &&
        obj.node.id == InodeViewModel.inode.id &&
        !obj.is_wan_network
      ) {
        let nwMac = "";
        if (
          obj &&
          obj.status &&
          obj.status.interface_status &&
          obj.status.interface_status.mac_address
        ) {
          nwMac = obj.status.interface_status.mac_address;
        } else if (obj && obj.mac_address) {
          nwMac = obj.mac_address;
        }
        const eth0 = result.find(
          obj => obj.type === "Interface" && obj.name === "eth0",
        );
        nwMac =
          obj.is_default_network &&
          InodeViewModel &&
          InodeViewModel.inode &&
          InodeViewModel.inode.profile &&
          InodeViewModel.inode.profile.name &&
          (InodeViewModel.inode.profile.name.toLowerCase() ===
            "unified cloud gateway" ||
            InodeViewModel.inode.profile.name.toLowerCase() === "virtual") &&
          eth0
            ? eth0.macAddress
            : nwMac;
        result.push({
          macAddress: nwMac,
          type: "Network",
          name: obj.name,
        });
      }
    });

    ServiceViewModel.services.map(obj => {
      if (
        InodeViewModel &&
        InodeViewModel.inode &&
        InodeViewModel.inode.id &&
        obj &&
        obj.node &&
        obj.node.id &&
        obj.node.id == InodeViewModel.inode.id
      ) {
        if (
          obj &&
          obj.status &&
          obj.status.service_ip &&
          obj.status.service_ip.length
        ) {
          obj.status.service_ip.map(childObj => {
            let x = {
              macAddress:
                childObj && childObj.mac_address ? childObj.mac_address : "-",
              type: "Service",
              name: obj.name,
            };

            result.push(x);
          });
        }
      }
    });

    setDataSource(result);
    setParsedDataSource(result);
  };

  const handleSearch = searchText => {
    let result = copyObject(dataSource);
    if (searchText) {
      result = dataSource.filter(item => {
        return (
          (item.macAddress &&
            item.macAddress.toLowerCase().includes(searchText.toLowerCase())) ||
          (item.type &&
            item.type.toLowerCase().includes(searchText.toLowerCase())) ||
          (item.name &&
            item.name.toLowerCase().includes(searchText.toLowerCase()))
        );
      });
    }

    setParsedDataSource(result);
    setSearchText(searchText);
  };

  const handleExport = () => {
    const headerContent = [
      getCurrentlocaleText("mac-address.text"),
      getCurrentlocaleText("dad.type.text"),
      getCurrentlocaleText("inode.systeminfo.name.text"),
    ];
    let csvContent = headerContent.join() + "\n";
    csvContent += dataSource
      .map(row => Object.values(row).join(","))
      .join("\n");

    let blob = new Blob([csvContent], {
      type: "text/csv;charset=utf-8;",
    });
    let file = "Mac Table";
    FileSaver.saveAs(blob, file);
  };

  return (
    <LoadingComponent
      loading={NetworkViewModel.loading || ServiceViewModel.loading}
    >
      <div className="justify-space-between align-center mb-15">
        <strong>{getCurrentlocaleText("general.node.mac.table.text")}</strong>
        <div className="justify-space-between">
          <SearchInput
            placeholder={getCurrentlocaleText("general.search.columns.text")}
            className="rounded-filter border-radius-8"
            size="large"
            onChange={e => handleSearch(e.target.value)}
            value={searchText}
            clearSearch={e => handleSearch("")}
          />
          <Button
            size="large"
            type="primary"
            className="btn-rounded d-inline-block ml-15"
            onClick={handleExport}
          >
            <FontAwesomeIcon icon={faFileExport} className="mr-10" />
            {getCurrentlocaleText("general.export.all.text")}
          </Button>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={parsedDataSource}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: [25, 50, 100],
          defaultPageSize: 25,
        }}
        showSizeChanger={true}
        hideRowSelection={true}
      />
    </LoadingComponent>
  );
};

export default MacTable;

// Sample structure for parsedDataSource
// [{
//   "macAddress": "",
//   "type": "Interface, Network, Service",
//   "name": "",
// }]
