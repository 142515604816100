import React, { useCallback } from "react";
// keys to non prevent default
const ARROW_KEYS = [37, 38, 39, 40]; // "event.which" code / "event.key" code

// the custom onKeyDown handler
const onKeyDownAllowingArrows = antdKeyDown => event => {
  if (ARROW_KEYS.indexOf(event.which) > -1) {
    return true;
  }

  return antdKeyDown(event);
};

// custom component
function CursorMovementInModal({ onKeyDown, ...props }) {
  const onKeyDownHandler = useCallback(onKeyDownAllowingArrows(onKeyDown), [
    onKeyDown,
  ]);
  // use the ul element as antd does
  return <ul {...props} onKeyDown={onKeyDownHandler} />;
}

export default CursorMovementInModal;
// https://github.com/ant-design/ant-design/issues/34125
