import React, { Component } from "react";
import { Card, Select, Row, Col } from "antd";
import {
  customSort,
  getInterval,
  getCurrentlocaleText,
  humanizeMetric,
} from "Core/Utils";
import { observer, inject } from "mobx-react";
import Icons from "components/UI-Components/Icons";
import { NetworkController } from "../../../controller/Controllers";

const Option = Select.Option;

@inject("AuthStore", "NodeStatsViewModel", "NetworkViewModel", "InodeViewModel")
@observer
class NetstatsOverviewChart extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = this.props.InodeViewModel;

    this.state = {
      interfaces: this.props.interfaces,
      loading: false,
      currentInterface: this.inodeModel.interfacesList,
      selectedTags: [],
      data: [],
    };
  }

  UNSAFE_componentWillReceiveProps(newprops) {
    if (this.props != newprops) {
      this.setState({
        data: newprops.data,
        interfaces: newprops.interfaces,
      });
      this.updateInterface();
    }
  }

  updateInterface = () => {
    let interfaces = this.state.interfaces;
    if (interfaces.length > 0) {
      interfaces.forEach(data => {
        if (data.name.startsWith("n-")) {
          if (!this.state[data.name]) this.checkForNeworkName(data.name);
        }
      });
    }
  };

  checkForNeworkName = name => {
    if (name) {
      if (name.startsWith("n-")) {
        let networkIds = name.split("_");
        let fromNetwork = {};
        let toNetwork = {};
        if (networkIds.length === 2) {
          NetworkController.getSingleNetworkById(networkIds[0]).then(res => {
            fromNetwork = res;
            NetworkController.getSingleNetworkById(networkIds[1]).then(res2 => {
              toNetwork = res2;
              this.setState({
                [name]:
                  (fromNetwork.name && fromNetwork.name) +
                  " \u27F7 " +
                  (toNetwork.node &&
                    toNetwork.node.name &&
                    toNetwork.node.name) +
                  "/" +
                  (toNetwork.name && toNetwork.name),
              });
            });
          });
        }
      } else {
        return name;
      }
    }
  };
  replaceInterfaceName = name => {
    if (name.startsWith("n-")) {
      return this.state[name] ? this.state[name] : name;
    } else {
      return name;
    }
  };

  changeInterface = value => {
    this.setState({ currentInterface: value });
    this.props.NodeStatsViewModel.currentInterface = value;
  };

  // Get the index of the interface with the specified name.
  getNetworkInterfaceIndex = (interfaceName, interfaces) => {
    if (interfaces) {
      for (var i = 0; i < interfaces.length; i++) {
        if (interfaces[i].name == interfaceName) {
          return i;
        }
      }
    }
    return -1;
  };

  renderInterfaces = () => {
    let options = this.state.interfaces ? this.state.interfaces : [];
    if (options.length > 0)
      options = options.sort((x, y) => customSort(x.name, y.name));

    let interfaceOptions = [];
    if (options.length > 0) {
      options.forEach((item, i) => {
        interfaceOptions.push(
          <Option
            key={i}
            value={item.name}
            title={this.replaceInterfaceName(item.name)}
          >
            {this.replaceInterfaceName(item.name)}
          </Option>,
        );
      });
    }

    return interfaceOptions;
  };

  getBytesRx = (showSpeed = true) => {
    let data = this.state.data;
    let current_usage = this.props.NodeStatsViewModel.getStatsAndInterfaces
      .currentUsage;

    if (
      data.length > 2 &&
      current_usage &&
      current_usage.interfaces &&
      current_usage.interfaces.length > 0
    ) {
      var intervalInSec =
        getInterval(data[0].timestamp, data[1].timestamp) / 1000000000;
      let cur = current_usage.interfaces ? current_usage.interfaces : null;
      let curInterfaceIndex = this.getNetworkInterfaceIndex(
        this.props.NodeStatsViewModel.currentInterface
          ? this.props.NodeStatsViewModel.currentInterface
          : this.inodeModel.interfacesList
          ? this.inodeModel.interfacesList
          : cur[0].name
          ? cur[0].name
          : null,
        cur,
      );
      if (
        cur[curInterfaceIndex] &&
        cur[curInterfaceIndex].current_rx_bytes &&
        cur[curInterfaceIndex].current_rx_bytes > 0
      ) {
        if (showSpeed) {
          return humanizeMetric(
            cur[curInterfaceIndex].current_rx_bytes > 0
              ? cur[curInterfaceIndex].current_rx_bytes
              : 0,
          );
        } else {
          return humanizeMetric(
            cur[curInterfaceIndex].current_rx_bytes > 0
              ? cur[curInterfaceIndex].current_rx_bytes * intervalInSec
              : 0,
            true,
          );
        }
      } else {
        if (!showSpeed) {
          return humanizeMetric(0, true);
        }
      }
    }
  };

  getBytesTx = (showSpeed = true) => {
    let data = this.state.data;
    let current_usage = this.props.NodeStatsViewModel.getStatsAndInterfaces
      .currentUsage;
    if (
      data.length > 2 &&
      current_usage &&
      current_usage.interfaces &&
      current_usage.interfaces.length > 0
    ) {
      var intervalInSec =
        getInterval(data[0].timestamp, data[1].timestamp) / 1000000000;
      let cur = current_usage.interfaces ? current_usage.interfaces : null;
      let curInterfaceIndex = this.getNetworkInterfaceIndex(
        this.props.NodeStatsViewModel.currentInterface
          ? this.props.NodeStatsViewModel.currentInterface
          : this.props.InodeViewModel.interfacesList
          ? this.props.InodeViewModel.interfacesList
          : cur[0].name
          ? cur[0].name
          : null,
        cur,
      );

      if (
        cur[curInterfaceIndex] &&
        cur[curInterfaceIndex].current_tx_bytes &&
        cur[curInterfaceIndex].current_tx_bytes > 0
      ) {
        if (showSpeed) {
          return humanizeMetric(
            cur[curInterfaceIndex].current_tx_bytes > 0
              ? cur[curInterfaceIndex].current_tx_bytes
              : 0,
          );
        } else {
          return humanizeMetric(
            cur[curInterfaceIndex].current_tx_bytes * intervalInSec > 0
              ? cur[curInterfaceIndex].current_tx_bytes * intervalInSec
              : 0,
            true,
          );
        }
      } else {
        if (!showSpeed) {
          return humanizeMetric(0, true);
        }
      }
    }
  };

  render() {
    let { interfaces } = this.state;
    return (
      <div>
        <Row>
          <Col span={12}>
            <div
              title={getCurrentlocaleText("stats.traffic_overview.title.text")}
              className="network-over-view-header network-over-view-header-text"
            >
              {getCurrentlocaleText("stats.traffic_overview.title.text")}
            </div>
          </Col>
          <Col span={12}>
            <Select
              key={"interface"}
              value={
                this.props.NodeStatsViewModel.currentInterface
                  ? this.props.NodeStatsViewModel.currentInterface
                  : this.inodeModel.interfacesList
                  ? this.inodeModel.interfacesList
                  : interfaces[0] && interfaces[0].name
                  ? interfaces[0].name
                  : null
              }
              onChange={this.changeInterface}
              className="network-over-selector"
              notFoundContent={getCurrentlocaleText(
                "general.notfound.placeholder.text",
              )}
            >
              {this.renderInterfaces()}
            </Select>
          </Col>
        </Row>
        <Card
          bordered={false}
          hoverable={false}
          className="iot-pointer-cursor"
          onClick={() => {
            this.props.redirectToAdvnancedStats();
          }}
        >
          <span className="card-container">
            <Row className="inode-card-network">
              <Col span={12}>
                <Card>
                  <Icons
                    className="inode-card-number-icon"
                    type="io"
                    name="IoIosArrowRoundDown"
                  />
                  <span className="stats-network-overview-count">
                    {this.getBytesRx() ? this.getBytesRx() : "0 bps"}
                  </span>
                </Card>
              </Col>
              <Col span={12}>
                <Card>
                  <Icons
                    type="io"
                    name="IoIosArrowRoundUp"
                    className="inode-card-number-icon"
                  />
                  <span className="stats-network-overview-count">
                    {this.getBytesTx() ? this.getBytesTx() : "0 bps"}
                  </span>
                </Card>
              </Col>
            </Row>
            <div className="card-overlay">
              <div className="card-child-content stats-overview-network-overlay">
                <Card className="stats-overview-child">
                  <Row>
                    <Col span={12}>{"Transmit"}</Col>
                    <Col span={12}>{this.getBytesTx(false)}</Col>
                  </Row>
                  <Row>
                    <Col span={12}>{"Receive"}</Col>
                    <Col span={12}>{this.getBytesRx(false)}</Col>
                  </Row>
                </Card>
              </div>
            </div>
          </span>
        </Card>
      </div>
    );
  }
}

export default NetstatsOverviewChart;
