import React, { Component } from "react";
import { Row, Col, Tag, Radio, Select } from "antd";
import { Form } from "@ant-design/compatible";
import Input from "../UI-Components/InputBox";
import { observer, inject } from "mobx-react";
import { getCurrentlocaleText, focusFirstInput } from "Core/Utils";
import Icons from "components/UI-Components/Icons";
import HelpCard from "components/UI-Components/HelpCard";
import NetworkController from "controller/NetworkController";
import ClusterConversionNodeConfigSelector from "components/Clusters/ClusterConversionNodeConfigSelector";
import PopOverHelp from "components/UI-Components/PopOverHelp";
const FormItem = Form.Item;
const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;
const Option = Select.Option;
@inject(
  "UiStore",
  "AuthStore",
  "OrgViewModel",
  "TimezoneViewModel",
  "InodeViewModel",
  "ServiceViewModel",
)
@observer
class ClusterConversionFormPage extends Component {
  constructor(props) {
    super(props);
    this.orgModel = this.props.OrgViewModel;
    this.timezoneModel = this.props.TimezoneViewModel;
    this.serviceModel = this.props.ServiceViewModel;
    this.nodeModel = this.props.InodeViewModel;
    this.state = {
      tags: [],
      metadata: {},
      intialValues: { name: null, metadata: null },
      config: { priority: 100, is_candidate: true },
      removedMasterNodes: [],
      nonCandidateMasterNodes: [],
      resetNodes: [],
      resetNodesToCandidate: [],
      vrrpTypeSelected: "WAN",
      vrrpstatus: false,
      networkList: [],
      networkSelected: "",
      vrrpDropdownStatus: true,
      orgInitialVal: {},
    };
    this.clusterModel = this.props.ClusterViewModel;
  }

  getTagKeyValues = labels => {
    let tags = [];
    if (labels.name) {
      delete labels.name;
    }
    for (let key in labels) {
      if (key && key.charAt(0) != "_") tags.push(key + " : " + labels[key]);
    }
    return tags;
  };

  updateSelectedKeys = values => {
    let selectedLabels = [];
    if (values.length > 0) {
      values.forEach(val => {
        selectedLabels.push(val.split(":")[0].trim());
      });
    }
    return selectedLabels;
  };

  networklistData = data => {
    return (
      data &&
      data.length > 0 &&
      data.map((val, index) => {
        if (!val.is_wan_network && val.type == "STATIC") {
          return <Option key={val.id}>{val.name}</Option>;
        }
      })
    );
  };
  getNodeNetworksList = nodeId => {
    let params = {};
    params.size = 100;
    params.page = 0;
    params.inheritance = false;
    params.node_id = nodeId;
    NetworkController.getNetworkByNodeId(params).then(resp => {
      if (resp && resp.results) {
        this.setState({
          networkList: resp.results,
        });
      }
    });
  };
  componentDidMount() {
    focusFirstInput();
    this.props.InodeViewModel.isClusterCreateVisible = true;
    this.getOrgInfo();
    if (this.props.map && this.props.map.id) {
      let givenNode = this.nodeModel.inode,
        initialValue = {},
        nodes = [];
      let node = { node: {}, config: {} };
      node.node.id = this.props.map.id;
      node.node.name = givenNode.name;
      node.config = this.state.config;
      node.nodeDetails = givenNode;
      nodes.push(node);
      initialValue.nodes = nodes;
      initialValue.organization = givenNode.organization;
      this.setState({
        intialValues: initialValue,
      });
      this.getNodeNetworksList(this.props.map.id);
    }
  }

  componentWillUnmount() {
    this.props.InodeViewModel.isClusterCreateVisible = false;
  }

  /**
   * GET CURRENT ORG POLICY INFO
   */
  getOrgInfo = () => {
    let currentOrgInfo = this.orgModel.org;
    if (currentOrgInfo) {
      this.setState({ orgInitialVal: currentOrgInfo });
    }
  };

  updateHelp = data => {
    this.props.UiStore.SetStoreData("helpData", data);
  };

  resetHelp = () => {
    this.props.UiStore.SetStoreData("helpData", {});
  };

  setClusteredNodes = value => {
    if (value != null) {
      this.props.form.setFields({
        nodes: {
          value: value,
        },
      });
    } else {
      this.props.form.resetFields(["nodes"]);
    }
  };
  getNodeNameTag = (nodeIds = []) => {
    let clusterData = this.state.intialValues;
    if (nodeIds && nodeIds.length > 0 && clusterData && clusterData.nodes) {
      let nodeNames = [];
      clusterData.nodes.forEach(clusterNode => {
        if (
          clusterNode &&
          clusterNode.node &&
          clusterNode.node.id &&
          nodeIds.includes(clusterNode.node.id)
        ) {
          nodeNames.push(clusterNode.node.name);
        }
      });
      if (nodeNames && nodeNames.length > 0) {
        return nodeNames.map((nodeName, index) => {
          return (
            <Tag key={index} className="tag-iotium-info tag-iotium-ellipse">
              {nodeName}
            </Tag>
          );
        });
      }
    }
  };

  vrrpTypeOnSelect = e => {
    let value = e.target.value;
    this.setState({
      vrrpTypeSelected: value,
    });
  };
  networkSelectedOnSelect = e => {
    let value = e.target.value;
    this.setState({
      networkSelected: value,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form
        noValidate
        onSubmit={this.props.onSubmit}
        className="cluster-conversion-form"
      >
        <FormItem
          label={
            <span>
              {getCurrentlocaleText("cluster.name.help.title") + " :"}{" "}
              <PopOverHelp
                isHelpOnForm={true}
                placement="right"
                helpData={{
                  title: getCurrentlocaleText("cluster.name.help.title"),
                  data: [
                    {
                      subtitle: "",
                      content: getCurrentlocaleText(
                        "cluster.name.help.content",
                      ),
                    },
                  ],
                }}
              />
            </span>
          }
          hasFeedback
        >
          {getFieldDecorator("name", {
            initialValue: this.state.intialValues.name,
            rules: [
              {
                required: true,
                message: getCurrentlocaleText(
                  "cluster.form.clustername.errormessage1.text",
                ),
                whitespace: true,
              },
              {
                max: 255,
                message: getCurrentlocaleText(
                  "cluster.form.clustername.errormessage2.text",
                ),
              },
              {
                pattern: /^[A-Za-z0-9 ._-]*$/,
                message: getCurrentlocaleText(
                  "cluster.form.clustername.errormessage3.text",
                ),
              },
            ],
          })(
            <Input
              autoComplete="off"
              prefix={<Icons type="ai" name="AiOutlineIdcard" />}
              placeholder={getCurrentlocaleText(
                "cluster.form.name.placeholder.text",
              )}
              className="firstInput"
            />,
          )}
        </FormItem>
        <FormItem
          label={
            <span>
              {getCurrentlocaleText("vrrp.select.label") + " :"}{" "}
              <PopOverHelp
                isHelpOnForm={true}
                placement="right"
                helpData={{
                  title: getCurrentlocaleText("vrrp.select.cluster.label"),
                  data: [
                    {
                      subtitle: "",
                      content: getCurrentlocaleText(
                        "vrrp.select.cluster.message",
                      ),
                    },
                  ],
                }}
              />
            </span>
          }
          colon={true}
        >
          {getFieldDecorator("election_network_type", {
            initialValue: this.state.vrrpTypeSelected,
          })(
            <RadioGroup
              onChange={this.vrrpTypeOnSelect}
              size="small"
              disabled={this.state.vrrpstatus}
            >
              <RadioButton value={"WAN"}>WAN</RadioButton>
              <RadioButton value={"TAN"}>TAN</RadioButton>
            </RadioGroup>,
          )}
        </FormItem>
        {this.state.vrrpTypeSelected &&
          this.state.vrrpTypeSelected === "TAN" &&
          this.state.networkList.length > 1 && (
            <FormItem
              colon={true}
              label={
                <span>
                  {getCurrentlocaleText("alertform.nw.placeholder.text") + " :"}{" "}
                  <PopOverHelp
                    isHelpOnForm={true}
                    placement="right"
                    helpData={{
                      title: getCurrentlocaleText("alertform.network.text"),
                      data: [
                        {
                          subtitle: "",
                          content: getCurrentlocaleText(
                            "vrrp.select.network.message",
                          ),
                        },
                      ],
                    }}
                  />
                </span>
              }
            >
              {getFieldDecorator("election_network_id", {
                initialValue: this.state.networkSelected
                  ? this.state.networkSelected
                  : undefined,
              })(
                <Select
                  placeholder={getCurrentlocaleText(
                    "alertform.nw.placeholder.text",
                  )}
                >
                  {this.networklistData(this.state.networkList)}
                </Select>,
              )}
            </FormItem>
          )}

        <Row gutter={2} type="flex" align="top" style={{ paddingTop: 15 }}>
          <Col span={24}>
            <ClusterConversionNodeConfigSelector
              value={this.state.intialValues.nodes}
              setClusteredNodes={this.setClusteredNodes}
              {...this.props}
              intialValues={this.state.intialValues}
              viewOnly={true}
              isFromClusterConversion={true}
            />
          </Col>
        </Row>
      </Form>
    );
  }
}
const ClusterConversionForm = Form.create()(ClusterConversionFormPage);
export default ClusterConversionForm;
