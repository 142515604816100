import React, { Component } from "react";
import { inject } from "mobx-react";
import { getCurrentlocaleText } from "Core/Utils";
import InputBox from "components/UI-Components/InputBox";
import { Input } from "antd";

const InputGroup = Input.Group;
const MaxAllowedDNS = 3;
@inject("UiStore", "InodeViewModel")
export default class DnsStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      DnsList: this.props.defaultDNSValue ? this.props.defaultDNSValue : [],
      AddedCount: [0],
      errorList: [],
      repeatedList: [],
      visibility: false,
    };
  }

  componentDidMount() {
    if (this.props.defaultDNSValue) {
      let AddedCount = this.state.AddedCount;
      if (this.props.defaultDNSValue.length === 2) {
        AddedCount.push(1);
      } else if (this.props.defaultDNSValue.length >= 3) {
        AddedCount.push(1);
        AddedCount.push(2);
      }
      this.setState({
        AddedCount: AddedCount,
        DnsList: this.props.defaultDNSValue ? this.props.defaultDNSValue : [],
      });
    }
  }
  toggle = () => {
    this.setState({ visibility: !this.state.visibility });
  };

  UNSAFE_componentWillReceiveProps(props) {
    if (
      props.defaultDNSValue &&
      this.props.defaultDNSValue != props.defaultDNSValue
    ) {
      let AddedCount = this.state.AddedCount;
      if (props.defaultDNSValue.length === 0 && AddedCount.length >= 1) {
        AddedCount = [0];
      }
      if (props.defaultDNSValue.length === 2) {
        AddedCount.push(1);
      } else if (props.defaultDNSValue.length >= 3) {
        AddedCount.push(1);
        AddedCount.push(2);
      }
      this.setState({
        AddedCount: AddedCount,
        DnsList: props.defaultDNSValue ? props.defaultDNSValue : [],
      });
    }
  }
  render() {
    let remainingDNSLists = [];
    let allowedDnsLists = [];
    if (this.state.DnsList.length > 3) {
      this.state.DnsList.slice(0, 3).forEach(dns => {
        allowedDnsLists.push(dns);
      });
      this.state.DnsList.slice(3).forEach(dns => {
        remainingDNSLists.push(dns);
      });
    } else {
      this.state.DnsList.forEach(dns => {
        allowedDnsLists.push(dns);
      });
    }
    if (allowedDnsLists.length === 0) {
      allowedDnsLists.push("");
    }
    return (
      <div className="App">
        <div
          className={
            this.state.DnsList.length > 3
              ? "dns-with-scroll"
              : "dns-without-scroll"
          }
        >
          <InputGroup>
            {this.state.visibility === false &&
              this.state.DnsList.map((value, i) => {
                return (
                  <span key="dns">
                    <InputBox
                      autoComplete="off"
                      type="text"
                      defaultValue={value.length > 0 ? value : undefined}
                      style={{
                        borderRadius: "unset",
                        border: "none",
                        marginBottom:
                          this.state.errorList.includes(1) ||
                          this.state.repeatedList.includes(1)
                            ? 0
                            : i == this.state.DnsList.length
                            ? "1%"
                            : 0,
                      }}
                      title={
                        "DNS Servers IP Address will get update when set to Default when the iNode status is ALIVE"
                      }
                      disabled={true}
                    />
                    {(this.state.errorList.includes(1) ||
                      this.state.repeatedList.includes(1)) && (
                      <p
                        style={{
                          color: "red",
                          display: "inline-block",
                          float: "left",
                        }}
                      >
                        {this.state.errorList.includes(1)
                          ? getCurrentlocaleText(
                              "network.form.startip.errormessage1.text",
                            )
                          : getCurrentlocaleText(
                              "services.dns.unique.error.message",
                            )}
                      </p>
                    )}
                  </span>
                );
              })}
          </InputGroup>
        </div>
      </div>
    );
  }
}
