import React, { Component } from "react";
import { Card, Row, Col } from "antd";
import { observer, inject } from "mobx-react";
import axios from "axios";

import ForgotPasswordForm from "./ForgotPasswordForm";

import MessageDisplay from "Core/MessageDisplay";
import { getCurrentlocaleText } from "Core/Utils";
import DarkAppInfo from "components/UI-Components/DarkAppInfo";

import IoFooter from "components/layout//Footer";

@inject("UiStore")
@observer
class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.interval = null;
    this.state = {
      isrequestTriggered: false,
      disableSubmit: false,
      isRequestSuccess: false,
      isRequestFailed: false,
      user_name: null,
      errorMessage: getCurrentlocaleText(
        "user.forgot_password_form.failure_message",
      ),
    };
  }

  // Request forgot for password  function
  Recover = (formData, recaptchaRef = null) => {
    this.setState({ disableSubmit: true });
    let loginReq = axios.post("/api/v1/forgotpassword", formData);
    loginReq
      .then(response => {
        if (formData.username) {
          this.setState({ user_name: formData.username });
        }
        this.setState({
          isrequestTriggered: true,
          isRequestSuccess: true,
          isRequestFailed: false,
          disableSubmit: false,
        });
        this.interval = setTimeout(() => {
          this.redirectLoginScreen();
        }, 30000);
      })
      .catch(error => {
        if (error.response && error.response.status === 422) {
          this.props.UiStore.isForceMessage = true;
          this.props.UiStore.errorMessage = getCurrentlocaleText(
            "user.forgot_password_unverified_email.error.message",
          );
        } else {
          this.setState({
            isrequestTriggered: true,
            isRequestFailed: true,
            isRequestSuccess: false,
            disableSubmit: false,
            errorMessage:
              error.response &&
              error.response.data &&
              error.response.data.message
                ? error.response.data.message
                : getCurrentlocaleText(
                    "user.forgot_password_form.failure_message",
                  ),
          });
        }
        if (this.forgotPasswordForm) {
          try {
            this.forgotPasswordForm.resetFields(["captcha"]);
          } catch (e) {}
        }
        if (recaptchaRef) {
          try {
            recaptchaRef.reset();
          } catch (e) {}
        }
      });
  };

  resetState = () => {
    this.setState({
      isrequestTriggered: false,
      disableSubmit: false,
      isRequestSuccess: false,
      isRequestFailed: false,
      user_name: null,
    });
    clearTimeout(this.interval);
  };

  componentWillUnmount = () => {
    /*Required to clear timeout on component unmount*/
    clearTimeout(this.interval);
  };

  redirectLoginScreen = () => {
    if (this.props.history) {
      this.props.history.push("/login");
    }
  };

  render() {
    let {
      isrequestTriggered,
      disableSubmit,
      isRequestSuccess,
      isRequestFailed,
    } = this.state;
    return (
      <div>
        <MessageDisplay />
        <Row type="flex" className="login-card" align="middle" justify="center">
          <Col
            className="login-col"
            lg={{ span: 7 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            xs={{ span: 16 }}
          >
            <div className="login-card-layout-container">
              <center>
                <DarkAppInfo />
              </center>
            </div>
            <Card bordered={false}>
              <center>
                <h1
                  style={{
                    paddingTop: 20,
                    paddingBottom: 20,
                    fontSize: !isrequestTriggered ? 15 : 14,
                  }}
                >
                  {!isrequestTriggered
                    ? getCurrentlocaleText(
                        "user.forgot_password_form.initial_message",
                      )
                    : getCurrentlocaleText(
                        "user.forgot_password_form.after_request_message",
                      )}
                </h1>
                {isrequestTriggered && (
                  <span className="card-inline-error-message-description">
                    {getCurrentlocaleText(
                      "user.email_not_received.info.message",
                    )}
                  </span>
                )}
                {!isrequestTriggered && (
                  <span className="card-inline-error-message-description">
                    {getCurrentlocaleText(
                      "general.login.form.support.reference.text",
                    )}
                  </span>
                )}
                {!isrequestTriggered && (
                  <ForgotPasswordForm
                    Recover={this.Recover}
                    disable={this.state.disableSubmit}
                    ref={c => (this.forgotPasswordForm = c)}
                  />
                )}
              </center>
            </Card>
            <div
              style={{
                fontSize: 13,
                fontWeight: 200,
              }}
            >
              {isrequestTriggered && (
                <Row type="flex" align="middle" justify="center">
                  <Col>
                    <span onClick={this.resetState}>
                      <a>Try Again</a>
                    </span>
                  </Col>
                </Row>
              )}
            </div>
          </Col>
        </Row>
        <IoFooter
          isNotChrome={this.props.isNotChrome}
          centerAlignFooter={true}
        />
      </div>
    );
  }
}
export default ForgotPassword;
