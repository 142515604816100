import React, { Component } from "react";
import Animate from "rc-animate";
import classNames from "classnames";
import { bool, oneOf, string } from "prop-types";
import { LoadingOutlined } from "@ant-design/icons";

import "../../../assets/styles/spinner.css";
import FaviconLogo from "components/UI-Components/FaviconLogo";

class IoTiumSpin extends Component {
  static defaultProps = {
    spinning: true,
    size: "default",
    tip: null,
  };

  static propTypes = {
    spinning: bool,
    size: oneOf(["small", "default", "large", "medium"]),
    tip: string,
  };

  constructor(props) {
    super(props);
    this.state = {
      spinning: this.props.spinning,
      tip: this.props.tip,
      size: "default",
    };
    this.debounceTimeout = null;
  }

  isNestedPattern() {
    return !!(this.props && this.props.children);
  }

  UNSAFE_componentWillMount() {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
  }

  componentWillUnmount() {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const currentSpinning = this.props.spinning;
    const spinning = nextProps.spinning;

    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    if (currentSpinning && !spinning) {
      this.debounceTimeout = setTimeout(() => this.setState({ spinning }), 200);
    } else {
      this.setState({ spinning });
    }

    if (this.props.tip !== nextProps.tip) {
      this.setState({ tip: nextProps.tip });
    }
  }

  render() {
    let prefixCls = this.props.isModalLoading
      ? "iotium-spin-modal"
      : "iotium-spin";
    let animateClassName = prefixCls + "-nested-loading";

    const { className, size, tip } = this.props;
    const { spinning } = this.state;

    const spinClassName = classNames(
      prefixCls,
      {
        [`${prefixCls}-sm`]: size === "small",
        [`${prefixCls}-me`]: size === "medium",
        [`${prefixCls}-lg`]: size === "large",
        [`${prefixCls}-spinning`]: spinning,
        [`${prefixCls}-show-text`]: !tip,
      },
      className,
    );

    const spinElement = (
      <div className={spinClassName}>
        {tip ? (
          <>
            <div className={`${prefixCls}-text`}>
              <LoadingOutlined className={"iotium-spin-loading-icon"} />
              <FaviconLogo />
              <div className={"iotium-spin-loading-text"}>{tip}</div>
            </div>
          </>
        ) : null}
      </div>
    );

    if (this.isNestedPattern()) {
      const containerClassName = classNames({
        [`${prefixCls}-container`]: true,
        [`${prefixCls}-blur`]: spinning,
      });

      return (
        <Animate
          component="div"
          className={animateClassName}
          style={null}
          transitionName="fade"
        >
          {spinning && <div key="loading">{spinElement}</div>}
          <div className={containerClassName} key="container">
            {this.props.children}
          </div>
        </Animate>
      );
    }

    return spinElement;
  }
}

export default IoTiumSpin;
