import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import {
  Tag,
  Table,
  Button,
  Row,
  Col,
  Collapse,
  Cascader,
  Spin,
  Popover,
  Select,
  Checkbox,
} from "antd";
import { Form } from "@ant-design/compatible";
import Input from "components/UI-Components/InputBox";
import {
  getCurrentlocaleText,
  isCidr,
  markCollapseHeader,
  isCidrRange,
  isRestrictedCIDR,
  getCIDRdata,
} from "Core/Utils";
import * as ip from "ip";
import Icons from "components/UI-Components/Icons";
import RouteConfigViaHelp from "components/Forms/Components/Network/RouteConfigViaHelp";
import PopOverHelp from "components/UI-Components/PopOverHelp";

import CidrProvider from "components/UI-Components/CidrProvider";
const FormItem = Form.Item;
const Panel = Collapse.Panel;
const Option = Select.Option;
const RouteErrorCode = "RouteConflictWithRemoteNetworkRoute";
const DEFAULT_DEST_CIDR = "0.0.0.0/0";

@inject("UiStore")
@observer
class RouteConfiguration extends Component {
  constructor(props) {
    super(props);
    this.tanCIDR = this.props.tanCIDR;
    this.cidrRangeInfo = this.props.cidrRangeInfo;
    this.formErrorResponse = {};
    this.state = {
      selectedVnode: [],
      availableNetwork: this.props.availableNetwork || [],
      availableInodes: this.props.availableInodes || [],
      routes: [],
      defaultRoutes: [],
      options: [],
      activeKey: [],
      loading: false,
      remoteNetworks: [],
      expandedRowKeys: [],
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    let removedItem = "";
    // Disable Routing for Other Networks in static route if Inter Network Routing is disabled
    if (props.enableInterTan === false) {
      let routes = this.state.routes;
      if (routes && routes.length > 0) {
        routes.forEach((route, i) => {
          route.enable_routing_for_other_tans = false;
        });
      }
    }

    this.renderRemoteNetworks(props.availableNetwork, props.selectedVnode);
    this.renderOptions(props.availableNetwork, props.selectedVnode);
    if (
      props.availableNetwork &&
      props.availableNetwork.length !== this.state.availableNetwork.length
    ) {
      this.setState({
        availableNetwork: props.availableNetwork,
      });
      let routes = this.state.routes;
      if (routes && routes.length > 0) {
        routes.forEach((route, i) => {
          if (route.network_id) {
            routes[i].via = this.getValue(
              route.network_id,
              props.availableNetwork,
            );
            routes[i].labels = this.getLabels(
              route.network_id,
              props.availableNetwork,
            );
          }
        });
        this.setState({ routes: routes });
        this.pushData(routes);
      }
    }
    if (
      (props.selectedVnode &&
        this.state.selectedVnode &&
        props.selectedVnode.length !== this.state.selectedVnode.length) ||
      (props.availableInodes &&
        this.state.availableInodes &&
        props.availableInodes.length !== this.state.availableInodes.length)
    ) {
      // Find removed remote network id
      removedItem = this.findRemovedItems(
        this.state.selectedVnode,
        props.selectedVnode,
        "network_id",
      );
      if (
        props.selectedVnode &&
        this.state.selectedVnode &&
        props.selectedVnode.length !== this.state.selectedVnode.length
      ) {
        this.setState({
          selectedVnode: props.selectedVnode,
        });
      }
      if (
        props.availableInodes &&
        this.state.availableInodes &&
        props.availableInodes.length !== this.state.availableInodes.length
      ) {
        this.setState({
          availableInodes: props.availableInodes,
        });
      }
      // Handling remote networks value dynamically
      // Remove the row from the static routes when remote network is removed from the vnodes
      let routes = this.state.routes;
      if (
        removedItem &&
        removedItem.length &&
        removedItem.length > 0 &&
        this.state.routes
      ) {
        const itemIndicesToDelete = [];
        this.state.routes.forEach((obj, index) => {
          if (
            obj &&
            obj.network_id &&
            removedItem[0] &&
            removedItem[0].network_id &&
            obj.network_id === removedItem[0].network_id
          ) {
            itemIndicesToDelete.push(index);
          }
        });

        // Sort the indices in descending order to ensure correct deletion
        itemIndicesToDelete.sort((a, b) => b - a);

        // Delete the items from the routes array
        itemIndicesToDelete.forEach(index => {
          this.state.routes.splice(index, 1);
        });
      }
      let expandedRowKeys = this.state.expandedRowKeys;
      if (routes && routes.length > 0) {
        // To form display format while refersh
        routes.forEach((route, i) => {
          if (route.network_id) {
            routes[i].via = this.getValue(
              route.network_id,
              props.availableNetwork,
            );
            routes[i].labels = this.getLabels(
              route.network_id,
              props.availableNetwork,
            );
            if (
              route.network_id !== props.wanNetworkId &&
              props.selectedVnode &&
              !props.isVirtualNode
            ) {
              let nw = props.selectedVnode.find(item => {
                return route.network_id === item.network_id;
              });
              if (!nw) {
                routes[i].via = null;
                routes[i].network_id = null;
                routes[i].isIpAddress = false;
                routes[i].disabled = false;
                let obj = {},
                  field = "via_" + routes[i].key;
                obj[field] = { value: null };
                this.props.form.setFields(obj);
              }
            }
          }
          // Remove allowed_virtual field
          let selectedVnodeArray = props.selectedVnode
            ? props.selectedVnode
            : this.state.selectedVnode;
          let availableInodeArray = props.availableInodes
            ? props.availableInodes
            : this.state.availableInodes;
          if (selectedVnodeArray) {
            let selectedVNwIds = selectedVnodeArray.map(item => {
              return item.network_id;
            });
            let selectedVNodesObject = [];
            selectedVnodeArray.forEach(item => {
              availableInodeArray.forEach(node => {
                if (node && node.networks) {
                  node.networks.forEach(network => {
                    if (network && network.id == item.network_id) {
                      selectedVNodesObject.push({
                        network_id: item.network_id,
                        node: node,
                      });
                    }
                  });
                }
              });
            });
            if (
              selectedVNwIds &&
              routes[i].allowed_virtual &&
              routes[i].allowed_virtual.length > 0
            ) {
              // Select the allowed virtual
              routes[i].allowed_virtual = routes[i].allowed_virtual.filter(
                allowed_virtual => {
                  return (
                    allowed_virtual &&
                    allowed_virtual.network_id &&
                    selectedVNwIds.includes(allowed_virtual.network_id)
                  );
                },
              );
              // Set the auto repnet has enable or not in the virtual
              routes[i].allowed_virtual.forEach(allowed_virtual => {
                let vNodeIndex = selectedVNodesObject.findIndex(vNode => {
                  return (
                    vNode && vNode.network_id == allowed_virtual.network_id
                  );
                });
                let vNode =
                  vNodeIndex !== -1
                    ? selectedVNodesObject[vNodeIndex].node
                    : null;
                allowed_virtual.has_auto_repnet_enabled =
                  vNode &&
                  vNode.cloud_route &&
                  vNode.cloud_route.representation_network_automation
                    ? vNode.cloud_route.representation_network_automation
                    : false;
              });
              routes[i].show_allowed_virtual = true;
              routes[i].enable_allowed_virtual =
                routes[i].allowed_virtual.length > 0 ? true : false;
              routes[i].has_rep_network = routes[i].representation_network
                ? true
                : false;
              // Removed expanded row
              if (!routes[i].show_allowed_virtual) {
                let key = routes[i].key;
                let idx = expandedRowKeys.indexOf(key);
                if (idx !== -1) {
                  expandedRowKeys.splice(idx, 1);
                }
              }
            } else {
              // remove advanced route check box status
              if (this.state.remoteNetworks.length > 0) {
                delete routes[i].show_allowed_virtual;
                delete routes[i].enable_allowed_virtual;
              }
            }
          }
        });
        this.setState({ routes: routes, expandedRowKeys: expandedRowKeys });
        this.pushData(routes);
      }
    }
    if (this.state.cidrRangeInfo !== props.cidrRangeInfo) {
      this.cidrRangeInfo = props.cidrRangeInfo;
    }
    if (this.tanCIDR !== props.tanCIDR) {
      this.tanCIDR = props.tanCIDR;
      if (this.hasAnyViaOutOfTanRange(props.tanCIDR)) {
        this.setState({ activeKey: ["1"], loading: true });
      }
      // Timeout for to automatically open the panel and load the component and so error if any
      setTimeout(() => {
        this.setState({ loading: false });
        this.validateVia(this.state.routes);
      }, 200);
    }
    // To display default routes
    if (props.UiStore.defaultRoutes) {
      if (
        props.UiStore.defaultRoutes &&
        props.UiStore.defaultRoutes.length > 0 &&
        props.UiStore.defaultRoutes !== this.state.defaultRoutes
      ) {
        let routes = this.state.routes;
        props.UiStore.defaultRoutes.forEach(defaultRoute => {
          // To avoid insert none
          if (defaultRoute && defaultRoute.via && defaultRoute.via[0]) {
            defaultRoute.disabled = true;
            defaultRoute.isDefaultRoute = true;
            let index = routes.findIndex(route => {
              return (
                route &&
                defaultRoute &&
                route.destination == defaultRoute.destination
              );
            });
            if (index !== -1) {
              routes[index] = Object.assign(
                {
                  key: Math.random()
                    .toString()
                    .replace(".", ""),
                },
                defaultRoute,
              );
            } else {
              routes.push(
                Object.assign(
                  {
                    key: Math.random()
                      .toString()
                      .replace(".", ""),
                  },
                  defaultRoute,
                ),
              );
            }
          } else {
            let index = routes.findIndex(route => {
              return (
                route &&
                defaultRoute &&
                route.destination == defaultRoute.destination
              );
            });
            if (index !== -1) {
              routes.splice(index, 1);
              this.setState({
                routes: routes,
                defaultRoutes: props.UiStore.defaultRoutes,
              });
            }
          }
        });
        if (routes && routes.length > 0) {
          routes.forEach((route, i) => {
            if (route.network_id) {
              routes[i].via = this.getValue(
                route.network_id,
                this.state.availableNetwork,
              );
              routes[i].labels = this.getLabels(
                route.network_id,
                this.state.availableNetwork,
              );
            }
          });
          this.setState({
            routes: routes,
            defaultRoutes: props.UiStore.defaultRoutes,
          });
          this.pushData(routes);
        }
        this.markHeader();
      }
    }
    // To display default routes
    if (props.UiStore.defaultRoutes) {
      if (
        props.UiStore.defaultRoutes &&
        props.UiStore.defaultRoutes.length > 0 &&
        props.UiStore.defaultRoutes !== this.state.defaultRoutes
      ) {
        let routes = this.state.routes;
        props.UiStore.defaultRoutes.forEach(defaultRoute => {
          // To avoid insert none
          if (defaultRoute && defaultRoute.via && defaultRoute.via[0]) {
            defaultRoute.disabled = true;
            defaultRoute.isDefaultRoute = true;
            let index = routes.findIndex(route => {
              return (
                route &&
                defaultRoute &&
                route.destination == defaultRoute.destination
              );
            });
            if (index !== -1) {
              routes[index] = defaultRoute;
            } else {
              routes.push(defaultRoute);
            }
          } else {
            let index = routes.findIndex(route => {
              return (
                route &&
                defaultRoute &&
                route.destination == defaultRoute.destination
              );
            });
            if (index !== -1) {
              routes.splice(index, 1);
              this.setState({
                routes: routes,
                defaultRoutes: props.UiStore.defaultRoutes,
              });
            }
          }
        });
        if (routes && routes.length > 0) {
          routes.forEach((route, i) => {
            if (route.network_id) {
              routes[i].via = this.getValue(
                route.network_id,
                this.state.availableNetwork,
              );
              routes[i].labels = this.getLabels(
                route.network_id,
                this.state.availableNetwork,
              );
            }
          });
          this.setState({
            routes: routes,
            defaultRoutes: props.UiStore.defaultRoutes,
          });
          this.pushData(routes);
        }
        this.markHeader();
      }
    }
  }

  findRemovedItems = (selectedVnodeState, selectedVnodeProps, key) => {
    // Check if selectedVnodeState and selectedVnodeProps are valid arrays
    if (
      !Array.isArray(selectedVnodeState) ||
      !Array.isArray(selectedVnodeProps)
    ) {
      return [];
    }
    const selectedNetworkIds = selectedVnodeProps.map(item => item[key]);
    // Filter out items from selectedVnodeState that do not exist in selectedVnodeProps
    const removedItems = selectedVnodeState.filter(
      item => !selectedNetworkIds.includes(item[key]),
    );
    return removedItems;
  };

  handleAllowedVirtualNetworkChange = (
    value,
    route_index,
    allowed_virtual_index,
  ) => {
    let routes = this.state.routes;
    if (
      value &&
      routes[route_index] &&
      routes[route_index].allowed_virtual &&
      routes[route_index].allowed_virtual[allowed_virtual_index]
    ) {
      let selectedVNodesObject = [];
      if (this.state.selectedVnode && this.state.availableInodes) {
        this.state.selectedVnode.forEach(item => {
          this.state.availableInodes.forEach(node => {
            if (node && node.networks) {
              node.networks.forEach(network => {
                if (network && network.id == item.network_id) {
                  selectedVNodesObject.push({
                    network_id: item.network_id,
                    node: node,
                  });
                }
              });
            }
          });
        });
      }
      let vNodeIndex = selectedVNodesObject.findIndex(vNode => {
        return vNode && vNode.network_id == value;
      });
      let vNode =
        vNodeIndex !== -1 ? selectedVNodesObject[vNodeIndex].node : null;
      routes[route_index].allowed_virtual[
        allowed_virtual_index
      ].network_id = value;
      routes[route_index].allowed_virtual[
        allowed_virtual_index
      ].has_auto_repnet_enabled =
        vNode &&
        vNode.cloud_route &&
        vNode.cloud_route.representation_network_automation
          ? vNode.cloud_route.representation_network_automation
          : false;
      this.setState({ routes: routes });
      this.pushData(routes);
      let field =
        "allowed_virtual_nw_id_" + route_index + "_" + allowed_virtual_index;
      this.props.form.setFieldsValue({
        [`${field}`]: value,
      });
    }
  };

  handleAllowedVirtualAutoRepnetChange = (
    value,
    route_index,
    allowed_virtual_index,
  ) => {
    let routes = this.state.routes;
    if (
      routes[route_index] &&
      routes[route_index].allowed_virtual &&
      routes[route_index].allowed_virtual[allowed_virtual_index]
    ) {
      routes[route_index].allowed_virtual[
        allowed_virtual_index
      ].enable_representation_network_automation = value;
      // If enabled representation network automation, reset local_representation_network
      if (value) {
        routes[route_index].allowed_virtual[
          allowed_virtual_index
        ].local_representation_network = null;
        this.props.form.resetFields([
          "allowed_virtual_repnet_" + route_index + "_" + allowed_virtual_index,
        ]);
      }
      this.setState({ routes: routes });
      this.pushData(routes);
    }
  };

  handleAllowedVirtualRepnetCidrChange = (
    value,
    route_index,
    allowed_virtual_index,
  ) => {
    let routes = this.state.routes;
    if (
      routes[route_index] &&
      routes[route_index].allowed_virtual &&
      routes[route_index].allowed_virtual[allowed_virtual_index]
    ) {
      value = value === "/" ? null : value.replace(/\/\s*$/, "");
      routes[route_index].allowed_virtual[
        allowed_virtual_index
      ].local_representation_network = value;
      this.setState({ routes: routes });
      this.pushData(routes);
      setTimeout(() => {
        let field =
          "allowed_virtual_repnet_" + route_index + "_" + allowed_virtual_index;
        if (value) {
          this.props.form.setFieldsValue({
            [`${field}`]: value,
          });
        } else {
          this.props.form.resetFields([field]);
        }
      }, 300);
    }
  };

  UNSAFE_componentWillMount() {
    // Setting initial value
    this.renderOptions(this.state.availableNetwork, this.state.selectedVnode);
    this.renderRemoteNetworks(
      this.state.availableNetwork,
      this.state.selectedVnode,
    );
    if (this.props.formData) {
      if (this.props.formData.routes && this.props.formData.routes.length > 0) {
        let routes = [];
        let selectedVNodesObject = [];
        if (this.props.selectedVnode && this.props.availableInodes) {
          this.props.selectedVnode.forEach(item => {
            this.props.availableInodes.forEach(node => {
              if (node && node.networks) {
                node.networks.forEach(network => {
                  if (network && network.id == item.network_id) {
                    selectedVNodesObject.push({
                      network_id: item.network_id,
                      node: node,
                    });
                  }
                });
              }
            });
          });
        }
        this.props.formData.routes.forEach(route => {
          routes.push({
            key: Math.random()
              .toString()
              .replace(".", ""),
            destination: route.dest,
            enable_routing_for_other_tans: route.enable_routing_for_other_tans,
            via: [route.via],
            network_id: ip.isV4Format(route.via) ? null : route.via,
            labels: [],
            isIpAddress: route.via && ip.isV4Format(route.via) ? true : false,
            show_allowed_virtual:
              route.via && ip.isV4Format(route.via) ? true : false,
            enable_allowed_virtual:
              route.allowed_virtual && route.allowed_virtual.length > 0
                ? true
                : false,
            allowed_virtual:
              route.allowed_virtual && route.allowed_virtual.length > 0
                ? route.allowed_virtual.map(allowed_virtual => {
                    let vNodeIndex = selectedVNodesObject.findIndex(vNode => {
                      returnvNode &&
                        vNode.network_id == allowed_virtual.network_id;
                    });
                    let vNode =
                      vNodeIndex !== -1
                        ? selectedVNodesObject[vNodeIndex].node
                        : null;
                    return {
                      network_id: allowed_virtual.network_id,
                      has_auto_repnet_enabled:
                        vNode &&
                        vNode.cloud_route &&
                        vNode.cloud_route.representation_network_automation
                          ? vNode.cloud_route.representation_network_automation
                          : false,
                      enable_representation_network_automation:
                        allowed_virtual.disable_representation_network_automation ==
                        false
                          ? true
                          : false,
                      local_representation_network:
                        allowed_virtual.representation_network &&
                        allowed_virtual.representation_network.local
                          ? allowed_virtual.representation_network.local
                          : null,
                    };
                  })
                : null,
            representation_network: route.representation_network
              ? route.representation_network
              : null,
            has_rep_network: route.representation_network ? true : false,
            disabled:
              route.dest && route.dest === DEFAULT_DEST_CIDR ? true : false,
          });
        });
        if (routes && routes.length > 0) {
          routes.forEach((route, i) => {
            if (route.network_id) {
              routes[i].via = this.getValue(
                route.network_id,
                this.state.availableNetwork,
              );
              routes[i].labels = this.getLabels(
                route.network_id,
                this.state.availableNetwork,
              );
            }
          });
        }
        this.setState({
          routes: routes,
        });
        this.defaultExpandRows(routes);
        this.pushData(routes);
        this.markHeader();
      }
    }
  }

  defaultExpandRows = routes => {
    this.setExpandedRowKeys(
      routes.reduce((keys, n) => {
        if (n.show_allowed_virtual) keys.push(n.key);
        return keys;
      }, []),
    );
  };

  hasAnyViaOutOfTanRange = tanCIDR => {
    let routes = this.state.routes;
    if (routes && routes.length > 0 && isCidr(tanCIDR)) {
      let indx = routes.findIndex(route => {
        return (
          route &&
          route.destination !== DEFAULT_DEST_CIDR &&
          route.via &&
          route.via[0] &&
          ip.isV4Format(route.via[0]) &&
          !isCidrRange(tanCIDR, route.via[0])
        );
      });
      if (indx !== -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  getLabels = (networkId, availableNetwork) => {
    let value = [];
    if (networkId && availableNetwork) {
      let network = availableNetwork.find(nw => {
        return nw.id === networkId;
      });
      if (network) {
        if (network.is_wan_network) {
          value.push(getCurrentlocaleText("wan_network.text"));
        } else {
          value.push(getCurrentlocaleText("network.remote_network.text"));
          value.push(network.organization.name);
          value.push(
            network.node && network.node.name
              ? network.node.name
              : network.cluster && network.cluster.name,
          );
          value.push(network.name);
        }
        return value;
      }
    }
    value.push(getCurrentlocaleText("route_config_via_default.text"));
    return value;
  };

  getValue = (networkId, availableNetwork) => {
    let value = [];
    if (networkId && availableNetwork) {
      let network = availableNetwork.find(nw => {
        return nw.id === networkId;
      });
      if (network) {
        if (network.is_wan_network) {
          value.push(network.id);
        } else {
          value.push(getCurrentlocaleText("network.remote_network.text"));
          value.push(network.organization.id);
          value.push(
            network.node && network.node.id
              ? network.node.id
              : network.cluster && network.cluster.id,
          );
          value.push(network.id);
        }
        return value;
      }
    }
    value.push(networkId);
    return value;
  };

  renderOptions = (availableNetwork, remoteNetworks) => {
    let options = [],
      option = {};
    // Ip Address Option
    option = {
      label: getCurrentlocaleText("route_config_via_ipaddress.text"),
      value: null,
    };
    options.push(option);

    // Wan network options
    if (availableNetwork) {
      let wanNetwork = availableNetwork.find(nw => {
        if (!this.props.cluster_id) {
          return (
            nw.is_wan_network && nw.node && nw.node.id === this.props.nodeId
          );
        } else {
          return (
            nw.is_wan_network &&
            nw.cluster &&
            nw.cluster.id === this.props.cluster_id
          );
        }
      });
      if (wanNetwork && !this.props.isVirtualNode) {
        option = {
          label: getCurrentlocaleText("wan_network.text"),
          value: wanNetwork.id,
        };
        options.push(option);
      }
      // Remote networks
      if (remoteNetworks) {
        option = {
          label: getCurrentlocaleText("network.remote_network.text"),
          value: getCurrentlocaleText("network.remote_network.text"),
          children: [],
        };
        let currentOrgId, currentNodeId;
        remoteNetworks.forEach(rnw => {
          let network = availableNetwork.find(nw => {
            return nw.id === rnw.network_id;
          });
          if (network) {
            // Group based on org
            if (currentOrgId !== network.organization.id) {
              option.children.push({
                label: network.organization.name,
                value: network.organization.id,
                children: [],
              });
            }
            // Find org index
            let org_index = option.children.findIndex(org => {
              return org.value === network.organization.id;
            });
            if (org_index !== -1) {
              // Group based on node/cluster
              if (
                currentNodeId !==
                (network.node && network.node.id
                  ? network.node.id
                  : network.cluster && network.cluster.id && network.cluster.id)
              ) {
                const newValue = {
                  label:
                    network.node && network.node.name
                      ? network.node.name
                      : network.cluster && network.cluster.name,
                  value:
                    network.node && network.node.id
                      ? network.node.id
                      : network.cluster && network.cluster.id
                      ? network.cluster.id
                      : null,
                  isCluster:
                    network.cluster && network.cluster.id ? true : false,
                  children: [],
                };

                if (
                  option.children[org_index] &&
                  option.children[org_index].children &&
                  !option.children[org_index].children.some(
                    obj => obj.value === newValue.value,
                  )
                ) {
                  option.children[org_index].children.push(newValue);
                }
              }

              // Find node index
              let node_index = option.children[org_index].children.findIndex(
                node => {
                  return (
                    node.value ===
                    (network.node && network.node.id
                      ? network.node.id
                      : network.cluster && network.cluster.id)
                  );
                },
              );

              if (node_index !== -1) {
                // Add network
                option.children[org_index].children[node_index].children.push({
                  label: network.name,
                  value: network.id,
                });
              }
            }
            // Check the remote network already added or not
            let in_dx = options.findIndex(option => {
              return (
                option.value ===
                getCurrentlocaleText("network.remote_network.text")
              );
            });
            if (in_dx === -1) {
              options.push(option);
            } else {
              options[in_dx] = option;
            }
            currentOrgId = network.organization.id;
            currentNodeId =
              network.node && network.node.id
                ? network.node.id
                : network.cluster && network.cluster.id;
          }
        });
      }
    }
    this.setState({ options: options });
  };

  renderRemoteNetworks = (availableNetworks, selectedVnode) => {
    let nws = [];
    if (selectedVnode) {
      selectedVnode.forEach(selectedNetwork => {
        availableNetworks.filter(networks => {
          if (selectedNetwork.network_id === networks.id) {
            nws.push(networks);
          }
        });
      });
    }
    this.setState({ remoteNetworks: nws });
  };

  buildRemoteNetworksOptionsForRoute = index => {
    let options = [],
      className = "valign-text-bottom iot-tag-icon-style";
    let routes = this.state.routes;
    let remoteNetworks = this.state.remoteNetworks;
    if (routes[index]) {
      let allowed_virtual_network_ids = [];
      if (routes[index].allowed_virtual) {
        routes[index].allowed_virtual.forEach(allowed_virtual => {
          if (allowed_virtual && allowed_virtual.network_id) {
            allowed_virtual_network_ids.push(allowed_virtual.network_id);
          }
        });
      }
      remoteNetworks.forEach(network => {
        options.push(
          <Option
            key={network.id}
            disabled={allowed_virtual_network_ids.includes(network.id)}
          >
            <Icons
              type={network.cluster && network.cluster.id ? "bs" : "fa"}
              name={
                network.cluster && network.cluster.id
                  ? "BsBoundingBox"
                  : "FaRegHdd"
              }
              className={className}
            />{" "}
            {network.node && network.node.name
              ? network.node.name
              : network.cluster && network.cluster.name}
            {"/"}
            <Icons
              type={"fa"}
              name="FaConnectdevelop"
              className={className}
            />{" "}
            {network.name}
          </Option>,
        );
      });
    }
    return options;
  };

  displayRender = (labels, selectedOptions) => {
    if (labels) {
      // For WAN Nework option
      if (labels.length === 1) {
        return <Tag style={{ border: "none" }}>{labels[0]}</Tag>;
      }
      // For other options
      if (labels.length === 4 && selectedOptions[2]) {
        return this.getDisplayTag(labels, selectedOptions[2].isCluster);
      }
    }
  };

  onExpand = (expanded, record) => {
    if (record.show_allowed_virtual) {
      let keys = this.state.expandedRowKeys;
      let index = keys.findIndex(val => {
        return val === record.key;
      });
      if (expanded) {
        if (index === -1) {
          keys.push(record.key);
        }
        this.setExpandedRowKeys(keys);
      } else {
        if (index !== -1) {
          keys.splice(index, 1);
        }
        this.setExpandedRowKeys(keys);
      }
    }
  };

  setExpandedRowKeys = keys => {
    this.setState({ expandedRowKeys: keys });
  };

  updateHelp = data => {
    this.props.UiStore.SetStoreData("helpData", data);
  };

  handleViaOnChange = (index, value, selectedOptions) => {
    let routes = this.state.routes;
    let expandedRowKeys = this.state.expandedRowKeys;
    // For other options
    if (value && value[0]) {
      routes[index].via = value;
      routes[index].network_id = value[value.length - 1];
      routes[index].labels = this.getLabels(
        routes[index].network_id,
        this.state.availableNetwork,
      );
      routes[index].isIpAddress = false;
      routes[index].disabled = false;
    } else {
      // For IpAddress option only
      routes[index].via = null;
      routes[index].isIpAddress = true;
      routes[index].allowed_virtual = [];
      routes[index].network_id = null;
      routes[index].labels = [];
      routes[index].disabled = false;
      if (!this.props.isVirtualNode) {
        routes[index].show_allowed_virtual = true;
        if (!expandedRowKeys.includes(routes[index].key)) {
          expandedRowKeys.push(routes[index].key);
        }
      }
      this.focusInput("route-config-via-" + index);
    }
    this.setState({ routes: routes });
    this.pushData(routes);
    this.setExpandedRowKeys(expandedRowKeys);
  };

  getDisplayTag = (labels, isCluster = false) => {
    let type = "fa",
      className = "valign-text-bottom iot-tag-icon-style";
    if (labels && labels.length > 1) {
      return (
        <Tag key={labels}>
          <Icons type={type} name="FaSitemap" className={className} />{" "}
          {labels[1]}&nbsp; /{" "}
          <Icons
            type={isCluster ? "bs" : type}
            name={isCluster ? "BsBoundingBox" : "FaRegHdd"}
            className={className}
          />{" "}
          {labels[2]}&nbsp; /{" "}
          <Icons type={type} name="FaConnectdevelop" className={className} />{" "}
          {labels[3]}&nbsp;
        </Tag>
      );
    }
    return <span />;
  };

  checkCidr = (rule, value, callback) => {
    let ct =
      rule &&
      rule.field &&
      rule.field.split("_") &&
      rule.field.split("_").length > 1 &&
      rule.field.split("_")[1];
    let message = undefined;
    if (value) {
      let isCidrValid = value && isCidr(value, false);
      if (!isCidrValid) {
        message = getCurrentlocaleText("network.form.cidr.errormessage2.text");
      }
      let currentCIDR = getCIDRdata(value);
      if (currentCIDR) {
        if (isRestrictedCIDR(currentCIDR)) {
          message = getCurrentlocaleText(
            "network.form.cidr.errormessage3.text",
          );
        }
      }

      // To check the duplicate cidr
      let routes = this.state.routes ? this.state.routes : [];
      let count = routes.filter(route => {
        return route && route.destination && route.destination === value;
      }).length;
      if (count > 1) {
        message = getCurrentlocaleText("route.duplicate.destination.error");
      }
      let routeFiltered = routes.filter(route => {
        return ct === route.key;
      });
      let destinationCIDR = getCIDRdata(
        routeFiltered &&
          routeFiltered.length > 0 &&
          routeFiltered[0].destination &&
          routeFiltered[0].destination,
      );
      if (
        destinationCIDR &&
        destinationCIDR.subnetMaskLength !== currentCIDR.subnetMaskLength
      ) {
        message = getCurrentlocaleText(
          "route.duplicate.destinationMask.error.text",
        );
      }
    }
    if (message) {
      callback(message);
    }
    callback();
  };

  addRoute = () => {
    let routes = this.state.routes;
    let route = {
      key: Math.random()
        .toString()
        .replace(".", ""),
      destination: null,
      via: null,
      network_id: null,
      isIpAddress: false,
      allowOtherTanNw: false,
    };
    routes.push(route);
    this.setState({ routes: routes });
    this.pushData(routes);
    this.focusInput("route-config-destination-" + (routes.length - 1));
  };

  removeRoute = index => {
    let routes = this.state.routes;
    routes.splice(index, 1);
    this.setState({ routes: routes });
    this.pushData(routes);
  };

  handleOnChangeCollapse = key => {
    this.setState({ activeKey: key });
    if (key && key.length) {
      this.setState({ loading: true });
      setTimeout(() => {
        this.setState({ loading: false });
      }, 200);
    }
  };

  markHeader = () => {
    let hasRoutes =
      this.props.formData &&
      this.props.formData.routes &&
      this.props.formData.routes.length > 0
        ? true
        : false;
    markCollapseHeader("route-config-collapse", hasRoutes);
  };

  pushData = routes => {
    if (this.props.setRoutes) {
      this.props.setRoutes(routes);
    }
  };

  handleDestinationOnChange = (index, e) => {
    if (e) {
      e = e.replace(/\/\s*$/, "");
      let routes = this.state.routes;
      routes[index].destination = e;
      this.setState({ routes: routes });
      this.pushData(routes);
    }
  };

  handleIpAddressOnChange = (index, e) => {
    if (e && e.target) {
      let routes = this.state.routes;
      routes[index].via = [e.target.value];
      this.setState({ routes: routes });
      this.pushData(routes);
    }
  };

  removeViaFromIpAddressMode = index => {
    let routes = this.state.routes;
    routes[index].labels = null;
    routes[index].via = null;
    routes[index].isIpAddress = false;
    routes[index].show_allowed_virtual = false;
    routes[index].allowed_virtual = [];
    routes[index].enable_routing_for_other_tans = false;
    routes[index].representation_network = null;
    this.setState({ routes: routes });
    this.pushData(routes);
    let obj = {},
      fieldName = "via_" + routes[index].key;
    obj[fieldName] = { value: this.props.form.getFieldValue(fieldName) };
    obj[fieldName].errors = null;
    this.props.form.setFields(obj);
    let expandedRowKeys = this.state.expandedRowKeys;
    let key = routes[index].key;
    let idx = expandedRowKeys.indexOf(key);
    if (idx !== -1) {
      expandedRowKeys.splice(idx, 1);
    }
    this.setExpandedRowKeys(expandedRowKeys);
  };

  checkIpV4 = (rule, value, callback) => {
    let failMessage = null;
    try {
      if (value && ip.isV4Format(value) !== true) {
        failMessage = getCurrentlocaleText(
          "network.form.startip.errormessage1.text",
        );
      } else if (
        value &&
        ip.isV4Format(value) === true &&
        (isCidr(value) === true || !isCidrRange(this.tanCIDR, value))
      ) {
        failMessage =
          this.cidrRangeInfo != null
            ? getCurrentlocaleText("static.route.ipaddress.errormessage.text") +
              " " +
              this.cidrRangeInfo
            : getCurrentlocaleText("static.route.ipaddress.errormessage.text");
      }
    } catch (e) {
      failMessage =
        this.cidrRangeInfo != null
          ? getCurrentlocaleText("static.route.ipaddress.errormessage.text") +
            " " +
            this.cidrRangeInfo
          : getCurrentlocaleText("static.route.ipaddress.errormessage.text");
    }

    if (failMessage) {
      callback(failMessage);
    } else {
      callback();
    }
  };

  isCidrAndIpValid = (cidrValue, ipValue, fieldName) => {
    let isCidrValid =
      cidrValue && isCidr(cidrValue) && isCidrRange(cidrValue, ipValue);
    let obj = {};
    if (ipValue) {
      obj[fieldName] = { value: this.props.form.getFieldValue(`${fieldName}`) };
      if (isCidrValid && ip.isV4Format(ipValue)) {
        this.props.form.setFields(obj);
      } else if (ip.isV4Format(ipValue)) {
        obj[fieldName].errors = [
          new Error(
            this.cidrRangeInfo != null
              ? getCurrentlocaleText(
                  "static.route.ipaddress.errormessage.text",
                ) +
                " " +
                this.cidrRangeInfo
              : getCurrentlocaleText(
                  "static.route.ipaddress.errormessage.text",
                ),
          ),
        ];
        this.props.form.setFields(obj);
      } else {
        obj[fieldName].errors = [
          new Error(
            getCurrentlocaleText("network.form.startip.errormessage1.text"),
          ),
        ];
        this.props.form.setFields(obj);
      }
    }
  };

  focusInput = elmId => {
    setTimeout(() => {
      if (elmId) {
        let elm = document.getElementById(elmId);
        if (elm) {
          elm.focus();
        }
      }
    }, 200);
  };

  /**
   * Method to move default route at the top position of the routes
   */
  moveDefaultRouteAtTop = routes => {
    if (routes) {
      let index = routes.findIndex(route => {
        return route && route.isDefaultRoute;
      });
      if (index != -1 && index != 0) {
        let route = routes[index];
        routes.splice(index, 1);
        routes.unshift(route);
      }
    }
    return routes;
  };

  validateVia = routes => {
    if (routes && routes.length > 0) {
      routes.forEach((route, i) => {
        if (
          route &&
          route.isIpAddress &&
          route.destination !== DEFAULT_DEST_CIDR
        ) {
          let formItem = "via_" + route.key;
          this.isCidrAndIpValid(
            this.tanCIDR,
            route.via && route.via[0],
            formItem,
          );
        }
      });
    }
  };

  markErrorRoutes = routes => {
    const error =
      this.props.UiStore.formErrorResponse &&
      this.props.UiStore.formErrorResponse.data &&
      this.props.UiStore.formErrorResponse.data.errorCode &&
      this.props.UiStore.formErrorResponse.data.errorCode === "RouteConflict"
        ? this.props.UiStore.formErrorResponse.data.errorList
        : null;
    // To reset error from routes for every form submit
    if (this.formErrorResponse !== this.props.UiStore.formErrorResponse.data) {
      if (routes) {
        routes.forEach(route => {
          route.isError = false;
          route.errorCode = null;
          route.additionalInfo = null;
        });
      }
      this.formErrorResponse = this.props.UiStore.formErrorResponse.data;
    }
    if (routes && error) {
      routes.forEach(route => {
        if (error[route.destination]) {
          route.isError = true;
          route.errorCode = error[route.destination].errorCode;
          route.additionalInfo = error[route.destination].additionalInfo;
          // To map error with route
          error[route.destination].key = route.key;
        }
      });
    }
    return routes;
  };

  showAllowedVirtual = index => {
    let routes = this.state.routes;
    if (
      routes[index].allowed_virtual &&
      routes[index].allowed_virtual.length > 0
    ) {
      routes[index].show_allowed_virtual = !routes[index].show_allowed_virtual;
      this.setState({ routes: routes });
    }
  };

  enableAllowedVirtual = (e, index) => {
    let routes = this.state.routes;
    routes[index].enable_allowed_virtual = e.target.checked;
    routes[index].show_allowed_virtual = e.target.checked;
    if (e.target.checked) {
      if (
        !routes[index].allowed_virtual ||
        routes[index].allowed_virtual.length == 0
      ) {
        routes[index].allowed_virtual = [
          {
            network_id: "",
            has_auto_repnet_enabled: true,
            enable_representation_network_automation: false,
            local_representation_network: null,
          },
        ];
      }
    } else {
      routes[index].allowed_virtual = [];
    }
    this.setState({ routes: routes });
    this.pushData(routes);
  };

  addAllowedVirtual = index => {
    let routes = this.state.routes;
    let allowVirtual = {
      network_id: "",
      has_auto_repnet_enabled: true,
      enable_representation_network_automation: false,
      local_representation_network: null,
    };
    routes[index].allowed_virtual.push(allowVirtual);
    this.setState({ routes: routes });
    this.pushData(routes);
  };

  removeAllowedVirtual = (routeIndex, allowedVirtualIndex) => {
    let routes = this.state.routes;
    routes[routeIndex].allowed_virtual.splice(allowedVirtualIndex, 1);
    if (routes[routeIndex].allowed_virtual.length == 0) {
      routes[routeIndex].enable_allowed_virtual = false;
      routes[routeIndex].show_allowed_virtual = false;
    }
    this.setState({ routes: routes });
    this.pushData(routes);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    let { routes, options } = this.state;
    routes = this.moveDefaultRouteAtTop(routes);
    routes = this.markErrorRoutes(routes);

    this.columns = [
      {
        dataIndex: "destination",
        key: "destination",
        width: "35%",
        render: (text, record, index) => {
          return (
            <div>
              <span>
                {getCurrentlocaleText("route_config.destination.text")}
              </span>
              <FormItem>
                {getFieldDecorator("destination_" + record.key, {
                  rules: [
                    { required: false },
                    {
                      validator: record.isDefaultRoute
                        ? null
                        : (rule, value, callback) => {
                            value = record.destination
                              ? record.destination
                              : null;
                            let isCidrValid = value && isCidr(value, false);
                            if (!isCidrValid) {
                              callback(
                                getCurrentlocaleText(
                                  "network.form.cidr.errormessage2.text",
                                ),
                              );
                            }
                            let currentCIDR = getCIDRdata(value);
                            if (currentCIDR) {
                              if (isRestrictedCIDR(currentCIDR)) {
                                callback(
                                  getCurrentlocaleText(
                                    "network.form.cidr.errormessage3.text",
                                  ),
                                );
                              }
                            }
                            // To check the duplicate cidr
                            let routes = this.state.routes;
                            let count = routes.filter(route => {
                              return (
                                route &&
                                route.destination &&
                                route.destination === value
                              );
                            }).length;
                            if (count > 1) {
                              callback(
                                getCurrentlocaleText(
                                  "route.duplicate.destination.error",
                                ),
                              );
                            }
                            callback();
                          },
                    },
                  ],
                  initialValue: record.destination ? record.destination : null,
                })(
                  <span>
                    {this.props.isVirtualNode &&
                    record.representation_network &&
                    record.representation_network.peer ? (
                      <Popover
                        content={
                          <span className="rep-network-view">
                            {getCurrentlocaleText(
                              "network.form.representational_network.text",
                            ) +
                              ": " +
                              record.representation_network.peer}
                          </span>
                        }
                      >
                        <span>
                          {" "}
                          <CidrProvider
                            maskLength={"34%"}
                            ipLength={"57%"}
                            id={"route-config-destination-" + index}
                            className={"rep-connected-network"}
                            tancidr={record.destination}
                            isDisabled={true}
                          />
                        </span>
                      </Popover>
                    ) : (
                      <CidrProvider
                        maskLength={"34%"}
                        ipLength={"57%"}
                        id={"route-config-destination-" + index}
                        className={
                          record && record.isError
                            ? "error-on-remote-network"
                            : null
                        }
                        tancidr={record.destination}
                        handleChange={this.handleDestinationOnChange.bind(
                          this,
                          index,
                        )}
                        isDisabled={
                          this.props.viewOnly || record.isDefaultRoute
                        }
                        onFocus={this.updateHelp.bind(this, {
                          title: getCurrentlocaleText(
                            "route_config.destination.text",
                          ),
                          data: [
                            {
                              subtitle: "",
                              content: getCurrentlocaleText(
                                "route_config.destination.help.text",
                              ),
                            },
                          ],
                        })}
                      />
                    )}
                  </span>,
                )}
              </FormItem>
            </div>
          );
        },
      },
      {
        dataIndex: "via",
        key: "via",
        width: "30%",
        render: (text, record, index) => {
          return (
            <div style={{ width: "90%" }}>
              <span>{getCurrentlocaleText("route_config.via.text")}</span>
              <FormItem>
                {getFieldDecorator("via_" + record.key, {
                  rules: [
                    { required: false },
                    { validator: record.isIpAddress ? this.checkIpV4 : null },
                  ],
                  initialValue:
                    record.isIpAddress && !record.isDefaultRoute
                      ? record.via && record.via[0]
                        ? record.via[0]
                        : null
                      : null,
                })(
                  <span>
                    {!record.isIpAddress ? (
                      <Cascader
                        id={"route-config-via-" + index}
                        popupClassName="route-config-cascade-popup"
                        placeholder={getCurrentlocaleText(
                          "static_route_config.via_placeholder.text",
                        )}
                        notFoundContent={getCurrentlocaleText(
                          "general.notfound.placeholder.text",
                        )}
                        options={options}
                        allowClear={false}
                        displayRender={this.displayRender.bind(this)}
                        expandTrigger={"hover"}
                        onChange={this.handleViaOnChange.bind(this, index)}
                        title={
                          record.labels
                            ? record.labels.length === 1
                              ? record.labels[0]
                              : `${record.labels[1]} / ${record.labels[2]} / ${record.labels[3]}`
                            : ""
                        }
                        value={
                          record.isDefaultRoute ? record.labels[0] : record.via
                        }
                        disabled={this.props.viewOnly || record.isDefaultRoute}
                        onFocus={this.updateHelp.bind(this, {
                          title: getCurrentlocaleText("route_config.via.text"),
                          data: [
                            {
                              subtitle: "",
                              content: (
                                <RouteConfigViaHelp
                                  selectedVnode={this.state.selectedVnode}
                                />
                              ),
                            },
                          ],
                        })}
                      />
                    ) : (
                      <span>
                        <Input
                          id={"route-config-via-" + index}
                          placeholder={getCurrentlocaleText(
                            "static_route_config.via_placeholder.text",
                          )}
                          value={
                            record.via && record.via[0] ? record.via[0] : null
                          }
                          onChange={this.handleIpAddressOnChange.bind(
                            this,
                            index,
                          )}
                          addonAfter={
                            !this.props.viewOnly && !record.isDefaultRoute ? (
                              <span
                                onClick={this.removeViaFromIpAddressMode.bind(
                                  this,
                                  index,
                                )}
                                title={getCurrentlocaleText(
                                  "route_config.remove.ipaddress.text",
                                )}
                              >
                                <Icons type="ai" name="AiOutlineCloseCircle" />
                              </span>
                            ) : null
                          }
                          disabled={
                            this.props.viewOnly || record.isDefaultRoute
                          }
                          onFocus={this.updateHelp.bind(this, {
                            title: getCurrentlocaleText(
                              "route_config.via.text",
                            ),
                            data: [
                              {
                                subtitle: "",
                                content: (
                                  <RouteConfigViaHelp
                                    selectedVnode={this.state.selectedVnode}
                                  />
                                ),
                              },
                            ],
                          })}
                        />
                      </span>
                    )}
                  </span>,
                )}
              </FormItem>
            </div>
          );
        },
      },
      {
        dataIndex: "allow_tan",
        key: "allow_tan",
        width: "20%",
        render: (text, record, index) => {
          // Disable if Repent or wan nw
          let repentOrWanNw = record.isIpAddress ? false : true;
          return (
            <div style={{ textAlign: "center" }}>
              <Checkbox
                checked={routes[index].enable_routing_for_other_tans}
                onChange={value => {
                  let routes = this.state.routes;
                  routes[index].enable_routing_for_other_tans = !routes[index]
                    .enable_routing_for_other_tans;
                  this.setState({ routes });
                  this.pushData(routes);
                }}
                disabled={
                  this.props.viewOnly ||
                  !this.props.enableInterTan ||
                  repentOrWanNw
                    ? true
                    : false
                }
              >
                {getCurrentlocaleText("route_config.inter_tan_route.text")}
              </Checkbox>
            </div>
          );
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "remove-route",
        render: (text, record, index) => {
          return (
            <div style={{ marginLeft: record.isDefaultRoute ? 16 : 24 }}>
              {/* Show remove icon to remove static routes */}
              {!this.props.viewOnly && !record.isDefaultRoute && (
                <span
                  title={getCurrentlocaleText("route_config.remove_route.text")}
                  className="remote-network-action-btn"
                  onClick={() => {
                    this.removeRoute(index);
                  }}
                >
                  <Icons type="ai" name="AiOutlineDelete" />
                </span>
              )}
              {/* Show info icon of the default route */}
              {record.isDefaultRoute && (
                <PopOverHelp
                  isHelpOnForm={true}
                  height={50}
                  width={180}
                  placement="top"
                  helpData={{
                    title: " ",
                    data: [
                      {
                        subtitle: "",
                        content: getCurrentlocaleText(
                          "default.destination.info.in.static.routes",
                        ),
                      },
                    ],
                  }}
                  style={{
                    fontSize: 18,
                  }}
                />
              )}
              {/* Show error icon if route conflict exists */}
              {record.isError && !record.additionalInfo && (
                <Popover
                  overlayStyle={{ maxWidth: "30%", float: "right" }}
                  placement="left"
                  content={
                    <span className="iotium-danger">
                      {record.errorCode === "RouteConflictWithInRoute"
                        ? getCurrentlocaleText(
                            "route.conflictWithInRoute_new.error",
                          )
                        : record.errorCode === "RouteConflictWithRemoteNetwork"
                        ? getCurrentlocaleText(
                            "route.conflictWithRemoteNetwork_new.error",
                          )
                        : record.errorCode === RouteErrorCode
                        ? getCurrentlocaleText(
                            "route.RouteConflictWithRemoteNetworkRoute.error",
                          )
                        : record.errorCode ===
                          "RouteConflictWithRemoteNetworkCloudRoute"
                        ? getCurrentlocaleText(
                            "route.RouteConflictWithRemoteNetworkCloudRoute.error",
                          )
                        : getCurrentlocaleText("route.conflict.error")}
                    </span>
                  }
                >
                  <Button
                    style={{ border: "none", marginLeft: 7 }}
                    shape="circle"
                    size={"small"}
                  >
                    <Icons
                      type="fa"
                      name="FaTimesCircle"
                      className="remote-network-action-btn error-on-remote-network-icon valign-sub"
                    />
                  </Button>
                </Popover>
              )}
            </div>
          );
        },
      },
    ];

    // Don't show enable inter routing for wan network, virtual network and dynamic network
    if (!this.props.showInterTan) {
      try {
        this.columns.splice(2, 1);
        this.columns[0].width = "40%";
        this.columns[1].width = "40%";
      } catch (error) {
        // Do nothing
      }
    }

    let content = (
      <>
        {!this.props.viewOnly && (
          <div style={{ textAlign: "right" }}>
            <Button
              icon={<Icons type="ai" name="AiOutlinePlus" />}
              size="small"
              style={{ margin: "0 20px 10px 0" }}
              onClick={this.addRoute}
              type={"primary"}
              title={getCurrentlocaleText("route_config.add_route.text")}
            >
              {getCurrentlocaleText("general.button.add.label.text")}
            </Button>
          </div>
        )}
        <Table
          size={"small"}
          bordered={false}
          columns={this.columns}
          dataSource={[...routes]}
          expandedRowRender={(record, index) => {
            return (
              <Row key={index} style={{ marginLeft: 40 }}>
                <Col span={24}>
                  {!this.props.isVirtualNode && (
                    <Row style={{ paddingBottom: 7 }}>
                      <Col span={24}>
                        <span
                          style={{
                            cursor:
                              record.allowed_virtual &&
                              record.allowed_virtual.length > 0
                                ? "pointer"
                                : "auto",
                          }}
                          onClick={() => this.showAllowedVirtual(index)}
                        >
                          <Icons
                            type="ai"
                            className="baseline"
                            name={
                              record.enable_allowed_virtual
                                ? "AiOutlineDown"
                                : "AiOutlineRight"
                            }
                            style={{ fontSize: 12, marginRight: 7 }}
                          />
                        </span>

                        <Checkbox
                          checked={
                            record.enable_allowed_virtual
                              ? record.enable_allowed_virtual
                              : false
                          }
                          disabled={
                            this.props.viewOnly || record.isDefaultRoute
                          }
                          onChange={value =>
                            this.enableAllowedVirtual(value, index)
                          }
                        >
                          {getCurrentlocaleText(
                            "route.allowed.remote.network.label",
                          )}
                        </Checkbox>
                        <span>
                          [
                          {record.allowed_virtual
                            ? record.allowed_virtual.length
                            : 0}
                          ]
                        </span>
                      </Col>
                    </Row>
                  )}
                  {record.allowed_virtual && record.allowed_virtual.length > 0 && (
                    <>
                      <Row
                        style={{
                          marginTop: 10,
                          marginBotton: 10,
                          display: record.show_allowed_virtual
                            ? "block"
                            : "none",
                        }}
                      >
                        <Col span={24}>
                          {record.allowed_virtual.map(
                            (allowed_virtual, allowed_virtual_index) => {
                              return (
                                <Row
                                  gutter={[24, 24]}
                                  key={`${index}-${allowed_virtual_index}`}
                                >
                                  <Col span={5}>
                                    <div>
                                      <span>
                                        {getCurrentlocaleText(
                                          "route_config.remote.network.text",
                                        )}
                                      </span>
                                      <FormItem>
                                        {getFieldDecorator(
                                          "allowed_virtual_nw_id_" +
                                            index +
                                            "_" +
                                            allowed_virtual_index,
                                          {
                                            rules: [
                                              {
                                                required: true,
                                                message: getCurrentlocaleText(
                                                  "route_config.remote.network.error",
                                                ),
                                              },
                                              {
                                                validator: (
                                                  rule,
                                                  value,
                                                  callback,
                                                ) => {
                                                  if (value) {
                                                    let same_virtual_networks = record.allowed_virtual.filter(
                                                      item => {
                                                        return (
                                                          item &&
                                                          item.network_id &&
                                                          item.network_id ==
                                                            value
                                                        );
                                                      },
                                                    );
                                                    if (
                                                      same_virtual_networks.length >
                                                      1
                                                    ) {
                                                      callback(
                                                        getCurrentlocaleText(
                                                          "route.duplicate.virtual_network.error",
                                                        ),
                                                      );
                                                    }
                                                  }
                                                  callback();
                                                },
                                              },
                                            ],
                                            initialValue: allowed_virtual.network_id
                                              ? allowed_virtual.network_id
                                              : null,
                                          },
                                        )(
                                          <Select
                                            style={{ paddingTop: 5 }}
                                            placeholder={getCurrentlocaleText(
                                              "route.allowed.remote.network.placeholder",
                                            )}
                                            onChange={val => {
                                              this.handleAllowedVirtualNetworkChange(
                                                val,
                                                index,
                                                allowed_virtual_index,
                                              );
                                            }}
                                            disabled={
                                              this.props.viewOnly ||
                                              record.isDefaultRoute
                                            }
                                            onFocus={this.updateHelp.bind(
                                              this,
                                              {
                                                title: getCurrentlocaleText(
                                                  "route.allowed.remote.network.label",
                                                ),
                                                data: [
                                                  {
                                                    subtitle: "",
                                                    content: getCurrentlocaleText(
                                                      "route.form.remotenetwork.help1.content",
                                                    ),
                                                  },
                                                ],
                                              },
                                            )}
                                            notFoundContent={getCurrentlocaleText(
                                              "general.notfound.placeholder.text",
                                            )}
                                          >
                                            {this.state.remoteNetworks
                                              ? this.buildRemoteNetworksOptionsForRoute(
                                                  index,
                                                )
                                              : []}
                                          </Select>,
                                        )}
                                      </FormItem>
                                    </div>
                                  </Col>
                                  <Col
                                    span={5}
                                    style={{ paddingRight: 0, marginLeft: -6 }}
                                  >
                                    <div style={{ marginTop: 30 }}>
                                      <Checkbox
                                        disabled={
                                          this.props.viewOnly ||
                                          record.isDefaultRoute ||
                                          !allowed_virtual.has_auto_repnet_enabled
                                        }
                                        checked={
                                          allowed_virtual.enable_representation_network_automation
                                            ? allowed_virtual.enable_representation_network_automation
                                            : false
                                        }
                                        onChange={e => {
                                          this.handleAllowedVirtualAutoRepnetChange(
                                            e.target.checked,
                                            index,
                                            allowed_virtual_index,
                                          );
                                        }}
                                      >
                                        {" "}
                                        {getCurrentlocaleText(
                                          "route_config.enable.repnet.text",
                                        )}
                                      </Checkbox>
                                    </div>
                                  </Col>
                                  <Col span={8} style={{ paddingLeft: 0 }}>
                                    <span>
                                      {getCurrentlocaleText(
                                        "network.form.route.rep_network.text",
                                      )}
                                    </span>
                                    <FormItem>
                                      {getFieldDecorator(
                                        "allowed_virtual_repnet_" +
                                          index +
                                          "_" +
                                          allowed_virtual_index,
                                        {
                                          rules: [
                                            { required: false },
                                            {
                                              validator: (
                                                rule,
                                                value,
                                                callback,
                                              ) => {
                                                value = allowed_virtual.local_representation_network
                                                  ? allowed_virtual.local_representation_network
                                                  : null;
                                                this.checkCidr(
                                                  rule,
                                                  value,
                                                  callback,
                                                );
                                              },
                                            },
                                          ],
                                          initialValue: allowed_virtual.local_representation_network
                                            ? allowed_virtual.local_representation_network
                                            : null,
                                        },
                                      )(
                                        <CidrProvider
                                          style={{ width: "80%" }}
                                          maskLength={"33%"}
                                          ipLength={"57%"}
                                          id={
                                            "allowed_virtual_repnet_" +
                                            index +
                                            "_" +
                                            allowed_virtual_index
                                          }
                                          className={
                                            record && record.isError
                                              ? "error-on-remote-network"
                                              : null
                                          }
                                          tancidr={
                                            allowed_virtual.local_representation_network
                                              ? allowed_virtual.local_representation_network
                                              : null
                                          }
                                          handleChange={value => {
                                            this.handleAllowedVirtualRepnetCidrChange(
                                              value,
                                              index,
                                              allowed_virtual_index,
                                            );
                                          }}
                                          isDisabled={
                                            this.props.viewOnly ||
                                            record.isDefaultRoute ||
                                            allowed_virtual.enable_representation_network_automation
                                          }
                                          onFocus={this.updateHelp.bind(this, {
                                            title: getCurrentlocaleText(
                                              "network.form.route.rep_network.text",
                                            ),
                                            data: [
                                              {
                                                subtitle: "",
                                                content: getCurrentlocaleText(
                                                  "route.form.rep_network.help1.content",
                                                ),
                                              },
                                            ],
                                          })}
                                        />,
                                      )}
                                    </FormItem>
                                  </Col>
                                  <Col span={3}>{/*/ for spacing */}</Col>
                                  <Col span={3} style={{ paddingLeft: 37 }}>
                                    <div>
                                      <span>
                                        {!this.props.viewOnly &&
                                          !record.isDefaultRoute && (
                                            <div
                                              style={{
                                                marginTop: 25,
                                                float: "left",
                                              }}
                                              title={getCurrentlocaleText(
                                                "route_config.remove_route.text",
                                              )}
                                              className="remote-network-action-btn"
                                              onClick={() => {
                                                this.removeAllowedVirtual(
                                                  index,
                                                  allowed_virtual_index,
                                                );
                                              }}
                                            >
                                              <Icons
                                                type="ai"
                                                name="AiOutlineDelete"
                                              />
                                            </div>
                                          )}
                                      </span>
                                      <span>
                                        {/* Show error icon if route conflict exists */}
                                        {record.isError &&
                                          record.additionalInfo &&
                                          record.additionalInfo.hasOwnProperty(
                                            allowed_virtual.network_id,
                                          ) && (
                                            <Popover
                                              overlayStyle={{
                                                maxWidth: "30%",
                                                float: "right",
                                              }}
                                              placement="left"
                                              content={
                                                <span className="iotium-danger">
                                                  {record.errorCode ===
                                                  "RouteConflictWithRemoteNetwork"
                                                    ? getCurrentlocaleText(
                                                        "route.repnetConflictWithRemoteNetwork.error",
                                                      )
                                                    : record.errorCode ===
                                                      RouteErrorCode
                                                    ? getCurrentlocaleText(
                                                        "route.repnetRouteConflictWithRemoteNetworkRoute.error",
                                                      )
                                                    : record.errorCode ===
                                                      "RouteConflictWithRemoteNetworkCloudRoute"
                                                    ? getCurrentlocaleText(
                                                        "route.repnetRouteConflictWithRemoteNetworkCloudRoute.error",
                                                      )
                                                    : getCurrentlocaleText(
                                                        "route.conflict.error",
                                                      )}
                                                </span>
                                              }
                                            >
                                              <Button
                                                style={{
                                                  border: "none",
                                                  marginTop: 30,
                                                  marginLeft: 7,
                                                }}
                                                shape="circle"
                                                size={"small"}
                                              >
                                                <Icons
                                                  type="fa"
                                                  name="FaTimesCircle"
                                                  className="remote-network-action-btn error-on-remote-network-icon baseline"
                                                />
                                              </Button>
                                            </Popover>
                                          )}
                                      </span>
                                    </div>
                                  </Col>
                                </Row>
                              );
                            },
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Button
                            type="text"
                            className={"primary-color"}
                            icon={<Icons type="ai" name="AiOutlinePlus" />}
                            disabled={
                              record.allowed_virtual &&
                              this.state.remoteNetworks &&
                              record.allowed_virtual.filter(nw => nw.network_id)
                                .length == this.state.remoteNetworks.length
                            }
                            style={{
                              paddingLeft: 0,
                              fontWeight: "bold",
                              display: record.show_allowed_virtual
                                ? "block"
                                : "none",
                            }}
                            onClick={() => {
                              this.addAllowedVirtual(index);
                            }}
                          >
                            {" "}
                            {getCurrentlocaleText(
                              "route_config.add.more.remote.network",
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                </Col>
              </Row>
            );
          }}
          expandedRowKeys={[...this.state.expandedRowKeys]}
          pagination={false}
          onExpand={this.onExpand}
          indentSize={5}
          rowClassName={(record, index) => {
            return record.show_allowed_virtual &&
              this.state.remoteNetworks.length > 0
              ? ""
              : "is-wan-network";
          }}
          locale={{
            emptyText: getCurrentlocaleText("route_config.no_route.text"),
          }}
        />
      </>
    );

    return (
      <Collapse
        onChange={this.handleOnChangeCollapse}
        className="route-config-collapse"
        activeKey={this.state.activeKey}
      >
        <Panel
          key={"1"}
          header={
            <div className="panel-header-text">
              {getCurrentlocaleText("static_routes.text")}
            </div>
          }
        >
          {this.state.loading ? (
            <div style={{ textAlign: "center" }}>
              <Spin tip="Loading..." />
            </div>
          ) : (
            <div>{content}</div>
          )}
        </Panel>
      </Collapse>
    );
  }
}

const RouteConfig = Form.create()(RouteConfiguration);
export default RouteConfig;
