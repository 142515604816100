import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import { Switch, Checkbox } from "antd";
import { inject } from "mobx-react";
import { copyObject, randomAlphaNumericString } from "Core/Utils";
import { computed } from "mobx";
import Twig from "twig";

const FormItem = Form.Item;
const formItemLayout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};

@inject("UiStore")
export default class ToggleInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: false,
      isUpdated: false,
    };
    this.validationMessage =
      this.props.properties.constraints &&
      this.props.properties.constraints.validationMessage
        ? this.props.properties.constraints.validationMessage
        : `${this.props.properties.label} is required`;
  }
  // change event updated
  onChange = checked => {
    this.setState({
      isChecked: checked,
      isUpdated: true,
    });
    if (this.props.updateCurrentValueOnChange) {
      this.props.updateCurrentValueOnChange(checked, "value");
      this.updateValue(checked);
    }
  };

  @computed
  get componentVisibility() {
    let visibility = null;
    if (
      this.props.UiStore.computedOutputObject &&
      this.props.UiStore.computedOutputObject.steps
    ) {
      if (this.props.properties.visibility) {
        var template = Twig.twig({
          data: this.props.properties.visibility,
        });
        let computedOutputObject = copyObject(
          this.props.UiStore.computedOutputObject,
        );
        visibility = template.render(computedOutputObject);
        try {
          visibility = JSON.parse(visibility);
        } catch {}
      }
    }
    return visibility ? true : false;
  }

  updateValue = value => {
    let steps = this.props.uiSchema.steps;
    if (!steps) {
      steps = toJS(this.props.baseSchema).steps;
    }
    if (steps.length > 0) {
      steps.forEach((step, i) => {
        let elements = steps[i].elements;
        elements.forEach((element, index) => {
          if (element["__id"] === this.props.properties["__id"]) {
            this.props.UiStore.templatingService.computedSchema.steps[i]
              .elements[index].props.defaultValue.value;
          }
        });
      });
    }
  };

  componentDidMount() {
    let isChecked =
      this.props.properties.defaultValue &&
      this.props.properties.defaultValue.value
        ? this.props.properties.defaultValue.value
        : typeof this.props.properties.defaultValue === "boolean" &&
          this.props.properties.defaultValue
        ? this.props.properties.defaultValue
        : false;
    this.setState({ isChecked: isChecked });
  }

  componentDidUpdate(prevProps) {
    if (this.props != prevProps && !this.state.isUpdated) {
      let isChecked =
        this.props.properties.defaultValue &&
        this.props.properties.defaultValue.value
          ? this.props.properties.defaultValue.value
          : typeof this.props.properties.defaultValue === "boolean" &&
            this.props.properties.defaultValue
          ? this.props.properties.defaultValue
          : false;
      this.setState({ isChecked: isChecked });
    }
  }

  validateSwitch = (rule, value, callback, field) => {
    if (
      this.props.properties.constraints &&
      !this.props.properties.constraints.required &&
      value != null
    ) {
      callback();
    } else if (
      this.props.properties.constraints &&
      this.props.properties.constraints.required &&
      value === null
    ) {
      callback(this.validationMessage);
    } else {
      callback();
    }
  };

  render() {
    let id = this.props.id
      ? this.props.id
      : `switch${randomAlphaNumericString(3)}`;

    const { getFieldDecorator } = this.props.referenceForm; // reference of base form
    let visibility = this.props.properties.visibility
      ? this.componentVisibility
      : true;

    let disable = false;
    if (this.props.editmode) {
      disable = this.props.properties.noneditable
        ? this.props.properties.noneditable
        : false;
    }
    disable = this.props.properties.disabled
      ? this.props.properties.disabled
      : disable;

    // For View only
    disable = this.props.viewOnly === true ? true : disable;

    let required =
      this.props.properties.constraints &&
      this.props.properties.constraints.required
        ? this.props.properties.constraints.required
        : false;

    return visibility ? (
      <div
        onFocus={
          this.props.onFocus &&
          this.props.onFocus.bind(null, this.props.properties.help)
        }
      >
        <FormItem
          label={this.props.properties.label}
          {...formItemLayout}
          onFocus={
            this.props.onFocus &&
            this.props.onFocus.bind(null, this.props.properties.help)
          }
          className={
            this.props.properties.className
              ? this.props.properties.className
              : ""
          }
        >
          {getFieldDecorator(id, {
            rules: [
              {
                required: required,
                message: this.validationMessage,
              },
              {
                validator: this.validateSwitch,
              },
            ],
            initialValue: this.state.isChecked,
          })(
            this.props.properties &&
              this.props.properties.component_type === "checkbox" ? (
              <Checkbox
                disabled={disable}
                checked={this.state.isChecked}
                onChange={e => {
                  this.onChange(e.target.checked ? true : false);
                }}
                onFocus={
                  this.props.onFocus &&
                  this.props.onFocus.bind(null, this.props.properties.help)
                }
              />
            ) : (
              <Switch
                onChange={this.onChange}
                disabled={disable}
                checkedChildren="On"
                unCheckedChildren="Off"
                checked={this.state.isChecked}
                onFocus={
                  this.props.onFocus &&
                  this.props.onFocus.bind(null, this.props.properties.help)
                }
              />
            ),
          )}
        </FormItem>
      </div>
    ) : (
      <div></div>
    );
  }
}
/* sample schema

{
  "type": "ToggleInput",
  "visible": true,
  "props": {
    "label": "Enable DNS",
    "defaultValue": {
      "value":true
    },
    "help" : "<div>Turn ON/OFF any component</div>",
    "disabled" : false,
    "noneditable" : true,
  }
}

*/
