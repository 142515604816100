import { observable, action, computed } from "mobx";
import Pageable from "./Pageable";
import DeviceDiscovey from "../domain-model/DeviceDiscovey";

class DeviceDiscoveryViewModel {
  @observable loading = false;
  @observable scanList = [];
  @observable pageable = new Pageable();
  @observable reportsLoading = false;
  @observable reportsList = [];
  @observable reportsPageable = new Pageable();

  @action
  setLoading = loading => {
    this.loading = loading;
  };

  @action
  setPageable = resp => {
    this.pageable = new Pageable(resp);
  };

  @action
  resetScans = () => {
    this.scanList = [];
    this.pageable = new Pageable();
  };

  @action
  setScanList = newData => {
    newData &&
      newData.forEach(element => {
        let recordIndex = this.scanList.findIndex(scan => {
          return scan.id === element.id;
        });
        if (recordIndex == -1) {
          this.scanList.push(Object.assign(new DeviceDiscovey(), element));
        } else {
          this.scanList[recordIndex] = Object.assign(
            new DeviceDiscovey(),
            element,
          );
        }
      });
  };

  @action
  setReportsLoading = loading => {
    this.reportsLoading = loading;
  };

  @action
  setReportsPageable = resp => {
    this.reportsPageable = new Pageable(resp);
  };

  @action
  resetReports = () => {
    this.reportsList = [];
    this.pageable = new Pageable();
  };

  @action
  setReportsList = newData => {
    newData &&
      newData.forEach(element => {
        let recordIndex = this.reportsList.findIndex(scan => {
          return scan.id === element.id;
        });
        if (recordIndex == -1) {
          this.reportsList.push(Object.assign(new DeviceDiscovey(), element));
        } else {
          this.reportsList[recordIndex] = Object.assign(
            new DeviceDiscovey(),
            element,
          );
        }
      });
  };
}
export default new DeviceDiscoveryViewModel();
