import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Form, Select, Input } from "antd";
import { getCurrentlocaleText, ALERT, customSort } from "Core/Utils";
import AlertController from "controller/AlertController";

const FormItem = Form.Item;
const Option = Select.Option;
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

@inject("UiStore", "AuthStore")
@observer
class WebhookTestForm extends Component {
  constructor(props) {
    super(props);
    this.UiStore = this.props.UiStore;
    this.state = {
      avilableAlerts: {},
      formItem: {
        name: undefined,
        type: undefined,
        status: [],
        duration: { duration: 5, units: ALERT.DURATION.MINUTES },
        hideDuration: false,
        isHeadlessType: false,
        isNodeUpgradeType: false,
        isNodeMetricsType: false,
      },
      status: [],
      headers: [
        {
          key: null,
          value: null,
        },
      ],
    };
  }

  renderAlertTypeOptions = () => {
    let options = [],
      alerts = this.state.avilableAlerts;
    if (alerts.length > 0) {
      alerts.forEach(alert => {
        if (
          alert.type.includes(ALERT.TYPE.HEADLESS_EXPIRY) &&
          (this.props.AuthStore.IsPermitted("NODE:CREATE") ||
            this.props.AuthStore.IsPermitted("ORG:SUPPORT"))
        ) {
          options.push({
            label: alert.display_type,
            value: alert.type,
          });
        } else if (!alert.type.includes(ALERT.TYPE.HEADLESS_EXPIRY)) {
          options.push({
            label: alert.display_type,
            value: alert.type,
          });
        }
      });
    }
    // Sort alert type based on labels (display text) instead of option value
    options.sort((a, b) => customSort(a.label, b.label));
    return options.map(option => {
      return (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      );
    });
  };

  updateHelp = data => {
    this.UiStore.SetStoreData("helpData", data);
    // Update help content
    setTimeout(() => {
      let elm = document.querySelector(
        ".alert-form .help-container .help-content",
      );
      if (elm) {
        elm.innerHTML =
          data && data.data && data.data[0] && data.data[0].content
            ? data.data[0].content
            : "";
      }
    }, 10);
  };

  handleTypeChange = value => {
    if (value) {
      let data = {
        alert_type: {
          value: value,
        },
      };
      this.props.formReference.setFields(data);
      this.props.UiStore.webhookFormData.test = { alert_type: value };
    }
  };

  UNSAFE_componentWillMount() {
    // Get Available alerts
    AlertController.getAvailableAlerts().then(response => {
      if (response && response.available_alerts) {
        this.setState({ avilableAlerts: response.available_alert_types });
        if (response.available_alert_types.length > 0) {
          let data = {
            alert_type: {
              value: response.available_alert_types[0].type,
            },
          };
          this.props.formReference.setFields(data);
          this.props.UiStore.webhookFormData.test = {
            alert_type: response.available_alert_types[0].type,
          };
        }
      }
    });
    let headers = this.props.UiStore.webhookFormData.configuration.headers
      ? this.props.UiStore.webhookFormData.configuration.headers
      : this.props.currrentWebhookData.headers;
    let headerFormValue = [];
    for (var key in headers) {
      // check if the property/key is defined in the object itself, not in parent
      if (headers.hasOwnProperty(key)) {
        headerFormValue.push({ key: key, value: headers[key] });
      }
    }
    this.setState({ headers: headerFormValue });
    this.checkForHelpUpdate();
  }

  componentDidUpdate(prevProps, prevState) {
    //update help
    if (
      prevProps.currrentWebhookData != this.props.currrentWebhookData.is_enabled
    ) {
      this.checkForHelpUpdate();
    }
  }

  checkForHelpUpdate = () => {
    if (!this.props.currrentWebhookData.is_enabled) {
      this.updateHelp({
        title: getCurrentlocaleText("webhook.verify.help.title"),
        data: [
          {
            subtitle: "",
            content: getCurrentlocaleText("webhook.verify.help.message"),
          },
        ],
      });
    } else {
      this.updateHelp({
        title: getCurrentlocaleText("webhook.test.help.title"),
        data: [
          {
            subtitle: "",
            content: getCurrentlocaleText("webhook.test.help.message"),
          },
        ],
      });
    }
  };

  render() {
    let { getFieldDecorator } = this.props.formReference;
    let { formItem } = this.state;
    let headers = this.props.UiStore.webhookFormData.configuration.headers
      ? this.props.UiStore.webhookFormData.configuration.headers
      : this.props.currrentWebhookData.headers || {};
    let headerFormValue = [];
    for (var key in headers) {
      // check if the property/key is defined in the object itself, not in parent
      if (headers.hasOwnProperty(key)) {
        headerFormValue.push({ key: key, value: headers[key] });
      }
    }

    let renderHeaderFormItems = headerFormValue.map((item, i) => {
      return (
        <FormItem
          className={"background-url-item"}
          key={item.key}
          colon={false}
          label={
            i === 0 ? (
              <span>
                {"Headers"}
                {" :"}
              </span>
            ) : (
              " "
            )
          }
          {...formItemLayout}
        >
          {" "}
          {getFieldDecorator("headers" + item.key, {
            rules: [{ required: false }],
            initialValue: item.value,
          })(
            <div>
              <div style={{ display: "inline-block", width: "90%" }}>
                <Input
                  autoComplete="off"
                  disabled={true}
                  style={{ display: "inline-block", width: "45%" }}
                  value={
                    this.state.headers[i] && this.state.headers[i]["key"]
                      ? this.state.headers[i]["key"]
                      : null
                  }
                />
                :
                <Input
                  autoComplete="off"
                  disabled={true}
                  style={{ display: "inline-block", width: "45%" }}
                  value={
                    this.state.headers[i] && this.state.headers[i]["value"]
                      ? this.state.headers[i]["value"]
                      : null
                  }
                />
              </div>
              <div
                style={{
                  display: "inline-block",
                  width: "10%",
                  textAlign: "center",
                }}
              ></div>
            </div>,
          )}
        </FormItem>
      );
    });

    return (
      <div style={{ marginTop: 10 }}>
        <FormItem
          label={<span>{getCurrentlocaleText("webhookform.url.text")}</span>}
          {...formItemLayout}
        >
          {getFieldDecorator("url", {
            initialValue: formItem.url,
          })(
            <Input
              autoComplete="off"
              className="firstInput"
              placeholder={getCurrentlocaleText("webhookform.url.text")}
              disabled={true}
            />,
          )}
        </FormItem>
        <FormItem
          label={<span>{getCurrentlocaleText("webhookform.secret.text")}</span>}
          {...formItemLayout}
        >
          {getFieldDecorator("secret", {
            initialValue: formItem.secret,
          })(
            <Input
              autoComplete="off"
              placeholder={getCurrentlocaleText("webhookform.secret.text")}
              disabled={true}
            />,
          )}
        </FormItem>
        {renderHeaderFormItems}
        {this.props.currrentWebhookData.is_enabled && (
          <FormItem
            label={
              <span>{getCurrentlocaleText("webhookform.alerttype.text")}</span>
            }
            {...formItemLayout}
          >
            {getFieldDecorator("alert_type", {
              rules: [
                {
                  required: true,
                  message: getCurrentlocaleText(
                    "webhookform.alerttype.required.message.text",
                  ),
                },
              ],
              initialValue:
                this.state.avilableAlerts &&
                this.state.avilableAlerts.length > 0 &&
                this.state.avilableAlerts[0]
                  ? this.state.avilableAlerts[0].type
                  : null,
            })(
              <Select
                placeholder={getCurrentlocaleText(
                  "alertform.alerttype.placeholder.text",
                )}
                onChange={this.handleTypeChange}
              >
                {this.renderAlertTypeOptions()}
              </Select>,
            )}
          </FormItem>
        )}
      </div>
    );
  }
}

export default WebhookTestForm;
