import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import { Row, Col, Select, Radio } from "antd";
import { inject } from "mobx-react";
import _ from "lodash";
import { isEmpty, getCurrentlocaleText } from "Core/Utils";
/*Import html elements*/
import TextBox from "../common/TextBox";
import CheckBox from "../common/CheckBox";
import RadioBox from "../common/RadioBox";
import { Textarea } from "../common/TextArea";
import SecretList from "./SecretList";

const FormItem = Form.Item;
const Option = Select.Option;
const RadioGroup = Radio.Group;

@inject("UiStore", "SecretViewModel", "ServiceViewModel", "InodeViewModel")
export default class SSH extends Component {
  constructor(props) {
    super(props);
    this.secretModel = this.props.SecretViewModel;
    this.serviceModel = this.props.ServiceViewModel;
    this.inodeModel = this.props.InodeViewModel;
    this.searchTimeout = null;
    this.state = {
      data: [],
      licenseType: null,
      secretDataList: [],
      showSSHContainer: false,
      showmountpath: false,
    };

    this.sshHelpText = {
      mountpath: "",
      name: "",
      new: "",
      previous: "",
      sshkey: "",
      enablessh: "",
    };
  }

  componentDidMount() {
    /*  initialize license help text */
    if (this.props.properties.help && this.props.properties.help["ssh"]) {
      let helpText = this.props.properties.help["ssh"];
      this.sshHelpText = {
        mountpath: helpText["mountpath"] ? helpText["mountpath"] : "",
        previous: helpText["previous"] ? helpText["previous"] : "",
        new: helpText["new"] ? helpText["new"] : "",
        name: helpText["name"] ? helpText["name"] : "",
        sshkey: helpText["sshkey"] ? helpText["sshkey"] : "",
        enablessh: helpText["enablessh"] ? helpText["enablessh"] : "",
        isBase64: helpText["isBase64"]
          ? helpText["isBase64"]
          : '<div><h3 style="font-weight:bold;"> SSH Public Key </h3> Please provide the SSH public key in base64 encoded format. It can be either RSA or DSA key.</div>',
      };
    }

    let keys = Object.keys(this.props.UiStore.templatingService.secrets);
    if (keys.indexOf(this.props.id) >= 0) {
      this.props.UiStore.templatingService.secrets[this.props.id] = {
        ...this.props.UiStore.templatingService.secrets[this.props.id],
        secret_volume: {},
      };
      /* Editing the service. update with previous license */
      this.props.urlParams.params.spec_id && this.InitWithApiResp();
    } else {
      this.props.UiStore.templatingService.secrets[this.props.id] = {};
      this.props.UiStore.templatingService.secrets[this.props.id] = {
        secret_volume: {},
      };
    }
    this.props.SecretViewModel.isSecretLoading = false;
    // setting default values
    // this.props.updateCurrentValueOnChange(
    //   {
    //     secret: {
    //       name: "",
    //       secret_volume: { secret: "" },
    //       mount_path: this.props.properties.mountpath,
    //     },
    //   },
    //   "custom_ssh_content",
    // );
  }

  InitWithApiResp = () => {
    this.setState({
      showSSHContainer: true,
      licenseType: "PREVIOUS",
      showmountpath: true,
    });
  };

  selectlicensetype = type => {
    this.setState({ licenseType: type, showmountpath: true });
    this.props.ServiceViewModel.isNewSSH = type;

    // this.props.updateCurrentValueOnChange(
    //   {
    //     secret: {
    //       name: "",
    //       secret_volume: { secret: "" },
    //       mount_path: this.props.properties.mountpath,
    //     },
    //   },
    //   "custom_lics_content",
    // );
  };

  inputName = e => {
    /*clear selected license from drop-down if new license is created*/
    if (!isEmpty(this.props.UiStore.templatingService.secrets[this.props.id])) {
      this.props.UiStore.templatingService.secrets[
        this.props.id
      ].previousSelectedLicense = null;
    }
  };

  UNSAFE_componentWillMount() {
    this.props.SecretViewModel.isSecretLoading = true;
  }
  selectedValue = (e, type = "volume") => {
    let keys = Object.keys(this.props.UiStore.templatingService.secrets);
    keys.indexOf(this.props.id) < 0
      ? (this.props.UiStore.templatingService.secrets[this.props.id] = {})
      : /*clear uploaded license if previous license selected from drop-down*/
        (this.props.UiStore.templatingService.secrets[
          this.props.id
        ].secret_volume = {});

    this.props.UiStore.templatingService.secrets[
      this.props.id
    ].previousSelectedLicense = e;
    let in_dx = this.secretModel.secretsObj[type].findIndex(object => {
      return object.id === e;
    });
    if (in_dx != -1) {
      this.props.updateCurrentValueOnChange(
        this.secretModel.secretsObj[type][in_dx].name,
        "name",
      );
      this.props.updateCurrentValueOnChange(e, "id");
      this.props.updateCurrentValueOnChange(
        this.props.properties.mountpath,
        "mount_path",
      );
    }
  };

  inputChange = input => {
    this.props.UiStore.templatingService.secrets[this.props.id].secret_volume[
      input.target.id
    ] = input.target.value;
  };

  onChange = val => {
    this.props.UiStore.templatingService.secrets[this.props.id].isBase64 = !val
      .target.value;
  };

  showSSH = val => {
    /* clear previous / new uploaded ssh */
    if (!val.target.checked) {
      this.props.UiStore.templatingService.secrets[this.props.id] = {
        secret_volume: {},
        previousSelectedLicense: null,
      };
      this.props.updateCurrentValueOnChange("", "name");
      this.props.updateCurrentValueOnChange(null, "id");
      this.props.updateCurrentValueOnChange("", "mount_path");
    }

    this.serviceModel.isSSHallowed = val.target.checked;

    this.setState({
      showSSHContainer: val.target.checked,
      licenseType: null,
      showmountpath: false,
    });
    setTimeout(() => {
      this.props.referenceForm.validateFields();
    }, 500);
  };

  renderList = () => {
    let display_name =
      this.props &&
      this.props.properties &&
      this.props.properties.display_props &&
      this.props.properties.display_props.display_name
        ? this.props.properties.display_props.display_name
        : "SSH public key ";
    let RSAPlaceholder =
      this.props &&
      this.props.properties &&
      this.props.properties.display_props &&
      this.props.properties.display_props.display_name
        ? this.props.properties.display_props.display_name
        : "SSH (RSA) public key";
    let secureShell =
      this.props &&
      this.props.properties &&
      this.props.properties.display_props &&
      this.props.properties.display_props.display_name
        ? this.props.properties.display_props.display_name
        : "Secure Shell";

    // For Custom label
    let custom_label =
      this.props &&
      this.props.properties &&
      this.props.properties.display_props &&
      this.props.properties.display_props.custom_label;
    // Header checkbox label & error message
    let checkbox_label =
      custom_label && custom_label.checkbox && custom_label.checkbox.label;
    let general_error_message = custom_label && custom_label.error_message;
    // RadioButton Option 1 labels & error messages
    let radiobutton1_label =
      custom_label &&
      custom_label.radiobutton1 &&
      custom_label.radiobutton1.label;
    let radiobutton1_textbox_label =
      custom_label &&
      custom_label.radiobutton1 &&
      custom_label.radiobutton1.textbox &&
      custom_label.radiobutton1.textbox.label;
    let radiobutton1_textbox_error_message =
      custom_label &&
      custom_label.radiobutton1 &&
      custom_label.radiobutton1.textbox &&
      custom_label.radiobutton1.textbox.error_message;
    let radiobutton1_textarea_label =
      custom_label &&
      custom_label.radiobutton1 &&
      custom_label.radiobutton1.textarea &&
      custom_label.radiobutton1.textarea.label;
    let radiobutton1_textarea_placeholder =
      custom_label &&
      custom_label.radiobutton1 &&
      custom_label.radiobutton1.textarea &&
      custom_label.radiobutton1.textarea.placeholder;
    let radiobutton1_textarea_error_message =
      custom_label &&
      custom_label.radiobutton1 &&
      custom_label.radiobutton1.textarea &&
      custom_label.radiobutton1.textarea.error_message;
    let radiobutton1_textarea_checkbox_label =
      custom_label &&
      custom_label.radiobutton1 &&
      custom_label.radiobutton1.textarea &&
      custom_label.radiobutton1.textarea.checkbox &&
      custom_label.radiobutton1.textarea.checkbox.label;
    // RadioButton Option2 labels && error message
    let radiobutton2_label =
      custom_label &&
      custom_label.radiobutton2 &&
      custom_label.radiobutton2.label;
    let radiobutton2_selectbox_label =
      custom_label &&
      custom_label.radiobutton2 &&
      custom_label.radiobutton2.selectbox &&
      custom_label.radiobutton2.selectbox.label;
    let radiobutton2_selectbox_error_message =
      custom_label &&
      custom_label.radiobutton2 &&
      custom_label.radiobutton2.selectbox &&
      custom_label.radiobutton2.selectbox.error_message;
    // Textbox label & error message
    let textbox_label =
      custom_label && custom_label.textbox && custom_label.textbox.label;
    let textbox_error_message =
      custom_label &&
      custom_label.textbox &&
      custom_label.textbox.error_message;

    return (
      <div>
        <Row gutter={24}>
          <Col span={22}>
            <CheckBox
              referenceForm={this.props.referenceForm}
              id={`${this.props.id}_showsSSH`}
              onFocus={this.props.onFocus}
              properties={{
                label: checkbox_label
                  ? checkbox_label
                  : getCurrentlocaleText("servicetemplates.ssh.enable.label", {
                      0: secureShell,
                    }),
                onChange: this.showSSH,
                defaultValue: this.state.showSSHContainer,
                disabled: this.props.viewOnly === true ? true : false,
                constraints: {
                  required: false,
                },
                help: this.sshHelpText["enablessh"],
              }}
            />
            {this.state.showSSHContainer && (
              <div>
                <RadioBox
                  id={`${this.props.id}_selectlicensetype`}
                  referenceForm={this.props.referenceForm}
                  onFocus={this.props.onFocus}
                  properties={{
                    label: "",
                    onChange: this.selectlicensetype,
                    defaultValue: this.state.licenseType,
                    disabled: this.props.viewOnly === true ? true : false,
                    options: {
                      list: [
                        {
                          value: "NEW",
                          label: radiobutton1_label
                            ? radiobutton1_label
                            : getCurrentlocaleText(
                                "servicetemplates.ssh.upload.label",
                                { 0: display_name },
                              ),
                        },
                        {
                          value: "PREVIOUS",
                          label: radiobutton2_label
                            ? radiobutton2_label
                            : getCurrentlocaleText(
                                "servicetemplates.ssh.existing.label",
                                { 0: display_name },
                              ),
                        },
                      ],
                    },
                    constraints: {
                      required: false,
                      validationMessage: general_error_message
                        ? general_error_message
                        : getCurrentlocaleText(
                            "servicetemplates.ssh.previous.validation.msg",
                            { 0: display_name.toLowerCase() },
                          ),
                    },
                    help: this.sshHelpText,
                  }}
                />
                <Row>
                  <Col span={12}>
                    {this.state.showmountpath && (
                      <div
                        onFocus={this.props.onFocus}
                        style={{ display: "none" }}
                      >
                        <TextBox
                          referenceForm={this.props.referenceForm}
                          id={`${this.props.id}_mountPath`}
                          onFocus={this.props.onFocus}
                          properties={{
                            label: textbox_label
                              ? textbox_label
                              : getCurrentlocaleText(
                                  "servicetemplates.ssh.mountpath.label",
                                  { 0: display_name.toLowerCase() },
                                ),
                            placeholder: textbox_label
                              ? textbox_label
                              : getCurrentlocaleText(
                                  "servicetemplates.ssh.mountpath.label",
                                  { 0: display_name.toLowerCase() },
                                ),
                            disabled:
                              this.props.viewOnly === true
                                ? true
                                : this.props.properties.disabled,
                            defaultValue: this.props.properties.mountpath
                              ? this.props.properties.mountpath
                              : "",
                            constraints: {
                              required: true,
                              validationMessage: textbox_error_message
                                ? textbox_error_message
                                : getCurrentlocaleText(
                                    "servicetemplates.ssh.mountpath.validation.msg",
                                  ),
                            },
                            help: this.sshHelpText["mountpath"],
                          }}
                        />
                      </div>
                    )}
                  </Col>
                </Row>

                {this.state.licenseType === "NEW" && (
                  <>
                    <Row gutter={24}>
                      <Col span={12}>
                        <TextBox
                          referenceForm={this.props.referenceForm}
                          id={`${this.props.id}_name`}
                          onFocus={this.props.onFocus}
                          properties={{
                            label: radiobutton1_textbox_label
                              ? radiobutton1_textbox_label
                              : getCurrentlocaleText(
                                  "servicetemplates.ssh.create_new.label",
                                  { 0: display_name },
                                ),
                            placeholder: radiobutton1_textbox_label
                              ? radiobutton1_textbox_label
                              : getCurrentlocaleText(
                                  "servicetemplates.ssh.create_new.label",
                                  { 0: display_name },
                                ),
                            defaultValue: "",
                            disabled:
                              this.props.viewOnly === true ? true : false,
                            constraints: {
                              required: true,
                              validationMessage: radiobutton1_textbox_error_message
                                ? radiobutton1_textbox_error_message
                                : getCurrentlocaleText(
                                    "servicetemplates.ssh.name.validation.msg",
                                    { 0: display_name },
                                  ),
                            },
                            inputChange: this.inputName,
                            help: this.sshHelpText["name"],
                          }}
                          updateCurrentValueOnChange={value => {
                            this.props.updateCurrentValueOnChange(
                              value,
                              "custom_lics_name",
                            );
                          }}
                        />
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={12}>
                        <CheckBox
                          referenceForm={this.props.referenceForm}
                          id={`${this.props.id}_isBase64`}
                          onFocus={this.props.onFocus}
                          properties={{
                            label: radiobutton1_textarea_checkbox_label
                              ? radiobutton1_textarea_checkbox_label
                              : "Input is already base64 encoded",
                            onChange: this.onChange,
                            constraints: {
                              required: false,
                            },
                            disabled:
                              this.props.viewOnly === true ? true : false,
                            options: {
                              style: {
                                marginBottom: "0px",
                                zIndex: 1,
                              },
                            },
                            help: this.sshHelpText["isBase64"],
                          }}
                        />
                        <Textarea
                          referenceForm={this.props.referenceForm}
                          id={`${this.props.id}_textarea`}
                          onFocus={this.props.onFocus}
                          properties={{
                            label: radiobutton1_textarea_label
                              ? radiobutton1_textarea_label
                              : display_name,
                            onChange: this.inputChange,
                            placeholder: radiobutton1_textarea_placeholder
                              ? radiobutton1_textarea_placeholder
                              : getCurrentlocaleText(
                                  "ssh.public.key.placeholder",
                                  { 0: RSAPlaceholder },
                                ),
                            disabled:
                              this.props.viewOnly === true ? true : false,
                            constraints: {
                              required: true,
                              validationMessage: radiobutton1_textarea_error_message
                                ? radiobutton1_textarea_error_message
                                : getCurrentlocaleText(
                                    "servicetemplates.ssh.textarea.validation.msg",
                                    { 0: display_name },
                                  ),
                            },
                            help: this.sshHelpText["sshkey"],
                          }}
                          updateCurrentValueOnChange={value => {
                            this.props.updateCurrentValueOnChange(
                              value,
                              "custom_lics_content",
                            );
                          }}
                        />
                      </Col>
                    </Row>
                  </>
                )}

                {this.state.licenseType === "PREVIOUS" && (
                  <Row gutter={24}>
                    <Col span={12}>
                      <SecretList
                        referenceForm={this.props.referenceForm}
                        id={`${this.props.id}`}
                        defaultValue=""
                        onFocus={this.props.onFocus}
                        org_id={
                          this.props.urlParams.params.org_id
                            ? this.props.urlParams.params.org_id
                            : this.props.UiStore.currentUser.organization.id
                        }
                        urlParams={this.props.urlParams}
                        properties={{
                          label: radiobutton2_selectbox_label
                            ? radiobutton2_selectbox_label
                            : getCurrentlocaleText(
                                "servicetemplates.ssh.select_files.label",
                                { 0: display_name },
                              ),
                          selectedValue: e => {
                            this.selectedValue(e, "volume");
                          },
                          options: {
                            mode: "",
                            type: "ssh",
                          },
                          disabled: this.props.viewOnly === true ? true : false,
                          constraints: {
                            required: true,
                            validationMessage: radiobutton2_selectbox_error_message
                              ? radiobutton2_selectbox_error_message
                              : getCurrentlocaleText(
                                  "service.ssh.notselected.error",
                                  { 0: display_name },
                                ),
                          },
                          help: this.sshHelpText["previous"],
                        }}
                      />
                    </Col>
                  </Row>
                )}
              </div>
            )}
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    return <div>{this.renderList()}</div>;
  }
}

/*
  sample schema

  {
    "type": "Secret",
    "visible": true,
    "props": {
      "subtype": "ssh",
      "disabled": true,
      "mountpath": "/root/.ssh_new",
      "display_props" : {
        "display_name": "SSH",
        "custom_label": {
          "checkbox": {
            "label": "Enable Secure Shell"
          },
          "error_message": "Please select option",
          "radiobutton1": {
            "label": "Specify SSH public key",
            "textbox": {
              "label": "SSH public key Name",
              "error_message": "Please enter public key name"
            },
            "textarea": {
              "label": "SSH public key ",
              "placeholder":"Paste a valid SSH (RSA) public key",
              "error_message": "Please enter public key",
              "checkbox": {
                "label": "Input is already base64 encoded SSH public key"
              }
            }
          },
          "radiobutton2": {
            "label": "Select a previously specified SSH public key",
            "selectbox": {
              "label": "Select SSH public key ",
              "error_message": "Please select public key"
            }
          },
          "textbox": {
            "label": "Mount Path",
            "error_message": "Error in mount path"
          }
        }
      }
      "help": {
        "ssh": {
          "previous": "select from previous ssh",
          "new": "specify a new ssh",
          "name": "<div>Specify a name for the ssh </div>",
          "sshkey": "Enter valid ssh key  ",
          "mountpath": "some text ",
          "enablessh": "enable ssh "
        }

      },
      "options": {
        "isBase64EncodingRequired": true
      }
    }
  }

*/
