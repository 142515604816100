import React, { Component } from "react";
import {
  Row,
  Col,
  Tabs,
  Tag,
  Select,
  Form,
  Collapse,
  Popover,
  Checkbox,
} from "antd";
import { observer, inject } from "mobx-react";
import ActionBtn from "Core/API/ActionBtn";
import AddLicense from "./AddLicense";
import SearchInput from "components/UI-Components/SearchInput";
import {
  highlightSearchText,
  copyObject,
  getCurrentlocaleText,
} from "Core/Utils";
import LoadingComponent from "components/UI-Components/LoadingComponent";

import SecretController from "controller/SecretController";

import VolumeComponent from "components/volumes";
import Icons from "../UI-Components/Icons";
import TableLayoutV1 from "../layout/TableLayoutV1";

const FormItem = Form.Item;
const Option = Select.Option;
const Panel = Collapse.Panel;
const TabPane = Tabs.TabPane;

@inject("ModelStore", "AuthStore", "UiStore", "SecretViewModel", "OrgViewModel")
@observer
class LicenseComponent extends Component {
  constructor(props) {
    super(props);
    this.secretModel = this.props.SecretViewModel;
    this.searchTimeout = null;
    this.state = {
      tabKey: "dockerconfig",
      selectedLicenses: {},
      searchText: "",
      currentPage: 1,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (this.props.orgId !== props.orgId) {
      this.secretList(true, 0, this.state.searchText, null, null, props.params);
    }
  }

  componentDidMount() {
    this.props.UiStore.temporaryLicenseData = {};
    this.props.UiStore.showChildSecrets = this.props.cameFromDashboard
      ? true
      : false;
    this.secretList(true);
  }

  componentWillUnmount() {
    this.props.UiStore.showChildSecrets = false;
  }

  createAfterCall = resp => {
    this.setState({
      currentPage: 1,
    });
    this.secretList(true, 0, this.state.searchText);
    this.resetStates();
  };

  deleteAfterCall = () => {
    let page = this.state.currentPage ? this.state.currentPage : 0;
    let secObj = this.LicenseTableData(
      this.secretModel.secretsObj["dockerconfig"],
    );
    if (secObj.length === 1) {
      page--;
    }
    this.secretList(true, page - 1, this.state.searchText);
    this.resetStates();
  };

  secretList = (
    loading = true,
    page = 0,
    search = "",
    sortBy = null,
    sortOrder = null,
    params = this.props.params || {},
  ) => {
    params = copyObject(params);
    params.page = page;
    params.search = search;
    // For sort
    if (sortBy && sortOrder) {
      params.sort = `${sortBy}:${sortOrder}`;
    }
    if (this.props.UiStore.showChildSecrets) {
      delete params.own;
    } else {
      params.own = true;
    }
    params.type = "Dockerconfigjson";
    SecretController.getSecrets(params, true);
  };

  LicenseTableData = volumeList => {
    let service = [];
    if (volumeList && volumeList.length > 0) {
      service = volumeList.map((val, index) => {
        let files,
          assignedService = [];
        files = _.keys(val.data);
        /*fetching services name*/
        if (val.services) {
          val.services.map(obj => {
            assignedService.push(obj.name);
          });
        }
        return {
          id: val.id,
          name: val.name,
          organization: val.organization.name,
          assignedServiceCount: assignedService.length,
          assignedService:
            assignedService.length > 0
              ? assignedService.join(",")
              : "Unassigned",
          disabled:
            !this.props.AuthStore.IsPermitted("SERVICE:CREATE") ||
            assignedService.length > 0,
          licenseFiles: files.length,
          selectedLicenseDetails: val,
        };
      });
    }
    return service;
  };

  resetStates = () => {
    this.setState({
      selectedLicenses: {},
    });
  };

  rowClick = (record, index, event) => {
    this.props.UiStore.serviceFormData[`secretCertiName`] = record.name;
    this.props.UiStore.serviceFormData[`assignedServiceCount`] =
      record.assignedServiceCount;
    this.props.UiStore.serviceFormData.rowDetails = record; //saving the license object
    if (
      record.selectedLicenseDetails.labels &&
      record.selectedLicenseDetails.labels.type.toLowerCase() === "dockerconfig"
    ) {
      let key = Object.keys(record.selectedLicenseDetails.data)[0];
      /* convert from base64 to normal json*/
      this.props.UiStore.serviceFormData.docker_container = atob(
        record.selectedLicenseDetails.data[key],
      );
    }

    if (
      !record.selectedLicenseDetails.labels ||
      (record.selectedLicenseDetails.labels.type &&
        record.selectedLicenseDetails.labels.type.toLowerCase() === "license")
    ) {
      /*Temporarily save previous license content so, on cancel can update the license with original content*/
      if (
        this.props.UiStore.temporaryLicenseData &&
        this.props.UiStore.temporaryLicenseData[record.name] &&
        this.props.UiStore.temporaryLicenseData[record.name]
          .tempInitialLicenseFileName
      ) {
        this.props.UiStore.serviceFormData.uploadedLicenseFileName = [];
        this.props.UiStore.serviceFormData.uploadedLicenseFileName = JSON.parse(
          this.props.UiStore.temporaryLicenseData[record.name]
            .tempInitialLicenseFileName,
        );
      } else {
        this.props.UiStore.serviceFormData.uploadedLicenseFileName = _.keys(
          record.selectedLicenseDetails.data,
        );
        this.props.UiStore.temporaryLicenseData[record.name] = {
          tempInitialLicenseFileName: [],
        };
        this.props.UiStore.temporaryLicenseData[
          record.name
        ].tempInitialLicenseFileName = JSON.stringify(
          this.props.UiStore.serviceFormData.uploadedLicenseFileName,
        );
      }
    }
  };

  selectedLicenseRows = (record, selected, selectedRows) => {
    this.setState({
      selectedLicenses: selectedRows,
    });
  };

  selectLicenseAll = (selected, selectedRows, changeRows) => {
    this.setState({
      selectedLicenses: selectedRows,
    });
  };

  dockerListFilter = e => {
    clearTimeout(this.searchTimeout);
    let searchText = e.target.value;
    this.setState({ searchText: searchText, currentPage: 1 });
    this.searchTimeout = setTimeout(() => {
      this.secretList(false, 0, searchText);
    }, 500);
  };

  cleardockerSearchFilter = () => {
    this.setState({ searchText: "", currentPage: 1 });
    this.secretList(true, 0);
  };

  /*updateSecret = (params, loading, forceCall) => {
    /*let resource = "secret";
    this.props.ModelStore.Query(resource, {}, {}, false).then(res => {
      this.moveToPage(this.newLicenseId);
    });
    this.props.updateSecret(params, loading, forceCall);
  };*/

  /*updateLicence = resps => {
    if (resps) {
      this.newLicenseId = resps[0].id;
    }
  };*/

  /*moveToPage = licenseId => {
    this.newLicenseId = null;
    if (licenseId) {
      let index = this.LicenseTableData.findIndex(val => {
        return val.id == licenseId;
      });
      if (index != -1) {
        let page = Math.floor(index / this.props.UiStore.pageSize) + 1;
        this.props.UiStore.setCurrentPage(page);
      }
    }
  };*/

  tabChange = key => {
    this.setState({ tabKey: key });
  };

  lazyLoad = () => {
    let nextLink = this.secretModel.pageable[this.state.tabKey].next;
    if (nextLink) {
      let page = this.secretModel.pageable[this.state.tabKey].page + 1;
      this.secretList(true, page, this.state.searchText);
    }
  };

  onTableChange = pagination => {
    this.setState({
      currentPage: pagination.current,
    });
    let page = pagination.current - 1;
    this.secretList(true, page);
  };

  changeListview = e => {
    this.props.UiStore.showChildSecrets = e.target.checked;
    this.setState({
      currentPage: 1,
    });
    this.secretList(true, 0, this.state.searchText);
  };

  render() {
    /* for docker config */
    let listdockerConfig = this.LicenseTableData(
      this.secretModel.secretsObj["dockerconfig"],
    );

    this.LicenseTableColumns = [
      {
        title: (
          <span
            title={getCurrentlocaleText("network.form.name.placeholder.text")}
          >
            {getCurrentlocaleText("network.form.name.placeholder.text")}
          </span>
        ),
        dataIndex: "name",
        key: "name",
        width: "25%",
        render: (text, record, index) => {
          return (
            <div>
              {this.state.searchText.trim().length > 0
                ? highlightSearchText(text, this.state.searchText)
                : text}
            </div>
          );
        },
      },
      {
        title: (
          <span title={getCurrentlocaleText("assigned.services")}>
            {getCurrentlocaleText("assigned.services")}
          </span>
        ),
        dataIndex: "assignedService",
        key: "assignedService",
        width: "30%",
        render: (text, record, index) => {
          let name = text.split(",");
          let popoverContent = name.map((val, i) => {
            return (
              <Tag key={i} className="licensepopoverContent" title={val}>
                {val}
              </Tag>
            );
          });

          return name.length == 1 && name[0].toLowerCase() != "unassigned" ? (
            <div className="licensepopoverContent" title={text}>
              <Tag>{text}</Tag>
            </div>
          ) : (
            <Popover
              content={popoverContent}
              title="Assigned Services"
              placement="right"
            >
              <Tag>
                {name[0].toLowerCase() === "unassigned"
                  ? `${name[0]}`
                  : ` ${name.length} services`}
              </Tag>
            </Popover>
          );
        },
      },
      {
        title: (
          <span title={getCurrentlocaleText("uploaded.files")}>
            {getCurrentlocaleText("uploaded.files")}
          </span>
        ),
        dataIndex: "licenseFiles",
        key: "licenseFiles",
        width: "20%",
      },
      {
        title: "",
        dataIndex: "",
        key: "edit_node",
        width: "15%",
        render: (text, record, index) => {
          return (
            <div>
              <AddLicense
                {...this.props}
                title={`${
                  record.selectedLicenseDetails.labels &&
                  record.selectedLicenseDetails.labels.type.toLowerCase() ===
                    "ssh"
                    ? getCurrentlocaleText("edit.ssh")
                    : record.selectedLicenseDetails.labels &&
                      record.selectedLicenseDetails.labels.type.toLowerCase() ===
                        "dockerconfig"
                    ? `Edit ${getCurrentlocaleText("pull.secret")}`
                    : record.selectedLicenseDetails.type &&
                      record.selectedLicenseDetails.type
                        .toLowerCase()
                        .includes("dockerconfig")
                    ? `Edit ${getCurrentlocaleText("pull.secret")}`
                    : record.selectedLicenseDetails.type &&
                      record.selectedLicenseDetails.type
                        .toLowerCase()
                        .includes("ssh")
                    ? getCurrentlocaleText("edit.ssh")
                    : getCurrentlocaleText("edit.license")
                } `}
                actionType="edit"
                extra={{
                  type: record.selectedLicenseDetails.labels
                    ? record.selectedLicenseDetails.labels.type
                    : "license",
                }}
                disabled={!this.props.AuthStore.IsPermitted("SERVICE:CREATE")}
                buttonName={<Icons type="ai" name="AiOutlineEdit" />}
                disableName={record.assignedServiceCount > 0 ? true : false}
              />
            </div>
          );
        },
      },
    ];

    let org_column = {
      title: (
        <span title={getCurrentlocaleText("myprofile.organization.text")}>
          {getCurrentlocaleText("myprofile.organization.text")}
        </span>
      ),
      dataIndex: "",
      key: "organization",
      width: "16%",
      render: (text, record, index) => {
        return (
          <a href={`/orgs/${record.selectedLicenseDetails.organization.id}`}>
            {record.selectedLicenseDetails.organization.name}
          </a>
        );
      },
    };

    if (this.props.UiStore.showChildSecrets) {
      this.LicenseTableColumns.splice(2, 0, org_column);
    }

    let container = (
      <div style={{ minHeight: 250 }}>
        <Tabs
          defaultActiveKey="dockerconfig"
          tabPosition="left"
          onChange={this.tabChange}
        >
          {/* docker starts */}
          {
            <TabPane
              tab={getCurrentlocaleText("pull.secrets")}
              key="dockerconfig"
            >
              <div>
                <div>
                  <div
                    style={{
                      marginBottom: "10px",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: 7,
                      }}
                    >
                      <Checkbox
                        checked={this.props.UiStore.showChildSecrets}
                        onChange={this.changeListview}
                        disabled={
                          this.props.OrgViewModel.org &&
                          this.props.OrgViewModel.org.org_count > 0
                            ? false
                            : true
                        }
                      >
                        {getCurrentlocaleText(
                          "secret.table_view.checkbox.label",
                        )}
                      </Checkbox>
                    </div>

                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <SearchInput
                        placeholder={getCurrentlocaleText(
                          "pull.secret.search.placeholder",
                        )}
                        onChange={this.dockerListFilter}
                        value={this.state.searchText}
                        clearSearch={this.cleardockerSearchFilter}
                      />
                    </div>
                    <div
                      style={{
                        marginLeft: "20px",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      {this.props.AuthStore.IsPermitted("SERVICE:CREATE") && (
                        <AddLicense
                          title={`Add ${getCurrentlocaleText("pull.secret")}`}
                          actionType="create"
                          afterCall={this.createAfterCall}
                          licenseType="dockerconfig"
                          extra={{
                            type: "dockerconfig",
                          }}
                          {...this.props}
                        />
                      )}
                      {this.props.AuthStore.IsPermitted("SERVICE:CREATE") && (
                        <ActionBtn
                          title={
                            <span>
                              <Icons type="ai" name="AiOutlineDelete" />{" "}
                              {getCurrentlocaleText(
                                "inode.list.table.delete.button.title.text",
                                {
                                  0:
                                    this.state.selectedLicenses.length > 1
                                      ? "Pull Secrets"
                                      : "Pull Secret",
                                },
                              )}
                            </span>
                          }
                          HoverText={
                            <span>
                              {getCurrentlocaleText(
                                "inode.list.table.delete.button.hover.text",
                                {
                                  0:
                                    this.state.selectedLicenses.length > 1
                                      ? "Pull Secrets"
                                      : "Pull Secret",
                                },
                              )}
                            </span>
                          }
                          resource="secret"
                          action="delete"
                          disabled={_.isEmpty(this.state.selectedLicenses)}
                          iconButton={true}
                          icon="AiOutlineDelete"
                          formData={this.state.selectedLicenses}
                          afterCall={this.deleteAfterCall}
                          secretType="ssh"
                          controller={SecretController}
                          messageContent={
                            <span>
                              <p>
                                {getCurrentlocaleText(
                                  this.state.selectedLicenses.length > 1
                                    ? "pullsecret.multiple.delete.confirmation.message.text"
                                    : "pullsecret.delete.confirmation.message.text",
                                )}
                              </p>
                            </span>
                          }
                          warningMessage={getCurrentlocaleText(
                            this.state.selectedLicenses.length > 1
                              ? "pullsecret.multiple.warning.message.text"
                              : "pullsecret.delete.warning.message.text",
                          )}
                          cancelText={getCurrentlocaleText(
                            "inode.delete.confirmation.cancel.text",
                            {
                              0:
                                this.state.selectedLicenses.length > 1
                                  ? "Pull Secrets"
                                  : "Pull Secret",
                            },
                          )}
                          okText={
                            <span>
                              <Icons
                                type="ai"
                                name="AiOutlineDelete"
                                className="iot-delete-icon"
                                style={{ margin: 0 }}
                              />
                              {getCurrentlocaleText(
                                "inode.delete.confirmation.ok.text",
                                {
                                  0:
                                    this.state.selectedLicenses.length > 1
                                      ? "Pull Secrets"
                                      : "Pull Secret",
                                },
                              )}
                            </span>
                          }
                        ></ActionBtn>
                      )}
                    </div>
                  </div>
                </div>
                <Row>
                  <Col span={24}>
                    {listdockerConfig.length > 0 ? (
                      <TableLayoutV1
                        columns={this.LicenseTableColumns}
                        dataSource={listdockerConfig}
                        pagination={{
                          pageSize:
                            this.secretModel &&
                            this.secretModel.pageable.dockerconfig.size,
                          total:
                            this.secretModel &&
                            this.secretModel.pageable.dockerconfig.total_count,
                          current: this.state.currentPage,
                        }}
                        hideExpandedRow={true}
                        rowClick={this.rowClick}
                        selectedRows={this.selectedLicenseRows}
                        selectAll={this.selectLicenseAll}
                        onChange={this.onTableChange}
                        className="secretSSHList"
                      />
                    ) : (
                      <div>
                        {!this.secretModel.loading && (
                          <h3>
                            {" "}
                            {getCurrentlocaleText(
                              "no.pull.secret.available",
                            )}{" "}
                          </h3>
                        )}
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </TabPane>
          }
          {/* docker ends */}

          {/* volume pane */}
          <TabPane tab="Volumes" key="volume">
            {this.props.AuthStore.IsPermitted("NODE:VIEW") && (
              <VolumeComponent
                params={{
                  org_id: this.props.params.org_id
                    ? this.props.params.org_id
                    : null,
                  own: true,
                }}
                orgId={
                  this.props.params.org_id ? this.props.params.org_id : null
                }
                {...this.props}
              />
            )}
          </TabPane>
        </Tabs>
      </div>
    );

    return (
      <LoadingComponent loading={this.secretModel.loading}>
        {container}
      </LoadingComponent>
    );
  }
}

export default LicenseComponent;
