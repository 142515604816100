import React, { Component } from "react";
import { getCurrentlocaleText } from "Core/Utils";
import Icons from "../UI-Components/Icons";
import { Modal, Button, Table, Alert } from "antd";

class InterfaceGroupReAssignModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      interfaceData: [],
    };
  }

  componentDidMount() {
    let interfaceData = [];
    if (this.props.interfaceData) {
      // TAN Group
      if (this.props.interfaceData.tan) {
        this.props.interfaceData.tan.forEach(item => {
          interfaceData.push({
            id: item.label,
            name: item.label,
            from: item.originalGroup
              ? item.originalGroup === "free"
                ? "FLOATING"
                : item.originalGroup.toUpperCase()
              : "-",
            to: "TAN",
          });
        });
      }
      // Free Group
      if (this.props.interfaceData.free) {
        this.props.interfaceData.free.forEach(item => {
          interfaceData.push({
            id: item.label,
            name: item.label,
            from: item.originalGroup ? item.originalGroup.toUpperCase() : "-",
            to: "FLOATING",
          });
        });
      }
      // WAN Group
      if (this.props.interfaceData.wan) {
        this.props.interfaceData.wan.forEach(item => {
          interfaceData.push({
            id: item.label,
            name: item.label,
            from: item.originalGroup ? item.originalGroup.toUpperCase() : "-",
            to: "WAN",
          });
        });
      }
    }
    this.setState({ interfaceData: interfaceData });
  }

  render() {
    this.columns = [
      {
        title: getCurrentlocaleText("inode.interface.name.label.text"),
        key: "name",
        dataIndex: "name",
      },
      {
        title: getCurrentlocaleText("general.firewall.from_network.label.text"),
        key: "from",
        dataIndex: "from",
      },
      {
        title: getCurrentlocaleText("general.firewall.to_network.label.text"),
        key: "to",
        dataIndex: "to",
      },
    ];
    return (
      <Modal
        title={
          <div>
            <span>
              <Icons
                type="go"
                name="GoAlert"
                className="iotium-warning"
                style={{ fontSize: 20, marginRight: 5 }}
              />
            </span>
            <h3 className="d-inline-block">
              {getCurrentlocaleText("re-assign.interface.group.model.title")}
            </h3>
          </div>
        }
        visible={this.props.visible}
        width="30%"
        maskClosable={false}
        closable={true}
        footer={[
          <Button key="cancel" onClick={this.props.closeModal}>
            {getCurrentlocaleText("cancel.button.text")}
          </Button>,
          <Button
            key="confirm"
            type="primary"
            onClick={this.props.handleConfirm}
          >
            {getCurrentlocaleText("general.button.confirm.label.text")}
          </Button>,
        ]}
      >
        <div>
          <Table
            columns={this.columns}
            dataSource={this.state.interfaceData}
            rowKey={record => record.id}
            size={"middle"}
            pagination={false}
            indentSize={5}
          />
          <br />
          <div>
            <Alert
              description={
                this.props.isCluster
                  ? getCurrentlocaleText(
                      "re-assign.interface.group.cluster.node.rebooting.message",
                    )
                  : getCurrentlocaleText(
                      "re-assign.interface.group.node.rebooting.message",
                    )
              }
              type="warning"
              showIcon
            />
          </div>
        </div>
      </Modal>
    );
  }
}

export default InterfaceGroupReAssignModal;
