import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Row, Col } from "antd";

import OrgsList from "components/DataLists/OrgsList";
import BreadcrumbComponent from "components/UI-Components/BreadcrumbComponent";
import Icons from "components/UI-Components/Icons";

import OrgController from "controller/OrgController";
import OrgSelector from "components/Orgs/OrgSelector";

@inject("UiStore", "AuthStore", "OrgViewModel")
@observer
class Orgs extends Component {
  constructor(props) {
    super(props);
    this.uistore = this.props.UiStore;
    this.orgModel = this.props.OrgViewModel;
    this.state = {
      orgsdata: [],
      BreadcrumbList: [{ name: "Organizations", link: "orgs" }],
    };
  }

  componentDidMount() {
    let params = this.props.params || {};
    params.page = 0;
    OrgController.getOrgs(params, true).then(resp => {
      if (resp) {
        resp.map(val => {
          val.is_me && this.orgModel.addOrg(val);
        });
      }
    });
  }

  showModal() {
    this.uistore.UiState("showAddOrg", true);
  }

  render() {
    let orgId = null;
    if (
      this.props.AuthStore.loggedInUser &&
      this.props.AuthStore.loggedInUser.organization
    ) {
      orgId = this.props.AuthStore.loggedInUser.organization.id;
    }

    let container = (
      <div>
        {this.props.AuthStore.loggedInUser && (
          <Row gutter={16} type="flex" align="middle">
            <Col lg={{ span: 18 }} xl={{ span: 20 }}>
              <h2 className="page-title">
                <Icons type="fa" name="FaSitemap" />
                {"  "}
                Organizations
              </h2>
              <BreadcrumbComponent
                {...this.props}
                BreadcrumbList={this.state.BreadcrumbList}
              />
            </Col>
            <Col
              lg={{ span: 6 }}
              xl={{ span: 4 }}
              className="flex flex-h-right"
            >
              <OrgSelector {...this.props} />
            </Col>
          </Row>
        )}
        <div
          style={{
            padding: 24,
            background: "#fff",
            minHeight: 360,
          }}
        >
          <Row gutter={16}>
            <Col span={24}>
              {this.props.AuthStore.IsPermitted("ORG:VIEW") ? (
                <OrgsList
                  cache={false}
                  icon={<Icons type="ai" name="AiOutlineSolution" />}
                  title="Organizations"
                  params={{
                    org_id: orgId,
                  }}
                  orgId={orgId}
                  isFromDashboard={true}
                  {...this.props}
                />
              ) : null}
            </Col>
          </Row>
        </div>
      </div>
    );

    return <div>{container}</div>;
  }
}

export default Orgs;
