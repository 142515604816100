import React, { Component } from "react";
import { Route } from "react-router-dom";
import {
  Card,
  Row,
  Col,
  Button,
  Menu,
  Tabs,
  Table,
  Tag,
  Modal,
  Popover,
} from "antd";
import { action } from "mobx";
import { observer, inject } from "mobx-react";

import SearchInput from "components/UI-Components/SearchInput";
import { NetworksList } from "components/DataLists";
import TableLayout from "components/layout/TableLayout";
import ActionBtn from "Core/API/ActionBtn";
import {
  highlightSearchText,
  notificationModal,
  getCurrentlocaleText,
  getNodeVersion,
  RestartServiceBaseVersion,
  serviceListenerBaseVersion,
} from "Core/Utils";
import Icons from "components/UI-Components/Icons";
import LoadingComponent from "components/UI-Components/LoadingComponent";

import OrgController from "controller/OrgController";
import NetworkController from "controller/NetworkController";
import ProfileController from "controller/ProfileController";
import ServiceController from "controller/ServiceController";
import ServiceListenerController from "controller/ServiceListenerController";
import SecretController from "controller/SecretController";
import InodeInterfaces from "components/INodes/InodeInterfaces";
import ActionRoute from "Core/API/ActionRoute";
import DropdownActionBtn from "components/UI-Components/DropdownActionBtn";

/* service templates*/
import AppStore from "components/serviceTemplates/Components/views/AppStore.js";
import { isArray } from "util";
import InodeEventList from "../Events/InodeEventList";
import InodeUpgradeHistoryList from "../UpgradeHistorys/InodeUpgradeHistoryList";
import InodeImageList from "../NodeImages/InodeImageList";
import InodeActivityList from "components/DataLists/InodeActivityList";
import MacTable from "../DataLists/MacTable";

//import LicenseComponent from "./License";  //LAT-2486
const TabPane = Tabs.TabPane;
/*
 * LAT-4555
 * In UI show as '3.0.15'. In api save as '3.0.15-06.08.2018'
 */
//const newSkysparkImgVersion = "3.0.15";

const RouteTab = props => (
  <Route
    render={({ history }) => (
      <Tabs
        onTabClick={key => {
          let path =
            props.match.url
              .replace("/networks", "")
              .replace("/services", "")
              .replace("/mactable", "")
              .replace("/listeners", "")
              .replace("/interfaces", "")
              .replace("/events", "")
              .replace("/images", "")
              .replace("/upgradehistory", "")
              .replace("/activities", "")
              .replace("/servicetemplates", "") +
            "/" +
            key;
          history.push(path);
          props.UiStore.setCurrentPage(1);
        }}
        {...props}
      >
        {props.children}
      </Tabs>
    )}
  />
);

@inject(
  "ModelStore",
  "UiStore",
  "AuthStore",
  "InodeViewModel",
  "ServiceViewModel",
  "SecretViewModel",
  "NetworkViewModel",
  "ServiceListenerViewModel",
)
@observer
class NetworkServiceTab extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = this.props.InodeViewModel;
    this.serviceModel = this.props.ServiceViewModel;
    this.secretModel = this.props.SecretViewModel;
    this.networkModel = this.props.NetworkViewModel;
    this.serviceListenerModel = this.props.ServiceListenerViewModel;
    this.columnWidth = "25%";
    this.searchTimeout = null;
    this.mounted = true;
    this.TAB_KEYS = {
      default: "networks",
      networks: "networks",
      services: "services",
      listeners: "listeners",
      events: "events",
      interfaces: "interfaces",
      servicetemplates: "servicetemplates",
      images: "images",
      upgradehistorys: "upgradehistory",
      activities: "activities",
      mactable: "mactable",
    };
    this.isNetworkThere = false;
    this.state = {
      tabKey: this.TAB_KEYS.default,
      selectedService: {},
      serviceRowClicked: {},
      templateSelected: "", //Name of the template
      isVirtualiNode: true,
      isVirtualEdgeiNode: false,
      isEdgeiNode: false,
      searchText: "",
      filterInfo: null,
      sorterInfo: null,
      nodeOrg: null,
      shwFirewallGroup: false,
      shwListener: false,
      expandedRowKeys: [],
      showAppStore: false,
      isNetworkDataLoaded: false,
      availableNetworks: [],
      showEvents: false,
      showUpgradeHistorys: false,
      loading: false,
      showRestartmod: false,
      showRestartCnm: true,
      message: null,
      currentService: null,
      showActivities: false,
    };
    this.networkApiByInterval = null;
    this.serviceInterval = null;
    this.serviceListenerInterval = null;
    this.UiStore = this.props.UiStore;
    this.selectService = this.selectService.bind(this);
    this.selectServiceAll = this.selectServiceAll.bind(this);
    this.rowClick = this.rowClick.bind(this);
    this.hasCustomPermission = false;
    this.serviceStatusAliasMsg = {
      "the pod status is unknown since node is":
        "Service status is unknown since the iNode status is",
      "no pod status available": "Service status is unavailable",
    };

    this.ServiceTemplatesTablecolumns = [
      {
        title: getCurrentlocaleText("orgs.tablelist.namecolumn.text"),
        dataIndex: "name",
        key: "name",
        width: "15%",
        render: (text, record, index) => {
          return (
            <span>
              {" "}
              {this.state.searchText.trim().length > 0
                ? highlightSearchText(text, this.state.searchText)
                : text}
              {record.system_service && (
                <div>
                  <Tag closable={false}>
                    <Icons
                      type="fa"
                      name="FaCogs"
                      style={{ height: 14, width: 14 }}
                    />{" "}
                    {getCurrentlocaleText("serial_list.system_service.text")}
                  </Tag>
                </div>
              )}
              {record.deleted_at && (
                <span className="iotium-danger">
                  {" "}
                  {getCurrentlocaleText("deleting.text")}
                </span>
              )}
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText("node.list.table.node_status.label.text"),
        dataIndex: "pod_status",
        key: "pod_status",
        width: "22%",
        render: (text, record) => {
          // Popover Content
          let unknownStatusContent = (
            <div>
              {record.pod && record.pod.message
                ? record.pod.message
                : getCurrentlocaleText("service.status.unavailable.text")}
            </div>
          );
          const nonUnknownStatusContent = (
            <div>
              <p>
                <strong>
                  {getCurrentlocaleText("node.list.table.popover.created")}
                  {" : "}
                </strong>
                {record.pod && record.pod.start_time
                  ? record.pod.start_time
                  : "-"}
              </p>
              <p>
                <strong>
                  {getCurrentlocaleText("node.list.table.popover.message")}
                  {" : "}
                </strong>
                {record.pod && record.pod.message ? record.pod.message : "-"}
              </p>
            </div>
          );
          return (
            <div>
              {record.pod && record.pod.status ? (
                <Popover
                  content={
                    record.pod.status.toLowerCase() === "unknown"
                      ? unknownStatusContent
                      : nonUnknownStatusContent
                  }
                  title={
                    record.pod.status.toLowerCase() === "unknown"
                      ? null
                      : getCurrentlocaleText("service.status")
                  }
                >
                  <Tag className={"tag-" + record.pod.status.toLowerCase()}>
                    {" "}
                    {record.pod.status}{" "}
                  </Tag>
                </Popover>
              ) : (
                <span>{"-"}</span>
              )}
            </div>
          );
        },
      },
      {
        title: getCurrentlocaleText("general.component.networks.text"),
        dataIndex: "network_id",
        key: "network",
        width: "15%",
        render: (text, record, index) => {
          if (
            isArray(record.networkDetails) &&
            record.networkDetails.length == 1
          ) {
            return (
              <a
                href={`/orgs/${record.serviceData &&
                  record.serviceData.organization &&
                  record.serviceData.organization
                    .id}/inodes/${record.serviceData &&
                  record.serviceData.node &&
                  record.serviceData.node.id}/view/${
                  record.networkDetails[0].networkId
                }`}
              >
                {record.networkDetails[0].network_name}
              </a>
            );
          } else if (
            isArray(record.networkDetails) &&
            record.networkDetails.length > 1
          ) {
            let popoverContentData = [];
            let networkIpAddress =
              record &&
              record.serviceData &&
              record.serviceData.spec &&
              record.serviceData.spec.networks;

            // Needed copy the array for popover content
            let popoverNetworkDetails =
              record &&
              record.networkDetails.map(detail => ({
                ...detail,
              }));

            networkIpAddress.length > 0 &&
              networkIpAddress.forEach((network, i, arr) => {
                if (
                  network.is_default === true &&
                  network.ip_address === record.networkDetails[i].ip_address
                ) {
                  popoverNetworkDetails[i].network_name += " (Default)";
                }
                popoverContentData.push(
                  <div key={i}>
                    {popoverNetworkDetails[i].networkId && (
                      <a
                        href={`/orgs/${record.serviceData &&
                          record.serviceData.organization &&
                          record.serviceData.organization
                            .id}/inodes/${record.serviceData &&
                          record.serviceData.node &&
                          record.serviceData.node.id}/view/${
                          popoverNetworkDetails[i].networkId
                        }`}
                      >
                        {popoverNetworkDetails[i].network_name}
                      </a>
                    )}
                    {!popoverNetworkDetails[i].network_name && <span>-</span>}
                    {arr.length - 1 !== i && <span>,&nbsp;</span>}
                  </div>,
                );
              });

            return (
              <Popover content={popoverContentData}>
                <a
                  href={`/orgs/${record.serviceData &&
                    record.serviceData.organization &&
                    record.serviceData.organization
                      .id}/inodes/${record.serviceData &&
                    record.serviceData.node &&
                    record.serviceData.node.id}/view/${
                    record.networkDetails[0].networkId
                  }`}
                >
                  {record.networkDetails[0].network_name}
                </a>
                ...
              </Popover>
            );
          } else {
            return "-";
          }
        },
      },
      {
        title: getCurrentlocaleText("inode.interface.ipv4.label.text"),
        dataIndex: "ip_address",
        key: "ipAddress",
        width: "20%",
        render: (text, record, index) => {
          if (
            isArray(record.networkDetails) &&
            record.networkDetails.length == 1
          ) {
            return <div>{record.networkDetails[0].ip_address}</div>;
          } else if (
            isArray(record.networkDetails) &&
            record.networkDetails.length > 1
          ) {
            let popoverContentData = [];
            let networkIpAddress =
              record &&
              record.serviceData &&
              record.serviceData.spec &&
              record.serviceData.spec.networks;

            networkIpAddress.length > 0 &&
              networkIpAddress.map((network, i, arr) => {
                let showIpAddress = network.ip_address;
                if (network.is_default === true) {
                  showIpAddress += " (Default)";
                }
                popoverContentData.push(
                  <div key={i}>
                    {showIpAddress ? showIpAddress : "-"}
                    {arr.length - 1 !== i && <span>,&nbsp;</span>}
                  </div>,
                );
              });

            return (
              <Popover content={popoverContentData}>
                <div>{record.networkDetails[0].ip_address}...</div>
              </Popover>
            );
          } else {
            return "-";
          }
        },
      },
      {
        title: getCurrentlocaleText("running.containers"),
        dataIndex: "running_container",
        key: "running_container",
        width: "18%",
        render: (text, record) => {
          return (
            <span style={{ paddingLeft: "20px" }}>
              {record.running_container}
            </span>
          );
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "edit_node",
        width: "5%",
        render: (text, record, index) => {
          /*  LAT-5077  */
          let serType =
            record.serviceData.spec.labels &&
            record.serviceData.spec.labels.io_iotium_template
              ? record.serviceData.spec.labels["io_iotium_template"]
              : record.serviceData.spec.labels &&
                record.serviceData.spec.labels["io-iotium-template"]
              ? record.serviceData.spec.labels["io-iotium-template"]
              : "custom";

          const view = (
            <span>
              <ActionRoute
                actionIcon="AiOutlineEye"
                actionLabel={getCurrentlocaleText("view.service.text")}
                iconButton
                title={getCurrentlocaleText("view.service.text")}
                route={this.serviceRoute(
                  serType,
                  record.id,
                  "serviceTemplatesView",
                )}
                iconBtnStyle={{ fontSize: 14 }}
              />
            </span>
          );
          const edit = (
            <span>
              <ActionRoute
                actionIcon="AiOutlineEdit"
                actionLabel={getCurrentlocaleText(
                  "network.form.useraction_edit.label.text",
                )}
                iconButton
                title={getCurrentlocaleText("service.edit.header.text")}
                disabled={record.disabled ? record.disabled : false}
                route={this.serviceRoute(
                  serType,
                  record.id,
                  "serviceTemplatesEdit",
                )}
              />
            </span>
          );
          const serviceLog = (
            <span>
              {/* New log Infra menu */}

              <ActionRoute
                actionIcon="AiOutlineFileText"
                actionLabel={getCurrentlocaleText("service.logs")}
                disabled={
                  (this.inodeModel.inode &&
                    this.inodeModel.inode.node_state &&
                    this.inodeModel.inode.node_state.toLowerCase() !=
                      "alive") ||
                  (record.pod &&
                    record.pod.status &&
                    record.pod.status.toLowerCase() === "pending") ||
                  (record.pod &&
                    record.pod.status &&
                    record.pod.status.toLowerCase() === "unknown")
                    ? true
                    : false
                }
                iconButton
                title={getCurrentlocaleText("service.logs")}
                route={this.serviceRoute(
                  serType,
                  record.id,
                  "serviceTemplatesLogs",
                )}
              />
            </span>
          );
          const restart = (
            <div>
              <Button
                title={
                  record.pod.restart_policy &&
                  record.pod.restart_policy === "Never"
                    ? getCurrentlocaleText(
                        "Service.restart.disabled.help.text1",
                      )
                    : "Restart Service"
                }
                onClick={() => {
                  this.showServiceRestartConfirm(record);
                }}
                disabled={
                  (record.pod.restart_policy &&
                    record.pod.restart_policy === "Never") ||
                  (record.pod &&
                    record.pod.status &&
                    record.pod.status.toLowerCase() === "restarting")
                    ? true
                    : false
                }
                style={{ minWidth: 50 }}
              >
                <Icons
                  style={{ margin: 0, fontSize: 16 }}
                  type="md"
                  name="MdRefresh"
                />
              </Button>
            </div>
          );
          const menu = (
            <Menu>
              {(this.props.AuthStore.IsPermitted("SERVICE:VIEW") ||
                this.props.AuthStore.IsPermitted("ORG:SUPPORT")) && (
                <Menu.Item key={0}>{view}</Menu.Item>
              )}
              {this.props.AuthStore.IsPermitted("SERVICE:CREATE") &&
                !record.is_inheritance &&
                !record.deleted_at && <Menu.Item key={1}>{edit}</Menu.Item>}
              {!record.deleted_at && (
                <Menu.Item key={2}>{serviceLog}</Menu.Item>
              )}
              {this.props.AuthStore.IsPermitted("SERVICE:RESTART") &&
                !record.deleted_at &&
                parseFloat(getNodeVersion(this.inodeModel.inode)) >=
                  RestartServiceBaseVersion && (
                  <Menu.Item key={3}>{restart}</Menu.Item>
                )}
            </Menu>
          );

          return (
            <div>
              <DropdownActionBtn menu={menu} />
            </div>
          );
        },
      },
    ];
    this.ServiceListenerTablecolumns = [
      {
        title: getCurrentlocaleText("orgs.tablelist.namecolumn.text"),
        dataIndex: "name",
        key: "name",
        width: "25%",
        render: (text, record, index) => {
          return (
            <span>
              {" "}
              {this.state.searchText.trim().length > 0
                ? highlightSearchText(text, this.state.searchText)
                : text}
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText(
          "service_listener.service_selector.label.text",
        ),
        dataIndex: "service_selector",
        key: "service_selector",
        width: "30%",
        render: (text, record, index) => {
          return <span>{record.service_selector}</span>;
        },
      },
      {
        title: getCurrentlocaleText("service_listener.ports.text"),
        dataIndex: "ports",
        key: "ports",
        width: "30%",
        render: (text, record, index) => {
          if (record.port && record.port.length > 0) {
            let name = record.port.split(",");
            let popoverContent = name.map((val, i) => {
              if (i > 0) {
                return <p>{val}</p>;
              }
            });

            return name.length <= 1 ? (
              <p>{name[0]}</p>
            ) : (
              <div>
                <Popover
                  content={popoverContent}
                  title="Ports"
                  placement="right"
                >
                  <p>{name[0] + ` ...`}</p>
                </Popover>
              </div>
            );
          } else {
            return <div>{"-"}</div>;
          }
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "edit_listener",
        width: "13%",
        render: (text, record, index) => {
          const view = (
            <span>
              <ActionRoute
                actionIcon="AiOutlineEye"
                actionLabel={getCurrentlocaleText("view.service_listener.text")}
                iconButton
                title={getCurrentlocaleText("view.service_listener.text")}
                route={window.location.pathname.replace(
                  "/listeners",
                  `/listeners/view/${record.id}`,
                )}
                iconBtnStyle={{ fontSize: 14 }}
              />
            </span>
          );
          const edit = (
            <span>
              <ActionRoute
                actionIcon="AiOutlineEdit"
                actionLabel={getCurrentlocaleText(
                  "service_listener.form.useraction_edit.label.text",
                )}
                iconButton
                title={getCurrentlocaleText(
                  "service_listener.form.useraction_edit.label.text",
                )}
                disabled={record.disabled ? record.disabled : false}
                route={window.location.pathname.replace(
                  "/listeners",
                  `/listeners/modify/${record.id}`,
                )}
              />
            </span>
          );
          const listenerStatus = (
            <span>
              {/*Listener status menu */}
              {
                <ActionRoute
                  iconType="fa"
                  iconName="FaShieldAlt"
                  actionLabel={"View Status"}
                  isCustomIcon
                  title={"View Status"}
                  route={window.location.pathname.replace(
                    "/listeners",
                    `/listeners/${record.id}/status`,
                  )}
                />
              }
            </span>
          );
          const menu = (
            <Menu>
              {(this.props.AuthStore.IsPermitted("SERVICE:VIEW") ||
                this.props.AuthStore.IsPermitted("ORG:SUPPORT")) && (
                <Menu.Item key={0}>{view}</Menu.Item>
              )}
              {this.props.AuthStore.IsPermitted("SERVICE:CREATE") && (
                <Menu.Item key={1}>{edit}</Menu.Item>
              )}
              {this.props.AuthStore.IsPermitted("ORG:SUPPORT") && (
                <Menu.Item key={1}>{listenerStatus}</Menu.Item>
              )}
            </Menu>
          );
          return (
            <div>
              <DropdownActionBtn menu={menu} />
            </div>
          );
        },
      },
    ];
  }

  editService = record => {
    let service_type = record.serviceData.spec.labels["io-iotium-template"]
      ? record.serviceData.spec.labels["io-iotium-template"]
      : record.serviceData.spec.labels["io_iotium_template"]
      ? record.serviceData.spec.labels["io_iotium_template"]
      : "custom";

    this.props.history.push(
      `/services/${service_type.toLowerCase()}/${record.id}`,
    );
  };

  editServiceListener = record => {
    this.props.history.push(`/listeners/${record.id}`);
  };
  updateOrgsNetworkData(totalCount, availableNetworks, loading) {
    if (totalCount > 0) {
      let allParams = [];
      for (let page = 0; page < totalCount; page++) {
        allParams.push({
          org_id: this.inodeModel.inode.organization
            ? this.inodeModel.inode.organization.id
            : this.props.orgId || this.props.match.params.id,
          own: true,
          size: 100,
          page: page,
        });
      }
      NetworkController.getAllNetworksForDropdown(allParams, loading).then(
        resp => {
          if (resp) {
            availableNetworks = availableNetworks.concat(resp);
            this.setState({ availableNetworks: availableNetworks });
            let iNodeNetworks = availableNetworks.filter(networks => {
              if (
                networks.node &&
                networks.node.id === this.inodeModel.inode.id
              )
                return networks;
            });
            if (iNodeNetworks.length === 1) {
              this.networkModel.resetNetworks();
              this.networkModel.addNetworks(availableNetworks);
            }
            let networks = availableNetworks;
            this.setState({ isNetworkDataLoaded: true });
            /*API CALL every 1 Minute Intiated HERE. Based on delete_at constarins*/
            this.getNetworksByInterval();

            /*update templatingService model with node and network details. LAT-4069 */
            let nodelist = [
              {
                name: this.inodeModel.inode.name,
                id: this.inodeModel.inode.id,
                organization: this.inodeModel.inode.organization,
              },
            ];
            let currentNodeNetworks = this.getCurrentNodeNetworks(
                this.networkModel.networks,
                this.inodeModel.inode.id,
              ),
              currentNetworks = [];
            currentNodeNetworks.map(network => {
              currentNetworks.push({
                name: `${network.name} (Network CIDR : ${network.config.network.cidr})`,
                id: network.id,
                config: network.config,
                type: network.type,
                status: network.status,
              });
              return null;
            });

            this.props.UiStore.templatingService.nodeDetails = {
              nodeList: nodelist,
              networkList: currentNetworks, //this.inodeModel.inode.networks,
            };
          }
        },
      );
    }
  }

  updateNetworkData = () => {
    let params = this.props.params || {
      //node_id: this.props.nodeId,
      org_id: this.inodeModel.inode.organization
        ? this.inodeModel.inode.organization.id
        : this.props.orgId || this.props.match.params.id,
      own: true,
      size: 1,
      page: 0,
    };
    params.page = 0;
    NetworkController.getNetworks(params, true, false, true).then(resp => {
      if (resp && resp.length > 0) {
        this.updateOrgsNetworkData(
          this.networkModel.pageable.total_count / 100,
          [],
          true,
        );
      }
    });
  };

  getNetworksByInterval = () => {
    let networks = this.networkModel.networks.filter(value => {
      if (value && value.node) return value.node.id === this.props.nodeId;
    });
    if (this.isNetworkDeleted(networks) || this.hasNetworkStatus(networks)) {
      clearInterval(this.networkApiByInterval);
      this.networkApiByInterval = setInterval(() => {
        let params = this.props.params || {
          org_id: this.inodeModel.inode.organization
            ? this.inodeModel.inode.organization.id
            : this.props.orgId || this.props.match.params.id,
          own: true,
          size: 1,
          page: 0,
        };
        NetworkController.getNetworks(params, false, true, false).then(
          response => {
            if (response && response.config && response.config.params) {
              if (response.data && response.data.results.length === 0) {
                this.networkModel.resetNetworks();
              }

              this.updateOrgsNetworkData(
                response.data.total_count / 100,
                [],
                false,
              );
              // check for clear interval
              if (this.networkModel.networks.length === 1) {
                clearInterval(this.networkApiByInterval);
              }
            }
          },
        );
      }, 30000);
    }
  };

  isNetworkDeleted(networks) {
    if (networks) {
      let deleted_networks = networks.filter(network => {
        return network.deleted_at;
      });
      return deleted_networks.length > 0 ? true : false;
    }
    return false;
  }

  hasNetworkStatus(networks) {
    if (networks) {
      let connected_networks = networks.filter(network => {
        return network.status && network.status.connected_networks;
      });
      return connected_networks.length > 0 ? true : false;
    }
    return false;
  }

  checkCustomServicePermission = () => {
    /*LAT-2776 */
    if (this.inodeModel.inode && this.props.AuthStore.IsPermitted("ORG:VIEW")) {
      OrgController.getOrgById(this.inodeModel.inode.organization.id).then(
        res => {
          res.policy.service
            ? (this.hasCustomPermission = res.policy.service.custom)
            : (this.hasCustomPermission = false);
          this.setState({
            shwFirewallGroup: res.policy.service
              ? res.policy.service.custom
              : false,
          });
        },
      );
    }
  };

  checkServiceListenerPermission = () => {
    if (this.inodeModel.inode && this.props.AuthStore.IsPermitted("ORG:VIEW")) {
      OrgController.getOrgById(
        this.inodeModel.inode.organization.id,
        true,
      ).then(res => {
        this.setState({
          shwListener:
            res.policy.service &&
            res.policy.service.listener &&
            res.policy.service.listener.enable
              ? res.policy.service.listener.enable
              : false,
        });
      });
    }
  };

  componentDidMount() {
    // preventive fix on to clear previous data
    this.serviceModel.resetServices();
    this.serviceListenerModel.resetServiceListeners();
    let paths = this.props.location.pathname.split("/");
    let tabKey = paths[paths.length - 1];
    if (tabKey && Object.values(this.TAB_KEYS).includes(tabKey)) {
      this.setState({
        tabKey: tabKey,
        showEvents: tabKey === this.TAB_KEYS.events ? true : false,
      });
    }
    if (tabKey && Object.values(this.TAB_KEYS).includes(tabKey)) {
      this.setState({
        tabKey: tabKey,
        showUpgradeHistorys:
          tabKey === this.TAB_KEYS.upgradehistorys ? true : false,
      });
    }
    if (tabKey && Object.values(this.TAB_KEYS).includes(tabKey)) {
      this.setState({
        tabKey: tabKey,
        showActivities: tabKey === this.TAB_KEYS.activities ? true : false,
      });
    }

    if (this.props.AuthStore.IsPermitted("SERVICE:VIEW")) {
      this.resetSelectedService();
      this.serviceList(true, 0, "");
      this.getServicesByInterval();
      this.resetSelectedServiceListener();
      this.serviceListenerList(true, 0, "");
      this.getServiceListenersByInterval();
    }
    this.updateNetworkData();
    this.mounted = true;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      let paths = nextProps.location.pathname.split("/");
      let tabKey = paths[paths.length - 1];
      if (tabKey && Object.values(this.TAB_KEYS).includes(tabKey)) {
        this.setState({
          tabKey: tabKey,
          showEvents: tabKey === this.TAB_KEYS.events ? true : false,
        });
      }
      if (tabKey && Object.values(this.TAB_KEYS).includes(tabKey)) {
        this.setState({
          tabKey: tabKey,
          showUpgradeHistorys:
            tabKey === this.TAB_KEYS.upgradehistorys ? true : false,
        });
      }
      if (tabKey && Object.values(this.TAB_KEYS).includes(tabKey)) {
        this.setState({
          tabKey: tabKey,
          showActivities: tabKey === this.TAB_KEYS.activities ? true : false,
        });
      }
    }

    setTimeout(() => {
      if (nextProps.currentInode.profile && nextProps.currentInode.profile.id) {
        if (this.mounted) {
          ProfileController.getProfile(nextProps.currentInode.profile.id).then(
            profile => {
              if (this.mounted) {
                if (profile && profile.config && profile.config.vpn_server) {
                  this.setState({ isVirtualiNode: true });
                } else {
                  this.setState({ isVirtualiNode: false });
                }
                if (
                  profile &&
                  profile.config &&
                  !profile.config.vpn_server &&
                  profile.config.pki.hsn === "GENERATED"
                ) {
                  this.setState({ isVirtualEdgeiNode: true });
                } else {
                  this.setState({ isVirtualEdgeiNode: false });
                }
                if (
                  profile &&
                  profile.config &&
                  !profile.config.vpn_server &&
                  profile.config.pki.hsn === "PREDEFINED"
                ) {
                  this.setState({ isEdgeiNode: true });
                } else {
                  this.setState({ isEdgeiNode: false });
                }
              }
            },
          );
        }
      }
    }, 2500);

    /*LAT-2776 */
    if (
      nextProps.currentInode &&
      nextProps.currentInode.organization &&
      nextProps.currentInode.organization.id &&
      this.state.nodeOrg == null
    ) {
      this.setState({ nodeOrg: nextProps.currentInode.organization.id });
      this.props.AuthStore.IsPermitted("SERVICE:CREATE") &&
        this.checkCustomServicePermission();
      this.checkServiceListenerPermission();

      /*update templatingService model with node and network details. LAT-4069 */
      let nodelist = [
        {
          name: this.inodeModel.inode.name,
          id: this.inodeModel.inode.id,
          organization: this.inodeModel.inode.organization,
        },
      ];
      this.props.UiStore.templatingService.nodeDetails = {
        nodeList: nodelist,
        networkList: this.inodeModel.inode.networks,
      };
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    clearInterval(this.networkApiByInterval);
    clearInterval(this.serviceInterval);
    clearInterval(this.serviceListenerInterval);
    // preventive fix on to clear previous data
    this.serviceModel.resetServices();
    this.serviceListenerModel.resetServiceListeners();
  }

  selectService = (record, selected, selectedRows) => {
    this.setState({
      selectedService: this.extractActiveServiceRows(selectedRows),
    });
  };

  selectServiceAll = (selected, selectedRows, changeRows) => {
    this.setState({
      selectedService: this.extractActiveServiceRows(selectedRows),
    });
  };

  resetSelectedService = () => {
    this.setState({ selectedService: {} });
  };

  extractActiveServiceRows = selectedRows => {
    // Return only active service rows
    return selectedRows.filter(row => {
      return row.disabled === false;
    });
  };

  selectServiceListener = (record, selected, selectedRows) => {
    this.setState({
      selectedServiceListener: selectedRows,
    });
  };

  selectServiceListenerAll = (selected, selectedRows, changeRows) => {
    this.setState({
      selectedServiceListener: selectedRows,
    });
  };

  extractActiveServiceListenerRows = selectedRows => {
    // Return only active service rows
    return selectedRows.filter(row => {
      return row.disabled === false;
    });
  };

  resetSelectedServiceListener = () => {
    this.setState({ selectedServiceListener: {} });
  };

  onTabChange = tab => {
    this.setState({
      tabKey: tab,
      showEvents: tab === this.TAB_KEYS.events ? true : false,
      showActivities: tab === this.TAB_KEYS.activities ? true : false,
    });
  };

  onUpgradeTabChange = tab => {
    this.setState({
      tabKey: tab,
      showUpgradeHistorys: tab === this.TAB_KEYS.upgradehistorys ? true : false,
    });
  };

  onActivityTabChange = tab => {
    this.setState({
      tabKey: tab,
      showActivities: tab === this.TAB_KEYS.activities ? true : false,
    });
  };

  rowClick = (record, index, event) => {
    /*Updating service icon*/
    let x = this.props.UiStore.AvailableServiceImages.filter(val => {
      let service_type =
        record.serviceData.spec.labels &&
        record.serviceData.spec.labels["io-iotium-template"]
          ? record.serviceData.spec.labels["io-iotium-template"]
          : record.serviceData.spec.labels &&
            record.serviceData.spec.labels["io_iotium_template"]
          ? record.serviceData.spec.labels["io_iotium_template"]
          : "custom";

      return service_type === val.key;
    });
    this.props.UiStore.selectedServiceImageIconDetails = x[0];

    if (record && !record.disabled) {
      this.updateServiceForm(record.serviceData);
    }
  };

  onTableChange = (pagination, filter, sorter) => {
    this.setState({ filterInfo: filter, sorterInfo: sorter });
  };

  serviceRoute = (serType, id, value) => {
    let route = window.location.pathname;
    let routeArray = route.split("/");
    let showRoute = [];

    if (routeArray.length >= 5) {
      routeArray = routeArray.slice(0, 5);
      routeArray.push("/");
      routeArray.push("servicetemplates");
      showRoute = routeArray;
    } else {
      showRoute = routeArray;
    }

    if (value === "serviceTemplatesLogs") {
      if (routeArray.includes("servicetemplates")) {
        return window.location.pathname.replace(
          "/servicetemplates",
          `/servicetemplates/${id}/logs`,
        );
      } else {
        return window.location.pathname + `/servicetemplates/${id}/logs`;
      }
    } else if (value == "serviceTemplatesEdit") {
      if (routeArray.includes("servicetemplates")) {
        return window.location.pathname.replace(
          "/servicetemplates",
          `/services/${serType.toLowerCase()}/${id}`,
        );
      } else {
        return (
          window.location.pathname + `/services/${serType.toLowerCase()}/${id}`
        );
      }
    } else if (value == "serviceTemplatesView") {
      if (routeArray.includes("servicetemplates")) {
        return window.location.pathname.replace(
          "/servicetemplates",
          `/serviceview/${serType.toLowerCase()}/${id}`,
        );
      } else {
        return (
          window.location.pathname +
          `/serviceview/${serType.toLowerCase()}/${id}`
        );
      }
    }
  };

  showServiceRestartConfirm = record => {
    this.setState({
      showRestartmod: true,
      loading: false,
      currentService: record,
    });
  };
  e;
  handleRestart = () => {
    let record = this.state.currentService ? this.state.currentService : {};
    //API REQ BEGINS HERE
    ServiceController.restart(record && record.id)
      .then(response => {
        this.setState(
          {
            message: response.message,
            showRestartmod: false,
            loading: false,
            currentService: {},
          },
          () => {
            this.serviceList(true, 0, this.state.searchText);
          },
        );
      })
      .catch(error => {
        this.setState({
          showRestartmod: false,
          loading: false,
          currentService: {},
        });
      });
  };

  /*fetchSSHBase64Data = id => {
    /*This is to fetch the base64 value of license to show in UI.
    /*TODO - repalce api with Modelstore secret obj
    let resource = "secret",
      map = {
        id: id,
      },
      params = {
        org_id: this.inodeModel.inode.organization.id,
        own: true,
      };
    this.props.ModelStore.Get(resource, map, params).then(response => {
      /*For one SSH container
      this.props.UiStore.serviceFormData.ssh_container = Object.values(
        response.data.data,
      )[0];
      this.props.UiStore.serviceFormData.original_ssh_container = response; /*Keep reference of response
      this.props.UiStore.serviceFormData.ssh_name = response.data.name;
    });
  };*/

  @action
  updateServiceForm = data => {
    let SkysparkObj = {},
      Opcedgeobj = {},
      availableServices = ["thingworx_edge", "niagara4", "skyspark", "opcedge"];
    let template_type =
      data.spec.labels && data.spec.labels["io-iotium-template"]
        ? data.spec.labels["io-iotium-template"]
        : data.spec.labels && data.spec.labels["io_iotium_template"]
        ? data.spec.labels["io_iotium_template"]
        : "custom";

    /*Updating UiStore object with selected service value */
    this.props.UiStore.serviceFormData.id = data.id;
    this.props.UiStore.serviceFormData.services = template_type;

    if (data.spec.networks && data.spec.networks.length > 0) {
      this.props.UiStore.serviceFormData.ip_address =
        data.spec.networks[0].ip_address;
      this.props.UiStore.serviceFormData.network_id =
        data.spec.networks[0].network_id;
    }
    this.props.UiStore.serviceFormData.name = data.spec.name;
    this.props.UiStore.serviceFormData.services_id = data.spec.services[0].id; //LAT-2470
    this.props.UiStore.serviceFormData.metadata =
      data.metadata && data.metadata; /// LAT-4821

    var skysparkImgVersion;

    switch (data.spec.services[0].image.version) {
      case "3.0.15-06.08.2018":
        skysparkImgVersion = "3.0.15";
        break;
      case "3.0.16-08.20.2018":
        skysparkImgVersion = "3.0.16";
        break;
      default:
        skysparkImgVersion = "3.0.15";
        break;
    }

    /*Any name other than availableServices are considered custom*/
    availableServices.indexOf(template_type.toLowerCase()) < 0
      ? //data.spec.labels["io-iotium-template"].toLowerCase() == "custom"
        (this.props.UiStore.serviceFormData.custom_service = JSON.stringify(
          data.spec,
          null,
          2,
        ))
      : null;
    template_type.toLowerCase() == "skyspark"
      ? ((SkysparkObj = {
          image: skysparkImgVersion, //data.spec.services[0].image.version,
          ADMIN_PASSWORD:
            data.spec.services[0].docker.environment_vars.ADMIN_PASSWORD,
          HTTP_PORT: data.spec.services[0].docker.environment_vars.HTTP_PORT,
          certi_list: data.spec.volumes[0].secret_volume.secret,
          secretCertiName: data.spec.volumes[0].name,
          previousCerti: data.spec.volumes[0],
          services_id: data.spec.services[0].id,
          services_name: data.spec.services[0].name,
          license: "PREVIOUS", //by default update drop-down on form edit and disable new upload
        }),
        /*Check for SSH container in skyspark template*/
        data.spec.volumes.forEach(arr => {
          let name = arr.name,
            isSSH = name
              .trim()
              .split("_")
              .indexOf("SSHContainer");

          if (arr.secret_volume) {
            /*let secret = _.find(this.secretModel.secrets, {
                id: arr.secret_volume.secret.id,
              });*/
            SecretController.getSecret(
              arr.secret_volume.secret,
              false,
              true,
            ).then(secret => {
              if (
                secret &&
                secret.id &&
                secret.labels &&
                secret.labels.type === "ssh"
              ) {
                SkysparkObj.show_ssh_container = true;
                SkysparkObj.ssh_certi_list = arr.secret_volume.secret;
                SkysparkObj.ssh = "PREVIOUS";
              }
              this.props.UiStore.serviceFormData = {
                ...this.props.UiStore.serviceFormData,
                ...SkysparkObj,
              };
            });
          }
        }),
        (this.props.UiStore.serviceFormData = {
          ...this.props.UiStore.serviceFormData,
          ...SkysparkObj,
        }))
      : //: data.spec.labels["io-iotium-template"].toLowerCase() == "opcedge"
      template_type.toLowerCase() == "opcedge"
      ? ((Opcedgeobj = {
          services_id: data.spec.services[0].id,
          services_name: data.spec.services[0].name,
          Opc_Gw_IpAddress:
            data.spec.services[1].docker.environment_vars.Opc_Gw_IpAddress,
          Opc_Server_Name:
            data.spec.services[1].docker.environment_vars.Opc_Server_Name,
        }),
        (this.props.UiStore.serviceFormData = {
          ...this.props.UiStore.serviceFormData,
          ...Opcedgeobj,
        }))
      : "";
    this.setState({
      serviceRowClicked: this.props.UiStore.serviceFormData,
      templateSelected: template_type,
    });
  };
  expandRow = () => {
    let keys = this.serviceModel.services.map(item => {
      return item.id;
    });
    this.setExpandedRowKeys(
      this.state.expandedRowKeys.length !== keys.length ? keys : [],
    );
  };
  expandListenerRow = () => {
    let keys = this.serviceListenerModel.listeners.map(item => {
      return item.id;
    });
    this.setExpandedRowKeys(
      this.state.expandedRowKeys.length !== keys.length ? keys : [],
    );
  };

  onExpand = (expanded, record) => {
    let keys = this.state.expandedRowKeys;
    let index = keys.findIndex(val => {
      return val === record.id;
    });
    if (expanded) {
      if (index === -1) {
        keys.push(record.id);
      }
      this.setExpandedRowKeys(keys);
    } else {
      if (index !== -1) {
        keys.splice(index, 1);
      }
      this.setExpandedRowKeys(keys);
    }
  };

  setExpandedRowKeys = keys => {
    this.setState({ expandedRowKeys: keys });
  };

  createAfterCall = resp => {
    this.serviceList(true, 0, this.state.searchText);
    this.resetSelectedService();
  };
  createListenerAfterCall = resp => {
    this.serviceListenerList(true, 0, this.state.searchText);
    this.resetSelectedServiceListener();
  };

  deleteAfterCall = () => {
    this.resetSelectedService();
    this.serviceList(true, 0, this.state.searchText);
  };
  deleteListenerAfterCall = () => {
    this.resetSelectedServiceListener();
    this.serviceListenerList(true, 0, this.state.searchText);
  };

  serviceListFilter = e => {
    clearTimeout(this.searchTimeout);
    let searchText = e.target.value;
    this.setState({ searchText: searchText });
    this.searchTimeout = setTimeout(() => {
      this.serviceList(false, 0, searchText);
    }, 500);
  };

  serviceListenerListFilter = e => {
    clearTimeout(this.searchTimeout);
    let searchText = e.target.value;
    this.setState({ searchText: searchText });
    this.searchTimeout = setTimeout(() => {
      this.serviceListenerList(false, 0, searchText);
    }, 500);
  };

  clearSearchFilter = () => {
    this.setState({ searchText: "" });
    this.serviceList(true, 0, "");
  };
  clearListenerSearchFilter = () => {
    this.setState({ searchText: "" });
    this.serviceListenerList(true, 0, "");
  };
  serviceList = (
    loading = true,
    page = 0,
    search = "",
    forceCall = false,
    resolveData = true,
  ) => {
    let params = this.props.params || {
      node_id: this.props.match.params.nodeId
        ? this.props.match.params.nodeId
        : this.props.nodeId,
    };
    params.page = page;
    params.search = search;
    ServiceController.getServices(params, loading, forceCall, resolveData);
  };

  serviceListenerList = (
    loading = true,
    page = 0,
    search = "",
    forceCall = false,
    resolveData = true,
  ) => {
    let params = this.props.params || {
      node_id: this.props.match.params.nodeId
        ? this.props.match.params.nodeId
        : this.props.nodeId,
    };
    params.page = page;
    params.search = search;
    params.status = "detail";
    ServiceListenerController.getServiceListeners(
      params,
      loading,
      forceCall,
      resolveData,
    );
  };

  lazyLoad = () => {
    if (this.serviceModel.pageable.next) {
      let page = this.serviceModel.pageable.page + 1;
      this.serviceList(true, page, this.state.searchText);
    }
  };

  lazyListenerLoad = () => {
    if (this.serviceListenerModel.pageable.next) {
      let page = this.serviceListenerModel.pageable.page + 1;
      this.serviceListenerList(true, page, this.state.searchText);
    }
  };
  updateServiceList = () => {
    for (let i = 0; i <= this.serviceModel.pageable.page; i++) {
      this.serviceList(false, i, this.state.searchText, true);
    }
  };

  getServicesByInterval = () => {
    /* call service api at regular intervals */
    clearInterval(this.serviceInterval);
    this.serviceInterval = setInterval(() => {
      let pageCount =
        this.serviceModel &&
        this.serviceModel.pageable &&
        this.serviceModel.pageable.page
          ? this.serviceModel.pageable.page
          : 0;
      let params = [];
      for (let page = 0; page <= pageCount; page++) {
        params.push({
          node_id: this.props.match.params.nodeId
            ? this.props.match.params.nodeId
            : this.props.nodeId,
          page: page,
          search: this.state.searchText,
        });
      }
      ServiceController.QueryAllServices(params, false);
    }, 30000);
  };

  updateServiceListenerList = () => {
    for (let i = 0; i <= this.serviceModel.pageable.page; i++) {
      this.serviceListenerList(false, i, this.state.searchText, true);
    }
  };
  getServiceListenersByInterval = () => {
    /* call service api at regular intervals */
    clearInterval(this.serviceListenerInterval);
    this.serviceListenerInterval = setInterval(() => {
      let pageCount =
        this.serviceListenerModel &&
        this.serviceListenerModel.pageable &&
        this.serviceListenerModel.pageable.page
          ? this.serviceListenerModel.pageable.page
          : 0;
      let params = [];
      for (let page = 0; page <= pageCount; page++) {
        params.push({
          node_id: this.props.match.params.nodeId
            ? this.props.match.params.nodeId
            : this.props.nodeId,
          page: page,
          search: this.state.searchText,
          status: "detail",
        });
      }
      ServiceListenerController.QueryAllServiceListeners(params, false);
    }, 30000);
  };
  enableAppStore = e => {
    this.setState({ showAppStore: true });
  };

  closeModal = () => {
    this.setState({ showAppStore: false });
  };

  closeServiceRestartModal = () => {
    this.setState({ showRestartmod: false });
  };

  appModalFooter = () => {
    return [
      <Button key="back" onClick={this.closeModal} type="primary">
        Cancel
      </Button>,
    ];
  };

  getCurrentNodeNetworks = (networks, node_id) => {
    let currentNetworks = networks.filter(network => {
      if (network && network.node) {
        return (
          network.node.id === node_id &&
          (!network.is_wan_network || network.is_wan_network === false)
        );
      }
    });
    return currentNetworks;
  };
  /**
   * GET LISTENER ROUTE
   */
  getCurrentRoute = () => {
    let current_path = window.location.pathname.split("/");
    //Current path will be something like  ['', 'orgs', 'xxx', 'inodes', 'yyy']
    //So splice 0 to 5 elements in that, which returns till iNodeId
    current_path = current_path.splice(0, 5);
    current_path = current_path.join("/");
    return current_path;
  };

  showExtraDetails = record => {
    // Defining service port table columns
    const container_columns = [
      {
        title: getCurrentlocaleText(
          "service_listener.ports.protocol.help.title",
        ),
        dataIndex: "protocol",
        key: "protocol",
      },
      {
        title: getCurrentlocaleText(
          "service_listener.ports.node_port.help.title",
        ),
        dataIndex: "node_port",
        key: "node_port",
      },
      {
        title: getCurrentlocaleText("service_listener.ports.port.help.title"),
        dataIndex: "port",
        key: "port",
      },
      {
        title: getCurrentlocaleText("service_listener.ports.state.title"),
        key: "state",
        render: (text, record) => {
          const content = (
            <div>
              <p>{record.state ? record.state : "UNKNOWN"}</p>
            </div>
          );

          return (
            <Popover
              content={content}
              title={getCurrentlocaleText("service_listener.ports.state.title")}
            >
              <Tag
                className={
                  record.state
                    ? "tag-" + record.state.toLowerCase()
                    : "tag-unknown"
                }
              >
                {" "}
                {record.state ? record.state : "UNKNOWN"}{" "}
              </Tag>
            </Popover>
          );
        },
      },
    ];

    return (
      <div>
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          {record.service_ports && record.service_ports.length > 0 && (
            <Table
              size={"small"}
              bordered={false}
              columns={container_columns}
              dataSource={record.service_ports}
              rowKey={record => record.id}
              pagination={false}
              scroll={{ y: 220 }}
            />
          )}
        </div>
      </div>
    );
  };
  render() {
    let iconStyle = { height: 16, width: 16 };
    let record = this.state.currentService ? this.state.currentService : {};

    // check TAN Network count is available
    if (
      this.inodeModel.inode &&
      this.inodeModel.inode.networks &&
      this.inodeModel.inode.networks.length > 1
    ) {
      this.isNetworkThere = true;
    } else {
      this.isNetworkThere = false;
    }
    let isNodeSupportsListener =
      (this.inodeModel.inode &&
        this.inodeModel.inode.node_state &&
        this.inodeModel.inode.node_state.toLowerCase() === "new") ||
      parseFloat(getNodeVersion(this.inodeModel.inode)) >=
        serviceListenerBaseVersion;

    return (
      <div>
        <Card>
          <RouteTab
            activeKey={this.state.tabKey}
            onChange={this.onTabChange}
            {...this.props}
          >
            {this.props.AuthStore.IsPermitted("NETWORK:CONNECT_DISCONNECT") && (
              <TabPane
                tab={
                  <span>
                    <Icons
                      type="fa"
                      name="FaConnectdevelop"
                      style={iconStyle}
                    />
                    {getCurrentlocaleText("networks.tabheader.label.text")}
                  </span>
                }
                key={this.TAB_KEYS.networks}
              >
                <NetworksList
                  params={{ node_id: this.props.nodeId }}
                  isCandidate={
                    this.props.currentInode.cluster_node_config
                      ? this.props.currentInode.cluster_node_config.is_candidate
                      : null
                  }
                  current_inode_cluster={
                    this.props.currentInode.cluster
                      ? this.props.currentInode.cluster.id
                      : null
                  }
                  orgId={this.props.orgId}
                  updateINodeDetails={this.props.updateINodeDetails}
                  updateServiceList={this.updateServiceList}
                  cameFromOrgDetailPage={
                    this.props.match.params.nodeId ? true : false
                  }
                  tabIndex={this.state.tabKey}
                  getNetworksByInterval={this.getNetworksByInterval}
                  isNetworkDataLoaded={this.state.isNetworkDataLoaded}
                  availableNetworks={this.state.availableNetworks}
                  {...this.props}
                  className={"iNodeNetworkList"}
                />
              </TabPane>
            )}

            {/* service templates implementation - start*/}

            {this.props.AuthStore.IsPermitted("SERVICE:VIEW") && (
              <TabPane
                tab={
                  <span>
                    <Icons type="fa" name="FaCogs" style={iconStyle} />{" "}
                    {getCurrentlocaleText(
                      "node.list.table.node_services.label.text",
                    )}
                  </span>
                }
                disabled={
                  this.state.isVirtualiNode &&
                  !(
                    this.props.AuthStore.IsPermitted("IOTIUM:ORG:ADMIN") ||
                    this.props.AuthStore.IsPermitted("ORG:SUPPORT")
                  )
                    ? true
                    : false
                }
                key={this.TAB_KEYS.servicetemplates}
              >
                <LoadingComponent loading={this.serviceModel.loading}>
                  <div>
                    <div
                      style={{
                        marginBottom: "10px",
                        display: "inline-flex",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      {this.serviceModel.services.length > 0 && (
                        <span>
                          <a
                            style={{
                              fontSize: 14,
                              marginLeft: "20px",
                              display: "inline-flex",
                            }}
                            onClick={this.expandRow}
                          >
                            <span>
                              <Icons
                                className={"valign-middle"}
                                type="ai"
                                name={
                                  this.state.expandedRowKeys.length !==
                                  this.serviceModel.services.length
                                    ? "AiOutlinePlusSquare"
                                    : "AiOutlineMinusSquare"
                                }
                              />
                              <span style={{ marginLeft: 5 }}>
                                {this.state.expandedRowKeys.length !==
                                this.serviceModel.services.length
                                  ? getCurrentlocaleText(
                                      "node.list.table.expand_all.label.text",
                                    )
                                  : getCurrentlocaleText(
                                      "node.list.table.collapse_all.label.text",
                                    )}
                              </span>
                            </span>
                          </a>
                        </span>
                      )}
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        {/*<SearchInput
                          placeholder="Filter services by name"
                          onChange={this.serviceListFilter}
                          value={this.state.searchText}
                          clearSearch={this.clearSearchFilter}
                        />*/}
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        {this.props.AuthStore.IsPermitted("SERVICE:CREATE") &&
                          !(
                            this.inodeModel.inode.cluster &&
                            this.inodeModel.inode.cluster.id
                          ) && (
                            <div>
                              <Button
                                title={
                                  this.isNetworkThere
                                    ? "Add Service"
                                    : "No network available to create services"
                                }
                                type="primary"
                                onClick={this.enableAppStore}
                                disabled={
                                  !this.isNetworkThere ||
                                  this.state.isVirtualiNode
                                }
                              >
                                <Icons type="ai" name="AiOutlinePlus" />
                              </Button>
                              {this.state.showAppStore && (
                                <Modal
                                  visible={this.state.showAppStore}
                                  onCancel={this.closeModal}
                                  width="70%"
                                  maskClosable={false}
                                  footer={this.appModalFooter()}
                                >
                                  <Row>
                                    <Col
                                      span={24}
                                      style={{ marginTop: "25px" }}
                                    >
                                      <AppStore {...this.props} />
                                    </Col>
                                  </Row>
                                </Modal>
                              )}
                            </div>
                          )}

                        {this.props.AuthStore.IsPermitted("SERVICE:CREATE") &&
                          !(
                            this.inodeModel.inode.cluster &&
                            this.inodeModel.inode.cluster.id
                          ) && (
                            <ActionBtn
                              title={
                                <span>
                                  <Icons type="ai" name="AiOutlineDelete" />{" "}
                                  {getCurrentlocaleText(
                                    "inode.list.table.delete.button.title.text",
                                    {
                                      0:
                                        this.state.selectedService.length > 1
                                          ? "Services"
                                          : "Service",
                                    },
                                  )}
                                </span>
                              }
                              HoverText={
                                <span>
                                  {getCurrentlocaleText(
                                    "inode.list.table.delete.button.hover.text",
                                    {
                                      0:
                                        this.state.selectedService.length > 1
                                          ? "Services"
                                          : "Service",
                                    },
                                  )}
                                </span>
                              }
                              resource="service"
                              action="delete"
                              disabled={_.isEmpty(this.state.selectedService)}
                              iconButton={true}
                              icon="AiOutlineDelete"
                              formData={this.state.selectedService}
                              afterCall={this.deleteAfterCall}
                              controller={ServiceController}
                              messageContent={
                                <span>
                                  <p>
                                    {getCurrentlocaleText(
                                      this.state.selectedService.length > 1
                                        ? "service.multiple.delete.confirmation.message.text"
                                        : "service.delete.confirmation.message.text",
                                    )}
                                  </p>
                                </span>
                              }
                              warningMessage={getCurrentlocaleText(
                                this.state.selectedService.length > 1
                                  ? "service.multiple.delete.warning.message.text"
                                  : "service.delete.warning.message.text",
                              )}
                              cancelText={getCurrentlocaleText(
                                "inode.delete.confirmation.cancel.text",
                                {
                                  0:
                                    this.state.selectedService.length > 1
                                      ? "Services"
                                      : "Service",
                                },
                              )}
                              okText={
                                <span>
                                  <Icons
                                    type="ai"
                                    name="AiOutlineDelete"
                                    style={{ margin: 0 }}
                                    className="iot-delete-icon"
                                  />
                                  {getCurrentlocaleText(
                                    "inode.delete.confirmation.ok.text",
                                    {
                                      0:
                                        this.state.selectedService.length > 1
                                          ? "Services"
                                          : "Service",
                                    },
                                  )}
                                </span>
                              }
                            >
                              <Icons type="ai" name="AiOutlineDelete" />{" "}
                              {getCurrentlocaleText(
                                "delete.confirmation.ok.text",
                              )}
                            </ActionBtn>
                          )}
                        {this.props.AuthStore.IsPermitted("SERVICE:CREATE") &&
                          !(
                            this.inodeModel.inode.cluster &&
                            this.inodeModel.inode.cluster.id
                          ) &&
                          parseFloat(getNodeVersion(this.inodeModel.inode)) <
                            RestartServiceBaseVersion && (
                            <Button
                              type=""
                              title="Reboot Services"
                              disabled={_.isEmpty(this.state.selectedService)}
                              onClick={() => {
                                notificationModal("info", {
                                  title: "Restart Services",
                                  data:
                                    "To restart the Services, please reboot the iNode.",
                                });
                              }}
                            >
                              <Icons
                                type="md"
                                name="MdRefresh"
                                style={{ fontSize: 16 }}
                              />
                            </Button>
                          )}
                      </div>
                    </div>
                    <div>
                      {this.state.showRestartmod && (
                        <Modal
                          title={getCurrentlocaleText(
                            "service.restart.title.text",
                          )}
                          visible={this.state.showRestartmod}
                          onCancel={this.closeServiceRestartModal}
                          onOk={this.handleRestart}
                          maskClosable={false}
                          closable={!this.state.loading}
                          okText={getCurrentlocaleText(
                            "service.restart.ok.text",
                          )}
                          cancelText={getCurrentlocaleText(
                            "service.restart.cancel.text",
                          )}
                        >
                          <span>
                            <h4 style={{ marginBottom: 15 }}>
                              <p>
                                {getCurrentlocaleText("service.restart.text1")}
                              </p>
                              <p>
                                <Tag className="tag-iotium-info">
                                  {record.name}
                                </Tag>
                              </p>
                              <p>
                                {getCurrentlocaleText("service.restart.text2")}
                              </p>
                            </h4>
                          </span>
                        </Modal>
                      )}
                      <Row />
                      <Row>
                        <Col span={24}>
                          {this.serviceModel.services.length > 0 ? (
                            <div>
                              <TableLayout
                                columns={this.ServiceTemplatesTablecolumns}
                                dataSource={this.serviceModel.serviceTableData}
                                pagination={false}
                                selectedRows={this.selectService.bind(null)}
                                selectAll={this.selectServiceAll.bind(null)}
                                rowClick={this.rowClick}
                                expandedRowRender={(
                                  record,
                                  index,
                                  intent,
                                  expanded,
                                ) => {
                                  return this.serviceModel.showExtraDetails(
                                    record.serviceData,
                                    index,
                                    intent,
                                    expanded,
                                  );
                                }}
                                expandedRowKeys={this.state.expandedRowKeys}
                                onExpand={this.onExpand}
                                className="iNodeServiceList"
                                hasLazyLoading={true}
                                lazyLoad={this.lazyLoad}
                                scroll={{ y: 200 }}
                              />
                            </div>
                          ) : (
                            <div>
                              {!this.serviceModel.loading && (
                                <span>
                                  {getCurrentlocaleText(
                                    "no.services.to.display",
                                  )}
                                </span>
                              )}
                            </div>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </LoadingComponent>
              </TabPane>
            )}

            <TabPane
              tab={
                <span>
                  <Icons type="tb" name="TbWorldPin" style={iconStyle} />
                  {getCurrentlocaleText("mac.table.tabheader.label.text")}
                </span>
              }
              key={this.TAB_KEYS.mactable}
            >
              <MacTable tabIndex={this.state.tabKey} {...this.props} />
            </TabPane>

            {/* Service Listener implementation - start*/}
            {this.props.AuthStore.IsPermitted("SERVICE:VIEW") &&
              isNodeSupportsListener &&
              this.state.shwListener && (
                <TabPane
                  tab={
                    <span>
                      <Icons
                        type="bs"
                        name="BsArrowLeftRight"
                        style={iconStyle}
                      />{" "}
                      {getCurrentlocaleText(
                        "node.list.table.node_service_listener.label.text",
                      )}
                    </span>
                  }
                  key={this.TAB_KEYS.listeners}
                  disabled={this.state.isVirtualEdgeiNode ? false : true}
                >
                  <LoadingComponent loading={this.serviceModel.loading}>
                    <div>
                      <div
                        style={{
                          marginBottom: "10px",
                          display: "inline-flex",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        {this.serviceListenerModel.listeners.length > 0 && (
                          <span>
                            <a
                              style={{
                                fontSize: 14,
                                marginLeft: "20px",
                                display: "inline-flex",
                              }}
                              onClick={this.expandListenerRow}
                            >
                              <span>
                                <Icons
                                  className={"valign-middle"}
                                  type="ai"
                                  name={
                                    this.state.expandedRowKeys.length !==
                                    this.serviceListenerModel.listeners.length
                                      ? "AiOutlinePlusSquare"
                                      : "AiOutlineMinusSquare"
                                  }
                                />
                                <span style={{ marginLeft: 5 }}>
                                  {this.state.expandedRowKeys.length !==
                                  this.serviceListenerModel.listeners.length
                                    ? getCurrentlocaleText(
                                        "node.list.table.expand_all.label.text",
                                      )
                                    : getCurrentlocaleText(
                                        "node.list.table.collapse_all.label.text",
                                      )}
                                </span>
                              </span>
                            </a>
                          </span>
                        )}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        ></div>
                        <div
                          style={{
                            marginLeft: "20px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              marginRight: "20px",
                            }}
                          >
                            <SearchInput
                              placeholder={getCurrentlocaleText(
                                "inodes.service_listener.filter_listener_by_name.text",
                              )}
                              onChange={this.serviceListenerListFilter}
                              value={this.state.searchText}
                              clearSearch={this.clearListenerSearchFilter}
                            />
                          </div>
                          {this.props.AuthStore.IsPermitted("SERVICE:CREATE") &&
                            !(
                              this.inodeModel.inode.cluster &&
                              this.inodeModel.inode.cluster.id
                            ) && (
                              <div>
                                <ActionRoute
                                  primaryButton
                                  actionIcon="AiOutlinePlus"
                                  title={"Add Listener"}
                                  route={
                                    this.getCurrentRoute() + "/listeners/modify"
                                  }
                                />
                              </div>
                            )}

                          {this.props.AuthStore.IsPermitted("SERVICE:CREATE") &&
                            !(
                              this.inodeModel.inode.cluster &&
                              this.inodeModel.inode.cluster.id
                            ) && (
                              <ActionBtn
                                title={
                                  <span>
                                    <Icons type="ai" name="AiOutlineDelete" />{" "}
                                    Delete Listeners
                                  </span>
                                }
                                resource="lister"
                                action="delete"
                                disabled={_.isEmpty(
                                  this.state.selectedServiceListener,
                                )}
                                iconButton={true}
                                icon="AiOutlineDelete"
                                formData={this.state.selectedServiceListener}
                                afterCall={this.deleteListenerAfterCall}
                                controller={ServiceListenerController}
                                cancelText={getCurrentlocaleText(
                                  "delete.confirmation.cancel.text",
                                )}
                              >
                                <Icons type="ai" name="AiOutlineDelete" />{" "}
                                {getCurrentlocaleText(
                                  "delete.confirmation.ok.text",
                                )}
                              </ActionBtn>
                            )}
                        </div>
                      </div>
                      <div>
                        <Row />
                        <Row>
                          <Col span={24}>
                            {this.serviceListenerModel.listeners.length > 0 ? (
                              <div>
                                <TableLayout
                                  columns={this.ServiceListenerTablecolumns}
                                  dataSource={
                                    this.serviceListenerModel.listenerTableData
                                  }
                                  pagination={false}
                                  selectedRows={this.selectServiceListener}
                                  selectAll={this.selectServiceListenerAll}
                                  expandedRowRender={record => {
                                    return this.showExtraDetails(record);
                                  }}
                                  expandedRowKeys={this.state.expandedRowKeys}
                                  onExpand={this.onExpand}
                                  className="iNodeServiceListenerList"
                                  hasLazyLoading={true}
                                  lazyLoad={this.lazyLoad}
                                  scroll={{ y: 200 }}
                                />
                              </div>
                            ) : (
                              <div>
                                {!this.serviceListenerModel.loading && (
                                  <span>
                                    {getCurrentlocaleText(
                                      "no.listeners.to.display",
                                    )}
                                  </span>
                                )}
                              </div>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </LoadingComponent>
                </TabPane>
              )}
            {
              <TabPane
                tab={
                  <span>
                    <Icons type="fa" name="FaRegFileArchive" />
                    {getCurrentlocaleText("inodes.images.tabheader.link.text")}
                  </span>
                }
                key={this.TAB_KEYS.images}
                disabled={this.state.isVirtualiNode ? true : false}
              >
                <InodeImageList
                  nodeId={
                    this.props.match.params.nodeId
                      ? this.props.match.params.nodeId
                      : this.props.nodeId
                  }
                  showMoreImages={true}
                />
              </TabPane>
            }
            <TabPane
              tab={
                <span>
                  <Icons type="md" name="MdDevicesOther" />
                  {getCurrentlocaleText("inode.interface.label.text")}{" "}
                </span>
              }
              key={this.TAB_KEYS.interfaces}
            >
              <Card>
                {this.state.tabKey === this.TAB_KEYS.interfaces && (
                  <InodeInterfaces
                    nodeId={
                      this.props.match.params.nodeId
                        ? this.props.match.params.nodeId
                        : this.props.nodeId
                    }
                    currentInode={this.props.currentInode}
                  />
                )}
              </Card>
            </TabPane>
            <TabPane
              tab={
                <span>
                  <Icons type="md" name="MdEvent" />{" "}
                  <Icons type="fa" name="FaCog" className="event-tab-icon" />
                  {getCurrentlocaleText("events.text")}
                </span>
              }
              key={this.TAB_KEYS.events}
            >
              {this.state.showEvents && (
                <InodeEventList
                  nodeId={
                    this.props.match.params.nodeId
                      ? this.props.match.params.nodeId
                      : this.props.nodeId
                  }
                />
              )}
            </TabPane>
            {(this.props.AuthStore.IsPermitted("NODE:CREATE") ||
              this.props.AuthStore.IsPermitted("ORG:SUPPORT")) && (
              <TabPane
                tab={
                  <span>
                    <Icons type="md" name="MdEventNote" />
                    <Icons
                      type="md"
                      name="MdPerson"
                      className="activity-tab-icon"
                    />{" "}
                    {getCurrentlocaleText("activity.inode.text")}
                  </span>
                }
                key={this.TAB_KEYS.activities}
              >
                {this.state.showActivities && (
                  <InodeActivityList
                    params={{ resource_id: this.props.nodeId }}
                  />
                )}
              </TabPane>
            )}
            {this.props.AuthStore.IsPermitted("UPGRADE_HISTORY:VIEW") && (
              <TabPane
                tab={
                  <span>
                    <Icons type="fa" name="FaRegCalendarCheck" />
                    Upgrade History
                  </span>
                }
                key={this.TAB_KEYS.upgradehistorys}
              >
                {this.state.showUpgradeHistorys && (
                  <InodeUpgradeHistoryList
                    nodeId={
                      this.props.match.params.nodeId
                        ? this.props.match.params.nodeId
                        : this.props.nodeId
                    }
                  />
                )}
              </TabPane>
            )}
          </RouteTab>
        </Card>
      </div>
    );
  }
}

export default NetworkServiceTab;
