import React, { Component } from "react";
import { Row, Col, Button } from "antd";
import { getIP, getCIDRdata, getCurrentlocaleText } from "Core/Utils";
import propTypes from "prop-types";
import Icons from "components/UI-Components/Icons";
import Logos from "components/UI-Components/Logos";
import InodeController from "controller/InodeController";

class RouteInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viNodeDetails: null,
      tanCIDR: this.props.tanCIDR,
      _isMounted: false,
    };
  }

  componentDidMount() {
    this.setState({ tanCIDR: this.props.tanCIDR, _isMounted: true });
    if (!this.props.viNodeDetails)
      this.updateVirtualNodeInfo(this.props.ViNodeId);
  }

  UNSAFE_componentWillReceiveProps(nextprops) {
    if (this.props.ViNodeId != nextprops.ViNodeId) {
      if (!this.props.viNodeDetails) {
        this.updateVirtualNodeInfo(nextprops.ViNodeId);
        if (this.state._isMounted)
          this.setState({ tanCIDR: nextprops.tanCIDR });
      }
    }
  }

  updateVirtualNodeInfo(nodeId) {
    if (
      nodeId &&
      nodeId !== (this.state.viNodeDetails && this.state.viNodeDetails.id)
    ) {
      InodeController.getInodeById(nodeId).then(response => {
        if (this.state._isMounted) this.setState({ viNodeDetails: response });
      });
    }
    if (this.state._isMounted) this.setState({ tanCIDR: this.props.tanCIDR });
  }

  componentWillUnmount() {
    this.setState({ _isMounted: false });
  }

  copyToClipboard(content) {
    navigator.clipboard.writeText(content);
  }

  render() {
    let StartAdress = "NA",
      SubnetMask = "NA",
      viNodeDetails = this.props.viNodeDetails
        ? this.props.viNodeDetails
        : this.state.viNodeDetails;
    if (this.props.tanCidr) {
      let tanCidrData = this.props.isVirtual
        ? getCIDRdata(this.props.remoteCidr)
        : getCIDRdata(this.props.tanCidr);
      if (
        this.props.remoteInterfaceIP &&
        this.props.remoteInterfaceIP.split("/") &&
        this.props.remoteInterfaceIP.split("/")[0] &&
        this.props.remoteInterfaceIP &&
        this.props.isVirtual
      ) {
        StartAdress = this.props.remoteInterfaceIP.split("/")[0];
      } else {
        if (
          this.props.localInterfaceIP &&
          this.props.localInterfaceIP.split("/") &&
          this.props.localInterfaceIP.split("/")[0] &&
          this.props.localInterfaceIP &&
          !this.props.isVirtual
        ) {
          StartAdress = this.props.localInterfaceIP.split("/")[0];
        }
      }
      if (tanCidrData) {
        // SubnetMask
        if (tanCidrData.subnetMask) {
          SubnetMask = tanCidrData.subnetMask;
        }
      }
    }
    let VipAddress = null;
    if (viNodeDetails) VipAddress = getIP(viNodeDetails, "private");
    let privateAddress = "<Virtual-iNode-Private-IP>";
    if (
      VipAddress &&
      VipAddress.split("/") &&
      VipAddress.split("/")[0] &&
      viNodeDetails
    ) {
      privateAddress = VipAddress.split("/")[0];
    }
    let returnVal = "";
    if (this.props.connType === "localToRemote") {
      returnVal = (
        <div className="route-info">
          <Row gutter={16}>
            <Col>
              <span>
                {getCurrentlocaleText("route-info-body-message1-reverse")}
              </span>
              <br />
            </Col>
            <Col style={{ paddingTop: 8 }}>
              <span>
                {getCurrentlocaleText("route-info-body-message2-reverse")}
              </span>
              <br />
            </Col>
            <Col style={{ paddingTop: 8 }}>
              <span>
                {getCurrentlocaleText("route-info-body-message3-reverse")}
              </span>
              <br />
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }} gutter={16}>
            <Col className="route-infolabel" span={24}>
              <Logos
                type="fa"
                name="FaWindows"
                style={{
                  fontSize: 25,
                  color: "#00aef0",
                }}
                lableStyle={{
                  color: "#00aef0",
                  marginLeft: 5,
                  fontFamily: "Segoe",
                  fontSize: "initial",
                }}
                label={"Windows"}
              />
            </Col>
            <Col className="route-infocard" span={24}>
              <code className="route-info-text">
                {"> route add " +
                  (this.props.isVirtual
                    ? this.props.tanCidr
                      ? this.props.tanCidr
                      : this.state.tanCIDR
                    : this.props.remoteCidr) +
                  " mask " +
                  SubnetMask +
                  " " +
                  StartAdress}
                <Button
                  type="link"
                  className="p-0 ml-15"
                  onClick={() => {
                    this.copyToClipboard(
                      "route add " +
                        (this.props.isVirtual
                          ? this.props.tanCidr
                            ? this.props.tanCidr
                            : this.state.tanCIDR
                          : this.props.remoteCidr) +
                        " mask " +
                        SubnetMask +
                        " " +
                        StartAdress,
                    );
                  }}
                >
                  <Icons
                    type="ai"
                    name="AiOutlineCopy"
                    className={"baseline iot-copy-icon-style"}
                  />
                </Button>
              </code>
            </Col>
            <Col className="route-infolabel" span={24}>
              <Logos
                type="fa"
                name="FaLinux"
                style={{
                  fontSize: 25,
                }}
                lableStyle={{
                  marginLeft: 5,
                  fontSize: "initial",
                  fontWeight: 600,
                }}
                label={"Linux"}
              />
            </Col>
            <Col span={24} className="route-infocard">
              <code className="route-info-text">
                {"$ sudo route add -net " +
                  (this.props.isVirtual
                    ? this.props.tanCidr
                      ? this.props.tanCidr
                      : this.state.tanCIDR
                    : this.props.remoteCidr) +
                  " gw " +
                  StartAdress}
                <Button
                  type="link"
                  className="p-0 ml-15"
                  onClick={() => {
                    this.copyToClipboard(
                      "sudo route add -net " +
                        (this.props.isVirtual
                          ? this.props.tanCidr
                            ? this.props.tanCidr
                            : this.state.tanCIDR
                          : this.props.remoteCidr) +
                        " gw " +
                        StartAdress,
                    );
                  }}
                >
                  <Icons
                    type="ai"
                    name="AiOutlineCopy"
                    className={"baseline iot-copy-icon-style"}
                  />
                </Button>
              </code>
            </Col>
          </Row>
        </div>
      );
    } else {
      returnVal = (
        <div className="route-info">
          <Row gutter={16}>
            <Col>
              <span>{getCurrentlocaleText("route-info-body-message1")}</span>
              <br />
            </Col>
            <Col style={{ paddingTop: 8 }}>
              <span>{getCurrentlocaleText("route-info-body-message2")}</span>
              <br />
            </Col>
            <Col style={{ paddingTop: 8 }}>
              <span>{getCurrentlocaleText("route-info-body-message3")}</span>
              <br />
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }} gutter={16}>
            <Col className="route-infolabel" span={24}>
              <Logos
                type="fa"
                name="FaWindows"
                style={{
                  fontSize: 25,
                  color: "#00aef0",
                }}
                lableStyle={{
                  color: "#00aef0",
                  marginLeft: 5,
                  fontFamily: "Segoe",
                  fontSize: "initial",
                }}
                label={"Windows"}
              />
            </Col>
            <Col className="route-infocard" span={24}>
              <code className="route-info-text">
                {"> route add " +
                  (!this.props.isVirtual
                    ? this.props.localRepnet
                      ? this.props.localRepnet
                      : this.props.tanCidr
                    : this.props.peerRepnet
                    ? this.props.peerRepnet
                    : this.props.remoteCidr) +
                  " mask " +
                  SubnetMask +
                  " " +
                  privateAddress}
                <Button
                  type="link"
                  className="p-0 ml-15"
                  onClick={() => {
                    this.copyToClipboard(
                      "route add " +
                        (!this.props.isVirtual
                          ? this.props.localRepnet
                            ? this.props.localRepnet
                            : this.props.tanCidr
                          : this.props.peerRepnet
                          ? this.props.peerRepnet
                          : this.props.remoteCidr) +
                        " mask " +
                        SubnetMask +
                        " " +
                        privateAddress,
                    );
                  }}
                >
                  <Icons
                    type="ai"
                    name="AiOutlineCopy"
                    className={"baseline iot-copy-icon-style"}
                  />
                </Button>
              </code>
            </Col>
            <Col className="route-infolabel" span={24}>
              <Logos
                type="fa"
                name="FaLinux"
                style={{
                  fontSize: 25,
                }}
                lableStyle={{
                  marginLeft: 5,
                  fontSize: "initial",
                  fontWeight: 600,
                }}
                label={"Linux"}
              />
            </Col>
            <Col span={24} className="route-infocard">
              <code className="route-info-text">
                {"$ sudo route add -net " +
                  (!this.props.isVirtual
                    ? this.props.localRepnet
                      ? this.props.localRepnet
                      : this.props.tanCidr
                    : this.props.peerRepnet
                    ? this.props.peerRepnet
                    : this.props.remoteCidr) +
                  " gw " +
                  privateAddress}
                <Button
                  type="link"
                  className="p-0 ml-15"
                  onClick={() => {
                    this.copyToClipboard(
                      "sudo route add -net " +
                        (!this.props.isVirtual
                          ? this.props.localRepnet
                            ? this.props.localRepnet
                            : this.props.tanCidr
                          : this.props.peerRepnet
                          ? this.props.peerRepnet
                          : this.props.remoteCidr) +
                        " gw " +
                        privateAddress,
                    );
                  }}
                >
                  <Icons
                    type="ai"
                    name="AiOutlineCopy"
                    className={"baseline iot-copy-icon-style"}
                  />
                </Button>
              </code>
            </Col>
          </Row>
        </div>
      );
    }

    return returnVal;
  }
}

RouteInfo.propTypes = {
  ViNodeId: propTypes.string.isRequired,
  tanCIDR: propTypes.string.isRequired,
};

export default RouteInfo;
