import React, { Component } from "react";
import { Row, Col, Dropdown, Button, Menu } from "antd";
import { observer, inject } from "mobx-react";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import BreadcrumbComponent from "components/UI-Components/BreadcrumbComponent";
import Icons from "components/UI-Components/Icons";
import OrgSelector from "components/Orgs/OrgSelector";
import OrgController from "controller/OrgController";
import ClusterController from "controller/ClusterController";
import ClusterInfo from "components/Clusters/ClusterInfo";
import ClusterTabs from "components/Clusters/ClusterTabs";
import ActionRoute from "Core/API/ActionRoute";
import ActionBtn from "Core/API/ActionBtn";
import InodeController from "controller/InodeController";
import { getCurrentlocaleText, copyObject } from "Core/Utils";
import { Link } from "react-router-dom";
@inject("AuthStore", "UiStore", "ClusterViewModel")
@observer
class ClusterDetails extends Component {
  constructor(props) {
    super(props);
    this.clusterModel = this.props.ClusterViewModel;
    this.nodeApiByInterval = null;
    this.clusterApiByInterval = null;
    this.state = {
      currentOrgDetails: {},
      BreadcrumbList: [],
      currentClusterDetails: {},
      ClusterediNodeList: [],
      query_params: {},
      showMasterWarning: false,
      isMasterUnAvailable: false,
      loading: true,
    };
    this.color = {};
  }

  toggleHover = (eventKey, domEvent) => {
    // removing default hover background color
    if (eventKey.domEvent) {
      let target = eventKey.domEvent.target;
      target.style.background = "#fff";
    }
  };

  componentDidMount() {
    OrgController.getOrg(
      this.props.match.params.id,
      false,
      this.props.UiStore.parentOrgData && this.props.UiStore.parentOrgData.id
        ? false
        : true,
    ).then(res => {
      this.updateClusterDetails(res);
      /*API CALL EVERY 30 SEC Intiated HERE */
      clearInterval(this.clusterApiByInterval);
      this.clusterApiByInterval = setInterval(() => {
        this.updateClusterDetails(undefined, false);
      }, 30000);
    });
  }

  componentWillUnmount() {
    clearInterval(this.nodeApiByInterval);
    clearInterval(this.clusterApiByInterval);
    this.props.ClusterViewModel.resetClusteriNodes();
  }

  getNodesByInterval = cluster_id => {
    clearInterval(this.nodeApiByInterval);

    this.nodeApiByInterval = setInterval(() => {
      let query_params = this.state.query_params;
      this.fetchInodeList(cluster_id, query_params);
    }, 30000);
  };
  updateClusterDetails = (orgInfo, loading = true) => {
    ClusterController.getCluster(this.props.match.params.cluster_id, loading)
      .then(result => {
        if (orgInfo) {
          let BreadcrumbList = [];
          BreadcrumbList.push(
            {
              name: orgInfo.name,
              link: `orgs/${orgInfo.id}/orgs`,
            },
            {
              name: getCurrentlocaleText("clusters.navigation_menu.text"),
              link: `orgs/${orgInfo.id}/clusters`,
            },
            {
              name: result.name,
              link: `orgs/${orgInfo.id}/clusters/` + result.id,
            },
          );

          this.setState({
            BreadcrumbList: BreadcrumbList,
            currentOrgDetails: orgInfo,
            currentClusterDetails: result,
          });
        } else {
          this.setState({
            currentClusterDetails: result,
          });
        }
        this.fetchInodeList(result.id);
        /*API CALL every 1 Minute Intiated HERE*/
        this.getNodesByInterval(result.id);
      })
      .catch(error => {
        // LAT-13252 redirects to cluster page if unknown cluster route hits
        if (
          this.props.match &&
          this.props.match.url &&
          this.props.match.url.split("/") &&
          this.props.match.url.split("/").length > 0
        )
          this.props.history.push(
            this.props.match.url.replace(
              "/" +
                this.props.match.url.split("/")[
                  this.props.match.url.split("/").length - 1
                ],
              "",
            ),
          );
        else this.props.history.push("/home");
      });
  };

  fetchInodeList = (
    cluster_id = this.props.match.params.cluster_id,
    query_params = {},
  ) => {
    query_params.cluster_id = cluster_id
      ? cluster_id
      : this.props.match.params.cluster_id;
    query_params.size = 100;
    this.setState({ query_params: copyObject(query_params) });
    InodeController.getInodes(copyObject(query_params), false, true)
      .then(result => {
        this.computeClusterediNodes(copyObject(result));
        this.setState({ loading: false });
      })
      .catch(error => {
        this.setState({ loading: false });
      });
  };
  computeClusterediNodes = inodes => {
    this.props.ClusterViewModel.clustered_inodes = inodes;
    let ClusterediNodeList = this.props.ClusterViewModel.getClusterediNodeList;
    let clustered_nodes = [];
    let master_count = 0;
    let nodes =
      this.state.currentClusterDetails && this.state.currentClusterDetails.nodes
        ? this.state.currentClusterDetails.nodes
        : [];
    let isMasterUnAvailable = false;
    if (ClusterediNodeList.length > 0) {
      ClusterediNodeList.forEach(list_info => {
        let info = {};
        let currentInodeConfig = nodes.filter(n => {
          if (list_info.id === n.node.id) return n;
        });
        if (currentInodeConfig.length > 0) {
          let updated_data = currentInodeConfig[0];
          info.priority =
            updated_data && updated_data.config && updated_data.config.priority
              ? updated_data.config.priority
              : null;
          info.is_candidate =
            updated_data &&
            updated_data.config &&
            updated_data.config.is_candidate
              ? updated_data.config.is_candidate
              : false;
          // is master check
          info.is_master =
            list_info.details &&
            list_info.details.status &&
            list_info.details.status.node &&
            list_info.details.status.node.node_cluster_status &&
            list_info.details.status.node.node_cluster_status.state &&
            list_info.details.status.node.node_cluster_status.state === "MASTER"
              ? true
              : false;
          info.details = list_info.details;
          info.id = list_info.id;
          info.key = list_info.id;
          info.name = list_info.name;
          info.status = list_info.status;
          // check for dual master
          if (info.is_master && info.is_candidate) {
            master_count = master_count + 1;
          }
          if (master_count === 0) {
            isMasterUnAvailable = true;
          } else {
            isMasterUnAvailable = false;
          }
          clustered_nodes.push(copyObject(info));
        }
      });
    }
    this.setState({
      ClusterediNodeList: copyObject(clustered_nodes),
      showMasterWarning: master_count > 1 ? true : false,
      isMasterUnAvailable: isMasterUnAvailable,
    });
  };
  deleteAfterCall = resp => {
    /*Required to clear interval after the cluster deleted */
    clearInterval(this.nodeApiByInterval);
    clearInterval(this.clusterApiByInterval);
    // including timeout to show notification message before redirect
    setTimeout(() => {
      let url = window.location.pathname.split("/"),
        updatedurl = "/orgs/" + url[2] + "/clusters";
      this.props.history.push(updatedurl);
    }, 3000);
  };

  render() {
    let currentClusterDetails = this.clusterModel.cluster;
    let ClusterediNodeStatus = this.props.ClusterViewModel
      .getClusterediNodeStatus;

    let ClusterediNodeList = this.state.ClusterediNodeList;
    {
      /* Manage Cluster Options */
    }
    let editUrl =
      "/orgs/" +
      this.props.match.params.id +
      "/clusters/edit/" +
      this.props.match.params.cluster_id;
    let clusterManageMenu = (
      <Menu style={{ marginTop: -6 }}>
        <Menu.Item
          key="0"
          style={this.color}
          onMouseEnter={this.toggleHover}
          className="iot-manage-dropdown"
        >
          <ActionRoute
            actionIcon="AiOutlineEdit"
            actionLabel={getCurrentlocaleText("general.edit.text")}
            title={getCurrentlocaleText(
              "cluster.form.useraction_edit.label.text",
            )}
            route={{
              pathname: this.props.AuthStore.IsPermitted("CLUSTER:CREATE")
                ? editUrl
                : "",
              state: {
                formValues: this.state.currentClusterDetails,
                isFromClusterDetails: true,
              },
            }}
            disabled={!this.props.AuthStore.IsPermitted("CLUSTER:CREATE")}
            className="iot-manage-dropdown-actbtn"
          />
        </Menu.Item>
        <Menu.Item
          key="1"
          style={this.color}
          onMouseEnter={this.toggleHover}
          className="iot-manage-dropdown"
        >
          <ActionRoute
            actionIcon="AiOutlineEye"
            actionLabel={getCurrentlocaleText("general.view.text")}
            title={getCurrentlocaleText("view.cluster.text")}
            route={{
              pathname:
                "/orgs/" +
                this.props.match.params.id +
                "/clusters/view/" +
                this.props.match.params.cluster_id,
              state: {
                formValues: this.state.currentClusterDetails,
                viewOnly: true,
                isFromClusterDetails: true,
              },
            }}
            iconBtnStyle={{ fontSize: 14 }}
            className="iot-manage-dropdown-actbtn"
          />
        </Menu.Item>
        <Menu.Item
          key="2"
          style={this.color}
          onMouseEnter={this.toggleHover}
          className="iot-manage-dropdown"
        >
          <ActionBtn
            title={
              <span>
                <Icons type="ai" name="AiOutlineDelete" />{" "}
                {getCurrentlocaleText(
                  "cluster.list.table.delete_cluster.button.hover.text",
                )}
              </span>
            }
            HoverText={
              <span>
                {getCurrentlocaleText(
                  "cluster.list.table.delete_cluster.button.title.text",
                )}
              </span>
            }
            orgId={this.props.match.params.id}
            icon="AiOutlineDelete"
            formData={this.state.currentClusterDetails}
            map={{ id: this.state.currentClusterDetails.id }}
            action="delete"
            okText={getCurrentlocaleText("delete.confirmation.ok.text")}
            disabled={!this.props.AuthStore.IsPermitted("CLUSTER:DELETE")}
            controller={ClusterController}
            afterCall={this.deleteAfterCall}
            cancelText={getCurrentlocaleText("delete.confirmation.cancel.text")}
            className="iot-manage-dropdown-actbtn"
          >
            <Icons
              className="valign-sub text-top"
              type="ai"
              name="AiOutlineDelete"
            />{" "}
            {getCurrentlocaleText("delete.text")}
          </ActionBtn>
        </Menu.Item>

        {/* Restrict for DAD */}
        {currentClusterDetails &&
          currentClusterDetails.duplicate_address_detection &&
          currentClusterDetails.duplicate_address_detection.enable == true &&
          currentClusterDetails.nodes &&
          currentClusterDetails.nodes.length > 0 && (
            <Menu.Item
              key="7"
              style={this.color}
              className="iot-manage-dropdown"
              onMouseEnter={this.toggleHover.bind(null)}
            >
              <Link
                to={
                  "/orgs/" +
                  this.props.match.params.id +
                  "/clusters/" +
                  this.props.match.params.cluster_id +
                  "/clusterdads"
                }
              >
                <Button
                  style={{ border: "none", position: "relative", left: 5 }}
                  className="iot-manage-dropdown-actbtn"
                  title={getCurrentlocaleText("dad.text")}
                >
                  <Icons
                    type="hi"
                    name="HiOutlineDocumentDuplicate"
                    style={{ fontSize: 16 }}
                  />
                  <span>{getCurrentlocaleText("dad.text")}</span>
                </Button>
              </Link>
            </Menu.Item>
          )}
      </Menu>
    );

    let container = (
      <div>
        <Row gutter={16} type="flex" align="middle">
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <h2 className="page-title">
              <Icons type="bs" name="BsBoundingBox" />
              {"  "}
              {currentClusterDetails.name ? currentClusterDetails.name : null}
            </h2>
            <BreadcrumbComponent
              {...this.props}
              BreadcrumbList={this.state.BreadcrumbList}
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            className="flex flex-h-right pr-0"
          >
            <Row gutter={16} type="flex" className="pr-0">
              <Col
                span={24}
                className="flex flex-h-left d-flex flex-justify-right"
              >
                <div className="page-actions inode-action-card">
                  <Dropdown overlay={clusterManageMenu} trigger={["hover"]}>
                    <Button style={{ height: 46, border: "none", width: 260 }}>
                      Manage Cluster <Icons type="ai" name="AiOutlineDown" />
                    </Button>
                  </Dropdown>
                </div>
                <OrgSelector
                  {...this.props}
                  className="orgslector-ellipse-menu"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <div style={{ minHeight: 360 }}>
          <Row gutter={16}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className="card-grid iot-details-card-col"
            >
              {!this.clusterModel.loading && !this.state.loading && (
                <ClusterInfo
                  clusterDetails={this.state.currentClusterDetails}
                  ClusterediNodeStatus={ClusterediNodeStatus}
                  {...this.props}
                  clusterId={this.props.match.params.cluster_id}
                />
              )}
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className="card-grid iot-details-card-col"
            >
              <ClusterTabs
                {...this.props}
                currentClusterDetails={this.state.currentClusterDetails}
                cluster_id={this.props.match.params.cluster_id}
                ClusterediNodeList={ClusterediNodeList}
                fetchInodeList={this.fetchInodeList}
                showMasterWarning={this.state.showMasterWarning}
                isMasterUnAvailable={this.state.isMasterUnAvailable}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={10} className="card-grid"></Col>
            <Col span={14} className="card-grid"></Col>
          </Row>
        </div>
      </div>
    );

    return (
      <LoadingComponent
        loading={this.clusterModel.loading || this.state.loading}
      >
        {container}
      </LoadingComponent>
    );
  }
}

export default ClusterDetails;
