import React, { Component } from "react";
import { Layout, Avatar } from "antd";
import Icons from "../UI-Components/Icons";

const { Header } = Layout;
// const SubMenu = Menu.SubMenu;

class IoHeader extends Component {
  render() {
    return (
      <Header
        className="flex flex-justify-right"
        style={{ background: "#fff", padding: 0 }}
      >
        <Avatar
          title="Log out"
          style={{
            backgroundColor: "#ECECEC",
            marginRight: 10,
            marginTop: 5,
            cursor: "pointer",
          }}
          icon={<Icons type="ai" name="AiOutlineLogout" />}
        />
      </Header>
    );
  }
}

export default IoHeader;
