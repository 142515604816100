import React, { Component } from "react";
import { Link } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { Row, Col, Steps, Button } from "antd";
import { Form } from "@ant-design/compatible";
import { getCurrentlocaleText } from "Core/Utils";
import HelpCard from "components/UI-Components/HelpCard";
import WebhookTestForm from "components/Webhooks/WebhookTestForm";
import WebhookConfig from "components/Webhooks/WebhookConfig";
import WebhookInfoDisplay from "components/Webhooks/WebhookInfoDisplay";
import "../../assets/styles/Alert.css";
import Icons from "../UI-Components/Icons";

const Step = Steps.Step;
const steps = [
  {
    title: getCurrentlocaleText("inode.interface.name.label.text"),
  },
  {
    title: getCurrentlocaleText("webhook.config.text"),
  },
  {
    title: getCurrentlocaleText("webhook.test_verify.text"),
  },
];

@inject("UiStore")
@observer
class WebhookStepForm extends Component {
  constructor(props) {
    super(props);
    this.UiStore = this.props.UiStore;
    this.state = {
      current: 0,
      isSubmitting: false,
      currrentWebhookData: this.props.currrentWebhookData,
      webhookId: this.props.webhookId,
    };
  }

  validateForm = () => {
    // Here Values is final step form values
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let webhookFormData = this.UiStore.webhookFormData,
          formData = Object.assign(
            {},
            webhookFormData.genInfo,
            webhookFormData.test,
            webhookFormData.configuration,
            values,
          );
        formData.headers = webhookFormData.configuration.headers
          ? webhookFormData.configuration.headers
          : [];
        // deleet for default secret from payload
        if (formData.secret && this.props.webhookId) {
          if (
            formData.secret === "" ||
            formData.secret === this.props.webhookId
          ) {
            delete formData.secret;
          }
        }
        this.props.onSubmit(formData);
        this.moveToNext();
      }
    });
  };

  moveToNext = () => {
    let current = this.state.current;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        //First step (General Info)
        if (current === 0) {
          this.UiStore.webhookFormData.genInfo = values;
          this.UiStore.webhookFormData.configuration = {};
        }
        // Second step (Config)
        if (current === 1) {
          values.headers = this.UiStore.webhookFormData.configuration.headers;
          delete values.headers_value;
          this.UiStore.webhookFormData.configuration = values;
          this.UiStore.webhookFormData.test = {};
        }
        // Third step (Test)
        if (current === 2) {
          this.UiStore.webhookFormData.test = values;
        }
        current += 1;
        this.setState({ current });
      }
    });
  };

  moveToPrevious = () => {
    let current = this.state.current;
    current -= 1;
    this.setState({ current });
  };

  render() {
    const { current } = this.state;
    return (
      <div>
        <Form
          noValidate
          onSubmit={this.props.onSubmit}
          className="webhook-form"
        >
          {/*Steps*/}
          <Row gutter={2} type="flex" align="top">
            <Col span={24}>
              <Steps current={current}>
                {steps.map(item => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
            </Col>
          </Row>
          <Row
            gutter={2}
            type="flex"
            align="top"
            className="webhook-steps-contianer"
          >
            <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 13 }}
              lg={{ span: 13 }}
              xl={{ span: 12 }}
              className="card-grid"
            >
              <div className="webhook-steps-contianer-col">
                {current === 0 && (
                  <WebhookInfoDisplay
                    formReference={this.props.form}
                    currrentWebhookData={this.props.currrentWebhookData}
                    webhookId={this.props.webhookId}
                  />
                )}
                {current === 1 && (
                  <WebhookConfig
                    formReference={this.props.form}
                    currrentWebhookData={this.props.currrentWebhookData}
                    webhookId={this.props.webhookId}
                  />
                )}
                {current === 2 && (
                  <WebhookTestForm
                    formReference={this.props.form}
                    currrentWebhookData={this.props.currrentWebhookData}
                    webhookId={this.props.webhookId}
                  />
                )}
              </div>
            </Col>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 11 }}
              lg={{ span: 11 }}
              xl={{ span: 12 }}
            >
              <HelpCard
                customStyle={{
                  borderLeft: "none",
                }}
                span={24}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24} className="col-center">
              <div className="webhook-steps-contianer">
                {current === 2 ? (
                  <Link to="/managewebhooks">
                    <Button
                      className="webhook-steps-contianer-btn"
                      disabled={this.state.isSubmitting}
                      title={getCurrentlocaleText("general.done.text")}
                    >
                      {getCurrentlocaleText("general.done.text")}
                    </Button>
                  </Link>
                ) : (
                  <Link to="/managewebhooks">
                    <Button
                      className="webhook-steps-contianer-btn"
                      disabled={this.state.isSubmitting}
                      title={getCurrentlocaleText("cancel.button.text")}
                    >
                      {getCurrentlocaleText("cancel.button.text")}
                    </Button>
                  </Link>
                )}
                {current !== 0 && current <= steps.length - 1 && (
                  <Button
                    className="webhook-steps-contianer-btn"
                    onClick={this.moveToPrevious}
                    disabled={this.state.isSubmitting}
                    title={getCurrentlocaleText("previous.button.text")}
                  >
                    {getCurrentlocaleText("previous.button.text")}
                  </Button>
                )}
                {current < steps.length - 1 && current != 1 && (
                  <Button
                    type="primary"
                    onClick={this.moveToNext}
                    title={getCurrentlocaleText("next.button.text")}
                  >
                    {getCurrentlocaleText("next.button.text")}
                  </Button>
                )}
                {current === 1 && (
                  <Button
                    type="primary"
                    onSubmit={this.validateForm}
                    onClick={this.validateForm}
                    loading={this.state.isSubmitting}
                    title={
                      this.props.webhookId
                        ? getCurrentlocaleText("update.text")
                        : getCurrentlocaleText("save.button.text")
                    }
                  >
                    <Icons
                      type="ai"
                      name="AiOutlineSave"
                      className="no-margin"
                    />{" "}
                    {this.props.webhookId
                      ? getCurrentlocaleText("update.text")
                      : getCurrentlocaleText("save.button.text")}
                  </Button>
                )}
                {current === 2 && (
                  <Button
                    type="primary"
                    onClick={
                      !this.props.currrentWebhookData.is_enabled
                        ? this.props.verifyChannel
                        : this.props.checkChannel
                    }
                    loading={this.state.isSubmitting}
                  >
                    <Icons
                      type="ai"
                      name="AiOutlineCheckCircle"
                      className="no-margin"
                    />{" "}
                    {this.props.currrentWebhookData.is_enabled
                      ? getCurrentlocaleText("webhook.channel.test.text")
                      : getCurrentlocaleText("webhook.verify.test.text")}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

const WebhookStepsForm = Form.create()(WebhookStepForm);
export default WebhookStepsForm;
