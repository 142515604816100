import React, { Component } from "react";
import { Card, Row, Col } from "antd";
import { observer, inject } from "mobx-react";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import BreadcrumbComponent from "components/UI-Components/BreadcrumbComponent";
import Icons from "components/UI-Components/Icons";

import ListenersFormPage from "components/Forms/ListenersFormPage";
import { getCurrentlocaleText } from "Core/Utils";
import NetworkContainer from "./NetworkContainer";
import OrgSelector from "components/Orgs/OrgSelector";

import InodeController from "controller/InodeController";
import ProfileController from "controller/ProfileController";
import ServiceListenerController from "controller/ServiceListenerController";
import ServiceController from "controller/ServiceController";

@inject(
  "ModelStore",
  "AuthStore",
  "UiStore",
  "InodeViewModel",
  "ServiceListenerViewModel",
  "ServiceViewModel",
  "ClusterViewModel",
)
@observer
class ListenerDetails extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = this.props.InodeViewModel;
    this.listenerModel = this.props.ServiceListenerViewModel;
    this.serviceModel = this.props.ServiceViewModel;
    this.clusterModel = this.props.ClusterViewModel;
    this.state = {
      isView:
        this.props.match.path.indexOf("/view/:listenerId") !== -1
          ? true
          : false,
      node_id: this.props.match.params.nodeId || this.props.match.params.id,
      nodeId: this.props.match.params.nodeId || this.props.match.params.id,
      BreadcrumbList: [],
      orgId: this.props.match.params.id,
      isVirtualNode: false,
      selectedService: {},
      action: "Listeners",
      listener_id: this.props.match.params.listenerId || null,
      formData: {},
      availableListener: [],
    };
    this.store = this.props.ModelStore;
    this.color = {};
    this.nodeApiByInterval = null;
    this.listener = {
      node_id: this.props.match.params.nodeId || this.props.match.params.id,
    };
  }

  // Listener create
  listenerFormSubmit = values => {
    this.setState({ loading: true });
    Object.assign(values, this.listener);
    // listener on node or cluster
    values.node_id = this.state.node_id;
    this.createListenerData(values);
  };
  componentWillUnmount() {
    clearInterval(this.listenerApiByInterval);
  }
  refreshListenerData = () => {
    let res = this.state.availableListener;
    let currentNodeID =
        this.props.match.params.nodeId || this.props.match.params.id,
      currentListener = {},
      currentListenerID = this.props.match.params.listenerId;
    if (
      res &&
      res.length > 0 &&
      res.forEach(listener => {
        if (
          listener.node &&
          listener.node.id === currentNodeID &&
          listener.id === currentListenerID
        ) {
          currentListener = listener;
        }
      })
    );
    if (currentListener.id) {
      if (currentListener) {
        this.setState({
          formData: currentListener,
        });
        this.setState({
          listener_id: currentListener.id,
          formData: currentListener,
          action: !this.state.isView
            ? getCurrentlocaleText(
                "service_listener.form.useraction_edit.label.text",
              )
            : getCurrentlocaleText(
                "service_listener.form.useraction_view.label.text",
              ),
        });
      }
    } else {
      this.setState({
        action: !this.state.isView
          ? getCurrentlocaleText(
              "service_listener.form.useraction_create.label.text",
            )
          : getCurrentlocaleText(
              "service_listener.form.useraction_view.label.text",
            ),
      });
    }
  };
  /**
   * Get Listener data for the page
   */
  getListenerdata = (orgId = null) => {
    let all_params = [
      {
        org_id: orgId
          ? orgId
          : this.inodeModel.inode.organization
          ? this.inodeModel.inode.organization.id
          : this.props.orgId || this.props.match.params.id,
        own: true,
        size: 1,
        page: 0,
      },
    ];
    this.listenerModel.resetServiceListeners();
    ServiceListenerController.getServiceListenersForDropdown(
      all_params,
      true,
    ).then(res => {
      if (res) {
        let total_count = this.listenerModel.pageable.total_count / 100;
        this.updateOrgsListenerData(total_count, [], true, orgId);
      }
    });
  };

  getListeners = () => {
    let params = this.props.params || {
      org_id: this.inodeModel.inode.organization
        ? this.inodeModel.inode.organization.id
        : this.props.orgId || this.props.match.params.id,
      own: true,
      size: 1,
      page: 0,
    };
    ServiceListenerController.getServiceListeners(
      params,
      false,
      true,
      false,
    ).then(response => {
      if (response && response.data && response.data.results) {
        if (
          this.listenerModel.listeners &&
          JSON.stringify(this.listenerModel.listeners) !==
            JSON.stringify(response.data.results)
        ) {
          this.updateOrgsListenerData(
            response.data.total_count / 100,
            [],
            false,
          );
        }

        // check for clear interval
        if (
          this.listenerModel.listeners &&
          this.listenerModel.listeners.length === 0
        ) {
          clearInterval(this.listenerApiByInterval);
        }
      }
    });
  };

  /**
   * getListenersByInterval
   */
  getListenersByInterval = () => {
    clearInterval(this.listenerApiByInterval);
    this.listenerApiByInterval = setInterval(() => {
      this.getListeners();
    }, 30000);
  };

  updateOrgsListenerData(
    totalCount,
    availableListeners,
    loading,
    orgId = null,
  ) {
    if (totalCount > 0) {
      let allParams = [];
      for (let page = 0; page < totalCount; page++) {
        allParams.push({
          org_id: orgId
            ? orgId
            : this.inodeModel.inode.organization
            ? this.inodeModel.inode.organization.id
            : this.props.orgId || this.props.match.params.id,
          own: true,
          size: 100,
          page: page,
        });
      }
      ServiceListenerController.QueryAllServiceListeners(
        allParams,
        loading,
      ).then(resp => {
        if (resp) {
          availableListeners = availableListeners.concat(resp);
          this.setState({ availableListener: availableListeners });
          this.refreshListenerData();
          this.getListenersByInterval();
        }
      });
    } else {
      if (this.state.listener_id && this.state.availableListener.length === 0) {
        ServiceListenerController.getServiceListener(
          this.state.listener_id,
          true,
          true,
        ).then(resp => {
          availableListeners = availableListeners.push(resp);
          this.setState({
            formData: resp,
            availableListener: availableListeners,
          });
          this.setState({
            action: !this.state.isView
              ? this.state.listener_id
                ? getCurrentlocaleText(
                    "service_listener.form.useraction_edit.label.text",
                  )
                : getCurrentlocaleText(
                    "service_listener.form.useraction_create.label.text",
                  )
              : getCurrentlocaleText(
                  "service_listener.form.useraction_view.label.text",
                ),
          });
        });
      } else {
        this.setState({
          action: !this.state.isView
            ? this.state.listener_id
              ? getCurrentlocaleText(
                  "service_listener.form.useraction_edit.label.text",
                )
              : getCurrentlocaleText(
                  "service_listener.form.useraction_create.label.text",
                )
            : getCurrentlocaleText(
                "service_listener.form.useraction_view.label.text",
              ),
        });
      }
    }
  }
  componentDidMount() {
    if (
      this.inodeModel.inode &&
      this.inodeModel.inode.organization &&
      this.inodeModel.inode.organization.id
    ) {
      this.getListenerdata();
    }
    this.getListeners();
  }
  cancelListener = () => {
    let link = window.location.pathname.replace(
      this.props.match.params.listenerId
        ? !this.state.isView
          ? "/listeners/modify/" + this.props.match.params.listenerId
          : "/listeners/view/" + this.props.match.params.listenerId
        : !this.state.isView
        ? "/listeners/modify"
        : "/listeners/view",
      "/listeners",
    );
    this.props.history.push(link);
    this.props.UiStore.formErrorResponse = {};
  };

  UNSAFE_componentWillMount() {
    if (!this.props.match.params.cluster_id) {
      let orgId = null;
      if (this.props.match.params.nodeId || this.props.match.params.id) {
        InodeController.getInode(
          this.props.match.params.nodeId || this.props.match.params.id,
        ).then(res => {
          orgId = res.organization.id;
          this.setState({ orgId: res.organization.id });
          if (this.props.AuthStore.IsPermitted("NODE:CREATE")) {
            // Getting profiles for listener create.
            ProfileController.getProfiles({
              org_id:
                orgId ||
                this.props.AuthStore.loggedInUser.organization.id ||
                this.state.orgId,
              own: true,
            }).then(resp => {
              // Filtering single profile details
              ProfileController.getProfile(res.profile.id).then(
                profileResponse => {
                  this.setState({
                    isVirtualNode: profileResponse.config.vpn_server,
                  });
                },
              );
            });
          }
          this.setState({
            orgId: orgId,
          });
          if (
            res.status &&
            res.status.node &&
            res.status.node.last_contacted_at
          ) {
            this.setState({ isNewNode: res.status.node.last_contacted_at });
          }
          this.setState(() => {
            let newObj = {},
              BreadcrumbList = [],
              nodeCrumb = [
                {
                  name: res.name ? res.name : "Details",
                  link: NetworkContainer.cameFromOrgDetailPage(
                    this.props.match.params.nodeId ||
                      this.props.match.params.id,
                  )
                    ? `orgs/${res.organization.id}/inodes/${res.id}`
                    : NetworkContainer.cameFromDashboardPage(
                        window.location.pathname,
                      )
                    ? NetworkContainer.dashboardiNodeURL(
                        window.location.pathname,
                      ).indexOf("dashboard/service") === -1
                      ? `${NetworkContainer.dashboardiNodeURL(
                          window.location.pathname,
                        )}/${res.id}`
                      : `${NetworkContainer.dashboardiNodeURL(
                          window.location.pathname,
                        )}/listeners`
                    : `inodes/${res.id}`,
                },
              ];
            if (
              NetworkContainer.cameFromOrgDetailPage(
                this.props.match.params.nodeId || this.props.match.params.id,
              )
            ) {
              BreadcrumbList.push(
                {
                  name: res.organization.name,
                  link: `orgs/${res.organization.id}`,
                },
                { name: "iNodes", link: `orgs/${res.organization.id}/inodes` },
                ...nodeCrumb,
                {
                  name: getCurrentlocaleText(
                    "node.list.table.node_service_listener.label.text",
                  ),
                  link: !this.state.isView
                    ? `orgs/${res.organization.id}/inodes/${res.id}/listeners/modify/${this.state.listener_id}`
                    : `orgs/${res.organization.id}/inodes/${res.id}/listeners/view/${this.state.listener_id}`,
                },
              );
            } else if (
              NetworkContainer.cameFromDashboardPage(window.location.pathname)
            ) {
              BreadcrumbList.push(
                {
                  name: NetworkContainer.linkNameForiNode(
                    window.location.pathname,
                  ),
                  link: NetworkContainer.dashboardiNodeURL(
                    window.location.pathname,
                  ),
                },
                ...nodeCrumb,
              );
            } else {
              BreadcrumbList.push(
                { name: "iNodes", link: "inodes" },
                ...nodeCrumb,
              );
            }
            this.props.UiStore.setInodeName(res.name);
            this.props.UiStore.setOrgName(res.organization.name);
            newObj["BreadcrumbList"] = BreadcrumbList;
            return newObj;
          });
          this.getListenerdata();
        });
      }
    }
  }

  // perform data computation for listener update
  doEditListener = values => {
    if (this.state.listener_id) {
      // Update API call func....
      return this.updateListenerData(
        values,
        this.props.match.params.listenerId,
        {},
      );
    }
  };
  // create listener call
  createListenerData(values) {
    this.listenerModel.loading = true;
    ServiceListenerController.create(values, this.props.params)
      .then(response => {
        //Success create listener
        this.setState({ listener_id: response.id });
        this.listenerModel.loading = false;
        this.cancelListener();
      })
      .catch(error => {
        console.log(error);
        this.setState({ loading: false });
        this.listenerModel.loading = false;
        this.props.UiStore.formErrorResponse = error.response;
      });
  }
  // update listener call
  updateListenerData(values, listenerId, params = {}) {
    // Update listener
    this.listenerModel.loading = true;
    return ServiceListenerController.update(
      values,
      { id: listenerId },
      this.props.params,
    )
      .then(response => {
        setTimeout(() => {
          this.updateServiceList(false, true);
        }, 1200);
        this.listenerModel.loading = false;
        this.cancelListener();
      })
      .catch(error => {
        let defaultMessage = getCurrentlocaleText("error.message.bad_request"),
          // LAT-3281 NAT base version needs to updated here!!!
          baseVersion = 1059.0;
        this.setState({ loading: false });
        this.listenerModel.loading = false;
      });
  }
  updateServiceList = (loading = true, forceCall = false) => {
    let params = {
      node_id: this.props.match.params.id,
    };
    ServiceController.getServices(params, loading, forceCall).then(res => {
      this.resetSelectedService();
    });
  };

  resetSelectedService = () => {
    this.setState({ selectedService: {} });
  };

  validateForm = values => {
    if (this.state.listener_id === null) {
      this.listenerFormSubmit(values);
    } else {
      this.updateListenerData(values, this.state.listener_id);
    }
  };
  render() {
    let node_data = this.inodeModel.inode;
    let container = (
      <div>
        <Row gutter={16} type="flex" align="middle">
          <Col lg={{ span: 18 }} xl={{ span: 20 }}>
            <h2 className="page-title">
              <Icons type="fa" name="FaRegHdd" />
              {"  "}
              {node_data.name ? node_data.name : null}
            </h2>

            <BreadcrumbComponent
              {...this.props}
              BreadcrumbList={this.state.BreadcrumbList}
            />
          </Col>
          <Col lg={{ span: 6 }} xl={{ span: 4 }} className="flex flex-h-right">
            <OrgSelector {...this.props} />
          </Col>
        </Row>

        <div>
          <Row gutter={2} type="flex" align="top">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
              xl={{ span: 24 }}
            >
              <Card
                loading={
                  this.state.action === "Listeners"
                    ? true
                    : this.props.match.params.listenerId && !this.state.formData
                    ? true
                    : false
                }
                title={
                  <span className="iot-main-card-title">
                    <Icons type="bs" name="BsArrowLeftRight" />
                    <span> {this.state.action}</span>
                  </span>
                }
              >
                <ListenersFormPage
                  ref={c => {
                    this.listenerForm = c;
                  }}
                  onSubmit={this.validateForm}
                  isloading={this.state.action === "Listeners" ? true : false}
                  onCancel={this.cancelListener}
                  formData={this.state.formData}
                  action={
                    this.state.isView
                      ? "view"
                      : this.props.match.params.listenerId
                      ? "edit"
                      : "create"
                  }
                  isVirtualNode={this.state.isVirtualNode}
                  availableListener={this.state.availableListener}
                  isNewlistener={
                    this.state.isView
                      ? false
                      : this.props.match.params.listenerId
                      ? false
                      : true
                  }
                  orgId={this.state.orgId}
                  nodeId={this.state.nodeId}
                  listenerId={this.props.match.params.listenerId}
                  viewOnly={this.state.isView}
                  {...this.props}
                />
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );

    return (
      <LoadingComponent loading={this.listenerModel.loading}>
        {container}
      </LoadingComponent>
    );
  }
}

export default ListenerDetails;
