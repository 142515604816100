import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { isLoggedIn, getDocumentTitle } from "Core/Utils";

@inject("AuthStore", "ModelStore", "UiStore", "OrgViewModel")
@observer
class ProtectedRoute extends Component {
  constructor(props) {
    super(props);
    // GLOBAL_SETTINGS configured from the node js
    this.GLOBAL_SETTINGS = GLOBAL_SETTINGS;
  }
  UNSAFE_componentWillMount() {
    this.props.UiStore.SetStoreData(
      "currentRouteLocation",
      this.props.location ? this.props.location.pathname : null,
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let path = nextProps.location.pathname;
    // Clear if we are entering in org details page
    if (path[1] === "orgs" && path[2] && !path[3] && !path[4] && !path[5]) {
      this.props.UiStore.setOrgName("");
    }
    // Clear if we are entering in inode details page only
    if (
      (path[1] === "inodes" && path[2] && !path[3] && !path[4] && !path[5]) ||
      (path[1] === "orgs" &&
        path[2] &&
        path[3] === "inodes" &&
        path[4] &&
        !path[5])
    ) {
      this.props.UiStore.setInodeName("");
    }
    let orgname =
      this.props.OrgViewModel.org && this.props.OrgViewModel.org.name
        ? this.props.OrgViewModel.org.name
        : null;
    this.props.UiStore.setDocumentTitle(
      getDocumentTitle(path, nextProps.AuthStore, orgname),
    );
  }

  render() {
    return (
      <Route
        component={this.props.component}
        path={this.props.path}
        render={this.props.render}
      />
    );
  }
}

export default ProtectedRoute;
