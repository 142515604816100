import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import Input from "components/UI-Components/InputBox";
import propTypes from "prop-types";
import { getCurrentlocaleText, copyObject } from "Core/Utils";
import Twig from "twig";
import { computed } from "mobx";

/*Define form elements*/
const FormItem = Form.Item;

export default class ServiceNameTextBox extends Component {
  constructor(props) {
    super(props);
  }

  validateInput = (rule, value, callback, field) => {
    let failMessage = null;
    let isSystemService = this.props.isSystemService;
    let regex = isSystemService
      ? /^[a-z0-9A-Z](-?[a-z0-9A-Z])*$/
      : /^[a-z0-9A-Z]+$/;
    let result = regex.test(value) && value.length >= 2 && value.length <= 30;
    if (!result) {
      failMessage = isSystemService
        ? getCurrentlocaleText(
            "service.templates.servicenamebox.validationmsg.for.systemservice",
          )
        : getCurrentlocaleText(
            "service.templates.servicenamebox.validationmsg",
          );
    }

    if (failMessage) {
      callback(failMessage);
    }
    callback();
  };

  componentDidMount() {
    this.props.updateCurrentValueOnChange(
      this.props.properties.defaultValue &&
        this.props.properties.defaultValue.value
        ? this.props.properties.defaultValue.value
        : typeof this.props.properties.defaultValue === "string" &&
          this.props.properties.defaultValue
        ? this.props.properties.defaultValue
        : "",
      "value",
    );
  }

  @computed
  get componentVisibility() {
    let visibility = null;
    if (
      this.props.UiStore.computedOutputObject &&
      this.props.UiStore.computedOutputObject.steps
    ) {
      if (this.props.properties.visibility) {
        var template = Twig.twig({
          data: this.props.properties.visibility,
        });
        let computedOutputObject = copyObject(
          this.props.UiStore.computedOutputObject,
        );
        visibility = template.render(computedOutputObject);
        try {
          visibility = JSON.parse(visibility);
        } catch (error) {
          visibility = null;
        }
      }
    }
    return visibility ? true : false;
  }

  render() {
    const { getFieldDecorator } = this.props.referenceForm; // reference of base form
    let disable = false;
    if (this.props.editmode) {
      disable = this.props.properties.noneditable
        ? this.props.properties.noneditable
        : false;
    }
    disable = this.props.properties.disabled
      ? this.props.properties.disabled
      : disable;

    // For View only
    disable = this.props.viewOnly === true ? true : disable;
    let visibility = this.props.properties.visibility
      ? this.componentVisibility
      : true;
    return visibility ? (
      <div
        onClick={
          this.props.onFocus &&
          this.props.onFocus.bind(null, this.props.properties.help)
        }
      >
        <FormItem label={this.props.properties.label} hasFeedback>
          {getFieldDecorator(`${this.props.id}`, {
            rules: [
              {
                required: true,
                message: getCurrentlocaleText(
                  "service.templates.servicenamebox.error",
                ),
              },
              {
                validator: this.validateInput,
              },
            ],
            initialValue:
              this.props.properties.defaultValue &&
              this.props.properties.defaultValue.value
                ? this.props.properties.defaultValue.value
                : typeof this.props.properties.defaultValue === "string" &&
                  this.props.properties.defaultValue
                ? this.props.properties.defaultValue
                : "",
          })(
            <Input
              placeholder={this.props.properties.placeholder}
              autoComplete="off"
              onFocus={
                this.props.onFocus &&
                this.props.onFocus.bind(null, this.props.properties.help)
              }
              onClick={
                this.props.onFocus &&
                this.props.onFocus.bind(null, this.props.properties.help)
              }
              disabled={disable}
              onChange={e => {
                this.props.updateCurrentValueOnChange(e.target.value, "value");
              }}
              className={this.props.className}
            />,
          )}
        </FormItem>
      </div>
    ) : (
      <span></span>
    );
  }
}

ServiceNameTextBox.propTypes = {
  referenceForm: propTypes.any,
  id: propTypes.string,
  properties: propTypes.shape({
    label: propTypes.string.isRequired,
    disabled: propTypes.bool,
    placeholder: propTypes.string,
    defaultValue: propTypes.object,
    updateCurrentValueOnChange: propTypes.func,
  }),
};

/* sample schema

{
  "type": "ServiceNameTextBox",
  "visible": true,
  "props": {
    "label": "Service name",
    "defaultValue": "",
    "help" : "<div>help for ServiceNameTextBox</div>",
    "placeholder": "Enter Service name",
    "disabled" : false,
    "noneditable" : true,
  }
}

*/
