import React, { Component } from "react";
import { Card, Empty } from "antd";
import { Link } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { getCurrentlocaleText } from "Core/Utils";
import Icons from "../UI-Components/Icons";
import InodeController from "../../controller/InodeController";
import LoadingComponent from "../UI-Components/LoadingComponent";
import DeviceDiscoveryBarChart from "./DeviceDiscoveryBarChart";

@inject("InodeViewModel", "OrgViewModel", "AuthStore")
@observer
class InodeDeviceDiscoveryChart extends Component {
  constructor(props) {
    super(props);
    this.orgModel = this.props.OrgViewModel;
    this.inodeModel = this.props.InodeViewModel;
    this.authStore = this.props.AuthStore;
    this.orgId =
      this.orgModel.org && this.orgModel.org.id
        ? this.orgModel.org.id
        : this.authStore.loggedInUser.organization &&
          this.authStore.loggedInUser.organization.id;
    this.inodeId =
      this.inodeModel.inode && this.inodeModel.inode.id
        ? this.inodeModel.inode.id
        : this.props.match.params.nodeId;
    this.getDeviceScans(this.inodeId);
  }

  getDeviceScans = nodeId => {
    this.inodeModel.getDeviceScansLoading = true;
    InodeController.getDeviceScans(nodeId, {})
      .then(resp => {
        if (resp) {
          this.inodeModel.setDeviceScans([
            {
              name: getCurrentlocaleText("total.scans"),
              count: resp.total_count ? resp.total_count : 0,
            },
            {
              name: getCurrentlocaleText("device.discovery.devices.found.text"),
              count: resp.total_device_count ? resp.total_device_count : 0,
            },
          ]);
        } else {
          this.inodeModel.setDeviceScans([]);
        }
        this.inodeModel.getDeviceScansLoading = false;
      })
      .catch(err => {
        this.inodeModel.setDeviceScans([]);
        this.inodeModel.getDeviceScansLoading = false;
      });
  };

  render() {
    return (
      <LoadingComponent loading={this.inodeModel.getDeviceScansLoading}>
        <Card
          className="device-discovert-widget"
          hoverable={false}
          title={
            <div className="justify-space-between">
              <span>{getCurrentlocaleText("device.discovery.text2")}</span>
              <Link
                to={`/orgs/${this.orgId}/inodes/${this.inodeId}/devicediscovery`}
              >
                <Icons type="bs" name="BsArrowUpRight" />
              </Link>
            </div>
          }
          style={{ minHeight: "340px", marginBottom: 0 }}
        >
          <div className="device-discovert-widget-content">
            <div>
              {this.inodeModel.getDeviceScans &&
                this.inodeModel.getDeviceScans.length == 0 && (
                  <div style={{ minHeight: 200 }}>
                    <Empty
                      image={
                        <span className="iot-pointer-cursor">
                          <Icons
                            type="fa"
                            name="FaChartLine"
                            className="no-metrics-empty-info"
                          />
                        </span>
                      }
                      description={
                        <div className="all-center">
                          {getCurrentlocaleText("releases.noContent.text")}
                        </div>
                      }
                    />
                  </div>
                )}
              {this.inodeModel.getDeviceScans &&
                this.inodeModel.getDeviceScans.length > 0 && (
                  <>
                    <b>
                      {getCurrentlocaleText("device.discovery.chart.heading")}
                    </b>
                    <div style={{ fontSize: "12px" }}>
                      <DeviceDiscoveryBarChart
                        headingName={getCurrentlocaleText(
                          "device.discovery.chart.heading.sub",
                        )}
                        data={this.inodeModel.getDeviceScans}
                        objKey="name"
                      />
                    </div>
                  </>
                )}
            </div>
            <div className="beta mt-15">
              <Icons
                type="fa"
                name="FaRocket"
                style={{ fontSize: 16, marginLeft: 10, marginRight: 10 }}
              />
              {getCurrentlocaleText("beta.version")}
            </div>
          </div>
        </Card>
      </LoadingComponent>
    );
  }
}

export default InodeDeviceDiscoveryChart;
