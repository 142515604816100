import React, { Component } from "react";
import { Form, Select } from "antd";
import {
  customSort,
  highlightSearchText,
  getCurrentlocaleText,
} from "Core/Utils";
import SerialNumberController from "controller/SerialNumberController";
import Icons from "./Icons";

const FormItem = Form.Item;
const Option = Select.Option;

class SerialSelect extends Component {
  constructor(props) {
    super(props);
    this.searthTimeout = null;
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    let params = this.props.params || {};
    params.page = 0;
    SerialNumberController.getPkiForDropdown(params).then(response => {
      this.setState({
        data: response ? this.renderList(response) : [],
      });
    });
  }

  fetchSerialNumbers = (search = "") => {
    clearTimeout(this.searthTimeout);
    this.searthTimeout = setTimeout(() => {
      let params = this.props.params || {};
      params.page = 0;
      params.search = search;
      SerialNumberController.getPkiForDropdown(params).then(response => {
        this.setState({
          data: response ? this.renderList(response, search) : [],
        });
      });
    }, 500);
  };

  renderList(serialNumbers, search = "") {
    if (serialNumbers.length > 0) {
      let sns = serialNumbers.map(sn => {
        let hsn = sn.legacy_serial_number
          ? sn.legacy_serial_number
          : sn.hardware_serial_number;
        return {
          id: sn.id,
          hardware_serial_number: hsn,
          created_at: sn.created_at,
        };
      });
      return sns.length > 0 ? (
        sns
          .sort((x, y) => new Date(y.created_at) - new Date(x.created_at))
          .map(item => {
            return (
              <Option
                title={item.hardware_serial_number}
                key={item.hardware_serial_number}
              >
                {search.length > 0
                  ? highlightSearchText(item.hardware_serial_number, search)
                  : item.hardware_serial_number}
              </Option>
            );
          })
      ) : (
        <Option key={1} disabled>
          Not Found
        </Option>
      );
    } else {
      return (
        <Option key={1} disabled>
          Not Found
        </Option>
      );
    }
  }

  setValue = (value, label) => {
    this.props.setSelectVal({
      value,
      type: "hardware_serial_number",
    });
    if (value) {
      setTimeout(() => {
        let element = document.querySelector(
          ".ant-select-selection-selected-value strong",
        );
        if (element) {
          element.style.fontWeight = "400";
        }
      }, 50);
    }
  };

  render() {
    return (
      <Select
        showSearch
        filterOption={false}
        onSearch={this.fetchSerialNumbers}
        onChange={this.setValue}
        placeholder={getCurrentlocaleText(
          "serialnumber.select.placeholder.text",
        )}
        notFoundContent={getCurrentlocaleText(
          "general.notfound.placeholder.text",
        )}
        allowClear={true}
        prefix={
          <Icons type="ai" name="AiOutlineIdcard" style={{ fontSize: 13 }} />
        }
      >
        {this.state.data}
      </Select>
    );
  }
}

export default SerialSelect;
