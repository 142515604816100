import React, { Component } from "react";
import { Card, Row, Col } from "antd";
import { observer, inject } from "mobx-react";
import axios from "axios";
import { Link } from "react-router-dom";

// Login Form
import LoginForm from "./LoginForm";
import DarkAppInfo from "components/UI-Components/DarkAppInfo";
import { clearConsole, getCurrentlocaleText } from "Core/Utils.js";

// Reactive Login Page
@inject("ModelStore", "UiStore", "AuthStore", "DashboardStore")
@observer
class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableLogin: false,
    };
  }
  componentDidMount() {
    if (!this.props.AuthStore.isCurrentSessionExpired) {
      this.props.history.push("/home");
    }
  }

  // Login function
  onLogin = formData => {
    this.setState({ disableLogin: true });
    let loginReq = axios.post("/api/v1/authenticate", formData);
    loginReq
      .then(response => {
        this.props.AuthStore.isCurrentSessionExpired = false;
        this.props.ModelStore.SetToken(response.data.token, this.props.history);
        localStorage.removeItem("currentOrgId");
        this.props.UiStore.SetLoginStatus(true);
        this.props.UiStore.passwordExpiredMessage = null;
        // to avoid push history null on landing which cause many console errors LAT-4815
        //https://developer.mozilla.org/en-US/docs/Web/API/History_API
        if (this.props.history) this.props.history.push("/home");
        this.props.UiStore.is2faEnabled = false;
      })
      .catch(error => {
        if (error.response && error.response.status === 412) {
          // Redirect to change password screen.
          sessionStorage.changePasswordUrl =
            error.response.data.change_password_url;
          let term_token = error.response.data.change_password_url.split(
            "=",
          )[1];
          this.props.UiStore.passwordExpiredMessage =
            error.response.data.message;
          this.props.history.push("/passwordexpired/" + term_token);
          clearConsole();
        } else if (error.response && error.response.status === 428) {
          // Redirect to 2FA screen
          sessionStorage.init2FAUrl = error.response.data.init2FAUrl;
          sessionStorage.confirm2FAUrl = error.response.data.confirm2FAUrl;
          let term_token = error.response.data.init2FAUrl.split("=")[1];
          this.props.UiStore.force2FaMessage = error.response.data.message;
          this.props.history.push("/twofactorsetup/" + term_token);
          clearConsole();
        } else if (error.response && error.response.status === 417) {
          this.props.UiStore.is2faEnabled = true;
          this.setState({ disableLogin: false });
          if (this.props.ModelStore.displayMessage) {
            this.props.ModelStore.resetMessages();
          }
        } else if (error.response && error.response.status === 406) {
          this.props.UiStore.showCaptcha = true;
          this.setState({ disableLogin: false });
          if (this.props.ModelStore.displayMessage) {
            this.props.ModelStore.resetMessages();
          }
        } else {
          this.setState({ disableLogin: false });
          this.props.UiStore.errorMessage =
            error.response && error.response.data
              ? error.response.data.message
              : "Error";
          this.props.UiStore.passwordExpiredMessage = null;
          if (this.props.ModelStore.displayMessage) {
            this.props.ModelStore.resetMessages();
          }
        }
      });
  };

  render() {
    return (
      <Row type="flex" className="login-card" align="middle" justify="center">
        <Col
          className="login-col"
          lg={{ span: 7 }}
          sm={{ span: 10 }}
          md={{ span: 10 }}
          xs={{ span: 16 }}
        >
          <Card>
            <center>
              <DarkAppInfo />
              <LoginForm
                onLogin={this.onLogin}
                disable={this.state.disableLogin}
              />
              <div style={{ fontWeight: 200 }}>
                <Row type="flex" align="middle" justify="center">
                  <Col>
                    <Link to={"/forgotpassword"}>
                      {getCurrentlocaleText(
                        "general.login.form.support.message2.text",
                      )}
                    </Link>
                  </Col>
                </Row>
              </div>
            </center>
          </Card>
        </Col>
      </Row>
    );
  }
}
export default LoginPage;
