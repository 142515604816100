import React, { Component } from "react";
import { injectIntl, FormattedMessage, defineMessages } from "react-intl";

var language = "en-US";
var languageWithoutRegionCode = "en";

if (typeof window !== "undefined") {
  language =
    (window.navigator.languages && window.navigator.languages[0]) ||
    window.navigator.language ||
    window.navigator.userLanguage;
  languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];
}
// LAT-3448 will have full details
class TranslatedText extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let localeData = this.props.intl.messages[language]
      ? this.props.intl.messages[language][this.props.UItextID]
        ? this.props.intl.messages[language][this.props.UItextID]
        : this.props.intl.messages["en-US"][this.props.UItextID]
      : this.props.intl.messages["en-US"][this.props.UItextID];
    let messages = defineMessages({
      data: localeData,
    });
    return (
      <FormattedMessage
        id={this.props.UItextID}
        defaultMessage={messages.data.defaultMessage}
        values={this.props.DynValues}
      />
    );
  }
}
//export default TranslatedText;
export default injectIntl(TranslatedText);
