import React, { Component } from "react";
import { Menu, Select, Row, Col } from "antd";
import { NavLink } from "react-router-dom";
import { observer, inject } from "mobx-react";
import SearchInput from "components/UI-Components/SearchInput";
import { customSort, ROOT_ORG } from "Core/Utils";
import Icons from "components/UI-Components/Icons";
import OrgController from "controller/OrgController";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
const menuItem = {
  height: 40,
  minWidth: "100%",
};
const Option = Select.Option;

@inject("AuthStore", "UiStore", "OrgViewModel", "InodeViewModel")
@observer
class OrgSelector extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = this.props.InodeViewModel;
    this.state = {
      parrentOrg: null,
      searchText: "",
      filterInfo: null,
      sorterInfo: { sorter: {}, sort: {} },
      orglist: [],
      showNotFound: false,
      data: [],
      defaultOrg:
        this.props.UiStore.currentRouteLocation.split("/")[1] &&
        this.props.UiStore.currentRouteLocation.split("/")[1] === "dashboard"
          ? this.props.AuthStore.loggedInUser.organization &&
            this.props.AuthStore.loggedInUser.organization.name
          : this.props.OrgViewModel.org.id != undefined
          ? this.props.OrgViewModel.org.name
          : this.props.AuthStore.loggedInUser.organization
          ? this.props.AuthStore.loggedInUser.organization.name
          : "",
    };
  }
  componentDidMount() {
    this.orgList();
  }
  renderList = orgs => {
    let options = [];
    if (orgs && orgs.length > 0) {
      options = orgs
        .sort((x, y) => {
          return customSort(x.name, y.name);
        })
        .map((item, i) => {
          return (
            <Option key={item.id} value={item.id} title={item.name}>
              <NavLink to={this.getCurrentRoute(item.id)}>
                {
                  <span>
                    <span
                      className={
                        this.props.className
                          ? "org-selector-menu " + this.props.className
                          : "org-selector-menu"
                      }
                      style={{ lineHeight: "1.5", width: 195 }}
                    >
                      {item.name}
                    </span>
                  </span>
                }
              </NavLink>
            </Option>
          );
        });
      options.unshift(
        <Option key="all_orgs" value="all_orgs" title="View All">
          <NavLink to="/orgs" title={"View All"}>
            {
              <span>
                <span
                  className={
                    this.props.className
                      ? "org-selector-menu " + this.props.className
                      : "org-selector-menu"
                  }
                  style={{ lineHeight: "1.5" }}
                >
                  {"View All"}
                </span>
              </span>
            }
          </NavLink>
        </Option>,
      );
      return options;
    } else {
      options.unshift(
        <Option>
          {this.props.UiStore.currentRouteLocation.split("/")[1] &&
          this.props.UiStore.currentRouteLocation.split("/")[1] === "dashboard"
            ? this.props.AuthStore.loggedInUser.organization &&
              this.props.AuthStore.loggedInUser.organization.name
            : this.props.OrgViewModel.org.name != undefined
            ? this.props.OrgViewModel.org.name
            : this.props.AuthStore.loggedInUser.organization
            ? this.props.AuthStore.loggedInUser.organization.name
            : ""}
        </Option>,
      );
      return (
        <Option key={1} disabled>
          Not found
        </Option>
      );
    }
  };
  orgList = (
    loading = true,
    page = 0,
    search = "",
    sortBy = null,
    sortOrder = null,
  ) => {
    const currentOrgId = this.props.AuthStore.loggedInUser.organization
      ? this.props.AuthStore.loggedInUser.organization.id
      : "";
    let params = { org_id: currentOrgId };
    params.page = page;
    params.search = search;
    params.size = 20;
    // For sort
    if (sortBy && sortOrder) {
      params.sort = `${sortBy}:${sortOrder}`;
    }
    if (currentOrgId) {
      OrgController.getOrgsForDropdown(params, true).then(res => {
        this.setState({
          orglist: res,
          showNotFound: res.length === 0 ? true : false,
          data: res ? this.renderList(res) : [],
          mounting: true,
          defaultOrg:
            this.props.UiStore.currentRouteLocation.split("/")[1] &&
            this.props.UiStore.currentRouteLocation.split("/")[1] ===
              "dashboard"
              ? this.props.AuthStore.loggedInUser.organization &&
                this.props.AuthStore.loggedInUser.organization.name
              : this.props.OrgViewModel.org.id != undefined
              ? this.props.OrgViewModel.org.name
              : this.props.AuthStore.loggedInUser.organization
              ? this.props.AuthStore.loggedInUser.organization.name
              : "",
        });
      });
    }
  };
  orgListFilter = e => {
    let searchText = e.target.value;
    clearTimeout(this.searchTimeout);
    this.setState({ searchText: searchText });
    this.searchTimeout = setTimeout(() => {
      this.orgList(
        false,
        0,
        searchText,
        this.state.sorterInfo.sort.by,
        this.state.sorterInfo.sort.order,
      );
    }, 500);
  };
  clearSearchFilter = () => {
    this.setState({ searchText: "" });
  };
  /**
   *  check for current route and compute the route for each value in org selector drop down
   */
  getCurrentRoute = orgId => {
    if (this.props.UiStore.currentRouteLocation) {
      if (this.props.UiStore.currentRouteLocation != "/") {
        let keys = this.props.UiStore.currentRouteLocation.split("/");
        // localhost/dashboard/inodes  ** check for this route and compute the route for each value in org selector drop down
        if (keys[1] === "dashboard" && keys[2] === "inodes") {
          // localhost/dashboard/inodes/(profile/status)
          if (keys[3] === "profile" || keys[3] === "status") {
            keys.splice(3, 0, orgId);
          } else {
            keys.splice(3, 1, orgId);
          }
        } else {
          // localhost/dashboards/activities
          // localhost/home/activities
          if (keys[2] === "activities") {
            keys.splice(3, 1, "activities");
          }
          keys.splice(2, 1, orgId);
        }
        // localhost/(profile/help)
        if (keys[1] === "profile" || keys[1] === "help") {
          keys.splice(1, 1, "orgs");
        }
        // this will handle the default landing to dashboard
        if (keys[1] === "home") {
          keys.splice(1, 1, "dashboard");
        }
        // localhost/orgs/:id/inodes
        if (
          !(keys[1] === "dashboard" && keys[2] === "inodes") &&
          keys.length >= 4
        ) {
          keys.splice(4);
        }
        let route = keys.join("/");
        return route;
      } else {
        // localhost/orgs/:id/orgs
        return location.pathname + "/orgs/" + orgId + "/" + "orgs";
      }
    } else {
      // localhost/orgs/:id/orgs
      return (location.pathname = "/orgs/" + orgId + "/" + "orgs");
    }
  };
  checkForParentdata = currentOrgId => {
    if (this.props.AuthStore.loggedInUser.organization.id === currentOrgId) {
      return false;
    } else {
      return true;
    }
  };
  renderSearchResults = () => {
    if (this.state.orglist.length > 0) {
      return this.state.orglist.map((item, i) => {
        return (
          <Menu.Item key={item.id} style={menuItem} title={item.name}>
            <NavLink to={this.getCurrentRoute(item.id)} title={item.name}>
              {
                <span title={item.name}>
                  <span
                    className={
                      this.props.className
                        ? "org-selector-menu " + this.props.className
                        : "org-selector-menu"
                    }
                    style={{ lineHeight: "1.5" }}
                    title={item.name}
                  >
                    {item.name}
                  </span>
                </span>
              }
            </NavLink>
          </Menu.Item>
        );
      });
    }
  };
  getHomeOrg = () => {
    // checks for dashboard route
    if (
      this.props.UiStore.currentRouteLocation.split("/")[1] &&
      this.props.UiStore.currentRouteLocation.split("/")[1] === "dashboard"
    ) {
      // checks for current org name in org-view model
      if (this.props.OrgViewModel.org.name != undefined)
        return this.props.OrgViewModel.org.name;
      else if (this.props.AuthStore.loggedInUser.organization)
        // checks for current org name in logged_in-user model
        return this.props.AuthStore.loggedInUser.organization.name;
    } else if (
      this.props.UiStore.currentRouteLocation.split("/")[3] &&
      this.props.UiStore.currentRouteLocation.split("/")[3] === "inodes"
    ) {
      // checks for current org name in org-view model
      if (this.props.OrgViewModel.org.name != undefined)
        return this.props.OrgViewModel.org.name;
      else if (this.props.AuthStore.loggedInUser.organization)
        // checks for current org name in logged_in-user model
        return this.props.AuthStore.loggedInUser.organization.name;
      else return "";
    } else {
      // checks for current org name in org-view model
      if (this.props.OrgViewModel.org.name != undefined)
        return this.props.OrgViewModel.org.name;
      else if (this.props.AuthStore.loggedInUser.organization)
        // checks for current org name in logged_in-user model
        return this.props.AuthStore.loggedInUser.organization.name;
      else return "";
    }
  };
  render() {
    const currentOrgId =
      this.props.match && this.props.match.params && this.props.match.params.id
        ? this.props.match.params.id
        : this.props.OrgViewModel.org.id != undefined
        ? this.props.OrgViewModel.org.id
        : this.props.AuthStore.loggedInUser.organization &&
          this.props.AuthStore.loggedInUser.organization.id;
    localStorage.setItem("currentOrgId", currentOrgId);
    return (
      <div>
        <Row type="flex" align="middle" style={{ float: "right" }}>
          <Col xs={23} sm={23} md={23} lg={23} xl={23}>
            {this.props.AuthStore.IsPermitted("ORG:VIEW") && (
              <Menu
                mode="horizontal"
                onClick={() => {
                  this.clearSearchFilter();
                }}
                className="iot-org-selector"
              >
                <SubMenu
                  key="homeOrg"
                  title={
                    <span>
                      <Icons
                        type="fa"
                        name="FaHome"
                        style={{ fontSize: 18 }}
                        className="baseline"
                      />
                      <span
                        className={
                          this.props.className
                            ? "org-selector-menu " + this.props.className
                            : "org-selector-menu"
                        }
                        title={this.getHomeOrg()}
                      >
                        {this.getHomeOrg()}
                      </span>
                    </span>
                  }
                >
                  <SearchInput
                    placeholder={
                      this.props.isChildOrgs
                        ? "Filter child orgs by name"
                        : "Filter orgs by name"
                    }
                    onChange={this.orgListFilter}
                    value={this.state.searchText}
                    clearSearch={this.clearSearchFilter}
                  />

                  {this.state.searchText != "" && this.renderSearchResults()}
                  {this.state.searchText === "" && (
                    <MenuItemGroup
                      style={{
                        border: 1,
                        borderColor: "#ffa50085",
                      }}
                    >
                      <Menu.Item key="currentorg" style={menuItem}>
                        <NavLink to={this.getCurrentRoute(currentOrgId)}>
                          <span
                            className="org-selector-menu"
                            style={{
                              lineHeight: "1.5",
                              marginLeft: -12,
                            }}
                          >
                            {this.props.OrgViewModel.org.name != undefined
                              ? this.props.OrgViewModel.org.name
                              : this.props.AuthStore.loggedInUser.organization
                              ? this.props.AuthStore.loggedInUser.organization
                                  .name
                              : ""}
                          </span>
                        </NavLink>
                      </Menu.Item>
                    </MenuItemGroup>
                  )}
                  {this.props.UiStore.parentOrgData &&
                    this.props.UiStore.parentOrgData.id &&
                    this.state.searchText === "" &&
                    this.checkForParentdata(currentOrgId) && (
                      <Menu.Item key="parent-org" style={menuItem}>
                        <NavLink
                          to={this.getCurrentRoute(
                            !this.props.AuthStore.loggedInUser.permissions.includes(
                              "SUPER:ADMIN",
                            ) &&
                              this.props.UiStore.parentOrgData.id === ROOT_ORG
                              ? currentOrgId
                              : this.props.UiStore.parentOrgData.id,
                          )}
                        >
                          {
                            <span>
                              <span
                                className="org-selector-menu"
                                style={{ lineHeight: "1.5", width: 195 }}
                              >
                                {"Parent Org"}
                              </span>
                            </span>
                          }
                        </NavLink>
                      </Menu.Item>
                    )}
                  {this.state.searchText === "" && (
                    <Menu.Item key="allorg" style={menuItem}>
                      <NavLink to="/orgs" title={"View All"}>
                        {
                          <span>
                            <span
                              className="org-selector-menu"
                              style={{ lineHeight: "1.5" }}
                            >
                              {"View All"}
                            </span>
                          </span>
                        }
                      </NavLink>
                    </Menu.Item>
                  )}
                </SubMenu>
              </Menu>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default OrgSelector;
