import React, { Component } from "react";
import { Row, Col, Button, Popconfirm, Popover, Badge, Tag, Icon } from "antd";
import { observer, inject } from "mobx-react";
import ChangePassword from "components/UI-Components/ChangePassword";
import EditableFormInput from "components/UI-Components/EditableFormInput";
import EditableUserTimezone from "components/UI-Components/EditableUserTimezone";
import UserController from "controller/UserController";
import Icons from "components/UI-Components/Icons";
import {
  getCurrentlocaleText,
  formatTimestampToIotiumLastAtTimeString,
  copyObject,
  getDaysLeftInfo,
  getAvailableTimezones,
} from "Core/Utils";
import UserSessionView from "components/UserSession/UserSessionView";
import OrgController from "controller/OrgController";
import TwoFactorSetup from "components/UI-Components/TwoFactorSetup";
import PopOverHelp from "components/UI-Components/PopOverHelp";
import ResendVerificationModal from "components/Containers/ResendVerificationModal";
import "../../assets/styles/Profile.css";

const NOT_APPLICAPLE = "N/A";

@inject("AuthStore", "UiStore", "TimezoneViewModel", "OrgViewModel")
@observer
class MyProfileDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,
      orgTimezone: "",
      isMe: this.props.isMe,
      show2FA: false,
      is2FaEnabled: false,
      current_uri: "https://view.com/",
      showResendModal: false,
      UserInfo: {},
      requestInProgress: false,
      disable: true,
      reset: true,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (this.state.user !== props.user) {
      // This to check the org has any timezone
      if (!props.user.timezone) {
        let interval = setInterval(() => {
          if (this.props.AuthStore.loggedInUserOrg) {
            let user = this.updateUserTimezone(props.user);
            this.setState({ user: user, isMe: props.isMe });
          }
          clearInterval(interval);
        }, 500);
      } else {
        let user = this.updateUserTimezone(props.user);
        this.setState({ user: user, isMe: props.isMe });
      }
      if (props.isMe) {
        let users_orgid =
          props.user && props.user.organization && props.user.organization.id;
        if (users_orgid && props.AuthStore.loggedInUserOrg === null)
          OrgController.getOrg(users_orgid, false).then(response => {
            this.props.AuthStore.SetStoreData("loggedInUserOrg", response);
          });
      }
    }
  }

  enableChangeModal = () => {
    this.props.UiStore.ChangePasswordMdl = !this.props.UiStore
      .ChangePasswordMdl;
  };

  enableResendLink = data => {
    data.last_triggerred_at = data.verify_email_triggered_at
      ? data.verify_email_triggered_at
      : null;
    data.emailaddress = data.email;
    if (data.verify_email_count > 0) {
      this.setState({
        showResendModal: true,
        UserInfo: data,
        requestInProgress: true,
      });
    } else {
      this.setState({ showResendModal: true, UserInfo: data });
    }
  };
  hidePopup = () => {
    this.setState({ showResendModal: false, UserInfo: {} });
  };

  updateUserTimezone = user => {
    // Replace browser timezone
    let Timezones = copyObject(getAvailableTimezones());
    // Check the user has timezone
    if (!user.timezone) {
      // Check current org has timezone or not
      if (this.props.isMe) {
        if (this.props.TimezoneViewModel.orgTimezone) {
          let timezone = Timezones.find(tz => {
            return tz.value === this.props.TimezoneViewModel.orgTimezone;
          });
          user.timezone = "";
          user.tzDisplayValue = getCurrentlocaleText(
            "org_default_timezone.text",
          );
          this.setState({ orgTimezone: timezone.value });
        } else {
          // Org default timezone
          user.timezone = "";
          user.tzDisplayValue = getCurrentlocaleText(
            "org_default_timezone.text",
          );
          this.setState({ orgTimezone: "" });
        }
      } else {
        OrgController.getOrg(user.organization.id, false).then(response => {
          if (response) {
            if (response.timezone) {
              let timezone = Timezones.find(tz => {
                return tz.value === response.timezone;
              });
              user.timezone = "";
              user.tzDisplayValue = getCurrentlocaleText(
                "org_default_timezone.text",
              );
              this.setState({ orgTimezone: timezone.value });
            } else {
              // Org default timezone
              user.timezone = "";
              user.tzDisplayValue = getCurrentlocaleText(
                "org_default_timezone.text",
              );
              this.setState({ orgTimezone: "" });
            }
          }
        });
      }
    } else {
      let timezone = Timezones.find(tz => {
        return tz.value === user.timezone;
      });
      // check if user timezone is equal to org timezone
      if (timezone) {
        if (this.props.isMe) {
          if (timezone.value === this.props.TimezoneViewModel.orgTimezone) {
            user.tzDisplayValue = getCurrentlocaleText(
              "org_default_timezone.text",
            );
          } else {
            user.tzDisplayValue = timezone.label;
          }
        } else {
          OrgController.getOrg(user.organization.id, false).then(response => {
            if (response) {
              if (timezone.value === response.timezone) {
                user.tzDisplayValue = getCurrentlocaleText(
                  "org_default_timezone.text",
                );
              } else {
                user.tzDisplayValue = timezone.label;
              }
            }
          });
        }
      }
    }
    return user;
  };

  updateUserInfo = () => {
    let user = this.state.user;
    UserController.getUser(user.id, false, true)
      .then(resp => {
        if (resp) {
          let user = this.updateUserTimezone(resp);
          this.setState({ user: user });
          this.props.UpdateUserInfo(user);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  submit = (values, field) => {
    if (values && this.state.isMe) {
      UserController.updateCurrentUser(values).then(resp => {
        if (resp) {
          let user = this.updateUserTimezone(resp);
          this.setState({ user: user });
          this.props.UiStore.setSuccessMessage(
            getCurrentlocaleText("general.api.update.success.text", {
              0: field,
            }),
          );
        }
      });
    } else if (values && !this.state.isMe) {
      let user = this.state.user;
      UserController.update(values, { id: user.id }).then(resp => {
        if (resp) {
          let user = this.updateUserTimezone(resp);
          this.setState({ user: user });
          this.props.UpdateUserInfo(user);
        }
      });
    }
  };
  /**
   * Intiate 2FA
   */
  initiate2Fa = () => {
    let user_id = this.state.user.id;
    UserController.setupTwofactor(user_id)
      .then(response => {
        if (response) {
          let current_uri = response.uri;
          if (!this.state.is2FaEnabled)
            this.setState({
              show2FA: true,
              current_uri: current_uri,
            });
          else this.setState({ is2FaEnabled: false });
        }
      })
      .catch(error => {});
  };

  /**
   * disable 2FA
   */
  reset2Fa = () => {
    let user_id = this.state.user.id;
    let isCentralAuthOrg =
      this.props.AuthStore.loggedInUserOrg &&
      this.props.AuthStore.loggedInUserOrg.is_central_auth
        ? true
        : false;
    UserController.ResetTwofactor(user_id, true)
      .then(response => {
        if (isCentralAuthOrg) {
          setTimeout(() => {
            this.props.UiStore.SetLoginStatus(false);
            this.props.UiStore.resetStore();
            this.props.AuthStore.resetStore();
            window.location.pathname = "/api/v1/logout";
          }, 2000);
        } else {
          UserController.getCurrentUser()
            .then(response => {
              if (response) {
                this.props.AuthStore.SetStoreData("loggedInUser", response);
                this.setState({ is2FaEnabled: false });
              }
            })
            .catch(error => {
              this.setState({ is2FaEnabled: false });
            });
        }
      })
      .catch(error => {
        this.setState({ is2FaEnabled: false });
      });
  };

  /**
   * disable 2FA
   */
  disable2Fa = () => {
    let user_id = this.state.user.id;
    UserController.DisableTwofactor(user_id)
      .then(response => {
        UserController.getCurrentUser()
          .then(response => {
            if (response) {
              this.props.AuthStore.SetStoreData("loggedInUser", response);
              this.setState({ is2FaEnabled: false });
            }
          })
          .catch(error => {
            this.setState({ is2FaEnabled: false });
          });
      })
      .catch(error => {
        this.setState({ is2FaEnabled: false });
      });
  };

  /**
   * enable 2FA
   */
  enable2Fa = () => {
    let user_id = this.state.user.id;
    UserController.EnableTwofactor(user_id)
      .then(response => {
        UserController.getCurrentUser()
          .then(response => {
            if (response) {
              this.props.AuthStore.SetStoreData("loggedInUser", response);
              this.setState({ is2FaEnabled: true });
            }
          })
          .catch(error => {
            this.setState({ is2FaEnabled: true });
          });
      })
      .catch(error => {
        this.setState({ is2FaEnabled: true });
      });
  };

  /**
   * Intiate 2FA
   */
  Setup2FaOTP = otp => {
    let user_id = this.state.user.id,
      params = { otp: Number(otp) };
    UserController.Confirm2Fa(user_id, params)
      .then(resp => {
        UserController.getCurrentUser()
          .then(response => {
            if (response) {
              this.props.AuthStore.SetStoreData("loggedInUser", response);
              if (!this.state.is2FaEnabled)
                this.setState({
                  show2FA: !this.state.show2FA,
                });
              else this.setState({ is2FaEnabled: false });
            }
          })
          .catch(error => {
            this.setState({ is2FaEnabled: false });
          });
      })
      .catch(error => {});
  };
  checkFortwofactorEnabled = () => {
    let user_org_id =
        this.state.user &&
        this.state.user.organization &&
        this.state.user.organization.id,
      current_org_details = this.props.AuthStore.loggedInUserOrg;
    if (
      current_org_details &&
      current_org_details.policy &&
      current_org_details.policy.two_factor &&
      current_org_details.policy.two_factor.enable
    )
      return current_org_details.policy.two_factor.enable;
    else return false;
  };
  checkFortwofactorEnabledAndForced = () => {
    let current_org_details = this.props.AuthStore.loggedInUserOrg;
    if (
      current_org_details &&
      current_org_details.policy &&
      current_org_details.policy.two_factor &&
      current_org_details.policy.two_factor.force &&
      current_org_details.policy.two_factor.force == true
    ) {
      return true;
    } else {
      return false;
    }
  };
  render() {
    let { user } = this.state;
    let Twofactor_Enabled = this.props.AuthStore.loggedInUser.two_factor;
    let User2faEnabled = Twofactor_Enabled && Twofactor_Enabled.enabled;
    let User2faEnabled_at = formatTimestampToIotiumLastAtTimeString(
      Twofactor_Enabled && Twofactor_Enabled.enabled_at,
    );
    let twoFaEnabledDate = new Date(User2faEnabled_at);
    let isActual2faEnabledDate =
      twoFaEnabledDate instanceof Date && !isNaN(twoFaEnabledDate);
    let org2faForced = this.checkFortwofactorEnabledAndForced();
    let isCentralAuthOrg =
      this.props.AuthStore.loggedInUserOrg &&
      this.props.AuthStore.loggedInUserOrg.is_central_auth
        ? true
        : false;
    return (
      <div>
        {/* Heading */}
        <div className="my-profile-container">
          <div style={{ fontSize: 32, paddingLeft: 10 }}>
            <strong>{user.name ? user.name : user.username}</strong>
          </div>
        </div>
        {/*Content*/}
        <Row gutter={0}>
          <Col span={8} style={{ fontSize: 13 }}>
            <div style={{ height: 50 }}>
              <span>
                <span style={{ marginRight: 4 }}>
                  <Icons
                    type="ai"
                    name="AiOutlineUser"
                    className="profile-icon-style valign-middle"
                  />
                </span>
                <strong>
                  {getCurrentlocaleText("myprofile.fullname.text")}
                </strong>
              </span>
            </div>
            <div style={{ height: 50 }}>
              <span>
                <span style={{ marginRight: 4 }}>
                  <Icons
                    type="ai"
                    name="AiOutlineMail"
                    className="profile-icon-style valign-middle"
                  />
                </span>
                <strong>
                  {getCurrentlocaleText("myprofile.emailaddress.text")}
                </strong>
              </span>
            </div>
            <div style={{ height: 50 }}>
              <span>
                <span style={{ marginRight: 4 }}>
                  <Icons
                    type="ai"
                    name="AiOutlineIdcard"
                    className="profile-icon-style valign-middle"
                  />
                </span>
                <strong>{getCurrentlocaleText("myprofile.role.text")}</strong>
              </span>
            </div>
            {this.state.isMe && !isCentralAuthOrg && (
              <div style={{ height: 50 }}>
                <span>
                  <span style={{ marginRight: 4 }}>
                    <Icons
                      type="ai"
                      name="AiOutlineLock"
                      className="profile-icon-style valign-middle"
                    />
                  </span>
                  <strong>
                    {getCurrentlocaleText("myprofile.password.text")}
                  </strong>
                </span>
              </div>
            )}
            <div style={{ height: 50 }}>
              <span>
                <span style={{ marginRight: 4 }}>
                  <Icons
                    type="fa"
                    name="FaSitemap"
                    className="profile-icon-style valign-middle"
                  />
                </span>
                <strong>
                  {getCurrentlocaleText("myprofile.organization.text")}
                </strong>
              </span>
            </div>
            {(user.timezone || user.timezone === "") && (
              <div style={{ height: 50 }}>
                <span>
                  <span style={{ marginRight: 4 }}>
                    <Icons
                      type="fa"
                      name="FaRegClock"
                      className="profile-icon-style valign-middle"
                    />
                  </span>
                  <strong>
                    {getCurrentlocaleText("myprofile.timezone.text")}
                  </strong>
                </span>
              </div>
            )}
            {(this.checkFortwofactorEnabled() || User2faEnabled) &&
              this.props.isMe && (
                <div style={{ height: 50 }}>
                  <span>
                    <span style={{ marginRight: 4 }}>
                      <Icons
                        type="md"
                        name="MdPhonelinkLock"
                        className="profile-icon-style valign-middle"
                      />
                    </span>
                    <strong>
                      {getCurrentlocaleText("profile.user.2fa.title")}
                    </strong>
                  </span>
                </div>
              )}
          </Col>
          {/* Content */}
          <Col style={{ fontSize: 13 }}>
            <div style={{ height: 50 }}>
              <span>
                {user.name ? (
                  <EditableFormInput
                    type="text"
                    placeholder={getCurrentlocaleText(
                      "myprofile.fullname.text",
                    )}
                    value={user.name}
                    formItemName="name"
                    submit={this.submit}
                    field={getCurrentlocaleText("myprofile.fullname.text")}
                    rules={[
                      {
                        required: true,
                        message: getCurrentlocaleText(
                          "myprofile.please_enter_your_fullname.text",
                        ),
                        whitespace: true,
                      },
                      {
                        max: 255,
                        message: getCurrentlocaleText(
                          "myprofile.full_name_cannot_be_longer_than_255_characters.text",
                        ),
                      },
                      {
                        pattern: /^[A-Za-z0-9 ]*$/,
                        message: getCurrentlocaleText(
                          "myprofile.full_name_must_not_contain_any_special_characters.text",
                        ),
                      },
                    ]}
                  />
                ) : (
                  user.username
                )}
              </span>
            </div>
            <div style={{ height: 50 }}>
              <span>
                {user.email ? user.email : user.username}
                {this.props.AuthStore.IsPermitted("USER:CREATE") ||
                this.props.AuthStore.IsPermitted("ORG:SUPPORT") ? (
                  <span>
                    {!user.email_verified && (
                      <span
                        onClick={() => {
                          this.enableResendLink(user);
                        }}
                        style={{ verticalAlign: "middle" }}
                      >
                        {user.verify_email_triggered_at ? (
                          <Popover
                            content={getCurrentlocaleText(
                              "user.support.email_triggered_at.message_text",
                              {
                                0: formatTimestampToIotiumLastAtTimeString(
                                  user.verify_email_triggered_at,
                                ),
                              },
                            )}
                          >
                            <Badge
                              count={user.verify_email_count}
                              className="verify-count-info"
                            >
                              <Button
                                style={{ marginLeft: 10, fontSize: 10 }}
                                size={"small"}
                              >
                                <Icons
                                  type="fa"
                                  name="FaRegClock"
                                  className="profile-icon-style valign-middle"
                                />
                                {getCurrentlocaleText(
                                  "user.support.resend_email.forcebutton.text",
                                )}
                              </Button>
                            </Badge>
                          </Popover>
                        ) : (
                          <Popover
                            content={
                              this.state.isMe
                                ? getCurrentlocaleText(
                                    "user.profile.unverified_email_reintiate.info_message",
                                  )
                                : getCurrentlocaleText(
                                    "user.unverified_email_reintiate_for_others.info_message",
                                  )
                            }
                            overlayStyle={{ width: 350 }}
                          >
                            {" "}
                            <Badge
                              count={user.verify_email_count}
                              className="verify-count-info"
                            >
                              <Button
                                style={{ marginLeft: 10, fontSize: 10 }}
                                size={"small"}
                              >
                                {getCurrentlocaleText(
                                  "user.support.resend_email.forcebutton.text",
                                )}
                              </Button>
                            </Badge>
                          </Popover>
                        )}
                      </span>
                    )}
                  </span>
                ) : !user.email_verified ? (
                  <span style={{ marginLeft: 5 }}>
                    <Popover
                      overlayStyle={{ width: 350 }}
                      content={
                        <span>
                          {this.state.isMe
                            ? getCurrentlocaleText(
                                "user.profile.unverified_email.info_message",
                              )
                            : getCurrentlocaleText(
                                "user.unverified_email_for_others.info_message",
                              )}
                        </span>
                      }
                    >
                      <Tag>
                        {getCurrentlocaleText(
                          "user.unverified.readonly_user.message",
                        )}{" "}
                      </Tag>
                    </Popover>
                  </span>
                ) : null}
                {/*<EditableFormInput
                    type="email"
                    placeholder="Email Address"
                    value={user.email}
                    formItemName="email"
                    submit={this.submit}
                    field="Email"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your email address.",
                        type: "email",
                      },
                    ]}
                  />*/}
              </span>
            </div>
            <div style={{ height: 50 }}>
              <span>
                {user.roles && user.roles[0]
                  ? user.roles[0].name
                  : NOT_APPLICAPLE}
              </span>
            </div>
            {this.state.isMe && !isCentralAuthOrg && (
              <div style={{ height: 50 }}>
                <a className="hover">
                  <span onClick={this.enableChangeModal.bind(this)}>
                    {getCurrentlocaleText("myprofile.change_password.text")}
                  </span>
                  {this.props.UiStore.ChangePasswordMdl ? (
                    <ChangePassword />
                  ) : null}
                </a>
              </div>
            )}
            <div style={{ height: 50 }}>
              <span>
                {user.organization ? user.organization.name : NOT_APPLICAPLE}
              </span>
            </div>
            {(user.timezone || user.timezone === "") && (
              <div style={{ height: 50 }}>
                <span>
                  <EditableUserTimezone
                    placeholder={getCurrentlocaleText(
                      "myprofile.timezone.text",
                    )}
                    initialValue={user.timezone}
                    formItemName="timezone"
                    submit={this.submit}
                    field={getCurrentlocaleText("myprofile.timezone.text")}
                    displayValue={user.tzDisplayValue}
                    orgTimezone={this.state.orgTimezone}
                  />
                </span>
              </div>
            )}
            {this.checkFortwofactorEnabled() && this.props.isMe && (
              <div style={{ height: 50 }}>
                <span>
                  {!Twofactor_Enabled && !isCentralAuthOrg ? (
                    <Button
                      onClick={() => {
                        this.initiate2Fa();
                      }}
                      size={"small"}
                      style={{ marginRight: 8 }}
                    >
                      <Icons type="md" name="MdPhonelinkLock" />
                      {getCurrentlocaleText("profile.user.2fa.setup.text")}
                    </Button>
                  ) : (
                    <span>
                      <Popconfirm
                        overlayClassName={
                          "custom-inline-popup inline-confirm-totp-title"
                        }
                        title={
                          <div
                            className="inline-popup"
                            style={{ marginLeft: -20 }}
                          >
                            <div className="inline-popup-header">
                              {" "}
                              <p className="inline-popup-title">
                                <Icons type="md" name="MdPhonelinkErase" />{" "}
                                {getCurrentlocaleText(
                                  "user.list.2fa.reset.confirmation.title",
                                ) + "?"}
                              </p>
                            </div>
                            <p className="inline-popup-body">
                              {
                                <span>
                                  <p>
                                    {getCurrentlocaleText(
                                      "user.profile.2fa.reset.confirmation.message",
                                    )}
                                  </p>
                                </span>
                              }
                            </p>
                          </div>
                        }
                        okText={getCurrentlocaleText(
                          "profile.user.2fa.reset.text",
                        )}
                        cancelText="No"
                        onConfirm={this.reset2Fa}
                      >
                        <Button size={"small"} style={{ marginRight: 8 }}>
                          {" "}
                          <Icons type="md" name="MdPhonelinkErase" />
                          {getCurrentlocaleText("profile.user.2fa.reset.text")}
                        </Button>
                      </Popconfirm>
                      <Popconfirm
                        disabled={org2faForced && User2faEnabled}
                        overlayClassName={
                          "custom-inline-popup inline-confirm-totp-title"
                        }
                        title={
                          <div
                            className="inline-popup"
                            style={{ marginLeft: -20 }}
                          >
                            <div className="inline-popup-header">
                              {" "}
                              <p className="inline-popup-title">
                                <Icon
                                  type={User2faEnabled ? "stop" : "check"}
                                  style={{ fontSize: 10 }}
                                />{" "}
                                {User2faEnabled
                                  ? getCurrentlocaleText(
                                      "user.list.2fa.disable.confirmation.title",
                                    )
                                  : getCurrentlocaleText(
                                      "user.list.2fa.enable.confirmation.title",
                                    ) + "?"}
                              </p>
                            </div>
                            <p className="inline-popup-body">
                              {
                                <span>
                                  <p>
                                    {User2faEnabled
                                      ? getCurrentlocaleText(
                                          "user.profile.2fa.disable.confirmation.message",
                                        )
                                      : getCurrentlocaleText(
                                          "user.profile.2fa.enable.confirmation.message",
                                        )}
                                  </p>
                                </span>
                              }
                            </p>
                          </div>
                        }
                        okText={
                          User2faEnabled
                            ? getCurrentlocaleText(
                                "profile.user.2fa.disable.text",
                              )
                            : getCurrentlocaleText(
                                "profile.user.2fa.enable.text",
                              )
                        }
                        cancelText="No"
                        onConfirm={
                          User2faEnabled ? this.disable2Fa : this.enable2Fa
                        }
                      >
                        <Button
                          disabled={org2faForced && User2faEnabled}
                          size={"small"}
                          style={{
                            marginRight: 8,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          {" "}
                          <Icon
                            type={User2faEnabled ? "stop" : "check"}
                            style={{ fontSize: 10 }}
                          />
                          {User2faEnabled
                            ? getCurrentlocaleText(
                                "profile.user.2fa.disable.text",
                              )
                            : getCurrentlocaleText(
                                "profile.user.2fa.enable.text",
                              )}
                        </Button>
                      </Popconfirm>
                    </span>
                  )}

                  {!User2faEnabled && (
                    <PopOverHelp
                      isHelpOnForm={true}
                      height={130}
                      width={275}
                      placement="right"
                      helpData={{
                        title: getCurrentlocaleText(
                          "user.profiles.2fa.setup.help.title.text",
                        ),
                        data: [
                          {
                            subtitle: "",
                            content: getCurrentlocaleText(
                              "user.profiles.2fa.setup.help.text",
                            ),
                          },
                        ],
                      }}
                    />
                  )}
                  {User2faEnabled && (
                    <span style={{ marginLeft: 5 }}>
                      <Icons
                        type="fa"
                        name="FaInfoCircle"
                        className="profile-2fa-icon-style"
                      />
                      {isActual2faEnabledDate
                        ? getCurrentlocaleText(
                            "user_profiles.2fa.enabledOn.since.text",
                          )
                        : getCurrentlocaleText(
                            "user_profiles.2fa.enabled.since.text",
                          )}{" "}
                      {User2faEnabled_at}
                    </span>
                  )}
                </span>
              </div>
            )}
          </Col>
        </Row>
        <div>
          <div className="myprofile-divider" />
          <div style={{ lineHeight: "2" }}>
            {/* user last login info*/}
            <div>
              <span>
                <span style={{ marginRight: 2 }}>
                  <Icons
                    type="fa"
                    name="FaInfoCircle"
                    className="profile-2fa-icon-style"
                  />
                </span>
                <span>
                  {/*All Last At Time stamp should be shown in this format across IOTIUM UI and this will displays value as timestamp when it exceeds 24hrs and above*/}
                  {getCurrentlocaleText(
                    "users.list.more_info.last_login.label.text",
                  )}
                  {": "}
                  {formatTimestampToIotiumLastAtTimeString(user.last_login_at)}
                </span>
              </span>
            </div>
            {!isCentralAuthOrg && (
              <div>
                <span>
                  <span style={{ marginRight: 2 }}>
                    <Icons
                      type="fa"
                      name="FaInfoCircle"
                      className="profile-2fa-icon-style"
                    />
                  </span>
                  <span>
                    {getCurrentlocaleText(
                      "users.list.more_info.password_expiry.label.text",
                    )}
                    {": "}
                    {getDaysLeftInfo(user.password_expire_at)}
                    {this.state.isMe &&
                      ". " +
                        getCurrentlocaleText(
                          "myprofile.to_change_password_now_plese_click_change_password.text",
                        )}
                  </span>
                </span>
              </div>
            )}
            {/*Session details*/}
            <div>
              <span>
                <span style={{ marginRight: 2 }}>
                  <Icons
                    type="fa"
                    name="FaInfoCircle"
                    className="profile-2fa-icon-style"
                  />
                  <UserSessionView
                    userId={!this.state.isMe ? user.id : null}
                    isMe={this.state.isMe}
                    isCentralAuthUser={isCentralAuthOrg}
                  />
                </span>
              </span>
            </div>
          </div>
          {this.state.show2FA && (
            <TwoFactorSetup
              onFinish={otp => {
                this.Setup2FaOTP(otp);
              }}
              onCancel={() => {
                this.setState({ show2FA: false });
              }}
              current_uri={this.state.current_uri}
              visibility={this.state.show2FA}
            />
          )}
        </div>
        <ResendVerificationModal
          visibility={this.state.showResendModal}
          requestInProgress={this.state.requestInProgress}
          user_info={this.state.user}
          hidePopup={this.hidePopup}
          updateAfterCall={this.updateUserInfo}
        />
      </div>
    );
  }
}

export default MyProfileDetails;
