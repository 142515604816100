import React, { Component } from "react";
import { Select, Form } from "antd";
import { observer, inject } from "mobx-react";
import VolumeModal from "./forms/volumeModal";
import SecretController from "controller/SecretController";

const FormItem = Form.Item;
const Option = Select.Option;

@inject("AuthStore", "UiStore", "OrgViewModel")
@observer
class VolumeCreation extends Component {
  constructor(props) {
    super(props);
    this.orgModel = this.props.OrgViewModel;
    this.state = {
      loading: false,
      clearmodal: false,
    };
  }

  componentDidMount() {
    if (this.props.actionType != "edit") {
      this.props.UiStore.volumes.volumeName = "";
      this.props.UiStore.volumes.assignedServiceCount = 0;
    }
  }

  closeModal = (e = null) => {
    /* clear service form values from store */
    this.props.UiStore.resetVolumes();
    this.props.UiStore.SetStoreData("serviceFormData", {
      uploadedLicenseFileName: [],
    });
    this.setState({ loading: false });
  };

  getSecretObj = () => {
    let secret_name = this.props.UiStore.volumes.volumeName;

    let SecretObj = {
      name: secret_name,
      data: {
        ...this.props.UiStore.volumes.data,
      },
      type: "Opaque",
      create_under:
        this.orgModel.org && this.orgModel.org.id
          ? this.orgModel.org.id
          : this.orgModel.inode.organization.id,
    };

    return SecretController.create(SecretObj);
  };

  getSecretUUID = () => {
    /*Convert object to array*/

    if (this.props.actionType === "edit") {
      this.updateLicense();
    } else {
      this.getSecretObj().then(
        responses => {
          this.props.UiStore.successMessage = ` Volume created successfully`;
          this.props.UiStore.temporaryLicenseData = {};
          this.closeModal();
          this.props.afterCall ? this.props.afterCall() : "";
        },
        error => {
          this.setState({ loading: false });
        },
      );
    }
  };

  updateLicense = () => {
    let secretObj = this.props.UiStore.serviceFormData.rowDetails
      .selectedLicenseDetails;

    /* updating secret data with newly updated volume data */
    this.setState({ loading: true });

    secretObj.data = {
      ...this.props.UiStore.volumes.data,
    };
    secretObj.name = this.props.UiStore.volumes.volumeName;
    SecretController.update(secretObj, {
      id: secretObj.id,
    })
      .then(response => {
        this.props.UiStore.temporaryLicenseData = {};
        this.setState({ loading: false });
        this.closeModal();
        this.props.afterCall ? this.props.afterCall() : "";
      })
      .catch(error => {
        this.setState({ loading: false });
      });
  };

  onSubmit = () => {
    this.setState({ loading: true });
    this.getSecretUUID();
  };

  render() {
    return (
      <div>
        <VolumeModal
          onSubmit={this.onSubmit}
          resource="secret"
          type={this.props.actionType !== "edit" ? "primary" : ""}
          title={this.props.title}
          okText={
            this.props.actionType === "edit" ? " Update Volume" : " Add Volume"
          }
          closeModal={this.closeModal}
          help={true}
          modalWidth="70%"
          maskClosable={false}
          confirmLoading={this.state.loading}
          afterClose={this.state.clearmodal}
          {...this.props}
        />
      </div>
    );
  }
}

export default VolumeCreation;
