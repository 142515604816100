import React, { Component } from "react";
import { Card, Row, Col, Empty } from "antd";
import { getCurrentlocaleText } from "Core/Utils";
import { observer, inject } from "mobx-react";
import NetstatsOverviewChart from "components/Stats/Charts/NetstatsOverviewChart";
import SummaryOverViewChart from "components/Stats/Charts/SummaryOverViewChart";
import Icons from "components/UI-Components/Icons";
import WaterMarkChart from "components/Stats/Charts/WaterMarkChart";
import { Link } from "react-router-dom";
import InodeController from "../../../controller/InodeController";
import ClusterController from "../../../controller/ClusterController";
import { stringify } from "flatted";

@inject("AuthStore", "InodeViewModel", "OrgViewModel", "ClusterViewModel")
@observer
class OverviewChart extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = this.props.InodeViewModel;
    this.clustereModel = this.props.ClusterViewModel;
    this.orgId = this.props.OrgViewModel.org.id;
    this.inodeId =
      this.inodeModel.inode && this.inodeModel.inode.id
        ? this.inodeModel.inode.id
        : this.props.match.params.nodeId;
    this.state = {
      interfaces: this.props.interfaces,
      loading: false,
      currentInterface: "eth0",
      data: this.props.dataList,
      FileSystemInfo: [],
      widgetCount: 0,
      extraInfo: this.props.extraInfo,
    };
  }

  //update the current layout in the stream.
  toggle = count => {
    this.setState({ widgetCount: count });
  };
  /*------------------------------------------------------------------------------------------------------------*/

  // Following the Metric naming convention
  humanizeMetric = num => {
    num = num >= 0 ? num : 0;
    var ret = this.humanize(num, 1000, ["TB", "GB", "MB", "KB", "Bytes"]);
    return ret[0].toFixed(2) + " " + ret[1];
  };
  humanize = (num, size, units) => {
    var unit;
    for (unit = units.pop(); units.length && num >= size; unit = units.pop()) {
      num /= size;
    }
    return [num, unit];
  };

  UNSAFE_componentWillMount() {
    let currentInode = this.inodeModel.inode;
    if (
      currentInode &&
      currentInode.cluster &&
      currentInode.cluster.id &&
      !this.clustereModel.isTrafficInsightDataExist
    ) {
      this.getClusterTrafficInsight(currentInode.cluster.id);
    } else if (!this.inodeModel.isTrafficInsightDataExist) {
      this.getTrafficInsight();
    }

    if (
      currentInode &&
      currentInode.cluster &&
      currentInode.cluster.id &&
      !this.clustereModel.isDeviceDiscoveryExist
    ) {
      ClusterController.getDeviceDiscovery(currentInode.cluster.id);
    } else if (!this.inodeModel.isDeviceDiscoveryExist) {
      InodeController.getDeviceDiscovery(this.inodeId);
    }
  }

  UNSAFE_componentWillReceiveProps(newprops) {
    if (stringify(this.props) !== stringify(newprops)) {
      this.setState({
        loading: newprops.loading,
        data: newprops.dataList,
        interfaces: newprops.interfaces,
        cpu_overview: newprops.cpu_overview,
        memory_overview: newprops.memory_overview,
        cpuLive: newprops.cpuLive,
        memoryLive: newprops.memoryLive,
        fileSystem: newprops.fileSystem,
        extraInfo: newprops.extraInfo,
      });
    }
  }

  getTrafficInsight = () => {
    this.inodeModel.getTrafficInsightLoading = true;

    InodeController.getTrafficInsight(this.inodeId)
      .then(response => {
        if (response) {
          this.inodeModel.setTrafficInsightData({
            response,
          });
          this.inodeModel.getTrafficInsightLoading = false;
        }
      })
      .catch(err => {
        this.inodeModel.getTrafficInsightLoading = false;
      });
  };

  getClusterTrafficInsight = clusterId => {
    this.inodeModel.getTrafficInsightLoading = true;
    ClusterController.getTrafficInsight(clusterId)
      .then(resp => {
        if (resp) {
          this.clustereModel.setTrafficInsightData({
            resp,
          });
          this.inodeModel.getTrafficInsightLoading = false;
        }
      })
      .catch(err => {
        this.inodeModel.getTrafficInsightLoading = false;
      });
  };

  render() {
    let isVirtual =
      this.inodeModel.inode &&
      this.inodeModel.inode.profile &&
      this.inodeModel.inode.profile.name &&
      this.inodeModel.inode.profile.name.toLowerCase() == "virtual"
        ? true
        : false;

    return (
      <div className="bg-white">
        <div>
          <Row justify="space-evenly">
            {!this.state.loading || this.state.data.length > 1 ? (
              <Col flex="auto">
                <Card
                  hoverable={false}
                  title={
                    <div className="justify-space-between">
                      <span>
                        {getCurrentlocaleText("stats.overview.title.text")}
                      </span>
                      <Link
                        to={`/orgs/${this.orgId}/inodes/${this.inodeId}/stats`}
                      >
                        <Icons type="bs" name="BsArrowUpRight" />
                      </Link>
                    </div>
                  }
                  className="mb-0"
                  style={{ height: 340 }}
                >
                  <Row justify="space-evenly" align="middle">
                    <Col>
                      <SummaryOverViewChart
                        interfaces={this.state.interfaces}
                        data={this.state.data}
                        iconType="md"
                        iconName="MdMemory"
                        highValue={
                          this.state.cpu_overview &&
                          this.state.cpu_overview.cpu_max
                            ? this.state.cpu_overview.cpu_max + "%"
                            : 0
                        }
                        value={
                          this.state.cpuLive && this.state.cpuLive
                            ? this.state.cpuLive
                            : "NA"
                        }
                        avgValue={
                          this.state.cpu_overview &&
                          this.state.cpu_overview.cpu_avg
                            ? this.state.cpu_overview.cpu_avg + "%"
                            : 0
                        }
                        type="CPU Usage"
                        {...this.props}
                        redirectToAdvnancedStats={
                          this.props.redirectToAdvnancedStats
                        }
                        extraInfo={
                          this.state.extraInfo &&
                          this.state.extraInfo.cpu &&
                          this.state.extraInfo.cpu.used &&
                          this.state.extraInfo.cpu.numberof_cores
                            ? {
                                title_1: getCurrentlocaleText(
                                  "stats.legend.fs.used.text",
                                ),
                                value_1:
                                  (this.state.extraInfo.cpu.numberof_cores !=
                                  this.state.extraInfo.cpu.used
                                    ? this.state.extraInfo.cpu.used.toFixed(2)
                                    : this.state.extraInfo.cpu.used) + " Cores",
                                title_2: getCurrentlocaleText(
                                  "stats.legend.fs.total.text",
                                ),
                                value_2:
                                  this.state.extraInfo.cpu.numberof_cores +
                                  " Cores",
                              }
                            : null
                        }
                      />
                    </Col>
                    <Col>
                      <SummaryOverViewChart
                        interfaces={this.state.interfaces}
                        data={this.state.data}
                        iconType="fa"
                        iconName="FaMemory"
                        highValue={
                          this.state.memory_overview &&
                          this.state.memory_overview.memory_max
                            ? this.humanizeMetric(
                                this.state.memory_overview.memory_max,
                              )
                            : 0
                        }
                        value={
                          this.state.memoryLive && this.state.memoryLive
                            ? this.state.memoryLive
                            : "NA"
                        }
                        avgValue={
                          this.state.memory_overview &&
                          this.state.memory_overview.memory_avg
                            ? this.humanizeMetric(
                                this.state.memory_overview.memory_avg,
                              )
                            : 0
                        }
                        type="Memory Usage"
                        {...this.props}
                        redirectToAdvnancedStats={
                          this.props.redirectToAdvnancedStats
                        }
                        extraInfo={
                          this.state.extraInfo &&
                          this.state.extraInfo.memory &&
                          this.state.extraInfo.memory.used &&
                          this.state.extraInfo.memory.total &&
                          this.state.extraInfo.memory.cache
                            ? {
                                title_1: getCurrentlocaleText(
                                  "stats.legend.fs.used.text",
                                ),
                                value_1: this.humanizeMetric(
                                  this.state.extraInfo.memory.used,
                                ),
                                title_2: getCurrentlocaleText(
                                  "stats.legend.fs.total.text",
                                ),
                                value_2: this.humanizeMetric(
                                  this.state.extraInfo.memory.total,
                                ),
                                title_3: getCurrentlocaleText(
                                  "stats.legend.memory.cache.text",
                                ),
                                value_3: this.humanizeMetric(
                                  this.state.extraInfo.memory.cache,
                                ),
                                title_4: getCurrentlocaleText(
                                  "stats.legend.fs.free.text",
                                ),
                                value_4: this.humanizeMetric(
                                  this.state.extraInfo.memory.free,
                                ),
                              }
                            : null
                        }
                      />
                    </Col>
                    <Col>
                      <SummaryOverViewChart
                        interfaces={this.state.interfaces}
                        data={this.state.data}
                        iconType="fa"
                        iconName="FaMemory"
                        highValue={
                          this.state.memory_overview &&
                          this.state.memory_overview.memory_max
                            ? this.humanizeMetric(
                                this.state.memory_overview.memory_max,
                              )
                            : 0
                        }
                        avgValue={
                          this.state.memory_overview &&
                          this.state.memory_overview.memory_avg
                            ? this.humanizeMetric(
                                this.state.memory_overview.memory_avg,
                              )
                            : 0
                        }
                        value={
                          this.state.fileSystem && this.state.fileSystem
                            ? this.state.fileSystem
                            : "NA"
                        }
                        type="Filesystem Usage"
                        {...this.props}
                        redirectToAdvnancedStats={
                          this.props.redirectToAdvnancedStats
                        }
                        extraInfo={
                          this.state.extraInfo &&
                          this.state.extraInfo.filesystem &&
                          this.state.extraInfo.filesystem.used &&
                          this.state.extraInfo.filesystem.total
                            ? {
                                title_1: getCurrentlocaleText(
                                  "stats.legend.fs.used.text",
                                ),
                                value_1: this.humanizeMetric(
                                  this.state.extraInfo.filesystem.used,
                                ),
                                title_2: getCurrentlocaleText(
                                  "stats.legend.fs.total.text",
                                ),
                                value_2: this.humanizeMetric(
                                  this.state.extraInfo.filesystem.total,
                                ),
                              }
                            : null
                        }
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            ) : (
              <Col
                span={
                  (this.inodeModel.isTrafficInsightDataExist ||
                    this.clustereModel.isTrafficInsightDataExist) &&
                  !isVirtual
                    ? 19
                    : 24
                }
              >
                <Card
                  hoverable={false}
                  title={
                    <div className="justify-space-between">
                      <span>
                        {getCurrentlocaleText("stats.overview.title.text")}
                      </span>
                    </div>
                  }
                  bordered={false}
                  className="mb-0 no-metrics-panel"
                  style={{ height: 340 }}
                >
                  <Empty
                    image={
                      <span
                        onClick={this.props.redirectToAdvnancedStats}
                        className="iot-pointer-cursor"
                        title={getCurrentlocaleText(
                          "stats.view_advanced.title.text",
                        )}
                      >
                        <Icons
                          type="fa"
                          name="FaChartLine"
                          className="no-metrics-empty-info"
                        />
                      </span>
                    }
                    description={
                      <span
                        className="iot-pointer-cursor"
                        onClick={this.props.redirectToAdvnancedStats}
                        title={getCurrentlocaleText(
                          "stats.view_advanced.title.text",
                        )}
                      >
                        {getCurrentlocaleText(
                          "stats.overview.show_advanced.text",
                        )}
                        <a
                          title="View Metrics Dashboard"
                          onClick={this.props.redirectToAdvnancedStats}
                        >
                          {getCurrentlocaleText(
                            "stats.advanced.page.title.text",
                          )}
                        </a>
                      </span>
                    }
                  />
                </Card>
              </Col>
            )}
          </Row>
          {this.state.loading && <span>Loading Chart</span>}
        </div>
      </div>
    );
  }
}

export default OverviewChart;
