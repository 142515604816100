import React, { Component } from "react";
import { observer, inject } from "mobx-react";

import { Row, Col, Card, Button } from "antd";
import Icons from "components/UI-Components/Icons";
import BreadcrumbComponent from "components/UI-Components/BreadcrumbComponent";
import OrgSelector from "components/Orgs/OrgSelector";
import PolicyForm from "components/Forms/PolicyForm";
import OrgController from "controller/OrgController";
import LoadingComponent from "components/UI-Components/LoadingComponent";

import { copyObject, getCurrentlocaleText, IOTIUM_USER } from "Core/Utils";
import AccessDenied from "components/Containers/AccessDenied";
import { ROOT_ORG } from "Core/Utils";

@inject("UiStore", "AuthStore")
@observer
class PolicySettings extends Component {
  constructor(props) {
    super(props);
    this.UiStore = this.props.UiStore;
    this.isView =
      this.props.match &&
      this.props.match.path &&
      this.props.match.path.indexOf("/view/policies") !== -1
        ? true
        : false;
    this.orgId =
      this.props.match && this.props.match.params
        ? this.props.match.params.id
        : null;
    this.state = {
      orgName: null,
      BreadcrumbList: [],
      org: null,
      loading: true,
      twoFactor: false,
      twoFactorForce: false,
      isMe: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.getcurrentOrg(this.props.match.params.id);
  }
  getcurrentOrg = orgId => {
    OrgController.getOrgById(orgId, true).then(response => {
      if (response) {
        let org = copyObject(response);
        let orgName = org.name;
        let BreadcrumbList = [
          {
            name: orgName,
            link: "dashboard/" + orgId,
          },
          {
            name: getCurrentlocaleText("policies.text"),
            link: !this.isView ? `/policies` : `/view/policies`,
          },
        ];
        let currentUserId = this.UiStore.currentUser
          ? this.UiStore.currentUser.id
          : null;
        this.setState({
          BreadcrumbList: BreadcrumbList,
          orgName: orgName,
          org: org,
          loading: false,
          isMe:
            response.is_me && currentUserId !== IOTIUM_USER
              ? response.is_me
              : false,
          twoFactor:
            response.policy.two_factor && response.policy.two_factor.enable
              ? true
              : false,
          twoFactorForce:
            response.policy.two_factor && response.policy.two_factor.force
              ? true
              : false,
        });
      }
    });
  };

  UNSAFE_componentWillReceiveProps(props) {
    if (this.props.match.params.id !== props.match.params.id) {
      this.getcurrentOrg(props.match.params.id);
    }
  }

  submitForm = () => {
    this.policyForm.validateFields((err, values) => {
      if (!err) {
        if (values) {
          let policyObject = {
            network: {
              max_network_limit: values.max_network_limit
                ? values.max_network_limit
                : 1,
              max_network_per_node: values.max_network_per_node
                ? values.max_network_per_node
                : 1,
              max_firewall_selector: values.max_firewall_selector
                ? values.max_firewall_selector
                : 1,
              max_route_limit: values.max_route_limit
                ? values.max_route_limit
                : 1,
              representation_network_automation: {
                enable: values.representationNetworkAutomation
                  ? values.representationNetworkAutomation
                  : false,
              },
            },
            firewall: {
              max_firewall_group_limit: values.max_firewall_group_limit
                ? values.max_firewall_group_limit
                : 1,
              max_rules_per_firewallgroup: values.max_rules_per_firewallgroup
                ? values.max_rules_per_firewallgroup
                : 1,
            },
            apikey: {
              enable: values.apikey ? values.apikey : false,
            },
            webhook: {
              enable: values.webhook,
              max_webhook_per_user: values.max_webhook_per_user
                ? values.max_webhook_per_user
                : 1,
              max_webhook_subscription_per_user: values.max_webhook_subscription_per_user
                ? values.max_webhook_subscription_per_user
                : 1,
            },
            cluster: {
              enable: values.cluster ? values.cluster : false,
              max_node_per_cluster: values.max_node_per_cluster
                ? values.max_node_per_cluster
                : 1,
              max_cluster_per_organization: values.max_cluster_per_organization
                ? values.max_cluster_per_organization
                : 1,
              max_candidate_per_cluster: values.max_candidate_per_cluster
                ? values.max_candidate_per_cluster
                : 1,
            },
            oneArmPolicy: {
              enable: values.one_arm_mode ? values.one_arm_mode : false,
            },
            inode_hw_monitoring: {
              enable: values.inode_hw_monitoring
                ? values.inode_hw_monitoring
                : false,
            },
            unified_cloud_gateway: {
              enable: values.unified_cloud_gateway
                ? values.unified_cloud_gateway
                : false,
            },
            two_factor: {
              enable: this.state.twoFactor ? this.state.twoFactor : false,
              force:
                this.state.twoFactor && this.state.twoFactorForce
                  ? this.state.twoFactorForce
                  : false,
            },
            headless_mode: {
              enable: values.headless_mode ? values.headless_mode : false,
            },
            software_upgrade: {
              channel:
                values.upgrade_policy == false
                  ? "OFF"
                  : values.upgrade_policy_channel,
            },
            vlan_support: {
              enable: values.vlan_support,
            },
            max_labels_per_resource: values.max_labels_per_resource
              ? values.max_labels_per_resource
              : 0,
            branding: {
              enable: values.branding_enable ? values.branding_enable : false,
              inherit_from_parent:
                values.branding_inherit_from_parent !== undefined ||
                values.branding_inherit_from_parent !== null
                  ? values.branding_inherit_from_parent
                  : null,
            },
            cli_config_rollback: {
              enable: values.cliConfigRollback
                ? values.cliConfigRollback
                : false,
            },
            download: {
              activity: {
                max_download_per_user: values.activity_max_download_per_user
                  ? values.activity_max_download_per_user
                  : 0,
              },
              events: {
                max_download_per_user: values.event_max_download_per_user
                  ? values.event_max_download_per_user
                  : 0,
              },
            },
            one_arm_mode: {
              enable: values.one_arm_mode ? values.one_arm_mode : false,
            },
            service: {
              max_service_per_node: values.max_service_per_node,
              listener: {
                enable: values.service_listener
                  ? values.service_listener
                  : false,
              },
            },
          };
          // Add bellow policy only the non central auth org
          if (this.state.org && !this.state.org.is_central_auth) {
            policyObject.session = {
              minutes_to_expire: values.min_to_expire_session
                ? values.min_to_expire_session
                : 0,
            };
            policyObject.forgot_password = {
              minutes_to_expire: values.min_to_expire_forgot_password
                ? values.min_to_expire_forgot_password
                : 0,
            };
            policyObject.verify_email = {
              minutes_to_expire: values.min_to_expire_verify_email
                ? values.min_to_expire_verify_email
                : 0,
            };
            policyObject.password = {
              rules: values.rule_data.filter(obj => obj != null),
              max_allowed_login_failures: values.max_allowed_login_failures
                ? values.max_allowed_login_failures
                : 0,
              expire_password_in_days: values.expire_password_in_days
                ? values.expire_password_in_days
                : 0,
              password_reuse_prevention: values.password_reuse_prevention
                ? values.password_reuse_prevention
                : 0,
            };
          }

          if (this.orgId !== ROOT_ORG) {
            let addData = {
              support_capability: {
                enable: values.supportCapability
                  ? values.supportCapability
                  : false,
              },
              child_organization: {
                enable: values.childOrganization
                  ? values.childOrganization
                  : false,
              },
              software_upgrade_capability: {
                enable: values.softwareUpgradeCapability
                  ? values.softwareUpgradeCapability
                  : false,
              },
              cloudroute_configure_capability: {
                enable: values.cloudroute_configure_capability
                  ? values.cloudroute_configure_capability
                  : false,
              },
            };
            policyObject = { ...policyObject, ...addData };
          }

          OrgController.updateOrgPolicy(
            policyObject,
            this.props.match.params.id,
          )
            .then(response => {
              if (response) {
                this.UiStore.setSuccessMessage(
                  getCurrentlocaleText("policies.success.message"),
                );
                this.cancelForm();
              }
            })
            .catch(error => {});
        }
      }
    });
  };
  updateTwoFactor = value => {
    this.setState({ twoFactor: value });
  };
  updateTwoFactorForce = value => {
    this.setState({ twoFactorForce: value });
  };
  cancelForm = () => {
    try {
      let pathname =
        window.location && window.location.pathname
          ? window.location.pathname.split("/")
          : [];
      let prefix = "orgs";
      if (pathname.length > 1) {
        prefix = pathname[1];
      }
      this.props.history.push(`/${prefix}/${this.props.match.params.id}/orgs`);
    } catch (e) {}
  };

  render() {
    let { orgName, BreadcrumbList } = this.state;
    let container = (
      <div>
        {this.props.AuthStore.IsPermitted("ORG:VIEW") ? (
          <div>
            <Row gutter={2} type="flex" align="top" className="policies-page">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 24 }}
              >
                <Card
                  title={
                    <Row gutter={2} type="flex" align="top">
                      <Col span={12}>
                        <div className="iot-main-card-title">
                          <div style={{ display: "inline-block" }}>
                            <Icons type="fa" name="FaWrench" />
                          </div>
                          <div
                            style={{
                              display: "inline-block",
                              position: "absolute",
                              marginLeft: 7,
                            }}
                          >
                            {getCurrentlocaleText("policies.text")}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  }
                >
                  <Row gutter={2} type="flex" align="top">
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}
                      xl={{ span: 24 }}
                      className="card-grid"
                    >
                      <div>
                        <PolicyForm
                          viewOnly={
                            !this.props.AuthStore.IsPermitted("ORG:CREATE") ||
                            this.isView
                          }
                          orgId={this.state.org && this.state.org.id}
                          ref={c => (this.policyForm = c)}
                          org={this.state.org}
                          updateTwoFactor={this.updateTwoFactor}
                          updateTwoFactorForce={this.updateTwoFactorForce}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24} className="col-center">
                      <div style={{ marginTop: 25 }}>
                        <Button
                          style={{ marginRight: 10 }}
                          title={
                            this.props.AuthStore.IsPermitted("ORG:CREATE") &&
                            !this.state.isMe &&
                            !this.isView
                              ? getCurrentlocaleText("cancel.button.text")
                              : getCurrentlocaleText("close.form.button.text")
                          }
                          onClick={this.cancelForm}
                        >
                          {this.props.AuthStore.IsPermitted("ORG:CREATE") &&
                          !this.state.isMe &&
                          !this.isView
                            ? getCurrentlocaleText("cancel.button.text")
                            : getCurrentlocaleText("close.form.button.text")}
                        </Button>
                        {this.props.AuthStore.IsPermitted("ORG:CREATE") &&
                          !this.state.isMe &&
                          !this.isView && (
                            <Button
                              type="primary"
                              onClick={this.submitForm}
                              title={getCurrentlocaleText("save.button.text")}
                            >
                              <Icons
                                type="ai"
                                name="AiOutlineSave"
                                style={{ margin: 0 }}
                              />{" "}
                              {getCurrentlocaleText("save.button.text")}
                            </Button>
                          )}
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </div>
        ) : (
          <AccessDenied />
        )}
      </div>
    );

    return (
      <div>
        <Row gutter={16} type="flex" align="middle">
          <Col lg={{ span: 18 }} xl={{ span: 20 }}>
            <h2 className="page-title">
              <Icons type="fa" name="FaSitemap" />
              {"  "}
              {orgName ? orgName : null}
            </h2>
            <BreadcrumbComponent
              {...this.props}
              BreadcrumbList={BreadcrumbList}
            />
          </Col>
          <Col lg={{ span: 6 }} xl={{ span: 4 }} className="flex flex-h-right">
            <OrgSelector {...this.props} />
          </Col>
        </Row>
        <LoadingComponent loading={this.state.loading}>
          {container}
        </LoadingComponent>
      </div>
    );
  }
}

export default PolicySettings;
