import React, { Component } from "react";
import { Input, Form, Col, Row, Button, Card } from "antd";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";
import {
  randomAlphaNumericString,
  isArray,
  deepMapValues,
  checkTwigAllowedConditions,
  copyObject,
  normalizeTemplates,
  getComponentDefaultValues,
} from "Core/Utils";
import Jsonpath from "jsonpath";
import Ajv from "ajv";
import { SecretController } from "controller/Controllers";
import Twig from "twig";
/*import schemas*/
import Services from "@iotium/jsonvalidator";
/*import forms*/
import ServiceFormRender from "../Forms/ServiceFormRender";

const FormItem = Form.Item;
const { TextArea } = Input;
const ajv = new Ajv();
const InputValidatorV2Schema = Services.uiDefinitionV2;
const InputValidatorSchema = Services.uiDefinition;
const viewService = window.location.href.indexOf("serviceview") > -1;

@inject(
  "UiStore",
  "SecretViewModel",
  "NetworkViewModel",
  "InodeViewModel",
  "ClusterViewModel",
)
@observer
class ValidateAndComputeTemplate extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = this.props.InodeViewModel;
    this.clusterModel = this.props.ClusterViewModel;
    this.networkViewModel = this.props.NetworkViewModel;
    this.state = {
      uiSchema: {},
      showForm: false,
      numberOfSteps: 0,
      JSONerror: [],
      userMappingError: [],
      secretLoadedOnedit: false,
      volumeLoading: false,
    };
    this.deepMapValues = deepMapValues;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.uiSchema != nextProps.uiSchema) {
      this.setState({
        uiSchema: this.props.uiSchema,
      });
      this.validateAndRender();
    }
  }

  componentDidMount() {
    this.setState(
      {
        uiSchema: this.props.uiSchema,
      },
      () => {
        this.validateAndRender();
      },
    );
  }

  UNSAFE_componentWillMount() {
    this.setState({ volumeLoading: true });
    this.getOpaqueSecrets();
    this.getDockerConfigSecrets();
  }

  getOpaqueSecrets = () => {
    let params = {
      own: true,
      size: 1,
      page: 0,
      org_id: this.props.match.params.org_id
        ? this.props.match.params.org_id
        : this.props.UiStore.currentUser.organization.id,
      type: "Opaque",
    };
    if (this.props.match.path === "/servicetemplate") {
      delete params.own;
    }
    SecretController.getSecrets(params).then(resp => {
      if (resp) {
        let total_count =
          this.props.SecretViewModel.pageable.volume.total_count / 100;
        if (total_count > 0) {
          let updated_params = [];
          for (let page = 0; page < total_count; page++) {
            updated_params.push({
              own: true,
              size: 100,
              page: page,
              org_id: this.props.match.params.org_id
                ? this.props.match.params.org_id
                : this.props.UiStore.currentUser.organization.id,
              type: "Opaque",
            });
          }
          if (updated_params.length > 0) {
            SecretController.getAllSecrets(updated_params).then(resp => {
              this.setState({ volumeLoading: true });
            });
          }
        }
      }
    });
  };
  getDockerConfigSecrets = () => {
    let params = {
      own: true,
      size: 1,
      page: 0,
      org_id: this.props.match.params.org_id
        ? this.props.match.params.org_id
        : this.props.UiStore.currentUser.organization.id,
      label: `type:dockerconfig`,
    };
    if (this.props.match.path === "/servicetemplate") {
      delete params.own;
    }
    SecretController.getSecrets(params).then(resp => {
      let total_count =
        this.props.SecretViewModel.pageable.dockerconfig.total_count / 100;
      if (total_count > 0) {
        let updated_params = [];
        for (let page = 0; page < total_count; page++) {
          updated_params.push({
            own: true,
            size: 100,
            page: page,
            org_id: this.props.match.params.org_id
              ? this.props.match.params.org_id
              : this.props.UiStore.currentUser.organization.id,
            label: `type:dockerconfig`,
          });
          SecretController.getAllSecrets(updated_params).then(resp => {});
        }
      }
    });
  };
  parseTemplateWithValues() {
    return new Promise((resolve, reject) => {
      let computedSchema = JSON.stringify(
          this.props.UiStore.templatingService.computedSchema,
        ),
        jp = Jsonpath,
        computedElementObject = toJS(JSON.parse(computedSchema), true);

      let steps = this.state.uiSchema.steps;
      let op =
        this.props.UiStore.templatingService &&
        this.props.UiStore.templatingService.computedSchema &&
        this.props.UiStore.templatingService.computedSchema.output
          ? this.props.UiStore.templatingService.computedSchema.output
          : {};
      if (typeof op === "object") {
        op = JSON.stringify(op);
      }
      if (checkTwigAllowedConditions(op)) {
        if (
          typeof this.props.UiStore.templatingService.computedSchema.output ===
          "string"
        ) {
          //Normalize output string by removing if, elseif, endif conditions
          var computedObject = [];
          let outputObject = toJS(this.props.UiStore.computedOutputObject);
          if (
            this.props.apiResponse &&
            this.props.apiResponse.spec &&
            Object.keys(this.props.apiResponse.spec).length > 0
          ) {
            Object.entries(this.props.apiResponse.spec.labels).forEach(
              ([key, value]) => {
                if (key.indexOf("[") > 0) {
                  try {
                    jp.value(computedObject, "$." + key, value);
                    jp.value(outputObject, "$." + key, JSON.parse(value));
                  } catch (err) {}
                }
              },
            );
          }
          this.props.UiStore.templatingService.computedSchema.output = normalizeTemplates(
            this.props.UiStore.templatingService.computedSchema.output,
            computedObject,
          );
          this.props.UiStore.computedOutputObject = toJS(outputObject);
        }

        if (steps && steps.length > 0) {
          steps.forEach((step, i) => {
            this.setState({ numberOfSteps: i });
            let elements = this.state.uiSchema.steps[i].elements;
            elements.forEach((ele, index) => {
              ele["__id"] =
                computedElementObject.steps[i].elements[index]["__id"];
              if (
                this.props.apiResponse &&
                this.props.apiResponse.spec &&
                Object.keys(this.props.apiResponse.spec).length > 0
              ) {
                let output = this.props.UiStore.templatingService.computedSchema
                  .output;
                var finalSpec = JSON.parse(output);
                var outputObject = toJS(finalSpec, true);
                if (
                  ele.type &&
                  ele.type.toLowerCase() === "secret" &&
                  ele.props &&
                  ele.props.subtype &&
                  ele.props.subtype.toLowerCase() !== "emptydir"
                ) {
                  let p = '"steps[' + i + "].elements[" + index + '].id"';

                  let r = jp.nodes(
                    outputObject,
                    "$..[?(@.includes(" + p + "))]",
                  );
                  if (r && r.length > 0) {
                    let path = "";
                    for (let size = 0; size < r.length; size++) {
                      let volume = jp.stringify(r[size].path);
                      if (volume.indexOf("volumes") >= 0) {
                        path = volume;
                      }
                    }
                    if (path.indexOf("volumes") >= 0) {
                      let secret_id =
                        typeof jp.query(
                          this.props.apiResponse.spec,
                          path,
                        )[0] === "object"
                          ? jp.query(this.props.apiResponse.spec, path)[0]
                              .secret_volume &&
                            jp.query(this.props.apiResponse.spec, path)[0]
                              .secret_volume.secret
                            ? jp.query(this.props.apiResponse.spec, path)[0]
                                .secret_volume.secret
                            : jp.query(this.props.apiResponse.spec, path)[0]
                          : jp.query(this.props.apiResponse.spec, path)[0];

                      SecretController.getSecret(secret_id, true).then(
                        response => {
                          this.setState({ secretLoadedOnedit: true });
                          this.props.UiStore.computedOutputObject.steps[
                            i
                          ].elements[index] = {
                            name: response.name,
                            id: response.id,
                            mount_path: ele.props.mountpath,
                          };
                          this.props.UiStore.templatingService.secrets[
                            ele["__id"]
                          ] = {
                            previousSelectedLicense: response.id,
                          };

                          /* volume in api resp obj at given index should be the value for this element */
                          if (
                            this.props.UiStore.computedOutputObject.steps[i]
                              .elements[index] &&
                            this.props.UiStore.computedOutputObject.steps[i]
                              .elements[index].id
                          ) {
                            this.props.UiStore.templatingService.secrets[
                              ele["__id"]
                            ] = {
                              previousSelectedLicense: this.props.UiStore
                                .computedOutputObject.steps[i].elements[index]
                                .id,
                            };
                          }
                        },
                      );
                    }
                  } else {
                    if (
                      this.props.UiStore.computedOutputObject.steps[i].elements[
                        index
                      ].id
                    ) {
                      this.props.uiSchema.steps[i].elements[
                        index
                      ].props.defaultValue = this.props.UiStore.computedOutputObject.steps[
                        i
                      ].elements[index];
                    }
                  }
                } else if (ele.type && ele.type.toLowerCase() === "dropdown") {
                  let p = '"steps[' + i + "].elements[" + index + '].value[0]"';
                  let y = jp.paths(
                    outputObject,
                    "$..[?(@.includes(" + p + "))]",
                  );
                  if (y && y[0]) {
                    let path = jp.stringify(y[0]);
                    /* set default as array. LAT-4005*/
                    this.props.uiSchema.steps[i].elements[
                      index
                    ].props.defaultValue = jp.query(
                      this.props.apiResponse.spec,
                      path,
                    );
                    this.props.UiStore.computedOutputObject.steps[i].elements[
                      index
                    ] = {
                      value: jp.query(this.props.apiResponse.spec, path),
                    };
                    ele.props.defaultValue = {
                      value: jp.query(this.props.apiResponse.spec, path),
                    };
                  } else {
                    if (
                      this.props.UiStore.computedOutputObject.steps[i].elements[
                        index
                      ].value
                    ) {
                      this.props.uiSchema.steps[i].elements[
                        index
                      ].props.defaultValue = this.props.UiStore.computedOutputObject.steps[
                        i
                      ].elements[index].value;
                    }
                  }
                } else if (ele.type && ele.type.toLowerCase() === "niclist") {
                  let p = '"steps[' + i + "].elements[" + index + '].value"';
                  let y = jp.paths(
                    outputObject,
                    "$..[?(@.includes(" + p + "))]",
                  );
                  if (y && y[0]) {
                    let path = jp.stringify(y[0]);
                    //eth,LAN2:plan2,up
                    let actualValue = jp.query(
                      this.props.apiResponse.spec,
                      path,
                    );
                    if (actualValue.length > 0) {
                      actualValue = actualValue[0];
                    } else {
                      actualValue = "";
                    }
                    //split value by ":"
                    let splited_value = actualValue.split(":");
                    let detectedValue = "";
                    if (splited_value.length > 1) {
                      let val = splited_value[0].split(",");
                      if (val && val.length > 1) {
                        detectedValue = val[1];
                      } else {
                        detectedValue = val[0];
                      }
                    }
                    /* set default as array. LAT-4005*/
                    this.props.uiSchema.steps[i].elements[
                      index
                    ].props.defaultValue = [detectedValue];
                    this.props.UiStore.computedOutputObject.steps[i].elements[
                      index
                    ] = {
                      value: [detectedValue],
                    };
                    ele.props.defaultValue = {
                      value: [detectedValue],
                    };
                  } else {
                    if (
                      this.props.UiStore.computedOutputObject.steps[i].elements[
                        index
                      ].value
                    ) {
                      //eth,LAN2:plan2,up
                      let actualValue = this.props.UiStore.computedOutputObject
                        .steps[i].elements[index].value;
                      if (actualValue.length > 0) {
                        actualValue = actualValue[0];
                      } else {
                        actualValue = "";
                      }
                      //split value by ":"
                      let splited_value = actualValue.split(":");

                      if (splited_value.length > 1) {
                        // further split by "," to get the exact value
                        let val = splited_value[0].split(",");
                        if (val && val.length > 1) {
                          detectedValue = val[1];
                        } else {
                          detectedValue = val[0];
                        }
                      }
                      this.props.uiSchema.steps[i].elements[
                        index
                      ].props.defaultValue = [detectedValue];
                    }
                  }
                } else if (
                  ele.type &&
                  ele.type.toLowerCase() === "nodenetworklist"
                ) {
                  let p =
                    '"steps[' + i + "].elements[" + index + '].network_id"';
                  let value = {
                    node_id: "",
                    network_id: "",
                    node: {},
                    network: {},
                    cluster_id: "",
                    kind: "",
                    ip: "",
                    node_selector: null,
                  };
                  let y = jp.paths(
                    outputObject,
                    "$..[?(@.includes(" + p + "))]",
                  );
                  if (y && y[0]) {
                    let path = jp.stringify(y[0]);
                    value.network_id = jp.query(
                      this.props.apiResponse.spec,
                      path,
                    )[0]
                      ? jp.query(this.props.apiResponse.spec, path)[0]
                      : "";
                    let networks = this.networkViewModel.networks;
                    let x = networks.find(
                      element => element.id === value.network_id,
                    );

                    if (
                      x &&
                      this.props.UiStore.templatingService.computedSchema
                        .version &&
                      this.props.UiStore.templatingService.computedSchema
                        .version === "2.0"
                    ) {
                      this.props.UiStore.templatingService.serviceCurrentNetwork = x;

                      value.network = toJS(x);
                    }
                  } else {
                    this.props.uiSchema.steps[i].elements[
                      index
                    ].props.defaultValue = this.props.UiStore.computedOutputObject.steps[
                      i
                    ].elements[index];
                  }

                  let p2 = '"steps[' + i + "].elements[" + index + '].node_id"';
                  let val2 = jp.paths(
                    outputObject,
                    "$..[?(@.includes(" + p2 + "))]",
                  );
                  if (val2 && val2[0]) {
                    let path = jp.stringify(val2[0]);
                    value.node_id =
                      this.props.apiResponse.node &&
                      this.props.apiResponse.node.id
                        ? this.props.apiResponse.node.id
                        : "";
                    value.cluster_id =
                      this.props.apiResponse.cluster &&
                      this.props.apiResponse.cluster.id
                        ? this.props.apiResponse.cluster.id
                        : "";
                    value.kind = this.props.apiResponse.kind
                      ? this.props.apiResponse.spec.kind
                      : "";
                    value.node_selector = this.props.apiResponse.spec
                      .node_selector
                      ? this.props.apiResponse.spec.node_selector
                      : "";
                    let nodes = this.inodeModel.inodes;
                    if (
                      this.props.apiResponse.node &&
                      this.props.apiResponse.node.id
                    ) {
                      let x = nodes.find(
                        element =>
                          element.id === this.props.apiResponse.node.id,
                      );
                      if (
                        x &&
                        this.props.UiStore.templatingService.computedSchema
                          .version &&
                        this.props.UiStore.templatingService.computedSchema
                          .version === "2.0"
                      ) {
                        this.props.UiStore.templatingService.serviceCurrentNode = x;
                        value.node = toJS(x);
                      }
                    }

                    let clusters = this.clusterModel.clusters;
                    if (
                      this.props.apiResponse.cluster &&
                      this.props.apiResponse.cluster.id
                    ) {
                      let x = clusters.find(
                        element =>
                          element.id === this.props.apiResponse.cluster.id,
                      );
                      if (
                        x &&
                        this.props.UiStore.templatingService.computedSchema
                          .version &&
                        this.props.UiStore.templatingService.computedSchema
                          .version === "2.0"
                      ) {
                        this.props.UiStore.templatingService.serviceCurrentNode = x;
                        value.cluster = toJS(x);
                      }
                    }
                  } else {
                    if (
                      this.props.UiStore.computedOutputObject.steps[i].elements[
                        index
                      ].network_id
                    ) {
                      this.props.uiSchema.steps[i].elements[
                        index
                      ].props.defaultValue = this.props.UiStore.computedOutputObject.steps[
                        i
                      ].elements[index];
                    }
                  }

                  let p3 = '"steps[' + i + "].elements[" + index + '].ip"';
                  let val3 = jp.paths(
                    outputObject,
                    "$..[?(@.includes(" + p3 + "))]",
                  );
                  if (val3 && val3[0]) {
                    let path = jp.stringify(val3[0]);
                    value.ip = jp.query(this.props.apiResponse.spec, path)[0]
                      ? jp.query(this.props.apiResponse.spec, path)[0]
                      : "";
                    this.props.uiSchema.steps[i].elements[
                      index
                    ].props.defaultValue = value;
                    this.props.UiStore.computedOutputObject.steps[i].elements[
                      index
                    ] = value;
                  } else {
                    if (
                      this.props.UiStore.computedOutputObject.steps[i].elements[
                        index
                      ].ip
                    ) {
                      this.props.uiSchema.steps[i].elements[
                        index
                      ].props.defaultValue = this.props.UiStore.computedOutputObject.steps[
                        i
                      ].elements[index];
                    }
                  }
                } else if (
                  ele.type &&
                  ele.type.toLowerCase() === "dockerconfig"
                ) {
                  if (
                    this.props.SecretViewModel.secretsObj.dockerconfig.length <=
                    0
                  ) {
                    let params = {
                      own: true,
                      size: 100,
                      org_id: this.props.match.params.org_id
                        ? this.props.match.params.org_id
                        : this.props.UiStore.currentUser.organization.id,
                      label: `type:dockerconfig`,
                    };
                    if (this.props.match.path === "/servicetemplate") {
                      delete params.own;
                    }
                    SecretController.getSecrets(params).then(resp => {
                      this.updateSecretInitialValue(
                        "dockerconfig",
                        ele,
                        i,
                        index,
                      );
                    });
                  } else {
                    this.updateSecretInitialValue(
                      "dockerconfig",
                      ele,
                      i,
                      index,
                    );
                  }
                } else if (
                  ele.type &&
                  ele.type.toLowerCase() === "dnscomponent"
                ) {
                  // dns value update
                  let p = '"steps[' + i + "].elements[" + index + '].dns"';
                  let y = jp.paths(
                    outputObject,
                    "$..[?(@.includes(" + p + "))]",
                  );
                  let value = {
                    dns: [],
                    dns_policy:
                      this.props.uiSchema &&
                      this.props.uiSchema.steps[i] &&
                      this.props.uiSchema.steps[i].elements[index] &&
                      this.props.uiSchema.steps[i].elements[index].props &&
                      this.props.uiSchema.steps[i].elements[index].props
                        .defaultValue &&
                      this.props.uiSchema.steps[i].elements[index].props
                        .defaultValue.dns_policy
                        ? this.props.uiSchema.steps[i].elements[index].props
                            .defaultValue.dns_policy
                        : "Default",
                  };
                  if (y) {
                    y.forEach(key => {
                      if (key.includes("dns_policy")) {
                        let path = jp.stringify(key);
                        value.dns_policy = jp.query(
                          this.props.apiResponse.spec,
                          path,
                        )[0];
                      } else {
                        key = key.slice(0, 2);
                        let path = jp.stringify(key);
                        value.dns = jp.query(
                          this.props.apiResponse.spec,
                          path,
                        )[0]
                          ? jp.query(this.props.apiResponse.spec, path)[0]
                          : [];
                      }
                    });
                  }
                  this.props.uiSchema.steps[i].elements[
                    index
                  ].props.defaultValue = value;
                  this.props.UiStore.computedOutputObject.steps[i].elements[
                    index
                  ] = value;
                } else if (
                  ele.type &&
                  ele.type.toLowerCase() === "jsoninputbox"
                ) {
                  this.props.uiSchema.steps[i].elements[
                    index
                  ].props.defaultValue = JSON.stringify(
                    this.props.apiResponse.spec,
                    null,
                    2,
                  );
                } else if (
                  ele.type &&
                  ele.type.toLowerCase() != "nodenetworklist"
                ) {
                  let p = '"steps[' + i + "].elements[" + index + '].value"';
                  let y = jp.paths(
                    outputObject,
                    "$..[?(@.includes(" + p + "))]",
                  );
                  if (y && y[0]) {
                    let path = jp.stringify(y[0]);
                    this.props.uiSchema.steps[i].elements[
                      index
                    ].props.defaultValue = jp.query(
                      this.props.apiResponse.spec,
                      path,
                    )[0];

                    this.props.uiSchema.steps[i].elements[
                      index
                    ].props.defaultValue = {
                      value: jp.query(this.props.apiResponse.spec, path)[0],
                    };
                    this.props.UiStore.computedOutputObject.steps[i].elements[
                      index
                    ] = {
                      value: jp.query(this.props.apiResponse.spec, path)[0],
                    };
                  } else {
                    if (
                      this.props.UiStore.computedOutputObject.steps[i].elements[
                        index
                      ].value
                    ) {
                      this.props.uiSchema.steps[i].elements[
                        index
                      ].props.defaultValue = this.props.UiStore.computedOutputObject.steps[
                        i
                      ].elements[index].value;
                    }
                  }
                }
              }
            });
          });
        }
        this.props.UiStore.templatingService.computedSchema.steps = this.state.uiSchema.steps;
        if (!this.state.uiSchema.version) {
          this.props.UiStore.templatingService.computedSchema.steps =
            computedElementObject.steps;
          /*Assign id_mapped_output to computed.output object*/
          this.props.UiStore.templatingService.computedSchema.output = {
            ...toJSObj,
          };
        }
        if (this.state.uiSchema.version) {
          computedElementObject = toJS(
            this.props.UiStore.templatingService.computedSchema,
            true,
          );
        }
        resolve(computedElementObject);
      } else {
        this.setState({
          userMappingError: [
            "Unsupported Output String. Conditions should have only if, elseif, else.",
          ],
          showForm: false,
        });
      }
    });
  }

  mapIdInOutput = () => {
    //TODO optimize regex
    return new Promise((resolve, reject) => {
      let startWith = /^\${/,
        endWith = /}$/,
        testObj = this.props.UiStore.templatingService.computedSchema.output;
      let output = JSON.stringify(
          this.props.UiStore.templatingService.computedSchema.output,
        ),
        toJSObj = toJS(JSON.parse(output), true);
      let jp = Jsonpath,
        computedSchema = JSON.stringify(
          this.props.UiStore.templatingService.computedSchema,
        ),
        mobxtoJSObj = toJS(JSON.parse(computedSchema), true);

      this.deepMapValues(testObj, (value, path) => {
        if (startWith.test(value) && endWith.test(value)) {
          let traverse = value.replace(startWith, "");
          traverse = traverse.replace(endWith, "");
          if (traverse.includes("..")) {
            traverse = traverse.split("..")[0];
          }
          try {
            let ele = jp.query(mobxtoJSObj, `$.${traverse}`);
            if (!this.state.uiSchema.version) {
              if (ele.length == 1) {
                /*replace map_value with ele_id in output*/
                jp.apply(toJSObj, `$.${path}`, val => {
                  val = ele[0]["__id"];
                  return val;
                });
              }
            }
            if (
              this.props.apiResponse &&
              this.props.apiResponse.spec &&
              Object.keys(this.props.apiResponse.spec).length > 0
            ) {
              if (ele.length == 1) {
                if (ele[0]) {
                  this.loadHtmlWithApiData(jp, ele[0], path);
                }
              }
            }
          } catch (e) {
            let msg = [];
            if (!this.state.uiSchema.version) {
              msg.push(e.message);
              this.setState({
                showForm: false,
                JSONerror: msg,
                userMappingError: [],
              });
            }
            reject(false);
          }
        }
      });
      if (!this.state.uiSchema.version) {
        this.props.UiStore.templatingService.computedSchema.steps =
          mobxtoJSObj.steps;
        /*Assign id_mapped_output to computed.output object*/
        this.props.UiStore.templatingService.computedSchema.output = {
          ...toJSObj,
        };
      }
      if (this.state.uiSchema.version) {
        mobxtoJSObj = toJS(
          this.props.UiStore.templatingService.computedSchema,
          true,
        );
      }
      resolve(mobxtoJSObj);
    });
  };

  updateSecretInitialValue = (type, ele, stepIndex = -1, elementIndex = -1) => {
    /* get all dockerconfig id's*/
    let secret = {
      license: [],
      dockerconfig: [],
      ssh: [],
    };
    let dockerList = [],
      dockerNames = [];
    /*
      LAT-4114. moving docker from volume to 'image_pull_secrets'
    */
    if (
      this.props.apiResponse.spec.image_pull_secrets &&
      this.props.apiResponse.spec.image_pull_secrets.length > 0
    ) {
      /* only for dockerConfig  */
      let dockerconfig = this.props.apiResponse.spec.image_pull_secrets;

      this.props.SecretViewModel.secretsObj.dockerconfig.forEach(docker => {
        if (dockerconfig.indexOf(docker.name) >= 0) {
          dockerList.push(docker.id);
          dockerNames.push(docker.name);
        } else if (dockerconfig.indexOf(docker.id) >= 0) {
          dockerList.push(docker.id);
          dockerNames.push(docker.name);
        }
      });
    }
    switch (type) {
      case "dockerconfig": {
        this.props.SecretViewModel.secretsObj.dockerconfig.map(docker => {
          if (
            stepIndex >= 0 &&
            elementIndex >= 0 &&
            this.state.uiSchema.version &&
            this.state.uiSchema.version === "2.0"
          ) {
            this.props.UiStore.computedOutputObject.steps[stepIndex].elements[
              elementIndex
            ] = {
              value: dockerNames,
            };
            if (ele.type.toLowerCase() === "dockerconfig") {
              this.props.UiStore.templatingService.secrets[ele["__id"]] = {
                previousSelectedLicense: dockerList,
              };
            }
          }
          secret["dockerconfig"].push(docker.id);
        });
        break;
      }
      case "license": {
        this.props.SecretViewModel.secretsObj.volume.map(docker => {
          if (
            stepIndex >= 0 &&
            elementIndex >= 0 &&
            this.state.uiSchema.version &&
            this.state.uiSchema.version === "2.0"
          ) {
            this.props.UiStore.computedOutputObject.steps[stepIndex].elements[
              elementIndex
            ] = {
              name: docker.name,
              id: docker.id,
              mount_path: ele.props.mountpath,
            };
          }
          secret["license"].push(docker.id);
        });
        break;
      }
      case "ssh": {
        this.props.SecretViewModel.secretsObj.volume.map(docker => {
          if (
            stepIndex >= 0 &&
            elementIndex >= 0 &&
            this.state.uiSchema.version &&
            this.state.uiSchema.version === "2.0"
          ) {
            this.props.UiStore.computedOutputObject.steps[stepIndex].elements[
              elementIndex
            ] = {
              name: docker.name,
              id: docker.id,
              mount_path: ele.props.mountpath,
            };
          }
          secret["ssh"].push(docker.id);
        });
        break;
      }
    }
    if (!this.state.uiSchema.version) {
      let podVols =
        this.props.UiStore.templatingService.computedSchema.output &&
        this.props.UiStore.templatingService.computedSchema.output.volumes;
      podVols.length > 0 &&
        podVols.forEach((vol, i) => {
          if (vol && vol.constructor !== Object) {
            /* not an obj, meaning ele id */
            if (podVols[i] === ele["__id"]) {
              /* volume in api resp obj at given index should be the value for this element */
              let defaultVol = this.props.apiResponse.spec.volumes[i];
              let previousVol =
                defaultVol && defaultVol.secret_volume
                  ? defaultVol.secret_volume.secret
                  : null;
              if (previousVol) {
                this.props.UiStore.templatingService.secrets[ele["__id"]] = {
                  previousSelectedLicense: previousVol,
                };
              }
            }
          }
        });
    }
  };

  loadHtmlWithApiData = (jp, ele, path) => {
    /*Assign api values to mapped attributes*/
    if (
      ele.type &&
      ele.type.toLowerCase() === "secret" &&
      path.indexOf("volumes") >= 0 &&
      ele.props &&
      ele.props.subtype &&
      ele.props.subtype.toLowerCase() !== "emptydir"
    ) {
      let params = {
        own: true,
        size: 1,
        page: 0,
        org_id: this.props.match.params.org_id
          ? this.props.match.params.org_id
          : this.props.UiStore.currentUser.organization.id,
        type: "Opaque",
      };
      if (this.props.match.path === "/servicetemplate") {
        delete params.own;
      }
      SecretController.getSecrets(params).then(resp => {
        let total_count = this.secretModel.pageable.volume.total_count / 100;
        if (total_count > 0) {
          for (let page = 0; page < total_count; page++) {
            let updated_params = {
              own: true,
              size: 100,
              page: page,
              org_id: this.props.match.params.org_id
                ? this.props.match.params.org_id
                : this.props.UiStore.currentUser.organization.id,
              type: "Opaque",
            };
            if (this.props.match.path === "/servicetemplate") {
              delete updated_params.own;
            }
            SecretController.getSecrets(updated_params).then(resp => {
              this.updateSecretInitialValue(
                `${ele.props.subtype.toLowerCase()}`,
                ele,
              );
            });
          }
        }
      });
    } else if (ele.type && ele.type.toLowerCase() === "dropdown") {
      /* set default as array. LAT-4005*/
      ele.props.defaultValue = jp.query(
        this.props.apiResponse.spec,
        `$.${path}`,
      );
      if (
        this.state.uiSchema.version &&
        this.state.uiSchema.version === "2.0"
      ) {
        ele.props.defaultValue = {
          value: jp.query(this.props.apiResponse.spec, `$.${path}`),
        };
      }
    } else if (ele.type && ele.type.toLowerCase() === "dockerconfig") {
      if (this.props.SecretViewModel.secretsObj.dockerconfig.length <= 0) {
        let params = {
          own: true,
          size: 100,
          org_id: this.props.match.params.org_id
            ? this.props.match.params.org_id
            : this.props.UiStore.currentUser.organization.id,
          label: `type:dockerconfig`,
        };
        if (this.props.match.path === "/servicetemplate") {
          delete params.own;
        }
        SecretController.getSecrets(params).then(resp => {
          this.updateSecretInitialValue("dockerconfig", ele);
        });
      } else {
        this.updateSecretInitialValue("dockerconfig", ele);
      }
    } else if (ele.type && ele.type.toLowerCase() != "nodenetworklist") {
      ele.props.defaultValue = jp.query(
        this.props.apiResponse.spec,
        `$.${path}`,
      )[0];
      if (
        this.state.uiSchema.version &&
        this.state.uiSchema.version === "2.0"
      ) {
        ele.props.defaultValue = {
          value: jp.query(this.props.apiResponse.spec, `$.${path}`)[0],
        };
      }
    }
  };
  //Validate V2 service template output spec
  validateUsermappingInV2Output = () => {
    //TODO optimize regex
    //test if expression starts with '${{' and ends with '}}'
    return new Promise((resolve, rej) => {
      this.setState({ userMappingError: [] });
      let startWith = /^\{{/,
        endWith = /}}/,
        testObj = this.props.UiStore.templatingService.computedSchema.output,
        error = [];

      let output = JSON.stringify(
        this.props.UiStore.templatingService.computedSchema.output,
      );

      this.deepMapValues(testObj, (value, path) => {
        if (startWith.test(value) && endWith.test(value)) {
          let traverse = value.replace(startWith, "");
          traverse = traverse.replace(endWith, "");
          /*LAT-4249*/
          let jp = Jsonpath,
            computedSchema = JSON.stringify(
              this.props.UiStore.templatingService.computedSchema,
            ),
            mobxtoJSObj = toJS(JSON.parse(computedSchema), true);
          let traverseSubString = traverse.substr(0, traverse.lastIndexOf("."));
          let ele = jp.query(mobxtoJSObj, `$.${traverseSubString}`);
          if (ele.length <= 0) {
            error.push(`${traverse}`);
            this.setState({ userMappingError: error, showForm: false });
          }
        }
      });
      resolve(true);
    });
  };
  validateUsermappingInOutput = () => {
    //TODO optimize regex
    //test if expression starts with '${' and ends with '}'
    return new Promise((resolve, rej) => {
      this.setState({ userMappingError: [] });
      let startWith = /^\${/,
        endWith = /}$/,
        testObj = this.props.UiStore.templatingService.computedSchema.output,
        error = [];

      let output = JSON.stringify(
        this.props.UiStore.templatingService.computedSchema.output,
      );

      this.deepMapValues(testObj, (value, path) => {
        if (startWith.test(value) && endWith.test(value)) {
          let traverse = value.replace(startWith, "");
          traverse = traverse.replace(endWith, "");
          /*LAT-4249*/
          let jp = Jsonpath,
            computedSchema = JSON.stringify(
              this.props.UiStore.templatingService.computedSchema,
            ),
            mobxtoJSObj = toJS(JSON.parse(computedSchema), true);
          let ele = jp.query(mobxtoJSObj, `$.${traverse}`);
          if (ele.length <= 0) {
            error.push(`${traverse}`);
            this.setState({ userMappingError: error, showForm: false });
          }
        }
      });

      resolve(true);
    });
  };

  /**
   *  Validate and render specs after version 2.0 and above
   */
  validateAndMapObject = () => {
    //test if expression starts with '${' and ends with '}'
    return new Promise((resolve, rej) => {
      this.setState({ userMappingError: [] });
      let jp = Jsonpath,
        computedSchema = JSON.stringify(
          this.props.UiStore.templatingService.computedSchema,
        ),
        mobxtoJSObj = toJS(JSON.parse(computedSchema), true);
      let computedObject = {
        steps: [],
      };
      if (mobxtoJSObj.steps && mobxtoJSObj.steps.length > 0) {
        mobxtoJSObj.steps.forEach((s, stepIndex) => {
          computedObject.steps.push({ elements: [] });
          if (s.elements.length > 0) {
            s.elements.forEach((e, elementIndex) => {
              computedObject.steps[stepIndex].elements.push(
                getComponentDefaultValues(e.type),
              );
            });
          }
        });
      }
      this.props.UiStore.computedOutputObject = computedObject;
      resolve(true);
    });
  };

  validateAndRender = () => {
    try {
      let schemaGiven;
      let versionInStringSpec = "";
      if (typeof this.props.uiSchema == "string") {
        /* input from serviceTemplatePlayground.Parse the JSON */ try {
          let schema = JSON.parse(this.props.uiSchema);
          versionInStringSpec = schema.version;
          this.setState({ uiSchema: schema }, () => {});
        } catch (err) {
          var computedObject = [];
          var outputSpec = copyObject(this.props.uiSchema);
          var index = this.props.uiSchema.indexOf('"output"');
          outputSpec = outputSpec.replace(outputSpec.substring(0, index), "");
          outputSpec = outputSpec.replace(/\"output\"([\\s]+)?:/, "");
          outputSpec = outputSpec.trim();
          outputSpec = outputSpec.substring(0, outputSpec.length - 1);
          outputSpec = outputSpec.substring(
            outputSpec.indexOf("{"),
            outputSpec.length,
          );
          outputSpec = String(outputSpec);
          //outputSpec = outputSpec.replace(/\"/g, '\\"');
          schemaGiven = this.props.uiSchema;
          schemaGiven = schemaGiven.replace(
            schemaGiven.substring(index, schemaGiven.length - 1),
            "",
          );
          schemaGiven = schemaGiven.trim();
          schemaGiven = schemaGiven.substring(0, schemaGiven.lastIndexOf(","));
          schemaGiven = schemaGiven + "}";
          schemaGiven = JSON.parse(schemaGiven);
          versionInStringSpec = schemaGiven.version;
          schemaGiven.output = String(outputSpec);
          this.setState({ uiSchema: schemaGiven }, () => {});
        }
      }

      this.props.UiStore.resetServiceSpec();
      /* check if schema has required steps and output attributes*/
      let x = schemaGiven ? schemaGiven : this.state.uiSchema,
        error = {};
      if (Object.keys(x).length > 0) {
        let keys = Object.keys(x);
        if (keys.length == 2) {
          if (keys.indexOf("steps") < 0 || keys.indexOf("output") < 0) {
            error = {
              message: "Attributes 'steps' and 'output' are required.",
            };
            throw error;
          }
        } else if (
          versionInStringSpec === "2.0" ||
          (keys.indexOf("version") >= 0 &&
            this.state.uiSchema.version &&
            this.state.uiSchema.version === "2.0")
        ) {
          //It is V2 spec
        } else {
          error = {
            message:
              "Attributes 'steps' and 'output' are only allowed and are mandatory.",
          };
          throw error;
        }
      } else {
        error = { message: "Attributes 'steps' and 'output' are missing." };
        throw error;
      }
      /*validate template*/
      let validTemplate = null;
      if (
        versionInStringSpec === "2.0" ||
        (this.state.uiSchema.version && this.state.uiSchema.version === "2.0")
      ) {
        let schema = copyObject(this.state.uiSchema);
        var computedObject = [];
        if (typeof schema === "string") {
          var template = Twig.twig({
            data: schema,
          });
          try {
            schema = JSON.parse(template.render(computedObject));
          } catch (err) {
            console.log(err);
          }
        } else {
          var template = Twig.twig({
            data: schema.output,
          });
          try {
            let stringSpec = template.render(computedObject);
            schema.output = JSON.parse(stringSpec);
          } catch (err) {
            console.log(err);
          }
        }
        validTemplate = ajv.validate(InputValidatorV2Schema, schema);
      } else {
        validTemplate = ajv.validate(InputValidatorSchema, this.state.uiSchema);
      }
      if (validTemplate) {
        this.props.UiStore.templatingService.computedSchema = {};
        this.setState({
          showForm: false,
          JSONerror: [],
          userMappingError: [],
        });
        if (versionInStringSpec.length > 0) {
          setTimeout(() => {
            this.renderSpec();
          }, 1000);
        } else {
          this.renderSpec();
        }
      } else {
        let msg = [];
        ajv.errors.map(val => {
          msg.push(`dataPath -> ${val.dataPath} : ${val.message}`);
        });
        msg = [...new Set(msg)]; /*remove duplicates*/
        throw msg;
      }
    } catch (e) {
      this.showError(e);
    }
  };

  showError = e => {
    let arr = isArray(e),
      x = [];
    if (!arr) {
      x.push(e.message);
    } else {
      x = e;
    }
    this.setState({
      JSONerror: x,
      showForm: false,
      userMappingError: [],
    });
  };

  renderSpec = () => {
    /*logic for adding id to tabs,elements and creating a computed schema to be used in ui*/
    this.props.UiStore.templatingService.originalSchema = this.state.uiSchema;
    this.props.UiStore.templatingService.computedSchema = this.state.uiSchema;

    let version = this.props.UiStore.templatingService.computedSchema.version
      ? this.props.UiStore.templatingService.computedSchema.version
      : null;
    let steps = [],
      network_index = 0;
    steps = this.props.UiStore.templatingService.computedSchema.steps.map(
      (tab, index) => {
        /* show only visible tabs */
        if (tab.visible) {
          tab.uniqId = randomAlphaNumericString(3);

          if (tab.elements.length > 0) {
            tab.elements.map((ele, i) => {
              let type = ele.type.toLowerCase();
              switch (type) {
                case "nodenetworklist": {
                  ele["__id"] = `nodenetwork_${randomAlphaNumericString(3)}`;
                  /* Assign default values to nodenetworklist component from api resp */
                  if (
                    this.props.apiResponse &&
                    this.props.apiResponse.spec &&
                    this.props.apiResponse.spec.networks &&
                    this.props.apiResponse.spec.networks.length > 0
                  ) {
                    let nwIdList =
                      viewService &&
                      this.props.apiResponse.spec.networks.length > 1
                        ? this.props.apiResponse.spec.networks.map(
                            obj => obj.network_id,
                          )
                        : this.props.apiResponse.spec.networks[network_index]
                            .network_id;
                    // multiple network handle
                    let networks =
                      this.props.apiResponse &&
                      this.props.apiResponse.spec &&
                      this.props.apiResponse.spec.networks
                        ? this.props.apiResponse.spec.networks
                        : [];
                    let network_ids = this.props.apiResponse.spec.networks.map(
                      obj => obj.network_id,
                    );
                    let ip_addresses = this.props.apiResponse.spec.networks.map(
                      obj => obj.ip_address,
                    );
                    /* initialize model with api resp */
                    this.props.UiStore.templatingService.node[ele["__id"]] = {
                      node_id:
                        this.props.apiResponse.node &&
                        this.props.apiResponse.node.id
                          ? this.props.apiResponse.node.id
                          : null,
                      cluster_id:
                        this.props.apiResponse.cluster &&
                        this.props.apiResponse.cluster.id
                          ? this.props.apiResponse.cluster.id
                          : null,
                      network: {
                        network_id: network_ids,
                        ip_address: ip_addresses,
                      },
                      kind:
                        this.props.apiResponse.spec &&
                        this.props.apiResponse.spec.kind
                          ? this.props.apiResponse.spec.kind
                          : null,
                      node_selector:
                        this.props.apiResponse.spec &&
                        this.props.apiResponse.spec.node_selector
                          ? this.props.apiResponse.spec.node_selector
                          : null,
                      networks: networks,
                    };
                    ++network_index;
                  }
                  break;
                }
                case "dnscomponent": {
                  ele["__id"] = `nodenetwork_${randomAlphaNumericString(3)}`;
                  /* Assign default values to DnsComponent component from api resp */
                  if (
                    this.props.apiResponse &&
                    this.props.apiResponse.spec &&
                    this.props.apiResponse.spec.dns &&
                    this.props.apiResponse.spec.dns.length > 0
                  ) {
                    /* initialize model with api resp */
                    this.props.UiStore.templatingService.dns = this.props.apiResponse.spec.dns;
                    this.props.UiStore.templatingService.dns_policy = this.props.apiResponse.spec.dns_policy;
                  }
                  if (
                    this.props.apiResponse &&
                    this.props.apiResponse.status &&
                    this.props.apiResponse.status.dns &&
                    this.props.apiResponse.status.dns.nameserver
                  ) {
                    this.props.UiStore.templatingService.dns_status = this.props.apiResponse.status.dns.nameserver;
                  }
                  break;
                }
                case "dockerconfig": {
                  ele["__id"] = `dockerconfig_${randomAlphaNumericString(3)}`;
                  break;
                }
                case "secret": {
                  ele["__id"] = `secret_${randomAlphaNumericString(
                    3,
                  )}_${ele.props && ele.props.subtype.toLowerCase()}`;
                  break;
                }
                case "fileupload": {
                  ele["__id"] = `fileupload_${randomAlphaNumericString(3)}`;
                  break;
                }
                default: {
                  let serviceType = null;
                  if (this.props.apiResponse && this.props.apiResponse.spec) {
                    /* in edit mode */
                    serviceType =
                      this.props.apiResponse.spec.labels &&
                      this.props.apiResponse.spec.labels["io_iotium_template"]
                        ? this.props.apiResponse.spec.labels &&
                          this.props.apiResponse.spec.labels[
                            "io_iotium_template"
                          ]
                        : this.props.apiResponse.spec.labels &&
                          this.props.apiResponse.spec.labels[
                            "io-iotium-template"
                          ]
                        ? this.props.apiResponse.spec.labels[
                            "io-iotium-template"
                          ]
                        : "custom";

                    /* assign spec for custom textarea */
                    if (
                      serviceType.toLowerCase() === "custom" &&
                      ele.type.toLowerCase() === "jsoninputbox"
                    ) {
                      ele.props.defaultValue = JSON.stringify(
                        this.props.apiResponse.spec,
                        null,
                        2,
                      );
                    }
                  } else if (this.props.templateId) {
                    serviceType = this.props.templateId;
                  }

                  if (
                    serviceType &&
                    serviceType.toLowerCase() === "custom" &&
                    ele.type.toLowerCase() === "jsoninputbox"
                  ) {
                    ele["__id"] = `custom_${randomAlphaNumericString(3)}`;
                  } else {
                    ele["__id"] = randomAlphaNumericString(5);
                  }
                }
              }
            });
          }

          return tab;
        }
      },
    );
    /*remove undefined,null values from array*/
    this.props.UiStore.templatingService.computedSchema.steps = steps.filter(
      x => !!x,
    );
    if (version && version === "2.0") {
      this.validateAndMapObject().then(res => {
        this.validateUsermappingInV2Output().then(res => {
          if (
            this.state.JSONerror.length <= 0 &&
            this.state.userMappingError.length <= 0
          ) {
            this.parseTemplateWithValues().then(
              res => {
                if (res) {
                  this.props.UiStore.templatingService.computedSchema.steps = toJS(
                    res.steps,
                  );
                  if (this.state.numberOfSteps > 0) {
                    this.setState({
                      showForm: true,
                    });
                  } else {
                    setTimeout(() => {
                      this.setState({
                        showForm: true,
                      });
                    }, 2000);
                  }
                }
              },
              reject => {
                error => {};
              },
            );
          }
        });
      });
    } else {
      let output = JSON.stringify(
        this.props.UiStore.templatingService.computedSchema.output,
      );
      this.props.UiStore.templatingService.computedSchema.output = toJS(
        JSON.parse(output),
        true,
      );
      /*logic to check if user mapping is done correctly. LAT-3949*/
      this.validateUsermappingInOutput().then(res => {
        if (
          this.state.JSONerror.length <= 0 &&
          this.state.userMappingError.length <= 0
        ) {
          this.mapIdInOutput().then(
            res => {
              if (res) {
                this.props.UiStore.templatingService.computedSchema.steps = toJS(
                  res.steps,
                );
                this.setState({
                  showForm: true,
                });
              }
            },
            reject => {},
          );
        }
      });
    }
  };

  showmappingError = () => {
    return this.state.userMappingError.map((val, i) => {
      return (
        <p key={i}>
          {i + 1}. {val}
        </p>
      );
    });
  };

  render() {
    let formTitle;
    if (viewService) {
      formTitle = "View Service";
    } else if (this.props.match.params.spec_id) {
      formTitle = "Edit Service";
    } else {
      formTitle = "Add Service";
    }

    return (
      <div>
        <Row gutter={24} type="flex">
          <Col span={24}>
            {this.state.showForm && (
              <div>
                {this.props.UiStore.templatingService.computedSchema && (
                  <Card title={formTitle} style={{ minHeight: "350px" }}>
                    <Row type="flex">
                      <Col span={24}>
                        <ServiceFormRender
                          baseSchema={
                            this.props.UiStore.templatingService.computedSchema
                          }
                          {...this.props}
                          secretLoaded={this.state.secretLoadedOnedit}
                          isSystemService={
                            this.props.apiResponse &&
                            this.props.apiResponse.spec &&
                            this.props.apiResponse.spec.system_service
                              ? this.props.apiResponse.spec.system_service
                              : false
                          }
                        />
                      </Col>
                    </Row>
                  </Card>
                )}
              </div>
            )}
            {this.state.userMappingError.length > 0 && (
              <div>
                <h3>
                  {" "}
                  Cannot find UI elements for the following mapping in the UI
                  spec. Please re-confirm the mapped values.{" "}
                </h3>
                {this.showmappingError()}
              </div>
            )}
            {this.state.JSONerror.length > 0 && (
              <div>
                <h3> Errors </h3>
                {this.state.JSONerror.map((val, i) => {
                  return (
                    <p key={i}>
                      {" "}
                      {++i} . {val}
                    </p>
                  );
                })}
              </div>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default ValidateAndComputeTemplate;
