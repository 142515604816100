import React, { Component } from "react";
import { Form, Button, Input } from "antd";
import InputBox from "../UI-Components/InputBox";
import { observer, inject } from "mobx-react";
import { focusFirstInput, getCurrentlocaleText } from "Core/Utils";
import PasswordInput from "components/UI-Components/PasswordInput";
import PopOverHelp from "components/UI-Components/PopOverHelp";
import ReCAPTCHA from "react-google-recaptcha";
import Icons from "components/UI-Components/Icons";
import {
  UserOutlined,
  ClockCircleOutlined,
  LockOutlined,
} from "@ant-design/icons";
const FormItem = Form.Item;

@inject("ModelStore", "UiStore")
@observer
class Login extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.EnvConfig = ENV_CONFIG;
    this.recaptchaRef = null;
    this.state = {
      is_2fa_enabled: false,
      otp_validate_state: "error",
      otp_validateMessage: getCurrentlocaleText(
        "user.login.otp.security_code.required.text",
      ),
      captcha_validate_state: "error",
      captcha_validateMessage: getCurrentlocaleText("captcha.error.message"),
      isCapsLockOn: false,
    };
  }
  componentDidMount() {
    /* Focus First Input */
    focusFirstInput();
    this.props.UiStore.setDocumentTitle(`Login`);
    this.setState({ is_2fa_enabled: this.props.UiStore.is2faEnabled });
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.is_2fa_enabled != prevProps.UiStore.is2faEnabled &&
      prevProps.UiStore.is2faEnabled
    ) {
      setTimeout(() => {
        if (document.querySelector(".otpInput input")) {
          document.querySelector(".otpInput input").focus();
        }
      }, 50);
    }
  }

  submitLogin = values => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      /* To avoid double click on submit */
      this.props.onLogin(values);
    }, 500);
  };

  resetOTPinput = () => {
    this.props.UiStore.is2faEnabled = false;
  };
  checkOtp = (rule, value, callback) => {
    var reg = new RegExp("^[0-9]+$");
    if (value) {
      if (value.length !== 6) {
        this.setState({
          otp_validate_state: "error",
          otp_validateMessage: getCurrentlocaleText(
            "user.login.otp.security_code.invalid.text",
          ),
        });
        callback(
          getCurrentlocaleText("user.login.otp.security_code.invalid.text"),
        );
      } else if (!reg.test(value)) {
        this.setState({
          otp_validate_state: "error",
          otp_validateMessage: getCurrentlocaleText(
            "user.login.otp.security_code.invalid.text",
          ),
        });
        callback(
          getCurrentlocaleText("user.login.otp.security_code.invalid.text"),
        );
      } else {
        this.setState({ otp_validate_state: "", otp_validateMessage: "" });
        callback();
      }
    } else {
      this.setState({
        otp_validate_state: "error",
        otp_validateMessage: getCurrentlocaleText(
          "user.login.otp.security_code.invalid.text",
        ),
      });
      callback(
        getCurrentlocaleText("user.login.otp.security_code.invalid.text"),
      );
    }
  };
  handleCaptchaChange = value => {
    setTimeout(() => {
      this.formRef.current.setFieldsValue({ captcha: value });
      if (value === null) {
        this.setState({
          captcha_validate_state: "error",
          captcha_validateMessage: getCurrentlocaleText(
            "captcha.error.message",
          ),
        });
      } else {
        this.setState({
          captcha_validate_state: "",
          captcha_validateMessage: "",
        });
      }
    }, 300);
  };
  render() {
    // const { getFieldDecorator } = this.props.form;

    return (
      <div style={{ margin: 35 }}>
        <Form
          ref={this.formRef}
          onFinish={this.submitLogin}
          className="login-form"
          noValidate
        >
          <FormItem
            name={"username"}
            rules={[
              {
                required: true,
                message: getCurrentlocaleText(
                  "general.login.form.field.email.placeholder",
                ),
              },
            ]}
          >
            {/* To allow autocomplete use antd Input component. Otherwise use our InputBox component */}
            <Input
              type="email"
              className="firstInput"
              placeholder={getCurrentlocaleText(
                "general.login.form.field.email.text",
              )}
              onChange={this.resetOTPinput}
              addonBefore={<UserOutlined style={{ fontSize: 13 }} />}
            />
          </FormItem>
          <FormItem
            name={"password"}
            rules={[
              {
                required: true,
                message: getCurrentlocaleText(
                  "general.login.form.field.password.placeholder",
                ),
              },
            ]}
          >
            <PasswordInput
              type="password"
              addonBefore={<LockOutlined style={{ fontSize: 13 }} />}
              placeholder={getCurrentlocaleText(
                "general.login.form.field.password.text",
              )}
              suffix={
                <span>
                  {this.state.isCapsLockOn && (
                    <span style={{ marginRight: 5 }} title="Caps Lock On">
                      <Icons type="fa" name="FaExclamationTriangle" />
                    </span>
                  )}
                  <span
                    onClick={this.togglePassword}
                    style={{ cursor: "pointer" }}
                    title={this.state.type === `password` ? `Show` : "Hide"}
                  >
                    <Icons
                      type="fa"
                      name={
                        this.state.type === `password` ? `FaEyeSlash` : `FaEye`
                      }
                    />
                  </span>
                </span>
              }
              allowAutoComplete
            />
          </FormItem>
          {this.props.UiStore.is2faEnabled && (
            <FormItem
              validateStatus={this.state.otp_validate_state}
              help={this.state.otp_validateMessage}
              name="otp"
              rules={[
                {
                  required: true,
                  message: getCurrentlocaleText(
                    "login-form-users-otp.placeholder.text",
                  ),
                },
                {
                  validator: this.checkOtp,
                },
              ]}
            >
              <span>
                <InputBox
                  addonBefore={<ClockCircleOutlined style={{ fontSize: 13 }} />}
                  placeholder={getCurrentlocaleText(
                    "login-form-users-otp.placeholder.text",
                  )}
                  maxLength={6}
                  minLength={6}
                  className="otpInput"
                  suffix={
                    <PopOverHelp
                      isHelpOnForm={true}
                      className="login-form-help-contaner"
                      height={80}
                      width={285}
                      placement="right"
                      helpData={{
                        title: "",
                        data: [
                          {
                            subtitle: "",
                            content: getCurrentlocaleText(
                              "user.login.otp.help.message.text",
                            ),
                          },
                        ],
                      }}
                    />
                  }
                />
              </span>
            </FormItem>
          )}
          {this.props.UiStore.showCaptcha && (
            <FormItem
              validateStatus={this.state.captcha_validate_state}
              help={this.state.captcha_validateMessage}
              name="captcha"
              rules={[
                {
                  required: true,
                  message: getCurrentlocaleText("captcha.error.message"),
                },
              ]}
            >
              <ReCAPTCHA
                ref={c => (this.recaptchaRef = c)}
                sitekey={
                  this.EnvConfig && this.EnvConfig.RECAPTCHA_CLIENT_SITE_KEY
                }
                onChange={this.handleCaptchaChange}
                placeholder="Captcha"
              />
              ,
            </FormItem>
          )}
          <FormItem>
            <Button
              type="primary"
              htmlType="submit"
              className="login-btn"
              disabled={this.props.disable}
            >
              <Icons type="ai" name="AiOutlineLogin" />
              {getCurrentlocaleText("general.login.form.button.login.text")}
            </Button>
          </FormItem>
        </Form>
      </div>
    );
  }
}

export default Login;
