import React, { Component } from "react";
import { Layout, ConfigProvider } from "antd";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PublicRoute from "Core/PublicRoute";

import enUS from "antd/lib/locale-provider/en_US";

import MessageDisplay from "Core/MessageDisplay";
import DocumentTitle from "Core/DocumentTitle";

import TwoFactorSetupPage from "components/Containers/TwoFactorSetupPage";

import IoFooter from "./Footer";

const { Content } = Layout;

class TwoFactorSetupLayout extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Layout style={{ background: "transparent" }}>
        <DocumentTitle />
        <MessageDisplay />
        <Content>
          <Layout
            style={{ padding: "60px 10px 100px", background: "transparent" }}
          >
            <ConfigProvider locale={enUS}>
              <Switch>
                <PublicRoute
                  exact
                  path="/twofactorsetup/:id"
                  component={TwoFactorSetupPage}
                />
              </Switch>
            </ConfigProvider>
          </Layout>
        </Content>
        <IoFooter
          centerAlignFooter={true}
          isNotChrome={this.props.isNotChrome}
        />
      </Layout>
    );
  }
}

export default TwoFactorSetupLayout;
