import { observable, action, computed } from "mobx";
import AuthStore from "Stores/AuthStore";

class DashboardStore {
  @observable data = localStorage.dashBoardLayout;
  @observable orgs = null;
  @observable
  activity_params = {
    size: 25,
  };
  @observable
  counts = {
    user: 0,
    inode: 0,
    network: 0,
    service: 0,
    org: 0,
    pki: 0,
    firewall: 0,
    secret: 0,
    role: 0,
    cluster: 0,
  };
  @observable
  statusCount = {
    new: 0,
    alive: 0,
    unreachable: 0,
    rebooting: 0,
  };
  @observable
  profilesCount = {
    edge: {
      new: 0,
      alive: 0,
      unreachable: 0,
      rebooting: 0,
    },
    virtual: {
      new: 0,
      alive: 0,
      unreachable: 0,
      rebooting: 0,
    },
    virtualedge: {
      new: 0,
      alive: 0,
      unreachable: 0,
      rebooting: 0,
    },
    cloudgateway: {
      new: 0,
      alive: 0,
      unreachable: 0,
      rebooting: 0,
    },
  };
  @observable conslidatedData = {};

  @observable userLoading = false;
  @observable inodeLoading = false;
  @observable networkLoading = false;
  @observable serviceLoading = false;
  @observable orgLoading = false;
  @observable forceUpdate = false;
  @observable refreshActivity = false;
  @observable allDetailsLoading = true;

  //get widget layout
  @computed
  get dashboardLayout() {
    let Completedata = this.data,
      userId = AuthStore.loggedInUser.id;

    let data = { rows: [] };

    if (Completedata) {
      let layoutData = JSON.parse(Completedata);
      layoutData.filter(value => {
        if (value.id === userId) {
          data = { rows: value.rows };
        }
      });
    }
    return data;
  }

  @action
  setDashboardLayout(data) {
    this.data = data;
  }

  @action
  resetDashboardProfileStatusChartData() {
    this.statusCount = {
      new: 0,
      alive: 0,
      unreachable: 0,
      rebooting: 0,
    };
  }
  @action
  resetDashboardStatusChartData() {
    this.profilesCount = {
      edge: {
        new: 0,
        alive: 0,
        unreachable: 0,
        rebooting: 0,
      },
      virtual: {
        new: 0,
        alive: 0,
        unreachable: 0,
        rebooting: 0,
      },
      virtualedge: {
        new: 0,
        alive: 0,
        unreachable: 0,
        rebooting: 0,
      },
      cloudgateway: {
        new: 0,
        alive: 0,
        unreachable: 0,
        rebooting: 0,
      },
    };
  }

  //get widget layout
  @computed
  get dashboardWidget() {
    let widgets = {};
    let Completedata = this.data;
    widgets = Completedata.widgets;
    return widgets;
  }

  @computed
  get totalCount() {
    let profileObj = this.conslidatedData ? this.conslidatedData : {};
    return {
      user:
        profileObj && profileObj.user && profileObj.user.total
          ? profileObj.user.total
          : 0,
      inode:
        profileObj && profileObj.inode && profileObj.inode.total
          ? profileObj.inode.total
          : 0,
      network:
        profileObj && profileObj.network && profileObj.network.total
          ? profileObj.network.total
          : 0,
      service:
        profileObj && profileObj.service && profileObj.service.total
          ? profileObj.service.total
          : 0,
      org:
        profileObj && profileObj.organization && profileObj.organization.total
          ? profileObj.organization.total
          : 0,
      pki:
        profileObj &&
        profileObj.hardware_serial_number &&
        profileObj.hardware_serial_number.total
          ? profileObj.hardware_serial_number.total
          : 0,
      firewall:
        profileObj &&
        profileObj.security_group &&
        profileObj.security_group.total
          ? profileObj.security_group.total
          : 0,
      secret:
        profileObj && profileObj.secret && profileObj.secret.total
          ? profileObj.secret.total
          : 0,
      role:
        profileObj && profileObj.role && profileObj.role.total
          ? profileObj.role.total
          : 0,
      cluster:
        profileObj && profileObj.cluster && profileObj.cluster.total
          ? profileObj.cluster.total
          : 0,
    };
  }

  @computed
  get countsByProfiles() {
    let profileObj =
      this.conslidatedData &&
      this.conslidatedData.inode &&
      this.conslidatedData.inode.profile
        ? this.conslidatedData.inode.profile
        : {};
    let computedInodeCount = {
      edge: {
        new:
          profileObj && profileObj.edge && profileObj.edge.new
            ? profileObj.edge.new
            : 0,
        alive:
          profileObj && profileObj.edge && profileObj.edge.alive
            ? profileObj.edge.alive
            : 0,
        unreachable:
          profileObj && profileObj.edge && profileObj.edge.unreachable
            ? profileObj.edge.unreachable
            : 0,
        rebooting:
          profileObj && profileObj.edge && profileObj.edge.rebooting
            ? profileObj.edge.rebooting
            : 0,
      },
      virtual: {
        new:
          profileObj && profileObj.virtual && profileObj.virtual.new
            ? profileObj.virtual.new
            : 0,
        alive:
          profileObj && profileObj.virtual && profileObj.virtual.alive
            ? profileObj.virtual.alive
            : 0,
        unreachable:
          profileObj && profileObj.virtual && profileObj.virtual.unreachable
            ? profileObj.virtual.unreachable
            : 0,
        rebooting:
          profileObj && profileObj.virtual && profileObj.virtual.rebooting
            ? profileObj.virtual.rebooting
            : 0,
      },
      virtualedge: {
        new:
          profileObj && profileObj.virtual_edge && profileObj.virtual_edge.new
            ? profileObj.virtual_edge.new
            : 0,
        alive:
          profileObj && profileObj.virtual_edge && profileObj.virtual_edge.alive
            ? profileObj.virtual_edge.alive
            : 0,
        unreachable:
          profileObj &&
          profileObj.virtual_edge &&
          profileObj.virtual_edge.unreachable
            ? profileObj.virtual_edge.unreachable
            : 0,
        rebooting:
          profileObj &&
          profileObj.virtual_edge &&
          profileObj.virtual_edge.rebooting
            ? profileObj.virtual_edge.rebooting
            : 0,
      },
    };
    if (profileObj && profileObj.cloud_gateway) {
      computedInodeCount.cloudgateway = {
        new:
          profileObj && profileObj.cloud_gateway && profileObj.cloud_gateway.new
            ? profileObj.cloud_gateway.new
            : 0,
        alive:
          profileObj &&
          profileObj.cloud_gateway &&
          profileObj.cloud_gateway.alive
            ? profileObj.cloud_gateway.alive
            : 0,
        unreachable:
          profileObj &&
          profileObj.cloud_gateway &&
          profileObj.cloud_gateway.unreachable
            ? profileObj.cloud_gateway.unreachable
            : 0,
        rebooting:
          profileObj &&
          profileObj.cloud_gateway &&
          profileObj.cloud_gateway.rebooting
            ? profileObj.cloud_gateway.rebooting
            : 0,
      };
    }
    return computedInodeCount;
  }
  /**
   *  iNode status count of ALIVE, NEW, UNREACHABLE, REBOOTING
   */
  @computed
  get countsByiNodeStatus() {
    let profileObj =
      this.conslidatedData &&
      this.conslidatedData.inode &&
      this.conslidatedData.inode.profile
        ? this.conslidatedData.inode.profile
        : {};

    return {
      new: this.getTotal(profileObj, "new"),
      alive: this.getTotal(profileObj, "alive"),
      unreachable: this.getTotal(profileObj, "unreachable"),
      rebooting: this.getTotal(profileObj, "rebooting"),
    };
  }

  getTotal = (profileObj, valToTotal) => {
    let total = 0;
    for (const key in profileObj) {
      if (profileObj.hasOwnProperty(key)) {
        total += profileObj[key][valToTotal];
      }
    }
    return total;
  };
}

export default new DashboardStore();
