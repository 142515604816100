import React, { Component } from "react";
import { getCurrentlocaleText } from "Core/Utils";

class RouteConfigViaHelp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedVnode: this.props.selectedVnode,
    };
  }

  UNSAFE_componentWillReceiveProps(newprops) {
    if (this.props.selectedVnode.length != newprops.selectedVnode.length) {
      this.setState({ selectedVnode: newprops.selectedVnode });
      this.updateHelp();
    }
  }

  componentDidMount() {
    this.updateHelp();
  }

  updateHelp = () => {
    let elm = document.querySelector("#route-config-via-help-content");
    if (elm) {
      elm.innerHTML =
        this.state.selectedVnode.length > 0
          ? getCurrentlocaleText("route_config.via.help.text")
          : getCurrentlocaleText("route_config.via.without_remote.help.text");
    }
  };

  render() {
    this.updateHelp();
    return <div id="route-config-via-help-content" />;
  }
}
export default RouteConfigViaHelp;
