import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Cascader,
  Table,
  Tag,
  message,
  InputNumber,
  Checkbox,
  Popover,
  Spin,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import { observer, inject } from "mobx-react";
import {
  getCurrentlocaleText,
  getNodeVersion,
  dynamicNetworkModeBaseVersion,
  getOrgPolicyCounInfo,
  isDevelopmentMode,
  arraysEqual,
} from "Core/Utils";
import InodeController from "controller/InodeController";
import ClusterUtils from "components/Clusters/ClusterUtils";
import Icons from "components/UI-Components/Icons";

const FormItem = Form.Item;

@inject("UiStore", "OrgViewModel")
@observer
class ClusterNodeConfig extends Component {
  constructor(props) {
    super(props);
    this.orgModel = this.props.OrgViewModel;
    this.state = {
      loading: false,
      clusterediNodes: [],
      inode_list_for_menu: [],
      total_remote_nodes: [],
      iNodeInfo: [],
      SelectedNodes: [],
      currentAddedNodes: [],
      currentNodeValue: {},
      isFormEdited: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({ loading: true });
    this.updateiNodeDetails();
  }

  componentWillUnmount() {
    this.setState({ isFormEdited: false });
  }

  componentDidUpdate(prevProps) {
    // Candidate
    if (
      prevProps.makeCandiateNodes !== this.props.makeCandiateNodes &&
      !arraysEqual(prevProps.makeCandiateNodes, this.props.makeCandiateNodes)
    ) {
      let clusterediNodes = this.state.clusterediNodes;
      if (clusterediNodes) {
        this.props.makeCandiateNodes.forEach(nodeId => {
          let index = clusterediNodes.findIndex(node => {
            return node && node.node_id === nodeId;
          });
          if (index !== -1) {
            clusterediNodes[index].is_candidate = true;
          }
        });
        this.setState({ clusterediNodes: clusterediNodes });
      }
    }
    // AddNodes
    if (
      prevProps.addNodes !== this.props.addNodes &&
      !arraysEqual(prevProps.addNodes, this.props.addNodes)
    ) {
      let clusterediNodes = this.state.clusterediNodes;
      let totalNodes = this.state.total_remote_nodes;
      if (
        clusterediNodes &&
        totalNodes &&
        this.props.intialValues &&
        this.props.intialValues.nodes
      ) {
        this.props.intialValues.nodes.forEach(clusterNode => {
          if (
            clusterNode &&
            clusterNode.node &&
            clusterNode.node.id &&
            this.props.addNodes.includes(clusterNode.node.id)
          ) {
            // Add Node
            let nodeDetails = totalNodes.find(node => {
              return node && node.id === clusterNode.node.id;
            });
            if (nodeDetails) {
              let nodeData = ClusterUtils.computeNewiNodeData();
              nodeData.key = nodeDetails.id;
              nodeData.node_id = nodeDetails.id;
              nodeData.org_name = nodeDetails.organization.name;
              nodeData.org_id = nodeDetails.organization.id;
              nodeData.node_name = nodeDetails.name;
              nodeData.record_value = [
                nodeDetails.organization.id,
                nodeDetails.id,
              ];
              nodeData.priority = clusterNode.config.priority;
              nodeData.is_candidate = clusterNode.config.is_candidate;
              nodeData.is_master = this.isMasteriNode(nodeDetails.id);
              //
              let index = clusterediNodes.findIndex(node => {
                return node && node.node_id === nodeData.node_id;
              });
              if (index === -1) {
                clusterediNodes.push(nodeData);
                this.setState({ clusterediNodes: clusterediNodes });
              }
            }
          }
        });
      }
    }
  }

  updateiNodeDetails = () => {
    let params = {
      org_id: this.props.match.params.id,
      own: true,
      // Allow virtual edge node also if the dev mode is enabled
      profile_name: isDevelopmentMode() ? ["Edge", "Virtual Edge"] : "Edge",
    };
    (params.page = 0), (params.size = 1);
    InodeController.getInodesForDropdown(params, false)
      .then(response => {
        if (response) {
          this.updateOrgsiNodeData(response.total_count / 100, []);
        } else {
          this.setState({ loading: false });
        }
      })
      .catch(error => {
        this.setState({ loading: false });
      });
  };

  /**
   *  UPDATE all inodes Data
   */
  updateOrgsiNodeData(totalCount, availableNodes = []) {
    if (totalCount > 0) {
      let allParams = [];
      for (let page = 0; page < totalCount; page++) {
        allParams.push({
          org_id: this.props.match.params.id,
          own: true,
          size: 100,
          page: page,
          // Allow virtual edge node also if the dev mode is enabled
          profile_name: isDevelopmentMode() ? ["Edge", "Virtual Edge"] : "Edge",
        });
      }
      InodeController.getAllInodesForDropdown(allParams, false)
        .then(resp => {
          if (resp) {
            availableNodes = availableNodes.concat(resp);
            if (
              (this.props.intialValues && this.props.intialValues.id) ||
              (this.props.match &&
                this.props.match.params &&
                this.props.match.params.cluster_id)
            ) {
              let cluster_id = this.props.intialValues.id
                ? this.props.intialValues.id
                : this.props.match.params.cluster_id;
              availableNodes = availableNodes.filter(nodes => {
                // Allow GEN2 node also if the dev mode is enabled
                let allowedDistro = isDevelopmentMode()
                  ? true
                  : nodes.status &&
                    nodes.status.node &&
                    nodes.status.node.info &&
                    nodes.status.node.info.distro &&
                    nodes.status.node.info.distro.id &&
                    nodes.status.node.info.distro.id === "GEN3"
                  ? true
                  : false;
                let hwmSuporetdNode = isDevelopmentMode()
                  ? true
                  : nodes.status &&
                    nodes.status.node &&
                    nodes.status.node.info &&
                    nodes.status.node.info.device_vendor_details &&
                    nodes.status.node.info.device_vendor_details
                      .toUpperCase()
                      .includes("R240");

                let isNodeAllowed =
                  nodes.node_state === "NEW" ||
                  (parseFloat(getNodeVersion(nodes)) >=
                    dynamicNetworkModeBaseVersion &&
                    allowedDistro &&
                    (this.props.hwmEnabled ? hwmSuporetdNode : true))
                    ? true
                    : false;

                if (
                  nodes.cluster &&
                  nodes.cluster.id &&
                  nodes.cluster.id === cluster_id
                ) {
                  return nodes;
                } else if (
                  !nodes.services ||
                  nodes.services.length === 0 ||
                  (this.checkSystemStat(nodes.services) &&
                    nodes.networks.length <= 1 &&
                    !nodes.cluster &&
                    isNodeAllowed)
                ) {
                  return nodes;
                }
              });
            } else {
              availableNodes = availableNodes.filter(nodes => {
                let hwmSuporetdNode = isDevelopmentMode()
                  ? true
                  : nodes.status &&
                    nodes.status.node &&
                    nodes.status.node.info &&
                    nodes.status.node.info.device_vendor_details &&
                    nodes.status.node.info.device_vendor_details
                      .toUpperCase()
                      .includes("R240");
                let isNodeAllowed =
                  nodes.node_state === "NEW" ||
                  (parseFloat(getNodeVersion(nodes)) >=
                    dynamicNetworkModeBaseVersion &&
                    (this.props.hwmEnabled ? hwmSuporetdNode : true))
                    ? true
                    : false;
                if (
                  (nodes.services && nodes.services.length === 0) ||
                  (this.checkSystemStat(nodes.services) &&
                    nodes.networks.length <= 1 &&
                    !nodes.cluster &&
                    isNodeAllowed)
                ) {
                  return nodes;
                }
              });
            }
            this.setState({
              loading: false,
              total_remote_nodes: availableNodes,
              inode_list_for_menu: ClusterUtils.computeCascaderOptions(
                availableNodes,
              ),
            });
          } else {
            this.setState({ loading: false });
          }
        })
        .catch(error => {
          this.setState({ loading: false });
        });
      let computedData = ClusterUtils.getConnectedNodesInfo(
        this.state.clusterediNodes,
        this.props.intialValues.nodes,
        this.props.intialValues,
      );
      this.setState({
        clusterediNodes:
          computedData.clusterediNodesData && computedData.clusterediNodesData,
        SelectedNodes: computedData.selectedInodes,
      });
      this.pushConnectedNodesdata(computedData.clusterediNodesData, true);
    } else {
      this.setState({ loading: false });
    }
  }

  /**
   * Method to remove association with  cluster
   */
  disassociateNode = (key, node_id) => {
    let clusterediNodes = this.state.clusterediNodes,
      SelectedNodesdata = this.state.SelectedNodes,
      index = SelectedNodesdata.indexOf(node_id);
    if (node_id !== null) {
      if (index > -1) {
        SelectedNodesdata.splice(index, 1);
      }
    }

    let data = clusterediNodes.filter(node_array => {
      return key !== node_array.key;
    });
    this.setState({
      clusterediNodes: data,
      SelectedNodes: SelectedNodesdata,
      currentNodeValue: {},
      showAddButton: false,
      iNodeInfo: [],
      isFormEdited: true,
    });
    // push data to parent component
    this.pushConnectedNodesdata(data);
  };

  /**
   * Remote Node on select func...
   */
  selectedOptions = (values, selectedOptions) => {
    if (selectedOptions.length > 0) {
      let selectedNode = {};
      let data = selectedOptions.filter(Options => {
        if (Options.value === values[0])
          selectedNode["org"] = {
            org_id: Options.value,
            org_name: Options.label,
          };
        else if (Options.value === values[1])
          selectedNode["inode"] = {
            node_id: Options.value,
            node_name: Options.label,
          };
      });
      let iNodeInfo = this.state.iNodeInfo;
      iNodeInfo.push(selectedNode);
      let currentaddedNodes = this.state.currentNodeValue;
      this.setState({
        iNodeInfo: iNodeInfo,
        showAddButton: true,
      });

      let remoteNode = this.state.iNodeInfo[0].inode;
      if (this.state.SelectedNodes.indexOf(remoteNode.node_id) === -1) {
        let currentAddedNode = this.state.clusterediNodes,
          currentRow = this.state.currentRowKey,
          CurrentIndex = -1;
        CurrentIndex = this.state.clusterediNodes.findIndex(clusteredNode => {
          return clusteredNode && clusteredNode.key === currentRow;
        });
        if (CurrentIndex !== -1) {
          let nodeData = currentAddedNode[CurrentIndex];
          nodeData.key = remoteNode.node_id;
          nodeData.node_id = this.state.iNodeInfo[0].inode.node_id;
          nodeData.org_name = this.state.iNodeInfo[0].org.org_name;
          nodeData.org_id = this.state.iNodeInfo[0].org.org_id;
          nodeData.node_name = this.state.iNodeInfo[0].inode.node_name;
          nodeData.record_value = values;
          nodeData.is_master = this.isMasteriNode(remoteNode.node_id);
          currentAddedNode[CurrentIndex] = nodeData;
          currentaddedNodes[remoteNode.node_id] = values;
          let SelectedNodes = this.state.SelectedNodes;
          SelectedNodes.push(remoteNode.node_id);
          this.setState({
            clusterediNodes: currentAddedNode,
            showAddButton: false,
            iNodeInfo: [],
            SelectedNodes: SelectedNodes,
            currentNodeValue: currentaddedNodes,
          });
          this.pushConnectedNodesdata(currentAddedNode);
        }
      } else {
        message.warning({
          content: getCurrentlocaleText(
            "cluster.form.node.duplicate.warning.text",
          ),
          duration: 8,
          icon: <span></span>,
        });
        let currentAddedNodes = this.state.currentAddedNodes,
          currentRow = this.state.currentRowKey,
          removedData = [],
          SelectedNodesdata = this.state.SelectedNodes,
          index = SelectedNodesdata.indexOf(currentRow);
        if (currentRow != null) {
          if (index > -1) {
            SelectedNodesdata.splice(index, 1);
          }
        }
        currentAddedNodes.forEach(node => {
          if (node.key === currentRow) {
            node.record_value = [];
            removedData.push(node);
          } else {
            removedData.push(node);
          }
        });
        this.setState({
          currentAddedNodes: removedData,
          showAddButton: false,
          iNodeInfo: [],
          SelectedNodes: SelectedNodesdata,
        });
      }
    } else {
      let currentAddedNodes = this.state.currentAddedNodes,
        currentRow = this.state.currentRowKey,
        removedData = [],
        SelectedNodesdata = this.state.SelectedNodes,
        index = SelectedNodesdata.indexOf(currentRow);
      if (currentRow != null) {
        if (index > -1) {
          SelectedNodesdata.splice(index, 1);
        }
      }
      currentAddedNodes.forEach(node => {
        if (node.key === currentRow) {
          node.record_value = [];
          removedData.push(node);
        } else {
          removedData.push(node);
        }
      });
      this.setState({
        currentAddedNodes: removedData,
        showAddButton: false,
        iNodeInfo: [],
        SelectedNodes: SelectedNodesdata,
      });
    }
  };

  /**
   * Push  cluster connected nodes to the parent component
   */
  pushConnectedNodesdata = (data, isInitial = null) => {
    let clusterediNodes = [];
    data.forEach(collections => {
      if (collections.node_id !== null) {
        let node = {
          node_id: collections.node_id,
          config: {
            priority: collections.priority,
            is_candidate: collections.is_candidate,
          },
        };
        clusterediNodes.push(node);
      }
    });
    this.setData(
      isInitial && clusterediNodes.length === 0 ? null : clusterediNodes,
    );
  };
  /**
   * check service is system stat
   */
  checkSystemStat = nodeService => {
    if (
      nodeService.length > 0 &&
      nodeService[0].name &&
      nodeService[0].name.toLowerCase() === "inode-systemstats"
    ) {
      return true;
    } else {
      return false;
    }
  };
  /**
   * updatePriority
   */
  updatePriority = (currentKey, priority) => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let clusterediNodes = this.state ? this.state.clusterediNodes : [];
        let index = clusterediNodes.findIndex(node => {
          return node && node.key && node.key === currentKey;
        });
        if (index !== -1) {
          clusterediNodes[index].priority = priority;
          this.setState({
            clusterediNodes: clusterediNodes,
            showAddButton: false,
            iNodeInfo: [],
          });
          this.pushConnectedNodesdata(clusterediNodes);
        }
      }
    });
  };

  handlePriorityChange = (value, key) => {
    this.updatePriority(key, value);
  };

  handleCandidateChange = (e, currentKey) => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let clusterediNodes = this.state ? this.state.clusterediNodes : [],
          is_candidate =
            e.target && e.target.checked ? e.target.checked : false;
        let index = clusterediNodes.findIndex(node => {
          return node && node.key && node.key === currentKey;
        });
        if (index !== -1) {
          clusterediNodes[index].is_candidate = is_candidate;
          this.setState({
            clusterediNodes: clusterediNodes,
            showAddButton: false,
            iNodeInfo: [],
          });
          this.pushConnectedNodesdata(clusterediNodes);
        }
      }
    });
  };

  setData = value => {
    this.props.setClusteredNodes(value);
  };
  updateHelp = data => {
    this.props.UiStore.SetStoreData("helpData", data);
  };

  /**
   *  Add New iNode for cluster list
   */
  addNewnode = () => {
    let data = ClusterUtils.computeNewiNodeData(),
      inodeObjects = this.state.clusterediNodes;
    let numberOfCandidate = this.getCandidateCount();
    if (
      numberOfCandidate >=
      getOrgPolicyCounInfo(this.orgModel.org, "cluster_candidate_count")
    ) {
      data.is_candidate = false;
    } else {
      data.is_candidate = true;
    }
    inodeObjects.push(data);
    this.setState({
      clusterediNodes: inodeObjects,
      isFormEdited: true,
    });

    setTimeout(() => {
      // geting class name
      let class_name = "cascader-disp" + data.key;
      // find element to scroll to view
      let element = document.getElementsByClassName(class_name);
      if (element && element[0]) {
        element[0].scrollIntoView();
      }
    }, 400);
  };

  /**
   *  Method to check the given iNode is master or not
   */
  isMasteriNode = node_id => {
    let status = null,
      state = "BACKUP",
      data = this.props.intialValues;
    if (data && data.status && data.status.nodes)
      status = data.status.nodes.filter(n => n.id === node_id);
    if (status && status.length > 0 && status[0]) {
      state = status[0].state;
    }
    if (state === "MASTER") {
      return true;
    } else {
      return false;
    }
  };

  /**
   * Method to get inode state
   */
  getiNodeState = node_id => {
    let status = "NEW";
    let data = this.state.total_remote_nodes;
    if (data && data.length > 0) status = data.filter(n => n.id === node_id);
    if (status && status.length > 0 && status[0]) {
      status = status[0].node_state;
    }
    return status;
  };

  /**
   * Method to get candidate iNode count
   */
  getCandidateCount = () => {
    let clusterediNodes = this.state.clusterediNodes;
    if (clusterediNodes && clusterediNodes.length > 0) {
      let candidates = clusterediNodes.filter(node => {
        return node && node.is_candidate;
      });
      return candidates ? candidates.length : 0;
    }
    return 0;
  };

  /**
   * Method to get master iNode count
   */
  getMasterCount = () => {
    let clusterediNodes = this.state.clusterediNodes;
    if (clusterediNodes && clusterediNodes.length > 0) {
      let masteriNodes = clusterediNodes.filter(node => {
        return node && node.is_master;
      });
      return masteriNodes ? masteriNodes.length : 0;
    }
    return 0;
  };

  render() {
    let dataSource = ClusterUtils.removeDuplicates(this.state.clusterediNodes);

    const displayRender = (labels, selectedOptions) => {
      if (selectedOptions.length > 0) {
        return (
          <Tag key={selectedOptions}>
            {labels.map((label, i) => {
              const option = selectedOptions[i];
              if (option) {
                if (i === labels.length - 1) {
                  return (
                    <span key={option.value}>
                      &nbsp;{option.tag}&nbsp;{label}&nbsp;
                    </span>
                  );
                }
                return (
                  <span key={option.value}>
                    &nbsp;{option.tag}&nbsp;{label}&nbsp;/
                  </span>
                );
              }
            })}
          </Tag>
        );
      }
    };
    this.columns = [
      {
        title: getCurrentlocaleText("clusters.inodes.table.column.text"),
        dataIndex: "node_info",
        key: "node_info",
        width: "35%",
        render: (text, record, index) => {
          let isMaster = this.isMasteriNode(record.node_id);
          return (
            <Col>
              <span>
                {
                  <div>
                    <FormItem>
                      <Cascader
                        allowClear={false}
                        size="medium"
                        placeholder={getCurrentlocaleText(
                          "alertform.inode.placeholder.text",
                        )}
                        key={record.key}
                        showSearch
                        value={record.record_value}
                        expandTrigger="hover"
                        onChange={this.selectedOptions}
                        className={"cascader-disp" + record.key}
                        options={this.state.inode_list_for_menu}
                        title={
                          record.record_value.length <= 0
                            ? getCurrentlocaleText(
                                "alertform.inode.placeholder.text",
                              )
                            : record.org_name + " / " + record.node_name
                        }
                        disabled={this.props.viewOnly}
                        onClick={() => {
                          this.setState({ currentRowKey: record.key });
                        }}
                        displayRender={displayRender}
                        notFoundContent={getCurrentlocaleText(
                          "general.notfound.placeholder.text",
                        )}
                        onFocus={() => {
                          this.setState({ currentRowKey: record.key });
                          this.updateHelp({
                            title: getCurrentlocaleText(
                              "general.component.inode.text",
                            ),
                            data: [
                              {
                                subtitle: "",
                                content: getCurrentlocaleText(
                                  "cluster.inode.help.content",
                                ),
                              },
                            ],
                          });
                        }}
                      />
                    </FormItem>
                  </div>
                }
              </span>
              <Col className="master-tag-cluster-pan">
                {isMaster && record.is_candidate && this.getMasterCount() == 1 && (
                  <Tag className="iot-tag-label-color" closable={false}>
                    {getCurrentlocaleText("cluster.node_list.master.cidr.text")}
                  </Tag>
                )}
                {isMaster &&
                this.getMasterCount() > 1 &&
                !this.state.isFormEdited &&
                record.is_candidate ? (
                  <Popover
                    overlayStyle={{ maxWidth: "20%", float: "right" }}
                    placement="right"
                    content={getCurrentlocaleText(
                      "cluster.master.election.inprogress.message",
                    )}
                  >
                    <span>
                      <Icons
                        style={{ fontSize: 15, marginLeft: 5, color: "orange" }}
                        className=""
                        type="fa"
                        name="FaRegClock"
                      />
                    </span>
                  </Popover>
                ) : (
                  this.getMasterCount() == 0 &&
                  record.is_candidate &&
                  this.getiNodeState(record.node_id) === "ALIVE" && (
                    <Popover
                      overlayStyle={{ maxWidth: "20%", float: "right" }}
                      placement="right"
                      content={getCurrentlocaleText(
                        "cluster.master.election.inprogress.message",
                      )}
                    >
                      <span>
                        <Icons
                          style={{
                            fontSize: 15,
                            marginLeft: 5,
                            color: "orange",
                          }}
                          className=""
                          type="fa"
                          name="FaRegClock"
                        />
                      </span>
                    </Popover>
                  )
                )}
              </Col>
            </Col>
          );
        },
      },
      {
        title: "",
        dataIndex: "is_candidate",
        key: "is_candidate",
        width: "45%",
        render: (text, record, index) => {
          return (
            <div style={{ marginBottom: "3%" }}>
              <Col style={{ width: "10%", display: "inline" }}>
                <Checkbox
                  className={`canididate-${record.key}`}
                  onChange={val => {
                    this.handleCandidateChange(val, record.key);
                  }}
                  checked={!record.is_candidate ? false : true}
                  disabled={this.props.viewOnly}
                  onFocus={() => {
                    this.updateHelp({
                      title: getCurrentlocaleText(
                        "cluster.candidate.label.text",
                      ),
                      data: [
                        {
                          subtitle: "",
                          content: getCurrentlocaleText(
                            "cluster.candidate.help.content",
                          ),
                        },
                      ],
                    });
                  }}
                >
                  {getCurrentlocaleText("cluster.candidate.label.text")}
                </Checkbox>
              </Col>
              <Col style={{ display: "inline", paddingLeft: "8px" }}>
                {record.is_candidate && (
                  <span
                    className={"iot-label-not-required"}
                    title={getCurrentlocaleText(
                      "firewall.list.table.header.priority.text",
                    )}
                    style={{ marginLeft: "0px", marginRight: 2 }}
                    onClick={() => {
                      this.updateHelp({
                        title: getCurrentlocaleText(
                          "firewall.rule.priority.help.title.text",
                        ),
                        data: [
                          {
                            subtitle: "",
                            content: getCurrentlocaleText(
                              "cluster.inode.priority.help.message.text",
                            ),
                          },
                          {
                            subtitle: "",
                            content: getCurrentlocaleText(
                              "cluster.inode.priority.help.message.text2",
                            ),
                          },
                        ],
                      });
                    }}
                  >
                    {getCurrentlocaleText(
                      "firewall.list.table.header.priority.text",
                    ) + " :"}
                  </span>
                )}
              </Col>
              <Col style={{ marginLeft: "2px", display: "inline" }}>
                {record.is_candidate && (
                  <InputNumber
                    className={`priority-${record.key}`}
                    onChange={val => {
                      this.handlePriorityChange(val, record.key);
                    }}
                    value={record.priority ? record.priority : 100}
                    disabled={this.props.viewOnly}
                    max={254}
                    min={1}
                    onFocus={() => {
                      this.updateHelp({
                        title: getCurrentlocaleText(
                          "firewall.rule.priority.help.title.text",
                        ),
                        data: [
                          {
                            subtitle: "",
                            content: getCurrentlocaleText(
                              "cluster.inode.priority.help.message.text",
                            ),
                          },
                          {
                            subtitle: "",
                            content: getCurrentlocaleText(
                              "cluster.inode.priority.help.message.text2",
                            ),
                          },
                        ],
                      });
                    }}
                  />
                )}
              </Col>
            </div>
          );
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "remove-node",
        width: "20%",
        render: (text, record, index) => {
          return (
            <div>
              <Col>
                {
                  <span className={"cluster-node-remove-btn"}>
                    {!this.props.viewOnly && (
                      <span
                        title={"Remove iNode"}
                        className="remote-network-action-btn"
                        onClick={() => {
                          this.disassociateNode(record.key, record.node_id);
                        }}
                        type="minus-circle"
                      >
                        <Icons type="ai" name="AiOutlineMinusCircle" />
                      </span>
                    )}
                  </span>
                }
              </Col>
            </div>
          );
        },
      },
    ];

    let content = (
      <Row gutter={2}>
        <Col>
          {!this.props.viewOnly && (
            <div style={{ textAlign: "right" }}>
              <Button
                icon={<PlusOutlined />}
                style={{ margin: "0 20px 10px 0" }}
                type="primary"
                size="small"
                onClick={this.addNewnode}
                title={
                  dataSource &&
                  dataSource.length >=
                    getOrgPolicyCounInfo(
                      this.orgModel.org,
                      "cluster_node_count",
                    )
                    ? getCurrentlocaleText(
                        "cluster.maximum_limit.node.warning.text",
                        {
                          0: getOrgPolicyCounInfo(
                            this.orgModel.org,
                            "cluster_node_count",
                          ),
                        },
                      )
                    : getCurrentlocaleText(
                        "cluster_node.button.add.label.title.text",
                      )
                }
                disabled={
                  dataSource &&
                  dataSource.length >=
                    getOrgPolicyCounInfo(
                      this.orgModel.org,
                      "cluster_node_count",
                    )
                    ? true
                    : false
                }
              >
                {getCurrentlocaleText(
                  "cluster_node.button.add.label.title.text",
                )}
              </Button>
            </div>
          )}
          <Table
            size={"small"}
            locale={{
              emptyText: getCurrentlocaleText(
                "cluster.form.inode.notfound.text",
              ),
            }}
            bordered={false}
            columns={this.columns}
            dataSource={ClusterUtils.removeDuplicates(
              this.state.clusterediNodes,
            )}
            pagination={false}
            expandedRowKeys={this.state.expandedRow}
            indentSize={5}
            scroll={{ y: 250 }}
          />
        </Col>
      </Row>
    );
    return (
      <div>
        {this.state.loading ? (
          <div className="chart-spinner">
            <Spin tip="Loading..." />
          </div>
        ) : (
          <div>{content}</div>
        )}
      </div>
    );
  }
}

const ClusterNodeConfigSelector = Form.create()(ClusterNodeConfig);
export default ClusterNodeConfigSelector;
