import React, { Component } from "react";
import { Select } from "antd";
import { Form } from "@ant-design/compatible";
import { customSort, copyObject, getCurrentlocaleText } from "Core/Utils";
import propTypes from "prop-types";
import { inject } from "mobx-react";
import { computed } from "mobx";
import Twig from "twig";

/*Define form elements*/
const FormItem = Form.Item;
const Option = Select.Option;

@inject("UiStore")
export default class DropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listData: [],
    };
    this.defaultValue = null;
  }

  listData = data => {
    return (
      data &&
      data
        .sort((x, y) => {
          return customSort(x.label, y.label);
        })
        .map((val, index) => {
          return <Option key={val.value}>{val.label}</Option>;
        })
    );
  };

  componentDidMount() {
    if (this.props.properties.options && this.props.properties.options.list) {
      this.setState({ listData: this.props.properties.options.list });

      let defaultVal = this.props.properties.defaultValue
        ? this.props.properties.defaultValue.toString()
        : null;

      defaultVal &&
        this.props.properties.options.list.forEach(obj => {
          if (obj.value === defaultVal) {
            this.defaultValue = defaultVal;
          }
        });
    }
    if (this.props.updateCurrentValueOnChange) {
      this.props.updateCurrentValueOnChange(
        this.props.properties.defaultValue &&
          this.props.properties.defaultValue.value
          ? [this.props.properties.defaultValue.value]
          : typeof this.props.properties.defaultValue === "object" &&
            this.props.properties.defaultValue
          ? [this.defaultValue]
          : null,
        "value",
      );
    }
  }

  @computed
  get componentVisibility() {
    let visibility = null;
    if (
      this.props.UiStore.computedOutputObject &&
      this.props.UiStore.computedOutputObject.steps
    ) {
      if (this.props.properties.visibility) {
        var template = Twig.twig({
          data: this.props.properties.visibility,
        });
        let computedOutputObject = copyObject(
          this.props.UiStore.computedOutputObject,
        );
        visibility = template.render(computedOutputObject);
        try {
          visibility = JSON.parse(visibility);
        } catch {}
      }
    }
    return visibility ? true : false;
  }

  selectedValue = (value, obj) => {};

  render() {
    /*required as output from select is an array*/

    const { getFieldDecorator } = this.props.referenceForm; // reference of base form
    let required =
      this.props.properties.constraints &&
      this.props.properties.constraints.required
        ? this.props.properties.constraints.required
        : false;

    let validationMessage =
      this.props.properties.constraints &&
      this.props.properties.constraints.validationMessage
        ? this.props.properties.constraints.validationMessage
        : `${this.props.properties.label} is required`;

    let disable = false;
    if (this.props.editmode) {
      disable = this.props.properties.noneditable
        ? this.props.properties.noneditable
        : false;
    }
    disable = this.props.properties.disabled
      ? this.props.properties.disabled
      : disable;

    let value =
      this.props.properties.defaultValue &&
      this.props.properties.defaultValue.value
        ? this.props.properties.defaultValue.value
        : typeof this.props.properties.defaultValue === "object" &&
          this.props.properties.defaultValue
        ? this.props.properties.defaultValue
        : null;

    let labels = null;
    if (value !== null) {
      let result = this.props.properties.options.list.filter(
        object => object.value === value[0] || object.value === value,
      );
      if (result && result[0]) labels = result[0].label;
      else labels = value[0] ? value[0] : value;
    }

    // For View only
    disable = this.props.viewOnly === true ? true : disable;

    let visibility = this.props.properties.visibility
      ? this.componentVisibility
      : true;
    return (
      <div>
        {this.state.listData.length > 0 &&
          (visibility ? (
            <FormItem label={this.props.properties.label} hasFeedback>
              {getFieldDecorator(`${this.props.id}`, {
                rules: [
                  {
                    required: required,
                    message: validationMessage,
                  },
                ],
                initialValue: labels,
              })(
                <Select
                  placeholder={this.props.properties.placeholder}
                  mode={this.props.properties.options.mode}
                  onSelect={value => {
                    this.props.properties.onSelect
                      ? this.props.properties.onSelect(value)
                      : this.selectedValue;
                    this.props.updateCurrentValueOnChange([value], "value");
                  }}
                  disabled={disable}
                  allowClear={true}
                  onFocus={
                    this.props.onFocus &&
                    this.props.onFocus.bind(null, this.props.properties.help)
                  }
                  notFoundContent={getCurrentlocaleText(
                    "general.notfound.placeholder.text",
                  )}
                >
                  {this.state.listData.length > 0 &&
                    this.listData(this.state.listData)}
                </Select>,
              )}
            </FormItem>
          ) : (
            <span></span>
          ))}
      </div>
    );
  }
}

DropDown.propTypes = {
  referenceForm: propTypes.any,
  id: propTypes.string,
  properties: propTypes.shape({
    label: propTypes.string.isRequired,
    defaultValue: propTypes.object,
    onSelect: propTypes.func,
    placeholder: propTypes.string,
    disabled: propTypes.bool,
    constraints: propTypes.shape({
      validationMessage: propTypes.string,
      required: propTypes.bool,
    }),
    options: propTypes.shape({
      mode: propTypes.string,
    }),
  }),
};

/*

sample schema

{
  "type": "DropDown",
  "visible": true,
  "props": {
    "label": "Select Version",
    "defaultValue": [
      "3.0.15"
    ],
    "constraints": {
      "required": true,
      "validationMessage": "Please select an image version"
    },
    "disabled": false,
    "noneditable" : true,
    "help" : "select any one value",
    "options": {
      "list": [
        {
          "value": "3.0.15-06.08.2018",
          "label": "3.0.15"
        }
      ],
      "mode": ""
    }
  }
}


*/
