import React, { Component } from "react";
import { Row, Col } from "antd";
import { observer, inject } from "mobx-react";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import BreadcrumbComponent from "components/UI-Components/BreadcrumbComponent";
import Icons from "components/UI-Components/Icons";
import AnalyticsSettings from "components/Containers/AnalyticsSettings";
import { getCurrentlocaleText } from "Core/Utils";
import OrgSelector from "components/Orgs/OrgSelector";

@inject("AuthStore", "UiStore")
@observer
class SettingsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      BreadcrumbList: [
        {
          name: getCurrentlocaleText("setting.navigation_menu.text"),
          link: "/settings",
        },
      ],
    };
    this.color = {};
  }

  render() {
    let container = (
      <div>
        <Row gutter={16} type="flex" align="middle">
          <Col lg={{ span: 18 }} xl={{ span: 20 }}>
            <h2 className="page-title">
              <Icons type="md" name="MdEventNote" className="valign-middle" />
              <Icons type="md" name="MdPerson" className="event-header-icon" />
              {"  "}
              {getCurrentlocaleText("setting.navigation_menu.text")}
            </h2>
            <BreadcrumbComponent
              {...this.props}
              BreadcrumbList={this.state.BreadcrumbList}
            />
          </Col>
          <Col lg={{ span: 6 }} xl={{ span: 4 }} className="flex flex-h-right">
            <OrgSelector {...this.props} />
          </Col>
        </Row>
        <div
          style={{
            padding: 24,
            marginTop: 2,
            background: "#fff",
            minHeight: 360,
          }}
        >
          <Row gutter={2} type="flex" align="top">
            <Col span={24} className="card-grid">
              <AnalyticsSettings />
            </Col>
          </Row>
        </div>
      </div>
    );

    return <LoadingComponent>{container}</LoadingComponent>;
  }
}

export default SettingsPage;
