import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import {
  Row,
  Col,
  Popover,
  Button,
  Input,
  Select,
  Modal,
  Radio,
  DatePicker,
} from "antd";
import {
  getCurrentlocaleText,
  getDefaultTimezone,
  getScanType,
  isoToDateString,
} from "../../Core/Utils";
import Icons from "../UI-Components/Icons";
import TableLayout from "../layout/TableLayout";
import DeviceDiscoveryController from "../../controller/DeviceDiscoveryController";
import LoadingComponent from "../UI-Components/LoadingComponent";
import moment from "moment";

const { Option } = Select;
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;
const { RangePicker } = DatePicker;
const scanType = getScanType();

@inject(
  "AuthStore",
  "UiStore",
  "InodeViewModel",
  "OrgViewModel",
  "DeviceDiscoveryViewModel",
  "TimezoneViewModel",
  "ClusterViewModel",
)
@observer
export default class DeviceDiscoveryReport extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = this.props.InodeViewModel;
    this.orgModel = this.props.OrgViewModel;
    this.deviceDisModel = this.props.DeviceDiscoveryViewModel;
    this.clusterModel = this.props.ClusterViewModel;
    this.timezoneModel = this.props.TimezoneViewModel;
    this.timezone =
      this.timezoneModel.userTimezone ||
      this.timezoneModel.orgTimezone ||
      getDefaultTimezone().value;

    this.orgId = this.props.match.params.orgId;
    this.nodeId = this.props.match.params.nodeId;
    this.clusterId = this.props.match.params.clusterId;
    this.scanId = this.props.match.params.scanId;
    this.requestFrom = this.props.match.params.clusterId ? "cluster" : "node";
    this.refreshData = "";

    this.state = {
      sorterInfo: { sorter: {}, sort: {} },
      isModalOpen: false,
      isReportLoading: false,
      reportsColumns: [],
      reportsData: {},
      modalReport: {},
      isCompareModalOpen: false,
      isCompareReportLoading: false,
      compareReportsData: {},
      selScanType: "",
      selectedRowKeys: [],
      selectedRows: [],
      scansList: [],
      selScanId: this.scanId,
      selectedTime: "today",
      startDate: "",
      endDate: "",
    };
  }

  componentDidMount() {
    this.deviceDisModel.resetReports();
    this.deviceDisModel.setReportsLoading(true);
    this.getScans();
    this.setTimeRange(); // Set the time and get the reports
    clearInterval(this.refreshData);
    this.refreshData = setInterval(() => {
      this.getReports();
    }, 90 * 1000);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params &&
      prevProps.match.params.scanId &&
      this.props.match.params &&
      this.props.match.params.scanId &&
      prevProps.match.params.scanId !== this.props.match.params.scanId
    ) {
      this.onSearchChange(this.props.match.params.scanId);
    }
  }

  componentWillUnmount() {
    clearInterval(this.refreshData);
  }

  getReports = (params = {}, foreceCall = false) => {
    params = {
      ...params,
      scan_id: this.state.selScanId,
      profile: this.state.selScanType,
      start_date: this.state.startDate,
      end_date: this.state.endDate,
    };

    DeviceDiscoveryController.getReports(
      this.clusterId ? this.clusterId : this.nodeId,
      this.requestFrom,
      params,
      foreceCall,
    );
  };

  lazyLoad = () => {
    if (this.deviceDisModel.reportsPageable.next) {
      this.deviceDisModel.setReportsLoading(true);
      let params = {
        page: this.deviceDisModel.reportsPageable.page + 1,
      };
      this.getReports(params);
    }
  };

  getScans = (params = {}, foreceCall = false) => {
    params = {
      ...params,
      size: 1,
    };
    DeviceDiscoveryController.getScans(
      this.clusterId ? this.clusterId : this.nodeId,
      this.requestFrom,
      params,
      foreceCall,
    ).then(resp => {
      if (resp && resp.results && resp.results.length > 0) {
        let totalCount = resp.total_count / 25;
        if (totalCount > 0) {
          for (let page = 0; page < totalCount; page++) {
            let p = {
              size: 25,
              page: page,
            };
            DeviceDiscoveryController.getScans(
              this.clusterId ? this.clusterId : this.nodeId,
              this.requestFrom,
              p,
              foreceCall,
            ).then(resp1 => {
              if (resp1 && resp1.results && resp1.results.length > 0) {
                let filterList = resp1.results.map(obj => {
                  return {
                    label: obj.name,
                    value: obj.id,
                  };
                });
                this.setState({
                  scansList: [...this.state.scansList, ...filterList],
                });
              }
            });
          }
        }
      }
    });
  };

  onScanTypeChange = e => {
    this.setState(
      {
        selScanType: e,
      },
      () => {
        this.getReports({}, true);
      },
    );
  };

  onSearchChange = e => {
    this.setState(
      {
        selScanId: e,
      },
      () => {
        this.getReports({}, true);
      },
    );
  };

  showModal = record => {
    this.setState(
      {
        isModalOpen: true,
        isReportLoading: true,
        modalReport: record,
      },
      () => {
        DeviceDiscoveryController.getReportById(
          this.requestFrom,
          this.clusterId ? this.clusterId : this.nodeId,
          record.id,
        )
          .then(resp => {
            let reportsColumns = [
              {
                title: getCurrentlocaleText("general.component.ip.text"),
                dataIndex: "ip",
                key: "ip",
              },
              {
                title: getCurrentlocaleText("mac-address.text"),
                dataIndex: "mac",
                render: (text, record, index) => {
                  return <div>{text ? text : ""}</div>;
                },
              },
              {
                title: getCurrentlocaleText("service_listener.port.text"),
                dataIndex: "port",
                render: (list, record, index) => {
                  let portList =
                    list &&
                    Array.isArray(list) &&
                    list.map((obj, i) => {
                      let end = i < list.length - 1 ? ", " : "";
                      return (
                        <span key={i}>
                          {(obj && obj.PortID ? obj.PortID : "-") +
                            "(" +
                            (obj && obj.Protocol ? obj.Protocol : "-") +
                            ")" +
                            end}
                        </span>
                      );
                    });

                  if (list.length == 0) portList = "-";
                  return <div>{portList}</div>;
                },
              },
            ];

            let reportsData = {
              host: [],
            };

            if (resp && resp.RunStats && resp.RunStats.Finished) {
              reportsData.time = resp.RunStats.Finished.Time
                ? moment
                    .unix(resp.RunStats.Finished.Time)
                    .tz(this.timezone)
                    .format("lll")
                : "-";
              reportsData.status = resp.RunStats.Finished.Exit
                ? resp.RunStats.Finished.Exit.toLowerCase()
                : "-";
              reportsData.elapsed = resp.RunStats.Finished.Elapsed
                ? resp.RunStats.Finished.Elapsed
                : "-";
            }

            if (resp && resp.Host && resp.Host.length) {
              for (let i = 0; i < resp.Host.length; i++) {
                let host = resp.Host[i];
                if (host.HostAddress && host.HostAddress.length) {
                  let ip = host.HostAddress.find(
                    obj => obj.AddressType == "ipv4",
                  );
                  let mac = host.HostAddress.find(
                    obj => obj.AddressType == "mac",
                  );

                  let reportOj = {
                    ip: ip && ip.Address ? ip.Address : "-",
                    mac: mac && mac.Address ? mac.Address : "-",
                    port: host && host.Port ? host.Port : [],
                  };

                  if (record.profile.toLowerCase() == "bacnet") {
                    reportOj.script = host && host.Port ? host.Port : "-";
                  }

                  reportsData.host.push(reportOj);
                }
              }

              if (
                record &&
                record.profile &&
                record.profile.toLowerCase() == "bacnet"
              ) {
                reportsColumns.push({
                  title: getCurrentlocaleText(
                    "general.component.script.name.text",
                  ),
                  dataIndex: "script",
                  key: "script",
                  render: (list, record, index) => {
                    let scriptList = [];
                    list &&
                      Array.isArray(list) &&
                      list.map((portObj, i) => {
                        portObj &&
                          portObj.Script &&
                          Array.isArray(portObj.Script) &&
                          portObj.Script.map((scriptObj, j) => {
                            scriptList.push(
                              <div key={"test" + i + j}>
                                {scriptObj.Output}
                              </div>,
                            );
                            return;
                          });
                      });
                    if (scriptList.length == 0) scriptList = "-";
                    return <div>{scriptList}</div>;
                  },
                });
              }
            }
            this.setState({
              reportsData,
              reportsColumns,
              isReportLoading: false,
            });
          })
          .catch(function(error) {
            this.setState({
              isReportLoading: false,
            });
          });
      },
    );
  };

  handleOk = () => {
    this.setState({ isModalOpen: false });
  };

  handleCancel = () => {
    this.setState({ isModalOpen: false });
  };

  showCompareModal = () => {
    let { selectedRows } = this.state;
    this.setState(
      {
        isCompareModalOpen: true,
        isCompareReportLoading: true,
      },
      () => {
        let postObj = {
          report_a:
            selectedRows && selectedRows[0] && selectedRows[0].id
              ? selectedRows[0].id
              : "",
          report_b:
            selectedRows && selectedRows[1] && selectedRows[1].id
              ? selectedRows[1].id
              : "",
        };
        let nwId = selectedRows[0].network.id;
        DeviceDiscoveryController.diffReport(nwId, postObj)
          .then(resp => {
            this.setState({
              isCompareReportLoading: false,
              compareReportsData: resp.result,
            });
          })
          .catch(function(error) {
            this.setState({
              isCompareReportLoading: false,
            });
          });
      },
    );
  };

  exportDiffCsvReport = () => {
    let { selectedRows } = this.state;
    let postObj = {
      report_a:
        selectedRows && selectedRows[0] && selectedRows[0].id
          ? selectedRows[0].id
          : "",
      report_b:
        selectedRows && selectedRows[1] && selectedRows[1].id
          ? selectedRows[1].id
          : "",
    };
    let nwId = selectedRows[0].network.id;

    let filename =
      this.generateFileName(selectedRows[0]) +
      "_diff_" +
      this.generateFileName(selectedRows[1]) +
      ".csv";
    let queryParam = {
      download: true,
    };
    DeviceDiscoveryController.downloadDiffReport(
      nwId,
      postObj,
      filename,
      queryParam,
    );
  };

  handleCompareOk = () => {
    this.setState({ isCompareModalOpen: false });
  };

  handleCompareCancel = () => {
    this.setState({ isCompareModalOpen: false });
  };

  handleTableChange = (record, selected, selectedRows) => {
    this.setState({ selectedRows });
  };

  onSelectAllChange = (selected, selectedRows, changeRows) => {
    this.setState({ selectedRows });
  };

  exportCsvReport = (id, exportFromModal) => {
    let { selectedRows, modalReport } = this.state;
    let postId = id
      ? id
      : selectedRows && selectedRows[0] && selectedRows[0].id;
    let selReport = exportFromModal ? modalReport : selectedRows[0];
    let filename = this.generateFileName(selReport) + ".csv";

    DeviceDiscoveryController.downloadReport(
      this.requestFrom,
      this.clusterId ? this.clusterId : this.nodeId,
      postId,
      filename,
    ).then(resp => {
      this.setState({ isModalOpen: false });
    });
  };

  generateFileName(selReport) {
    let filename = getCurrentlocaleText("report");
    let scanName =
      selReport && selReport.scan && selReport.scan.name
        ? selReport.scan.name
        : "";
    if (scanName) {
      filename += "_" + scanName;
    }
    let nwName =
      selReport && selReport.network && selReport.network.name
        ? selReport.network.name
        : "";
    if (nwName) {
      filename += "_" + nwName;
    }
    let createdAt =
      selReport && selReport.created_at ? selReport.created_at : "";
    if (createdAt) {
      filename += "_" + createdAt;
    }
    return filename;
  }

  getCompareDisabled() {
    let { selectedRows } = this.state;
    let scanType1 =
      selectedRows && selectedRows[0] && selectedRows[0].profile
        ? selectedRows[0].profile
        : "";
    let scanType2 =
      selectedRows && selectedRows[1] && selectedRows[1].profile
        ? selectedRows[1].profile
        : "";
    let nwId1 =
      selectedRows &&
      selectedRows[0] &&
      selectedRows[0].network &&
      selectedRows[0].network.id
        ? selectedRows[0].network.id
        : "";
    let nwId2 =
      selectedRows &&
      selectedRows[1] &&
      selectedRows[1].network &&
      selectedRows[1].network.id
        ? selectedRows[1].network.id
        : "";

    if (selectedRows.length != 2 || scanType1 != scanType2 || nwId1 != nwId2) {
      return true;
    } else {
      return false;
    }
  }

  handleTimeRange = e => {
    this.setState(
      {
        selectedTime: e.target.value,
      },
      () => {
        this.setTimeRange();
      },
    );
  };

  setTimeRange = fromRangePicker => {
    let start_date, end_date;
    switch (this.state.selectedTime) {
      case "today":
        start_date = moment().startOf("day");
        end_date = moment();
        break;
      case "yesterday":
        start_date = moment()
          .subtract(1, "day")
          .startOf("day");
        end_date = moment()
          .subtract(1, "day")
          .endOf("day");
        break;
      case "7d":
        start_date = moment()
          .subtract(7, "day")
          .startOf("day");
        end_date = moment();
        break;
      case "30d":
        start_date = moment()
          .subtract(30, "day")
          .startOf("day");
        end_date = moment();
        break;
      case "custom":
        start_date = moment(this.state.startDate);
        end_date = moment(this.state.endDate);
        break;
    }

    this.setState(
      {
        startDate: start_date.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        endDate: end_date.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      },
      () => {
        if (
          this.state.selectedTime == "today" ||
          this.state.selectedTime == "yesterday" ||
          this.state.selectedTime == "7d" ||
          this.state.selectedTime == "30d" ||
          (this.state.selectedTime == "custom" && fromRangePicker == "custom")
        ) {
          this.getReports({}, true);
        }
      },
    );
  };

  render() {
    let {
      sorterInfo,
      isModalOpen,
      isReportLoading,
      reportsColumns,
      reportsData,
      modalReport,
      isCompareModalOpen,
      isCompareReportLoading,
      selectedRowKeys,
      selectedRows,
      compareReportsData,
      scansList,
      selScanId,
      selectedTime,
      startDate,
      endDate,
    } = this.state;
    let reportsList = this.deviceDisModel.reportsList;
    let reportATime =
      compareReportsData &&
      compareReportsData.scan_diff &&
      compareReportsData.scan_diff.report_a_time
        ? moment(compareReportsData.scan_diff.report_a_time).format(
            "DD MMM YYYY hh:mm A",
          )
        : "";
    let reportBTime =
      compareReportsData &&
      compareReportsData.scan_diff &&
      compareReportsData.scan_diff.report_b_time
        ? moment(compareReportsData.scan_diff.report_b_time).format(
            "DD MMM YYYY hh:mm A",
          )
        : "";

    const reportsCompareColumns = [
      {
        title: "IP",
        dataIndex: "host",
        key: "ipaddress",
        render: (obj, record, index) => {
          return obj && obj.ipaddress ? obj.ipaddress : "-";
        },
      },
      {
        title: "MAC Address",
        dataIndex: "host",
        key: "mac",
        render: (obj, record, index) => {
          return obj && obj.mac ? obj.mac : "-";
        },
      },
      {
        title: reportATime,
        dataIndex: "report_a",
        key: "report_a",
        render: (obj, record, index) => {
          return (
            <div>
              {obj && obj.state && obj.state.toLowerCase() == "up" ? (
                <Icons
                  type="ai"
                  name="AiOutlineCheck"
                  className="ml-10"
                  style={{ color: "#00804F" }}
                />
              ) : (
                <Icons
                  type="ai"
                  name="AiOutlineClose"
                  className="ml-10"
                  style={{ color: "#D82C0D" }}
                />
              )}
            </div>
          );
        },
      },
      {
        title: reportBTime,
        dataIndex: "report_b",
        key: "report_b",
        render: (obj, record, index) => {
          return (
            <div>
              {obj && obj.state && obj.state.toLowerCase() == "up" ? (
                <Icons
                  type="ai"
                  name="AiOutlineCheck"
                  className="ml-10"
                  style={{ color: "#00804F" }}
                />
              ) : (
                <Icons
                  type="ai"
                  name="AiOutlineClose"
                  className="ml-10"
                  style={{ color: "#D82C0D" }}
                />
              )}
            </div>
          );
        },
      },
    ];

    const columns = [
      {
        title: getCurrentlocaleText("general.component.scan.name.text"),
        dataIndex: "scan",
        render: (obj, record, index) => {
          return <div>{obj && obj.name ? obj.name : "-"}</div>;
        },
      },
      {
        title: getCurrentlocaleText("general.component.networks.text"),
        dataIndex: "network",
        render: (obj, record, index) => {
          return <div>{obj && obj.name ? obj.name : "-"}</div>;
        },
      },
      {
        title: getCurrentlocaleText("general.component.scan.type.text"),
        dataIndex: "profile",
        render: (text, record, index) => {
          let obj = scanType.find(obj => obj.value == text);
          return <div>{obj && obj.label ? obj.label : "-"}</div>;
        },
      },
      {
        title: getCurrentlocaleText("general.component.date.completed.text"),
        key: "created_at",
        dataIndex: "created_at",
        render: (text, record, index) => {
          return <div>{text ? isoToDateString(text) : "-"}</div>;
        },
      },
      {
        title: getCurrentlocaleText("device.discovery.time.finish.text"),
        key: "timeTakenToFinishScan",
        dataIndex: "stats",
        render: (obj, record, index) => {
          return (
            <div>
              {obj && obj.scan_duration
                ? moment.duration(obj.scan_duration, "seconds").humanize()
                : "-"}
            </div>
          );
        },
      },
      {
        title: getCurrentlocaleText("device.discovery.devices.found.text"),
        key: "devicesFound",
        dataIndex: "stats",
        render: (obj, record, index) => {
          return <div>{obj && obj.device_count ? obj.device_count : "-"}</div>;
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "action",
        render: (text, record, index) => {
          return (
            <Button
              type="text"
              onClick={() => this.showModal(record)}
              className="p-5"
            >
              <Icons type="ai" name="AiOutlineRight" style={{ fontSize: 18 }} />
            </Button>
          );
        },
      },
    ];

    const rowSelection = {
      selectedRowKeys,
      onChange: selectedRowKeys => {
        this.setState({ selectedRowKeys });
      },
    };

    const disabledDate = current => {
      // Disable future dates
      if (current && current > new Date()) {
        return true;
      }
      // Disable dates before a particular date
      const specificDate = new Date("2023-11-15");
      return current && current < specificDate;
    };

    startDate = moment(startDate).format("ll");
    endDate = moment(endDate).format("ll");
    const isValidStartDate = moment(startDate).isValid();
    const isValidEndDate = moment(endDate).isValid();

    return (
      <div>
        <section className="mt-15">
          <Row justify="start">
            <Col span={12} className="search-wrapper">
              <Col span={13}>
                <Select
                  size="large"
                  className="border-radius-8"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Search By Scan Name"
                  value={selScanId}
                  onChange={e => this.onSearchChange(e)}
                >
                  <Option value="" key={101}>
                    {getCurrentlocaleText("general.component.all.scans.text")}
                  </Option>
                  {scansList.map((obj, i) => {
                    return (
                      <Option value={obj.value} key={i}>
                        {obj.label}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
              <Col span={7}>
                <Select
                  size="large"
                  className="border-radius-8 search-btn"
                  icon={<Icons type="ai" name="AiOutlineFilter" />}
                  defaultValue=""
                  value={this.state.selScanType}
                  span={4}
                  onChange={this.onScanTypeChange}
                >
                  <Option value="" key={101}>
                    {getCurrentlocaleText(
                      "general.component.all.scans.type.text",
                    )}
                  </Option>
                  {scanType.map((obj, i) => {
                    return (
                      <Option value={obj.value} key={i}>
                        {obj.label}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
            </Col>
            <Col span={12} className="flex-reverse">
              <RadioGroup
                onChange={this.handleTimeRange}
                value={this.state.selectedTime}
              >
                <RadioButton value="today">Today</RadioButton>
                <RadioButton value="yesterday">Yesterday</RadioButton>
                <RadioButton value="7d">7D</RadioButton>
                <RadioButton value="30d">30D</RadioButton>
                <Popover
                  placement="bottomRight"
                  trigger="click"
                  title=""
                  content={
                    <>
                      <RangePicker
                        disabledDate={disabledDate}
                        onChange={e => {
                          if (e && e[0] && e[1]) {
                            this.setState(
                              {
                                startDate: moment(e[0]),
                                endDate: moment(e[1]),
                              },
                              () => {
                                this.setTimeRange("custom");
                              },
                            );
                          }
                        }}
                      />
                    </>
                  }
                >
                  <RadioButton value="custom">
                    <Icons
                      type="fa"
                      name="FaRegCalendarCheck"
                      className="valign-middle mr-5"
                    />
                    {selectedTime == "custom" &&
                    isValidStartDate &&
                    isValidEndDate ? (
                      <span className="fs-075">
                        ({startDate} ~ {endDate})
                      </span>
                    ) : (
                      "Custom Date"
                    )}
                    <Icons
                      type="ai"
                      name="AiOutlineDown"
                      className="valign-middle ml-5"
                    />
                  </RadioButton>
                </Popover>
              </RadioGroup>
            </Col>
          </Row>

          <Row justify="end">
            <Col span={24} className="report-compare-wrapper">
              <Popover
                content={"Compare two same type of scans"}
                placement="bottom"
              >
                <Button
                  type="link"
                  icon={<Icons type="md" name="MdCompareArrows" />}
                  onClick={this.showCompareModal}
                  disabled={this.getCompareDisabled()}
                >
                  {getCurrentlocaleText("general.component.compare.text")}
                </Button>
              </Popover>
              <Popover content={"Export scan reports"} placement="bottom">
                <Button
                  type="link"
                  icon={<Icons type="ai" name="AiOutlineExport" />}
                  onClick={() =>
                    this.exportCsvReport(
                      selectedRows && selectedRows[0] && selectedRows[0].id,
                      false,
                    )
                  }
                  disabled={selectedRows.length != 1}
                >
                  {getCurrentlocaleText("export.csv.text")}
                </Button>
              </Popover>
            </Col>
          </Row>

          <Row>
            <Col span={24} className="flex-column">
              <LoadingComponent loading={this.deviceDisModel.reportsLoading}>
                <TableLayout
                  columns={columns}
                  dataSource={[...reportsList]}
                  pagination={false}
                  selectedRows={this.handleTableChange}
                  selectAll={this.onSelectAllChange}
                  hasLazyLoading={true}
                  lazyLoad={this.lazyLoad}
                  className="orgList"
                  scroll={{ y: 200 }}
                  showExpandColumn={false}
                  rowSelection={rowSelection}
                />
              </LoadingComponent>
            </Col>
          </Row>
        </section>

        {isModalOpen && (
          <Modal
            title={getCurrentlocaleText("reports.text")}
            visible={true}
            closable={true}
            maskClosable={false}
            footer={[
              <Button
                key="ok"
                type="primary"
                onClick={() => this.exportCsvReport(modalReport.id, true)}
                disabled={isReportLoading}
                className="border-radius-4"
              >
                {getCurrentlocaleText("export.csv.text")}
              </Button>,
            ]}
            width={800}
            onCancel={this.handleCancel}
          >
            <LoadingComponent loading={isReportLoading}>
              <h3 className="mt-5 mb-15">
                {reportsData.elapsed && (
                  <span>
                    {getCurrentlocaleText("general.component.time.run.text")}{" "}
                    {reportsData.time}
                  </span>
                )}
                {reportsData.status == "success" ? (
                  <Icons
                    type="ai"
                    name="AiOutlineCheck"
                    className="ml-10"
                    style={{ color: "#00804F" }}
                  />
                ) : (
                  <Icons
                    type="ai"
                    name="AiOutlineClose"
                    className="ml-10"
                    style={{ color: "#D82C0D" }}
                  />
                )}
                {reportsData.elapsed && (
                  <div className="fs-075">
                    {getCurrentlocaleText("techdump.elapsed_time.text")}{" "}
                    {reportsData.elapsed}s{" "}
                  </div>
                )}
              </h3>
              <Row>
                <Col span={24}>
                  {reportsData &&
                  reportsData.host &&
                  Array.isArray(reportsData.host) &&
                  reportsData.host.length > 0 ? (
                    <TableLayout
                      columns={reportsColumns}
                      dataSource={reportsData.host}
                      pagination={false}
                      hideExpandedRow={true}
                      className="width-full"
                      scroll={{ y: 250 }}
                      showExpandColumn={false}
                      hideRowSelection={true}
                    />
                  ) : (
                    <p>{getCurrentlocaleText("report.host.noContent.text")}</p>
                  )}
                </Col>
              </Row>
            </LoadingComponent>
          </Modal>
        )}

        {isCompareModalOpen && (
          <Modal
            title={
              <div className="text-black">
                {getCurrentlocaleText("compare.reports.text")}

                {selectedRows &&
                selectedRows[0] &&
                selectedRows[0].scan &&
                selectedRows[0].scan.name
                  ? " - " + selectedRows[0].scan.name
                  : ""}
              </div>
            }
            visible={true}
            closable={true}
            maskClosable={false}
            footer={[
              <Button
                key="ok"
                type="primary"
                onClick={this.exportDiffCsvReport}
                className="border-radius-4"
              >
                {getCurrentlocaleText("export.csv.text")}
              </Button>,
            ]}
            width={800}
            onCancel={this.handleCompareCancel}
          >
            <LoadingComponent loading={isCompareReportLoading}>
              <Row>
                <Col span={24}>
                  <TableLayout
                    columns={reportsCompareColumns}
                    dataSource={
                      compareReportsData && compareReportsData.host_diff
                        ? compareReportsData.host_diff
                        : []
                    }
                    pagination={false}
                    hideExpandedRow={true}
                    className="width-full"
                    scroll={{ y: 250 }}
                    showExpandColumn={false}
                    hideRowSelection={true}
                  />
                </Col>
              </Row>
            </LoadingComponent>
          </Modal>
        )}
      </div>
    );
  }
}
