import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import IoTiumSpin from "components/UI-Components/spin";

@inject("UiStore")
@observer
class ModalLoadingComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let loading = this.props.UiStore.modalLoading;

    const iotiumSpin = (
      <IoTiumSpin
        spinning={loading}
        tip="Loading..."
        isModalLoading={true}
        size="medium"
      >
        {this.props.children}
      </IoTiumSpin>
    );

    return iotiumSpin;
  }
}

export default ModalLoadingComponent;
