import React, { Component } from "react";
import { Row, Col } from "antd";
import BreadcrumbComponent from "components/UI-Components/BreadcrumbComponent";
import Icons from "components/UI-Components/Icons";
import { getCurrentlocaleText } from "Core/Utils";
import AlertList from "components/Alerts/AlertList";

class ManageAlerts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      BreadcrumbList: [
        {
          name: getCurrentlocaleText("manage_alerts.text"),
          link: "managealerts",
        },
      ],
    };
  }

  render() {
    return (
      <div>
        <Row gutter={24} type="flex" align="middle">
          <Col span={24}>
            <h2 className="page-title">
              <Icons type="fa" name="FaBell" />
              {"  "}
              {getCurrentlocaleText("manage_alerts.text")}
            </h2>
            <BreadcrumbComponent
              {...this.props}
              BreadcrumbList={this.state.BreadcrumbList}
            />
          </Col>
        </Row>
        <div className="list-contianer">
          <Row gutter={16} type="flex" align="middle">
            <Col span={24}>
              <AlertList />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default ManageAlerts;
