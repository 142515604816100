import React, { Component } from "react";
import { Select, Form } from "antd";
import { observer, inject } from "mobx-react";
import LicenseForms from "components/Forms/LicenseCreationForm";
import ModalForm from "components/Forms/ModalForm";

import SecretController from "controller/SecretController";
import { isEmpty, getCurrentlocaleText } from "Core/Utils";

const FormItem = Form.Item;
const Option = Select.Option;

@inject("AuthStore", "UiStore", "OrgViewModel")
@observer
class LicenseCreation extends Component {
  constructor(props) {
    super(props);
    this.orgModel = this.props.OrgViewModel;
    this.state = {
      loading: false,
      clearmodal: false,
    };
  }

  closeModal = (e = null) => {
    /* clear service form values from store */
    this.props.UiStore.closeFormModal("secret");
    this.props.UiStore.SetStoreData("helpData", "");
    this.props.UiStore.SetStoreData("serviceFormData", {});
    this.setState({ loading: false, clearmodal: true });

    setTimeout(() => {
      /* required to reset state after modal close */
      this.setState({ clearmodal: false });
    }, 500);
  };

  checkAndEncryptToBase64 = () => {
    let val,
      base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/,
      isBase64 = base64regex.test(
        this.props.UiStore.serviceFormData.ssh_container,
      );
    if (!isBase64 && !this.props.UiStore.serviceFormData.base64) {
      /*Plain text and not base64 checked*/
      try {
        /*convert to base64*/
        val = btoa(this.props.UiStore.serviceFormData.ssh_container);
      } catch (e) {
        this.props.UiStore.errorMessage =
          "Error in encoding the key to base64 format.Please check the SSH Public key.";
        //this.props.UiStore.UpdateserviceObj();
        return null;
      }
    } else if (!isBase64 && this.props.UiStore.serviceFormData.base64) {
      /*Plain text and base64 checked*/
      this.props.UiStore.errorMessage =
        "Please provide the SSH public key in base64 encoded format";
      //this.props.UiStore.UpdateserviceObj();
      return null;
    } else if (isBase64 && this.props.UiStore.serviceFormData.base64) {
      /*Already encoded : encoding not required*/
      val = this.props.UiStore.serviceFormData.ssh_container;
    } else if (isBase64 && !this.props.UiStore.serviceFormData.base64) {
      /*Key encoded but didnt mention in UI , it must be encoded*/
      val = btoa(this.props.UiStore.serviceFormData.ssh_container);
    }

    return val;
  };

  getSSHID = values => {
    let filename = this.props.UiStore.serviceFormData.secretCertiName
      ? this.props.UiStore.serviceFormData.secretCertiName
      : `${this.props.UiStore.serviceFormData.name}_${randomAlphaNumericString(
          5,
        )}_SSHContainerFile`;

    /*Secret object creation*/
    let val = this.checkAndEncryptToBase64();
    if (val) {
      let obj = {
        name: this.props.UiStore.serviceFormData.secretCertiName.trim(),
        data: {},
        type: "Opaque",
        create_under: this.orgModel.org.id,
        labels: {
          type: "ssh",
        },
      };
      obj.data["authorized_keys"] = val;
      //Resources.secret.create(obj).then(
      SecretController.create(obj).then(
        responses => {
          this.props.UiStore.successMessage =
            "SSH public key created successfully";
          this.props.UiStore.temporaryLicenseData = {};
          this.closeModal();
          this.props.afterCall ? this.props.afterCall() : "";
        },
        error => {
          this.setState({ loading: false });
        },
      );
    } else {
      this.setState({ loading: false });
    }
  };

  getSecretObj = values => {
    let secret_type =
      this.props.extra && this.props.extra.type
        ? this.props.extra.type
        : "license";
    let secret_name = this.props.UiStore.serviceFormData
      ? this.props.UiStore.serviceFormData.secretCertiName.trim()
      : Object.keys(val)[0];
    let volume =
      this.props.extra.type.toLowerCase() === "dockerconfig"
        ? {
            ".dockerconfigjson": btoa(
              this.props.UiStore.serviceFormData.docker_container,
            ), // LAT-4114
          }
        : this.props.UiStore.serviceFormData.secret_volume[0];

    //new Promise( (resolve,reject) =>{
    let SecretObj = {
      name: secret_name,
      data: {
        ...volume,
      },
      type:
        this.props.extra.type.toLowerCase() === "dockerconfig"
          ? "Dockerconfigjson"
          : "Opaque",
      create_under:
        this.orgModel.org && this.orgModel.org.id
          ? this.orgModel.org.id
          : this.orgModel.inode.organization.id,
      labels: {
        type: secret_type,
      },
    };

    return SecretController.create(SecretObj);
  };

  getSecretUUID = values => {
    /*Convert object to array*/
    this.props.UiStore.serviceFormData.secret_volume = this.props.UiStore
      .serviceFormData.secret_volume
      ? _.castArray(this.props.UiStore.serviceFormData.secret_volume)
      : null;

    if (this.props.actionType === "edit") {
      this.updateLicense(values);
    } else if (
      this.props.extra &&
      this.props.extra.type === "ssh" &&
      !_.isEmpty(this.props.UiStore.serviceFormData.ssh_container)
    ) {
      /*Paste SSH key*/
      this.getSSHID(values);
    } else if (
      this.props.extra &&
      this.props.extra.type === "dockerconfig" &&
      !isEmpty(this.props.UiStore.serviceFormData.docker_container)
    ) {
      try {
        JSON.parse(
          this.props.UiStore.serviceFormData.docker_container.toString(),
        );
        if (
          Object.keys(
            JSON.parse(
              this.props.UiStore.serviceFormData.docker_container.toString(),
            ),
          ).length > 0
        ) {
          this.getSecretObj(values).then(
            responses => {
              let type =
                this.props.extra.type === "dockerconfig"
                  ? "Pull Secret "
                  : this.props.extra.type;
              this.props.UiStore.successMessage = ` ${type} created successfully`;
              this.props.UiStore.temporaryLicenseData = {};
              this.closeModal();
              this.props.afterCall ? this.props.afterCall() : "";
            },
            error => {
              this.setState({ loading: false });
            },
          );
        } else {
          this.props.UiStore.errorMessage = getCurrentlocaleText(
            "empty.json.text",
          );
          this.setState({ loading: false });
        }
      } catch (e) {
        this.props.UiStore.errorMessage = getCurrentlocaleText("invalid.json");
        this.setState({ loading: false });
      }
    } else {
      this.getSecretObj(values).then(
        responses => {
          let type =
            this.props.extra.type === "dockerconfig"
              ? "Pull Secret "
              : this.props.extra.type;
          this.props.UiStore.successMessage = ` ${type} created successfully`;
          this.props.UiStore.temporaryLicenseData = {};
          this.closeModal();
          this.props.afterCall ? this.props.afterCall() : "";
        },
        error => {
          this.setState({ loading: false });
        },
      );
    }
  };

  updateLicense = values => {
    let secretObj = this.props.UiStore.serviceFormData.rowDetails
      .selectedLicenseDetails;
    /* updating secret data with newly updated license
        valid for single license upload only*/
    this.setState({ loading: true });

    if (
      this.props.extra &&
      this.props.extra.type === "dockerconfig" &&
      !isEmpty(this.props.UiStore.serviceFormData.docker_container)
    ) {
      try {
        JSON.parse(
          this.props.UiStore.serviceFormData.docker_container.toString(),
        );
        if (
          Object.keys(
            JSON.parse(
              this.props.UiStore.serviceFormData.docker_container.toString(),
            ),
          ).length > 0
        ) {
          secretObj.name = this.props.UiStore.serviceFormData.secretCertiName;
          let key = Object.keys(secretObj.data)[0];
          secretObj.data[key] = btoa(
            this.props.UiStore.serviceFormData.docker_container,
          );
          SecretController.update(secretObj, {
            id: secretObj.id,
          })
            .then(response => {
              this.props.UiStore.temporaryLicenseData = {};
              this.closeModal();
              this.setState({ loading: false });
            })
            .catch(error => {
              this.setState({ loading: false });
            });
        } else {
          this.props.UiStore.errorMessage = getCurrentlocaleText(
            "empty.json.text",
          );
          this.setState({ loading: false });
        }
      } catch (e) {
        this.props.UiStore.errorMessage = getCurrentlocaleText("invalid.json");
        this.setState({ loading: false });
      }
    } else if (
      this.props.UiStore.serviceFormData.ssh_container &&
      this.props.UiStore.serviceFormData.ssh_container.length > 0
    ) {
      /*Paste a new ssh*/
      let encryptedVal = this.checkAndEncryptToBase64();
      encryptedVal
        ? ((secretObj.data[_.keys(secretObj.data)[0]] = encryptedVal),
          (secretObj.name = this.props.UiStore.serviceFormData.secretCertiName) /*LAT-3198*/,
          /*this.props.ModelStore
            .Update("secret", secretObj, { id: secretObj.id })*/
          SecretController.update(secretObj, {
            id: secretObj.id,
          })
            .then(response => {
              /*let params = {
              org_id: this.props.ModelStore.current.org.id,
              own: true,
            };
            this.props.updateSecret(params, false, false);*/
              this.props.UiStore.temporaryLicenseData = {};
              this.closeModal();
              this.setState({ loading: false });
            })
            .catch(error => {
              this.setState({ loading: false });
            }))
        : this.setState({ loading: false });
    } else {
      /*upload a new ssh*/
      /*LAT-3198*/
      if (secretObj.labels && secretObj.labels.type === "ssh") {
        let isArray = _.isArrayLikeObject(
            this.props.UiStore.serviceFormData["secret_volume"],
          ),
          isNewLicenseUpload = false;
        if (isArray) {
          isNewLicenseUpload = !_.isEmpty(
            this.props.UiStore.serviceFormData["secret_volume"][0],
          );
        } else {
          isNewLicenseUpload = !_.isEmpty(
            this.props.UiStore.serviceFormData["secret_volume"],
          );
        }

        if (isNewLicenseUpload) {
          let previousKey = _.keys(secretObj.data)[0];
          let newKey = _.keys(
            this.props.UiStore.serviceFormData.secret_volume[0],
          );
          secretObj.data[
            previousKey
          ] = this.props.UiStore.serviceFormData.secret_volume[0][newKey];
        }
      } else {
        /* for type = "license" */
        if (!_.isEmpty(this.props.UiStore.serviceFormData.secret_volume[0])) {
          secretObj.data = {
            ...secretObj.data,
            ...this.props.UiStore.serviceFormData.secret_volume[0],
          };
        }
      }

      secretObj.name = this.props.UiStore.serviceFormData.secretCertiName;
      SecretController.update(secretObj, {
        id: secretObj.id,
      })
        .then(response => {
          this.props.UiStore.temporaryLicenseData = {};
          this.closeModal();
          this.setState({ loading: false });
        })
        .catch(error => {
          this.setState({ loading: false });
        });
    }
  };

  onSubmit = values => {
    this.setState({ loading: true });
    this.getSecretUUID(values);
  };

  render() {
    return (
      <div>
        <ModalForm
          onSubmit={this.onSubmit}
          actionForm={LicenseForms}
          resource="secret"
          type={this.props.actionType !== "edit" ? "primary" : ""}
          title={this.props.title}
          okText={this.props.actionType === "edit" ? " Update" : " Create"}
          closeModal={this.closeModal}
          help={true}
          modalWidth="70%"
          maskClosable={false}
          confirmLoading={this.state.loading}
          afterClose={this.state.clearmodal}
          {...this.props}
        />
      </div>
    );
  }
}

export default LicenseCreation;
