import React, { Component } from "react";
import { Row, Col } from "antd";

import { observer, inject } from "mobx-react";

import LoadingComponent from "components/UI-Components/LoadingComponent";
import BreadcrumbComponent from "components/UI-Components/BreadcrumbComponent";
import Icons from "components/UI-Components/Icons";
import DownloadsContainer from "components/DownloadManager/Components/DownloadsContainer";
import DownloadController from "controller/DownloadController";
import { getCurrentlocaleText } from "../../../Core/Utils";
import OrgSelector from "components/Orgs/OrgSelector";

@inject("AuthStore", "UiStore", "DownloadViewModal", "InodeViewModel")
@observer
class EventDownloadManager extends Component {
  constructor(props) {
    super(props);
    this.DownloadViewModal = this.props.DownloadViewModal;
    this.downloadApiByInterval = null;
    this.state = {
      BreadcrumbList: [
        {
          name: getCurrentlocaleText("download.events.button.text"),
          link: `orgs/${this.props.match.params.id}/event/downloads`,
        },
      ],
    };
    this.color = {};
  }

  UNSAFE_componentWillMount() {
    this.getRequestList();
    this.downloadApiByInterval = setInterval(() => {
      let allParams = [];
      let total_page = this.DownloadViewModal.pageable
        ? this.DownloadViewModal.pageable.page
        : 1;
      for (let page = 0; page <= total_page; page++) {
        let params = {
          size: 25,
          page: page,
        };
        allParams.push(params);
      }
      // Quering total lsit of pages in single get with setting UI loading false
      DownloadController.QueryAllDownloads(
        allParams,
        false,
        "event_downloads",
      ).then(updated_nodes => {});
    }, 30000);
  }
  componentWillUnmount() {
    clearInterval(this.downloadApiByInterval);
  }
  getRequestList = (loading = true, forcecall = true) => {
    // get download request list
    let params = {};
    params.size = 25;
    DownloadController.getRequestList(
      "event_downloads",
      {},
      params,
      loading,
      forcecall,
      true,
    )
      .then(results => {})
      .catch(error => {});
  };
  lazyLoad = (page, loading = true, forcecall = true) => {
    // get download request list
    let params = {};
    params.size = 25;
    params.page = page;
    params;
    DownloadController.getRequestList(
      "event_downloads",
      {},
      params,
      loading,
      forcecall,
      true,
    )
      .then(results => {})
      .catch(error => {});
  };

  /**
   *  Send download data request
   */
  downLoadFile = id => {
    let map = {};
    map.id = id;
    DownloadController.downloadFile(
      "download_event_file",
      map,
      {},
      true,
    ).then(result => {});
  };

  /**
   *  send Download request
   */
  sendDownloadRequest = (params = {}) => {
    DownloadController.sendDownloadRequest(
      "event_downloads",
      params,
      {},
      true,
      false,
    )
      .then(result => {
        this.getRequestList();
      })
      .catch(error => {});
  };

  render() {
    let container = (
      <div>
        <Row gutter={16} type="flex" align="middle">
          <Col lg={{ span: 18 }} xl={{ span: 20 }}>
            <h2 className="page-title">
              <Icons type="md" name="MdEvent" className="valign-middle" />
              <Icons type="fa" name="FaCog" className="event-header-icon" />
              {"  "}
              {getCurrentlocaleText("download.events.button.text")}
            </h2>
            <BreadcrumbComponent
              {...this.props}
              BreadcrumbList={this.state.BreadcrumbList}
            />
          </Col>
          <Col lg={{ span: 6 }} xl={{ span: 4 }} className="flex flex-h-right">
            <OrgSelector {...this.props} />
          </Col>
        </Row>
        <div>
          <Row gutter={2} type="flex" align="top">
            <Col span={24} className="card-grid">
              <DownloadsContainer
                type={"event_downloads"}
                downLoadFile={this.downLoadFile}
                sendDownloadRequest={this.sendDownloadRequest}
                getDownloadRequestLists={this.getRequestList}
                {...this.props}
                lazyLoad={this.lazyLoad}
              />
            </Col>
          </Row>
        </div>
      </div>
    );

    return (
      <LoadingComponent loading={this.DownloadViewModal.loading}>
        {container}
      </LoadingComponent>
    );
  }
}

export default EventDownloadManager;
