import React, { Component } from "react";
import { Input } from "antd";
import { isChrome } from "Core/Utils";

class InputBox extends Component {
  constructor(props) {
    super(props);
    this.state = { isNotChrome: false };
  }
  componentDidMount() {
    var chrome = isChrome();
    if (!chrome) {
      this.setState({ isNotChrome: true });
    }
  }
  handleChange = event => {
    this.props.onChange(event.target.value);
  };
  render() {
    return (
      <Input
        {...this.props}
        autoComplete={this.state.isNotChrome ? "no" : "new-password"}
      />
    );
  }
}

export default InputBox;
