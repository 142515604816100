import React, { Component } from "react";
import { Card, Select, Row, Col } from "antd";
import { getInterval, getCurrentlocaleText, humanizeMetric } from "Core/Utils";
import { observer, inject } from "mobx-react";
import UtilizationChart from "components/Stats/Charts/UtilizationChart";

const Option = Select.Option;

@inject("AuthStore", "NodeStatsViewModel", "InodeViewModel")
@observer
class WaterMarkChart extends Component {
  constructor(props) {
    super(props);
    // GLOBAL_SETTINGS is configured form node js
    this.GLOBAL_SETTINGS = GLOBAL_SETTINGS;
    this.textColor =
      this.GLOBAL_SETTINGS && this.GLOBAL_SETTINGS.text_color
        ? this.GLOBAL_SETTINGS.text_color
        : "#365c6f";
    this.inodeModel = this.props.InodeViewModel;
    this.NodeStatsViewModel = this.props.NodeStatsViewModel;
    this.state = {
      loading: true,
    };
  }

  // Draw the graph for total CPU usage.
  drawCpuTotalUsage() {
    let stats = this.NodeStatsViewModel.getStatsAndInterfaces.stats
      ? this.NodeStatsViewModel.getStatsAndInterfaces.stats
      : [];
    let memory_high =
      this.NodeStatsViewModel.getCPUoverViewInfo &&
      this.NodeStatsViewModel.getCPUoverViewInfo.cpu_max
        ? this.NodeStatsViewModel.getCPUoverViewInfo.cpu_max
        : 0;
    let memory_avg =
      this.NodeStatsViewModel.getCPUoverViewInfo &&
      this.NodeStatsViewModel.getCPUoverViewInfo.cpu_avg
        ? this.NodeStatsViewModel.getCPUoverViewInfo.cpu_avg
        : 0;
    var data = [];
    for (var i = 1; i < stats.length; i++) {
      var cur = stats[i];
      var prev = stats[i - 1];
      var elements = [];
      if (cur.timestamp) {
        var intervalNs = getInterval(cur.timestamp, prev.timestamp);
        if (cur.cpu && prev.cpu) {
          let numberof_cores =
            cur && cur.cpu && cur.cpu.usage && cur.cpu.usage.per_cpu_usage
              ? cur.cpu.usage.per_cpu_usage.length
              : 2;
          var dataSet =
            (cur.cpu &&
              cur.cpu.usage &&
              cur.cpu.usage.total -
                (prev && prev.cpu && prev.cpu.usage && prev.cpu.usage.total)) /
            intervalNs;
          elements.push(new Date(cur.timestamp));
          if (dataSet > 0) {
            elements.push(
              Math.round(dataSet) >= 0
                ? (dataSet / numberof_cores) * 100
                : null,
            );
            elements.push(parseFloat(memory_high));
            elements.push(parseFloat(memory_avg));
          } else {
            elements.push(0);
            elements.push(parseFloat(memory_high));
            elements.push(parseFloat(memory_avg));
          }
        } else {
          elements.push(new Date(cur.timestamp));
          elements.push(null);
          elements.push(null);
          elements.push(null);
        }
      }
      data.push(elements);
    }
    data[0] = ["Time", "Used", "High", "Average"];
    //  LAT-10761 To avoid unwanted error in rendering
    let indx = data.findIndex(item => {
      return item && item[0] && (item[1] || item[2]);
    });
    return indx !== -1 ? data : [];
  }

  // Draw the graph for total CPU usage.
  drawMemoryTotalUsage() {
    let stats = this.NodeStatsViewModel.getStatsAndInterfaces.stats
      ? this.NodeStatsViewModel.getStatsAndInterfaces.stats
      : [];
    let memory_high =
      this.NodeStatsViewModel.getMemoryoverViewInfo &&
      this.NodeStatsViewModel.getMemoryoverViewInfo.memory_max
        ? this.NodeStatsViewModel.getMemoryoverViewInfo.memory_max
        : 0;
    let total_memory = this.NodeStatsViewModel.getTotalMemoryValue
      ? this.NodeStatsViewModel.getTotalMemoryValue
      : 0;

    let memory_avg =
      this.NodeStatsViewModel.getMemoryoverViewInfo &&
      this.NodeStatsViewModel.getMemoryoverViewInfo.memory_avg
        ? this.NodeStatsViewModel.getMemoryoverViewInfo.memory_avg
        : 0;
    var data = [];
    var oneMegabyte = 1000 * 1000;
    for (var i = 1; i < stats.length; i++) {
      var cur = stats[i];
      var prev = stats[i - 1];
      var elements = [];
      if (cur.timestamp) {
        if (cur.memory) {
          if (
            Math.round(
              cur.memory &&
                cur.memory.working_set &&
                cur.memory.working_set / oneMegabyte,
            ) > 0
          ) {
            elements.push(new Date(cur.timestamp));
            elements.push(
              Math.round(
                cur.memory &&
                  cur.memory.working_set &&
                  cur.memory.working_set / oneMegabyte,
              ) <= 0
                ? null
                : {
                    v: Math.round(
                      cur.memory &&
                        cur.memory.working_set &&
                        cur.memory.working_set / oneMegabyte,
                    ),
                    f:
                      Math.round(
                        cur.memory &&
                          cur.memory.working_set &&
                          cur.memory.working_set / oneMegabyte,
                      ) + " MB",
                  },
            );
            elements.push({
              v: Math.round(memory_high / oneMegabyte),
              f: Math.round(memory_high / oneMegabyte) + " MB",
            });
            elements.push({
              v: Math.round(memory_avg / oneMegabyte),
              f: Math.round(memory_avg / oneMegabyte) + " MB",
            });
            elements.push({
              v: Math.round(total_memory / oneMegabyte),
              f: Math.round(total_memory / oneMegabyte) + " MB",
            });
          } else {
            elements.push(new Date(cur.timestamp));
            elements.push(null);
            elements.push({
              v: Math.round(memory_high / oneMegabyte),
              f: Math.round(memory_high / oneMegabyte) + " MB",
            });
            elements.push({
              v: Math.round(memory_avg / oneMegabyte),
              f: Math.round(memory_avg / oneMegabyte) + " MB",
            });
            elements.push({
              v: Math.round(total_memory / oneMegabyte),
              f: Math.round(total_memory / oneMegabyte) + " MB",
            });
          }
        } else {
          elements.push(new Date(cur.timestamp));
          elements.push(null);
          elements.push(null);
          elements.push(null);
          elements.push(null);
        }
      }
      data.push(elements);
    }
    data[0] = ["Time", "Used", "High", "Average", "Total"];

    //  LAT-10761 To avoid unwanted error in rendering
    let indx = data.findIndex(item => {
      return item && item[0] && (item[1] || item[2]);
    });
    return indx !== -1 ? data : [];
  }

  // Draw the graph for FS usage.
  drawFileSystemUsage() {
    let stats = this.NodeStatsViewModel.getStatsAndInterfaces.stats
      ? this.NodeStatsViewModel.getStatsAndInterfaces.stats
      : [];
    var data = [];
    let byteRxValues = 0;
    let byteTxValues = 0;
    let fs_avg =
      this.NodeStatsViewModel.getFileSystemOverViewInfo &&
      this.NodeStatsViewModel.getFileSystemOverViewInfo.fs_avg
        ? this.NodeStatsViewModel.getFileSystemOverViewInfo.fs_avg
        : 0;

    let fs_max =
      this.NodeStatsViewModel.getFileSystemOverViewInfo &&
      this.NodeStatsViewModel.getFileSystemOverViewInfo.fs_max
        ? this.NodeStatsViewModel.getFileSystemOverViewInfo.fs_max
        : 0;

    for (var i = 1; i < stats.length; i++) {
      var cur = stats[i];
      let filesystems = cur && cur.filesystem ? cur.filesystem : [];
      var elements = [];
      if (cur.timestamp) {
        if (filesystems.length > 0) {
          for (var j = 0; j < filesystems.length; j++) {
            var fs = filesystems[j];
            // plot only root container and ignore others
            if (fs.device.includes("ROOT")) {
              elements.push(new Date(cur.timestamp));
              if (fs.capacity > byteTxValues) byteTxValues = fs.capacity;
              if (fs.usage > byteRxValues) byteRxValues = fs.usage;
              elements.push({
                v: Math.round(fs.usage),
                f: humanizeMetric(fs.usage, true),
              });
              elements.push({
                v: Math.round(fs_avg),
                f: humanizeMetric(fs_avg, true),
              });
              elements.push({
                v: Math.round(fs_max),
                f: humanizeMetric(fs_max, true),
              });
              elements.push({
                v: Math.round(fs.capacity),
                f: humanizeMetric(fs.capacity, true),
              });
            }
          }
        } else {
          elements.push(new Date(cur.timestamp));
          elements.push(null);
          elements.push(null);
          elements.push(null);
          elements.push(null);
        }
      }
      // plot only root container and ignore others filesystem - LAT-10825 to avoid data mismatch during upgrade time
      if (elements.length > 0) data.push(elements);
    }
    let computedMaxVal = byteTxValues;

    let ticks = [
      { v: 0, f: "0" },
      {
        v: computedMaxVal / 2,
        f: humanizeMetric(computedMaxVal / 2, true, true),
      },
      {
        v: computedMaxVal + computedMaxVal / 4,
        f: humanizeMetric(computedMaxVal + computedMaxVal / 4, true, true),
      },
    ];
    data[0] = ["Time", "Used", "Average", "High", "Total"];
    //  LAT-10761 To avoid unwanted error in rendering
    let indx = data.findIndex(item => {
      return (item && item[1] && item[1].v >= 0) || (item[2] && item.v >= 0);
    });
    return { data: indx !== -1 && data.length > 2 ? data : [], ticks: ticks };
  }

  UNSAFE_componentWillReceiveProps(newprops) {
    if (this.props != newprops) {
      this.setState({
        loading: newprops.loading,
      });
    }
  }

  redirectToAdvnancedStats = () => {
    let path = window.location.pathname;
    let updated_path = path;
    let path_array = path.split("/");
    if (path_array.length >= 6) {
      path_array.splice(5, 1, "stats");
      path_array = path_array.join("/");
      updated_path = path_array;
    } else {
      updated_path = updated_path + "/stats";
    }
    if (!path.includes("/stats")) {
      this.props.history.push(updated_path);
    }
  };
  // Get the index of the interface with the specified name.
  getNetworkInterfaceIndex = (interfaceName, interfaces) => {
    for (var i = 0; i < interfaces.length; i++) {
      if (interfaces[i].name == interfaceName) {
        return i;
      }
    }
    return -1;
  };
  drawNetworkBytes = () => {
    let stats = this.NodeStatsViewModel.getStatsAndInterfaces.stats
      ? this.NodeStatsViewModel.getStatsAndInterfaces.stats
      : [];
    let interfaces = this.NodeStatsViewModel.getStatsAndInterfaces.interfaces
      ? this.NodeStatsViewModel.getStatsAndInterfaces.interfaces
      : [];
    let interfaceName = this.props.NodeStatsViewModel.currentInterface
      ? this.props.NodeStatsViewModel.currentInterface
      : this.inodeModel.interfacesList
      ? this.inodeModel.interfacesList
      : interfaces[0] && interfaces[0].name
      ? interfaces[0].name
      : null;
    let summaryInterface = this.NodeStatsViewModel.getInterfaceOverViewInfo
      ? this.NodeStatsViewModel.getInterfaceOverViewInfo
      : [];
    let summaryInterfaceIndex = this.getNetworkInterfaceIndex(
      interfaceName,
      summaryInterface,
    );
    // Get interface index.
    var curInterfaceIndex = -1;
    var prevInterfaceIndex = -1;
    var data = [];
    let byteRxValues = 0;
    let byteTxValues = 0;

    let maxRxBytes = 0;
    let maxTxBytes = 0;
    let avgRxBytes = 0;
    let avgTxBytes = 0;

    if ((summaryInterfaceIndex >= 0) & (summaryInterface.length > 0)) {
      maxRxBytes = summaryInterface[summaryInterfaceIndex].max_rx_bytes;
      maxTxBytes = summaryInterface[summaryInterfaceIndex].max_tx_bytes;
      avgRxBytes = summaryInterface[summaryInterfaceIndex].avg_rx_bytes;
      avgTxBytes = summaryInterface[summaryInterfaceIndex].avg_tx_bytes;
    }

    for (var i = 1; i < stats.length; i++) {
      var cur = stats[i];
      var prev = stats[i - 1];
      if (
        cur.network &&
        cur.network.interfaces &&
        cur.network.interfaces.length > 0
      ) {
        curInterfaceIndex = this.getNetworkInterfaceIndex(
          interfaceName,
          cur.network.interfaces,
        );
      }
      if (
        prev.network &&
        prev.network.interfaces &&
        prev.network.interfaces.length > 0
      ) {
        prevInterfaceIndex = this.getNetworkInterfaceIndex(
          interfaceName,
          prev.network.interfaces,
        );
      }
      var intervalInSec =
        getInterval(cur.timestamp, prev.timestamp) / 1000000000;

      var elements = [];
      if (cur && cur.timestamp) {
        if (
          curInterfaceIndex >= 0 &&
          prevInterfaceIndex >= 0 &&
          cur.network &&
          cur.network.interfaces &&
          cur.network.interfaces.length > 0 &&
          cur.network.interfaces[curInterfaceIndex] &&
          cur.network.interfaces[curInterfaceIndex].name &&
          cur.network.interfaces[curInterfaceIndex].name !== "" &&
          cur.network.interfaces[curInterfaceIndex].tx_bytes >= 0 &&
          cur.network.interfaces[curInterfaceIndex].rx_bytes >= 0 &&
          prev.network &&
          prev.network.interfaces &&
          prev.network.interfaces.length > 0 &&
          prev.network.interfaces[prevInterfaceIndex] &&
          prev.network.interfaces[prevInterfaceIndex].name &&
          prev.network.interfaces[prevInterfaceIndex].name !== "" &&
          prev.network.interfaces[prevInterfaceIndex].tx_bytes >= 0 &&
          prev.network.interfaces[prevInterfaceIndex].rx_bytes >= 0
        ) {
          elements.push(new Date(cur.timestamp));
          let tx_bytes =
            (cur.network.interfaces[curInterfaceIndex].tx_bytes -
              prev.network.interfaces[prevInterfaceIndex].tx_bytes) /
            intervalInSec;
          let rx_bytes =
            (cur.network.interfaces[curInterfaceIndex].rx_bytes -
              prev.network.interfaces[prevInterfaceIndex].rx_bytes) /
            intervalInSec;
          if (tx_bytes > byteTxValues) byteTxValues = tx_bytes;
          if (rx_bytes > byteRxValues) byteRxValues = rx_bytes;
          elements.push(
            tx_bytes > 0
              ? { v: Math.round(tx_bytes), f: humanizeMetric(tx_bytes) }
              : { v: 0, f: humanizeMetric(0) },
          );
          elements.push(
            rx_bytes > 0
              ? { v: Math.round(rx_bytes), f: humanizeMetric(rx_bytes) }
              : { v: 0, f: humanizeMetric(0) },
          );
          elements.push({
            v: Math.round(avgTxBytes),
            f: humanizeMetric(avgTxBytes),
          });
          elements.push({
            v: Math.round(maxTxBytes),
            f: humanizeMetric(maxTxBytes),
          });
          elements.push({
            v: Math.round(avgRxBytes),
            f: humanizeMetric(avgRxBytes),
          });
          elements.push({
            v: Math.round(maxRxBytes),
            f: humanizeMetric(maxRxBytes),
          });
        } else {
          elements.push(new Date(cur.timestamp));
          elements.push(null);
          elements.push(null);
          elements.push(null);
          elements.push(null);
          elements.push(null);
          elements.push(null);
        }
      }
      if (elements.length > 0) data.push(elements);
    }

    let computedMaxVal = Math.max(
      maxTxBytes,
      byteTxValues,
      byteRxValues,
      maxRxBytes,
    );
    let ticks = [
      { v: 0, f: "0" },
      {
        v: computedMaxVal / 2,
        f: humanizeMetric(computedMaxVal / 2, false, true),
      },
      {
        v: computedMaxVal + computedMaxVal / 4,
        f: humanizeMetric(computedMaxVal + computedMaxVal / 4, false, true),
      },
    ];
    data[0] = [
      "Time",
      "Transmit",
      "Receive",
      "Average Transmit",
      "High Transmit",
      "Average Receive",
      "High Receive",
    ];

    //  LAT-10761 To avoid unwanted error in rendering
    let indx = data.findIndex(item => {
      return (
        (item && item[0] && item[1] && item[1].v >= 0) ||
        (item[2] && item.v >= 0)
      );
    });

    return {
      data: computedMaxVal > 0 && data.length > 2 && indx !== -1 ? data : [],
      ticks: ticks,
    };
  };
  render() {
    let stats_data = this.NodeStatsViewModel.getStatsAndInterfaces.stats
      ? this.NodeStatsViewModel.getStatsAndInterfaces.stats
      : [];
    return (
      <Card
        title={
          <div>
            {this.props.type === "cpu" ? (
              <Row>
                <Col span={20}>
                  {getCurrentlocaleText(
                    "chart.cpu-utilization.percentage.legend",
                  )}
                </Col>
                <Col span={4}>
                  {" "}
                  {getCurrentlocaleText("netstats.selector.time.text", {
                    0: "5",
                  })}
                </Col>
              </Row>
            ) : this.props.type === "memory" ? (
              <Row>
                <Col span={20}>
                  {getCurrentlocaleText("chart.cpu-utilization.memeory.legend")}
                </Col>
                <Col span={4}>
                  {" "}
                  {getCurrentlocaleText("netstats.selector.time.text", {
                    0: "5",
                  })}
                </Col>
              </Row>
            ) : this.props.type === "fs" ? (
              <Row>
                <Col span={20}>
                  {getCurrentlocaleText("stats.filesystem.title.text")}{" "}
                  {stats_data &&
                  stats_data[0] &&
                  stats_data[0].filesystem[0] &&
                  stats_data[0].filesystem[0].device.includes("ROOT")
                    ? "(" + stats_data[0].filesystem[0].device + ")"
                    : null}
                </Col>
                <Col span={4}>
                  {" "}
                  {getCurrentlocaleText("netstats.selector.time.text", {
                    0: "5",
                  })}
                </Col>
              </Row>
            ) : this.props.type === "nw" ? (
              <Row>
                <Col span={20}>
                  {getCurrentlocaleText("stats.card.interface.traffic.text")}{" "}
                </Col>
                <Col span={4}>
                  {" "}
                  {getCurrentlocaleText("netstats.selector.time.text", {
                    0: "5",
                  })}
                </Col>
              </Row>
            ) : null}
          </div>
        }
        bordered={false}
        hoverable={false}
      >
        {this.props.type === "cpu" ? (
          <Col span={24}>
            <UtilizationChart
              height={250}
              loading={this.state.loading}
              chartType="LineChart"
              data={
                this.drawCpuTotalUsage() && this.drawCpuTotalUsage().length > 2
                  ? this.drawCpuTotalUsage()
                  : []
              }
              options={{
                chartArea: {
                  left: "5%",
                  top: "10%",
                  width: "100%",
                  height: "75%",
                },
                curveType: "function",
                theme: "material",
                annotations: { highContrast: false },
                focusTarget: "category",
                series: {
                  1: { color: "#f04134", lineDashStyle: [2, 2] },
                  2: { color: "#0000ff", lineDashStyle: [2, 2] },
                  0: { color: "#00A854" },
                },
                titlePosition: "none",
                hAxis: {
                  format: "hh:mm a ",
                  textStyle: { color: this.textColor },
                },
                legend: {
                  position: "bottom",
                  textStyle: { color: this.textColor },
                },
                vAxis: {
                  minValue: 0,
                  format: "#'%'",
                  suffix: " %",
                  viewWindow: { min: 0 },
                },
                title: getCurrentlocaleText(
                  "chart.cpu-utilization.percentage.legend",
                ),
                titleTextStyle: {
                  color: this.textColor,
                  textAlign: "center",
                },
              }}
              formatters={[
                {
                  type: "NumberFormat",
                  column: 1,
                  options: {
                    suffix: " %",
                  },
                },
                {
                  type: "NumberFormat",
                  column: 2,
                  options: {
                    suffix: " %",
                  },
                },
                {
                  type: "NumberFormat",
                  column: 3,
                  options: {
                    suffix: " %",
                  },
                },
              ]}
              {...this.props}
              redirectToAdvnancedStats={this.redirectToAdvnancedStats}
            />
          </Col>
        ) : this.props.type === "fs" ? (
          <Col span={24}>
            <UtilizationChart
              height={250}
              chartType="LineChart"
              loading={this.state.loading}
              data={
                this.drawFileSystemUsage() &&
                this.drawFileSystemUsage().data &&
                this.drawFileSystemUsage().data.length > 2
                  ? this.drawFileSystemUsage().data
                  : []
              }
              options={{
                chartArea: {
                  left: "7%",
                  top: "10%",
                  width: "100%",
                  height: "75%",
                },
                theme: "material",
                annotations: { highContrast: false },
                focusTarget: "category",
                curveType: "function",
                series: {
                  1: { color: "#f04134", lineDashStyle: [2, 2] },
                  2: { color: "#0000ff", lineDashStyle: [2, 2] },
                  3: { color: "#008000", lineDashStyle: [2, 2] },
                  0: { color: "#00A854" },
                },
                hAxis: {
                  format: "hh:mm a ",
                  textStyle: { color: this.textColor },
                },
                legend: {
                  position: "bottom",
                  textStyle: { color: this.textColor },
                },
                vAxis: {
                  legend: {
                    position: "left",
                  },
                  viewWindowMode: "explicit",
                  minValue: 2,
                  viewWindow: { min: 0 },
                  ticks:
                    this.drawFileSystemUsage() &&
                    this.drawFileSystemUsage().ticks
                      ? this.drawFileSystemUsage().ticks
                      : {},
                  textStyle: { color: this.textColor },
                },
                titlePosition: "none",
                titleTextStyle: { color: this.textColor },
              }}
              redirectToAdvnancedStats={this.redirectToAdvnancedStats}
              {...this.props}
            />
          </Col>
        ) : this.props.type === "nw" ? (
          <Col span={24}>
            <UtilizationChart
              height={250}
              chartType="LineChart"
              loading={this.state.loading}
              data={
                this.drawNetworkBytes() &&
                this.drawNetworkBytes().data &&
                this.drawNetworkBytes().data.length > 2
                  ? this.drawNetworkBytes().data
                  : []
              }
              options={{
                chartArea: {
                  left: "7%",
                  top: "10%",
                  width: "100%",
                  height: "75%",
                },
                theme: "material",
                annotations: { highContrast: false },
                focusTarget: "category",
                curveType: "function",
                series: {
                  0: { color: "#6f9654" },
                  1: { color: "#1c91c0" },
                  2: { color: "#0000ff", lineDashStyle: [2, 2] },
                  3: { color: "#f04134", lineDashStyle: [2, 2] },
                  4: { color: "#003366", lineDashStyle: [2, 2] },
                  5: { color: "#ff9900", lineDashStyle: [2, 2] },
                },
                hAxis: {
                  format: "hh:mm a ",
                  textStyle: { color: this.textColor },
                },
                legend: {
                  position: "bottom",
                  textStyle: { color: this.textColor },
                },
                vAxis: {
                  legend: {
                    position: "left",
                  },
                  minValue: 2,
                  viewWindowMode: "explicit",
                  viewWindow: { min: 0 },
                  ticks:
                    this.drawNetworkBytes() && this.drawNetworkBytes().ticks
                      ? this.drawNetworkBytes().ticks
                      : {},
                  textStyle: { color: this.textColor },
                },
                titlePosition: "none",
                titleTextStyle: { color: this.textColor },
              }}
              redirectToAdvnancedStats={this.redirectToAdvnancedStats}
              {...this.props}
            />
          </Col>
        ) : (
          <Col span={24}>
            <UtilizationChart
              height={250}
              chartType="LineChart"
              loading={this.state.loading}
              data={
                this.drawMemoryTotalUsage() &&
                this.drawMemoryTotalUsage().length > 2
                  ? this.drawMemoryTotalUsage()
                  : []
              }
              options={{
                chartArea: {
                  left: "7%",
                  top: "10%",
                  width: "100%",
                  height: "75%",
                },
                theme: "material",
                annotations: { highContrast: false },
                focusTarget: "category",
                curveType: "function",
                series: {
                  1: { color: "#f04134", lineDashStyle: [2, 2] },
                  2: { color: "#0000ff", lineDashStyle: [2, 2] },
                  3: { color: "#008000", lineDashStyle: [2, 2] },
                  0: { color: "#00A854" },
                },
                hAxis: {
                  format: "hh:mm a ",
                  textStyle: { color: this.textColor },
                },
                legend: {
                  position: "bottom",
                  textStyle: { color: this.textColor },
                },
                vAxis: {
                  minValue: 0,
                  viewWindowMode: "explicit",
                  format: "#' MB'",
                  baseline: 0,
                  viewWindow: {
                    min: 0,
                  },
                },
                titlePosition: "none",
                titleTextStyle: { color: this.textColor },
              }}
              redirectToAdvnancedStats={this.redirectToAdvnancedStats}
              {...this.props}
            />
          </Col>
        )}
        {this.NodeStatsViewModel.getSummaryDuration &&
          this.NodeStatsViewModel.getSummaryDuration.start_date &&
          this.NodeStatsViewModel.getSummaryDuration.end_date &&
          this.NodeStatsViewModel.getStatsAndInterfaces.stats.length > 0 &&
          this.drawCpuTotalUsage() &&
          this.drawCpuTotalUsage().length > 2 && (
            <div style={{ float: "left", fontSize: 10, fontStyle: "italic" }}>
              <Row>
                <Col span={24}>
                  {getCurrentlocaleText("stats.avghightime.timeinfo.message")}
                </Col>
              </Row>
              <Row />
            </div>
          )}
      </Card>
    );
  }
}

export default WaterMarkChart;
