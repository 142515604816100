import React, { Component } from "react";
import { Row, Col, Button } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import BreadcrumbComponent from "components/UI-Components/BreadcrumbComponent";
import Icons from "components/UI-Components/Icons";
import ReleaseElement from "components/Containers/ReleaseElement";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import { inject, observer } from "mobx-react";
import SearchInput from "components/UI-Components/SearchInput";
import {
  highlightSearchText,
  getCurrentlocaleText,
  getTimezoneToDisplay,
  TIME_DATE_FORMAT,
} from "Core/Utils.js";
import moment from "moment";

import ReleaseController from "controller/ReleaseController";
import OrgSelector from "components/Orgs/OrgSelector";

@inject("AuthStore", "ReleaseViewModel", "TimezoneViewModel")
@observer
class Releases extends Component {
  constructor(props) {
    super(props);
    this.releaseModel = this.props.ReleaseViewModel;
    this.state = {
      searchText: "",
      rescaning: false,
      BreadcrumbList: [
        {
          name: getCurrentlocaleText("release.navigation_menu.text"),
          link: "help/releases",
        },
      ],
      sortOrder: "desc",
    };
  }

  UNSAFE_componentWillMount() {
    this.releaseList({}, true, true);
  }

  releaseList = (params = {}, loading = true, forceCall = false) => {
    loading && this.setState({ data: [] });
    ReleaseController.getReleases(params, loading, forceCall)
      .then(resp => {
        if (resp) {
          this.setState({ data: resp });
        }
        this.setState({ rescaning: false });
      })
      .catch(error => {
        this.setState({ rescaning: false });
      });
  };

  reScan = () => {
    this.setState({ rescaning: true });
    this.forceGetReleases({}, false, true);
  };
  forceGetReleases = (params = {}, loading = true, forceCall = false) => {
    loading && this.setState({ data: [] });
    ReleaseController.forceGetReleases(params, loading, forceCall)
      .then(resp => {
        if (resp) {
          this.setState({ data: resp });
        }
        this.setState({ rescaning: false });
      })
      .catch(error => {
        this.setState({ rescaning: false });
      });
  };

  renderList() {
    let data =
      this.state.searchText.trim().length > 0
        ? this.state.data.filter(val => {
            return val.version.indexOf(this.state.searchText) !== -1;
          })
        : this.state.data;
    return data.length > 0 ? (
      data
        .sort((x, y) => {
          let Xreleased_at = new Date(x.released_at).getTime(),
            Yreleased_at = new Date(y.released_at).getTime();

          return this.state.sortOrder === "desc"
            ? Yreleased_at - Xreleased_at
            : Xreleased_at - Yreleased_at;
        })
        .map((item, index) => {
          let released_at = `${moment(
            new Date(item.released_at).getTime(),
          ).format(TIME_DATE_FORMAT)} ${getTimezoneToDisplay(
            item.released_at,
          )}`;
          let title = (
            <div>
              <div key={item.version}>
                {"Software Version: "}
                {this.state.searchText.trim().length > 0
                  ? highlightSearchText(
                      item.version,
                      this.state.searchText,
                      "#dc7c3a",
                    )
                  : item.version}{" "}
              </div>
              <div className="release-at-date">
                <span>{released_at}</span>
              </div>
            </div>
          );
          return (
            <ReleaseElement
              key={item.version}
              releaseData={item}
              cardTitle={title}
              version={item.version}
              deleteVersion={this.deleteInodeVersion}
              sortOrder={this.state.sortOrder}
            />
          );
        })
    ) : (
      <div>{getCurrentlocaleText("releases.noContent.text")}</div>
    );
  }

  listFilter = e => {
    this.setState({ searchText: e.target.value });
  };

  clearSearchFilter = () => {
    this.setState({ searchText: "" });
  };

  deleteInodeVersion = version => {
    let data = this.state.data;
    let in_dx = data.findIndex(obj => {
      return obj.version === version;
    });
    if (in_dx !== -1) {
      data.splice(in_dx, 1);
    }
    this.setState({ data: data });
  };

  handleSort = () => {
    this.setState({
      sortOrder: this.state.sortOrder === "desc" ? "asc" : "desc",
    });
  };

  render() {
    let container = (
      <div>
        {this.state.data.length > 0 ? (
          <div>
            <div
              style={{
                marginBottom: "10px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: 30,
                }}
              >
                <Button
                  style={{ marginRight: 15 }}
                  onClick={this.handleSort}
                  title={getCurrentlocaleText("releases.sortBydate.text")}
                >
                  <Icons
                    type="ai"
                    name={
                      this.state.sortOrder === "desc"
                        ? "AiOutlineArrowDown"
                        : "AiOutlineArrowUp"
                    }
                    style={{ fontSize: 16 }}
                  />
                  {getCurrentlocaleText("releases.sortBydate.text")}
                </Button>
                <SearchInput
                  placeholder={getCurrentlocaleText(
                    "releases.filterByVersion.text",
                  )}
                  onChange={this.listFilter}
                  value={this.state.searchText}
                  clearSearch={this.clearSearchFilter}
                />
              </div>
            </div>
            {this.renderList()}
          </div>
        ) : (
          <div>
            {!this.releaseModel.loading && (
              <div>{getCurrentlocaleText("releases.noContent.text")}</div>
            )}
          </div>
        )}
      </div>
    );

    return (
      <div>
        <Row gutter={16} type="flex" align="middle">
          <Col lg={{ span: 18 }} xl={{ span: 20 }}>
            <h2 className="page-title">
              <Icons type="fa" name="FaDownload" />
              {"  "}
              {getCurrentlocaleText("release.navigation_menu.text")}
            </h2>
            <BreadcrumbComponent
              {...this.props}
              BreadcrumbList={this.state.BreadcrumbList}
            />
          </Col>
          <Col lg={{ span: 6 }} xl={{ span: 4 }} className="flex flex-h-right">
            <OrgSelector {...this.props} />
          </Col>
        </Row>
        <Row gutter={16} type="flex" align="middle">
          <Col push={20} span={4}>
            {this.props.AuthStore.IsPermitted("RELEASESYNC:VIEW") && (
              <Row type="flex" justify="end">
                <Col span={24}>
                  <div
                    style={{
                      textAlign: "right",
                      marginRight: 52,
                      marginBottom: 10,
                    }}
                  >
                    <Button
                      type="primary"
                      icon={<SyncOutlined />}
                      onClick={this.reScan}
                      disabled={this.releaseModel.loading}
                      loading={this.state.rescaning}
                    >
                      {getCurrentlocaleText("sync.to.s3")}
                    </Button>
                  </div>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        <div
          style={{
            padding: 24,
            background: "#fff",
            minHeight: 360,
          }}
        >
          <LoadingComponent loading={this.releaseModel.loading}>
            {container}
          </LoadingComponent>
        </div>
      </div>
    );
  }
}

export default Releases;
