import React, { Component } from "react";
import { Button, Upload, Row, Col, Spin } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Input from "components/UI-Components/InputBox";

class BrandingLogoUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideInitialLoad: true,
      formData: this.props.formData,
      fileList: this.props.fileList,
      fileNames: this.props.fileNames,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ hideInitialLoad: false });
    }, 200);
  }

  componentWillReceiveProps(newProps, newState) {
    if (newProps != this.props) {
      this.setState({
        fileList: newProps.fileList,
        fileNames: newProps.fileNames,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        {!this.state.hideInitialLoad &&
          (!this.props.showUpload &&
          !(this.props.uploadedInfo && this.props.uploadedInfo.logo_id) ? (
            <Spin tip="Uploading..." spinning={this.props.loading}>
              <Row gutter={8}>
                {!this.props.loading && !this.props.isUploadFailed && (
                  <Col span={20}>
                    <Input
                      type="url"
                      placeholder={this.props.placeholder}
                      onFocus={this.props.onFocus}
                      defaultValue={
                        this.state.formData &&
                        this.state.formData[this.props.url_type]
                          ? this.state.formData[this.props.url_type]
                          : this.props.initialValue
                      }
                      onChange={this.props.URLChange}
                      disabled={
                        this.props.isDisabled ? this.props.isDisabled : false
                      }
                    />
                  </Col>
                )}
                <Col
                  span={
                    this.props.loading || this.props.isUploadFailed ? 24 : 4
                  }
                >
                  <Upload
                    customRequest={this.props.customRequest}
                    onChange={this.props.onChange}
                    listType="picture"
                    onFocus={this.props.onFocus}
                    accept="image/*"
                    name={null}
                    maxCount={1}
                    onRemove={this.props.onRemove}
                    disabled={
                      this.props.isDisabled ? this.props.isDisabled : false
                    }
                  >
                    <Button
                      onFocus={this.props.onFocus}
                      disabled={
                        this.props.isDisabled ? this.props.isDisabled : false
                      }
                      icon={<UploadOutlined />}
                    ></Button>
                  </Upload>
                </Col>
              </Row>
            </Spin>
          ) : (
            <Spin tip="Removing..." spinning={this.props.loading}>
              <Row gutter={8}>
                <Col span={24}>
                  <Upload
                    customRequest={this.props.customRequest}
                    onChange={this.props.onChange}
                    listType="picture"
                    maxCount={1}
                    onFocus={this.props.onFocus}
                    name={null}
                    onRemove={this.props.onRemove}
                    accept="image/*"
                    defaultFileList={
                      this.state.fileList && this.state.fileList.length > 0
                        ? this.state.fileList
                        : []
                    }
                    disabled={
                      this.props.isDisabled ? this.props.isDisabled : false
                    }
                    fileList={this.state.fileList}
                  ></Upload>
                </Col>
              </Row>
            </Spin>
          ))}
      </React.Fragment>
    );
  }
}

export default BrandingLogoUploader;
