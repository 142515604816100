import React, { Component } from "react";
import { Table } from "antd";
import { getCurrentlocaleText } from "../../Core/Utils";

class StatusContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      BytesCount: this.props.bytes,
      PacketsCount: this.props.packets,
    };
  }

  UNSAFE_componentWillReceiveProps(newprops) {
    if (newprops != this.props) {
      this.setState({
        BytesCount: newprops.bytes,
        PacketsCount: newprops.packets,
      });
    }
  }
  render() {
    const columns = [
      {
        title: getCurrentlocaleText(
          "firewall.list.table.header.packetcount.text",
        ),
        dataIndex: "packets",
      },
      {
        title: getCurrentlocaleText(
          "firewall.list.table.header.bytecount.text",
        ),
        dataIndex: "bytes",
      },
    ];
    const data = [
      {
        key: "0",
        bytes: this.state.BytesCount,
        packets: this.state.PacketsCount,
      },
    ];
    return (
      <span>
        <Table
          pagination={false}
          columns={columns}
          dataSource={data}
          size="small"
        />
      </span>
    );
  }
}
export default StatusContent;
