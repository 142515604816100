import React from "react";
import { useState, useEffect } from "react";
import { Space, Input, Button, Switch, Row, Col } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import * as ip from "ip";
import { getCurrentlocaleText, isCidr, isCidrRange } from "Core/Utils";
const FormItem = Form.Item;

function MultipleIpAddressInputList(props) {
  const { selected_networks } = props;
  // set default network in config
  const [defaultNetworkList, setDeFaultNetworkList] = useState([]);
  const [isDefaultChosen, setDefaultChosed] = useState(false);
  // remove network handle
  const removeNetwork = nwid => {
    props.removeNetwork(nwid);
  };
  const { getFieldDecorator } = props.referenceForm;

  useEffect(() => {
    checkDefaultNetworkIndex();
  }, [selected_networks]);

  const checkIpV4 = (rule, value, callback, networkCidr) => {
    let failMessage = null;
    if (value) {
      value = value.toString();
    }
    if (
      value &&
      value.toLowerCase() !== "dynamic" &&
      ip.isV4Format(value) !== true
    ) {
      failMessage = getCurrentlocaleText(
        "network.form.startip.errormessage1.text",
      );
    }
    if (
      value &&
      value.toLowerCase() !== "dynamic" &&
      ip.isV4Format(value) &&
      networkCidr
    ) {
      let cidrValue = networkCidr;
      let cidrRangeInfo = null,
        cidrSubnet = isCidr(cidrValue) && ip.cidrSubnet(cidrValue);
      if (cidrSubnet) {
        cidrRangeInfo = `${cidrSubnet.firstAddress} - ${cidrSubnet.lastAddress}`;
      }
      if (!isCidrRange(networkCidr, value) && cidrRangeInfo) {
        failMessage =
          getCurrentlocaleText("network.form.startip.errormessage2.text") +
          cidrRangeInfo;
      }
    }
    if (failMessage) {
      callback(failMessage);
    }
    callback();
  };

  const updateIp = (value, networkId) => {
    props.updateResource(value, networkId, false);
  };
  // default Network selection
  const onDefaultNetworkChange = (value, index, networkId) => {
    if (value) {
      setDefaultChosed(value);
      setDeFaultNetworkList([index]);
      props.updateResource(value, networkId, true);
    } else {
      setDeFaultNetworkList([]);
      setDefaultChosed(value);
      props.updateResource(value, networkId, true);
    }
  };

  const checkDefaultNetworkIndex = () => {
    let updatedValue = props.updatedValue;
    if (updatedValue && updatedValue.length > 0) {
      updatedValue.forEach((network, index) => {
        let currentDefaultData =
          network && network.is_default ? network.is_default : false;
        if (currentDefaultData) {
          let currentIndex = selected_networks.findIndex(
            net => net.id === network.network_id,
          );
          if (currentIndex != -1) {
            setDeFaultNetworkList([currentIndex]);
            setDefaultChosed(true);
          }
        }
      });
    }
  };
  return selected_networks
    .sort((a, b) => (a.id > b.id ? 1 : -1))
    .map((nw, index) => {
      let service_addressing =
        nw.config && nw.config.network && nw.config.network.service_addressing
          ? nw.config.network.service_addressing
          : "AUTO";
      let currentIpAddressData = props.updatedValue.filter(
        ne => ne.network_id === nw.id,
      );
      let currentIpAddress =
        currentIpAddressData &&
        currentIpAddressData.length > 0 &&
        currentIpAddressData[0].ip_address
          ? currentIpAddressData[0].ip_address
          : null;
      return (
        <div
          key={index}
          className="multiple-ip-list-container"
          style={index > 0 ? { marginTop: 15 } : {}}
        >
          {index === 0 && (
            <Row className="service-multinetwork-title">
              <Col span={9}>
                <span>Network</span>
              </Col>
              <Col span={8}>
                <span>IP Address</span>
              </Col>
              {selected_networks.length > 1 ? (
                <Col span={3}>
                  <span>Default</span>
                </Col>
              ) : null}
              <Col span={4}>
                <span>Remove</span>
              </Col>
            </Row>
          )}
          <Row>
            <Col span={9}>{nw.name}</Col>
            <Col span={8}>
              {service_addressing.toLowerCase() === "manual" ? (
                <FormItem hasFeedback>
                  {getFieldDecorator(`${props.id}_ipaddress${index}`, {
                    rules: [
                      {
                        required: true,
                        message: ` ${getCurrentlocaleText(
                          "network.form.startip.errormessage1.text",
                        )}`,
                      },
                      {
                        validator: (rule, value, callback) => {
                          let cidr =
                            nw.config &&
                            nw.config.network &&
                            nw.config.network.cidr
                              ? nw.config.network.cidr
                              : null;
                          checkIpV4(rule, value, callback, cidr);
                        },
                      },
                    ],
                    initialValue: currentIpAddress,
                  })(
                    <Input
                      style={{ width: 250, display: "inline-block" }}
                      onChange={e => {
                        updateIp(e.target.value, nw.id);
                      }}
                      disabled={props.disabled}
                    />,
                  )}
                </FormItem>
              ) : (
                <span style={{ marginBottom: 25 }}>{"Dynamic"}</span>
              )}
            </Col>
            {selected_networks.length > 1 ? (
              <Col span={3}>
                <Switch
                  size="small"
                  className="service-multinetwork-df-ntw"
                  onChange={value => {
                    onDefaultNetworkChange(value, index, nw.id);
                  }}
                  checked={
                    defaultNetworkList.includes(index) ||
                    (selected_networks &&
                      defaultNetworkList.length === 0 &&
                      index === 0)
                  }
                  disabled={props.disabled}
                />
              </Col>
            ) : null}
            <Col span={4}>
              <Button
                className="multiple-ip-list-remove-btn no-border no-box-shadow"
                onClick={() => {
                  removeNetwork(nw.id);
                }}
              >
                <Space>
                  <DeleteOutlined />
                </Space>
              </Button>
            </Col>
          </Row>
        </div>
      );
    });
}

export default MultipleIpAddressInputList;
