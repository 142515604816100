import React, { Component } from "react";
import { string } from "prop-types";
import { Tag, Popover } from "antd";
import { NOT_APPLICAPLE } from "Core/Utils";
import { NodeUnreachableStateInfo } from "components/INodes";

class NodeStatus extends Component {
  static propTypes = {
    nodeState: string.isRequired,
    lastContact: string,
  };

  static defaultProps = {
    nodeState: "NEW",
  };

  constructor(props) {
    super(props);

    //Color code for different node status
    this.colorCode = {
      new: "tag-iotium-info",
      unreachable: "tag-iotium-danger",
      alive: "tag-iotium-success",
      rebooting: "tag-iotium-warning",
    };
  }

  getBadge = nodeState => {
    switch (nodeState) {
      case "new":
        return "processing";
        break;
      case "alive":
        return "success";
        break;
      case "unreachable":
        return "error";
        break;
      default:
        return "warning";
        break;
    }
  };

  render() {
    const { nodeState, lastContact, nodeProfile } = this.props;

    let statusLabel = (
      <Tag
        className={this.colorCode[nodeState.toLowerCase()]}
        style={{ cursor: "default" }}
      >
        {nodeState.toUpperCase()}
      </Tag>
    );

    return (
      <div>
        <h4>
          {lastContact &&
          lastContact !== NOT_APPLICAPLE &&
          (nodeState === "UNREACHABLE" || nodeState === "REBOOTING") ? (
            <Popover content={lastContact} title="Last Contact">
              {statusLabel}
            </Popover>
          ) : (
            statusLabel
          )}
          <NodeUnreachableStateInfo
            nodeState={nodeState}
            nodeProfile={nodeProfile}
          />
        </h4>
      </div>
    );
  }
}

export default NodeStatus;
