import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import propTypes from "prop-types";
import { Row, Col } from "antd";
import { focusFirstInput } from "Core/Utils";
import TimezoneComponent from "components/Logs/Components/TimezoneComponent";
import Icons from "./Icons";

const FormItem = Form.Item;

class EditableUserTimeZone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editable: false,
    };
  }

  componentDidMount() {
    this.setState({ value: this.props.displayValue });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ value: nextProps.displayValue });
  }

  onSave = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (this.props.initialValue !== values.timezone) {
          this.props.submit(values, this.props.field);
        }
        this.setState({ editable: false });
      }
    });
  };

  onEdit = () => {
    this.setState({ editable: true });
    // Focus editable input while edit
    focusFirstInput();
  };

  onClose = () => {
    this.setState({ editable: false });
  };

  selectedTimezoneValue = value => {
    this.props.form.setFields({
      timezone: {
        value: value,
      },
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        {this.state.editable ? (
          <div>
            <Row gutter={0}>
              <Col span={18} style={{ fontSize: 13 }}>
                <Form noValidate>
                  <FormItem
                    hasFeedback
                    style={{ display: "inline-block", marginTop: "-7px" }}
                  >
                    {getFieldDecorator(this.props.formItemName, {
                      initialValue: this.props.initialValue,
                    })(
                      <TimezoneComponent
                        style={{ width: "100%" }}
                        placeholder={this.props.placeholder}
                        initialValue={this.props.initialValue}
                        selectedValue={this.selectedTimezoneValue}
                        defaultTimezone={"org"}
                        defaultTimezoneValue={this.props.orgTimezone}
                      />,
                    )}
                  </FormItem>
                </Form>
              </Col>
              <Col span={6} style={{ fontSize: 13 }}>
                <span onClick={this.onSave}>
                  <Icons
                    type="ai"
                    name="AiOutlineCheckCircle"
                    className="editable-input-icon valign-middle"
                    title="Save"
                  />
                </span>
                <span onClick={this.onClose}>
                  <Icons
                    type="ai"
                    name="AiOutlineCloseCircle"
                    className="editable-input-icon valign-middle"
                    title="Close"
                  />
                </span>
              </Col>
            </Row>
          </div>
        ) : (
          <div style={{ height: 50 }}>
            {this.state.value || "-"}
            <span className="iot-pointer-cursor" onClick={this.onEdit}>
              <Icons
                type="ai"
                name="AiOutlineEdit"
                className="editable-input-icon valign-middle"
                title="Edit"
              />
            </span>
          </div>
        )}
      </div>
    );
  }
}

EditableUserTimeZone.propTypes = {
  placeholder: propTypes.string,
  initialValue: propTypes.string,
  formItemName: propTypes.string.isRequired,
  submit: propTypes.any,
  field: propTypes.string,
  rules: propTypes.array,
  displayValue: propTypes.string,
  orgTimezone: propTypes.string,
};

const EditableUserTimezone = Form.create()(EditableUserTimeZone);
export default EditableUserTimezone;
