import React, { Component } from "react";
import { Col, Row, Button, Card, Icon, Avatar } from "antd";
import { inject, observer } from "mobx-react";
import "assets/styles/service.css";
import InodeViewModel from "view-model/InodeViewModel";
import NetworkViewModel from "view-model/NetworkViewModel";
import { serviceTemplateController } from "controller/Controllers";
import { getCurrentlocaleText, getErrorMessage } from "Core/Utils";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import FormErrorMessage from "components/UI-Components/FormErrorMessage";
import Icons from "../../../UI-Components/Icons";
import customImg from "assets/img/logo_custom.png";

@inject("UiStore", "AuthStore")
@observer
export default class AppStore extends Component {
  constructor(props) {
    super(props);
    this.syncInterval;
    this.state = {
      showHelp: false,
      selectedSpec: null,
      path: "/",
      availableServices: [],
      isScanning: false,
      fetchingTemplates: true,
      imageIcons: [],
    };
    this.hasCustomPermission = true;
  }

  UNSAFE_componentWillMount() {
    this.fetchTemplates();
  }

  componentWillUnmount() {
    clearInterval(this.syncInterval);
  }

  fetchTemplates = (params = {}) => {
    return new Promise((resolve, reject) => {
      params.size = 100;
      params.org_id = this.props.match.params.id;
      params.own = true;
      serviceTemplateController.getTemplates(params).then(
        res => {
          let serviceList = res.map(val => {
            return {
              id: val.id,
              ...val.template_metadata,
            };
          });
          /* sorting services list */
          serviceList.sort((a, b) => {
            var nameA = a.name.toLowerCase(),
              nameB = b.name.toLowerCase();
            if (
              nameA < nameB //sort string ascending
            )
              return -1;
            if (nameA > nameB) return 1;
            return 0; //default return value (no sorting)
          });
          serviceList &&
            serviceList.length > 0 &&
            serviceList.forEach((item, i) => {
              if (item.name.toLowerCase() === "custom") {
                serviceList.splice(i, 1);
                serviceList.unshift(item);
              }
            });
          this.setState(
            {
              availableServices: serviceList,
              fetchingTemplates: false,
              imageIcons: [],
            },
            () => {
              this.renderImageIcons();
              resolve(true);
            },
          );
        },
        error => {
          this.setState({ fetchingTemplates: false });
          this.props.UiStore.setErrorMessage(
            getErrorMessage(error, "servicetemplate"),
          );
          reject(true);
        },
      );
    });
  };

  renderImageIcons = () => {
    if (this.state.availableServices.length > 0) {
      let imageIcons = this.state.imageIcons;
      for (let index in this.state.availableServices) {
        let val = this.state.availableServices[index];
        if (val.logo) {
          let url = "/api/v2/servicetemplate/" + val.id + "/" + val.logo;
          serviceTemplateController
            .getImage(url)
            .then(blobResponse => {
              if (blobResponse) {
                imageIcons[index] = this.showServiceImages(
                  val,
                  index,
                  blobResponse,
                );
                this.setState({ imageIcons: imageIcons });
              }
            })
            .catch(error => {
              // show app without image and with only text avatar
              imageIcons[index] = this.showServiceImages(val, index);
              this.setState({ imageIcons: imageIcons });
            });
        } else {
          imageIcons[index] = this.showServiceImages(val, index);
          this.setState({ imageIcons: imageIcons });
        }
      }
    } else {
      this.setState({
        imageIcon: <div>{getCurrentlocaleText("no.services.to.display")}</div>,
      });
    }
  };

  serviceSelected = val => {
    let networks = 0;
    let org_id = this.props.match.params.id,
      node_id = this.props.match.params.nodeId,
      template_name = val.id;
    let cluster_id = this.props.match.params.nodeId;

    if (this.props.isCluster) {
      cluster_id = this.props.match.params.cluster_id;
    }

    /* redirect only when node has networks */
    InodeViewModel.inodes.map(val => {
      if (val.networks) {
        ++networks;
      }
      return null;
    });
    let cluster_networks = [];
    // for cluster networks
    if (this.props.isCluster) {
      cluster_networks = NetworkViewModel.networks.filter(val => {
        if (
          val.cluster &&
          val.cluster.id === this.props.match.params.cluster_id
        ) {
          return val;
        }
      });
    }

    networks > 0 || (this.props.isCluster && cluster_networks.length > 1)
      ? this.props.isCluster
        ? this.props.history.push(
            `/orgs/${org_id}/clusters/${cluster_id}/services/${template_name}`,
          )
        : this.props.history.push(
            `/orgs/${org_id}/inodes/${node_id}/services/${template_name}`,
          )
      : (this.props.UiStore.errorMessage = `${getCurrentlocaleText(
          "appstore.no.inodes.error.msg",
        )}`);
  };

  scanS3 = () => {
    this.setState({ isScanning: true, fetchingTemplates: true });
    clearInterval(this.syncInterval);
    serviceTemplateController
      .triggerSync(true)
      .then(response => {
        if (response && response.request_id) {
          let requestId = response.request_id;
          this.syncInterval = setInterval(() => {
            serviceTemplateController
              .getSyncStatus(requestId)
              .then(response => {
                if (response) {
                  if (response.request_id === requestId) {
                    if (response.status === "COMPLETED") {
                      clearInterval(this.syncInterval);
                      if (response.error) {
                        this.setFormErrorMessage(response.error);
                      }
                      this.fetchTemplates().then(
                        success => {
                          if (!response.error) {
                            this.props.UiStore.successMessage = `${getCurrentlocaleText(
                              "s3.scanned.successfully",
                            )}`;
                          }
                          this.setState({ isScanning: false });
                        },
                        error => {
                          this.setState({ isScanning: false });
                        },
                      );
                    } else if (response.status === "ERROR") {
                      this.setFormErrorMessage(response.error);
                    } else {
                      // Do nothing
                    }
                  }
                } else {
                  clearInterval(this.syncInterval);
                  this.setState({
                    isScanning: false,
                    fetchingTemplates: false,
                  });
                }
              })
              .catch(error => {
                clearInterval(this.syncInterval);
                this.setState({ isScanning: false, fetchingTemplates: false });
              });
          }, 10000);
        } else {
          this.setState({ isScanning: false, fetchingTemplates: false });
        }
      })
      .catch(error => {
        this.setState({ isScanning: false, fetchingTemplates: false });
      });
  };

  setFormErrorMessage = errors => {
    if (errors && errors.length > 0) {
      this.props.UiStore.formErrorMessage = errors.map((error, index) => {
        return (
          <div key={index}>
            {errors.length > 1 && <span>{`${index + 1}. `}</span>}
            {error}
          </div>
        );
      });
    } else {
      this.props.UiStore.formErrorMessage = null;
    }
  };

  showServiceImages = (val, i, blob) => {
    return (
      <div
        key={i}
        className="service-card"
        onClick={this.serviceSelected.bind(null, val)}
      >
        {val.id.toLowerCase() !== "custom" && val.logo && blob ? (
          <div
            id={val.id}
            title={val.name}
            className="service-image"
            style={{
              backgroundImage: "url(" + blob + ")",
            }}
          />
        ) : val.id.toLowerCase() !== "custom" ? (
          <div
            id={val.id}
            className="service-default-image-div"
            style={{ height: 70, minWidth: 70 }}
          >
            <center>
              <Avatar
                className="service-template-default-image"
                size="large"
                title={val.name}
              >
                {val.name}
              </Avatar>
            </center>
          </div>
        ) : (
          val.id.toLowerCase() === "custom" && (
            <div title={val.name}>
              <img src={customImg} className="service-custom-icon" />
            </div>
          )
        )}

        <div
          className={
            !blob && val.id.toLowerCase() != "custom"
              ? "service_unknown_text"
              : "service_text"
          }
          title={val.name}
        >
          {val.name}
        </div>
      </div>
    );
  };

  renderTemplateImage = () => {
    return this.state.imageIcons.map(icon => {
      return icon;
    });
  };

  render() {
    return (
      <div>
        <Card
          title={getCurrentlocaleText("add.service")}
          extra={
            (this.props.AuthStore.IsPermitted("ORG:SUPPORT") ||
              this.props.AuthStore.IsPermitted("SUPER:ADMIN")) &&
            this.props.AuthStore.isLoggedInUserOrgIsRoot() && (
              <Button
                type={this.state.isScanning ? "" : "primary"}
                icon={<Icons type="ai" name="AiOutlineSync" />}
                title={
                  this.state.isScanning
                    ? getCurrentlocaleText("sync.in.progress")
                    : getCurrentlocaleText("sync.to.s3")
                }
                onClick={this.scanS3}
                loading={this.state.isScanning ? true : false}
              >
                Rescan
              </Button>
            )
          }
          style={{
            minHeight: "200px",
          }}
        >
          <Row
            style={{
              maxHeight: "400px",
              minWidth: 800,
              overflowY: "scroll",
              height: "200px",
            }}
          >
            <Col span={24}>
              <LoadingComponent loading={this.state.fetchingTemplates}>
                <div className="service-card-wrapper">
                  {this.renderTemplateImage()}
                </div>
              </LoadingComponent>
            </Col>
          </Row>
          {(this.props.AuthStore.IsPermitted("ORG:SUPPORT") ||
            this.props.AuthStore.IsPermitted("SUPER:ADMIN")) && (
            <Row style={{ marginTop: 10 }}>
              <Col span={23}>
                <FormErrorMessage />
              </Col>
            </Row>
          )}
        </Card>
      </div>
    );
  }
}
