import React, { Component } from "react";
import { Layout } from "antd";
import IoMenu from "./Menu";
import { observer, inject } from "mobx-react";
//Logo
import LightLogo from "components/UI-Components/LightLogo";
import FaviconLogo from "components/UI-Components/FaviconLogo";

const { Sider } = Layout;

@inject("UiStore")
@observer
class IoSidebar extends Component {
  constructor(props) {
    super(props);
    // GLOBAL_SETTINGS configured from the node js
    this.GLOBAL_SETTINGS = GLOBAL_SETTINGS;
    this.state = {
      isCollapsed: null,
    };
  }

  render() {
    return (
      <Sider
        collapsible={true}
        width={195}
        collapsed={this.props.UiStore.isSiderFold}
        theme={
          this.GLOBAL_SETTINGS && this.GLOBAL_SETTINGS.sidebar_mode
            ? this.GLOBAL_SETTINGS.sidebar_mode
            : "dark"
        }
        trigger={null}
        style={{ overflow: "hidden" }}
      >
        <div />
        <div
          className="logo"
          style={{
            cursor: this.GLOBAL_SETTINGS
              ? !this.GLOBAL_SETTINGS.is_default
                ? "default"
                : "pointer"
              : "pointer",
          }}
        >
          {this.props.UiStore.isSiderFold ? <FaviconLogo /> : <LightLogo />}
        </div>
        <IoMenu
          ref={c => {
            this.iomenu = c;
          }}
          collapsed={this.state.isCollapsed}
        />
      </Sider>
    );
  }
}

export default IoSidebar;
