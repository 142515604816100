import React, { Component } from "react";
import { Layout } from "antd";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
//Stores Provider
import { Provider } from "mobx-react";
import Stores from "Stores";
import ViewModels from "view-model";
import { isChrome } from "Core/Utils";
import MainLayout from "components/layout/MainLayout";
import LoginLayout from "components/layout/LoginLayout";

import TwoFactorSetupLayout from "components/layout/TwoFactorSetupLayout";
import ChangePasswordLayout from "components/layout/ChangePasswordLayout";
import { defineMessages, IntlProvider, addLocaleData } from "react-intl";
import ResetPassword from "./components/Containers/ResetPassword";
import ForgotPassword from "./components/Containers/ForgotPassword";
import VerifyEmail from "./components/Containers/VerifyEmail";

import ReactGA from "react-ga4";

// this defines the supported locale messages
const MessageData = require("assets/messages/messages.json");
// language  selection
let language =
  (window.navigator.languages && window.navigator.languages[0]) ||
  window.navigator.language ||
  window.navigator.userLanguage;

let languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

let localeMessages = {};
let messages = {};

class App extends Component {
  constructor(props) {
    super(props);
    // GLOBAL_SETTINGS is configured form node js
    this.GLOBAL_SETTINGS = GLOBAL_SETTINGS;
    this.state = {
      collapsed: false,
      mode: "inline",
      isNotChrome: false,
    };
    // Set Dynamic theme
    if (this.GLOBAL_SETTINGS) {
      this.applyTheme(this.GLOBAL_SETTINGS);
    }
  }

  onCollapse = collapsed => {
    this.setState({
      collapsed,
      mode: collapsed ? "vertical" : "inline",
    });
  };

  UNSAFE_componentWillMount() {
    // To set login success url in the session storage
    const trackingId =
      this.GLOBAL_SETTINGS &&
      this.GLOBAL_SETTINGS.settings &&
      this.GLOBAL_SETTINGS.settings.analytics &&
      this.GLOBAL_SETTINGS.settings.analytics.ga4 &&
      this.GLOBAL_SETTINGS.settings.analytics.ga4.id
        ? this.GLOBAL_SETTINGS.settings.analytics.ga4.id
        : null;
    const base_fqdn =
      this.GLOBAL_SETTINGS && this.GLOBAL_SETTINGS.base_fqdn
        ? this.GLOBAL_SETTINGS.base_fqdn
        : window.location.hostname;
    if (trackingId && trackingId.trim().length > 0) {
      ReactGA.initialize([
        {
          trackingId: trackingId,
          gaOptions: {
            cookieDomain: base_fqdn,
          },
        },
      ]);
    }
    if (window.sessionStorage) {
      let redirectUrl = "/";
      let path = window.location.pathname;
    }
    var chrome = isChrome();
    if (!chrome) {
      this.setState({ isNotChrome: true });
    }
  }

  applyTheme = (settings = null) => {
    try {
      if (window.less) {
        let themingVars = {};
        if (settings) {
          if (settings.primary_color) {
            themingVars["@primary-color"] = settings.primary_color;
          }
          if (settings.text_color) {
            themingVars["@text-color"] = settings.text_color;
            themingVars["@heading-color"] = settings.text_color;
          }
        }
        window.less
          .modifyVars(themingVars)
          .then(() => {
            console.log("Update dynamic theme successfully.");
          })
          .catch(error => {
            console.error("Failed to update dynamic theme.");
            console.error(error);
          });
      } else {
        console.error("window less is undefined.");
      }
    } catch (e) {
      console.error(e);
    }
  };
  componentDidMount() {
    var chrome = isChrome();
    if (!chrome) {
      this.setState({ isNotChrome: true });
    }
  }
  render() {
    return (
      <IntlProvider locale={"en"} messages={MessageData}>
        <Router>
          <Provider {...Stores} {...ViewModels}>
            <Switch>
              <Route exact path="/login" component={LoginLayout} />
              <Route
                exact
                path="/passwordexpired/:id"
                render={props => {
                  return (
                    <ChangePasswordLayout
                      isNotChrome={this.state.isNotChrome}
                      {...this.props}
                    />
                  );
                }}
              />
              <Route
                exact
                path="/twofactorsetup/:id"
                render={props => {
                  return (
                    <TwoFactorSetupLayout
                      isNotChrome={this.state.isNotChrome}
                      {...this.props}
                    />
                  );
                }}
              />
              <Route
                exact
                path="/mailer/reset-password/:token"
                render={props => {
                  return (
                    <ResetPassword
                      isNotChrome={this.state.isNotChrome}
                      {...props}
                    />
                  );
                }}
              />

              <Route
                exact
                path="/mailer/verify-email/:token"
                render={props => {
                  return (
                    <VerifyEmail
                      isNotChrome={this.state.isNotChrome}
                      {...props}
                    />
                  );
                }}
              />

              <Route
                exact
                path="/forgotpassword"
                render={props => {
                  return (
                    <ForgotPassword
                      isNotChrome={this.state.isNotChrome}
                      {...props}
                    />
                  );
                }}
              />
              <Route
                path="/*"
                render={props => {
                  return (
                    <MainLayout
                      isNotChrome={this.state.isNotChrome}
                      {...props}
                    />
                  );
                }}
              />
            </Switch>
          </Provider>
        </Router>
      </IntlProvider>
    );
  }
}

export default App;
