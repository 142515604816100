import React, { Component } from "react";
import { Card, Row, Col, Select, Button, Popover, Checkbox } from "antd";
import { observer, inject } from "mobx-react";
import {
  highlightSearchText,
  getCurrentlocaleText,
  toTimezone,
  getDefaultTimezone,
} from "Core/Utils";
import Icons from "components/UI-Components/Icons";
import SearchInput from "components/UI-Components/SearchInput";
import TimezoneComponent from "../Components/TimezoneComponent";

const Option = Select.Option;

@inject("LogViewModel", "TimezoneViewModel")
@observer
export default class LogsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onScroll: false,
      isInverted: false,
      containerId: this.props.defaultContainer,
      searchText: "",
      isAutoScroll: false,
      triggerAutoScrollBottom: this.props.triggerAutoScrollBottom,
      triggerAutoScrollTop: this.props.triggerAutoScrollTop,
      lastScrollPosition: 0,
    };
    this.lastIndex = null;
    this.LogViewModel = this.props.LogViewModel;
  }

  UNSAFE_componentWillMount() {
    this.LogViewModel.resetSelectedTimeZone();
    this.LogViewModel.showPreviousLogs = false;
  }
  componentWillUnmount() {
    this.LogViewModel.showPreviousLogs = false;
  }

  /**
   *  Change console view to black/white
   */
  changeConsoleView = () => {
    this.setState({ isInverted: !this.state.isInverted });
  };

  /**
   *
   * @param {*} newprops- updated props will be recieved to fill the latest selected container ID
   */
  UNSAFE_componentWillReceiveProps(newprops) {
    if (this.props.defaultContainer != newprops.defaultContainer) {
      this.setState({ containerId: newprops.defaultContainer });
    }
    if (
      this.props.triggerAutoScrollBottom != newprops.triggerAutoScrollBottom &&
      newprops.triggerAutoScrollBottom
    ) {
      this.setState({ isAutoScroll: true });
      this.scrollToBottom();
    }
    if (
      this.props.triggerAutoScrollTop != newprops.triggerAutoScrollTop &&
      newprops.triggerAutoScrollTop
    ) {
      this.setState({ isAutoScroll: true });
      this.scrollToTop();
    }
    if (this.state.lastScrollPosition > 0) {
      this.scrollToPreviousPointer();
    }
    if (
      this.props.isAutoloaded != newprops.isAutoloaded &&
      newprops.isAutoloaded
    ) {
      this.setState({ isAutoScroll: true });
    } else if (
      this.props.isAutoloaded != newprops.isAutoloaded &&
      !newprops.isAutoloaded
    ) {
      this.setState({ isAutoScroll: false });
    }
  }
  /**
   * Initiate download request
   */
  downLoadRequest = () => {
    this.props.downLoadRequest();
  };

  componentDidMount() {
    document.onmousemove = () => {
      this.props.resetAutoScroll();
    };
    document.onmousewheel = () => {
      this.props.resetAutoScroll();
    };
    this.LogViewModel.showPreviousLogs = false;
  }

  renderLogs = () => {
    if (this.divRef && this.divRef.style)
      this.divRef.style.display = "inline-flex";
    let sortedContent = this.sortContent();
    if (sortedContent && sortedContent.length == 0) {
      return (
        <div
          style={{
            opacity: 1,
            display: "flex",
            justifyContent: "center",
            fontFamily: "monospace",
          }}
        >
          {getCurrentlocaleText("logs.no_reponse.message")}
        </div>
      );
    }

    let DOM = sortedContent.map((val, index) => {
      let isEven = index % 2 == 0 ? true : false;
      let showLogs = (
        <Row
          key={index}
          style={{
            backgroundColor: !this.state.isInverted
              ? isEven
                ? "#f3f3f3"
                : "white"
              : "",
            fontFamily: "monospace",
          }}
        >
          {val.timestamp && (
            <Col xl={4} lg={5} md={6} style={{ maxWidth: "200px" }}>
              <span>
                {this.state.searchText.trim().length > 0
                  ? highlightSearchText(
                      toTimezone(
                        val.timestamp,
                        this.LogViewModel.selectedTimezone,
                      ),
                      this.state.searchText,
                    )
                  : toTimezone(
                      val.timestamp,
                      this.LogViewModel.selectedTimezone,
                    )}
              </span>
            </Col>
          )}
          {val.content && (
            <Col xl={20} lg={19} md={18}>
              <span>
                {this.state.searchText.trim().length > 0
                  ? highlightSearchText(val.content, this.state.searchText)
                  : val.content}
              </span>
            </Col>
          )}
        </Row>
      );
      return showLogs;
    });

    return DOM;
  };

  /**
   *  Container selector view
   */
  renderContainers = () => {
    if (this.props.containers) {
      return this.props.containers.map(container => {
        return (
          <Option key={container.id} value={container.id}>
            {container.name}
          </Option>
        );
      });
    }
  };
  /**
   *  Container selector change method
   */
  handleContainerChange = value => {
    if (this.props.onContainerChange) {
      this.props.onContainerChange(value);
    }
  };

  scrollFunction = e => {
    // container component
    let containerDiv = document.getElementsByClassName("_IOT_LOG_CONTAINER");
    // New Log button
    let newLogButton = document.getElementsByClassName("_IOT_LOG_NEW_BUTTON");
    // Old Log button
    let oldLogButton = document.getElementsByClassName("_IOT_LOG_OLD_BUTTON");
    if (containerDiv && containerDiv[0] && containerDiv[0].scrollTop > 300) {
      if (
        Math.round(containerDiv[0].scrollTop + containerDiv[0].offsetHeight) >=
          containerDiv[0].scrollHeight &&
        !this.state.isAutoScroll
      ) {
        // this delay will prevent hitting the scroll event again
        setTimeout(() => {
          this.props.collectForwardLogs();
        }, 300);
      }
      if (newLogButton && newLogButton[0] && oldLogButton && oldLogButton[0]) {
        newLogButton[0].style.display = "block";
        oldLogButton[0].style.display = "none";
      }
    } else if (
      containerDiv &&
      containerDiv[0] &&
      containerDiv[0].scrollTop <= 300
    ) {
      if (containerDiv[0].scrollTop <= 0 && !this.state.isAutoScroll) {
        this.setState({ lastScrollPosition: containerDiv[0].scrollHeight });
        this.props.collectPreviousLogs();
      }
      if (newLogButton && newLogButton[0] && oldLogButton && oldLogButton[0]) {
        newLogButton[0].style.display = "none";
        oldLogButton[0].style.display = "block";
      }
    } else {
      if (newLogButton && newLogButton[0] && oldLogButton && oldLogButton[0]) {
        newLogButton[0].style.display = "none";
        oldLogButton[0].style.display = "none";
      }
    }
  };

  logSearch = e => {
    this.setState({ searchText: e.target.value });
  };

  clearSearch = () => {
    this.setState({ searchText: "" });
  };

  searchContent = () => {
    return this.state.searchText.trim().length > 0
      ? this.LogViewModel.LogInfo.filter(val => {
          if (
            val.content
              .toLowerCase()
              .indexOf(this.state.searchText.toLowerCase()) !== -1 ||
            toTimezone(val.timestamp, this.LogViewModel.selectedTimezone)
              .toLowerCase()
              .indexOf(this.state.searchText.toLowerCase()) !== -1
          ) {
            return val;
          }
        })
      : this.LogViewModel.LogInfo;
  };

  sortContent = () => {
    let data = this.searchContent();
    if (data) {
      return this.state.searchText.trim().length > 0
        ? data.sort((x, y) => {
            return Date.parse(x.time) - Date.parse(y.time);
          })
        : data.sort((x, y) => {
            return Date.parse(y.time) - Date.parse(x.time);
          });
    }
  };

  scrollToBottom = () => {
    let containerDiv = document.getElementsByClassName("_IOT_LOG_CONTAINER");

    if (containerDiv && containerDiv[0]) {
      containerDiv[0].scrollTo(0, containerDiv[0].scrollHeight);
      setTimeout(() => {
        this.setState({ isAutoScroll: false });
        this.props.resetScrollState();
      }, 1000);
    }
  };
  scrollToTop = () => {
    let containerDiv = document.getElementsByClassName("_IOT_LOG_CONTAINER");
    if (containerDiv && containerDiv[0]) {
      containerDiv[0].scrollTo(0, 0);
      setTimeout(() => {
        this.setState({ isAutoScroll: false });
        this.props.resetScrollState();
      }, 1000);
    }
  };

  scrollToPreviousPointer = () => {
    let containerDiv = document.getElementsByClassName("_IOT_LOG_CONTAINER");
    if (containerDiv && containerDiv[0]) {
      containerDiv[0].scrollTo(
        0,
        containerDiv[0].scrollHeight - this.state.lastScrollPosition > 100
          ? containerDiv[0].scrollHeight - this.state.lastScrollPosition
          : this.state.lastScrollPosition / 2,
      );
      setTimeout(() => {
        this.setState({ lastScrollPosition: 0 });
      }, 1000);
    }
  };

  updateTimestamp = value => {
    this.LogViewModel.setSelectedTimeZone(value);
  };
  /**
   *  Set/Unset show previous falg on query params
   */
  changePrevious = e => {
    this.LogViewModel.showPreviousLogs = e.target.checked;
    this.props.refreshLog(true);
  };

  render() {
    const { isInverted } = this.state;
    return (
      <div>
        <Card
          style={{ Width: "100%", height: "80%" }}
          title={
            <span className="iot-main-card-title">
              <Icons type="ai" name="AiOutlineFileText" />
              <span>
                {" "}
                {this.props.type === "inode"
                  ? getCurrentlocaleText("inode.logs")
                  : getCurrentlocaleText("service.logs")}
              </span>
              {this.props.type === "service" && (
                <Popover
                  content={getCurrentlocaleText(
                    "logs.service.show.previous.info.message",
                  )}
                  placement="top"
                  trigger="hover"
                >
                  <span className="iot-service-show-prev-checkbox">
                    <Checkbox
                      checked={this.LogViewModel.showPreviousLogs}
                      onChange={this.changePrevious}
                    >
                      {getCurrentlocaleText("logs.service.show.previous.label")}
                    </Checkbox>
                  </span>
                </Popover>
              )}
            </span>
          }
          extra={
            <Row gutter={2}>
              {this.props.type === "service" && (
                <Col span={7} style={{ marginRight: 5 }}>
                  <Popover
                    content={getCurrentlocaleText(
                      "log-filter-by-container-name-msg",
                    )}
                    placement="top"
                    trigger="hover"
                  >
                    <span>
                      <Select
                        value={this.state.containerId}
                        onChange={this.handleContainerChange}
                        className={"log_selectbox_base_style"}
                        notFoundContent={getCurrentlocaleText(
                          "general.notfound.placeholder.text",
                        )}
                      >
                        {this.renderContainers()}
                      </Select>
                    </span>
                  </Popover>
                </Col>
              )}
              <Col
                span={this.props.type === "service" ? 7 : 11}
                style={{ marginRight: 5 }}
              >
                <Popover
                  content={getCurrentlocaleText("logs.filter.by.text")}
                  placement="top"
                  trigger="hover"
                >
                  <span>
                    <SearchInput
                      placeholder={getCurrentlocaleText("logs.filter.by.text")}
                      onChange={this.logSearch}
                      value={this.state.searchText}
                      className={"log_selectbox_base_style"}
                      clearSearch={this.clearSearch}
                      disabled={
                        this.LogViewModel.LogInfo.length > 0 ? false : true
                      }
                    />
                  </span>
                </Popover>
              </Col>
              <Col
                span={this.props.type === "service" ? 7 : 11}
                style={{ marginRight: 5 }}
              >
                <TimezoneComponent
                  selectedValue={this.updateTimestamp}
                  placeholder={getCurrentlocaleText(
                    "log-filter-by-select-timezone-msg",
                  )}
                  disabled={
                    this.LogViewModel.LogInfo.length.length === 0 ? true : false
                  }
                  showPopOver={true}
                  initialValue={
                    this.props.TimezoneViewModel.userTimezone ||
                    this.props.TimezoneViewModel.orgTimezone ||
                    getDefaultTimezone().value
                  }
                  className={"log_selectbox_base_style"}
                />
              </Col>
              <Col span={1}>
                <Popover
                  content={getCurrentlocaleText("log-view-toggle-mode")}
                  placement="top"
                  trigger="hover"
                >
                  <span onClick={this.changeConsoleView}>
                    <Button
                      style={{
                        backgroundColor: !isInverted ? "black" : "",
                        color: !isInverted ? "white" : "",
                      }}
                      className={
                        !isInverted
                          ? "log-tgle-mode-dark"
                          : "log-tgle-mode-light"
                      }
                      size={"small"}
                      shape={"circle"}
                    >
                      <Icons
                        type="md"
                        style={{ marginBottom: 1 }}
                        name={isInverted ? "MdBrightness5" : "MdBrightness2"}
                      />
                    </Button>
                  </span>
                </Popover>
              </Col>
            </Row>
          }
          bodyStyle={{
            padding: "1px 1px 2px 15px",
            fontSize: 12,
            backgroundColor: isInverted ? "black" : "",
            color: isInverted ? "white" : "",
          }}
        >
          <div
            style={{
              maxHeight: screen.width < 1500 ? 400 : 700,
            }}
            onScroll={this.scrollFunction}
            className={"_IOT_LOG_CONTAINER log-base-screen"}
          >
            <Popover
              content={"Get oldest logs"}
              placement="bottom"
              trigger="hover"
            >
              <Button
                type="primary"
                onClick={() => {
                  this.setState({ isAutoScroll: true });
                  this.props.collectOldLogs(true);
                }}
                className={"_IOT_LOG_OLD_BUTTON log-oldest-btn"}
                icon={<Icons type="ai" name="AiOutlineVerticalLeft" />}
              />
            </Popover>
            {this.renderLogs()}
            <Popover content={"Get newest logs"} trigger="hover">
              <Button
                onClick={() => {
                  this.setState({ isAutoScroll: true });
                  this.props.collectNewLogs(true);
                }}
                type="primary"
                className={"_IOT_LOG_NEW_BUTTON log-newest-btn"}
                shape="circle"
                icon={<Icons type="ai" name="AiOutlineVerticalRight" />}
              />
            </Popover>
          </div>
          <Row className="log-footer-style">
            <Col span={14} style={{ marginTop: 7 }}>
              {/* {this.LogViewModel.getFromToLogDate &&
                this.LogViewModel.getFromToLogDate.fromDate &&
                this.LogViewModel.getFromToLogDate.toDate &&
                <strong>
                  {getCurrentlocaleText("logs.from_to.index.label.message", {
                    0: toTimezone(
                      this.LogViewModel.getFromToLogDate.fromDate,
                      this.LogViewModel.selectedTimezone,
                    ),
                    1: toTimezone(
                      this.LogViewModel.getFromToLogDate.toDate,
                      this.LogViewModel.selectedTimezone,
                    ),
                  })}
                </strong>} */}
            </Col>
            <Col span={10} className="log-footer-btn-grp-style">
              <Button
                onClick={this.props.onCancel}
                className="log-footer-btn-style"
                key="cancel"
              >
                {getCurrentlocaleText("cancel.button.text")}
              </Button>
              <Button
                onClick={this.props.downLoadRequest}
                className="log-footer-btn-style"
                key="download"
              >
                {getCurrentlocaleText("log-download-btn-label")}
              </Button>
              <Button
                type="primary"
                style={{ fontWeight: "bold" }}
                onClick={() => {
                  this.setState({ isAutoScroll: true });
                  this.props.collectNewLogs(true);
                }}
              >
                {getCurrentlocaleText("log-refersh-btn-label")}
              </Button>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}
