import React, { Component } from "react";
import { Card, Row, Col } from "antd";
import { observer, inject } from "mobx-react";
import axios from "axios";

import ResetPasswordForm from "components/Containers/ResetPasswordForm";

import MessageDisplay from "Core/MessageDisplay";
import { getCurrentlocaleText } from "Core/Utils";
import LoadingComponent from "components/UI-Components/LoadingComponent";

import DarkAppInfo from "components/UI-Components/DarkAppInfo";

import IoFooter from "components/layout//Footer";
import Icons from "../UI-Components/Icons";
@inject("UiStore")
@observer
class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isrequestTriggered: false,
      disableSubmit: false,
      isRequestSuccess: false,
      isRequestFailed: false,
      failureMessage: getCurrentlocaleText(
        "user.reset_password_form.info_message2",
      ),
      isLinkExpired: false,
      loading: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({ loading: true });
    this.ResetPassword({}, true);
  }

  //Reset password api call
  ResetPassword = (formData, intial = false, recaptchaRef = null) => {
    this.setState({ disableSubmit: true, isRequestFailed: false });
    let token =
      this.props &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.token
        ? this.props.match.params.token
        : null;
    if (token) {
      let loginReq = axios.post(
        "/api/v1/setnewpassword?temp_token=" + token,
        formData,
      );
      loginReq
        .then(response => {
          this.props.UiStore.errorMessage = null;
          if (this.props.history) {
            setTimeout(() => {
              this.props.history.push("/login");
              this.setState({ loading: false });
              this.props.UiStore.successMessage = getCurrentlocaleText(
                "user.reset_password_form.success_message",
              );
              this.props.UiStore.isForceMessage = true;
            }, 1000);
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            if (this.props.history) {
              setTimeout(() => {
                this.props.history.push("/forgotpassword");
                this.setState({ loading: false });
                this.props.UiStore.errorMessage = getCurrentlocaleText(
                  "user.reset_password_form.info_message2",
                );
                this.props.UiStore.isForceMessage = true;
              }, 1000);
            }
          } else if (!intial) {
            this.setState({
              isrequestTriggered: false,
              isRequestFailed: true,
              isRequestSuccess: false,
              disableSubmit: false,
              isLinkExpired: false,
              loading: false,
              failureMessage:
                error.response &&
                error.response.data &&
                error.response.data.message
                  ? error.response.data.message
                  : getCurrentlocaleText(
                      "user.reset_password_form.info_message2",
                    ),
            });

            this.props.UiStore.errorMessage =
              error.response &&
              error.response.data &&
              error.response.data.message
                ? error.response.data.message
                : getCurrentlocaleText(
                    "user.reset_password_form.info_message2",
                  );
            this.props.UiStore.isForceMessage = false;
          } else {
            this.setState({ loading: false });
          }
          if (this.resetPasswordForm) {
            try {
              this.resetPasswordForm.resetFields(["captcha"]);
            } catch (e) {}
          }
          if (recaptchaRef) {
            try {
              recaptchaRef.reset();
            } catch (e) {}
          }
        });
    }
  };

  redirectLoginScreen = () => {
    if (this.props.history) {
      this.props.history.push("/login");
    }
  };
  renderChild = () => {
    let {
      isrequestTriggered,
      disableSubmit,
      isRequestSuccess,
      isRequestFailed,
      failureMessage,
      isLinkExpired,
    } = this.state;
    return (
      <div>
        <MessageDisplay />
        <Row type="flex" className="login-card" align="middle" justify="center">
          <Col
            className="login-col"
            lg={{ span: 7 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            xs={{ span: 16 }}
          >
            <div style={{ paddingTop: 10, backgroundColor: "white" }}>
              <center>
                <DarkAppInfo />
              </center>
            </div>
            <Card bordered={false}>
              <center>
                {isrequestTriggered &&
                  (isRequestSuccess ? (
                    <Icons
                      type="ai"
                      style={{ fontSize: 40, color: "green" }}
                      name="AiOutlineCheckCircle"
                    />
                  ) : isLinkExpired ? (
                    <Icons
                      type="ai"
                      style={{ fontSize: 40, color: "red" }}
                      name="AiOutlineDisconnect"
                    />
                  ) : (
                    <Icons
                      type="ai"
                      style={{ fontSize: 40, color: "red" }}
                      name="AiOutlineCloseCircle"
                    />
                  ))}
                <h1
                  style={{
                    paddingTop: 20,
                    paddingBottom: 20,
                    color: isRequestSuccess ? "green" : "",
                    fontSize: 15,
                  }}
                >
                  {!isrequestTriggered
                    ? getCurrentlocaleText(
                        "user.reset_password_form.initial_message",
                      )
                    : isRequestSuccess
                    ? getCurrentlocaleText(
                        "user.reset_password_form.success_message",
                      )
                    : failureMessage}
                </h1>
                {!isrequestTriggered && (
                  <ResetPasswordForm
                    ResetPassword={this.ResetPassword}
                    disable={disableSubmit}
                    autoFocus={isRequestFailed && !isLinkExpired ? true : false}
                    ref={c => (this.resetPasswordForm = c)}
                  />
                )}
              </center>
            </Card>
          </Col>
        </Row>
        <IoFooter
          centerAlignFooter={true}
          isNotChrome={this.props.isNotChrome}
        />
      </div>
    );
  };

  render() {
    return (
      <div className="iot-reset-layout">
        <LoadingComponent loading={this.state.loading}>
          {this.renderChild()}
        </LoadingComponent>
      </div>
    );
  }
}
export default ResetPassword;
