import React, { Component } from "react";
import { Button } from "antd";
import propTypes from "prop-types";
import { observer, inject } from "mobx-react";
import { getCurrentlocaleText, copyObject } from "Core/Utils";
import Icons from "components/UI-Components/Icons";
import * as ip from "ip";
import { Input } from "antd";
import InputBox from "components/UI-Components/InputBox";

const InputGroup = Input.Group;
const MaxAllowedDNS = 3;

@inject("UiStore")
@observer
class DNSinputBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      DnsList:
        this.props.defaultDNSValue && this.props.defaultDNSValue.length > 0
          ? this.props.defaultDNSValue
          : [null],
      errorList: [],
      repeatedList: [],
    };
  }

  componentDidMount() {
    /* Focus First Input */
    if (!this.props.defaultDNSValue || this.props.defaultDNSValue.length <= 0) {
      setTimeout(() => {
        this.focusDnsInput(0);
      }, 100);
    }
  }

  focusDnsInput = (index = 0) => {
    if (index !== -1) {
      try {
        let dnsFieldInputs = document.getElementsByClassName("dns-field-style");
        if (dnsFieldInputs && dnsFieldInputs.length > 0) {
          dnsFieldInputs[index].focus();
        }
      } catch (e) {}
    }
  };

  onAddField = () => {
    let addedCount = this.getDnsList();
    if (addedCount < MaxAllowedDNS) {
      let DnsList = this.state.DnsList;
      DnsList.push(null);
      this.setState({ DnsList: DnsList });
      let filteredDns = DnsList.filter(function(el) {
        return el != null;
      });
      this.props.onChange(filteredDns);
      // Add focus to dns input
      setTimeout(() => {
        this.focusDnsInput(DnsList.length - 1);
      }, 100);
    }
  };

  getDnsList = () => {
    let dnsList = copyObject(this.state.DnsList);
    let length = dnsList.length;
    return length;
  };

  onRemoveField = index => {
    let errorList = this.state.errorList,
      repeatedList = this.state.repeatedList,
      DnsList = copyObject(this.state.DnsList);

    let errorKey = errorList.indexOf(index);
    if (errorKey > -1) {
      errorList.splice(errorKey, 1);
      this.setState({ errorList: errorList });
    }
    let repeatedKey = repeatedList.indexOf(index);
    if (repeatedKey > -1) {
      repeatedList.splice(repeatedKey, 1);
      this.setState({ repeatedList: repeatedList });
    }
    DnsList.splice(index, 1);
    this.setState({ DnsList: DnsList });
    this.props.onError({ errorList: errorList, repeatedList: repeatedList });
    let filteredDns = DnsList.filter(function(el) {
      return el != null;
    });
    this.props.onChange(filteredDns);
    // Add focus to dns input
    setTimeout(() => {
      this.focusDnsInput(DnsList.length - 1);
    }, 100);
  };

  checkForValidDNS = (value, index) => {
    let failMessage = null;
    let errorList = this.state.errorList,
      DnsList = copyObject(this.state.DnsList),
      repeatedList = this.state.repeatedList;
    value = value.toString();
    if ((value && ip.isV4Format(value) !== true) || value.length <= 0) {
      failMessage = getCurrentlocaleText(
        "network.form.startip.errormessage1.text",
      );
      if (!errorList.includes(index)) {
        errorList.push(index);
      }
      DnsList[index] = value;
      this.setState({ DnsList: DnsList });
    } else if (
      DnsList.length > 0 &&
      DnsList.indexOf(value) != -1 &&
      DnsList.indexOf(value) != index
    ) {
      failMessage = "Repeated IP";
      if (!repeatedList.includes(index)) {
        repeatedList.push(index);
      }
      DnsList[index] = value;
      this.setState({ DnsList: DnsList });
      var key = errorList.indexOf(index);
      if (key > -1) {
        errorList.splice(key, 1);
      }
    } else {
      var key = errorList.indexOf(index);
      if (key > -1) {
        errorList.splice(key, 1);
      }
      var rkey = repeatedList.indexOf(index);
      if (rkey > -1) {
        repeatedList.splice(rkey, 1);
      }
      DnsList.splice(index, 1, value);
      this.setState({ DnsList: DnsList });
    }
    if (errorList.length <= 0 && repeatedList.length <= 0) {
      this.setState({
        errorList: errorList,
        DnsList: DnsList,
        repeatedList: repeatedList,
      });
    }
    this.props.onError({ errorList: errorList, repeatedList: repeatedList });
    this.props.onChange(DnsList);
  };

  render() {
    let dnsLen = this.getDnsList();
    return (
      <InputGroup>
        <InputBox
          autoComplete="dontshow"
          defaultValue={
            this.state.DnsList.length > 0 && this.state.DnsList[0]
              ? this.state.DnsList[0]
              : undefined
          }
          disabled={this.props.isDisabled}
          className={
            dnsLen <= 1
              ? "dns-field-style dns-field-with-bottom-border"
              : "dns-field-style dns-field-with-no-bottom-border"
          }
          onChange={e => {
            let value = e.target.value;
            this.checkForValidDNS(value, 0);
          }}
          tabIndex="1"
          onFocus={this.props.onFocus}
        />
        {!this.props.isDisabled && (
          <Button
            className="no-border light-gray-45"
            onClick={this.onAddField}
            disabled={dnsLen >= MaxAllowedDNS}
            title={"Add"}
          >
            <Icons
              type="ai"
              name="AiOutlinePlusSquare"
              className="valign-sub middle"
              style={{ fontSize: 20 }}
            />
          </Button>
        )}
        {(this.state.errorList.includes(0) ||
          this.state.repeatedList.includes(0)) && (
          <p
            style={{
              color: "red",
              display: "inline-block",
              minWidth: "20%",
            }}
          >
            {this.state.errorList.includes(0)
              ? getCurrentlocaleText("network.form.startip.errormessage1.text")
              : getCurrentlocaleText("services.dns.unique.error.message")}
          </p>
        )}
        {dnsLen > 0 && dnsLen > 1 && (
          <span>
            <InputBox
              autoComplete="dontshow"
              defaultValue={
                this.state.DnsList.length > 0 && this.state.DnsList[1]
                  ? this.state.DnsList[1]
                  : undefined
              }
              value={
                this.state.DnsList.length > 0 && this.state.DnsList[1]
                  ? this.state.DnsList[1]
                  : undefined
              }
              disabled={this.props.isDisabled}
              className={
                dnsLen === 3
                  ? "dns-field-style dns-with-no-top-border dns-field-with-no-bottom-border"
                  : dnsLen === 2 ||
                    this.state.errorList.includes(1) ||
                    this.state.repeatedList.includes(1)
                  ? "dns-field-style dns-with-no-top-border dns-field-with-bottom-border"
                  : "dns-field-style dns-with-no-top-border dns-field-with-no-bottom-border"
              }
              onChange={e => {
                let value = e.target.value;
                this.checkForValidDNS(value, 1);
              }}
              tabIndex="2"
              onFocus={this.props.onFocus}
            />
            {!this.props.isDisabled && (
              <Button
                className="no-border light-gray-45"
                shape="circle"
                onClick={() => {
                  this.onRemoveField(1);
                }}
                style={{
                  float: "left",
                  display: "inline-block",
                  marginLeft: 10,
                  marginRight: 10,
                }}
                title={"Remove"}
              >
                <Icons
                  type="ai"
                  name="AiOutlineMinusCircle"
                  className="valign-sub middle"
                  style={{ fontSize: 20 }}
                />
              </Button>
            )}
            {(this.state.errorList.includes(1) ||
              this.state.repeatedList.includes(1)) && (
              <p
                style={{
                  color: "red",
                  display: "inline-block",
                  float: "left",
                  minWidth: "20%",
                }}
              >
                {this.state.errorList.includes(1)
                  ? getCurrentlocaleText(
                      "network.form.startip.errormessage1.text",
                    )
                  : getCurrentlocaleText("services.dns.unique.error.message")}
              </p>
            )}
          </span>
        )}
        {dnsLen > 0 && dnsLen > 2 && (
          <span>
            <InputBox
              autoComplete="dontshow"
              className={
                dnsLen === 3
                  ? "dns-field-style dns-with-no-top-border dns-margin-bottom dns-field-with-bottom-border"
                  : "dns-field-style dns-with-no-top-border dns-margin-bottom dns-field-with-no-bottom-border"
              }
              defaultValue={
                this.state.DnsList.length > 0 && this.state.DnsList[2]
                  ? this.state.DnsList[2]
                  : undefined
              }
              value={
                this.state.DnsList.length > 0 && this.state.DnsList[2]
                  ? this.state.DnsList[2]
                  : undefined
              }
              disabled={this.props.isDisabled}
              onFocus={this.props.onFocus}
              onChange={e => {
                let value = e.target.value;
                this.checkForValidDNS(value, 2);
              }}
              tabIndex="3"
            />
            {!this.props.isDisabled && (
              <Button
                className="no-border light-gray-45"
                shape="circle"
                onClick={() => {
                  this.onRemoveField(2);
                }}
                style={{
                  float: "left",
                  display: "inline-block",
                  marginLeft: 10,
                  marginRight: 10,
                }}
                title={"Remove"}
              >
                <Icons
                  type="ai"
                  name="AiOutlineMinusCircle"
                  className="valign-sub middle"
                  style={{ fontSize: 20 }}
                />
              </Button>
            )}
            {(this.state.errorList.includes(2) ||
              this.state.repeatedList.includes(2)) && (
              <p
                style={{
                  color: "red",
                  display: "inline-block",
                  float: "left",
                  minWidth: "20%",
                }}
              >
                {this.state.errorList.includes(2)
                  ? getCurrentlocaleText(
                      "network.form.startip.errormessage1.text",
                    )
                  : getCurrentlocaleText("services.dns.unique.error.message")}
              </p>
            )}
          </span>
        )}
      </InputGroup>
    );
  }
}

DNSinputBox.propTypes = {
  DnsList: propTypes.array,
};

export default DNSinputBox;
