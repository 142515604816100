import React, { Component } from "react";
import { Card, Row, Col, Avatar } from "antd";
import BreadcrumbComponent from "components/UI-Components/BreadcrumbComponent";
import Icons from "components/UI-Components/Icons";
import UserImage from "../../assets/img/user_img.png";
import { observer, inject } from "mobx-react";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import MyProfileDetails from "components/Containers/MyProfileDetails";
import { getCurrentlocaleText } from "Core/Utils";
import OrgSelector from "components/Orgs/OrgSelector";

import UserController from "controller/UserController";

@inject("UserViewModel", "UiStore", "AuthStore")
@observer
class MyProfile extends Component {
  constructor(props) {
    super(props);
    this.userModel = this.props.UserViewModel;
    this.uistore = this.props.UiStore;
    this.state = {
      BreadcrumbList: [
        { name: getCurrentlocaleText("myprofile.text"), link: "profile" },
      ],
      currentUser: {},
      isMe: true,
    };
  }

  componentDidMount() {
    // Getting user details
    if (this.props.match.params && this.props.match.params.user_id) {
      let user_id = this.props.match.params.user_id;
      UserController.getUser(user_id).then(resp => {
        if (resp) {
          let UpdatedBreadCrumbList = [
            {
              name: resp.organization.name,
              link: "orgs/" + resp.organization.id + "/orgs",
            },
            {
              name: getCurrentlocaleText(
                "general.left_navigation.all_users.text",
              ),
              link: "orgs/" + resp.organization.id + "/users",
            },
            {
              name: resp.name,
              link: "orgs/" + resp.organization.id + "/usersdetails/" + resp.id,
            },
          ];
          this.setState({
            currentUser: resp,
            isMe: false,
            BreadcrumbList: UpdatedBreadCrumbList,
          });
        }
      });
    } else {
      UserController.getCurrentUser().then(resp => {
        if (resp) {
          this.setState({ currentUser: resp });
          this.props.AuthStore.SetStoreData("loggedInUser", resp);
        }
      });
    }
  }
  UpdateUserInfo = user_data => {
    if (user_data) {
      let UpdatedBreadCrumbList = [
        {
          name: user_data.organization.name,
          link: "orgs/" + user_data.organization.id + "/orgs",
        },
        {
          name: getCurrentlocaleText("general.left_navigation.all_users.text"),
          link: "orgs/" + user_data.organization.id + "/users",
        },
        {
          name: user_data.name,
          link:
            "orgs/" +
            user_data.organization.id +
            "/usersdetails/" +
            user_data.id,
        },
      ];
      this.setState({
        currentUser: user_data,
        BreadcrumbList: UpdatedBreadCrumbList,
      });
    }
  };
  render() {
    let user = this.state.currentUser;
    let container = (
      <div>
        <Row gutter={24} type="flex" align="middle">
          <Col lg={{ span: 18 }} xl={{ span: 20 }}>
            <h2 className="page-title">
              <Icons type="ai" name="AiOutlineSolution" />
              {"  "}
              {this.state.isMe
                ? getCurrentlocaleText("myprofile.text")
                : user.name}
            </h2>
            <BreadcrumbComponent
              {...this.props}
              BreadcrumbList={this.state.BreadcrumbList}
            />
          </Col>
          <Col lg={{ span: 6 }} xl={{ span: 4 }} className="flex flex-h-right">
            <OrgSelector {...this.props} />
          </Col>
        </Row>
        <div className="content-page">
          <Card>
            <Row gutter={2}>
              <Col style={{ width: 75, float: "left" }}>
                <div>
                  <Avatar
                    style={{ width: 55, height: 55 }}
                    shape="round"
                    size="large"
                    src={UserImage}
                  />
                </div>
              </Col>
              <Col span={22} style={{ marginLeft: 5 }}>
                <div>
                  <div>
                    <MyProfileDetails
                      UpdateUserInfo={this.UpdateUserInfo}
                      isMe={this.state.isMe}
                      user={user}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    );

    return (
      <LoadingComponent loading={this.userModel.loading}>
        {container}
      </LoadingComponent>
    );
  }
}

export default MyProfile;
