import React, { Component } from "react";
import { Modal } from "antd";
import { getCurrentlocaleText } from "../../Core/Utils";
import EdgeGateway5100 from "../../assets/img/hardwares/Edge_Gateway_5100.svg";
import EdgeGateway5000 from "../../assets/img/hardwares/Edge_Gateway_5000.svg";
import NCA_1510 from "../../assets/img/hardwares/NCA_1510.svg";
import R240 from "../../assets/img/hardwares/R240.svg";
import V2_2271G from "../../assets/img/hardwares/2271G_V2.svg";
import V2_2484G from "../../assets/img/hardwares/2484G_V2.svg";
import V1_2484G from "../../assets/img/hardwares/2484G_V1.svg";

class InterfaceDiagram extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visibility: this.props.visibility,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props != prevProps) {
      this.setState({
        visibility: this.props.visibility,
      });
    }
  }

  onClose = () => {
    this.props.onClose();
  };

  getImage = () => {
    let vendorDetails = this.props.vendorDetails;
    if (vendorDetails) {
      let details = vendorDetails.split("/");
      if (details && details.length > 0) {
        let model = details[1];
        let convertedModel = model.replace(" ", "_");
        switch (convertedModel) {
          case "Dell_Gateway_5100":
            return <EdgeGateway5100 viewBox="0 0 450 120" />;
          case "Dell_Gateway_5000":
            return <EdgeGateway5000 viewBox="0 0 450 120" />;
          case "PUB1245-100^LANNER-CSP1":
          case "PUB5421-010^LANNER-CSP1":
            return <NCA_1510 viewBox="0 0 450 80" />;
          case "PowerEdge_R240":
            return <R240 viewBox="0 0 450 70" />;
          case "UNO-2271G_V2":
            return <V2_2271G viewBox="0 0 230 80" />;
          case "UNO-2484G-734xAE":
            return <V1_2484G viewBox="0 0 450 80" />;
          case "UNO-2484G_V2":
            return <V2_2484G viewBox="0 0 450 80" />;
        }
      }
    }
    return <EdgeGateway5100 viewBox="0 0 450 120" />;
  };

  render() {
    return (
      <Modal
        title={
          <div>
            <span>{getCurrentlocaleText("inode.hardware.diagram")}</span>
            <br />
            <span>{this.props.vendorDetails}</span>
          </div>
        }
        visible={this.state.visibility}
        maskClosable={false}
        footer={null}
        onCancel={this.onClose}
        width={800}
      >
        {this.getImage()}
      </Modal>
    );
  }
}

export default InterfaceDiagram;
