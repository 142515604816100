import React, { Component } from "react";
import { Radio } from "antd";
import { getCurrentlocaleText } from "../../Core/Utils";

const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

class InterfaceTrafficSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      val: "SLOW",
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({
      val: this.props.val,
    });
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (this.props.val !== props.val) {
      this.setState({
        val: props.val,
      });
    }
  }

  handleChange = e => {
    this.setState({
      val: e.target.value,
    });
    if (this.props.onChange) {
      this.props.onChange(e.target.value);
    }
  };

  render() {
    return (
      <div>
        <RadioGroup
          onChange={this.handleChange}
          value={this.state.val}
          disabled={this.props.disabled}
          style={this.props.style}
          defaultValue={"SLOW"}
        >
          <RadioButton value={"SLOW"}>
            {getCurrentlocaleText(
              "inode.metered.connection.metrics.option1.text",
            )}
          </RadioButton>
          <RadioButton value={"OFF"}>
            {getCurrentlocaleText(
              "inode.metered.connection.metrics.option2.text",
            )}
          </RadioButton>
        </RadioGroup>
      </div>
    );
  }
}

export default InterfaceTrafficSelector;
