import React, { Component } from "react";
import propTypes from "prop-types";
import { Select, Popover } from "antd";
import { observer } from "mobx-react";
import {
  getCurrentlocaleText,
  copyObject,
  getAvailableTimezones,
} from "Core/Utils";

const Option = Select.Option;

@observer
export default class TimezoneComponent extends Component {
  constructor(props) {
    super(props);
  }

  updateTimezone = value => {
    this.props.selectedValue(value);
  };

  renderZones = () => {
    let Timezones = copyObject(getAvailableTimezones());
    let defaultTimezone = null;
    if (this.props.defaultTimezone === "browser") {
      defaultTimezone = {
        value: "",
        label: `${getCurrentlocaleText("browser_default_timezone.text")}`,
      };
      Timezones.unshift(defaultTimezone);
    } else if (this.props.defaultTimezone === "org") {
      let in_dx = Timezones.findIndex(tz => {
        return tz.value === this.props.defaultTimezoneValue;
      });
      if (in_dx !== -1) {
        let label = `${Timezones[in_dx].label} (${getCurrentlocaleText(
          "org_default_timezone.text",
        )})`;
        Timezones[in_dx].label = label;
        Timezones[in_dx].value = "";
      } else {
        // Add if org timezone is browser default timezone.
        defaultTimezone = {
          value: "",
          label: `${getCurrentlocaleText("org_default_timezone.text")}`,
        };
        Timezones.unshift(defaultTimezone);
      }
    }
    return Timezones.map(val => {
      return (
        <Option key={val.value} value={val.value} title={val.label}>
          {val.label}
        </Option>
      );
    });
  };

  render() {
    return (
      <span>
        {this.props.showPopOver ? (
          <Popover
            content={getCurrentlocaleText(
              "log-filter-by-select-timezone-help-msg",
            )}
            placement="top"
            trigger="hover"
          >
            <span>
              <Select
                showSearch
                style={this.props.style ? this.props.style : { width: "100%" }}
                className={this.props.className ? this.props.className : ""}
                placeholder={
                  this.props.placeholder
                    ? this.props.placeholder
                    : getCurrentlocaleText("log-filter-by-select-timezone-msg")
                }
                onChange={this.updateTimezone}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                disabled={this.props.disabled}
                defaultValue={this.props.initialValue}
                notFoundContent={getCurrentlocaleText(
                  "general.notfound.placeholder.text",
                )}
              >
                {this.renderZones()}
              </Select>
            </span>
          </Popover>
        ) : (
          <Select
            showSearch
            style={this.props.style ? this.props.style : { width: 240 }}
            className={this.props.className ? this.props.className : ""}
            placeholder={
              this.props.placeholder
                ? this.props.placeholder
                : getCurrentlocaleText("log-filter-by-select-timezone-msg")
            }
            onChange={this.updateTimezone}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            disabled={this.props.disabled}
            defaultValue={this.props.initialValue}
            notFoundContent={getCurrentlocaleText(
              "general.notfound.placeholder.text",
            )}
          >
            {this.renderZones()}
          </Select>
        )}
      </span>
    );
  }
}

TimezoneComponent.propTypes = {
  placeholder: propTypes.string,
  selectedValue: propTypes.func,
  style: propTypes.object,
  defaultTimezone: propTypes.string,
  defaultTimezoneValue: propTypes.string,
};
