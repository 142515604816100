import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Col, Row, Button, Modal } from "antd";
import { Form } from "@ant-design/compatible";
import QRCode from "qrcode.react";
import { getCurrentlocaleText } from "Core/Utils";

import { Input } from "antd";
const InputGroup = Input.Group;
import InputBox from "components/UI-Components/InputBox";

@inject("UserViewModel", "UiStore")
class TwoFactorSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 1,
      modalVisible: this.props.visibility,
      platform: null,
      OTP: null,
      errorState: false,
      loading: false,
    };
  }
  proceedNext = e => {
    if (this.props.fromLayout) {
      e.preventDefault();
    }
    let currentStep = this.state.currentStep;
    currentStep = currentStep + 1;
    if (currentStep > 1) {
      if (this.state.OTP) {
        this.setState({ errorState: false });
        this.props.onFinish(this.state.OTP);
      } else {
        this.setState({ errorState: true });
      }
    } else {
      this.setState({ currentStep: currentStep });
    }
  };
  componentDidMount() {
    setTimeout(() => {
      let step1 = getCurrentlocaleText("user_profiles.2fa.setup.steps.step2");
      if (document.getElementById("setup-totp-step2")) {
        document.getElementById("setup-totp-step2").innerHTML = step1;
      }
      let step3 = getCurrentlocaleText("user_profiles.2fa.setup.steps.step4");
      if (document.getElementById("setup-totp-step4")) {
        document.getElementById("setup-totp-step4").innerHTML = step3;
      }
      if (document.querySelector(".otp-input-field")) {
        document.querySelector(".otp-input-field").focus();
      }
    }, 500);
  }
  onCodeChange = e => {
    const { value } = e.target;
    var reg = new RegExp("^[0-9]+$");
    if (
      ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") &&
      value.toString().length === 6
    ) {
      this.setState({ errorState: false, OTP: e.target.value });
    } else {
      this.setState({ errorState: true, OTP: null });
    }
  };
  onCancel = () => {
    this.setState({ modalVisible: false, currentStep: 1 });
    this.props.onCancel();
  };
  render() {
    const STEPS = {
      1: {
        title: getCurrentlocaleText("user_profiles.2fa.setup.title"),
        content: (
          <div>
            <div style={{ fontSize: 15 }}>
              <p style={{ marginTop: 10 }}>
                {getCurrentlocaleText("user_profiles.2fa.setup.message1")}
              </p>
              <p>
                {getCurrentlocaleText("user_profiles.2fa.setup.steps.step1")}
              </p>
              <p id="setup-totp-step2" />
              <p id="setup-totp-step3" />
              <p />
              <div
                style={{
                  textAlign: "left",
                  marginTop: 10,
                  marginBottom: 10,
                  marginLeft: 20,
                }}
              >
                <QRCode value={this.props.current_uri} />
              </div>
              <p id="setup-totp-step4" />
            </div>
            <p />
            <div style={{ textAlign: "center", margin: 10 }}>
              <InputGroup size="large">
                <Col style={{ marginLeft: 10 }} push={0} span={9}>
                  <InputBox
                    maxLength="6"
                    minLength="6"
                    onChange={this.onCodeChange}
                    placeholder={getCurrentlocaleText(
                      "user.profiles.secuirtycode.placeholder.text",
                    )}
                    className={"otp-input-field"}
                  />
                  {this.state.errorState && (
                    <span
                      style={{ color: "#f04134" }}
                      className={"ant-form-explain"}
                    >
                      {getCurrentlocaleText(
                        "user_profiles.2fa.setup.error.text",
                      )}
                    </span>
                  )}
                </Col>
              </InputGroup>
            </div>
          </div>
        ),
        btntext: getCurrentlocaleText("user_profiles.2fa.setup.verify.text"),
      },
    };
    return (
      <div>
        {!this.props.fromLayout ? (
          <Modal
            title={STEPS["1"].title}
            visible={this.props.visibility}
            onCancel={this.onCancel}
            maskClosable={false}
            confirmLoading={this.state.loading}
            closable={!this.state.loading}
            footer={
              <div>
                <Button onClick={this.onCancel}>Cancel</Button>
                <Button
                  type={this.state.platform != null ? "primary" : ""}
                  onClick={this.proceedNext}
                >
                  {STEPS["1"].btntext}
                </Button>
              </div>
            }
          >
            {STEPS["1"].content}
          </Modal>
        ) : (
          <Form noValidate onSubmit={this.proceedNext}>
            <Row gutter={2} type="flex" align="top">
              {STEPS["1"].content}
            </Row>
            <Row type="flex" align="middle" justify="end">
              <div style={{ margin: "10px 0 10px" }}>
                <Button onClick={this.onCancel}>Cancel</Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginLeft: 8 }}
                  onClick={this.proceedNext}
                >
                  {STEPS["1"].btntext}
                </Button>
              </div>
            </Row>
          </Form>
        )}
      </div>
    );
  }
}
export default TwoFactorSetup;
