import React, { Component } from "react";
import Chart from "react-google-charts";

export default class CTDSeverityChart extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const customColors = [
      "#5c8edf",
      "#7de0d4",
      "#7e86d0",
      "#68bfe4",
      "#f3aaa1",
      "#cbc1fb",
    ];
    const areaData = this.props.areaData;
    const areaOptions = {
      chartArea: {
        bottom: 0,
        width: "100%",
        height: "30%",
      },
      style: {
        borderRadius: "10px",
      },
      isStacked: true,
      legend: "none",
      backgroundColor: this.props.color,
      colors: ["#e0b4a9"],
      hAxis: {
        title: "",
        minValue: 0,
        maxValue: 100,
        textPosition: "none",
        baselineColor: "none",
      },
      vAxis: {
        title: "",
        minValue: 0,
        textPosition: "none",
        baselineColor: "none",
        gridlines: {
          color: "transparent",
        },
      },
    };

    return (
      <div
        className="severity-chart-wrapper"
        style={{ backgroundColor: this.props.color }}
      >
        <div className="s-chart-wrapper">
          {!this.props.allZero && (
            <Chart
              chartType="AreaChart"
              width="100%"
              data={areaData}
              options={areaOptions}
            />
          )}
        </div>
        <div className="s-text-container ">
          <div>{this.props.type}</div>
          <span>{this.props.percentage ? this.props.percentage : 0}%</span>
        </div>
      </div>
    );
  }
}
