import React, { Component } from "react";
import { Link } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { Checkbox, Row, Col, Tag, Popover, Select, Input } from "antd";

import ActionBtn from "Core/API/ActionBtn";
import InodesForm from "components/Forms/InodesForm";
import { ConnectedNodes, NodeStatus } from "components/INodes";
import {
  highlightSearchText,
  getLastContactedAt,
  copyObject,
  getCurrentlocaleText,
  checkforFeatureEnabled,
} from "Core/Utils";

import RebootForm from "components/Forms/RebootForm";
import _ from "lodash";
import TableLayout from "components/layout/TableLayout";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import SearchInput from "components/UI-Components/SearchInput";
import Icons from "components/UI-Components/Icons";
import BulkConfigure from "components/BulkConfig/BulkConfigure";
import BulkConfigController from "../../controller/BulkConfigController";
import OrgController from "controller/OrgController";
import InodeController from "controller/InodeController";
import propTypes from "prop-types";
import { EnvironmentOutlined } from "@ant-design/icons";
import TableLayoutV1 from "../layout/TableLayoutV1";
const { Option } = Select;

@inject(
  "AuthStore",
  "UiStore",
  "DashboardStore",
  "InodeViewModel",
  "OrgViewModel",
  "ProfileViewModel",
)
@observer
class InodesList extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = this.props.InodeViewModel;
    this.orgModel = this.props.OrgViewModel;
    this.profileModel = this.props.ProfileViewModel;
    this.searchTimeout = null;
    this.state = {
      disableReboot: true,
      selectedNode: {},
      rebootableNodes: [],
      selectedRowKeys: [], // for table to identify selected rows
      deletableNodes: {
        nodes: [],
      },
      nodeListByInterval: null,
      searchText: "",
      filterInfo: null,
      sorterInfo: { sorter: {}, sort: {} },
      inode_state: null,
      inode_profile: null,
      expandedRowKeys: [],
      hardware_serial_number: null,
      isSearchByname: true,
      autoOpen: false,
      showBulkConfig: false,
      currentPage: 1,
      modalContent: "",
      modalTitle: "",
      isTableModalToggle: false,
    };
  }

  UNSAFE_componentWillMount() {
    // For Dashboard filter
    let filter = {};
    let path = window.location.pathname;
    this.props.UiStore.showChildNodes = this.props.isFromDashboard
      ? true
      : false;
    if (this.props.isFromDashboard) {
      // For inode status filter
      const currentOrgId =
        this.props.match.params && this.props.match.params.id
          ? this.props.match.params.id
          : this.props.OrgViewModel.org.id != undefined
          ? this.props.OrgViewModel.org.id
          : this.props.AuthStore.loggedInUser.organization &&
            this.props.AuthStore.loggedInUser.organization.id;
      let index = path.indexOf("/inodes/" + currentOrgId + "/status/");
      if (index !== -1) {
        let state = path.substring(
          index + ("/inodes/" + currentOrgId + "/status/").length,
        );
        if (state) {
          filter["state"] = [state.toUpperCase()];
          this.setState({ filterInfo: filter });
          this.props.UiStore.nodeParams.filterInfo = filter;
        }
      }
      // For inode profile & status filter
      let inx1 = path.indexOf("/profile/");
      let inx2 = path.indexOf("/status/");
      if (inx1 !== -1 && inx2 !== -1) {
        let paths = path.split("/");
        filter["state"] = [paths[7].toUpperCase()];
        if (paths[5] === "virtualedge") {
          filter["profile_name"] = ["virtual edge"];
        } else if (paths[5] === "unifiedcloudgateway") {
          filter["profile_name"] = ["unified cloud gateway"];
        } else {
          filter["profile_name"] = [paths[5]];
        }
        this.setState({ filterInfo: filter });
        this.props.UiStore.nodeParams.filterInfo = filter;
      }
    } else {
      // For inode status filter
      const currentOrgId =
        this.props.match.params && this.props.match.params.id
          ? this.props.match.params.id
          : this.props.OrgViewModel.org.id != undefined
          ? this.props.OrgViewModel.org.id
          : this.props.AuthStore.loggedInUser.organization &&
            this.props.AuthStore.loggedInUser.organization.id;
      // for cluster inode status navigation
      let cluster_index = path.indexOf("/orgs/" + currentOrgId + "/clusters/");
      let cluster_id = path.substring(
        cluster_index + ("/orgs/" + currentOrgId + "/clusters/").length,
      );
      if (
        cluster_id &&
        cluster_id.split("/") &&
        cluster_id.split("/").length > 0
      ) {
        cluster_id = cluster_id.split("/")[0];
      }
      if (cluster_id) {
        let index = path.indexOf(
          "/orgs/" +
            currentOrgId +
            "/clusters/" +
            cluster_id +
            "/inodes/status/",
        );

        if (index !== -1) {
          let state = path.substring(
            index +
              (
                "/orgs/" +
                currentOrgId +
                "/clusters/" +
                cluster_id +
                "/inodes/status/"
              ).length,
          );
          if (state) {
            filter["state"] = [state.toUpperCase()];
            if (cluster_id) {
              filter["cluster_id"] = cluster_id;
            }
            this.setState({ filterInfo: filter });
            this.props.UiStore.nodeParams.filterInfo = filter;
          }
        }
      }
    }

    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.cluster_id
    ) {
      delete filter.org_id;
      delete filter.own;
      filter["cluster_id"] = this.props.match.params.cluster_id;
    }

    this.inodeList(
      true,
      0,
      this.state.searchText,
      this.state.sorterInfo.sort.by,
      this.state.sorterInfo.sort.order,
      filter,
      false,
      this.props.params,
    );
    this.setState({ inodes: this.inodeModel.inodeListView });
  }
  UNSAFE_componentWillReceiveProps(props) {
    if (this.props.orgId != props.orgId) {
      this.inodeList(
        true,
        0,
        this.state.searchText,
        this.state.sorterInfo.sort.by,
        this.state.sorterInfo.sort.order,
        this.state.filterInfo,
        false,
        props.params,
      );
    }
  }

  componentDidMount() {
    /*API CALL every 1 Minute Intiated HERE */
    this.nodeListByInterval = setInterval(() => {
      let existing_nodes = this.inodeModel.inodes;
      let allParams = [];
      for (let page = 0; page <= this.inodeModel.pageable.page; page++) {
        let params = {
          org_id:
            this.props.match &&
            this.props.match.params &&
            this.props.match.params.id
              ? this.props.match.params.id
              : this.props.orgId
              ? this.props.orgId
              : this.inodeModel.inode.organization
              ? this.inodeModel.inode.organization.id
              : localStorage.currentOrgId,
          own: true,
          size: 25,
          page: page,
        };
        if (
          this.props.match &&
          this.props.match.params &&
          this.props.match.params.cluster_id
        ) {
          delete params.org_id;
          delete params.own;
          params.cluster_id = this.props.match.params.cluster_id;
        }
        // For search
        if (this.state.searchText) {
          params.search = this.state.searchText;
        } else {
          if (params.search) {
            delete params.search;
          }
        }
        // For hsn
        if (this.state.hardware_serial_number) {
          params.hardware_serial_number = this.state.hardware_serial_number;
        } else {
          if (params.hardware_serial_number) {
            delete params.hardware_serial_number;
          }
        }
        // For sort
        if (this.state.sorterInfo.sort.by && this.state.sorterInfo.sort.order) {
          params.sort = `${this.state.sorterInfo.sort.by}:${this.state.sorterInfo.sort.order}`;
        } else {
          if (params.sort) {
            delete params.sort;
          }
        }
        // For Filter
        if (this.state.filterInfo) {
          let keys = Object.keys(this.state.filterInfo);
          keys.forEach(key => {
            if (this.state.filterInfo[key]) {
              params[key] = this.state.filterInfo[key];
            }
          });
        } else {
          if (params.profile_name) {
            delete params.profile_name;
          }
          if (params.state) {
            delete params.state;
          }
        }
        // For child orgs
        if (this.props.UiStore.showChildNodes) {
          delete params.own;
        }
        allParams.push(params);
      }
      // Quering total lsit of pages in single get with setting UI loading false
      InodeController.QueryAlliNodes(allParams, false).then(updated_nodes => {
        InodeController.findAndRemoveDeletedInode(
          existing_nodes,
          updated_nodes,
        );
      });
    }, 60000);

    let pathname = location.pathname;
    let path = pathname.split("/");
    if (pathname) {
      if (path[1] === "orgs") {
        this.setState({
          showBulkConfig: true,
        });
      }
      if (
        path[1] === "orgs" &&
        path[2] &&
        path[3] === "inodes" &&
        path[4] === "create"
      ) {
        this.setState({
          autoOpen: true,
          modelAction: "create",
        });
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.nodeListByInterval);
    this.props.UiStore.showChildNodes = false;
    this.inodeModel.resetInodes();
    this.props.UiStore.nodeParams = {
      searchText: "",
      filterInfo: null,
      sorterInfo: { sorter: {}, sort: {} },
    };
  }

  getTagKeyValues = labels => {
    let tags = [];
    for (let key in labels) {
      if (key && key.charAt(0) != "_") tags.push(key + ":" + labels[key]);
    }
    return tags;
  };

  createAfterCall = resp => {
    this.props.onAfterClick("inodes");
    this.setState({
      currentPage: 1,
    });
    this.inodeList(
      true,
      0,
      this.state.searchText,
      this.state.sorterInfo.sort.by,
      this.state.sorterInfo.sort.order,
      this.state.filterInfo,
      false,
    );
    this.initializeState();
  };

  changeListview = e => {
    this.props.UiStore.showChildNodes = e.target.checked;
    this.setState({
      currentPage: 1,
    });
    this.inodeList(
      true,
      0,
      this.state.searchText,
      this.state.sorterInfo.sort.by,
      this.state.sorterInfo.sort.order,
      this.state.filterInfo,
      false,
      {},
      this.state.hardware_serial_number,
    );
  };
  deleteAfterCall = () => {
    let page = this.state.currentPage ? this.state.currentPage : 0;
    if (this.inodeModel.inodeListView.length == 0) {
      page--;
    }
    this.inodeList(
      true,
      page - 1,
      this.state.searchText,
      this.state.sorterInfo.sort.by,
      this.state.sorterInfo.sort.order,
      this.state.filterInfo,
      true,
    );
    this.initializeState();
  };

  loadOrgState = () => {
    if (this.props.loadOrgState) {
      OrgController.getOrg(this.props.orgId, false, true).then(resp => {
        this.props.UiStore.setOrgName(resp.name);
      });
    }
  };

  inodeList = (
    loading = true,
    page = 0,
    search = "",
    sortBy = null,
    sortOrder = null,
    filter = null,
    forcecall = false,
    qry_params = this.props.params ? this.props.params : {},
    hardware_serial_number = null,
  ) => {
    let params = copyObject(qry_params);
    params.page = page;
    params.search = search;
    params.org_id = params.org_id
      ? params.org_id
      : this.props.match.params.org_id
      ? this.props.match.params.org_id
      : this.props.OrgViewModel.org && this.props.OrgViewModel.org.id;
    // For sort
    if (sortBy && sortOrder) {
      params.sort = `${sortBy}:${sortOrder}`;
    }
    if (hardware_serial_number) {
      params.hardware_serial_number = hardware_serial_number;
    } else {
      delete params.hardware_serial_number;
    }

    // For Filter
    if (filter) {
      let keys = Object.keys(filter);
      keys.forEach(key => {
        if (filter[key]) {
          params[key] = filter[key];
        }
      });
    }
    if (this.props.UiStore.showChildNodes) {
      delete params.own;
    } else {
      params.own = true;
    }
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.cluster_id
    ) {
      delete params.org_id;
      delete params.own;
      params.cluster_id = this.props.match.params.cluster_id;
    }
    InodeController.getInodes(params, loading, forcecall);
  };

  setRebootStates = (record = {}, selected, selectedRows) => {
    for (let i = 0; i < selectedRows.length; i++) {
      let id = selectedRows[i].id;

      this.setState(() => {
        let newObj = {
            deletableNodes: {
              nodes: [],
            },
          },
          rebootableNodes = [], // this.state.rebootableNodes,
          selectedNode = this.state.selectedNode,
          item = selectedRows[i],
          tempArray = [];

        selectedNode[id] = {
          value: true,
          status: item.status.toLowerCase(),
          name: item.name,
          id: item.id,
          hardware_serial_number: item.node.hardware_serial_number,
        };

        //Temp array for deletable nodes list

        for (let i in selectedRows) {
          selectedNode[selectedRows[i].id]
            ? tempArray.push(selectedNode[selectedRows[i].id])
            : "";
        }

        // To set the value : false , when node is deselected and remove it from delete list

        !selected && selectedNode[record.id]
          ? ((selectedNode[record.id].value = false),
            (tempArray = _.filter(tempArray, val => {
              return val.id !== record.id;
            })))
          : null;

        // Generate list of rebootable Nodes
        for (let i in selectedRows) {
          if (selectedRows[i].status.toLowerCase() === "alive") {
            rebootableNodes.push(selectedRows[i].id),
              (rebootableNodes = _.uniq(rebootableNodes));
          }
        }

        // enable/ disable reboot button
        rebootableNodes.length == 0
          ? (newObj["disableReboot"] = true)
          : (newObj["disableReboot"] = false);

        newObj["rebootableNodes"] = rebootableNodes;
        newObj["selectedNode"] = selectedNode;
        newObj.deletableNodes["nodes"] = tempArray;
        return newObj;
      });
    }
  };

  onSelectAllChange = (selected, selectedRows, changeRows) => {
    if (selected) {
      selectedRows = selectedRows.filter(item => !!item);
      this.setRebootStates(null, selected, selectedRows);
    } else {
      // on deselect all
      this.resetState();
    }
  };

  handleTableChange = (record, selected, selectedRows) => {
    selectedRows = selectedRows.filter(item => !!item); // Remove null and undefined
    selectedRows.length == 0 ? this.resetState() : "";
    this.setRebootStates(record, selected, selectedRows);
  };

  iNodeListFilter = e => {
    clearTimeout(this.searchTimeout);
    let searchText = e.target.value;
    this.setState({
      searchText: searchText,
      hardware_serial_number: "",
    });
    this.props.UiStore.nodeParams.searchText = searchText;
    this.searchTimeout = setTimeout(() => {
      this.inodeList(
        false,
        0,
        searchText,
        this.state.sorterInfo.sort.by,
        this.state.sorterInfo.sort.order,
        this.state.filterInfo,
        false,
      );
    }, 500);
  };

  iNodeListByHsnFilter = e => {
    clearTimeout(this.searchTimeout);
    let hardware_serial_number = e.target.value;
    let orgId = this.props.orgId;
    this.setState({
      hardware_serial_number: hardware_serial_number,
      searchText: "",
    });
    this.searchTimeout = setTimeout(() => {
      this.inodeList(
        false,
        0,
        this.state.searchText,
        this.state.sorterInfo.sort.by,
        this.state.sorterInfo.sort.order,
        this.state.filterInfo,
        false,
        this.props.params,
        hardware_serial_number,
      );
    }, 500);
  };

  selectSearchType = value => {
    if (value === "hardware_serial_number") {
      this.setState({ isSearchByname: false });
    } else {
      this.setState({ isSearchByname: true });
    }
  };

  clearSearchFilter = value => {
    this.setState({
      searchText: "",
      hardware_serial_number: "",
      currentPage: 1,
    });
    this.props.UiStore.nodeParams.searchText = "";
    this.inodeList(
      true,
      0,
      "",
      this.state.sorterInfo.sort.by,
      this.state.sorterInfo.sort.order,
      this.state.filterInfo,
      false,
    );
  };

  onTableChange = (pagination, filter, sorter) => {
    let sort = {};
    if (sorter.columnKey && sorter.order) {
      if (sorter.order === "ascend") {
        sort.order = "ASC";
      } else {
        sort.order = "DESC";
      }
      sort.by = sorter.columnKey;
    }
    this.setState({
      currentPage: pagination.current,
      filterInfo: filter,
      sorterInfo: { sorter: sorter, sort: sort },
    });
    let page = pagination.current - 1;
    this.props.UiStore.nodeParams.filterInfo = filter;
    this.props.UiStore.nodeParams.sorterInfo = { sorter: sorter, sort: sort };
    this.inodeList(
      true,
      page,
      this.state.searchText,
      sort.by,
      sort.order,
      filter,
      false,
    );
  };

  initializeState = () => {
    this.loadOrgState();
    this.resetState();
  };

  resetState = () => {
    this.setState({
      disableReboot: true,
      selectedNode: {},
      rebootableNodes: [],
      deletableNodes: {
        nodes: [],
      },
    });
  };

  get cameFromOrgDetailPage() {
    if (this.props.match && this.props.match.params.id) {
      return true;
    }
    return false;
  }

  expandRow = () => {
    let keys = this.inodeModel.inodes.map(item => {
      return item.id;
    });
    this.setExpandedRowKeys(
      this.state.expandedRowKeys.length !== keys.length ? keys : [],
    );
  };

  onExpand = (expanded, record) => {
    let keys = this.state.expandedRowKeys;
    let index = keys.findIndex(val => {
      return val === record.id;
    });
    if (expanded) {
      if (index === -1) {
        keys.push(record.id);
      }
      this.setExpandedRowKeys(keys);
    } else {
      if (index !== -1) {
        keys.splice(index, 1);
      }
      this.setExpandedRowKeys(keys);
    }
  };

  setExpandedRowKeys = keys => {
    this.setState({ expandedRowKeys: keys });
  };

  iNodeAfterCall = () => {
    let params = {};
    let filter = this.state.filterInfo;
    let sortBy = this.state.sorterInfo.sort.by;
    let sortOrder = this.state.sorterInfo.sort.order;
    let search = this.state.searchText;

    params.page = 0;
    params.size = 1;
    params.search = search;
    params.org_id =
      this.props.match && this.props.match.params && this.props.match.params.id
        ? this.props.match.params.id
        : this.props.orgId
        ? this.props.orgId
        : localStorage.currentOrgId;
    // For sort
    if (sortBy && sortOrder) {
      params.sort = `${sortBy}:${sortOrder}`;
    }
    // For Filter
    if (filter) {
      let keys = Object.keys(filter);
      keys.forEach(key => {
        if (filter[key]) {
          params[key] = filter[key];
        }
      });
    }
    if (this.props.UiStore.showChildNodes) {
      delete params.own;
    } else {
      params.own = true;
    }
    //intialize paginated api call
    let page = Math.ceil(this.inodeModel.inodes.length / 25);
    this.updateNodeData(page, [], true, copyObject(params));
  };

  //intialize paginated api call based on total available count
  updateNodeData(totalCount, availableNetworks, loading, params = {}) {
    if (totalCount > 0) {
      let allParams = [];
      let existParams = params;
      for (let page = 0; page < totalCount; page++) {
        let params = copyObject(existParams) || {};
        params.page = page;
        params.size = 25;
        allParams.push(params);
      }
      InodeController.getAllInodesForDropdown(
        allParams,
        loading,
        true,
      ).then(resp => {});
    }
  }
  lazyLoad = () => {
    if (this.inodeModel.pageable.next) {
      let page = this.inodeModel.pageable.page + 1;
      this.inodeList(
        true,
        page,
        this.state.searchText,
        this.state.sorterInfo.sort.by,
        this.state.sorterInfo.sort.order,
        this.state.filterInfo,
        false,
      );
    }
  };

  downloadNodes = (bulkConfig = false) => {
    let params = {};
    params.search = this.state.searchText;
    if (this.state.sorterInfo.sort.by && this.state.sorterInfo.sort.order) {
      params.sort = `${this.state.sorterInfo.sort.by}:${this.state.sorterInfo.sort.order}`;
    }
    if (this.props.UiStore.showChildUsers) {
      delete params.own;
    } else {
      params.own = true;
    }
    if (bulkConfig) {
      params.fields =
        "hardware_serial_number,name,ssh_keys,labels,profile_name";
    }
    if (this.state.filterInfo) {
      let keys = Object.keys(this.state.filterInfo);
      keys.forEach(key => {
        if (this.state.filterInfo[key]) {
          params[key] = this.state.filterInfo[key];
        }
      });
    }
    if (this.props.orgId) {
      params.org_id = this.props.orgId;
    }

    BulkConfigController.downloadNodes(params, false);
  };

  onNewTemplate = () => {
    BulkConfigController.downloadNodesTemplate();
  };
  computeLocation = location => {
    if (
      location &&
      location.street &&
      location.city &&
      location.state &&
      location.country &&
      location.zipcode
    ) {
      return (
        <span style={{ lineHeight: "3px" }}>
          <p>{`${location.street}`}</p>
          <p>{`${location.city}, ${location.state}`}</p>
          <p>{`${location.country}, ${location.zipcode}`}</p>
        </span>
      );
    }
  };

  getModalContentJSX = record => {
    let modalContent = this.props.InodeViewModel.setExtraRowModal(record);
    this.setState({
      modalTitle: record && record.name ? record.name : "",
      modalContent: modalContent,
    });
  };

  handleModalOk(e) {
    this.setState({
      isTableModalToggle: !this.state.isTableModalToggle,
    });
  }

  render() {
    const { isTableModalToggle } = this.state;
    let { filterInfo, sorterInfo } = this.state;
    filterInfo = filterInfo || {};
    sorterInfo = sorterInfo || {};

    let inodes = this.inodeModel.inodeListView;
    this.columns = [
      {
        title: (
          <span
            title={getCurrentlocaleText(
              "node.list.table.node_status.label.text",
            )}
          >
            {getCurrentlocaleText("node.list.table.node_status.label.text")}
          </span>
        ),
        dataIndex: "status",
        key: "state",
        width: this.props.UiStore.showChildNodes ? "20%" : "18%",
        render: (text, record, index) => {
          return (
            <div style={{ paddingLeft: 8 }}>
              <NodeStatus
                nodeState={record.status}
                lastContact={getLastContactedAt(record.node)}
                nodeProfile={record.node.profile}
              />
            </div>
          );
        },
        filters: [
          {
            text: "ALIVE",
            value: "ALIVE",
          },
          {
            text: "NEW",
            value: "NEW",
          },
          {
            text: "REBOOTING",
            value: "REBOOTING",
          },
          {
            text: "UNREACHABLE",
            value: "UNREACHABLE",
          },
        ],
        filteredValue: filterInfo.state || null,
      },
      {
        title: (
          <span
            title={getCurrentlocaleText("node.list.table.node_name.label.text")}
          >
            {getCurrentlocaleText("node.list.table.node_name.label.text")}
          </span>
        ),
        dataIndex: "name",
        key: "name",
        width: this.props.UiStore.showChildNodes ? "22%" : "24%",
        ellipsis: true,
        sorter: true,
        sortOrder:
          sorterInfo.sorter.columnKey === "name" && sorterInfo.sorter.order,
        render: (text, record, index) => {
          let inodeLink = `/inodes/${record.id}`;
          if (this.cameFromOrgDetailPage) {
            inodeLink = `/orgs/${
              record.org_id
                ? record.org_id
                : this.orgModel.org && this.orgModel.org.id
            }/inodes/${record.id}`;
          }
          if (this.props.isFromDashboard) {
            inodeLink = `/orgs/${
              record.org_id
                ? record.org_id
                : this.props.AuthStore.loggedInUser.organization
                ? this.props.AuthStore.loggedInUser.organization.id
                : ""
            }/inodes/${record.id}`;
          }
          let cluster;
          if (record.node && record.node.cluster) {
            let node_data = record.node;
            cluster = Object.assign({}, record.node.cluster);
            cluster.status =
              node_data &&
              node_data.status &&
              node_data.status.node &&
              node_data.status.node.node_cluster_status &&
              node_data.status.node.node_cluster_status.state
                ? node_data.status.node.node_cluster_status.state
                : null;
            cluster.isMaster =
              cluster.status && cluster.status.toUpperCase() === "MASTER"
                ? true
                : false;
          }
          let isDualMaster =
            record.cluster_id &&
            this.inodeModel.inodeListView.clusterNodeStatusCount &&
            this.inodeModel.inodeListView.clusterNodeStatusCount[
              record.cluster_id
            ] &&
            this.inodeModel.inodeListView.clusterNodeStatusCount[
              record.cluster_id
            ] > 1
              ? true
              : false;
          return (
            <div title={text} className="col-wrap">
              <Link to={inodeLink}>
                {this.state.searchText.trim().length > 0 ? (
                  record.isDeleted ? (
                    <span>
                      {highlightSearchText(text, this.state.searchText)}&nbsp;
                      <span className="delete-header-red">
                        {getCurrentlocaleText("deleting.text")}
                      </span>
                    </span>
                  ) : (
                    highlightSearchText(text, this.state.searchText)
                  )
                ) : record.isDeleted ? (
                  <span>
                    {text}&nbsp;
                    <span className="delete-header-red">
                      {getCurrentlocaleText("deleting.text")}
                    </span>
                  </span>
                ) : (
                  text
                )}
              </Link>
              {cluster &&
                cluster.status &&
                cluster.status.toUpperCase() === "MASTER" &&
                !isDualMaster && (
                  <div>
                    <Tag className="iot-tag-label-color" closable={false}>
                      {getCurrentlocaleText(
                        "cluster.node_list.master.cidr.text",
                      )}
                    </Tag>
                  </div>
                )}
              {isDualMaster && (
                <Popover
                  overlayStyle={{ maxWidth: "20%", float: "right" }}
                  placement="right"
                  content={getCurrentlocaleText(
                    "cluster.master.election.inprogress.message",
                  )}
                >
                  <span>
                    <Icons
                      style={{ fontSize: 15, marginLeft: 5, color: "orange" }}
                      className=""
                      type="fa"
                      name="FaRegClock"
                    />
                  </span>
                </Popover>
              )}
            </div>
          );
        },
      },
      {
        title: (
          <span title={getCurrentlocaleText("myprofile.organization.text")}>
            {getCurrentlocaleText("myprofile.organization.text")}
          </span>
        ),
        dataIndex: "org_name",
        key: "org_name",
        width: "15%",
        render: (text, record, index) => {
          let inodeLink = `/dashboard/${record.org_id}`;
          return (
            <div title={record.org_name} className="col-wrap">
              <Link to={inodeLink}>{record.org_name}</Link>
            </div>
          );
        },
      },
      {
        title: (
          <span
            title={getCurrentlocaleText(
              "node.list.table.node_profile.label.text",
            )}
          >
            {getCurrentlocaleText("node.list.table.node_profile.label.text")}
          </span>
        ),
        dataIndex: "profile",
        key: "profile_name",
        width: "10%",
        filters: [
          {
            text: "Edge",
            value: "edge",
          },
          {
            text: "Virtual",
            value: "virtual",
          },
          {
            text: "Virtual Edge",
            value: "virtual edge",
          },
          {
            text: "Unified Cloud Gateway",
            value: "unified cloud gateway",
          },
        ],
        filteredValue: filterInfo.profile_name || null,
        render: (text, record, index) => {
          return <div>{record.profile}</div>;
        },
      },
      {
        title: (
          <span
            title={getCurrentlocaleText(
              "node.list.table.node_connected_network.label.text",
            )}
          >
            {getCurrentlocaleText(
              "node.list.table.node_connected_network.label.text",
            )}
          </span>
        ),
        dataIndex: "",
        key: "connected_nodes",
        width: this.props.UiStore.showChildNodes ? "18%" : "20%",
        render: (text, record, index) => {
          let vProfileIds = this.profileModel.profiles
            .filter(profile => {
              if (profile.config.vpn_server) {
                return true;
              }
            })
            .map(profile => {
              return profile.id;
            });
          return (
            record.node && (
              <div>
                {this.props.AuthStore.IsPermitted("NETWORK:VIEW") && (
                  <ConnectedNodes
                    isVirtualNode={true}
                    showRepNetwork={true}
                    nodeId={record.id}
                    showPeers={
                      !record.node.cluster_node_config ||
                      record.node.cluster_node_config.is_candidate
                    }
                  />
                )}
              </div>
            )
          );
        },
      },
      {
        title: (
          <span
            title={getCurrentlocaleText(
              "node.list.table.node_services.label.text",
            )}
            style={{ display: "flex" }}
          >
            {getCurrentlocaleText("node.list.table.node_services.label.text")}
          </span>
        ),
        dataIndex: "services",
        key: "services",
        width: "8%",
        render: (text, record, index) => {
          return (
            <div style={{ marginLeft: 15 }}>
              {record.node.services ? (
                `${record.node.services.length}`
              ) : (
                <p>{`-`}</p>
              )}
            </div>
          );
        },
      },
    ];

    if (
      this.props.AuthStore.IsPermitted("SUPER:ADMIN") ||
      this.props.AuthStore.IsPermitted("ORG:SUPPORT")
    ) {
      let version_columns = {
        title: <span title={"Version"}>{"Version"}</span>,
        dataIndex: "version",
        key: "version",
        width: this.props.UiStore.showChildNodes ? "10%" : "12%",
        filterMultiple: false,
        filters: (inodes.available_versions && inodes.available_versions) || [],
        filteredValue: filterInfo.version || null,
        onFilter: (value, record) => record.version.indexOf(value) === 0,
        render: (text, record, index) => {
          return (
            <div className="iot-table-column-overflow">{record.version}</div>
          );
        },
      };
      this.columns.splice(4, 0, version_columns);
    }
    if (!this.props.UiStore.showChildNodes) {
      this.columns.splice(2, 1);
    }
    // cluster column check only if cluster feature is enabled
    if (
      checkforFeatureEnabled(
        this.props.OrgViewModel.org.id != undefined
          ? this.props.OrgViewModel.org
          : this.props.AuthStore.loggedInUserOrg,
        "cluster",
      )
    ) {
      let cluster_column = {
        title: (
          <span title={getCurrentlocaleText("cluster.field.text")}>
            {getCurrentlocaleText("cluster.field.text")}
          </span>
        ),
        dataIndex: "cluster",
        key: "cluster_name",
        width: "15%",
        ellipsis: true,
        render: (text, record, index) => {
          let clusterLink = `/orgs/${record.org_id}/clusters/${record.cluster_id}`;
          return (
            <div className="col-wrap">
              {record.cluster_id ? (
                <Link to={clusterLink}>{record.cluster_name}</Link>
              ) : (
                <span style={{ marginLeft: 15 }}>{record.cluster_name}</span>
              )}
            </div>
          );
        },
      };
      this.columns.splice(
        this.props.UiStore.showChildNodes ? 4 : 2,
        0,
        cluster_column,
      );
    }

    let container = (
      <div style={{ minHeight: 250 }}>
        <div style={{ display: "inline-flex", width: "40%" }}>
          <span className="headertext">
            {getCurrentlocaleText("inodes.text")}
          </span>
        </div>
        <div
          style={{
            marginBottom: "10px",
            display: "inline-flex",
            justifyContent: "flex-end",
            width: "60%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 7,
            }}
          >
            <Checkbox
              checked={this.props.UiStore.showChildNodes}
              onChange={this.changeListview}
              disabled={
                this.props.match &&
                this.props.match.params &&
                this.props.match.params.cluster_id
                  ? true
                  : this.props.OrgViewModel.org &&
                    this.props.OrgViewModel.org.org_count > 0
                  ? false
                  : true
              }
            >
              {getCurrentlocaleText("node.list.table_view.checkbox.label")}
            </Checkbox>
          </div>
          {/**search in nodes */}
          <div>
            <Input.Group size="default" style={{ display: "flex" }}>
              <Select
                style={{
                  width: "auto",
                }}
                defaultValue={getCurrentlocaleText(
                  "node.list.table.filter_all.placeholder.text",
                )}
                onChange={this.selectSearchType}
              >
                <Option value={"name"}>
                  {getCurrentlocaleText(
                    "node.list.table.filter_all.placeholder.text",
                  )}
                </Option>
                <Option value={"hardware_serial_number"}>
                  {getCurrentlocaleText(
                    "node.list.table.filter_hsn.placeholder.text",
                  )}
                </Option>
              </Select>
              <SearchInput
                style={{
                  width: "auto",
                }}
                placeholder={
                  this.state.isSearchByname
                    ? getCurrentlocaleText(
                        "node.list.table.filter_all.placeholder.text",
                      )
                    : getCurrentlocaleText(
                        "node.list.table.filter_hsn.placeholder.text",
                      )
                }
                allowClear
                onChange={
                  this.state.isSearchByname
                    ? this.iNodeListFilter
                    : this.iNodeListByHsnFilter
                }
                value={
                  this.state.isSearchByname
                    ? this.state.searchText
                    : this.state.hardware_serial_number
                }
                clearSearch={this.clearSearchFilter}
              />
            </Input.Group>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}></div>
          <div
            style={{
              marginLeft: "20px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {this.props.AuthStore.IsPermitted("NODE:CREATE") &&
              !this.props.isFromDashboard && (
                <ActionBtn
                  title={
                    <span>
                      <Icons type="fa" name="FaRegHdd" />{" "}
                      {getCurrentlocaleText(
                        "node.list.table.add_inode.label.text",
                      )}
                    </span>
                  }
                  resource="inodes"
                  orgId={this.props.orgId}
                  type="primary"
                  icon="AiOutlinePlus"
                  iconButton
                  afterCall={this.createAfterCall}
                  width={600}
                  autoOpenModel={this.state.autoOpen}
                  changeRoute={this.props.changeRoute}
                  action="create"
                  actionForm={InodesForm}
                  controller={InodeController}
                >
                  <Icons type="ai" name="AiOutlinePlus" style={{ margin: 0 }} />
                  {getCurrentlocaleText("node.list.table.add_inode.label.text")}
                </ActionBtn>
              )}
            {this.props.AuthStore.IsPermitted("NODE:DELETE") &&
              !this.props.isFromDashboard &&
              (this.state.deletableNodes.nodes.length == 1 ? (
                <ActionBtn
                  title={
                    <span className="delete-header-red">
                      <Icons
                        type="ai"
                        name="AiOutlineDelete"
                        style={{ fontSize: 20 }}
                      />{" "}
                      {getCurrentlocaleText(
                        "node.list.table.delete_node.button.title.text",
                      )}
                      ?
                    </span>
                  }
                  HoverText={
                    <span>
                      {getCurrentlocaleText(
                        "inode.list.table.delete.button.hover.text",
                        {
                          0: "iNode",
                        },
                      )}
                    </span>
                  }
                  iconButton
                  orgId={this.props.orgId}
                  formData={this.state.deletableNodes.nodes}
                  afterCall={this.deleteAfterCall}
                  icon="AiOutlineDelete"
                  resource="inodes"
                  action="delete"
                  custom={true}
                  actionForm={InodesForm}
                  okText={getCurrentlocaleText("general.delete.text")}
                  controller={InodeController}
                  cancelText={getCurrentlocaleText("general.cancel.text")}
                ></ActionBtn>
              ) : (
                <ActionBtn
                  title={
                    <span>
                      <Icons type="ai" name="AiOutlineDelete" />{" "}
                      {getCurrentlocaleText(
                        "inode.list.table.delete.button.title.text",
                        {
                          0: "iNodes",
                        },
                      )}
                    </span>
                  }
                  HoverText={
                    <span>
                      {getCurrentlocaleText(
                        "inode.list.table.delete.button.hover.text",
                        {
                          0: "iNodes",
                        },
                      )}
                    </span>
                  }
                  resource="inodes"
                  orgId={this.props.orgId}
                  icon="AiOutlineDelete"
                  formData={this.state.deletableNodes.nodes}
                  iconButton
                  action="delete"
                  actionForm={InodesForm}
                  disabled={this.state.deletableNodes.nodes.length === 0}
                  afterCall={this.deleteAfterCall}
                  controller={InodeController}
                  messageContent={
                    <span>
                      <p>
                        {getCurrentlocaleText(
                          this.state.deletableNodes.nodes.length > 1
                            ? "inode.multiple.delete.confirmation.message.text"
                            : "inode.delete.confirmation.message.text",
                        )}{" "}
                      </p>
                    </span>
                  }
                  warningMessage={getCurrentlocaleText(
                    this.state.deletableNodes.nodes.length > 1
                      ? "inode.multiple.delete.confirmation.message1.text"
                      : "inode.delete.confirmation.message1.text",
                  )}
                  cancelText={getCurrentlocaleText(
                    "inode.delete.confirmation.cancel.text",
                    {
                      0:
                        this.state.deletableNodes.nodes.length > 1
                          ? "iNodes"
                          : "iNode",
                    },
                  )}
                  okText={
                    <span>
                      <Icons
                        type="ai"
                        name="AiOutlineDelete"
                        style={{ margin: 0 }}
                        className="iot-delete-icon"
                      />
                      {getCurrentlocaleText(
                        "inode.delete.confirmation.ok.text",
                        {
                          0:
                            this.state.deletableNodes.nodes.length > 1
                              ? "iNodes"
                              : "iNode",
                        },
                      )}
                    </span>
                  }
                ></ActionBtn>
              ))}
            {this.props.AuthStore.IsPermitted("NODE:REBOOT") &&
              !this.props.isFromDashboard && (
                <ActionBtn
                  title={
                    <span>
                      <Icons
                        type="md"
                        name="MdRefresh"
                        style={{ fontSize: 16 }}
                      />{" "}
                      {getCurrentlocaleText(
                        "node.list.table.reboot_node.label.text",
                      )}
                    </span>
                  }
                  resource="inodes"
                  action="reboot"
                  actionForm={RebootForm}
                  icon="AiOutlineSync"
                  iconButton
                  afterCall={() => {
                    this.resetState();
                    this.iNodeAfterCall();
                  }}
                  disabled={this.state.disableReboot}
                  //TODO - move below attributes to extendData
                  selectedNode={this.state.selectedNode}
                  rebootableNodes={this.state.rebootableNodes}
                  controller={InodeController}
                >
                  <Icons type="md" name="MdRefresh" style={{ fontSize: 16 }} />{" "}
                  {getCurrentlocaleText(
                    "node.list.table.reboot_node.button.text",
                  )}
                </ActionBtn>
              )}
          </div>
        </div>
        {this.state.showBulkConfig && (
          <div>
            <BulkConfigure
              readOnly={!this.props.AuthStore.IsPermitted("NODE:CREATE")}
              getList={this.inodeList}
              onAfterClick={this.props.onAfterClick}
              orgId={this.props.orgId}
              changeRoute={this.props.changeRoute}
              createTemplate={this.onNewTemplate}
              download={this.downloadNodes}
              resource={"Node"}
            />
          </div>
        )}
        {this.state.filterInfo || this.inodeModel.inodes.length > 0 ? (
          <div>
            <TableLayoutV1
              columns={this.columns}
              dataSource={inodes}
              pagination={{
                pageSize: this.inodeModel && this.inodeModel.pageable.size,
                total: this.inodeModel && this.inodeModel.pageable.total_count,
                current: this.state.currentPage,
              }}
              selectedRows={this.handleTableChange}
              selectAll={this.onSelectAllChange}
              onChange={this.onTableChange}
              hideRowSelection={this.props.isFromDashboard}
              className="inodeList"
              getModalContent={record => {
                return this.getModalContentJSX(record);
              }}
              handleModalOk={e => this.handleModalOk(e)}
              modalTitle={this.state.modalTitle}
              modalContent={this.state.modalContent}
              isTableModalToggle={isTableModalToggle}
              isModalLoading={false}
              disableFooter={true}
            />
          </div>
        ) : (
          <div>
            {!this.inodeModel.loading && <h3>{"No iNodes to display"}</h3>}
          </div>
        )}
      </div>
    );
    return (
      <LoadingComponent loading={this.inodeModel.loading}>
        {container}
      </LoadingComponent>
    );
  }
}

InodesList.propTypes = {
  params: propTypes.object,
  map: propTypes.object,
  cache: propTypes.bool,
  orgId: propTypes.string.isRequired,
};

export default InodesList;
