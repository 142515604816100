import React, { Component } from "react";
import { Card, Spin, Modal, Button } from "antd";
import { getCurrentlocaleText, humanizeMetric } from "Core/Utils";
import Chart from "react-google-charts";
import { observer, inject } from "mobx-react";
import Icons from "../../UI-Components/Icons";

@inject(
  "AuthStore",
  "EventViewModel",
  "ServiceStatsViewModel",
  "NodeStatsViewModel",
)
@observer
class FileSystemChart extends Component {
  constructor(props) {
    super(props);
    // GLOBAL_SETTINGS is configured form node js
    this.GLOBAL_SETTINGS = GLOBAL_SETTINGS;
    this.textColor =
      this.GLOBAL_SETTINGS && this.GLOBAL_SETTINGS.text_color
        ? this.GLOBAL_SETTINGS.text_color
        : "#365c6f";
    this.eventModel = this.props.EventViewModel;
    this.ServiceStatsViewModel = this.props.ServiceStatsViewModel;
    this.state = {
      loading: false,
      currentInterface: "eth0",
      data: this.props.match.params.containerId
        ? this.ServiceStatsViewModel.getServiceStats.stats
        : this.props.NodeStatsViewModel.getStatsAndInterfaces.stats,
      chartData: [],
      containerId: this.props.match.params.containerId
        ? this.props.match.params.containerId
        : null,
      chartModalvisible: false,
    };
  }
  UNSAFE_componentWillReceiveProps(newprops) {
    if (this.props != newprops) {
      this.setState({
        loading: newprops.loading,
        data: newprops.match.params.containerId
          ? this.ServiceStatsViewModel.getServiceStats.stats
          : newprops.NodeStatsViewModel.getStatsAndInterfaces.stats,
        containerId: newprops.match.params.containerId
          ? newprops.match.params.containerId
          : null,
      });
    }
  }

  componentWillUnmount() {
    this.props.NodeStatsViewModel.resetMetricsDashboardStore();
  }

  buildNodeEvents = (name, status) => {
    return (
      "<span style='color:#666'><table style='padding:2px;font-size:12px;min-width:50px;'><tr><td>" +
      getCurrentlocaleText("resource.name.text") +
      ":</td><td>" +
      name +
      "</td></tr><tr><td>" +
      getCurrentlocaleText("inode_status_alerts.text") +
      ":</td><td><strong style='color:" +
      this.getStatusStyle(status) +
      "'>" +
      status +
      "</strong></td></tr></table></span>"
    );
  };

  buildServiceEvents = (name, status) => {
    return (
      "<span style='color:#666'><table style='padding:2px;  font-size:12px;min-width:50px;'><tr><td>" +
      getCurrentlocaleText("resource.name.text") +
      ":</td><td>" +
      name +
      "</td></tr><tr><td>" +
      getCurrentlocaleText("events.service_status.header.text") +
      ":</td><td><strong style='color:" +
      this.getStatusStyle(status) +
      "'>" +
      status +
      "</strong></td></tr></table></span>"
    );
  };

  buildNetworkEvents = (record, status) => {
    return (
      "<span style='color:#666'><table style='padding:2px;  font-size:12px;min-width:50px;'><tr><td>" +
      getCurrentlocaleText("resource.name.text") +
      ":</td><td><span>&nbsp;" +
      record.network.name +
      "</span><span>&nbsp;\u27F7&nbsp;" +
      record.peer_node.name +
      "/" +
      record.peer_network.name +
      "</span></td></tr><tr><td>" +
      getCurrentlocaleText("tunnel_status_alerts.text") +
      ":</td><td><strong style='color:" +
      this.getStatusStyle(status) +
      "'>" +
      status +
      "</strong></td></tr></table></span>"
    );
  };

  getStatusStyle = status => {
    if (status) {
      switch (status.toLowerCase()) {
        case "terminated":
        case "unreachable":
          return "#f04134";
        case "healthy":
        case "alive":
        case "connected":
          return "#00a854";
        case "reboot":
          return "#ffbf00";
        default:
          return "#ffbf00";
      }
    }
  };

  // Draw the graph for total CPU usage.
  drawCpuTotalUsage() {
    let stats = this.props.match.params.containerId
      ? this.props.ServiceStatsViewModel.getServiceStats.stats
      : this.props.NodeStatsViewModel.getStatsAndInterfaces.stats;
    var data = [];
    let byteRxValues = 0;
    let byteTxValues = 0;

    let events = this.eventModel.eventList || [];
    if (events.length > 0) stats = stats.concat(events);
    for (var i = 1; i < stats.length; i++) {
      var cur = stats[i];
      let filesystems = cur && cur.filesystem ? cur.filesystem : [];
      var elements = [];
      if (cur.timestamp) {
        if (filesystems.length > 0) {
          for (var j = 0; j < filesystems.length; j++) {
            var fs = filesystems[j];
            // plot only root container and ignore others
            if (fs.device.includes("ROOT")) {
              elements.push(new Date(cur.timestamp));
              if (
                (this.props.match.params.containerId
                  ? this.props.NodeStatsViewModel.getTotalFileSystemValue
                    ? this.props.NodeStatsViewModel.getTotalFileSystemValue
                    : 0
                  : fs.capacity) > byteTxValues
              )
                byteTxValues = this.props.match.params.containerId
                  ? this.props.NodeStatsViewModel.getTotalFileSystemValue
                    ? this.props.NodeStatsViewModel.getTotalFileSystemValue
                    : 0
                  : fs.capacity;
              if (fs.usage > byteRxValues) byteRxValues = fs.usage;
              elements.push({
                v: Math.round(
                  this.props.match.params.containerId
                    ? this.props.NodeStatsViewModel.getTotalFileSystemValue
                      ? this.props.NodeStatsViewModel.getTotalFileSystemValue
                      : 0
                    : fs.capacity,
                  true,
                ),
                f: humanizeMetric(
                  this.props.match.params.containerId
                    ? this.props.NodeStatsViewModel.getTotalFileSystemValue
                      ? this.props.NodeStatsViewModel.getTotalFileSystemValue
                      : 0
                    : fs.capacity,
                  true,
                ),
              });
              elements.push({
                v: Math.round(fs.usage),
                f: humanizeMetric(fs.usage, true),
              });
              if (events.length > 0) {
                elements.push(undefined);
                elements.push(undefined);
                elements.push(undefined);
              }
            }
          }
        } else {
          elements.push(new Date(cur.timestamp));
          elements.push(null);
          elements.push(null);
          if (events.length > 0) {
            elements.push(undefined);
            elements.push(undefined);
            elements.push(undefined);
          }
        }
      } else {
        let currentEventInfo = stats[i];
        let name = undefined;
        let status = undefined;
        if (events.length > 0) {
          elements.push(new Date(currentEventInfo.created_at));
          elements.push(undefined);
          elements.push(undefined);
          elements.push(0);
          if (currentEventInfo.type == "service") {
            name = currentEventInfo.service && currentEventInfo.service.name;
            status = currentEventInfo.status;
            elements.push(
              "point { size: 6; shape-type: square; fill-color: " +
                this.getStatusStyle(status) +
                "; visible: true; sides: 5; dent: 0.05; ",
            );
            elements.push(this.buildServiceEvents(name, status));
          } else if (currentEventInfo.type == "node") {
            name = currentEventInfo.node && currentEventInfo.node.name;
            status = currentEventInfo.status;
            elements.push(
              "point { size: 6; shape-type: triangle; fill-color: " +
                this.getStatusStyle(status) +
                "; visible: true; ",
            );
            elements.push(this.buildNodeEvents(name, status));
          } else if (currentEventInfo.type == "network") {
            name = currentEventInfo.network && currentEventInfo.network.name;
            status = currentEventInfo.status;
            elements.push(
              "point { size: 6; shape-type: star; fill-color: " +
                this.getStatusStyle(status) +
                "; visible: true; ",
            );
            elements.push(this.buildNetworkEvents(currentEventInfo, status));
          } else {
            elements.push(undefined);
            elements.push(undefined);
          }
        }
      }
      // plot only root container and ignore others filesystem - LAT-10825 to avoid data mismatch during upgrade time
      if (elements.length > 0) data.push(elements);
    }
    let computedMaxVal = byteTxValues;
    let ticks = [
      { v: 0, f: "0" },
      {
        v: computedMaxVal / 2,
        f: humanizeMetric(computedMaxVal / 2, true, true),
      },
      {
        v: computedMaxVal + computedMaxVal / 4,
        f: humanizeMetric(computedMaxVal + computedMaxVal / 4, true, true),
      },
    ];
    let display_name = this.props.NodeStatsViewModel.currentContainerDisplayName
      ? this.props.NodeStatsViewModel.currentContainerDisplayName
      : null;
    if (events.length === 0) {
      data[0] = [
        "Time",
        display_name ? display_name + " : Total " : "Total",
        display_name ? display_name + " : Used " : "Used",
      ];
    } else {
      data[0] = [
        "Time",
        display_name ? display_name + " : Total " : "Total",
        display_name ? display_name + " : Used " : "Used",
        "",
        { type: "string", role: "style" },
        { type: "string", role: "tooltip", p: { html: true } },
      ];
    }
    //  LAT-10761 To avoid unwanted error in rendering
    let indx = data.findIndex(item => {
      return (item && item[1] && item[1].v >= 0) || (item[2] && item.v >= 0);
    });
    return { data: indx !== -1 && data.length > 2 ? data : [], ticks: ticks };
  }

  render() {
    let chartData =
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.containerId
        ? this.drawCpuTotalUsage()
        : this.props.NodeStatsViewModel.computeContainerStats.filesystem_stats;
    let stats_data = this.state.data;
    let ChartInfo = props => {
      return !this.props.NodeStatsViewModel.loading &&
        !this.props.ServiceStatsViewModel.loading ? (
        <div>
          {stats_data.length > 0 &&
          stats_data[0] &&
          stats_data[0].filesystem[0] &&
          stats_data[0].filesystem[0].device.includes("ROOT") &&
          chartData &&
          chartData.data.length > 0 ? (
            <div
              className="chartWithOverlay"
              style={{ position: "relative", width: "auto" }}
            >
              <Chart
                chartType="LineChart"
                data={chartData && chartData.data ? chartData.data : chartData}
                chartEvents={[
                  {
                    eventName: "ready",
                    callback: ({ chartWrapper, google }) => {
                      const chart = chartWrapper.getChart();
                      google.visualization.events.addListener(
                        chart,
                        "click",
                        e => {
                          let currentSelection = e.targetID.split("#");
                          if (
                            currentSelection &&
                            currentSelection.length > 1 &&
                            currentSelection[1]
                          ) {
                            let selectedPoint = parseInt(currentSelection[1]);

                            // to avoid negative index LAT-11383
                            if (selectedPoint === -1) {
                              selectedPoint = 0;
                            }
                            let elementId = chartWrapper
                              .getDataTable()
                              .getValue(selectedPoint, 0);
                            let element = document.getElementById(elementId);
                            if (element) {
                              this.setState({ chartModalvisible: false });
                              // Finding a class with (creates an array of results)
                              let x = document.getElementsByClassName(
                                "statsfocusable-events",
                              );

                              if (x.length > 0) {
                                // Removing a class
                                x[0].classList.remove("statsfocusable-events");
                              }
                              element.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                                inline: "center",
                              });
                              element.classList.add("statsfocusable-events");
                            } else {
                              let x = document.getElementsByClassName(
                                "statsfocusable-events",
                              );
                              if (x.length > 0) {
                                // Removing a class
                                x[0].classList.remove("statsfocusable-events");
                              }
                            }
                          }
                        },
                      );
                    },
                  },
                ]}
                options={{
                  curveType: "function",
                  series:
                    this.eventModel &&
                    this.eventModel.eventList &&
                    this.eventModel.eventList.length <= 0
                      ? {
                          0: { color: "#6f9654" },
                          1: { color: "#1c91c0" },
                        }
                      : {
                          0: { color: "#6f9654" },
                          1: { color: "#1c91c0" },
                          [chartData.data &&
                          chartData.data[0] &&
                          chartData.data[0].length - 4]: { color: "" },
                        },
                  vAxis: {
                    legend: {
                      position: "left",
                    },
                    minValue: 2,
                    viewWindow: { min: 0 },
                    ticks: chartData && chartData.ticks ? chartData.ticks : {},
                    textStyle: { color: this.textColor },
                    titleTextStyle: { color: this.textColor },
                  },
                  legend: {
                    position: "right",
                    textStyle: { color: this.textColor },
                  },
                  theme: "material",
                  tooltip: {
                    isHtml: true,
                  },
                  explorer: {
                    keepInBounds: false,
                    actions: ["dragToZoom", "rightClickToReset"],
                  },
                  focusTarget: "category",
                  hAxis: {
                    format: "hh:mm a ",
                    textStyle: { color: this.textColor },
                  },
                  chartArea: props.chartArea,
                }}
              />
            </div>
          ) : (
            getCurrentlocaleText("stats.nometrics.text")
          )}
        </div>
      ) : (
        <div className="chart-spinner">
          <Spin tip="Loading..." />
        </div>
      );
    };
    return (
      <Card
        title={
          <span>
            <span>
              {getCurrentlocaleText("stats.filesystem.title.text")}{" "}
              {stats_data &&
              stats_data[0] &&
              stats_data[0].filesystem[0] &&
              stats_data[0].filesystem[0].device.includes("ROOT")
                ? "(" + stats_data[0].filesystem[0].device + ")"
                : null}
            </span>
            <span
              className="stats-maximize-icon"
              onClick={() => {
                this.setState({
                  chartModalvisible: true,
                });
              }}
            >
              <Icons type="ai" name="AiOutlineFullscreen" title={"Maximize"} />
            </span>
          </span>
        }
        bordered={false}
        hoverable={false}
      >
        {this.state.chartModalvisible && (
          <Modal
            title={getCurrentlocaleText("stats.card.memory.title.text")}
            visible={true}
            bodyStyle={{ minHeight: 250 }}
            width={1200}
            maskClosable={false}
            onCancel={() => {
              this.setState({
                chartModalvisible: false,
              });
            }}
            footer={[
              <Button
                key="submit"
                type="primary"
                onClick={() => {
                  this.setState({
                    chartModalvisible: false,
                  });
                }}
              >
                Close
              </Button>,
            ]}
          >
            <ChartInfo
              chartArea={{
                left: "8%",
                top: "5%",
                width: "100%",
                height: "85%",
                right: "15%",
                bottom: "10%",
              }}
            />
          </Modal>
        )}
        <ChartInfo
          chartArea={{
            left: "10%",
            top: "8%",
            width: "65%",
            height: "70%",
            right: "25%",
          }}
        />
      </Card>
    );
  }
}

export default FileSystemChart;
