import React, { Component } from "react";
import { Upload, Radio, message, Spin, Row, Col } from "antd";
import { getCurrentlocaleText } from "../../Core/Utils";
import {
  InfoCircleOutlined,
  LoadingOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import BulkConfigController from "../../controller/BulkConfigController";
import BulkDrag from "../../assets/img/bulk-drag.svg";

const { Dragger } = Upload;
class UploadComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRadioValue: 1,
      uploading: false,
      success: false,
    };
    this.props.showFooter(this.state.success);
  }
  onUploadStart = () => {
    this.setState({ uploading: true });
  };
  handleRadioChange = e => {
    this.setState({ selectedRadioValue: e.target.value });
  };
  onUploadFinish = () => {
    this.setState({
      uploading: false,
      success: true,
    });
    message.success("File uploaded successfully.");
  };
  onUploadError = error => {
    this.setState({ uploading: false });
    message.error("File upload failed.");
  };
  customRequest = info => {
    let formData = new FormData();
    formData.append("file", info.file);
    formData.append("create_under", this.props.orgId);
    let action = "";
    if (this.state.selectedRadioValue === 1) {
      action = "create";
    } else if (this.state.selectedRadioValue === 2) {
      action = "edit";
    }
    if (this.props.resource) {
      BulkConfigController.bulkJob(
        this.props.resource,
        formData,
        action,
        info,
        event => {
          info.onProgress({ percent: (event.loaded / event.total) * 100 });
        },
      )
        .then(response => {
          this.setState({
            uploading: false,
            success: true,
          });
          this.props.showFooter(true);
        })
        .catch(error => {
          this.setState({
            uploading: false,
          });
        });
    }
  };
  render() {
    const draggerProps = {
      onChange: info => {
        const { status } = info.file;
        if (status === "uploading") {
          this.onUploadStart();
        } else if (status === "done") {
          this.onUploadFinish();
        } else if (status === "error") {
          this.onUploadError();
        }
      },
      showUploadList: false,
    };
    let infoText = "";
    if (this.props.resource === "Network") {
      infoText = getCurrentlocaleText("bulk.network.upload.description");
    }
    const draggerStyle = {
      backgroundColor: "#fff",
      border: "none",
    };
    const antIcon = <LoadingOutlined className="ant-upload-drag-icon" spin />;
    let showUploadModal = (
      <div>
        <div className="bulk-modal-title">
          <strong>{getCurrentlocaleText("bulk.configure.modal.title")}</strong>
        </div>
        {!this.state.success && (
          <div style={{ padding: "15px 0" }}>
            <Radio.Group
              value={this.state.selectedRadioValue}
              onChange={this.handleRadioChange}
            >
              <Radio value={1}>
                {getCurrentlocaleText("bulk.new.resource", {
                  0: this.props.resource,
                })}
              </Radio>
              <Radio value={2}>
                {getCurrentlocaleText("bulk.existing.resource", {
                  0: this.props.resource,
                })}
              </Radio>
            </Radio.Group>
          </div>
        )}
        <div className="modal-body">
          <Dragger
            customRequest={this.customRequest}
            style={draggerStyle}
            {...draggerProps}
          >
            {!this.state.uploading && !this.state.success && (
              <div>
                <p className="ant-upload-drag-icon">
                  <BulkDrag
                    style={{
                      width: "48px",
                      height: "48px",
                      verticalAlign: "middle",
                      fill: "currentColor",
                      overflow: "hidden",
                      paddingTop: "1px",
                    }}
                  />
                </p>
                <p className="ant-upload-text">
                  {getCurrentlocaleText("bulk.file.upload")}
                </p>
              </div>
            )}
          </Dragger>
          {this.state.uploading && !this.state.success && (
            <div className="ant-upload-list-item-uploading">
              <div>
                <Spin
                  style={{
                    fontSize: "48px",
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  indicator={antIcon}
                />
                <p className="ant-upload-text">
                  <br />
                  {getCurrentlocaleText("bulk.file.uploading")}
                </p>
              </div>
            </div>
          )}
          {this.state.success && (
            <div className="ant-upload-list-item-done">
              <div>
                <CheckCircleFilled
                  style={{
                    fontSize: "48px",
                    color: "#66b395",
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
                <p className="ant-upload-text">
                  <br />
                  {getCurrentlocaleText("bulk.file.uploaded")}
                </p>
              </div>
            </div>
          )}
        </div>
        {this.props.resource === "Network" &&
          !this.state.uploading &&
          !this.state.success && (
            <Row>
              <Col>
                <InfoCircleOutlined />
              </Col>
              <Col>
                <div className="ant-upload-hint">
                  {infoText} <sup>*</sup>
                </div>
              </Col>
            </Row>
          )}
      </div>
    );
    return <div>{this.props.showUpload ? showUploadModal : null}</div>;
  }
}
export default UploadComponent;
