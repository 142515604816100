import React, { Component } from "react";
import { Alert } from "antd";
import { observer, inject } from "mobx-react";

@inject("UiStore")
@observer
class FormErrorMessage extends Component {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    this.clearFormErrorMessage();
  }

  componentWillUnmount() {
    this.clearFormErrorMessage();
  }

  clearFormErrorMessage = () => {
    this.props.UiStore.formErrorMessage = null;
  };

  render() {
    const msgStyle = { fontSize: "13px", fontWeight: "500" };
    return (
      <div>
        {this.props.UiStore.formErrorMessage && (
          <div>
            <Alert
              type="error"
              message={this.props.UiStore.formErrorMessage}
              showIcon
              style={msgStyle}
            />
          </div>
        )}
      </div>
    );
  }
}

export default FormErrorMessage;
