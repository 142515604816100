import React, { Component } from "react";
import { Form, Button, Row, Col } from "antd";
import { observer, inject } from "mobx-react";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import { getCurrentlocaleText } from "Core/Utils";
import InputBox from "components/UI-Components/InputBox";
import SettingsController from "controller/SettingsController";
import HelpCard from "components/UI-Components/HelpCard";

const FormItem = Form.Item;

@inject("UserViewModel", "UiStore", "AuthStore")
@observer
class SettingsPage extends Component {
  constructor(props) {
    super(props);
    this.userModel = this.props.UserViewModel;
    this.formRef = React.createRef();
    this.state = {
      id: null,
    };
  }

  componentDidMount() {
    SettingsController.getAnalytics().then(response => {
      if (response) {
        let id =
          response && response.ga4 && response.ga4.id ? response.ga4.id : null;
        this.setState({ id: id }, () => {
          if (this.formRef.current) {
            this.formRef.current.setFieldsValue({
              id: id,
            });
          }
        });
      }
    });
  }

  updateHelp = data => {
    this.props.UiStore.SetStoreData("helpData", data);
    setTimeout(() => {
      let elm = document.querySelector(".help-container .help-content");
      if (elm) {
        elm.innerHTML =
          data && data.data && data.data[0] && data.data[0].content
            ? data.data[0].content
            : "";
      }
    }, 10);
  };

  onFinish = values => {
    if (values && values.id !== null && values.id !== "") {
      let analyticsData = { ga4: { id: values.id } };
      SettingsController.addAnalytics(analyticsData).then(response => {
        if (response) {
          this.props.UiStore.setSuccessMessage(
            getCurrentlocaleText("analytics.success.message"),
          );
        }
      });
    } else if ((values && values.id === null) || values.id === "") {
      SettingsController.removeAnalytics().then(response => {
        if (response) {
          this.props.UiStore.setSuccessMessage(
            getCurrentlocaleText("analytics.delete.message"),
          );
        }
      });
    }
  };

  render() {
    let container = (
      <div
        style={{
          padding: 24,
          marginTop: 2,
          background: "#fff",
          minHeight: 360,
        }}
      >
        <div>
          <Form
            ref={this.formRef}
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 8,
            }}
            onFinish={this.onFinish}
            autoComplete="off"
            labelAlign="left"
            onFocus={this.updateHelp.bind(this, {
              title: getCurrentlocaleText("analytics.measurement.id.label"),
              data: [
                {
                  subtitle: "",
                  content: getCurrentlocaleText("analytics.help.content.1"),
                },
              ],
            })}
          >
            <Row gutter={2} type="flex" align="top" style={{ marginTop: 25 }}>
              <Col
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 13 }}
                lg={{ span: 13 }}
                xl={{ span: 12 }}
                className="card-grid"
                style={{
                  paddingRight: 20,
                  borderRight: "solid 1px rgba(0,0,0,0.2)",
                }}
              >
                <FormItem
                  label={getCurrentlocaleText("analytics.measurement.id.label")}
                  name="id"
                  rules={[
                    {
                      whitespace: true,
                      message: getCurrentlocaleText(
                        "analytics.measurement.id.error.message",
                      ),
                    },
                    {
                      pattern: /^G-.+$/,
                      message: getCurrentlocaleText(
                        "analytics.measurement.id.error.message",
                      ),
                    },
                  ]}
                >
                  <InputBox
                    className="firstInput"
                    placeholder={getCurrentlocaleText(
                      "analytics.measurement.id.placeholder",
                    )}
                  />
                </FormItem>
              </Col>
              <Col
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 11 }}
                lg={{ span: 11 }}
                xl={{ span: 12 }}
              >
                <HelpCard
                  customStyle={{
                    borderLeft: "none",
                  }}
                  span={24}
                />
              </Col>
            </Row>
            <FormItem
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                {getCurrentlocaleText("save.button.text")}
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    );

    return (
      <LoadingComponent loading={this.userModel.loading}>
        {container}
      </LoadingComponent>
    );
  }
}

export default SettingsPage;
