import React, { Component } from "react";
import Icons from "../../components/UI-Components/Icons";

class ClusterUtils extends Component {
  /**
   *  Intialize the cluster iNode Object
   */
  computeNewiNodeData = () => {
    let currentTime = new Date().getTime();
    let newData = {
      key: currentTime.toString(),
      node_id: null,
      color: "default",
      record_value: [],
      priority: 100,
      is_candidate: true,
    };
    return newData;
  };

  removeDuplicates(myArr, key = "key") {
    let tempData = [];
    return myArr.filter((obj, pos, arr) => {
      if (!tempData.includes(obj[key])) {
        tempData.push(obj[key]);
        return obj;
      }
    });
  }

  computeCascaderOptions = vNodes => {
    vNodes = this.removeDuplicates(vNodes, "id");
    let options = [],
      computedInfo = {},
      currentOrgId = null,
      currentNodeId = null;
    let x = 0,
      y = 0;
    vNodes.filter((vNodes, i) => {
      if (options.length <= 0) {
        computedInfo = {
          value: vNodes.organization.id,
          label: vNodes.organization.name,
          tag: (
            <Icons
              type="fa"
              name="FaSitemap"
              className="valign-text-bottom iot-tag-icon-style"
            />
          ),
          children: [
            {
              value: vNodes.id,
              label: vNodes.name,
              tag: (
                <Icons
                  type="fa"
                  name="FaRegHdd"
                  className="valign-text-bottom iot-tag-icon-style"
                />
              ),
            },
          ],
        };
        options.push(computedInfo);
        //}
      } else {
        if (currentOrgId === vNodes.organization.id) {
          if (currentNodeId === vNodes.id) {
            y++;
          } else {
            options[x].children.push({
              value: vNodes.id,
              label: vNodes.name,
              tag: (
                <Icons
                  type="fa"
                  name="FaRegHdd"
                  className="valign-text-bottom iot-tag-icon-style"
                />
              ),
            });
          }
        } else {
          computedInfo = {
            value: vNodes.organization.id,
            label: vNodes.organization.name,
            tag: (
              <Icons
                type="fa"
                name="FaSitemap"
                className="valign-text-bottom iot-tag-icon-style"
              />
            ),
            children: [
              {
                value: vNodes.id,
                label: vNodes.name,
                tag: (
                  <Icons
                    type="fa"
                    name="FaRegHdd"
                    className="valign-text-bottom iot-tag-icon-style"
                  />
                ),
              },
            ],
          };
          options.push(computedInfo);
          x++;
        }
      }
      currentOrgId = vNodes.organization.id;
      currentNodeId = vNodes.id;
    });
    return options;
  };

  getConnectedNodesInfo = (
    clusterediNodesData = [],
    apiResponseInfo,
    intialValues,
  ) => {
    let selectedInodes = [];
    if (apiResponseInfo && apiResponseInfo.length > 0) {
      apiResponseInfo.forEach(node_list => {
        let values = [];
        values.push(intialValues.organization.id);
        values.push(node_list.node.id);
        let is_master = false;
        if (intialValues && intialValues.status && intialValues.status.nodes) {
          let node = intialValues.status.nodes.find(n => {
            return n && n.id === node_list.node.id;
          });
          if (node && node.state && node.state.toUpperCase() === "MASTER") {
            is_master = true;
          }
        }
        let data = {
          key: node_list.node.id,
          node_id: node_list.node.id,
          org_name: intialValues.organization.name,
          org_id: intialValues.organization.id,
          node_name: node_list.node.name,
          record_value: values,
          priority: node_list.config.priority,
          is_candidate: node_list.config.is_candidate
            ? node_list.config.is_candidate
            : false,
          is_master: is_master,
        };
        clusterediNodesData.push(data);
        selectedInodes.push(node_list.node.id);
      });
    }
    return {
      clusterediNodesData: clusterediNodesData,
      selectedInodes: selectedInodes,
    };
  };
}
export default new ClusterUtils();
