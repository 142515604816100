import React, { Component } from "react";
import { Button } from "antd";
import { inject, observer } from "mobx-react";
import Icons from "../UI-Components/Icons";
import InodeController from "../../controller/InodeController";
import OrgController from "../../controller/OrgController";
import FileSaver from "file-saver";

@inject("InodeViewModel", "OrgViewModel")
@observer
export class CTDDownloadReport extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = this.props.InodeViewModel;
    this.orgModel = this.props.OrgViewModel;
    this.orgId = this.props.orgId;
    this.nodeId = this.props.nodeId;
    this.refreshData = "";
  }

  componentDidMount() {
    clearInterval(this.refreshData);
  }

  downloadByTimestamp = () => {
    let params = this.nodeId
      ? this.inodeModel.nodeThreatReportParams
      : this.orgModel.orgThreatReportParams;
    if (this.nodeId) {
      this.downloadNodeThreatReport(params);
    } else {
      this.downloadOrgThreatReport(params);
    }
  };

  /**Download node level threats report */
  downloadNodeThreatReport = params => {
    InodeController.getNodeThreatsDownloadReport(this.nodeId, params).then(
      response => {
        let blob = new Blob([response], {
          type: "text/csv;charset=utf-8;",
        });
        let file = "Threat Report";
        FileSaver.saveAs(blob, file);
      },
    );
  };

  /**Download org level threats report */
  downloadOrgThreatReport = params => {
    OrgController.getOrgThreatsDownloadReport(this.orgId, params).then(
      response => {
        let blob = new Blob([response], {
          type: "text/csv;charset=utf-8;",
        });
        let file = "Threat Report";
        FileSaver.saveAs(blob, file);
      },
    );
  };

  render() {
    return (
      <Button
        type="link"
        size={"small"}
        className="report-icon-color pr-15"
        onClick={this.downloadByTimestamp}
      >
        <Icons
          style={{ fontSize: 16, marginBottom: "2px" }}
          type="gr"
          name="GrDocumentDownload"
        />
        Download Report
      </Button>
    );
  }
}
