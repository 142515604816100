import React, { Component } from "react";
import { Modal, Space, Radio, Button } from "antd";
import { getCurrentlocaleText } from "../../Core/Utils";
import BulkTable from "./BulkTable";
import UploadComponent from "./UploadComponent";
class BulkConfigureModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bulkActionType: 0,
      allButtonClicked: true,
    };
  }
  showFooter = status => {
    this.setState({ showFooter: status });
  };
  bulkActionTypeOnChange = e => {
    this.setState({ bulkActionType: e.target.value });
    this.props.getTableData(e.target.value);
  };
  viewHistory = () => {
    this.props.showTableModal();
  };

  onClose = () => {
    this.handleButtonClick("0");
    this.props.closeModal();
  };

  handleButtonClick = value => {
    this.setState({
      bulkActionType: value,
      allButtonClicked: value === "0",
      successfulButtonClicked: value === "1",
      failedButtonClicked: value === "2",
      successButtonClicked: value === "3",
      inprogressButtonClicked: value === "4",
    });
  };
  render() {
    let container = (
      <Modal
        visible={this.props.showUpload || this.props.showTable}
        closable={true}
        maskClosable={false}
        destroyOnClose={true}
        footer={
          !this.props.showTable && this.state.showFooter
            ? [
                <Button key="ok" onClick={this.viewHistory}>
                  {getCurrentlocaleText("bulk.configure.modal.view.history")}
                </Button>,
                <Button
                  key="cancel"
                  type="primary"
                  onClick={this.props.closeModal}
                >
                  {"Close"}
                </Button>,
              ]
            : this.props.showTable
            ? [
                <Button key="cancel" onClick={this.onClose}>
                  {"Close"}
                </Button>,
              ]
            : null
        }
        width={900}
        height={500}
        onCancel={this.onClose}
      >
        {this.props.showUpload && (
          <UploadComponent
            orgId={this.props.orgId}
            showUpload={this.props.showUpload}
            resource={this.props.resource}
            closeModel={this.props.closeModal}
            showFooter={this.showFooter}
          />
        )}
        {this.props.showTable && (
          <div>
            <div className="bulk-modal-title">
              <h4>
                {getCurrentlocaleText("bulk.configure.modal.history.title")}
              </h4>
            </div>
            <div>
              <Space className="bulk-radio-container">
                <div className="bulk-radio-group-wrapper">
                  <div className="bulk-radio-group-title">
                    <Radio.Group
                      value={this.state.bulkActionType}
                      onChange={this.bulkActionTypeOnChange}
                    >
                      <Radio.Button
                        className={
                          this.state.allButtonClicked
                            ? "button-style clicked"
                            : "button-style"
                        }
                        onClick={() => this.handleButtonClick("0")}
                        value="0"
                        defaultChecked
                      >
                        All
                      </Radio.Button>
                      <Radio.Button
                        className={
                          this.state.successfulButtonClicked
                            ? "button-style clicked"
                            : "button-style"
                        }
                        onClick={() => this.handleButtonClick("1")}
                        value="1"
                      >
                        {getCurrentlocaleText(
                          "bulk.configure.modal.successful",
                        )}
                      </Radio.Button>
                      <Radio.Button
                        className={
                          this.state.failedButtonClicked
                            ? "button-style clicked"
                            : "button-style"
                        }
                        onClick={() => this.handleButtonClick("2")}
                        value="2"
                      >
                        {getCurrentlocaleText("bulk.configure.modal.failed")}
                      </Radio.Button>
                      <Radio.Button
                        className={
                          this.state.successButtonClicked
                            ? "button-style clicked"
                            : "button-style"
                        }
                        onClick={() => this.handleButtonClick("3")}
                        value="3"
                      >
                        {getCurrentlocaleText(
                          "bulk.configure.modal.partial.success",
                        )}
                      </Radio.Button>
                      <Radio.Button
                        className={
                          this.state.inprogressButtonClicked
                            ? "button-style clicked"
                            : "button-style"
                        }
                        onClick={() => this.handleButtonClick("4")}
                        value="4"
                      >
                        {getCurrentlocaleText(
                          "bulk.configure.modal.inprogress",
                        )}
                      </Radio.Button>
                    </Radio.Group>
                  </div>
                </div>
              </Space>
            </div>
            <BulkTable
              columns={this.props.columns}
              dataSource={this.props.dataSource}
              pagination={false}
              scroll={this.props.scroll}
              style={{
                backgroundColor: "white",
                fontSize: "16px",
              }}
            />
          </div>
        )}
      </Modal>
    );
    return (
      <div>
        {this.props.showUpload
          ? container
          : this.props.showTable
          ? container
          : null}
      </div>
    );
  }
}
export default BulkConfigureModal;
