import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { getCurrentlocaleText } from "Core/Utils";
import { Button, Popconfirm } from "antd";
import Icons from "components/UI-Components/Icons";
import UserController from "controller/UserController";

@inject("ModelStore")
@observer
class Reset2FA extends Component {
  constructor(props) {
    super(props);
  }

  disable2Fa = () => {
    let user_id = this.props.user_id;
    UserController.ResetTwofactor(user_id, false)
      .then(response => {
        this.props.afterCall();
      })
      .catch(error => {
        this.props.afterCall();
      });
  };

  render() {
    return (
      <div>
        <Popconfirm
          overlayClassName={"custom-inline-popup inline-confirm-totp-title"}
          title={
            <div className="inline-popup" style={{ marginLeft: -20 }}>
              <div className="inline-popup-header">
                {" "}
                <p className="inline-popup-title">
                  <Icons type="md" name="MdPhonelinkErase" />{" "}
                  {getCurrentlocaleText(
                    "user.list.2fa.reset.confirmation.title",
                  ) + "?"}
                </p>
              </div>
              <p className="inline-popup-body">
                {
                  <span>
                    <p>
                      {getCurrentlocaleText(
                        "user.profile.2fa.reset.confirmation.message",
                      )}
                    </p>
                  </span>
                }
              </p>
            </div>
          }
          okText={getCurrentlocaleText("profile.user.2fa.reset.text")}
          onConfirm={this.disable2Fa}
          cancelText="No"
        >
          <Button
            title={getCurrentlocaleText("users.2fa.reset.title")}
            style={{ minWidth: 50 }}
          >
            {" "}
            <Icons
              type="md"
              name="MdPhonelinkErase"
              style={{ margin: 0, fontSize: 16 }}
            />
          </Button>
        </Popconfirm>
      </div>
    );
  }
}
export default Reset2FA;
