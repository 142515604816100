import React, { Component } from "react";
import { Col } from "antd";
import { inject, observer } from "mobx-react";

@inject("ModelStore", "UiStore")
@observer
class HelpCard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Col
        span={this.props.span ? this.props.span : 10}
        className="helpcard help-container"
        style={this.props.customStyle ? this.props.customStyle : {}}
      >
        <h3 className="help-title">
          {this.props.UiStore.helpData.title
            ? this.props.UiStore.getHelpcontent.title
            : null}
        </h3>
        {this.props.UiStore.getHelpcontent.data &&
          this.props.UiStore.getHelpcontent.data.map((item, i) => {
            return (
              <div key={i + "help-div"}>
                <h3 className="help-subtitle" key={i + "help-subtitle"}>
                  {item.subtitle}
                </h3>
                <div className="help-content" key={i + "help-content"}>
                  {item.content}
                </div>
                <br />
              </div>
            );
          })}
      </Col>
    );
  }
}
export default HelpCard;
