import React, { Component } from "react";
import { Card, Select, Row, Col, Progress } from "antd";
import { getCurrentlocaleText } from "Core/Utils";
import { observer, inject } from "mobx-react";

const Option = Select.Option;

@inject("AuthStore")
@observer
class SummaryOverViewChart extends Component {
  constructor(props) {
    super(props);
    // GLOBAL_SETTINGS is configured form node js
    this.GLOBAL_SETTINGS = GLOBAL_SETTINGS;
    this.primaryColor =
      this.GLOBAL_SETTINGS && this.GLOBAL_SETTINGS.primary_color
        ? this.GLOBAL_SETTINGS.primary_color
        : "#365c6f";
  }

  render() {
    return (
      <div
        className="iot-pointer-cursor"
        onClick={() => {
          this.props.redirectToAdvnancedStats();
        }}
      >
        {this.props.value && (
          <span>
            <span className="network-over-view-header">{this.props.type}</span>
            <Card bordered={false} hoverable={false}>
              <Row type="flex" align="middle">
                <Col
                  span={24}
                  className={
                    screen.width < 1500 ? "stats-gauge-hover-card-width" : null
                  }
                >
                  <center>
                    {this.props.value != "NA" ? (
                      <span className="card-container">
                        <Progress
                          type="dashboard"
                          strokeColor={{
                            "100%": this.primaryColor,
                          }}
                          style={{ width: 85 }}
                          percent={parseFloat(this.props.value.toFixed(1))}
                          format={percent => `${percent} %`}
                          strokeWidth={4}
                        />
                        {this.props.extraInfo && (
                          <div className="card-overlay">
                            <div className="card-child-content stats-overview-gauge-overlay">
                              <Card
                                className="stats-overview-child"
                                bodyStyle={
                                  screen.width < 1500
                                    ? {
                                        paddingLeft: 5,
                                        paddingRight:
                                          this.props.type === "CPU Usage"
                                            ? 30
                                            : this.props.type === "Memory Usage"
                                            ? 13
                                            : 24,
                                      }
                                    : { paddingLeft: 5 }
                                }
                              >
                                <center
                                  className={
                                    this.props.type === "Memory Usage"
                                      ? "stats-gauge-container-memory"
                                      : ""
                                  }
                                >
                                  <Row>
                                    <Col span={12}>
                                      {this.props.extraInfo &&
                                        this.props.extraInfo.title_1}
                                    </Col>
                                    <Col span={12}>
                                      {this.props.extraInfo &&
                                        this.props.extraInfo.value_1}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col span={12}>
                                      {this.props.extraInfo &&
                                        this.props.extraInfo.title_3}
                                    </Col>
                                    <Col span={12}>
                                      {this.props.extraInfo &&
                                        this.props.extraInfo.value_3}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col span={12}>
                                      {this.props.extraInfo &&
                                        this.props.extraInfo.title_4}
                                    </Col>
                                    <Col span={12}>
                                      {this.props.extraInfo &&
                                        this.props.extraInfo.value_4}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col span={12}>
                                      {this.props.extraInfo &&
                                        this.props.extraInfo.title_2}
                                    </Col>
                                    <Col span={12}>
                                      {this.props.extraInfo &&
                                        this.props.extraInfo.value_2}
                                    </Col>
                                  </Row>
                                </center>
                              </Card>
                            </div>
                          </div>
                        )}
                      </span>
                    ) : (
                      getCurrentlocaleText("stats.nometrics.text")
                    )}
                  </center>
                </Col>
              </Row>
            </Card>
          </span>
        )}
      </div>
    );
  }
}

export default SummaryOverViewChart;
