import React from "react";
import Icons from "components/UI-Components/Icons";
import {
  getFirewallProtocolAndPort,
  getCurrentlocaleText,
  isEmpty,
} from "Core/Utils";
import { Tag, Popover } from "antd";

class WanFirewallFunc {
  getfilteredData = (allData, NetworkID) => {
    if (NetworkID) {
      return allData[NetworkID];
    } else {
      return allData;
    }
  };
  getNetworkGroupInfo = (networkId, InterfaceLabels) => {
    let Networkdata = InterfaceLabels || [];
    let data = getCurrentlocaleText("general.unknown.text.value");
    if (Networkdata.length > 0) {
      Networkdata.forEach(network => {
        if (network.id === networkId) {
          let string =
            (network.cluster ? network.cluster.name : network.node.name) +
            network.name;
          data =
            string.length < 15 ? (
              <Tag
                key={network.id}
                style={{ height: string.length > 10 ? "auto" : 22 }}
                className="iot-tag-label-color"
              >
                {" "}
                <Icons type={"fa"} name="FaRegHdd" />
                <span className="iot-tag-label">
                  {" "}
                  {network.cluster ? network.cluster.name : network.node.name}
                </span>
                / {string.length > 15 ? <br /> : null}
                <Icons
                  type={network.cluster ? "bs" : "fa"}
                  name={network.cluster ? "BsBoundingBox" : "FaRegHdd"}
                />{" "}
                <span className="iot-tag-label">{network.name}</span>
              </Tag>
            ) : (
              <Popover
                placement="top"
                content={
                  <Tag key={network.id} className="iot-tag-label-color">
                    {" "}
                    <Icons
                      type={network.cluster ? "bs" : "fa"}
                      name={network.cluster ? "BsBoundingBox" : "FaRegHdd"}
                    />
                    <span className="iot-tag-label">
                      {network.cluster
                        ? network.cluster.name
                        : network.node.name}
                    </span>
                    /
                    <Icons
                      type={"fa"}
                      name="FaConnectdevelop"
                      className="iot-tag-label"
                    />
                    <span className="iot-tag-label">{network.name}</span>
                  </Tag>
                }
              >
                <span className="iot-tag-label">
                  <Tag
                    key={network.id}
                    className={"firewall-rules-tag-content iot-tag-label-color"}
                  >
                    {" "}
                    <Icons
                      type={"fa"}
                      name="FaConnectdevelop"
                      className="iot-tag-label"
                    />
                    {network.name}
                  </Tag>
                </span>
              </Popover>
            );
        }
      });
    }
    return data;
  };

  computeCascaderInterfaceOptions = Networks => {
    let selectByNameOptions = [],
      selectByClusterOptions = [],
      computedData = [],
      networkLabels = [];
    Networks.forEach((network, i) => {
      let labels = network.metadata ? network.metadata.labels : null;
      let labelTags = labels ? this.getTagKeyValuesAll(labels) : [];
      if (labelTags.length > 0) {
        labelTags.forEach(label => {
          networkLabels.push(label);
        });
      }
      // Select by name (Node networks only)
      if (network.node) {
        // Get org index
        let orgIndex = selectByNameOptions.findIndex(option => {
          return (
            option && option.value && option.value === network.organization.id
          );
        });
        // Check org
        if (orgIndex === -1) {
          let computedOption = {
            value: network.organization.id,
            label: network.organization.name,
            tag: (
              <Icons
                type="fa"
                name="FaSitemap"
                className="middle iot-tag-icon-style"
              />
            ),
            children: [
              {
                value: network.node.id,
                label: network.node.name,
                tag: (
                  <Icons
                    type="fa"
                    name="FaSitemap"
                    className="middle iot-tag-icon-style"
                  />
                ),
                children: [
                  {
                    value: network.id,
                    label: network.name,
                    tag: (
                      <Icons
                        type="fa"
                        name="FaConnectdevelop"
                        className="middle iot-tag-icon-style"
                      />
                    ),
                  },
                ],
              },
            ],
          };
          selectByNameOptions.push(computedOption);
        } else {
          // Get node index
          let nodeIndex = selectByNameOptions[orgIndex].children.findIndex(
            option => {
              return option && option.value && option.value === network.node.id;
            },
          );
          // Check node
          if (nodeIndex === -1) {
            selectByNameOptions[orgIndex].children.push({
              value: network.node.id,
              label: network.node.name,
              tag: (
                <Icons
                  type="fa"
                  name="FaSitemap"
                  className="middle iot-tag-icon-style"
                />
              ),
              children: [
                {
                  value: network.id,
                  label: network.name,
                  tag: (
                    <Icons
                      type="fa"
                      name="FaConnectdevelop"
                      className="middle iot-tag-icon-style"
                    />
                  ),
                },
              ],
            });
          } else {
            selectByNameOptions[orgIndex].children[nodeIndex].children.push({
              value: network.id,
              label: network.name,
              tag: (
                <Icons
                  type="fa"
                  name="FaConnectdevelop"
                  className="middle iot-tag-icon-style"
                />
              ),
            });
          }
        }
      }
      // Select by cluster (Cluster only)
      else if (network.cluster) {
        // Get org index
        let orgIndex = selectByClusterOptions.findIndex(option => {
          return (
            option && option.value && option.value === network.organization.id
          );
        });
        // Check org
        if (orgIndex === -1) {
          let computedOption = {
            value: network.organization.id,
            label: network.organization.name,
            tag: (
              <Icons
                type="fa"
                name="FaSitemap"
                className="middle iot-tag-icon-style"
              />
            ),
            children: [
              {
                value: network.cluster.id,
                label: network.cluster.name,
                tag: (
                  <Icons
                    type="bs"
                    name="BsBoundingBox"
                    className="middle iot-tag-icon-style"
                  />
                ),
                children: [
                  {
                    value: network.id,
                    label: network.name,
                    tag: (
                      <Icons
                        type="fa"
                        name="FaConnectdevelop"
                        className="middle iot-tag-icon-style"
                      />
                    ),
                  },
                ],
              },
            ],
          };
          selectByClusterOptions.push(computedOption);
        } else {
          // Get cluster index
          let clusterIndex = selectByClusterOptions[
            orgIndex
          ].children.findIndex(option => {
            return (
              option && option.value && option.value === network.cluster.id
            );
          });
          // Check cluster
          if (clusterIndex === -1) {
            // allow only wan network to select for cluster
            //if (network.is_wan_network) {
            selectByClusterOptions[orgIndex].children.push({
              value: network.cluster.id,
              label: network.cluster.name,
              tag: (
                <Icons
                  type="bs"
                  name="BsBoundingBox"
                  className="middle iot-tag-icon-style"
                />
              ),
              children: [
                {
                  value: network.id,
                  label: network.name,
                  tag: (
                    <Icons
                      type="fa"
                      name="FaConnectdevelop"
                      className="middle iot-tag-icon-style"
                    />
                  ),
                },
              ],
            });
            //}
          } else {
            // allow only wan network to select for cluster
            //if (network.is_wan_network) {
            selectByClusterOptions[orgIndex].children[
              clusterIndex
            ].children.push({
              value: network.id,
              label: network.name,
              tag: (
                <Icons
                  type="fa"
                  name="FaConnectdevelop"
                  className="middle iot-tag-icon-style"
                />
              ),
            });
            //}
          }
        }
      }
    });
    if (selectByNameOptions.length > 0) {
      computedData.push({
        value: "use_existing_network",
        label: getCurrentlocaleText(
          "firewall.rule.select_network.placeholder1.text",
        ),
        tag: (
          <Icons
            type="fa"
            name="FaRegHdd"
            className="middle iot-tag-icon-style"
          />
        ),
        children: selectByNameOptions,
      });
    }
    if (selectByClusterOptions.length > 0) {
      computedData.push({
        value: "use_existing_cluster",
        label: getCurrentlocaleText(
          "firewall.rule.select_network.placeholder4.text",
        ),
        tag: (
          <Icons
            type="fa"
            name="FaRegHdd"
            className="middle iot-tag-icon-style"
          />
        ),
        children: selectByClusterOptions,
      });
    }
    if (this.removeDuplicates(networkLabels).length > 0) {
      computedData.push({
        value: "use_existing_label",
        label: getCurrentlocaleText(
          "firewall.rule.select_network.placeholder2.text",
        ),
        tag: (
          <Icons
            type="fa"
            name="FaRegHdd"
            className="middle iot-tag-icon-style"
          />
        ),
        children: this.removeDuplicates(networkLabels),
      });
    }
    computedData.push({
      value: "#newlabel",
      label: getCurrentlocaleText(
        "firewall.rule.select_network.placeholder3.text",
      ),
      tag: (
        <Icons
          type="fa"
          name="FaRegHdd"
          className="middle iot-tag-icon-style"
        />
      ),
      children: [],
    });
    return computedData;
  };

  removeDuplicates(myArr) {
    let tempData = [];
    return myArr.filter((obj, pos, arr) => {
      if (!tempData.includes(obj.value)) {
        tempData.push(obj.value);
        return obj;
      }
    });
  }
  /**
   * Prepare Firewall table under Networks
   * @param {*} networks
   */
  FirewallTableData(
    networks,
    fwgroups,
    showUnResolvedRules = false,
    isFromTest = false,
  ) {
    let firewall_rules = {};
    let data = [];
    if (
      networks &&
      networks.status &&
      networks.status.firewall_status &&
      networks.status.firewall_status.rules
    ) {
      data = data.concat(
        this.getFirewallRules(
          networks.status.firewall_status.rules,
          "Inbound",
          fwgroups,
          showUnResolvedRules,
          networks,
          isFromTest,
        ),
      );
    }
    firewall_rules = data;
    return firewall_rules;
  }
  /**
   * Prepare Firewall table under Networks
   * @param {*} networks
   */
  FirewallTableAllData(
    networks,
    fwgroups,
    showUnResolvedRules = false,
    isFromTest = false,
  ) {
    let firewall_rules = [];
    let data = [];
    if (networks.length > 0) {
      networks.forEach(network => {
        data = data.concat(
          this.FirewallTableData(
            network,
            fwgroups,
            showUnResolvedRules,
            isFromTest,
          ),
        );
      });
      firewall_rules = data;
    }
    return firewall_rules;
  }
  computeDiodeGroup(firewallID, groups) {
    let data = { group_name: "NA", is_diode: false };
    groups.forEach(group => {
      if (group.id === firewallID) {
        data.group_name = group.name;
        if (
          group.metadata &&
          group.metadata.labels &&
          group.metadata.labels.hasOwnProperty("_iotium.firewall.diode")
        )
          data.is_diode = true;
      }
    });
    return data;
  }
  /**
   * Prepare Rules Array for firewall
   */
  getFirewallRules = (
    rules,
    type,
    groups = {},
    showUnResolvedRules = false,
    network,
    isFromTest = false,
  ) => {
    let data = [];
    let priority_list = [];
    rules.forEach((val, index) => {
      let obj = getFirewallProtocolAndPort(
        `${val.protocol.toLowerCase()}${val.destination_port &&
          val.destination_port.start}-${val.destination_port &&
          val.destination_port.end}`,
      );
      let group_name = "NA",
        is_diode = false;
      if (val.more_info && val.more_info.firewall_group) {
        group_name = this.computeDiodeGroup(
          val.more_info.firewall_group,
          groups,
        ).group_name;
        is_diode = this.computeDiodeGroup(val.more_info.firewall_group, groups)
          .is_diode;
      }
      let random = Math.random()
        .toString()
        .replace(".", "");
      data.push({
        key: isFromTest ? `${type}${index}` : `${type}${index}-${random}`,
        id: isFromTest ? `${type}${index}` : `${type}${index}-${random}`,
        type: type,
        direction: val.direction,
        protocol: !isEmpty(obj.key) ? obj.applicationProtocol : val.protocol,
        port: val.destination_port
          ? val.destination_port.start === val.destination_port &&
            val.destination_port.end
            ? val.destination_port && val.destination_port.start !== -1
              ? `${val.destination_port && val.destination_port.start}`
              : `1 - 65535`
            : val.destination_port &&
              val.destination_port.start != val.destination_port.end
            ? `${val.destination_port &&
                val.destination_port.start} - ${val.destination_port &&
                val.destination_port.end}`
            : `${val.destination_port.start}`
          : "1 - 65535",
        source_port: val.source_port
          ? val.source_port.start === val.source_port && val.source_port.end
            ? val.source_port && val.source_port.start !== -1
              ? `${val.source_port && val.source_port.start}`
              : `1 - 65535`
            : val.source_port && val.source_port.start != val.source_port.end
            ? `${val.source_port &&
                val.source_port.start} - ${val.source_port &&
                val.source_port.end}`
            : `${val.source_port.start}`
          : "1 - 65535",
        source_ipaddress: val.source_ip,
        source_tag: val.source_tag,
        destination_ipaddress: val.destination_ip,
        destination_tag: val.destination_tag,
        source_network: val.source_network_domain
          ? val.source_network_domain
          : this.getSelectorValues(val.source_network),
        destination_network: val.destination_network_domain
          ? val.destination_network_domain
          : this.getSelectorValues(val.destination_network),
        byte_count: val.byte_count,
        packet_count: val.packet_count,
        priority: val.priority,
        action: val.action,
        is_generated: val.generated ? val.generated : false,
        is_resolved: val.not_resolved ? false : true,
        show_warning: priority_list.includes(val.priority) ? true : false,
        group_name: group_name,
        is_diode: is_diode,
        network_name: network.name,
        firewall_data: val.firewall ? val.firewall : [],
      });
      priority_list.push(val.priority);
    });
    if (showUnResolvedRules) {
      return data;
    } else {
      return data.filter(d => {
        if (d.is_resolved) return d;
      });
    }
  };
  getSelectorValues = selectors => {
    let Selectedselectors = [];
    for (let key in selectors) {
      Selectedselectors.push(key + ":" + selectors[key]);
    }
    return Selectedselectors;
  };

  filterDefaultFirewallGroups(inputString, InterfaceLabels = []) {
    if (
      inputString &&
      (inputString.includes("_iotium.network.id") ||
        inputString.includes("_iotium.cluster.wan.network.id") ||
        inputString.includes("_iotium.cluster.tan.network.id"))
    ) {
      return this.getNetworkGroupInfo(
        inputString.split(":")[1].trim(),
        InterfaceLabels,
      );
    } else if (inputString && inputString.includes("_iotium.")) {
      return inputString.replace("_iotium.", " ");
    } else {
      return inputString.replace("AnyPeer", "AnyRemoteNetwork");
    }
  }
  checkforAnyString(inputString, InterfaceLabels = []) {
    if (inputString && inputString.includes("@tag:")) {
      return "CIDR Not Resolved";
    }
    switch (inputString) {
      case "0.0.0.0/0":
        return "ANY";
        break;
      case "1 - 65535":
      case "-1 - -1":
      case "-1":
      case "*":
        return "ANY";
        break;
      default:
        return this.filterDefaultFirewallGroups(inputString, InterfaceLabels);
        break;
    }
  }

  getTagKeyValuesAll = labels => {
    let tags = [];
    if (labels) {
      if (labels["_iotium.network.id"]) {
        delete labels["_iotium.network.id"];
      }
      if (labels["_iotium.cluster.id"]) {
        delete labels["_iotium.cluster.id"];
      }
      if (labels["_iotium.cluster.wan.network.id"]) {
        delete labels["_iotium.cluster.wan.network.id"];
      }
      if (labels["_iotium.cluster.tan.network.id"]) {
        delete labels["_iotium.cluster.tan.network.id"];
      }
      for (let key in labels) {
        let trimed_key = key.replace("_iotium.", " ");
        tags.push({
          value: key + ":" + labels[key],
          label: trimed_key + " : " + labels[key],
        });
      }
    }
    return tags;
  };

  getTagColor = action => {
    switch (action) {
      case "ALLOW":
        return "iotium-success";
        break;
      case "DENY":
        return "iotium-danger";
        break;
      default:
        return "default";
        break;
    }
  };
  getStatusPacketsData = data => {
    if (data.packet_count) return data.packet_count;
  };
  /**
   *  Get direction filter check for from and to network selector
   */
  getAllSpecifiedRules = values => {
    let DirectionSpecifiedRules = [];
    if (values && values.length > 0) {
      values.forEach((value, index) => {
        if (value.source_network || value.destination_network) {
          DirectionSpecifiedRules.push("undefined" + index);
        }
      });
    }
    return DirectionSpecifiedRules;
  };
  /**
   *  Get direction filter check for from and to network selector
   */
  getRowCurrentKey = (currentFieldKey = []) => {
    if (currentFieldKey.length > 0) {
      return currentFieldKey.includes("from_network-")
        ? "to_network-"
        : "from_network-";
    } else {
      return "from_network-";
    }
  };
  /**
   *  Get direction filter check for from and to network selector
   */
  filterCurrentKey = currentFieldKey => {
    return currentFieldKey
      .replace("from_network-", "")
      .replace("to_network-", "");
  };
  /**
   *  Get direction filter check for from and to network selector
   */
  getDirectionSpecificRules = (
    OtherFieldValue,
    DirectionSpecifiedRules,
    rowKey,
  ) => {
    if (isEmpty(OtherFieldValue)) {
      let index = DirectionSpecifiedRules.indexOf(rowKey);
      if (index != -1) {
        DirectionSpecifiedRules.splice(index, 1);
      }
    }
    return DirectionSpecifiedRules;
  };
  /**
   * Check for replacing default test case
   */
  replaceDefaultGroupName = group_name => {
    switch (group_name) {
      case "tandefault":
        return getCurrentlocaleText(
          "secuirty_policy.default_name.tandefault.translated_text",
        );
        break;
      case "default":
      case "virtual-ingress":
        return getCurrentlocaleText(
          "secuirty_policy.default_name.default.translated_text",
        );
        break;
      case "egress-only":
        return getCurrentlocaleText(
          "secuirty_policy.default_name.egress_ingress_only.translated_text",
        );
        break;
      case "ingress-only":
        return getCurrentlocaleText(
          "secuirty_policy.default_name.egress_ingress_only.translated_text",
        );
        break;
      case "hairpin-allow":
        return getCurrentlocaleText("networks.hairpinning.label.text");
        break;
      default:
        return group_name;
        break;
    }
  };
  checkForDebugEnabled = network_status => {
    if (
      network_status.policy &&
      network_status.policy.firewall &&
      network_status.policy.firewall.debug
    )
      return true;
    else return false;
  };
}
export default new WanFirewallFunc();
