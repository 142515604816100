import React, { Component } from "react";
import { Layout } from "antd";
import { Switch } from "react-router-dom";
import PublicRoute from "Core/PublicRoute";
import LoginPage from "components/Containers/LoginPage";
import { ConfigProvider } from "antd";
import enUS from "antd/lib/locale-provider/en_US";
import MessageDisplay from "Core/MessageDisplay";
import DocumentTitle from "Core/DocumentTitle";
import { random as randomBg } from "../../assets/img/bg";
import BackgroundImage from "components/UI-Components/BackgroundImage";
import CookieBanner from "components/UI-Components/CookieBanner";
import BrowserCheck from "components/UI-Components/BrowserCheck";

const { Content } = Layout;

class LoginLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let container = (
      <Layout style={{ background: "transparent" }}>
        <DocumentTitle />
        <MessageDisplay />
        <Content>
          <Layout
            style={{ padding: "60px 10px 100px", background: "transparent" }}
          >
            <ConfigProvider locale={enUS}>
              <Switch>
                <PublicRoute exact path="/login" component={LoginPage} />
              </Switch>
            </ConfigProvider>
          </Layout>
        </Content>
        <BrowserCheck />
        <CookieBanner />
      </Layout>
    );

    return (
      <BackgroundImage
        className="login-background"
        src={randomBg()}
        placeHolder=""
      >
        {container}
      </BackgroundImage>
    );
  }
}

export default LoginLayout;
