import React, { Component } from "react";
import { Card, Row, Col, Button } from "antd";
import { observer, inject } from "mobx-react";
import axios from "axios";
import ChangePasswordForm from "components/Forms/ChangePasswordForm";
import { getPasswordExpireUrl } from "Core/Utils.js";
import DarkAppInfo from "components/UI-Components/DarkAppInfo";

@inject("ModelStore", "UiStore", "AuthStore")
@observer
class ChangePasswordPage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // Redirect to login page if password expire url is not present in the session storage.
    if (!getPasswordExpireUrl()) {
      this.loadLoginPage();
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.actionForm.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let url = getPasswordExpireUrl();
        if (url) {
          // Remove confirm password field
          if (values && values.confirm_password) {
            delete values.confirm_password;
          }
          axios
            .post(url, values)
            .then(res => {
              this.props.UiStore.successMessage =
                "Password changed successfully";
              setTimeout(() => {
                this.loadLoginPage();
              }, 1000);
            })
            .catch(error => {
              if (error && error.response) {
                this.props.UiStore.errorMessage = error.response.data
                  ? error.response.data.message
                  : "Error";
              }
            });
        }
      }
    });
  };

  handleCancel = () => {
    this.loadLoginPage();
  };

  loadLoginPage = () => {
    if (this.props.history) {
      if (sessionStorage.changePasswordUrl) {
        delete sessionStorage.changePasswordUrl;
      }
      this.props.history.push("/login");
    }
  };

  render() {
    return (
      <Row type="flex" className="login-card" align="middle" justify="center">
        <Col
          className="login-col"
          lg={{ span: 6 }}
          sm={{ span: 10 }}
          md={{ span: 10 }}
          xs={{ span: 16 }}
        >
          <Card>
            <Row type="flex" align="middle" justify="center">
              <center>
                <DarkAppInfo />
              </center>
            </Row>
            <Row type="flex" align="middle" justify="center">
              <p style={{ margin: "15px 0 15px", textAlign: "center" }}>
                <b>
                  {this.props.UiStore.passwordExpiredMessage &&
                    this.props.UiStore.passwordExpiredMessage}
                </b>
              </p>
            </Row>
            <ChangePasswordForm
              oldPasswordLabel="Old Password"
              newPasswordLabel="New Password"
              confirmPasswordLabel="Confirm New Password"
              errorMessage="Please enter your old password"
              showFormErrorMessage={false}
              ref={c => (this.actionForm = c)}
              showHeader={true}
            />
            <Row type="flex" align="middle" justify="end">
              <div style={{ margin: "10px 0 10px" }}>
                <Button onClick={this.handleCancel}>Cancel</Button>
                <Button
                  type="primary"
                  style={{ marginLeft: 8 }}
                  onClick={this.handleSubmit}
                >
                  Change
                </Button>
              </div>
            </Row>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default ChangePasswordPage;
