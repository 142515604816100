import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { message } from "antd";

@inject("UiStore")
@observer
class SuccessMessageDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successMsg: null,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.displaySuccessMessage(props.message);
  }

  componentDidMount() {
    this.setState({ successMsg: this.props.message });
    this.displaySuccessMessage(this.props.message);
  }

  displaySuccessMessage(success_message) {
    if (success_message && this.state.successMsg !== success_message) {
      let msg_content = <span style={{ fontSize: 14 }}>{success_message}</span>;
      setTimeout(() => {
        /*
          https://github.com/ant-design/ant-design/issues/7793#issuecomment-354249673
        */
        message.success(
          msg_content,
          this.props.UiStore.isForceMessage ? 8 : 3,
          () => {
            this.props.UiStore.setSuccessMessage(null);
          },
        );
      }, 100);
    }
  }

  render() {
    return null;
  }
}

export default SuccessMessageDisplay;
