import React, { Component } from "react";
import { Row, Col, Card, Empty } from "antd";
import { Link } from "react-router-dom";
import { observer, inject } from "mobx-react";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import { getCurrentlocaleText } from "Core/Utils";
import { Doughnut as PieChart } from "react-chartjs-2";
import Icons from "../UI-Components/Icons";
import ClusterController from "../../controller/ClusterController";
import DeviceDiscoveryBarChart from "../Containers/DeviceDiscoveryBarChart";

//colors to define the node status
const StatusColor = {
  ALIVE: "#00A854",
  NEW: "#108EE9",
  UNREACHABLE: "#F04134",
  REBOOTING: "#FFBF00",
};

@inject("AuthStore", "UiStore", "ClusterViewModel", "OrgViewModel")
@observer
class ClusterInfo extends Component {
  constructor(props) {
    super(props);
    this.clusterModel = this.props.ClusterViewModel;
    this.state = {
      deviceDiscovery: false,
    };
    this.color = {};
  }

  componentDidMount() {
    this.getDeviceDiscovery();
  }

  getDeviceDiscovery() {
    ClusterController.getDeviceDiscovery(this.props.clusterId).then(res => {
      if (res && res.enable) {
        this.setState({
          deviceDiscovery: res.enable,
        });
        this.clusterModel.setDeviceDiscovery(res.enable);
        this.getDeviceScans();
      }
    });
  }

  getDeviceScans = () => {
    this.clusterModel.getDeviceScansLoading = true;
    ClusterController.getDeviceScans(this.props.clusterId, {})
      .then(resp => {
        if (resp) {
          this.clusterModel.setDeviceScans([
            {
              name: getCurrentlocaleText("total.scans"),
              count: resp.total_count ? resp.total_count : 0,
            },
            {
              name: getCurrentlocaleText("device.discovery.devices.found.text"),
              count: resp.total_device_count ? resp.total_device_count : 0,
            },
          ]);
        } else {
          this.clusterModel.setDeviceScans([]);
        }
        this.clusterModel.getDeviceScansLoading = false;
      })
      .catch(err => {
        this.clusterModel.setDeviceScans([]);
        this.clusterModel.getDeviceScansLoading = false;
      });
  };

  handleClick = chartElement => {
    const currentOrgId =
      this.props.OrgViewModel.org.id != undefined
        ? this.props.OrgViewModel.org.id
        : this.props.AuthStore.loggedInUser.organization &&
          this.props.AuthStore.loggedInUser.organization.id;
    let key =
      chartElement[0] && chartElement[0]._chart
        ? chartElement[0]._chart.data.labels[chartElement[0]._index]
        : null;
    if (key) {
      key = key.replace(/\s+/g, "");
      let url =
        `/orgs/` +
        currentOrgId +
        `/clusters/` +
        this.props.clusterId +
        `/inodes/status/${key.toLowerCase()}`;
      let route_info = {
        pathname: url,
        state: this.props.location.state,
      };
      this.props.history.push(route_info);
    }
  };

  render() {
    const currentOrgId =
      this.props.OrgViewModel.org.id != undefined
        ? this.props.OrgViewModel.org.id
        : this.props.AuthStore.loggedInUser.organization &&
          this.props.AuthStore.loggedInUser.organization.id;
    const options = {
      responsive: true,
      animation: {
        segmentShowStroke: false,
        animateScale: true,
        easing: "easeOutBounce",
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: -52,
          bottom: -8,
        },
      },
      title: {
        display: true,
        fontSize: 25,
        fontColor: "#373737",
      },
      cutoutPercentage: 65,
      legend: {
        display: true,
        position: "bottom",
        fullWidth: true,
        labels: {
          usePointStyle: true,
          padding: 18,
          fontStyle: "bold",
          fontColor:
            this.GLOBAL_SETTINGS && this.GLOBAL_SETTINGS.text_color
              ? this.GLOBAL_SETTINGS.text_color
              : "#365c6f",
          generateLabels: function(chart) {
            var data = chart.data;
            if (data.labels.length && data.datasets.length) {
              return data.labels.map(function(label, i) {
                var meta = chart.getDatasetMeta(0);
                var ds = data.datasets[0];
                var arc = meta.data[i];
                var custom = (arc && arc.custom) || {};
                var getValueAtIndexOrDefault =
                  Chart.helpers.getValueAtIndexOrDefault;
                var arcOpts = chart.options.elements.arc;
                var fill = custom.backgroundColor
                  ? custom.backgroundColor
                  : getValueAtIndexOrDefault(
                      ds.backgroundColor,
                      i,
                      arcOpts.backgroundColor,
                    );
                var stroke = custom.borderColor
                  ? custom.borderColor
                  : getValueAtIndexOrDefault(
                      ds.borderColor,
                      i,
                      arcOpts.borderColor,
                    );
                var bw = custom.borderWidth
                  ? custom.borderWidth
                  : getValueAtIndexOrDefault(
                      ds.borderWidth,
                      i,
                      arcOpts.borderWidth,
                    );
                // We get the value of the current label
                var value =
                  chart.config.data.datasets[arc._datasetIndex].data[
                    arc._index
                  ];

                return {
                  // Instead of `text: label,`
                  // We add the value to the string
                  text: label + " : " + value,
                  fillStyle: fill,
                  strokeStyle: stroke,
                  lineWidth: bw,
                  hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                  index: i,
                };
              });
            } else {
              return [];
            }
          },
        },
        onHover: function(e) {
          e.target.style.cursor = "pointer";
        },
      },
      tooltips: {
        enabled: true,
        mode: "single",
        intersect: true,
        labelColor: function(tooltipItem, chart) {
          return {
            borderColor: "rgb(255, 0, 0)",
            backgroundColor: "rgb(255, 0, 0)",
          };
        },
      },
      hover: {
        onHover: function(e) {
          var point = this.getElementAtEvent(e);
          if (point.length) e.target.style.cursor = "pointer";
          else e.target.style.cursor = "default";
        },
      },
    };

    let dataCounts = [],
      dataLables = [],
      dataBackgroundcolors = [];
    // data iteration for lables and data.
    for (var key in this.props.ClusterediNodeStatus) {
      if (this.props.ClusterediNodeStatus[key] > 0) {
        dataLables.push(key.toUpperCase());
        dataCounts.push(this.props.ClusterediNodeStatus[key]);
        dataBackgroundcolors.push(StatusColor[key.toUpperCase()]);
      }
    }
    const data = {
      datasets: [
        {
          data: dataCounts,
          backgroundColor: dataBackgroundcolors,
          hoverBackgroundColor: dataBackgroundcolors,
        },
      ],
      // These labels appear in the legend and in the tooltips when hovering different arcs
      labels: dataLables,
    };

    const deviceDiscoveryData = {
      datasets: [
        {
          data: [],
          backgroundColor: dataBackgroundcolors,
          hoverBackgroundColor: dataBackgroundcolors,
        },
      ],
      // These labels appear in the legend and in the tooltips when hovering different arcs
      labels: dataLables,
    };
    let container = (
      <div>
        <Row type="flex" align="strech" className="cluster-info-base-card">
          <Col flex="auto" className="mb-15">
            <Card
              title={
                <span>
                  <Icons type="md" name="MdSettings" style={{ fontSize: 16 }} />
                  &nbsp;{getCurrentlocaleText("inode.systeminfo.text")}{" "}
                </span>
              }
              style={{ height: "100%" }}
              bordered={true}
            >
              <Row gutter={0} type="flex" justify="center">
                <Col span={24}>
                  <Row>
                    <Col span={12} className="node-info-top-row">
                      <strong>
                        {getCurrentlocaleText("inode.systeminfo.name.text")}
                      </strong>
                    </Col>
                    <Col span={12} className="node-info-top-row">
                      <div className="ellipsis node-info-right-column">
                        {this.clusterModel &&
                        this.clusterModel.cluster &&
                        this.clusterModel.cluster.name
                          ? this.clusterModel.cluster.name
                          : "-"}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12} className="node-info-top-row">
                      <strong>
                        {getCurrentlocaleText(
                          "cluster.systeminfo.mastership.election.text",
                        )}
                      </strong>
                    </Col>
                    <Col span={12} className="node-info-top-row">
                      <div className="ellipsis node-info-right-column">
                        {this.clusterModel &&
                        this.clusterModel.cluster &&
                        this.clusterModel.cluster.config &&
                        this.clusterModel.cluster.config.election_network_type
                          ? this.clusterModel.cluster.config
                              .election_network_type
                          : "-"}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col flex="none" className="mb-15">
            <Card
              title={getCurrentlocaleText("cluster.overview.card.title")}
              bordered={true}
              className="d-inline-block mb-0"
              style={{ height: "100%" }}
            >
              {this.clusterModel &&
              this.clusterModel.clustered_inodes &&
              this.clusterModel.clustered_inodes.length > 0 ? (
                <PieChart
                  height={180}
                  width={350}
                  options={options}
                  data={data}
                  getElementAtEvent={this.handleClick}
                />
              ) : (
                <div style={{ minHeight: 200 }}>
                  {getCurrentlocaleText("cluster.form.inode.notfound.text")}
                </div>
              )}
            </Card>
          </Col>
          {this.state.deviceDiscovery && (
            <Col className="mb-15">
              <LoadingComponent
                loading={this.clusterModel.getDeviceScansLoading}
              >
                <Card
                  title={
                    <div className="justify-space-between">
                      <span>
                        {getCurrentlocaleText("device.discovery.text2")}
                      </span>
                      <Link
                        to={`/orgs/${currentOrgId}/clusters/${this.props.clusterId}/devicediscovery`}
                        className="pl-10"
                      >
                        <Icons type="bs" name="BsArrowUpRight" />
                      </Link>
                    </div>
                  }
                  bordered={true}
                  className="d-inline-block mb-0"
                  style={{ height: "100%" }}
                >
                  {this.clusterModel &&
                  this.clusterModel.getDeviceScans &&
                  this.clusterModel.getDeviceScans.length > 0 ? (
                    <>
                      <b>
                        {getCurrentlocaleText("device.discovery.chart.heading")}
                      </b>
                      <div style={{ fontSize: "12px" }}>
                        <DeviceDiscoveryBarChart
                          headingName={getCurrentlocaleText(
                            "device.discovery.chart.heading.sub",
                          )}
                          data={this.clusterModel.getDeviceScans}
                          objKey="name"
                        />
                      </div>
                    </>
                  ) : (
                    <div style={{ minHeight: 200 }}>
                      <Empty
                        image={
                          <span className="iot-pointer-cursor">
                            <Icons
                              type="fa"
                              name="FaChartLine"
                              className="no-metrics-empty-info"
                            />
                          </span>
                        }
                        description={
                          <div className="all-center">
                            {getCurrentlocaleText("releases.noContent.text")}
                          </div>
                        }
                      />
                    </div>
                  )}
                  <div className="beta">
                    <Icons type="fa" name="FaRocket" className="beta-icon" />
                    {getCurrentlocaleText("beta.version")}
                  </div>
                </Card>
              </LoadingComponent>
            </Col>
          )}
        </Row>
      </div>
    );

    return (
      <LoadingComponent loading={this.clusterModel.loading}>
        {container}
      </LoadingComponent>
    );
  }
}

export default ClusterInfo;
