import React, { Component } from "react";
import { Select } from "antd";
import { customSort, getCurrentlocaleText } from "Core/Utils";
import OrgController from "controller/OrgController";

const Option = Select.Option;

class OrgFilter extends Component {
  constructor(props) {
    super(props);
    this.searchText = "";
    this.searthTimeout = null;
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    let params = {};
    params.page = 0;
    OrgController.getOrgsForDropdown(params).then(response => {
      this.setState({
        data: response ? this.renderList(response) : [],
      });
    });
  }

  searchOrgs = (search = "") => {
    clearTimeout(this.searthTimeout);
    this.searthTimeout = setTimeout(() => {
      this.fetchOrgs(search);
    }, 500);
  };

  fetchOrgs = (search = "") => {
    this.searchText = search;
    let params = {};
    params.page = 0;
    params.search = search;
    OrgController.getOrgsForDropdown(params).then(response => {
      this.setState({ data: response ? this.renderList(response) : [] });
    });
  };

  renderList = orgs => {
    let options = [];
    if (orgs.length > 0) {
      options = orgs
        .sort((x, y) => {
          return customSort(x.name, y.name);
        })
        .map((item, i) => {
          return (
            <Option key={item.id} title={item.name}>
              {item.name}
            </Option>
          );
        });
      return options;
    } else {
      return (
        <Option key={1} disabled>
          {getCurrentlocaleText("general.notfound.placeholder.text")}
        </Option>
      );
    }
  };

  setValue = (value, label) => {
    if (!value && this.searchText !== "") {
      this.fetchOrgs();
    }
    if (this.props.onChange) {
      this.props.onChange(value, label);
    }
  };

  onBlur = value => {
    if (!value && this.searchText !== "") {
      this.fetchOrgs();
    }
  };

  render() {
    return (
      <span>
        <Select
          showSearch
          allowClear={this.props.allowClear ? this.props.allowClear : false}
          placeholder={this.props.placeholder}
          onChange={this.setValue}
          defaultValue={this.props.defaultValue}
          style={this.props.style}
          filterOption={false}
          onSearch={this.searchOrgs}
          optionLabelProp="title"
          onBlur={this.onBlur}
          className={this.props.className ? this.props.className : ""}
          notFoundContent={getCurrentlocaleText(
            "general.notfound.placeholder.text",
          )}
        >
          {this.state.data}
        </Select>
      </span>
    );
  }
}

export default OrgFilter;
