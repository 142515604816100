import React, { Component } from "react";
import { Row, Timeline, Col, Spin, Popover } from "antd";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import {
  convertEpochToISOTimestampString,
  copyObject,
  customSort,
  getCurrentlocaleText,
  TIME_DATE_FORMAT,
  getTimezoneToDisplay,
} from "Core/Utils";
import ActivityController from "controller/ActivityController";
import moment from "moment";
import Icons from "components/UI-Components/Icons";

const extraRowStyle = { marginTop: 2, marginBottom: 2 };

@inject("DashboardStore", "EventViewModel", "TimezoneViewModel", "UiStore")
@observer
class EventsTimeLine extends Component {
  constructor(props) {
    super(props);
    this.activityModel = this.props.ActivityViewModel;
    this.lastParams = {};
    this.state = {
      countRange: 25,
      filterType: null,
    };
  }

  //activity count
  changeRange = range => {
    this.setState({ countRange: range });
  };

  //filter type
  changeType = type => {
    this.setState({ filterType: type });
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.DashboardStore.refreshActivity) {
      this.getReq();
    }
  }

  getContainerStatusTag = data => {
    if (data.length > 0) {
      return data
        .sort((x, y) => customSort(x.name, y.name))
        .map((item, i) => {
          let popoverContent = item.extraData
            ? item.extraData.map((extra, i) => {
                return (
                  <div key={extra.name + i}>
                    <strong>{extra.name}</strong>
                    {extra.status}
                  </div>
                );
              })
            : undefined;

          return (
            <Row key={item.name + i}>
              <p key={item.name + i} value={item.name}>
                <span>{item.name + " / " + item.status}&nbsp;</span>
                {item.extraData && item.extraData.length > 0 && (
                  <Popover
                    content={popoverContent}
                    title={<strong>Information</strong>}
                    placement="top"
                  >
                    <span>
                      <Icons
                        type="fa"
                        name="FaInfoCircle"
                        className="profile-2fa-icon-style"
                      />
                    </span>
                  </Popover>
                )}
              </p>
            </Row>
          );
        });
    } else {
      return "-";
    }
  };

  lazyLoading = event => {
    let element = this.ActivityContainer;
    if (element) {
      let maxScroll = event.target.scrollHeight - event.target.clientHeight;
      let currentScroll = event.target.scrollTop;
      if (
        (Math.floor(currentScroll) != 0 &&
          Math.floor(maxScroll) != 0 &&
          Math.floor(currentScroll) === Math.floor(maxScroll)) ||
        (Math.ceil(currentScroll) != 0 &&
          Math.ceil(maxScroll) != 0 &&
          Math.ceil(currentScroll) === Math.ceil(maxScroll))
      ) {
        if (this.activityModel.pageable.next) {
          event.persist();
          this.getReq(this.activityModel.pageable.page + 1, maxScroll);
        }
      }
    }
  };
  getSourceName(record) {
    switch (record.type) {
      case "node":
        return record.node.name;
      case "service":
        return record.service.name;
      case "network":
        return (
          <span>
            {record.network.name}
            &nbsp;{"\u27F7"}&nbsp;{record.peer_node.name}/
            {record.peer_network.name}
          </span>
        );
      default:
    }
  }

  getIconWithStyle = record => {
    switch (record.type) {
      case "node":
        return (
          <Icons
            type="ai"
            name="AiFillCaretUp"
            style={{ fontSize: 16 }}
            className={this.getStatusStyle(record)}
          />
        );
      case "service":
        return (
          <Icons
            type="fa"
            name="FaSquare"
            style={{ fontSize: 16 }}
            className={this.getStatusStyle(record)}
          />
        );
      case "network":
        return (
          <Icons
            type="fa"
            name="FaStar"
            style={{ fontSize: 16 }}
            className={this.getStatusStyle(record)}
          />
        );
      default:
    }
  };

  getImage = record => {
    switch (record.type) {
      case "node":
        return (
          <Icons
            type="fa"
            name="FaRegHdd"
            style={{ fontSize: "16px", marginRight: 5 }}
          />
        );
      case "service":
        return (
          <Icons
            type="fa"
            name="FaCogs"
            style={{ fontSize: "16px", marginRight: 5 }}
          />
        );
      case "network":
        return (
          <Icons
            type="fa"
            name="FaConnectdevelop"
            style={{ fontSize: "16px", marginRight: 5 }}
          />
        );
      default:
    }
  };

  getStatusStyle = record => {
    let status = null;
    if (record.type === "node") {
      status = record.status;
    } else if (record.type === "service") {
      status = record.status;
    } else if (record.type === "network") {
      status = record.status;
    }
    if (status) {
      switch (status.toLowerCase()) {
        case "terminated":
        case "unreachable":
          return "events-timeline-terminated";
        case "healthy":
        case "alive":
        case "connected":
        case "successful":
          return "events-timeline-healthy";
        case "reboot":
          return "events-timeline-reboot";
        default:
          return "events-timeline-reboot";
      }
    }
  };
  //timeline element
  timelineEvents = () => {
    let events = this.props.EventViewModel.eventList.reverse();
    return events.map((val, index) => {
      return (
        <Timeline.Item
          className="iot-event-timeline"
          dot={this.getIconWithStyle(val)}
          pending={false}
          key={"timeleine" + index}
        >
          <div id={new Date(val.event_occured_at).toString()}>
            <Row>
              <Col span={16}>
                {this.getImage(val)}
                {this.getSourceName(val)}
              </Col>
              <Col span={8}>
                <b className={this.getStatusStyle(val)}>{val.status}</b>
              </Col>
            </Row>
            <Row>
              <Col>
                <span style={{ fontStyle: "italic", fontSize: 11 }}>
                  {val.created_at &&
                    `${moment(new Date(val.event_occured_at).getTime()).format(
                      TIME_DATE_FORMAT,
                    )} ${getTimezoneToDisplay(val.event_occured_at)}`}
                </span>
              </Col>
            </Row>
            {this.getMoreInfo(val)}
          </div>
        </Timeline.Item>
      );
    });
  };

  getMoreInfo = record => {
    let data = [];
    if (record.container_status) {
      let keys = ["exitCode", "reason", "message"];
      for (let key in record.container_status) {
        let status_reason = [];
        for (let val in record.container_status[key]) {
          if (keys.includes(val)) {
            status_reason.push({
              key: val,
              name:
                val === "exitCode"
                  ? getCurrentlocaleText(
                      "inode.events.container_status.exitCode.text",
                    )
                  : val === "reason"
                  ? getCurrentlocaleText(
                      "inode.events.container_status.reason.text",
                    )
                  : getCurrentlocaleText(
                      "inode.events.container_status.message.text",
                    ),
              status: record.container_status[key][val],
            });
          }
        }
        if (
          !keys.includes(key) &&
          record.container_status.hasOwnProperty(key)
        ) {
          data.push({
            key: key,
            name: key,
            status:
              record.container_status[key] && record.container_status[key].state
                ? record.container_status[key].state
                : record.container_status[key],
            extraData: status_reason,
          });
        }
      }
    }
    return (
      <div>
        {record.type === "node" && (
          <div>
            {(record.from || record.to) &&
            record.status.toLowerCase() != "failed" ? (
              <Row style={extraRowStyle}>
                <Col span={8}>
                  <strong className="timeline-font-size">
                    {record.status &&
                    record.status.toLowerCase() === "successful"
                      ? getCurrentlocaleText("inode.events.upgrade_status.text")
                      : getCurrentlocaleText("ipaddress.text")}
                  </strong>
                </Col>
                <Col span={16}>
                  <Row>
                    <Col span={12}>
                      <strong className="timeline-font-size">
                        {getCurrentlocaleText(
                          "inode.events.ipchange.from.text",
                        )}
                      </strong>
                    </Col>
                    <Col span={12} className="timeline-font-size">
                      {record.from ? record.from : "-"}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <strong className="timeline-font-size">
                        {getCurrentlocaleText("inode.events.ipchange.to.text")}
                      </strong>
                    </Col>
                    <Col span={12} className="timeline-font-size">
                      {record.to ? record.to : "-"}
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              record.status.toLowerCase() === "failed" && (
                <Row style={extraRowStyle}>
                  <Col span={8}>
                    <strong className="timeline-font-size">
                      {record.status && record.status.toLowerCase() === "failed"
                        ? getCurrentlocaleText(
                            "inode.events.upgrade_status.text",
                          )
                        : getCurrentlocaleText("ipaddress.text")}
                    </strong>
                  </Col>
                  <Col span={16}>
                    <Row>
                      <Col span={11}>
                        <strong className="timeline-font-size">
                          {getCurrentlocaleText(
                            "inode.events.ipchange.from.text",
                          )}
                        </strong>
                      </Col>
                      <Col span={1}>{":"}</Col>
                      <Col span={12} className="timeline-font-size">
                        {record.from ? record.from : "-"}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={11}>
                        <strong className="timeline-font-size">
                          {getCurrentlocaleText(
                            "inode.events.ipchange.to.text",
                          )}
                        </strong>
                      </Col>
                      <Col span={1}>{":"}</Col>
                      <Col span={12} className="timeline-font-size">
                        {record.to ? record.to : "-"}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )
            )}
          </div>
        )}
        {record.type === "service" && (
          <div>
            {record.running_containers && (
              <Row style={extraRowStyle}>
                <Col span={12}>
                  <strong className="timeline-font-size">
                    {getCurrentlocaleText("running.containers") + " :"}
                  </strong>
                </Col>
                <Col className="timeline-font-size" span={12}>
                  {record.running_containers}
                </Col>
              </Row>
            )}
            {record.container_status && (
              <Row style={extraRowStyle}>
                <Col span={12} className="timeline-font-size">
                  <strong>
                    {getCurrentlocaleText("events.container_name_status.text")}
                  </strong>
                </Col>
                <Col className="timeline-font-size" span={12}>
                  {this.getContainerStatusTag(data)}
                </Col>
              </Row>
            )}
          </div>
        )}
      </div>
    );
  };

  getReq = (page = 0, scroll = window.innerHeight - 300, scrollable = true) => {
    let resource = "activity",
      params = (this.state.startDate &&
        this.state.endDate && {
          start_date: convertEpochToISOTimestampString(
            this.state.startDate * 1000,
          ),
          end_date: convertEpochToISOTimestampString(this.state.endDate * 1000),
          size: this.state.countRange,
          type: this.state.filterType,
        }) ||
        (!this.state.startDate &&
          this.state.endDate && {
            end_date: convertEpochToISOTimestampString(
              this.state.endDate * 1000,
            ),
            size: this.state.countRange,
            type: this.state.filterType,
          }) ||
        (this.state.startDate &&
          !this.state.endDate && {
            start_date: convertEpochToISOTimestampString(
              this.state.startDate * 1000,
            ),
            size: this.state.countRange,
            type: this.state.filterType,
          }) || { size: this.state.countRange, type: this.state.filterType },
      map = this.props.map || {},
      cache = this.props.cache || false;
    params.page = page;
    if (this.state.filterType === null || this.state.filterType === " ") {
      delete params.type;
    }
    if (this.props.DashboardStore.activity_params.org_id) {
      params.org_id = this.props.DashboardStore.activity_params.org_id;
      this.props.DashboardStore.activity_params = params;
    }
    if (!scrollable) {
      this.activityModel.resetActivities();
    }
    ActivityController.getActivities(params, true, false).then(response => {
      if (this.activityModel.pageable.next && scrollable) {
        this.ActivityContainer.scrollTop += scroll;
      }
    });
    this.lastParams = copyObject(params);
    this.props.DashboardStore.refreshActivity = false;
  };

  disabledDate = current => {
    // Can not select days after today and today
    return current && current.valueOf() > Date.now();
  };

  render() {
    return (
      <div>
        <Col span={24}>
          <div
            ref={container => {
              this.ActivityContainer = container;
            }}
            style={{
              height: "1500px",
              overflow: "auto",
              padding: "10px 0px 0px 10px",
            }}
          >
            {this.props.EventViewModel.loading ? (
              <div className="chart-spinner">
                <Spin tip="Loading..." />
              </div>
            ) : this.props.EventViewModel.eventList.length > 0 ? (
              <Timeline>{this.timelineEvents()}</Timeline>
            ) : (
              getCurrentlocaleText("noevents.text")
            )}
          </div>
        </Col>
      </div>
    );
  }
}

EventsTimeLine.propTypes = {
  timeLineData: PropTypes.object,
};
export default EventsTimeLine;
