import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import { Radio } from "antd";
import propTypes from "prop-types";
import { randomAlphaNumericString, copyObject } from "Core/Utils";
import { inject } from "mobx-react";
import { computed } from "mobx";
import Twig from "twig";

/*Define form elements*/
const FormItem = Form.Item;
const RadioGroup = Radio.Group;

@inject("UiStore")
export default class RadioBox extends Component {
  constructor(props) {
    super(props);
    this.validationMessage =
      this.props.properties.constraints &&
      this.props.properties.constraints.validationMessage
        ? this.props.properties.constraints.validationMessage
        : `${this.props.properties.label} is required`;
  }

  radioList = () => {
    let helpText = "",
      helpObj = {};
    if (
      !this.props.properties.help ||
      (this.props.properties.help &&
        typeof this.props.properties.help === "string")
    ) {
      /*  if help text is a string */
      helpText = this.props.properties.help;

      return (
        this.props.properties.options &&
        this.props.properties.options.list.map((val, i) => {
          return (
            <Radio
              value={val.value}
              key={val.value}
              onFocus={
                this.props.onFocus && this.props.onFocus.bind(null, helpText)
              }
            >
              {val.label}
            </Radio>
          );
        })
      );
    } else if (
      this.props.properties.help &&
      typeof this.props.properties.help === "object"
    ) {
      /*  if help text is an object */
      let helpKeys = Object.keys(this.props.properties.help);
      helpKeys.forEach(val => {
        helpObj[val.toLowerCase()] = this.props.properties.help[val];
      });
      return (
        this.props.properties.options &&
        this.props.properties.options.list.map((val, i) => {
          let x = val.value.toLowerCase();
          return (
            <Radio
              value={val.value}
              key={val.value}
              onFocus={
                this.props.onFocus &&
                this.props.onFocus.bind(null, helpObj[x] ? helpObj[x] : "")
              }
              onClick={
                this.props.onFocus &&
                this.props.onFocus.bind(null, helpObj[x] ? helpObj[x] : "")
              }
              className={this.props.className ? this.props.className : ""}
            >
              {val.label}
            </Radio>
          );
        })
      );
    }
  };

  componentDidMount() {
    if (this.props.updateCurrentValueOnChange) {
      this.props.updateCurrentValueOnChange(
        this.props.properties.defaultValue &&
          this.props.properties.defaultValue.value
          ? this.props.properties.defaultValue.value
          : typeof this.props.properties.defaultValue === "object" &&
            this.props.properties.defaultValue
          ? [this.props.properties.defaultValue]
          : null,
        "value",
      );
    }
  }

  @computed
  get componentVisibility() {
    let visibility = null;
    if (
      this.props.UiStore.computedOutputObject &&
      this.props.UiStore.computedOutputObject.steps
    ) {
      if (this.props.properties.visibility) {
        var template = Twig.twig({
          data: this.props.properties.visibility,
        });
        let computedOutputObject = copyObject(
          this.props.UiStore.computedOutputObject,
        );
        visibility = template.render(computedOutputObject);
        try {
          visibility = JSON.parse(visibility);
        } catch {}
      }
    }
    return visibility ? true : false;
  }

  onChange = e => {
    this.props.properties.onChange
      ? this.props.properties.onChange(e.target.value)
      : this.props.updateCurrentValueOnChange(e.target.value, "value");
  };

  render() {
    /*required as output from select is an array*/
    let defaultValue =
      this.props.properties.defaultValue &&
      this.props.properties.defaultValue.value
        ? this.props.properties.defaultValue.value
        : typeof this.props.properties.defaultValue === "string" &&
          this.props.properties.defaultValue
        ? this.props.properties.defaultValue.toString()
        : null;
    const { getFieldDecorator } = this.props.referenceForm; // reference of base form
    let required =
      this.props.properties.constraints &&
      this.props.properties.constraints.required
        ? this.props.properties.constraints.required
        : false;

    let id = this.props.id
      ? this.props.id
      : `Radio${randomAlphaNumericString(3)}`;

    let disable = false;
    if (this.props.editmode) {
      disable = this.props.properties.noneditable
        ? this.props.properties.noneditable
        : false;
    }
    disable = this.props.properties.disabled
      ? this.props.properties.disabled
      : disable;

    // For View only
    disable = this.props.viewOnly === true ? true : disable;

    let visibility = this.props.properties.visibility
      ? this.componentVisibility
      : true;

    return visibility ? (
      <div>
        <FormItem
          label={
            this.props.properties.label ? this.props.properties.label + ":" : ""
          }
          labelCol={{ span: 24 }}
        >
          {getFieldDecorator(id, {
            rules: [
              {
                required: required,
                message: this.validationMessage,
              },
            ],
            initialValue: defaultValue ? defaultValue : [],
          })(
            <RadioGroup onChange={this.onChange} disabled={disable}>
              {this.radioList()}
            </RadioGroup>,
          )}
        </FormItem>
      </div>
    ) : (
      <div></div>
    );
  }
}

RadioBox.propTypes = {
  referenceForm: propTypes.any,
  id: propTypes.string,
  properties: propTypes.shape({
    label: propTypes.string,
    defaultValue: propTypes.string, // key value
    disabled: propTypes.bool,
    onChange: propTypes.func,
    constraints: propTypes.shape({
      validationMessage: propTypes.string,
      required: propTypes.bool,
    }),
    options: propTypes.shape({
      list: propTypes.array,
    }),
  }),
};

/*
  sample schema

  {
    "type": "RadioBox",
    "visible": true,
    "id": "12",
    "props": {
      "label": "Enable Secure Shell",
      "defaultValue": [],
      "disabled": false,
      "noneditable" : true,
      "constraints": {
        "required": false,
        "validationMessage": " validate me"
      },
      "help": "<div>help for radioBox</div>",
      "options": {
        "list": [{
            "value": "12",
            "label": "hai"
          },
          {
            "value": "2",
            "label": "hello"
          }
        ]
      }
    }
  }


*/
