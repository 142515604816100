import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Row, Col, Empty, Icon } from "antd";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import TableLayout from "components/layout/TableLayout";
import InodeController from "controller/InodeController";
import { getCurrentlocaleText } from "Core/Utils";
import TanDadForm from "components/Forms/TanDadForm";
import ActionBtn from "Core/API/ActionBtn";
import Icons from "../UI-Components/Icons";
@inject("DadViewModel", "UiStore", "AuthStore")
@observer
class InodeDadList extends Component {
  constructor(props) {
    super(props);
    this.UiStore = this.props.UiStore;
    this.dadModel = this.props.DadViewModel;
    this.state = {
      isDontShowChecked: false,
      disableScanDad: false,
      selectedNode: {},
    };
  }

  componentDidMount() {
    if (this.props.AuthStore.IsPermitted("NODE:VIEW")) {
      let resource_id = this.props.nodeId;
      this.iNodeDetails(resource_id);
      InodeController.getInodeDad(resource_id, true, true);
    }
  }
  iNodeDetails = (resource_id = null) => {
    //let resource = "inodes",
    let map = {
      id: resource_id !== null ? resource_id : this.props.nodeId,
    };
    InodeController.getInode(map.id).then(res => {
      return this.updateInodeDetails(res);
    });
  };
  updateInodeDetails = res => {
    if (res && res.id) {
      this.setState(() => {
        let newObj = {},
          selectedNode = this.state.selectedNode,
          disableScanDad = this.state.disableScanDad;
        selectedNode[res.id] = {
          value: true,
          cluster: res.cluster,
          status: res.node_state && res.node_state.toLowerCase(),
          name: res.name,
          id: res.id,
        };
        res.node_state &&
        res.node_state.toLowerCase() === "alive" &&
        (this.props.AuthStore.IsPermitted("NODE:CREATE") ||
          this.props.AuthStore.IsPermitted("NODE:REBOOT"))
          ? (newObj["disableScanDad"] = false)
          : (newObj["disableScanDad"] = true);
        newObj["selectedNode"] = selectedNode;
        return newObj;
      });
    }
  };
  ChangePopupVisibility = () => {
    this.setState({ isDontShowChecked: !this.state.isDontShowChecked });
    if (!this.state.isDontShowChecked === true) {
      InodeController.scanNowNodeDad([this.props.nodeId]);
      this.setState({ isDontShowChecked: false });
    }
  };
  render() {
    let dads = this.dadModel.getInterfacesWithDuplicates || [];
    let dadScanAt = this.dadModel.getDadStatusAt;
    let columns = [
      {
        title: getCurrentlocaleText("dad.address.text"),
        key: "Duplicate Address",
        render: (text, record, index) => {
          return (
            <span>
              {record ? <span>{record.address}</span> : <span>-</span>}
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText("dad.type.text"),
        key: "type",
        render: (text, record, index) => {
          return (
            <span>
              {record.dup_type ? (
                <span>
                  <strong>{record.dup_type}</strong>
                </span>
              ) : (
                <span>-</span>
              )}
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText("dad.interface_name.text"),
        key: "Name",
        render: (text, record, index) => {
          return (
            <span>
              {record.tan_name ? (
                <span>{record.tan_name}</span>
              ) : (
                <span>-</span>
              )}
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText("dad.service.text"),
        key: "ServiceName",
        render: (text, record, index) => {
          return (
            <span>
              {record.service_name ? (
                <span>
                  {record.service_name ? (
                    <span>{record.service_name}</span>
                  ) : (
                    <span></span>
                  )}
                </span>
              ) : (
                <span>-</span>
              )}
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText("dad.local_mac_address.text"),
        key: "Local Mac Address",
        render: (text, record, index) => {
          return (
            <span>
              {record.mac_address ? (
                <span>{record.local_mac_address}</span>
              ) : (
                <span>-</span>
              )}
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText("dad.mac_address.text"),
        key: "Mac Address",
        render: (text, record, index) => {
          return (
            <span>
              {record.mac_address ? (
                <span>{record.mac_address}</span>
              ) : (
                <span>-</span>
              )}
            </span>
          );
        },
      },
    ];

    let container = (
      <div>
        {/* Dad List */}
        <div className="listing-page-data-container">
          {dads && dads.length > 0 && (
            <div
              style={{
                display: "inline-flex",
                width: "50%",
                marginBottom: 15,
              }}
            ></div>
          )}
          <Row gutter={16} type="flex" align="middle">
            <Col span={24}>
              <div style={{ textAlign: "right", marginBottom: "10" }}>
                {((dads && dads.length > 0 && dads[0].status_at) ||
                  dadScanAt) && (
                  <span className="listing-page-top-label">
                    <strong>{getCurrentlocaleText("dad.time.text")}</strong>
                    {dads && dads[0] && dads[0].status_at
                      ? dads[0].status_at
                      : dadScanAt}
                  </span>
                )}
                {this.state.selectedNode &&
                  this.state.selectedNode[this.props.nodeId] &&
                  !this.state.selectedNode[this.props.nodeId].cluster && (
                    <span style={{ fontWeight: "normal", marginRight: 20 }}>
                      <ActionBtn
                        title={
                          <span>
                            <Icons
                              type="ai"
                              name="AiOutlineFileSync"
                              style={{ marginRight: 8 }}
                            />
                            {getCurrentlocaleText("dad.force_button.text")}
                          </span>
                        }
                        resource="scanNowNodeDad"
                        type="primary"
                        action="scanNowNodeDad"
                        actionForm={TanDadForm}
                        disabled={this.state.disableScanDad}
                        selectedNode={this.state.selectedNode}
                        nodes={[this.props.nodeId]}
                        controller={InodeController}
                        afterCall={() => {
                          this.iNodeDetails(null, false, true);
                        }}
                        okText={
                          <span>
                            <Icons
                              type="ai"
                              name="AiOutlineFileSync"
                              style={{ marginRight: 8 }}
                            />
                            {getCurrentlocaleText("dad.force_button.text")}
                          </span>
                        }
                      >
                        <Icons
                          type="ai"
                          name="AiOutlineFileSync"
                          style={{ marginRight: 8 }}
                        />
                        <span>
                          {getCurrentlocaleText("dad.force_button.text")}
                        </span>
                      </ActionBtn>
                    </span>
                  )}
              </div>
              {dads && dads.length > 0 ? (
                <TableLayout
                  pagination={false}
                  columns={columns}
                  hideRowSelection={true}
                  dataSource={dads}
                  className="dadList"
                  hideExpandedRow={true}
                />
              ) : (
                <div>
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={getCurrentlocaleText("no.dad.text")}
                  />
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    );

    return (
      <LoadingComponent loading={this.dadModel.loading}>
        {container}
      </LoadingComponent>
    );
  }
}

export default InodeDadList;
