import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Row, Col, DatePicker, Button, Empty, Select } from "antd";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import ActivityController from "controller/ActivityController";
import OrgController from "controller/OrgController";
import { getCurrentlocaleText, getDefaultTimezone } from "Core/Utils";
import moment from "moment";
import Icons from "../UI-Components/Icons";
import TableLayoutV1 from "../layout/TableLayoutV1";

const Option = Select.Option;
const extraRowStyle = { marginTop: 5, marginBottom: 5 };

@inject(
  "DashboardStore",
  "ActivityViewModel",
  "TimezoneViewModel",
  "UiStore",
  "OrgViewModel",
  "AuthStore",
)
@observer
class ActivityList extends Component {
  constructor(props) {
    super(props);
    this.UiStore = this.props.UiStore;
    this.authStore = this.props.AuthStore;
    this.dashboardStore = this.props.DashboardStore;
    this.activityModel = this.props.ActivityViewModel;
    this.timezoneModel = this.props.TimezoneViewModel;
    this.state = {
      group: "",
      orgId: null,
      startDate: null,
      endDate: null,
      isInvalidRange: false,
      expandedRowKeys: [],
      currentPage: 1,
      isTableModalToggle: false,
      modalContent: "",
      modalTitle: "",
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.nodeId) {
      this.activityList(
        true,
        0,
        false,
        null,
        this.state.group,
        null,
        null,
        this.props.nodeId,
      );
    } else {
      const currentOrgId =
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.id
          ? this.props.match.params.id
          : this.props.OrgViewModel.org.id
          ? this.props.OrgViewModel.org.id
          : this.props.AuthStore.loggedInUser.organization &&
            this.props.AuthStore.loggedInUser.organization.id;
      this.setState({ orgId: currentOrgId });
      this.activityList(true, 0, false, currentOrgId);
      this.updateOrgModel(currentOrgId);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.match &&
      this.props.match.params &&
      nextProps.match &&
      nextProps.match.params &&
      this.props.match.params.id !== nextProps.match.params.id
    ) {
      const currentOrgId =
        nextProps.match.params && nextProps.match.params.id
          ? nextProps.match.params.id
          : this.props.OrgViewModel.org.id
          ? this.props.OrgViewModel.org.id
          : this.props.AuthStore.loggedInUser.organization &&
            this.props.AuthStore.loggedInUser.organization.id;
      this.setState({ orgId: currentOrgId, expandedRowKeys: [] });
      this.activityList(
        true,
        0,
        false,
        currentOrgId,
        this.state.group,
        this.state.startDate
          ? this.state.startDate.format()
          : this.state.startDate,
        this.state.endDate ? this.state.endDate.format() : this.state.endDate,
      );
      this.updateOrgModel(currentOrgId);
    }
  }

  // This will update or reset the current selected org
  updateOrgModel = org_value => {
    //updates the org-model based on currently selected organization
    OrgController.getOrg(org_value, false).then(response => {
      if (response && response.parent_organization)
        this.props.UiStore.parentOrgData = response.parent_organization;
      else this.props.UiStore.parentOrgData = {};
    });
  };

  renderActivityGroups = () => {
    let activityGroups = [
      {
        value: "",
        label: getCurrentlocaleText("activity.selector.all.option.label.text"),
      },
      {
        value: "API_KEY_ACTIVITIES",
        label: getCurrentlocaleText(
          "activity.selector.apikeys.option.label.text",
        ),
      },
      {
        value: "CLUSTER_ACTIVITIES",
        label: getCurrentlocaleText(
          "activity.selector.cluster.option.label.text",
        ),
      },
      {
        value: "DOWNLOAD_ACTIVITIES",
        label: getCurrentlocaleText(
          "activity.selector.downloads.option.label.text",
        ),
      },
      {
        value: "NODE_ACTIVITIES",
        label: getCurrentlocaleText(
          "activity.selector.inodes.option.label.text",
        ),
      },
      {
        value: "NETWORK_ACTIVITIES",
        label: getCurrentlocaleText(
          "activity.selector.networks.option.label.text",
        ),
      },
      {
        value: "ORG_ACTIVITIES",
        label: getCurrentlocaleText("activity.selector.orgs.option.label.text"),
      },
      {
        value: "SECURITY_GROUP_ACTIVITIES",
        label: getCurrentlocaleText(
          "activity.selector.firewall.option.label.text",
        ),
      },
      {
        value: "SERIAL_NUMBER_ACTIVITIES",
        label: getCurrentlocaleText(
          "activity.selector.serial.option.label.text",
        ),
      },
      {
        value: "SECRET_ACTIVITIES",
        label: getCurrentlocaleText(
          "activity.selector.secret.option.label.text",
        ),
      },
      {
        value: "SERVICE_ACTIVITIES",
        label: getCurrentlocaleText(
          "activity.selector.services.option.label.text",
        ),
      },
      {
        value: "SSH_KEY_ACTIVITIES",
        label: getCurrentlocaleText(
          "activity.selector.sshkeys.option.label.text",
        ),
      },
      {
        value: "ACCOUNT_ACTIVITIES",
        label: getCurrentlocaleText(
          "activity.selector.accounts.option.label.text",
        ),
      },
      {
        value: "ROLE_ACTIVITIES",
        label: getCurrentlocaleText(
          "activity.selector.roles.option.label.text",
        ),
      },
      {
        value: "USER_ACTIVITIES",
        label: getCurrentlocaleText(
          "activity.selector.users.option.label.text",
        ),
      },
    ];
    return activityGroups.map((item, i) => {
      return (
        <Option key={i} title={item.label} value={item.value}>
          {item.label}
        </Option>
      );
    });
  };

  activityList = (
    loading = true,
    page = 0,
    forcecall = false,
    orgId = null,
    group = null,
    startDate = null,
    endDate = null,
    resourceId = null,
  ) => {
    let params = {};
    params.page = page;
    params.size = 25;
    if (resourceId) {
      (params.resource_id = resourceId),
        (params.group = [
          "NODE_ACTIVITIES",
          "NETWORK_ACTIVITIES",
          "SERVICE_ACTIVITIES",
        ]);
      if (startDate) {
        params.start_date = startDate;
      }
      if (endDate) {
        params.end_date = endDate;
      }
    } else {
      // Group filter
      if (group) {
        params.group = group;
      }
      // Org Filter
      if (orgId) {
        params.org_id = orgId;
      }
      // Start date Filter
      if (startDate) {
        params.start_date = startDate;
      }
      // End date Filter
      if (endDate) {
        params.end_date = endDate;
      }
    }
    // For all the activities
    ActivityController.getActivities(params, loading, forcecall);
  };

  lazyLoad = () => {
    if (
      this.activityModel.pageable.page <
      this.activityModel.pageable.total_page - 1
    ) {
      let page = this.activityModel.pageable.page + 1;
      if (this.props.nodeId) {
        let resource_id = this.props.nodeId;
        this.activityList(
          true,
          page,
          false,
          null,
          this.state.group,
          this.state.startDate
            ? this.state.startDate.format()
            : this.state.startDate,
          this.state.endDate ? this.state.endDate.format() : this.state.endDate,
          resource_id,
        );
      } else {
        this.activityList(
          true,
          page,
          false,
          this.state.orgId,
          this.state.group,
          this.state.startDate
            ? this.state.startDate.format()
            : this.state.startDate,
          this.state.endDate ? this.state.endDate.format() : this.state.endDate,
        );
      }
    }
  };

  onGroupChange = value => {
    this.setState({ group: value });
  };

  getActivityList = pagination => {
    this.setState({ currentPage: pagination.current, expandedRowKeys: [] });
    let page = pagination.current;
    if (this.props.nodeId) {
      let resource_id = this.props.nodeId;
      this.activityList(
        true,
        page,
        false,
        null,
        this.state.group,
        this.state.startDate
          ? this.state.startDate.format()
          : this.state.startDate,
        this.state.endDate ? this.state.endDate.format() : this.state.endDate,
        resource_id,
      );
    } else {
      this.activityList(
        true,
        page,
        false,
        this.state.orgId,
        this.state.group,
        this.state.startDate
          ? this.state.startDate.format()
          : this.state.startDate,
        this.state.endDate ? this.state.endDate.format() : this.state.endDate,
      );
    }
  };

  disabledDate = current => {
    // Can not select days after today and today
    return current && current.valueOf() > Date.now();
  };

  handleStartDateChange = (date, dateString) => {
    date && this.state.endDate && this.state.endDate.diff(date) < 0
      ? this.setState({
          isInvalidRange: true,
        })
      : this.setState({
          isInvalidRange: false,
        });
    // _z referes to zone.
    let timezone =
      this.timezoneModel.userTimezone ||
      this.timezoneModel.orgTimezone ||
      getDefaultTimezone().value;
    date = date
      ? date._z
        ? date.tz(timezone)
        : moment.tz(dateString, timezone)
      : date;
    this.setState({
      startDate: date,
    });
  };

  handleEndDateChange = (date, dateString) => {
    date && this.state.startDate && date.diff(this.state.startDate) < 0
      ? this.setState({
          isInvalidRange: true,
        })
      : this.setState({
          isInvalidRange: false,
        });
    // _z referes to zone.
    let timezone =
      this.timezoneModel.userTimezone ||
      this.timezoneModel.orgTimezone ||
      getDefaultTimezone().value;
    date = date
      ? date._z
        ? date.tz(timezone)
        : moment.tz(dateString, timezone)
      : date;
    this.setState({
      endDate: date,
    });
  };

  expandRow = () => {
    let keys = this.activityModel.activities.map(item => {
      return item.id;
    });
    this.setExpandedRowKeys(
      this.state.expandedRowKeys.length !== keys.length ? keys : [],
    );
  };

  onTableChange = pagination => {
    this.setState({
      currentPage: pagination.current,
    });
    let page = pagination.current - 1;
    let resource_id = this.props.nodeId;
    this.activityList(
      true,
      page,
      false,
      this.state.orgId ? this.state.orgId : "",
      this.state.group,
      this.state.startDate
        ? this.state.startDate.format()
        : this.state.startDate,
      this.state.endDate ? this.state.endDate.format() : this.state.endDate,
      resource_id,
    );
  };

  onExpand = (expanded, record) => {
    let keys = this.state.expandedRowKeys;
    let index = keys.findIndex(val => {
      return val === record.id;
    });
    if (expanded) {
      if (index === -1) {
        keys.push(record.id);
      }
      this.setExpandedRowKeys(keys);
    } else {
      if (index !== -1) {
        keys.splice(index, 1);
      }
      this.setExpandedRowKeys(keys);
    }
  };

  setExpandedRowKeys = keys => {
    this.setState({ expandedRowKeys: keys });
  };

  getModalContentJSX = record => {
    this.setState({
      modalTitle:
        record && record.display_longdate ? record.display_longdate : "",
      modalContent: this.getMoreInfo(record),
    });
  };

  getMoreInfo = record => {
    return (
      <div>
        <Row style={extraRowStyle}>
          <Col span={6}>
            <strong>{getCurrentlocaleText("activity.group.text")}</strong>
          </Col>
          <Col span={2}>{":"}</Col>
          <Col>{record.display_group}</Col>
        </Row>
        <Row style={extraRowStyle}>
          <Col span={6}>
            <strong>{getCurrentlocaleText("organizationName.text")}</strong>
          </Col>
          <Col span={2}>{":"}</Col>
          <Col>{record.organization.name}</Col>
        </Row>
      </div>
    );
  };

  handleModalOk(e) {
    this.setState({
      isTableModalToggle: !this.state.isTableModalToggle,
    });
  }

  render() {
    const { isTableModalToggle } = this.state;
    let count = this.activityModel.count || 0;
    let activities = this.activityModel.activityList || [];
    let columns = [
      {
        title: getCurrentlocaleText("activity.time.text"),
        key: "time",
        width: "30%",
        ellipsis: true,
        render: (text, record, index) => {
          return (
            <span className="col-wrap">
              {record.date ? (
                <span>{record.display_longdate}</span>
              ) : (
                <span>-</span>
              )}
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText("activity.type.text"),
        key: "type",
        width: "20%",
        render: (text, record, index) => {
          return (
            <span>
              {record.display_type ? (
                <span>{record.display_type}</span>
              ) : (
                <span>-</span>
              )}
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText("activity.description.text"),
        key: "description",
        width: "40%",
        ellipsis: true,
        render: (text, record, index) => {
          return (
            <span className="col-wrap">
              {record ? (
                <span>{record.display_description_without_time}</span>
              ) : (
                <span>-</span>
              )}
            </span>
          );
        },
      },
    ];

    let container = (
      <div>
        {/* Activity Filters */}
        <div className="listing-page-top-container">
          <Row type="flex" align="middle">
            <Col span={2} className="d-flex">
              <div className="events-header">
                {getCurrentlocaleText("activity.text")}
              </div>
              <div className="number-circle">
                {"("}
                {count}
                {")"}
              </div>
            </Col>

            <Col span={22}>
              <div className="listing-page-filter-container">
                {/* Activity Type Filter */}
                {!this.props.nodeId && (
                  <span className="listing-page-filter">
                    <Select
                      defaultValue=""
                      title="Filter"
                      style={{ width: 200 }}
                      notFoundContent={getCurrentlocaleText(
                        "general.notfound.placeholder.text",
                      )}
                      onChange={this.onGroupChange}
                    >
                      {this.renderActivityGroups()}
                    </Select>
                  </span>
                )}
                {/* Start Date Filter */}
                <span
                  className="listing-page-filter"
                  style={{ display: "inline-grid" }}
                >
                  <DatePicker
                    showTime={{
                      defaultValue: moment(),
                    }}
                    format="YYYY-MM-DD HH:mm:ss"
                    placeholder={getCurrentlocaleText(
                      "event.startdatefilter.placeholder",
                    )}
                    style={{ width: 120 }}
                    onChange={this.handleStartDateChange}
                    disabledDate={this.disabledDate}
                    title={getCurrentlocaleText(
                      "event.startdatefilter.placeholder",
                    )}
                  />{" "}
                  {this.state.isInvalidRange && (
                    <span className="invalid-range">
                      {getCurrentlocaleText(
                        "download.fromto.invalid_date_range.error.text",
                      )}
                    </span>
                  )}
                </span>
                {/* End Date Filter */}
                <span
                  className="listing-page-filter"
                  style={{ display: "inline-grid" }}
                >
                  <DatePicker
                    showTime={{
                      defaultValue: moment(),
                    }}
                    format="YYYY-MM-DD HH:mm:ss"
                    placeholder={getCurrentlocaleText(
                      "event.enddatefilter.placeholder",
                    )}
                    style={{ width: 120 }}
                    onChange={this.handleEndDateChange}
                    disabledDate={this.disabledDate}
                    title={getCurrentlocaleText(
                      "event.enddatefilter.placeholder",
                    )}
                  />{" "}
                  {this.state.isInvalidRange && (
                    <span className="invalid-range">
                      {getCurrentlocaleText(
                        "download.fromto.invalid_date_range.error_1.text",
                      )}
                    </span>
                  )}
                </span>
                {/* Apply button */}
                <span className="listing-page-btn">
                  <Button
                    type="primary"
                    className="act-button"
                    style={{ height: "-webkit-fill-available" }}
                    onClick={this.getActivityList}
                    icon={<Icons type="ai" name="AiOutlineFilter" />}
                    size="small"
                    title={getCurrentlocaleText(
                      "events.apply.button.title.text",
                    )}
                  >
                    <i className="activity-icon"> </i>
                  </Button>
                </span>
              </div>
            </Col>
          </Row>
        </div>
        {/* Activity List */}
        <div className="listing-page-data-container">
          <Row gutter={16} type="flex" align="middle">
            <Col span={24}>
              {this.activityModel.activities.length > 0 ? (
                <TableLayoutV1
                  pagination={{
                    pageSize:
                      this.activityModel && this.activityModel.pageable.size,
                    total:
                      this.activityModel &&
                      this.activityModel.pageable.total_count,
                    current: this.state.currentPage,
                  }}
                  columns={columns}
                  dataSource={activities}
                  expandedRowKeys={this.state.expandedRowKeys}
                  onExpand={this.onExpand}
                  hideRowSelection={true}
                  getModalContent={record => {
                    return this.getModalContentJSX(record);
                  }}
                  onChange={this.onTableChange}
                  handleModalOk={e => this.handleModalOk(e)}
                  modalTitle={this.state.modalTitle}
                  modalContent={this.state.modalContent}
                  isTableModalToggle={isTableModalToggle}
                  isModalLoading={false}
                  disableFooter={true}
                  className="activityList"
                />
              ) : (
                <div>
                  {!this.activityModel.loading &&
                    (this.activityModel.activities ||
                      this.activityModel.activities.length === 0) && (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={getCurrentlocaleText("no.activity.text")}
                      />
                    )}
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    );

    return (
      <LoadingComponent loading={this.activityModel.loading}>
        {container}
      </LoadingComponent>
    );
  }
}

export default ActivityList;
