import React, { Component, useRef, useState } from "react";
import Icons from "components/UI-Components/Icons";
import {
  Modal,
  Tag,
  Button,
  Tree,
  Radio,
  Row,
  Col,
  Input,
  Space,
  Table,
} from "antd";
import TableLayout from "components/layout/TableLayout";
import { observer, inject } from "mobx-react";
import {
  DeploymentUnitOutlined,
  CheckCircleOutlined,
  SwapOutlined,
  ApartmentOutlined,
  SearchOutlined,
  FlagOutlined,
} from "@ant-design/icons";
import { getCurrentlocaleText } from "Core/Utils";
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

@inject("AuthStore", "NetworkViewModel")
@observer
class RepNetHierarchy extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      showInfo: false,
      viewType: "TABLE",
      searchedColumn: null,
      searchText: null,
    };
  }

  showRepnetData = () => {
    this.setState({ showInfo: !this.state.showInfo });
  };

  changeView = e => {
    this.setState({ viewType: e.target.value });
  };

  getRemoteNetworkDetails = id => {
    let remoteNetworkDetails = {};
    if (id) {
      let availableNetworks = this.props.NetworkViewModel.networks || [];
      if (availableNetworks && availableNetworks.length > 0) {
        let currentData = availableNetworks.filter(net => {
          return net.id === id;
        });
        if (currentData && currentData.length > 0) {
          remoteNetworkDetails = currentData[0];
        }
      }
    }
    return remoteNetworkDetails;
  };

  renderList(data) {
    let actualData = data;
    let computedData = [];
    if (actualData && actualData.length > 0) {
      let node_list = [];
      actualData.forEach((net, index) => {
        let currentRemoteData = this.getRemoteNetworkDetails(
          net.network && net.network.id,
        );
        let nodeIndex = node_list.findIndex(list => list.id == net.node.id);
        if (nodeIndex === -1) {
          node_list.push({
            key: net.node && net.node.id + index,
            id: net.node && net.node.id ? net.node.id : "NA",
            title: (
              <span className="autorep-dash-edge">
                <Icons
                  type={"fa"}
                  name={"FaRegHdd"}
                  style={{ color: "white", marginRight: 5 }}
                />
                {net.node.name}
              </span>
            ),
            icon: null,
            children: [
              {
                key: net.network && net.network.name,
                title: !net.disable_representation_network_automation ? (
                  <span className="autorep-dash-network">
                    <Tag
                      icon={
                        <Icons
                          type={"fa"}
                          name={"FaConnectdevelop"}
                          style={{ color: "green" }}
                        />
                      }
                    >
                      {net.network.name}
                    </Tag>
                  </span>
                ) : (
                  <span className="autorep-dash-network">
                    <Tag icon={<Icons type={"fa"} name={"FaConnectdevelop"} />}>
                      {net.network.name}
                    </Tag>
                  </span>
                ),
                icon: null,
                children: [
                  {
                    key:
                      net.representation_network &&
                      net.representation_network.peer
                        ? net.representation_network.peer
                        : "NA",
                    title: (
                      <span>
                        <strong>
                          {getCurrentlocaleText("vnode.repnet.cidr.title") +
                            ":  "}
                        </strong>
                        <code>
                          {net.representation_network &&
                          net.representation_network.peer
                            ? net.representation_network.peer
                            : "NA"}
                        </code>
                      </span>
                    ),
                    icon: <SwapOutlined />,
                    children: [
                      {
                        key:
                          currentRemoteData.config &&
                          currentRemoteData.config.network &&
                          currentRemoteData.config.network.cidr
                            ? currentRemoteData.config.network.cidr
                            : "NA",
                        title: (
                          <span>
                            <strong>
                              {getCurrentlocaleText("network.form.cidr.text") +
                                ":  "}
                            </strong>
                            <code>
                              {currentRemoteData.config &&
                              currentRemoteData.config.network &&
                              currentRemoteData.config.network.cidr
                                ? currentRemoteData.config.network.cidr
                                : "NA"}
                            </code>
                          </span>
                        ),
                        children: [],
                        icon: <DeploymentUnitOutlined />,
                      },
                    ],
                  },
                ],
              },
            ],
          });
        } else {
          let existingNodeRecords = node_list[nodeIndex].children;
          existingNodeRecords.push({
            key: net.network && net.network.name,
            title: !net.disable_representation_network_automation ? (
              <span className="autorep-dash-network">
                <Tag
                  icon={
                    <Icons
                      type={"fa"}
                      name={"FaConnectdevelop"}
                      style={{ color: "green" }}
                    />
                  }
                >
                  {net.network.name}
                </Tag>
              </span>
            ) : (
              <span className="autorep-dash-network">
                <Tag icon={<Icons type={"fa"} name={"FaConnectdevelop"} />}>
                  {net.network.name}
                </Tag>
              </span>
            ),
            icon: null,
            children: [
              {
                key:
                  net.representation_network && net.representation_network.peer
                    ? net.representation_network.peer
                    : "NA",
                title: (
                  <span>
                    <strong>
                      {getCurrentlocaleText("vnode.repnet.cidr.title") + ":"}
                    </strong>
                    {net.representation_network &&
                    net.representation_network.peer
                      ? net.representation_network.peer
                      : "NA"}
                  </span>
                ),
                icon: <SwapOutlined />,
                children: [
                  {
                    key:
                      currentRemoteData.config &&
                      currentRemoteData.config.network &&
                      currentRemoteData.config.network.cidr
                        ? currentRemoteData.config.network.cidr
                        : "NA",
                    title: (
                      <span>
                        <strong>
                          {getCurrentlocaleText("network.form.cidr.text") + ":"}
                        </strong>
                        {currentRemoteData.config &&
                        currentRemoteData.config.network &&
                        currentRemoteData.config.network.cidr
                          ? currentRemoteData.config.network.cidr
                          : "NA"}
                      </span>
                    ),
                    children: [],
                    icon: <DeploymentUnitOutlined />,
                  },
                ],
              },
            ],
          });
          node_list[nodeIndex].children = existingNodeRecords;
        }
      });
      computedData = node_list;
    }
    let virtualNodeName =
      `${this.props.virtualNodeName} (Representation Automation CIDR: ${this.props.cloudRouteInfo.cidr})` ||
      "NA";
    let treeData = [
      {
        title: <span className="autorep-dash-virtual">{virtualNodeName}</span>,
        key: virtualNodeName ? virtualNodeName : null,
        icon: (
          <Icons type={"md"} name={"MdCloudQueue"} style={{ color: "green" }} />
        ),
        children: computedData,
      },
    ];

    return (
      <div
        className="iot-repnet-tree"
        style={{
          maxHeight: 500,
          overflow: "scroll",
          width: "100%",
          minWidth: 866,
          overflowX: "hidden",
        }}
      >
        <Tree
          defaultExpandAll={true}
          rootClassName="autorep-dash"
          showIcon={true}
          showLine={{ showLeafIcon: false }}
          treeData={treeData}
        />
      </div>
    );
  }

  // Search Functions
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({ searchText: selectedKeys[0], searchedColumn: dataIndex });
  };
  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "", searchedColumn: "" });
  };

  // Search Fields
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={e => e.stopPropagation()}
      >
        <Input
          ref={this.searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined
        className={filtered ? "iotium-custom-icons" : undefined}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    render: text => text,
  });

  getComputedData = networkData => {
    let computedData = [];
    if (networkData && networkData.length > 0) {
      networkData.forEach(data => {
        let record = {};
        let remoteData = this.getRemoteNetworkDetails(
          data.network && data.network.id,
        );
        record.nodeName =
          data && data.node && data.node.name
            ? data.node.name
            : data.cluster && data.cluster.name
            ? data.cluster.name
            : "";
        record.isCluster =
          data && data.cluster && data.cluster.name ? true : false;
        record.networkName =
          data && data.network && data.network.name ? data.network.name : "";
        record.remoteNetworkIpAddress =
          remoteData.config &&
          remoteData.config.network &&
          remoteData.config.network.cidr
            ? remoteData.config.network.cidr
            : "NA";
        record.repNetIpAddress =
          data.representation_network && data.representation_network.peer
            ? data.representation_network.peer
            : "NA";
        record.disable_representation_network_automation = data.disable_representation_network_automation
          ? data.disable_representation_network_automation
          : false;
        computedData.push(record);
      });
    }
    return computedData;
  };

  render() {
    let networkData = this.props.networkData || [];

    this.columns = [
      {
        title: getCurrentlocaleText("node.repnet.edgenode.title"),
        key: "nodeName",
        width: "20%",
        ...this.getColumnSearchProps("nodeName"),
        render: (text, record, index) => {
          return (
            <div>
              {record.isCluster ? (
                <Tag>
                  <Icons className={"mr-5"} type="bs" name="BsBoundingBox" />
                  {record.nodeName}
                </Tag>
              ) : (
                <Tag>
                  <Icons className={"mr-5"} type="fa" name="FaRegHdd" />
                  {record.nodeName}
                </Tag>
              )}
            </div>
          );
        },
      },
      {
        title: getCurrentlocaleText("network.remote_network.text"),
        key: "networkName",
        width: "20%",
        ...this.getColumnSearchProps("networkName"),
        render: (text, record, index) => {
          return (
            <div>
              <Tag>
                <Icons className={"mr-5"} type="fa" name="FaConnectdevelop" />
                {record.networkName}
              </Tag>
            </div>
          );
        },
      },
      {
        title: getCurrentlocaleText("node.repnet.remotenetwork.title"),
        key: "remoteNetworkIpAddress",
        width: "20%",
        ...this.getColumnSearchProps("remoteNetworkIpAddress"),
        render: (text, record, index) => {
          return (
            <div>
              <span>{record.remoteNetworkIpAddress}</span>
            </div>
          );
        },
      },
      {
        title: getCurrentlocaleText("vnode.repnet.cidr.title"),
        key: "repNetIpAddress",
        ...this.getColumnSearchProps("repNetIpAddress"),
        width: "20%",
        render: (text, record, index) => {
          return (
            <div>
              <span>{record.repNetIpAddress}</span>
            </div>
          );
        },
      },
      {
        title: getCurrentlocaleText(
          "vnode.repnet.remotenetwork.automation.title",
        ),
        key: "repNetAutomation",
        width: "20%",
        render: (text, record, index) => {
          return (
            <div>
              <span>
                {record && record.disable_representation_network_automation ? (
                  <Tag>{"Disabled"}</Tag>
                ) : (
                  <Tag>{"Enabled"}</Tag>
                )}
              </span>
            </div>
          );
        },
      },
    ];
    return (
      <div>
        <span onClick={this.showRepnetData}>
          <ApartmentOutlined
            style={{ margin: 0, fontSize: 14, cursor: "pointer" }}
            title={getCurrentlocaleText("inode.repnet.heairchy.title")}
          />
        </span>
        {this.state.showInfo && (
          <Modal
            title={getCurrentlocaleText(
              "vnode.repnet.remotenetwork.hierarchy.title",
            )}
            visible={this.state.showInfo}
            maskClosable={false}
            onCancel={this.showRepnetData}
            width={1000}
            footer={[
              <Button key="ok" type="primary" onClick={this.showRepnetData}>
                {"Close"}
              </Button>,
            ]}
          >
            {/* <Row>
              <Col push={18}>
                <RadioGroup
                  size="small"
                  value={this.state.viewType}
                  onChange={this.changeView}
                  type={"primary"}
                >
                  <RadioButton value={"TABLE"}>{"TABLE VIEW"}</RadioButton>
                  <RadioButton value={"TREE"}>{"TREE VIEW"}</RadioButton>
                </RadioGroup>
              </Col>
            </Row> */}
            {this.state.viewType === "TABLE" && (
              <div>
                <Row>
                  <Col span={8}>
                    {getCurrentlocaleText("inode.repnet.virtual.name")}
                  </Col>
                  <Col span={12}>{this.props.virtualNodeName}</Col>
                </Row>
                <Row>
                  <Col span={8}>
                    {getCurrentlocaleText("inode.repnet.virtual.cide.title")}
                  </Col>
                  <Col span={12}>
                    {this.props.cloudRouteInfo.cidr
                      ? this.props.cloudRouteInfo.cidr
                      : "NA"}
                  </Col>
                </Row>
              </div>
            )}
            <Row>
              <Col>
                {/* {this.state.viewType === "TREE" && this.renderList(networkData)} */}
                {this.state.viewType === "TABLE" && (
                  <Table
                    columns={this.columns}
                    dataSource={this.getComputedData(networkData)}
                    pagination={false}
                    className="RepNetHierarchyList"
                    rowSelection={null}
                    expandedRowRender={false}
                    scroll={{ y: 500 }}
                  />
                )}
              </Col>
            </Row>
          </Modal>
        )}
      </div>
    );
  }
}
export default RepNetHierarchy;
