import React, { Component } from "react";
import { Row, Col } from "antd";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import BreadcrumbComponent from "components/UI-Components/BreadcrumbComponent";
import Icons from "components/UI-Components/Icons";
import { observer, inject } from "mobx-react";
import {
  isCameFromDashboardPage,
  getInodeDetailsBreadcrumbUrl,
  getInodeDetailsBreadcrumbLinkName,
  getCurrentlocaleText,
} from "Core/Utils";
import WanFirewall from "components/Containers/WanFirewall";
import InodeController from "controller/InodeController";
import NetworkController from "controller/NetworkController";
import FirewallGroupController from "controller/FirewallGroupController";
import OrgController from "controller/OrgController";
import OrgSelector from "components/Orgs/OrgSelector";

@inject(
  "ModelStore",
  "AuthStore",
  "UiStore",
  "InodeViewModel",
  "NetworkViewModel",
)
@observer
class InodeSecurityPolicy extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = this.props.InodeViewModel;
    this.networkModel = this.props.NetworkViewModel;

    this.state = {
      nodeId: this.props.match.params.nodeId || this.props.match.params.id,
      nodeDetails: {},
      BreadcrumbList: [],
      selectedNode: {},
      disableReboot: true,
      availableNetwork: [],
      orgId: this.props.match.params.id,
      orgsNetwork: [],
    };
  }
  componentDidMount() {
    OrgController.getOrg(
      this.inodeModel.inode.organization
        ? this.inodeModel.inode.organization.id
        : this.props.orgId || this.props.match.params.id,
      false,
      this.props.UiStore.parentOrgData && this.props.UiStore.parentOrgData.id
        ? false
        : true,
    ).then(res => {
      if (res.parent_organization)
        this.props.UiStore.parentOrgData = res.parent_organization;
      else this.props.UiStore.parentOrgData = {};
    });
    this.iNodeDetails(null, true, true);
    this.getNetworkdata();
    this.getFirewallGroups();
  }
  get cameFromOrgDetailPage() {
    if (this.props.match.params.nodeId) {
      return true;
    }
    return false;
  }

  get cameFromDashboardPage() {
    return isCameFromDashboardPage(window.location.pathname);
  }

  get dashboardiNodeURL() {
    return getInodeDetailsBreadcrumbUrl(window.location.pathname);
  }

  get linkNameForiNode() {
    return getInodeDetailsBreadcrumbLinkName(window.location.pathname);
  }
  /**
   * Get Network data for the page
   */
  getNetworkdata = () => {
    NetworkController.getNetworks({
      org_id: this.inodeModel.inode.organization
        ? this.inodeModel.inode.organization.id
        : this.props.orgId || this.props.match.params.id,
      own: true,
      size: 1,
      page: 0,
    }).then(res => {
      if (res) {
        this.updateOrgsNetworkData(
          this.networkModel.pageable.total_count / 100,
          [],
        );
      }
    });
  };

  updateOrgsNetworkData(totalCount, availableNetworks) {
    if (totalCount > 0) {
      let allParams = [];
      for (let page = 0; page < totalCount; page++) {
        if (this.networkModel.pageable.next) {
          allParams.push({
            org_id: this.inodeModel.inode.organization
              ? this.inodeModel.inode.organization.id
              : this.props.orgId || this.props.match.params.id,
            own: true,
            size: 100,
            page: page,
          });
        }
      }
      NetworkController.getAllNetworksForDropdown(allParams).then(resp => {
        if (resp) {
          availableNetworks = availableNetworks.concat(resp);

          let currentNodeID =
            this.props.match.params.nodeId || this.props.match.params.id;
          if (currentNodeID) {
            let networks = availableNetworks.filter(network => {
              if (network.node && network.node.id === currentNodeID)
                return network;
            });
            this.setState({
              availableNetwork: networks,
              orgsNetwork: availableNetworks,
              orgId: this.inodeModel.inode.organization
                ? this.inodeModel.inode.organization.id
                : this.props.orgId || this.props.match.params.id,
              nodeId: currentNodeID,
            });
          }
        }
      });
    }
  }
  iNodeDetails = (res = null, loading = true, forceCall = false) => {
    //let resource = "inodes",
    let map = {
      id: res !== null ? res.id : this.state.nodeId,
    };
    InodeController.getInode(map.id, loading, forceCall).then(res => {
      return this.updateInodeDetails(res);
    });
  };
  getFirewallGroups = (
    loading = true,
    page = 0,
    search = "",
    sortBy = null,
    sortOrder = null,
  ) => {
    let params = {
      org_id: this.inodeModel.inode.organization
        ? this.inodeModel.inode.organization.id
        : this.props.orgId || this.props.match.params.id,
      own: true,
    };
    params.page = page;
    params.search = search;

    let firewall_labels = [];
    // For sort
    if (sortBy && sortOrder) {
      params.sort = `${sortBy}:${sortOrder}`;
    }
    FirewallGroupController.getFirewallGroups(params, loading).then(res => {
      res.forEach(l => {
        if (l.metadata) {
          for (var key in l.metadata.labels) {
            if (key && key.charAt(0) != "_")
              firewall_labels.push(key + ":" + l.metadata.labels[key]);
          }
        }
      });
      this.setState({
        firewallLables: res,
        firewallLabel_options: firewall_labels,
      });
    });
  };
  updateInodeDetails = res => {
    if (res && res.id) {
      this.setState(() => {
        let newObj = {},
          nodeDetails = this.state.nodeDetails,
          selectedNode = this.state.selectedNode,
          BreadcrumbList = [],
          disableReboot = this.state.disableReboot;

        nodeDetails = res;
        selectedNode[res.id] = {
          value: true,
          status: res.node_state.toLowerCase(),
          name: res.name,
          id: res.id,
        };
        res.node_state.toLowerCase() === "alive" &&
        (this.props.AuthStore.IsPermitted("NODE:CREATE") ||
          this.props.AuthStore.IsPermitted("NODE:REBOOT"))
          ? (newObj["disableReboot"] = false)
          : (newObj["disableReboot"] = true);

        let nodeCrumb = [
          {
            name: res.name ? res.name : "Details",
            link: this.cameFromOrgDetailPage
              ? `orgs/${res.organization.id}/inodes/${res.id}`
              : this.cameFromDashboardPage
              ? this.dashboardiNodeURL.indexOf("dashboard/service") === -1
                ? `${this.dashboardiNodeURL}/${res.id}`
                : `${this.dashboardiNodeURL}/${res.id}/services`
              : `inodes/${res.id}`,
          },
        ];
        if (this.cameFromOrgDetailPage) {
          BreadcrumbList.push(
            // { name: "Organizations", link: "orgs" },
            {
              name: res.organization.name,
              link: `orgs/${res.organization.id}`,
            },
            { name: "iNodes", link: `orgs/${res.organization.id}/inodes` },
            ...nodeCrumb,
            {
              name: getCurrentlocaleText(
                "network.security_policy.show_link.text",
              ),
              link: `orgs/${res.organization.id}/inodes/${res.id}/securitypolicy`,
            },
          );
        } else if (this.cameFromDashboardPage) {
          BreadcrumbList.push(
            {
              name: this.linkNameForiNode,
              link: this.dashboardiNodeURL,
            },
            ...nodeCrumb,
          );
        } else {
          BreadcrumbList.push({ name: "iNodes", link: "inodes" }, ...nodeCrumb);
        }
        this.props.UiStore.setInodeName(res.name);
        this.props.UiStore.setOrgName(res.organization.name);
        newObj["nodeDetails"] = nodeDetails;
        newObj["BreadcrumbList"] = BreadcrumbList;
        newObj["selectedNode"] = selectedNode;
        return newObj;
      });
    }
  };

  render() {
    let node_data = this.inodeModel.inode;
    let container = (
      <div>
        <Row gutter={16} type="flex" align="middle">
          <Col lg={{ span: 18 }} xl={{ span: 20 }}>
            <h2 className="page-title">
              <Icons type="fa" name="FaRegHdd" />
              {"  "}
              {node_data.name ? node_data.name : null}
            </h2>
            <BreadcrumbComponent
              {...this.props}
              BreadcrumbList={this.state.BreadcrumbList}
            />
          </Col>
          <Col lg={{ span: 6 }} xl={{ span: 4 }} className="flex flex-h-right">
            <OrgSelector {...this.props} />
          </Col>
        </Row>
        <div>
          <WanFirewall
            {...this.props}
            firewallResource="firewallgroup"
            orgId={this.props.orgId}
            FirewallStatus={this.state.availableNetwork}
            availableNetwork={this.state.availableNetwork}
            orgsNetwork={this.state.orgsNetwork}
            NetworkID={this.props.netWorkId}
            ShowAll={true}
            nodeState={node_data.node_state ? node_data.node_state : null}
            nodeId={this.props.match.params.nodeId}
          />
        </div>
      </div>
    );
    return (
      <div>
        <LoadingComponent
          loading={
            !this.inodeModel.loading && !this.networkModel.loading
              ? false
              : true
          }
        >
          {container}
        </LoadingComponent>
      </div>
    );
  }
}

export default InodeSecurityPolicy;
