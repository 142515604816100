import React, { Component } from "react";
import { InodeController } from "controller/Controllers";
import { customSort, copyObject } from "Core/Utils";
import { Select } from "antd";
import axios from "axios";

const Option = Select.Option;

class ServiceTemplatesUtils extends Component {
  validateRegex = (secret, templatingServiceObj, UiStore) => {
    return new Promise((resolve, reject) => {
      if (!secret || !templatingServiceObj) {
        /* reject if secret/templatingServiceObj is missing */
        reject(false);
        return false;
      }
      let val,
        //base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/,
        base64regex = /(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)/,
        isBase64 = base64regex.test(
          templatingServiceObj.secret_volume[`${secret}_textarea`],
        );

      if (!isBase64 && !templatingServiceObj.isBase64) {
        /*Plain text and not base64 checked*/
        try {
          /*convert to base64*/
          val = btoa(templatingServiceObj.secret_volume[`${secret}_textarea`]);

          resolve(val);
        } catch (e) {
          if (UiStore) {
            UiStore.errorMessage =
              "Error in encoding the key to base64 format.Please check the SSH Public key.";
          }

          reject(false);
          return null;
        }
      } else if (!isBase64 && templatingServiceObj.isBase64) {
        /*Plain text and base64 checked*/
        if (UiStore) {
          UiStore.errorMessage =
            "Please provide the SSH public key in base64 encoded format.";
        }

        reject(false);
        return null;
      } else if (isBase64 && templatingServiceObj.isBase64) {
        /*Already encoded : encoding not required*/
        val = templatingServiceObj.secret_volume[`${secret}_textarea`];

        resolve(val);
      } else if (isBase64 && !templatingServiceObj.isBase64) {
        /*Key encoded but didnt mention in UI , it must be encoded*/
        val = btoa(templatingServiceObj.secret_volume[`${secret}_textarea`]);
        resolve(val);
      }
    });
  };

  createCustomObj = (customObj, toJSObj) => {
    let parsed = copyObject(customObj);
    for (var key in toJSObj) {
      if (toJSObj.hasOwnProperty(key)) {
        parsed[key] = toJSObj[key];
      }
    }

    // Set customObj networks to parsed JSObj
    if (customObj.networks) {
      if (parsed.networks) {
        customObj.networks.forEach(nw => {
          if (
            parsed.networks.findIndex(
              item => item && item.network_id == nw.network_id,
            ) === -1
          ) {
            parsed.networks.push(nw);
          }
        });
      }
    }

    // Set customObj labels to parsed JSObj
    if (customObj.labels) {
      if (parsed.labels) {
        parsed.labels = Object.assign(parsed.labels, customObj.labels);
      } else {
        parsed.labels = customObj.labels;
      }
    }

    // Set default custom template labels
    if (parsed.labels) {
      parsed.labels["io_iotium_template"] = "custom";
      parsed.labels["_iotium_template"] = "custom";
    } else {
      parsed.labels = {
        io_iotium_template: "custom",
        _iotium_template: "custom",
      };
    }

    if (parsed._links) {
      delete parsed._links;
    }

    return parsed;
  };

  validateUserInput = (regex, value) => {
    /*remove quotes from regex received as props*/
    let reg_x = new RegExp(regex);
    return reg_x.test(value);
  };

  fetchInodes = (
    params,
    loading = true,
    forceCall = true,
    resolveData = true,
  ) => {
    return InodeController.getInodes(params, loading, forceCall, resolveData);
  };

  getNodesFromOrgsList = org_id => {
    return new Promise((resolve, reject) => {
      let allreceivedNodes = [],
        multiParmas = [],
        nodeWithOrg = [];

      org_id.forEach((id, index) => {
        let attr = {
          org_id: id,
          own: true,
          size: 1,
          profile_name: ["edge", "virtual edge"],
        };
        nodeWithOrg.push(this.fetchInodes(attr, true, true, false));
      });
      axios.all(nodeWithOrg).then(
        nodeResp => {
          nodeResp.forEach(nodes => {
            if (nodes.results.length > 0) {
              let size = 25,
                totalApiCount;
              totalApiCount = Math.ceil(nodes.total_count / size);
              /*  for performance use axios.all */
              for (let page = 0; page < totalApiCount; page++) {
                let obj = {
                  org_id: nodes.results[0].organization.id,
                  own: true,
                  size,
                  page,
                  profile_name: ["edge", "virtual edge"],
                };
                multiParmas.push(this.fetchInodes(obj, true, true, false));
              }
            } else {
              //resolve([]);
            }
          });

          if (multiParmas.length > 0) {
            axios.all(multiParmas).then(
              responses => {
                responses.length > 0 &&
                  responses.forEach(val => {
                    val.results.forEach(inode => {
                      let in_dx = allreceivedNodes.findIndex(val => {
                        return val.id === inode.id;
                      });

                      if (in_dx === -1) {
                        allreceivedNodes.push(inode);
                      }
                    });
                  });
                resolve(allreceivedNodes);
              },
              error => {
                console.debug(error);
                reject([]);
              },
            );
          } else {
            resolve([]);
          }
        },
        error => {
          console.debug(error);
          reject([]);
        },
      );
    });
  };

  getSelectedServiceOrg = (attrs, InodeViewModel) => {
    let currentOrg = null;
    if (!attrs) return null;

    /*
      based on node selection, org is decided.
    */
    if (
      InodeViewModel &&
      InodeViewModel.inodes &&
      InodeViewModel.inodes.length > 0 &&
      attrs
    ) {
      let formValues = attrs,
        nodeId;

      if (Object.keys(formValues).length > 0) {
        let allFormKeys = Object.keys(formValues);

        let selectedNode = allFormKeys.filter(val => {
          return val.indexOf("nodenetwork") >= 0 && val.indexOf("nodeid") >= 0;
        });
        nodeId = selectedNode.length > 0 && formValues[selectedNode[0]];
      }

      nodeId &&
        InodeViewModel.inodes.map(node => {
          if (node.id === nodeId) {
            currentOrg = node.organization && node.organization.id;
          }
          return null;
        });
    }
    return currentOrg;
  };

  listDataForSelect = data => {
    if (data && data.length > 0) {
      return data
        .sort((x, y) => {
          return customSort(x.name, y.name);
        })
        .map((val, index) => {
          /* disable when no content found */
          return (
            <Option key={val.id} disabled={val.id === null}>
              {val.name}
            </Option>
          );
        });
    } else {
      return (
        <Option key={null} disabled={true}>
          {"No content found"}
        </Option>
      );
    }
  };
}

export default new ServiceTemplatesUtils();
