import React, { Component } from "react";
import { Row, Col } from "antd";
import BreadcrumbComponent from "components/UI-Components/BreadcrumbComponent";
import Icons from "components/UI-Components/Icons";
import ActivityList from "components/DataLists/ActivityList";
import OrgSelector from "components/Orgs/OrgSelector";
import { getCurrentlocaleText } from "Core/Utils";

class Activity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      BreadcrumbList: [
        {
          name: getCurrentlocaleText("activity.text"),
          link: window.location.pathname.substring(1),
        },
      ],
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (this.props !== props) {
      let breadcrumbList = this.state.BreadcrumbList;
      if (breadcrumbList[0]) {
        breadcrumbList[0].link = window.location.pathname.substring(1);
        this.setState({ BreadcrumbList: breadcrumbList });
      }
    }
  }

  render() {
    return (
      <div>
        <Row gutter={24} type="flex" align="middle">
          <Col lg={{ span: 18 }} xl={{ span: 20 }}>
            <h2 className="page-title">
              <Icons type="md" name="MdEventNote" className="valign-middle" />
              <Icons type="md" name="MdPerson" className="event-header-icon" />
              {"  "}
              {getCurrentlocaleText("activity.text")}
            </h2>
            <BreadcrumbComponent
              {...this.props}
              BreadcrumbList={this.state.BreadcrumbList}
            />
          </Col>
          <Col lg={{ span: 6 }} xl={{ span: 4 }} className="flex flex-h-right">
            <OrgSelector {...this.props} />
          </Col>
        </Row>
        <div style={{ minHeight: 360 }}>
          <Row gutter={16} type="flex" align="middle">
            <Col span={24}>
              <ActivityList {...this.props} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Activity;
