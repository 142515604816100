import React, { Component } from "react";
import { Select, Alert } from "antd";
import { Form } from "@ant-design/compatible";
import Input from "components/UI-Components/InputBox";
import { observer, inject } from "mobx-react";
import _ from "lodash";
import { focusFirstInput, customSort, getCurrentlocaleText } from "Core/Utils";
import FormErrorMessage from "components/UI-Components/FormErrorMessage";
import OrgController from "controller/OrgController";
import RoleController from "controller/RoleController";

const FormItem = Form.Item;
const Option = Select.Option;

@inject("UiStore", "AuthStore", "OrgViewModel")
@observer
class RolesForm extends Component {
  constructor(props) {
    super(props);
    this.orgModel = this.props.OrgViewModel;
    this.getinitialValue = this.getinitialValue.bind(this);
    this.tempArray = [];
    this.state = {
      isPermissionChanged: false,
      isApiAccessEnabled: false,
      showLogoutWarning: false,
    };
  }

  componentDidMount() {
    this.tempArray = [];
    let params = {};
    if (this.orgModel.org && this.orgModel.org.id) {
      params =
        {
          org_id:
            this.orgModel.org && this.orgModel.org.id
              ? this.orgModel.org.id
              : null,
        } || {};
    } else {
      params = { org_id: this.props.orgId };
    }
    RoleController.getPermissions(params).then(resp => {
      this.props.UiStore.permissions = resp;
    });
    /* Focus First Input */
    focusFirstInput();

    /* Find current user role and give warning message */
    if (this.props.action === "edit") {
      let currentUserRoles = this.props.UiStore.currentUser.roles;
      if (currentUserRoles && currentUserRoles.length > 0) {
        let isRoleMatched = false;
        currentUserRoles.forEach(role => {
          if (
            this.props.map &&
            role.id === this.props.map.id &&
            !isRoleMatched
          ) {
            isRoleMatched = true;
          }
        });
        // if current user role matches the updated role
        if (isRoleMatched) {
          this.setState({ showLogoutWarning: true });
        } else {
          this.setState({ showLogoutWarning: false });
        }
      }

      // Get org of this role
      if (this.props.orgId) {
        OrgController.getOrgById(this.props.orgId).then(response => {
          if (response) {
            if (response.policy && response.policy.apikey) {
              this.setState({
                isApiAccessEnabled: response.policy.apikey.enable,
              });
            } else {
              this.setState({
                isApiAccessEnabled: false,
              });
            }
          }
        });
      }
    }
  }

  //render list for permission options
  renderList = () => {
    let optionArray = [];
    this.props.UiStore.permissions.map((val, index) => {
      optionArray.push(val);
    });
    return optionArray
      .sort((x, y) => customSort(x.permission, y.permission))
      .map((val, index) => {
        return (
          <Option title={val.description} key={val.id} value={val.permission}>
            {val.permission}
          </Option>
        );
      });
  };
  //initial value of the available permission
  getinitialValue = () => {
    return this.props.initialVal &&
      this.props.initialVal.permissions &&
      this.props.initialVal.permissions.length &&
      this.props.initialVal.permissions.length > 0
      ? this.props.initialVal.permissions.map(val => {
          return { key: val.id, value: val.permission, label: val.permission };
        })
      : [];
  };

  onPermissionsChanged = values => {
    // Check if permissions has changed or not to show warning.
    let initial = this.getinitialValue(),
      isChanged = false;
    if (initial && values) {
      if (values.length == 0) {
        isChanged = true;
      } else if (initial.length < values.length) {
        isChanged = true;
      } else {
        for (let i = 0; i < initial.length; i++) {
          let val = initial[i];
          if (
            val &&
            val.key &&
            values.findIndex(newVal => {
              return newVal.key === val.key;
            }) === -1
          ) {
            isChanged = true;
            break;
          }
        }
      }
    }
    this.setState({ isPermissionChanged: isChanged });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form noValidate onSubmit={this.props.onSubmit} className="">
        <FormItem label="Role Name">
          {getFieldDecorator("name", {
            rules: [
              {
                required: true,
                message: "Please enter a role name",
                whitespace: true,
              },
              {
                max: 255,
                message: "Role name cannot be longer than 255 characters.",
              },
              {
                pattern: /^[A-Za-z0-9 .&,_-]*$/,
                message:
                  "Role name can contain only the following special characters . , & - _",
              },
            ],
            initialValue: this.props.initialVal
              ? this.props.initialVal.name
              : "",
          })(
            <Input
              className="firstInput"
              autoComplete="off"
              placeholder="Role name"
              disabled={this.props.action === "view"}
            />,
          )}
        </FormItem>
        <FormItem label="Description">
          {getFieldDecorator("description", {
            rules: [
              {
                message: "Please provide a description",
                whitespace: true,
              },
              {
                max: 1000,
                message: "Description cannot be longer than 1000 characters.",
              },
            ],
            initialValue: this.props.initialVal
              ? this.props.initialVal.description
              : "",
          })(
            <Input
              autoComplete="off"
              placeholder="Description"
              disabled={this.props.action === "view"}
            />,
          )}
        </FormItem>

        {this.props.UiStore.permissions.length > 0 ? (
          <FormItem label="Permissions">
            {getFieldDecorator("permissions", {
              rules: [
                {
                  required: true,
                  message: "Please select atleast one permission",
                  type: "array",
                },
              ],
              initialValue: this.props.initialVal ? this.getinitialValue() : [],
            })(
              <Select
                showSearch
                optionFilterProp="children"
                mode="multiple"
                labelInValue
                placeholder={getCurrentlocaleText(
                  "roles.permissions.select.placeholder.text",
                )}
                disabled={this.props.action === "view"}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={this.onPermissionsChanged}
                notFoundContent={getCurrentlocaleText(
                  "general.notfound.placeholder.text",
                )}
              >
                {this.renderList()}
              </Select>,
            )}
          </FormItem>
        ) : (
          <p>No permission available</p>
        )}

        {this.props.action === "edit" &&
          this.props.UiStore.permissions.length > 0 &&
          this.state.isPermissionChanged &&
          this.props.userCount > 0 &&
          (this.state.isApiAccessEnabled || this.state.showLogoutWarning) && (
            <Alert
              type="warning"
              message={
                <div>
                  {this.state.isApiAccessEnabled && (
                    <div>
                      {getCurrentlocaleText(
                        "role.permissionchange.warning.message",
                      )}
                    </div>
                  )}
                  {this.state.showLogoutWarning && (
                    <div>
                      {getCurrentlocaleText(
                        "role.permissionchange.logout.warning.message",
                      )}
                    </div>
                  )}
                </div>
              }
              showIcon
            />
          )}
        <FormErrorMessage />
      </Form>
    );
  }
}

const RolesForms = Form.create()(RolesForm);
export default RolesForms;
