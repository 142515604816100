import React, { Component } from "react";
import { Link } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { Checkbox, Menu, Row, Col, Tag, Popover } from "antd";
import ActionBtn from "Core/API/ActionBtn";
import {
  highlightSearchText,
  getCurrentlocaleText,
  getOrgPolicyCounInfo,
} from "Core/Utils";
import SearchInput from "components/UI-Components/SearchInput";
import ClusterController from "controller/ClusterController";
import ActionRoute from "Core/API/ActionRoute";
import DropdownActionBtn from "components/UI-Components/DropdownActionBtn";
import Icons from "components/UI-Components/Icons";
import TableLayoutV1 from "../layout/TableLayoutV1";
import LoadingComponent from "../UI-Components/LoadingComponent";

@inject("AuthStore", "ClusterViewModel", "UiStore", "OrgViewModel")
@observer
class ClusterList extends Component {
  constructor(props) {
    super(props);
    this.clusterModel = this.props.ClusterViewModel;
    this.orgModel = this.props.OrgViewModel;
    this.searchTimeout = null;
    this.clusterListByInterval = null;
    this.state = {
      searchText: "",
      filterInfo: null,
      sorterInfo: { sorter: {}, sort: {} },
      expandedRowKeys: [],
      deletableClusters: {
        clusters: [],
      },
      selectedCluster: {},
      selectedRowKeys: [],
      currentPage: 1,
      isTableModalToggle: false,
      modalContent: "",
      modalTitle: "",
    };
  }

  componentDidMount() {
    if (this.props.isFromDashboard) {
      this.props.UiStore.showChildClusters = true;
    } else {
      this.props.UiStore.showChildClusters = false;
    }
    /*API CALL every 1 Minute Intiated HERE */
    this.clusterListByInterval = setInterval(() => {
      this.clusterList(
        false,
        this.state.currentPage - 1,
        this.state.searchText,
        this.state.sorterInfo.sort.by,
        this.state.sorterInfo.sort.order,
        null,
        false,
      );
    }, 30000);
    this.clusterList(
      true,
      this.state.currentPage - 1,
      this.state.searchText,
      this.state.sorterInfo.sort.by,
      this.state.sorterInfo.sort.order,
      null,
      false,
    );
  }

  changeListview = e => {
    this.props.UiStore.showChildClusters = e.target.checked;
    this.setState({ currentPage: 1 });
    this.clusterList(
      true,
      0,
      this.state.searchText,
      this.state.sorterInfo.sort.by,
      this.state.sorterInfo.sort.order,
      this.state.filterInfo,
      false,
    );
  };
  deleteAfterCall = () => {
    let page = this.state.currentPage ? this.state.currentPage : 0;
    if (this.clusterModel.clusterListData.length == 0) {
      page--;
    }
    this.clusterList(
      true,
      page - 1,
      this.state.searchText,
      this.state.sorterInfo.sort.by,
      this.state.sorterInfo.sort.order,
      this.state.filterInfo,
      true,
    );
    this.resetState();
  };

  clusterListFilter = e => {
    clearTimeout(this.searchTimeout);
    let searchText = e.target.value;
    this.setState({ currentPage: 1, searchText: searchText });
    this.props.UiStore.nodeParams.searchText = searchText;
    this.searchTimeout = setTimeout(() => {
      this.clusterList(
        false,
        0,
        searchText,
        this.state.sorterInfo.sort.by,
        this.state.sorterInfo.sort.order,
        this.state.filterInfo,
        false,
      );
    }, 500);
  };

  UNSAFE_componentWillUpdate(newProps) {
    if (this.props.match.params.id != newProps.match.params.id) {
      this.clusterList(
        true,
        0,
        this.state.searchText,
        this.state.sorterInfo.sort.by,
        this.state.sorterInfo.sort.order,
        this.state.filterInfo,
        false,
        newProps,
      );
    }
  }

  componentWillUnmount() {
    clearInterval(this.clusterListByInterval);
  }

  clusterList = (
    loading = true,
    page = 0,
    search = "",
    sortBy = null,
    sortOrder = null,
    filter = null,
    forcecall = false,
  ) => {
    let params = {};
    params.page = page;
    params.search = search;

    // Find org id
    let orgId =
      this.props.params && this.props.params.org_id
        ? this.props.params.org_id
        : this.props.match &&
          this.props.match.params &&
          this.props.match.params.id
        ? this.props.match.params.id
        : null;

    // Filter with org
    if (orgId) {
      params.org_id = orgId;
    }

    // For sort
    if (sortBy && sortOrder) {
      params.sort = `${sortBy}:${sortOrder}`;
    }
    // For Filter
    if (filter) {
      let keys = Object.keys(filter);
      keys.forEach(key => {
        if (filter[key]) {
          params[key] = filter[key];
        }
      });
    }
    if (this.props.UiStore.showChildClusters) {
      delete params.own;
    } else {
      params.own = true;
    }
    ClusterController.getClusters(params, loading, forcecall).then(result => {
      let clusters = result,
        clusterServiceParams = [],
        clusterNetworkParams = [],
        clusterNodeParams = [];
      if (clusters && clusters.length > 0) {
        clusters.forEach(cluster => {
          clusterServiceParams.push({
            cluster_id: cluster.id,
            size: 100,
            page: 0,
            inheritance: false,
          });
          clusterNetworkParams.push({
            cluster_id: cluster.id,
            size: 100,
            page: 0,
            inheritance: false,
          });
          clusterNodeParams.push({
            cluster_id: cluster.id,
            size: 100,
            page: 0,
          });
        });
        ClusterController.getClusterServiceCount(clusterServiceParams, true);
        ClusterController.getClusterNetwork(clusterNetworkParams, true);
        ClusterController.getClusteriNodes(clusterNodeParams, true);
      }
    });
  };

  clearSearchFilter = () => {
    this.setState({ currentPage: 1, searchText: "" });
    this.clusterList(
      false,
      0,
      "",
      this.state.sorterInfo.sort.by,
      this.state.sorterInfo.sort.order,
      this.state.filterInfo,
      false,
    );
  };

  onTableChange = (pagination, filter, sorter) => {
    let sort = {};
    if (sorter.columnKey && sorter.order) {
      if (sorter.order === "ascend") {
        sort.order = "ASC";
      } else {
        sort.order = "DESC";
      }
      sort.by = sorter.columnKey;
    }
    this.setState({
      currentPage: pagination.current,
      filterInfo: filter,
      sorterInfo: { sorter: sorter, sort: sort },
    });
    let page = pagination.current - 1;
    this.clusterList(
      true,
      page,
      this.state.searchText,
      this.state.sorterInfo.sort.by,
      this.state.sorterInfo.sort.order,
      this.state.filterInfo,
      false,
    );
  };

  resetState = () => {
    this.setState({
      selectedCluster: {},
      deletableClusters: {
        clusters: [],
      },
    });
  };

  expandRow = () => {
    let keys = this.clusterModel.clusters.map(item => {
      return item.id;
    });
    this.setExpandedRowKeys(
      this.state.expandedRowKeys.length !== keys.length ? keys : [],
    );
  };

  onExpand = (expanded, record) => {
    let keys = this.state.expandedRowKeys;
    let index = keys.findIndex(val => {
      return val === record.id;
    });
    if (expanded) {
      if (index === -1) {
        keys.push(record.id);
      }
      this.setExpandedRowKeys(keys);
    } else {
      if (index !== -1) {
        keys.splice(index, 1);
      }
      this.setExpandedRowKeys(keys);
    }
  };

  setExpandedRowKeys = keys => {
    this.setState({ expandedRowKeys: keys });
  };

  lazyLoad = () => {
    if (this.props.ClusterViewModel.pageable.next) {
      let page = this.props.ClusterViewModel.pageable.page + 1;
      this.clusterList(
        true,
        page,
        this.state.searchText,
        this.state.sorterInfo.sort.by,
        this.state.sorterInfo.sort.order,
        this.state.filterInfo,
        false,
      );
    }
  };
  onSelectAllChange = (selected, selectedRows, changeRows) => {
    if (selected) {
      this.setSelectedStates(null, selected, selectedRows);
    } else {
      // on deselect all
      this.resetState();
    }
  };

  setSelectedStates = (record = {}, selected, selectedRows) => {
    for (let i = 0; i < selectedRows.length; i++) {
      if (selectedRows[i] && selectedRows[i].id) {
        let id = selectedRows[i].id;
        this.setState(() => {
          let newObj = {
              deletableClusters: {
                clusters: [],
              },
            },
            selectedCluster = this.state.selectedCluster,
            item = selectedRows[i],
            tempArray = [];

          selectedCluster[id] = {
            value: true,
            name: item.name,
            id: item.id,
          };

          //Temp array for deletable orgs list

          for (let i in selectedRows) {
            if (selectedRows[i]) {
              selectedCluster[selectedRows[i].id]
                ? tempArray.push(selectedCluster[selectedRows[i].id])
                : "";
            }
          }

          // To set the value : false , when orgs is deselected and remove it from delete list

          !selected && selectedCluster[record.id]
            ? ((selectedCluster[record.id].value = false),
              (tempArray = _.filter(tempArray, val => {
                return val.id !== record.id;
              })))
            : null;

          newObj["selectedCluster"] = selectedCluster;
          newObj.deletableClusters["clusters"] = tempArray;
          return newObj;
        });
      }
    }
  };

  handleTableChange = (record, selected, selectedRows) => {
    selectedRows.length == 0 ? this.resetState() : "";
    this.setSelectedStates(record, selected, selectedRows);
  };
  /**
   * GET CLUSTER ROUTE
   */
  getCurrentRoute = () => {
    let current_path = window.location.pathname.split("/");
    current_path = current_path.splice(0, 5);
    current_path = current_path.join("/");
    return current_path;
  };
  getTags = data => {
    if (data.inodes.length > 0) {
      let master_status =
        data.status && data.status.nodes ? data.status.nodes : [];
      let master_count = 0;
      if (master_status.length > 0) {
        let filtered_master = master_status.filter(status => {
          if (status.state === "MASTER") return status;
        });
        master_count = filtered_master.length;
      }
      return data.inodes.map((nodes, index) => {
        let status = null,
          state = "BACKUP";
        if (nodes.node && nodes.node.id && data.status && data.status.nodes)
          status = data.status.nodes.filter(n => n.id === nodes.node.id);
        if (status && status.length > 0 && status[0]) {
          state = status[0].state;
        }
        let nodeState =
          nodes && nodes.nodeDetails && nodes.nodeDetails.node_state
            ? nodes.nodeDetails.node_state
            : null;
        let is_candidate =
          nodes && nodes.config && nodes.config.is_candidate
            ? nodes.config.is_candidate
            : false;
        return (
          <span key={nodes.node.id}>
            <Row className={"master-warning-span"}>
              <Col style={{ display: "flex" }}>
                <Tag className="ellipsis-modal">
                  <Icons
                    type="fa"
                    name="FaSitemap"
                    className="valign-text-bottom iot-tag-icon-style"
                  />
                  {" " + data.organization.name + "/"}
                  <Icons
                    type="fa"
                    name="FaRegHdd"
                    className="valign-text-bottom iot-tag-icon-style"
                  />
                  {" " + nodes.node.name}
                </Tag>
                {state === "MASTER" && is_candidate ? (
                  <div className="d-inline-flex">
                    {master_count == 1 && (
                      <Tag className="iot-tag-label-color" closable={false}>
                        {getCurrentlocaleText(
                          "cluster.node_list.master.cidr.text",
                        )}
                      </Tag>
                    )}
                    {master_count > 1 && (
                      <Popover
                        overlayStyle={{ maxWidth: "20%", float: "right" }}
                        placement="right"
                        content={getCurrentlocaleText(
                          "cluster.master.election.inprogress.message",
                        )}
                      >
                        <span>
                          <Icons
                            style={{
                              fontSize: 15,
                              marginLeft: 5,
                              color: "orange",
                            }}
                            className=""
                            type="fa"
                            name="FaRegClock"
                          />
                        </span>
                      </Popover>
                    )}
                  </div>
                ) : (
                  master_count == 0 &&
                  is_candidate &&
                  nodeState === "ALIVE" && (
                    <div className="d-inline-flex">
                      <Popover
                        key={
                          master_count == 0 &&
                          is_candidate &&
                          nodeState === "ALIVE"
                        }
                        overlayStyle={{ maxWidth: "20%", float: "right" }}
                        placement="right"
                        content={getCurrentlocaleText(
                          "cluster.master.election.inprogress.message",
                        )}
                      >
                        <span>
                          <Icons
                            style={{
                              fontSize: 15,
                              marginLeft: 5,
                              color: "orange",
                            }}
                            className=""
                            type="fa"
                            name="FaRegClock"
                          />
                        </span>
                      </Popover>
                    </div>
                  )
                )}
              </Col>
            </Row>
            <Row></Row>
          </span>
        );
      });
    }
  };

  getNetworkTags = data => {
    if (data.remote_network.length > 0) {
      return data.remote_network
        .filter(network_list => (network_list ? true : false))
        .map((network_list, index) => {
          return network_list.map(network => {
            if (
              network.node &&
              network.node.id &&
              network.network &&
              network.network.id
            )
              return (
                <span key={network.network.id}>
                  {network.representation_network &&
                  network.representation_network.peer ? (
                    <Popover
                      content={
                        <span>
                          {getCurrentlocaleText(
                            "network.form.representational_network.text",
                          ) +
                            ": " +
                            network.representation_network.peer}
                        </span>
                      }
                      trigger="hover"
                    >
                      <Tag className="rep-connected-network">
                        {data.current_network.length > 0 && (
                          <span>
                            <Icons
                              type="fa"
                              name="FaConnectdevelop"
                              className="valign-text-bottom iot-tag-icon-style"
                            />
                            {" " + data.current_network &&
                              data.current_network[index] &&
                              data.current_network[index].name}
                            &nbsp;{" \u27F7 "}&nbsp;
                          </span>
                        )}
                        <Icons
                          type="fa"
                          name="FaRegHdd"
                          className="valign-text-bottom iot-tag-icon-style"
                        />
                        {" " + network.node.name + "/"}
                        <Icons
                          type="fa"
                          name="FaConnectdevelop"
                          className="valign-text-bottom iot-tag-icon-style"
                        />
                        {" " + network.network.name}&nbsp;
                      </Tag>
                    </Popover>
                  ) : (
                    <Tag>
                      {data.current_network.length > 0 && (
                        <span>
                          <Icons
                            type="fa"
                            name="FaConnectdevelop"
                            className="valign-text-bottom iot-tag-icon-style"
                          />
                          {" " + data.current_network &&
                            data.current_network[index] &&
                            data.current_network[index].name}
                          &nbsp;{" \u27F7 "}&nbsp;
                        </span>
                      )}
                      <Icons
                        type="fa"
                        name="FaRegHdd"
                        className="valign-text-bottom iot-tag-icon-style"
                      />
                      {" " + network.node.name + "/"}
                      <Icons
                        type="fa"
                        name="FaConnectdevelop"
                        className="valign-text-bottom iot-tag-icon-style"
                      />
                      {" " + network.network.name}&nbsp;
                    </Tag>
                  )}
                </span>
              );
          });
        });
    }
  };

  getModalContentJSX = record => {
    this.setState({
      modalTitle: record && record.name ? record.name : "",
      modalContent: this.getMoreInfo(record),
    });
  };

  getMoreInfo(record) {
    return (
      <div key={record.id}>
        <Row>
          <Col span={8}>
            <strong>
              {getCurrentlocaleText("orgs.details.tabs.inodes.text")}
            </strong>
          </Col>
          <Col span={1}>
            <strong>{":"}</strong>
          </Col>
          <Col span={15}>
            {record.inodes && record.inodes.length > 0
              ? this.getTags(record)
              : "-"}
          </Col>
        </Row>
        &nbsp;
        <Row>
          <Col span={8}>
            <strong>
              {getCurrentlocaleText(
                "network.form.remotenetwork.selectbox.helptext.title",
              )}
            </strong>
          </Col>
          <Col span={1}>
            <strong>{":"}</strong>
          </Col>
          <Col span={15}>
            {record.remote_network && record.remote_network.length > 0
              ? this.getNetworkTags(record)
              : "-"}
          </Col>
        </Row>
      </div>
    );
  }

  getCurrentOrgClusterLength = () => {
    let clusterData = this.clusterModel.clusterListData;
    // Find org id
    let orgId =
      this.props.params && this.props.params.org_id
        ? this.props.params.org_id
        : this.props.match &&
          this.props.match.params &&
          this.props.match.params.id
        ? this.props.match.params.id
        : null;

    let currentOrgsCluster = clusterData.filter(cluster => {
      if (cluster.organization.id === orgId) return cluster;
    });
    return currentOrgsCluster.length;
  };

  handleModalOk(e) {
    this.setState({
      isTableModalToggle: !this.state.isTableModalToggle,
    });
  }

  render() {
    const { isTableModalToggle } = this.state;
    let { filterInfo, sorterInfo } = this.state;
    filterInfo = filterInfo || {};
    sorterInfo = sorterInfo || {};

    let cluster_list_data = this.clusterModel.clusterListData;

    this.columns = [
      {
        title: (
          <span
            title={getCurrentlocaleText("cluster.list.cluster.name").replace(
              ":",
              "",
            )}
          >
            {getCurrentlocaleText("cluster.list.cluster.name").replace(":", "")}
          </span>
        ),
        dataIndex: "name",
        key: "name",
        width: "22%",
        ellipsis: true,
        sorter: true,
        sortOrder:
          sorterInfo.sorter.columnKey === "name" && sorterInfo.sorter.order,
        render: (text, record, index) => {
          let clusterLink = `/orgs/${record.organization.id}/clusters/${record.id}`;
          return (
            <div className="col-wrap">
              <Link
                to={{
                  pathname: clusterLink,
                  state: {
                    cluster_name: text,
                  },
                }}
              >
                {this.state.searchText.trim().length > 0
                  ? highlightSearchText(text, this.state.searchText)
                  : text}
              </Link>
            </div>
          );
        },
      },
      {
        title: (
          <span title={getCurrentlocaleText("myprofile.organization.text")}>
            {getCurrentlocaleText("myprofile.organization.text")}
          </span>
        ),
        dataIndex: "org_name",
        key: "org_name",
        width: "20%",
        ellipsis: true,
        render: (text, record, index) => {
          let inodeLink = `/orgs/${record.organization.id}`;
          return (
            <div className="col-wrap">
              <Link to={inodeLink}>{record.organization.name}</Link>
            </div>
          );
        },
      },
      {
        title: (
          <span
            title={getCurrentlocaleText("clusters.inodes.table.column.text")}
          >
            {getCurrentlocaleText("clusters.inodes.table.column.text")}
          </span>
        ),
        dataIndex: "inodes",
        key: "inodes",
        render: (text, record, index) => {
          return (
            <div style={{ marginLeft: 15 }}>
              {record.inodes ? `${record.inodes.length}` : <p>{`-`}</p>}
            </div>
          );
        },
      },
      {
        title: (
          <span
            title={getCurrentlocaleText(
              "network.form.remotenetwork.selectbox.helptext.title",
            )}
          >
            {getCurrentlocaleText(
              "network.form.remotenetwork.selectbox.helptext.title",
            )}
          </span>
        ),
        dataIndex: "remote_networks",
        key: "remote_networks",
        render: (text, record, index) => {
          return (
            <div style={{ marginLeft: 15 }}>
              {record.remote_network_count &&
              record.remote_network_count > 0 ? (
                `${record.remote_network_count}`
              ) : (
                <p>{`-`}</p>
              )}
            </div>
          );
        },
      },
      {
        title: (
          <span
            title={getCurrentlocaleText(
              "node.list.table.node_services.label.text",
            )}
          >
            {getCurrentlocaleText("node.list.table.node_services.label.text")}
          </span>
        ),
        dataIndex: "services",
        key: "services",
        render: (text, record, index) => {
          return (
            <div style={{ marginLeft: 15 }}>
              {record.service_count > 0 ? (
                `${record.service_count}`
              ) : (
                <p>{`-`}</p>
              )}
            </div>
          );
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "Actions",
        width: "10%",
        render: (text, record, index) => {
          const view = (
            <span>
              <ActionRoute
                actionIcon="AiOutlineEye"
                actionLabel={getCurrentlocaleText("view.cluster.text")}
                iconButton
                title={getCurrentlocaleText("view.cluster.text")}
                route={{
                  pathname:
                    "/orgs/" +
                    record.organization.id +
                    "/clusters/view/" +
                    record.id,
                  state: {
                    formValues: record.values,
                    viewOnly: true,
                  },
                }}
                iconBtnStyle={{ fontSize: 14 }}
              />
            </span>
          );
          const edit = (
            <span>
              <ActionRoute
                actionIcon="AiOutlineEdit"
                actionLabel={getCurrentlocaleText(
                  "cluster.form.useraction_edit.label.text",
                )}
                iconButton
                title={getCurrentlocaleText(
                  "cluster.form.useraction_edit.label.text",
                )}
                route={{
                  pathname:
                    "/orgs/" +
                    record.organization.id +
                    "/clusters/edit/" +
                    record.id,
                  state: {
                    formValues: record.values,
                  },
                }}
                disabled={!this.props.AuthStore.IsPermitted("CLUSTER:CREATE")}
              />
            </span>
          );
          const del = (
            <span>
              <ActionBtn
                title={
                  <span>
                    <Icons type="ai" name="AiOutlineDelete" />{" "}
                    {getCurrentlocaleText(
                      "single.cluster.list.table.multidelete_cluster.button.hover.text",
                    )}
                  </span>
                }
                HoverText={
                  <span>
                    {getCurrentlocaleText(
                      "single.cluster.list.table.multidelete_cluster.button.title.text",
                    )}
                  </span>
                }
                orgId={this.props.orgId}
                icon="AiOutlineDelete"
                formData={record}
                iconButton
                map={{ id: record.id }}
                action="delete"
                disabled={!this.props.AuthStore.IsPermitted("CLUSTER:DELETE")}
                controller={ClusterController}
                afterCall={this.deleteAfterCall.bind()}
                cancelText={getCurrentlocaleText(
                  "cluster.delete.confirmation.cancel.text",
                )}
                messageContent={getCurrentlocaleText(
                  "cluster.list.table.single.message.text",
                )}
                warningMessage={getCurrentlocaleText(
                  "single.cluster.delete.warning.message",
                )}
                okText={
                  <span>
                    <Icons
                      type="ai"
                      name="AiOutlineDelete"
                      style={{ margin: 0 }}
                      className="iot-delete-icon"
                    />
                    {getCurrentlocaleText("inode.delete.confirmation.ok.text", {
                      0: "Cluster",
                    })}
                  </span>
                }
              ></ActionBtn>
            </span>
          );

          const menu = (
            <Menu>
              <Menu.Item key={0}>{view}</Menu.Item>
              <Menu.Item key={1}>{edit}</Menu.Item>
              <Menu.Item key={2}>{del}</Menu.Item>
            </Menu>
          );
          return (
            <div
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <DropdownActionBtn menu={menu} />
            </div>
          );
        },
      },
    ];

    if (!this.props.UiStore.showChildClusters) {
      this.columns.splice(1, 1);
    }

    let container = (
      <Row>
        <Col xs={24} sm={12} md={18} lg={18} xl={24}>
          <div style={{ minHeight: 250 }}>
            <div className="page-header mb-10">
              <div style={{ display: "inline-flex", width: "40%" }}>
                <span className="headertext">
                  {getCurrentlocaleText("clusters.text")}
                </span>
              </div>
              <div
                style={{
                  marginBottom: "10px",
                  display: "inline-flex",
                  justifyContent: "flex-end",
                  width: "60%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 7,
                  }}
                >
                  <Checkbox
                    checked={this.props.UiStore.showChildClusters}
                    onChange={this.changeListview}
                    disabled={
                      this.props.OrgViewModel.org &&
                      this.props.OrgViewModel.org.org_count > 0
                        ? false
                        : true
                    }
                  >
                    {getCurrentlocaleText(
                      "cluster.list.table_view.checkbox.label",
                    )}
                  </Checkbox>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <SearchInput
                    placeholder={getCurrentlocaleText(
                      "cluster.list.table.filter_all.placeholder.text",
                    )}
                    onChange={this.clusterListFilter}
                    value={this.state.searchText}
                    clearSearch={this.clearSearchFilter}
                  />
                </div>

                <div
                  style={{
                    marginLeft: "20px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {this.props.AuthStore.IsPermitted("CLUSTER:CREATE") && (
                    <ActionRoute
                      primaryButton
                      actionIcon="AiOutlinePlus"
                      title={
                        this.getCurrentOrgClusterLength() &&
                        this.getCurrentOrgClusterLength() >=
                          getOrgPolicyCounInfo(
                            this.orgModel.org,
                            "cluster_count",
                          )
                          ? getCurrentlocaleText(
                              "cluster.maximum_limit.organization.warning.text",
                              {
                                0: getOrgPolicyCounInfo(
                                  this.orgModel.org,
                                  "cluster_count",
                                ),
                              },
                            )
                          : getCurrentlocaleText(
                              "cluster.list.create.addbutton.text",
                            )
                      }
                      route={
                        (!this.props.isFromDashboard
                          ? this.getCurrentRoute()
                          : "/orgs/" +
                            this.props.match.params.id +
                            "/clusters") + "/create"
                      }
                      disabled={
                        this.getCurrentOrgClusterLength() &&
                        this.getCurrentOrgClusterLength() >=
                          getOrgPolicyCounInfo(
                            this.orgModel.org,
                            "cluster_count",
                          )
                          ? true
                          : false
                      }
                    />
                  )}
                  {this.props.AuthStore.IsPermitted("CLUSTER:DELETE") &&
                    !this.props.isFromDashboard && (
                      <ActionBtn
                        title={
                          <span>
                            <Icons type="ai" name="AiOutlineDelete" />{" "}
                            {getCurrentlocaleText(
                              this.state.deletableClusters.clusters.length > 1
                                ? "multiple.cluster.list.table.multidelete_cluster.button.hover.text"
                                : "single.cluster.list.table.multidelete_cluster.button.hover.text",
                            )}
                          </span>
                        }
                        HoverText={
                          <span>
                            {getCurrentlocaleText(
                              this.state.deletableClusters.clusters.length > 1
                                ? "multiple.cluster.list.table.multidelete_cluster.button.title.text"
                                : "single.cluster.list.table.multidelete_cluster.button.title.text",
                            )}
                          </span>
                        }
                        resource="cluster"
                        icon="AiOutlineDelete"
                        formData={this.state.deletableClusters.clusters}
                        iconButton
                        action="delete"
                        disabled={
                          this.state.deletableClusters.clusters.length === 0
                        }
                        afterCall={this.deleteAfterCall}
                        controller={ClusterController}
                        cancelText={getCurrentlocaleText(
                          this.state.deletableClusters.clusters.length > 1
                            ? "cluster.multiple.delete.confirmation.cancel.text"
                            : "cluster.delete.confirmation.cancel.text",
                        )}
                        messageContent={getCurrentlocaleText(
                          this.state.deletableClusters.clusters.length > 1
                            ? "cluster.list.table.multidelete_cluster.message.text"
                            : "cluster.list.table.single.message.text",
                        )}
                        warningMessage={getCurrentlocaleText(
                          this.state.deletableClusters.clusters.length > 1
                            ? "multiple.cluster.delete.warning.message"
                            : "single.cluster.delete.warning.message",
                        )}
                        okText={
                          <span>
                            <Icons
                              type="ai"
                              name="AiOutlineDelete"
                              style={{ margin: 0 }}
                              className="iot-delete-icon"
                            />
                            {getCurrentlocaleText(
                              "inode.delete.confirmation.ok.text",
                              {
                                0:
                                  this.state.deletableClusters.clusters.length >
                                  1
                                    ? "Clusters"
                                    : "Cluster",
                              },
                            )}
                          </span>
                        }
                      ></ActionBtn>
                    )}
                </div>
              </div>
            </div>
            {cluster_list_data.length > 0 ? (
              <div>
                <TableLayoutV1
                  columns={this.columns}
                  dataSource={cluster_list_data}
                  pagination={{
                    pageSize:
                      this.clusterModel && this.clusterModel.pageable.size,
                    total:
                      this.clusterModel &&
                      this.clusterModel.pageable.total_count,
                    current: this.state.currentPage,
                  }}
                  selectedRows={this.handleTableChange}
                  selectAll={this.onSelectAllChange}
                  onChange={this.onTableChange}
                  hideRowSelection={this.props.isFromDashboard}
                  expandedRowKeys={this.state.expandedRowKeys}
                  onExpand={this.onExpand}
                  getModalContent={record => {
                    return this.getModalContentJSX(record);
                  }}
                  handleModalOk={e => this.handleModalOk(e)}
                  modalTitle={this.state.modalTitle}
                  modalContent={this.state.modalContent}
                  isTableModalToggle={isTableModalToggle}
                  isModalLoading={false}
                  disableFooter={true}
                  className="clusterList"
                />
              </div>
            ) : (
              <div>
                {!this.clusterModel.loading && (
                  <h3>
                    {getCurrentlocaleText("clusters.nocluster_to_display.text")}
                  </h3>
                )}
              </div>
            )}
          </div>
        </Col>
      </Row>
    );
    return (
      <LoadingComponent loading={this.clusterModel.loading}>
        {container}
      </LoadingComponent>
    );
  }
}

ClusterList.propTypes = {};

export default ClusterList;
