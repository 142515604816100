import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import { observer, inject } from "mobx-react";
import { focusFirstInput, passwordValidator } from "Core/Utils";
import PasswordInput from "components/UI-Components/PasswordInput";
import Icons from "../UI-Components/Icons";

const FormItem = Form.Item;

@inject("UiStore")
@observer
class ChangePasswordFm extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    /* Focus First Input */
    focusFirstInput();
    if (this.props.showHeader) {
      this.currentDocTitle = this.props.UiStore.currentDocTitle;
      this.props.UiStore.setDocumentTitle(`Change Password`);
    }
  }

  checkPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue("confirm_password")) {
      callback("Password does not match the New password");
    } else {
      callback();
    }
  };

  validatePassword = (rule, value, callback) => {
    let validation = passwordValidator(value);
    if (validation) {
      return callback(validation);
    }
    // Check confirm password
    let new_password = this.props.form.getFieldValue("new_password");
    if (new_password) {
      this.checkConfirmNewPassword(new_password);
    }
    return callback();
  };

  checkConfirmNewPassword = new_password => {
    let obj = {};
    let confirm_password = this.props.form.getFieldValue("confirm_password");
    obj["new_password"] = { value: `${new_password}` };
    if (new_password !== confirm_password) {
      obj["new_password"].errors = [
        new Error("Password does not match the New password"),
      ];
    }
    this.props.form.setFields(obj);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form noValidate onSubmit={this.props.onSubmit}>
        <FormItem label={this.props.oldPasswordLabel}>
          {getFieldDecorator("old_password", {
            rules: [
              {
                required: true,
                message: this.props.errorMessage,
                whitespace: true,
              },
            ],
          })(
            <PasswordInput
              className="firstInput"
              prefix={
                <Icons
                  type="ai"
                  name="AiOutlineLock"
                  style={{ fontSize: 13 }}
                />
              }
              type="password"
              placeholder={this.props.oldPasswordLabel}
              onChange={e => {}}
            />,
          )}
        </FormItem>
        <FormItem label={this.props.newPasswordLabel}>
          {/*TODO - TS Create Dynamic Password rules form api Response for custom rules*/}
          {getFieldDecorator("confirm_password", {
            rules: [
              { required: true, message: "Please confirm your password" },
              { validator: this.validatePassword },
            ],
          })(
            <PasswordInput
              prefix={
                <Icons
                  type="ai"
                  name="AiOutlineLock"
                  style={{ fontSize: 13 }}
                />
              }
              type="password"
              placeholder="New Password"
              onChange={e => {}}
            />,
          )}
        </FormItem>
        <FormItem label={this.props.confirmPasswordLabel}>
          {getFieldDecorator("new_password", {
            rules: [
              { required: true, message: "Please confirm your password" },
              { validator: this.checkPassword },
            ],
          })(
            <PasswordInput
              prefix={
                <Icons
                  type="ai"
                  name="AiOutlineLock"
                  style={{ fontSize: 13 }}
                />
              }
              type="password"
              placeholder="Confirm Password"
              onChange={e => {}}
            />,
          )}
        </FormItem>
      </Form>
    );
  }
}

const ChangePasswordForm = Form.create()(ChangePasswordFm);
export default ChangePasswordForm;
