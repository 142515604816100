import React, { Component } from "react";
import { Row, Col } from "antd";
import { observer, inject } from "mobx-react";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import BreadcrumbComponent from "components/UI-Components/BreadcrumbComponent";
import Icons from "components/UI-Components/Icons";
import OrgSelector from "components/Orgs/OrgSelector";
import OrgController from "controller/OrgController";
import ClusterController from "controller/ClusterController";
import ClusterList from "components/Clusters/ClusterList";
import ClusterForm from "components/Clusters/ClusterForm";
import { getCurrentlocaleText } from "Core/Utils";

@inject("AuthStore", "UiStore", "ClusterViewModel")
@observer
class Clusters extends Component {
  constructor(props) {
    super(props);
    this.clusterModel = this.props.ClusterViewModel;
    this.state = {
      currentOrgDetails: {},
      BreadcrumbList: [],
      disableReboot: true,
    };
    this.color = {};
  }

  componentDidMount() {
    this.updateInfo(this.props);
  }

  UNSAFE_componentWillUpdate(newProps) {
    if (this.props.match.params.id !== newProps.match.params.id) {
      this.updateInfo(newProps);
    }
  }

  updateInfo = props => {
    OrgController.getOrg(
      props.match.params.id,
      false,
      props.UiStore.parentOrgData && props.UiStore.parentOrgData.id
        ? false
        : true,
    ).then(res => {
      let BreadcrumbList = [];
      BreadcrumbList.push(
        {
          name: res.name,
          link: `orgs/${res.id}/orgs`,
        },
        {
          name: getCurrentlocaleText("clusters.navigation_menu.text"),
          link: `orgs/${res.id}/clusters`,
        },
      );
      this.setState({ BreadcrumbList: BreadcrumbList, currentOrgDetails: res });
    });
  };

  createCluster = values => {
    values.create_under = this.props.match.params.id;
    ClusterController.create(values, {})
      .then(res => {
        this.navigateBack();
      })
      .catch(err => {});
  };

  updateCluster = (values, id) => {
    ClusterController.update(values, { id: id }, {})
      .then(res => {
        this.navigateBack();
      })
      .catch(err => {});
  };

  navigateBack = () => {
    // Navigate back to cluster listing page by default
    let link = window.location.pathname.replace("/create", "");
    link = link.split("/").slice(0, 4);
    link = link.join("/");
    // Navigate back to the cluster details page
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.isFromClusterDetails &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.cluster_id
    ) {
      link = link.concat(`/${this.props.match.params.cluster_id}`);
    }
    this.props.history.push(link);
  };

  render() {
    let currentOrgDetails = this.state.currentOrgDetails;
    let container = (
      <div>
        {!this.props.isFromDashboard && (
          <Row gutter={16} type="flex" align="middle">
            <Col xs={8} sm={8} md={12} lg={10} xl={10}>
              <h2 className="page-title">
                <Icons type="fa" name="FaSitemap" />
                {"  "}
                {currentOrgDetails.name ? currentOrgDetails.name : null}
              </h2>
              <BreadcrumbComponent
                {...this.props}
                BreadcrumbList={this.state.BreadcrumbList}
              />
            </Col>
            <Col
              xs={16}
              sm={16}
              md={12}
              lg={14}
              xl={14}
              className="flex flex-h-right"
            >
              <Row gutter={16} type="flex" align="middle">
                <Col span={24} className="flex flex-h-right">
                  <OrgSelector
                    {...this.props}
                    className="org-selector-menu-padding"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        <div style={{ background: "#fff", minHeight: 360 }}>
          {this.props.match &&
          this.props.match.path &&
          (this.props.match.path.includes("create") ||
            this.props.match.path.includes("edit") ||
            this.props.match.path.includes("view")) ? (
            <ClusterForm
              {...this.props}
              onCancel={this.navigateBack}
              createCluster={this.createCluster}
              updateCluster={this.updateCluster}
            />
          ) : (
            <ClusterList {...this.props} />
          )}
        </div>
      </div>
    );

    return (
      <LoadingComponent loading={this.clusterModel.loading}>
        {container}
      </LoadingComponent>
    );
  }
}

export default Clusters;
