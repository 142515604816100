import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import Input from "components/UI-Components/InputBox";
import propTypes from "prop-types";
import { randomAlphaNumericString } from "Core/Utils";
import ServiceTemplatesUtils from "components/serviceTemplates/Utils/ServiceTemplatesUtils";
import { getCurrentlocaleText, copyObject } from "Core/Utils";
import { inject } from "mobx-react";
import Twig from "twig";

/*Define form elements*/
const FormItem = Form.Item;

@inject("UiStore")
export default class PasswordBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmPassword: false,
      new_password: "",
      confirm_password: "",
    };
    this.validationMessage =
      this.props.properties.constraints &&
      this.props.properties.constraints.validationMessage
        ? this.props.properties.constraints.validationMessage
        : `${this.props.properties.label} is required`;
  }

  inputChange = e => {};

  validateInput = (rule, value, callback, field) => {
    if (
      this.props.properties.constraints &&
      this.props.properties.constraints.regex
    ) {
      let result = ServiceTemplatesUtils.validateUserInput(
        this.props.properties.constraints.regex,
        value,
      );

      let failMessage = null;
      if (!result) {
        failMessage = this.props.properties.constraints.validationMessage
          ? this.props.properties.constraints.validationMessage
          : `Input should evaluate to this regex - ${this.props.properties.constraints.regex}`;
      }

      if (failMessage) {
        callback(failMessage);
      }
    }
    this.setState({
      new_password: value,
      showConfirmPassword: true,
    });
    callback();
  };

  get componentVisibility() {
    let visibility = null;
    if (
      this.props.UiStore.computedOutputObject &&
      this.props.UiStore.computedOutputObject.steps
    ) {
      if (this.props.properties.visibility) {
        var template = Twig.twig({
          data: this.props.properties.visibility,
        });
        let computedOutputObject = copyObject(
          this.props.UiStore.computedOutputObject,
        );
        visibility = template.render(computedOutputObject);
        try {
          visibility = JSON.parse(visibility);
        } catch {}
      }
    }
    return visibility ? true : false;
  }

  checkConfirmPassword = (rule, value, callback) => {
    if (
      this.props.properties.constraints &&
      this.props.properties.constraints.regex
    ) {
      let result = ServiceTemplatesUtils.validateUserInput(
        this.props.properties.constraints.regex,
        value,
      );

      let failMessage = null;
      if (!result) {
        failMessage = this.props.properties.constraints.validationMessage
          ? this.props.properties.constraints.validationMessage
          : `Input should evaluate to this regex - ${this.props.properties.constraints.regex}`;
      }
      if (failMessage) {
        callback(failMessage);
      }
    }
    if (value && value !== this.state.new_password) {
      callback(
        getCurrentlocaleText("user.reset_password_form.confirm_password_error"),
      );
    } else {
      this.setState({
        confirm_password: value,
      });
      let new_password = this.state.new_password;
      if (new_password) {
        this.checkConfirmNewPassword(new_password);
      }
      callback();
    }
  };

  checkConfirmNewPassword = new_password => {
    let obj = {};
    let confirm_password = this.state.new_password;
    obj["confirm_password"] = { value: `${new_password}` };
    if (new_password !== confirm_password) {
      obj["confirm_password"].errors = [
        new Error(
          getCurrentlocaleText(
            "user.reset_password_form.confirm_password_error",
          ),
        ),
      ];
    }
    this.props.updateCurrentValueOnChange(new_password, "value");
  };

  componentDidMount() {
    let newpassword =
      this.props.properties.defaultValue &&
      this.props.properties.defaultValue.value
        ? this.props.properties.defaultValue.value
        : typeof this.props.properties.defaultValue === "string" &&
          this.props.properties.defaultValue
        ? this.props.properties.defaultValue
        : "";
    this.setState({
      new_password: newpassword,
    });
    this.props.updateCurrentValueOnChange(newpassword, "value");
  }

  render() {
    const { getFieldDecorator } = this.props.referenceForm; // reference of base form
    let required =
      this.props.properties.constraints &&
      this.props.properties.constraints.required
        ? this.props.properties.constraints.required
        : false;

    let id = this.props.id
      ? this.props.id
      : `textbox${randomAlphaNumericString(3)}`;

    let disable = false;
    if (this.props.editmode) {
      disable = this.props.properties.noneditable
        ? this.props.properties.noneditable
        : false;
    }
    disable = this.props.properties.disabled
      ? this.props.properties.disabled
      : disable;

    // For viewOnly
    disable = this.props.viewOnly === true ? true : disable;

    let visibility = this.props.properties.visibility
      ? this.componentVisibility
      : true;

    return visibility ? (
      <div
        onClick={
          this.props.onFocus &&
          this.props.onFocus.bind(null, this.props.properties.help)
        }
      >
        <FormItem label={this.props.properties.label} hasFeedback>
          {getFieldDecorator("new_password", {
            rules: [
              {
                required: required,
                message: this.validationMessage,
              },
              {
                validator: this.validateInput,
              },
            ],
            initialValue: this.state.new_password,
          })(
            <Input
              placeholder={this.props.properties.placeholder}
              type="password"
              autoComplete="off"
              disabled={disable}
              onChange={e => {
                this.props.properties.showConfirmPassword === false &&
                this.props.properties.inputChange
                  ? this.props.properties.inputChange(e.target.value)
                  : this.inputChange;
                this.props.updateCurrentValueOnChange(e.target.value, "value");
              }}
              onFocus={
                this.props.onFocus &&
                this.props.onFocus.bind(null, this.props.properties.help)
              }
            />,
          )}
        </FormItem>
        {this.props.properties.showConfirmPassword &&
          (this.state.showConfirmPassword ||
            !this.props.properties.defaultValue ||
            !this.props.properties.defaultValue.value) && (
            <FormItem
              label={getCurrentlocaleText(
                "user.reset_password_form.confirm_password_placeholder",
              )}
              hasFeedback
            >
              {getFieldDecorator("confirm_password", {
                rules: [
                  {
                    required: required,
                    message: this.validationMessage,
                  },
                  {
                    validator: this.checkConfirmPassword,
                  },
                ],
              })(
                <Input
                  placeholder={getCurrentlocaleText(
                    "user.reset_password_form.confirm_password_placeholder",
                  )}
                  type="password"
                  autoComplete="off"
                  disabled={disable}
                  onChange={e => {}}
                  onFocus={
                    this.props.onFocus &&
                    this.props.onFocus.bind(null, this.props.properties.help)
                  }
                />,
              )}
            </FormItem>
          )}
      </div>
    ) : (
      <div></div>
    );
  }
}

PasswordBox.propTypes = {
  referenceForm: propTypes.any,
  id: propTypes.string,
  properties: propTypes.shape({
    label: propTypes.string.isRequired,
    disabled: propTypes.bool,
    placeholder: propTypes.string,
    inputChange: propTypes.func,
    defaultValue: propTypes.string,
    constraints: propTypes.shape({
      validationMessage: propTypes.string,
      required: propTypes.bool,
    }),
  }),
};

/* sample schema

{
  "type": "PasswordBox",
  "visible": true,
  "id" : "p12",
  "props": {
    "label": "Service name",
    "defaultValue": "qbx",
    "help" : "<div>help for password</div>",
    "placeholder": "Enter Service name",
    "disabled" : false,
    "noneditable" : true,
    "constraints" : {
      "validationMessage" : "validate me",
      "required" : true,
      "regex" : "^[a-z0-9A-Z]{1,30}$"

    }
  }
}

*/
