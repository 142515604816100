import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import { Input } from "antd";
import propTypes from "prop-types";
import { getCurrentlocaleText, copyObject } from "Core/Utils";
import { inject } from "mobx-react";
import { computed } from "mobx";
import Twig from "twig";

/*Define form elements*/
const FormItem = Form.Item;
const { TextArea } = Input;

@inject("UiStore")
export default class JsonInputBox extends Component {
  constructor(props) {
    super(props);
    this.validationMessage =
      this.props.properties.constraints &&
      this.props.properties.constraints.validationMessage
        ? this.props.properties.constraints.validationMessage
        : `${this.props.properties.label} is required`;
  }

  inputChange = input => {
    this.props.updateCurrentValueOnChange(input.target.value, "value");
  };

  validateInput = (rule, value, callback, field) => {
    let failMessage = null;
    try {
      JSON.parse(value);
    } catch (e) {
      failMessage = this.validationMessage;
    }
    if (failMessage) {
      callback(failMessage);
    }
    callback();
  };

  componentDidMount() {
    if (this.props.updateCurrentValueOnChange) {
      this.props.updateCurrentValueOnChange(
        this.props.properties.defaultValue &&
          this.props.properties.defaultValue.value
          ? this.props.properties.defaultValue.value
          : typeof this.props.properties.defaultValue === "string" &&
            this.props.properties.defaultValue
          ? this.props.properties.defaultValue
          : "",
        "value",
      );
    }
  }

  @computed
  get componentVisibility() {
    let visibility = null;
    if (
      this.props.UiStore.computedOutputObject &&
      this.props.UiStore.computedOutputObject.steps
    ) {
      if (this.props.properties.visibility) {
        var template = Twig.twig({
          data: this.props.properties.visibility,
        });
        let computedOutputObject = copyObject(
          this.props.UiStore.computedOutputObject,
        );
        visibility = template.render(computedOutputObject);
        try {
          visibility = JSON.parse(visibility);
        } catch {}
      }
    }
    return visibility ? true : false;
  }

  render() {
    let errMsg = getCurrentlocaleText("custom.service.spec.error");
    const { getFieldDecorator } = this.props.referenceForm; // reference of base form
    let required =
      this.props.properties.constraints &&
      this.props.properties.constraints.required
        ? this.props.properties.constraints.required
        : false;

    let id = this.props.id
      ? this.props.id
      : `jsoninputbox${randomAlphaNumericString(3)}`;

    let maxlength =
      this.props.properties.options && this.props.properties.options.maxlength
        ? this.props.properties.options.maxlength
        : 10000;

    let visibility = this.props.properties.visibility
      ? this.componentVisibility
      : true;

    return visibility ? (
      <div
        onClick={
          this.props.onFocus &&
          this.props.onFocus.bind(null, this.props.properties.help)
        }
      >
        <FormItem label={this.props.properties.label}>
          {getFieldDecorator(id, {
            rules: [
              {
                required: required,
                message: errMsg, //`Service spec is mandatory`,
              },
              {
                validator: this.validateInput,
              },
            ],
            initialValue:
              this.props.properties.defaultValue &&
              this.props.properties.defaultValue.value
                ? JSON.stringify(this.props.properties.defaultValue.value)
                : typeof this.props.properties.defaultValue === "string" &&
                  this.props.properties.defaultValue
                ? this.props.properties.defaultValue
                : "",
          })(
            <TextArea
              autoSize={{ minRows: "13", maxRows: "13" }}
              onChange={
                this.props.properties.onChange
                  ? this.props.properties.onChange
                  : this.inputChange
              }
              placeholder={this.props.properties.placeholder}
              disabled={
                this.props.viewOnly === true
                  ? true
                  : this.props.properties.disabled
              }
              onFocus={
                this.props.onFocus &&
                this.props.onFocus.bind(null, this.props.properties.help)
              }
              rows={
                this.props.properties.options &&
                this.props.properties.options.minRows
                  ? this.props.properties.options.minRows
                  : 4
              }
              cols={
                this.props.properties.options &&
                this.props.properties.options.maxRows
                  ? this.props.properties.options.maxRows
                  : 50
              }
            />,
          )}
        </FormItem>
      </div>
    ) : (
      <div></div>
    );
  }
}

JsonInputBox.propTypes = {
  referenceForm: propTypes.any,
  id: propTypes.string,
  properties: propTypes.shape({
    disabled: propTypes.bool,
    placeholder: propTypes.string,
    onChange: propTypes.func,
    defaultValue: propTypes.string,
    constraints: propTypes.shape({
      validationMessage: propTypes.string,
      required: propTypes.bool,
    }),
    options: propTypes.shape({
      minRows: propTypes.number,
    }),
  }),
};

/* sample schema

{
  "type": "JsonInputBox",
  "visible": true,
  "props": {
    "label": "Service spec",
    "defaultValue": "",
    "help" : "<div>help for JsonInputBox</div>",
    "placeholder": "Enter Service name",
    "disabled" : false,
    "options" : {
      "minRows" : 4,
      "maxRows" : 4
    },
    "constraints" : {
      "validationMessage" : "validate me",
      "required" : true

    }
  }
}

*/
