import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Tree, Tag } from "antd";
import { customSort, getCurrentlocaleText } from "Core/Utils";
import ModalLoadingComponent from "components/UI-Components/ModalLoadingComponent";

import OrgController from "controller/OrgController";

const TreeNode = Tree.TreeNode;
@inject("UiStore", "OrgViewModel")
@observer
class OrgInOrgView extends Component {
  constructor(props) {
    super(props);
    this.uistore = this.props.UiStore;
    this.orgModel = this.props.OrgViewModel;
    this.state = {
      ModalVisiblity: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.orgModel.resetOrgHierarchy();
  }

  componentDidMount() {
    this.uistore.modalLoading = true;
    OrgController.getOrgHierarchy(this.props.orgId)
      .then(resp => {
        this.uistore.modalLoading = false;
      })
      .catch(error => {
        this.uistore.modalLoading = false;
      });
  }

  //Modal close
  closeModal = () => {
    this.uistore.SetStoreData("OrgInorgView", false);
  };

  // Child view tree list generation.
  renderList(data) {
    if (data && data.length > 0) {
      return data.map((org, i) => {
        return (
          <TreeNode
            id={org.id}
            title={<Tag className={"tag-iotium-info"}>{org.name}</Tag>}
            key={org.id}
          >
            {this.renderList(this.getChildOrg(org.id))}
          </TreeNode>
        );
      });
    }
  }

  // Get child orgs for given org id.
  getChildOrg(id) {
    var OrgsArray = [];
    if (this.orgModel.orgHierarchy.length > 0) {
      this.orgModel.orgHierarchy.filter(val => {
        if (val.parent_id === id) {
          OrgsArray.push(val);
        }
      });
    }
    return OrgsArray.sort((a, b) => {
      return customSort(a.name, b.name);
    });
  }

  render() {
    let data = this.orgModel.orgHierarchy;
    let parent = data.find(org => {
      return org.id === this.props.orgId;
    });
    let childs = data
      .filter(org => {
        return org.parent_id === this.props.orgId;
      })
      .sort((a, b) => {
        return customSort(a.name, b.name);
      });
    let container =
      data.length > 0 ? (
        <div>
          <Tree defaultExpandAll={true} showIcon={false} showLine={true}>
            <TreeNode
              title={
                <div>
                  <Tag className="tag-iotium-success">
                    {parent ? parent.name : null}
                  </Tag>
                </div>
              }
              key={parent ? parent.id : null}
            >
              {this.renderList(childs)}
            </TreeNode>
          </Tree>
        </div>
      ) : (
        <div style={{ minHeight: 60 }}>
          {getCurrentlocaleText("no_org_hierarchy.text")}
        </div>
      );

    return (
      <div>
        <ModalLoadingComponent>{container}</ModalLoadingComponent>
      </div>
    );
  }
}
export default OrgInOrgView;
