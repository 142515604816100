import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Row, Col, Popover } from "antd";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import Icons from "components/UI-Components/Icons";
import {
  getCurrentlocaleText,
  isoToDateString,
  checkforFeatureEnabled,
  getOrgPolicyCounInfo,
  getDaysLeftInfo,
  getTimezoneToDisplay,
  TIME_DATE_FORMAT,
} from "Core/Utils";
import ActionBtn from "Core/API/ActionBtn";
import moment from "moment";
import UserAPIKeyController from "controller/UserAPIKeyController";
import ActionRoute from "Core/API/ActionRoute";
import TableLayoutV1 from "../layout/TableLayoutV1";

@inject("AuthStore", "ApiKeyViewModel", "OrgViewModel")
@observer
class ApiKeyList extends Component {
  constructor(props) {
    super(props);
    this.ApiKeyModel = this.props.ApiKeyViewModel;
    this.orgModel = this.props.OrgViewModel;
    this.state = {
      expandedRowKeys: [],
      deletableKeys: {
        keys: [],
      },
      selectedKey: {},
      currentPage: 1,
      modalContent: "",
      modalTitle: "",
      isTableModalToggle: false,
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.isMe) {
      this.userKeyList(true);
    }
  }
  userKeyList = (loading = true, params = {}) => {
    UserAPIKeyController.getKeys(params, loading);
  };

  hidePopup = () => {
    this.ApiKeyModel.resetKeyInfo();
  };

  createAfterCall = () => {
    if (this.props.isMe) {
      this.userKeyList(true);
    } else {
      UserAPIKeyController.getUserKeys(this.props.userId, true);
    }
  };

  deleteAfterCall = () => {
    if (this.props.isMe) {
      this.userKeyList(true);
    } else {
      UserAPIKeyController.getUserKeys(this.props.userId, true);
    }
    this.resetState();
  };

  /**
   * GET NETWORK ROUTE
   */
  getCurrentRoute = () => {
    let current_path = window.location.pathname.split("/");
    current_path = current_path.splice(0, 5);
    current_path = current_path.join("/");
    return current_path;
  };

  getMoreInfo(record) {
    return (
      <Row>
        <Col span={3}>
          <strong>{getCurrentlocaleText("user.apikey.expiry.label")}</strong>
        </Col>
        <Col span={1}>
          <strong>{":"}</strong>
        </Col>
        <Col span={20}>
          {record.expiry_at
            ? getDaysLeftInfo(record.expiry_at)
            : getCurrentlocaleText("user.api_keys.key.validity.button.text2")}
        </Col>
      </Row>
    );
  }

  setExpandedRowKeys = keys => {
    this.setState({ expandedRowKeys: keys });
  };
  onExpand = (expanded, record) => {
    let keys = this.state.expandedRowKeys;
    let index = keys.findIndex(val => {
      return val === record.id;
    });
    if (expanded) {
      if (index === -1) {
        keys.push(record.id);
      }
      this.setExpandedRowKeys(keys);
    } else {
      if (index !== -1) {
        keys.splice(index, 1);
      }
      this.setExpandedRowKeys(keys);
    }
  };

  // Check for API key security warning
  checkForWarningMessage(created_at, last_used_at) {
    let currentDate = moment(new Date().getTime());
    let keyCreatedAt = moment(new Date(created_at).getTime());
    let keyLastUsedAt = moment(new Date(last_used_at).getTime());
    let createdTime = currentDate.diff(keyCreatedAt, "days");
    if (last_used_at != null) {
      let LastUsedTime = currentDate.diff(keyLastUsedAt, "days");
      if (LastUsedTime > 90) {
        return true;
      }
    } else if (createdTime > 90 && last_used_at === null) {
      return true;
    } else {
      return false;
    }
  }

  expandRow = () => {
    let keys = this.ApiKeyModel.usersApiKeyData.map(item => {
      return item.id;
    });
    this.setExpandedRowKeys(
      this.state.expandedRowKeys.length !== keys.length ? keys : [],
    );
  };

  onSelectAllChange = (selected, selectedRows, changeRows) => {
    if (selected) {
      this.setSelectedKeys(null, selected, selectedRows);
    } else {
      // on deselect all
      this.resetState();
    }
  };

  resetState = () => {
    this.setState({
      selectedKey: {},
      deletableKeys: {
        keys: [],
      },
    });
  };

  handleTableChange = (record, selected, selectedRows) => {
    selectedRows.length == 0 ? this.resetState() : "";
    this.setSelectedKeys(record, selected, selectedRows);
  };

  setSelectedKeys = (record = {}, selected, selectedRows) => {
    for (let i = 0; i < selectedRows.length; i++) {
      let id = selectedRows[i].id;

      this.setState(() => {
        let newObj = {
            deletableKeys: {
              keys: [],
            },
          },
          selectedKey = this.state.selectedKey,
          item = selectedRows[i],
          tempArray = [];

        selectedKey[id] = {
          value: true,
          name: item.name,
          apikey_id: item.id,
        };

        //Temp array for deletable key list
        for (let i in selectedRows) {
          selectedKey[selectedRows[i].id]
            ? tempArray.push(selectedKey[selectedRows[i].id])
            : "";
        }

        // To set the value : false , when key is deselected and remove it from delete list
        !selected && selectedKey[record.id]
          ? ((selectedKey[record.id].value = false),
            (tempArray = _.filter(tempArray, val => {
              console.log(val);
              return val.id !== record.id;
            })))
          : null;

        newObj["selectedKey"] = selectedKey;
        newObj.deletableKeys["keys"] = tempArray;
        return newObj;
      });
    }
  };

  getModalContentJSX = record => {
    this.setState({
      modalTitle: record && record.name ? record.name : "",
      modalContent: this.getMoreInfo(record),
    });
  };

  handleModalOk(e) {
    this.setState({
      isTableModalToggle: !this.state.isTableModalToggle,
    });
  }

  onTableChange = pagination => {
    this.setState({
      currentPage: pagination.current,
    });
    let page = pagination.current - 1;
    this.userKeyList(true, page);
  };

  render() {
    // user api key from store
    const { isTableModalToggle } = this.state;
    let usersKeys = this.ApiKeyModel.usersApiKeyData;
    let isAddLimitReached =
      usersKeys &&
      usersKeys.length >=
        getOrgPolicyCounInfo(this.orgModel.org, "apikey_count")
        ? true
        : false;

    //table columns
    this.columns = [
      {
        title: getCurrentlocaleText("user.api_keys.name.title"),
        dataIndex: "name",
        key: "name",
        width: "10%",
        render: (text, record, index) => {
          return <div>{text}</div>;
        },
      },
      {
        title: getCurrentlocaleText("user.api_keys.key.title"),
        dataIndex: "prefix",
        width: "15%",
        key: "prefix",
        render: (text, record, index) => {
          return <div>{text}</div>;
        },
      },
      {
        title: getCurrentlocaleText("user.api_keys.created_at.title"),
        dataIndex: "createdat",
        width: "25%",
        key: "created_at",
        sorter: (a, b) => {
          if (a.created_at)
            return Date.parse(a.created_at) - Date.parse(b.created_at);
        },
        render: (text, record, index) => {
          return (
            <div>
              {
                <Popover
                  content={`${moment(
                    new Date(record.created_at).getTime(),
                  ).format(TIME_DATE_FORMAT)} ${getTimezoneToDisplay(
                    record.created_at,
                  )}`}
                >
                  {" "}
                  <span>{isoToDateString(record.created_at)}</span>
                </Popover>
              }
            </div>
          );
        },
      },
      {
        title: getCurrentlocaleText("user.api_keys.last_used_at.title"),
        dataIndex: "lastused",
        width: "25%",
        key: "last_used",
        sorter: (a, b) => {
          if (a.last_used)
            return Date.parse(a.last_used) - Date.parse(b.last_used);
        },
        render: (text, record, index) => {
          return (
            <div>
              {record.last_used ? (
                <Popover
                  content={`${moment(
                    new Date(record.created_at).getTime(),
                  ).format(TIME_DATE_FORMAT)} ${getTimezoneToDisplay(
                    record.last_used,
                  )}`}
                >
                  {" "}
                  <span>{isoToDateString(record.last_used)}</span>
                </Popover>
              ) : (
                getCurrentlocaleText("user.api_keys.key.validity.button.text2")
              )}
              {this.checkForWarningMessage(
                record.created_at,
                record.last_used,
              ) &&
                !record.expired && (
                  <Popover
                    overlayStyle={{ maxWidth: "20%", float: "right" }}
                    placement="right"
                    content={getCurrentlocaleText(
                      "user.api_keys.lastused_exceeds_time.error",
                    )}
                  >
                    <span>
                      <Icons
                        style={{ fontSize: 15, marginLeft: 5, color: "orange" }}
                        type="fa"
                        name="FaExclamationCircle"
                      />
                    </span>
                  </Popover>
                )}
            </div>
          );
        },
      },
      {
        title: getCurrentlocaleText("user.api_keys.expiry_at.title"),
        dataIndex: "expiryat",
        width: "25%",
        key: "expiry_at",
        sorter: (a, b) => {
          if (a.expiry_at)
            return Date.parse(a.expiry_at) - Date.parse(b.expiry_at);
        },
        render: (text, record, index) => {
          return (
            <div>
              {record.expiry_at ? (
                <Popover content={getDaysLeftInfo(record.expiry_at)}>
                  {" "}
                  <span>{isoToDateString(record.expiry_at)}</span>
                </Popover>
              ) : (
                getCurrentlocaleText("user.api_keys.key.validity.button.text2")
              )}
            </div>
          );
        },
      },
    ];

    let container = (
      <div>
        {/* Event List */}
        <div
          style={{
            padding: 24,
            marginTop: 2,
            background: "#fff",
            minHeight: this.props.isMe ? 360 : 150,
          }}
        >
          <div style={{ display: "inline-flex", width: "50%" }}>
            <span className="headertext">
              {getCurrentlocaleText("general.keys.label.text.gatitle")}
            </span>
          </div>
          {this.props.isMe &&
            checkforFeatureEnabled(
              this.props.AuthStore.loggedInUserOrg,
              "apikey",
            ) && (
              <div
                style={{
                  marginBottom: "10px",
                  display: "inline-flex",
                  justifyContent: "flex-end",
                  width: "50%",
                }}
              >
                <div
                  style={{
                    marginLeft: "20px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <ActionRoute
                    primaryButton
                    actionIcon="AiOutlinePlus"
                    actionLabel={getCurrentlocaleText(
                      "user.api_keys.create.title",
                    )}
                    title={
                      isAddLimitReached
                        ? getCurrentlocaleText(
                            "user.api_keys.maximum.reached",
                            {
                              0: getOrgPolicyCounInfo(
                                this.orgModel.org,
                                "apikey_count",
                              ),
                            },
                          )
                        : getCurrentlocaleText("user.api_keys.create.title")
                    }
                    route={
                      !isAddLimitReached ? this.getCurrentRoute() + "/add" : "#"
                    }
                    iconBtnStyle={{ fontSize: 14 }}
                    disabled={isAddLimitReached}
                  >
                    {getCurrentlocaleText("user.api_keys.create.title")}
                  </ActionRoute>
                </div>
                <div
                  style={{
                    marginLeft: "20px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {(this.props.AuthStore.IsPermitted("APIKEY:REVOKE") ||
                    this.props.isMe) && (
                    <ActionBtn
                      title={
                        <span>
                          <Icons type="ai" name="AiOutlineDelete" />{" "}
                          {getCurrentlocaleText(
                            "inode.list.table.delete.button.title.text",
                            {
                              0:
                                this.state.deletableKeys.keys.length > 1
                                  ? "API Keys"
                                  : "API Key",
                            },
                          )}
                        </span>
                      }
                      HoverText={
                        <span>
                          {getCurrentlocaleText(
                            "inode.list.table.delete.button.hover.text",
                            {
                              0:
                                this.state.deletableKeys.keys.length > 1
                                  ? "API Keys"
                                  : "API Key",
                            },
                          )}
                        </span>
                      }
                      resource="apikey"
                      action="delete"
                      iconButton={true}
                      controller={UserAPIKeyController}
                      icon="AiOutlineDelete"
                      params={{
                        user_id: this.props.userId
                          ? this.props.userId
                          : "current",
                      }}
                      disabled={this.state.deletableKeys.keys.length <= 0}
                      formData={this.state.deletableKeys.keys}
                      afterCall={this.deleteAfterCall}
                      messageContent={
                        <span>
                          <p>
                            {getCurrentlocaleText(
                              this.state.deletableKeys.keys.length > 1
                                ? "multiple.apikeys.delete.confirmation.message"
                                : "single.apikeys.delete.confirmation.message",
                            )}
                          </p>
                        </span>
                      }
                      warningMessage={
                        this.state.deletableKeys.keys.length > 1
                          ? getCurrentlocaleText(
                              "multiple.keys.delete.warning.message",
                            )
                          : getCurrentlocaleText(
                              "single.keys.delete.warning.message",
                            )
                      }
                      cancelText={getCurrentlocaleText(
                        "inode.delete.confirmation.cancel.text",
                        {
                          0:
                            this.state.deletableKeys.keys.length > 1
                              ? "API Keys"
                              : "API Key",
                        },
                      )}
                      okText={
                        <span>
                          <Icons
                            type="ai"
                            name="AiOutlineDelete"
                            style={{ margin: 0 }}
                            className="iot-delete-icon"
                          />
                          {getCurrentlocaleText(
                            "inode.delete.confirmation.ok.text",
                            {
                              0:
                                this.state.deletableKeys.keys.length > 1
                                  ? "API Keys"
                                  : "API Key",
                            },
                          )}
                        </span>
                      }
                    ></ActionBtn>
                  )}
                </div>
              </div>
            )}
          <Row gutter={16} type="flex" align="middle">
            <Col span={24}>
              {this.ApiKeyModel.keys.length > 0 ? (
                <div>
                  <TableLayoutV1
                    columns={this.columns}
                    dataSource={usersKeys}
                    pagination={{
                      pageSize:
                        this.ApiKeyModel && this.ApiKeyModel.pageable.size,
                      total:
                        this.ApiKeyModel &&
                        this.ApiKeyModel.pageable.total_count,
                      current: this.state.currentPage,
                    }}
                    className="userApiList"
                    selectedRows={this.handleTableChange}
                    selectAll={this.onSelectAllChange}
                    expandedRowKeys={this.state.expandedRowKeys}
                    onExpand={this.onExpand}
                    expandedRowRender={record => {
                      return this.getMoreInfo(record);
                    }}
                    getModalContent={record => {
                      return this.getModalContentJSX(record);
                    }}
                    onChange={this.onTableChange}
                    handleModalOk={e => this.handleModalOk(e)}
                    modalTitle={this.state.modalTitle}
                    modalContent={this.state.modalContent}
                    isTableModalToggle={isTableModalToggle}
                    isModalLoading={false}
                    disableFooter={true}
                  />
                </div>
              ) : (
                <div>
                  <h3 style={{ paddingLeft: 24 }}>
                    {getCurrentlocaleText(
                      "user.api_keys.no_data_to_display.message",
                    )}
                  </h3>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    );

    return <LoadingComponent loading={false}>{container}</LoadingComponent>;
  }
}

export default ApiKeyList;
