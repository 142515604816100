import React, { Component } from "react";
import { Card, Row, Col } from "antd";
import { observer, inject } from "mobx-react";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import BreadcrumbComponent from "components/UI-Components/BreadcrumbComponent";
import Icons from "components/UI-Components/Icons";

import NetworksFormPage from "components/Forms/NetworksFormPage";
import {
  getNodeVersion,
  getCurrentlocaleText,
  removeDefaultLabels,
  copyObject,
  isEmpty,
} from "Core/Utils";
import VinetChangeConfirmDialog from "components/UI-Components/VinetChangeConfirmDialog";
import NetworkContainer from "./NetworkContainer";
import OrgSelector from "components/Orgs/OrgSelector";

import InodeController from "controller/InodeController";
import ProfileController from "controller/ProfileController";
import NetworkController from "controller/NetworkController";
import ServiceController from "controller/ServiceController";
import ClusterController from "controller/ClusterController";

const ONE_ARM_MODE = "ONE_ARM_MODE";

@inject(
  "ModelStore",
  "AuthStore",
  "UiStore",
  "InodeViewModel",
  "NetworkViewModel",
  "ServiceViewModel",
  "ClusterViewModel",
)
@observer
class NetworkDetails extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = this.props.InodeViewModel;
    this.networkModel = this.props.NetworkViewModel;
    this.serviceModel = this.props.ServiceViewModel;
    this.clusterModel = this.props.ClusterViewModel;
    this.state = {
      isView:
        this.props.match.path.indexOf("/view/:networkId") !== -1 ? true : false,
      node_id: this.props.match.params.nodeId || this.props.match.params.id,
      nodeId: this.props.match.params.nodeId || this.props.match.params.id,
      cluster_id: this.props.match.params.cluster_id,
      BreadcrumbList: [],
      orgId: this.props.match.params.id,
      isVirtualNode: false,
      selectedService: {},
      action: "Networks",
      NetworksConnectedwithRemote: [],
      vinetDetails: [],
      vinetChangeConfirm: false,
      rememberRebootCall: false,
      isWan: false,
      network_id: this.props.match.params.networkId || null,
      formData: {},
      availableNetwork: [],
      enableSecurityPolicyDebugger: true,
      clusterDetails: null,
      showInterfaces: false,
    };
    this.store = this.props.ModelStore;
    this.color = {};
    this.nodeApiByInterval = null;
    this.network = {
      interface: "eth1",
      node_id: this.props.match.params.nodeId || this.props.match.params.id,
    };
  }

  // Network connect and create
  networkFormSubmit = values => {
    this.setState({ loading: true });
    Object.assign(values, this.network);
    // Network on node or cluster
    if (!this.props.match.params.cluster_id) {
      values.node_id = this.state.node_id;
    } else {
      if (values.node_id) {
        delete values.node_id;
      }
      values.cluster_id = this.props.match.params.cluster_id;
    }
    delete values.firewall_selector;
    let firewallSelector =
      this.props.UiStore.firewall_selector &&
      this.props.UiStore.firewall_selector.firewall_selector;
    values.firewall_selector = firewallSelector;
    if (values.reserved) {
      if (!values.reserved.end) {
        values.reserved.end = values.reserved.start;
      }
    }
    if (
      values.tan_interface_ip &&
      values.tan_interface_ip.split("/")[0].length == 0
    ) {
      values.tan_interface_ip = null;
    }
    // FOR ROUTES
    values = this.setRoutesInFormData(values);

    // If VLAN disabled (form data is null), set VLAN ID to 0 and send it
    if (!values.vlan_id) {
      values.vlan_id = 0;
    }
    // LAT-7650 Remove vlan_enabled field since it is not being used
    delete values.vlan_enabled;
    // If vnode is selected, connect to the vnode else create network
    if (values.vNode) {
      // Create network and connect
      values.connect_networks = values.vNode;
      // Remove internal remote network selection form item from the payload
      delete values.vNode;
      // Remove remote_representation_network form item from the payload
      Object.keys(values)
        .filter(key => {
          return key.startsWith("remote_representation_network");
        })
        .forEach(field => {
          if (field) {
            delete values[field];
          }
        });
      // Remove tunnel_diode form item from the payload
      Object.keys(values)
        .filter(key => {
          return key.startsWith("tunnel_diode");
        })
        .forEach(field => {
          if (field) {
            delete values[field];
          }
        });
      // Create API call func....
      this.createNetworkData(values);
    } else {
      if (!this.props.UiStore.showRepNetwork) {
        // Update API call func....
        this.createNetworkData(values);
      } else {
        this.setState({ loading: false });
      }
    }
  };
  componentWillUnmount() {
    clearInterval(this.networkApiByInterval);
  }
  refreshNetworkData = () => {
    let res = this.state.availableNetwork;
    if (res) {
      let currentNodeID =
          this.props.match.params.nodeId || this.props.match.params.id,
        currentNetwork = {},
        currentNetworkID = this.props.match.params.networkId,
        wanNetworkId = null,
        currentClusterID = this.props.match.params.cluster_id;
      res.forEach(net => {
        if (
          this.props.match.params.cluster_id &&
          net.cluster &&
          net.cluster.id
        ) {
          if (
            net.cluster &&
            net.cluster.id === currentClusterID &&
            net.id === currentNetworkID
          ) {
            currentNetwork = net;
          }
        } else {
          if (
            net.node &&
            net.node.id === currentNodeID &&
            net.id === currentNetworkID
          ) {
            currentNetwork = net;
          }
        }
      });
      // For wan network id
      if (this.props.match.params.cluster_id) {
        res.forEach(net => {
          if (
            net.cluster &&
            net.cluster.id === currentClusterID &&
            net.is_wan_network
          ) {
            wanNetworkId = net.id;
          }
        });
      } else {
        res.forEach(net => {
          if (net.node && net.node.id === currentNodeID && net.is_wan_network) {
            wanNetworkId = net.id;
          }
        });
      }
      currentNetwork.enable_routing_for_other_tans = res.enable_routing_for_other_tans
        ? res.enable_routing_for_other_tans
        : false;
      if (currentNetwork.id) {
        if (currentNetwork) {
          this.setState({
            NetworksConnectedwithRemote:
              currentNetwork.connected_networks &&
              currentNetwork.connected_networks.length > 0 &&
              currentNetwork.connected_networks,
            formData: currentNetwork,
            wanNetworkId: wanNetworkId,
          });
        }
        this.setState({
          network_id: currentNetwork.id,
          formData: currentNetwork,
          action: !this.state.isView
            ? getCurrentlocaleText("network.form.useraction_edit.label.text")
            : "View Network",
          wanNetworkId: wanNetworkId,
        });

        this.props.UiStore.SetStoreData(
          "Discovery",
          currentNetwork.policy &&
            currentNetwork.policy.discovery &&
            currentNetwork.policy.discovery.enable,
        );
      } else {
        this.setState({
          wanNetworkId: wanNetworkId,
          action: !this.state.isView
            ? getCurrentlocaleText("network.form.useraction_create.label.text")
            : "View Network",
        });
      }
    }
  };
  /**
   * Get Network data for the page
   */
  getNetworkdata = (orgId = null) => {
    let all_params = [
      {
        org_id: orgId
          ? orgId
          : this.inodeModel.inode.organization
          ? this.inodeModel.inode.organization.id
          : this.props.orgId || this.props.match.params.id,
        own: true,
        size: 1,
        page: 0,
      },
    ];
    this.networkModel.resetNetworks();
    NetworkController.getAllNetworksForDropdown(all_params, true).then(res => {
      if (res) {
        let total_count = this.networkModel.pageable.total_count / 100;
        this.updateOrgsNetworkData(total_count, [], true, orgId);
      }
    });
  };
  /**
   * hasNetworkStatus
   */
  hasNetworkStatus(networks) {
    if (networks) {
      let connected_networks = networks.filter(network => {
        return network.status && network.status.connected_networks;
      });
      return connected_networks.length > 0 ? true : false;
    }
    return false;
  }

  /**
   * getNetworksByInterval
   */
  getNetworksByInterval = () => {
    let networks = this.networkModel.networks.filter(value => {
      if (value && value.id)
        return value.id === this.props.match.params.networkId;
    });
    if (this.hasNetworkStatus(networks)) {
      clearInterval(this.networkApiByInterval);
      this.networkApiByInterval = setInterval(() => {
        let params = this.props.params || {
          org_id: this.inodeModel.inode.organization
            ? this.inodeModel.inode.organization.id
            : this.props.orgId || this.props.match.params.id,
          own: true,
          size: 1,
          page: 0,
        };
        NetworkController.getNetworks(params, false, true, false).then(
          response => {
            if (response && response.data && response.data.results) {
              this.updateOrgsNetworkData(
                response.data.total_count / 100,
                [],
                false,
              );
              // check for clear interval
              if (this.networkModel.networks.length === 0) {
                clearInterval(this.networkApiByInterval);
              }
            }
          },
        );
      }, 30000);
    }
  };

  updateOrgsNetworkData(totalCount, availableNetworks, loading, orgId = null) {
    if (totalCount > 0) {
      let allParams = [];
      for (let page = 0; page < totalCount; page++) {
        allParams.push({
          org_id: orgId
            ? orgId
            : this.inodeModel.inode.organization
            ? this.inodeModel.inode.organization.id
            : this.props.orgId || this.props.match.params.id,
          own: true,
          size: 100,
          page: page,
        });
      }
      NetworkController.getAllNetworksForDropdown(allParams, loading).then(
        resp => {
          if (resp) {
            availableNetworks = availableNetworks.concat(resp);
            this.setState({ availableNetwork: availableNetworks }, () => {
              this.refreshNetworkData();
            });
            this.getNetworksByInterval();
          }
        },
      );
    }
  }
  cancelNetwork = () => {
    let link = window.location.pathname.replace(
      this.props.match.params.networkId
        ? !this.state.isView
          ? "/modify/" + this.props.match.params.networkId
          : "/view/" + this.props.match.params.networkId
        : !this.state.isView
        ? "/modify"
        : "/view",
      "",
    );
    // adding cluster routes for second tab (network tab)
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.cluster_id
    )
      link = link + "/networks";

    this.props.history.push(link);
    this.props.UiStore.formErrorResponse = {};
  };

  UNSAFE_componentWillMount() {
    if (!this.props.match.params.cluster_id) {
      let orgId = null;
      if (this.props.match.params.nodeId || this.props.match.params.id) {
        InodeController.getInode(
          this.props.match.params.nodeId || this.props.match.params.id,
        ).then(res => {
          orgId = res.organization.id;
          let isMultiNicEnabled =
            res && res.multinic_mode && res.multinic_mode.enable ? true : false;
          let showInterfaces = isMultiNicEnabled ? true : false;
          this.setState({
            orgId: res.organization.id,
            showInterfaces: showInterfaces,
          });
          if (
            this.props.AuthStore.IsPermitted("NODE:CREATE") ||
            this.props.AuthStore.IsPermitted("NETWORK:CONNECT_DISCONNECT")
          ) {
            // Getting profiles for network multi connect.
            ProfileController.getProfiles({
              org_id:
                orgId ||
                this.props.AuthStore.loggedInUser.organization.id ||
                this.state.orgId,
              own: true,
            }).then(resp => {
              // Filtering single profile details
              ProfileController.getProfile(res.profile.id).then(
                profileResponse => {
                  this.setState({
                    isVirtualNode: profileResponse.config.vpn_server,
                  });
                },
              );
            });
          }
          this.setState({
            orgId: orgId,
          });
          if (
            res.status &&
            res.status.node &&
            res.status.node.last_contacted_at
          ) {
            this.setState({ isNewNode: res.status.node.last_contacted_at });
          }
          this.setState(() => {
            let newObj = {},
              BreadcrumbList = [],
              nodeCrumb = [
                {
                  name: res.name ? res.name : "Details",
                  link: NetworkContainer.cameFromOrgDetailPage(
                    this.props.match.params.nodeId ||
                      this.props.match.params.id,
                  )
                    ? `orgs/${res.organization.id}/inodes/${res.id}`
                    : NetworkContainer.cameFromDashboardPage(
                        window.location.pathname,
                      )
                    ? NetworkContainer.dashboardiNodeURL(
                        window.location.pathname,
                      ).indexOf("dashboard/service") === -1
                      ? `${NetworkContainer.dashboardiNodeURL(
                          window.location.pathname,
                        )}/${res.id}`
                      : `${NetworkContainer.dashboardiNodeURL(
                          window.location.pathname,
                        )}/services`
                    : `inodes/${res.id}`,
                },
              ];
            if (
              NetworkContainer.cameFromOrgDetailPage(
                this.props.match.params.nodeId || this.props.match.params.id,
              )
            ) {
              BreadcrumbList.push(
                {
                  name: res.organization.name,
                  link: `orgs/${res.organization.id}`,
                },
                { name: "iNodes", link: `orgs/${res.organization.id}/inodes` },
                ...nodeCrumb,
                {
                  name: this.state.action,
                  link: !this.state.isView
                    ? `orgs/${res.organization.id}/inodes/${res.id}/modify/${this.state.network_id}`
                    : `orgs/${res.organization.id}/inodes/${res.id}/view/${this.state.network_id}`,
                },
              );
            } else if (
              NetworkContainer.cameFromDashboardPage(window.location.pathname)
            ) {
              BreadcrumbList.push(
                {
                  name: NetworkContainer.linkNameForiNode(
                    window.location.pathname,
                  ),
                  link: NetworkContainer.dashboardiNodeURL(
                    window.location.pathname,
                  ),
                },
                ...nodeCrumb,
              );
            } else {
              BreadcrumbList.push(
                { name: "iNodes", link: "inodes" },
                ...nodeCrumb,
              );
            }
            this.props.UiStore.setInodeName(res.name);
            this.props.UiStore.setOrgName(res.organization.name);
            newObj["BreadcrumbList"] = BreadcrumbList;
            return newObj;
          });
          this.getNetworkdata();
        });
      }
    } else {
      ClusterController.getCluster(this.props.match.params.cluster_id).then(
        res => {
          let orgId = res.organization.id;
          let isMultiNicEnabled =
            res &&
            res.config &&
            res.config.multinic_mode &&
            res.config.multinic_mode.enable
              ? true
              : false;
          let showInterfaces = isMultiNicEnabled ? true : false;
          this.setState({
            orgId: orgId,
            isVirtualNode: false,
            isNewNode: false,
            clusterDetails: res,
            showInterfaces: showInterfaces,
          });

          // Update breadcrumb
          this.setState(() => {
            let newObj = {},
              BreadcrumbList = [],
              nodeCrumb = [
                {
                  name: res.name ? res.name : "Details",
                  link: `orgs/${res.organization.id}/clusters/${res.id}`,
                },
              ];
            if (
              NetworkContainer.cameFromOrgDetailPage(
                this.props.match.params.nodeId || this.props.match.params.id,
              )
            ) {
              BreadcrumbList.push(
                {
                  name: res.organization.name,
                  link: `orgs/${res.organization.id}`,
                },
                {
                  name: "Clusters",
                  link: `orgs/${res.organization.id}/clusters`,
                },
                ...nodeCrumb,
                {
                  name: this.state.action,
                  link: !this.state.isView
                    ? `orgs/${res.organization.id}/clusters/${res.id}/modify/${this.state.network_id}`
                    : `orgs/${res.organization.id}/clusters/${res.id}/view/${this.state.network_id}`,
                },
              );
            } else {
              BreadcrumbList.push(
                { name: "Clusters", link: "clusters" },
                ...nodeCrumb,
              );
            }
            this.props.UiStore.setInodeName(res.name);
            this.props.UiStore.setOrgName(res.organization.name);
            newObj["BreadcrumbList"] = BreadcrumbList;
            return newObj;
          });
          this.getNetworkdata(orgId);
        },
      );
    }
  }

  setRoutesInFormData = values => {
    if (values.routes || values.static_routes) {
      if (values.routes && values.routes.length > 0) {
        // Clear static routes if any
        values.routes = values.routes.filter(route => {
          return route && route.destination === "0.0.0.0/0";
        });
        if (values.static_routes && values.static_routes.length > 0) {
          // Clear default routes if any
          values.static_routes = values.static_routes.filter(route => {
            return route && route.destination !== "0.0.0.0/0";
          });
          values.routes.push(...values.static_routes);
          delete values.static_routes;
        }
      } else {
        if (values.static_routes && values.static_routes.length > 0) {
          values.routes = copyObject(values.static_routes);
          delete values.static_routes;
        }
      }
    }
    // Remove form item of destination and via [from Default route and Static routes]
    Object.keys(values)
      .filter(key => {
        return key.startsWith("via_");
      })
      .forEach(viaField => {
        if (viaField) {
          delete values[viaField];
        }
      });
    Object.keys(values)
      .filter(key => {
        return key.startsWith("destination_");
      })
      .forEach(destinationField => {
        if (destinationField) {
          delete values[destinationField];
        }
      });
    Object.keys(values)
      .filter(key => {
        return key.startsWith("allowed_virtual_nw_id_");
      })
      .forEach(virtualNwId => {
        if (virtualNwId) {
          delete values[virtualNwId];
        }
      });
    Object.keys(values)
      .filter(key => {
        return key.startsWith("allowed_virtual_repnet_");
      })
      .forEach(representationField => {
        if (representationField) {
          delete values[representationField];
        }
      });
    // For routes in the request object
    let routes = [];
    if (values.routes) {
      values.routes.forEach(route => {
        let routeObj = {};
        routeObj.destination = route.destination;
        routeObj.enable_routing_for_other_tans =
          route.enable_routing_for_other_tans;
        if (!route.isIpAddress) {
          routeObj.via = route.network_id;
        } else {
          if (route.via && route.via.length > 0 && route.via[0])
            routeObj.via = route.via[0];
          if (route.allowed_virtual && route.allowed_virtual.length > 0) {
            routeObj.allowed_virtual = route.allowed_virtual.map(
              allowed_virtual => {
                return {
                  network_id: allowed_virtual.network_id,
                  disable_representation_network_automation: allowed_virtual.has_auto_repnet_enabled
                    ? allowed_virtual.enable_representation_network_automation
                      ? false
                      : true
                    : true,
                  local_representation_network:
                    allowed_virtual.local_representation_network,
                };
              },
            );
          }
        }
        if (routeObj.destination && routeObj.via && !routeObj.disabled) {
          routes.push(routeObj);
        }
      });
    }
    values.routes = routes;
    return values;
  };

  // perform data computation for network update
  doEditNetwork = (values, isWan) => {
    let request = null;
    if (values.vNodeconnected) {
      values.vNode = values.vNodeconnected;
      delete values.vNodeconnected;
    }
    if (values.firewall_selector) {
      delete values.firewall_selector;
      values.firewall_selector =
        this.props.UiStore.firewall_selector &&
        this.props.UiStore.firewall_selector.firewall_selector;
    }
    // IF REMOTE NETWORKS SELECTED
    if (values.vNode) {
      values.connect_networks = values.vNode;
      delete values.vNode;
    }

    // FOR ROUTES
    values = this.setRoutesInFormData(values);

    // If VLAN disabled (form data is null), set VLAN ID to 0 and send it
    if (!values.vlan_id) {
      values.vlan_id = 0;
    }
    // LAT-7650 Remove vlan_enabled field since it is not being used
    delete values.vlan_enabled;

    // FOR VIRTUAL NODE NETWORK
    if (this.state.isVirtualNode) {
      let virtualData = {
        connect_networks: values.connect_networks,
        network_type: values.network_type,
        metadata: values.metadata,
        policy: values.policy,
        firewall_selector:
          this.props.UiStore.firewall_selector &&
          this.props.UiStore.firewall_selector.firewall_selector,
        routes: values.routes,
      };
      values = virtualData;
    }

    // For Virtual, routes throws error "iNode doesn't support setting WAN network static routes."
    if (
      this.inodeModel &&
      this.inodeModel.inode &&
      this.inodeModel.inode.profile &&
      this.inodeModel.inode.profile.name &&
      (this.inodeModel.inode.profile.name.toLowerCase() ===
        "unified cloud gateway" ||
        this.inodeModel.inode.profile.name.toLowerCase() === "virtual") &&
      isWan
    ) {
      delete values.routes;
    }

    if (this.state.network_id) {
      // Update API call func....
      return this.updateNetworkData(
        values,
        this.props.match.params.networkId,
        {},
        isWan,
      );
    }
  };
  // create network call
  createNetworkData(values) {
    this.networkModel.loading = true;
    NetworkController.create(values, this.props.params)
      .then(response => {
        //Success -Connect to network
        this.setState({ network_id: response.id });
        this.props.UiStore.showRepNetwork = false;
        this.networkModel.loading = false;
        this.props.UiStore.networkResponseMsg = "";
        this.props.UiStore.firewall_selector = null;
        this.cancelNetwork();
      })
      .catch(error => {
        this.setState({ loading: false });
        this.networkModel.loading = false;
        let defaultMessage = getCurrentlocaleText("error.message.bad_request"),
          // LAT-3281 NAT base version needs to updated here!!!
          baseVersion = 1059.0;
        this.props.UiStore.formErrorResponse = error.response;
        if (
          this.props.UiStore.formErrorResponse.data &&
          (this.props.UiStore.formErrorResponse.data.errorCode ===
            "ConflictWithConnectedNetwork" ||
            this.props.UiStore.formErrorResponse.data.errorCode ===
              "CidrLengthMismatch" ||
            this.props.UiStore.formErrorResponse.data.errorCode ===
              "ConflictWithNetworkCidr" ||
            this.props.UiStore.formErrorResponse.data.errorCode ===
              "AlreadyInUseNxid") &&
          parseFloat(getNodeVersion(this.inodeModel.inode)) >= baseVersion
        ) {
          this.props.UiStore.networkResponseMsg = error.message;
          this.props.UiStore.showRepNetwork = true;
          this.props.UiStore.lastfailedremoteiNode = values.vNode;
        } else if (
          this.props.UiStore.formErrorResponse.data.errorCode ===
          "NetworkVersionMismatch"
        ) {
          // Do nothing
          // Error has shown by network controller itself from API
        } else {
          // TODO - AV - success and error message shown twice. Because of store successmessage not cleared when errormessage set.
          if (
            !(
              this.props.UiStore.formErrorResponse.data &&
              this.props.UiStore.formErrorResponse.data.validationErrors
            )
          ) {
            this.props.UiStore.errorMessage = this.props.UiStore
              .formErrorResponse.data
              ? `${this.props.UiStore.formErrorResponse.data.message}`
              : defaultMessage;
            if (
              this.props.UiStore.errorMessage ===
              getCurrentlocaleText("error.message.bad_request")
            ) {
              let ele = document.querySelector(".portal-err-msg");
              //dynamic design update for length messages
              if (ele) {
                ele.innerHTML = getCurrentlocaleText(
                  "error.message.bad_request",
                );
                ele = document.querySelector(".portal-error-close-btn");
                ele && ele.classList.add("portal-error-close-btn-wrapped");
                ele = document.querySelector(".ant-message-notice-content");
                ele && ele.classList.add("portal-error-message-wrapped");
              }
            }
          }
        }
      });
  }
  // update network call
  updateNetworkData(values, networkId, params = {}, isWan) {
    // Update network and connect
    this.networkModel.loading = true;
    if (
      values.tan_interface_ip &&
      values.tan_interface_ip.split("/")[0].length == 0
    ) {
      values.tan_interface_ip = "";
    }
    values.service_addressing = "Auto";
    return NetworkController.update(
      values,
      { id: networkId },
      this.props.params,
      isWan,
    )
      .then(response => {
        //Success -Connect to network
        this.props.UiStore.showRepNetwork = false;
        setTimeout(() => {
          this.updateServiceList(false, true);
        }, 1200);
        this.props.UiStore.showRepNetwork = false;
        this.networkModel.loading = false;
        this.props.UiStore.networkResponseMsg = "";
        this.cancelNetwork();
      })
      .catch(error => {
        let defaultMessage = getCurrentlocaleText("error.message.bad_request"),
          // LAT-3281 NAT base version needs to updated here!!!
          baseVersion = 1059.0;
        this.setState({ loading: false });
        this.networkModel.loading = false;
        if (
          this.props.UiStore.formErrorResponse.data &&
          (this.props.UiStore.formErrorResponse.data.errorCode ===
            "ConflictWithConnectedNetwork" ||
            this.props.UiStore.formErrorResponse.data.errorCode ===
              "CidrLengthMismatch" ||
            this.props.UiStore.formErrorResponse.data.errorCode ===
              "ConflictWithNetworkCidr" ||
            this.props.UiStore.formErrorResponse.data.errorCode ===
              "AlreadyInUseNxid") &&
          (this.props.match.params.cluster_id ||
            parseFloat(getNodeVersion(this.inodeModel.inode)) >= baseVersion)
        ) {
          this.props.UiStore.networkResponseMsg = this.props.UiStore.formErrorResponse.data.message;
          this.props.UiStore.showRepNetwork = true;
        } else if (
          this.props.UiStore.formErrorResponse.data.errorCode ===
          "NetworkVersionMismatch"
        ) {
          // Do nothing
          // Error has shown by network controller itself from API
        } else {
          // TODO - AV - success and error message shown twice. Because of store successmessage not cleared when errormessage set.
          if (
            !(
              this.props.UiStore.formErrorResponse.data &&
              this.props.UiStore.formErrorResponse.data.validationErrors
            )
          ) {
            this.props.UiStore.errorMessage = this.props.UiStore
              .formErrorResponse.data
              ? `${this.props.UiStore.formErrorResponse.data.message}`
              : defaultMessage;
            if (
              this.props.UiStore.errorMessage ===
              getCurrentlocaleText("error.message.bad_request")
            ) {
              let ele = document.querySelector(".portal-err-msg");
              //dynamic design update for length messages
              if (ele) {
                ele.innerHTML = getCurrentlocaleText(
                  "error.message.bad_request",
                );
                ele = document.querySelector(".portal-error-close-btn");
                ele && ele.classList.add("portal-error-close-btn-wrapped");
                ele = document.querySelector(".ant-message-notice-content");
                ele && ele.classList.add("portal-error-message-wrapped");
              }
            }
          }
        }
      });
  }

  isItCidrChange = formData => {
    let status = NetworkContainer.isItCidrChange(
      formData,
      this.inodeModel,
      this.networkModel,
      this.state.network_id,
    );
    this.props.UiStore.isCidrChanged = status;
    return status;
  };
  onDeclineCidrChange = () => {
    this.setState({
      editNetworkFormData: {},
      loading: false,
      rememberRebootCall: false,
    });
    this.networkModel.loading = false;
  };
  onDeclineviNetChange = () => {
    this.setState({ vinetChangeConfirm: false, editNetworkFormData: {} });
  };
  onConfirmviNetChange = () => {
    const defaultValues = this.state.editNetworkFormData,
      IntialValue = {};
    IntialValue.connect_networks = [];
    IntialValue.name = defaultValues.name;
    IntialValue.cidr = defaultValues.cidr;
    IntialValue.reserved = defaultValues.reserved;
    IntialValue.network_type = defaultValues.network_type;
    if (this.state.isVirtualNode) {
      delete IntialValue.reserved;
      delete IntialValue.cidr;
      delete IntialValue.name;
    }
    NetworkController.update(
      IntialValue,
      {
        id: this.props.match.params.networkId,
      },
      this.props.params,
    ).then(response => {
      if (this.isItCidrChange(defaultValues) && !this.state.isVirtualNode) {
        // if its changed show information popup
        this.networkModel.loading = false;
        this.setState({
          vinetChangeConfirm: false,
          editNetworkFormData: defaultValues,
          rememberRebootCall: true,
          loading: false,
        });
      } else {
        this.setState({ vinetChangeConfirm: false });
        this.doEditNetwork(defaultValues, this.state.isWan)
          .then(response => {})
          .catch(error => {});
      }
    });
  };
  onConfirmCidrChange = () => {
    // on submit update network and call reboot.
    this.doEditNetwork(this.state.editNetworkFormData, this.state.isWan)
      .then(response => {})
      .catch(error => {
        let defaultMessage = getCurrentlocaleText("error.message.bad_request"),
          // LAT-3281 NAT base version needs to updated here!!!
          baseVersion = 1059.0;
        this.setState({ loading: false });
        if (
          this.props.UiStore.formErrorResponse.data &&
          (this.props.UiStore.formErrorResponse.data.errorCode ===
            "ConflictWithConnectedNetwork" ||
            this.props.UiStore.formErrorResponse.data.errorCode ===
              "CidrLengthMismatch" ||
            this.props.UiStore.formErrorResponse.data.errorCode ===
              "ConflictWithNetworkCidr" ||
            this.props.UiStore.formErrorResponse.data.errorCode ===
              "AlreadyInUseNxid") &&
          (this.props.match.params.cluster_id ||
            parseFloat(getNodeVersion(this.inodeModel.inode)) >= baseVersion)
        ) {
          this.props.UiStore.networkResponseMsg = this.props.UiStore.formErrorResponse.data.message;
          this.props.UiStore.showRepNetwork = true;
          //this.props.UiStore.lastfailedremoteiNode = values.vNode;
        } else {
          // TODO - AV - success and error message shown twice. Because of store successmessage not cleared when errormessage set.
          if (
            !(
              this.props.UiStore.formErrorResponse.data &&
              this.props.UiStore.formErrorResponse.data.validationErrors
            )
          ) {
            this.props.UiStore.errorMessage = this.props.UiStore
              .formErrorResponse.data
              ? `${this.props.UiStore.formErrorResponse.data.message}`
              : defaultMessage;
          }
        }
      });
  };
  updateServiceList = (loading = true, forceCall = false) => {
    let params = {
      node_id: this.props.match.params.id,
    };
    ServiceController.getServices(params, loading, forceCall).then(res => {
      this.resetSelectedService();
    });
  };

  resetSelectedService = () => {
    this.setState({ selectedService: {} });
  };

  validateForm = (values, isWan) => {
    // removing default labels
    if (values.metadata && values.metadata.labels) {
      values.metadata.labels = removeDefaultLabels(
        values.metadata && values.metadata.labels,
      );
    }
    // For Interfaces
    if (values.attached_interfaces) {
      if (
        values.attached_interfaces &&
        values.attached_interfaces.display_name
      ) {
        values.attached_interfaces = values.attached_interfaces.display_name;
      }
      values.attached_to = {
        interfaces: [values.attached_interfaces],
      };
      delete values.attached_interfaces;
    }
    // update isWan in state
    this.setState({
      formValues: values,
      isWan: this.state.network_id === this.state.wanNetworkId,
    });
    let firewallDebugger = {
      firewall: {
        debug: this.state.enableSecurityPolicyDebugger,
      },
    };
    values.policy = firewallDebugger;
    delete values.firewall_debugger;

    if (this.state.network_id === null) {
      this.networkFormSubmit(values);
    } else {
      // this will check the change for vinet
      if (
        NetworkContainer.checkForviNetchange(
          values,
          this.state.NetworksConnectedwithRemote,
          this.inodeModel.inode,
        ) &&
        !this.state.rememberRebootCall
      ) {
        this.setState({
          vinetChangeConfirm: true,
          editNetworkFormData: values,
        });
      } else if (this.state.rememberRebootCall) {
        // this will remembers for reboot the iNode after vinet change check
        this.doEditNetwork(values, isWan)
          .then(response => {})
          .catch(error => {});
      } else {
        /* Replacing with LAT-5252 . */

        this.setState(
          {
            editNetworkFormData: values,
          },
          () => {
            /*  after state update , proceed */
            if (
              !this.props.match.params.cluster_id &&
              this.props.InodeViewModel.inode.node_state.toLowerCase() ===
                "alive" &&
              this.isItCidrChange(values)
            ) {
              /* reboot only on alive and cidr is changed */
              this.setState({
                rememberRebootCall: true,
              });
            }

            this.onConfirmCidrChange();
          },
        );
      }
    }
    if (
      this.props.AuthStore.IsPermitted("NODE:CREATE") ||
      this.props.AuthStore.IsPermitted("NETWORK:CONNECT_DISCONNECT")
    ) {
    }
  };
  render() {
    let node_data = this.inodeModel.inode;
    let cluster_data = this.state.clusterDetails
      ? this.state.clusterDetails
      : this.clusterModel && this.clusterModel.cluster;
    let container = (
      <div>
        {this.state.vinetChangeConfirm ? (
          <VinetChangeConfirmDialog
            visible={this.state.vinetChangeConfirm}
            onOk={this.onConfirmviNetChange}
            onCancel={this.onDeclineviNetChange}
          />
        ) : null}
        <Row gutter={16} type="flex" align="middle">
          <Col lg={{ span: 18 }} xl={{ span: 20 }}>
            {this.props.match.params.cluster_id ? (
              <h2 className="page-title">
                <Icons type="bs" name="BsBoundingBox" />
                {"  "}
                {cluster_data.name ? cluster_data.name : null}
              </h2>
            ) : (
              <h2 className="page-title">
                <Icons type="fa" name="FaRegHdd" />
                {"  "}
                {node_data.name ? node_data.name : null}
              </h2>
            )}
            <BreadcrumbComponent
              {...this.props}
              BreadcrumbList={this.state.BreadcrumbList}
            />
          </Col>
          <Col lg={{ span: 6 }} xl={{ span: 4 }} className="flex flex-h-right">
            <OrgSelector {...this.props} />
          </Col>
        </Row>

        <div>
          <Row gutter={2} type="flex" align="top">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
              xl={{ span: 24 }}
            >
              <Card
                loading={this.state.action === "Networks" ? true : false}
                title={
                  <span className="iot-main-card-title">
                    <Icons type="fa" name="FaConnectdevelop" />
                    <span> {this.state.action}</span>
                  </span>
                }
              >
                <NetworksFormPage
                  ref={c => {
                    this.networkForm = c;
                  }}
                  isCandidate={
                    node_data.cluster_node_config
                      ? node_data.cluster_node_config.is_candidate
                      : null
                  }
                  onSubmit={this.validateForm}
                  isloading={this.state.action === "Networks" ? true : false}
                  onCancel={this.cancelNetwork}
                  formData={this.state.formData}
                  action={
                    this.state.isView
                      ? "view"
                      : this.state.action === "Edit Network"
                      ? "edit"
                      : "create"
                  }
                  isVirtualNode={this.state.isVirtualNode}
                  availableNetwork={this.state.availableNetwork}
                  isNewnetwork={
                    this.state.isView
                      ? false
                      : this.state.action === "Edit Network"
                      ? false
                      : true
                  }
                  orgId={this.state.orgId}
                  nodeId={this.state.nodeId}
                  clusterDetails={this.state.clusterDetails}
                  cluster_id={this.props.match.params.cluster_id}
                  netWorkId={this.props.match.params.networkId}
                  wanNetworkId={this.state.wanNetworkId}
                  onDebuggerChange={status => {
                    this.setState({
                      enableSecurityPolicyDebugger: status,
                    });
                  }}
                  viewOnly={this.state.isView}
                  showInterfaces={this.state.showInterfaces}
                  {...this.props}
                />
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );

    return (
      <LoadingComponent loading={this.networkModel.loading}>
        {container}
      </LoadingComponent>
    );
  }
}

export default NetworkDetails;
