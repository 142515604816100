import { Table } from "antd";
import React, { Component } from "react";
import BulkArrow from "../../assets/img/arrow.png";

class BulkTable extends Component {
  render() {
    const { columns, dataSource, scroll, pagination } = this.props;

    return (
      <Table
        className="my-table-class"
        columns={columns}
        dataSource={dataSource}
        size="small"
        sortIcon={{ ascend: BulkArrow, descend: BulkArrow }}
        scroll={scroll}
        displaySelectAll={false}
        pagination={pagination}
        showHeader={true}
      />
    );
  }
}

export default BulkTable;
