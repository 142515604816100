import React, { Component } from "react";
import { Row, Col } from "antd";
import BreadcrumbComponent from "components/UI-Components/BreadcrumbComponent";
import Icons from "components/UI-Components/Icons";
import EventList from "components/Events/EventList";
import { getCurrentlocaleText } from "Core/Utils";

class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {
      BreadcrumbList: [
        {
          name: getCurrentlocaleText("events.text"),
          link: "events",
        },
      ],
    };
  }

  render() {
    return (
      <div>
        <Row gutter={24} type="flex" align="middle">
          <Col span={24}>
            <h2 className="page-title">
              <Icons type="md" name="MdEvent" className="valign-middle" />
              <Icons type="fa" name="FaCog" className="event-header-icon" />
              {"  "}
              {getCurrentlocaleText("events.text")}
            </h2>
            <BreadcrumbComponent
              {...this.props}
              BreadcrumbList={this.state.BreadcrumbList}
            />
          </Col>
        </Row>
        <div style={{ minHeight: 360 }}>
          <Row gutter={16} type="flex" align="middle">
            <Col span={24}>
              <EventList />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Events;
