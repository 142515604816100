import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Cascader,
  Table,
  Tag,
  message,
  InputNumber,
  Checkbox,
  Popover,
  Spin,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import { observer, inject } from "mobx-react";
import {
  getCurrentlocaleText,
  getNodeVersion,
  dynamicNetworkModeBaseVersion,
  getOrgPolicyCounInfo,
  isDevelopmentMode,
  arraysEqual,
} from "Core/Utils";
import InodeController from "controller/InodeController";
import ClusterUtils from "components/Clusters/ClusterUtils";
import Icons from "components/UI-Components/Icons";

import Input from "../UI-Components/InputBox";
const FormItem = Form.Item;

@inject("UiStore", "OrgViewModel")
@observer
class ClusterNodeConfig extends Component {
  constructor(props) {
    super(props);
    this.orgModel = this.props.OrgViewModel;
    this.state = {
      loading: false,
      clusterediNodes: [],
      isFormEdited: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({ loading: true });
    this.updateiNodeDetails();
  }

  componentWillUnmount() {
    this.setState({ isFormEdited: false });
  }

  componentDidUpdate(prevProps) {
    // AddNodes
    if (
      prevProps.intialValues !== this.props.intialValues ||
      prevProps.intialValues.nodes !== this.props.intialValues.nodes
    ) {
      let clusterediNodes = this.state.clusterediNodes;
      if (
        clusterediNodes &&
        this.props.intialValues &&
        this.props.intialValues.nodes
      ) {
        this.props.intialValues.nodes.forEach(clusterNode => {
          if (clusterNode && clusterNode.node && clusterNode.node.id) {
            // Add Node
            let nodeDetails = clusterNode.nodeDetails;
            if (nodeDetails) {
              let nodeData = ClusterUtils.computeNewiNodeData();
              nodeData.key = nodeDetails.id;
              nodeData.node_id = nodeDetails.id;
              nodeData.org_name = nodeDetails.organization.name;
              nodeData.org_id = nodeDetails.organization.id;
              nodeData.node_name = nodeDetails.name;
              nodeData.record_value = [
                nodeDetails.organization.id,
                nodeDetails.id,
              ];
              nodeData.priority = clusterNode.config.priority;
              nodeData.is_candidate = clusterNode.config.is_candidate;
              //
              let index = clusterediNodes.findIndex(node => {
                return node && node.node_id === nodeData.node_id;
              });
              if (index === -1) {
                clusterediNodes.push(nodeData);
                this.setState({ clusterediNodes: clusterediNodes });
              }
            }
          }
        });
      }
    }
  }

  updateiNodeDetails = () => {
    let computedData = ClusterUtils.getConnectedNodesInfo(
      this.state.clusterediNodes,
      this.props.intialValues.nodes,
      this.props.intialValues,
    );
    let availableNodes = [];
    if (this.props.intialValues.nodes) {
      this.props.intialValues.nodes.forEach(clusterNode => {
        availableNodes.push(clusterNode.nodeDetails);
      });
    }
    this.setState({
      clusterediNodes:
        computedData.clusterediNodesData && computedData.clusterediNodesData,
      loading: false,
    });
    this.pushConnectedNodesdata(computedData.clusterediNodesData, true);
  };

  /**
   * Push  cluster connected nodes to the parent component
   */
  pushConnectedNodesdata = (data, isInitial = null) => {
    let clusterediNodes = [];
    data.forEach(collections => {
      if (collections.node_id !== null) {
        let node = {
          node_id: collections.node_id,
          config: {
            priority: collections.priority,
            is_candidate: collections.is_candidate,
          },
        };
        clusterediNodes.push(node);
      }
    });
    this.setData(
      isInitial && clusterediNodes.length === 0 ? null : clusterediNodes,
    );
  };
  setData = value => {
    this.props.setClusteredNodes(value);
  };
  updateHelp = data => {
    this.props.UiStore.SetStoreData("helpData", data);
  };

  render() {
    this.columns = [
      {
        title: getCurrentlocaleText("clusters.inodes.table.column.text"),
        dataIndex: "node_info",
        key: "node_info",
        width: "35%",
        render: (text, record, index) => {
          return (
            <Col>
              <span>
                {
                  <div>
                    <FormItem>
                      <Input
                        autoComplete="off"
                        disabled={true}
                        placeholder={getCurrentlocaleText(
                          "alertform.inode.placeholder.text",
                        )}
                        className={"cascader-disp" + record.key}
                        title={
                          record.record_value.length <= 0
                            ? getCurrentlocaleText(
                                "alertform.inode.placeholder.text",
                              )
                            : record.org_name + " / " + record.node_name
                        }
                        value={record.org_name + " / " + record.node_name}
                        onFocus={() => {
                          this.setState({ currentRowKey: record.key });
                          this.updateHelp({
                            title: getCurrentlocaleText(
                              "general.component.inode.text",
                            ),
                            data: [
                              {
                                subtitle: "",
                                content: getCurrentlocaleText(
                                  "cluster.inode.help.content",
                                ),
                              },
                            ],
                          });
                        }}
                      />
                    </FormItem>
                  </div>
                }
              </span>
            </Col>
          );
        },
      },
      {
        title: "",
        dataIndex: "is_candidate",
        key: "is_candidate",
        width: "45%",
        render: (text, record, index) => {
          return (
            <div style={{ marginBottom: "3%" }}>
              <Col style={{ width: "10%", display: "inline" }}>
                <Checkbox
                  className={`canididate-${record.key}`}
                  checked={!record.is_candidate ? false : true}
                  disabled={true}
                  onFocus={() => {
                    this.updateHelp({
                      title: getCurrentlocaleText(
                        "cluster.candidate.label.text",
                      ),
                      data: [
                        {
                          subtitle: "",
                          content: getCurrentlocaleText(
                            "cluster.candidate.help.content",
                          ),
                        },
                      ],
                    });
                  }}
                >
                  {getCurrentlocaleText("cluster.candidate.label.text")}
                </Checkbox>
              </Col>
              <Col style={{ display: "inline", paddingLeft: "8px" }}>
                {record.is_candidate && (
                  <span
                    className={"iot-label-not-required"}
                    title={getCurrentlocaleText(
                      "firewall.list.table.header.priority.text",
                    )}
                    style={{ marginLeft: "0px", marginRight: 2 }}
                    onClick={() => {
                      this.updateHelp({
                        title: getCurrentlocaleText(
                          "firewall.rule.priority.help.title.text",
                        ),
                        data: [
                          {
                            subtitle: "",
                            content: getCurrentlocaleText(
                              "cluster.inode.priority.help.message.text",
                            ),
                          },
                          {
                            subtitle: "",
                            content: getCurrentlocaleText(
                              "cluster.inode.priority.help.message.text2",
                            ),
                          },
                        ],
                      });
                    }}
                  >
                    {getCurrentlocaleText(
                      "firewall.list.table.header.priority.text",
                    ) + " :"}
                  </span>
                )}
              </Col>
              <Col style={{ marginLeft: "2px", display: "inline" }}>
                {record.is_candidate && (
                  <InputNumber
                    className={`priority-${record.key}`}
                    value={record.priority ? record.priority : 100}
                    disabled={true}
                    max={254}
                    min={1}
                    onFocus={() => {
                      this.updateHelp({
                        title: getCurrentlocaleText(
                          "firewall.rule.priority.help.title.text",
                        ),
                        data: [
                          {
                            subtitle: "",
                            content: getCurrentlocaleText(
                              "cluster.inode.priority.help.message.text",
                            ),
                          },
                          {
                            subtitle: "",
                            content: getCurrentlocaleText(
                              "cluster.inode.priority.help.message.text2",
                            ),
                          },
                        ],
                      });
                    }}
                  />
                )}
              </Col>
            </div>
          );
        },
      },
    ];

    let content = (
      <Row gutter={2}>
        <Col>
          <Table
            size={"small"}
            locale={{
              emptyText: getCurrentlocaleText(
                "cluster.form.inode.notfound.text",
              ),
            }}
            bordered={false}
            columns={this.columns}
            dataSource={ClusterUtils.removeDuplicates(
              this.state.clusterediNodes,
            )}
            pagination={false}
            expandedRowKeys={this.state.expandedRow}
            indentSize={5}
            {...(this.props.isFromClusterConversion
              ? scroll
              : this.props.isFromClusterConversion
              ? null
              : { y: 250 })}
            style={this.props.isFromClusterConversion ? { width: 750 } : null}
          />
        </Col>
      </Row>
    );
    return (
      <div>
        {this.state.loading ? (
          <div className="chart-spinner">
            <Spin tip="Loading..." />
          </div>
        ) : (
          <div>{content}</div>
        )}
      </div>
    );
  }
}

const ClusterNodeConfigSelector = Form.create()(ClusterNodeConfig);
export default ClusterNodeConfigSelector;
