import React, { Component } from "react";
import { string, object, node } from "prop-types";

/*
  This component displays a placeholder image that is hosted locally
  while it waits for a remote image to load.

  Usage: <BackgroundImage src={source} placeHolder={localImage} {...other attributes}>
        {...child components}
       </BackgroundImage>
*/
class BackgroundImage extends Component {
  static propTypes = {
    src: string.isRequired,
    placeHolder: string.isRequired,
    className: string,
    style: object,
    children: node,
  };

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      error: false,
    };

    this.handleLoad = this.handleLoad.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    // Making this a global so it can be later
    // nullified when the component unmounts
    this.image = new Image();

    this.image.src = this.props.src;
    this.image.onload = this.handleLoad;
    this.image.onerror = this.handleError;
  }

  shouldComponentUpdate(nextState, nextProps) {
    return !this.state.loaded;
  }

  componentWillUnmount() {
    this.image.onerror = null;
    this.image.onload = null;
    this.image = null;
  }

  handleLoad(e) {
    this.setState({
      loaded: true,
    });
  }

  handleError(e) {
    console.error("Failed to load ", this.props.src);

    this.setState({
      error: true,
    });
  }

  render() {
    const { src, placeHolder, children, ...props } = this.props;
    const source = !this.state.loaded || this.state.error ? placeHolder : src;
    let style = { backgroundImage: `url(${source})` };
    // GLOBAL_SETTINGS configured from the node js
    if (
      GLOBAL_SETTINGS &&
      (!GLOBAL_SETTINGS.login_background_url ||
        GLOBAL_SETTINGS.login_background_url.length === 0)
    ) {
      delete style.backgroundImage;
    }
    return (
      <div style={style} {...props}>
        {children}
      </div>
    );
  }
}

export default BackgroundImage;
