import BG0 from "./0.jpg";
import BG1 from "./1.jpg";
import BG2 from "./2.jpg";
import BG3 from "./3.jpg";
import bg from "../viewbg.jpg";

// TODO - AV - This has to be added as base64. so no need to wait on server response.
const BG = {
  BG0: BG0,
  BG1: BG1,
  BG2: BG2,
  BG3: BG3,
};

function random() {
  // GLOBAL_SETTINGS is configured form node js
  if (GLOBAL_SETTINGS) {
    if (
      GLOBAL_SETTINGS.login_background_url &&
      GLOBAL_SETTINGS.login_background_url.length > 0
    ) {
      return GLOBAL_SETTINGS.login_background_url[
        Math.floor(Math.random() * GLOBAL_SETTINGS.login_background_url.length)
      ];
    } else {
      return defaultBg();
    }
  } else {
    let keys = [];
    Object.keys(BG).forEach(key => {
      keys.push(key);
    });
    let selectedKey = keys[Math.floor(Math.random() * keys.length)];
    if (selectedKey) {
      return BG[selectedKey];
    }
    return defaultBg();
  }
}

function defaultBg() {
  return bg;
}

export default BG;

export { random };
