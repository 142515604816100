import React, { Component } from "react";

import { Input, Button } from "antd";
import { Form } from "@ant-design/compatible";
import { observer, inject } from "mobx-react";

import { focusFirstInput, getCurrentlocaleText } from "Core/Utils";
import FormErrorMessage from "components/UI-Components/FormErrorMessage";
import Icons from "components/UI-Components/Icons";
import GenerateSSHKeyAddHelp from "components/UI-Components/GenerateSSHKeyAddHelp";

const FormItem = Form.Item;
const { TextArea } = Input;

@inject("ModelStore", "UiStore")
@observer
class CertificateForm extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    /* Focus First Input */
    focusFirstInput();
  }

  setSelectVal = values => {
    let formVals = {
      orgId: this.props.extendData.orgDetails.id,
    };
    this.props.form.setFields(formVals);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form noValidate onSubmit={this.props.onSubmit} className="login-form">
        <FormItem label={getCurrentlocaleText("ssh.key.name")} hasFeedback>
          {getFieldDecorator("name", {
            rules: [
              {
                required: true,
                message: getCurrentlocaleText(
                  "servicetemplates.ssh.name.validation.msg",
                  { 0: getCurrentlocaleText("ssh.public.key") },
                ),
                whitespace: true,
              },
              {
                pattern: /^[A-Za-z0-9 ._-]*$/,
                message: getCurrentlocaleText(
                  "certificate.add.form.name.invalid.text",
                ),
              },
            ],
          })(
            <TextArea
              className="firstInput"
              type="string"
              autoComplete="off"
              prefix={<Icons type="fa" name="FaKey" />}
              placeholder={getCurrentlocaleText("ssh.key.name")}
              rows={1}
            />,
          )}
        </FormItem>
        <FormItem label={getCurrentlocaleText("ssh.public.key")} hasFeedback>
          {getFieldDecorator("public_key", {
            rules: [
              {
                required: true,
                message: getCurrentlocaleText("ssh.public.key.error"),
                whitespace: true,
              },
            ],
          })(
            <TextArea
              className="secondInput"
              type="string"
              autoComplete="off"
              prefix={<Icons type="fa" name="FaKey" />}
              placeholder={getCurrentlocaleText("ssh.public.key.placeholder", {
                0: getCurrentlocaleText("ssh.rsa.public.key"),
              })}
              rows={6}
            />,
          )}
        </FormItem>

        <GenerateSSHKeyAddHelp />

        <Button className="hidden-submit" htmlType="submit" />
        <FormErrorMessage />
      </Form>
    );
  }
}

const CertificateForms = Form.create()(CertificateForm);
export default CertificateForms;
