import React, { Component } from "react";
import { Row, Form, Col, Tag, Select, Radio } from "antd";
import Input from "components/UI-Components/InputBox";
import { observer, inject } from "mobx-react";
import _ from "lodash";
import propTypes from "prop-types";
import { customSort, getCurrentlocaleText } from "Core/Utils";
import LicenseSSH from "components/DataLists/licenseSSH";
import SecretDockerConfig from "components/DataLists/SecretDockerConfig";
import SecretController from "controller/SecretController";

const FormItem = Form.Item;
const Option = Select.Option;
const RadioGroup = Radio.Group;

const helperObjects = {
  secretCertiNameHelpObj: {
    help: {
      title: "License Name",
      data: [
        {
          subtitle: "",
          content:
            "Specify a name for the License. This name is for identification purpose only.",
        },
      ],
    },
  },
  uploaderHelpObj: {
    help: {
      title: "License File Upload",
      data: [
        {
          subtitle: "",
          content: "Upload a valid license file required to run the service.",
        },
      ],
    },
  },
  certificateObj: {
    help: {
      title: "Select a previously uploaded License",
      data: [
        {
          subtitle: "",
          content:
            "A license is required for running SkySpark. Select a previously uploaded license.",
        },
      ],
    },
  },
  secretDockerNameHelpObj: {
    help: {
      title: "Name for docker configuration",
      data: [
        {
          subtitle: "",
          content:
            "Specify a name for the docker configuration. This name is for identification purpose only.",
        },
      ],
    },
  },
};

@inject("UiStore", "SecretViewModel", "ServiceViewModel", "InodeViewModel")
@observer
class licenseForm extends Component {
  constructor(props) {
    super(props);
    this.secretModel = this.props.SecretViewModel;
    this.serviceModel = this.props.ServiceViewModel;
    this.inodeModel = this.props.InodeViewModel;
    this.searchTimeout = null;
    this.state = {
      uploadedLicenseFileName: this.props.UiStore.serviceFormData
        .uploadedLicenseFileName,
      data: [],
    };
  }

  componentDidMount() {
    if (!this.props.renderFrom) {
      if (this.inodeModel.inode && this.inodeModel.inode.organization) {
        let params = {
          org_id: this.inodeModel.inode.organization.id,
          own: true,
        };
        params.page = 0;
        params.label = "type:license";
        params.has_service = false;
        SecretController.getSecretsForDropdown(params).then(response => {
          // For edit only
          if (
            this.props.UiStore.serviceFormData.certi_list &&
            this.props.UiStore.serviceFormData.certi_list.length > 0
          ) {
            if (response) {
              let in_dx = response.findIndex(secret => {
                return (
                  secret.id === this.props.UiStore.serviceFormData.certi_list
                );
              });
              if (in_dx === -1) {
                let sec_id = Array.isArray(
                  this.props.UiStore.serviceFormData.certi_list,
                )
                  ? this.props.UiStore.serviceFormData.certi_list[0]
                  : this.props.UiStore.serviceFormData.certi_list;

                SecretController.getSecret(sec_id, false).then(resp => {
                  response.push(resp);
                  this.setState({
                    data: response ? this.listCertificate(response) : [],
                  });
                });
              } else {
                this.setState({
                  data: response ? this.listCertificate(response) : [],
                });
              }
            } else {
              let sec_id = Array.isArray(
                this.props.UiStore.serviceFormData.certi_list,
              )
                ? this.props.UiStore.serviceFormData.certi_list[0]
                : this.props.UiStore.serviceFormData.certi_list;

              SecretController.getSecret(sec_id, false).then(resp => {
                response.push(resp);
                this.setState({
                  data: response ? this.listCertificate(response) : [],
                });
              });
            }
          } else {
            this.setState({
              data: response ? this.listCertificate(response) : [],
            });
          }
        });
      }
    }
  }

  fetchCertificate = (search = "") => {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      let params = {
        org_id: this.inodeModel.inode.organization.id,
        own: true,
      };
      params.page = 0;
      params.search = search;
      params.label = "type:license";
      params.has_service = false;
      SecretController.getSecretsForDropdown(params).then(response => {
        this.setState({ data: response ? this.listCertificate(response) : [] });
      });
    }, 500);
  };

  listCertificate = data => {
    /*license drop-down only available for skyspark service*/
    let licenseList = [],
      id;
    if (data.length > 0) {
      // TODO - RK - Need to remove
      /*List assigned licenses*/
      /*
      let assignedService = [];
      this.serviceModel.services.length > 0 &&
        this.serviceModel.services.map((obj, i) => {
          obj.spec.labels["io-iotium-template"].toLowerCase() === "skyspark" &&
            obj.spec.volumes.map((dockerObj, index) => {
              if (
                dockerObj.secret_volume &&
                dockerObj.secret_volume.secret.id
              ) {
                assignedService.push(dockerObj.secret_volume.secret.id);
              }
            });
        });

      /*Do not show assigned services except secret assigned to this service while editing service

      this.props.UiStore.serviceFormData.previousCerti &&
        this.props.UiStore.serviceFormData.previousCerti.secret_volume &&
        (
          (id = this.props.UiStore.serviceFormData.previousCerti.secret_volume
            .secret.id),
          assignedService.splice(assignedService.indexOf(id), 1)
        );*/

      licenseList = data
        .sort((x, y) => customSort(x.name, y.name))
        /*.filter(val => {
          return (
            (assignedService.indexOf(val.id) < 0 &&
              val.labels &&
              val.labels.type === "license") ||
            (assignedService.indexOf(val.id) < 0 && !val.labels)
          );
        })*/
        .map((val, index) => {
          return <Option key={val.id}>{val.name}</Option>;
        });
    }

    return licenseList;
  };

  validateLicenseName = (rule, value, callback, field) => {
    /*validate name only when creating new service*/
    //if (this.props.actionType != "edit") {
    let failMessage = null,
      x = -1;

    /*LAT-3198*/
    this.props.UiStore.serviceFormData.rowDetails
      ? value !=
          this.props.UiStore.serviceFormData.rowDetails.selectedLicenseDetails
            .name &&
        (x =
          this.props.extra && this.props.extra.type === "license"
            ? this.props.UiStore.availableLicenseName.indexOf(value)
            : this.props.UiStore.availableSSHName.indexOf(value))
      : (x =
          this.props.extra && this.props.extra.type === "license"
            ? this.props.UiStore.availableLicenseName.indexOf(value)
            : this.props.UiStore.availableSSHName.indexOf(value));

    if (x >= 0) {
      failMessage =
        this.props.extra.type === "license"
          ? "License name is already in use. Please specify a different name."
          : "SSH key name is already in use. Please specify a different name.";
    }

    if (failMessage) {
      callback(failMessage);
    }
    //}
    callback();
  };

  removeSecret = e => {
    e.preventDefault(); /*required, else multi-tags will be deleted*/
    if (this.state.uploadedLicenseFileName.length == 1) {
      this.props.UiStore.errorMessage = "Atleast one license file is required.";
    } else {
      delete this.props.UiStore.serviceFormData.rowDetails
        .selectedLicenseDetails.data[e.target.parentElement.innerText];
      this.props.UiStore.serviceFormData.secret_volume &&
        delete this.props.UiStore.serviceFormData.secret_volume[
          e.target.parentElement.innerText
        ];
      let licenseList = this.props.UiStore.serviceFormData.uploadedLicenseFileName.indexOf(
        e.target.parentElement.innerText,
      );
      this.props.UiStore.serviceFormData.uploadedLicenseFileName.splice(
        licenseList,
        1,
      );
      this.setState({
        uploadedLicenseFileName: this.props.UiStore.serviceFormData
          .uploadedLicenseFileName,
      });
    }
  };

  renderList = () => {
    const { getFieldDecorator } = this.props.referenceForm;
    let licenseType = this.props.licenseType,
      licenseComponent = this.props.renderFrom,
      actionType = this.props.actionType,
      selectoptional =
        this.props.renderFrom === "licenseComponent" ? " - (optional)" : "",
      uploadoptional =
        this.props.licenseType === "PREVIOUS" ? " - (optional)" : "";

    return (
      <div>
        <Row gutter={8} style={{ marginBottom: "20px" }}>
          <Col span={12}>
            {(this.props.licenseType === "NEW" ||
              this.props.renderFrom === "licenseComponent") && (
              <FormItem label="License Name" hasFeedback key="secretCertiName">
                {getFieldDecorator(`secretCertiName`, {
                  rules: [
                    {
                      required: true,
                      message: ` Please enter License name`,
                      whitespace: true,
                    },
                    {
                      max: 255,
                      message:
                        "License name cannot be longer than 60 characters.",
                    },
                    {
                      pattern: /^[A-Za-z0-9 .&,-_]*$/,
                      message:
                        "License name may contain only the special characters . , & - _",
                    },
                    {
                      //validator: this.validateLicenseName,
                    },
                  ],
                  initialValue: this.props.UiStore.serviceFormData
                    ? this.props.UiStore.serviceFormData[`secretCertiName`]
                    : "",
                })(
                  <Input
                    type="input"
                    autoComplete="off"
                    placeholder="License Name"
                    onChange={this.props.inputChange}
                    id="secretCertiName"
                    // disabled={
                    //   this.props.type === "edit" ||
                    //   this.props.actionType === "edit"
                    // }
                    //disabled={this.props.actionType === "edit"}
                    onFocus={this.props.updateHelp.bind(
                      null,
                      helperObjects.secretCertiNameHelpObj.help,
                    )}
                  />,
                )}
              </FormItem>
            )}
          </Col>
          <Col span={12}>
            {(this.props.licenseType === "NEW" ||
              this.props.renderFrom === "licenseComponent") && (
              <FormItem
                label="Upload Licenses"
                //label={"Upload License" + uploadoptional}
                hasFeedback
                key="uploader"
                disabled={this.props.enableDisableLicenseUpload}
              >
                {getFieldDecorator("uploader", {
                  rules: [
                    {
                      required:
                        licenseType === "NEW" || actionType === "create"
                          ? true
                          : false,
                      message: `Please Upload a License`,
                    },
                  ],
                })(
                  <Input
                    style={{ padding: "3px" }}
                    type="file"
                    multiple={true}
                    onChange={this.props.fileUpload}
                    onFocus={this.props.updateHelp.bind(
                      null,
                      helperObjects.uploaderHelpObj.help,
                    )}
                    //disabled={this.props.type === "edit"}
                  />,
                )}
              </FormItem>
            )}
          </Col>
        </Row>
        <Row gutter={8}>
          {this.props.licenseType === "PREVIOUS" && (
            <FormItem
              label={
                this.props.label
                  ? this.props.label
                  : "Select License" + selectoptional
              }
            >
              {getFieldDecorator(`volumes_certi_list`, {
                rules: [
                  {
                    required: licenseType === "PREVIOUS" ? true : false,
                    message: `Please select a License`,
                  },
                ],
                initialValue: this.props.UiStore.serviceFormData
                  ? this.props.UiStore.serviceFormData.certi_list
                  : [],
              })(
                <Select
                  placeholder={
                    this.props.label ? this.props.label : "select a License"
                  }
                  onChange={this.props.serviceSelected.bind(
                    null,
                    this.props.certificateObj,
                  )}
                  mode={this.props.mode}
                  onFocus={this.props.updateHelp.bind(
                    null,
                    helperObjects.certificateObj.help,
                  )}
                  showSearch
                  filterOption={false}
                  onSearch={this.fetchCertificate}
                  notFoundContent={getCurrentlocaleText(
                    "general.notfound.placeholder.text",
                  )}
                  //disabled={this.props.type === "edit"}
                >
                  {this.state.data}
                </Select>,
              )}
            </FormItem>
          )}
        </Row>
        <Row gutter={8}>
          <Col>
            {this.props.actionType === "edit" &&
              this.props.UiStore.serviceFormData &&
              this.props.UiStore.serviceFormData.rowDetails && (
                <Row>
                  <Col span={4}>
                    <h3> License ID : </h3>
                  </Col>
                  <Col>{this.props.UiStore.serviceFormData.rowDetails.id}</Col>
                </Row>
              )}
          </Col>
          <Col>
            {//this.props.UiStore.serviceFormData &&
            this.state.uploadedLicenseFileName &&
              this.state.uploadedLicenseFileName.length > 0 && (
                <Row>
                  <Col span={4}>
                    <h3>Uploaded Files : </h3>
                  </Col>
                  <Col>
                    {this.state.uploadedLicenseFileName.map((val, i) => {
                      return (
                        <Tag
                          closable
                          key={i}
                          style={{
                            marginBottom: "10px",
                            marginRight: "12px",
                          }}
                          onClose={this.removeSecret}
                        >
                          {val}
                        </Tag>
                      );
                    })}
                  </Col>
                </Row>
              )}
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.props.renderFrom === "licenseComponent" ? (
          this.props.extra && this.props.extra.type === "license" ? (
            this.renderList()
          ) : this.props.extra && this.props.extra.type === "ssh" ? (
            <LicenseSSH
              referenceForm={this.props.referenceForm}
              validateLicenseName={this.validateLicenseName}
              //helperObjects={helperObjects}
              {...this.props}
            />
          ) : (
            <SecretDockerConfig
              referenceForm={this.props.referenceForm}
              {...this.props}
            />
          )
        ) : (
          this.renderList()
        )}
      </div>
    );
  }
}

licenseForm.propTypes = {
  fileUpload: propTypes.func,
  updateHelp: propTypes.func,
  inputChange: propTypes.func,
  serviceSelected: propTypes.func,
};

export default licenseForm;
