import React, { Component } from "react";
import { getCurrentlocaleText } from "Core/Utils";

class RouteHelp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedVnode: this.props.selectedVnode,
    };
  }

  UNSAFE_componentWillReceiveProps(newprops) {
    if (this.props.selectedVnode.length != newprops.selectedVnode.length) {
      this.setState({ selectedVnode: newprops.selectedVnode });
      this.updateHelp();
    }
  }

  componentDidMount() {
    this.updateHelp();
  }

  updateHelp = () => {
    let elm = document.querySelector("#route-help-content");
    if (elm) {
      let helpMessage;
      if (
        this.state.selectedVnode.length > 0 &&
        this.props.isSpecificIPSupported
      ) {
        helpMessage = getCurrentlocaleText("default_destination_help_message");
      } else if (
        (!this.state.selectedVnode || this.state.selectedVnode.length === 0) &&
        this.props.isSpecificIPSupported
      ) {
        helpMessage = getCurrentlocaleText(
          "default_destination_without_remote_help_message",
        );
      } else if (
        this.state.selectedVnode.length > 0 &&
        !this.props.isSpecificIPSupported
      ) {
        helpMessage = getCurrentlocaleText(
          "default_destination_without_specific_ip_help_message",
        );
      } else if (
        (!this.state.selectedVnode || this.state.selectedVnode.length === 0) &&
        !this.props.isSpecificIPSupported
      ) {
        helpMessage = getCurrentlocaleText(
          "default_destination_without_remote_and_specific_ip_help_message",
        );
      } else {
        helpMessage = getCurrentlocaleText("default_destination_help_message");
      }
      elm.innerHTML = helpMessage;
    }
  };

  render() {
    this.updateHelp();
    return <div id="route-help-content" />;
  }
}
export default RouteHelp;
