import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Input, InputNumber, Select } from "antd";
import { ALERT, getCurrentlocaleText } from "Core/Utils";

const InputGroup = Input.Group;
const Option = Select.Option;

@inject("UiStore")
@observer
class AlertDuration extends Component {
  constructor(props) {
    super(props);
    this.UiStore = this.props.UiStore;
    this.state = {
      duration: 5,
      units: ALERT.DURATION.MINUTES,
      min: 1,
      max: 59,
      step: 5,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    let value = this.state;
    if (
      props.default &&
      props.default.duration &&
      props.default.duration !== this.state.duration
    ) {
      value.duration = props.default.duration;
      this.setState({ value });
      this.triggerChange({ duration: value.duration, units: value.units });
    }
    if (props.default && props.default.units !== this.state.units) {
      let units = props.default.units;
      if (units === ALERT.DURATION.MINUTES) {
        value.units = units;
        value.min = 1;
        value.max = 59;
        value.step = 5;
      } else if (units === ALERT.DURATION.HOURS) {
        value.units = units;
        value.min = 1;
        value.max = 23;
        value.step = 1;
      } else {
        value.units = units;
        value.min = 1;
        value.max = 7;
        value.step = 1;
      }
      this.setState({ value });
      this.triggerChange({ duration: value.duration, units: value.units });
    }
  }

  handleDuration = duration => {
    let value = this.state;
    value.duration = duration;
    this.setState({ value });
    this.triggerChange({ duration: value.duration, units: value.units });
  };

  handleUnits = units => {
    let value = this.state;
    if (units === ALERT.DURATION.MINUTES) {
      value.duration = 5;
      value.units = units;
      value.min = 1;
      value.max = 59;
      value.step = 5;
    } else if (units === ALERT.DURATION.HOURS) {
      value.duration = 1;
      value.units = units;
      value.min = 1;
      value.max = 23;
      value.step = 1;
    } else {
      value.duration = 1;
      value.units = units;
      value.min = 1;
      value.max = 7;
      value.step = 1;
    }
    this.setState({ value });
    this.triggerChange({ duration: value.duration, units: value.units });
  };

  triggerChange = value => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  render() {
    return (
      <InputGroup>
        <InputNumber
          disabled={this.props.formType == "view" || this.props.isDefault}
          min={this.state.min}
          max={this.state.max}
          step={this.state.step}
          value={this.state.duration}
          onChange={this.handleDuration}
          onFocus={this.props.onFocus}
        />
        <Select
          disabled={this.props.formType == "view" || this.props.isDefault}
          value={this.state.units}
          onChange={this.handleUnits}
          style={{ width: 100 }}
          onFocus={this.props.onFocus}
        >
          <Option key={ALERT.DURATION.MINUTES} value={ALERT.DURATION.MINUTES}>
            {getCurrentlocaleText("alert.duration.minutes.text")}
          </Option>
          <Option key={ALERT.DURATION.HOURS} value={ALERT.DURATION.HOURS}>
            {getCurrentlocaleText("alert.duration.hours.text")}
          </Option>
          <Option key={ALERT.DURATION.DAYS} value={ALERT.DURATION.DAYS}>
            {getCurrentlocaleText("alert.duration.days.text")}
          </Option>
        </Select>
      </InputGroup>
    );
  }
}

export default AlertDuration;
