import React from "react";
import { Doughnut as PieChart } from "react-chartjs-2";
import { Spin } from "antd";
import AuthStore from "../../Stores/AuthStore";
import OrgViewModel from "../../view-model/OrgViewModel";
import DashboardStore from "../../Stores/DashboardStore";

//colors to define the node status
const StatusColor = {
  ALIVE: "#00A854",
  NEW: "#108EE9",
  UNREACHABLE: "#F04134",
  REBOOTING: "#FFBF00",
};

const Charts = props => {
  const handleClick = chartElement => {
    const currentOrgId =
      props.match.params && props.match.params.id
        ? props.match.params.id
        : OrgViewModel && OrgViewModel.org && OrgViewModel.org.id
        ? OrgViewModel.org.id
        : AuthStore &&
          AuthStore.loggedInUser &&
          AuthStore.loggedInUser.organization &&
          AuthStore.loggedInUser.organization.id;
    let key =
      chartElement[0] && chartElement[0]._chart
        ? chartElement[0]._chart.data.labels[chartElement[0]._index]
        : null;
    if (key) {
      key = key.replace(/\s+/g, "");
      let url =
        `/dashboard/inodes/` + currentOrgId + `/status/${key.toLowerCase()}`;
      props.history.push(url);
    }
  };

  let dataCounts = [];
  let dataLables = [];
  let dataBackgroundcolors = [];
  let statusCount = DashboardStore.countsByiNodeStatus;
  // data iteration for lables and data.
  for (var key in statusCount) {
    if (statusCount[key] > 0) {
      dataLables.push(key.toUpperCase());
      dataCounts.push(statusCount[key]);
      dataBackgroundcolors.push(StatusColor[key.toUpperCase()]);
    }
  }

  const data = {
    datasets: [
      {
        data: dataCounts,
        backgroundColor: dataBackgroundcolors,
        hoverBackgroundColor: dataBackgroundcolors,
      },
    ],
    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels: dataLables,
  };

  const options = {
    responsive: true,
    animation: {
      segmentShowStroke: false,
      animateScale: true,
      easing: "easeOutBounce",
    },
    title: {
      display: true,
      //text: "Total : " + this.props.DashboardStore.Counts.inodes_count,
      fontSize: 25,
      fontColor: "#373737",
      //position: "bottom",
    },
    cutoutPercentage: 65,
    legend: {
      display: true,
      position: "bottom",
      fullWidth: true,
      labels: {
        usePointStyle: true,
        fontStyle: "bold",
        fontSize: 9,
        padding: 18,
        fontColor:
          GLOBAL_SETTINGS && GLOBAL_SETTINGS.text_color
            ? GLOBAL_SETTINGS.text_color
            : "#365c6f",
        generateLabels: function(chart) {
          var data = chart.data;
          if (data.labels.length && data.datasets.length) {
            return data.labels.map(function(label, i) {
              var meta = chart.getDatasetMeta(0);
              var ds = data.datasets[0];
              var arc = meta.data[i];
              var custom = (arc && arc.custom) || {};
              var getValueAtIndexOrDefault =
                Chart.helpers.getValueAtIndexOrDefault;
              var arcOpts = chart.options.elements.arc;
              var fill = custom.backgroundColor
                ? custom.backgroundColor
                : getValueAtIndexOrDefault(
                    ds.backgroundColor,
                    i,
                    arcOpts.backgroundColor,
                  );
              var stroke = custom.borderColor
                ? custom.borderColor
                : getValueAtIndexOrDefault(
                    ds.borderColor,
                    i,
                    arcOpts.borderColor,
                  );
              var bw = custom.borderWidth
                ? custom.borderWidth
                : getValueAtIndexOrDefault(
                    ds.borderWidth,
                    i,
                    arcOpts.borderWidth,
                  );
              // We get the value of the current label
              var value =
                chart.config.data.datasets[arc._datasetIndex].data[arc._index];

              return {
                // Instead of `text: label,`
                // We add the value to the string
                text: label + " : " + value,
                fillStyle: fill,
                strokeStyle: stroke,
                lineWidth: bw,
                hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                index: i,
              };
            });
          } else {
            return [];
          }
        },
      },
      onHover: function(e) {
        e.target.style.cursor = "pointer";
      },
    },
    tooltips: {
      enabled: true,
      mode: "single",
      intersect: true,
      labelColor: function(tooltipItem, chart) {
        return {
          borderColor: "rgb(255, 0, 0)",
          backgroundColor: "rgb(255, 0, 0)",
        };
      },
    },
    hover: {
      onHover: function(e) {
        var point = this.getElementAtEvent(e);
        if (point.length) e.target.style.cursor = "pointer";
        else e.target.style.cursor = "default";
      },
    },
  };

  return (
    <div>
      <div className="widget-title">{"iNodes by Status"}</div>{" "}
      {dataCounts &&
      dataCounts.length > 0 &&
      AuthStore.IsPermitted("NODE:VIEW") ? (
        <div>
          <PieChart
            height={400}
            width={400}
            options={options}
            data={data}
            getElementAtEvent={handleClick}
          />
        </div>
      ) : (
        <div className="widget-nodata-msg">
          {DashboardStore &&
          DashboardStore.counts &&
          DashboardStore.counts.inode &&
          DashboardStore.counts.inode !== 0 &&
          AuthStore.IsPermitted("NODE:VIEW") ? (
            <div className="chart-spinner">
              <Spin tip="Loading..." />
            </div>
          ) : (
            <i>No iNodes</i>
          )}
        </div>
      )}
    </div>
  );
};

export default Charts;
