import React from "react";
import { Link } from "react-router-dom";
import { getCurrentlocaleText } from "../../Core/Utils";

export default function RepNetTableFromVirtual(sorterInfo) {
  return [
    {
      title: (
        <span title={getCurrentlocaleText("vir.inode.title")}>
          {getCurrentlocaleText("vir.inode.title")}
        </span>
      ),
      dataIndex: "virtual_node",
      key: "virtual_node",
      render: (textObj, record, index) => {
        return (
          <>
            {textObj && textObj.id && (
              <Link
                to={"/orgs/" + record.organization.id + "/inodes/" + textObj.id}
              >
                {textObj.name}
              </Link>
            )}
          </>
        );
      },
    },
    {
      title: (
        <span title={getCurrentlocaleText("cidr.native.title")}>
          {getCurrentlocaleText("cidr.native.title")}
        </span>
      ),
      dataIndex: "native_cidr",
      key: "nativeCidr",
      render: (obj, record, index) => {
        return obj && obj.cidr ? obj.cidr : "-";
      },
    },
    {
      title: (
        <span title={getCurrentlocaleText("cidr.repnet.title")}>
          {getCurrentlocaleText("cidr.repnet.title")}
        </span>
      ),
      dataIndex: "repnet_cidr",
      key: "repnetCidr",
      render: (obj, record, index) => {
        return obj && obj.cidr ? obj.cidr : "-";
      },
    },
    {
      title: (
        <span title={getCurrentlocaleText("node.cluster.edge.title")}>
          {getCurrentlocaleText("node.cluster.edge.title")}
        </span>
      ),
      dataIndex: "nodeCluster",
      key: "nodeCluster",
      render: (text, record, index) => {
        return (
          <>
            {record && record.organization && record.organization.id && (
              <>
                {record && record.cluster && record.cluster.id ? (
                  <Link
                    to={
                      "/orgs/" +
                      record.organization.id +
                      "/clusters/" +
                      record.cluster.id
                    }
                  >
                    {record.cluster.name}
                  </Link>
                ) : record && record.edge_node && record.edge_node.id ? (
                  <Link
                    to={
                      "/orgs/" +
                      record.organization.id +
                      "/inodes/" +
                      record.edge_node.id
                    }
                  >
                    {record.edge_node.name}
                  </Link>
                ) : (
                  "-"
                )}
              </>
            )}
          </>
        );
      },
    },
    {
      title: (
        <span title={getCurrentlocaleText("network.networkname.help.title")}>
          {getCurrentlocaleText("network.networkname.help.title")}
        </span>
      ),
      dataIndex: "edge_network",
      key: "name",
      render: (textObj, record, index) => {
        return <>{textObj && textObj.name && <div>{textObj.name}</div>}</>;
      },
    },
  ];
}
