import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import { Checkbox } from "antd";
import { randomAlphaNumericString, copyObject } from "Core/Utils";
import propTypes from "prop-types";
import { inject } from "mobx-react";
import { computed } from "mobx";
import Twig from "twig";

/*Define form elements*/
const FormItem = Form.Item;

@inject("UiStore")
export default class EULA extends Component {
  constructor(props) {
    super(props);

    this.validationMessage =
      this.props.properties.constraints &&
      this.props.properties.constraints.validationMessage
        ? this.props.properties.constraints.validationMessage
        : `Please accept to terms and conditions`;
  }

  validateAcceptance = (rule, value, callback, field) => {
    let failMessage = null;
    if (value === false || value === null) {
      failMessage = this.validationMessage;
    }

    if (failMessage) {
      callback(failMessage);
    }
    callback();
  };

  @computed
  get componentVisibility() {
    let visibility = null;
    if (
      this.props.UiStore.computedOutputObject &&
      this.props.UiStore.computedOutputObject.steps
    ) {
      if (this.props.properties.visibility) {
        var template = Twig.twig({
          data: this.props.properties.visibility,
        });
        let computedOutputObject = copyObject(
          this.props.UiStore.computedOutputObject,
        );
        visibility = template.render(computedOutputObject);
        try {
          visibility = JSON.parse(visibility);
        } catch {}
      }
    }
    return visibility ? true : false;
  }

  render() {
    const { getFieldDecorator } = this.props.referenceForm; // reference of base form
    //let required = this.props.properties.constraints.required,
    let id = this.props.id
      ? this.props.id
      : `EULA${randomAlphaNumericString(3)}`;

    let disable = false;
    if (this.props.editmode) {
      disable = this.props.properties.noneditable
        ? this.props.properties.noneditable
        : false;
    }
    disable = this.props.properties.disabled
      ? this.props.properties.disabled
      : disable;

    // For View only
    disable = this.props.viewOnly === true ? true : disable;

    let iniVal =
      this.props.urlParams.params && this.props.urlParams.params.spec_id
        ? true
        : false;

    let visibility = this.props.properties.visibility
      ? this.componentVisibility
      : true;

    return visibility ? (
      <div>
        <FormItem>
          {getFieldDecorator(id, {
            rules: [
              {
                required: true,
                message: this.validationMessage,
              },
              {
                validator: this.validateAcceptance,
              },
            ],
            valuePropName: "checked",
            initialValue: iniVal /* LAT-5566 : in edit mode , always checked. */,
          })(
            <Checkbox
              disabled={disable}
              onFocus={
                this.props.onFocus &&
                this.props.onFocus.bind(null, this.props.properties.help)
              }
            >
              <span>
                {this.props.properties.label}
                {this.props.properties.link && (
                  <a href={this.props.properties.link.url} target="blank">
                    {" "}
                    {this.props.properties.link.text}
                  </a>
                )}
              </span>
            </Checkbox>,
          )}
        </FormItem>
      </div>
    ) : (
      <div></div>
    );
  }
}

EULA.propTypes = {
  referenceForm: propTypes.any,
  id: propTypes.string,
  properties: propTypes.shape({
    label: propTypes.string.isRequired,
    onChange: propTypes.func,
    constraints: propTypes.shape({
      validationMessage: propTypes.string,
    }),
    options: propTypes.shape({
      style: propTypes.object,
    }),
  }),
};

/*
  sample schema

  {
    "type": "EULA",
    "visible": true,
    "id": "abc",
    "props": {
      "label": "Enable Secure Shell",
      "link": {
        "text": "hello",
        "url": "http://www.google.com"
      },
      "help": "<div>help for Eula</div>",
      "disabled": false,
      "noneditable" : true,
      "constraints": {
        "validationMessage": " validate me"
      }
    }
  }


*/
