import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Popover } from "antd";
import { GlobalOutlined, MailOutlined } from "@ant-design/icons";
import Icons from "components/UI-Components/Icons";
import ChannelController from "../../../controller/ChannelController";
import WebhookController from "../../../controller/WebhookController";
import { ALERT, getCurrentlocaleText } from "Core/Utils";

const EMAIL = "Email";
const WEBHOOK = "Webhook";

@inject("UiStore", "AuthStore", "AlertViewModel")
@observer
class AlertNotificationDisplay extends Component {
  constructor(props) {
    super(props);
    this.UiStore = this.props.UiStore;
    this.AuthStore = this.props.AuthStore;
    this.alertModel = this.props.AlertViewModel;
    this.state = {
      channelType: ALERT.CHANNELS.EMAIL,
    };
  }

  UNSAFE_componentWillMount() {
    let record = this.props.record;
    let channel = {},
      channelType;
    if (record.channelType) {
      channelType = record.channelType;
      this.setState({ channelType: channelType });
      // Get channel details
      if (record.channel) {
        // For Email
        if (channelType === ALERT.CHANNELS.EMAIL) {
          if (this.alertModel.ownAlerts) {
            if (this.AuthStore.loggedInUser) {
              channel = {
                id: this.AuthStore.loggedInUser.email,
                to: this.AuthStore.loggedInUser.email,
              };
              this.alertModel.addEmailChannel(channel);
            }
          } else {
            if (record.user) {
              ChannelController.getChannel(
                record.user.id,
                record.channel.id,
              ).then(response => {
                if (response) {
                  channel = response;
                  this.alertModel.addEmailChannel(channel);
                }
              });
            }
          }
        } else {
          // For webhook
          if (channelType === ALERT.CHANNELS.WEBHOOK) {
            if (this.alertModel.ownAlerts) {
              WebhookController.getWebhook(record.channel.id).then(response => {
                if (response) {
                  channel = response;
                  this.alertModel.addWebhookChannel(channel);
                }
              });
            } else {
              if (record.user) {
                ChannelController.getChannel(
                  record.user.id,
                  record.channel.id,
                ).then(response => {
                  if (response) {
                    channel = response;
                    this.alertModel.addWebhookChannel(channel);
                  }
                });
              }
            }
          }
        }
      }
    }
  }

  componentWillUnmount() {
    this.alertModel.resetChannels();
  }

  render() {
    let { channelType } = this.state;
    let channel;
    if (channelType === ALERT.CHANNELS.EMAIL) {
      channel = this.alertModel.channel.email.find(email => {
        if (this.alertModel.ownAlerts) {
          return (
            email &&
            this.AuthStore.loggedInUser &&
            email.id === this.AuthStore.loggedInUser.email
          );
        } else {
          return (
            email &&
            this.props.record &&
            this.props.record.channel &&
            email.id === this.props.record.channel.id
          );
        }
      });
    }

    if (channelType === ALERT.CHANNELS.WEBHOOK) {
      channel = this.alertModel.channel.webhook.find(webhook => {
        return (
          webhook &&
          this.props.record &&
          this.props.record.channel &&
          webhook.id === this.props.record.channel.id
        );
      });
    }

    return (
      <div>
        {channelType && (
          <span key={channelType}>
            {/* EMAIL NOTIFICATION ICON */}
            <span
              style={{
                marginRight: 7,
                pointerEvents:
                  channelType === ALERT.CHANNELS.EMAIL ? "auto" : "none",
              }}
            >
              <Popover
                title={channelType === ALERT.CHANNELS.EMAIL && EMAIL}
                content={
                  channelType === ALERT.CHANNELS.EMAIL && (
                    <div>
                      {channel && channel.to && (
                        <div>
                          <span>{channel.to}</span>
                        </div>
                      )}
                    </div>
                  )
                }
              >
                {channelType === ALERT.CHANNELS.EMAIL ? (
                  <MailOutlined
                    className={
                      channelType === ALERT.CHANNELS.EMAIL
                        ? "alert-type-medium-icon"
                        : "alert-event-header-icon"
                    }
                  />
                ) : null}
              </Popover>
            </span>
            {/* WEBHOOK NOTIFICATION ICON */}
            <span
              style={{
                marginRight: 7,
                pointerEvents:
                  channelType === ALERT.CHANNELS.WEBHOOK ? "auto" : "none",
              }}
            >
              <Popover
                title={channelType === ALERT.CHANNELS.WEBHOOK && WEBHOOK}
                content={
                  channelType === ALERT.CHANNELS.WEBHOOK && (
                    <div
                      style={{
                        width: 300,
                        height: 60,
                      }}
                    >
                      {channel && channel.name && (
                        <div className="alert-notifiction-popover-content">
                          <strong className="alert-notifiction-popover-content-text">
                            {getCurrentlocaleText(
                              "alert.notification.webhook.name",
                            )}
                          </strong>
                          &nbsp;
                          <span
                            className="alert-notifiction-popover-content-text"
                            title={channel.name}
                          >
                            {channel.name}
                          </span>
                        </div>
                      )}
                      {channel && channel.url && (
                        <div className="alert-notifiction-popover-content">
                          <strong className="alert-notifiction-popover-content-text">
                            {getCurrentlocaleText(
                              "alert.notification.webhook.url",
                            )}
                          </strong>
                          &nbsp;
                          <span
                            className="alert-notifiction-popover-content-text"
                            title={channel.url}
                          >
                            {channel.url}
                          </span>
                        </div>
                      )}
                    </div>
                  )
                }
              >
                {channelType === ALERT.CHANNELS.WEBHOOK ? (
                  <GlobalOutlined
                    className={
                      channelType === ALERT.CHANNELS.WEBHOOK
                        ? channel && channel.is_enabled
                          ? "alert-type-medium-icon"
                          : "alert-type-medium-icon-error"
                        : "alert-event-header-icon"
                    }
                  />
                ) : null}
              </Popover>
            </span>
          </span>
        )}
      </div>
    );
  }
}

export default AlertNotificationDisplay;
